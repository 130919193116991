import WorldIcon from "../../../../content/world.svg";
import LockIcon from "../../../../content/lock.svg";
import { SharePostDestinations } from "./sharePostDestinationEnums";

// Constants Values
export const DEFAULT_MAX_POLL_OPTIONS = 4;
export const FeedFilterTypeEnum = {
  posts: 1,
  polls: 2,
  docs: 3,
  tagged: 4,
  Department: 5,
  photos: 6,
  videos: 7,
};

// Enums
export const PostReferenceType = Object.freeze({
  MAIN_FEED: 1,
  GROUP: 2,
  PROJECTS: 3,
  TIMELINE: 4,
});

export const ReactionType = Object.freeze({
  NoReaction: 0,
  Like: 1,
  Celebrate: 2,
  Support: 3,
  Love: 4,
  Insightful: 5,
  Curious: 6,
});

export const PostType = Object.freeze({
  DEFAULT: 1,
  POLL: 2,
  isPollType: (type) => type === PostType.POLL,
});

export const PollType = Object.freeze({
  DEFAULT: 1,
  PICTURE: 2,
});

export const PostPrivacyType = Object.freeze({
  PUBLIC: 1,
  PRIVATE: 2,
  EXTERNAL: 3,
  getPostTypeIcon: (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case PostPrivacyType.PUBLIC:
        return WorldIcon;
      case PostPrivacyType.PRIVATE:
        return LockIcon;
      case PostPrivacyType.EXTERNAL:
        return LockIcon;
    }
  },
});

export const CanSharePostOnFeed = (type) => {
  if (!PostType.isPollType(type)) {
    return true;
  } else return false;
};

export const IsPostShareable = (privacyId, modulePrivacyId) => {
  if (privacyId !== PostPrivacyType.PRIVATE) {
    return true;
  } else return false;
};

export const PostTypeAction = (
  postType,
  SharedestinationNames,
  isUser = false
) => {
  const currentPath = window.location.pathname;

  switch (postType) {
    case PostReferenceType.GROUP:
      return {
        destination: SharedestinationNames[SharePostDestinations.GROUP],
        disabled: true,
      };
    case PostReferenceType.PROJECTS:
      return {
        destination: SharedestinationNames[SharePostDestinations.PROJECT],
        disabled: true,
      };
    case PostReferenceType.TIMELINE:
      return {
        destination: isUser
          ? SharedestinationNames[SharePostDestinations.TIMELINE]
          : SharedestinationNames[SharePostDestinations.MEMBERS_PROFILE],
        disabled: currentPath === "/" ? false : (isUser ? false : true),
      };
    default:
      return {
        destination: SharedestinationNames[SharePostDestinations.FEED],
        disabled: false,
      };
  }
};

export const PostDestinationByPostRefType = {
  [PostReferenceType.GROUP]: SharePostDestinations.GROUP,
  [PostReferenceType.PROJECTS]: SharePostDestinations.PROJECT,
  [PostReferenceType.MAIN_FEED]: SharePostDestinations.FEED,
  [PostReferenceType.TIMELINE]: SharePostDestinations.TIMELINE,
  [`${PostReferenceType.TIMELINE}Member`]: SharePostDestinations.MEMBERS_PROFILE,
};

export const DestinationTagsColor = {
  [SharePostDestinations.GROUP]: "green",
  [SharePostDestinations.PROJECT]: "blue",
  [SharePostDestinations.FEED]: "geekblue",
  [SharePostDestinations.TIMELINE]: "purple",
  [SharePostDestinations.MEMBERS_PROFILE]: "red",
};
