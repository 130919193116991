import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllAttendanceDeductionPolicyService, GetAttendanceDeductionPolicyByIdService, saveAttandanceDeductionPolicyService } from "../service/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { message } from "antd";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";

export const saveAttandanceDeductionPolicy = createAsyncThunk(
    "allowance/saveAttandanceDeductionPolicy",
    async (args) => {
      const res = await saveAttandanceDeductionPolicyService(args);
      console.log(res, "response");
      if (res.responseCode) {
        if (res.responseCode === responseCode.Success) {
          message.success("DeductionPolicy save successfully!");
        } else {
          message.error(`Error: ${res.message}`);
        }
      }
      return res;
    }
  );


  export const GetAllAttendanceDeductionPolicy = createAsyncThunk(
    "allowance/GetAllAttendanceDeductionPolicy",
    async (args, { dispatch }) => {
      const res = await GetAllAttendanceDeductionPolicyService();
      if (!res.responseCode) {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  );

  export const GetAttendanceDeductionPolicyById = createAsyncThunk(
    "allowance/GetAttendanceDeductionPolicyById",
    async (args, { dispatch }) => {
      const res = await GetAttendanceDeductionPolicyByIdService(args);
      if (!res.responseCode) {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  );