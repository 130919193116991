import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Tag, Collapse } from "antd";
import Avatar from "../../../../sharedComponents/Avatar/avatarOLD";
import {
	getStatusLabelAndColor,
	ScheduleMemberStatus,
	ScheduleMemberType,
} from "../../enum/enum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { ScheduleTypeEnum } from "../../utils/ScheduleTypeEnum";
import { useDispatch } from "react-redux";
import { SendEmailAction } from "../../store/action";
import { responseCode } from "../../../../../services/enums/responseCode";

function ScheduleMembersList({
  status,
  data,
  memberType,
  isActionEnabled,
  handleMemberStatusChange,
  handleMemberTypeStatusChange,
  id,
  creator = {},
  index,
  scheduleType = ScheduleTypeEnum.Meeting,
  interviewQuestions = <></>,
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule, sharedLabels } = dictionaryList[userLanguage];
  const { label, color } = getStatusLabelAndColor("", "")[status];
  const loggedInUserId = useSelector((state) => state.userSlice.user.id);
  const [isEmailSent, setIsEmailSent]  = useState(false)
  const [isLoading, setisLoading]  = useState(false)

  const dispatch = useDispatch()
  const handleSendEmail = () =>{
    setisLoading(true)
      !isEmailSent && dispatch(SendEmailAction(id)).then((res)=>{
        res?.payload?.responseCode === responseCode.Success && setIsEmailSent(true)
        const timer = setTimeout(() => {
          setisLoading(false);
        }, 2000);
  
        return () => clearTimeout(timer);
      })
  }

  useEffect(()=>{
    if (isEmailSent) {
      const timer = setTimeout(() => {
        setIsEmailSent(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  },[isEmailSent])
  
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const renderContent = () => {
    const content = (
      <div className="approval__body-header">
        <div className="left">
          <Avatar
            src={data?.image && data?.image}
            name={data?.name}
            round
            width={"30px"}
            height={"30px"}
          />
          <div className="userDetail">
            <span className={`username ${validateEmail(data?.name) && "!normal-case"}`}>{data?.name}</span>
            <span className="designation">
              {data?.designation
                ? data?.designation
                : sharedLabels.NoDesignation}
            </span>
          </div>
        </div>
        <div className="right">
          <div className="flex gap-1 items-center">
            <span className="text-xs text-primary-color">
              {memberType === ScheduleMemberType.Admin && schedule.Host}
            </span>

            {(status === ScheduleMemberStatus.Waiting && isActionEnabled) ||
            (status === ScheduleMemberStatus.Waiting &&
              loggedInUserId === data?.id) ? (
              <div className="flex gap-1">
                {memberType !== ScheduleMemberType.Admin &&
                  creator?.id === loggedInUserId && (
                    <Button
                      className={` ${ !isEmailSent ? "ThemeBtn" : "!text-white !bg-green-600 !border-transparent !rounded !w-[60px]"} border-white	!hidden !p-1 group-hover:!inline attend-declineTag`}
                      onClick={()=> !isLoading && handleSendEmail()}
                    >
                      {!isEmailSent ? "Send Email" : "Sent"}
                    </Button>
                  )}
                {memberType !== ScheduleMemberType.Admin &&
                  creator?.id === loggedInUserId && (
                    <Button
                      className="!hidden !rounded-sm !p-1 group-hover:!inline attend-declineTag"
                      type="dashed"
                      onClick={(e) => {
                        handleMemberTypeStatusChange(
                          id,
                          ScheduleMemberType.Admin
                        );
                      }}
                    >
                      {schedule.MakeHost}
                    </Button>
                  )}

                <Tag
                  onClick={() => {
                    handleMemberStatusChange(
                      id,
                      ScheduleMemberStatus.Attending
                    );
                  }}
                  className="attend-declineTag attendingTag"
                >
                  {schedule.Attending}
                </Tag>
                <Tag
                  onClick={() => {
                    handleMemberStatusChange(
                      id,
                      ScheduleMemberStatus.NotAttending
                    );
                  }}
                  className=" attend-declineTag declineTag"
                >
                  {sharedLabels.Decline}
                </Tag>
              </div>
            ) : (
              <Tag style={{ background: color }}>{label}</Tag>
            )}
          </div>
        </div>
      </div>
    );

    if (scheduleType === ScheduleTypeEnum.Interview) {
      return (
        <Collapse className="approvalCollapse ant-collapse cursor-pointer group">
          <Collapse.Panel header={content} key={index}>
            {interviewQuestions}
          </Collapse.Panel>
        </Collapse>
      );
    }

	return (
		<div className="approvalCollapse ant-collapse cursor-pointer group">
		  <div className="ant-collapse-item">{content} </div>
		</div>
	  );
  };

  return <div>{renderContent()}</div>;
}

export default ScheduleMembersList;
