import React, { useState, useContext, useEffect } from "react";
import { Button, Drawer, Form, Input, Radio } from "antd";
import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { CareerDictionary } from "../../../localization";
import { addCareerAplicantLinkAction, addCareerApplicant } from "../../../store/action";
import SingleUpload from "../../../../../sharedComponents/Upload/singleUpload";
import { STRINGS } from "../../../../../../utils/base";
import { useSelector } from "react-redux";
import { handleOpenApplyComposer } from "../../../store/slice";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { ResponseType } from "../../../../../../utils/api/ResponseResult";

const ApplyComposer = (props) => {
  const { isExternal = false } = props



  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, Direction, careers } = dictionaryList[userLanguage];

  const dispatch = useDispatch();
  const [resumeState, setResumeState] = useState(null);
  const [form] = Form.useForm();

  const { careerLoader, applySuccess, applyComposer } = useSelector(
    (state) => state.careerSlice
  );

  useEffect(() => {
    if (applySuccess) {
      form.resetFields();
    }
  }, [applySuccess, form]);


  const onFinish = (values) => {

    let resume = {
      id: STRINGS.DEFAULTS.guid,
      file: resumeState && resumeState[0]?.originFileObj
    }
    const payload = {
      ...values,
      careerId: props.id,
      resume: resume,
    };

    // Value need to set  (Need to get from Enum)
    if (payload.status === "applicant") {
      payload.status = 1; // No selection
    } else if (payload.status === "shortlisted") {
      payload.status = 2; // Shortlisted
    } else if (payload.status === "completed") {
      payload.status = 4; // completed
    }
    if(props?.isExternal)
    {
      dispatch(addCareerAplicantLinkAction(payload)).then(response =>{
        console.log(response,"===response====")
        if(response?.payload?.type === ResponseType.SUCCESS)
        {
            form.resetFields()
        }
      })
    }
    else
    {
      dispatch(addCareerApplicant(payload));
    }
  };


  return (
    <>
      <Drawer
        title={
          <h1 style={{ fontSize: "20px", margin: 0 }}>{careers.applyJob}</h1>
        }
        width="768"
        // onClose={props.onClose}
        onClose={() => {
          dispatch(handleOpenApplyComposer(false));
          form.resetFields();
        }}
        open={applyComposer}
        className="detailedViewComposer drawerSecondary"
        placement={Direction === "rtl" ? "left" : "right"}
        style={{
          cursor: "pointer",
          textAlign: Direction === "ltr" ? "" : "end",
        }}
      >
        <Form
          form={form}
          name="createCareer"
          className={Direction === "rtl" ? "createCareerRight" : "createCareer"}
          // initialValues={initialState}
          onFinish={onFinish}
          layout="vertical"
          style={{ direction: Direction }}
        >
          <Form.Item
            label={careers.firstName}
            name="firstName"
            rules={[
              {
                required: true,
                message: careers.requiredMessageFName,
              },
            ]}
          >
            <Input
              size="large"
              placeholder={careers.enterFirstName}
              type="text"
            />
          </Form.Item>
          <Form.Item
            label={careers.lastName}
            name="LastName"
            rules={[
              {
                required: true,
                message: careers.requiredMessageLName,
              },
            ]}
          >
            <Input
              size="large"
              placeholder={careers.enterLastName}
              type="text"
            />
          </Form.Item>
          <Form.Item
            label={careers.email}
            name="email"
            rules={[
              {
                required: true,
                message: careers.requiredMessageEmail,
                type: "email",
              },
            ]}
          >
            <Input size="large" placeholder={careers.enterEmail} type="email" />
          </Form.Item>
          <Form.Item
            label={careers.phoneNumber}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: careers.requiredMessagePhoneNumbeer,
              },
            ]}
          >
            <Input
              size="large"
              placeholder={careers.enterPhoneNumber}
              type="number"
            />
          </Form.Item>
          {/* {isExternal && <> */}
          <div className="flex justify-between">
            <Form.Item
              label={careers.currentSalary}
              name="currentSalary"
              rules={[
                {
                  required: true,
                  message: careers.requiredMessageCurrentSalary,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Input
                size="large"
                placeholder={careers.currentSalary}
                type="number"
              />
            </Form.Item>
            <Form.Item
              label={careers.expectedSalary}
              name="expectedSalary"
              rules={[
                {
                  required: true,
                  message: careers.requiredMessageExpextedsalary,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Input
                size="large"
                placeholder={careers.expectedSalary}
                type="number"
              />
            </Form.Item>
          </div>
          <div className="flex justify-between">
            <Form.Item
              label={sharedLabels.Experience}
              name="experience"
              rules={[
                {
                  required: true,
                  message: careers.requiredMessageExperience,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Input
                size="large"
                placeholder={sharedLabels.Experience}
                type="number"
              />
            </Form.Item>
            <Form.Item
              label={careers.noticePeriod}
              name="noticePeriod"
              rules={[
                {
                  required: true,
                  message: careers.requiredMessageNoticePeriod,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Input
                size="large"
                placeholder={careers.noticePeriod}
                type="number"
              />
            </Form.Item>
          </div>
          {/* </>} */}
          {!isExternal && (
            <>
              {/* ... other form items ... */}
              <Form.Item label={sharedLabels.Status} name="status" initialValue="applicant">

                <Radio.Group>
                  <Radio value="applicant">{careers.radioMessageApplicant}</Radio>
                  <Radio value="shortlisted">{careers.radioMessageShortlisted}</Radio>
                  <Radio value="completed">{careers.radioMessageCompleted}</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}

          <Form.Item label={careers.coverNote} name="notes">
            <Input.TextArea size="large" placeholder={careers.coverNote} showCount maxLength={500} autoSize={{ minRows: 3, maxRows: 3 }}/>
          </Form.Item>
          <Form.Item   
            rules={[
                {
                  required: !resumeState?.length ? true : false,
                  message: "Resume Required",
                },
              ]} area="true" label={"Upload Resume"} name="resumeId">
            <SingleUpload
              handleImageUpload={(val) => {
                setResumeState(val);
              }}
              img="Add Image"
              position="flex-start"
              uploadText={sharedLabels.upload}
              accept={"application/pdf,application/msword,text/plain"}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="medium"
              className="ThemeBtn"
              block
              htmlType="submit"
              loading={careerLoader}
            >
              {careers.applyJob}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ApplyComposer;
