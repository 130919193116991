import React, { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { Avatar, Button, Divider, List, Skeleton, Spin  } from 'antd';
import '../Style/list.css'
import { useSelector, useDispatch } from "react-redux";
import { STRINGS } from '../../../../utils/base';
import { GetAllSuggestionAction } from '../store/action';
import {responseCode as Codes } from "../../../../services/enums/responseCode"
import { getNameForImage} from "../../../../utils/base";
import { setArrowDownFromInputFiled, setBacktoInputFieldFromList, setLengthOfCurrentSerach } from "../store/slice";
import CustomTag1 from "./CustomTag1";
import { SuggestionApiPayload } from "../Helper/SuggestionApiPayload";
import { SuggestionBoxType } from "../utils/SuggestionBoxType";
import { SuggestionTypeEnum } from "../utils/SuggestionTypeEnum";


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Roboto", sans-serif;
  }
`;

const PageDiv = styled.div`
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SelectBoxDiv = styled.div`
  display: flex;
  width: calc(100% - 40px);
  max-width: 500px;
  height: 30px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 6px;
  cursor: pointer;
`;

const popupIn = keyframes`
  from {
    transform: translate(0, 25px);
    opacity: 0;
  }
  to {
    transform: translate(0, 50px);
    opacity: 1;
  }
`;

const SelectBoxPopup = styled.div`
  position: absolute;
  display: flex;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  padding: 6px;
  border-radius: 8px;
  animation: ${popupIn} 0.2s;
  width: calc(100% - 40px);
  max-width: 500px;
  flex-direction: column;
  transform: translate(0, 50px);
  background-color: #fff;
  z-index:5;
  outline:none
`;

const SelectBoxCurrent = styled.div`
  font-size: 17px;
  margin-left: 10px;
`;

const SelectBoxArrow = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 7px;
  transition: 0.2s transform;
`;

const SelectBoxPopupItem = styled.span`
  padding: 2px;
  width: auto;
  border-radius: 5px;
  margin-bottom: 1px;
  margin-top: 1px;
  cursor: pointer;
  transition: 0.2s background-color;
  :hover {
    background-color: #5d70a1;;
    color: white;
  }
`;


 function CustomList(props) {

  const {
    SuggestionData,
    setSuggestionData, 
    debouncedSearch,
    setValue,
    selected,
    setSelected,
    isList,
    setisList,
    tagContainerHeight,
    setTagContainerHeight,
    tagContainerRef,
    isMultiSelect,
    removeSelf,
    referenceId,
    nonRemoveAbleids,
    componentType,
    suggestionType,
    callback,
    isFocus,
    setisFocus,
    nonRemoveableData,
    initialData,
    TopPosition,
    ArrowDownFromInputField,
    setArrowDownFromInputFiled,
    setLengthOfCurrentSerach,
    setBacktoInputField,
  } = props
  

  //UseRef
  const listInnerRef = useRef();
  const ref = useRef(null);

  //Dispatch
  const dispatch = useDispatch();

  //Local-State - useState
  const [lengthOfData , setLengthOfData] = useState(0)
  const [loading, setLoading] = useState(false);
  const [hovered , setHovered] = useState(null)
  const [listData , setListData ] = useState([])
  const [defaultPayload , setdefaultPayload] = useState({
          pageNo:1,
          pageSize:20,
          search:"",
          types: suggestionType,
          notRequired:nonRemoveAbleids,
          removeSelf:removeSelf,
          referenceId:referenceId,
        })
      
  //functions 
  const loadMoreData = (payload , isSearch = false) => {
    if (loading) {
      return;
    }
    setLoading(true);

    dispatch(GetAllSuggestionAction(payload))
    .then(
      response => {
          const {responseCode , data } = response?.payload;
          if(responseCode==Codes.Success)
          {
            setLengthOfData(data.length)
            setLengthOfCurrentSerach(data.length)
            if(!isSearch)
            {
              const existingIds = new Set(listData.map(item => item.id));
              const filteredData = data.filter(item => !existingIds.has(item.id));
              const updatedListData = [...listData, ...filteredData];
              setListData(updatedListData);
            }
            else
            {
              setListData(data)
            }
            setLoading(false);
          }
          else{
            setLoading(false);
          }
      }
    )
    .catch(()=>{
      setLoading(false);
    })
  };


 
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && lengthOfData>=20 ) {
        const nextPage = defaultPayload.pageNo + 1; // Increment the page number
        const updatedPayload = { ...defaultPayload, pageNo: nextPage }; // Update the payload with the new page number
        loadMoreData(updatedPayload);
      }
    }
  };

  const  handleItemClick = (item) => {


    let updatedSuggestionData = initialData
    if(isMultiSelect)
    {

      updatedSuggestionData.push(item);
    }
    else
    {
      // setSuggestionData([item]) 
      updatedSuggestionData = [item]
    }
    callback(updatedSuggestionData , item , "add")
    
    setValue("")
    setisList(false)
    setBacktoInputField(true)
    setisFocus(true)

  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      // Arrow up key
      event.preventDefault();
      setSelected((prevSelected) => (prevSelected > 0 ? prevSelected - 1 : prevSelected));
      scrollSelectedItemIntoView();
    } else if (event.keyCode === 40) {
      // Arrow down key
      event.preventDefault();
      setSelected((prevSelected) => (prevSelected < listData.length - 1 ? prevSelected + 1 : prevSelected));
      scrollSelectedItemIntoView();
    }
    else if (event.keyCode === 13) {
      // Enter key
      event.preventDefault(); // Prevent the default behavior of the Enter key
      event.stopPropagation(); // Stop the propagation of the event to parent elements
      if (selected !== -1) {
        handleItemClick(listData[selected]);
      }
    }
  };

  
  const scrollSelectedItemIntoView = () => {
    if (listInnerRef.current && selected !== -1) {
      const itemElement = listInnerRef.current.children[selected];
      if (itemElement) {
        itemElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth', // Add smooth scrolling behavior
          // inline: 'start' // Scroll the item to the start of the container
        });
      }
    }
  };

  function hasMoreDataFunction(){
    onScroll();
   }

const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

function numberFromText(text) {
  // numberFromText("AA");
  const charCodes = text
    .split('') // => ["A", "A"]
    .map(char => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return parseInt(charCodes, 10);
}

  
  useEffect(() => {
    if(ArrowDownFromInputField)
    {
      listInnerRef.current.focus();
      setSelected(0)
      setArrowDownFromInputFiled(false)
    }
  }, [ArrowDownFromInputField]);

  useEffect(() => {
    if (debouncedSearch && isList) {
      const updatedPayload = { ...defaultPayload, search: debouncedSearch };
      // setListData([]); // Clear the list data when debouncedSearch changes
      loadMoreData(updatedPayload , true);
    } 
    else {
      // setListData([]); // Clear the list data when debouncedSearch changes
      loadMoreData(defaultPayload);
    }

    setSelected(-1)

    // setSelected(1)
  }, [debouncedSearch]);

  const handleOutsideClick = (event) => {
    if (listInnerRef.current && !listInnerRef.current.contains(event.target)) {
      setisList(false);
    }
  };

  // Effect to add event listener when the list is opened
  useEffect(() => {
    if (isList) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isList]);
  

  
  return (
    <>
      
        {/* {loading ?  <Skeleton active /> :  */}
        <SelectBoxPopup 
            className="select-box-popup"
            onKeyDown={handleKeyDown} // Handle arrow up and down keys
            tabIndex={0} // Enable keyboard focus
            onScroll={hasMoreDataFunction}
            ref={listInnerRef}
            style={{ left: 1, top: TopPosition}}
        >
          {loading ?<Spin tip="Loading" size="large">
        {/* <div className="content" /> */}
      </Spin> :
            listData.length > 0 ?
            listData.map((elem, index) => {
              return (
                <SelectBoxPopupItem
                  className={`${selected === index  ? "selected SelectBoxPopupItem" : ""}`}
                  // onMouseEnter={() => setSelected(-1)}
                  // onMouseLeave={() => setHovered(null)}
                  key={elem.id}
                  onClick={() => {handleItemClick(elem)}}
                >
                    <CustomTag1 selectedUser={elem} selected={selected} index={index}/>
                </SelectBoxPopupItem>
              );
            }) : <h2>No Data</h2>
          }
       
        </SelectBoxPopup> 
    </>
  );
}


export default CustomList;