import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { setAuthEnv } from "../../../../utils/base";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums/";

import {
  addReward,
  cancelReward,
  getAllRewards,
  GetRewardById,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";

const initialState = {
  success: false,
  rewards: [],
  loadingData: false,
  loader: true,
  addRewardLoader: false,
  rewardDetail: {},
  drawerOpen: false,
  cancelReward: {},
  approvers: [],
  pagingReward: {},
  //new
  data: null,
  newItem: null,
  totalRecords: [],
  recordSize: 0,
  newCountFilterButton: false

};

const rewardSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {
    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(state.data?.[identifier])
    },
    //approval remarks
    handleRewardApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;

        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              console.log(finalApprovers, "Final Approvers = ");
              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: approverStatus.length > 1 ? 1 : approverStatus[0],
                approverStatus:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) { }
    },

    //add remarks approval
    handleAddRewardpprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [...referenceItem?.approvers];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    ////
    cancelRewardSuccess: (state, { payload }) => {
      let rewardList = [...state.rewards];
      let index = rewardList.findIndex(
        (item) => item.id === payload.rewardId.id
      );
      let reward = rewardList.filter(
        (item) => item.id === payload.rewardId.id
      )[0];

      rewardList[index] = {
        ...reward,
        status: ApprovalStatus.Cancelled,
      };

      state.rewards = rewardList;
      state.rewardDetail = {
        ...reward,
        status: ApprovalStatus.Cancelled,
      };
      console.log("xxxxxxx", payload.rewardId.id);
    },
    updateApprover: (state, action) => {
      const { stateapprovalDetail, index } = action.payload;
      if (stateapprovalDetail?.length > 0) {
        if (
          state.approvers[index][0]?.referenceId ===
          stateapprovalDetail[0].referenceId
        ) {
          state.approvers[index] = stateapprovalDetail;
        }
      }
    },
    readCountforReward: (state, { payload }) => {

      const id = payload
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === id)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id
        }
      }
      catch (e) {
        console.log("===error===", e)
      }
    },
    handleNotificationNewItem: (state, { payload }) => {

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === payload.featureId)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch (e) {
        console.log("===error===", e)
      }
    },
    handleRewardReminder : (state , {payload}) =>{
      const {referenceId , module , approvalId} = payload

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRewards.fulfilled, (state, action) => {
      const { segmentType, identifier, filter } = action?.meta?.arg;
      const data = action.payload ?? [];
      if (segmentType === SegmentTypeEnum.Grid) {
        state.data = {
          ...state.data,
          [identifier]: [...(state.data?.[identifier] || []), ...data],
        };
        state.recordSize = data.length;
      } else {
        state.data = {
          ...state.data,
          [identifier]: data.data,
        };
        state.totalRecords = action?.payload?.totalRecords;
      }
    });

    builder.addCase(GetRewardById.fulfilled, (state, action) => {
      const { identifier } = action?.meta?.arg;
      const data = action.payload.data ?? [];

      state.data = {
        ...state.data,
        [identifier]: [data],
      };
    });

    builder.addCase(addReward.fulfilled, (state, { payload }) => {
      state.newItem = payload.data.data;
    });
  },
});

export const {
  cancelRewardSuccess,
  updateApprover,
  handleResetState,
  removeIdentifier,
  handleNewItem,
  handleRewardApprovalRemark,
  handleAddRewardpprover,
  handleNotificationNewItem,
  readCountforReward,
  handleRewardReminder
} = rewardSlice.actions;
export default rewardSlice.reducer;
