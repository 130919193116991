import React, { useState, useEffect } from 'react';
import './style.css';

export default function TimerCountDown({ endDate }) {
  const endDateTime = new Date(endDate);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = endDateTime - now;

    if (difference <= 0) {
      return {
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const months = Math.floor(difference / (30 * 24 * 60 * 60 * 1000));
    const weeks = Math.floor((difference % (30 * 24 * 60 * 60 * 1000)) / (7 * 24 * 60 * 60 * 1000));
    const days = Math.floor((difference % (7 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));
    const hours = Math.floor((difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);

    return {
      months,
      weeks,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="cardSections">
      <div className="cardSectionItem">
        <div className="cardSection__title TimerFontSize">{"Months"}</div>
        <div className="cardSection__body layout TimerFontSize">{timeLeft.months}</div>
      </div>
      <div className="cardSectionItem">
        <div className="cardSection__title TimerFontSize">{"Weeks"}</div>
        <div className="cardSection__body TimerFontSize">{timeLeft.weeks}</div>
      </div>
      <div className="cardSectionItem">
        <div className="cardSection__title TimerFontSize">{"Days"}</div>
        <div className="cardSection__body TimerFontSize">{timeLeft.days}</div>
      </div>
      <div className="cardSectionItem">
        <div className="cardSection__title TimerFontSize">{"Hours"}</div>
        <div className="cardSection__body TimerFontSize">{timeLeft.hours}</div>
      </div>
      <div className="cardSectionItem">
        <div className="cardSection__title TimerFontSize">{"Minutes"}</div>
        <div className="cardSection__body TimerFontSize">{timeLeft.minutes}</div>
      </div>
      <div className="cardSectionItem">
        <div className="cardSection__title TimerFontSize">{"Seconds"}</div>
        <div className="cardSection__body TimerFontSize">{timeLeft.seconds}</div>
      </div>
    </div>
  );
}
