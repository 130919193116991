import { useEffect, useContext, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  ItemHeader,
  SingleItem,
} from "../../../sharedComponents/Card/CardStyle";
import SublineDesigWithTime from "../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import UserInfo from "../../../sharedComponents/UserShortInfo/UserInfo";
import { getRequestListItemsById } from "../store/action";
import RemarksApproval from "../../../sharedComponents/AppComponents/Approvals/view";
import { ApprovalsModule } from "../../../sharedComponents/AppComponents/Approvals/enums";
import { Tag, Skeleton } from "antd";
import Avatar from "../../../sharedComponents/Avatar/avatar";
import StatusTag from "../../../sharedComponents/Tag/StatusTag";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import ItemDetailModal from "../../../sharedComponents/ItemDetails";
import { dictionaryList } from "../../../../utils/localization/languages";

const RequestDetailCard = (props) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, requestListItems } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const { requestItemDetail, loadingData } = useSelector((state) => state.requestItemSlice);
  const [openModal, setOpenModal] = useState(false);

  const [approvalDetail, setApprovalDetail] = useState(
    requestItemDetail?.approvers
  );
  console.log(requestItemDetail, "requestItemDetail");
  const [assetController, setAssetControler] = useState(requestItemDetail?.assetController)



  useEffect(() => {
    setApprovalDetail(requestItemDetail?.approvers);
    setAssetControler(requestItemDetail?.assetController);

  }, [requestItemDetail]);

  useEffect(() => {
    if (props.id) dispatch(getRequestListItemsById(props.id));
  }, [props.id]);

  const handleModalOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenModal(true);
  };

  if (loadingData) return <Skeleton />;

  return (
    <>
      {
        <ItemDetailModal
          data={requestItemDetail.assetController} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={openModal}
          setVisible={(da) => setOpenModal(da)}
        />
      }
      <SingleItem onClick={props.onClick}>
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={
                requestItemDetail.creator?.image
                  ? requestItemDetail.creator?.image
                  : "https://58.65.211.234:4436/Resources/cfe50d8d-7c47-4abb-9154-661daf129cec/Images/45f43115-c12f-4fc4-82ec-e570fbc13a70.jpeg"
              }
              name={requestItemDetail.creator?.name}
              Subline={
                <SublineDesigWithTime
                  designation={
                    requestItemDetail.creator?.designation
                      ? requestItemDetail.creator?.designation
                      : ""
                  }
                  time={moment
                    .utc(requestItemDetail.createDate)
                    .local()
                    .fromNow()}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{requestItemDetail.referenceNo}</Tag>
            <StatusTag status={requestItemDetail.status}></StatusTag>
          </div>
        </ItemHeader>
        <div className="cardSections" style={{ marginTop: "20px" }}>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.CategoryName}
            </div>
            <div className="cardSection__body">
              {requestItemDetail.category}
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {" "}
              {sharedLabels.Quantity}
            </div>
            <div className="cardSection__body">
              {requestItemDetail.quantity}
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {" "}
              {sharedLabels.Approver}
            </div>
            <div className="approvers" onClick={(e) => handleModalOpen(e)}>
              <div className="cardSection__body">
                <Avatar
                  isAvatarGroup={true}
                  heading={"approvers"}
                  membersData={approvalDetail ? approvalDetail : []}
                />
              </div>
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {" "}
              {requestListItems.AssetController}
            </div>
            <div className="cardSection__body">
              <Avatar
                isAvatarGroup={true}
                heading={sharedLabels.Approver}
                membersData={
                  requestItemDetail.assetController
                    ? requestItemDetail.assetController
                    : []
                }
              />
            </div>
          </div>
        </div>
        <RemarksApproval
          reference={props.id}
          setApprovalDetail={setApprovalDetail}
          data={approvalDetail && approvalDetail}
          title={sharedLabels.Approver}
          module={ApprovalsModule.RequestForItemApproval}
          onStatusChanged={() => { }}
          itemCreator={requestItemDetail.creator}
        />
        {/* <RemarksApproval
          reference={props.id}
          setOtherApproval={setAssetControler}
          data={assetController ?? []}
          title={requestListDictionary.AssetsController}
          module={ApprovalsModule.RequestForItemApproval}
          onStatusChanged={() => {}}
        /> */}

        <RemarksApproval
          module={ApprovalsModule.RequestForItemApproval}
          reference={props.id}

          onStatusChanged={() => { }}

          setOtherApproval={(data) => {
            setAssetControler(data);
          }}
          data={assetController ?? []}
          title={requestListItems.AssetController}
          itemCreator={requestItemDetail.creator}
        />
      </SingleItem>
    </>
  );
};

export default RequestDetailCard;
