import MasterConfig from "../../../../utils/services/MasterConfig";
const API_PREFIX = "/api/Group/";

export const getAllGroupService = (data) => {
  return MasterConfig.post(`${API_PREFIX}GetAllGroup`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addGroupService = (data) => {
  return MasterConfig.post(`${API_PREFIX}AddGroup`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getGroupByIdService = (id) => {
  return MasterConfig.get(`${API_PREFIX}GetGroupById?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const updateGroupService = (data) => {
  return MasterConfig.put(`${API_PREFIX}UpdateGroup`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addGroupMemberService = (data) => {
  let id = data.id;
  let memberId = data.memberId;
  let memberType = data?.memberType || 1
  let member = [
    {
      memberId: memberId,
      memberType: memberType,
    },
  ];
  return MasterConfig.post(`api/Group/AddGroupMember?id=${id}`,  Array.isArray(data?.memberId) ?data.memberId : member)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllGroupMemberService = (id) => {
  return MasterConfig.get(`api/Group/GetAllGroupMember?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteGroupMemberService = (data) => {
  const id = data.id;
  const memberId = data.memberId;
  return MasterConfig.post(`api/Group/RemoveGroupMember?id=${id}`, [memberId])
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addGroupFavoriteMarkService = (payload) => {
  return MasterConfig.get(
    `api/Group/AddGroupFavouriteMark?groupId=${payload.id}&isPinned=${payload.isPinned}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addGroupFeaturesService = (data) => {
  console.log(data, "data");
  // const id = data.find((feature) => feature.groupId)?.groupId;
  const id = data.id;

  return MasterConfig.post(
    `api/Group/AddGroupFeatures?groupId=${id}`,
    data.payload
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeGroupFeaturesService = ({ id, featureId }) => {
  return MasterConfig.delete(
    `api/Group/RemoveGroupFeature?id=${id}&featureId=${featureId}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getGroupFeaturesService = (id) => {
  return MasterConfig.get(`api/Group/GetAllGroupFeature?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addGroupTagService = (args) => {
  const { description, name, referenceId } = args;
  let payload = {
    referenceId,
    name,
    description,
  };

  return MasterConfig.post(`api/Group/AddGroupTag`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllGroupTagService = (data) => {
  //console.log(data, "getAllGroupTagService");
  return MasterConfig.post(`api/Group/GetAllGroupTag`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const updateGroupTagService = (args) => {
  return MasterConfig.put(`api/Group/UpdateGroupTag`, args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeGroupTagService = (id) => {
  return MasterConfig.delete(`api/Group/RemoveGroupTag?id=${id}`)
    .then((res) => {
      console.log(res, "responseeeeee");
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addGroupTagMemberService = (data) => {
  console.log(data, "dataaservucesss");
  let id = data.id;
  let memberId = data.memberId;
  let referenceId = data.referenceId;
  let member = [
    {
      referenceId: referenceId,
      memberId: memberId,
    },
  ];
  return MasterConfig.post(`api/Group/AddGroupTagMember?id=${id}`, member)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeGroupTagMemberService = (data) => {
  let id = data.id;
  let memberId = data.memberId;
  return MasterConfig.post(`api/Group/RemoveGroupTagMember?id=${id}`, [
    memberId,
  ])
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

/*ACCESS ROLE*/

export const addGroupAccessRoleService = (data) => {
  return MasterConfig.post(`${API_PREFIX}AddGroupAccessRole`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllGroupAccessRolesService = (data) => {
  return MasterConfig.get(
    `${API_PREFIX}GetAllGroupAccessRole?referenceId=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateGroupAccessRoleByIdService = (data) => {
  return MasterConfig.put(`${API_PREFIX}UpdateGroupAccessRole`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getGroupAccessRoleByIdService = (data) => {
  return MasterConfig.get(`${API_PREFIX}GetGroupAccessRoleById?id=${data}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};


export const AssignGroupTagToMemberService = (data) => {

  return MasterConfig.post(`${API_PREFIX}AssignGroupTagToMember?groupId=${data.groupId}&memberId=${data.memberId}` ,data.tags)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getAllGroupPagingService = (data) => {
  return MasterConfig.post(`${API_PREFIX}GetAllGroupPaging` ,data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};