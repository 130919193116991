import React, { useContext } from "react";

import { feedSlice } from "../../../store/slice";
import store from "../../../../../../store/store";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import TagSearchable from "../../../../../sharedComponents/UserSearchable/TagSearchable";
import { dictionaryList } from "../../../../../../utils/localization/languages";

const PostTagField = ({referenceType , referenceId}) => {
	const { userLanguage } = useContext(LanguageChangeContext);
	const { newsFeed, sharedLabels  } = dictionaryList[userLanguage];

	return (
		<div className="select-users">
			<div className="badge">{sharedLabels.With}</div>
			<TagSearchable
				referenceType={referenceType}
				referenceId={referenceId}
				placeholder={newsFeed.WriteName}
				name={"tagUsers"}
				className="c-multi-select "
				onChange={e => {
					store.dispatch(feedSlice.actions.onPostTagsChange(e));
				}}
			/>
		</div>
	);
};

export default PostTagField;
