import { Checkbox } from "antd";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { useContext } from "react";

const CreateEntryTableHead = () => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { payroll, sharedLabels, voucher } = dictionaryList[userLanguage];
    return (<>
        <thead>
            <tr className="whitespace-nowrap">
                {/* <th >
                    <Checkbox
                   
                    />
                </th> */}
                <th >{sharedLabels.name}</th>
                <th >{sharedLabels.amount}</th>
                <th >{payroll.TransactionMode}</th>
                <th>{payroll.IBANNumber}</th>
                <th>{voucher.ChequeNo}</th>
            </tr>
        </thead>
    </>)
}
export default CreateEntryTableHead;