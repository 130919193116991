import React, { useContext, useEffect, useState } from "react";
import { Switch, message } from "antd";
import "../../../style/style.css";
import { DiscussionBoardStatusEnums } from "../../../utils/enum";
import CommentWrapper from "../../../../../sharedComponents/Comment/CommentWrapper";
import { updateDiscussionBoardStatus } from "../../../store/action";
import { useDispatch } from "react-redux";
import Comments from "../../../../Comment/comments";
import { CommentModuleEnum } from "../../../../Comment/utils/enum/commentModuleEnum";
import ExpandableDescription from "../../UI/expandableDescription";
import NotificationBadge from "../../../../../sharedComponents/Badge/NotificationBadge";
import { NotificationFeatureTypeEnum } from "../../../../notifiation/enums";
import { readNotificationAction } from "../../../../notifiation/store/action";
import { readCountforDiscussionBoard } from "../../../store/slice";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { useSelector } from "react-redux";

export default function DiscussionBoardDetail({ item }) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  const {user} = useSelector((state) => state.userSlice)
  const [isCreator , setIsCreator] = useState(false)

  const { id, status, description, notificationCount } = item;
  const [currentStatus, setCurrentState] = useState(mapStatusToBoolean(status))
  const dispatch = useDispatch();
  const onChange = (value) => {
    setCurrentState(value)
    const newstatus = value
      ? DiscussionBoardStatusEnums.Open
      : DiscussionBoardStatusEnums.Close;
    let data = {
      id: id,
      status: newstatus,
    };
    dispatch(updateDiscussionBoardStatus(data));
    console.log(`switch to ${value}`);
  };
  function mapStatusToBoolean(status) {
    return status === 1 ? true : false;
  }
  const switchValue = status !== null ? mapStatusToBoolean(status) : false;


  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.DiscussionBoard,
          featureId: id
        }
      }
      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforDiscussionBoard(id))
      })
    }
  }, [])

  useEffect(()=>{
    item?.createBy === user?.id && setIsCreator(true)
    setCurrentState(mapStatusToBoolean(status))
  },[item])

  return (
    <div className="approvalDetail">
      <div className="topHeader">
        <div className="policyHeader flex justify-between">
          <div className="names">{item?.name}</div>
          {item?.name && (
            <div className="end">
              <Switch
                checkedChildren={sharedLabels.Open}
                unCheckedChildren={sharedLabels.Closed}
                onChange={(value)=>{isCreator ? onChange(value) : message.error("Only creator can modify the status")}}
                defaultChecked={switchValue}
                checked={currentStatus}
              />
            </div>
          )}
        </div>
      </div>

      {item?.name && (
        <div className="policycard">
          <ExpandableDescription description={description} />
          <Comments
            isOpenComposer={currentStatus}
            referenceId={id}
            module={CommentModuleEnum.DiscussionBoard}
            referenceType={item.referenceType}
            mentionMemberRefId = {item.referenceId}
          />
        </div>
      )}
    </div>
  );
}
