import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllFinanceAccountService } from "../services/service";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";

export const getAllFinanceAccount = createAsyncThunk(
    "employees/getAllFinanceAccount",
    async (data, { dispatch, getState, rejectWithValue }) => {
        const res = await getAllFinanceAccountService(data);
        if (res.responseCode === responseCode.Success) {
            return res.data;
        } else {
            message.error(res.data.message);
            return rejectWithValue(res.data.message);
        }
    }
);