import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { GetAuctionOfferByIdService, addAuctionMemberService, addAuctionOfferService, addAuctionService, addAuctionSubmitBidService, getAllAuctionMemberService, getAllAuctionOfferService, getAllAuctionPagingService, getAllAuctionService, getAuctionBuyNowService, getAuctionByIdService, removeAuctionMemberService } from "../service/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { APIRESPONSETOASTDURATION } from "../../../../utils/base";
import { openNotification } from "../../../sharedComponents/Notification/notificationHelper";

export const GetAllAuctionAction = createAsyncThunk(
    "Auction/GetAllAuctionAction",
    async (
      { filter, pageSize, segmentType = SegmentTypeEnum.Grid, identifier },
      { dispatch, getState, rejectWithValue }
    ) => {
      if (segmentType === SegmentTypeEnum.List) {
        const res = await getAllAuctionPagingService({...filter , pageSize});
        if (res.responseCode === responseCode.Success) {
            return res.data;
          } else {
            dispatch(
              openNotification({
                message: res.message,
                type: "error",
                duration: APIRESPONSETOASTDURATION,
              })
            );
            return rejectWithValue(res.message);
          }
      } else {
        const res = await getAllAuctionService(filter);
        if (res.responseCode === responseCode.Success) {
            return res.data;
          } else {
            dispatch(
              openNotification({
                message: res.message,
                type: "error",
                duration: APIRESPONSETOASTDURATION,
              })
            );
            return rejectWithValue(res.message);
          }
      }
    }
);

export const getAuctionByIdAction = createAsyncThunk(
    "Auction/getAuctionByIdAction",
    async ({id , identifier}, { dispatch, getState, rejectWithValue }) => {
      const res = await getAuctionByIdService(id);
      if (res.responseCode === responseCode.Success) {
        return res;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );
  export const addAuctionAction = createAsyncThunk(
    "Auction/addAuctionAction",
    async (data, { dispatch, getState, rejectWithValue }) => {
      const res = await addAuctionService(data);
      console.log(res,"====response=Add====")
      if (res.responseCode === responseCode.Success) {
        message.success( "Auction Created Successfully");
        // dispatch(
        //   openNotification({
        //     message: "Auction Created Successfully",
        //     type: "success",
        //     duration:  APIRESPONSETOASTDURATION,
        //   })
        // );
        return res;
      } else {
        dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration:  APIRESPONSETOASTDURATION,
          })
        );
        return rejectWithValue(res.message);
      }
    }
  );


  export const getAuctionOfferByIdAction = createAsyncThunk(
    "Auction/getAuctionOfferByIdAction",
    async ({id , identifier}, { dispatch, getState, rejectWithValue }) => {
      const res = await GetAuctionOfferByIdService(id);
      if (res.responseCode === responseCode.Success) {
        return res;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const getAllAuctionOfferAction = createAsyncThunk(
    "Auction/getAllAuctionOfferAction",
    async ({filter , identifier}, { dispatch, getState, rejectWithValue }) => {
      const res = await getAllAuctionOfferService(filter);
      if (res.responseCode === responseCode.Success) {
        return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const addAuctionSubmitBidAction = createAsyncThunk(
    "Auction/addAuctionSubmitBidAction",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const res = await addAuctionSubmitBidService(payload);
      if (res.responseCode === responseCode.Success) {
        dispatch(
          openNotification({
            message: "Bid Submitted Successfully!",
            type: 'success',
            duration: APIRESPONSETOASTDURATION,
          })
        );
        // return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const getAuctionBuyNowAction = createAsyncThunk(
    "Auction/getAuctionBuyNowAction",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const res = await getAuctionBuyNowService(payload);
      if (res.responseCode === responseCode.Success) {
        dispatch(
          openNotification({
            message: "Buy Now Request Sent!",
            type: 'success',
            duration: APIRESPONSETOASTDURATION,
          })
        );
        return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const addAuctionOfferAction = createAsyncThunk(
    "Auction/addAuctionOfferAction",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const res = await addAuctionOfferService(payload);
      if (res.responseCode === responseCode.Success) {
        message.success("Bid Submitted Successfully!");
        return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const addAuctionMemberAction = createAsyncThunk(
    "Auction/addAuctionMember",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const res = await addAuctionMemberService(payload);
      if (res.responseCode === responseCode.Success) {
        return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const getAllAuctionMemberAction = createAsyncThunk(
    "Auction/getAllAuctionMember",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const res = await getAllAuctionMemberService(payload);
      if (res.responseCode === responseCode.Success) {
        return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );

  export const removeAuctionMemberAction = createAsyncThunk(
    "Auction/removeAuctionMember",
    async (payload, { dispatch, getState, rejectWithValue }) => {
      const res = await removeAuctionMemberService(payload);
      if (res.responseCode === responseCode.Success) {
        return res.data;
      } else {
        dispatch(
            openNotification({
              message: res.message,
              type: "error",
              duration: APIRESPONSETOASTDURATION,
            })
          );
        return rejectWithValue(res.message);
      }
    }
  );