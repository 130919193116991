import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import {
  addOfficeTimingGroup,
  getAllOfficeTimingGroups,
  updateOfficeTimingGroupAction,
  getOfficeTimingByIdAction,
} from "./actions.js";

const initialState = {
  officeTimingGroups: [],
  loadingData: false,
  loader: false,
  drawerOpen: false,
  success: false,
  error: false,
  editData: null,
  officeTimingDetail: null,
};

const officeTimingSlice = createSlice({
  name: "officeTimingGroup",
  initialState,
  reducers: {
    handleComposer: (state, { payload }) => {
      state.editData = payload;
    },
    handleUpdateComposer: (state, { payload }) => {
      state.officeTimingDetail = payload;
    },
    officeTimingGroupDeleted: (state, { payload }) => {
      state.officeTimingGroups = state.officeTimingGroups.filter(
        (e) => e.id !== payload.id
      );
    },
    removeOfficeTimingData: (state, { payload }) => {
      state.officeTimingDetail = null;

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOfficeTimingGroups.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.officeTimingGroups = payload.data;
      })
      .addCase(addOfficeTimingGroup.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.success = true;
        state.drawerOpen = false;
        if (payload.responseCode === responseCode.Success)
          state.officeTimingGroups.push(payload.data);
      })
      // .addCase(
      //   updateOfficeTimingGroupAction.fulfilled,
      //   (state, { payload }) => {
      //     let index = state.officeTimingGroups.findIndex((x) => x.id === payload.data.id)
      //     console.log(index, "dFdADFa");

      //     state.items = state.items.map((x) =>
      //       x.id === payload.data.id ? payload.data : x
      //     );
      //     state.officeTimingDetail = null;

      //     state.success = true;
      //   }
      // )
      .addCase(
        updateOfficeTimingGroupAction.fulfilled,
        (state, { payload }) => {
          const index = state.officeTimingGroups.findIndex((x) => x.id === payload.data.id);

          if (index !== -1) {
            state.officeTimingGroups[index] = payload.data;
            state.officeTimingDetail = null;
            state.success = true;
          }
        }
      )

      .addCase(getOfficeTimingByIdAction.fulfilled, (state, { payload }) => {
        state.officeTimingDetail = payload.data;
        console.log(payload.data, "officetiminggg");
        state.loader = false;
      })

      .addMatcher(isPending(...[addOfficeTimingGroup]), (state) => {
        state.loader = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(isPending(...[getAllOfficeTimingGroups]), (state) => {
        state.loadingData = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(
        isRejected(...[getAllOfficeTimingGroups, addOfficeTimingGroup]),
        (state) => {
          state.loader = false;
          state.loadingData = false;
          state.success = false;
          state.error = false;
        }
      );
  },
});

export const {
  officeTimingGroupDeleted,
  handleComposer,
  removeOfficeTimingData,
  handleUpdateComposer
} = officeTimingSlice.actions;
export default officeTimingSlice.reducer;
