export const CustomApprovalStatusEum = {
    InProcess : 1,
    Approved : 2,
    Declined : 3,
    Resend : 4,
    InActive : 5,
    NotRequired : 6,
    Cancelled : 7,
    Hold : 8,

}