import { Slider, Progress } from "antd";
import React, { useEffect, useState } from "react";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import { updateUserTaskMemberProgressService } from "../../services/service";
import useDebounce from "../../../../../utils/Shared/helper/use-debounce";
import { useSelector } from "react-redux";
import { TaskStatusEnum } from "../../utils/TaskStatusEnum";

export default function TaskMembers({
  members,
  status,
  isRating,
  changeOnProgress,
  hasSubTasks = 0,
}) {
  const [localProgress, setLocalProgress] = useState(
    members.reduce((acc, member) => {
      acc[member.memberId] = member.progress;
      return acc;
    }, {})
  );
  const { user } = useSelector((state) => state.userSlice);
  let userId = user?.id;

  const [isExpanded, setIsExpanded] = useState(false);
  const sortedMembers = [...members].sort((a, b) =>
    a?.memberId === userId ? -1 : b?.memberId === userId ? 1 : 0
  );
  const membersToDisplay = isExpanded
    ? sortedMembers
    : sortedMembers.slice(0, 2);

  const debouncedProgresses = useDebounce(localProgress, 300);

  const handleProgressSlider = async (progress, member) => {
    const { taskId, memberId, memberType } = member;
    const request = {
      progress,
      taskId,
      memberId,
      memberType,
    };
    const { data } = await updateUserTaskMemberProgressService(request);
    changeOnProgress(data);
  };

  useEffect(() => {
    members.forEach((member) => {
      if (debouncedProgresses[member.memberId] !== member.progress) {
        handleProgressSlider(debouncedProgresses[member.memberId], member);
      }
    });
  }, [debouncedProgresses]);

  return (
    <div className="taskMembers">
      <div className="inline-block ">
        {membersToDisplay.map((item) => {
          return (
            <div key={item.memberId} className="flex items-center mt-5">
              <div className="w-40 mr-3">
                <UserInfo
                  avatarSrc={item?.member?.image}
                  name={item?.member?.name}
                  nameStyle={{ fontSize: "12px" }}
                  avatarSize={36}
                  Subline={
                    <SublineDesigWithTime
                      designation={
                        item?.member?.designation
                          ? item?.member?.designation
                          : "Not Designated"
                      }
                      desgStyle={{ fontSize: "6px !import" }}
                    />
                  }
                />
              </div>
              <div className=" w-40  ">
                {userId === item?.memberId ? (
                  <Slider
                    disabled={
                      status === TaskStatusEnum.Cancel ||
                      isRating ||
                      localProgress[item.memberId] === 100 ||
                      userId !== item.memberId || hasSubTasks
                    }
                    value={localProgress[item.memberId]}
                    trackStyle={{ backgroundColor: "var(--currentThemeColor)" }}
                    handleStyle={{
                      borderColor: "var(--currentThemeColor)",
                      backgroundColor: "var(--currentThemeColor)",
                    }}
                    onChange={(progress) => {
                      setLocalProgress((prev) => ({
                        ...prev,
                        [item.memberId]: progress,
                      }));
                    }}
                  />
                ) : (
                  <Progress
                    size="small"
                    strokeColor="#526bb1"
                    percent={localProgress[item.memberId]}
                  />
                )}
              </div>
            </div>
          );
        })}
        {members.length > 2 && (
          <div className="mt-2 flex items-center justify-center">
            <button
              className="expandBtn"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
