import React, { useContext, useEffect, useState } from "react";
import { Tag, Skeleton, Alert, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import {
  ItemContent,
  ItemHeader,
} from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { GetCustomApprovalById } from "../../store/actions";
import {
  ApprovalsModule,
} from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { readCountforCustomApproval, removeIdentifier } from "../../store/slice";
import Attachments from "../../../travel/view/UI/Attachments";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import customApprovalIcon from "../../../../../content/svg/menu/newNavBarIcon/Custom Approval.svg";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import ExpandableDescription from "../../../../sharedComponents/expandableDescription/expandableDescription";
import {formatNumberWithCommas} from "../../../../../utils/Shared/helper/formatNumberWithCommas"

export default function CustomApprovalDetail({ id, index }) {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];

  const { user } = useSelector((state) => state.userSlice);
  const [isExpanded, setIsExpanded] = useState(false);
  const [state, setState] = useState({
    identifier: moment().unix(),
    customApprovalDetail: {
      creator: {},
      description: "",
      image: customApprovalIcon,
      approvers: [],
      status: 1,
      referenceNo: 0,
      subject: "",
      category: "",
      value: "",
      createDate: "",
      attachments: "",
      isLoading: true,
      notificationCount: 0,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });
  const { data } = useSelector((state) => state.customApprovalSlice);
  let customApprovalDetail = data !== null ? data[state.identifier] : [];
  customApprovalDetail =
    customApprovalDetail &&
    customApprovalDetail?.length > 0 &&
    customApprovalDetail[0];



  useEffect(() => {
    id &&
      dispatch(GetCustomApprovalById({ id: id, identifier: state.identifier }));
  }, [id]);

  useEffect(() => {
    customApprovalDetail &&
      setState({
        ...state,
        customApprovalDetail: {
          ...state.customApprovalDetail,
          ...customApprovalDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: customApprovalDetail?.approvers,
        },
      });
  }, [customApprovalDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  //close Approvers Modal
  const onCloseApproversModal = (status) => {
    setState({
      ...state,
      approvers: {
        ...state.approvers,
        isOpen: status,
      },
    });
  };

  const {
    creator,
    description,
    image = customApprovalIcon,
    approvers = [],
    status,
    referenceNo,
    subject,
    category,
    value,
    createDate,
    attachments,
    notificationCount
  } = state.customApprovalDetail;


  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.CustomApproval,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforCustomApproval(id))
      })
    }
  }, [notificationCount])

  if (state.customApprovalDetail.isLoading) return <Skeleton />;


  return (
    <>
      {/*Approvers Modal  */}
      {
        <ItemDetailModal
          data={state.approvers.data} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={state.approvers.isOpen}
          setVisible={(status) => onCloseApproversModal(status)}
        />
      }
      {/* Detail Card */}
      

      <div className="detailedCard">
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              profileId={creator?.id}
              status={creator?.userActiveStatus}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator?.designation : ""}
                  time={moment
                    .utc(createDate)
                    .local()
                    .fromNow()}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
          </div>
        </ItemHeader>
        <ItemContent className="flex gap-5">
          <div className="description">
            <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
          </div>
          <div className="!w-max m-4 ml-auto attachmentBox">
            <Attachments
              data={attachments}
              key={{ data: attachments }}
              toShow={1}
              onClick={() => { }}
              size={"60px"}
            />
          </div>
        </ItemContent>
        <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.subject}
            </div>
            <div className="cardSection__body layout">{subject}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.category}
            </div>
            <div className="cardSection__body">{category}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.amount}
            </div>
            <div className="cardSection__body">{formatNumberWithCommas(value)}</div>
          </div>
        </div>
        {/* Remarks Approval */}
        <RemarksApproval
          module={ApprovalsModule.CustomApproval}
          status={status}
          reference={customApprovalDetail?.id}
          onStatusChanged={(prev) => { }}
          setApprovalDetail={(data) => { }}
          data={state.approvers.data && state.approvers.data}
          title={sharedLabels.approvers}
          itemCreator={creator}
        />
      </div>
    </>
  );
}
