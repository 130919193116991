import React from "react";
import { Tag } from "antd";
import { ScheduleReferenceTypeEnum, getKeyByValue } from "../enum/enum";
import { STRINGS, getRouteByType } from "../../../../utils/base";
import { ScheduleReferenceTypeColor } from "../enum/schedulteReferenceTypeColor";
import { ReferenceTypeEnum } from "../../../sharedComponents/reference/utils/referenceTypeEnum";
import schedulesIcon from "../../../../content/svg/menu/newNavBarIcon/Schedules.svg";
import projectIcon from "../../../../content/svg/menu/newNavBarIcon/Projects.svg";
import groupIcon from "../../../../content/svg/menu/newNavBarIcon/Groups.svg";
import leadIcon from "../../../../content/svg/menu/newNavBarIcon/Lead Manager.svg";

const ReferenceTypeTag = ({
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = ScheduleReferenceTypeEnum.General,
  reference = [],
}) => {
  let color = ScheduleReferenceTypeColor?.[referenceType];
  let value =
    reference && reference !== null && reference?.name
      ? reference?.name
      : getKeyByValue(referenceType);
  let icon = schedulesIcon;

  switch (referenceType) {
    case ScheduleReferenceTypeEnum.Project:
      icon = projectIcon;
      break;
    case ScheduleReferenceTypeEnum.Group:
      icon = groupIcon;
      break;
    case ScheduleReferenceTypeEnum.Lead:
      icon = leadIcon;
      break;
    default:
      icon = schedulesIcon;
      break;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      referenceId &&
      referenceId !== STRINGS.DEFAULTS.guid &&
      referenceType !== ScheduleReferenceTypeEnum.General
    ) {
      let refType;
      switch (referenceType) {
        case ScheduleReferenceTypeEnum.Project:
          refType = ReferenceTypeEnum.Project;
          break;
        case ScheduleReferenceTypeEnum.Group:
          refType = ReferenceTypeEnum.Group;
          break;
        case ScheduleReferenceTypeEnum.Lead:
          refType = ReferenceTypeEnum.Lead;
          break;
        default:
          break;
      }

      let url = refType && getRouteByType(referenceId, refType);
      if (url) {
        window.location.href = url;
      }
    }
  };

  return (
    <>
      <Tag
        icon={
          <img src={icon} className="h-[20px] w-[20px] mr-2" alt="icon" />
        }
        color={color}
        onClick={handleClick}
        className="scheduleReferenceTypeTag"
      >
        {value}
      </Tag>
    </>
  );
};

export default ReferenceTypeTag;
