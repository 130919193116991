import { useContext, useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { groupByKey } from "../../../../../utils/base";
import { DOMAIN_PREFIX } from "../../../../../utils/routes";
import NavMenuList from "../../navbarMenuList";
import ReactDragListView from "react-drag-listview";
import { Collapse } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import {
  CaretRightOutlined,
  MenuUnfoldOutlined,
  UsergroupAddOutlined,
  FileDoneOutlined,
  GlobalOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import {
  FeaturePermissionEnumList,
} from "../../../../../utils/Shared/enums/featuresEnums";
import NotificationBadge from "../../../Badge/NotificationBadge";
import { useDispatch } from "react-redux";
import { getAllNotificationCount } from "../../../../features/notifiation/store/action";
import { userTypeEnum } from "../../../../../utils/Shared/enums/enums";
import { useDebounceNavigation } from "../../../../../utils/Shared/helper/useDebounceNavigation";
const { Panel } = Collapse;

export default function Menu({ cancelRequests }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { Direction, navMenuLabel } = dictionaryList[userLanguage];
  const { notificationFeatureTypes } = useSelector((state) => state.notificationSliceNew);
  console.log(notificationFeatureTypes, "notificationCounts")
  const debouncedNavigate = useDebounceNavigation(500);

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  let { menuItems } = NavMenuList(notificationFeatureTypes);

  useEffect(() => {
    dispatch(getAllNotificationCount());
  }, []);

  // console.log("menuItems", menuItems);
  const { navBarStatus } = useSelector((state) => state.responsiveSlice);
  const { user } = useSelector((state) => state.userSlice);

  const groupedMenuItems = groupByKey(
    [...menuItems.filter((x) => getUserPermissions().includes(x.featureId))],
    "key"
  );
  const [data, setData] = useState(groupedMenuItems);
  let currentCategory = "";
  function getUserPermissions() {
    if(user && user?.permissions?.length > 0 )
    {
      return FeaturePermissionEnumList.map((x) => {
        if (user?.permissions.includes(x?.id)) {
            return x.featureId;
        }
        else
        {
          return undefined
        }
      }).filter(permission => permission!==undefined);
    }
    return []
  }

  useEffect(() => {
    setData(groupedMenuItems);
  }, [Direction, notificationFeatureTypes, navMenuLabel]);

  const activeTab = (isActive, path) => {
    return isActive
      ? "on"
      : DOMAIN_PREFIX.length > 0
      ? pathname.split("/").includes(path.split("/")[2])
        ? "on"
        : ""
      : pathname.split("/").includes(path.split("/")[1])
      ? "on"
      : "";
  };

  const renderIcons = {
    Menu: (
      <MenuUnfoldOutlined
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
    HR: (
      <UsergroupAddOutlined
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
    Finance: (
      <FileDoneOutlined
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
    "Workwise Companies": (
      <GlobalOutlined
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
    Inventory: (
      <SafetyCertificateOutlined
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
  };

  const dragProps = {
    onDragEnd: (fromIndex, toIndex) => {
      const dataCopy = { ...data };
      const item = dataCopy[currentCategory].splice(fromIndex, 1)[0];
      dataCopy[currentCategory].splice(toIndex, 0, item);
      setData(dataCopy);
    },
    nodeSelector: "div",
    handleSelector: "a",
  };

  const handleNavigation = (e, path) => {
    console.log("handleNavigation", path);
    e.preventDefault();
    //request canceller before navigation
    if (cancelRequests) {
      cancelRequests();
    }
    debouncedNavigate(path);
  };

  console.log(data,"Menu.jsx");
  return (
    <div className="menu">
      {Object.keys(data).map((key, ObjIndex) => {
        if(key === "Workwise Companies" && ![userTypeEnum.SuperAdmin].includes(user.userTypeId))
          return (<></>);
        else {
          return (
            <>
              <Collapse
                expandIconPosition="end"
                className={`MenuCollapse ${!navBarStatus ? "MenuCollapseHide" : ""}`}
                defaultActiveKey={["1", "2", "3", "4", "5"]}
                onChange={() => {}}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel header={key} key="1" extra={renderIcons[key]}>
                  <ReactDragListView {...dragProps}>
                    {data[key].map(
                      ({ name, to: path, icon, notificationCount }, index) => {
                        return !navBarStatus ? (
                          <Tooltip
                            title={name}
                            color={""}
                            placement="right"
                            key={index}
                            overlayClassName=""
                          >
                            <div
                              className="menu-item"
                              onDrag={() => {
                                currentCategory = key;
                              }}
                            >
                              <NavLink
                                className={({ isActive }) => {
                                  return activeTab(isActive, path);
                                }}
                                to={path}
                                end
                              >
                                <div className="icon">
                                  <img src={icon} alt="#" />
                                </div>
                                <p>{name}</p>
                                <p>{notificationCount}</p>
                              </NavLink>
                            </div>
                          </Tooltip>
                        ) : (
                          <div
                            className="menu-item"
                            onDrag={() => {
                              currentCategory = key;
                            }}
                          >
                            <NavLink
                              className={({ isActive }) => {
                                return activeTab(isActive, path);
                              }}
                              to={path}
                              end
                              onClick={(e) => handleNavigation(e,path)}
                            >
                              <div className="icon">
                                <img src={icon} alt="#" />
                              </div>
                              <p>{name}</p>
                              <NotificationBadge
                                notificationCount={notificationCount}
                              />
                            </NavLink>
                          </div>
                        );
                      }
                    )}
                  </ReactDragListView>
                </Panel>
              </Collapse>
            </>
          );
        }
      })}
    </div>
  );
}
