import { Button, Form, Input, Modal } from "antd"
import React ,  { useState } from "react"
import SingleUpload from "../../../Upload/singleUpload"
import { defaultUiid } from "../../../../../utils/Shared/enums/enums"
import TravelGroupAttachment from "../../../../features/travel/view/UI/travelGroupAttachment"
// import "./expenseAddRemark.css"


export default function ExpenseAddRemark({onExpneseSubmit = () =>{}, isAgentExpense , isRemarkAdded ,setIsAgentExpense}){


    const [state , setState] = useState({
        amount:"",
        description:"",
        attachments:[]
    })

    const [attachments , setAttachments] = useState([])

    const onFinish = (values) =>{

        const payload = {
            ...values, 
            attachments: attachments.flatMap(attachment => 
              attachment.files.map(file => ({
                id: defaultUiid,
                file: file.originFileObj,
                type: attachment.documentType,
              }))
            ),
          };
            onExpneseSubmit(payload)
    }

    const onFinishFailed = () =>{

    }

    return(
        <>
        <Modal
            open={isAgentExpense}
            width={700}
            footer={null}
            onCancel={()=>{
                setIsAgentExpense(false)
            }}
        >           
                <Form
                     layout="vertical"
                     onFinish={onFinish}
                     onFinishFailed={onFinishFailed}
                >
            
                    <Form.Item
                        label={
                            <span className="text-primary-color">{"Amount"}</span>
                        }
                        name="amount"
                        rules={[{ required:  true, message: `Amount is Required!`, }]}
                    >
                        <Input
                            className="flex-1"
                            placeholder="Amount"
                            type="number"
                        />
                    </Form.Item>
                 
                    <Form.Item
                        label={
                            <span className="text-primary-color">{"Description"}</span>
                        }
                        name="description"
                    >
                        <Input.TextArea
                            placeholder="Description"
                            maxLength={500}
                            showCount
                        />
                    </Form.Item>
                    {/* <Form.Item label={
                            <span className="text-primary-color">{"Attachment"}</span>
                        } labelPosition="top">
                        <SingleUpload
                            handleImageUpload={(file) => {
                                setAttachments(file)
                            }}
                            multiple={true}
                            position={"left"}
                        />
                    </Form.Item> */}
                    <TravelGroupAttachment setAttachments = {setAttachments}/>
                    <Form.Item>
                        <div className="flex justify-end mt-4">
                            <Button
                                loading={isRemarkAdded}
                                className="ThemeBtn"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </div>
                    </Form.Item>

                </Form>
        </Modal>
           
        </>
    )
}