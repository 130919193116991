import { DocsComposerEnums, DOCUMENT_ENUM } from ".";
import folderIcon from "../../../../content/NewContent/Documents/file/folder.svg";
import pdfIcon from "../../../../content/NewContent/Documents/file/PDF_DOC.svg";
import gridIcon from "../../../../content/NewContent/Documents/file/milegrid.svg";
import drawIcon from "../../../../content/NewContent/Documents/file/mileboard.svg";
import signature from "../../../../content/NewContent/Documents/file/signature.svg";
import padIcon from "../../../../content/NewContent/Documents/file/milepad.svg";
import showIcon from "../../../../content/NewContent/Documents/file/mileshow.svg";
import wordIcon from "../../../../content/NewContent/Documents/file/ms-word-icon.svg";
import excelIcon from "../../../../content/NewContent/Documents/file/ms-excel-icon.svg";
import playIcon from "../../../../content/NewContent/Documents/file/play.svg";
import powerpointIcon from "../../../../content/NewContent/Documents/file/ms-ppt-icon.svg";
import mindmap from "../../../../content/NewContent/Documents/file/MapIcon.svg"
import defaultImage from "../../../../content/business_default.png";
import { DocumentFilterTypeEnum } from "../utils/FilterTypeEnum";
import { DocumentCustomFilterEnum } from "../utils/DocumentCustomFilterEnum";
import { DocumentTypeEnum } from "../utils/DocumentTypeEnum";
import { SignatureDocumentFilterTypeEnum } from "../utils/SignatureDocumentFilterTypeEnum";

export const getIconByExtensionType = (
  AttachmentType,
  extensionType = null,
  imagePath = null, 
  isSignedDocument = false
) => {
  let { DUCOMENT_TYPE, EXTENSION_TYPE } = DOCUMENT_ENUM;

  switch (AttachmentType) {
    case DUCOMENT_TYPE.folder:
      return folderIcon;
    case DUCOMENT_TYPE.image:
      return imagePath;
    case DUCOMENT_TYPE.video:
      return playIcon;
    case DUCOMENT_TYPE.grid: 
      return gridIcon
    case DUCOMENT_TYPE.draw:
      return drawIcon;
    case DUCOMENT_TYPE.pad:
      if(isSignedDocument)
        return signature
      else 
        return padIcon
    case DUCOMENT_TYPE.show:
      return showIcon;
    case DUCOMENT_TYPE.mindMap:
      return mindmap;

    case DUCOMENT_TYPE.attachment:
      switch (extensionType) {
        case EXTENSION_TYPE.Pdf: {
          return pdfIcon;
        }
        case EXTENSION_TYPE.Doc: {
          return wordIcon;
        }
        case EXTENSION_TYPE.Docx: {
          return wordIcon;
        }
        case EXTENSION_TYPE.Xls: {
          return excelIcon;
        }
        case EXTENSION_TYPE.Xlsx: {
          return excelIcon;
        }
        case EXTENSION_TYPE.Ppt: {
          return powerpointIcon;
        }
        case EXTENSION_TYPE.Pptx: {
          return powerpointIcon;
        }

        default:
          return defaultImage;
      }
    default:
      return defaultImage;
      break;
  }
};

export const getComposerKeyByType = (documentType) => {
  switch (documentType) {
    case DOCUMENT_ENUM.DUCOMENT_TYPE.attachment:
      return DocsComposerEnums.upload;
    case DOCUMENT_ENUM.DUCOMENT_TYPE.attachment:
      return DocsComposerEnums.upload;
    case DOCUMENT_ENUM.DUCOMENT_TYPE.attachment:
      return DocsComposerEnums.upload;
    case DOCUMENT_ENUM.DUCOMENT_TYPE.attachment:
      return DocsComposerEnums.upload;
    case DOCUMENT_ENUM.DUCOMENT_TYPE.attachment:
      return DocsComposerEnums.upload;
    default:
      break;
  }
};

export const getDocumentRightLabel = (documentType) => {
  switch (documentType) {
    case DOCUMENT_ENUM.DUCOMENT_TYPE.folder:
      return "Access To";
    case DOCUMENT_ENUM.DUCOMENT_TYPE.image:
      return "Downloaders";
    case DOCUMENT_ENUM.DUCOMENT_TYPE.video:
      return "Downloaders";
    case DOCUMENT_ENUM.DUCOMENT_TYPE.attachment:
      return "Downloaders";
    default:
      return "Collaborators";
      break;
  }
};

export const getDocumentLabelByType = (documentType, document) => {
  switch (documentType) {
    case 1:
      return document.AllFiles
    case DocumentTypeEnum.Grid:
      return document.Grids
    case DocumentTypeEnum.Pad:
      return document.Pads
    case DocumentTypeEnum.Slide:
      return document.Slides
    case DocumentTypeEnum.Board:
      return document.Boards
    case DocumentTypeEnum.MindMap:
      return document.Mindmaps
    default:
      return " ";
  }
}

export const getSignatureDocumentLabelByType = (documentType, document) => {
  switch (documentType) {
    case SignatureDocumentFilterTypeEnum.MySignedDocuments:
      return document.mySigned
    case SignatureDocumentFilterTypeEnum.DocumentsForSignature:
      return document.forMySignature
     case SignatureDocumentFilterTypeEnum.PendingSignature:
      return document.draftSignature
     case SignatureDocumentFilterTypeEnum.SignedDocuments:
      return document.inSigning
     case SignatureDocumentFilterTypeEnum.CompleteSignedDocuments:
      return document.signaturesCompleted
    default:
      return " ";
  }
}



