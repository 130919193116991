import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllNotification, markAllReadAction } from "../store/action";
import NotificationItem from "./NotificationItem";
import "./style.css";
import OnScroll from "../../appraisalModule/view/OnScroll";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { Empty } from 'antd';
import { dictionaryList } from "../../../../utils/localization/languages";

const defaultFilter = {
  pageNo: 1,
  search: "",
};

export default function Notifications() {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, setting } = dictionaryList[userLanguage];

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(defaultFilter);
  const notifcationStatus = useSelector(
    (state) => state.responsiveSlice.notifcationStatus
  );
  const notificationList = useSelector(
    (state) => state.notificationSliceNew.notificationList
  );
  const [state, setState] = useState({ isLoading: false, isNext: false });

  useEffect(() => {
    setState({ ...state, isLoading: true });
    if (notifcationStatus) {
      dispatch(getAllNotification(filter)).then((res) =>
        setState({ isLoading: false, isNext: res?.payload?.length === 20 })
      );
    } else {
      filter?.pageNo !== 1 && setFilter(defaultFilter);
    }
  }, [notifcationStatus, filter]);

  const markAllRead = () => {
    console.log("readdAll");
    dispatch(markAllReadAction());
  };

  return (
    <div className="approval_list_cont">
      <div className="approval_header">
        <div className="approval_header_child1">
          {setting.Notifications}
        </div>
        <div className="approval_header_child2" onClick={markAllRead}>
          <div></div>
          <div>{sharedLabels.MarkAllRead}</div>
        </div>
      </div>
      <OnScroll
        isNext={state.isNext}
        loader={state?.isLoading}
        currentPage={filter?.pageNo}
        onPageChange={(page) => setFilter({ ...filter, pageNo: page })}
        parentDivClass="approval_list max-h-[500px]"
      >
        <>
          {notificationList.length > 0 ? notificationList.map((item, index) => (
            <NotificationItem item={item} index={index} />
          )) : !state?.isLoading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="h-[370px] flex justify-center items-center" />}
        </>
      </OnScroll>
    </div>
  );
}
