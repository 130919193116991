import { Tag } from "antd";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { RewardStatusEnum } from "../../utils/RewardStatusEnum";
import { useContext } from "react";

const StatusTag = ({ status = RewardStatusEnum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { reward, Direction } = dictionaryList[userLanguage];
  let color = ColorsEnum.Primary;

  switch (status) {
    case RewardStatusEnum.InProcess:
      color = ColorsEnum.Primary;
      break;
    case RewardStatusEnum.Approved:
      color = ColorsEnum.Success;
      break;
    case RewardStatusEnum.Declined:
      color = ColorsEnum.Danger;
      break;
    case RewardStatusEnum.Cancelled:
      color = ColorsEnum.Cancelled;
      break;
    case RewardStatusEnum.Hold:
      color = ColorsEnum.Hold;
      break;
    default:
      color = ColorsEnum.Primary;
  }
  console.log(color, "color");
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {reward.statusEnum[status]}
      </Tag>
    </>
  );
};

export default StatusTag;
