import React, { useContext, useEffect, useState } from "react";
import termsAndCondition from "../../../../../content/file/termandcondition.pdf";
import systemLogo from "../../../../../content/systemLogo.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Space, message } from "antd";
import TextInput from "../../../../sharedComponents/Input/TextInput";
import PasswordInput from "../../../../sharedComponents/Input/PasswordInput";
import { SvgSpinner } from "../../../../../utils/base";
import { useSelector, useDispatch } from "react-redux";
import { addFcmDeviceOnServer, loginUser } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import FormFooter from "./formFooter";
import { getFirebaseToken } from "../../../../../firebase/initFirebase";
import "../styles/style.css";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { setUser } from "../../../../../store/appReducer/userSlice";
import { responseCode } from "../../../../../services/enums/responseCode";

function SignIn() {
  let formData = {};
  const [localState, setlocalState] = useState({
    disableFormSubmit: false,
    dialogMessage: "",
    openView: false,
    path: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];

  const { loader } = useSelector((state) => state.authSlice);

  const { token } = useSelector((state) => state.userSlice);

  const [reset, setReset] = useState(false);

  const onSubmit = (payload) => {
    const { email, password } = payload;

    if (!email && !password) {
      message.error(sharedLabels.LoginMessage)
      return;
    }

    if (!email) {
      message.error("Email Required!")
      return;
    }

    if (!password) {
      message.error("Password Required!")
      return;
    }

    let deviceToken = null;

    // Proceed with login regardless of notification permission
    dispatch(loginUser({ ...payload })).then(async (res) => {
      let data = res?.payload?.data;
    
      // Check if the login was successful
      if (data.responseCode === responseCode.Success) {
        let deviceToken = null;
        
        try {
          // Request notification permission
          let permission = await Notification.requestPermission();
          if (permission === "granted") {
            console.log("Notification permission granted.");
            deviceToken = await getFirebaseToken();
          }
        } catch (error) {
          console.error("Error requesting notification permission:", error);
        }
        
        // device token was obtained
        if (deviceToken) {
          try {
            const addDeviceRes = await addFcmDeviceOnServer({
              user: data.data,
              deviceToken: deviceToken,
            });

           //update the user state
            if (addDeviceRes.responseCode === responseCode.Success) {
              dispatch(
                setUser({
                  user: data.data,
                  token: data.data.accessToken,
                  refreshToken: data.data.refreshToken,
                  deviceToken: deviceToken,
                })
              );
            }
          } catch (error) {
            console.error("Error adding device token to server:", error);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  const onChange = (value, name) => {
    formData = { ...formData, [name]: value };
  };
  const closeViewerModal = () => {
    setlocalState({ ...localState, openView: false, path: "" });
  };

  return (
    <React.Fragment>
      <div className="">
        <img src={systemLogo} alt="#" className="mobileLogo" />
      </div>

      <Form onFinish={onSubmit} className="lg-form">
        <div className="welcome-heading">
          <div>Welcome</div>
        </div>
        <div className="note note-heading">
          Enter your login credentials to continue.
        </div>
        <div className="">
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <Form.Item name="email">
              <TextInput
                type="email"
                // onChange={onChange}
                placeholder="Email"
                prefix={UserOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>
            <Form.Item name="password">
              <PasswordInput
                placeholder="Password"
                prefix={LockOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>
          </Space>
        </div>
        <div className="btn">
          <button className={`button ${loader ? "disable" : ""}`}>
            Sign in
            {!loader ? (
              <span className="icon-login">
                <i className="ic-login_icon" />
              </span>
            ) : (
              <SvgSpinner />
            )}
          </button>
        </div>

        <FormFooter></FormFooter>

        {/* {localState.openView && (
          <ViewerModal
            path={localState.path}
            closeViewerModal={closeViewerModal}
          />
        )} */}
      </Form>
      {/* <div className="note">{STRINGS.COPY_RIGHTS}</div> */}
    </React.Fragment>
  );
}

export default SignIn;
