import React, { useEffect, useState } from 'react';
import SuggestionBox from '../../../../sharedComponents/SuggestionBox/View/SuggestionBox';
import MemberListContainer from '../../memberListContainer';
import { Button, Form, Space } from 'antd';
import DefaultMemberList from '../defaultMemberList/defaultMemberList';
import {SuggestionTypeEnum} from "../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { STRINGS } from '../../../../../utils/base';

const AddMember = ({
                       referenceId = STRINGS.DEFAULTS.guid,
                       suggestionType = [SuggestionTypeEnum.Employee],
                       placeHolder = "",
                       callback = ()=> {},
                       removeSelf = false,
                       isMultiSelect = true,
                       existingMembers = [], // List of Ids which are already in list
                       showAddedMembers = false,
                       initialData = []
}) => {
    const [data, setData] = useState(showAddedMembers ? initialData : []);
    const onDelete = (id) => {

        console.log(id,"==data==",data)
        const updatedData = data.filter((item)=>item.id!==id)
        setData(updatedData);
    };

    useEffect(()=>{
        callback(data);
    },[data])
   

    const notRequiredMemberiDs = data?.map((x)=> {return x.id}).concat(existingMembers);
   
    return (
        <Form.Item>
            <div className="mb-4">
                <SuggestionBox
                    referenceId={referenceId}
                    notRequiredMemberiDs = {notRequiredMemberiDs}
                    placeholder={placeHolder}
                    suggestionType = {suggestionType}
                    callback={(item) => {
                        if(item.length > 0)
                            isMultiSelect ? setData([...data, item[0]]) : setData([item[0]]);
                    }}
                    removeSelf={removeSelf}
                    isMultiSelect={false}
                        />
            </div>
            <div className="mb-4">
                <DefaultMemberList data={data} onDelete={onDelete} isDelete={true}/>
            </div>
            {/* <MemberListContainer data={data} onDelete={onDelete} /> */}
            {/* <div className="mt-3 flex space-x-3 items-center justify-end">
                <Space>
                    <Button type="primary" size="medium" className="ThemeBtn" block htmlType="submit">
                        Cancel
                    </Button>
                    <Button type="primary" size="medium" className="ThemeBtn" block htmlType="submit">
                        Save
                    </Button>
                </Space>
            </div> */}
        </Form.Item>
    );
};

export default AddMember;
