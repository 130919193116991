import { useState } from "react";
import { STRINGS } from "../../../../../utils/base";
import { TransactionReferenceTypeEnum } from "../../../createVoucher/enum/TransactionReferenceTypeEnum";
import { Modal } from "antd";
import DisperseSuggestion from "./DisperseSiggestion";

function DisperseModal({
    open = false,
    onCancel = () => { },
    id = STRINGS.DEFAULTS.guid,
    referenceType = TransactionReferenceTypeEnum.General,
}) {

    function stopPropagation(e) {
        e.stopPropagation();
    }

    const handleModalCancel = (e) => {
        e.stopPropagation();
        onCancel();
    };

    return (
        <>
            <div onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                <Modal
                    open={open}
                    onCancel={handleModalCancel}
                    footer={null}
                    className="modal-body"
                    width={"160vh"}
                >
                    <div onClick={stopPropagation}>
                        <DisperseSuggestion id={id}
                            referenceType={referenceType}
                            onCancel={onCancel}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
}
export default DisperseModal;
