import { Drawer } from "antd";
import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import ExpenseDetail from "./DeatailItem/ExpenseDetail";
import { dictionaryList } from "../../../../../../utils/localization/languages";

function ExpenseDetailContainer({ id, index, onClose, isOpen }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { expenses } = dictionaryList[userLanguage];
  return (
    <Drawer
      title={
        <h1
          style={{
            fontSize: "20px",
            margin: 0,
            textAlign: "ltr",
          }}
        >
          {expenses.ExpenseDetail}
        </h1>
      }
      placement={"ltr" === "ltr" ? "right" : "left"}
      width="768"
      onClose={onClose}
      visible={isOpen}
      destroyOnClose={true}
      className="detailedViewComposer drawerSecondary"
    >
      {id && <ExpenseDetail id={id} index={index} />}
    </Drawer>
  );
}

export default ExpenseDetailContainer;
