import React, { useContext } from "react";
import AvatarOld from "../../../../sharedComponents/Avatar/avatarOLD";
import moment from "moment";
import "../../style/offerDetail.css";
import { AuctionOfferStatusEnum, AuctionStatusEnum } from "../../utils/enums";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";

const BiddingListItem = ({ item, index }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { auction } = dictionaryList[userLanguage];
  let bidTime = moment
    .utc(item?.createDate)
    .local()
    .fromNow();
  let statusLabel = Object.keys(AuctionOfferStatusEnum).find(
    (key) => AuctionOfferStatusEnum[key] === item.status
  );

  return (
    <div
      className={`flex items-center justify-between ${
        index % 2 !== 0 ? "bg-white" : "bg-gray-100"
      }`}
    >
      <div className={`bid_item cursor-pointer`}>
        <div>
          <AvatarOld
            src={item?.member?.image}
            name={item?.member?.name}
            size={30}
            round={true}
          />
        </div>
        <div className="bid_item_detail">
          <div className="bid_item_detail_child1">
            <span className="bid_creator">{item?.member?.name}</span>
            <span className="bid_message">{" placed a bid of"}</span>
          </div>
          <div className="bid_item_detail_child2">
            <div className="dateTime">
              <div className="shortDesc">{bidTime}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <div className="text-[15px] font-semibold mr-3">{formatNumberWithCommas(item?.offer)}</div>
        {(AuctionOfferStatusEnum.BidInProcess === item.status || AuctionOfferStatusEnum.BidSold === item.status) && (
          <div className={`bidBadge ${statusLabel} relative top-[-5px]`}>
            {auction.auctionOfferStatus[item.status]}
          </div>
        )}
      </div>
    </div>
  );
};

export default BiddingListItem;
