import React, { useContext, useState } from "react";
import { Button, DatePicker, Tooltip } from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import SubTaskDateRangeDisplay from "./subtaskDateRage";
import moment from "moment";
import SubtaskMemberModal from "./subTaskMemberModal";
import { useSelector } from "react-redux";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";

const SubTaskRowBtn = ({
  startDate,
  endDate,
  onDateRangeConfirm,
  alowClearDate = false,
  record = {},
  onAddMember = () => { },
  showAddedMembers = false,
  initialData = [],
  stopAction = false,
  isGoal = false,
  isTask = false,
}) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startDate ? moment(startDate) : null,
    endDate: endDate ? moment(endDate) : null,
  });
  const { user } = useSelector((state) => state.userSlice);

  const showDatePicker = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDatePickerVisible(true);
  };

  const handleDateChange = (dates) => {
    if (dates) {
      setSelectedDateRange({
        startDate: dates[0] ? moment(dates[0]) : null,
        endDate: dates[1] ? moment(dates[1]) : null,
      });
      onDateRangeConfirm([
        dates[0] ? moment(dates[0]) : null,
        dates[1] ? moment(dates[1]) : null,
      ]);
    } else if (alowClearDate) {
      setSelectedDateRange({ startDate: null, endDate: null });
      onDateRangeConfirm([null, null]);
    }
    setIsDatePickerVisible(false);
  };

  const handleClearDate = () => {
    setSelectedDateRange({ startDate: null, endDate: null });
    onDateRangeConfirm([null, null]);

    setIsDatePickerVisible(false);
  };

  const handleOpenChange = (open) => {
    if (!open) {
      setIsDatePickerVisible(false);
    }
  };

  return (
    <>
      <div className="flex items-center">
        {selectedDateRange.startDate && selectedDateRange.endDate ? (
          <SubTaskDateRangeDisplay
            startDate={selectedDateRange.startDate}
            endDate={selectedDateRange.endDate}
            onClick={(e) =>
              user?.id === record?.creator?.id &&
              !stopAction &&
              showDatePicker(e)
            }
            progress={record?.progress}
            className={`${!initialData?.length ? "mr-7" : "mr-2"}`}
          />
        ) : (
          !isDatePickerVisible && (
            <CalendarOutlined
              className={` ${!record?.isNew && "calendarIcon"} cursor-pointer ${!initialData?.length ? "mr-7" : "mr-2"
                } `}
              onClick={(e) => !stopAction && showDatePicker(e)}
            />
          )
        )}
        {( isTask || (user?.id === record?.creator?.id && !stopAction && !isGoal)) &&(
          <SubtaskMemberModal
            record={record}
            addMembers={onAddMember}
            showAddedMembers={showAddedMembers}
            initialData={initialData}
          />
        )}
      </div>
      {isDatePickerVisible && (
        <div className = {`${isDatePickerVisible && "absolute right-3"}`} onClick={(e) => e.stopPropagation()}>
          <DatePicker.RangePicker
            value={[selectedDateRange.startDate, selectedDateRange.endDate]}
            onChange={handleDateChange}
            onOpenChange={handleOpenChange}
            open={isDatePickerVisible}
            bordered={false}
            suffixIcon={null}
            placeholder=""
            className="subtask-date"
            renderExtraFooter={() =>
              alowClearDate &&
              selectedDateRange.startDate && (
                <Button
                  className="subtaskDateClearbtn"
                  onClick={handleClearDate}
                >
                  {sharedLabels.clear}
                </Button>
              )
            }
            disabledDate={(current) =>
              record?.isNew
                ? current && current < moment().startOf(tasks.days)
                : current &&
                current <
                (moment(startDate)
                  .startOf(tasks.days)
                  .isAfter(moment().startOf(tasks.days))
                  ? moment().startOf(tasks.days)
                  : moment(startDate).startOf(tasks.days))
            }
          />
        </div>
      )}
    </>
  );
};

export default SubTaskRowBtn;
