import { ApprovalsModuleEnum } from "../../approval/utils/enum/approvalModuleEnum";
export const ResignationApprovalsModuleEnum = {
    [ApprovalsModuleEnum.ResignationAdminApproval] : 'admin',
    [ApprovalsModuleEnum.ResignationExitApproval]: 'exit',
    [ApprovalsModuleEnum.ResignationFinanceApproval]: 'finance',
    [ApprovalsModuleEnum.ResignationHrApproval] : 'hr',
    [ApprovalsModuleEnum.ResignationItApproval]: 'it',
    [ApprovalsModuleEnum.ResignationOtherApproval]: 'other',
    [ApprovalsModuleEnum.ResignationReportingToApproval]: 'reportingTo'

};