export const ReactionModuleEnum = {
    Feed: 1,
    UserTask: 2,
    FeedComment: 3,
    WorkBoard: 4,
    WorkBoardTodo: 5,
    Document: 6,
    LeadManager: 7,
    LeadManagerDetail: 8,
    LeadManagerDetailContact: 9,
    ScheduleComment: 10,
    Requisition  : 11,
    CareerApplicantComment : 12,
    WorkBoardSection:13,
    ProjectContact : 14,
    DiscussionBoard : 15,
    Pages:16,
    BusinessNumber:17,
    Issues : 18,
    Project : 19,
    Group : 20,
    Auction : 21,
    AuctionOffer : 22,
};