import moment from "moment";

export const filterEventsByBusinessHours = (events, businessHours) => {
  return events.filter((event) => {
    const eventStart = moment.utc(event.startDate).local();
    const eventEnd = moment.utc(event.endDate).local();
    return businessHours.some((hours) => {
      const startTime = moment(eventStart).set({
        hour: parseInt(hours.startTime.split(":")[0], 10),
        minute: parseInt(hours.startTime.split(":")[1], 10),
        second: 0,
        millisecond: 0,
      });
      const endTime = moment(eventEnd).set({
        hour: parseInt(hours.endTime.split(":")[0], 10),
        minute: parseInt(hours.endTime.split(":")[1], 10),
        second: 0,
        millisecond: 0,
      });

      return eventStart.isBefore(endTime) && eventEnd.isAfter(startTime);
    });
  });
};
