import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MainBannerContent from "../AuthPageContent/MainBannerContent";
import { Col, Row, message } from "antd";
import { ROUTES } from "../../../../../utils/routes";
import { useSelector, useDispatch } from "react-redux";
import "../styles/style.css"
import PasswordForm from "./setupPassword";
import { verifyTokenAction } from "../../store/actions";
import { responseCode } from "../../../../../services/enums/responseCode";

const SetupPassword = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const passwordToken = searchParams.get("token");
	const passwordTokenId = searchParams.get("id");

	const { token } = useSelector(state => state.userSlice);
	const isLoggedIn = !!token;
	const [isTokenVerified, setIsTokenVerified] = useState(false)
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (passwordToken || passwordTokenId ) {
		  dispatch(verifyTokenAction(passwordTokenId ? passwordTokenId : passwordToken)).then((response) => {
			const { payload } = response;
			if (payload?.responseCode === responseCode.Success) {
			  setIsTokenVerified(true);
			} else {
				navigate(`/`)
				setIsTokenVerified(false)};
		  });
		}
	  }, [dispatch, passwordToken,passwordTokenId]);

	useEffect(() => {
		if (isLoggedIn) {
			clearLocalStorage();
			return navigate(ROUTES.DASHBOARD);
		}
	}, [isLoggedIn]);
	const clearLocalStorage = () => {
		localStorage.clear();
	};

	return (
		<>
		{isTokenVerified && <div className="lg-area">
			<div className="lg-area-color-layer" />

			<Row gutter={{ xs: 0, sm: 0, lg: 24 }} className="main-landing-row">
				<Col
					className="SinginFirstColumn"
					xs={{ order: 2, span: 24 }}
					sm={{ order: 1, span: 12 }}
					lg={{ order: 1, span: 12 }}
				>
						<MainBannerContent />
				</Col>
				<Col
					className="FormColumn"
					xs={{ order: 1, span: 24 }}
					sm={{ order: 2, span: 12 }}
					lg={{ order: 2, span: 12 }}
				>
					<div className="center-area">
						<PasswordForm />
					</div>
				</Col>
			</Row>
		</div>}
		</>
	);
};

export default SetupPassword;
