import { useContext, useEffect } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { Button, Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";

export default function AuctionBidExternalUserInfo({
  isOfferSubmitLoader,
  isOpenExternalModal,
  setIsOpenExternalModal,
  offer,
  onSubmit = () => {},
}) {
  const [form] = Form.useForm();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  useEffect(() => {
    form.setFieldsValue({
      offer: offer,
    });
  }, []);
  const onFinish = (values) => {
    onSubmit(values);
  };

  return (
    <>
      <Modal
        open={isOpenExternalModal}
        onCancel={() => setIsOpenExternalModal(false)}
        width={800}
        footer={null}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label={<span className="text-primary-color">{"Name"}</span>}
            name="name"
            rules={[{ required: true, message: `Name is required` }]}
          >
            <Input type={"text"} placeholder={"Enter Name : "} />
          </Form.Item>
          <Form.Item
            label={<span className="text-primary-color">{"Email"}</span>}
            name="email"
            rules={[{ required: true, message: `Email is required` }]}
          >
            <Input type={"email"} placeholder={"Enter Email : "} />
          </Form.Item>
          <Form.Item
            label={<span className="text-primary-color">{"Phone Number"}</span>}
            name="phoneNumber"
            rules={[{ required: true, message: `Phone No: is required` }]}
          >
            <Input type={"number"} placeholder={"Enter Number : "} />
          </Form.Item>
          <Form.Item
            label={<span className="text-primary-color">{"Offer"}</span>}
            name="offer"
            rules={[{ required: true, message: `Offer is required` }]}
          >
            <Input type={"text"} placeholder={"Enter Offer : "} />
          </Form.Item>
          <Form.Item
            label={<span className="text-primary-color">{"Description"}</span>}
            name="description"
            rules={[{ required: true, message: `Description is required` }]}
          >
            <TextArea
              rows={6}
              placeholder={sharedLabels.WriteDescriptionhere}
              name=""
              id=""
            ></TextArea>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="ThemeBtn"
              block
              htmlType="submit"
              loading={isOfferSubmitLoader}
            >
              {"Submit Offer"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
