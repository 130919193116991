import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  getAllRequestListItems,
  getRequestListItemsById,
  addRequestListItems,
  getAllRequestForItemsPaging,
} from "./action";

const initialState = {
  success: false,
  requestItems: [],
  loadingData: false,
  loader: false,
  requestItemDetail: {},
  drawerOpen: false,
  pagingRequestForItems : {}
};

const requestItemSlice = createSlice({
  name: "RequestListItems",
  initialState,
  reducers: {
    clearRequestDetails: (state) => {
      state.salaryDetail = null;
    },
    handleOpenComposer: (state, action) => {
      state.drawerOpen = action.payload;
    },
    handleStatus: (state, action) => {
      state.requestItemDetail = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRequestListItems.fulfilled, (state, action) => {
      console.log(action.payload, "getAllRequestListItems slice");
      state.requestItems = action.payload ? action.payload : [];
      state.loader = false;
    });

    builder.addCase(getAllRequestForItemsPaging.fulfilled, (state, action) => {
      state.pagingRequestForItems = action.payload ?? []
      state.requestItems = action.payload.data ?? [];
     
      state.loader = false;
    });

    builder.addCase(getRequestListItemsById.fulfilled, (state, action) => {
      console.log(action.payload.data, "getRequestListItemsById slice");
      state.requestItemDetail = action.payload.data;
      state.loadingData = false;
      state.loader = false;
    });
    builder
    
      .addCase(addRequestListItems.fulfilled, (state, { payload }) => {
        console.log(payload, "addRequestListItems slice");
        state.success = true;
        state.drawerOpen = false;
        state.loader = true;
        state.requestItems = [...state.requestItems, payload.data.data];
      })



      .addMatcher(
        isPending(...[getAllRequestListItems, addRequestListItems ]),
        (state) => {
          console.log("isPending");
          state.loader = true;
        }
      )
      .addMatcher(isPending(...[getAllRequestForItemsPaging]), (state , action) => {
        const {pageNo , pageSize }  = action.meta.arg
        state.loader = pageNo > 1 || pageSize > 20 ? false : true;
  
      })

      .addMatcher(isPending(...[getRequestListItemsById]), (state , action) => {
        state.loadingData = true

  
      })
      .addMatcher(
        isRejected(
          ...[
            getAllRequestListItems,
            getRequestListItemsById,
            addRequestListItems,
          ]
        ),
        (state) => {
          console.log("isRejected");
          state.loader = true;
        }
      );
  },
});

export const {
  clearRequestDetails,
  handleOpenComposer,
  handleStatus,
} = requestItemSlice.actions;
export default requestItemSlice.reducer;
