import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import { getBusinessAssociation, addBusinessAssociation , updateBusinessAssociationAction} from "./action.js";

const initialState = {
  BusinessAssociation : [],
  loadingData: false,
  loader: false,
};

const businessAssociationSlice = createSlice({
  name: "BusinessAssociation",
  initialState,
  reducers: {
    BusinessAssociationDeleted: (state, { payload }) => {
      state.BusinessAssociation = state.BusinessAssociation.filter((e) => e.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessAssociation.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.BusinessAssociation = payload.data;
      })
      .addCase(addBusinessAssociation.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.BusinessAssociation.push(payload.data);
      })
      .addCase(updateBusinessAssociationAction.fulfilled, (state, { payload })=>{
         state.BusinessAssociation = state.BusinessAssociation.map((item)=>
          item.id === payload.data.id ? payload.data:item
         );
      })
      .addMatcher(isPending(...[addBusinessAssociation]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getBusinessAssociation]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(
        isRejected(...[getBusinessAssociation, addBusinessAssociation]),
        (state) => {
          state.loader = false;
          state.loadingData = false;
        }
      );
  },
});

export const { BusinessAssociationDeleted } = businessAssociationSlice.actions;
export default businessAssociationSlice.reducer;
