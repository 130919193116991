import { useContext, useEffect, useState } from "react";
import { DatePicker, Divider, Form, Input, Select, Row, Col, Avatar, Button } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { employeeDictionaryList } from "../../localization/index";
import { genderList, maritalStatusList } from "../../../../../utils/Shared/enums/enums";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCities, getCountries } from "../../../../../utils/Shared/store/actions";
import { getNameForImage } from "../../../../../utils/base";
import systemLogo from '../../../../../content/systemLogo.png';
import SingleUpload from '../../../../sharedComponents/Upload/singleUpload';
import PreEducationForm from "./preEducationForm";
import PreExperienceForm from "./preWorkExperienceForm";
import { PreEmployee } from "../../store/actions";
import PreEmergencyForm from "./preEmergencyForm";
import PreBankForm from "./preBankForm";

const { Option } = Select;

const PreBasicInfo = ({ mode, handleImageUpload }) => {
    const { countries, cities } = useSelector((state) => state.sharedSlice);
    const [form] = Form.useForm();

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCountries())
        dispatch(
            getCities({
                textData: "",
                page: 0,
            })
        );
    }, [])
    const initialState = {
        firstName: "",
        lastName: "",
        fatherName: "",
        email: "",
        nic: "",
        phoneNo: "",
        countryId: [],
        cityId: [],
        birthDate: "",
        genderId: [],
        maritalStatusId: [],
        middleName: "",
        address: "",
    };

    const [initialValues, setInitialValues] = useState(initialState);
    const [educationFormData, setEducationFromData] = useState([]);
    const [workFormData, setWorkFormData] = useState([]);
    const [emergencyFormData, setEmergencyFormData] = useState([]);
    const [bankFormData, setBankFormData] = useState([]);
    console.log(educationFormData, "educationFormDataeducationFormData")

    const {
        employee: { basicdetails, profileDetails },
    } = useSelector((state) => state.employeeSlice);

    const { userLanguage } = useContext(LanguageChangeContext);
    const { employeesDictionary, Direction } = employeeDictionaryList[
        userLanguage
    ];
    const labels = employeesDictionary.EmployeeForm;
    const placeholder = employeesDictionary.placeholders;
    const { loader, preEducationData, preEmployeFormData } = useSelector((state) => state.employeeSlice);


    Object.defineProperty(form, "values", {
        value: function () {
            return {
                ...form.getFieldsValue(),
                birthDate: moment(form.getFieldValue("birthDate")._ds).format(),
            };
        },
        writable: true,
        enumerable: true,
        configurable: true,
    });

    let classes = "employeeForm rm-grid basicInfo";
    classes += Direction === "ltr" ? "ltr" : "rtl";

    //...Image upload...//
    const isEdit = mode === "edit";
    const [updateProfileImage, setUpdateProfileImage] = useState(null);


    const handleUpdateImageUpload = (fileData) => {
        setUpdateProfileImage(fileData[0].originFileObj);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const values = await form.validateFields();
        const employeeData = {
            image: updateProfileImage,
            firstName: values.firstName,
            lastName: values.lastName,
            fatherName: values.fatherName,
            email: values.email,
            nic: values.nic,
            phoneNo: values.phoneNo,
            address: values.address,
            countryId: values.countryId,
            cityId: values.cityId,
            birthDate: values.birthDate,
            genderId: values.genderId,
            maritalStatusId: values.maritalStatusId,
            educations: [...educationFormData],
            experiences: [...workFormData],
            emergencyContact: [...emergencyFormData],
            bankDetail: [...bankFormData]

        }
        console.log(employeeData, 'employe data payload')
        dispatch(PreEmployee(employeeData)).then((action) => {
            if (PreEmployee.fulfilled.match(action)) {
                form.resetFields();
            }
        });
    };
    const getEducationFormData = (data) => {
        setEducationFromData(data);
    }
    const getWorkFormData = (data) => {
        setWorkFormData(data);
    }
    const getEmergencyFormData = (data) => {
        setEmergencyFormData(data);
    }
    const getBankFormData = (data) => {
        setBankFormData(data);
    }

    return (
        <div className={classes}>
            <Form
                name="basicInfo"
                form={form}
                initialValues={initialValues}
                onFinish={handleFormSubmit}
            >

                <div className='frame-top flex justify-between'>
                    <div className="frame-logo">
                        <div className="company-logo">
                            <img src={systemLogo} alt="#" />
                        </div>
                    </div>
                    <div id="upload-img" className="pr-img">
                        <Form.Item area="true">
                            <SingleUpload
                                handleImageUpload={isEdit ? handleUpdateImageUpload : handleImageUpload}
                                position="flex-start"
                                multiple={false}
                            />
                        </Form.Item>
                    </div>

                </div>
                <Divider orientation="left">{labels.BasicInfo}</Divider>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, message: "First Name is required" }]}
                            name="firstName"
                            label={"First Name"}
                        >
                            <Input placeholder={"Enter First Name"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Last Name is required",
                                },
                            ]}
                            name="lastName"
                            label={"Last Name"}
                        >
                            <Input placeholder={"Enter Last Name"} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Father Name is required",
                                },
                            ]}
                            name="fatherName"
                            label={"Father Name"}
                        >
                            <Input placeholder={"Enter Father Name"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your email",
                                },
                                {
                                    type: "email",
                                },
                            ]}
                            name="email"
                            label={labels.Email}
                        >
                            <Input placeholder={placeholder.email}></Input>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item name="nic" label={labels.CNICNumber}>
                            <Input
                                type="number"
                                min={0}
                                onWheel={(e) => e.target.blur()}
                                placeholder={placeholder.cnicNo}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, message: "Mobile Number is required" }]}
                            name="phoneNo"
                            label={"Mobile Number"}
                        >
                            <Input
                                type="number" min={0} placeholder={"Enter Mobile Number"}
                            ></Input>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, message: "Address is required" }]}
                            name="address"
                            label={"Address"}
                        >
                            <Input
                                placeholder={"Enter Address"}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="countryId"
                            label={labels.Country}
                            rules={[{ required: true, message: "Please select your country" }]}
                        >
                            <Select
                                showSearch={true}
                                placeholder={placeholder.selectCountry}
                                size="large"
                                getPopupContainer={(trigger) => trigger.parentNode}
                                optionFilterProp="children"
                                value={basicdetails.countryId}
                                onChange={() => {
                                    form.setFieldsValue({
                                        cityId: "",
                                    });
                                }}
                            >
                                {countries.map((item) => (
                                    <Option key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="cityId"
                            label={labels.City}
                            rules={[{ required: true, message: "Please select your city" }]}
                        >
                            <Select
                                showSearch={true}
                                placeholder={placeholder.searchToSelect}
                                size="large"
                                getPopupContainer={(trigger) => trigger.parentNode}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                }
                            >
                                {cities?.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        <Avatar src={item.image} className="!bg-black">
                                            {getNameForImage(item.name)}
                                        </Avatar>
                                        <span className="ml-1 text-semibold">
                                            {item.name} - {item.country}
                                        </span>
                                    </Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="birthDate" label={labels.DateOfBirth}>
                            <DatePicker
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder={placeholder.selectDate}
                                size="large"
                                format={"DD/MM/YYYY"}
                            />
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={[16, 16]}>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="genderId"
                            label={labels.Gender}
                            rules={[{ required: true, message: "Gender must be selected" }]}
                        >
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder={placeholder.selectGender}
                                size="large"
                            >
                                {genderList.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="maritalStatusId" label={labels.MaritalStatus}>
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder={placeholder.selectMarital}
                                size="large"
                            >
                                {maritalStatusList.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <PreEducationForm getEducationFormData={getEducationFormData} />
                <PreExperienceForm getWorkFormData={getWorkFormData} />
                <PreEmergencyForm getEmergencyFormData={getEmergencyFormData} />
                <PreBankForm getBankFormData={getBankFormData} />

                <div id="btn-frm" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form.Item style={{ width: '100%' }}>
                        <Button
                            loading={loader}
                            id=""
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className="ThemeBtn"
                            onClick={handleFormSubmit}
                            style={{ width: '100%' }}
                        >
                            Save info
                        </Button>
                    </Form.Item>
                </div>


            </Form >
        </div >
    );
};

export default PreBasicInfo;
