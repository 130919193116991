import MasterConfig from "../../../../utils/services/MasterConfig";


export const getMenuFoldersService = (configurationId) => {
    return MasterConfig.get(`api/Mailbox/GetAllMailboxFolder?configurationId=${configurationId}`)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        });
};

export const getAllEmailService = (objData) => {
    let {payload, configurationId} = objData

    return MasterConfig.post(`api/Mailbox/GetAllEmail?configurationId=${configurationId}`, payload)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        })
};
export const getMailByIdService = ({ id, folderPath, configurationId }) => {
    return MasterConfig.get(`api/Mailbox/GetEmail?id=${id}&path=${folderPath}&configurationId=${configurationId}`)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        })
};

export const composeMailService = ({dataobj, configurationId}) => {
    return MasterConfig.post(`api/Mailbox/SendEmail?configurationId=${configurationId}`, dataobj)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        })
};

export const changeMailSeenFlagService = ({ uid, flag, folderPath }) => {
    return MasterConfig.get(`/konnectmailbox/api/Mailbox/ChangeSeenFlagEmail?uid=${uid}&flag=${flag}&folderPath=${folderPath}`)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        })
};

export const moveEmailToTrashService = ({ id, folderPath }) => {
    return MasterConfig.get(`/konnectmailbox/api/Mailbox/MoveEmailToTrash?id=${id}&folderPath=${folderPath}`)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        })
};

export const deleteEmailService = (id) => {
    return MasterConfig.get(`/konnectmailbox/api/Mailbox/deleteemail?id=${id}`)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        })

}

// export const MailServices = {
// getMenuFolders: () => {
//     return MasterConfig.get("api/Mailbox/GetAllMailboxFolder");

// },

// getAllEmail: (payload) => {
//     return MasterConfig.post("api/Mailbox/GetAllEmail", payload);
// },

// getMailById: ({ id, folderPath }) => {
//     return MasterConfig.get(`api/Mailbox/GetEmail?id=${id}&path=${folderPath}`);
// },
// composeMail: (data) => {
//     return MasterConfig.post(`api/Mailbox/SendEmail`, data)
// },


// changeMailSeenFlag: ({ uid, flag, folderPath }) => {
//     return MasterConfig.get(`/konnectmailbox/api/Mailbox/ChangeSeenFlagEmail?uid=${uid}&flag=${flag}&folderPath=${folderPath}`)
// },
// moveEmailToTrash: ({ id, folderPath }) => {
//     return MasterConfig.get(`/konnectmailbox/api/Mailbox/MoveEmailToTrash?id=${id}&folderPath=${folderPath}`)
// },
// deleteEmail: (id) => {
//     return MasterConfig.get(`/konnectmailbox/api/Mailbox/deleteemail?id=${id}`)
// },

// }
