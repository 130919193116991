import React, { useEffect, useRef, cloneElement, useState } from "react";
import { Spin } from "antd";

const OnScroll = ({
  isNext,
  loader,
  currentPage,
  onPageChange,
  children,
  parentDivClass,
}) => {
  const listRef = useRef(null);
  const observerRef = useRef(null);
  const [loadingMore, setLoadingMore] = useState(false);
  let current = 0;
  let next = 0;
  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const threshold = 2;
      const isNearBottom = scrollTop + clientHeight + threshold >= scrollHeight;
      if (isNearBottom && isNext && !loader) {
        next = currentPage + 1;
        if (next > current) {
          current = currentPage + 1;
          setLoadingMore(true);
          onPageChange(currentPage + 1);
        }
      }
    }
  };

  useEffect(() => {
    const currentRef = listRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isNext, loader, currentPage]);

  useEffect(() => {
    let timeoutId = null;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && isNext && !loader && !loadingMore) {
          timeoutId = setTimeout(() => {
            setLoadingMore(true);
            onPageChange(currentPage + 1);
          }, 500);
        }
        else if (!loader && !loadingMore){
          observer.disconnect();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isNext, loader , loadingMore]);

  useEffect(()=>{
    !loader && setLoadingMore(false)
    if (!loader && listRef.current) {
      setLoadingMore(false);
      listRef.current.scrollTop -= 100;   // Scroll up a bit after new data is loaded
    }
  },[loader])

  const modifiedChildren = cloneElement(children, {
    // ref: listRef,
    style: {
      ...children.props.style,
      // overflowY: "auto",
      // minHeight: "20vh",
      // maxHeight: "100vh",
    },
  });

  return (
    <div className={`w-full ${parentDivClass} overflow-scroll	`} ref={listRef}>
      {modifiedChildren}
      <div className="w-100 mt-3 mb-1 flex justify-center" ref={observerRef}>
        <Spin spinning={loader} size="large" />
      </div>
    </div>
  );
};

export default OnScroll;
