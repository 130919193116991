import React, { useContext } from "react";
import { Tag } from "antd";
import { AppraisalStatusEnum } from "../../utils/appraisalStatusEnum";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";

const StatusTag = ({ status = AppraisalStatusEnum.InProcess }) => {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { appraisal: statusEnum } = dictionaryList[userLanguage];

    let color = ColorsEnum.Primary;

    switch (status) {
        case AppraisalStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case AppraisalStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case AppraisalStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case AppraisalStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;

        default:
            color = ColorsEnum.Primary;
    }
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {statusEnum.statusEnum[status]}
            </Tag>
        </>
    );
};


export default StatusTag;