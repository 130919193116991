import React, { useContext } from "react";
import { Tag } from "antd";
import {
  TeamOutlined,
  PieChartOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { TaskReferenceTypeEnum } from "../../utils/TaskReferenceTypeEnum";
import { TaskReferenceTypeColor } from "../../utils/TaskReferenceTypeColor";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ReferenceTypeEnum } from "../../../../sharedComponents/reference/utils/referenceTypeEnum";
import { STRINGS, getRouteByType } from "../../../../../utils/base";
import taskIcon from "../../../../../content/svg/menu/newNavBarIcon/Tasks.svg";
import projectIcon from "../../../../../content/svg/menu/newNavBarIcon/Projects.svg";
import groupIcon from "../../../../../content/svg/menu/newNavBarIcon/Groups.svg";
import leadIcon from "../../../../../content/svg/menu/newNavBarIcon/Lead Manager.svg";

const ReferenceTypeTag = ({
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = TaskReferenceTypeEnum.General,
  reference = [],
  iconClassName = "",
  className = ""
}) => {
    const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks } = dictionaryList[userLanguage];
  const { taskReferenceTypeEnum } = tasks;
  let color = TaskReferenceTypeColor?.[referenceType];
  let value =
    reference && reference !== null && reference?.name
      ? reference?.name
      : taskReferenceTypeEnum?.[referenceType];

  let icon = taskIcon;

  switch (referenceType) {
    case TaskReferenceTypeEnum.Project:
      icon = projectIcon;
      break;
    case TaskReferenceTypeEnum.Group:
      icon = groupIcon;
      break;
    case TaskReferenceTypeEnum.Lead:
      icon = leadIcon;
      break;
    default:
      icon = taskIcon;
      break;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      referenceId &&
      referenceId !== STRINGS.DEFAULTS.guid &&
      referenceType !== TaskReferenceTypeEnum.General
    ) {

      let refType;
      switch (referenceType) {
        case TaskReferenceTypeEnum.Project:
          refType = ReferenceTypeEnum.Project;
          break;
        case TaskReferenceTypeEnum.Group:
          refType = ReferenceTypeEnum.Group;
          break;
        case TaskReferenceTypeEnum.Lead:
          refType = ReferenceTypeEnum.Lead;
          break;
        default:
          break;
      }

      let url = refType && getRouteByType(referenceId, refType);
      if (url) {
        window.location.href = url;
      }
    }
  };

  return (
    <>
      <Tag
        icon={<img src={icon} className={`h-[20px] w-[20px] mr-2 ${iconClassName}`}  alt="icon" />}
        color={color}
        onClick={handleClick}
        className={`taskReferenceTypeTag ${className}`}
      >
        {value}
      </Tag>
    </>
  );
};

export default ReferenceTypeTag;
