import Config from "../../../../utils/services/MasterConfig";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import { responseCode as responseCodeEnum } from "../../../../services/enums/responseCode";

const career_data = (data) => {
  return {
    pageNo: data.request.pageNo ? data.request.pageNo : 1,
    pageSize: data.request.pageSize ? data.request.pageSize : 20,
    approverStatus: data.approverStatus ? data.approverStatus : [],
    filterType: data.request.filterType ? data.request.filterType : 0,
    sortBy: data.request.sortBy ? data.request.sortBy : 2,
    search: data.request.search ? data.request.search : "",
  };
};

export const getAllJobBoardService = async (data) => {
  let request = career_data(data);

  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Career/GetAllJobBoard`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const getAuctionDetailForMarketplaceService = (id) => {
  return Config.get(`api/Marketplace/GetAuctionDetailForMarketplace?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
  }
  export const addAuctionSubmitBidService = (payload) =>{
    return Config.post(`api/Marketplace/AddAuctionSubmitBid`,payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
  }
  

  export const getAllAuctionMarketplaceService = (payload) => {
    return Config.post(`api/Marketplace/GetAllAuction`,payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
    
    }