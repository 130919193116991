import { useEffect, useState } from "react";
import { Button, Avatar, DatePicker, Form, Input, Radio, Select } from "antd";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";

import { getAllEmployees } from "../../../../utils/Shared/store/actions";

import { ScheduleTypeEnum } from "../../schedule/enum/enum";

import "../../schedule/styles/style.css";
import { formats, meetingDuration, modules } from "../../schedule/utils";
import {
  RequestForScheduleConfirmationAction,
  addAppointmentByExternal,
} from "./store/action";
import { closeModal } from "./store/slice";
import RichTextEditor from "../../../sharedComponents/RichTextEditor/RichTextEditor";
import { responseCode } from "../../../../services/enums/responseCode";
import OTPModal from "./otpModal";

function CreateSchedule(
  props,
  { scheduleDetail = {}, referenceType, referenceId }
) {
  const [quillError, setQuillError] = useState(false);
  const [subject, setSubject] = useState("");
  const [discription, setDiscription] = useState("");
  const [internalDuration, setInternalDuration] = useState();
  const [RichTextEditorValue, setRichTextEditorValue] = useState("");
  const [loader, setLoader] = useState(false);
  const {
    setIsOTPModalVisible,
    setConfirmationToken,
    slotCreator,
    userData,
    setAppointmentData,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinishFailed = (value) => {
    if (form.getFieldError("description")[0]) {
      setQuillError(true);
      return;
    }
    setQuillError(false);
  };
  const onFinish = (value, props) => {
    let objToSend = value;
    objToSend.description = RichTextEditorValue;
    objToSend.scheduleWith = userData?.userId;
    objToSend.onVideoConference = true;
    if (objToSend.startDate) {
      objToSend.startDate = moment(objToSend.startDate)
        .utc()
        .format(); // convert start date to UTC format
    } else {
      objToSend.startDate = defaultValue;
    }
    if (objToSend.startDate) {
      objToSend.endDate = moment(value.startDate)
        .add(+value.endDate.split(" ")[0], value.endDate.split(" ")[1])
        .add(internalDuration ? internalDuration : duration, "minutes")
        .utc() // convert end date to UTC format
        .format();
      objToSend.startDate = moment(objToSend.startDate)
        .utc()
        .format();
    } else {
      objToSend.endDate = "";
    }

    setLoader(true);

    dispatch(RequestForScheduleConfirmationAction(objToSend)).then(
      (response) => {
        if (response?.payload?.data.responseCode === responseCode.Success) {
          setIsOTPModalVisible(true);
          setConfirmationToken(response.payload.data.data);
          dispatch(closeModal(false));
          setAppointmentData({
            email: value.email,
            appointmentWith: userData?.username,
            dateTime: moment(objToSend.startDate).format(
              "MMMM D, YYYY | h:mm A"
            ),
          });
        }
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    if (Object.keys(scheduleDetail).length > 0) {
      const startD = moment(scheduleDetail.startDate);
      const endD = moment(scheduleDetail.endDate);

      form.setFieldsValue({
        ...scheduleDetail,
        startDate: startD,
        endDate: "gvahsgcx",
      });
    }
  }, [scheduleDetail]);
  const defaultValue = moment(props.selectedStartTime, "YYYY-MM-DD HH:mm");
  const duration = props.durationInMinutes.toString();

  return (
    <>
      <div className="createSchedule">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            description: "",
            scheduleType: ScheduleTypeEnum.Meeting,
            endDate: `${duration} min`,
            travelTime: 0,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="subject"
            label={"Subject:"}
            onChange={(e) => setSubject(e.target.value)}
            rules={[{ required: true, message: "Subject is required" }]}
          >
            <Input placeholder="Write Subject"></Input>
          </Form.Item>
          <Form.Item
            name="email"
            label={"Email:"}
            //   direction={Direction}
            rules={[
              {
                required: true,
                message: "Email is required",
                type: "email",
              },
            ]}
          >
            <Input placeholder={"Enter the Email Address"} />
          </Form.Item>

          <Form.Item name="description" label={"Description:"}>
            <RichTextEditor
              setRichTextEditorValue={setRichTextEditorValue}
              placeholderValue="Write your description here..."
            />
          </Form.Item>

          <div className="formInput w-50">
            <Form.Item
              label={"Start Date & Time:"}
              name="startDate"
              rules={[
                {
                  required: defaultValue ? false : true,
                  message: "Date & Time is required",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                defaultValue={defaultValue}
                showTime={{ format: "HH:mm" }}
                placeholder="Select Date & Time"
                utc={true}
              />
            </Form.Item>
            <Form.Item
              label={"Duration:"}
              name="endDate"
              rules={[{ required: true, message: "Duration is required" }]}
            >
              <Select
                name={internalDuration}
                onSelect={(e) => setInternalDuration(e)}
                defaultValue={duration}
                options={meetingDuration}
              ></Select>
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              type="primary"
              size="large"
              className="ThemeBtn"
              block
              htmlType="submit"
              loading={loader}
            >
              {"Create Schedule"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default CreateSchedule;
