import MasterConfig from "../../../../utils/services/MasterConfig";
export const getBusinessAssociationService = (data) => {
	return MasterConfig.post(`api/ExternalAssociation/GetAllExternalBusinessAssociation`,data)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addBusinessAssociationService = args => {
	return MasterConfig.post(`api/ExternalAssociation/AddExternalBusinessAssociation`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const updateBusinessAssociationService = args => {
	return MasterConfig.put(`api/BusinessAssociation/UpdateBusinessAssociation`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
