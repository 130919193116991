import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseCode } from '../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';
import { ResponseType } from "../../../../utils/api/ResponseResult";
// import {
//     getAllCustomTagByIdService,
//     addCustomTagService,
//     updateCustomTagService,
//     removeCustomTagService,
//     addCustomTagMemberService,
//     getAllCustomTagMemberService,
//     getAllCustomTagService,
//     removeCustomTagMemberService,
// } from '../services/service';
import {addCustomMember , deleteCustomTagMember } from "./slice";
import { message } from 'antd';
import { AssignProjectAccessRoleToMemberService, AssignProjectRoleToMemberService, addCustomTagMemberService, addCustomTagService, addProjectRoleMemberService, addProjectRoleService, getAllCustomTagMemberService, getAllCustomTagService, getAllProjectRoleService, removeCustomTagMemberService, removeCustomTagService, removeProjectRoleService, updateCustomTagService, updateProjectRoleService } from '../service/service';

// export const getAllCustomTag = createAsyncThunk(
//   "customtag/getAllCustomTag",
//   async (data) => {
//     console.log(data);
//     const response = await getAllCustomTagService(data);
//     if (!response.responseCode) {
//       message.error("Something went wrong");
//     }
//     return response.data;
//   }
// );
// export const getAllCustomTagMember = createAsyncThunk(
//   "getMember",
//   async (id, { dispatch }) => {
//     const res = await getAllCustomTagMemberService(id);
//     if (res.responseCode) {
//       if (res.responseCode === responseCode.Success)
//         responseMessage({ dispatch, data: res });
//     } else {
//       responseMessage({
//         dispatch: dispatch,
//         type: responseMessageType.ApiFailure,
//       });
//     }
//     return res;
//   }
// );




// export const deleteCustomTagMemberAction = createAsyncThunk(
//   "deleteCustomTagMemberAction",
//   async (data, { dispatch }) => {
//     const res = await removeCustomTagMemberService(data);
//     if (res.responseCode) {
//       if (res.responseCode === responseCode.Success)
//         dispatch(deleteCustomTagMember(data));
//       return data.memberId;
//     } else {
//       responseMessage({
//         dispatch: dispatch,
//         type: responseMessageType.ApiFailure,
//       });
//     }
//     return res;
//   }
// );





export const updateProjectRole = createAsyncThunk(
  'ProjectRole/updateProjectRole',
  async (args, { dispatch }) => {
    const res = await updateProjectRoleService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'ProjectRole updated successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);
export const removeProjectRole = createAsyncThunk(
  'ProjectRole/removeProjectRole',
  async (args, { dispatch }) => {
    const res = await removeProjectRoleService(args.id);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Project Role removed successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addProjectRole = createAsyncThunk(
  'ProjectRole/addProjectRole',
  async (args, { dispatch }) => {
    const res = await addProjectRoleService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Project Role added successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);
export const addProjectRoleMemberByRollId = createAsyncThunk(
  "addProjectRoleMemberS",
  async (data, { dispatch }) => {
    const res = await addProjectRoleMemberService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        // dispatch(addCustomMember(res.data));
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res.data;
  }
);


export const getAllProjectRoleById = createAsyncThunk(
  'getAllProjectRole/getAllProjectRoleById',
  async (id, { dispatch }) => {
    const res = await getAllProjectRoleService(id);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);


export const AssignProjectRoleToMember = createAsyncThunk(
  "AssignProjectRoleToMember",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AssignProjectRoleToMemberService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Roles updated successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);


