import { Mentions } from "antd";
import React, { useEffect, useRef, useState } from "react";
import MentionUserItem from "../ListItem/MentionUserItem/MentionUserItem";
import { STRINGS } from "../../../utils/base";
import { GetAllSuggestionAction } from "../SuggestionBox/store/action";
import { useDispatch } from "react-redux";
import { responseCode  as resCode} from "../../../services/enums/responseCode";
import { SuggestionTypeEnum } from "../SuggestionBox/utils/SuggestionTypeEnum";
import { prepareSuggestionTypes } from "../../features/Comment/utils/prepareSuggestionTypes";
function CustomMentions({
  placeholder,
  value,
  onChange,
  onSelect,
  initialMentions = [],
  isOpen,
  referenceId = STRINGS.DEFAULTS.guid,
  module,
  referenceType = SuggestionTypeEnum.Employee,
  autoSize = false,
  ...props
}) {

  const [defaultPayload , setdefaultPayload] = useState({
    pageNo:1,
    pageSize:20,
    search:"",
    types: prepareSuggestionTypes(module, referenceType),
    notRequired:[],
    removeSelf:true,
    referenceId:referenceId,
  })

  const [data, setData] = useState([]);
  const dispatch = useDispatch()
  const loadEmployeeData = (payload) => {
    
    dispatch(GetAllSuggestionAction(payload)).then(response =>{
      const {responseCode , data } = response?.payload;
      if(responseCode === resCode.Success)
      {
        setData(data)
      }
    })
      
  };
 
  const onSearch = (search) => {
    const payload = {
      ...defaultPayload, search:search
    } 
    loadEmployeeData(payload);
  };

  useEffect(() => {
    loadEmployeeData(defaultPayload)
  }, []);

  let myRef = useRef()

  useEffect(() => {
    if (isOpen)
      myRef.current.focus()
  }, [])
  return (
    <Mentions
      {...props}
      rows={props.row || 4}
      autoSize={autoSize}
      placeholder={placeholder}
      value={value}
      // loading={loading}
      onSearch={onSearch}
      onChange={(value) => onChange(value)}
      onSelect={(e) => onSelect(e)}
      ref={myRef}
    >
      {data.map(({ id, image, name, designation }) => (
        <Mentions.Option key={id} value={name}>
          <MentionUserItem
            avatar={image}
            name={name}
            designation={designation}

          />
        </Mentions.Option>
      ))}
    </Mentions>
  );
}

export default CustomMentions;
