import React from "react";
const PaymentCard = () => {
  return (
    <>
      <div className="flex justify-center items-center h-full text-2xl font-bold">
        Coming Soon!!
      </div>
    </>
  );
};
export default PaymentCard;
