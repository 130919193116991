import { message } from "antd";
import { responseCode } from "../../../../../services/enums/responseCode";
import { jsonToFormData } from "../../../../../utils/base";
import MasterConfig from "../../../../../utils/services/MasterConfig";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../../utils/api/ResponseResult";
const API_PREFIX = "api/Approval/";

const getAllApproval_dto = (data) => {
  return {
    pageNo: data.filter.pageNo ? data.filter.pageNo : 1,
    pageSize: data.filter.pageSize ? data.filter.pageSize : 20,
    search: data.filter.search ? data.filter.search : "",
    status: data.filter.status ? data.filter.status : "",
    modules: data.filter.modules ? data.filter.modules : [],
  };
};
export const saveApprovalsRemarks = async (remark) => {
  const data = jsonToFormData(remark);
  const response = await MasterConfig.post(`${API_PREFIX}AddRemark`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
  if (response.responseCode !== responseCode.Success) {
    message.error("Something went wrong");
  }
  return response.data;
};

export const addApprovalService = async (data) => {
  let module = data.module;
  let referenceId = data.referenceId.toString();
  let dataObject = data.approverId
  ;
  return MasterConfig.post(
    `api/Approval/AddApproval?referenceId=${referenceId}&module=${module}`,
    dataObject
  )
    .then((res) => {
      return res.data;
    })
    .catch((res) => {
      return res;
    });
};
export const getAllApprovalByReferenceIdService = async (payload) => {
  const referenceId = payload.referenceId;
  const module = payload.module;
  return MasterConfig.get(
    `api/Approval/GetAllApprovalByReferenceId?referenceId=${referenceId}&module=${module}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((res) => {
      return res;
    });
};


export const AddApprovalReminderService = (data) => {
  return MasterConfig.post(`api/Approval/AddApprovalReminder?approvalId=${data.approvalId}&module=${data.module}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const getAllApprovalReminderService = (data) => {
  return MasterConfig.get(`api/Approval/GetAllApprovalReminder?approvalId=${data.approvalId}&module=${data.module}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeApprovalService = ({id , module}) =>{

  return MasterConfig.get(`api/Approval/RemoveApproval?id=${id}&module=${module}`)
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    return err;
  });
};
