import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";

import {
  addGroup,
  getAllGroup,
  getGroupById,
  updateGroup,
  addGroupMemberAction,
  getAllGroupMemberAction,
  deleteGroupMemberAction,
  addGroupFeatures,
  getGroupFeatures,
  addGroupTag,
  removeGroupFeaturesAction,
  getAllGroupTag,
  addGroupTagMember,
  updateGroupTag,
  removGroupTag,
  getAllGroupPagination
} from "./actions";

const initialState = {
  isAdminPresent: false,
  groups: [],
  groupTag: [],
  groupMembers: [],
  loadingData: false,
  loader: false,
  createLoader: false,
  groupDetail: null,
  grouptagMembers: null,

  success: false,
  error: false,
  getDataLoading: false,
  memberData: [],
  isComposerOpen: false,
  isEditComposer: false,
  addMemberModal: false,
  open: false,
  drawerOpen: false,
  removeMemberSucess: false,
  groupFeatures: [],
  previousGroupDetail: {},
  totalRecords: [],
  recordSize: 0,
};

const groupSlice = createSlice({
  name: "groupSlice",
  initialState,
  reducers: {
    checkIsAdminPresent: (state, { payload }) => {
      state.isAdminPresent = payload;
      console.log(state.isAdminPresent, "stateAdminPresentGroup");

    },
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    resetGroupDetail(state, { payload }) {
      state.groupDetail = null;
    },
    handleReset(state, { payload }) {
      state.groups = []
      state.totalRecords = []
    },
    getGroupDetailById(state, { payload }) {
      state.groupDetail = state.groups.find((list) => list.id === payload);
    },
    handleComposer(state, { payload }) {
      const { isOpen, isEdit } = payload;
      state.isEditComposer = isEdit;
      state.isComposerOpen = isOpen;
    },
    addMember: (state, { payload }) => {
      state.addMemberModal = payload;
    },
    addGroupMember: (state, { payload }) => {
      const newGroups = state.groups.map((item, i) => {
        if (item.id === payload[0].groupId) {
          let members = [...item.members, payload[0]];
          let newItem = {
            ...item,
            members,
          };

          return newItem;
        } else {
          return item;
        }
      });
      state.groups = newGroups;
    },
    deleteGroupMember(state, { payload }) {
      const deleteGroupMembers = state.groups.map((item, i) => {
        if (item.id === payload.id) {
          let delMember = item?.members.filter(
            (member) => member.memberId !== payload.memberId
          );
          let deleteItem = {
            ...item,
            members: delMember,
          };
          return deleteItem;
        } else {
          return item;
        }
      });
      state.groups = deleteGroupMembers;
    },
    handleFavoriteMark(state, { payload }) {
      const favGroups = state.groups.find((doc) => doc.id === payload.id);
      favGroups.isPinnedPost = !favGroups.isPinnedPost;
    },
    removeGroupFeatures(state, { payload }) {
      state.groupFeatures = state.groupFeatures.filter(
        (feature) => feature.featureId !== payload.featureId
      );
    },

    DeleteGroupTag: (state, { payload }) => {
      state.groupTag = state.groupTag.filter((e) => e.id !== payload.id);
    },

    GroupTagMember: (state, { payload }) => {
      const groupTagMember = state.groupTag.map((item, i) => {
        if (item.id === payload[0].groupTagId) {
          let members = [...item.members, payload[0]];
          let newItem = { ...item, members };
          return newItem;
        } else {
          return item;
        }
      });
      state.groupTag = groupTagMember;
    },

    deleteGroupTagMember(state, { payload }) {
      const deleteGroupTagMembers = state.groupTag.map((item, i) => {
        if (item.id === payload.id) {
          let delMember = item.members.filter(
            (member) => member.memberId !== payload.memberId
          );
          let deleteItem = {
            ...item,
            members: delMember,
          };
          return deleteItem;
        } else {
          return item;
        }
      });
      state.groupTag = deleteGroupTagMembers;
    },
    handleNotificationNewItem: (state, { payload }) => {

      try {
        const groups = state.groups.map((item, i) => {
          if (item.id === payload.parentReferenceId) {
            let updatedItem = {
              ...item,
              notificationCount: item.notificationCount + 1,
            };
            return updatedItem;
          } else {
            return item;
          }
        });
        state.groups = groups;
      }
      catch (e) {
        console.log("===error===", e)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGroup.fulfilled, (state, action) => {
        const { pageNo } = action.meta.arg;
        const data = action.payload.data;
        if (pageNo === 1) {
          state.groups = data;
        } else if (pageNo > 1) {
          state.groups = [...state.groups, ...data];
        }
        state.loader = false;
        state.success = true;
        // state.groups = payload ? payload.data : [];
        // var t = [];
        // payload.data.forEach((element, index) => {
        //   console.log(element);
        //   t[element.id] = element.members;
        // });
        // state.groupMembers = t;
        // state.success = true;
        // state.loader = false;
      })
      .addCase(getAllGroupPagination.fulfilled, (state, { payload }) => {
        state.success = true;
        state.loader = false;
        state.groups = payload.data.data;
        state.totalRecords = payload.data.totalRecords;
      })
      .addCase(addGroup.fulfilled, (state, { payload }) => {
        state.groups = [payload.data, ...state.groups];
        state.drawerOpen = false;
        state.createLoader = false;
        return state;
      })
      .addCase(getGroupById.fulfilled, (state, { payload }) => {
        state.groupDetail = payload.data;
        state.loadingData = false;
        state.success = true;
      })
      .addCase(updateGroup.fulfilled, (state, { payload }) => {
        let newData = payload.data;
        const index = state.groups.findIndex(
          (group) => group.id === newData.id
        );
        state.groupDetail = {
          ...state.groupDetail,
          name: newData.name,
          description: newData.description,
          privacyId: newData.privacyId,
          image: newData.image,
        };
        state.groups[index] = state.groupDetail;
        state.isComposerOpen = false;
        state.createLoader = false;
        state.success = true;
        return state;
      })
      .addCase(addGroupMemberAction.fulfilled, (state, { payload }) => {
        if (state.groupDetail) {
          if (payload.data?.length) {
            let newMembers = [...state.groupDetail.members, ...payload.data];
            state.groupDetail = {
              ...state.groupDetail,
              members: newMembers,
            };
          }
        }
        console.log(state.groupDetail, "GroupDetailsGroupDetails");
      })

      // .addCase(addGroupTagMember.fulfilled, (state, { payload }) => {
      //   console.log(state.grouptagMembers,"stategrouptagMembers");
      //   if (state.grouptagMembers) {
      //     if (payload[0]?.length) {
      //       let newMembers = [...state.grouptagMembers.members, payload[0]];
      //       state.grouptagMembers = {
      //         ...state.grouptagMembers,
      //         member: newMembers,
      //       };
      //     }
      //   }
      // })

      .addCase(updateGroupTag.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.groupTag = state.groupTag.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })

      .addCase(addGroupTag.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.groupTag.push(payload);
        // state.projectTag = payload.data?payload.data:[];
      })
      .addCase(getAllGroupTag.fulfilled, (state, { payload }) => {
        state.groupTag = payload ? payload : [];
        state.loader = false;
      })

      // .addCase(addGroupTagMember.fulfilled, (state, { payload }) => {
      //   console.log(payload, "payloooood");
      //   if (payload[0].length > 0) {
      //     state.memberData = [...state.memberData, payload[0]];
      //   }
      //   return state;
      // })

      .addCase(getAllGroupMemberAction.fulfilled, (state, { payload }) => {
        state.memberData = payload.data.length > 0 ? payload.data : [];
      })
      .addCase(deleteGroupMemberAction.fulfilled, (state, action) => {
        const {id , memberId} = action.meta.arg

        if(state.groupDetail)
        {
          let newMembers = state.groupDetail.members.filter(
            (member) => member.memberId !== memberId
          );
          state.groupDetail = { ...state.groupDetail, members: newMembers };
        }
      })
      .addCase(addGroupFeatures.fulfilled, (state, { payload }) => {
        // state.groupFeatures = payload.data.length > 0 ? payload.data : [];
        state.groupFeatures = payload.data;
      })
      .addCase(getGroupFeatures.fulfilled, (state, { payload }) => {
        console.log(payload, "payloadd");

        state.groupFeatures = payload.data;
      })
      .addCase(removeGroupFeaturesAction.fulfilled, (state, { payload }) => { })
      .addMatcher(isPending(...[deleteGroupMemberAction]), (state) => {
        state.removeMemberSucess = false;
      })
      .addMatcher(isPending(...[getAllGroup]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getGroupById]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(
        isPending(...[addGroup, addGroupTag, updateGroup]),
        (state) => {
          state.createLoader = true;
          // state.success = false;
          // state.error = false;
        }
      )
      .addMatcher(isRejected(...[getAllGroup]), (state) => {
        state.loader = true;
      })
      .addMatcher(
        isRejected(...[addGroup, updateGroup, addGroupTag]),
        (state) => {
          state.createLoader = false;
          state.success = false;
          state.error = false;
        }
      )
      .addMatcher(isRejected(...[deleteGroupMemberAction]), (state) => {
        state.removeMemberSucess = false;
      })
      .addMatcher(isRejected(...[getGroupById]), (state) => {
        state.loader = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const {
  checkIsAdminPresent,
  resetGroupDetail,
  getGroupDetailById,
  handleComposer,
  addMember,
  deleteGroupMember,
  addGroupMember,
  handleOpenComposer,
  handleFavoriteMark,
  removeGroupFeatures,
  DeleteGroupTag,
  addgroupTagMember,
  GroupTagMember,
  deleteGroupTagMember,
  handleNotificationNewItem,
  handleReset
} = groupSlice.actions;
export default groupSlice.reducer;
