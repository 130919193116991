import { Modal, Tag } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { getIconByFeaturesType } from "../../../../../utils/Shared/helper/helpers";
import {
  ApprovalStatus,
  ApprovalsModule,
  getStatusLabelAndColor,
} from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { getFeaturesTypeByApprovalsType } from "../../../../sharedComponents/AppComponents/Approvals/helper/helpers";
import { ApprovalDictionary } from "../../../../sharedComponents/AppComponents/Approvals/localization";
import Avatar from "../../../../sharedComponents/Avatar/avatarOLD";
import "../style.css";
import ApprovalActions from "./approvalActions";
import ApprovalDetailedView from "./detail";
import { readCounterforApproval } from "../../store/slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ApprovalDetailPage from "./approvalDetailPage";
import ReminderWrapper from "../../../../sharedComponents/AppComponents/Approvals/components/reminderWrapper";
import { dictionaryList } from "../../../../../utils/localization/languages";

export default function ApprovalItem({
  item,
  onDClose,
  handleApprovalDetail = (e) => false,
  detail = false,
}) {
  let { creator, updateDate, message, referenceNo } = item;
  let notiTime = moment
    .utc(updateDate)
    .local()
    .fromNow();

  const { userLanguage } = useContext(LanguageChangeContext);
  const { status: statusLabels } = dictionaryList[userLanguage];
  // const { status: statusLabels } = ApprovalDictionary[userLanguage];
  const { label, color } = getStatusLabelAndColor("", statusLabels)[
    item.status
  ];
  const [approvalDetailData, setApprovalDetailData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const handleItemClose = (key) => {
    // Call the callback function from Comp sideBarApproval
    onDClose(key);
  };

  const { user } = useSelector((state) => state.userSlice);

  const handleCancel = () => {
    setIsOpenModal(false);
  };
  const handleOpenApprovalDetail = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setApprovalDetailData(item);
    setIsOpenModal(true);
    if (item) {
      dispatch(readCounterforApproval(item));
    }
  };

  return (
    <>
      <div
        className={`approval_item cursor-pointer ${
          item.notificationCount > 0 ? "unreadNotifyItem" : ""
        }`}
        onClick={
          !detail
            ? (e) => handleOpenApprovalDetail(item, e)
            : () => handleApprovalDetail(item)
        }
      >
        <div>
          <Avatar
            src={creator?.image}
            name={creator?.name}
            size={35}
            round={true}
            // active={true}
          />
        </div>
        <div className="approval_item_detail">
          <div className="approval_item_detail_child1">
            <span className="approval_creator">{creator?.name}</span>
            <span className="approval_message">{message}</span>
          </div>
          <div className="approval_item_detail_child2">
            <div className="dateTime">
              <div className="shortDesc">{notiTime}</div>
              <div className="shortDesc">{referenceNo}</div>
            </div>
            <div>
              <div className="statusHolder flex gap-2 justify-end">
                <ReminderWrapper
                  approversId={item?.approverId}
                  reminderCount={item?.reminderCount}
                  approvalId={item?.id}
                  status={item?.status}
                  lastReminder={item?.lastReminder}
                  module={item?.module}
                  showLastReminderText={false}
                  reference={item?.referenceId}
                />
                <div className="featureIcon">
                  <img
                    src={getIconByFeaturesType(
                      getFeaturesTypeByApprovalsType(item.module)
                    )}
                  />
                </div>
              </div>
              <div>
                {item.approverId === user.id &&
                  item.status === ApprovalStatus.InProcess && (
                    <ApprovalActions item={item} onDClose={handleItemClose} />
                  )}
              </div>
            </div>

            {item.status !== ApprovalStatus.InProcess && (
              <div className="approval_status_tag">
                <Tag style={{ background: color }}>{label}</Tag>
              </div>
            )}
          </div>
        </div>
      </div>
      {!detail && isOpenModal && (
        <Modal
          title={null}
          visible={isOpenModal}
          footer={null}
          closable={false}
          onCancel={handleCancel}
          className="modal-body"
          width={700}
        >
          <ApprovalDetailedView approvalDetailData={approvalDetailData} />
        </Modal>
      )}
      {/* {approvalDetailData?.module === ApprovalsModule.CareerApproval && <ApprovalDetailPage approvalDetailData={approvalDetailData} />} */}
    </>
  );
}
