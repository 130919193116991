import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import { GetAllProjectIssues, addProjectIssues } from "./action.js";

const initialState = {
  data: null,
  newItem: null,
};

const ProjectIssuesSlice = createSlice({
  name: "ProjectIssuesSlice",
  initialState,
  reducers: {
    handleMemberRealTime:(state , {payload})=>{
        const { identifier } = payload;

    state.data[identifier]  = state.data[identifier]?.map((x) => {
    if (x.id === payload.id) {
      return { ...x, members: payload.member };
    }
    return x;
  });
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined") delete state?.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    // handleUpdateMembers: (state, { payload }) => {
    //   const { identifier, referenceId, members } = payload;
    //   if (typeof state.data !== "undefined" && state.data[identifier]) {
    //     state.data[identifier] = state.data[identifier].map((item) => {
    //       // If the item's id matches the referenceId, update its members
    //       if (item.id === referenceId) {
    //         return {
    //           ...item,
    //           members: members,
    //         };
    //       }
    //       return item;
    //     });
    //   }
    // },
    // readCountforPages: (state, { payload }) => {
    //   const id = payload;
    //   const currentState = current(state);
    //   try {
    //     if (currentState.data !== null) {
    //       const keys = Object.keys(currentState.data);
    //       let updatedState = { ...currentState.data };

    //       keys.forEach((identifier) => {
    //         const current = currentState.data[identifier];
    //         const index = current.findIndex((y) => y.id === id);
    //         if (index !== -1) {
    //           const updatedCurrent = [...current];
    //           let referenceItem = updatedCurrent[index];

    //           referenceItem = {
    //             ...referenceItem,
    //             notificationCount: 0,
    //           };
    //           updatedCurrent[index] = referenceItem;

    //           updatedState = {
    //             ...updatedState,
    //             [identifier]: updatedCurrent,
    //           };
    //         }
    //       });

    //       state.data = updatedState;
    //       state.newCountId = id;
    //     }
    //   } catch (e) {}
    // },
    // handleNotificationNewItem: (state, { payload }) => {
    //   try {
    //     const currentState = current(state);
    //     if (currentState.data !== null) {
    //       const keys = Object.keys(currentState.data);
    //       let updatedState = { ...currentState.data };

    //       keys?.forEach((identifier) => {
    //         const current = currentState.data[identifier];
    //         const index = current.findIndex((y) => y.id === payload.featureId);
    //         if (index !== -1) {
    //           const updatedCurrent = [...current]; // Create a deep copy of the current array
    //           let referenceItem = updatedCurrent[index];
    //           referenceItem = {
    //             ...referenceItem,
    //             notificationCount: referenceItem.notificationCount + 1,
    //           };

    //           updatedCurrent[index] = referenceItem;

    //           updatedState = {
    //             ...updatedState,
    //             [identifier]: updatedCurrent,
    //           };
    //         }
    //       });
    //       state.data = updatedState;
    //     }
    //   } catch (e) {
    //     console.log("===error===customApproval", e);
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllProjectIssues.fulfilled, (state, action) => {
        const { identifier, filter } = action?.meta?.arg;
        console.log(action ,"action")
        const data = action.payload?.data ?? [];
        state.data = { ...state.data, [identifier]: data };
      })
      .addCase(addProjectIssues.fulfilled, (state, { payload }) => {
        console.log(payload ,"ololololololo");
        state.newItem = payload.data?.data;
        console.log(state.newItem ,"state.newItem");
      })
    // .addCase(GetProjectIssuesById.fulfilled, (state, action) => {
    //     const { identifier } = action?.meta?.arg;
    //     const data = action.payload.data ?? [];
  
    //     state.data = {
    //       ...state.data,
    //       [identifier]: [data],
    //     };
    //   });
  },
});

export const {
  removeIdentifier,
  handleNewItem,
  handleUpdateMembers,
  readCountforPages,
  handleNotificationNewItem,
  handleMemberRealTime
} = ProjectIssuesSlice.actions;
export default ProjectIssuesSlice.reducer;
