import React, { useContext } from "react";
import Tab from "../../../../sharedComponents/Tab";
import QuestionList from "../UI/questionList";
import TaskList from "../UI/TaskList";
import { STRINGS } from "../../../../../utils/base";
import BonusDetail from "../../../bonus/view/UI/detailCardItem";
import SalaryDetail from "../../../salary/view/Page/Detail/DetailItem/salaryDetail";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { FeaturePermissionEnum } from "../../../../../utils/Shared/enums/featuresEnums";
import { useSelector } from "react-redux";

function DetailTabs({
  questions,
  tasks,
  RemarksApproval,
  bonusId,
  salaryId,
}) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { appraisal } = dictionaryList[userLanguage];
  const { user } = useSelector((state) => state.userSlice);
  const userPermissions = user.permissions; 

  //TODO: approvers will be added when we will get approvers from the backend
  const panes = [
    {
      featureId: 0,
      featureName: appraisal.Approvers,
      content: RemarksApproval,
      featurePermissionEnum : false
    },
    {
      featureId: 1,
      featureName: appraisal.Questions,
      content: <QuestionList questions={questions} />,
      featurePermissionEnum : false
    },
    {
      featureId: 2,
      featureName: appraisal.Tasks,
      content: <TaskList tasks={tasks} />,
      featurePermissionEnum : false
    },
    ...(bonusId !== STRINGS.DEFAULTS.guid
      ? [
        {
          featureId: 3,
          featureName: appraisal.Bonus,
          content: (
            <BonusDetail id={bonusId} />
          ),
          featurePermissionEnum : FeaturePermissionEnum.ViewBonus
        },
      ]
      : []),
    ...(salaryId !== STRINGS.DEFAULTS.guid
      ? [
        {
          featureId: 4,
          featureName: appraisal.Increment,
          content: (
            <SalaryDetail id={salaryId} />
          ),
          featurePermissionEnum : false
        },
      ]
      : []),
  ];
  return (
    <div className="detailTabs">
      <Tab panes={panes.filter(
        (item) =>
          userPermissions.includes(item.featurePermissionEnum) ||
          !item.featurePermissionEnum
      )} />
    </div>
  );
}

export default DetailTabs;
