import React, { Children, useEffect, useState } from 'react';
import { Modal, Button } from 'antd';

const BasicModal = ({
  isOpen = false,
  footer = {},
  title = "",
  children = "",
  onSave = () => { },
  onCancel = () => { },
  footerRequired = true

}) => {

  const [isModalVisible, setIsModalVisible] = useState(isOpen);

  const { hasFooter = true, footerButtons = [] } = footer;
  useEffect(() => {
    setIsModalVisible(isOpen);
  }, [isOpen])



  const handleOk = () => {
    onSave();
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsModalVisible(false);
  };
  // Define a style object to add padding
  const bodyStyle = {
    padding: '24px',
    fontSize: '14px',
    lineHeight: '1.5715',
    wordWrap: 'break-word',
  };

  return (
    <>
      <Modal title={title} open={isModalVisible} onCancel={()=>{handleCancel()}} closable={false} bodyStyle={bodyStyle}
       footer={footerRequired ? [
        <Button key="cancel" size="medium" className="ThemeBtn" onClick={handleCancel}>Cancel</Button>,
        <Button key="save" type="primary" size="medium" className="ThemeBtn" onClick={handleOk}>Save</Button>
      ] : null}
      >
        {children}
      </Modal>
    </>
  );
};
export default BasicModal;