import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";

import {
  addTravelService,
  getAllTravelService,
  getTravelByIdService,
  getAllTravelPagingService,
  UpdateTravelService,
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { message } from "antd";

export const addTravel = createAsyncThunk(
  "addTravel",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addTravelService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Travel Created Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);
export const getAllTravel = createAsyncThunk(
  "getAllTravel",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllTravelService(data);
    console.log(res, "responsee");
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);
export const getTravelById = createAsyncThunk(
  "getTravelById",
  async (data, { dispatch, getState }) => {
    const res = await getTravelByIdService(data?.id || data);

    if (res?.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
  }
);

export const getAllTravelPaging = createAsyncThunk(
  "getAllTravelPaging",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllTravelPagingService(data);
    console.log(res, "responsee");
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);

export const getAllTravelAction = createAsyncThunk("travel/getAllTravelAction", async ({filter,segmentType = SegmentTypeEnum.Grid,identifier},{ dispatch, getState, rejectWithValue }) => {
 
  if(segmentType === SegmentTypeEnum.List){
    const response = await getAllTravelPagingService(filter);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
  else
  {
    const response = await getAllTravelService(filter);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
});

export const UpdateTravelAction = createAsyncThunk(
  "updateTravel",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await UpdateTravelService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);
