import SideDrawer from "../../../../../sharedComponents/Drawer/SideDrawer";
import React, { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { useDispatch } from "react-redux";
import DuplicateDocumentComposer from "./composer";
import { DocumentTypeEnum } from "../../../utils/DocumentTypeEnum";

function DuplicateDocumentComposerDrawer({ item, isOpen, handleOnClose }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, document } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const title = (type) => {
    switch (type) {
      case DocumentTypeEnum.Folder:
        return document.newFolder;
      case DocumentTypeEnum.Grid:
        return sharedLabels.createGrid;
      case DocumentTypeEnum.Pad:
        return sharedLabels.createPad;
      case DocumentTypeEnum.Slide:
        return sharedLabels.createSlide;
      case DocumentTypeEnum.Board:
        return sharedLabels.createBoard;
      case DocumentTypeEnum.MindMap:
        return sharedLabels.createMindmap;
      case DocumentTypeEnum.Attachment:
        return sharedLabels.upload;
      default:
        return "";
    }
  };

  return (
    <div>
      {isOpen && (
        <SideDrawer
          title={
            <>
              {title(item.documentType)} <span className="text-[13px]">(Copy)</span>
            </>
          }
          isDisable={true}
          isOpen={isOpen}
          isAccessDrawer={false}
          handleClose={handleOnClose}
          children={
            <DuplicateDocumentComposer item={item} OnClose={handleOnClose} />
          }
        />
      )}
    </div>
  );
}
export default DuplicateDocumentComposerDrawer;
