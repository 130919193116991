import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
function CountryPhoneInput({
  name,
  type,
  placeholder,
  onChange,
  value,
  error,
  children,
  label,
  country,
  reset,
  inputStyle,
  buttonStyle,
  dropdownStyle,
  dropdownClass,
  searchStyle,
  disabled,
  disableDropdown,
  className, 
  autoFocus = true,   
  ...props
}) {
  const [state, setState] = useState(value || "");
  const [validationError, setValidationError] = useState("");
  const phoneInputRef = useRef(null);

  const handleChange = (value, country, e, formattedValue) => {
    setState(value);
    console.log(country);
    onChange(value, (e?.target?.name || "countryCode"));
    if (value.trim() === "") {
      setValidationError(""); // Clear validation error if the input is empty
    } else if (isValidPhoneNumber(value, country.countryCode.toUpperCase())) {
      setValidationError(""); // Clear validation error if the phone number is valid
    } else {
      setValidationError("Invalid phone number"); // Set validation error if the phone number is invalid
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      phoneInputRef.current.focus();
    }
  };

  const handleFocus = () => {
    phoneInputRef.current.openDropdown();
  };

  useEffect(() => {
    setState(value || "");
  }, [value, reset]);

  // Determine whether to show validation error based on context
  const shouldShowValidationError = !props.disableValidationInCard && !!validationError;
  return (
    <>
      <PhoneInput
        ref={phoneInputRef}
        country={country}
        value={state}
        name={name}
        enableAreaCodes={true}
        inputStyle={inputStyle}
        buttonStyle={buttonStyle}
        dropdownStyle={dropdownStyle}
        dropdownClass={dropdownClass}
        searchStyle={searchStyle}
        disableDropdown={disableDropdown}
        inputProps={{
          name: "phoneNo",
          required: true,
          autoFocus: autoFocus,
          disabled: disabled,
        }}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={(e)=>handleKeyDown(e)}
        onFocus={()=>handleFocus()}
      />
      {shouldShowValidationError && <p style={{ color: "red" }}>{validationError}</p>}
      {error && <p>{error}</p>}
    </>
  );
}

export default CountryPhoneInput;
CountryPhoneInput.defaultProps = {
  type: "number",
  className: "",
  disableValidationInCard: false, // Default to false
};

CountryPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  country: PropTypes.string,
  reset: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  inputStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  dropdownStyle: PropTypes.object,
  dropdownClass: PropTypes.object,
  searchStyle: PropTypes.object,
  disabled: PropTypes.bool,
  disableValidationInCard: PropTypes.bool, // New prop type
};
