export const PrepareApprovalsByNewRemark=({remark,approversData})=>{

    try {
        console.log(remark,approversData,"PrepareApprovalsByNewRemark_INPUT");

        let approvers = [...approversData];
        let approval = approvers.find((x) => x.id === remark.approvalId);
        console.log(approval,"PrepareApprovalsByNewRemark_approval");

        const index = approvers.findIndex((x) => x.id === remark.approvalId);
        console.log(index,"PrepareApprovalsByNewRemark_index");

       
        let remarks = [...approval.remarks]
        
        remarks  = remarks.filter(x => x.id !== remark.id)

        remarks = [...remarks,remark];
        
        console.log(remarks,"PrepareApprovalsByNewRemark_remark2222");

        /*approval.remarks = remarks;
        console.log(approval,remarks,"PrepareApprovalsByNewRemark_remark");*/

        console.log(approval,"PrepareApprovalsByNewRemark_status");
        let updatedApproval = {
            ...approval,
            remarks: remarks,
        };
        const distinctStatus = Array.from(new Set(remarks.map((item) => item.status)));
        updatedApproval.status = remark.status
        if (index !== -1) {
            // Replace the object at that index with the updated object
            approvers[index] = updatedApproval;
        }
        console.log(approvers,"PrepareApprovalsByNewRemark_FINAL");

        console.log(approvers,"PREPARE APPROVALS");
        return approvers;
    }catch (e) {

        console.log(e,"EXCEPTION");
    }
}