import React from "react";
import TaskComposer from "../../task/view/Page/Composer/createTask";
import { DEFAULT_GUID } from "../../../../utils/constants";

// This component wraps TaskComposer and provides a callback function to handle task creation.
export default function TaskComposerForMintueOfMeeting({
  onTaskCreated = () => {}, // Callback function to handle task creation
  index, // Index parameter
  subject,
  taskId = DEFAULT_GUID,
  mintueOfMeetingDetail // Detail of Current Of Meeting
}) {
  
  // Desturing the Mintue of Meeting Object - Api - GetScheduleMintuesofMeetingById
  const {id , referenceId , referenceType} = mintueOfMeetingDetail
 // Callback function that includes index and taskdetail in its parameters
  const handleTaskCreated = (taskdetail) => {
    onTaskCreated(index, taskdetail);
  };


  // Render the TaskComposer component and pass necessary props
  return (
    <TaskComposer
      referenceId={referenceId}
      referenceType={referenceType}
      subject={subject}
      minutesOfMeetingId={id}
      mintueofMeetingPointId={taskId}
      handleTaskCreated={handleTaskCreated} // Pass the custom callback
    />
  );
}
