import { ApprovalsModuleEnum } from "../../approval/utils/enum/approvalModuleEnum";
export const ResignationApprovalsModuleStatusEnum = {
    [ApprovalsModuleEnum.ResignationAdminApproval] : 'adminStatus',
    [ApprovalsModuleEnum.ResignationExitApproval]: 'exitStatus',
    [ApprovalsModuleEnum.ResignationFinanceApproval]: 'financeStatus',
    [ApprovalsModuleEnum.ResignationHrApproval] : 'hrStatus',
    [ApprovalsModuleEnum.ResignationItApproval]: 'itStatus',
    [ApprovalsModuleEnum.ResignationOtherApproval]: 'otherStatus',
    [ApprovalsModuleEnum.ResignationReportingToApproval]: 'reportingToStatus'

};