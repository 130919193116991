import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import {
    addPageCategory,
    getAllPageCategory,
    updatePageCategory,
} from "./actions.js";

const initialState = {
    pageCategories: [],
    loadingData: false,
    loader: false,
    success: false,
    error: false,
};

const pageCategorySlice = createSlice({
    name: "pageCategorySlice",
    initialState,
    reducers: {
        PageCategoryDeleted: (state, { payload }) => {
            state.pageCategories = state.pageCategories.filter(
                (e) => e.id !== payload.id
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPageCategory.fulfilled, (state, { payload }) => {
                console.log(payload, "FROM SLICE");
                state.loadingData = false;
                state.pageCategories = payload.data;
            })
            .addCase(addPageCategory.fulfilled, (state, { payload }) => {
                state.loader = false;
                if (payload.responseCode === responseCode.Success)
                    state.pageCategories.push(payload.data);
            })
            .addCase(updatePageCategory.fulfilled, (state, { payload }) => {
                state.loader = false;
                state.pageCategories = state.pageCategories.map((x) =>
                    x.id === payload.data.id ? payload.data : x
                );
            })
            .addMatcher(
                isPending(...[addPageCategory, updatePageCategory]),
                (state) => {
                    state.loader = true;
                    state.success = false;
                    state.error = false;
                }
            )
            .addMatcher(isPending(...[getAllPageCategory]), (state) => {
                state.loadingData = true;
                state.success = false;
                state.error = false;
            })
            .addMatcher(
                isRejected(
                    ...[
                        getAllPageCategory,
                        addPageCategory,
                        updatePageCategory,
                    ]
                ),
                (state) => {
                    state.loader = false;
                    state.loadingData = false;
                    state.success = false;
                    state.error = false;
                }
            );
    },
});

export const { PageCategoryDeleted } = pageCategorySlice.actions;
export default pageCategorySlice.reducer;
