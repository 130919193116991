import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import AddMember from "../../../../members/view/addMember/addMember";
import { useSelector } from "react-redux";
import BasicModal from "../../../../../sharedComponents/Modal/BasicModal/BasicModal";
import { UserOutlined } from "@ant-design/icons";
import { SuggestionTypeEnum } from "../../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { STRINGS } from "../../../../../../utils/base";
import { TaskReferenceTypeEnum } from "../../../utils/TaskReferenceTypeEnum";
import Avatar from "../../../../../sharedComponents/Avatar/avatar";
import { Button } from "antd";
import { AddUserTaskMembersAction } from "../../../store/actions";

const initialState = {
  isVisible: false,
  existingMembers: [],
  type: 0,
  key: "",
  selectedMembers: [],
  data: [],
  listData: [],
  isFirst: false,
  initialData: [],
};
function SubtaskMemberModal({
  record,
  addMembers,
  showAddedMembers = false,
  initialData = [],
}) {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const resetState = () => {
    setState({
      ...state,
      isVisible: false,
      key: new Date(),
      selectedMembers: record?.isNew ? initialData : [],
    });
  };

  const handleAddMember = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setState({
      ...state,
      isVisible: true,
      key: new Date(),
      initialData: initialData,
      isFirst: false,
    });
  };

  const handleUpdateMembers = () => {
    let member = state.selectedMembers?.map((item) => ({ memberId: item.id }));
    dispatch(AddUserTaskMembersAction({ member: member, id: record?.id }));
  };

  return (
    <>
      {initialData.length === 0 || !record?.isNew ? (
        <UserOutlined
          className={`calendarIcon cursor-pointer`}
          onClick={handleAddMember}
        />
      ) : (
        <Button onClick={handleAddMember} shape="circle" size="small">
          <span className="text-[11px] mb-2"> {`+ ${initialData.length}`}</span>
        </Button>
      )}
      <div onClick={(e) => e.stopPropagation()}>
        <BasicModal
          isOpen={state.isVisible}
          onCancel={() => {
            resetState();
          }}
          onSave={() => {
            record?.isNew
              ? addMembers(state.selectedMembers)
              : handleUpdateMembers();

            setState({
              ...state,
              isVisible: false,
            });
          }}
        >
          <AddMember
            suggestionType={[
              record?.referenceType === TaskReferenceTypeEnum.Project
                ? SuggestionTypeEnum.ProjectMember
                : record?.referenceType === TaskReferenceTypeEnum.Group
                ? SuggestionTypeEnum.GroupMember
                : SuggestionTypeEnum.Employee,
            ]}
            referenceId={record ? record?.referenceId : STRINGS.DEFAULTS.guid}
            key={state.key}
            placeHolder={"Add Members"}
            isMultiSelect={true}
            removeSelf={false}
            callback={(data) => {
              if (state.isFirst) {
                setState({
                  ...state,
                  selectedMembers: data,
                });
              } else {
                setState({ ...state, isFirst: true });
              }
            }}
            existingMembers={
              !record?.isNew
                ? record?.members?.map((x) => {
                    return x.memberId;
                  })
                : []
            }
            showAddedMembers={showAddedMembers}
            initialData={state.initialData}
          />
        </BasicModal>
      </div>
    </>
  );
}
export default SubtaskMemberModal;
