import React, { useState, useEffect } from "react";
const ThemeContext = React.createContext();

const ThemeStore = ({ children }) => {
    const [theme, setTheme] = useState("light"); // line B - setting the initial theme

    const switchTheme = (theme) => {
        setTheme(theme);
        localStorage.setItem("theme", theme); // Store the theme in local storage
    }; // line C - changing the theme

    useEffect(() => {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme && storedTheme !== theme) {
            setTheme(storedTheme);
        }
    }, [theme]); // Check for theme changes in local storage

    return (
        <ThemeContext.Provider value={{ switchTheme, theme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeStore, ThemeContext };