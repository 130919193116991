import MasterConfig from "../../../../../../utils/services/MasterConfig";


export const getAllSignatureService = () => {
    return MasterConfig.get("/api/Signature/GetSignatureByUserId")
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
}

export const saveSignature = args => {
    return MasterConfig.post("/api/Signature/SaveSignature", args)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
}