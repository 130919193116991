
import { TravelOptionsEnums } from "./travelOptionsEnum";
export const travelCategoryData = travel => [
	{
		id: TravelOptionsEnums.ByPlane,
		image: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="46.083"
				height="34.562"
				viewBox="0 0 46.083 34.562"
			>
				<path
					id="Icon_awesome-plane-departure"
					data-name="Icon awesome-plane-departure"
					d="M44.93,32.2H1.152A1.152,1.152,0,0,0,0,33.355v2.3a1.152,1.152,0,0,0,1.152,1.152H44.93a1.152,1.152,0,0,0,1.152-1.152v-2.3A1.152,1.152,0,0,0,44.93,32.2ZM5.8,24.518a2.375,2.375,0,0,0,1.752.771l9.4-.013a4.725,4.725,0,0,0,2.134-.513l20.95-10.631a12.24,12.24,0,0,0,4.826-4.2c1.318-2.051,1.462-3.535.941-4.583s-1.781-1.82-4.194-1.976a12.1,12.1,0,0,0-6.212,1.4L28.3,8.377,12.555,2.468a1.282,1.282,0,0,0-1.3-.08l-4.734,2.4a1.316,1.316,0,0,0-.372,2.054L17.4,13.909,9.969,17.68,4.76,15.054a1.282,1.282,0,0,0-1.157,0L.714,16.522a1.316,1.316,0,0,0-.4,2.022Z"
					transform="translate(0 -2.249)"
					fill="currentColor"
				/>
			</svg>
		),
		name: travel.plane,
	},
	{
		id: TravelOptionsEnums.ByShip,
		image: (
			<svg
				id="Group_4927"
				data-name="Group 4927"
				xmlns="http://www.w3.org/2000/svg"
				width="43.416"
				height="43.416"
				viewBox="0 0 43.416 43.416"
			>
				<path
					id="Path_1627"
					data-name="Path 1627"
					d="M5.618,18V6.427A1.809,1.809,0,0,1,7.427,4.618h9.045V1h7.236V4.618h9.045a1.809,1.809,0,0,1,1.809,1.809V18l1.965.59a1.809,1.809,0,0,1,1.234,2.171L35.018,31.742a9.031,9.031,0,0,1-7.692-3.607,9.045,9.045,0,0,1-14.472,0,9.031,9.031,0,0,1-7.692,3.607L2.42,20.765a1.809,1.809,0,0,1,1.234-2.171Zm3.618-1.085L20.09,13.663l4.982,1.494,3.273.982,2.6.78V8.236H9.236v8.683ZM5.618,35.371a10.814,10.814,0,0,0,7.236-2.764,10.853,10.853,0,0,0,14.472,0,10.814,10.814,0,0,0,7.236,2.764H38.18v3.618H34.562a14.405,14.405,0,0,1-7.236-1.936,14.493,14.493,0,0,1-14.472,0,14.405,14.405,0,0,1-7.236,1.936H2V35.371Z"
					transform="translate(1.618 0.809)"
					fill="currentColor"
				/>
			</svg>
		),
		name: travel.ship,
	},
	{
		id: TravelOptionsEnums.ByBus,
		image: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="46.166"
				height="46.166"
				viewBox="0 0 46.166 46.166"
			>
				<path
					id="Icon_awesome-bus"
					data-name="Icon awesome-bus"
					d="M44,11.542h-.721V7.213c0-4.04-8.945-7.213-20.2-7.213-1.249,0-2.47.039-3.954.134C9.955.715,2.885,3.622,2.885,7.213v4.328H2.164A2.164,2.164,0,0,0,0,13.706v7.213a2.165,2.165,0,0,0,2.164,2.164h.721V37.51A2.886,2.886,0,0,0,5.771,40.4v2.885a2.886,2.886,0,0,0,2.885,2.885h2.885a2.886,2.886,0,0,0,2.885-2.885V40.4H31.739v2.885a2.886,2.886,0,0,0,2.885,2.885H37.51A2.886,2.886,0,0,0,40.4,43.281V40.4h.577a2.276,2.276,0,0,0,2.308-2.308v-15H44a2.165,2.165,0,0,0,2.164-2.164V13.706A2.164,2.164,0,0,0,44,11.542ZM10.1,36.067a2.885,2.885,0,1,1,2.885-2.885A2.886,2.886,0,0,1,10.1,36.067Zm1.443-10.1a2.886,2.886,0,0,1-2.885-2.885V11.542a2.886,2.886,0,0,1,2.885-2.885H34.625a2.886,2.886,0,0,1,2.885,2.885V23.083a2.886,2.886,0,0,1-2.885,2.885Zm24.526,10.1a2.885,2.885,0,1,1,2.885-2.885A2.886,2.886,0,0,1,36.067,36.067Z"
					fill="currentColor"
				/>
			</svg>
		),
		name: travel.bus,
	},
	{
		id: TravelOptionsEnums.ByTrain,
		image: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="33.606"
				height="46.368"
				viewBox="0 0 33.606 46.368"
				className="expense_img_selected"
			>
				<path
					id="Icon_map-train-station"
					data-name="Icon map-train-station"
					d="M31.713,36.323l-.109-.209.055-.078.055-.078a12.919,12.919,0,0,0,2.767-1.552,4.576,4.576,0,0,0,1.665-2.717l.208-.956V6.047A3.783,3.783,0,0,0,35.825,4.2,7.07,7.07,0,0,0,34.5,2.485,7.583,7.583,0,0,0,32.74,1.217a4.127,4.127,0,0,0-1.876-.5H13.2a4.192,4.192,0,0,0-1.823.471A7.557,7.557,0,0,0,9.58,2.407,6.714,6.714,0,0,0,8.236,4.064a3.688,3.688,0,0,0-.529,1.828V30.946a3.233,3.233,0,0,0,.316,1.318,6.487,6.487,0,0,0,.791,1.287,8.155,8.155,0,0,0,1.032,1.112,7.005,7.005,0,0,0,1.081.816c.17.072.483.182.926.322s.636.243.6.31L5.226,47.088H9.444l5.278-7.65H29.285l5.273,7.65h4.273ZM17.835,2.828a.842.842,0,0,1,.264-.556.721.721,0,0,1,.527-.285H25.38a1.1,1.1,0,0,1,.424.206c.208.141.313.281.313.422V5.2a.6.6,0,0,1-.262.474.8.8,0,0,1-.474.219h-6.8l-.215-.167a1.926,1.926,0,0,1-.341-.291.528.528,0,0,1-.185-.388V2.828Zm-6.807,7.228a2.435,2.435,0,0,1,.239-1.031,4.052,4.052,0,0,1,.606-.924,3.049,3.049,0,0,1,.869-.681,2.186,2.186,0,0,1,1.033-.265H30.184a2.434,2.434,0,0,1,.945.207A3.246,3.246,0,0,1,32,7.944a3.311,3.311,0,0,1,.634.8,1.782,1.782,0,0,1,.234.893v5.594a1.784,1.784,0,0,1-.261.919,2.849,2.849,0,0,1-.686.8,3.946,3.946,0,0,1-.9.546,2.176,2.176,0,0,1-.947.213h-16.2l-.267-.047a1.6,1.6,0,0,1-.318-.109,2.676,2.676,0,0,1-1.58-.95,2.593,2.593,0,0,1-.688-1.738v-4.8Zm4.884,21.789a2.721,2.721,0,0,1-2.031.843,2.561,2.561,0,0,1-1.983-.843,2.963,2.963,0,0,1-.763-2.061,2.663,2.663,0,0,1,.794-1.921,2.605,2.605,0,0,1,1.952-.823,2.9,2.9,0,0,1,2.031.763,2.658,2.658,0,0,1,.817,2.036A2.78,2.78,0,0,1,15.912,31.844Zm12.08,0a2.86,2.86,0,0,1-.82-2.061,2.52,2.52,0,0,1,.871-1.981,3.022,3.022,0,0,1,2.035-.763,2.586,2.586,0,0,1,1.976.823,2.727,2.727,0,0,1,.767,1.921,2.906,2.906,0,0,1-.793,2.061,2.638,2.638,0,0,1-2.008.843,2.708,2.708,0,0,1-2.027-.843Z"
					transform="translate(-5.226 -0.72)"
					fill="currentColor"
				/>
			</svg>
		),
		name: travel.train,
	},
	{
		id: TravelOptionsEnums.ByCar,
		image: (
			<svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="46.368"
      viewBox="0 0 24 24"
      className="expense_img_selected"
    >
      <path
        id="Icon_car"
        data-name="Icon car"
        d="M23.5 7c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202zm-1.441 3.506c.639 1.186.946 2.252.946 3.666 0 1.37-.397 2.533-1.005 3.981v1.847c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1h-13v1c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1.847c-.608-1.448-1.005-2.611-1.005-3.981 0-1.414.307-2.48.946-3.666.829-1.537 1.851-3.453 2.93-5.252.828-1.382 1.262-1.707 2.278-1.889 1.532-.275 2.918-.365 4.851-.365s3.319.09 4.851.365c1.016.182 1.45.507 2.278 1.889 1.079 1.799 2.101 3.715 2.93 5.252zm-16.059 2.994c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm10 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm2.941-5.527s-.74-1.826-1.631-3.142c-.202-.298-.515-.502-.869-.566-1.511-.272-2.835-.359-4.441-.359s-2.93.087-4.441.359c-.354.063-.667.267-.869.566-.891 1.315-1.631 3.142-1.631 3.142 1.64.313 4.309.497 6.941.497s5.301-.184 6.941-.497zm2.059 4.527c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-18.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2z"
        fill="currentColor"

      />
    </svg>
		),
		name: travel.car,
	},
];
