import React, { useCallback } from "react";
import axiosInstance from "./MasterConfig";

export default function RequestCanceler({ children }) {
  const cancelRequests = useCallback(() => {
    console.log("Cancelling requests");
    axiosInstance.cancelRequest();
  }, []);

  // Provide the cancelRequests function to children
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { cancelRequests });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
}
