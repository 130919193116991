import { createSlice, isPending } from "@reduxjs/toolkit";
import {
  addAttendanceCheckIn,
  getAllAttendanceByBusinessAction,
  getAllAttendanceByUserAction,
  getAllAttendanceCheckInAction,
  saveAttendanceAction,
} from "./actions";
import moment from "moment";
import { ATTENDANCE_ENUM } from "../utils/constant";
import { AttendanceCheckInFilterTypeEnum } from "../utils/AttendanceCheckInFilterTypeEnum";
import calculateAttendanceTotals from "../utils/calculateAttendanceTotals";

const initialState = {
  success: false,
  loader: false,
  error: false,
  checkInData: [],
  lastCheckIn: null,
  userAttendanceData: [],
  checkInNotification: null,
  buisnessAttendance: [],
};

export const AttendanceSlice = createSlice({
  name: "Attendance",
  initialState: initialState,
  reducers: {
    checkInNotification: (state, { payload }) => {
      state.checkInNotification = payload;
    },
    updateAttendanceLog: (state, { payload }) => {
      // Find the index of the item with the matching day, month and year
      const itemIndex = state.buisnessAttendance.findIndex(
        (item) => item.month === payload.month && item.year === payload.year && item.day === payload.day 
      );

      if (itemIndex !== -1) {
        // Check if the day, month and year are found
        const item = state.buisnessAttendance[itemIndex];
        const updatedDetails = item.details.map((detail) => {
          if (detail.userId === payload.userId) {
            // Update the data for that uesr
            return {
              ...detail,
              ...payload, // Overwrite the existing data with the payload
              user: detail?.user
            };
          }
          return detail;
        });

        // Recalculate totalAbsent, totalLate, totalLeave, totalPresent, and overallTimeSpent
        const {
          totalAbsent,
          totalLate,
          totalLeave,
          totalPresent,
          overallTimeSpent,
        } = calculateAttendanceTotals(updatedDetails);

        // Update the totals in the buisnessAttendance item
        state.buisnessAttendance[itemIndex] = {
          ...item,
          details: updatedDetails,
          totalAbsent,
          totalLate,
          totalLeave,
          totalPresent,
          overallTimeSpent,
        };
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllAttendanceCheckInAction.fulfilled, (state, action) => {
        state.loader = false;
        state.checkInData =
          action.meta?.arg?.filterType ===
          AttendanceCheckInFilterTypeEnum.ForApproval
            ? state.checkInData
            : action?.payload.data;
        state.lastCheckIn = state.checkInData.length
          ? { ...state.checkInData[0] }
          : null;
        const today = new Date();
        if (
          state.lastCheckIn &&
          state.lastCheckIn?.type === ATTENDANCE_ENUM.CHECK_IN.IN &&
          (state.lastCheckIn.year !== today.getFullYear() ||
            state.lastCheckIn.month !== today.getMonth() + 1 ||
            state.lastCheckIn.day !== today.getDate())
        ) {
          state.lastCheckIn.type = ATTENDANCE_ENUM.CHECK_IN.OUT;
        }
      })
      .addCase(getAllAttendanceByUserAction.fulfilled, (state, action) => {
        state.userAttendanceData = action?.payload?.attendance;
        const currentDate = moment();
        if (state.userAttendanceData.length > 0) {
          state.userAttendanceData.forEach((item, index) => {
            const year = item.year;
            const month = item.month;
            const daysInMonth = moment(
              `${year}-${month}`,
              "YYYY-MM"
            ).daysInMonth();
            const allDaysArray = [];
            const backendData = item.details;  
            for (let day = 1; day <= daysInMonth; day++) {
              // Check if the current day is not a future day
              if (moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isSameOrBefore(currentDate, 'day')) {
                const dayData = backendData.find(
                  (data) =>
                    data.day === day && data.month === month && data.year === year
                );
        
                if (dayData) {
                  allDaysArray.push(dayData);
                } else {
                  allDaysArray.push({
                    id: null,
                    userId: null,
                    checkIn: null,
                    checkOut: null,
                    checkInLat: null,
                    checkInLng: null,
                    checkOutLat: null,
                    checkOutLng: null,
                    createBy: null,
                    createDate: moment(
                      `${year}-${month}-${day}`,
                      "YYYY-MM-DD"
                    ).toISOString(),
                    day: day,
                    graceTime: null,
                    month: month,
                    status: null,
                    timeSpent: 0,
                    userId: null,
                    year: year,
                  });
                }
              }
            }
            item.details = allDaysArray;
          });
        }        
      })
      .addCase(saveAttendanceAction.fulfilled, (state, { payload }) => {
        // Find the index of the item with the matching month and year
        const itemIndex = state.userAttendanceData.findIndex(
          (item) => item.month === payload.month && item.year === payload.year
        );

        if (itemIndex !== -1) {
          // Check if the month and year are found
          const item = state.userAttendanceData[itemIndex];
          const updatedDetails = item.details.map((detail) => {
            if (detail.day === payload.day) {
              // Update the data for that day
              return {
                ...detail,
                ...payload, // Overwrite the existing data with the payload
              };
            }
            return detail;
          });

          // Recalculate totalAbsent, totalLate, totalLeave, totalPresent, and overallTimeSpent
          const {
            totalAbsent,
            totalLate,
            totalLeave,
            totalPresent,
            overallTimeSpent,
          } = calculateAttendanceTotals(updatedDetails);

          // Update the totals in the userAttendanceData item
          state.userAttendanceData[itemIndex] = {
            ...item,
            details: updatedDetails,
            totalAbsent,
            totalLate,
            totalLeave,
            totalPresent,
            overallTimeSpent,
          };
        }
      })
      .addCase(addAttendanceCheckIn.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllAttendanceByUserAction.rejected, (state, action) => {
        state.userAttendanceData = [];
      })
      .addCase(
        getAllAttendanceByBusinessAction.fulfilled,
        (state, { payload }) => {
          state.buisnessAttendance = payload.data;
        }
      )
      .addMatcher(isPending(...[getAllAttendanceCheckInAction]), (state) => {
        state.loader = true;
        state.success = false;
        state.error = false;
      });
  },
});
export const {
  checkInNotification,
  updateAttendanceLog,
} = AttendanceSlice.actions;
export default AttendanceSlice.reducer;
