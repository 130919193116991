export function timeAgoFromNow(dateString) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateString + 'Z');

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDate;


    if (timeDifference < 60 * 1000) {
        return 'just now';
    }

    // Calculate years, days, hours, and minutes
    const years = Math.floor(timeDifference / (365 * 24 * 60 * 60 * 1000));
    const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    const hours = Math.floor(timeDifference / (60 * 60 * 1000));
    const minutes = Math.floor(timeDifference / (60 * 1000));

    // Determine the appropriate time unit
    if (years > 0) {
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    } else if (days > 0) {
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (hours > 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    }
}