export const NotificationTypesEnum = {
  RewardApproval: 1,
  RewardRemark: 2,
  RewardFinalApprovalStatus: 3,
  FeedComment: 4,
  NewFeed: 5,
  NewGroupFeed: 6,
  NewProjectFeed: 7,
  NewTimelineFeed: 8,
  MentionedOnFeed: 9,
  TaggedOnFeed: 10,
  DocumentApproval: 11,
  DocumentCollabrator: 12,
  DocumentReader: 13,
  DirectoryAccess: 14,
  FeedReaction: 15,
  FeedCommentReaction: 16,
  CommentReaction: 17,
  ProjectMember: 18,
  TaskComment: 19,
  WorkBoardComment: 20,
  WorkBoardTodoComment: 21,
  CommentReply: 22,
  ScheduleMember: 23,
  GroupMember: 24,
  MessageSend: 25,
  FeedPoll: 26,
  GroupFeedPoll: 27,
  TaggedOnGroupFeed: 28,
  MentionedOnGroupFeed: 29,
  ProjectFeedPoll: 30,
  TaggedOnProjectFeed: 31,
  MentionedOnProjectFeed: 32,
  GroupTask: 33,
  GroupWorkBoard: 34,
  GroupExpense: 35,
  GroupSchedule: 36,
  GroupTravel: 37,
  GroupDocument: 38,
  ProjectTask: 39,
  ProjectWorkBoard: 40,
  ProjectExpense: 41,
  ProjectSchedule: 42,
  ProjectTravel: 43,
  ProjectDocument: 44,
  NewSchedule: 45,
  LeadManager: 46,
  LeadManagerSection: 47,
  LeadManagerTodo: 48,
  CustomApproval: 49,
  Travel: 50,
  NewWorkBoard: 51,
  DocumentApprovalRemark: 52,
  ExpenseApproval: 53,
  LeaveApproval: 54,
  TravelApproval: 55,
  WarningApproval: 56,
  ComplainApproval: 57,
  BonusApproval: 58,
  PromotionApproval: 59,
  RequestForItemApproval: 60,
  LoanApproval: 61,
  CustomApprovalApproval: 62,
  FormApproval: 63,
  EmployeeSalaryApproval: 64,
  SignupApproval: 65,
  PayrollApproval: 66,
  CareerApproval: 67,
  RequisitionApproval: 68,
  RequisitionFinalApproval: 69,
  ItemApproval: 70,
  AssetApproval: 71,
  ResignationAdminApproval: 72,
  ResignationExitApproval: 73,
  ResignationFinanceApproval: 74,
  ResignationHrApproval: 75,
  ResignationItApproval: 76,
  ResignationOtherApproval: 77,
  ResignationReportingToApproval: 78,
  QuotationApproval: 79,
  QuotationClientApproval: 80,
  AppraisalApproval: 81,
  ExpenseApprovalRemark: 82,
  ExpenseExecutorRemark: 83,
  ExpenseFinancerRemark: 84,
  LeaveApprovalRemark: 85,
  TravelApprovalRemark: 86,
  TravelAgentRemark: 87,
  WarningApprovalRemark: 88,
  ComplainApprovalRemark: 89,
  RewardApprovalRemark: 90,
  BonusApprovalRemark: 91,
  PromotionApprovalRemark: 92,
  RequestForItemApprovalRemark: 93,
  LoanApprovalRemark: 94,
  CustomApprovalRemark: 95,
  FormApprovalRemark: 96,
};

// export const NotificationFeatureTypeEnum = {
//   All: 0,
//   Feed: 1,
//   Messenger: 2,
//   Group: 3,
//   Project: 4,
//   Task: 5,
//   WorkBoard: 6,
//   LeadManager: 7,
//   Schedule: 8,
//   Expense: 9,
//   Travel: 10,
//   Document: 11,
//   DiscussionBoard: 12,
//   Leave: 13,
//   Quotation: 14,
//   Form: 15,
//   Appraisal: 16,
//   Promotion: 17,
//   Warning: 18,
//   Bonus: 19,
//   Complain: 20,
//   Reward: 21,
//   Career: 22,
//   Salary: 23,
//   Loan: 24,
//   Resignation: 25,
//   Payroll: 26,
//   Requisition: 27,
//   Approval: 28,
//   Pages: 29,
//   CustomApproval: 30,
//   Mailbox : 31,
//   Notification : 32,
// };
export const NotificationParentReferenceTypeEnum = 
{
    General : 1,
    Project : 2,
    Group : 3,
    Lead : 4 ,
    Travel: 5,
    
}

export const  FeaturesEnum = 
{
    Feed : 1,
    Mailbox : 2,
    Messenger : 3,
    Group : 4,
    Project : 5,
    Task : 6,
    WorkBoard : 7,
    Lead : 8,
    Expense : 9,
    Schedule : 10,
    Travel : 11,
    Document : 12,
    ELearning : 13,
    Asset : 14,
    CustomApproval : 15,
    Employee : 16,
    Administration : 17,
    Appraisal : 18,
    Department : 19,
    Leave : 20,
    Loan : 21,
    Holiday : 22,
    Career : 23,
    AudioCalling : 24,
    VideoCalling : 25,
    Salary:26,
    Attendance : 27,
    Requisition : 28,
    Payroll : 29,	
    Rewards	: 30,	
    Complains : 31,	
    Warnings : 32,	
    Bonus : 33,	
    Promotion : 34,	
    OrganizationalChart : 35,	
    MyTeam : 36,	
    JobBoard : 37,	
    Form : 38,	
    Resignation	: 39,	
    BusinessPolicy : 40,	
    ChartOfAccount : 41,	
    Voucher : 42,	
    Quotation : 43,	
    Company	: 44,	
    Reports	: 45,	
    DiscussionBoard : 46,	
    MarketPlace : 47,
    Pages : 48
}
export const  NotificationFeatureTypeEnum =
{
    Reward : 1,
    Feed : 2,
    Document : 3,
    Project : 4,
    Task : 5,
    WorkBoard : 6,
    Schedule : 7,
    Messenger : 9,
    Expense : 10,
    Auction:11,
    Leave : 13,
    Travel : 14,
    Warning : 15,
    Complain : 16,
    Bonus : 17,
    Promotion : 18,
    RequestForItem : 19,
    Loan : 20,
    CustomApproval : 21,
    Form : 22,
    EmployeeSalary : 23,
    Signup : 24,
    Payroll : 25,
    Career : 26,
    Requisition : 27,
    Item : 28,
    Asset : 29,
    Resignation : 30,
    Quotation : 31,
    Appraisal : 32,
    LeadManager : 33,
    DiscussionBoard : 34,
    Pages : 35,
    Mailbox : 36,
    Approval : 37,
    Notification : 38,
    Group:39,
    Contract: 41,
   
}
export const NotificationBlockFeatureTypeEnum = {
    Reward: 1,
    Feed: 2,
    Document: 3,
    Project: 4,
    Task: 5,
    WorkBoard: 6,
    Schedule: 7,
    Group: 8,
    Messenger: 9,
    Expense: 10,
    Auction: 11,
    Leave: 12,
    Travel: 13,
    Warning: 14,
    Complain: 15,
    Bonus: 16,
    Promotion: 17,
    Loan: 18,
    CustomApproval: 19,
    Form: 20,
    EmployeeSalary: 21,
    Payroll: 22,
    Career: 23,
    Requisition: 24,
    Resignation: 25,
    Quotation: 26,
    Appraisal: 27,
    LeadManager: 28,
    DiscussionBoard: 29,
    Pages: 30,
};