import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addCustomApproval,
  GetAllCustomApprovalsAction,
  GetCustomApprovalById,
  GetAllCustomApprovals,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import {
  GetApprovalStatusByRemarks,
  PrepareApprovalsByNewRemark,
} from "../../approval/utils/constant/constant";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
  customApprovals: [],
  data: null, //THis will be with identifiers
  loadingData: false,
  loader: true,
  addCustonApprovalLoader: false,
  customApprovalDetail: {},
  drawerOpen: false,
  approvers: [],
  pagingCustomApprovals: {},
  newItem: null,
  totalRecords: [],
  recordSize: 0,
  newCountFilterButton:false
};

const customApprovalSlice = createSlice({
  name: "customApprovals",
  initialState,
  reducers: {
    updateCustomApprovalStatus: (state, actions) => {},
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        // state.data[identifier] = state.data?.[identifier].concat([state.newItem]);
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    handleNotificationNewItem: (state, { payload }) => {
      
          try{
            const currentState = current(state);
            if(currentState.data!==null)
            { 
              const keys = Object.keys(currentState.data);
              let updatedState = { ...currentState.data};

              keys?.forEach((identifier)=>{
                const current = currentState.data[identifier]
                const index  = current.findIndex(y => y.id === payload.featureId)
                if(index !==-1)
                {
                  const updatedCurrent = [...current]; // Create a deep copy of the current array
                  let referenceItem = updatedCurrent[index];
                  referenceItem = {
                      ...referenceItem,
                      notificationCount:referenceItem.notificationCount+1
                  }

                  updatedCurrent[index] = referenceItem;

                  updatedState = {
                    ...updatedState,
                    [identifier]: updatedCurrent,
                  };
                }
              });
              state.data = updatedState;

            }
          }
          catch(e)
          {
            console.log("===error===customApproval",e)
          }    },
    readCountforCustomApproval:(state , {payload}) =>{

          const id = payload
          try{
            const currentState = current(state);
            if(currentState.data!==null)
            { 
              const keys = Object.keys(currentState.data);
              let updatedState = { ...currentState.data};

              keys?.forEach((identifier)=>{
                const current = currentState.data[identifier]
                const index  = current.findIndex(y => y.id === id)
                if(index !==-1)
                {
                  const updatedCurrent = [...current]; // Create a deep copy of the current array
                  let referenceItem = updatedCurrent[index];
                  referenceItem = {
                      ...referenceItem,
                      notificationCount:0
                  }

                  updatedCurrent[index] = referenceItem;

                  updatedState = {
                    ...updatedState,
                    [identifier]: updatedCurrent,
                  };
                }
              });
              state.data = updatedState;

              state.newCountFilterButton = id
            }
          }
          catch(e)
          {
            console.log("===error===customApproval",e)
          }
    },
    handleApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );
              
              let newStatus = null;
              
              if(approverStatus.length > 1 )
              {
                 
                    if (approverStatus.length === 2 && approverStatus.includes(ApprovalStatus.Approved) && approverStatus.includes(ApprovalStatus.Removed)) {
                      newStatus = ApprovalStatus.Approved;
                  } else {
                      newStatus = ApprovalStatus.InProcess;
                  }
              
              }
              else
              {
                newStatus = approverStatus[0] === ApprovalStatus.Removed ? ApprovalStatus.Approved : approverStatus[0]
              }

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status:newStatus,
                approverStatus:
                newStatus,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptionss");
      }
    },

    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    updateValue: (state, action) => {
      const { stateapprovalDetail, index } = action.payload;
      if (stateapprovalDetail?.length > 0) {
        if (
          state.approvers[index][0]?.referenceId ===
          stateapprovalDetail[0].referenceId
        ) {
          state.approvers[index] = stateapprovalDetail;
        }
      }
    },
    handleAddCustomApprovalsApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem?.approvers,
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
               approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleCustomApprovalReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllCustomApprovalsAction.fulfilled, (state, action) => {
      const { segmentType, identifier, filter } = action?.meta?.arg;
      const data = action.payload ?? [];
      if (segmentType === SegmentTypeEnum.Grid) {
        state.data = {
          ...state.data,
          [identifier]: [...(state.data?.[identifier] || []), ...data],
        };
        state.recordSize = data.length;
      } else {
        state.data = {
          ...state.data,
          [identifier]: data.data,
        };
        state.totalRecords = action?.payload?.totalRecords;
      }
    });
    builder.addCase(GetCustomApprovalById.fulfilled, (state, action) => {
      const { identifier } = action?.meta?.arg;
      const data = action.payload.data ?? [];

      state.data = {
        ...state.data,
        [identifier]: [data],
      };
      console.log(state.data,"customDataaaaaa");
    });
   
    // builder.addCase(getAllCustomApprovalspaging.fulfilled, (state, action) => {
    //   state.pagingCustomApprovals = action.payload ?? []
    //   state.customApprovals = action.payload.data ?? [];
    //   state.approvers = action.payload.data
    //     ? action.payload.data.map((item) => item.approvers)
    //     : [];
    //   state.loader = false;
    // });
    // builder.addCase(getAllCustomApprovals.fulfilled, (state, action) => {
    //   state.customApprovals = action.payload ? action.payload : [];
    //   state.approvers = action.payload
    //     ? action.payload.map((item) => item.approvers)
    //     : [];
    //   state.loader = false;
    // });

    // builder.addCase(GetCustomApprovalById.fulfilled, (state, action) => {
    //   state.customApprovalDetail = action.payload.data;
    //   state.loadingData = false;
    // });
    builder.addCase(addCustomApproval.fulfilled, (state, { payload }) => {
      state.newItem = payload.data.data;

      /*state.customApprovals = [payload.data.data, ...state.customApprovals];
      state.approvers = [payload.data.data.approvers, ...state.approvers];
      state.drawerOpen = false;
      // state.loader = false;
      state.addCustonApprovalLoader = false;

      return state;*/
    });

    builder
      .addMatcher(isPending(...[GetCustomApprovalById]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(isPending(...[addCustomApproval]), (state) => {
        state.addCustonApprovalLoader = true;
      })
      .addMatcher(isRejected(...[addCustomApproval]), (state) => {
        state.addCustonApprovalLoader = false;
      });
  },
});

export const {
  handleOpenComposer,
  updateValue,
  handleNewItem,
  removeIdentifier,
  handleApprovalRemark,
  handleResetState,
  readCountforCustomApproval,
  handleAddCustomApprovalsApprover,
  handleNotificationNewItem,
  handleCustomApprovalReminder
} = customApprovalSlice.actions;
export default customApprovalSlice.reducer;
