import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  addScheduleService,
  getAllScheduleService,
  getScheduleByIdService,
  updateMemberScheduleStatusService,
  updateScheduleMemberTypeService,
  addScheduleMemberService,
  getAllScheduleMemberService,
  getCalendarService,
  addScheduleExternalMemberService,
  roomCheckAvailableService,
  addScheduleConferenceCallService,
  calendarEmailInvite,
  getMinutesOfMeetingsService,
  updateMinutesOfMeetingsService,
  convertMinutesOfMeetingIntoTaskService,
  getTaskByIdService,
  getAllRequestForInterviews,
  updateScheduleMeetingTimeService,
  getScheduleUserCalendarLinkService,
  updateScheduleUserCalenderSettingIsBlockedService,
  changeScheduleUserCalenderLinkService,
  updateScheduleService,
  updateScheduleMeetingMemberTimeService,
  getCallingTranscribeByRoomIdSercive,
  SendEmailService,
  getAllUserCalenderSettingAvailabilityService,
  saveAllUserCalenderSettingAvailabilityService,
  saveUserCalenderSettingAvailabilityService,
  GetAiGeneratedSummaryService
} from "../services/services";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { message } from "antd";

export const addSchedule = createAsyncThunk(
  "addSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Schedule Created Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addScheduleMemberAction = createAsyncThunk(
  "addScheduleMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addScheduleMemberService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Schedule Member Added Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllSchedule = createAsyncThunk(
  "getAllSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllScheduleMemberAction = createAsyncThunk(
  "getAllScheduleMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllScheduleMemberService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getScheduleById = createAsyncThunk(
  "getScheduleById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getScheduleByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllEventSchedule = createAsyncThunk(
  "getAllEventSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllCurrentSchedule = createAsyncThunk(
  "getAllCurrentSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllUpcomingSchedule = createAsyncThunk(
  "getAllUpcomingSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateMemberScheduleStatus = createAsyncThunk(
  "updateMemberScheduleStatus",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateMemberScheduleStatusService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateScheduleMemberType = createAsyncThunk(
  "updateScheduleMemberType",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateScheduleMemberTypeService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);
export const getCalendar = createAsyncThunk(
  "getCalendar",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getCalendarService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);
export const calendarInviteByEmail = createAsyncThunk(
  "sendcalendarInvitationByEmail",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await calendarEmailInvite(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "invitation sent successfully ",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addScheduleExternalMemberAction = createAsyncThunk(
  "addScheduleMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addScheduleExternalMemberService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Schedule External Member Added Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const roomCheckAvailableServiceAction = createAsyncThunk(
  "roomCheckAvailable",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await roomCheckAvailableService(data);
    if (res.responseCode === responseCode.Success) {
      // dispatch(
      //   openNotification({
      //     message: "Schedule External Member Added Successfully",
      //     type: "success",
      //     duration: 2,
      //   })
      // );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const AddScheduleConferenceCallAction = createAsyncThunk(
  "AddScheduleConferenceCall",
  async (schId, { dispatch, getState, rejectWithValue }) => {
    const res = await addScheduleConferenceCallService(schId);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Added Successfully!",
          type: "success",
          duration: 2,
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getMinutesMeetings = createAsyncThunk(
  "getMinutesMeetings",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getMinutesOfMeetingsService(id);
    if (res.responseCode === responseCode.Success) {
      // dispatch(
      //   openNotification({
      //     message: "Added Successfully!",
      //     type: "success",
      //     duration: 2,
      //   })
      // );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateMinutesMeetings = createAsyncThunk(
  "updateMinutesMeetings",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateMinutesOfMeetingsService(data);
    if (res.responseCode === responseCode.Success) {
       dispatch(
        openNotification({
          message: "Updated Successfully!",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);


export const getAllRequestForInterviewsAction = createAsyncThunk(
  "GetAllRequestedInterviews",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllRequestForInterviews();
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const convertMinutesMeetingsIntoTask = createAsyncThunk(
  "convertMinutesMeetingsIntoTask",
  async ({ payload, onSuccess }, { dispatch, getState, rejectWithValue }) => {

    const res = await convertMinutesOfMeetingIntoTaskService(payload);
    if (res.responseCode === responseCode.Success) {
      // dispatch(
      //   openNotification({
      //     message: "Added Successfully!",
      //     type: "success",
      //     duration: 2,
      //   })
      // );
      onSuccess(res.data);
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);
export const getTaskById = createAsyncThunk(
  "task/getTaskById",
  async (id, { rejectWithValue }) => {
    const response = await getTaskByIdService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const updateScheduleMeetingTime = createAsyncThunk(
  "updateScheduleMeetingTime",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateScheduleMeetingTimeService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const UpdateScheduleMemberMeetingTimeAction = createAsyncThunk(
  "updateScheduleMeetingTime",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateScheduleMeetingMemberTimeService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getScheduleUserCalendarLink = createAsyncThunk(
  "getScheduleUserCalendarLink",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getScheduleUserCalendarLinkService();
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const changeScheduleUserCalenderLink = createAsyncThunk(
  "changeScheduleUserCalenderLink",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await changeScheduleUserCalenderLinkService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateScheduleUserCalenderSettingIsBlocked = createAsyncThunk(
  "updateScheduleUserCalenderSettingIsBlocked",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateScheduleUserCalenderSettingIsBlockedService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateScheduleAction = createAsyncThunk(
  "updateSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getCallingTranscribeByRoomIdAction = createAsyncThunk(
  "getCallingTranscribeByRoomId",
  async (roomId, { dispatch, getState, rejectWithValue }) => {
    const res = await getCallingTranscribeByRoomIdSercive(roomId);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const SendEmailAction = createAsyncThunk(
  "SendEmail",
  async (roomId, { dispatch, getState, rejectWithValue }) => {
    const res = await SendEmailService(roomId);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Email Sent Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);



export const GetAiGeneratedSummaryAction = createAsyncThunk(
  "GetAiGeneratedSummaryAction",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await GetAiGeneratedSummaryService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllUserCalenderSettingAvailabilityAction = createAsyncThunk(
  "getAllUserCalenderSetting",
  async (roomId, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllUserCalenderSettingAvailabilityService();
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);


export const saveUserCalenderSettingAvailabilityAction = createAsyncThunk(
  "saveAllUserCalenderSetting",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await saveUserCalenderSettingAvailabilityService(payload);
    if (res.responseCode === responseCode.Success) {
      message.success("Added Successfully")
      return res;
    } else {
      message.error(res.message)
      return rejectWithValue(res.message);
    }
  }
);  
