import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { responseCode } from '../../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../../services/slices/notificationSlice';
import MasterConfig from '../../../../../utils/services/MasterConfig';
import {
  addCompanyGoalsService,
  addDepartmentGoalsService,
  getAllAdminstrationAppraisalQuestionService,
  getAllCompanyGoalsService,
  getAllDepartmentGoalsService,
  getApprovalQuestionForAppraisalByIdService,
  removeCompanyGoalsService,
  removeDepartmentGoalsService,
  updateCompanyGoalsService,
  updateDepartmentGoalsService,
} from '../services/service';
import { appraisalQuestionDeleted } from './slice';
import { companyGoalEnum } from '../utils/companyGoalEnum';

export const getAllGoals = createAsyncThunk(
  'goals/getAllGoals',
  async (args, { dispatch }) => {
    let res = null 
    if(args.type === companyGoalEnum.CompanyGoal){
      res = await getAllCompanyGoalsService();

    }else if (args.type === companyGoalEnum.DepartmentGoal){

      res = await getAllDepartmentGoalsService(args.payload)
    }
   
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addGoals = createAsyncThunk(
  'goals/addGoals',
  async (args, { dispatch }) => {
    let res = null 
    if(args.type === companyGoalEnum.CompanyGoal){
      res = await addCompanyGoalsService(args.payload);

    }else if(args.type === companyGoalEnum.DepartmentGoal){

      res = await addDepartmentGoalsService(args.payload)
    }
   
    if (res.responseCode && res.responseCode === responseCode.Success) {
      message.success('Goal added successfully!');
      responseMessage({ dispatch, data: res });
    } else {
      message.error(res.message);
    }
    return res;
  }
);


export const updateGoals = createAsyncThunk(
  'goals/updateGoals',
  async (args, { dispatch }) => {
    let res = null 
    if(args.type === companyGoalEnum.CompanyGoal){
      res = await updateCompanyGoalsService(args.payload);

    }else if (args.type === companyGoalEnum.DepartmentGoal){

      res = await updateDepartmentGoalsService(args.payload)
    }
   
    if (res.responseCode && res.responseCode === responseCode.Success) {
      message.success('Goal update successfully!');
      responseMessage({ dispatch, data: res });
    } else {
      message.error(res.message);
    }
    return res;
  }
);

export const removeGoals = createAsyncThunk(
  'goals/removeGoals',
  async (args, { dispatch }) => {
    let res = null 
    if(args.type === companyGoalEnum.CompanyGoal){
      res = await removeCompanyGoalsService(args.payload);

    }else if (args.type === companyGoalEnum.DepartmentGoal){

      res = await removeDepartmentGoalsService(args.payload)
    }
   
    if (res.responseCode && res.responseCode === responseCode.Success) {
      message.success('Goal remove successfully!');
      responseMessage({ dispatch, data: res });
    } else {
      message.error(res.message);
    }
    return res;
  }
);


export const getAllAdminstrationAppraisalQuestion = createAsyncThunk(
  'appraisalQuestion/getAllAdminstrationAppraisalQuestion',
  async (args, { dispatch }) => {
    const res = await getAllAdminstrationAppraisalQuestionService();
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);



export const getApprovalQuestionForAppraisalById = createAsyncThunk(
  'appraisalQuestion/getApprovalQuestionForAppraisalById',
  async (args, { dispatch }) => {
    const res = await getApprovalQuestionForAppraisalByIdService(args);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);
