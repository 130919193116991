import React, { useContext } from "react";
import { Table } from "../../../../sharedComponents/customTable";
import { tableColumn } from "./TableColumn";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";

const TaskList = ({ tasks }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  return (
    <>
      <Table
        columns={tableColumn(sharedLabels)}
        // handleChange={handleColumnSorting}
        dragable={true}
        // Data will be from get all Tasks of that user
        data={tasks ? tasks : []}
      />
    </>
  );
};

export default TaskList;
