export const CustomApprovalFilterSortEnum = {

    CreateDateAsc: 1,
    CreateDateDesc: 2,
    UpdateDateAsc: 3,
    UpdateDateDesc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
    Value: 7,
    ValueDesc: 8
}



export const CustomApprovalTableSort = {
    referenceNo: {
        descend: CustomApprovalFilterSortEnum.ReferenceNoDesc,
        ascend: CustomApprovalFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: CustomApprovalFilterSortEnum.CreateDateDesc,
        ascend: CustomApprovalFilterSortEnum.CreateDateAsc
    },
    updateDate: {
        descend: CustomApprovalFilterSortEnum.UpdateDateDesc,
        ascend: CustomApprovalFilterSortEnum.UpdateDateAsc
    },
    value: {
        descend: CustomApprovalFilterSortEnum.ValueDesc,
        ascend: CustomApprovalFilterSortEnum.Value
    },

}