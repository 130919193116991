import {
  Button,
  Col,
  Form,
  Image,
  Row,
  Select,
  Tooltip,
  Upload,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { TravelAttachmentTypeEnum } from "../../utils/travelAttachmentTypeEnum";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { docImage } from "../../utils/TravelDoc";
import { defaultUiid } from "../../../../../utils/Shared/enums/enums";

export default function TravelGroupAttachment({ setAttachments }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { travel, sharedLabels } = dictionaryList[userLanguage];
  const { TravelAttachmentTypeOptions } = travel;

  const [state, setState] = useState({
    selectedDocument: TravelAttachmentTypeEnum.Other,
    selectedFiles: [],
    uploadedDocuments: [],
  });

  let attachments;

  if (state.uploadedDocuments?.length > 0) {
    attachments = state.uploadedDocuments.flatMap((type) => {
      return type.files.map((file) => {
        return {
          id: defaultUiid,
          file: file.originFileObj,
          type: type.documentType,
        };
      });
    });
  }

  useEffect(() => {
    setAttachments(state.uploadedDocuments);
  }, [state.uploadedDocuments]);

  const handleFileUpload = (info) => {
    const updatedDocuments = [...state.uploadedDocuments];
    const existingDocumentIndex = updatedDocuments.findIndex(
      (doc) => doc.documentType === state.selectedDocument
    );

    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      const updatedFileList = info.fileList.map((file) => ({ ...file }));

      if (existingDocumentIndex !== -1) {
        const existingFiles = updatedDocuments[existingDocumentIndex].files;
        const newFiles = updatedFileList.filter(
          (file) =>
            !existingFiles.some((existingFile) => existingFile.uid === file.uid)
        );

        updatedDocuments[existingDocumentIndex].files = [
          ...existingFiles,
          ...newFiles,
        ];
      } else {
        updatedDocuments.push({
          documentType: state.selectedDocument,
          files: updatedFileList,
        });
      }

      setState({ ...state, uploadedDocuments: updatedDocuments });
    }
  };

  const documentOptionArray = Object.entries(
    TravelAttachmentTypeEnum
  ).map(([key, value]) => ({ value, label: key.toString() }));

  const handleDocumentChange = (value) => {
    setState({ ...state, selectedDocument: value });
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    const isPDF = file.type === "application/pdf";

    if (isImage || isPDF) {
      file.preview = URL.createObjectURL(file);
      return file;
    } else {
      message.error(sharedLabels.Invalidfiletype);
      return false;
    }
  };

  const handleMouseEnter = (x, y) => {
    setState({ ...state, isHovered: `${x}-${y}` });
  };

  const handleMouseLeave = () => {
    setState({ ...state, isHovered: null });
  };

  const handleDeletefile = (x, y) => {
    const updatedFiles = [...state.uploadedDocuments];
    updatedFiles[x].files.splice(y, 1);
    if (!updatedFiles[x].files.length) {
      updatedFiles.splice(x, 1);
    }
    setState({ ...state, uploadedDocuments: updatedFiles });
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label={travel.AttachmentType}>
            <Select
              defaultValue={TravelAttachmentTypeEnum.Other}
              options={documentOptionArray}
              onChange={handleDocumentChange}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Upload Documents">
            <Upload
              fileList={state.selectedFiles}
              accept=".jpg,.png,.gif,.pdf"
              multiple
              onChange={handleFileUpload}
              beforeUpload={beforeUpload}
            >
              <Button icon={<UploadOutlined />}>Select Files</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      {state.uploadedDocuments.map((doc, x) => (
        <div key={doc.documentType}>
          {doc.files.length > 0 && (
            <>
              <h3>{TravelAttachmentTypeOptions[doc.documentType]}</h3>
              <Row gutter={[16, 16]}>
                {doc.files.map((file, y) => (
                  <Col span={4} key={file.uid}>
                    <div
                      onMouseEnter={() => handleMouseEnter(x, y)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div className="travelDoc-img-wrapper">
                        {file.name.endsWith(".pdf") ? (
                          <>
                            <div className="flex flex-col items-center">
                              {docImage.image}
                              <div className="text-center">{file.name}</div>
                            </div>
                          </>
                        ) : (
                          <Image
                            src={file.preview ? file.preview : "/file-icon.png"}
                            className="imgPreview"
                            rootClassName="travelDoc-img"
                          />
                        )}
                      </div>

                      {state.isHovered === `${x}-${y}` && (
                        <Tooltip title="">
                          <CloseOutlined
                            className="absolute bottom-[82%] left-[82%] text-[10px] deleteIcon "
                            onClick={() => handleDeletefile(x, y)}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      ))}
    </>
  );
}
