import {createAsyncThunk} from "@reduxjs/toolkit";
import {ResponseType} from "../../../../utils/api/ResponseResult";
import {addReactionService} from "../service/service";
import { addReactionAtComment } from "../../Comment/store/commentSlice";

export const addReactionAction = createAsyncThunk(
    "reaction/addReactionAction",
    async (data, { _, rejectWithValue , dispatch }) => {
        const { referenceId, reactionModule, reactionType } = data;
        const payload = { referenceId, reactionModule, reactionType };
        console.log(payload,"payloooad")
        const response = await addReactionService(payload);
        switch (response.type) {
            case ResponseType.ERROR:
                return rejectWithValue(response.errorMessage);
            case ResponseType.SUCCESS:
                dispatch(addReactionAtComment({payload:data , response:response.data}))
                return response.data;
                
        }
    }
);
