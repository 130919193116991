import React, { useContext } from "react";
import { Tag } from "antd";
import { ResignationApprovalStatusEnum } from "../../utils/resignationStatusEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";

const StatusTag = ({ status = ResignationApprovalStatusEnum.InProcess }) => {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { resignations } = dictionaryList[userLanguage];

    let color = ColorsEnum.Primary;

    switch (status) {
        case ResignationApprovalStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case ResignationApprovalStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case ResignationApprovalStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case ResignationApprovalStatusEnum.Completed:
            color = ColorsEnum.Completed;
            break;
        case ResignationApprovalStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;

        default:
            color = ColorsEnum.Primary;
    }
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {resignations.statusEnum[status]}
            </Tag>
        </>
    );
};


export default StatusTag;