import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import {
  GetAllAuctionAction,
  addAuctionAction,
  addAuctionMemberAction,
  getAllAuctionOfferAction,
  getAuctionByIdAction,
  getAuctionOfferByIdAction,
  removeAuctionMemberAction,
} from "./action";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
    data:null,
    newItem: null,
    isUpdateFilterButtonCount : false,
    autionOfferData : null,
}

const auctionSlice = createSlice({
   name:"auctionSlice",
   initialState,
   reducers:{
      handleResetState: (state, { payload }) => {
        state.data = [];
        // state.totalRecords = 0;
      },
      removeIdentifier: (state, { payload }) => {
        const { identifier } = payload;
        if (typeof state.data !== "undefined") delete state.data?.[identifier];
      },
      handleNewItem: (state, { payload }) => {
        const { identifier } = payload;
        if (typeof state.data !== "undefined")
          state.data[identifier] = [state.newItem].concat(
            state.data?.[identifier]
          );
          state.newItem = null
      },
      handleNotificationNewItem: (state, { payload }) => {
        
        try{
          const currentState = current(state);
          if(currentState.data!==null)
          { 
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data};

            keys?.forEach((identifier)=>{
              const current = currentState.data[identifier]
              const index  = current.findIndex(y => y.id === payload.featureId)
              if(index !==-1)
              {
                const updatedCurrent = [...current]; // Create a deep copy of the current array
                let referenceItem = updatedCurrent[index];
                referenceItem = {
                    ...referenceItem,
                    notificationCount:referenceItem.notificationCount+1
                }

                updatedCurrent[index] = referenceItem;

                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
            state.data = updatedState;

          }
        }
        catch(e)
        {
          console.log("===error===customApproval",e)
        }    
      },
      readCountforAuction:(state , {payload}) =>{

        const id = payload
        try{
          const currentState = current(state);
          if(currentState.data!==null)
          { 
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data};

            keys?.forEach((identifier)=>{
              const current = currentState.data[identifier]
              const index  = current.findIndex(y => y.id === id)
              if(index !==-1)
              {
                const updatedCurrent = [...current]; // Create a deep copy of the current array
                let referenceItem = updatedCurrent[index];
                referenceItem = {
                    ...referenceItem,
                    notificationCount:0
                }

                updatedCurrent[index] = referenceItem;

                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
            state.data = updatedState;

            state.newCountFilterButton = id
          }
        }
        catch(e)
        {
          console.log("===error===customApproval",e)
        }
      },
      handleAddAuctionApprovers:(state , {payload}) => {
        try {
          const currentState = current(state);
          const { referenceId, module, approver } = payload;
          if (currentState.data !== null) {
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data };
  
            keys.forEach((identifier) => {
              const current = currentState.data[identifier];
              const index = current.findIndex((y) => y.id === referenceId);
              if (index !== -1) {
                const updatedCurrent = [...current];
                let referenceItem = updatedCurrent[index];
                const approvers = [...referenceItem?.approvers];
                const finalApprovers = [...approvers, ...approver];
  
                referenceItem = {
                  ...referenceItem,
                  approvers: finalApprovers,
                };
  
                updatedCurrent[index] = referenceItem;
  
                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
            state.data = updatedState;
          }
        } catch (e) {
          console.log(e, "Exceptions");
        }
      },
      handleAuctionReminder : (state , {payload}) =>{
        console.log(payload , "payloadpayloadpayloadvas");
        const {referenceId , module , approvalId} = payload
        console.log(approvalId ,"executorsIndexexecutorsIndex")
    
        try {
          const currentState = current(state)
          if (currentState.data !== null) {
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data};
    
            keys.forEach((identifier) => {
              const current = currentState.data[identifier];
              const index = current.findIndex((y) => y.id === referenceId);
              const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
          
    
              if(index!==-1)
              {
                const updatedCurrent = [...current]
                let referenceItem = updatedCurrent[index]
    
                if(approvalIndex !==-1)
                {
                  let approvers =[ ...referenceItem.approvers]
                  let currentApprover = approvers[approvalIndex]
    
                  currentApprover = {
                    ...currentApprover,
                    reminderCount : currentApprover.reminderCount + 1,
                    lastReminder  : new Date().toISOString().slice(0, 19)
                  }
    
                  approvers[approvalIndex] = currentApprover
                  
                  referenceItem = {
                    ...referenceItem,
                    approvers : approvers
                  }
    
    
                }
    
                updatedCurrent[index] = referenceItem
    
                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
              state.data = updatedState;
          }
        } catch (e) {
          console.log(e, "Exceptions");
        }
      },
      handleAuctionApprovalRemrk:(state,{payload}) =>{
        try {
          const currentState = current(state);
          const { referenceId, remark } = payload;
          if (currentState.data !== null) {
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data};
  
            keys.forEach((identifier) => {
              const current = currentState.data[identifier];
              const index = current.findIndex((y) => y.id === referenceId);
              if (index !== -1) {
                const updatedCurrent = [...current]; // Create a deep copy of the current array
                let referenceItem = updatedCurrent[index];
  
                const approvers = [...referenceItem.approvers];
                const finalApprovers = PrepareApprovalsByNewRemark({
                  remark: remark,
                  approversData: approvers,
                });
  
                const approverStatus = Array.from(
                  new Set(finalApprovers.map((item) => item.status))
                );
  
                let newStatus = null;
              
                if(approverStatus.length > 1 )
                {
                   
                      if (approverStatus.length === 2 && approverStatus.includes(ApprovalStatus.Approved) && approverStatus.includes(ApprovalStatus.Removed)) {
                        newStatus = ApprovalStatus.Approved;
                    } else {
                        newStatus = ApprovalStatus.InProcess;
                    }
                
                }
                else
                {
                  newStatus = approverStatus[0] === ApprovalStatus.Removed ? ApprovalStatus.Approved : approverStatus[0]
                }

                
                referenceItem = {
                  ...referenceItem,
                  approvers: finalApprovers,
                  status: newStatus,
                  approverStatus:
                  newStatus,
                };
  
                updatedCurrent[index] = referenceItem;
  
                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
            state.data = updatedState;
          }
        } catch (e) {
          console.log(e, "Exceptionss");
        }
      }


  },
   extraReducers:(builder)=>{
        builder.addCase(GetAllAuctionAction.fulfilled,(state,action) =>{
            const { segmentType, identifier, filter } = action?.meta?.arg;
            const data = action.payload ?? []
            if (segmentType === SegmentTypeEnum.Grid) {
                state.data = {
                  ...state.data,
                  [identifier]: [...(state.data?.[identifier] || []), ...data],
                };
                state.recordSize = data.length;
              } else {
                state.data = {
                  ...state.data,
                  [identifier]: data.data,
                };
                state.totalRecords = action?.payload?.totalRecords;
              }
        });
        builder.addCase(getAllAuctionOfferAction.fulfilled,(state,action) =>{
        
          const { identifier } = action?.meta?.arg;
          const data = action.payload ?? []
          state.autionOfferData = {
            ...state.autionOfferData,
            [identifier]: [...(state.autionOfferData?.[identifier] || []), ...data],
          };
      });
        builder.addCase(getAuctionByIdAction.fulfilled, (state, action) => {
            const { identifier } = action?.meta?.arg;
            const data = action.payload.data ?? [];
      
            state.data = {
              ...state.data,
              [identifier]: [data],
            };
        });
        builder.addCase(getAuctionOfferByIdAction.fulfilled, (state, action) => {
          const { identifier } = action?.meta?.arg;
          const data = action.payload.data ?? [];
    
          state.autionOfferData = {
            ...state.autionOfferData,
            [identifier]: [data],
          };
      });
        builder.addCase(addAuctionAction.fulfilled, (state, { payload }) => {
          console.log(payload,"===payload===")
            state.newItem = payload.data;
        });
        builder.addCase(addAuctionMemberAction.fulfilled, (state, { payload }) => {
          let { auctionId } = payload?.[0];
          try {
            const currentState = current(state);
            if (currentState.data !== null) {
              const keys = Object.keys(currentState.data);
              let updatedState = { ...currentState.data };
    
              keys?.forEach((identifier) => {
                const current = currentState.data[identifier];
                const index = current.findIndex((y) => y.id === auctionId);
                if (index !== -1) {
                  const updatedCurrent = [...current]; // Create a deep copy of the current array
                  let referenceItem = updatedCurrent[index];
    
                  referenceItem = {
                    ...referenceItem,
                    members: [...referenceItem.members, ...payload],
                  };
    
                  updatedCurrent[index] = referenceItem;
    
                  updatedState = {
                    ...updatedState,
                    [identifier]: updatedCurrent,
                  };
                }
              });
              state.data = updatedState;
            }
          } catch (e) {
            console.log("===error===customApproval", e);
          }
        });
        builder.addCase(removeAuctionMemberAction.fulfilled, (state, action) => {
          let auctionId = action?.meta?.arg.id;
          let memberId = action?.meta?.arg?.payload?.[0];
    
          try {
            const currentState = current(state);
            if (currentState.data !== null) {
              const keys = Object.keys(currentState.data);
              let updatedState = { ...currentState.data };
    
              keys?.forEach((identifier) => {
                const current = currentState.data[identifier];
                const index = current.findIndex((y) => y.id === auctionId);
                if (index !== -1) {
                  const updatedCurrent = [...current]; // Create a deep copy of the current array
                  let referenceItem = updatedCurrent[index];
    
                  referenceItem = {
                    ...referenceItem,
                    members: referenceItem?.members.filter(
                      (item) => item.memberId !== memberId
                    ),
                  };
    
                  updatedCurrent[index] = referenceItem;
    
                  updatedState = {
                    ...updatedState,
                    [identifier]: updatedCurrent,
                  };
                }
              });
              state.data = updatedState;
            }
          } catch (e) {
            console.log("===error===customApproval", e);
          }
        });
        
   }
    
})

export const {
  handleResetState,
  handleNewItem,
  removeIdentifier,
  readCountforAuction,
  handleNotificationNewItem,
  handleAuctionReminder,
  handleAddAuctionApprovers,
  handleAuctionApprovalRemrk,
} = auctionSlice.actions;
export default auctionSlice.reducer;
