import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { readCountforBonus, removeIdentifier } from "../../store/slice";
import { Button, Skeleton, Tag } from "antd";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { BonusAmountTypeEnum } from "../../utils/bonusAmountTypeEnum";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import { GetBonusById } from "../../store/actions";
import { STRINGS } from "../../../../../utils/base";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { BonusStatusEnum } from "../../utils/bonusStatusEnum";
import { TransactionReferenceTypeEnum } from "../../../createVoucher/enum/TransactionReferenceTypeEnum";
import CustomModal from "../../../workboard/Modal/CustomModal";
import VoucherPrint from "../../../createVoucher/view/voucherPrintModal";
import PostVoucher from "../../../createVoucher/view/postVoucher/UI/PostVoucher";
import Disperse from "../../../disperse/view/UI/Disperse";
import { timeFromNow } from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";

export default function BonusDetail({ id, index }) {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, Direction, bonus } = dictionaryList[userLanguage];

  const [state, setState] = useState({
    identifier: moment().unix(),
    bonusDetail: {
      creator: {},
      approvers: [],
      status: 1,
      referenceNo: "",
      createDate: "",
      isLoading: true,
      amount: 0,
      value: 0,
      currentSalary: 0,
      member: {},
      memberId: STRINGS.DEFAULTS.guid,
      notificationCount: 0,
      voucherNo: "",
      voucherId: STRINGS.DEFAULTS.guid
    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data } = useSelector((state) => state.bonusSlice);
  let bonusDetail = data !== null ? data[state.identifier] : [];
  bonusDetail = bonusDetail && bonusDetail?.length > 0 && bonusDetail[0];

  useEffect(() => {
    id && dispatch(GetBonusById({ id: id, identifier: state.identifier }));
  }, []);

  useEffect(() => {
    bonusDetail &&
      setState({
        ...state,
        bonusDetail: {
          ...state.bonusDetail,
          ...bonusDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: bonusDetail?.approvers,
        },
      });
  }, [bonusDetail]);

  const {
    creator,
    status,
    referenceNo,
    createDate,
    type,
    value,
    currentSalary,
    member,
    amount,
    notificationCount,
    voucherNo = "",
    voucherId = STRINGS.DEFAULTS.guid
  } = state.bonusDetail;
  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);




  //close Approvers Modal
  const onCloseApproversModal = (status) => {
    setState({
      ...state,
      approvers: {
        ...state.approvers,
        isOpen: status,
      },
    });
  };


  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Bonus,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforBonus(id))
      })
    }
  }, [notificationCount])


  const handleOpenPrintVoucher = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenModal(true);
  }
  if (state.bonusDetail.isLoading) return <Skeleton />;


  return (
    <>
      {
        <ItemDetailModal
          data={state.approvers.data} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={state.approvers.isOpen}
          setVisible={(da) => onCloseApproversModal(da)}
        />
      }

      <div className="detailedViewComposer">
        <div className="detailedCard ">
          <div className="item-header">
            <div className="left">
              {creator && (
                <UserInfo
                  avatarSrc={creator?.image}
                  name={creator?.name}
                  status={creator?.userActiveStatus}
                  profileId={creator?.id}
                  Subline={
                    <SublineDesigWithTime
                      designation={
                        creator.designation ? creator?.designation : ""
                      }
                      time={timeFromNow(createDate)}
                    />
                  }
                />
              )}
            </div>
            <div className="right">
              <Tag className="IdTag">{referenceNo}</Tag>
              <StatusTag status={status}></StatusTag>
            </div>
          </div>

          <div className="flex justify-end">
            {status === BonusStatusEnum.Approved && (

              <Disperse
                label={sharedLabels.disburse}
                id={id}
                TransactionReferenceType={TransactionReferenceTypeEnum.Bonus}
              />

            )}

            {voucherId === STRINGS.DEFAULTS.guid && (status === BonusStatusEnum.Disbursed || status === BonusStatusEnum.Completed) && (
              <PostVoucher
                label="Post Voucher"
                id={id}
                TransactionReferenceType={TransactionReferenceTypeEnum.Bonus}
              />
            )}
          </div>
          <div className="cardSections" style={{ marginTop: "20px" }}>

            <div className="cardSectionItem">
              <div className="cardSection__title">{sharedLabels.amount}</div>
              <div className="cardSection__body">{amount}</div>
            </div>


            <div className="cardSectionItem">
              <div className="cardSection__title">{bonus.BonusTo}</div>
              <div className="cardSection__body">{member && member.name}</div>
            </div>




            {voucherId !== STRINGS.DEFAULTS.guid && (
              <div className="cardSectionItem">
                <div className="cardSection__title"> {"Voucher No"}</div>
                <div className="cardSection__body">
                  {voucherNo && <Button type="link" className="!text-[12px] !mt-[-6px] !text-[var(--currentThemeColor)] !font-semibold" onClick={(e) => handleOpenPrintVoucher(e)}
                  >{voucherNo}</Button>
                  }
                </div>
              </div>
            )}

          </div>
        </div>
        {/* Remarks Approval */}
        <RemarksApproval
          module={ApprovalsModule.BonusApproval}
          status={status}
          reference={bonusDetail?.id}
          onStatusChanged={(prev) => { }}
          setApprovalDetail={(data) => { }}
          data={state.approvers.data && state.approvers?.data}
          title={sharedLabels.approvers}
          notRequiredMemberId={[state.bonusDetail.memberId]}
          itemCreator={creator}
        />

        <CustomModal
          isModalVisible={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          width={"70%"}
          title={"Voucher"}
          footer={null}
          children={<VoucherPrint id={voucherId} />}
          className={""}
        />
      </div>
    </>
  );
}
