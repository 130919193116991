import { ResponseResultError, ResponseResultSuccess } from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";

export const getDisburseDetailSuggestionService = async (id, referenceType) => {
    try {
        const {
            data: { responseCode, data, message },
        } = await Config.get(
            `api/Disburse/GetDisburseDetailSuggestion?id=${id}&referenceType=${referenceType}`
        );
        if (responseCode === 1001) return ResponseResultSuccess(data);
        return false;
    } catch (e) {
        return ResponseResultError(e);
    }
};

export const submitDisburseService = async (payload) => {
    try {
        const {
            data: { responseCode, data, message },

        } = await Config.post(
            `api/Disburse/SubmitDisburse`, payload
        );
        if (responseCode === 1001) return ResponseResultSuccess(data);
        return ResponseResultError(message);
    } catch (e) {
        return ResponseResultError(e);
    }

};