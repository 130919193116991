export const JobShiftTypeEnum = [
    {
        label: "Day",
        value: 1,
    },
    {
        label: "Morning",
        value: 2,
    },
    {
        label: "AfterNoon",
        value: 3,
    },
    {
        label: "Night",
        value: 4,
    },
    {
        label: "Other",
        value: 5,
    },
];


export const JobShiftNameTypeEnum = {
    1: "Day",
    2: "Morning",
    3: "AfterNoon",
    4: "Night",
    5: "Other"
};
export const JobShiftTypeEnums = {
    Day: 1,
    Morning: 2,
    AfterNoon: 3,
    Night: 4,
    Other: 5,
}