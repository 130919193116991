import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Drawer } from "antd";
import ScheduleComposerDetail from "./ScheduleComposerDetail";
import { handleResetState } from "../../../travel/store/slice";
import TaskDetailItem from "../../../task/view/Page/Detail/DetailItem";
import TravelDetail from "../../../travel/view/Page/Detail/DetailItem/travelDetail";
import { ScheduleTypeEnum } from "../../enum/enum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
// import { toggleEventDetailComposer } from "../../store/slice";

function ScheduleComposer({ onClose, id, visible, type, Direction }) {


  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule, travel, tasks } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (type === ScheduleTypeEnum.Travel) {
        dispatch(handleResetState());
      }
    };
  }, [id]);
  return (
    <Drawer
      title={
        type === ScheduleTypeEnum.Travel
          ? travel.TravelDetail
          : type === ScheduleTypeEnum.Task
            ? tasks.TaskDetail
            : schedule.ScheduleDetail
      }
      placement={Direction === "ltr" ? "right" : "left"}
      width="768"
      onClose={() => {
        onClose();
      }}
      open={visible}
      destroyOnClose={true}
      className=" drawerSecondary"
    >
      {type === ScheduleTypeEnum.Travel ? (
        <TravelDetail id={id} />
      ) : type === ScheduleTypeEnum.Task ? (
        <TaskDetailItem id={id} />
      ) : (
        <ScheduleComposerDetail id={id} shortEvent={false} onClose={onClose} />
      )}
    </Drawer>
  );
}

export default ScheduleComposer;
