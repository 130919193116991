import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { addPayrollService, getAllPayrollService, getCalculatedPayrollService, getAllPayrollpagingService, getPayrollByIdService } from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { message } from "antd";
import { handleUnauthorized } from "../../../../utils/base";

export const getCalculatedPayroll = createAsyncThunk(
  "Payroll/getCalculatedPayroll",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await getCalculatedPayrollService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage
          }
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllPayroll = createAsyncThunk(
  "Payroll/getAllPayroll",
  async (
    { filter, pageSize, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllPayrollpagingService({ ...filter, pageSize });
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      else if(response.responseCode  === 1011) handleUnauthorized();
      return response.data;
    } else {
      const response = await getAllPayrollService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      else if(response.responseCode  === 1011) handleUnauthorized();
      return response.data;
    }

  }
);

export const addPayroll = createAsyncThunk(
  "Payroll/addPayroll",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await addPayrollService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage
          }
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(openNotification({
          message: "Payroll Create Successfully",
          type: "success",
          duration: 2
        }))
        return response.data;
      default:
        return;
    }
  }
);

export const getPayrollById = createAsyncThunk(
  "Payroll/getPayrollById",
  async ({ id }) => {
    const response = await getPayrollByIdService(id);
    return response.data;
  }
);