import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { ImgContainer } from "../../../../utils/formImageContainer";

const TextFields = (props) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { forms } = dictionaryList[userLanguage];
  const { formQuestionTypeEnum } = forms;
  const { fieldData, handleChange, index, disableSubmit, required } = props;
  return (
    <>
      <div className="c-row txt-fields bg-clr p_15">
        <label className={` ${required && "required"}  label`} htmlFor="">
          {fieldData.question}
          <span className="text-sm">
            {` (${formQuestionTypeEnum?.[fieldData.answerType]})`}
          </span>
        </label>
        {fieldData.image && (
          <div className="w-full flex justify-center">
            {ImgContainer(fieldData.image, "250px")}
          </div>
        )}
        <input
          onChange={(e) => handleChange(e.target.value, index)}
          className="styled-input"
          type={formQuestionTypeEnum?.[fieldData.answerType]}
          placeholder={forms.YourAnswer}
          disabled={disableSubmit}
        />
      </div>
    </>
  );
};

export default TextFields;
