import React, { useEffect, useState } from 'react'
import CustomTag1 from '../../../SuggestionBox/View/CustomTag1';
import TableContainer from '../../../../features/membersDetail/view/page/listing/table';
import { useDispatch } from 'react-redux';
import { getAllApprovalReminder } from '../action/action';
import { useSelector } from 'react-redux';
import { timeAgoFromNow } from '../../../../../utils/Shared/helper/timeAgoFromNow';

const ReminderDetail = ({ approvalId , module , key }) => {
  
    const { AllreminderApproval , ReminderLoader} = useSelector(({ ApproverSlice }) => ApproverSlice)

const dispatch = useDispatch()




   useEffect(()=>{
    const requestObj = {
        approvalId : approvalId , module : module
    }
  dispatch(getAllApprovalReminder(requestObj))
},[approvalId ])




  const reminderColumn = ()=>{
    return [

      {
          title: "",
          dataIndex: "creator",
          ellipsis: true,
          render: (creator, record) => {
              return (

                  <CustomTag1 selectedUser={{ image: creator?.image, name: creator?.name, designation: creator?.designation }} />
              )

          },
          width: "65%",
          sort: true,
      },
      {
          title: "",
          dataIndex: "createDate",
          defaultSortOrder: "descend",

          ellipsis: true,
          render: (createDate, record) => {
              console.log(record, "onTableHoveronTableHover");
              return (

                <div className='remindertext'>{timeAgoFromNow(createDate)}</div>
              )
          },
          width: "35%",
          sort: true,
      },


  ];
  }


  return (
    <>
    <TableContainer showHeader={false} data={ReminderLoader ? [] :AllreminderApproval } columns={reminderColumn()} isloading={ReminderLoader}/>
    </>
  )
}

export default ReminderDetail