import { useContext, useEffect, useState } from "react";
import { STRINGS } from "../../../../../utils/base";
import { TransactionReferenceTypeEnum } from "../../../createVoucher/enum/TransactionReferenceTypeEnum";
import CreateEntryTableHead from "./CreateEntryTableHead";
import { useDispatch } from "react-redux";
import { getDisburseDetailSuggestion, submitDisburse } from "../../store/actions";
import CreateEntryItem from "./CreateEntryItem";
import { Button } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";

const DisperseSuggestion = ({ id = STRINGS.DEFAULTS.guid,
    referenceType = TransactionReferenceTypeEnum.General,
    onCancel = () => { } }) => {
    const [disburseData, setDisburseData] = useState([]);

    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, Direction, disperse } = dictionaryList[userLanguage];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDisburseDetailSuggestion({ id, referenceType })).then((res) => {
            setDisburseData(res.payload);
        })
    }, [])
    const handleTransactionModeChange = (index, newMode) => {
        setDisburseData(currentData =>
            currentData.map((item, idx) =>
                idx === index ? { ...item, transactionMode: newMode } : item
            )
        );
    };
    const handleChequeChange = (index, value) => {
        setDisburseData(currentData => currentData.map((item, idx) =>
            idx === index ? { ...item, chequeNo: value } : item
        )

        );
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(submitDisburse(
            disburseData?.map((item) => {
                return {
                    referenceId: item?.referenceId,
                    referenceType: item?.referenceType,
                    transactionMode: parseInt(item?.transactionMode),
                    userId: item?.userId,
                    description: item?.description ? item?.description : "",
                    chequeNo: item?.chequeNo ? item?.chequeNo : "",
                    amount: item?.amount,
                    bankAccountId: item?.bankAccountId ? item?.bankAccountId : STRINGS.DEFAULTS.guid
                }
            })


        )).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                onCancel();
            }
        })
    }


    return (<>
        <div className="createEntryTable">

            <div className="bg-white p-4 rounded-md overflow-x-auto">
                <table >
                    <CreateEntryTableHead />
                    <tbody>
                        {disburseData?.map((item, ind) => {
                            return (
                                <CreateEntryItem
                                    key={ind}
                                    index={ind}
                                    value={item}
                                    setChequeNo={(value) => handleChequeChange(ind, value)}
                                    setTransactionMode={(mode) => handleTransactionModeChange(ind, mode)}
                                />
                            );
                        })}
                    </tbody>
                </table>


            </div>
            <div className="!flex !justify-end !items-end !ml-auto">
                <Button className="ThemeBtn mr-2" onClick={handleSubmit}>
                    {sharedLabels.Submit}
                </Button>
            </div>
        </div>
    </>)
}
export default DisperseSuggestion;