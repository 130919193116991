import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addForm,
  getAllForms,
  getFormById,
  submitForm,
  updateForm,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
  forms: [],
  loadingData: false,
  createLoader: false,
  loader: false,
  createSuccess: false,
  success: false,
  error: false,
  formDetail: {},
  listLoading: false,
  currentTab: "allDocuments",
  isOpenComposers: {
    folder: false,
    upload: false,
    milegrid: false,
    milepad: false,
    mileboard: false,
    mileshow: false,
  },
  approvers: [],
  //new
  data: null,
  newItem: null,
  totalRecords: [],
  recordSize: 0,
  newCountFilterButton: false,
};

const formSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    //new
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        // state.data[identifier] = state.data?.[identifier].concat([state.newItem]);
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    handleNotificationNewItem: (state, { payload }) => {
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === payload.featureId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log("===error===customApproval", e);
      }
    },

    readCountforForm: (state, { payload }) => {
      const id = payload;
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id;
        }
      } catch (e) {
        console.log("===error===customApproval", e);
      }
    },
    handleFormApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              let newStatus = null;

              if (approverStatus.length > 1) {
                if (
                  approverStatus.length === 2 &&
                  approverStatus.includes(ApprovalStatus.Approved) &&
                  approverStatus.includes(ApprovalStatus.Removed)
                ) {
                  newStatus = ApprovalStatus.Approved;
                } else {
                  newStatus = ApprovalStatus.InProcess;
                }
              } else {
                newStatus =
                  approverStatus[0] === ApprovalStatus.Removed
                    ? ApprovalStatus.Approved
                    : approverStatus[0];
              }

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: newStatus,
                approverStatus: newStatus,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptionss");
      }
    },

    handleAddFormsApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [...referenceItem?.approvers];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    ////
    handleOpenDocComposer: (state, { payload: key }) => {
      state.isOpenComposers[key] = true;
    },
    handleCloseDocComposer: (state, { payload: key }) => {
      state.isOpenComposers[key] = false;
    },
    handleChangeTab: (state, { payload: tab }) => {
      state.currentTab = tab;
    },
    updateApprover: (state, action) => {
      const { stateapprovalDetail, index } = action.payload;
      if (stateapprovalDetail?.length > 0) {
        if (
          state.approvers[index][0]?.referenceId ===
          stateapprovalDetail[0].referenceId
        ) {
          state.approvers[index] = stateapprovalDetail;
        }
      }
    },

    handleFormReminder: (state, { payload }) => {
      console.log(payload, "payloadpayloadpayloadvas");
      const { referenceId, module, approvalId } = payload;
      console.log(approvalId, "executorsIndexexecutorsIndex");

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(
              (item) => item.id === approvalId
            );

            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              if (approvalIndex !== -1) {
                let approvers = [...referenceItem.approvers];
                let currentApprover = approvers[approvalIndex];

                currentApprover = {
                  ...currentApprover,
                  reminderCount: currentApprover.reminderCount + 1,
                  lastReminder: new Date().toISOString().slice(0, 19),
                };

                approvers[approvalIndex] = currentApprover;

                referenceItem = {
                  ...referenceItem,
                  approvers: approvers,
                };
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllForms.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
          state.recordSize = data.length;
        } else {
          console.log(action.payload, "payloaddd tableee");
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
          state.totalRecords = action?.payload?.totalRecords;
        }
      })
      .addCase(updateForm.fulfilled, (state, { payload }) => {
        // console.log(payload, "payload of update appraisal question");
        state.loader = false;
        state.forms = state.forms.map((x) =>
          x.id === payload.data.data.id ? payload.data.data : x
        );
      })
      .addCase(submitForm.fulfilled, (state, { payload }) => {
        state.success = true;
        state.createLoader = false;
      })
      .addCase(getFormById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];

        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })
      .addMatcher(isPending(...[getAllForms]), (state) => {
        // console.log("its pending");
        state.loader = true;
      })
      .addMatcher(isPending(...[submitForm]), (state) => {
        // state.createLoader = true;
        console.log("pending");
        // state.createSuccess = false;
      })
      .addMatcher(isPending(...[getFormById]), (state) => {
        // console.log("its pending form by ids");
        state.loader = true;
      });
  },
});

export const {
  handleOpenDocComposer,
  handleCloseDocComposer,
  handleChangeTab,
  updateApprover,

  //neww
  removeIdentifier,
  handleResetState,
  handleNewItem,
  handleNotificationNewItem,
  readCountforForm,
  handleFormApprovalRemark,
  handleAddFormsApprover,
  handleFormReminder,
} = formSlice.actions;
export default formSlice.reducer;
