import { Modal } from "antd";
import React, { useContext, useState } from "react";
import PayBillAuthentication from "./payBillAuthentication";
import "../../../auth/view/styles/style.css";

export default function PayBill({ isOpen, onClose, setIsAuthenticated, setAuthToken }) {
  return (
    <>
      <Modal
        visible={isOpen}
        onCancel={onClose}
        footer={null}
        className="payBillAuthModal !w-fit"
        closable={false}
      >
        <div className="center-area">
          <PayBillAuthentication
            setIsAuthenticated={setIsAuthenticated}
            onClose={onClose}
            setAuthToken = {setAuthToken}
          />
        </div>
      </Modal>
    </>
  );
}
