import {ReferenceTypeEnum} from "./utils/referenceTypeEnum";
import {STRINGS} from "../../../utils/base";
import {Link} from "react-router-dom";

function ReferenceRoute({
            id = STRINGS.DEFAULTS.guid,
            name="",type = ReferenceTypeEnum.User
}) {
    const url = `${STRINGS.ROUTES.USER.DEFAULT}${id}`;
    return(<Link to={url}>{name}</Link>);

}
export default ReferenceRoute;