import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  addProjectFeatureService,
  addProjectService,
  getAllProjectsService,
  getProjectByIdService,
  removeProjectFeatureService,
  updateProjectService,
  getAllProjectStickyService,
  getAllProjectMemberService,
  addProjectMemberService,
  deleteProjectMemberService,
  addProjectFavoriteService,
  getProjectFeatureService,
  saveStickyNoteProject,
  addProjectTagService,
  getAllProjectTagService,
  updateProjectTagService,
  removeProjectTagService,
  addProjectTagMemberService,
  removeProjectTagMemberService,
  addProjectAccessRoleService,
  getAllProjectAccessRolesService,
  updateProjectAccessRoleByIdService,
  getProjectAccessRoleByIdService,
  AssignProjectTagToMemberService,
  AddProjectExternalService,
  AssignProjectAccessRoleToMemberService,
  AssignProjectMemberToAccessRoleService,
  getAllProjectsPaginationService,
  GetAllProjectUserPermissionsService,
} from "../services/service";
import { message } from "antd";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
  deleteProjectMember,
  addProjectMember,
  deleteProjectFeature,
  ProjectTagMember,
  deleteProjectTagMember,
  checkIsAdminPresent,
} from "../store/slice";
import { ActionType } from "../../../sharedComponents/CustomModal";
import { ROUTES } from "../../../../utils/routes";

export const getAllProjects = createAsyncThunk(
  "getAllProject",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllProjectsService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(checkIsAdminPresent(false));

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addProject = createAsyncThunk(
  "addProject",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addProjectService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Project Created Successfully!",
          type: "success",
          duration: 2,
          actionType: ActionType.Route,
          actionData: {
            path: `${ROUTES.PROJECT.DEFAULT}/${res.data.id}`,
          },
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateProject = createAsyncThunk(
  "updateProject",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateProjectService(data);
    console.log(res, "responseeeeee");
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Project Updated Successfully!",
          type: "success",
          duration: 2,
        })
      );
      return res.data;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getProjectById = createAsyncThunk(
  "getProjectById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getProjectByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res);
    }
  }
);

export const removeProjectFeature = createAsyncThunk(
  "removeProjectFeature",
  async (data, { dispatch }) => {
    const res = await removeProjectFeatureService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        // responseMessage({ dispatch, data: res });
        dispatch(deleteProjectFeature(data));
      return data;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addProjectFeature = createAsyncThunk(
  "addProjectFeature",
  async (id, { dispatch }) => {
    const res = await addProjectFeatureService(id);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const saveStickyproject = createAsyncThunk(
  "saveStickyProject",
  async (data, { dispatch }) => {
    const res = await saveStickyNoteProject(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getProjectSticky = createAsyncThunk(
  "getSticky",
  async (data, { dispatch }) => {
    const res = await getAllProjectStickyService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getAllProjectMemberAction = createAsyncThunk(
  "getMember",
  async (id, { dispatch }) => {
    const res = await getAllProjectMemberService(id);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addProjectMemberAction = createAsyncThunk(
  "addMember",
  async (data, { dispatch }) => {
    const res = await addProjectMemberService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        dispatch(addProjectMember(res.data));
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const deleteProjectMemberAction = createAsyncThunk(
  "deleteMember",
  async (data, { dispatch }) => {
    const res = await deleteProjectMemberService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        dispatch(deleteProjectMember(data));
      return data.memberId;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addProjectFavoriteAction = createAsyncThunk(
  "addFavorite",
  async (data, { dispatch }) => {
    const res = await addProjectFavoriteService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getProjectFeature = createAsyncThunk(
  "getProjectFeature",
  async (id, { dispatch }) => {
    const res = await getProjectFeatureService(id);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addProjectTag = createAsyncThunk(
  "project/addProjectTag",
  async (args, { dispatch }) => {
    const res = await addProjectTagService(args);
    console.log(res.data, "actionData");
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = "Project Tag added successfully!";
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res.data;
  }
);

export const getAllProjectTag = createAsyncThunk(
  "project/getAllCustomTag",
  async (data) => {
    const response = await getAllProjectTagService(data);
    console.log(response, "responseeeeee");

    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);

export const updateProjectTag = createAsyncThunk(
  "project/updateprojectTag",
  async (args, { dispatch }) => {
    const res = await updateProjectTagService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = "ProjectTag updated successfully!";
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const removProjectTag = createAsyncThunk(
  "Project/removeProjectTag",
  async (args, { dispatch }) => {
    const res = await removeProjectTagService(args.id);

    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = "ProjectTag removed successfully!";
      message.success(res.message);

      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const deleteProjectTagMemberAction = createAsyncThunk(
  "deleteProjectTagMember",
  async (data, { dispatch }) => {
    const res = await removeProjectTagMemberService(data);
    console.log(res, "ressssspeee");
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        dispatch(deleteProjectTagMember(data));
      return data.memberId;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addProjectTagMember = createAsyncThunk(
  "project/addProjectTagMember",
  async (data, { dispatch }) => {
    const res = await addProjectTagMemberService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        dispatch(ProjectTagMember(res.data));
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        type: responseMessageType.ApiFailure,
      });
    }

    return res.data;
  }
);

/* ACCESS ROLE */

export const addProjectAccessRole = createAsyncThunk(
  "addAccessRole",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await addProjectAccessRoleService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Access Role added successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAllProjectAccessRoles = createAsyncThunk(
  "getAllAccessRoles",
  async (data, { dispatch, getState }) => {
    const res = await getAllProjectAccessRolesService(data);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const updateProjectAccessRoleById = createAsyncThunk(
  "updateAccessRoleById",
  async (data, { dispatch }) => {
    const res = await updateProjectAccessRoleByIdService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Access Role updated successfully!");
    }
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getProjectAccessRoleById = createAsyncThunk(
  "getAccessRoleById",
  async (data, { dispatch }) => {
    const res = await getProjectAccessRoleByIdService(data);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const assignProjectTagToMember = createAsyncThunk(
  "assignProjectTagToMember",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AssignProjectTagToMemberService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Tags added successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);

export const AddProjectExternalAction = createAsyncThunk(
  "assignProjectTagToMember",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AddProjectExternalService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Emails are added Successfully!",
          type: "success",
          duration: 3,
        })
      );
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
    }
  }
);

export const AssignProjectAccessRoleToMember = createAsyncThunk(
  "AssignProjectAccessRoleToUser",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AssignProjectAccessRoleToMemberService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Acces Role added successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);

export const AssignProjectMemberToAccessRole = createAsyncThunk(
  "AssignProjectMemberToAccessRole",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AssignProjectMemberToAccessRoleService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Members added successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAllProjectsPagination = createAsyncThunk(
  "getAllProjectsPagination",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllProjectsPaginationService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const GetAllProjectUserPermissionsAction = createAsyncThunk(
  "GetAllProjectUserPermissions",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await GetAllProjectUserPermissionsService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      message.error(res.message);
      return rejectWithValue(res.message);
    }
  }
);
