import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApprovalStatus } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { saveApprovalsRemarks } from "../../../../sharedComponents/AppComponents/Approvals/services";
import { getAllApproval, getAllDefaultApproval } from "../../store/action";
import ConfirmationRemarkModal from "../../../../sharedComponents/ConfirmationRemarkModal/ConfirmationRemarkModal";
import { removeApprovalItem } from "../../store/slice";
import { useLocation } from "react-router-dom";

export default function ApprovalActions({ item, onDClose}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(ApprovalStatus.InProcess);
  const isMyApprovals = location.pathname.includes("myApprovals");
  const [modalKey, setModalKey] = useState(Date.now()); // Key for re-rendering ConfirmationRemarkModal

  const defaultFilter = {
    pageNo: 1,
    search: "",
    status: [ApprovalStatus.InProcess],
    pageSize: 10
  };
  const [filter, setFilter] = useState(defaultFilter);

  const handleAction = (e, status) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(true);

    setCurrentStatus(status);
  };

  const createRemark = async (status, remarksValue) => {
    let isMyApproval = true;
    const remarks = {
      approvalId: item.id,
      remark: remarksValue,
      module: item.module,
      status: status,
      type: item.approverType,
      attachments: [],
    };

    const remark = await saveApprovalsRemarks(remarks);
    if (remark) {
      dispatch(removeApprovalItem({ id:item.id,remarks,isMyApprovals}))
      // dispatch(
      //   getAllApproval({
      //     isMyApproval,
      //     filter,
      //   })
      // );
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onFinish = (values) => {
    let remarks = values.remarks;
    setIsOpen(false);
    createRemark(currentStatus, remarks);
    setModalKey(Date.now());
    onDClose(currentStatus);
  };

  return (
    <>
      <div className="approval_item_status">
        <div
          className="accept"
          onClick={(e) => handleAction(e, ApprovalStatus.Approved)}
        >
          Accept
        </div>
        <div
          className="decline"
          onClick={(e) => handleAction(e, ApprovalStatus.Declined)}
        >
          Decline
        </div>
        <div
          className="hold"
          onClick={(e) => handleAction(e, ApprovalStatus.Hold)}
        >
          Hold
        </div>
      </div>

      <ConfirmationRemarkModal
        key={modalKey} // Re-render ConfirmationRemarkModal when key changes
        isOpen={isOpen}
        onCancel={onClose}
        onFinish={onFinish}
      />
    </>
  );
}
