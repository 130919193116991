export const getAllRequisitionOffer_dto = (data) => {
  return {
    pageNo: data.filter.pageNo ? data.filter.pageNo : 1,
    pageSize: data.filter.pageSize ? data.filter.pageSize : 20,
    search: data.filter.search ? data.filter.search : "",
    status: data.filter.status ? data.filter.status : "",
    requisitionIds: data.filter.requisitionIds
      ? data.filter.requisitionIds
      : [],
  };
};
