import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addTravel,
  getAllTravel,
  getAllTravelPaging,
  getTravelById,
  getAllTravelAction,
  UpdateTravelAction,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { TravelApprovalsModuleEnum } from "../utils/travelApprovalsModuleEnum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { TravelApprovalsModuleStatusEnum } from "../utils/travelApprovalsModuleStatusEnum";
import { calculateFinalStatus } from "../utils/calculateStatus";

const initialState = {
  data: null,
  newItem: null,
  newCountId:null,
};

const travelSlice = createSlice({
  name: "travel",
  initialState,
  reducers: {

    handleResetState: (state, { payload }) => {
      state.data = [];
    },
        removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined" ) delete state?.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined" )
        state.data[identifier] = [state.newItem].concat(state.data?.[identifier]);
    },
    readCountforTravel:(state,{payload})=>{
      const id = payload
      const currentState = current(state);
      try{
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index]

              referenceItem = {
                ...referenceItem,
                notificationCount:0
              };
              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
          state.newCountId = id
        }
      }
      catch(e)
      {

      }
    },
    handleTravelApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark, module } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              const approvers = [
                ...referenceItem[TravelApprovalsModuleEnum[module]],
              ];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                [TravelApprovalsModuleEnum[module]]: finalApprovers,
                [TravelApprovalsModuleStatusEnum[module]]:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };
              const statusEnums = Object.values(
                TravelApprovalsModuleStatusEnum
              );
              const statuses = statusEnums.map(
                (statusKey) => referenceItem[statusKey]
              );

              referenceItem = {
                ...referenceItem,
                status: calculateFinalStatus(statuses),
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleAddTravelApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem[TravelApprovalsModuleEnum[module]],
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                [TravelApprovalsModuleEnum[module]]: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }

    },
    handleNotificationNewItem: (state, { payload }) => {
      
      try{
        const currentState = current(state);
        if(currentState.data!==null)
        {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys?.forEach((identifier)=>{
            const current = currentState.data[identifier]
            const index  = current.findIndex(y => y.id === payload.featureId)
            if(index !==-1)
            {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount:referenceItem.notificationCount+1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        
        }
      }
      catch(e)
      {
        console.log("===error===",e)
      }    },
      handleTravelReminder : (state , {payload}) =>{
        console.log(payload , "payloadpayloadpayloadvas");
        const {referenceId , module , approvalId} = payload
        console.log(approvalId ,"executorsIndexexecutorsIndex")
  
        try {
          const currentState = current(state)
          if (currentState.data !== null) {
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data};
  
            keys.forEach((identifier) => {
              const current = currentState.data[identifier];
              const index = current.findIndex((y) => y.id === referenceId);
              const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
              const agentsIndex = current[index].agents.findIndex(item => item.id == approvalId)
  
              if(index!==-1)
              {
                const updatedCurrent = [...current]
                let referenceItem = updatedCurrent[index]
  
                if(approvalIndex !==-1)
                {
                  let approvers =[ ...referenceItem.approvers]
                  let currentApprover = approvers[approvalIndex]
  
                  currentApprover = {
                    ...currentApprover,
                    reminderCount : currentApprover.reminderCount + 1,
                    lastReminder  : new Date().toISOString().slice(0, 19)
                  }
  
                  approvers[approvalIndex] = currentApprover
                  
                  referenceItem = {
                    ...referenceItem,
                    approvers : approvers
                  }
  
  
                }
                else if(agentsIndex !==-1)
              {
                let agents =[ ...referenceItem.agents]
                let currentagent = agents[agentsIndex]

                currentagent = {
                  ...currentagent,
                  reminderCount : currentagent.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                agents[agentsIndex] = currentagent
                
                referenceItem = {
                  ...referenceItem,
                  agents : agents
                }
              }
                updatedCurrent[index] = referenceItem
  
                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
              state.data = updatedState;
          }
        } catch (e) {
          console.log(e, "Exceptions");
        }
        }
     },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTravelAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
        }
      })
      .addCase(addTravel.fulfilled, (state, { payload }) => {
        state.newItem = payload.data;
      })
      .addCase(UpdateTravelAction.fulfilled, (state,  {payload} ) => {
        try {
          const { id } = payload?.data;
          if (state.data !== null) {
            const keys = Object.keys(state.data);

            keys.forEach((identifier) => {
              const current = state.data[identifier];
              const index = current.findIndex((y) => y.id === id);
              let item = state.data[identifier][index];
              if (index !== -1) {
                state.data[identifier][index] = {
                  ...payload?.data,
                  attachments: item?.attachments,
                  approvers: item?.approvers,
                  agents: item?.agents,
                  creator: item?.creator,
                  members: item?.members
                };
              }
            });
          }
        } catch (e) {
          console.log(e, "Exceptions");
        }
      })
      .addCase(getTravelById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      });
  },
});

export const {
  removeIdentifier,
  handleResetState,
  handleNewItem,
  handleTravelApprovalRemark,
  handleAddTravelApprover,
  readCountforTravel,
  handleNotificationNewItem,
  handleTravelApproval,
  handleTravelReminder
} = travelSlice.actions;
export default travelSlice.reducer;
