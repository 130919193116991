import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addLeadManager,
  addLeadManagerDetailMember,
  addLeadManagerContact,
  addLeadManagerDetail,
  getAllLeadManager,
  getAllLeadManagerContactDetail,
  getAllLeadManagerPaging,
  getLeadManagerById,
  getLeadManagerDetailById,
  getLeadManagerSectionById,
  updateLeadManager,
  updateLeadManagerContact,
  updateLeadManagerDetail,
  getAllScheduleAction,
  getScheduleByIdAction,
  addLeadManagereMember,
  deleteLeadManagerMemberById,
  deleteLeadManagerDetailMember,
  getAllLeadManangerDetailPaging,
  GetAllLeadManagerDetailCallAction,
  AddLeadManagerDetailCallAction,
} from "./actions";

const initialComposerData = {
  name: "",
  description: "",
  members: [],
  attachments: [],
  privacyId: 1,
  image: "",
};

const initialState = {
  LeadManagerDetailCallData: [],
  isLoadingLeadManagerDetailCallData: false,
  success: false,
  error: false,
  loading: false,
  leadManagersData: [],
  memberData: [],
  leadManagerDetail: null,
  leadManagerSectionDetailData: null,
  isSectionDetailLoading: false,
  isComposerOpen: false,
  isEditComposer: false,
  composerData: initialComposerData,
  isComposerDataLoading: false,
  leadManagerSections: [],
  isContactDetailLoading: false,
  contactDetail: null,
  toggleForm: false,
  contactLeadManagerDetail: [],
  meetingDetail: [],
  meetingDetailComposer: {},
  isMeetingDetailLoading: false,

  isContactUpdated: false,
  contactDataUpdating: false,
  isAssignMemberModalOpen: false,
  assignToMemberId: "",
  isSectionModalOpen: false,
  addMemberModal: false,
  addAssignMemberModal: false,
  removeMemberSuccess: false,

  contactModal: {
    isOpen: false,
    add: false,
  },
  composeEmail: false,
  drawerOpen: false,
  scheduleComposerData: null,
  addLeadManagerLoader: false,
  totalRecords: [],
  recordSize: 0,
  leadDetailTableData: [],
  newCountFilterButton: false

};
const leadMangerSlice = createSlice({
  name: "leadManager",
  initialState,
  reducers: {
    handleChangeLeadValueVisibility: (state, { payload }) => {
      const { id } = payload

      try {
        const index = state.leadDetailTableData.findIndex(item => item.id === id)
        if (index !== -1) {

          const leadData = [...state.leadDetailTableData]
          let currentObject = leadData[index]

          currentObject = {
            ...currentObject,
            isShowLeadValue: !currentObject.isShowLeadValue
          }

          leadData[index] = currentObject

          state.leadDetailTableData = leadData
        }
      }
      catch (e) {
        console.log(e)
      }


    },
    toggleAllLeadValue: (state, { payload }) => {
      const { isVisible } = payload
      state.leadDetailTableData = state.leadDetailTableData.map(item => {
        return {
          ...item, isShowLeadValue: isVisible
        }
      })
    },

    handleFormDetail: (state, { payload }) => {
      state.toggleForm = payload;
    },
    handleResetState: (state, { payload }) => {
      state.leadManagersData = [];
      state.leadDetailTableData = [];
      state.totalRecords = 0;
    },
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    addMember: (state, { payload }) => {
      state.addMemberModal = payload;
    },
    addAssignMember: (state, { payload }) => {
      state.addAssignMemberModal = payload;
    },
    toggleEventDetailComposer: (state, { payload }) => {
      state.meetingDetailComposer = !state.meetingDetailComposer;
      state.scheduleComposerData = payload;
    },
    handleAssignMemberModal(state, { payload }) {
      state.assignToMemberId = payload.id;
      state.isAssignMemberModalOpen = !state.isAssignMemberModalOpen;
    },
    handleComposeEmail(state, { payload }) {
      state.composeEmail = payload;
    },
    handleSectionDetailModal(state, { payload }) {
      state.isSectionModalOpen = !state.isSectionModalOpen;
    },
    handleContactDetailModal(state, { payload }) {
      state.contactModal.isOpen = payload.open;
      state.contactModal.add = payload.add;
    },
    handleLeadComposer(state, { payload }) {
      state.isEditComposer = payload.isEdit;
      state.isComposerOpen = payload.isVisible;
    },
    // deleteLeadManagerMember(state, { payload }) {
    //   const { isOpen, isEdit } = payload;
    //   state.isEditComposer = isEdit;
    //   state.isComposerOpen = isOpen;
    // },
    getLeadManagerGroupDetailById(state, { payload }) {
      state.leadManagerDetail = state.leadManagersData.find(
        (manager) => manager.id === payload
      );
    },
    resetLeadManagerDetail(state, { payload }) {
      state.leadManagerDetail = null;
      state.isEditComposer = false;
      state.isComposerOpen = false;
    },
    resetContactDetail(state, { payload }) {
      state.contactDetail = null;
    },
    resetSuccess(state, { payload }) {
      state.success = false;
    },
    moveSection(state, { payload }) {
      const { oldListIndex, newListIndex } = payload;
      const newLists = Array.from(state.leadManagerDetail.sections);
      const [removedList] = newLists.splice(oldListIndex, 1);
      newLists.splice(newListIndex, 0, removedList);
      state.leadManagerDetail.sections = newLists;
    },
    addAllContactLeadManagerDetail(state, { payload }) {

      const { data } = payload
      console.log("data=====", data)
      state.contactLeadManagerDetail = data
    },
    addNewContactLeadManagerDetail(state, { payload }) {
      console.log(payload, "===contactNew")
      state.contactLeadManagerDetail = [payload, ...state.contactLeadManagerDetail]
    },
    updateContactLeadManagerDetail(state, { payload }) {
      const { id } = payload
      const index = state.contactLeadManagerDetail?.findIndex(contact => contact.id === id)
      if (index !== -1) {
        const updatedContactData = [...state.contactLeadManagerDetail];
        updatedContactData[index] = payload
        state.contactLeadManagerDetail = updatedContactData
      }

    },
    moveDetail(state, { payload }) {
      const { oldCardIndex, newCardIndex, sourceListId, destListId, detailId } = payload;
      // // Move within the same list


      const sourceSection = state.leadManagerDetail.sections.find(
        (section) => section.sectionType === sourceListId
      );
      const destinationsSection = state.leadManagerDetail.sections.find(
        (section) => section.sectionType === destListId
      );
      const sectionIndex = state.leadManagerDetail.sections.findIndex(
        (section) => section.sectionType === sourceListId
      );
      const destinationSectionIndex = state.leadManagerDetail.sections.findIndex(
        (section) => section.sectionType === destListId
      );


      sourceSection.details[0].sectionId = destinationsSection.id;

      if (sourceListId === destListId) {
        const newTodos = sourceSection.details;
        const [removedCard] = newTodos.splice(oldCardIndex, 1);
        newTodos.splice(newCardIndex, 0, removedCard);

        console.log(state.leadManagerDetail.sections[sectionIndex], "====LeadManager=====")

        state.leadManagerDetail.sections[sectionIndex].details = newTodos;
        return;
      }


      const findDetailIndex = state.leadManagerDetail.sections[sectionIndex].details.findIndex(item => item.id === detailId)
      const currentObject = state.leadManagerDetail.sections[sectionIndex].details[findDetailIndex]
      //move todo from one section to another
      state.leadManagerDetail.sections[sectionIndex].detailCount = state.leadManagerDetail.sections[sectionIndex].detailCount - 1;
      state.leadManagerDetail.sections[destinationSectionIndex].detailCount = state.leadManagerDetail.sections[destinationSectionIndex].detailCount + 1;

      state.leadManagerDetail.sections[sectionIndex].leadValue = state.leadManagerDetail.sections[sectionIndex].leadValue - currentObject.leadValue;
      state.leadManagerDetail.sections[destinationSectionIndex].leadValue = state.leadManagerDetail.sections[destinationSectionIndex].leadValue + currentObject.leadValue


      const removedTodo = sourceSection.details.splice(oldCardIndex, 1);
      destinationsSection.details.splice(newCardIndex, 0, removedTodo[0]);
    },
    addLeadMember: (state, { payload }) => {
      const leadMembers = state.leadManagersData.map((item, i) => {
        if (item.id === payload[0].leadManagerId) {
          let members = [...item.members, payload[0]];
          let newItem = {
            ...item,
            members,
          };
          return newItem;
        } else {
          return item;
        }
      });

      state.leadManagersData = leadMembers;
    },
    deleteLeadManagerMember(state, { payload }) {
      const deleteLeadMembers = state.leadManagersData.map((item, i) => {
        if (item.id === payload.id) {
          let delMember = item.members.filter(
            (member) => member.memberId !== payload.memberId
          );
          let deleteItem = {
            ...item,
            members: delMember,
          };
          return deleteItem;
        } else {
          return item;
        }
      });
      state.leadManagersData = deleteLeadMembers;

      if(state.leadManagerDetail){
        state.leadManagerDetail.members = state.leadManagerDetail.members.filter(member => member.memberId !== payload.memberId);
      }
    },
    readCounterLeadManagerDetail(state, { payload }) {
      const { sectionId, detailId } = payload
      const sectionIndex = state.leadManagerDetail?.sections?.findIndex((section) => section?.id === detailId)
      const detailIndex = state.leadManagerDetail?.sections[sectionIndex].details.findIndex(detail => detail.id === sectionId)
      state.leadManagerDetail.sections[sectionIndex].details[detailIndex].notificationCount = 0;
    },
    handleNotificationNewItem: (state, { payload }) => {

      try {
        const leadManagersData = state.leadManagersData.map((item, i) => {
          if (item.id === payload.referenceId) {
            let updatedItem = {
              ...item,
              notificationCount: item.notificationCount + 1,
            };
            return updatedItem;
          } else {
            return item;
          }
        });

        state.leadManagersData = leadManagersData;

      }
      catch (e) {
        console.log("===error===", e)
      }
    },
    getLeadMangerContactDetailById: (state, { payload }) => {
      state.isContactDetailLoading = false;
      state.success = true;
      state.contactDetail = payload;
    },
    handleNotificationNewItemLeadManagerDetail: (state, { payload }) => {

      try {
        let sectionIndex = -1;
        let detailIndex = -1;

        for (let sectionsIndex = 0; sectionsIndex < state.leadManagerDetail.sections.length; sectionsIndex++) {
          const item = state.leadManagerDetail.sections[sectionsIndex];

          for (let detailsIndex = 0; detailsIndex < item.details.length; detailsIndex++) {
            const detail = item.details[detailsIndex];

            if (detail.id === payload.featureId) {
              console.log(sectionIndex, detailIndex, "===indexxxxx")
              sectionIndex = sectionsIndex;
              detailIndex = detailsIndex;
              break; // Exit the loop when the condition is met
            }
          }
          console.log(sectionIndex, detailIndex, "===indexxxxx")

          if (sectionIndex !== -1 && detailIndex !== -1) {
            break; // Exit the outer loop as well
          }
        }
        if (sectionIndex !== -1 && detailIndex !== -1) {
          state.leadManagerDetail.sections[sectionIndex].details[detailIndex].notificationCount += 1;
        }
      }
      catch (e) {
        console.log("===Exception==")
      }

    },
    addMemberLeadTable: (state, { payload }) => {
      const { detailId } = payload;
      const sectionIndex = state.leadDetailTableData?.findIndex(
        (lead) => lead.id === detailId
      );
      if (sectionIndex !== -1) {
        state.leadDetailTableData[sectionIndex] = {
          ...state.leadDetailTableData[sectionIndex],
          members: [...state.leadDetailTableData[sectionIndex].members, payload],
        };
      }

      if(state.leadManagerSectionDetailData){
        state.leadManagerSectionDetailData.members = [...state.leadManagerSectionDetailData.members, payload];
      }
    },
    deleteMemberLeadTable: (state, { payload }) => {
      const { detailId , memberId} = payload;
      const sectionIndex = state.leadDetailTableData?.findIndex(
        (lead) => lead.id === detailId
      );
      if (sectionIndex !== -1) {
        state.leadDetailTableData[sectionIndex] = {
          ...state.leadDetailTableData[sectionIndex],
          members: state.leadDetailTableData[sectionIndex].members.filter(member => member.memberId !== memberId),
        };
      }
      if(state.leadManagerSectionDetailData){
        state.leadManagerSectionDetailData.members = state.leadManagerSectionDetailData.members.filter(member => member.memberId !== memberId);
      }
    },
  //lead manager group notification
  handleNotificationGroupNewItem: (state, { payload }) => {

    try {
      const currentState = current(state);
      if (currentState.data !== null) {
        const keys = Object.keys(currentState.data);
        let updatedState = { ...currentState.data };

        keys?.forEach((identifier) => {
          const current = currentState.data[identifier]
          const index = current.findIndex(y => y.id === payload.featureId)
          if (index !== -1) {
            const updatedCurrent = [...current]; // Create a deep copy of the current array
            let referenceItem = updatedCurrent[index];
            referenceItem = {
              ...referenceItem,
              notificationCount: referenceItem.notificationCount + 1
            }

            updatedCurrent[index] = referenceItem;

            updatedState = {
              ...updatedState,
              [identifier]: updatedCurrent,
            };
          }
        });
        state.data = updatedState;

      }
    }
    catch (e) {
      console.log("===error===customApproval", e)
    }
  },
  addnewLeadManagerDetailCall: (state, { payload }) => {
    state.LeadManagerDetailCallData = [payload, ...state.leadManagerSectionDetailData]
  },
},
  extraReducers: (builder) => {
    builder
      .addCase(addLeadManager.fulfilled, (state, { payload }) => {
        state.leadManagersData.unshift(payload.data);
        state.addLeadManagerLoader = false;
        state.success = true;
      })
      .addCase(GetAllLeadManagerDetailCallAction.fulfilled, (state, { payload }) => {
        state.LeadManagerDetailCallData = payload?.data
        state.isLoadingLeadManagerDetailCallData = false
      })
      .addCase(addLeadManagereMember.fulfilled, (state, { payload }) => {
        if (payload?.length > 0) {
          state.memberData = [...state?.memberData, payload[0]];
          if(state.leadManagerDetail){
            state.leadManagerDetail.members = [...state.leadManagerDetail.members, ...payload];
          }
          return state;
        }
      })
      .addCase(AddLeadManagerDetailCallAction.fulfilled, (state, { payload }) => {
        state.LeadManagerDetailCallData = [payload?.data, ...state.LeadManagerDetailCallData]

      })
      .addCase(getLeadManagerById.fulfilled, (state, { payload }) => {
        state.isComposerDataLoading = false;
        state.leadManagerDetail = payload.data;
      })
      .addCase(getScheduleByIdAction.fulfilled, (state, { payload }) => {
        ////meetings by iddd
        state.isComposerDataLoading = false;
        state.meetingDetailComposer = payload.data;
      })
      .addCase(getAllLeadManager.fulfilled, (state, action) => {
        const { pageNo } = action.meta.arg;
        const data = action.payload.data;
        if (pageNo === 1) {
          state.leadManagersData = data;
        } else if (pageNo > 1) {
          state.leadManagersData = [...state.leadManagersData, ...data];
        }
        state.loading = false;
        state.success = true;

      })
      .addCase(getAllLeadManagerPaging.fulfilled, (state, { payload }) => {
        state.success = true;
        state.loading = false;
        state.leadManagersData = payload.data.data;
        state.totalRecords = payload.data.totalRecords;
      })
      .addCase(
        getAllLeadManangerDetailPaging.fulfilled,
        (state, { payload }) => {
          state.success = true;
          state.loading = false;
          state.leadDetailTableData = payload.data.map(item => {
            return {
              ...item, isShowLeadValue: true
            }
          });
          state.totalRecords = payload.totalRecords;
        }
      )
      .addCase(updateLeadManager.fulfilled, (state, { payload }) => {
        const { data } = payload;
        let dataToUpdate = {
          name: data.name,
          description: data.description,
          image: data.image,
          privacyId: data.privacyId,
        };
        if (state.leadManagersData?.length > 0) {
          const updatedManagerIndex = state.leadManagersData.findIndex(
            (manager) => manager.id === data.id
          );
          if (updatedManagerIndex !== -1) {
            //TODO
            let newData = {
              ...state.leadManagersData[updatedManagerIndex],
              members: state.leadManagersData[updatedManagerIndex].members,
              ...dataToUpdate
            };
            state.leadManagersData[updatedManagerIndex] = newData;
          }
        }
        if (state.leadManagerDetail && state.leadManagerDetail.id === data.id) {
          state.leadManagerDetail = { ...state.leadManagerDetail, ...dataToUpdate };
        }
        state.addLeadManagerLoader = false;
        state.success = true;
        state.error = false;
      })
      .addCase(getLeadManagerSectionById.fulfilled, (state, { payload }) => {
        state.success = true;
        state.loading = false;
        state.leadManagerSections = payload.data;
      })
      .addCase(updateLeadManagerDetail.fulfilled, (state, { payload }) => {
        if (state.leadManagerDetail !== null) {
          const sectionIndex = state.leadManagerDetail.sections.findIndex(
            (section) => section.id === payload.data.sectionId
          );

          const detailIndex = state.leadManagerDetail.sections[
            sectionIndex
          ].details.findIndex((details) => details.id === payload.data.id);

          if (detailIndex >= 0) {
            state.leadManagerDetail.sections[sectionIndex].details[
              detailIndex
            ] = payload.data;
          } else {
            const filteredSections = state.leadManagerDetail.sections.map(section => {
              const filteredDetails = section.details.filter(detail => detail.id !== payload?.data.id);
              return { ...section, details: filteredDetails };
            });

            filteredSections[sectionIndex].details[
              filteredSections[sectionIndex].details.length
            ] = payload.data;
            state.leadManagerDetail.sections = filteredSections

          }
        } else {
          const sectionIndex = state.leadDetailTableData.findIndex(
            (section) => section.id === payload.data.id
          );
          if (sectionIndex !== -1) {
            state.leadDetailTableData[sectionIndex] = {
              ...payload.data,
              members: state.leadDetailTableData[sectionIndex].members,
            };
          }
        }
        state.leadManagerSectionDetailData = { ...payload.data, members: state.leadManagerSectionDetailData.members, contacts: state.leadManagerSectionDetailData.contacts, }
        state.success = true;
        state.loading = false;
      })
      .addCase(addLeadManagerDetail.fulfilled, (state, { payload }) => {
        state.success = true;
        state.loading = false;
        const { sectionId } = payload.data;
        if (state.leadManagerDetail) {
          const sectionIndex = state.leadManagerDetail.sections.findIndex(
            (section) => section.id === sectionId
          );
          state.leadManagerDetail.sections[sectionIndex]?.details.push(
            payload.data
          );
        }
        state.leadDetailTableData = [
          payload.data,
          ...state.leadDetailTableData,
        ];

      })
      .addCase(getLeadManagerDetailById.fulfilled, (state, { payload }) => {
        state.isSectionDetailLoading = false;
        state.success = true;
        state.leadManagerSectionDetailData = payload.data;
      })
      .addCase(
        getAllLeadManagerContactDetail.fulfilled,
        (state, { payload }) => {
          state.isContactDetailLoading = false;
          state.success = true;
          state.contactDetail = payload.data;
        }
      )
      .addCase(addLeadManagerDetailMember.fulfilled, (state, action) => {
        const {
          meta: { arg },
        } = action;
        const { sectionId, detailId } = arg;
        const data = action?.payload[0];
        if (state.leadManagerDetail) {
          let sectionIndex = state.leadManagerDetail?.sections?.findIndex(
          (section) => section.id === sectionId
        );
        if (sectionIndex !== -1) {
        let detailIndex = state.leadManagerDetail.sections[
          sectionIndex
        ]?.details?.findIndex((detail) => detail.id === detailId);

        if (detailIndex !== -1) {
          //append new members to the existing array
          state.leadManagerDetail.sections[sectionIndex].details[
            detailIndex
          ].members.push(data);

          if (state.leadManagerSectionDetailData) {
            // Append new members to the existing array using the spread operator
            state.leadManagerSectionDetailData.members = [
              ...state.leadManagerSectionDetailData.members,
              data,
            ];
          }     
        }}
      }
      })
      .addCase(deleteLeadManagerDetailMember.fulfilled, (state, action) => {
        const {
          meta: { arg },
        } = action;
        const { sectionId, detailId, memberId } = arg;
        if(state.leadManagerDetail){
        let sectionIndex = state.leadManagerDetail.sections.findIndex(
          (section) => section.id === sectionId
        );
        if(sectionIndex !== -1){
        let detailIndex = state.leadManagerDetail.sections[
          sectionIndex
        ].details.findIndex((detail) => detail.id === detailId);
        const filteredMembers = state.leadManagerDetail.sections[
          sectionIndex
        ].details[detailIndex].members.filter(
          (member) => member.memberId !== memberId
        );
        state.leadManagerDetail.sections[sectionIndex].details[
          detailIndex
        ].members = filteredMembers;
        if (state.leadManagerSectionDetailData) {
          state.leadManagerSectionDetailData.members = filteredMembers;
        }
      }
      }
      })
      .addCase(addLeadManagerContact.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.loading = false;
        state.success = true;
        state.leadManagerSectionDetailData.contacts.push(data);
        state.isContactUpdated = true;
        state.contactDataUpdating = false;
      })
      .addCase(updateLeadManagerContact.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.loading = false;
        state.success = true;
        const sectionContactIndex = state.leadManagerSectionDetailData.contacts.findIndex(
          (contact) => contact.id === data.id
        );
        state.leadManagerSectionDetailData.contacts[sectionContactIndex] = data;
        state.isContactUpdated = true;
        state.contactDataUpdating = false;
      })
      .addCase(getAllScheduleAction.fulfilled, (state, { payload }) => {
        if (payload.data.length > 0) {
          state.meetingDetail = payload.data;
          return state;
        }
      })
      .addCase(deleteLeadManagerMemberById.fulfilled, (state, { payload }) => {
        state.removeMemberSuccess = true;
      })
      .addMatcher(isPending(...[deleteLeadManagerMemberById]), (state) => {
        state.removeMemberSuccess = false;
      })
      .addMatcher(isPending(getAllLeadManagerContactDetail), (state) => {
        state.isContactDetailLoading = true;
        state.contactDetail = null;
      })
      .addMatcher(isPending(getLeadManagerById), (state) => {
        state.leadManagerDetail = null;
      })
      .addMatcher(isPending(getLeadManagerDetailById), (state) => {
        state.isSectionDetailLoading = true;
        state.leadManagerSectionDetailData = null;
      })
      .addMatcher(isPending(GetAllLeadManagerDetailCallAction), (state) => {
        state.isLoadingLeadManagerDetailCallData = true
      })

      .addMatcher(
        isPending(...[updateLeadManagerContact, addLeadManagerContact]),
        (state) => {
          state.isContactUpdated = false;
          state.contactDataUpdating = true;
          state.success = false;
          state.error = false;
        }
      )
      .addMatcher(isRejected(...[deleteLeadManagerMemberById]), (state) => {
        state.removeMemberSuccess = false;
      })
      .addMatcher(
        isRejected(...[updateLeadManagerContact, addLeadManagerContact]),
        (state) => {
          state.isContactUpdated = false;
          state.contactDataUpdating = false;
          state.success = false;
          state.error = true;
        }
      )
      .addMatcher(
        isPending(
          ...[
            getAllLeadManager,
            getAllLeadManagerPaging,
            getLeadManagerSectionById,
            addLeadManagerDetail,
            updateLeadManagerDetail,
          ]
        ),
        (state) => {
          state.loading = true;
          state.success = false;
          state.error = false;
        }
      )
      .addMatcher(
        isPending(...[addLeadManager, updateLeadManager]),
        (state) => {
          state.addLeadManagerLoader = true;
          state.success = false;
          state.error = false;
        }
      )
      .addMatcher(
        isRejected(
          ...[
            getAllLeadManager,
            getAllLeadManagerPaging,
            getLeadManagerSectionById,
            getLeadManagerById,
            addLeadManagerDetail,

            updateLeadManagerDetail,
          ]
        ),
        (state) => {
          state.loading = false;
          state.success = false;
          state.error = true;
        }
      )
      .addMatcher(
        isRejected(...[addLeadManager, updateLeadManager]),
        (state) => {
          state.addLeadManagerLoader = false;
          state.success = false;
          state.error = true;
        }
      );
  },
});

export const {
  handleFormDetail,
  handleLeadComposer,
  moveSection,
  moveDetail,
  resetLeadManagerDetail,
  resetContactDetail,
  getLeadManagerGroupDetailById,
  handleAssignMemberModal,
  handleContactDetailModal,
  handleSectionDetailModal,
  handleComposeEmail,
  resetSuccess,
  addMember,
  addAssignMember,
  handleOpenComposer,
  toggleEventDetailComposer,
  deleteLeadManagerMember,
  addLeadMember,
  readCounterLeadManagerDetail,
  handleNotificationNewItem,
  handleNotificationNewItemLeadManagerDetail,
  addAllContactLeadManagerDetail,
  addNewContactLeadManagerDetail,
  getLeadMangerContactDetailById,
  updateContactLeadManagerDetail,
  handleResetState,
  addnewLeadManagerDetailCall,
  handleChangeLeadValueVisibility,
  toggleAllLeadValue,
  handleNotificationGroupNewItem,
  addMemberLeadTable,
  deleteMemberLeadTable
} = leadMangerSlice.actions;

export default leadMangerSlice.reducer;
