export const ExpenseCategoryEnum =
{
    Transport:1,
    Health:2,
    Food:3,
    Shopping:4,
    Entertainment:5,
    Travel:6,
    Bill:7,
    Office:8,
    Fuel:9,
    Asset:10,
    Other:11
}