import React from 'react'
import { Avatar, Divider, List, Skeleton, Tag } from 'antd';
import "../Style/tag.css"
import { CloseOutlined } from '@ant-design/icons';
import { getNameForImage} from "../../../../utils/base";


export default function CustomTag1(props)
{

    const {name , id , image, designation  } = props.selectedUser
    const {selected , index} =  props
    const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

    function numberFromText(text) {
    const charCodes = text
        ?.split('')
        ?.map(char => char.charCodeAt(0))
        ?.join('');
    return parseInt(charCodes, 10);
    }

    return(
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                    // className="cursor-pointer !bg-black  imageAvatar"
                    src={(image?.length && image)}
                    style={{backgroundColor: colors[numberFromText(name) % colors?.length]}}
                >
                    {getNameForImage(name)}
                </Avatar>
        
                <div className="contentBox">
                    
                    <p style={{fontSize:"13px"}}>
                        {name}
                    </p>
                    <p  
                        style={{fontSize:"11px"}}
                        className={"notSelected"}
                    >
                        {designation}
                    </p>
                </div>
            </div>
        </>

        )
    }