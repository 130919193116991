import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { getCallingTranscribeByRoomIdAction } from "../../store/action";
import Transcript from "./transcript";

export default function MeetingTranscriptModal({
  setistranscript,
  istranscript,
  roomId,
}) {
  const dispatch = useDispatch();
  const [transcriptData, setTranscriptData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getCallingTranscript = () => {
    setIsLoading(true); // Make sure to set loading to true at the start of the call
    dispatch(getCallingTranscribeByRoomIdAction(roomId))
      .then((res) => {
        console.log("rrrrrrrrrrrrrrr", res);
        res?.payload?.data
          ? setTranscriptData(res?.payload?.data)
          : handleClose();
      })
      .catch((error) => {
        console.error("Error fetching transcript:", error);
      })
      .finally(() => {
        setIsLoading(false); // Ensure isLoading is set to false both after success and error
      });
  };

  useEffect(() => {
    roomId && getCallingTranscript(roomId);
  }, [roomId]);

  const handleClose = () => {
    setistranscript(false);
  };

  return (
    <Modal
      open={istranscript}
      footer={null}
      onCancel={handleClose}
      width={800}
      className="transcript-modal"
    >
      <Transcript transcriptionData={transcriptData} isLoading={isLoading} />
    </Modal>
  );
}
