import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  UpdateUserTaskTimeAction,
  addNewTask,
  getAllTasksAction,
} from "../../../../store/actions";
import { TaskFilterSortEnum } from "../../../../utils/TaskFilterSortEnum";
import { STRINGS } from "../../../../../../../utils/base";
import Spinner from "../../../../../../sharedComponents/spinner/spinner";
import { Collapse, Divider, Input } from "antd";
import { SegmentTypeEnum } from "../../../../../../sharedComponents/Segment/utils/enum";
import { TaskFilterTypeEnum } from "../../../../utils/TaskFilterTypeEnum";
import {
  handleNewItem,
  handleNewSubTask,
  removeIdentifier,
} from "../../../../store/taskSlice";
import SubTaskShortCardItem from "../../../UI/subtask/subTaskShortCardItem";
import { Table } from "antd";
import { priorityColors } from "../../../../utils/priorityColors";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import TagAvatar from "../../../../../../sharedComponents/Avatar/TagAvatar";
import Avatar from "../../../../../../sharedComponents/Avatar/avatar";
import AddSubTask from "../../../UI/subtask/addSubTask";
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import SubTaskRowBtn from "../../../UI/subtask/subTaskRowBtn";
import CreateSubTask from "../../../UI/subtask/createSubTask";
import { SubTaskTableColumn } from "../../../UI/subtask/subtaskTableColumns";
import TaskDetailModal from "../TaskModal";
import { userTaskTypeEnum } from "../../../../utils/TaskViewTypeEnum";
import { useParams } from "react-router-dom";
import { TaskStatusEnum } from "../../../../utils/TaskStatusEnum";
import { TaskPriorityEnum } from "../../../../utils/TaskPriorityEnum";

function SubTaskShortCard({
  parentId = STRINGS.DEFAULTS.guid,
  parentRecord = {},
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const { id } = useParams();

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [state, setState] = useState({
    identifier: parentId,
    taskLoader: true,
    subTaskSubject: "",
    subTaskDate: [null, null],
    subTaskMembers: [],
    createDetailSubtask: false,
    isDetailOpen: false,
    subTaskId: STRINGS?.DEFAULTS.guid,
  });
  const [isGoal, setIsgoal] = useState(false);

  const { data } = useSelector((state) => state.taskSlice);
  let taskdata = data !== null ? data[state?.identifier] : [];
  const [tableData, setTableData] = useState(taskdata);
  let hideTableHeader = tableData?.length === 1 && tableData?.[0]?.isNew;

  useEffect(() => {
    taskdata = data !== null ? data[state?.identifier] : [];
  }, [data]);

  useEffect(() => {
    setTableData(taskdata);
    hideTableHeader = taskdata?.length === 1 && taskdata?.[0]?.isNew;
  }, [taskdata]);

  useEffect(() => {
    // Check if the last element of your tableData array has isNew
    if (tableData?.length > 0 && tableData?.[tableData?.length - 1]?.isNew) {
      // Apply focus to the input element
      inputRef.current?.focus();
    }
  }, [tableData]);

  useEffect(() => {
    setIsgoal(
      Object.keys(parentRecord).length > 0 &&
        parentRecord?.userTaskType === userTaskTypeEnum.Goal
    );
  }, [parentRecord]);

  const addSubTask = () => {
    if (
      tableData.length > 0 &&
      tableData[tableData.length - 1].isNew &&
      state.subTaskSubject
    ) {
      handleSubTask();
    } else {
      setState({
        ...state,
        subTaskSubject: "",
        subTaskDate: [null, null],
        subTaskMembers: [],
      });
      const newRow = { id: Date.now(), isNew: true };
      setTableData([...tableData, newRow]);

      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 0);
    }
  };

  const handleBlur = () => {
    !state.subTaskSubject &&
      setTableData(tableData.filter((row) => !row.isNew));
  };

  const handleSubTask = () => {
    dispatch(
      addNewTask({
        subject: state.subTaskSubject,
        parentId: parentId,
        ...(state.subTaskDate[0] &&
          state.subTaskDate[1] && {
            startDate: state.subTaskDate[0].format(),
            endDate: state.subTaskDate[1].format(),
          }),
        priority: isGoal ? TaskPriorityEnum.High : TaskPriorityEnum.Default,
        members: !isGoal
          ? state.subTaskMembers?.map((member) => {
              return { memberId: member.id };
            })
          : id
          ? [{ memberId: id }]
          : [{ memberId: parentRecord?.members?.[0]?.memberId }],
        userTaskType: isGoal ? userTaskTypeEnum.Goal : userTaskTypeEnum.Task,
        referenceId: parentRecord?.referenceId,
        referenceType: parentRecord.referenceType
      })
    ).then((response) => {
      dispatch(
        handleNewSubTask({
          identifier: state.identifier,
          subtask: response?.payload?.data,
        })
      );
      setState({
        ...state,
        subTaskSubject: "",
        subTaskDate: [null, null],
        subTaskMembers: [],
      });
    });
  };

  const handleSubmit = (response) => {
    setTableData(tableData.filter((row) => !row.isNew));
    setState({
      ...state,
      createDetailSubtask: false,
      subTaskSubject: "",
      subTaskDate: [null, null],
      subTaskMembers: [],
    });
    dispatch(
      handleNewSubTask({
        identifier: state.identifier,
        subtask: response?.payload?.data,
      })
    );
  };

  useEffect(() => {
    parentId &&
      parentId !== STRINGS.DEFAULTS.guid &&
      dispatch(
        getAllTasksAction({
          filter: {
            sortBy: TaskFilterSortEnum.CreateDateAsc,
            parentId: parentId,
            filterType: TaskFilterTypeEnum.AllTask,
          },
          segmentType: SegmentTypeEnum.Grid,
          identifier: state.identifier,
        })
      ).then(() => {
        setState({ ...state, taskLoader: false });
      });
  }, [parentId]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const createDetail = () => {
    setState({ ...state, createDetailSubtask: true });
  };

  const handleDateChange = (dates, id) => {
    dispatch(
      UpdateUserTaskTimeAction({
        startDate: dates?.[0].format(),
        endDate: dates?.[1].format(),
        id: id,
      })
    );
  };

  const handleOnClick = (id = STRINGS.DEFAULTS.guid) => {
    setState({ ...state, isDetailOpen: !state.isDetailOpen, subTaskId: id });
    setTableData(tableData.filter((item) => !item.isNew));
  };

  return (
    <>
      <>
        <Divider />
        {!(
          parentRecord?.status === TaskStatusEnum.Cancel &&
          tableData?.length === 0
        ) && (
          <div className="memberProgress font-semibold mb-2">
            {isGoal ? tasks.SubGoals : tasks.SubTasks}
          </div>
        )}
        {tableData?.length > 0 && (
          <Table
            className={`min-w-full custom_table subTaskCardTable ${
              hideTableHeader ? "hide-table-header" : ""
            }`}
            columns={SubTaskTableColumn(
              tasks,
              sharedLabels,
              inputRef,
              state,
              setState,
              handleSubTask,
              handleBlur,
              parentRecord,
              handleDateChange,
              createDetail,
              isGoal
            )}
            dataSource={
              state.isDetailOpen
                ? tableData.filter((item) => !item.isNew)
                : tableData
            }
            rowKey="id"
            pagination={false}
            onRow={(record, index) => {
              return {
                ...(!record?.isNew && { className: "customTableRowSubTask" }),
                style: {
                  "--dynamic-right": `${100 - record?.progress}%`,
                },
                onClick: () => {
                  if (!record?.isNew) {
                    handleOnClick(record?.id);
                  }
                },
              };
            }}
          />
        )}
        {parentRecord.status !== TaskStatusEnum.Cancel && (
          <AddSubTask addSubTask={addSubTask} isGoal={isGoal} />
        )}
      </>
      {state.createDetailSubtask && !isGoal && (
        <CreateSubTask
          id={parentRecord?.id}
          subject={parentRecord?.subject}
          referenceId={parentRecord?.referenceId}
          referenceType={parentRecord?.referenceType}
          date={state.subTaskDate}
          member={state.subTaskMembers}
          isOpen={state.createDetailSubtask}
          key={state.createDetailSubtask}
          detailCheck={true}
          onClose={() => setState({ ...state, createDetailSubtask: false })}
          subTaskSubject={state.subTaskSubject}
          onSubmit={(response) => handleSubmit(response)}
        />
      )}

      <TaskDetailModal
        id={state?.subTaskId}
        isOpen={state.isDetailOpen}
        onClose={handleOnClick}
        key={state?.subTaskId}
      />
    </>
  );
}

export default SubTaskShortCard;
