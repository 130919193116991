import {CommentModuleEnum} from "../../utils/enum/commentModuleEnum";
import {CommentTypeEnum} from "../../utils/enum/commentTypeEnum";
import CommentItem from "./userComment/commentItem";
import {useEffect, useState} from "react";
import CommentList from "../commentList/commentList";
import SystemCommentItem from "./systemComment/systemCommentItem";
import {STRINGS} from "../../../../../utils/base";


function CommentItemWrapper({isHeightFull=false , comment, module = CommentModuleEnum.Feed,referenceId = STRINGS.DEFAULTS.guid , identifier ,isOpenComposer}) {


    const [state,setState] = useState({
        showReplies : false,
        // commentItem : comment
    })

    const {createDate, type  = CommentTypeEnum.UserComment,id = STRINGS.DEFAULTS.guid,
        creator = {name : "",image: ""}
    } = comment;
    return (<>
        <div className={"commentItem"} >
            {type === CommentTypeEnum.UserComment ?
                <CommentItem commentItem={comment} module={module} replyOnClick={()=>setState({...state,showReplies: !state.showReplies})} identifier={identifier} />:
                <SystemCommentItem creator={creator} createDate={createDate} comment={comment.comment} mentions={comment.mentions} type={comment.type}/>
            }
        </div>
        {state.showReplies && <CommentList  isHeightFull={isHeightFull} module={module} parentId={id} referenceId={referenceId} identifier={identifier} isOpenComposer={isOpenComposer}/>}

    </>);

}
export default CommentItemWrapper;