import { Checkbox, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { TransactionModeTypeEnum } from "../../utils/transactionModeEnum";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";

const CreateEntryItem = ({ value, index,
    setChequeNo = () => { },
    setTransactionMode = () => { },
}) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, Direction, disperse } = dictionaryList[userLanguage];

    let {
        username,
        amount,
        bankAccountNumber,
        bankAccountTitle,
        bankBranchCode,
        bankIbanNumber,
        bankName,
        transactionMode,
        transactionModeName,
    } = value;
    const [transactionModeState, setTransactionModeState] = useState(transactionMode)
    const [chequeNoState, setChequeNoState] = useState("");



    const changeTransactionMode = (value,) => {
        setTransactionModeState(value);
        setTransactionMode(value);

    }

    const onChangeCheque = (e) => {
        const cheque = e.target.value;
        setChequeNoState(cheque)
        setChequeNo(cheque)
    }



    return (
        <>
            <tr key={index}>
                <td>{username}</td>
                <td>{amount ? formatNumberWithCommas(amount.toFixed(2)) : ""}</td>
                <td>
                    {Object.keys(disperse.transactionModeEnumList).length > 0 && (
                        <Select placeholder={"Select Transaction Mode"}
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            size="large"
                            allowClear
                            onChange={changeTransactionMode}
                            value={disperse.transactionModeEnumList && disperse.transactionModeEnumList[transactionModeState]}

                        >
                            {Object.entries(disperse.transactionModeEnumList).map(([key, value]) => (
                                <Select.Option key={key} value={key}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </td>
                <td>
                    {bankIbanNumber}
                </td>
                <td><input name="chequeNo" onChange={onChangeCheque} disabled={transactionModeState !== TransactionModeTypeEnum.Cheque.toString()} /></td>

            </tr>

        </>);
};
export default CreateEntryItem;
