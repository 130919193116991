import { createSlice } from "@reduxjs/toolkit";

const formSubmittedSlice = createSlice({
  name: "form",
  initialState: {
    isSubmitted: false,
  },
  reducers: {
    submitForm: (state, action) => {
      state.isSubmitted = action.payload;
    },
  },
});

export const { submitForm } = formSubmittedSlice.actions;
export const formSubmitted = (state) => state.form.isSubmitted;
export default formSubmittedSlice.reducer;
