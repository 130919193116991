import React, { useContext, useState } from "react";
import { RiShareForwardLine } from "react-icons/ri";
import { BsChatSquareText } from "react-icons/bs";
import PostShareModal from "./PostShareModal";
import { Popover } from "antd";
import shareFeed from "../../../../../../../content/NewContent/NewsFeed/svg/shareFeed.svg";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import SharePostComposer from "../../../sharePostComposer";
import { STRINGS } from "../../../../../../../utils/base";

function PostShareContent({
  postId,
  content = "",
  post = null,
  referenceType,
  referenceId,
  parentId,
  shareOnFeed,
  modulePrivacyId,
  postRefType,
  postRefId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [shareType, setShareType] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed, sharedLabels } = dictionaryList[userLanguage];
  const [showComposer, setShowCompose] = useState(false);

  const handleShareOnChat = () => {
    setIsOpen(true);
    setOpenPopover(false);
    setShareType("Chat");
  };
  const handleShareOnFeed = () => {
    // setIsOpen(true);
    setOpenPopover(false);
    // setShareType("Feed");
    setShowCompose(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
    setShareType("");
  };

  /* --------------------------- SHARE IN CHAT FEATURE HIDDEN FOR NOW --------------------------- */
  return (
    <>
      {/* <Popover
        placement="bottom"
        trigger="click"
        overlayClassName="share-feed__content w-[250px]"
        open={openPopover}
        onOpenChange={(e) => setOpenPopover(e)}
        content={
          <div className="flex flex-col gap-2">
            {shareOnFeed && (
              <div
                className="flex gap-2 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
                onClick={handleShareOnFeed}
              >
                <RiShareForwardLine className="text-2xl text-[#5B626A]" />
                <span>{"Share Post"}</span>
              </div>
            )}
            <div
              className="flex gap-3 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
              onClick={handleShareOnChat}
            >
              <BsChatSquareText className="text-xl text-[#5B626A]" />
              <span>{newsFeed.ShareinChat}</span>
            </div>
          </div>
        }
      > */}
       {shareOnFeed && (
        <div className="btn" onClick={() => handleShareOnFeed()}>
          <div>
            <img src={shareFeed} alt="" />
          </div>
          <div> {sharedLabels.Share}</div>
        </div>
      )}
      {/* </Popover> */}
      {isOpen && (
        <PostShareModal
          isOpen={isOpen}
          shareType={shareType}
          handleCancel={handleCancel}
          postId={postId}
          content={content}
          modulePrivacyId={modulePrivacyId}
        />
      )}

      {showComposer && (
        <SharePostComposer
          showComposer={showComposer}
          setShowComposer={setShowCompose}
          post={post}
          postId={parentId !== STRINGS.DEFAULTS.guid ? parentId : postId}
          moduleReferenceType={referenceType}
          postRefType={postRefType}
          postRefId={postRefId}
          modulePrivacyId={modulePrivacyId}
          key={showComposer}
        />
      )}
    </>
  );
}

export default PostShareContent;
