import React, { useContext, useMemo } from "react";
import Spinner from "../../../../sharedComponents/spinner/spinner";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import moment from "moment"; // Ensure moment is installed
import TagAvatar from "../../../../sharedComponents/Avatar/TagAvatar";

export default function Transcript({ transcriptionData, isLoading }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule } = dictionaryList[userLanguage];

  // Assuming the first message's dateTime represents the start of the meeting
  const meetingStartTime = transcriptionData[0]
    ? moment(transcriptionData[0].dateTime)
    : moment();

  // Function to format the elapsed time since the meeting started
  const formatElapsedTime = (dateTime) => {
    const endTime = moment(dateTime);
    const duration = moment.duration(endTime.diff(meetingStartTime));
    return `${duration.minutes()}:${duration
      .seconds()
      .toString()
      .padStart(2, "0")}`;
  };

  // Combine consecutive messages from the same speaker
  const combinedData = useMemo(() => {
    return transcriptionData.reduce((acc, current, index, array) => {
      const elapsedTime = formatElapsedTime(current.dateTime);
      if (index === 0 || array[index - 1].memberId !== current.memberId) {
        // If it's the first item or the speaker changed, add a new entry with the start time
        acc.push({
          ...current,
          time: elapsedTime,
          messages: [current.message],
        });
      } else {
        // If the speaker is the same as the previous one, append the message and update the time range
        const lastEntry = acc[acc.length - 1];
        lastEntry.messages.push(current.message);
        lastEntry.time = `${lastEntry.time.split(" - ")[0]} - ${elapsedTime}`;
      }
      return acc;
    }, []);
  }, [transcriptionData]);

  return isLoading ? (
    <Spinner />
  ) : (
    <div
      className="mainTranscript p-4 overflow-auto"
      style={{ maxHeight: "80vh" }}
    >
      <h2 className="text-2xl font-semibold mb-4">
        {schedule.MeetingTranscript}
      </h2>
      {transcriptionData.length > 0 ?<div className="space-y-4">
        {combinedData.map((entry, index) => (
          <div key={index} className="transcript-entry flex flex-row">
            <div className="speaker-name flex !flex-col w-fit">
              {/* <span className="font-bold w-[120px]">{entry.memberName}</span> */}
              <TagAvatar
                text={entry?.member?.name}
                img={entry?.member?.image}
                className={"!bg-transparent !p-0 !font-bold"}
              />
              <span className="text-xs text-gray-600 ml-8">{entry.time}</span>
            </div>
            <div className="transcript-text text-justify ml-4">
              {entry.messages.map((msg, msgIndex) => (
                <React.Fragment key={msgIndex}>
                  {msg}
                  {msgIndex < entry.messages.length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>: <span>No Transcription  Data</span>}
    </div>
  );
}
