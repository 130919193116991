// import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
// import { responseCode } from "../../../../services/enums/responseCode.js";
// import { addProjectRole, getAllProjectRoleById, removeProjectRole, updateProjectRole } from "./action.js";

// const initialState = {
//     ProjectRole: [],
//   memberData: [],
//   customTagMembers:null,
//   loadingData: false,
//   loader: false,
//   addMemberModal :false,
//   isMemberModalOpen: false,
//   MemberId: "",
// };
// const ProjectRoleSlice = createSlice({
//   name: "ProjectRoleSlice",
//   initialState,
//   reducers: {
//     customDeleted: (state, { payload }) => {
//       state.ProjectRole = state.ProjectRole.filter((e) => e.id !== payload.id);
//     },
//     addMember: (state, { payload }) => {
//       state.addMemberModal = payload;
//     },
//     handleMemberModal(state, { payload }) {
//       state.MemberId = payload.id;
//       state.isMemberModalOpen = !state.isMemberModalOpen;
//     },
//     addCustomMember: (state, { payload }) => {
//       const customMember = state.ProjectRole.map((item, i) => {
//         if (item.id === payload[0].customTagId) {
//           let members = [...item.members, payload[0]];
//           let newItem = {
//             ...item,
//             members,
//           };
//           return newItem;
//         } else {
//           return item;
//         }
//       });
  
//       state.ProjectRole = customMember;
//     },

//     deleteCustomTagMember(state, { payload }) {
//       const deleteCustomTagMembers = state.ProjectRole.map((item, i) => {
//         if (item.id === payload.id) {
//           let delMember = item.members.filter(
//             (member) => member.memberId !== payload.memberId
//           );
//           let deleteItem = {
//             ...item,
//             members: delMember,
//           };
//           return deleteItem;
//         } else {
//           return item;
//         }
//       });
//       state.ProjectRole = deleteCustomTagMembers;
//     },


//   },
//   extraReducers: (builder) => {
//     builder
//     //   .addCase(getAllCustomTagById.fulfilled, (state, { payload }) => {
//     //     state.loadingData = false;
//     //     state.customTagMembers = payload.data;
//     //   })
//       .addCase(getAllProjectRoleById.fulfilled, (state, { payload }) => {
//         state.ProjectRole = payload ? payload : [];
//         state.loader = false;
//       })
//       .addCase(addProjectRole.fulfilled, (state, { payload }) => {
//         state.loader = false;
//         if (payload.responseCode === responseCode.Success)
//           state.ProjectRole.push(payload.data);
//       })
//     //   .addCase(getAllCustomTagMember.fulfilled, (state, action) => {
//     //     state.memberData = action.payload.data;
//     //     state.memberData = action.payload ? action.payload : [];
//     //   })
      
//     //   .addCase(addProjectRoleMemberByRollId.fulfilled, (state, { payload }) => {
//     //     if (payload.data.length > 0) {
//     //       state.memberData = [...state.memberData, payload.data[0]];
//     //       return state;
//     //     }
//     //   })

//       .addCase(removeProjectRole.fulfilled, (state, { payload }) => {
//         let newMembers = state.ProjectRole.members.filter(
//           (member) => member.memberId !== payload
//         );
//         state.ProjectRole = { ...state.ProjectRole, members: newMembers };
//       })


//       .addCase(updateProjectRole.fulfilled, (state, { payload }) => {
//         state.loader = false;
//         state.ProjectRole = state.ProjectRole.map((x) =>
//           x.id === payload.data.id ? payload.data : x
//         );
//       })
//       .addMatcher(isPending(...[addProjectRole, updateProjectRole]), (state) => {
//         state.loader = true;
//       })
//       .addMatcher(isPending(...[getAllProjectRoleById,removeProjectRole]), (state) => {
//         state.loadingData = true;
//       })
//     //   .addMatcher(
//     //     isRejected(...[getAllCustomTagById, addCustomTag, updateCustomTag]),
//     //     (state) => {
//     //       state.loader = false;
//     //       state.loadingData = false;
//     //     }
//     //   );
//   },
// });
// export const { customDeleted,addMember,addCustomMember,deleteCustomTagMember,
//   handleMemberModal } = ProjectRoleSlice.actions;
  
// export default ProjectRoleSlice.reducer;



import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import { addProjectRole, getAllProjectRoleById, removeProjectRole, updateProjectRole } from "./action.js";

const initialState = {
    ProjectRole: [],
//   memberData: [],
//   customTagMembers:null,
//   loadingData: false,
//   loader: false,
//   addMemberModal :false,
//   isMemberModalOpen: false,s
//   MemberId: "",
};
const ProjectRoleSlice = createSlice({
  name: "ProjectRoleSlice",
  initialState,
  reducers: {
    customDeleted: (state, { payload }) => {
              state.ProjectRole = state.ProjectRole.filter((e) => e.id !== payload.id);
       },
  },
  extraReducers: (builder) => {
    builder
  
      .addCase(getAllProjectRoleById.fulfilled, (state, { payload }) => {
        state.ProjectRole = payload.data ? payload.data : [];
        state.loader = false;
      })
      .addCase(addProjectRole.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.ProjectRole.push(payload.data);
      })
   
    //   .addCase(removeProjectRole.fulfilled, (state, { payload }) => {
    //     let newMembers = state.ProjectRole.members.filter(
    //       (member) => member.memberId !== payload
    //     );
    //     state.ProjectRole = { ...state.ProjectRole, members: newMembers };
    //   })


      .addCase(updateProjectRole.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.ProjectRole = state.ProjectRole.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })
    //   .addMatcher(isPending(...[addProjectRole, updateProjectRole]), (state) => {
    //     state.loader = true;
    //   })
    //   .addMatcher(isPending(...[getAllProjectRoleById,removeProjectRole]), (state) => {
    //     state.loadingData = true;
    //   })
   
  },
}

);
export const {customDeleted } = ProjectRoleSlice.actions;
  
export default ProjectRoleSlice.reducer;