export const DocumentTypeEnum = {
    Folder : 1,
    Image : 2,
    Video : 3,
    Attachment : 4,
    Grid : 5,
    Pad : 6,
    Slide : 7,
    Board : 8,
    MindMap : 9,
    IsDocumentSuite : (documentType) =>{
        switch (documentType) {
            case DocumentTypeEnum.Grid:
            case DocumentTypeEnum.Pad:
            case DocumentTypeEnum.Slide:
            case DocumentTypeEnum.Board:
            case DocumentTypeEnum.MindMap:
                return true;
            default:
                return false;

        }
    },
    IsAttachmentSuite : (documentType) =>{
        switch(documentType)
        {
            case DocumentTypeEnum.Attachment:
            case DocumentTypeEnum.Video:
            case DocumentTypeEnum.Image:
                return true;
            default:
                return false
        }
    }
}