import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { AddApprovalReminder, addApproversAction, getAllApprovalByReferenceId, getAllApprovalReminder, removeApprovalAction } from "./action";

const initialState = {
  approver: {},
  approverList: [],
  addApproverLoader: false,
  success: false,
  ReminderLoader : false,
  checkLoadingButtonId : "",
  checkHoverd : {
    id : "",
    isHoverd : false
  },
  AllreminderApproval : []
};

const ApproverSlice = createSlice({
  name: "ApproverSlice",
  initialState,
  reducers : {
    showReminderOnHover : (state , {payload})=>{
      state.checkHoverd = {id : payload.id , isHoverd : payload.data }

    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllApprovalReminder.fulfilled, (state, action) => {
      state.AllreminderApproval = action.payload.data;
      state.ReminderLoader = false;

    })
      .addCase(addApproversAction.fulfilled, (state, action) => {
        state.approver = action.payload;
        state.addApproverLoader = false;
        state.success = true;
      })
      .addCase(AddApprovalReminder.fulfilled, (state, {meta}) => {
        state.checkLoadingButtonId = ""
        state.ReminderLoader = false
        state.success = true;
      })
      .addCase(getAllApprovalByReferenceId.fulfilled, (state, action) => {
        state.approverList = action.payload ? action.payload : [];
        state.addApproverLoader = false;
        state.success = true;
      })
      .addCase(removeApprovalAction.fulfilled, (state, action) => {
    

      })
      .addMatcher(isPending(...[addApproversAction]), (state) => {
        state.addApproverLoader = true;
        state.success = false;
      })
      .addMatcher(isPending(...[AddApprovalReminder]), (state , {meta}) => {
        state.checkLoadingButtonId = meta?.arg?.requestObj?.approvalId ?? ""

        state.ReminderLoader = true;
        state.success = false;
      })
      .addMatcher(isPending(...[getAllApprovalReminder]), (state , {meta}) => {
        state.ReminderLoader = true;
      })
      .addMatcher(isRejected(...[addApproversAction]), (state) => {
        state.addApproverLoader = true;
        state.success = false;
      })
      .addMatcher(isRejected(...[AddApprovalReminder , getAllApprovalReminder]), (state) => {
        state.checkLoadingButtonId = ""
        state.ReminderLoader = false;
        state.success = false;
      });

      
  },
});
export const {
  showReminderOnHover
} = ApproverSlice.actions;
export default ApproverSlice.reducer;
