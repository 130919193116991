import { Button, Form } from "antd";
import { STRINGS } from "../../../../../../../../utils/base";
import { DocumentReferenceTypeEnum } from "../../../../../utils/DocumentReferenceTypeEnum";
import { DocumentTypeEnum } from "../../../../../utils/DocumentTypeEnum";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { PostPrivacyType } from "../../../../../../../../utils/Shared/enums/enums";
import { useDispatch } from "react-redux";
import { DOCUMENT_ENUM } from "../../../../../utils/DocumentEnum";
import { getIconByExtensionType } from "../../../../../constant/helpers";
import TextInput from "../../../../../../../sharedComponents/Input/TextInput";
import PrivacyOptions from "../../../../../../../sharedComponents/PrivacyOptionsDropdown/PrivacyOptions";
import { updateDocument } from "../../../../../store/actions";
import pdfIcon from "../../../../../../../../content/NewContent/Documents/file/PDF_DOC.svg";
import { LanguageChangeContext } from "../../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../../utils/localization/languages";

const UpdateAttachment = ({
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = DocumentReferenceTypeEnum.General,
  documentType = DocumentTypeEnum.Attachment,
  OnClose = () => {},
  item = {},
}) => {
  console.log(item, "item--->");
  const [form] = Form.useForm();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, document } = dictionaryList[userLanguage];

  const [state, setState] = useState({
    privacyId: PostPrivacyType.PUBLIC,
    isFormSubmiting: false,
  });
  const dispatch = useDispatch();

  //get values in fields
  useEffect(() => {
    form.setFieldsValue({
      name: item?.name,
    });

    onPrivacyChange(item.privacyId);
  }, [item]);

  const onPrivacyChange = (value) => {
    setState({
      ...state,
      privacyId: value,
    });
  };

  const getIconByType = (item) => {
    const { extensionTypeId, path } = item;
    return getIconByExtensionType(documentType, extensionTypeId, path);
  };

  const handleFormSubmit = () => {
    setState({
      ...state,
      isFormSubmiting: true,
    });
    let members = item.members.map((item) => ({
      memberId: item?.memberId,
      memberRightType: item.memberRightType,
      id: item?.id,
    }));

    form
      .validateFields()
      .then((values) => {
        dispatch(
          updateDocument({
            attachmentId: item?.attachmentId,
            name: values.name,
            privacyId: state.privacyId,
            members: members,
          })
        ).then(() => {
          setState({
            ...state,
            isFormSubmiting: false,
          });
          OnClose();
        });
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo);
      });
  };
  return (
    <>
      <Form
        form={form}
        name="uploadFile"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
      >
        <div className="flex justify-between items-center gap-[20px]">
          <div className="flex-1">
            <Form.Item
              label={sharedLabels.name}
              name="name"
              labelPosition="top"
              rules={[
                {
                  required: true,
                  message: sharedLabels.nameRequired,
                },
              ]}
            >
              <TextInput placeholder={sharedLabels.enterName} />
            </Form.Item>
          </div>

          <div className="cursor-pointer">
            <img
              className="h-[50px] w-[50px] rounded-md"
              src={
                documentType === DocumentTypeEnum.Image
                  ? item?.path
                  : getIconByType(item)
              }
            />
          </div>
        </div>
        <Form.Item>
          <div className="flex items-center gap-2">
            <PrivacyOptions
              privacyId={state.privacyId}
              onPrivacyChange={onPrivacyChange}
            />
            <Button
              type="primary"
              size="medium"
              className="ThemeBtn"
              block
              htmlType="submit"
              loading={state.isFormSubmiting}
              onClick={handleFormSubmit}
            >
              {"Update"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
export default UpdateAttachment;
