import React, { useState, useEffect, useRef } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import './style/style.css';

const RichTextEditor = ({ setRichTextEditorValue, placeholderValue="", editorContent='' ,readonly = false}) => {
  const contentState = stateFromHTML(editorContent);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [showToolbar, setShowToolbar] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    setShowToolbar(true);
  }, []);

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    setRichTextEditorValue(html);
    console.log(html);
  }, [editorState]);

  useEffect(()=>{
    const NewContent = stateFromHTML(editorContent)
    setEditorState(EditorState.createWithContent(NewContent))
  },[editorContent])

  const handleReturn = (event, editorState) => {
    if (!event.shiftKey) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  };

  const handleKeyCommand = (command, editorState) => {
    if (command === 'toggle-bold') {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
      return 'handled';
    } else if (command === 'toggle-underline') {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
      return 'handled';
    } else if (command === 'toggle-italic') {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
      return 'handled';
    } else if (command === 'increase-font-size') {
      handleFontSizeChange(2);
      return 'handled';
    } else if (command === 'decrease-font-size') {
      handleFontSizeChange(-2);
      return 'handled';
    }
    return 'not-handled';
  };
  const handleFontSizeChange = changeValue => {
    setEditorState(prevEditorState => {
      let newEditorState = prevEditorState;
      const currentInlineStyles = newEditorState.getCurrentInlineStyle();
      let fontSizeMatch = currentInlineStyles.find(style => style.startsWith('fontsize-'));
  
      if (fontSizeMatch) {
        const currentFontSize = parseInt(fontSizeMatch.split('-')[1]);
        const newFontSize = currentFontSize + changeValue;
        if (newFontSize >= 8) {
          newEditorState = RichUtils.toggleInlineStyle(newEditorState, fontSizeMatch);
          newEditorState = RichUtils.toggleInlineStyle(newEditorState, `fontsize-${newFontSize}`);
        }
      } else {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, `fontsize-${14}`);
      }
  
      return newEditorState;
    });
  };
  const keyBindingFn = event => {
    if ((event.ctrlKey) && (event.key === 'b' || event.key === 'B' )) {
      return 'toggle-bold';
    } else if ((event.ctrlKey) && (event.key === 'u' || event.key === 'U' )) {
      return 'toggle-underline';
    } else if ((event.ctrlKey) && (event.key === 'i' || event.key === 'I' )) {
      return 'toggle-italic';
    } else if (event.ctrlKey && event.shiftKey && event.key === '>') {
      return 'increase-font-size';
    } else if (event.ctrlKey && event.shiftKey && event.key === '<') {
      return 'decrease-font-size';
    }
    return getDefaultKeyBinding(event);
  };
  const toolbarOptions = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
    blockType: {
      options: [
        'Normal',
        'H1',
        'H2',
        'H3',
        'H4',
        'H5',
        'H6',
        'Blockquote',
        'Code',
        'UL',
        'OL',
        'Indent',
        'AlignLeft',
        'AlignCenter',
        'AlignRight',
        'AlignJustify',
      ],
    },
    fontSize: {
      options: [8, 10, 12, 14, 16, 18, 24, 30, 36, 48],
    },
    fontFamily: {
      options: [
        'Arial',
        'Georgia',
        'Impact',
        'Tahoma',
        'Times New Roman',
        'Verdana',
      ],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
    },
    link: {
      popupClassName: 'link-popup',
    },
  };
  return (
    <div className="my-header">
      {showToolbar && (
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbarOptions}
          placeholder={placeholderValue}
          onEditorStateChange={setEditorState}
          // handleReturn={handleReturn}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          ref={editorRef}
          readOnly={readonly}
          onContentStateChange={() => {
            const contentDiv = document.querySelector(".rdw-editor-main");
            contentDiv.scrollTop = contentDiv.scrollHeight;
          }}
        />
      )}
    </div>
  );
};

export default RichTextEditor;