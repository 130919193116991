import React, { useContext, useEffect, useState } from "react";
import {
  ALLOWANCE_TYPE_ENUM,
  ALLOWANCE_UNIT_ENUM,
} from "../../../../allowance/constants/enum";
import moment from "moment";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { STRINGS } from "../../../../../../utils/base";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCurrency } from "../../../../../../utils/Shared/store/actions";
import CustomTable from "../../../../../sharedComponents/customTable/CustomTable";
import { formatNumberWithCommas } from "../../../../../../utils/Shared/helper/formatNumberWithCommas";
import { Button } from "antd";

//Parent Table Columns
const ParentTableColumn = (sharedLabels, salary, defaultCurrency, showDetails) => {
  return [
    {
      title: sharedLabels.name,
      dataIndex: ["user", "name"],
      ellipsis: true,
      key: ["user", "name"],
    },
    {
      title: salary.BasicSalary,
      dataIndex: "basicSalary",
      ellipsis: true,
      key: "basicSalary",
      render: (basicSalary, record) => {
        return (
          <span>{showDetails ? `${
            record?.currencyId !== STRINGS.DEFAULTS.guid
              ? record?.currencySymbol
              : defaultCurrency?.symbol
              ? defaultCurrency?.symbol
              : ""
          } ${basicSalary?.toLocaleString()}` : "*****"}</span>
        );
      },
    },
    {
      title: salary.Deduction,
      dataIndex: "deduction",
      ellipsis: true,
      key: "deduction",
      render: (deduction, record) => {
        return (
          <span>{showDetails ? `${
            record?.currencyId !== STRINGS.DEFAULTS.guid
              ? record?.currencySymbol
              : defaultCurrency?.symbol
              ? defaultCurrency?.symbol
              : ""
          } ${deduction?.toLocaleString()}` : "*****"}</span>
        );
      },
    },
    {
      title: "Gross Salary",
      dataIndex: "basicSalary",
      ellipsis: true,
      render: (basicSalary, record) => {
        const sumAllowanceType1 = record?.details.reduce((sum, item) => {
          if (item.allowanceType === 1) {
            return sum + item.allowance;
          }
          return sum;
        }, 0);
        return (
          <div>
         {showDetails ? `${record?.currencySymbol} ${formatNumberWithCommas(basicSalary+sumAllowanceType1)}` : "*****"}
         </div>
        );
      },
    },
    {
      title: sharedLabels.netSalary,
      dataIndex: "netSalary",
      ellipsis: true,
      key: "netSalary",
      render: (netSalary, record) => {
        return (
          <span>{showDetails ? `${
            record?.currencyId !== STRINGS.DEFAULTS.guid
              ? record?.currencySymbol
              : defaultCurrency?.symbol
              ? defaultCurrency?.symbol
              : ""
          } ${netSalary?.toLocaleString()}` : "*****"}</span>
        );
      },
    },
    {
      title: salary.EffectiveDate,
      dataIndex: "effectiveDate",
      key: "effectiveDate",
      render: (date) => moment(date).format("Do MMM YY"),
    },
  ];
};

//Child Table Column
const childTableColumn = (
  sharedLabels,
  salary,
  defaultCurrency,
  currencyId,
  currencySymbol,
  showDetails
) => {
  return [
    {
      title: salary.Allowance,
      dataIndex: "allowanceName",
      key: "allowanceName",
    },
    {
      title: sharedLabels.value,
      dataIndex: "value",
      render: (text, record) => {
        return (
          <span>
            {showDetails ? record.allowanceUnit === ALLOWANCE_UNIT_ENUM.PERCENT
              ? record.value + "%"
              : `${
                  currencyId !== STRINGS.DEFAULTS.guid
                    ? currencySymbol
                    : defaultCurrency?.symbol
                    ? defaultCurrency?.symbol
                    : ""
                } ${record.value?.toLocaleString()}` : "*****"}
          </span>
        );
      },
    },
    {
      title: sharedLabels.amount,
      dataIndex: "allowance",
      render: (allowance) => {
        return (
          <span>{showDetails ? `${
            currencyId !== STRINGS.DEFAULTS.guid
              ? currencySymbol
              : defaultCurrency?.symbol
              ? defaultCurrency?.symbol
              : ""
          } ${allowance?.toLocaleString()}` : "*****"}</span>
        );
      },
    },
    {
      title: salary.IsTaxable,
      dataIndex: "allowanceIsTaxable ",
      render: (text, record) => (
        <span>{record.isTaxable ? sharedLabels.Yes : sharedLabels.No}</span>
      ),
    },
    {
      title: salary.AllowanceType,
      dataIndex: "allowanceIsTaxable ",
      render: (text, record) => (
        <span>
          {record.allowanceType === ALLOWANCE_TYPE_ENUM.BENEFIT
            ? "Benefit"
            : "Deduction"}
        </span>
      ),
    },
  ];
};

// Outer Table
export default function NestedTable({ data }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, salary } = dictionaryList[userLanguage];

  const { currencies } = useSelector((state) => state.sharedSlice);
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    currencies === null && dispatch(getCurrency());
  }, []);
  
  const defaultCurrency = currencies?.find(
    (currency) => currency.code === "USD" || ""
  );
  // Inner Table
  const expandedRowRender = (mainRowData) => {
    const childData = mainRowData?.details || [];
    const sortedTable = [...(childData || [])].sort((s1, s2) => s1?.allowanceType -  s2?.allowanceType);

    return (
      <div>
      <CustomTable
        columns={childTableColumn(
          sharedLabels,
          salary,
          defaultCurrency,
          mainRowData.currencyId,
          mainRowData.currencySymbol,
          showDetails
        )}
        dataSource={sortedTable}
        pagination={false}
        rowKey={(row) => row.id}
        
      />
      </div>
    );
  };

  return (
    <div>
      <Button className="ThemeBtn mb-1 " onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? "Hide Salaries" : "Show Salaries"}
      </Button>
    <CustomTable
      columns={ParentTableColumn(sharedLabels, salary, defaultCurrency, showDetails)}
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: [], // Default Row Open
      }}
      dataSource={data}
      pagination={false}
      rowKey={(row) => row.id}
    />
    </div>
  );
}
