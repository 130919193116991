import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
  addWarningService,
  cancelWarningService,
  getAllWarningPagingService,
  getAllWarningService,
  GetWarningByIdService,
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { openNotification } from "../../../../utils/Shared/store/slice";

export const getAllWarnings = createAsyncThunk(
  "Warning/GetAllWarning",
  async (data) => {
    const response = await getAllWarningService(data);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);

export const getAllWarningAction = createAsyncThunk(
  "Warning/GetAllWarningAction",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllWarningPagingService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllWarningService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

export const addWarning = createAsyncThunk(
  "Warning/addWarning",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addWarningService(data);
    if (res.data?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Warning Created Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.data.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.data.message);
    }
  }
);

export const GetWarningById = createAsyncThunk(
  "Warning/GetWarningById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const response = await GetWarningByIdService(data?.id || data);
    return response.data;
  }
);

export const cancelWarning = createAsyncThunk(
  "Warning/cancelWarning",
  async (id, { dispatch }) => {
    const response = await cancelWarningService(id);
    return response;
  }
);

export const getAllWarningPaging = createAsyncThunk(
  "Warning/getAllWarning/paging",
  async (data) => {
    const response = await getAllWarningPagingService(data);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);
