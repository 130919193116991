export const FeaturesEnum = {
  Feed: 1,
  Mailbox: 2,
  Messenger: 3,
  Group: 4,
  Project: 5,
  Task: 6,
  WorkBoard: 7,
  Lead: 8,
  Expense: 9,
  Schedule: 10,
  Travel: 11,
  Document: 12,
  ELearning: 13,
  Asset: 14,
  CustomApproval: 15,
  Employee: 16,
  Administration: 17,
  Appraisal: 18,
  Department: 19,
  Leave: 20,
  Loan: 21,
  Holiday: 22,
  Career: 23,
  AudioCalling: 24,
  VideoCalling: 25,
  Salary: 26,
  Attendance: 27,
  Requisition: 28,
  Payroll: 29,
  Rewards: 30,
  Complains: 31,
  Warnings: 32,
  Bonus: 33,
  Promotion: 34,
  OrganizationalChart: 35,
  MyTeam: 36,
  JobBoard: 37,
  Form: 38,
  Resignation: 39,
  BusinessPolicy: 40,
  Finance: 41, // Finance
  Voucher: 42,
  Quotation: 43,
  Company: 44,
  Reports: 45,
  DiscussionBoard: 46,
  MarketPlace: 47,
  Pages: 48,
  Issues: 49,
  Auction: 50,
  LedgerReport: 51,
  TrialBalance: 52,
  ApprovalManagement: 53,
  ChartOfAccount: 54,
  AccessRole: 55,
  Grade: 56,
  BusinessLogo: 57,
  FiscalYear: 58,
  PayrollGroup: 59,
  TaxSlabsGroup: 60,
  eLearningCategory: 61,
  Subsidiary: 62,
  SubsidiaryOffice: 63,
  ComplainCategory: 64,
  DefaultHiringCriteria: 65,
  Designation: 66,
  Appraisal2: 67,
  OfficeTimings: 68,
  LeaveTypes: 69,
  ExpenseHeader: 70,
  SalaryHeader: 71,
  EmailConfiguration: 72,
  DiscussionBoardCategory: 73,
  Billing: 74,
  DefaultAccountHeader: 75,
  CustomTag: 76,
  CustomApprovalCategory: 77,
  PageCategory: 78,
  ProjectCategory: 79,
  Allowances: 80,
  DefaultApproval: 81,
  JobDescription: 82,
  RewardCategory: 83,
  BusinessNumber: 84,
  WarningCategory: 85,
  RebateCategory: 86,
  GradeAllowances: 87,
  AssetsCategory: 88,
  NonMandatory: 89,
  Payment: 90,
  WIPGatingCriteria: 91,
  CompanyInfo: 92,
  BusinessApproval: 93,
  AttendanceSettings: 94,
  CompanyStatistics: 95,
  Room: 96,
  BusinessAssociation: 97,
  Contract : 99,
  ContractPurpose: 98
};

export const FeaturePermissionEnum = {
  ViewFeed: 1,
  PostFeed: 2,
  ViewMailbox: 3,
  ViewMessenger: 4,
  ViewGroup: 5,
  CreateGroup: 6,
  ViewProject: 7,
  CreateProject: 8,
  ViewTask: 9,
  CreateTask: 10,
  ViewWorkboard: 11,
  CreateWorkboard: 12,
  ViewLeadManager: 13,
  CreateLeadManagerGroup: 14,
  ViewExpense: 15,
  CreateExpense: 16,
  ViewSchedule: 17,
  CreateSchedule: 18,
  InviteExternals: 19,
  ViewTravel: 20,
  CreateTravel: 21,
  ViewDocument: 22,
  CreateDocument: 23,
  ViewElearning: 24,
  CreateCourse: 25,
  CreateQuiz: 26,
  CreateeBooks: 27,
  CreateTedTalks: 28,
  CreateVideos: 29,
  ViewAsset: 30,
  CreateAsset: 31,
  ViewCustomApproval: 32,
  CreateCustomApproval: 33,
  ViewEmployees: 34,
  CreateEmployees: 35,
  UpdateEmployees: 36,
  DisableEmployees: 37,
  AccessRole: 38,
  Grade: 39,
  BusinessLogo: 40,
  FiscalYear: 41,
  PayrollGroup: 42,
  TaxSlabsGroup: 43,
  eLearningCategory: 44,
  Subsidiary: 45,
  SubsidiaryOffice: 46,
  ComplainCategory: 47,
  DefaultHiringCriteria: 48,
  Designation: 49,
  Appraisal: 50,
  OfficeTimings: 51,
  LeaveTypes: 52,
  ExpenseHeader: 53,
  SalaryHeader: 54,
  EmailConfiguration: 55,
  ViewAppraisal: 56,
  CreateAppraisal: 57,
  ViewDepartment: 58,
  CreateDepartment: 59,
  ViewLeave: 60,
  CreateLeave: 61,
  ViewLoan: 62,
  CreateLoan: 63,
  ViewHoliday: 64,
  CreateHoliday: 65,
  ViewCareer: 66,
  CreateCareer: 67,
  ViewSalary: 68,
  CreateSalary: 69,
  ViewAttendanceCheckIn: 70,
  ViewRequisition: 71,
  CreateRequisition: 72,
  ViewPayroll: 73,
  CreatePayroll: 74,
  ViewRewards: 75,
  CreateRewards: 76,
  ViewComplains: 77,
  CreateComplains: 78,
  ViewWarnings: 79,
  CreateWarnings: 80,
  ViewBonus: 81,
  CreateBonus: 82,
  ViewPromotion: 83,
  CreatePromotion: 84,
  ViewOrganizationalChart: 85,
  ViewMyTeam: 86,
  ViewJobBoard: 87,
  ViewForm: 88,
  CreateForm: 89,
  ViewResignation: 90,
  CreateResignation: 91,
  ViewBusinessPolicy: 92,
  CreateBusinessPolicy: 93,
  ViewChartOfAccount: 94,
  CreateChartOfAccount: 95,
  ViewVouchers: 96,
  CreateVouchers: 97,
  ViewQuotation: 98,
  CreateQuotation: 99,
  ViewCompany: 100,
  CreateArticle: 101,
  ViewReports: 102,
  CreateDiscussionBoard: 103,
  ViewDiscussionBoard: 104,
  ViewMarketPlace: 105,
  CreatePages: 106,
  ViewPages: 107,
  CreateAuction: 127,
  ViewAuction: 128,
  ViewTrialBalance: 130,
  RemoveApproval: 132,
  ViewDiscussionBoardCategory: 133, // new-- 
  ViewBilling: 134,  
  ViewDefaultAccountHeader: 135,  
  ViewCustomTag: 136,  
  ViewCustomApprovalCategory: 137,  
  ViewPageCategory: 138,  
  ViewProjectCategory: 139,  
  ViewAllowances: 140,  
  ViewDefaultApproval: 141,  
  ViewJobDescription: 142,  
  ViewRewardCategory: 143,  
  ViewBusinessNumber: 144,  
  ViewWarningCategory: 145,  
  ViewRebateCategory: 146,  
  ViewGradeAllowances: 147,
  ViewAssetsCategory: 148,
  ViewNonMandatory: 149,
  ViewPayment: 150,
  ViewWIPGatingCriteria: 151,
  ViewCompanyInfo: 152,
  ViewBusinessApproval: 153,  
  ViewAttendanceSettings: 154,
  ViewCompanyStatistics: 155,
  ViewRoom: 156,
  ViewBusinessAssociation: 157,  
  UpdateAttendance: 158, 
  AttendanceCheckInApproved: 159,
  ViewAttendanceMyTeams: 160,
  ViewLeaves: 161,
  ViewRewardsMyTeams: 162,
  ViewWarning: 163,
  ViewComplainsMyTeams: 164,
  ViewLoanMyTeams: 165,
  ViewAppraisalMyTeams: 166,
  ViewStorageStatistics: 167,
  MakeAudioCalling: 168,
  MakeVideoCalling: 169,
  ViewContractPurpose: 170,
  ViewContract : 171,
  CreateContract : 172
};
export const FeaturePermissionEnumList = [
  {
    id: FeaturePermissionEnum.ViewFeed,
    name: "View Feed",
    featureId: FeaturesEnum.Feed,
  },
  {
    id: FeaturePermissionEnum.PostFeed,
    name: "Post Feed",
    featureId: FeaturesEnum.Feed,
  },
  {
    id: FeaturePermissionEnum.ViewMailbox,
    name: "View Mailbox",
    featureId: FeaturesEnum.Mailbox,
  },
  {
    id: FeaturePermissionEnum.ViewMessenger,
    name: "View Messenger",
    featureId: FeaturesEnum.Messenger,
  },
  {
    id: FeaturePermissionEnum.ViewGroup,
    name: "View Group",
    featureId: FeaturesEnum.Group,
  },
  {
    id: FeaturePermissionEnum.CreateGroup,
    name: "Create Group",
    featureId: FeaturesEnum.Group,
  },
  {
    id: FeaturePermissionEnum.ViewProject,
    name: "View Project",
    featureId: FeaturesEnum.Project,
  },
  {
    id: FeaturePermissionEnum.CreateProject,
    name: "Create Project",
    featureId: FeaturesEnum.Project,
  },
  {
    id: FeaturePermissionEnum.ViewTask,
    name: "View Task",
    featureId: FeaturesEnum.Task,
  },
  {
    id: FeaturePermissionEnum.CreateTask,
    name: "Create Task",
    featureId: FeaturesEnum.Task,
  },
  {
    id: FeaturePermissionEnum.ViewWorkboard,
    name: "View Workboard",
    featureId: FeaturesEnum.WorkBoard,
  },
  {
    id: FeaturePermissionEnum.CreateWorkboard,
    name: "Create Workboard",
    featureId: FeaturesEnum.WorkBoard,
  },
  {
    id: FeaturePermissionEnum.ViewLeadManager,
    name: "View Lead Manager",
    featureId: FeaturesEnum.Lead,
  },
  {
    id: FeaturePermissionEnum.CreateLeadManagerGroup,
    name: "Create Lead Manager Group",
    featureId: FeaturesEnum.Lead,
  },
  {
    id: FeaturePermissionEnum.ViewExpense,
    name: "View Expense",
    featureId: FeaturesEnum.Expense,
  },
  {
    id: FeaturePermissionEnum.CreateExpense,
    name: "Create Expense",
    featureId: FeaturesEnum.Expense,
  },
  {
    id: FeaturePermissionEnum.ViewSchedule,
    name: "View Schedule",
    featureId: FeaturesEnum.Schedule,
  },
  {
    id: FeaturePermissionEnum.CreateSchedule,
    name: "Create Schedule",
    featureId: FeaturesEnum.Schedule,
  },
  {
    id: FeaturePermissionEnum.InviteExternals,
    name: "Invite Externals",
    featureId: FeaturesEnum.Schedule,
  },
  {
    id: FeaturePermissionEnum.ViewTravel,
    name: "View Travel",
    featureId: FeaturesEnum.Travel,
  },
  {
    id: FeaturePermissionEnum.CreateTravel,
    name: "Create Travel",
    featureId: FeaturesEnum.Travel,
  },
  {
    id: FeaturePermissionEnum.ViewDocument,
    name: "View Document",
    featureId: FeaturesEnum.Document,
  },
  {
    id: FeaturePermissionEnum.CreateDocument,
    name: "Create Document",
    featureId: FeaturesEnum.Document,
  },
  {
    id: FeaturePermissionEnum.ViewElearning,
    name: "View Elearning",
    featureId: FeaturesEnum.ELearning,
  },
  {
    id: FeaturePermissionEnum.CreateCourse,
    name: "Create Course",
    featureId: FeaturesEnum.ELearning,
  },
  {
    id: FeaturePermissionEnum.CreateQuiz,
    name: "Create Quiz",
    featureId: FeaturesEnum.ELearning,
  },
  {
    id: FeaturePermissionEnum.CreateeBooks,
    name: "Create eBooks",
    featureId: FeaturesEnum.ELearning,
  },
  {
    id: FeaturePermissionEnum.CreateTedTalks,
    name: "Create Ted Talks",
    featureId: FeaturesEnum.ELearning,
  },
  {
    id: FeaturePermissionEnum.CreateVideos,
    name: "Create Videos",
    featureId: FeaturesEnum.ELearning,
  },
  {
    id: FeaturePermissionEnum.ViewAsset,
    name: "View Asset",
    featureId: FeaturesEnum.Asset,
  },
  {
    id: FeaturePermissionEnum.CreateAsset,
    name: "Create Asset",
    featureId: FeaturesEnum.Asset,
  },
  {
    id: FeaturePermissionEnum.ViewCustomApproval,
    name: "View CustomApproval",
    featureId: FeaturesEnum.CustomApproval,
  },
  {
    id: FeaturePermissionEnum.CreateCustomApproval,
    name: "Create CustomApproval",
    featureId: FeaturesEnum.CustomApproval,
  },
  {
    id: FeaturePermissionEnum.ViewEmployees,
    name: "View Employees",
    featureId: FeaturesEnum.Employee,
  },
  {
    id: FeaturePermissionEnum.CreateEmployees,
    name: "Create Employees",
    featureId: FeaturesEnum.Employee,
  },
  {
    id: FeaturePermissionEnum.UpdateEmployees,
    name: "Update Employees",
    featureId: FeaturesEnum.Employee,
  },
  {
    id: FeaturePermissionEnum.DisableEmployees,
    name: "Disable Employees",
    featureId: FeaturesEnum.Employee,
  },
  {
    id: FeaturePermissionEnum.AccessRole,
    name: "AccessRole",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.Grade,
    name: "Grade",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.BusinessLogo,
    name: "Business Logo",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.FiscalYear,
    name: "Fiscal Year",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.PayrollGroup,
    name: "Payroll Group",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.TaxSlabsGroup,
    name: "TaxSlabs Group",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.eLearningCategory,
    name: "eLearning Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.Subsidiary,
    name: "Subsidiary",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.SubsidiaryOffice,
    name: "Subsidiary Office",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ComplainCategory,
    name: "Complain Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewContractPurpose ,
    name: "Contact Purpose",
    featureId: FeaturesEnum.ContractPurpose ,
  },
  {
    id: FeaturePermissionEnum.DefaultHiringCriteria,
    name: "Default Hiring Criteria",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.Designation,
    name: "Designation",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.Appraisal,
    name: "Appraisal",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.OfficeTimings,
    name: "Office Timings",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.LeaveTypes,
    name: "Leave Types",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ExpenseHeader,
    name: "Expense Header",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.SalaryHeader,
    name: "Salary Header",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.EmailConfiguration,
    name: "Email Configuration",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewDiscussionBoardCategory,
    name: "View DiscussionBoard Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewBilling,
    name: "View Billing",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewDefaultAccountHeader,
    name: "View Default Account Header",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewCustomTag,
    name: "View CustomTag",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewCustomApprovalCategory,
    name: "View Custom Approval Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewPageCategory,
    name: "View Page Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewProjectCategory,
    name: "View Project Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewAllowances,
    name: "View Allowances",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewDefaultApproval,
    name: "View Default Approval",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewJobDescription,
    name: "View Job Description",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewRewardCategory,
    name: "View Reward Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewBusinessNumber,
    name: "View Business Number",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewWarningCategory,
    name: "View Warning Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewRebateCategory,
    name: "View Rebate Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewGradeAllowances,
    name: "View Grade Allowances",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewAssetsCategory,
    name: "View Assets Category",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewNonMandatory,
    name: "View Non-Mandatory",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewPayment,
    name: "View Payment",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewWIPGatingCriteria,
    name: "View WIP Gating Criteria",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewCompanyInfo,
    name: "View Company Info",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewBusinessApproval,
    name: "View Business Approval",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewAttendanceSettings,
    name: "View Attendance Settings",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewCompanyStatistics,
    name: "View Company Statistics",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewRoom,
    name: "View Room",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewBusinessAssociation,
    name: "View Business Association",
    featureId: FeaturesEnum.Administration,
  },
  {
    id: FeaturePermissionEnum.ViewAppraisal,
    name: "View Appraisal",
    featureId: FeaturesEnum.Appraisal,
  },
  {
    id: FeaturePermissionEnum.CreateAppraisal,
    name: "Create Appraisal",
    featureId: FeaturesEnum.Appraisal,
  },
  {
    id: FeaturePermissionEnum.ViewDepartment,
    name: "View Department",
    featureId: FeaturesEnum.Department,
  },
  {
    id: FeaturePermissionEnum.CreateDepartment,
    name: "Create Department",
    featureId: FeaturesEnum.Department,
  },
  {
    id: FeaturePermissionEnum.ViewLeave,
    name: "View Leave",
    featureId: FeaturesEnum.Leave,
  },
  {
    id: FeaturePermissionEnum.CreateLeave,
    name: "Create Leave",
    featureId: FeaturesEnum.Leave,
  },
  {
    id: FeaturePermissionEnum.ViewLoan,
    name: "View Loan",
    featureId: FeaturesEnum.Loan,
  },
  {
    id: FeaturePermissionEnum.CreateLoan,
    name: "Create Loan",
    featureId: FeaturesEnum.Loan,
  },
  {
    id: FeaturePermissionEnum.ViewHoliday,
    name: "View Holiday",
    featureId: FeaturesEnum.Holiday,
  },
  {
    id: FeaturePermissionEnum.CreateHoliday,
    name: "Create Holiday",
    featureId: FeaturesEnum.Holiday,
  },
  {
    id: FeaturePermissionEnum.ViewCareer,
    name: "View Career",
    featureId: FeaturesEnum.Career,
  },
  {
    id: FeaturePermissionEnum.CreateCareer,
    name: "Create Career",
    featureId: FeaturesEnum.Career,
  },
  {
    id: FeaturePermissionEnum.ViewSalary,
    name: "View Salary",
    featureId: FeaturesEnum.Salary,
  },
  {
    id: FeaturePermissionEnum.CreateSalary,
    name: "Create Salary",
    featureId: FeaturesEnum.Salary,
  },
  {
    id: FeaturePermissionEnum.ViewAttendanceCheckIn,
    name: "View Attendance CheckIn",
    featureId: FeaturesEnum.Attendance,
  },
  {
    id: FeaturePermissionEnum.UpdateAttendance,
    name: "Update Attendance",
    featureId: FeaturesEnum.Attendance,
  },
  {
    id: FeaturePermissionEnum.ViewRequisition,
    name: "View Requisition",
    featureId: FeaturesEnum.Requisition,
  },
  {
    id: FeaturePermissionEnum.CreateRequisition,
    name: "Create Requisition ",
    featureId: FeaturesEnum.Requisition,
  },
  {
    id: FeaturePermissionEnum.ViewPayroll,
    name: "View Payroll",
    featureId: FeaturesEnum.Payroll,
  },
  {
    id: FeaturePermissionEnum.CreatePayroll,
    name: "Create Payroll",
    featureId: FeaturesEnum.Payroll,
  },
  {
    id: FeaturePermissionEnum.ViewRewards,
    name: "View Rewards",
    featureId: FeaturesEnum.Rewards,
  },
  {
    id: FeaturePermissionEnum.CreateRewards,
    name: "Create Rewards",
    featureId: FeaturesEnum.Rewards,
  },
  {
    id: FeaturePermissionEnum.ViewComplains,
    name: "View Complains",
    featureId: FeaturesEnum.Complains,
  },
  {
    id: FeaturePermissionEnum.CreateComplains,
    name: "Create Complains",
    featureId: FeaturesEnum.Complains,
  },
  {
    id: FeaturePermissionEnum.ViewWarnings,
    name: "View Warnings",
    featureId: FeaturesEnum.Warnings,
  },
  {
    id: FeaturePermissionEnum.CreateWarnings,
    name: "Create Warnings",
    featureId: FeaturesEnum.Warnings,
  },
  {
    id: FeaturePermissionEnum.ViewBonus,
    name: "View Bonus",
    featureId: FeaturesEnum.Bonus,
  },
  {
    id: FeaturePermissionEnum.CreateBonus,
    name: "Create Bonus",
    featureId: FeaturesEnum.Bonus,
  },
  {
    id: FeaturePermissionEnum.ViewPromotion,
    name: "View Promotion",
    featureId: FeaturesEnum.Promotion,
  },
  {
    id: FeaturePermissionEnum.CreatePromotion,
    name: "Create Promotion",
    featureId: FeaturesEnum.Promotion,
  },
  {
    id: FeaturePermissionEnum.ViewOrganizationalChart,
    name: "View Organizational Chart",
    featureId: FeaturesEnum.OrganizationalChart,
  },
  {
    id: FeaturePermissionEnum.ViewMyTeam,
    name: "View MyTeam",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.AttendanceCheckInApproved,
    name: "Attendance Check-In Approved",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewAttendanceMyTeams,
    name: "View Attendance My-Teams",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewLeaves,
    name: "View Leaves",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewRewardsMyTeams,
    name: "View Rewards My-Teams",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewWarning,
    name: "View Warning",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewComplainsMyTeams,
    name: "View Complains My-Teams",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewLoanMyTeams,
    name: "View Loan My-Teams",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewAppraisalMyTeams,
    name: "View Appraisal My-Teams",
    featureId: FeaturesEnum.MyTeam,
  },
  {
    id: FeaturePermissionEnum.ViewStorageStatistics,
    name: "View Storage Statistics",
    featureId: FeaturesEnum.MyTeam,
  },
  // {
  //   id: FeaturePermissionEnum.ViewJobBoard,
  //   name: "View JobBoard",
  //   featureId: FeaturesEnum.JobBoard,
  // },
  {
    id: FeaturePermissionEnum.ViewForm,
    name: "View Form",
    featureId: FeaturesEnum.Form,
  },
  {
    id: FeaturePermissionEnum.CreateForm,
    name: "Create Form",
    featureId: FeaturesEnum.Form,
  },
  {
    id: FeaturePermissionEnum.ViewResignation,
    name: "View Resignation",
    featureId: FeaturesEnum.Resignation,
  },
  {
    id: FeaturePermissionEnum.CreateResignation,
    name: "Create Resignation",
    featureId: FeaturesEnum.Resignation,
  },
  {
    id: FeaturePermissionEnum.ViewDiscussionBoard,
    name: "View DiscussionBoard ",
    featureId: FeaturesEnum.DiscussionBoard,
  },

  {
    id: FeaturePermissionEnum.CreateDiscussionBoard,
    name: "Create DiscussionBoard",
    featureId: FeaturesEnum.DiscussionBoard,
  },
  {
    id: FeaturePermissionEnum.ViewPages,
    name: "View Pages ",
    featureId: FeaturesEnum.Pages,
  },
  {
    id: FeaturePermissionEnum.CreatePages,
    name: "Create Pages ",
    featureId: FeaturesEnum.Pages,
  },
  {
    id: FeaturePermissionEnum.ViewBusinessPolicy,
    name: "View BusinessPolicy ",
    featureId: FeaturesEnum.BusinessPolicy,
  },
  {
    id: FeaturePermissionEnum.CreateBusinessPolicy,
    name: "Create BusinessPolicy",
    featureId: FeaturesEnum.BusinessPolicy,
  },
  {
    id: FeaturePermissionEnum.ViewChartOfAccount,
    name: "View Chart Of Account",
    featureId: FeaturesEnum.ChartOfAccount,
  },
  {
    id: FeaturePermissionEnum.CreateChartOfAccount,
    name: "Create Chart Of Account",
    featureId: FeaturesEnum.ChartOfAccount,
  },
  {
    id: FeaturePermissionEnum.ViewVouchers,
    name: "View Vouchers",
    featureId: FeaturesEnum.Voucher,
  },
  {
    id: FeaturePermissionEnum.CreateVouchers,
    name: "Create Vouchers",
    featureId: FeaturesEnum.Voucher,
  },

  {
    id: FeaturePermissionEnum.ViewQuotation,
    name: "View Quotation",
    featureId: FeaturesEnum.Quotation,
  },
  {
    id: FeaturePermissionEnum.CreateQuotation,
    name: "Create Quotation",
    featureId: FeaturesEnum.Quotation,
  },
  {
    id: FeaturePermissionEnum.ViewCompany,
    name: "View Company",
    featureId: FeaturesEnum.Company,
  },
  {
    id: FeaturePermissionEnum.ViewReports,
    name: "View Ledger Report",
    featureId: FeaturesEnum.LedgerReport,
  },
  {
    id: FeaturePermissionEnum.ViewTrialBalance,
    name: "View Trial Balance",
    featureId: FeaturesEnum.TrialBalance,
  },
  {
    id: FeaturePermissionEnum.ViewAuction,
    name: "View Auction",
    featureId: FeaturesEnum.Auction,
  },
  {
    id: FeaturePermissionEnum.CreateAuction,
    name: "Create Auction",
    featureId: FeaturesEnum.Auction,
  },
  {
    id: FeaturePermissionEnum.ViewMarketPlace,
    name: "View MarketPlace",
    featureId: FeaturesEnum.MarketPlace,
  },
  {
    id: FeaturePermissionEnum.MakeAudioCalling,
    name: "Make Audio Calling",
    featureId: FeaturesEnum.AudioCalling,
  },
  {
    id: FeaturePermissionEnum.MakeVideoCalling,
    name: "Make Video Calling",
    featureId: FeaturesEnum.VideoCalling,
  },
  {
    id: FeaturePermissionEnum.ViewContract,
    name: "View Contract",
    featureId: FeaturesEnum.Contract,
  },
  {
    id: FeaturePermissionEnum.CreateContract,
    name: "Create Contract",
    featureId: FeaturesEnum.Contract,
  },
];

export const FeaturesEnumList = [
  {
    label: "Feed",
    value: FeaturesEnum.Feed,
    description: "A board to have open conversations",
  },
  { label: "Mailbox", value: FeaturesEnum.Mailbox },
  { label: "Messenger", value: FeaturesEnum.Messenger },
  { label: "Group", value: FeaturesEnum.Group },
  { label: "Project", value: FeaturesEnum.Project },
  {
    label: "Task",
    value: FeaturesEnum.Task,
    description: "Key tasks and milestones",
  },
  {
    label: "Workboard",
    value: FeaturesEnum.WorkBoard,
    description: "A Kanban methodology board to manage tasks",
  },
  { label: "Lead", value: FeaturesEnum.Lead },
  {
    label: "Expense",
    value: FeaturesEnum.Expense,
    description: "Expense management",
  },
  {
    label: "Schedule",
    value: FeaturesEnum.Schedule,
    description: "Schedule to manage timelines",
  },
  {
    label: "Travel",
    value: FeaturesEnum.Travel,
    description: "ManagementTravel requirements",
  },
  { label: "Document", value: FeaturesEnum.Document, description: "documents" },
  { label: "ELearning", value: FeaturesEnum.ELearning },
  { label: "Asset", value: FeaturesEnum.Asset },
  { label: "Custom Approval", value: FeaturesEnum.CustomApproval },
  { label: "Employees", value: FeaturesEnum.Employee },
  { label: "Administration", value: FeaturesEnum.Administration },
  { label: "Appraisal", value: FeaturesEnum.Appraisal },
  { label: "Department", value: FeaturesEnum.Department },
  { label: "Leave", value: FeaturesEnum.Leave },
  { label: "Loan", value: FeaturesEnum.Loan },
  { label: "Holiday", value: FeaturesEnum.Holiday },
  { label: "Career", value: FeaturesEnum.Career },
  { label: "Audio Calling", value: FeaturesEnum.AudioCalling },
  { label: "Video Calling", value: FeaturesEnum.VideoCalling },
  { label: "Salary", value: FeaturesEnum.Salary },
  { label: "Attendance", value: FeaturesEnum.Attendance },
  { label: "Requisition", value: FeaturesEnum.Requisition },
  { label: "Payroll", value: FeaturesEnum.Payroll },
  { label: "Rewards", value: FeaturesEnum.Rewards },
  { label: "Complains", value: FeaturesEnum.Complains },
  { label: "Warnings", value: FeaturesEnum.Warnings },
  { label: "Bonus", value: FeaturesEnum.Bonus },
  { label: "Promotion", value: FeaturesEnum.Promotion },
  { label: "Organizational Chart", value: FeaturesEnum.OrganizationalChart },
  { label: "My Team", value: FeaturesEnum.MyTeam },
  { label: "Job Board", value: FeaturesEnum.JobBoard },
  { label: "Form", value: FeaturesEnum.Form },
  { label: "Resignation", value: FeaturesEnum.Resignation },
  { label: "BusinessPolicy", value: FeaturesEnum.BusinessPolicy },
  { label: "Discussion Board", value: FeaturesEnum.DiscussionBoard },
  { label: "Pages", value: FeaturesEnum.Pages },
  { label: "Chart of Account", value: FeaturesEnum.ChartOfAccount },
  { label: "Voucher", value: FeaturesEnum.Voucher },
  { label: "Quotation", value: FeaturesEnum.Quotation },
  { label: "Company", value: FeaturesEnum.Company },
  {label:"Contract",value:FeaturesEnum.Contract},
];
