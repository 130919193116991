import { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { approvalDictionaryList } from "../localization";
import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";
import { dictionaryList } from "../../../../utils/localization/languages";

export default function ApprovalModuleLabel(module) {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { approvalDictionaryLabel } = dictionaryList[userLanguage];
    const [state, setState] = useState("");
    const prepareLabel = () => {
        switch (module) {
            case ApprovalsModuleEnum.ExpenseExecutor:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ExpenseExecutor]
                break;
            case ApprovalsModuleEnum.ExpenseFinance:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ExpenseFinance]
                break;
            case ApprovalsModuleEnum.ResignationHrApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationHrApproval]
                break;
            case ApprovalsModuleEnum.ResignationFinanceApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationFinanceApproval]
                break;
            case ApprovalsModuleEnum.ResignationItApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationItApproval]
                break;
            case ApprovalsModuleEnum.ResignationAdminApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationAdminApproval]
                break;
            case ApprovalsModuleEnum.ResignationOtherApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationOtherApproval]
                break;
            case ApprovalsModuleEnum.ResignationExitApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationExitApproval]
                break;
            case ApprovalsModuleEnum.ResignationReportingToApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.ResignationReportingToApproval]
                break;
            case ApprovalsModuleEnum.TravelApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.TravelApproval]
                break;
            case ApprovalsModuleEnum.TravelAgent:
                return approvalDictionaryLabel[ApprovalsModuleEnum.TravelAgent]
                break;
            case ApprovalsModuleEnum.RequisitionFinalApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.RequisitionFinalApproval]
                break;
            case ApprovalsModuleEnum.LoanApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.LoanApproval]
                break;
            case ApprovalsModuleEnum.AuctionApproval:
                return approvalDictionaryLabel[ApprovalsModuleEnum.AuctionApproval]
            default:
                return approvalDictionaryLabel?.Approval;
        }
    }

    return prepareLabel();
    /* useEffect(()=>{
         const label = prepareLabel();
         labelName(label);
         setState(label);
     },[]);
 
     return(<>{state}</>);*/
}
export function ApprovalModulePlaceHolder(module) {


    const { userLanguage } = useContext(LanguageChangeContext);
    const { approvalDictionaryPlaceHolder, } = dictionaryList[userLanguage];

    switch (module) {
        case ApprovalsModuleEnum.ExpenseApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ExpenseApproval]
        case ApprovalsModuleEnum.ExpenseExecutor:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ExpenseExecutor]
            break;
        case ApprovalsModuleEnum.ExpenseFinance:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ExpenseFinance]
            break;
        case ApprovalsModuleEnum.ResignationHrApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationHrApproval]
            break;
        case ApprovalsModuleEnum.ResignationFinanceApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationFinanceApproval]
            break;
        case ApprovalsModuleEnum.ResignationItApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationItApproval]
            break;
        case ApprovalsModuleEnum.ResignationAdminApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationAdminApproval]
            break;
        case ApprovalsModuleEnum.ResignationOtherApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationOtherApproval]
            break;
        case ApprovalsModuleEnum.ResignationExitApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationExitApproval]
            break;
        case ApprovalsModuleEnum.ResignationReportingToApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.ResignationReportingToApproval]
            break;
        case ApprovalsModuleEnum.TravelApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.TravelApproval]
            break;
        case ApprovalsModuleEnum.TravelAgent:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.TravelAgent]
            break;
        case ApprovalsModuleEnum.RequisitionFinalApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.RequisitionFinalApproval]
            break;
        case ApprovalsModuleEnum.LoanApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.LoanApproval]
        case ApprovalsModuleEnum.AuctionApproval:
            return approvalDictionaryPlaceHolder[ApprovalsModuleEnum.AuctionApproval]
        default:
            return approvalDictionaryPlaceHolder.Approval;
    }
}