import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import {
  addContractPurpose,
  getAllContractPurpose,
  updateContractCategory,
} from "./actions.js";

const initialState = {
  contractPurpose: [],
  loadingData: false,
  loader: false,
  success: false,
  error: false,
};

const contractPurposeSlice = createSlice({
  name: "contractPurpose",
  initialState,
  reducers: {
    ContractPurposeDeleted: (state, { payload }) => {
      state.contractPurpose = state.contractPurpose.filter(
        (e) => e.id !== payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContractPurpose.fulfilled, (state, { payload }) => {
        console.log(payload, "FROM SLICE");
        state.loadingData = false;
        state.contractPurpose = payload.data;
      })


      .addCase(addContractPurpose.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.contractPurpose.push(payload.data);
      })


      .addCase(updateContractCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.contractPurpose = state.contractPurpose.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })


      .addMatcher(
        isPending(...[addContractPurpose, updateContractCategory]),
        (state) => {
          state.loader = true;
          state.success = false;
          state.error = false;
        }
      )


      .addMatcher(isPending(...[getAllContractPurpose]), (state) => {
        state.loadingData = true;
        state.success = false;
        state.error = false;
      })

      
      .addMatcher(
        isRejected(
          ...[
            getAllContractPurpose,
            addContractPurpose, 
            updateContractCategory,
          ]
        ),
        (state) => {
          state.loader = false;
          state.loadingData = false;
          state.success = false;
          state.error = false;
        }
      );
  },
});

export const { ContractPurposeDeleted } = contractPurposeSlice.actions;
export default contractPurposeSlice.reducer;
