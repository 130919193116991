import React from "react";
import CardStatusTagView from "./CardStatusTagView";
import PropTypes from "prop-types";
import ProfileDetailView from "./ProfileDetailView";

function CardProfileTopView(props) {
  return (
    <div className="flex items-center justify-between w-full gap-2 sm:gap-0 flex-wrap">
      <ProfileDetailView
        profileImgSrc={props.profileImgSrc}
        profileImgSize={props.profileImgSize}
        createDate={props.createDate}
        isPublic={props.isPublic}
        name={props.name}
        destination={props.destination}
        showIcon={props.showIcon}
      />
      <CardStatusTagView refNo={props.refNo} status={props.status} notificationCount={props?.notificationCount}/>
    </div>
  );
}

export default CardProfileTopView;
CardProfileTopView.propTypes = {
  refNo: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.element]).isRequired,
  isPublic: PropTypes.bool.isRequired,
  createDate: PropTypes.string.isRequired,
  profileImgSrc: PropTypes.string.isRequired,
  profileImgSize: PropTypes.number.isRequired,
  destination: PropTypes.string.isRequired,
  notificationCount: PropTypes.number.isRequired
};
CardProfileTopView.defaultProps = {
  refNo: "",
  status: 0,
  isPublic: true,
  createDate: "",
  profileImgSrc: "https://joeschmoe.io/api/v1/random",
  profileImgSize: 40,
  notificationCount: 0
};
