import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
    responseMessage,
    responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {

    addProjectCategoryService,
    getAllProjectCategoryService,
    removeProjectCategoryService,
    updateProjectCategoryService,
} from "../services/service";

export const getAllProjectCategory = createAsyncThunk(
    "ProjectCategory/getAllProjectCategory",
    async (args, { dispatch }) => {
        const res = await getAllProjectCategoryService();
        if (!res.responseCode) {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }
        return res;
    }
);

export const addProjectCategory = createAsyncThunk(
    "ProjectCategory/addProjectCategory",
    async (args, { dispatch }) => {
        const res = await addProjectCategoryService(args);
        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                message.success("Project Category added successfully!")
            responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }

        return res;
    }
);

export const updateProjectCategory = createAsyncThunk(
    "ProjectCategory/updateProjectCategory",
    async (args, { dispatch }) => {
        const res = await updateProjectCategoryService(args);
        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                message.success("Project Category updated successfully!")
            responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }

        return res;
    }
);

export const removeProjectCategory = createAsyncThunk(
    "ProjectCategory/removeProjectCategory",
    async (args, { dispatch }) => {
        const res = await removeProjectCategoryService(args.id);

        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                res.message = "Project Category removed successfully!";
            responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }

        return res;
    }
);
