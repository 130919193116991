import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

const initialState = {
  commentsQueue: [],
};

const realTimeEventSlice = createSlice({
  name: "realTime",
  initialState,
  reducers: {
    removeCommentQueue: (state, { payload }) => {
      const filteredQueue = state.commentsQueue.filter((item) => item.referenceId !== payload);
      if(filteredQueue.length !== state.commentsQueue.length){
        state.commentsQueue = filteredQueue;
      }
    },
    pushCommentQueue: (state, { payload }) => {
      state.commentsQueue.push(payload)
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(getAllNotification.fulfilled, (state, { payload }) => {
  //     state.data = payload;
  //   });
  // }
});

export const { pushCommentQueue, removeCommentQueue } = realTimeEventSlice.actions;
export default realTimeEventSlice.reducer;
