import React from "react";
import MainBannerContent from "../../auth/view/AuthPageContent/MainBannerContent";
import PaymentGatway from "./index";
import "../../auth/view/styles/style.css"
import { Col, Row } from "antd";
import InvoiceAndPaymentDetailCard from "./InvoiceDetail";
import InvoiceAndPaymentCardWrapper from "./invoiceAndPaymentCardWrapper";



const MainComponent = () => {

	return (
		<div className="lg-area">
			<div className="lg-area-color-layer" />

			<Row gutter={{ xs: 0, sm: 0, lg: 24 }} className="main-landing-row">
				<Col
					className="SinginFirstColumn"
					xs={{ order: 2, span: 24 }}
					sm={{ order: 1, span: 12 }}
					lg={{ order: 1, span: 12 }}
				>
						<MainBannerContent />
				</Col>
				<Col
					className="FormColumn"
					xs={{ order: 1, span: 24 }}
					sm={{ order: 2, span: 12 }}
					lg={{ order: 2, span: 12 }}
				>
						<InvoiceAndPaymentCardWrapper/>
						
				</Col>
			</Row>
		</div>
	);
};


export default MainComponent;
