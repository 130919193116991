import { useState } from "react";
import { useSelector } from "react-redux";
import PostHeader from "./PostHeader";
import PostSection from "./PostSection";
import "../stylesheet/Post.css";
import "../../../../../../../../src/App.css";

const PostBody = ({
  post = { attachments: [] },
  isDetail = false,
  isShared = false,
}) => {
  const [modelState, setmodelState] = useState(false);
  const {
    userSlice: { user },
  } = useSelector((state) => state);
  const userPermissions = user.permissions;

  const openModel = (value) => {
    setmodelState(value);
  };

  const {
    id,
    creator,
    isPinned = false,
    tags,
    createDate,
    privacyId,
    attachments,
  } = post;

  return (
    <>
      <PostHeader
        id={id}
        privacyId={privacyId}
        creator={creator}
        isPinned={isPinned}
        tags={tags}
        createDate={createDate}
        isShared={isShared}
      />
      <PostSection
        post={post}
        attachments={attachments}
        isOpen={modelState}
        onOpen={openModel}
        id={id}
        isDetail={isDetail}
        isShared={isShared}
      />
    </>
  );
};

export default PostBody;
