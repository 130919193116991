import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useContext } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { VOUCHER_ENUM } from "../../utils/constant";
import { dictionaryList } from "../../../../../utils/localization/languages";
import CustomSelectAccount from "../../../../sharedComponents/CustomTreeSelect/CustomsSelectAccount";

const CreateEntryItem = ({
  index,
  accounts,
  handleChange,
  handleRemoveRow,
  value,
  handleCntrlSpacePress
}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { voucher } = dictionaryList[userLanguage];
  const onChangeAccount = (accId) => {
    handleChange(accId, "accountId", index);
  };
  const onDr_Cr_Change = (typeId) => {
    handleChange(typeId, "dr_cr", index);

  };
  const handleInputChange = (e) => {
    handleChange(e.target.value, e.target.name, index);
  };

  const accountName = accounts.find((item) => item?.id === value?.accountId)



  //click on cntrl+space for copy content
  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.keyCode === 32) {
      handleCntrlSpacePress(index, e.target.name);
    }

  }
  return (
    <tr >
      <td>{index + 1}</td>
      <td>
        <CustomSelectAccount data={accounts} value={accountName?.name} onChange={onChangeAccount} index={index} handleCntrlSpacePress={handleCntrlSpacePress} bordered={false} />
      </td>
      <td>
        <input
          name="chequeNo"
          onChange={handleInputChange}
          value={value?.chequeNo}
          onKeyDown={handleKeyDown}
        />
      </td>
      <td>
        <input
          className="w-full"
          name="narration"
          onChange={handleInputChange}
          value={value?.narration}
          onKeyDown={handleKeyDown}


        />
      </td>
      <td>
        <input
          name="amount"
          onChange={handleInputChange}
          value={value.amount}
          onKeyDown={handleKeyDown}

        />
      </td>
      <td>
        <Select
          optionFilterProp="children"
          onChange={onDr_Cr_Change}
          name="dr_cr"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          value={value.dr_cr}
        >
          {[
            { label: voucher.Dr, value: VOUCHER_ENUM.DR_CR.DR },
            { label: voucher.Cr, value: VOUCHER_ENUM.DR_CR.CR },
          ].map(({ label, value }) => (
            <Option value={value}>{label}</Option>
          ))}
        </Select>
      </td>
      <td onClick={() => handleRemoveRow(index)}>
        <DeleteOutlined />
      </td>
    </tr>
  );
};
export default CreateEntryItem;



