import newsIcon from "../../../content/svg/menu/newNavBarIcon/News Feed.svg";
import mailsIcon from "../../../content/svg/menu/newNavBarIcon/Mail Box.svg";
import messengerIcon from "../../../content/svg/menu/newNavBarIcon/Messenger.svg";
import groupsIcon from "../../../content/svg/menu/newNavBarIcon/Groups.svg";
import projectsIcon from "../../../content/svg/menu/newNavBarIcon/Projects.svg";
import taskIcon from "../../../content/svg/menu/newNavBarIcon/Tasks.svg";
import todoBoard from "../../../content/svg/menu/newNavBarIcon/Work Board.svg";
import marketPlace from "../../../content/svg/menu/newNavBarIcon/new/marketplace.svg";
import contactManagerIcon from "../../../content/svg/menu/newNavBarIcon/Lead Manager.svg";
import schedulesIcon from "../../../content/svg/menu/newNavBarIcon/Schedules.svg";
import expensesIcon from "../../../content/svg/menu/newNavBarIcon/Expenses.svg";
import travelIcon from "../../../content/svg/menu/newNavBarIcon/Travel.svg";
import eLearningIcon from "../../../content/svg/menu/newNavBarIcon/E Learning.svg";
import customApprovalIcon from "../../../content/svg/menu/newNavBarIcon/Custom Approval.svg";
import promotionIcon from "../../../content/svg/menu/newNavBarIcon/promotionIcon.svg";
import teamIcon from "../../../content/svg/menu/newNavBarIcon/My Team.svg";
import payrollIcon from "../../../content/svg/menu/newNavBarIcon/PayrolIcon.svg";
import employeeIcon from "../../../content/svg/menu/newNavBarIcon/Employees.svg";
import administrator from "../../../content/svg/menu/newNavBarIcon/Administration.svg";
import appraisalsIcon from "../../../content/svg/menu/newNavBarIcon/Appraisals.svg";
import leavesIcon from "../../../content/svg/menu/newNavBarIcon/Leaves.svg";
import companyIcon from "../../../content/svg/menu/newNavBarIcon/companies.svg";
import loanIcon from "../../../content/svg/menu/newNavBarIcon/Loan.svg";
import career from "../../../content/svg/menu/newNavBarIcon/Career.svg";
import resignation from "../../../content/svg/menu/newNavBarIcon/resignation.svg";
import rewardIcon from "../../../content/svg/menu/rewardIcon.svg";
import complainIcon from "../../../content/svg/menu/newNavBarIcon/complainIcon.svg";
import contractsIcon from "../../../content/svg/menu/newNavBarIcon/contracts.svg";
import departmentIcon from "../../../content/NewContent/department/departmentIcon.svg";
import bonusIcon from "../../../content/NewContent/bonus/bonus.svg";
import orgChartIcon from "../../../content/svg/menu/newNavBarIcon/Org Chart.svg";
import coaIcon from "../../../content/NewContent/chartOfAccount/coa.svg";
import reportIcon from "../../../content/NewContent/report/report.svg";
import documentIcon from "../../../content/NewContent/Documents/file/folder.svg";
import voucherIcon from "../../../content/NewContent/voucher/voucher.svg";
import requisitionIcon from "../../../content/svg/menu/newNavBarIcon/Employees.svg";
import assetsIcon from "../../../content/svg/menu/newNavBarIcon/assetsIC.svg";
import businessPolicy from "../../../content/svg/menu/newNavBarIcon/businessPolicy.svg";
import salaryIcon from "../../../content/svg/menu/newNavBarIcon/salary.svg";
import assetsAllocationIcon from "../../../content/svg/menu/newNavBarIcon/assetAllocation.svg";
import createAssetsIcon from "../../../content/svg/menu/newNavBarIcon/createassets.svg";
import requestListIcon from "../../../content/svg/menu/newNavBarIcon/requestItems.svg";
import quotationIcon from "../../../content/svg/menu/newNavBarIcon/quotationsIcon.svg";
import chartAccountIcon from "../../../content/svg/menu/newNavBarIcon/chartAccountsIcon.svg";
import auction from "../../../content/auction/navbar/AuctionIcon1.svg";
import voucherListIcon from "../../../content/NewContent/voucher/voucherList.svg";
import trialIcon from "../../../content/NewContent/trialBalance/trialBalance.svg";
import attendance from "../../../content/svg/menu/newNavBarIcon/AttendanceIcon.svg";

import { FeaturesEnum } from "../../../utils/Shared/enums/featuresEnums";

function getIconByFeatureId(featureId) {
  switch (featureId) {
    case FeaturesEnum.Feed:
      return newsIcon;
    case FeaturesEnum.Schedule:
      return schedulesIcon;
    case FeaturesEnum.Messenger:
      return messengerIcon;
    case FeaturesEnum.Mailbox:
      return mailsIcon;
    case FeaturesEnum.Lead:
      return contactManagerIcon;
    case FeaturesEnum.CustomApproval:
      return customApprovalIcon;
    case FeaturesEnum.Travel:
      return travelIcon;
    case FeaturesEnum.Document:
      return documentIcon;
    case FeaturesEnum.ELearning:
      return eLearningIcon;
    case FeaturesEnum.Employee:
      return employeeIcon;
    case FeaturesEnum.Appraisal:
      return appraisalsIcon;
    case FeaturesEnum.Project:
      return projectsIcon;
    case FeaturesEnum.WorkBoard:
      return todoBoard;
    case FeaturesEnum.Group:
      return groupsIcon;
    case FeaturesEnum.Expense:
      return expensesIcon;
    case FeaturesEnum.Administration:
      return administrator;
    case FeaturesEnum.Task:
    case FeaturesEnum.Transaction:
      return taskIcon;
    case FeaturesEnum.Contract:
      return contractsIcon;
    case FeaturesEnum.Promotion:
      return promotionIcon;
    case FeaturesEnum.Warnings:
    case FeaturesEnum.Career:
      return career;
    case FeaturesEnum.Bonus:
      return bonusIcon;
    case FeaturesEnum.OrganizationalChart:
      return orgChartIcon;
    case FeaturesEnum.Complains:
      return complainIcon;
    case FeaturesEnum.Rewards:
      return rewardIcon;
    case FeaturesEnum.MyTeam:
    case FeaturesEnum.DiscussionBoard:
      return teamIcon;
    case FeaturesEnum.Leave:
      return leavesIcon;
    case FeaturesEnum.Department:
      return departmentIcon;
    case FeaturesEnum.JobBoard:
    case FeaturesEnum.Voucher:
      return voucherIcon;
    case FeaturesEnum.ChartOfAccount:
      return chartAccountIcon;
    case FeaturesEnum.Quotation:
      return quotationIcon;
    case FeaturesEnum.LedgerReport:
      return reportIcon;
    case FeaturesEnum.TrialBalance:
      return trialIcon;
    case FeaturesEnum.Payroll:
      return payrollIcon;
    case FeaturesEnum.Salary:
      return salaryIcon;
    case FeaturesEnum.BusinessPolicy:
      return businessPolicy;
    case FeaturesEnum.Form:
      return coaIcon;
    case FeaturesEnum.MarketPlace:
      return marketPlace;
    case FeaturesEnum.Auction:
      return auction;
    case FeaturesEnum.Pages:
    case "RequestListItems":
      return requestListIcon;
    case FeaturesEnum.Loan:
      return loanIcon;
    case FeaturesEnum.Resignation:
      return resignation;
    case FeaturesEnum.Attendance:
      return attendance;
    case FeaturesEnum.Requisition:
      return requisitionIcon;
    case FeaturesEnum.Asset:
      return assetsAllocationIcon;
    case FeaturesEnum.Company:
      return companyIcon;
    case "VoucherList":
      return voucherListIcon;
    case "CreateAsset":
      return createAssetsIcon;
    case "AssetList":
      return assetsIcon;
    default:
      return null;
  }
}

export default getIconByFeatureId;
