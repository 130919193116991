import React, { useEffect, useState, useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";
import { dictionaryList } from "../../../../../utils/localization/languages";

const VoucherFooter = ({ dr, cr }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { voucher } = dictionaryList[userLanguage];
  return (
    <div className="flex items-center">
      <div className="totalAmountItem flex mx-5">
        <div className="totalAmountLabel">{voucher.Dr} : </div>
        <div className="totalAmountValue">{formatNumberWithCommas(dr.toFixed(2))}</div>
      </div>
      <div className="totalAmountItem flex mx-5">
        <div className="totalAmountLabel">{voucher.Cr} : </div>
        <div className="totalAmountValue">{formatNumberWithCommas(cr.toFixed(2))}</div>
      </div>
      <div className="totalAmountItem flex mx-5">
        <div className="totalAmountLabel">
          {voucher.differences} :
        </div>
        <div className="totalAmountValue">{formatNumberWithCommas((dr - cr).toFixed(2))}</div>
      </div>
    </div>
  );
};
export default VoucherFooter;
