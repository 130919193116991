export const CommentModuleEnum = {
    Feed: 1,
    UserTask: 2,
    WorkBoard: 3,
    WorkBoardTodo: 4,
    Document: 5,
    LeadManager: 6,
    LeadManagerDetail: 7,
    LeadManagerContact: 8,
    Schedule: 9,
    Requisition: 10,
    CareerApplicant: 11,
    WorkBoardSection: 12,
    ProjectContact: 13,
    DiscussionBoard: 14,
    Page: 15,
    BusinessNumber: 16,
    Issues: 17,
    Project: 18,
    Group: 19,
    Auction: 20,
    AuctionOffer: 21,
    Career: 22,

}