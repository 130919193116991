const turkish = {
  employeesDictionary: {
    placeholders: {
      organization: "Organization",
      department: "Department",
      subsidiary: "Subsidiary",
      subsidiaryOffice: "Subsidiary Office",
      payroll: "Payroll ",
      fName: "Ad Girin",
      lName: "Soyadı Girin",
      fatherName: "Baba Adı Girin",
      email: "E-posta Girin",
      pEmail: "Kişisel E-posta Girin",
      phNo: "Telefon Numarasını Girin",
      rAddress: "Konut Adresini Girin",
      pAddress: "Kalıcı Adres Girin",
      cnicNo: "CNIC Numarasını Girin",
      searchToSelect: "Seçmek İçin Ara",
      probPeriod: "Soyadı Girin0",
      noticePeriod: "Soyadı Girin1",
      selectDate: "Soyadı Girin2",
      selectGender: "Soyadı Girin3",
      selectMarital: "Soyadı Girin4",
      selectShift: "Soyadı Girin5",
      empNo: "Soyadı Girin6",
      empType: "Soyadı Girin7",
      selectUserType: "Soyadı Girin8",
      selectAccessRole: "Soyadı Girin9",
      degree: "Baba Adı Girin0",
      institute: "Baba Adı Girin1",
      desc: "Baba Adı Girin2",
      tMarks: "Baba Adı Girin3",
      oMarks: "Baba Adı Girin4",
      sDate: "Baba Adı Girin5",
      eDate: "Baba Adı Girin6",
      position: "Baba Adı Girin7",
      name: "Baba Adı Girin8",
      address: "Baba Adı Girin9",
      number: "E-posta Girin0",
      selectRelation: "E-posta Girin1",
      bankName: "E-posta Girin2",
      accTitle: "E-posta Girin3",
      branchCode: "E-posta Girin4",
      accNo: "E-posta Girin5",
      iban: "E-posta Girin6",
      sortCode: "E-posta Girin7",
    },
    EmployeeForm: {
      organization: "Organization",
      department: "Department",
      subsidiary: "Subsidiary",
      subsidiaryOffice: "Subsidiary Office",
      payroll: "Payroll ",
      AddImage: "Resim Ekle",
      FirstName: "Ad",
      LastName: "Soyadı",
      FatherName: "Baba Adı",
      Email: "E-posta",
      PersonalEmail: "Kişisel E-posta",
      CNICNumber: " CNIC Numarası",
      rAddress: "İkamet Adresi",
      pAddress: "Kalıcı Adres",
      PhoneNumber: " Telefon Numarası",
      Designation: "Ad0",
      Manager: "Ad1",
      Grades: "Ad2",
      Department: "Ad3",
      Country: "Ad4",
      City: "Ad5",
      Languages: "Ad6",
      ProbationPeriod: "Ad7",
      NoticePeriod: "Ad8",
      DateOfBirth: "Ad9",
      DateOfJoining: "Soyadı0",
      Gender: "Soyadı1",
      MaritalStatus: "Soyadı2",
      OfficeShift: "Soyadı3",
      EmploymentType: "Soyadı4",
      UserType: "Soyadı5",
      AccessRole: "Soyadı6",
      EmpNo: "Soyadı7",
    },
    EmergencyForm: {
      UpdateEmergency: "Update Emergency",
      EmergencyInfo: "Acil Durum Bilgisi",
      Designation: "Tanıma",
      Address: "Adres",
      Number: "Sayı",
      Relation: "İlişki",
      Name: "Ad",
      AddMoreEmergency: "Daha Fazla Acil Durum Ekle",
      AddEmergency: "Daha Fazla Acil",
    },

    EducationForm: {
      UpdateBasicInfo: "Update BasicInfo",
      BasicInfo: "Basic Info",
      AddEducation: "Add Education",
      UpdateEducation: "Update Education",
      EducationInfo: "Eğitim Bilgileri",
      Degree: "Derece",
      Institute: "Enstitü",
      StartEndDate: "Başlangıç/Bitiş Tarihi",
      StartDate: "Başlangıç ​​Tarihi",
      Description: "Açıklama",
      TotalMarks: "Toplam Puanlar",
      ObtainedMarks: "Alınan Notlar",
      Present: "Mevcut",
      Attachments: "Ekler",
      AddEducation: "Derece0",
      Upload: "Derece1",
      City: "Derece2",
    },
    WorkExperienceForm: {
      organization: "Organization",
      WorkExperienceInfo: "Derece3",
      Employer: "Derece4",
      Position: "Derece5",
      Designation: "Derece6",
      Department: "Derece7",
      StartEndDate: "Başlangıç/Bitiş Tarihi",
      StartDate: "Başlangıç ​​Tarihi",
      Present: "Mevcut",
      AddMoreExperience: "Derece",
      AddExperience: "Derece",
      EmploymentType: "Derece9",
      City: "Derece2",
      UpdateExperience: "Update Experience",
    },
    UserForm: {
      UserRights: "Enstitü0",
      UserType: "Enstitü1",
      UserRole: "Enstitü2",
    },
    BankForm: {
      updateBank: "Update Bank",
      BankInfo: "Enstitü3",
      BankName: "Enstitü4",
      AccountTitle: "Enstitü5",
      BranchCode: "Enstitü6",
      AccountNumber: "Enstitü7",
      IBAN: "Enstitü8",
      SortCode: "Enstitü9",
      City: "Derece2",
      Country: "Başlangıç/Bitiş Tarihi0",
      AddMoreBank: "Başlangıç/Bitiş Tarihi1",
      AddBank: "Başlangıç/Bitiş Tarihi",
    },

    EmployeeList: {
      number: "Sayı",
      email: "E-posta",
      designation: "Tanımlama",
    },
    AddEmployee: "Add Employee",
  },

  /*** Define language write side ***/
  Direction: "ltr",
};
export default turkish;
