import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums/";
import {
  addPromotion,
  GetPromotionById,
  GetAllPromotionsAction,
} from "./actions";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PromotionStatusEnum } from "../utils/PromotionStatusEnum";

const initialState = {
  data: null, //This will be with identifiers
  newItem: null,
  promotions: [],
  idFilterButton: null,
  cancelPromotion: {},
  loadingData: false,
  loader: true,
  createLoader: false,
  promotionDetail: {},
  drawerOpen: false,
  approvers: [],
  pagingPromotion: {},
  totalRecords: [],
  recordSize: 0,
  PromotionState: {},
};

const promotionSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data[identifier];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
    },
    handleFilterChange: (state, { payload }) => {
      state.promotions = [];
      state.data = [];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    handlePromotionApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;

        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );
              let newStatus = null;
              
              if(approverStatus.length > 1 )
              {
                 
                    if (approverStatus.length === 2 && approverStatus.includes(ApprovalStatus.Approved) && approverStatus.includes(ApprovalStatus.Removed)) {
                      newStatus = ApprovalStatus.Approved;
                  } else {
                      newStatus = ApprovalStatus.InProcess;
                  }
              
              }
              else
              {
                newStatus = approverStatus[0] === ApprovalStatus.Removed ? ApprovalStatus.Approved : approverStatus[0]
              }

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status:
                  newStatus,
                approverStatus:
                  newStatus,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) { }
    },

    handleNotificationNewItem: (state, { payload }) => {

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === payload.featureId)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },
    readCountforPromotion: (state, { payload }) => {
      const id = payload;
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];

              referenceItem = {
                ...referenceItem,
                notificationCount: 0
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
          state.idFilterButton = id
        }
      } catch (e) { }

    },
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },

    cancelPromotionSuccess: (state, { payload }) => {
      let promotionList = [...state.promotions];
      let index = promotionList.findIndex(
        (item) => item.id === payload.promotionId
      );
      let promotion = promotionList.filter(
        (item) => item.id === payload.promotionId
      )[0];

      promotionList[index] = {
        ...promotion,
        status: ApprovalStatus.Cancelled,
      };

      state.promotions = promotionList;
      state.promotionDetail = {
        ...promotion,
        status: ApprovalStatus.Cancelled,
      };
    },
    updateApprover: (state, action) => {

      const { stateapprovalDetail, index } = action.payload;
      if (stateapprovalDetail?.length > 0) {
        {
          state.approvers[index] = stateapprovalDetail;
        }
      }
    },
    updatePromotionStatus: (state, { id, status }) => {
      const index = state.promotions.findIndex((item) => item.id == id);
      if (index !== -1) {
        state.promotions[index].status = status;
      }
    },
    releasePromotionState: (state, { identifer }) => {
      delete state.PromotionState[identifer];
    },
    handleAddPromotionApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem?.approvers,
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handlePromotionReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllPromotionsAction.fulfilled, (state, action) => {
      const { segmentType, identifier, filter } = action?.meta?.arg;
      const data = action.payload ?? [];
      if (segmentType === SegmentTypeEnum.Grid) {
        state.data = {
          ...state.data,
          [identifier]: [...(state.data?.[identifier] || []), ...data],
        };
        state.recordSize = data.length;
      } else {
        state.data = {
          ...state.data,
          [identifier]: data.data,
        };
        state.totalRecords = action?.payload?.totalRecords;
      }
    });

    builder.addCase(GetPromotionById.fulfilled, (state, action) => {
      const { identifier } = action?.meta?.arg;
      const data = action.payload.data ?? [];

      state.data = {
        ...state.data,
        [identifier]: [data],
      };
    });

    builder
      .addCase(addPromotion.fulfilled, (state, { payload }) => {
        state.newItem = payload.data.data;
      })
  },
});

export const {
  handleOpenComposer,
  cancelPromotionSuccess,
  updatePromotionStatus,
  updateApprover,
  releasePromotionState,
  handleNewItem,
  removeIdentifier,
  handlePromotionApprovalRemark,
  handleResetState,
  handleAddPromotionApprover,
  readCountforPromotion,
  handleNotificationNewItem,
  handlePromotionReminder
} = promotionSlice.actions;
export default promotionSlice.reducer;
