import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { getDiscussionBoardByIdAction } from "../../store/action";
import DiscussionBoardDetail from "../Page/Detail/detailView";
import { STRINGS } from "../../../../../utils/base";
import moment from "moment";

export default function DiscussionBoardNotificationDetail({ id }) {
  const dispatch = useDispatch();
    const { boardDetail } = useSelector((state) => state.DiscussionBoardSlice);

  const [state, setState] = useState({
    identifier: moment().unix(),
  });
  let boarddetail = boardDetail !== null && boardDetail !== undefined ? boardDetail[state.identifier] : {};

  useEffect(() => {
    id &&
      id != undefined &&
      id != STRINGS.DEFAULTS.guid &&
      dispatch(getDiscussionBoardByIdAction({ id: id, identifier: state.identifier }))
  }, [id]);

  return (
    Object.keys(boarddetail ? boarddetail : {}).length !== 0 && (
      <DiscussionBoardDetail item={boarddetail} key={id} />
    )
  );
}
