import React, { useState } from "react";
import { STRINGS } from "../../../utils/base";
import BasicModal from "../Modal/BasicModal/BasicModal";
import AddMember from "../../features/members/view/addMember/addMember";
import { addApproversAction } from "../AppComponents/Approvals/action/action";
import { useDispatch } from "react-redux";
import { ApprovalsModuleEnum } from "../../features/approval/utils/enum/approvalModuleEnum";
import { ExpenseReferenceTypeEnum } from "../../features/expense/utils/expenseReferenceTypeEnum";
import { SuggestionTypeEnum } from "../SuggestionBox/utils/SuggestionTypeEnum";
import { TravelReferenceTypeEnum } from "../../features/travel/utils/travelReferenceTypeEnum";
const CustomAddApproversModal = ({
  referenceId = STRINGS.DEFAULTS.guid,
  visible = false,
  onClose,
  existingMembers = [],
  module = 0,
  referenceType = 0,
  referenceTypeID = STRINGS.DEFAULTS.guid,
  notRequiredMemberId = [],
  levelNo = 0,
}) => {

  console.log("==ExistingMember==",existingMembers)
  const [state, setState] = useState({
    newApprovers: [],
    key: "",
  });
  const dispatch = useDispatch();

  const prepareSuggestionTypes = () => {
    switch (module) {
      case ApprovalsModuleEnum.ExpenseApproval:
      case ApprovalsModuleEnum.ExpenseExecutor:
      case ApprovalsModuleEnum.ExpenseFinance:
        switch (referenceType) {
          case ExpenseReferenceTypeEnum.Group:
            return [SuggestionTypeEnum.GroupMember];
          case ExpenseReferenceTypeEnum.Project:
            return [SuggestionTypeEnum.ProjectMember];
          default:
            return [SuggestionTypeEnum.Employee];
        }
      case ApprovalsModuleEnum.TravelApproval:
      case ApprovalsModuleEnum.TravelAgent:
        switch (referenceType) {
          case TravelReferenceTypeEnum.Project:
            return [SuggestionTypeEnum.ProjectMember];
          default:
            return [SuggestionTypeEnum.Employee];
        }
      default:
        return [SuggestionTypeEnum.Employee];
    }
  };

  const handleAddApprovals = () => {
    if (state.newApprovers.length > 0) {
      const payload = {
        approverId: state.newApprovers.map((member) => {
          return { approverId: member.id , levelNo:levelNo };
        }),
        module: module,
        referenceId: referenceId,
      };

      dispatch(addApproversAction(payload)).then((response) => {
        let approver = response?.payload;

        setState({ ...state, key: new Date() });
        onClose();
      });
    }
  };

  const resetState = (data = null) => {
    setState({ ...state, key: new Date() });
    onClose();
  };

  return (
    <>
      <BasicModal
        isOpen={visible}
        onCancel={() => {
          resetState();
        }}
        onSave={() => {
          handleAddApprovals();
        }}
      >
        <AddMember
          key={referenceId}
          removeSelf={true}
          placeHolder={"Add Approvals"}
          callback={(data) => {
            setState({ ...state, newApprovers: data });
          }}
          existingMembers={[
            ...(existingMembers?.[0]?.createBy ? [existingMembers?.[0]?.createBy] : []),
            ...existingMembers.map((member) => member.approverId),
            ...notRequiredMemberId
          ]}
          suggestionType={prepareSuggestionTypes()}
          referenceId={referenceTypeID}
        />
      </BasicModal>
    </>
  );
};
export default CustomAddApproversModal;
