import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import {
	updateLongBusinessLogoService,
	updateFullBusinessLogoService,
	updateShortBusinessLogoService,
} from "../services/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { updateUser} from "../../../../store/appReducer/userSlice";
export const updateLongLogoAction = createAsyncThunk(
	`businesslogo/updateBusinessLogo`,
	async (data, { dispatch,rejectWithValue }) => {
		const response = await updateLongBusinessLogoService(data);
		if (response.data?.responseCode === responseCode.Success) {
			dispatch(
				openNotification({
					message: "Long BusinessLogo Added Successfully",
					type: "success",
				})
			);
			dispatch(updateUser({businessImage:response.data.data.path}));
			return response.data.data;
		}
		else {
			message.error(response.data.message);
			return rejectWithValue(response.data.message);
		}
	}
);
export const updateShortLogoAction = createAsyncThunk(
	`businesslogo/updateBusinessLogoActionnn`,
	async (data, { dispatch,rejectWithValue }) => {
		const response = await updateShortBusinessLogoService(data);
		console.log(response, "responseLogo");
		if (response.data?.responseCode === responseCode.Success) {
			dispatch(
				openNotification({
					message: "Short BusinessLogo Added Successfully",
					type: "success",
				})
			);
			dispatch(updateUser({businessShortImage:response.data.data.path}));
			return response.data.data;
		}
		else {
			message.error(response.data.message);
			return rejectWithValue(response.data.message);
		}
	}
);
export const updateFullBusinessLogoAction = createAsyncThunk(
	`businesslogo/updateLogoAction`,
	async (data, { dispatch,rejectWithValue }) => {
		const response = await updateFullBusinessLogoService(data);
		console.log(response, "responseLogo");
		if (response.data?.responseCode === responseCode.Success) {
			dispatch(
				openNotification({
					message: "Full BusinessLogo Added Successfully",
					type: "success",
				})
			);
			return response.data.data;
		}
		else {
			message.error(response.data.message);
			return rejectWithValue(response.data.message);
		}
	}
);