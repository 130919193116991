import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { Form, Input } from "antd";

const FormHeader = (props) => {
  const {
    description,
    title,
    handleChangeEmail,
    disableSubmit,
    isAcceptingResp,
  } = props;
  const { userLanguage } = useContext(LanguageChangeContext);
  const { forms } = dictionaryList[userLanguage];

  return (
    <>
      <div className="c-row bg-clr">
        <div className="f-head-item p_15">
          <h1 className="tlt">{title}</h1>
          <p className="desc f-bold">{description}</p>
        </div>
      </div>
      {!disableSubmit && (
        <div className="c-row txt-fields bg-clr p_15">
            <Form.Item
              name="email"
              rules={[
                { type: "email", message: "The input is not a valid email" },
              ]}
              className="w-[80%] !mb-[0px]"
            >
              <div>
                <label className="required label" htmlFor="">
                  {forms.EnterEmail}
                </label>
                <Input
                  placeholder={forms.EnterEmail}
                  onChange={handleChangeEmail}
                  className="styled-input"
                />
              </div>
            </Form.Item>
        </div>
      )}
    </>
  );
};

export default FormHeader;
