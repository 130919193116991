import React, { useContext, useState } from "react";

import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { Button, Input, message } from "antd";
import store from "../../../../../../store/store";
import { feedSlice } from "../../../store/slice";
import ToneSelect from "../../../../../sharedComponents/AI/toneSelect";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { useDispatch } from "react-redux";
import { GenerateAIResult } from "../../../../../../utils/Shared/store/actions";

const AiInput = ({ isOpen, setIsOpenAi }) => {
	const { userLanguage } = useContext(LanguageChangeContext);
	const { newsFeed } = dictionaryList[userLanguage];
	const [tone, setTone] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isFirstSubmit, setIsFirstSubmit] = useState(true);
	const [state, setState] = useState(({
		input: "",
		tone: 0
	}))
	const dispatch = useDispatch()
	const handleSubmit = async () => {
		setIsLoading(true)

		const aiQuestion = `Write a paragraph about ${state.input} in the ${tone} in two line`
		const response = await dispatch(GenerateAIResult(aiQuestion));
		if (response.payload.message === 'success') {
			setIsOpenAi(false)
			setIsFirstSubmit(false)
			simulateTypingEffect(response.payload.data)
		} else {
			message.error(response.message)
		}

		setIsLoading(false)
	}

	const simulateTypingEffect = (response) => {
		const textToType = response;
		let currentIndex = 0;

		const typingInterval = setInterval(() => {
			const currentText = textToType.substring(0, currentIndex + 5);
			store.dispatch(feedSlice.actions.onPostTitleTextChange({ value: currentText }))
			currentIndex += 5;

			if (currentIndex >= textToType.length) {
				clearInterval(typingInterval);
			}
		}, 20); // Delay between each character (adjust as needed)
	};

	if (!isOpen) return <></>

	return (
		<>
			<div className="ai-input"  >
				<div className="input-cont"  >
					<Input
						size="small"
						className="poll-input"
						placeholder={newsFeed.Whatwouldyouliketotalkabout}
						onChange={(e) => setState({
							...state,
							input: e.target.value
						})} />

					<ToneSelect setTone={setTone} />
				</div>
				<Button
					className="generateBtn"
					type="submit"
					onClick={() => {
						handleSubmit()
					}}
					loading={isLoading} >{isFirstSubmit ? newsFeed.Generate : newsFeed.Change}


				</Button>
			</div>
		</>
	);
};

export default AiInput;