import { Button, Form, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import TextInput from "../../../sharedComponents/Input/TextInput";
import { useSelector, useDispatch } from "react-redux";
import {
  userTypeEnum,
  userTypeList,
} from "../../../../utils/Shared/enums/enums";
import { addInList } from "../store/slice";
import { getAllDesignation } from "../../designation/store/actions";
import CustomSelect from "../../../sharedComponents/Select/Select"

const { Option } = Select;

const initialState = {
  id: "",
  name: "",
};

const QuickForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState(initialState);
  const [userTypeValue, setUserTypeValue] = useState([]);

  const { success, items } = useSelector((state) => state.quickAddSlice);
  const { designations } = useSelector((state) => state.designationSlice);

  const onFinish = (values) => {
    // console.log(values);
    let email = values.email.toLowerCase();
    let phoneNo = values.phoneNo.toLowerCase();
    let error = false;

    if (items.length > 0) {
      items.map((item) => {
        if (email === item.email || phoneNo === item.phoneNo) {
          message.error("Email or Phone Number already exist");
          error = true;
        }
      });
    }

    if (!error) {
      dispatch(addInList({...values, userTypeId: userTypeEnum.Employee}));
      form.resetFields();
    }
  };

 useEffect(()=>{
   dispatch(getAllDesignation())
 },[dispatch])

  useEffect(() => {
    if (success) {
      form.resetFields();
    }
  }, [success]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="quickAddForm">
      <div className="formHeader">
        <h2 className="font-semibold">Add Quick Employee</h2>
      </div>
      <Form
        form={form}
        name="quickAdd"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="row">
          <Form.Item
            label={"First Name"}
            name="firstName"
            labelPosition="top"
            rules={[
              {
                required: true,
                message: "Please Enter First Name",
              },
            ]}
          >
            <TextInput placeholder={"Enter First Name"} />
          </Form.Item>
          <Form.Item
            label={"Last Name"}
            name="lastName"
            labelPosition="top"
            rules={[
              {
                required: true,
                message: "Please Enter Last Name",
              },
            ]}
          >
            <TextInput placeholder={"Enter Last Name"} />
          </Form.Item>
          <Form.Item
            label={"Email"}
            name="email"
            labelPosition="top"
            rules={[
              {
                required: true,
                message: "Please Enter Email",
              },
            ]}
          >
            <TextInput placeholder={"Enter Email"} type="email" />
          </Form.Item>
          {/* <Form.Item
            name="userTypeId"
            rules={[{ required: true }]}
            label={"Type"}
          >
            <Select
              onChange={(value) => {
                setUserTypeValue(value);
              }}
              size="large"
              placeholder={"Select User Type"}
              getPopupContainer={(trigger) => trigger.parentNode}
              options={userTypeList
                .filter(
                  (x) =>
                    x.id === userTypeEnum.Employee ||
                    x.id === userTypeEnum.Admin
                )
                .map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
            >
              {userTypeList.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item> */}

        <Form.Item
          rules={[{ required: true, message: "Please select designation!" }]}
          name="designationId"
          labelPosition="top"
          label={"Designation"}
          placeholder={"Please select Designation"}
          className="quickAddDesignation"
        >
          <CustomSelect
            showSearch={true}
            data={designations}
            size="large"
            placeholder="Select designation"
          />
        </Form.Item>

          <Form.Item
            label={"Phone Number"}
            name="phoneNo"
            labelPosition="top"
            rules={[
              {
                required: true,
                message: "Please Enter Phone Number",
              },
            ]}
          >
            <TextInput placeholder={"Enter Phone Number"} type="number" />
          </Form.Item>
        </div>
        <div className="formButtons">
          <Form.Item>
            <Button
              type="primary"
              size="medium"
              className="ThemeBtn"
              block
              htmlType="submit"
              // loading={loader}
              title={"Quick Add"}
            >
              {"Add"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default QuickForm;
