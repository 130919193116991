import MasterConfig from "../../../../utils/services/MasterConfig";
export const getAllDiscussionBoardService = (data) => {
	return MasterConfig.post(`api/DiscussionBoard/GetAllDiscussionBoard`, data)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addDiscussionBoardService = args => {
	return MasterConfig.post(`api/DiscussionBoard/AddDiscussionBoard`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const GetDiscussionBoardById = id => {
	return MasterConfig.get(`api/DiscussionBoard/GetDiscussionBoardById?id=${id}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const updateDiscussionBoardStatusService = args => {
	let id = args.id;
	let status = args.status;
	return MasterConfig.put(`api/DiscussionBoard/UpdateDiscussionBoardStatus?id=${id}&status=${status}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const updateDiscussionBoardService = args => {
	return MasterConfig.put(`api/DiscussionBoard/UpdateDiscussionBoard`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addDiscussionBoardMemberService = (data) => {
	let id = data.id;
	let memberId = data.memberId;
	return MasterConfig.post(`api/DiscussionBoard/AddDiscussionBoardMember?id=${id}`, [
		{ memberId: memberId },
	])
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const removeDiscussionBoardMemberService = (data) => {
	let id = data.id;
	let memberId = data.memberId;
	return MasterConfig.post(`api/DiscussionBoard/RemoveDiscussionBoardMember?id=${id}`, [
		memberId,
	])
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};