import React, { useEffect } from "react";
import AvatarOld from "../../../../sharedComponents/Avatar/avatarOLD";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import DestinationTag from "./destinationTag";


const SharePostHeader = ({
  setReferenceId,
  referenceId,
  setPostReferenceType,
  moduleReferenceType,
  modulePrivacyId,
  postRefType,
  postRefId,
}) => {
  const isExtraSmall = useMediaQuery({ query: "(max-width: 600px)" });

  const {
    userSlice: {
      user: { name, userImage },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    setReferenceId(postRefId);
  }, [postRefId]);

  return (
    <>
      <div className="avatar-wrapper">
        <AvatarOld
          width={isExtraSmall ? 33 : 35}
          height={isExtraSmall ? 33 : 35}
          src={userImage}
          name={name}
          round
        />

        <div className="flex flex-col items-baseline">
          <h3 className="text-bold">{name}</h3>
          <DestinationTag
            postReferenceType={postRefType}
            postReferenceId={referenceId}
            setPostReferenceType={setPostReferenceType}
            setPostReferenceId={setReferenceId}
          />
        </div>
      </div>
    </>
  );
};

export default SharePostHeader;
