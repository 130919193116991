import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums/";
import { ResignationApprovalsModuleEnum } from "../utils/resignationApprovalModuleEnum";
import { ResignationApprovalsModuleStatusEnum } from "../utils/resignationApprovalModuleStatusEnum";
import {
  addResignation,
  getAllResignations,
  GetResignationById,
  getAllResignationPagingAction,
  getAllResignationAction,
} from "./action";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { calculateFinalStatus } from "../../travel/utils/calculateStatus";

const initialState = {
  data: null,
  newItem: null,
  newCountFilterButton: false,
};

const resignationSlice = createSlice({
  name: "resignations",
  initialState,
  reducers: {
    handleResetState: (state, { payload }) => {
      state.data = [];
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    handleResignationApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark, module } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              const approvers = [
                ...referenceItem[ResignationApprovalsModuleEnum[module]],
              ];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                [ResignationApprovalsModuleEnum[module]]: finalApprovers,
                [ResignationApprovalsModuleStatusEnum[module]]:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };
              const statusEnums = Object.values(
                ResignationApprovalsModuleStatusEnum
              );
              const statuses = statusEnums.map(
                (statusKey) => referenceItem[statusKey]
              );

              referenceItem = {
                ...referenceItem,
                status: calculateFinalStatus(statuses),
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleAddResignationApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem[ResignationApprovalsModuleEnum[module]],
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                [ResignationApprovalsModuleEnum[module]]: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleNotificationNewItem: (state, { payload }) => {
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === payload.featureId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log("===error===resignation", e);
      }
    },

    readCountforResignation: (state, { payload }) => {
      const id = payload;
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id;
        }
      } catch (e) {
        console.log("===error===resignation", e);
      }
    },
    handleResignationReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const reportingToIndex = current[index].reportingTo.findIndex(item => item.id === approvalId)
            const finanecersIndex = current[index].finance.findIndex(item => item.id == approvalId)
            const itIndex = current[index].it.findIndex(item => item.id == approvalId)
            const exitIndex = current[index].exit.findIndex(item => item.id == approvalId)
            const adminIndex = current[index].admin.findIndex(item => item.id == approvalId)
            const hrIndex = current[index].hr.findIndex(item => item.id == approvalId)


            // const executorsIndex = current[index].executors.findIndex(item => item.id == approvalId)

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(reportingToIndex !==-1)
              {
                let reportingTo =[ ...referenceItem.reportingTo]
                let currentreportingTo = reportingTo[reportingToIndex]

                currentreportingTo = {
                  ...currentreportingTo,
                  reminderCount : currentreportingTo.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                reportingTo[reportingToIndex] = currentreportingTo
                
                referenceItem = {
                  ...referenceItem,
                  reportingTo : reportingTo
                }


              }
              else if(finanecersIndex !==-1)
              {
                let finance =[ ...referenceItem.finance]
                let currentfinancer = finance[finanecersIndex]

                currentfinancer = {
                  ...currentfinancer,
                  reminderCount : currentfinancer.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                finance[finanecersIndex] = currentfinancer
                
                referenceItem = {
                  ...referenceItem,
                  finance : finance
                }
              }
              
              else if(itIndex !==-1)
              {
                let it =[ ...referenceItem.it]
                let currentItApprover = it[itIndex]

                currentItApprover = {
                  ...currentItApprover,
                  reminderCount : currentItApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                it[itIndex] = currentItApprover
                
                referenceItem = {
                  ...referenceItem,
                  it : it
                }
              }

              else if(exitIndex !==-1)
              {
                let exit =[ ...referenceItem.exit]
                let currentexitApprover = exit[exitIndex]

                currentexitApprover = {
                  ...currentexitApprover,
                  reminderCount : currentexitApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                exit[exitIndex] = currentexitApprover
                
                referenceItem = {
                  ...referenceItem,
                  exit : exit
                }
              }
              else if(adminIndex !==-1)
              {
                let admin =[ ...referenceItem.admin]
                let currentadminIndexApprover = admin[adminIndex]

                currentadminIndexApprover = {
                  ...currentadminIndexApprover,
                  reminderCount : currentadminIndexApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                admin[adminIndex] = currentadminIndexApprover
                
                referenceItem = {
                  ...referenceItem,
                  admin : admin
                }
              }
              else if(hrIndex !==-1)
              {
                let hr =[ ...referenceItem.hr]
                let currenthrApprover = hr[hrIndex]

                currenthrApprover = {
                  ...currenthrApprover,
                  reminderCount : currenthrApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                hr[hrIndex] = currenthrApprover
                
                referenceItem = {
                  ...referenceItem,
                  hr : hr
                }
              }
              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllResignationAction.fulfilled, (state, action) => {
      const { segmentType, identifier, filter } = action?.meta?.arg;
      const data = action.payload ?? [];
      if (segmentType === SegmentTypeEnum.Grid) {
        state.data = {
          ...state.data,
          [identifier]: [...(state.data?.[identifier] || []), ...data],
        };
      } else {
        state.data = {
          ...state.data,
          [identifier]: data.data,
        };
      }
    });

    builder.addCase(GetResignationById.fulfilled, (state, action) => {
      const { identifier } = action?.meta?.arg;
      const data = action.payload.data ?? [];
      state.data = {
        ...state.data,
        [identifier]: [data],
      };
    });

    builder.addCase(addResignation.fulfilled, (state, { payload }) => {
      state.newItem = payload.data.data;
    });
  },
});

export const {
  handleResetState,
  removeIdentifier,
  handleResignationApprovalRemark,
  handleNewItem,
  handleAddResignationApprover,
  handleNotificationNewItem,
  readCountforResignation,
  handleResignationReminder
} = resignationSlice.actions;
export default resignationSlice.reducer;
