import React, { useEffect, useState, useContext } from "react";
import { Image, Tag } from "antd";
import moment from "moment";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { useDispatch, useSelector } from "react-redux";
import { getCareerLinkByIdAction } from "../../store/action";
import { useParams } from "react-router-dom";
import ApplyComposer from "../Page/Composer/applyComposer";
import { handleOpenApplyComposer } from "../../store/slice";
import "./style.css";
import {
  EducationLevelNameEnum,
} from "../../utils/educationTypeEnum";
import {
  JobShiftNameTypeEnum,
} from "../../utils/jobShiftTypeEnum";
import { JobTypeNameEnum } from "../../utils/jobTypeEnum";
import {
  CareerLevelNameEnum,
} from "../../utils/careerLevelTypeEnum";
import Spinner from "../../../../sharedComponents/spinner/spinner";
import { dictionaryList } from "../../../../../utils/localization/languages";
import career from "../../../../../content/svg/menu/newNavBarIcon/Career.svg";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";

const ApplyJob = (props) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, careers } = dictionaryList[userLanguage];

  const { id } = useParams();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [careerData, setCareerData] = useState({});
  const {
    items: careersdata,
    carrerDetailsByLink: linkcareer,
    applyComposer,
  } = useSelector((state) => state.careerSlice);
  const [loader, setLoader] = useState(true);
  let careerId = id || linkcareer?.id;
  useEffect(() => {
    //call career by id function
    if (id) {
      dispatch(getCareerLinkByIdAction(id)).then((res) => {
        setLoader(false);
      });
    }
  }, []);

  useEffect(() => {
    //works at start to set first element from in state array
    if (Object.keys(careersdata).length > 1) {
      setCareerData(careersdata[0]);
    }
  }, [careersdata]);

  const {
    city,
    country,
    createDate,
    description,
    designation,
    department,
    skills,
    creator,
    minSalary,
    maxSalary,
    experience,
    endDate,
    status,
    jobTypeId,
    jobShiftId,
    educationId,
    careerLevelId,
    businessName,
    businessLogo,
    image,
    // id
  } = linkcareer;

  const handleDrawerClose = () => {
    setVisible(false);
  };

  const handleDrawerOpen = () => {
    setVisible(true);
    dispatch(handleOpenApplyComposer(true));
  };

  const skillsArray = skills?.split(",");

  console.log(props);

  return (
    <div className="flex flex-col w-full items-center p-5 !pb-0">
      {applyComposer && (
        <ApplyComposer
          visible={visible}
          onClose={handleDrawerClose}
          id={careerId}
          isExternal={true}
        />
      )}

      <div
        id="job-let-card"
        className="job-let-card bg-white max-w-md rounded-lg shadow-lg border border-gray-300"
      >
        {/* {loader ? (
          <Spinner />
        ) : ( */}
        <>
          <div
            id="job-let-header"
            className="job-let-header border-b border-gray-300 "
          >
            <div
              id="job-let-company"
              className="job-let-company flex items-center"
            >
              <div className="company-logo">
                <img src={businessLogo} alt="company logo" />
              </div>
              <div
                id="company-name"
                className="company-name font-bold text-blue-500 ml-2"
              >
                {businessName}
              </div>
            </div>
            <div id="job-let-det" className="job-let-det mt-2">
              <div
                id="job-let-det-top"
                className="job-let-det-top flex items-center"
              >
                <div
                  id="job-let-title"
                  className="job-let-title font-bold text-blue-500"
                >
                  <div id="job-basics-label" className="job-basics-label font-bold text-black">
                    {sharedLabels.Designation}
                  </div>

                  {designation ? designation : "No Designation"}
                </div>

                <div
                  id="job-let-apply-btn"
                  className="job-let-apply-btn py-2 px-4 bg-blue-500 text-white rounded cursor-pointer"
                  onClick={handleDrawerOpen}
                >
                  {careers.applyNow}
                </div>
              </div>
              <div className="job-let-location">
                <div id="job-basics-label" className="job-basics-label font-bold text-black">
                  {sharedLabels.Department}
                </div>

                {department}
              </div>
              <div className="flex flex-row h-full">
                <div className="flex flex-col justify-evenly w-full">
                  <div id="job-basics" className="job-basics mt-2">
                    <div id="job-date" className="job-date">
                      <div
                        id="job-basics-label" className="job-basics-label font-bold text-black"
                      >
                        {careers.jobPost}
                      </div>
                      <div
                        id="job-basics-value"
                        className="job-basics-value font-bold text-blue-500"
                      >
                        {moment
                          .utc(createDate)
                          .local()
                          .fromNow()}
                      </div>
                    </div>
                    <div id="job-date" className="job-date">
                      <div
                        id="job-basics-label"
                        className="job-basics-label font-bold text-blue-500"
                      >
                        {careers.jobexpire}
                      </div>
                      <div
                        id="job-basics-value"
                        className="job-basics-value font-bold text-blue-500"
                      >
                        {endDate ? moment(endDate).format("Do MMM YY") : "-"}
                      </div>
                    </div>
                  </div>

                  <div className=" flex flex-row mt-5">
                    <div className="w-[50%]">
                      <div
                        id="job-basics-label"
                        className="job-basics-label font-bold"
                      >
                        {careers.skillrequired}
                      </div>
                      <div class="box-shadow">
                        {skills
                          ? skillsArray?.map((item, index) => {
                            return (
                              <Tag id="LinkTag" className="LinkTag">
                                {item}
                              </Tag>
                            );
                          })
                          : "No Requirements"}
                      </div>
                    </div>
                    <div className="w-[50%]">
                      <div
                        id="job-basics-label"
                        className="job-basics-label font-bold"
                      >
                        {sharedLabels.salaryRange}
                      </div>
                      <div
                        id="job-basics-value"
                        className="job-basics-value font-bold text-blue-500"
                      >
                        {`${formatNumberWithCommas(
                          minSalary
                        )}-${formatNumberWithCommas(maxSalary)}`}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-end">
                  <div className="h-fit w-[100px] ">
                    <Image
                      src={image ? image : career}
                      alt=""
                      rootClassName="antdImgPreviewWrapper"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="job-let-section" className="job-let-section">
            <div
              id="job-let-desc"
              className={`job-let-desc  ${!description ? "!hidden" : ""}`}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>

            <div
              id="job-let-line"
              className={`job-let-line w-px bg-gray-400  ${!description ? "hidden" : ""
                }`}
            ></div>

            <div id="job-let-esents" className="job-let-esents">
              <div id="esent-row" className="esent-row">
                <div
                  id="esent-label"
                  className="esent-label font-bold text-blue-500"
                >
                  {careers.joblocation}
                </div>
                <div
                  id="esent-value"
                  className="esent-value font-bold text-blue-500"
                >
                  {city}, {country}
                </div>
              </div>
              <div id="esent-row" className="esent-row">
                <div
                  id="esent-label"
                  className="esent-label font-bold text-blue-500"
                >
                  {careers.jobType}
                </div>
                <div
                  id="esent-value"
                  className="esent-value font-bold text-blue-500"
                >
                  {JobTypeNameEnum[jobTypeId]}
                </div>
              </div>
              <div id="esent-row" className="esent-row">
                <div
                  id="esent-label"
                  className="esent-label font-bold text-blue-500"
                >
                  {careers.jobShift}
                </div>
                <div
                  id="esent-value"
                  className="esent-value font-bold text-blue-500"
                >
                  {JobShiftNameTypeEnum[jobShiftId]}
                </div>
              </div>
              <div id="esent-row" className="esent-row">
                <div
                  id="esent-label"
                  className="esent-label font-bold text-blue-500"
                >
                  {sharedLabels.education}
                </div>
                <div
                  id="esent-value"
                  className="esent-value font-bold text-blue-500"
                >
                  {EducationLevelNameEnum[educationId]}
                </div>
              </div>
              <div id="esent-row" className="esent-row">
                <div
                  id="esent-label"
                  className="esent-label font-bold text-blue-500"
                >
                  {careers.careerLevel}
                </div>
                <div
                  id="esent-value"
                  className="esent-value font-bold text-blue-500"
                >
                  {CareerLevelNameEnum[careerLevelId]}
                </div>
              </div>
              <div id="esent-row" className="esent-row">
                <div
                  id="esent-label"
                  className="esent-label font-bold text-blue-500"
                >
                  {sharedLabels.Experience}
                </div>
                <div
                  id="esent-value"
                  className="esent-value font-bold text-blue-500"
                >
                  {" "}
                  {experience ? experience : "-"}
                </div>
              </div>
            </div>
          </div>
        </>
        {/* )} */}
      </div>
      {props.isShowFooter && (
        <div id="apply-Job-footer" className="text-center text-gray-500 mt-4">
          &copy; {sharedLabels.poweredbyWorkw}
          {new Date().getFullYear()}
        </div>
      )}
    </div>
  );
};
export default ApplyJob;
