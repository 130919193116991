import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  addNewTaskService,
  getAllTaskService,
  getTaskByIdService,
  cancelTaskService,
  getAllTaskPagingService,
  UpdateUserTaskTimeService,
  AddUserTaskMembersService,
} from "../services/service";
import { cancelTaskSuccess } from "./taskSlice";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const addNewTask = createAsyncThunk(
  "task/addNewTask",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addNewTaskService(data);
    if (res?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Task Created Successfully",
          type: "success",
          duration: 2,
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      throw new Error(res.message);
    }
  }
);
export const getAllTask = createAsyncThunk(
  "task/getAllTask",
  async (request, { rejectWithValue }) => {
    const response = await getAllTaskService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const getAllTaskPaging = createAsyncThunk(
  "task/getAllTaskPaging",
  async (request, { rejectWithValue }) => {
    const response = await getAllTaskPagingService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllTasksAction = createAsyncThunk(
  "task/getAllTaskAction",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllTaskPagingService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    } else {
      const response = await getAllTaskService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  }
);

export const getTaskById = createAsyncThunk(
  "task/getTaskById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const response = await getTaskByIdService(data?.id || data);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const cancelTaskAction = createAsyncThunk(
  "task/cancelTaskAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await cancelTaskService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: response.message,
            type: "error",
            duration: 2,
          })
        );
        dispatch(cancelTaskSuccess({ taskId: id }));
        return response.data;
      default:
        return;
    }
  }
);

export const UpdateUserTaskTimeAction = createAsyncThunk(
  "task/UpdateUserTaskTimeAction",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await UpdateUserTaskTimeService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Date Updated",
            type: "success",
            duration: 2,
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const AddUserTaskMembersAction = createAsyncThunk(
  "task/AddUserTaskMembers",
  async (data,{ dispatch, getState, rejectWithValue }) => {
    const {member, id} = data
    const res = await AddUserTaskMembersService(member, id);
    if (res?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Member Added",
          type: "success",
          duration: 2,
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      throw new Error(res.message);
    }
  }
);
