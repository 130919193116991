import { List } from "antd";
import MemberListItem from "./listItem";
function DefaultMemberList({
  data = [],
  onDelete = () => {},
  isDelete = false,
}) {
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="max-h-[300px] overflow-y-auto"
        renderItem={(item) => {
          console.log(item, "itemmmm");
          return (
            <MemberListItem
              member={item}
              onDelete={onDelete}
              isDelete={isDelete}
            />
          );
        }}
      />
    </>
  );
}
export default DefaultMemberList;
