export const ResignationPurposeEnum = {
  InsufficientWorkLifeBalance: 1,
  TimeOffAndFlexibility: 2,
  UnrealisticGoalsAndPerformanceObjectives: 3,
  LackOfAClearPathForCareerAdvancement: 4,
  FeelUnsupportedByManage: 5,
  DontFeelChallenged: 6,
  Relocation: 7,
  Others: 8,
};
