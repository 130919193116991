import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import { addRoom, getAllRoom, removeRoom, updateRoom } from "./actions.js";

const initialState = {
  rooms: [],
  loadingData: false,
  loader: false,
};

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    roomDeleted: (state, { payload }) => {
      state.rooms = state.rooms.filter((e) => e.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoom.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.rooms = payload.data;
      })
      .addCase(addRoom.fulfilled, (state, { payload }) => {
        console.log(payload,"payload");
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.rooms.push(payload.data);

          console.log(state.rooms,"rooms");
      })
      .addCase(updateRoom.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.rooms = state.rooms.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })
      .addMatcher(isPending(...[addRoom, updateRoom]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getAllRoom]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(
        isRejected(...[getAllRoom, addRoom, updateRoom]),
        (state) => {
          state.loader = false;
          state.loadingData = false;
        }
      );
  },
});

export const { roomDeleted } = roomSlice.actions;
export default roomSlice.reducer;
