import { Skeleton } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { NoDataFound } from "../../../../sharedComponents/NoDataIcon";
import ListItem from "./ListItem";

function ListView({ identifier }) {
  const { data } = useSelector((state) => state.careerSlice);
  const career = data !== null ? data[identifier] : [];
  return (
    <div className="">
      <div className="listView pt-3">
        {(
          <>
            {career?.length > 0 ? (
              career?.map((data, index) => {
                return (<ListItem id={data.id}
                  key={index} item={data} />
                )
              })
            ) : (
              <div className="p-20">
                <NoDataFound />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ListView;
