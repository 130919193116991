import { createSlice, current } from "@reduxjs/toolkit";
import {BusinessNumberAuthAction , addContactNumberAction, getAllBusinessNumbeerAction } from "./action";
const dialerSlice = createSlice({
  name: "dialerSlice",
  initialState: {
    isDialeropen: false,
    dialerData: null,
    clients : null,
    currentClient: null,
    currentCallId: null,
    contactListData : [],
    selectedNumberData: null,
  },
  reducers: {
    toggleDialer: (state) => {
      state.isDialeropen = !state.isDialeropen;
    },
    setClient: (state, { payload }) => {
      let { client, token } = payload;
      state.clients = { ...state.clients, [token]: client };

      if(state.selectedNumberData !== null){
        state.currentClient = state.clients[state.selectedNumberData?.token]
      }
    },
    setCallId: (state, { payload }) => {
      state.currentCallId = payload;
    },
    setSelectedNumber: (state, { payload }) => {
      state.selectedNumberData = payload;
      state.currentClient = state.clients[state.selectedNumberData?.token]
    },
  },
  extraReducers: (builder) => {
    builder
    // .addCase(requestForACall.fulfilled, (state, { payload }) => {
    //   state.dialerData = payload;
    // })
    .addCase(addContactNumberAction.fulfilled,(state, { payload }) => {
      console.log(payload,"===add===")
      const newData = [
        payload?.data,
        ...state.contactListData
      ]
      state.contactListData =newData
    })
    .addCase(getAllBusinessNumbeerAction.fulfilled,(state ,{payload} )=>{
        if(payload)
          {
            state.contactListData = payload
          }
    })
    builder.addCase(
      BusinessNumberAuthAction.fulfilled,
      (state, { payload }) => {
        state.dialerData = payload; 
        if (payload?.status) {
          state.selectedNumberData = payload.numbers?.find(item => item?.token !== "") ?? null;
        }
      }
    );
  },
});
export const {
  toggleDialer,
  setClient,
  setCallId,
  setSelectedNumber,
} = dialerSlice.actions;
export default dialerSlice.reducer;
