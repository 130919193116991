import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Row,
  Col,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getNameForImage } from "../../../../../../utils/base";
import CitySelect from "../../../../../sharedComponents/AntdCustomSelects/SharedSelects/CitySelect";
import { travelCategoryData } from "../../../utils/TravelCategories";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { TravelOptionsEnums } from "../../../utils/travelOptionsEnum";
import { ShiftEnum } from "../../../utils/travelShiftEnum";
import { dictionaryList } from "../../../../../../utils/localization/languages";

function CreateTravelDetail(props) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { travel, sharedLabels } = dictionaryList[userLanguage];
  const { Shift, travelBy } = travel;

  const {
    fetchCityData,
    onSelectCity,
    onTravelDetailAdd,
    traveldetail,
  } = props;
  const cities = useSelector((state) => state.sharedSlice.cities);
  const [form] = Form.useForm();
  const [isReturn, setIsReturn] = useState(false);
  const [departureDate, setDepartureDate] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [cityData, setCityData] = useState(cities);

  const travelDetailFormSubmit = async () => {
    form.submit();
    try {
      const travelDetailVal = await form.validateFields();
      onTravelDetailAdd(travelDetailVal);
      setIsReturn(false);
      form.resetFields();
    } catch (error) {}
  };

  const initialValues = {
    departureDate: moment(new Date()),
    departureShift: ShiftEnum.Morning,
    returnDate: moment().add(1, "day"),
    returnShift: ShiftEnum.Morning,
    isHotelRequired: false,
    isTADARequired: false,
    isCarRequired: false,
    isPickupRequired: false,
    isDropOffRequired: false,
    return: false,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setDepartureDate(initialValues.departureDate);
  }, []);

  const handleDepartureDateChange = (date) => {
    setDepartureDate(date);
    form.setFieldsValue({ returnDate: null });
  };

  const disabledReturnDates = (currentDate) => {
    return (
      currentDate &&
      (currentDate < moment(departureDate).startOf("day") ||
        currentDate.isSame(departureDate, "day"))
    );
  };

  useEffect(() => {
    if (traveldetail && Object.keys(traveldetail).length > 0) {
      let cityData = traveldetail?.cities;
      setIsUpdate(true);
      if (cityData.length > 1) {
        setIsReturn(true);
        form.setFieldsValue({
          returnDate: moment(cityData?.[1]?.departureDate),
          returnShift: cityData?.[1]?.shiftType,
          return: true,
        });
      }
      form.setFieldsValue({
        ...cityData?.[0],
        departureDate: moment(cityData?.[0]?.departureDate),
        departureShift: cityData?.[0]?.shiftType,
      });
    }
  }, [traveldetail]);

  useEffect(() => {
    let uniqueCities = [];
    if (traveldetail) {
      let traveldataCity = traveldetail?.cities?.[0];
      let citiesdata = [
        ...cities,
        {
          country: traveldataCity?.departureCountry,
          id: traveldataCity?.departureId,
          name: traveldataCity?.departure,
        },
        {
          country: traveldataCity?.arrivalCountry,
          id: traveldataCity?.arrivalId,
          name: traveldataCity?.arrival,
        },
      ];

      uniqueCities = Array.from(
        new Set(citiesdata.map((city) => city.id))
      ).map((id) => citiesdata.find((city) => city.id === id));
    } else {
      uniqueCities = cities;
    }
    setCityData(uniqueCities);
  }, [cities, traveldetail]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="travelDetailForm"
        initialValues={initialValues}
        className=""
      >
        <div className="!bg-neutral-100 rounded !p-3">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter valid reason.",
              },
            ]}
            label={sharedLabels.reason}
            name="reason"
          >
            <Input placeholder={sharedLabels.TravelReason} size="middle" />
          </Form.Item>
          <Form.Item label={travel.TravelToandFrom} required>
            <div className="flex gap-4 flex-col sm:flex-row">
              <div className="flex flex-col w-full">
                <CitySelect
                  key={isUpdate}
                  data={cityData}
                  selectedData={(val, obj) => {
                    onSelectCity("departure", obj);
                  }}
                  canFetchNow={cities && cities.length > 0}
                  fetchData={fetchCityData}
                  optionComponent={(opt) => {
                    return (
                      <>
                        <Avatar src={opt.image} className="!bg-black">
                          {getNameForImage(opt.name)}
                        </Avatar>
                        {opt.name + " - " + opt.country}
                      </>
                    );
                  }}
                  defaultKey={"id"}
                  isObject={true}
                  mode={""}
                  placeholder={travel.SearchDepartureCity}
                  size="middle"
                  name="departureId"
                  rules={[
                    {
                      required: true,
                      message: travel.PleaseSelectDepartureCity,
                    },
                  ]}
                  
                />
              </div>
              <div className="flex flex-col w-full">
                <CitySelect
                  key={isUpdate}
                  data={cityData}
                  selectedData={(val, obj) => {
                    onSelectCity("arrival", obj);
                  }}
                  canFetchNow={cities && cities.length > 0}
                  fetchData={fetchCityData}
                  optionComponent={(opt) => {
                    return (
                      <>
                        <Avatar src={opt.image} className="!bg-black">
                          {getNameForImage(opt.name)}
                        </Avatar>
                        {opt.name + " - " + opt.country}
                      </>
                    );
                  }}
                  defaultKey={"id"}
                  isObject={true}
                  mode={""}
                  placeholder={travel.SearchDestinationCity}
                  size="middle"
                  name="arrivalId"
                  rules={[
                    {
                      required: true,
                      message: travel.PleaseSelectDestinationCity,
                    },
                  ]}
                 
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            className="custom-form-item"
            label={sharedLabels.Date}
            required
          >
            <div className="flex w-full gap-3">
              <Form.Item
                className="custom-form-item"
                name="departureDate"
                rules={[
                  {
                    required: true,
                    message: "Departure date is required",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder={sharedLabels.SelectDate}
                  size="middle"
                  onChange={handleDepartureDateChange}
                />
              </Form.Item>
              <Form.Item
                className="custom-form-item"
                name="departureShift"
                defaultValue={ShiftEnum.Morning}
                rules={[
                  {
                    required: true,
                    message: travel.DepartureTimeIsRequired,
                  },
                ]}
              >
                <Select defaultValue={ShiftEnum.Morning}>
                  {Object.entries(Shift).map(([id, label]) => (
                    <Select.Option
                      key={id}
                      value={parseInt(id, 10)}
                      label={label}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="custom-form-item"
                valuePropName="checked"
                name="return"
              >
                <Checkbox
                  onChange={(e) => {
                    setIsReturn(e.target.checked);
                  }}
                >
                  {sharedLabels.Return}
                </Checkbox>
              </Form.Item>
            </div>
          </Form.Item>
          {isReturn && (
            <Form.Item
              label={`${sharedLabels.Return} ${sharedLabels.Date}`}
              required
            >
              <div className="flex w-full gap-3">
                <Form.Item
                  name="returnDate"
                  rules={[
                    {
                      required: true,
                      message: travel.ReturnDateIsRequired,
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    size="middle"
                    placeholder={sharedLabels.SelectDate}
                    disabledDate={disabledReturnDates}
                  />
                </Form.Item>
                <Form.Item
                  name="returnShift"
                  defaultValue={ShiftEnum.Morning}
                  rules={[
                    {
                      required: true,
                      message: travel.RetunTimeIsRequired,
                    },
                  ]}
                >
                  <Select defaultValue={ShiftEnum.Morning}>
                    {Object.entries(Shift).map(([id, label]) => (
                      <Select.Option
                        key={id}
                        value={parseInt(id, 10)}
                        label={label}
                      >
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>
          )}
          <Form.Item label={travel.HotelTADACar} className="travelBox">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="isHotelRequired" valuePropName="checked">
                  <Checkbox>{travel.HotelRequired}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="isTADARequired" valuePropName="checked">
                  <Checkbox>{travel.TADAApplicable}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="isCarRequired" valuePropName="checked">
                  <Checkbox>{travel.RentaCar}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="isPickupRequired" valuePropName="checked">
                  <Checkbox>{travel.PickUpRequired}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="isDropOffRequired" valuePropName="checked">
                  <Checkbox>{travel.DropOffRequired}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <div className="flex flex-wrap justify-between items-center">
            <Form.Item
              label={travel.TravelBy}
              name="travelById"
              initialValue={TravelOptionsEnums.ByPlane}
              rules={[
                {
                  required: true,
                  message: travel.PleaseSelectTravelType,
                },
              ]}
            >
              <Radio.Group
                defaultValue={TravelOptionsEnums.ByPlane}
                className="travelCategory !flex gap-5"
              >
                {travelCategoryData(travelBy).map((travel) => (
                  <Radio.Button value={travel.id}>
                    {travel.image}
                    {travel.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
            <Button onClick={travelDetailFormSubmit} className="ThemeBtn">
              {sharedLabels.add}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default CreateTravelDetail;
