import React, { useContext, useEffect, useState } from "react";
import {
  TeamOutlined,
  PieChartOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { STRINGS } from "../../../../../../utils/base";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { Form, Radio } from "antd";
import SuggestionBox from "../../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionTypeEnum } from "../../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { TaskReferenceTypeEnum as referenceType} from "../../../utils/TaskReferenceTypeEnum";
const defaultReferenceState = {
  id: STRINGS.DEFAULTS.guid,
};
function TaskReferenceTypeComposer({ onChangeCallback = (p) => {} }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const { taskReferenceTypeEnum } = tasks;
  const [state, setState] = useState({
    referenceType: referenceType.General,
    reference: defaultReferenceState,
  });

  useEffect(() => {
    onChangeCallback({
      referenceId: state.reference.id,
      referenceType: state.referenceType,
    });
  }, [state]);

  return (
    <>
      <Form.Item
        label={sharedLabels.type}
        name="referenceType"
        rules={[
          {
            required: true,
            message: sharedLabels.Pleaseselecttype,
          },
        ]}
      >
        <Radio.Group
          defaultValue={referenceType.General}
          className=" taskTypeRadio radioPrimary"
          onChange={(event) => {
            setState({
              ...state,
              referenceType: event.target.value,
              reference: defaultReferenceState,
            });
          }}
        >
          <Radio.Button value={referenceType.General}>
            <CalendarOutlined />
            {taskReferenceTypeEnum[referenceType.General]}
          </Radio.Button>
          <Radio.Button value={referenceType.Project}>
            <PieChartOutlined />
            {taskReferenceTypeEnum[referenceType.Project]}
          </Radio.Button>
          <Radio.Button value={referenceType.Group}>
            <TeamOutlined />
            {taskReferenceTypeEnum[referenceType.Group]}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      {state.referenceType !== referenceType.General && (
        <Form.Item
          label={
            state.referenceType === referenceType.Project
              ? sharedLabels.Project
              : sharedLabels.Group
          }
          name="reference"
        >
          <SuggestionBox
            suggestionType={
              state.referenceType === referenceType.Project
                ? [SuggestionTypeEnum.Project]
                : [SuggestionTypeEnum.Group]
            }
            placeholder={
              state.referenceType === referenceType.Project
                ? sharedLabels.SelectProject
                : sharedLabels.SelectGroup
            }
            isMultiSelect={false}
            removeSelf={true}
            initialData={
              state.reference.id === STRINGS.DEFAULTS.guid
                ? []
                : [state.reference]
            }
            callback={(data) => {
              setState({
                ...state,
                reference: data.length > 0 ? data[0] : defaultReferenceState,
              });
            }}
          />
        </Form.Item>
      )}
    </>
  );
}

export default TaskReferenceTypeComposer;
