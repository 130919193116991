import MasterConfig from "../../../../utils/services/MasterConfig";

export const getAllPageingCategoryService = () => {
    return MasterConfig.get(`api/PageCategory/GetAllPageCategory`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};

export const addPagingCategoryService = args => {
    return MasterConfig.post(`api/PageCategory/AddPageCategory`, args)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
export const updatePagingCategoryService = args => {
    return MasterConfig.put(`api/PageCategory/UpdatePageCategory`, args)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
export const removePagingCategoryService = id => {
    return MasterConfig.delete(`api/PageCategory/RemovePageCategory?id=${id}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
