import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  current,
} from "@reduxjs/toolkit";
import {
  onFeedCreateSubmitAction,
  onPostTitleTextChange,
  onPostMention,
  onPostTagsChange,
  addPostAttachment,
  removePostAttachment,
  onPostTypeChange,
  onPostPollOptionTextChange,
  onPostPollAttachmentChange,
  addPostPollOption,
  removePostPollOption,
  onPostPrivacyChange,
  toggleComposerVisibility,
  onSaveComment,
  getAllFeed,
  getAllUser,
  getFeedById,
  clearSinglePost,
  savePollResponse,
  sharePostOnFeed,
  resetComposeFeed,
  getAllReactionsAction,
} from "./actions";
import { PollType, PostPrivacyType, PostType } from "../utils/constants";
import { STRINGS } from "../../../../utils/base";
import { ReactionTypeEnum } from "../../reaction/utils/enums/reactionTypeEnum";
import { NotificationTypesEnum } from "../../notifiation/enums";

const composeInitialState = {
  clicked: false,
  reactionMembersData: [],
  showComposer: false,
  loading: false,
  privacyType: PostPrivacyType.PUBLIC,
  type: PostType.DEFAULT,
  title: "",
  pollTitle: "",
  mentions: [],
  tags: [],
  attachments: [],
  poll: {
    options: [
      { type: PollType.DEFAULT, value: "", attachment: null },
      { type: PollType.DEFAULT, value: "", attachment: null },
    ],
  },
};
const allFeedInitialState = {
  posts: [],
  postIds: [],
  loading: false,
  
};

export const feedSlice = createSlice({
  name: "feedSlice",
  initialState: {
    reactionMembersData: [],
    loading: false,
    allFeed: { ...allFeedInitialState },
    singlePost: {},
    tagsOptions: [],
    mentionsOptions: [],
    postCompose: { ...composeInitialState },
    feedData : null, // used with Identifier
    newItem : null,
    shouldScrollToTop: false,
  },
  reducers: {
    onPostTitleTextChange,
    onPostMention,
    onPostTagsChange,
    resetComposeFeed,
    addPostAttachment,
    removePostAttachment,
    onPostTypeChange,
    onPostPollOptionTextChange,
    onPostPollAttachmentChange,
    addPostPollOption,
    removePostPollOption,
    onPostPrivacyChange,
    toggleComposerVisibility,
    onSaveComment,
    clearSinglePost,
    addFeedFavourite(state, { payload }) {
      const currentState = current(state)

      if(currentState.feed!==null)
      {
        const keys = Object.keys(currentState.feedData)
        let updatedState = { ...currentState.feedData };
        keys.forEach((identifier)=>{
          const current = currentState.feedData[identifier];
          const index = current.findIndex((y) => y.id === payload.id);
          if(index!==-1)
          {
            const updateCurrent = [...current]
            let referenceItem = updateCurrent[index]
            referenceItem = {
              ...referenceItem,
              isPinned : !referenceItem.isPinned

            }

            updateCurrent[index] = referenceItem
                          
            updatedState = {
              ...updatedState,
              [identifier] : [...updateCurrent]
            }
            
            
            
          }
        })
        state.feedData = updatedState
      }
    },
    readCounterFeed(state, {payload}) {

      const { postid , count , type = NotificationTypesEnum.NewFeed} = payload
      
      const currentState = current(state)
      const keys = Object.keys(currentState.feedData)
      let updatedState = { ...currentState.feedData };
      keys.forEach((identifier)=>{
        const current = currentState.feedData[identifier];
        const index = current.findIndex((y) => y.id === postid);
        if(index!==-1)
        {
          const updateCurrent = [...current]
          let referenceItem = updateCurrent[index]
          if(type === NotificationTypesEnum.FeedComment || type === NotificationTypesEnum.CommentReply)
          {
            referenceItem = {
              ...referenceItem,
              notificationCount : count,
              commentNotificationCount : 0
            }  
          }
          else
          {
            referenceItem = {
              ...referenceItem,
              notificationCount : count
  
            }
          }

          updateCurrent[index] = referenceItem
                        
          updatedState = {
            ...updatedState,
            [identifier] : [...updateCurrent]
          }
          
          
          
        }
      })
      state.feedData = updatedState

    },
    //for Identifier
    addNewFeed(state,{payload}){

      const {identifier, data} = payload


      if(typeof state.feedData !== "undefined")
        {
          const index = state.feedData[identifier]?.findIndex(it => it.id === data.id)
            if(index === -1)
            {
              state.feedData[identifier] = [data , ...state.feedData[identifier]]
            }
        }  

        state.newItem = null
    },
    addFeedReaction(state, { payload }) {
      const {reactionType ,referenceId  } = payload
      const currentState = current(state)
      if(currentState.feedData!==null)
      {
        const keys = Object.keys(currentState.feedData)
        let updatedState = {...currentState.feedData}

        keys.forEach((identifier)=>{
          const current = currentState.feedData[identifier]
          const index = current.findIndex((x)=> x.id === referenceId)
          if(index!==-1)
          {
            const updateCurrent = [...current]
            let count = updateCurrent[index].reactionCount
            let existingReactionType = updateCurrent[index].myReaction
            if(existingReactionType === ReactionTypeEnum.NoReaction && reactionType !==ReactionTypeEnum.NoReaction)
            {
                count  = count + 1;
            }
            else if (existingReactionType !== ReactionTypeEnum.NoReaction  && reactionType===ReactionTypeEnum.NoReaction)
            {
                count = count - 1;
            }

            let referenceItem = updateCurrent[index]
             
            
            referenceItem = {
              ...referenceItem,
              reactionCount:count,
              myReaction:reactionType
            }
            
            updateCurrent[index] = referenceItem
                      
            updatedState = {
              ...updatedState,
              [identifier] : [...updateCurrent]
            }
          }
          
        })

        state.feedData = updatedState
      }
    },
    commentCountatFeed(state, {payload}){

      try{
        const {comment } = payload
        const {parentId , referenceId} = comment
        const currentState = current(state)
        if(parentId === STRINGS.DEFAULTS.guid)
        {
            if(currentState.feedData !== null)
            {
              const keys = Object.keys(currentState.feedData)
              let updatedState = { ...currentState.feedData };
              keys.forEach((identifier)=>{
                  const current = currentState.feedData[identifier];
                  const index = current.findIndex((y) => y.id === referenceId);
                  if(index!==-1)
                  {
                      const updateCurrent = [...current]
                      let referenceItem = updateCurrent[index]
                      const count = referenceItem.commentCount + 1
                      referenceItem = {
                        ...referenceItem,
                        commentCount:count
                      }

                      updateCurrent[index] = referenceItem
                      
                      updatedState = {
                        ...updatedState,
                        [identifier] : [...updateCurrent]
                      }
                     
                  }
                })
                state.feedData = updatedState


            }
        } 
      }
      catch(e)
      {
        console.log(e,"error===")
      }
    },
    feedReactionCount(state,{payload}){
    },
    addCommentsReaction(state, { payload }) {

      const {referenceId, id, reactionType, isDetail } = payload;
      if (!isDetail) {
        const postIndex = state.allFeed.posts.findIndex(
          (post) => post.id === referenceId
        );
        const feedCommentIndex = state.allFeed.posts[
          postIndex
        ]?.comments.findIndex((comment) => comment.id === id);
        let feedComment =
          state.allFeed.posts[postIndex]?.comments[feedCommentIndex];

        if (feedComment.myReaction === reactionType) {
          // User clicked on the same reaction as before
          feedComment.myReaction = 0;
          feedComment.reactionCount -= 1;
        } else {
          // User clicked on a different reaction than before
          if (feedComment.myReaction !== 0) {
            feedComment.reactionCount -= 1;
          }
          feedComment.myReaction = reactionType;
          feedComment.reactionCount += 1;
        }
      }
    },
    addReplyReaction(state, { payload }) {
      const {
        referenceId,
        parentId,
        id,
        reactionType,
        isDetail,
      } = payload;
      if (!isDetail) {
        const postIndex = state.allFeed.posts.findIndex(
          (post) => post.id === referenceId
        );
        const feedCommentIndex = state.allFeed.posts[
          postIndex
        ]?.comments.findIndex((comment) => comment.id === parentId);
        let feedComment =
          state.allFeed.posts[postIndex]?.comments[feedCommentIndex];

        const replyIndex = feedComment.replies.findIndex(
          (reply) => reply.id === id
        );
        let reply = feedComment.replies[replyIndex];

        if (reply.myReaction === reactionType) {
          // User clicked on the same reaction as before
          reply.myReaction = 0;
          reply.reactionCount -= 1;
        } else {
          // User clicked on a different reaction than before
          if (reply.myReaction !== 0) {
            reply.reactionCount -= 1;
          }
          reply.myReaction = reactionType;
          reply.reactionCount += 1;
        }
      }
    },
    handleImportant(state, { payload }) {
      state.clicked = payload
    },

    postPoll(state, { payload }) {
      const { id, postId } = payload;
      const currentState = current(state)

      if(currentState.feed!==null)
      {
        const keys = Object.keys(currentState.feedData)
        let updatedState = { ...currentState.feedData };
        keys.forEach((identifier)=>{
          const current = currentState.feedData[identifier];
          const index = current.findIndex((y) => y.id === postId);
          if(index!==-1)
          {
            const updateCurrent = [...current]
            let referenceItem = updateCurrent[index]
            let pollOption = referenceItem.pollOptions
            let youVoted = false;
            for (const poll of pollOption) {
              if (poll.youVoted) {
                youVoted = true;
                break;
              }
            }
            const updatedPollOption = [...pollOption]
            if(!youVoted)
            {
              const voteindex = pollOption.findIndex((poll) => poll.id === id);
                // Clone the pollOption array and the object you want to modify
               ;
                const updatedPollItem = { ...updatedPollOption[voteindex] };

                // Update the cloned object
                updatedPollItem.voteCount = updatedPollItem.voteCount + 1;
                updatedPollItem.youVoted = true;

                // Update the cloned array
                updatedPollOption[voteindex] = updatedPollItem;

            }

            referenceItem = {
              ...referenceItem,
              pollOptions : updatedPollOption

            }
            updateCurrent[index] = referenceItem
                      
            updatedState = {
              ...updatedState,
              [identifier] : [...updateCurrent]
            }
            
          }
        })
        state.feedData = updatedState
      }
    },
    addRealTimePost(state, { payload }) {
      let filteredFeeds = state.allFeed.posts.filter(
        (it) => it.id === payload.id
      );
      if (filteredFeeds.length === 0) state.allFeed.posts.unshift(payload);
    },
    handleNotificationNewItem(state,{payload}){
      try{
     
        const {type} = payload
        const currentState = current(state)
       
        if(currentState.feedData !== null)
        {
          const keys = Object.keys(currentState.feedData)
          let updatedState = { ...currentState.feedData };
          keys.forEach((identifier)=>{
              const current = currentState.feedData[identifier];
              const index = current.findIndex((y) => y.id === payload.featureId) 
            
              if(index!==-1)
              {
                  const updateCurrent = [...current]
                  let referenceItem = updateCurrent[index]
                  if(type === NotificationTypesEnum.FeedComment || type === NotificationTypesEnum.CommentReply)
                  {
                    referenceItem = {
                      ...referenceItem,
                      notificationCount:referenceItem.notificationCount+1,
                      commentNotificationCount:referenceItem.commentNotificationCount+1
                    }
                  }
                  else
                  {
                    referenceItem = {
                      ...referenceItem,
                      notificationCount:referenceItem.notificationCount+1
                    }
                  }
                  

                  updateCurrent[index] = referenceItem
                  
                  updatedState = {
                    ...updatedState,
                    [identifier] : [...updateCurrent]
                  }
                  
              }
            })
            state.feedData = updatedState


        }
        
      }
      catch(e)
      {
        console.log(e,"error===")
      }
    },
    newFeedPollVoteOut(state,{payload}){
       const {referenceId ,id } = payload
       const currentState = current(state)

       if(currentState.feedData!==null)
       {
          const keys = Object.keys(currentState.feedData)
          let updatedState = {};
          keys.forEach((identifier)=>{
            const current = currentState.feedData[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if(index!==-1)
            {
              const updateCurrent = [...current]
              let referenceItem = updateCurrent[index]
              let pollOption = referenceItem.pollOptions
              const voteindex = pollOption.findIndex((poll) => poll.id === id);
             
                const updatedPollOption = [...pollOption]
                const updatedPollItem = { ...updatedPollOption[voteindex]};
                updatedPollItem.voteCount = updatedPollItem.voteCount + 1;
                updatedPollOption[voteindex] = updatedPollItem;
                referenceItem = {
                  ...referenceItem,
                  pollOptions : updatedPollOption
    
                }
                updateCurrent[index] = referenceItem
                          
                updatedState = {
                  ...updatedState,
                  [identifier] : updateCurrent
                }
                state.feedData = updatedState

              

            }

          })
        
        }
    },
    resetComposerFeed(state,actions){
      state.postCompose = composeInitialState
    },
    setShouldScrollToTop: (state, action) => {
      state.shouldScrollToTop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      onFeedCreateSubmitAction.fulfilled,
      (state, { payload }) => {
        state.postCompose = composeInitialState;
        state.newItem = payload
      }
    );
    builder.addCase(sharePostOnFeed.fulfilled, (state, { payload }) => {
      state.postCompose = composeInitialState;
      state.newItem = payload
    });
    builder.addCase(onFeedCreateSubmitAction.pending, (state, _) => {
      state.postCompose.loading = true;
    });
    builder.addCase(onFeedCreateSubmitAction.rejected, (state, _) => {
      state.postCompose.loading = false;
    });
    builder.addCase(getAllReactionsAction.fulfilled, (state, { payload }) => {
      state.postCompose.reactionMembersData = payload;
    });
    builder
      .addMatcher(isFulfilled(...[getAllFeed]), (state, actions) => {

          const {identifier} =  actions.meta.arg;
          const {data , pageNo} = actions.payload

          if(pageNo === 1)
          {
            state.feedData =  {
              ...state.feedData ,
              [identifier] : data
            }
          }
          else
          {
            state.feedData = {
              ...state.feedData,
              [identifier]: [
                ...state.feedData[identifier] , // If identifier doesn't exist, initialize as an empty array
                ...data
              ]
            };
          }

        })
      .addMatcher(isPending(...[getAllFeed]), (state) => {
        state.allFeed.loading = true;
      })
      .addMatcher(isRejected(...[getAllFeed]), (state) => {
        state.allFeed.loading = true;
      });
    builder
      .addMatcher(isFulfilled(...[getAllUser]), (state, { payload }) => {
        state.tagsOptions = payload;
        state.mentionsOptions = payload;
        state.loading = false;
      })
      .addMatcher(isPending(...[getAllUser]), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejected(...[getAllUser]), (state) => {
        state.loading = true;
      });
    builder
      .addMatcher(isFulfilled(...[getFeedById]), (state, actions) => {
        const {identifier} = actions?.meta?.arg;
        const data = actions.payload ?? []

        state.feedData = {
          ...state.feedData,
          [identifier] : [data]
        }
      })
      .addMatcher(isPending(...[getFeedById]), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejected(...[getFeedById]), (state) => {
        state.loading = true;
      });
    builder
      .addMatcher(isPending(...[savePollResponse]), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejected(...[savePollResponse]), (state) => {
        state.loading = true;
      });
  },
});
export const {
  addFeedFavourite,
  addFeedReaction,
  postPoll,
  addRealTimePost,
  addCommentsReaction,
  addReplyReaction,
  handleImportant,
  addNewFeed,
  commentCountatFeed,
  newFeedPollVoteOut,
  readCounterFeed,
  handleNotificationNewItem,
  feedReactionCount,
  resetComposerFeed,
  setShouldScrollToTop
} = feedSlice.actions;
export default feedSlice.reducer;
