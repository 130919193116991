import { Modal } from "antd";
import { DOCUMENT_ENUM } from "../../constant";
import { STRINGS } from "../../../../../utils/base";
import DocumentMemberWrapper from "./documentMemberWrapper";

const DocumentMemberModal = ({
  documentType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
  referenceId = STRINGS.DEFAULTS.guid,
  visible = false,
  setVisible = () => {},
  memberRightType = DOCUMENT_ENUM.MEMBER_RIGHT_TYPE.COLLABRATOR,
}) => {
  return (
    <>
      {visible && (
        <Modal
          title={null}
          centered
          open={visible}
          onCancel={() => setVisible(false)}
          width={500}
          footer={null}
          closeIcon={null}
          className="doc-modal-member"
        >
          <DocumentMemberWrapper
            documentType={documentType}
            referenceId={referenceId}
            memberRightType={memberRightType}
          />
        </Modal>
      )}
    </>
  );
};
export default DocumentMemberModal;
