import { Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SystemLogo from "../../../../content/systemLogo.png";
import "../../financeReport/view/style.css";
import { getVoucherDetail } from "../store/actions";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";
import { formatNumberWithCommas } from "../../../../utils/Shared/helper/formatNumberWithCommas";
function VoucherPrint({ id }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { voucher } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const [watermarkCount, setWatermarkCount] = useState(1);
  const voucherDetail = useSelector(
    (state) => state.voucherSlice.voucherDetail
  );
  useEffect(() => {
    dispatch(getVoucherDetail(id));
  }, [id]);

  useEffect(() => {
    const updateWatermarks = () => {
      const ledgerElement = document.querySelector('.ledger');
      if (ledgerElement) {
        const height = ledgerElement.offsetHeight;
        const numberOfWatermarks = Math.ceil(height / 5000);
        setWatermarkCount(Math.max(numberOfWatermarks, 1));
      }
    };

    // Initial calculation
    updateWatermarks();

    // Update on window resize
    window.addEventListener('resize', updateWatermarks);

    // Cleanup
    return () => window.removeEventListener('resize', updateWatermarks);
  }, [voucherDetail?.details]);

  if (!voucherDetail)
    return (
      <div className="overflow-x-auto">
        <div className="reportView">
          <div className="ledger">
            <Skeleton active={true} />

          </div>
        </div>
      </div>
    );

  let { voucherType, amount, voucherDate, voucherNo, details } = voucherDetail;

  return (
    <div className="overflow-x-auto print-container">
      <div className="reportView">
        <div className="ledger">
          <div className="reportHeader">
            <div className="font-bold text-xl">
              {voucher.voucherTypeList && voucher.voucherTypeList[voucherType]}
            </div>
            <div className="text-sm">
              {moment(voucherDate).format("Do MMM YYYY")}
            </div>
            <div className="text-sm">{voucherNo}</div>
          </div>

          <table className="reportTable">
            <tr>
              <th>{voucher.Account}</th>
              <th>{voucher.ChequeNo}</th>
              <th>{voucher.Narration}</th>
              <th>{voucher.Debit}</th>
              <th>{voucher.Credit}</th>
            </tr>
            {details.map((item) => (
              <tr>
                <td >{item.account}</td>
                <td>{item.chequeNo}</td>
                <td>{item.narration}</td>
                <td>{formatNumberWithCommas(item.dbAmount)}</td>
                <td>{formatNumberWithCommas(item.crAmount)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td className="!font-bold">Total</td>
              <td className="!font-bold">{formatNumberWithCommas(amount)}</td>
              <td className="!font-bold">{formatNumberWithCommas(amount)}</td>
            </tr>
          </table>

          {[...Array(watermarkCount)].map((_, index) => {
            let topValue;

            if (index === 0) {
              //  first watermark at the center
              topValue = '50%';
            } else {
              // Calculate the position for additional watermarks below the center
              const stepSize = 60; // space between the watermarks
              const offset = index * stepSize;
              topValue = `calc(50% + ${offset}%)`;
            }

            return (
              <div key={index} className="reportWaterMark" style={{ top: topValue }}>
                <img src={SystemLogo} alt="Watermark" />
              </div>
            );
          })}
          <div className="reportCompanyLogo">
            <img src={SystemLogo} />
          </div>

        </div>
      </div>
    </div>
  );
}
export default VoucherPrint;
