import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../utils/api/ResponseResult";

import { openNotification } from "../../../../utils/Shared/store/slice";
import { addAuctionSubmitBidService, getAllAuctionMarketplaceService, getAllJobBoardService, getAuctionDetailForMarketplaceService,  } from "../Services/service";
import { APIRESPONSETOASTDURATION } from "../../../../utils/base";
import { responseCode } from "../../../../services/enums/responseCode";
import { message } from "antd";

export const getAllJobBoardAction = createAsyncThunk(
  "careerslice/ getAllJobBoardAction",
  async (request, { rejectWithValue }) => {
    const response = await getAllJobBoardService({ request });

    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAuctionDetailForMarketplaceAction = createAsyncThunk(
  "marketplace/getAuctionDetailForMarketplace",
  async ({id , identifier}, { dispatch, getState, rejectWithValue }) => {
    const res = await getAuctionDetailForMarketplaceService(id);
    console.log(res,"==response====")
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: APIRESPONSETOASTDURATION,
          })
        );
     
      return rejectWithValue(res.message);
    }
  }
);

export const addAuctionSubmitBidAction = createAsyncThunk(
  "marketplace/addAuctionSubmitBidAction",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await addAuctionSubmitBidService(payload);
    if (res.responseCode === responseCode.Success) {
      // dispatch(
      //   openNotification({
      //     message: "Bid Submitted Successfully!",
      //     type: 'success',
      //     duration: APIRESPONSETOASTDURATION,
      //   })
      // );
      message.info("Bid Submitted Successfully!")
      return res.data;
    } else {
      // dispatch(
      //     openNotification({
      //       message: res.message,
      //       type: "error",
      //       duration: APIRESPONSETOASTDURATION,
      //     })
      //   );
      message.error(res.message)
      return rejectWithValue(res.message);
    }
  }
);

export const getAllAutionMarketplaceAction = createAsyncThunk(
  "marketplace/getAllAutionMarketplaceActions",
  async ({filter,identifier}, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllAuctionMarketplaceService(filter);
    if (res.responseCode === responseCode.Success) {
      return res.data;
    } else {
      dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: APIRESPONSETOASTDURATION,
          })
        );
      return rejectWithValue(res.message);
    }
  }
);
