import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import blackLogo from '../../../../../content/blackLogo.svg';
import whiteLogo from '../../../../../content/whiteLogo.svg';
import { dictionaryList } from '../../../../../utils/localization/languages';
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import { darkModeHandler } from '../../../../../utils/Shared/store/slice';
import { Modal } from 'antd';
import Mac from '../../../../../content/Mac.png';
import windows from '../../../../../content/windows.png';
import android from '../../../../../content/android.png';
import ios from '../../../../../content/IOS.png';
import '../style/style.css';
import PrivacyContentWrapper from '../../PrivacyContentWrapper';
import TermsAndConditionWrapper from '../../TermAndConditionWrapper.js';
import FeedBackForm from '../../../../features/feedBack/view/feedbackForm.js';

function NavigationBottom() {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const localDictionary = dictionaryList[userLanguage];
  const { isDarkMode } = useSelector((state) => state.sharedSlice);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [isFeedBackModalVisible, setIsFeedBackModalVisible] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [isTcOpen, setIsTcOpen] = useState(false);
  const [theme, setTheme] = useState(window.localStorage.getItem('darkMode') === '1');

  useEffect(() => {
    setTheme(isDarkMode);
    dispatch(darkModeHandler(isDarkMode));
  }, [isDarkMode]);

  const showDownloadModal = () => {
    setIsDownloadModalVisible(true);
  };

  const showFeedBackModal = () => {
    setIsFeedBackModalVisible(true);
  };

  const handleDownloadModalClose = () => {
    setIsDownloadModalVisible(false);
  };

  const handleFeedBackModalClose = () => {
    setIsFeedBackModalVisible(false);
  };

  return (
    <>
      <div className="navigationBottom">
        <div className="logo">
          <img src={theme ? whiteLogo : blackLogo} alt="dark-icon" />
        </div>
        <div className="bottomLinks">
          <div className="left">
            <p onClick={() => setIsPrivacy(true)}>{localDictionary.navMenuLabel.privacy}</p>
            <p onClick={() => setIsTcOpen(true)}>{localDictionary.navMenuLabel.TC}</p>
          </div>
          <div className="right">
            <p onClick={showDownloadModal}>{localDictionary.navMenuLabel.download}</p>
            <Modal
              title="Download"
              visible={isDownloadModalVisible}
              onOk={handleDownloadModalClose}
              onCancel={handleDownloadModalClose}
              wrapClassName="custom-modal"
              width="20%"
              footer={null}
            >
              <ul>
                <li className="flex gap-3">
                  <img className="w-10" src={windows} alt="windowsIcon" />
                  <a className="my-2" href="www.workw.com/downloads/Workwise.exe" target="_blank" rel="noopener noreferrer">
                    Windows
                  </a>
                </li>
                <li className="flex gap-3 my-2">
                  <img className="w-10" src={Mac} alt="macIcon" />
                  <a className="my-2" href="www.workw.com/downloads/Workwise.dmg" target="_blank" rel="noopener noreferrer">
                    Macos
                  </a>
                </li>
                <li className="flex gap-3 my-2">
                  <img className="w-10" src={android} alt="androidIcon" />
                  <a className="my-2" href="" target="_blank" rel="noopener noreferrer">
                    Android
                  </a>
                </li>
                <li className="flex gap-3 my-2">
                  <img className="w-10" src={ios} alt="iosIcon" />
                  <a className="my-2" href="" target="_blank" rel="noopener noreferrer">
                    Ios
                  </a>
                </li>
              </ul>
            </Modal>
            <p onClick={showFeedBackModal}>{localDictionary.navMenuLabel.feedBack}</p>
          </div>
        </div>
      </div>

      <PrivacyContentWrapper
        isOpenPrivacyContent={isPrivacy}
        setIsOpenPrivacyCotent={setIsPrivacy}
      />
      <TermsAndConditionWrapper
        isOpenTermsAndCondition={isTcOpen}
        setisOpenTermsAndCondtion={setIsTcOpen}
      />
      <FeedBackForm 
        isVisible={isFeedBackModalVisible} 
        onClose={handleFeedBackModalClose} 
      />
    </>
  );
}

export default NavigationBottom;
