import React, { useContext } from "react";
import { ColorsEnum } from "../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";
import { BillingStatusEnum } from "../enum/billingStatusEnum";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";

const StatusTag = ({ status = BillingStatusEnum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  let color = ColorsEnum.Primary;
  let tag = sharedLabels.inProcess;
  switch (status) {
    case BillingStatusEnum.Paid:
      color = ColorsEnum.Completed;
      tag = "Paid";
      break;
    default:
      color = ColorsEnum.Primary;
      tag = sharedLabels.inProcess;
  }
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {tag}
      </Tag>
    </>
  );
};

export default StatusTag;
