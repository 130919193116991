import MasterConfig from "../../../../utils/services/MasterConfig"

export const getAllProjectCategoryService = () => {
    return MasterConfig.get(`api/Project/GetAllProjectCategory`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};

export const addProjectCategoryService = args => {
    return MasterConfig.post(`api/Project/AddProjectCategory`, args)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
export const updateProjectCategoryService = args => {
    return MasterConfig.put(`api/Project/UpdateProjectCategory`, args)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
export const removeProjectCategoryService = id => {
    return MasterConfig.delete(`api/Project/RemoveProjectCategory?id=${id}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
