import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetBookedScheduleService,
  GetDetailForBookAppointmentService,
  GetReferenceByIdService,
  RequestForScheduleConfirmationService,
  ScheduleConfirmationWithOtpService,
  UpdateScheduleMemberStatusByTokenService,
  addScheduleByExternalService,
} from "../services/service";
import { openNotification } from "../../../../../utils/Shared/store/slice";
import { responseCode } from "../../../../../services/enums/responseCode";
import { message } from "antd";

export const GetReferenceById = createAsyncThunk("referenceId", async (id) => {
  try {
    const response = await GetReferenceByIdService(id);
    console.log(response.data, "action data");
    return response.data;
  } catch (error) {
    console.log(error.message);
    throw new Error(`Error Fething Data: ${error}`, { cause: error });
  }
});

export const addAppointmentByExternal = createAsyncThunk(
  "addSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addScheduleByExternalService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification(message.success("Schedule Created Successfully"))
      );
      return res;
    } else {
       dispatch(
        openNotification(message.error(res.message))
      );
      return rejectWithValue(res.message);
    }
  }
);

export const GetDetailForBookAppointment = createAsyncThunk(
  "GetDetailForBookAppointment",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await GetDetailForBookAppointmentService(payload);
      if(response.data.responseCode !== responseCode.Success)
        message.error(response?.data?.message)
      
        return response.data;
      
    } catch (error) {
      console.log(error.message);
      throw new Error(`Error Fething Data: ${error}`, { cause: error });
    }
  }
);

export const GetBookedSchedule = createAsyncThunk(
  "GetBookedSchedule",
  async (data) => {
    try {
      const response = await GetBookedScheduleService(data);
      console.log(response.data, "action data");
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw new Error(`Error Fething Data: ${error}`, { cause: error });
    }
  }
);

export const UpdateScheduleMemberStatusByTokenAction = createAsyncThunk(
  "UpdateScheduleMemberStatusByToken",
  async (payload) => {
    try {
      const response = await UpdateScheduleMemberStatusByTokenService(payload);
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw new Error(`Error ${error}`, { cause: error });
    }
  }
);

export const RequestForScheduleConfirmationAction = createAsyncThunk(
  "RequestForScheduleConfirmation",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await RequestForScheduleConfirmationService(data);
    if (res.data.responseCode !== responseCode.Success) {
      dispatch(
        openNotification(message.error(res.data.message))
      );
      return rejectWithValue(res.message);
    } 
    return res;
  }
);

export const ScheduleConfirmationWithOtpAction = createAsyncThunk(
  "ScheduleConfirmationWithOtp",
  async (payload, { dispatch , getState, rejectWithValue }) => {
    try {
      const response = await ScheduleConfirmationWithOtpService(payload);
      if (response.data.responseCode !== responseCode.Success) {
        dispatch(openNotification(message.error(response.data.message)));
        return rejectWithValue(response.message);
      }
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw new Error(`Error ${error}`, { cause: error });
    }
  }
);

