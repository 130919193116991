import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  addRequisitionOfferService,
  addRequisitionService,
  GetAllRequisitionOfferService,
  getAllRequisitionService,
  GetRequisitionByIdService,
  updateRequisitionOfferStatusService,
  getRequisitionOfferByIdService,
  GetRequisitionLinkByIdService,
  getAllRequisitionsPagingService,
} from "../services/service";

export const getAllRequisition = createAsyncThunk(
  "Requisition/GetAllRequisition",
  async (data) => {
    const response = await getAllRequisitionService(data);

    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);

export const GetRequisitionById = createAsyncThunk(
  "Requisition/GetRequisitionById",
  async (id) => {
    const response = await GetRequisitionByIdService(id);
    return response.data;
  }
);

export const GetAllRequisitionOffer = createAsyncThunk(
  "Requisition/GetRequisitionOfferById",
  async (id) => {
    const response = await GetAllRequisitionOfferService(id);
    return response.data;
  }
);

export const addRequisition = createAsyncThunk(
  "Requisition/addRequisition",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addRequisitionService(data);
    console.log(res.data.message, "RESPONSE");
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Requisition Created");
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const addRequisitionOffer = createAsyncThunk(
  "Requisition/addRequisitionOffer",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addRequisitionOfferService(data);
    console.log(res.data.message, "RESPONSE");
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Offer Created");
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);
export const updateRequisitionOfferStatus = createAsyncThunk(
  "Requisition/updateRequisitionOfferStatus",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateRequisitionOfferStatusService(data);
    console.log(res.data.message, "RESPONSE");
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Offer Updated!");
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const getRequisitionOfferById = createAsyncThunk(
  "Requisition/getRequisitionOfferById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getRequisitionOfferByIdService(data);
    console.log(res.data.message, "RESPONSE");
    if (res.data?.responseCode === responseCode.Success) {
      // message.success("Offer get!");
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const GetRequisitionLinkById = createAsyncThunk(
  "Requisition/GetRequisitionLinkById",
  async (id) => {
    const response = await GetRequisitionLinkByIdService(id);
    return response.data;
  }
);

export const getAllRequisitionsPaging = createAsyncThunk("Requisitions/getAllRequisitions/paging", async (data) => {
  const response = await getAllRequisitionsPagingService(data);
  if (!response.responseCode) {
    message.error("Something went wrong");
  }
  return response.data;
});
// export const cancelReward = createAsyncThunk("reward/cancelReward", async (id, { dispatch, setState }) => {
//   const response = await cancelRewardService(id);
//   return response;
// });

