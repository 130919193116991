import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  getBasicInfoAction,
  updateProfileAction,
  updateEmployeeEmailAction,
  updateEmployeePhoneAction,
  updateEmployeeStatusAction,
  updatePasswordAction,
  getNotificationById,
  addNotificationsPermission,
} from "./action";

const initialState = {
  success: false,
  loadingData: false,
  loader: false,
  settings: {},
  notificationPermitions: []
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationById.fulfilled, (state, action) => {
        state.notificationPermitions = action.payload.data;
        state.loader = false;
      })
      .addCase(addNotificationsPermission.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(addNotificationsPermission.fulfilled, (state, action) => {
        state.loader = false;
      })
      .addCase(getBasicInfoAction.fulfilled, (state, action) => {
        state.settings = action.payload.data;
        state.loader = false;
      })
      .addCase(updateProfileAction.fulfilled, (state, { payload }) => {
        state.settings = payload.data;
        state.loader = false;
      })
      .addCase(updateEmployeeEmailAction.fulfilled, (state, { payload }) => {
        state.settings = payload.data;
        state.loader = false;
      })
      .addCase(updateEmployeePhoneAction.fulfilled, (state, { payload }) => {
        state.settings = payload.data;
        state.loader = false;
      })
      .addCase(updateEmployeeStatusAction.fulfilled, (state, { payload }) => {
        state.settings = payload.data;
        state.loader = false;
      })
      .addCase(updatePasswordAction.fulfilled, (state, { payload }) => {
        state.settings = payload.data;
        state.loader = false;
      });
  },
});

export const { } = settingSlice.actions;
export default settingSlice.reducer;



// Signature Slice

const initialStateSignature = {
  signatureData: null,
  loadingData: false,
  error: null,
}

export const signatureSlice = createSlice({
  name: "signature",
  initialState: initialStateSignature,
  reducers: {
    setSignatureData(state, action) {
      state.signatureData = action.payload;
    },
    setLoading(state, action) {
      state.loadingData = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    }
  }
});

export const { setSignatureData, setLoading, setError } = signatureSlice.actions;

