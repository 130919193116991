import React, { useContext } from "react";
import { Tag } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { EmployeeSalaryStatusEnum } from "../../utils/EmployeeSalaryStatusEnum";

const StatusTag = ({ status = EmployeeSalaryStatusEnum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { salary } = dictionaryList[userLanguage];
  const { statusEnum } = salary;

  let color = ColorsEnum.Primary;

  switch (status) {
    case EmployeeSalaryStatusEnum.Approved:
      color = ColorsEnum.Success;
      break;
    case EmployeeSalaryStatusEnum.Declined:
      color = ColorsEnum.Danger;
      break;
    case EmployeeSalaryStatusEnum.Cancelled:
      color = ColorsEnum.Cancelled;
      break;
    case EmployeeSalaryStatusEnum.Hold:
      color = ColorsEnum.Hold;
      break;
    default:
      color = ColorsEnum.Primary;
  }
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {statusEnum[status]}
      </Tag>
    </>
  );
};

export default StatusTag;
