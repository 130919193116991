import React, { useEffect, useContext } from "react";
import { TeamTable } from "./TaskTable/TeamTable";
import StatusTag from "../../../sharedComponents/Tag/StatusTag";
import { useDispatch, useSelector } from "react-redux";
import { getAllWarningAction } from "../store/action";
import "../Styles/table.css";
import { useParams } from "react-router-dom";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";
import convertUTCtoLocal from "../../../sharedComponents/DateHandler/dateUTCtoLocal";

function Warnings({ userId = null }) {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  const {
    team: { warningdetails },
    success,
  } = useSelector((state) => state.teamSlice);
  const { id } = useParams();

  let myId = userId ? userId : id;

  useEffect(() => {
    dispatch(getAllWarningAction(myId));
  }, []);
  const columns = [
    {
      title: sharedLabels.referenceNo,
      dataIndex: "referenceNo",
      key: "referenceNo",
      sort: true,
      width: 200,
      // className: "referenceNo",
    },
    {
      title: sharedLabels.Date,
      dataIndex: "createDate",
      render: (createDate) => convertUTCtoLocal(createDate, "DD MMM YYYY"),
      key: "createDate",
      sort: true,
      width: 200,
      // className: "dateTime",
    },

    {
      title: sharedLabels.category,
      dataIndex: "category",
      key: "category",
      sort: true,
      width: 200,
      // className: "category",
    },

    {
      title: sharedLabels.Status,
      dataIndex: "status",
      render: (status) => <StatusTag status={status} />,
      key: "status",
      sort: true,
      width: 200,
    },
  ];
  return (
    <>
      <TeamTable
        bordered
        columns={columns}
        // dragable={true}
        // scroll={{ x: true }}
        className="custom_table"
        dataSource={warningdetails}
      // dataSource={tableColumn()}
      />
    </>
  );
}
export default Warnings;
