export const ApprovalsModuleEnum = {
    ExpenseApproval: 1,
    ExpenseExecutor: 2,
    ExpenseFinance: 3,
    LeaveApproval: 4,
    TravelApproval: 5,
    TravelAgent: 6,
    WarningApproval: 7,
    UserEducation: 8,
    UserWorkExperience: 9,
    ComplainApproval: 10,
    RewardApproval: 11,
    BonusApproval: 12,
    PromotionApproval: 13,
    RequestForItemApproval: 14,
    LoanApproval: 15,
    CustomApproval: 16,
    FormApproval: 17,
    DocumentApproval: 18,
    SalaryApproval: 19,
    SignupApproval: 20,
    PayrollApproval: 21,
    CareerApproval: 22,
    RequisitionApproval: 23,
    RequisitionFinalApproval: 24,
    RequestForItemAssetControllerApproval: 25,
    ItemApproval: 26,
    AssetApproval: 27,
    ResignationAdminApproval: 28,
    ResignationExitApproval: 29,
    ResignationFinanceApproval: 30,
    ResignationHrApproval: 31,
    ResignationItApproval: 32,
    ResignationOtherApproval: 33,
    ResignationReportingToApproval: 34,
    QuotationApproval: 35,
    QuotationClientApproval: 36,
    AppraisalApproval: 37,
    AuctionApproval : 38,
    

};