import React, { useContext } from "react";
import { Tag } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import {
  InvoiceStatusColor,
  InvoiceStatusEnum,
} from "../../enum/InvoiceStatusEnum";
import convertUTCtoLocal from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";
import { getMonthName } from "../../../billing/enum";
import StatusTag from "../../../billing/view/statusTag";
import { STRINGS } from "../../../../../utils/base";
import { BillingTypeEnum } from "../../../billing/utils/BillingTypeEnum";
import { BillingStatusEnum } from "../../../billing/enum/billingStatusEnum";
import "./style.css";
import SystemLogo from "../../../../../../src/content/systemLogo.png";

const InvoiceItemDetail = ({ billingData }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { administration, sharedLabels, billing } = dictionaryList[
    userLanguage
  ];

  const {
    businessName = "",
    referenceNo = STRINGS.DEFAULTS.guid,
    status = BillingStatusEnum.InProcess,
    netAmount = "00",
    dueDate = convertUTCtoLocal(),
    month = 1,
    year = 2024,
    type = BillingTypeEnum.MonthlySubscription,
  } = billingData;

  return (
    <>
      <div className="invoice-header">
        <div className="flex flex-col gap-1">
          <tr>
            <td>
              <label className="font-semibold">{sharedLabels.To}</label>
            </td>
            <td>
              <label>{businessName}</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-semibold">
                {administration.billing.InvoiceNo}
              </label>
            </td>
            <td>
              <label>{referenceNo}</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-semibold">{"Invoice Date"}</label>
            </td>
            <td>
              <label>{`${getMonthName(month)} - ${year} `}</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-semibold">{"Due Date"}</label>
            </td>
            <td>
              <label>{convertUTCtoLocal(dueDate, "DD-MM-YYYY")}</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-semibold">{"Bill Type"}</label>
            </td>
            <td>
              <label>{billing.billingTypeEnum?.[type]}</label>
            </td>
          </tr>
          <tr>
            <td>
              <label className="font-semibold">{sharedLabels.status}</label>
            </td>
            <td>
            <StatusTag status={status} />
            </td>
          </tr>
        </div>
        <div className="w-[50%] flex justify-center items-center flex-col">
          <span> {"Total Amount"}</span>
          <span className="text-[34px] font-bold mr-2">{`$${netAmount}`}</span>
        </div>
      </div>
    </>
  );
};

export default InvoiceItemDetail;
