import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { STRINGS } from '../../../../../utils/base';
import blackLogo from '../../../../../content/blackLogo.svg';
import whiteLogo from '../../../../../content/whiteLogo.svg';
import { darkModeHandler } from '../../../../../utils/Shared/store/slice';
import { useDispatch } from 'react-redux';
import { isImageValid } from './isImageValid';
import Spinner from '../../../spinner/spinner';
function BusinessLogo() {
  const [validImage, setValidImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.sharedSlice);
	const {user: {businessImage}} = useSelector((state) => state.userSlice); 
   const [theme, setTheme] = useState(
    window.localStorage.getItem('darkMode') === '1'
  );
  useEffect(() => {
    setTheme(isDarkMode);
    dispatch(darkModeHandler(isDarkMode));
  }, [isDarkMode]);

  useEffect(() => {
    const checkImage = async () => {
      if (businessImage) {
        const isValid = await isImageValid(businessImage);
        setValidImage(isValid ? businessImage : null);
      } else {
        setValidImage(null);
      }
      setLoading(false);
    };

    checkImage();
  }, [businessImage]);

  return (
    <div className="businessLogo">
      <NavLink to={STRINGS.ROUTES.ROOT}>
      {loading ? (
          <Spinner />
        ) : (
          <img src={validImage || (theme ? whiteLogo : blackLogo)} alt="business-logo" />
        )}
      </NavLink>
    </div>
  );  
}

export default BusinessLogo;
