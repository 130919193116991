import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../../services/enums/responseCode.js";
import {
  addGoals,
  getAllGoals,
  updateGoals,
} from "./actions.js";

const initialState = {
  appraisals: [],
  loadingData: false,
  loader: false,
  success: false,
  error: false,
};

const appraisalSlice = createSlice({
  name: "Appraisal",
  initialState,
  reducers: {
    appraisalQuestionDeleted: (state, { payload }) => {
      state.appraisals = state.appraisals.filter((e) => e.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGoals.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.appraisals = payload.data;
      })
      .addCase(addGoals.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.createLoader = false;
        if (payload.responseCode === responseCode.Success)
          state.appraisals.push(payload.data);
      })
      .addCase(updateGoals.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.appraisals = state.appraisals.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })
      .addMatcher(isPending(...[updateGoals]), (state) => {
        state.loader = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(isPending(...[getAllGoals]), (state) => {
        state.loadingData = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(isPending(...[addGoals]), (state) => {
        state.createLoader = true;
      })
      .addMatcher(isRejected(...[getAllGoals, updateGoals]), (state) => {
        state.loader = false;
        state.loadingData = false;
        state.success = false;
        state.error = false;
      })
      .addMatcher(isRejected(...[addGoals]), (state) => {
        state.createLoader = false;
      });
  },
});

export const { appraisalQuestionDeleted } = appraisalSlice.actions;
export default appraisalSlice.reducer;
