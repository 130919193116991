import React, { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { Form } from "antd";
import SuggestionBox from "../../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionTypeEnum } from "../../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { TaskReferenceTypeEnum } from "../../../utils/TaskReferenceTypeEnum";
import { SelfTaskEnum } from "../../../utils/SelfTaskEnum";

function TaskAssignMembersComposer(props) {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { tasks } = dictionaryList[userLanguage];
    const { selfTaskEnum } = tasks;
    const [state, setState] = useState({
        selfTaskEnumType: props.selfTaskEnumType,
        members: props.members,
        suggestionBoxType: SuggestionTypeEnum.Employee,
        referenceId: props.referenceId,
        referenceType: props.referenceType
    });

    const { onChangeCallback } = props;

    useEffect(() => {
        setState((previousState) => {
            return ({
                ...previousState,
                referenceId: props.referenceId,
                referenceType: props.referenceType,
                selfTaskEnumType: props.selfTaskEnumType,
                suggestionBoxType: props.referenceType === TaskReferenceTypeEnum.Project ?
                    SuggestionTypeEnum.ProjectMember : props.referenceType === TaskReferenceTypeEnum.Group ?
                        SuggestionTypeEnum.GroupMember : SuggestionTypeEnum.Employee,
                members: state.selfTaskEnumType !== props.selfTaskEnumType ? [] : state.members
            });
        })
    }, [props]);

    return (<>
        {state.selfTaskEnumType === SelfTaskEnum.AssignTask &&
            <Form.Item
                label={selfTaskEnum[SelfTaskEnum.AssignTask]}
                name="reference"
            >
                <SuggestionBox
                    placeholder={"Select Assign To"}
                    suggestionType={[state.suggestionBoxType]}
                    isMultiSelect={true}
                    removeSelf={false}
                    referenceId={state.referenceId}
                    initialData={state.members}
                    callback={(data) => {
                        setState({ ...state, members: data })
                        onChangeCallback(data);
                    }}
                />
            </Form.Item>
        }
    </>);
}
export default TaskAssignMembersComposer;