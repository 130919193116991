import MasterConfig from "../../../../utils/services/MasterConfig";
import { jsonToFormData } from "../../../../utils/base";
export const updateLongBusinessLogoService = async (data) => {
	const formData = jsonToFormData(data);
	console.log(formData,"formdataaaaaa");
	return MasterConfig.put(`/api/Business/UpdateBusinessLogo`, formData)
	  .then((res) => {
		return res;
	  })
	  .catch((err) => {
		return err;
	  });
  };
  export const updateFullBusinessLogoService = async (data) => {
	const formData = jsonToFormData(data);
	console.log(formData,"formdataaaaaa");
	return MasterConfig.put(`/api/Business/UpdateBusinessFullLogo`, formData)
	  .then((res) => {
		return res;
	  })
	  .catch((err) => {
		return err;
	  });
  };
  export const updateShortBusinessLogoService = async (data) => {
	const formData = jsonToFormData(data);
	console.log(formData,"formdataaaaaa");
	return MasterConfig.put(`/api/Business/UpdateBusinessShortLogo`, formData)
	  .then((res) => {
		return res;
	  })
	  .catch((err) => {
		return err;
	  });
  };