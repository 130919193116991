import { Input, Rate } from "antd";
import { Table } from "../../../../sharedComponents/customTable";
import { useSelector } from "react-redux";

const getColumns = (isEditable, onCommentChange, onRatingChange, isAvg) => {
  const columns = [
    {
      title: "Questions",
      dataIndex: "question",
      ellipsis: true,
      sort: true,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      ellipsis: true,
      sort: true,
      key: "rating",
      render: (rating, record, index) => (
        <Rate
          disabled={!isEditable}
          value={rating}
          onChange={(e) => onRatingChange(e, index)}
        />
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      ellipsis: true,
      sort: true,
      render: (comment, record, index) => (
        <Input
          placeholder={isEditable ? "Comment here ..." : ""}
          disabled={!isEditable}
          value={comment}
          onChange={(e) => onCommentChange(e.target.value, index)}
        />
      ),
    },
  ];

  // If isAvg is true, filter out the "Comment" column
  return isAvg
    ? columns.filter((column) => column.dataIndex !== "comment")
    : columns;
};

export default function InterviewRatingQuestions({ data = [], isEditable = false, onCmtChange = () => { }, onRateChange = () => { },isAvg = false, }) {
  const onCommentChange = (text, index) => {
        onCmtChange(text, index);
    };

  const onRatingChange = (rating, index) => {
    onRateChange(rating, index);
  };

  return (
    <>
      <Table
        columns={getColumns(isEditable, onCommentChange, onRatingChange, isAvg)}
        draggable={true}
        data={data}
        pagination={false}
      />
    </>
  );
}