import moment from "moment";
import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import FlyLocation from "../../../../../../../content/svg/travel-location.svg";
import { dictionaryList } from "../../../../../../../utils/localization/languages";

function TravelDetailCard(props) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { travel , sharedLabels } = dictionaryList[userLanguage];
  const {TravelOptions, Shift} = travel
  const { travelDetail } = props;

  return (
    <div
      className={`bg-[#F4F4F4] rounded-lg w-[530px] text-primary-color p-[10px]`}
    >
      <div className="flex flex-col justify-center p-4">
        <div className="flex justify-between items-center ">
          <div className="flex flex-col items-center ">
            <span className="text-lg font-semibold">
              {travelDetail?.departure?.name
                ? travelDetail.departure?.name
                : travelDetail.departure}
            </span>
            <span>
              {travelDetail?.departure?.country
                ? travelDetail.departure?.country
                : travelDetail.departureCountry}
            </span>
          </div>
          <div className="flex flex-col items-center ">
            <span className="text-lg font-semibold">
              {travelDetail?.arrival?.name ? travelDetail.arrival?.name : travelDetail.arrival}
            </span>
            <span>
              {travelDetail?.arrival?.country
                ? travelDetail.arrival?.country
                : travelDetail.arrivalCountry}
            </span>
          </div>
        </div>
        <div className="flex justify-center">
          <img src={FlyLocation} alt="" />
        </div>

        <div className="flex items-center justify-between font-semibold">
          <span>
            {`${moment
              .utc(travelDetail?.departureDate)
              .local()
              .format("DD MMM, YYYY")} - ${Shift[travelDetail.shiftType]}`}
          </span>
          <span className="">{TravelOptions[travelDetail?.travelById]}</span>
        </div>
      </div>
      <hr />
      <div className="flex flex-wrap">
        <div className="flex w-full justify-between p-2">
          <span>
            {travel.HotelRequired}{" "}
            <span className="p-1 ml-[26px] bg-primary-color text-white text-xs rounded text-semi-bold inline-block w-8 text-center">
              {travelDetail?.isHotelRequired ? sharedLabels.Yes : sharedLabels.No}
            </span>
          </span>
          <span>
            {travel.TADAApplicable}{" "}
            <span className="p-1 ml-[12px] bg-primary-color text-white text-xs rounded text-semi-bold inline-block w-8 text-center relative left-[9px] ">
              {travelDetail?.isTADARequired ? sharedLabels.Yes : sharedLabels.No}
            </span>
          </span>
          <span>
            {travel.RentaCar}{" "}
            <span className="p-1 bg-primary-color text-white text-xs rounded text-semi-bold inline-block w-8 text-center">
              {travelDetail?.isCarRequired ? sharedLabels.Yes : sharedLabels.No}
            </span>
          </span>
        </div>

        <div className="flex w-full  p-2">
          <span>
            {travel.PickUpRequired}{" "}
            <span className="p-1 ml-2 bg-primary-color text-white text-xs rounded text-semi-bold inline-block w-8 text-center">
              {travelDetail?.isPickupRequired ? sharedLabels.Yes : sharedLabels.No}
            </span>
          </span>
          <span className="ml-[27px]">
            {travel.DropOffRequired}{" "}
            <span className="p-1 ml-2 bg-primary-color text-white text-xs rounded text-semi-bold inline-block w-8 text-center ">
              {travelDetail?.isDropOffRequired ? sharedLabels.Yes : sharedLabels.No}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default TravelDetailCard;
