import TagAvatar from "../../../../sharedComponents/Avatar/TagAvatar";
import AvatarCustom from "../../../../sharedComponents/Avatar/avatarOLD";
import { Rate, Progress } from "antd";
import convertUTCtoLocal from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";

export const tableColumn = (sharedLabels) => {
  return [
    {
      title: sharedLabels.subject,
      dataIndex: "subject",
      ellipsis: true,
      sort: true,
    },
    {
      title: sharedLabels.startDate,
      dataIndex: "startDate",
      render: (startDate) => convertUTCtoLocal(startDate, "DD MMM YYYY"),
      ellipsis: true,
      sort: true,
    },
    {
      title: sharedLabels.endDate,
      dataIndex: "endDate",
      render: (endDate) => convertUTCtoLocal(endDate, "DD MMM YYYY"),
      ellipsis: true,
      sort: true,
    },
    {
      title: sharedLabels.Progress,
      dataIndex: "progress",
      ellipsis: true,
      render: (progress) => (
        <Progress percent={progress} size="small" status="active" />
      ),
      sort: true,
    },
    {
      title: sharedLabels.Rating,
      dataIndex: "ratingAssign",
      ellipsis: true,
      sort: true,
      render: (rating) => (
        <Rate disabled defaultValue={rating} style={{ fontSize: 10 }} />
      ),
    },
  ];
};
