import MasterConfig from "../../../../utils/services/MasterConfig";
export const getAllDiscussionBoardCategoryService = () => {
	return MasterConfig.get(`api/DiscussionBoardCategory/GetAllDiscussionBoardCategory`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addDiscussionBoardCategoryService = args => {
	return MasterConfig.post(`api/DiscussionBoardCategory/AddDiscussionBoardCategory`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const updateDiscussionBoardCategoryService = args => {
	return MasterConfig.put(`api/DiscussionBoardCategory/UpdateDiscussionBoardCategory`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const removeDiscussionBoardCategoryService = id => {
	return MasterConfig.delete(`api/DiscussionBoardCategory/RemoveDiscussionBoardCategory?id=${id}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
