export const BuisnessNumberStatusEnum = {
    Waiting: 1,
    Purchased: 2,
    Declined: 3,
    RequestCancelled: 4,
    NumberCancelled: 5,
    Rejected: 6
}


export const BusinessNumberDirectionEnum = {
    1: "Outbound",
    2: "Inbound"
}