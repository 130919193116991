export const ExpenseReferenceTypeEnum = {
	General: 1,
	Group: 2,
	Project: 3,
	Travel: 4,
	Assets: 5,
};

export const expenseReferenceTypeHandler = (type, name = "") => {
  switch (type) {
    case ExpenseReferenceTypeEnum.General:
      return "General";
    case ExpenseReferenceTypeEnum.Group:
      return "Group";
    case ExpenseReferenceTypeEnum.Project:
      return "Project";
    case ExpenseReferenceTypeEnum.Travel:
      return "Travel";
    case ExpenseReferenceTypeEnum.Assets:
      return "Asset";
    default:
      return name;
  }
};