import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Col, Row, Skeleton } from "antd";
import { ROUTES } from "../../../../../utils/routes";
import { useSelector, useDispatch } from "react-redux";
import MainBannerContent from "./ExternalPageContent/MainBannerContent";
import Signup from "./signup/Signup";
import { LoadingOutlined } from "@ant-design/icons";
import { getVerifyProjectExternalMember } from "../store/action";
import { verifyTokenAction } from "../../../auth/store/actions";
import { responseCode } from "../../../../../services/enums/responseCode";

export const ExternalProject = () => {
  const dispatch = useDispatch();
  const { verificationSuccess, verificationLoader } = useSelector(
    (state) => state.projectExternalSlice
  );
  console.log(verificationLoader);
  const id = useLocation();
  const usertoken = id.search.split("=");
  const stoken = usertoken[1];
  const [reset, setReset] = useState(false);
  const [email , setEmail] = useState(null)

  useEffect(() => {
    if (stoken) dispatch(verifyTokenAction(stoken)).then((response)=>{
        const {payload} = response
        if(payload?.responseCode === responseCode.Success)
        {
          setEmail(payload?.data?.email)
        }
        console.log(payload,"===payload===")
    });
  }, [stoken]);
  console.log(email,"===email====")
  return (
    <>
      {verificationLoader ? (
        <div className="loaderBody">
          <LoadingOutlined className="verificationLoader" />
        </div>
      ) : (
        <dv className="lg-area">
          <div className="lg-area-color-layer" />
          <Row gutter={{ xs: 0, sm: 0, lg: 24 }} className="main-landing-row">
            <Col
              className="SinginFirstColumn"
              xs={{ order: 2, span: 24 }}
              sm={{ order: 1, span: 12 }}
              lg={{ order: 1, span: 12 }}
            >
              <MainBannerContent />
            </Col>
            <Col
              className="FormColumn"
              xs={{ order: 1, span: 24 }}
              sm={{ order: 2, span: 12 }}
              lg={{ order: 2, span: 12 }}
            >
              <div className="center-area">
                <Signup email={email}/>
              </div>
            </Col>
          </Row>
        </dv>
      )}
    </>
  );
};
