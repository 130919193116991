import { Button, Popover, Tag } from "antd";
import { useState } from "react";
import { TransactionReferenceTypeEnum } from "../../../enum/TransactionReferenceTypeEnum";
import { DisperseTypeEnum } from "../../../enum/DisperseTypeEnum";
import DisperseModal from "./PostVoucherModal";
import { STRINGS } from "../../../../../../utils/base";
import PostVoucherModal from "./PostVoucherModal";

export default function PostVoucher({
    type = DisperseTypeEnum.Button,
    label = "Post Voucher",
    id = STRINGS.DEFAULTS.guid,
    TransactionReferenceType = TransactionReferenceTypeEnum.General,
    details = []
}) {
    const [state, setState] = useState({
        isVoucherOpen: false,
    });
    const [open, setOpen] = useState(false);

    const handleVoucherOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(!open)
    };
    const handleUpdate = (e) => {
        e.preventDefault();
        e.stopPropagation();

    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const VoucherOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({
            ...state,
            isVoucherOpen: true,
        });
        setOpen(false)
    };
    return (
        <div>

            <PostVoucherModal
                open={state.isVoucherOpen}
                onCancel={() => setState(!state.isVoucherOpen)}
                id={id}
                referenceType={TransactionReferenceType}
                details={details}
            />
            <div
                className="docsPopover"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Button onClick={(e) => VoucherOpen(e)}
                    className="ThemeBtn ">
                    {label}
                </Button>
              
            </div>
        </div >
    );
}
