import { createSlice, isPending, isRejected, current } from "@reduxjs/toolkit";
import { addLoan, getAllLoans, GetLoanById } from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
  // success: false,
  // loader: false,
  // error: false,
  // approvers: [],
  ///new
  data: null,
  newItem: null,
  newCountFilterButton: false
};

const LoanSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data[identifier];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]);
    },

    ////new notification
    handleNotificationNewItem: (state, { payload }) => {

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === payload.featureId)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },

    //read count for loan
    readCountforLoan: (state, { payload }) => {
      const id = payload
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === id)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id
        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },
    //handle remarks
    handleLoanApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;

        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              console.log(finalApprovers, "Final Approvers = ");
              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              let newStatus = null;
              
              if(approverStatus.length > 1 )
              {
                 
                    if (approverStatus.length === 2 && approverStatus.includes(ApprovalStatus.Approved) && approverStatus.includes(ApprovalStatus.Removed)) {
                      newStatus = ApprovalStatus.Approved;
                  } else {
                      newStatus = ApprovalStatus.InProcess;
                  }
              
              }
              else
              {
                newStatus = approverStatus[0] === ApprovalStatus.Removed ? ApprovalStatus.Approved : approverStatus[0]
              }


              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: newStatus,
                approverStatus:
                newStatus,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) { }
    },

    //handle add approver
    handleAddLoanApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [...referenceItem?.approvers];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },

    updateApprover: (state, action) => {
      if (action.payload?.length > 0) {
        let approverList = [...state.approvers];
        let index = approverList.findIndex(
          (item) => item[0].referenceId === action.payload[0].referenceId
        );
        if (index !== -1) state.approvers[index] = action.payload;
      }
    },
    handleLoanReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLoans.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
        }
      })
      .addCase(GetLoanById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })

      .addCase(addLoan.fulfilled, (state, { payload }) => {
        state.newItem = payload.data;
      })

      .addMatcher(isRejected(...[addLoan]), (state) => {
        state.success = false;
      });
  },
});

export const {
  updateApprover,
  removeIdentifier,
  handleResetState,
  handleNewItem,
  handleLoanApprovalRemark,
  handleAddLoanApprover,
  handleNotificationNewItem,
  readCountforLoan,
  handleLoanReminder
} = LoanSlice.actions;
export default LoanSlice.reducer;
