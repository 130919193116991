import React, { useEffect, useState } from "react";
import {
  ScheduleMemberType,
  ScheduleMinutesOfMeetingStatusEnum,
} from "../enum/enum";
import MinuteofMeetingDetailPrintCard from "./MinuteofMeetingDetailPrintCard";
import MintuesOfMeetingDetailForm from "./MintuesOfMeetingDetailForm";
import { useSelector } from "react-redux";
import { getMinutesMeetings } from "../store/action";
import { useDispatch } from "react-redux";

const MinutesOfMeetingForm = ({ handleMintueOfmeetingPopUp , ScheduleData }) => {
  const { meetingDetail } = useSelector((state) => state.scheduleSlice);
  const { scheduleComposerData: sched } = useSelector(
    (state) => state.scheduleSlice
  );

     
  const dispatch = useDispatch();
  
  const { user: {id} } = useSelector((state) => state.userSlice);

    // Find the current user's member object from the members list
  const currentUserMember = meetingDetail?.members?.find(member => member.memberId === id);

  // Extract memberType if the current user's member object is found
  const currentMemberType = currentUserMember?.memberType;

  useEffect(() => {
    ScheduleData && ScheduleData.id && dispatch(getMinutesMeetings(ScheduleData?.id));
  }, []);
    return (
    <>
      {currentMemberType !== ScheduleMemberType.Admin ? (
        <div style={{ overflow: "scroll", height: "" }}>
          <MinuteofMeetingDetailPrintCard
            minutesOfMeetingDetail={meetingDetail}
          />
        </div>
      ) : (
        <MintuesOfMeetingDetailForm handleMintueOfmeetingPopUp={handleMintueOfmeetingPopUp}/>
      )}
    </>
  );
};

export default MinutesOfMeetingForm;