import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";

const turkish = {
    approvalDictionaryLabel: {
        Approval: "Onay",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Yürütücü",
        [ApprovalsModuleEnum.ExpenseFinance]: "Finans",
        [ApprovalsModuleEnum.ResignationHrApproval]: "İK",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finans",
        [ApprovalsModuleEnum.ResignationItApproval]: "IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Yönetici",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Diğer Onaylar",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Çıkış Görüşmesi",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Raporlama",
        [ApprovalsModuleEnum.TravelApproval]: "Onaylayanlar",
        [ApprovalsModuleEnum.TravelAgent]: "Acente",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Son Onaylayanlar",
    },
    approvalDictionaryPlaceHolder: {
        Approval: "Onay",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Harcama Yürütücüsünü Seçin",
        [ApprovalsModuleEnum.ExpenseFinance]: "Finanstan Onaylayanları Seçin",
        [ApprovalsModuleEnum.ExpenseApproval]: "Harcama Onaylayanlarını Seçin",
        [ApprovalsModuleEnum.ResignationHrApproval]: "İK'yi Seçin",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finansı Seçin",
        [ApprovalsModuleEnum.ResignationItApproval]: "IT'yi Seçin",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Yöneticiyi Seçin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Diğer Onayları Seçin",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Çıkış Görüşmesini Seçin",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Raporlama İçin Seçin",
        [ApprovalsModuleEnum.TravelApproval]: "Seyahat İçin Onaylayanları Seçin",
        [ApprovalsModuleEnum.TravelAgent]: "Seyahat Düzenlemeleri İçin Acenteyi Seçin",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Son Onaylayanları Seçin",
    },
    Direction: 'ltr',
};

export default turkish;