import { createSlice, current } from "@reduxjs/toolkit";
import {
  addNewTask,
  getTaskById,
  getAllTasksAction,
  AddUserTaskMembersAction,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { TaskStatusEnum } from "../utils/TaskStatusEnum";
import { STRINGS } from "../../../../utils/base";

const initialState = {
  data: null,
  newItem: null,
  newCountFilterButton: null,
};

export const taskSlice = createSlice({
  name: "task",
  initialState: initialState,
  reducers: {
    cancelTaskSuccess: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { taskId } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === taskId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              referenceItem = {
                ...referenceItem,
                status: TaskStatusEnum.Cancel,
              };
              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    changeOnProgress: (state, { payload }) => {
      const { taskId, progress, memberId } = payload;

      try {
        const updateMemberProgress = (task) => {
          const memberIndex = task?.members.findIndex(
            (x) => x.memberId === memberId
          );

          if (memberIndex === undefined || memberIndex === -1) return;

          // Update member's progress
          task.members[memberIndex].progress = progress;

          // Update task's overall progress
          task.progress = Math.floor(
            task.members.reduce((acc, member) => acc + member.progress, 0) /
              task.members.length
          );
        };

        if (state.data) {
          Object.values(state.data).forEach((tasks) => {
            const task = tasks.find((t) => t.id === taskId);
            if (task) {
              updateMemberProgress(task);
            }
          });
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleTaskRating: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, data } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              referenceItem = {
                ...referenceItem,
                ratingAssign: data,
              };
              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleFilterChange: (state, { payload }) => {
      state.tasks.data = [];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined") delete state?.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined")
        state.data[identifier] = [state?.newItem].concat(
          state.data?.[identifier]
        );
    },
    handleNewSubTask: (state, { payload }) => {
      const { identifier, subtask } = payload;
      if (typeof state?.data !== "undefined") {
        const filteredSubtasks =
          state.data?.[identifier]?.filter((item) => !item.isNew) || [];
        state.data[identifier] = filteredSubtasks.concat([
          subtask,
          { id: Date.now(), isNew: true },
        ]);
      }
    },
    handleNotificationNewItem: (state, { payload }) => {
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === payload.featureId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log("===error===tasks", e);
      }
    },
    readCountforTask: (state, { payload }) => {
      const id = payload;
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id;
        }
      } catch (e) {
        console.log("===error===Tasks", e);
      }
    },
    commentCountatUserTask: (state, { payload }) => {
      try {
        const { comment } = payload;
        const { parentId, referenceId } = comment;

        const currentState = current(state);
        if (parentId === STRINGS.DEFAULTS.guid) {
          if (currentState.data !== null) {
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data };

            keys?.forEach((identifier) => {
              const current = currentState.data[identifier];
              const index = current.findIndex((y) => y.id === referenceId);
              if (index !== -1) {
                const updatedCurrent = [...current];
                let referenceItem = updatedCurrent[index];
                const count = referenceItem.commentCount + 1;
                referenceItem = {
                  ...referenceItem,
                  commentCount: count,
                };

                updatedCurrent[index] = referenceItem;

                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
            state.data = updatedState;
          }
        }
      } catch (e) {
        console.log(e, "error===");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewTask.fulfilled, (state, action) => {
        if (action?.payload?.data?.predecessor === "") {
          state.newItem = action?.payload?.data;
        } else {
          let parentId = action?.meta?.arg?.parentId;
          try {
            const currentState = current(state);
            if (currentState.data !== null) {
              const keys = Object.keys(currentState.data);
              let updatedState = { ...currentState.data };

              keys?.forEach((identifier) => {
                const current = currentState.data[identifier];
                const index = current.findIndex((y) => y.id === parentId);
                if (index !== -1) {
                  const updatedCurrent = [...current]; // Create a deep copy of the current array
                  let referenceItem = updatedCurrent[index];
                  referenceItem = {
                    ...referenceItem,
                    subTaskCount: referenceItem.subTaskCount + 1,
                  };

                  updatedCurrent[index] = referenceItem;

                  updatedState = {
                    ...updatedState,
                    [identifier]: updatedCurrent,
                  };
                }
              });
              state.data = updatedState;
            }
          } catch (e) {
            console.log("===error===Tasks", e);
          }
        }
      })
      .addCase(getAllTasksAction.fulfilled, (state, action) => {
        const { segmentType, identifier } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
        }
      })
      .addCase(getTaskById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })
      .addCase(AddUserTaskMembersAction.fulfilled, (state, action) => {
        let data = action?.payload?.data;
        let id = action?.meta?.arg?.id;

        try {
          const currentState = current(state);
          if (currentState.data !== null) {
            const keys = Object.keys(currentState.data);
            let updatedState = { ...currentState.data };

            keys.forEach((identifier) => {
              const current = currentState.data[identifier];
              const index = current.findIndex((y) => y.id === id);
              if (index !== -1) {
                const updatedCurrent = [...current];
                let referenceItem = updatedCurrent[index];

                referenceItem = {
                  ...referenceItem,
                  members: [...referenceItem?.members, ...data],
                };
                updatedCurrent[index] = referenceItem;

                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
            state.data = updatedState;
          }
        } catch (e) {
          console.log(e, "Exceptions");
        }
      });
  },
});

export const {
  handleResetState,
  handleNewItem,
  removeIdentifier,
  handleNotificationNewItem,
  readCountforTask,
  handleTaskRating,
  changeOnProgress,
  cancelTaskSuccess,
  handleNewSubTask,
  commentCountatUserTask,
} = taskSlice.actions;
export default taskSlice.reducer;
