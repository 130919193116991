import { useContext, useState } from "react";
import { Modal, Tag } from "antd";
import { useSelector } from "react-redux";
import MinutesOfMeetingForm from "./MinutesOfMeetingForm";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { dictionaryList } from "../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";

function MinutesOfMeetings({ data }) {


  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule } = dictionaryList[userLanguage];
  const isTablet = useMediaQuery({ maxWidth: 1000 });
  console.log(isTablet, "isTablet");
  const [showMilePad, setShowMilePad] = useState(false);
  const [keys, setkeys] = useState(moment().unix());

  const handleMintueOfmeetingPopUp = () => {
    setkeys(moment().unix())
    //setShowMilePad(!showMilePad);
    setShowMilePad(true);
    
  };

  const handleCancel = () => {
    setShowMilePad(false);
  };

  const user = useSelector((state) => state.userSlice.user);
  return (
    <>
      {/* <div
        className="p-2 border rounded-lg border-[#757d86]"
        onClick={handleMintueOfmeetingPopUp}
      >
              Minutes of Meetings

            </div> */}

      <Tag onClick={handleMintueOfmeetingPopUp} color="default" className="scheduleReferenceTypeTag !mr-[4px]">
        {schedule.MinutesOftheMeeting}
      </Tag>
     { showMilePad && <Modal
        open={showMilePad}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className=""
        width={1000}
        centered={false}
        style={{ top: 20 }}
      >
        <div className="h-[800px]">
          {/* <iframe
            src={`https://milepad.konnect.im/p/${data?.id}?userName=${user.name}`}
            className="w-full h-full"
            title="MilePad"
          /> */}
          <MinutesOfMeetingForm
            key={keys}
            handleMintueOfmeetingPopUp={handleMintueOfmeetingPopUp}
            ScheduleData={data}
          />
        </div>
      </Modal>}
    </>
  );
}

export default MinutesOfMeetings;
