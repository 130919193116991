import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DOCUMENT_ENUM } from "../../constant";
import {
  AddDocumentMember,
  RemoveDocumentMember,
  addDocumentDirectoryMember,
  getAllDocumentDirectoryMember,
  getAllDocumentMember,
  removeDocumentDirectoryMember,
} from "../../store/actions";
import DocumentMemberContainer from "./documentMemberContainer";
import { STRINGS } from "../../../../../utils/base";
import "../components/quickOptions/style.css";

const DocumentMemberWrapper = ({
  documentType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
  referenceId = STRINGS.DEFAULTS.guid,
  memberRightType = DOCUMENT_ENUM.MEMBER_RIGHT_TYPE.COLLABRATOR,
}) => {
  const [state, setState] = useState({
    data: [],
    pageNo: 1,
  });

  const dispatch = useDispatch();

  //get all api of document//
  useEffect(() => {
    if (documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder) {
      dispatch(
        getAllDocumentDirectoryMember({
          id: referenceId,
          pageNo: state.pageNo,
        })
      ).then((response) => {
        setState({ ...state, data: response.payload });
      });
    } else {
      dispatch(
        getAllDocumentMember({
          id: referenceId,
          pageNo: state.pageNo,
        })
      ).then((res) => {
        setState({
          ...state,
          data: res.payload.filter(
            (it) => it.memberRightType === memberRightType
          ),
        });
      });
    }
  }, []);

  //add member apis of document//
  const addMemberHandler = (item) => {
    if (documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder) {
      let memberId = item[0]?.id.toString();
      const members = {
        id: referenceId,
        memberId: memberId,
      };
      dispatch(addDocumentDirectoryMember(members)).then((res) => {
        if (res?.payload?.length > 0) {
          setState({ data: [...state.data, ...res?.payload] });
        }
      });
    } else {
      dispatch(
        AddDocumentMember([
          {
            id: referenceId,
            memberId: item[0]?.id,
            memberRightType: memberRightType,
          },
        ])
      ).then((res) => {
        if (res?.payload?.length > 0) {
          setState({ data: [...state.data, ...res?.payload] });
        }
      });
    }
  };

  //delete member apis of document//
  const deleteMemberHandler = (id) => {
    if (documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder) {
      const memberId = id.toString();
      const delmembers = {
        id: referenceId,
        memberId: memberId,
      };
      dispatch(removeDocumentDirectoryMember(delmembers)).then(() => {
        setState({ data: state.data.filter((obj) => obj.memberId !== id) });
      });
    } else {
      const memberId = id.toString();
      const delmembers = {
        id: referenceId,
        memberId: memberId,
        status: memberRightType,
      };
      dispatch(RemoveDocumentMember(delmembers)).then(() => {
        setState({ data: state.data.filter((obj) => obj.memberId !== id) });
      });
    }
  };

  return (
    <DocumentMemberContainer
      documentType={documentType}
      data={state.data}
      referenceId={referenceId}
      addMemberHandler={addMemberHandler}
      onDelete={deleteMemberHandler}
    />
  );
};
export default DocumentMemberWrapper;
