import MasterConfig from "../../../../utils/services/MasterConfig";

export const GetAllCountriesService = () => {
    return MasterConfig.get(`api/Utility/GetAllCountries`)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };

  export const addBusinessNumberService = (data) => {
    return MasterConfig.post(`api/BusinessNumber/AddBusinessNumber`, data)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };

  export const GetAllAvailableNumberService = (data) => {
    return MasterConfig.get(`api/BusinessNumber/GetAllAvailableNumbers?country=${data.country}&pageNo=${data.pageNo}`)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };

  export const GetBusinessNumberService = () => {
    return MasterConfig.get(`api/BusinessNumber/GetAllBusinessNumbers`)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };

  export const allocateNumberService = (payload) => {
    let id = payload[0]?.id
    return MasterConfig.post(`api/BusinessNumber/AllocateBusinessNumber?id=${id}`, payload)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error; 
      });
  };

  export const deleteAllocateNumberService = (id) => {
    return MasterConfig.delete(`api/BusinessNumber/RemoveAllocationBusinessNumber?id=${id}`)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };


  export const removeAllocatedMemberService = (payload) => {
    const {allocationNumberId,
      businessNumberId} = payload
    return MasterConfig.delete(`/api/BusinessNumber/RemoveAllocationBusinessNumber?id=${allocationNumberId}`)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };

  export const GetAllBusinessNumberCallHistoryService = (data) => {
    return MasterConfig.post(`/api/BusinessNumber/GetAllBusinessNumberCallHistoryPaging`, data)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };