import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addRequisition,
  addRequisitionOffer,
  getAllRequisition,
  GetAllRequisitionOffer,
  GetRequisitionById,
  updateRequisitionOfferStatus,
  getRequisitionOfferById,
  GetRequisitionLinkById,
  getAllRequisitionsPaging,
} from "./actions";

const initialState = {
  success: false,
  pagingRequisitions :{},
  items: [],
  offers: [],
  loadingData: false,
  loader: true,
  createLoader: false,
  Detail: {},
  drawerOpen: false,
  drawerOpenOffer: false,
  cancelReward: {},
  toggleSuccess: false,
};
const requisitionSlice = createSlice({
  name: "requisition",
  initialState,
  reducers: {
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    handleOpenOfferComposer: (state, { payload }) => {
      state.drawerOpenOffer = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRequisitionsPaging.fulfilled, (state, action) => {
      state.pagingRequisitions = action.payload ?? []
      state.items = action.payload.data ?? [];
      // state.approvers = action.payload.data
      //   ? action.payload.data.map((item) => item.approvers)
      //   : [];
      state.loader = false;
    });

    builder.addCase(getAllRequisition.fulfilled, (state, action) => {
      state.items = action.payload ? action.payload : [];
      state.loader = false;
    });
   

    builder.addCase(GetRequisitionById.fulfilled, (state, action) => {
      state.Detail = action.payload.data;
      state.loadingData = false;
    });

    builder.addCase(GetRequisitionLinkById.fulfilled, (state, action) => {
      state.Detail = action.payload.data;
      state.loadingData = false;
    });

    builder.addCase(GetAllRequisitionOffer.fulfilled, (state, action) => {
      state.offers = action.payload.data;
    });

    // builder.addCase(cancelReward.fulfilled, (state, action) => {
    //   state.cancelReward = action.payload.data;
    // });

    builder.addCase(addRequisition.fulfilled, (state, { payload }) => {
      state.drawerOpen = false;
      state.success = true;
      state.items = [...state.items, payload.data.data];
      state.toggleSuccess = !state.toggleSuccess;
      state.createLoader = false;
    });
    builder
      .addCase(addRequisitionOffer.fulfilled, (state, { payload }) => {
        state.drawerOpenOffer = false;
        state.success = true;
        state.toggleSuccess = !state.toggleSuccess;
      })
      .addCase(updateRequisitionOfferStatus.fulfilled, (state, { payload }) => {
        console.log(payload.data.data, "payloaddd");
        // state.offers = payload.data.data;
      })
      .addCase(getRequisitionOfferById.fulfilled, (state, { payload }) => {})
      .addMatcher(isPending(...[getAllRequisition]), (state) => {
        state.loader = true;
        state.loadingData = true;
      })
      .addMatcher(isPending(...[GetRequisitionById]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(isPending(...[getAllRequisitionsPaging]), (state , action) => {
        const {pageNo , pageSize }  = action.meta.arg
        console.log(action , "action")
        state.loader = pageNo > 1 || pageSize > 20 ? false : true;
        console.log(state.loader ,"loader")

      })
      .addMatcher(isPending(...[addRequisition]), (state) => {
        // state.loader = true;
        state.createLoader = true;
      })
      .addMatcher(isRejected(...[addRequisition]), (state) => {
        state.createLoader = true;
      })
      .addMatcher(isRejected(...[getAllRequisition]), (state) => {
        state.loader = true;
      });
  },
});

export const {
  handleOpenComposer,
  handleOpenOfferComposer,
} = requisitionSlice.actions;
export default requisitionSlice.reducer;
