import React, { useContext, useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import store from "../../../../../../store/store";
import { feedSlice, handleImportant } from "../../../store/slice";
import { PostType } from "../../../utils/constants";
import CustomMentions from "../../../../../sharedComponents/Mentions";
import AiInput from "./AiInput";
import writeForMe from "../../../../../../content/NewContent/Documents/ai_post.svg";
import '../../../../../../App.css'
import markAsImportant from '../../../../../../content/NewContent/Documents/red colour important -ic.svg'
import important1 from "../../../../../../content/importantIcon.png"


import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";

const PostTitleField = ({ isOpen, aiInputRef, referenceId, referenceType, module , placeholder = "", autoSize = false}) => {

	const { userLanguage } = useContext(LanguageChangeContext);
	const { newsFeed  } = dictionaryList[userLanguage];
  
  const dispatch = useDispatch();
  
  const { type, title, pollTitle } = useSelector(
    ({ feedSlice }) => feedSlice.postCompose
  );
  const feedMentions = useSelector(
    ({ feedSlice = [] }) => feedSlice.mentionsOptions
  );

  const [isOpenAi, setIsOpenAi] = useState(false);
  const {clicked} = useSelector((state)=>state.feedSlice)

   const setPostTypeToImportant = () => {
    dispatch(handleImportant(!clicked))
  };

  return (
    <Form.Item name={"postDetail"}>
      <Form.Item  className={clicked ? "importantItem" : ""}>
        <div>
          <CustomMentions
            onChange={(value) =>
              store.dispatch(feedSlice.actions.onPostTitleTextChange({ value }))
            }
            onSelect={(e) =>
              store.dispatch(feedSlice.actions.onPostMention({ ...e }))
            }
            placeholder={placeholder ? placeholder : newsFeed.PostTypePlaceHolder[type]}
            value={PostType.isPollType(type) ? pollTitle : title}
            initialMentions={[...feedMentions]}
            isOpen={isOpen}
            referenceId={referenceId}
            referenceType={referenceType}
            module={module}
            autoSize = {autoSize}
          />
          <img className="importantButton" src={clicked ? markAsImportant : important1} onClick={() => setPostTypeToImportant(true)} alt="Newsfeed important button"/>
          <img className="aiButton" ref={aiInputRef} src={writeForMe} onClick={() => setIsOpenAi(true)} alt="Newsfeed generate text button"/>
         
        </div>
      </Form.Item>
      <AiInput isOpen={isOpenAi} setIsOpenAi={setIsOpenAi} />

    </Form.Item>
  );
};

export default PostTitleField;
