import { createSlice } from "@reduxjs/toolkit";
import { getAllBusinessApproval } from "./action";

const initialState = {
    approvalList: [],
    loader: false,
    recordSize: [],
};

const businessApprovalSlice = createSlice({
    name: "businessApproval",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder

            .addCase(getAllBusinessApproval.fulfilled, (state, action) => {
                const { pageNo } = action.meta.arg;
                const data = action.payload;
                if (pageNo === 1) {
                    state.approvalList = data;
                } else if (pageNo > 1) {
                    state.approvalList = [...state.approvalList, ...data];
                }

            })
    }
})
export const { } = businessApprovalSlice.actions;
export default businessApprovalSlice.reducer;