import moment from "moment";

export const taskDatesHandling = (startDate, endDate) => {
  const now = moment();
  const adjustedStart = moment(startDate);
  const adjustedEnd = moment(endDate);

  if (adjustedStart.isSame(now, 'day')) {
    // If start date is today, use current time for start and end of day for end
    adjustedStart.set({
      hour: now.get('hour'),
      minute: now.get('minute'),
      second: now.get('second')
    });
    adjustedEnd.endOf('day');
  } else {
    // If start date is in the future, use start of day for start and end of day for end
    adjustedStart.startOf('day');
    adjustedEnd.endOf('day');
  }

  return {
    startDate: adjustedStart.format(),
    endDate: adjustedEnd.format()
  };
};