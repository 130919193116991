import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import RewardDefaultIcon from "../.././../../../content/svg/menu/rewardIcon.svg";
import { GetRewardById } from "../../store/actions";
import { readCountforReward, removeIdentifier } from "../../store/slice";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import {
  ItemContent,
  ItemHeader,
} from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import { Image, Skeleton, Tag } from "antd";
import Avatar from "../../../../sharedComponents/Avatar/avatar";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import StatusTag from "./statusTag";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { readCountforBonus } from "../../../bonus/store/slice";
import ExpandableDescription from "../../../../sharedComponents/expandableDescription/expandableDescription";
import { timeFromNow } from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";

export const RewardDetail = ({ id, index }) => {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, Direction, reward } = dictionaryList[userLanguage];
  const { user } = useSelector((state) => state.userSlice);

  const [state, setState] = useState({
    identifier: moment().unix(),
    rewardDetail: {
      creator: {},
      name: "",
      description: "",
      image: RewardDefaultIcon,
      approvers: [],
      status: 1,
      reason: "",
      category: "",
      createDate: "",
      referenceNo: "",
      members: [],
      isLoading: true,
      notificationCount: 0,


    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const { data } = useSelector((state) => state.rewardSlice);
  let rewardDetail = data !== null ? data[state.identifier] : [];
  rewardDetail = rewardDetail && rewardDetail?.length > 0 && rewardDetail[0];

  useEffect(() => {
    id && dispatch(GetRewardById({ id: id, identifier: state.identifier }));
  }, []);

  useEffect(() => {
    rewardDetail &&
      setState({
        ...state,
        rewardDetail: {
          ...state.rewardDetail,
          ...rewardDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: rewardDetail?.approvers,
        },
      });
  }, [rewardDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  //close Approvers Modal
  const onCloseApproversModal = (status) => {
    setState({
      ...state,
      approvers: {
        ...state.approvers,
        isOpen: status,
      },
    });
  };
  const {
    creator,
    name,
    description,
    image = RewardDefaultIcon,
    status,
    referenceNo,
    category,
    createDate,
    reason,
    members,
    notificationCount

  } = state.rewardDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Reward,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforReward(id))
      })
    }
  }, [notificationCount])
  if (state.rewardDetail.isLoading) return <Skeleton />;

  return (
    <>
      {
        <ItemDetailModal
          data={state.approvers.data} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={state.approvers.isOpen}
          setVisible={(status) => onCloseApproversModal(status)}
        />
      }

      <div id={id} className="detailedViewComposer">
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator.image}
              name={creator.name}
              status={creator.userActiveStatus}
              profileId={creator?.id}
              Subline={
                <SublineDesigWithTime
                  designation={creator.designation ? creator.designation : ""}
                  time={timeFromNow(createDate)}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
            {/* {userId === creator.id ? (
              status != Declined && status != Resend && status != Approved ? (
                <Button
                  className="ThemeBtn"
                  onClick={(e) => handleCancel(e, props.id)}
                >
                  {rewardDictionary.cancel}
                </Button>
              ) : (
                ""
              )
            ) : (
              ""
            )} */}
          </div>
        </ItemHeader>
        <ItemContent className="flex">
          <div className="description w-full">
            {/* <p>{description}</p> */}
            <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />

          </div>
          <div
            className="ml-auto attachmentBox"
            style={{ width: "65px", height: "60px" }}
          >
            <Image
              preview={true}
              src={image === "" ? "" : image}
              rootClassName="antdImgPreviewWrapper"
            />
          </div>
        </ItemContent>
        <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
          <div className="cardSectionItem">
            <div className="cardSection__title">{sharedLabels.category}</div>
            <div className="cardSection__body">{category}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title"> {sharedLabels.name}</div>
            <div className="cardSection__body layout">{name}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title"> {sharedLabels.reason}</div>
            <div className="cardSection__body layout">{reason}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">{reward.RewardTo}</div>
            <div className="cardSection__body">
              {members && (
                <Avatar
                  isAvatarGroup={true}
                  isTag={false}
                  heading={"Members"}
                  membersData={members}
                  text={"Members"}
                  image={"https://joeschmoe.io/api/v1/random"}
                />
              )}
            </div>
          </div>
        </div>
        <RemarksApproval
          module={ApprovalsModule.RewardApproval}
          status={status}
          reference={rewardDetail?.id}
          onStatusChanged={(prev) => { }}
          setApprovalDetail={(data) => { }}
          data={state.approvers.data && state.approvers.data}
          title={sharedLabels.approvers}
          itemCreator={creator}
        />
      </div>
    </>
  );
};
