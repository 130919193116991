import { Button, Tooltip } from "antd";
import { React, useContext, useEffect, useState } from "react";
import CreateTask from "../../Page/Composer/createTask";
import SideDrawer from "../../../../../sharedComponents/Drawer/SideDrawer";
import { PlusOutlined } from "@ant-design/icons";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { STRINGS } from "../../../../../../utils/base";
import moment from "moment";
const CreateSubTask = ({
  id = STRINGS.DEFAULTS.guid,
  subject = "",
  subTaskSubject = "",
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType,
  createBtnClass = "",
  date = [moment(), moment().add(1, "day")],
  member = [],
  isOpen = false,
  onClose,
  onSubmit,
  detailCheck = false,
}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks } = dictionaryList[userLanguage];

  const [state, setState] = useState({
    isDrawerOpen: false,
  });

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({
      ...state,
      isDrawerOpen: true,
    });
  };

  const onFormSubmit = (e , response) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    setState({
      ...state,
      isDrawerOpen: false,
    });
    onSubmit(response);
    e.stopImmediatePropagation();
  };

  function stopPropagation(e) {
    e.stopPropagation();
  }

  useEffect(() => {
    setState({ isDrawerOpen: isOpen });
  }, isOpen);

  return (
    <div onClick={stopPropagation} className="inline-block float-right">

      <div className="w-100 flex justify-end ">
        {!detailCheck && (
          <Tooltip title={tasks.CreateSubtask}>
            <Button
              className={`subTaskbtn ${createBtnClass}`}
              shape="circle"
              icon={<PlusOutlined />}
              onClick={onClick}
              size="small"
            />
          </Tooltip>
        )}
      </div>
      {state.isDrawerOpen && (
        <SideDrawer
          isDisable={true}
          title={
            <h1
              style={{
                fontSize: "20px",
                margin: 0,
              }}
            >
              {tasks.CreateSubtask}
            </h1>
          }
          width="768"
          handleClose={(e) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            setState({
              ...state,
              isDrawerOpen: false,
            });
            onClose();
          }}
          isOpen={state.isDrawerOpen}
          destroyOnClose={true}
          className="detailedViewComposer drawerSecondary"
        >
          <CreateTask
            parentId={id}
            onFormSubmit={onFormSubmit}
            predecessor={subject}
            referenceId={referenceId}
            referenceType={referenceType}
            subject={subTaskSubject}
            members={member}
            taskDate={date}
          />
        </SideDrawer>
      )}
    </div>
  );
};

export default CreateSubTask;
