import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import { dictionaryList } from '../../../../../utils/localization/languages';
import { useSelector } from 'react-redux';
import { Button, Col, Divider, Form, Input, Row, Select, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { addUserEmergencyContactAction, getUserEmergency } from '../../../emergencyInfo/store/actions';
import { resetEmergencydetails } from '../../store/slice';
import { relations } from '../../../../../utils/Shared/enums/enums';
import { PlusOutlined, EditOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';


const { Option } = Select;
const PreEmergencyForm = ({ mode, userId, getEmergencyFormData = () => { } }) => {

    const param = useParams();
    const isEdit = mode === 'edit';
    const [emergencyInfo, setEmergencyInfo] = useState([]);
    const [newUserId, setNewUserId] = useState('');
    const [handleUpdateButton, setHandleUpdateButton] = useState(false)
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, Direction, Employees } = dictionaryList[userLanguage];
    const { emergencyInformation, success, emergencyDetails } = useSelector(
        (state) => state.employeeSlice
    );
    console.log(emergencyDetails?.id, 'emergencyInformation');

    const initialState = {
        name: '',
        address: '',
        contactNo: '',
        relation: [],
    };
    const [initialValues, setInitialValues] = useState(initialState);
    const [form] = Form.useForm();

    Object.defineProperty(form, 'values', {
        value: function () {
            return emergencyInformation;
        },
        writable: true,
        enumerable: true,
        configurable: true,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) setEmergencyInfo([]);

    }, [success]);
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues, form]);

    useEffect(() => {
        if (isEdit) setEmergencyInfo(emergencyInformation);
    }, [emergencyInformation]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getUserEmergency(userId));
        }
        return () => {
            dispatch(resetEmergencydetails());
        };
    }, []);

    const handleAddMore = async () => {
        try {
            form.submit();
            const isValidation = await form.validateFields();
            if (isValidation) {
                const payload = {
                    ...form.getFieldsValue(),
                };
                if (isEdit) {
                    console.log('is edit work');
                    const payloadObj = {
                        payload: form.getFieldsValue(),
                        id: param.id,
                    };
                    dispatch(addUserEmergencyContactAction(payloadObj));
                }

                setEmergencyInfo((preValues) => [...preValues, payload]);
                form.resetFields();
                setInitialValues(initialState);

            }
        } catch (err) {
            console.log(err, 'err');
            throw new Error('something went wrong', { cause: err });
        }
    };

    const handleRowChange = (rowId) => {

        const selectedRow = emergencyInfo.find((row) => row.id === rowId);
        setInitialValues(selectedRow);
        setHandleUpdateButton(true)
    };
    useEffect(() => {

        getEmergencyFormData(emergencyInfo)
    }, [emergencyInfo])



    const columns = (data) => {
        return [
            {
                title: sharedLabels.name,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: sharedLabels.address,
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: sharedLabels.Number,
                dataIndex: 'contactNo',
                key: 'contactNo',
            },
            {
                title: sharedLabels.Relation,
                dataIndex: 'relation',
                key: 'relation',
                render: (value) => {
                    return relations[value - 1]?.name;
                },
            },

            {
                title: sharedLabels.action,
                render: (value, __, rowIndex) => {
                    return (
                        <a
                            href=" "
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (isEdit) {
                                    handleRowChange(rowIndex);
                                    setNewUserId(data[rowIndex].id);
                                    console.log(data[rowIndex].id, 'data[rowIndex].id');
                                } else {
                                    const filterArray = data.filter((value, i) => {
                                        if (rowIndex !== i) return value;
                                    });
                                    setEmergencyInfo(filterArray);
                                }
                            }}
                        >
                            {isEdit ? <EditFilled style={{ color: "#1b5669" }} /> : <DeleteFilled style={{ color: "#1b5669" }} />}
                        </a>
                    );
                },
            },
        ];
    };

    let classes = 'employeeForm emergencyInfo ';
    classes += Direction === 'ltr' ? 'ltr' : 'rtl';
    return (
        <div className={classes}>
            <Divider orientation="left" orientationMargin="0"> {Employees.EmergencyInfo}  &nbsp; &nbsp;
                {!isEdit && <span className="non-mandatory-text">({sharedLabels.NonMandatory})</span>}
            </Divider>

            <Form
                name="emergencyInfo"
                form={form}
                layout={'vertical'}
                initialValues={initialValues}
            >
                <Row gutter={[16]}>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    message: sharedLabels.requiredMessageName,
                                    required: true,
                                },
                            ]}
                            name="name"
                            label={sharedLabels.name}
                        >
                            <Input placeholder={sharedLabels.enterName}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    message: sharedLabels.requiredMessageAddress,
                                    required: true,
                                },
                            ]}
                            name="address"
                            label={sharedLabels.address}
                        >
                            <Input placeholder={sharedLabels.EnterAddress}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    message: sharedLabels.requiredMessageNumber,
                                    required: true,
                                },
                            ]}
                            name="contactNo"
                            label={sharedLabels.Number}
                        >
                            <Input type="number" min={0} placeholder={sharedLabels.EnterNumber}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="relation"
                            label={sharedLabels.Relation}
                            showSearch={true}
                            rules={[{ required: true, message: sharedLabels.requiredMessageRelation }]}
                        >
                            <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder={sharedLabels.SelectRelation}
                                size="large"
                            >
                                {relations.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div className={isEdit ? 'editButtons' : 'buttons'}>
                {handleUpdateButton ?
                    <Button
                        className="mb-2 btn ThemeBtn"
                        icon={<EditOutlined />}
                    // onClick={handleUpdate}
                    >
                        {Employees.UpdateEmergencyForm}
                    </Button> : <Button
                        type="dashed"
                        className="btn addMore"
                        icon={<PlusOutlined />}
                        onClick={handleAddMore}
                    >
                        {Employees.AddEmergency}
                    </Button>
                }
            </div>

            {emergencyInfo.length > 0 && (
                <Table
                    columns={columns(emergencyInfo)}
                    dragable={true}
                    dataSource={emergencyInfo}
                />
            )}
        </div>
    )
}

export default PreEmergencyForm
