import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { openNotification } from "../../../../utils/Shared/store/slice";

import {
  addMultipleQuotationService,
  getAllQuotationService,
  getQuotationByIdService,
  getAllQuotationpagingService
} from "../services/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { message } from "antd";



export const createQuotation = createAsyncThunk(
  "Quotation/createQuotation",
  async (args, { dispatch, getState }) => {
    console.log(args, "argsss");
    const res = await addMultipleQuotationService(args);
    if (res?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Quotation Created Successfully",
          type: "success",
          duration: 2,
        })
      );

      return res.data;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return isRejectedWithValue(res.message);
    }
  }
);

export const getQuotationById = createAsyncThunk(
  "Quotation/getQuotationById",
  async ({ id }) => {
    const response = await getQuotationByIdService(id);
    return response.data;
  }
);

export const getAllQuotation = createAsyncThunk(
  "Quotation/getAllQuotation",
  async (
    { filter, pageSize, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllQuotationpagingService({ ...filter, pageSize });
      console.log(response, "responseeeee");
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllQuotationService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
  // async (data, { rejectWithValue, dispatch }) => {
  //   const response = await getAllQuotationService(data);
  //   switch (response.type) {
  //     case ResponseType.ERROR:
  //       responseMessage({
  //         dispatch: dispatch,
  //         type: responseMessageType.ApiFailure,
  //         data: {
  //           message: response.errorMessage,
  //         },
  //       });
  //       return rejectWithValue(response.errorMessage);
  //     case ResponseType.SUCCESS:
  //       return response;
  //     default:
  //       return;
  //   }
  // }
);
