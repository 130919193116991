import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addCareer,
  getAllCareerAction,
  getCareerByIdAction,
  addCareerApplicant,
  getAllCareerApplicant,
  UpdateCareerApplicantStatus,
  AddCareerApplicantOfferLetterAction,
  AddRequestToScheduleInterview,
  getCareerLinkByIdAction,
} from "./action";
import { AddRequestForInterviewersToScheduleInterviewService } from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { CareerApplicantNameStatusEnum } from "../utils/careerApplicantStatusEnum";

const defaultCareer = {
  id: 1,
  designation: "",
  description: "",
  department: "",
  minSalary: 0,
  maxSalary: 0,
  experience: "",
};
const initialState = {
  applySuccess: false,
  applyComposer: false,
  success: false,
  items: [],
  currentTab: "careers",
  drawerOpen: false,
  careerDetail: {},
  careerApplicants: [],
  loader: false,
  careerLoader: false,
  careerByIdLoader: false,
  createLoader: false,
  careerUpdateStatusResponse: [],
  carrerDetailsByLink: [],
  careerDataobj: {
    pageNo: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    isLoading: false
  },
  /////
  data: null, //THis will be with identifiers
  newItem: null,
  totalRecords: [],
  recordSize: 0,
  newCountFilterButton: false,
  itemRemove: null,

};

const careerSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {
    ////
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },

    handleNotificationNewItem: (state, { payload }) => {

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === payload.featureId)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },

    //read count notification
    readCountforCareer: (state, { payload }) => {

      const id = payload
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === id)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id
        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },
    ////handle approval remarks
    handleCareerApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: approverStatus.length > 1 ? 1 : approverStatus[0],
                approverStatus:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptionss");
      }
    },
    //handle add approval in career
    handleAddCareerApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem?.approvers,
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    handleOpenApplyComposer: (state, { payload }) => {
      state.applyComposer = payload;
    },
    addCareerList: (state) => {
      state.items = [
        ...state.items,
        {
          ...defaultCareer,
        },
      ];
    },
    handleChangeTab: (state, { payload: tab }) => {
      state.currentTab = tab;
    },
    updateScheduleInterview :(state , {payload}) => {
      const index = state.careerApplicants.findIndex((x)=> x.id === payload.id)
      
        if (index !== -1) {
          const updatedCurrent = [...state.careerApplicants];
          updatedCurrent[index].status = payload.status;
          state.careerApplicants = updatedCurrent;
      }
    },
    handleCareerReminder: (state, { payload }) => {
      console.log(payload, "payloadpayloadpayloadvas");
      const { referenceId, module, approvalId } = payload
      console.log(approvalId, "executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)


            if (index !== -1) {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if (approvalIndex !== -1) {
                let approvers = [...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount: currentApprover.reminderCount + 1,
                  lastReminder: new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover

                referenceItem = {
                  ...referenceItem,
                  approvers: approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },

    removeCandidateItem: (state, { payload }) => {
      if(!payload.selectedStatus.includes(CareerApplicantNameStatusEnum[payload.res.status])){
      const newApprovalList = state.careerApplicants.filter(item => item.id !== payload.id)
      state.careerApplicants = newApprovalList
      state.itemRemove = payload.id
      }else{
        const index = state.careerApplicants.findIndex((x)=> x.id === payload.id)
        console.log(index , "indexindex");
      
        if (index !== -1) {
          const updatedCurrent = [...state.careerApplicants];
          updatedCurrent[index].status = payload.res.status;
          state.careerApplicants = updatedCurrent;
      }
    }
  },
  
  setOfferSentStatus : (state , {payload})=> {
    console.log(payload , "indexindexpayload");

    const index = state.careerApplicants.findIndex((x)=> x.id === payload.id)
  
    if (index !== -1) {
      const updatedCurrent = [...state.careerApplicants];
      updatedCurrent[index].status = payload.status;
      state.careerApplicants = updatedCurrent;
  }
  }

  },
  extraReducers: (builder) => {
    builder
      .addCase(addCareer.fulfilled, (state, { payload }) => {
        state.newItem = payload;

      })
      .addCase(getCareerLinkByIdAction.fulfilled, (state, { payload }) => {
        state.success = true;
        state.carrerDetailsByLink = payload;
      })
      .addCase(addCareerApplicant.fulfilled, (state, { payload }) => {
        state.applySuccess = true;
        state.applyComposer = false;
        state.careerLoader = false;
      })
      .addCase(getAllCareerApplicant.fulfilled, (state, { payload }) => {
        state.careerApplicants = payload;
      })
      .addCase(getAllCareerAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
          state.recordSize = data.length;
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
          state.totalRecords = action?.payload?.totalRecords;
        }

      })
      .addCase(getCareerByIdAction.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })
      .addCase(UpdateCareerApplicantStatus.fulfilled, (state, { payload }) => {
        state.careerUpdateStatusResponse = payload;
      })
      .addCase(
        AddRequestToScheduleInterview.fulfilled,
        (state, { payload }) => {
          // state.careerApplicants =  state.careerApplicants.filter(applicant => applicant.id !== payload.userId)
        }
      )


      .addMatcher(isPending(...[addCareerApplicant]), (state) => {
        state.applySuccess = false;
        state.careerLoader = true;
      })

      .addMatcher(isRejected(...[getCareerByIdAction]), (state) => {
        // state.loader = false;
        state.careerByIdLoader = false;
      })
      .addMatcher(isRejected(...[addCareer]), (state) => {
        state.createLoader = false;
      })
      .addMatcher(isRejected(...[addCareerApplicant]), (state) => {
        state.careerLoader = false;
      });
  },
});

export const {
  handleOpenComposer,
  addCareerList,
  handleChangeTab,
  handleOpenApplyComposer,
  ///
  removeIdentifier,
  handleResetState,
  handleNewItem,
  handleNotificationNewItem,
  readCountforCareer,
  handleCareerApprovalRemark,
  handleAddCareerApprover,
  handleCareerReminder,
  removeCandidateItem,
  updateScheduleInterview,
  setOfferSentStatus
} = careerSlice.actions;
export default careerSlice.reducer;
