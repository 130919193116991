import {
  Button,
  Carousel,
  Form,
  Input,
  message,
  Upload,
  Row,
  Col,
  Select,
  Tooltip,
  Image,
} from "antd";
import React, { useContext, useEffect, useState, useCallback } from "react";
import moment from "moment";
import "../../../styles/travelComposer.css";
import { defaultUiid } from "../../../../../../utils/Shared/enums/enums";
import {
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTravelAction, addTravel } from "../../../store/actions";
import CreateTravelDetail from "./CreateTravelDetail";
import {
  getAllEmployees,
  getCities,
} from "../../../../../../utils/Shared/store/actions";
import { jsonToFormData } from "../../../../../../utils/base";
import TravelDetailCard from "../Detail/DetailItem/detailCard";
import TravelApprovals from "./TravelApprovals";
import { ApprovalsModuleEnum } from "../../../../approval/utils/enum/approvalModuleEnum";
import { docImage } from "../../../utils/TravelDoc";
import { TravelAttachmentTypeEnum } from "../../../utils/travelAttachmentTypeEnum";

function CreateTravel({
  onFormSubmit = () => {},
  referenceType,
  referenceId,
  traveldetail = null,
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { Direction } = dictionaryList[userLanguage];
  const { travel, sharedLabels } = dictionaryList[userLanguage];
  const { TravelAttachmentTypeOptions } = travel;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [state, setState] = useState({
    cities: {
      departure: null,
      arrival: null,
    },
    travelDetails: [],
    selectedDocument: TravelAttachmentTypeEnum.Other,
    selectedFiles: [],
    uploadedDocuments: [],
    isHovered: null,
    isFormSubmitting: false,
    referenceId: referenceId,
    referenceType: referenceType,
  });

  useEffect(() => {
    fetchCityData("", 0);
  }, []);

  const fetchCityData = (text, pgNo) => {
    dispatch(getCities({ textData: text, page: pgNo }));
  };

  const documentOptionArray = Object.entries(
    TravelAttachmentTypeEnum
  ).map(([key, value]) => ({ value, label: key.toString() }));

  const onFinishForm = (travelVal, travelDetailVal) => {
    if (travelDetailVal.length === 0) {
      message.error(travel.PleaseAddTravelDetail);
      return;
    }

    setState({
      ...state,
      isFormSubmitting: true,
    });
    const cities = state.travelDetails.map((travel) => {
      return {
        id: defaultUiid,
        reason: travel.reason,
        departureId: travel.departureId,
        arrivalId: travel.arrivalId,
        departureDate: moment(travel.departureDate).format(),
        shiftType: travel.shiftType,
        returnDate: moment(travel.returnDate).format(),
        travelById: travel.travelById,
        isTADARequired: travel.isTADARequired,
        isHotelRequired: travel.isHotelRequired,
        isCarRequired: travel.isCarRequired,
        isPickupRequired: travel.isPickupRequired,
        isDropOffRequired: travel.isDropOffRequired,
      };
    });

    const { subject, description, specialRequest } = travelVal;
    let attachments;

    if (state.uploadedDocuments?.length > 0) {
      attachments = state.uploadedDocuments.flatMap((type) => {
        return type.files.map((file) => {
          return {
            id: defaultUiid,
            file: file.originFileObj,
            type: type.documentType,
          };
        });
      });
    }

    const payload = {
      cities,
      subject,
      description,
      specialRequest,
      referenceId: state.referenceId,
      referenceType: state.referenceType,
    };

    if (isUpdate) {
      payload.id = traveldetail?.id;
    } else {
      payload.approvers = travelVal[ApprovalsModuleEnum.TravelApproval].map(
        (item) => ({
          approverId: item.id,
          levelNo:item.levelNo
        })
      );
      payload.agents = travelVal[ApprovalsModuleEnum.TravelAgent].map(
        (item) => ({
          approverId: item.id,
        })
      );
      payload.attachments = attachments;
    }

    !isUpdate
      ? dispatch(addTravel(jsonToFormData(payload))).then((response) => {
          setState({
            ...state,
            isFormSubmitting: false,
          });
          onFormSubmit(response?.payload?.data);
        })
      : dispatch(UpdateTravelAction(payload)).then((response) => {
          setState({
            ...state,
            isFormSubmitting: false,
          });
          onFormSubmit(response?.payload?.data);
        });
  };

  const onSelectCity = (name, objVal) => {
    objVal?.[0] &&
      setState({ ...state, cities: { ...state.cities, [name]: objVal[0] } });
  };

  const onTravelDetailAdd = (values) => {
    let tempArr = [];
    let tempObj = { ...values, ...state.cities };

    tempArr.push({
      ...tempObj,
      shiftType: values.departureShift,
    });

    if (values.return) {
      let tempObj;
      tempObj = { ...values };
      tempObj.returnDate = values.departureDate;
      tempObj.departure = state.cities.arrival;
      tempObj.arrival = state.cities.departure;
      tempObj.departureDate = values.returnDate;
      tempObj.arrivalId = values.departureId;
      tempObj.departureId = values.arrivalId;
      tempObj.shiftType = values.returnShift;
      tempArr.push(tempObj);
    }
    setState({ ...state, travelDetails: [...state.travelDetails, ...tempArr] });
  };

  const handleDocumentChange = (value) => {
    setState({ ...state, selectedDocument: value });
  };

  const handleFileUpload = (info) => {
    const updatedDocuments = [...state.uploadedDocuments];
    const existingDocumentIndex = updatedDocuments.findIndex(
      (doc) => doc.documentType === state.selectedDocument
    );

    if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      const updatedFileList = info.fileList.map((file) => ({ ...file }));

      if (existingDocumentIndex !== -1) {
        const existingFiles = updatedDocuments[existingDocumentIndex].files;
        const newFiles = updatedFileList.filter(
          (file) =>
            !existingFiles.some((existingFile) => existingFile.uid === file.uid)
        );

        updatedDocuments[existingDocumentIndex].files = [
          ...existingFiles,
          ...newFiles,
        ];
      } else {
        updatedDocuments.push({
          documentType: state.selectedDocument,
          files: updatedFileList,
        });
      }

      setState({ ...state, uploadedDocuments: updatedDocuments });
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    const isPDF = file.type === "application/pdf";

    if (isImage || isPDF) {
      file.preview = URL.createObjectURL(file);
      return file;
    } else {
      message.error(sharedLabels.Invalidfiletype);
      return false;
    }
  };

  const handleMouseEnter = (x, y) => {
    setState({ ...state, isHovered: `${x}-${y}` });
  };

  const handleMouseLeave = () => {
    setState({ ...state, isHovered: null });
  };

  const handleDeletefile = (x, y) => {
    const updatedFiles = [...state.uploadedDocuments];
    updatedFiles[x].files.splice(y, 1);
    if (!updatedFiles[x].files.length) {
      updatedFiles.splice(x, 1);
    }
    setState({ ...state, uploadedDocuments: updatedFiles });
  };

  useEffect(() => {
    if (traveldetail && Object.keys(traveldetail).length > 0) {
      setIsUpdate(true);
      form.setFieldsValue({
        ...traveldetail,
      });
      let cityData = traveldetail?.cities?.[0];
      setState({
        ...state,
        cities: {
          ...state.cities,
          departure: {
            country: cityData?.departureCountry,
            id: cityData?.departureId,
            name: cityData?.departure,
          },
          arrival: {
            country: cityData?.arrivalCountry,
            id: cityData?.arrivalId,
            name: cityData?.arrival,
          },
        },
      });
    }
  }, [traveldetail]);

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        try {
          const travelVal = await forms.travelForm.validateFields();
          onFinishForm(travelVal, state.travelDetails);
        } catch (error) {}
        if (state.travelDetails.length === 0) {
          try {
            await forms.travelDetailForm.validateFields();
          } catch (error) {}
        }
      }}
      key={1}
    >
      <Form>
        <Form
          className="travel-composer"
          layout="vertical"
          dir={Direction}
          name="travelForm"
          form={form}
        >
          <Form.Item
            name="subject"
            label={sharedLabels.subject}
            rules={[
              {
                required: true,
                message: sharedLabels.Pleaseinputyoursubject,
              },
            ]}
            direction={Direction}
          >
            <Input placeholder={sharedLabels.EnterSubject} size="middle" />
          </Form.Item>
          <Form.Item
            name="description"
            label={sharedLabels.Description}
            rules={[
              {
                required: true,
                message: sharedLabels.Pleaseinputyourdescription,
              },
            ]}
            direction={Direction}
          >
            <Input.TextArea
              style={{ borderRadius: "5px" }}
              placeholder={sharedLabels.enterDescription}
              maxLength={500}
              autoSize={{ minRows: 3, maxRows: 3 }}
              showCount
            />
          </Form.Item>

          {!isUpdate && (
            <TravelApprovals
              form={form}
              referenceId={referenceId}
              referenceType={referenceType}
              key={referenceId}
            />
          )}

          <CreateTravelDetail
            key={0}
            fetchCityData={fetchCityData}
            Direction={Direction}
            onSelectCity={onSelectCity}
            onTravelDetailAdd={onTravelDetailAdd}
            traveldetail={traveldetail}
          />
          <Carousel
            // afterChange={onCardSlide}
            infinite={false}
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            slidesToShow={1}
            dots={true}
            arrows
          >
            {state.travelDetails.map((travel, index) => (
              <div className="travelCarrouselbox">
                <TravelDetailCard
                  key={index}
                  travelDetail={travel}
                  index={index}
                  // onClick={onClick}
                  isCloseable={true}
                />
              </div>
            ))}
          </Carousel>
          <Form.Item
            name="specialRequest"
            direction={Direction}
            label={travel.SpecialRequest}
          >
            <Input.TextArea
              style={{ borderRadius: "5px" }}
              placeholder={travel.WriteSpecialRequestDetail}
              rows={4}
            />
          </Form.Item>
          {!isUpdate && (
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item direction={Direction} label={travel.AttachmentType}>
                  <Select
                    defaultValue={TravelAttachmentTypeEnum.Other}
                    options={documentOptionArray}
                    onChange={handleDocumentChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Upload Documents">
                  <Upload
                    fileList={state.selectedFiles}
                    accept=".jpg,.png,.gif,.pdf"
                    multiple
                    onChange={handleFileUpload}
                    beforeUpload={beforeUpload}
                  >
                    <Button icon={<UploadOutlined />}>Select Files</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          )}
          {state.uploadedDocuments.map((doc, x) => (
            <div key={doc.documentType}>
              {doc.files.length > 0 && (
                <>
                  <h3>{TravelAttachmentTypeOptions[doc.documentType]}</h3>
                  <Row gutter={[16, 16]}>
                    {doc.files.map((file, y) => (
                      <Col span={4} key={file.uid}>
                        <div
                          onMouseEnter={() => handleMouseEnter(x, y)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="travelDoc-img-wrapper">
                            {file.name.endsWith(".pdf") ? (
                              <>
                                <div className="flex flex-col items-center">
                                  {docImage.image}
                                  <div className="text-center">{file.name}</div>
                                </div>
                              </>
                            ) : (
                              <Image
                                src={
                                  file.preview ? file.preview : "/file-icon.png"
                                }
                                className="imgPreview"
                                rootClassName="travelDoc-img"
                              />
                            )}
                          </div>

                          {state.isHovered === `${x}-${y}` && (
                            <Tooltip title="">
                              <CloseOutlined
                                className="absolute bottom-[82%] left-[82%] text-[10px] deleteIcon "
                                onClick={() => handleDeletefile(x, y)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </div>
          ))}
        </Form>

        <Button
          htmlType="submit"
          className="ThemeBtn"
          block
          loading={state.isFormSubmitting}
        >
          {isUpdate ? travel.UpdateTravel : travel.CreateTravel}
        </Button>
      </Form>
    </Form.Provider>
  );
}

export default CreateTravel;
