import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  addLeadManagerContactService,
  addLeadManagerDetailService,
  addLeadManagerService,
  getAllLeadManagerPagingService,
  getAllLeadManagerService,
  getLeadManagerByIdService,
  getLeadManagerContactDetailService,
  getLeadManagerSectionByIdService,
  getLeadManagerSectionDetailByIdService,
  moveLeadManagerDetailService,
  moveLeadManagerSectionService,
  updateLeadManagerContactService,
  updateLeadManagerDetailService,
  updateLeadManagerService,
  getAllScheduleService,
  getScheduleByIdService,
  getAllLeadManagerMemberService,
  addLeadManagerMemberService,
  deleteLeadManagerMemberByService,
  covertLeadIntoPrjectService,
  deleteLeadManagerDetailCoverPhotoToService,
  addLeadManagerDetailMemberService,
  getAllLeadManangerDetailMemberService,
  deleteLeadManagerDetailMemberService,
  getAllLeadManagerDetailPagingService,
  LeadManagerMetricsService,
  AddLeadManagerDetailCallService,
  GetAllLeadManagerDetailCallService,
  GetAllLeadManagerSectionService
} from "../services/services";
import {
  addLeadDetailMembers,
  addLeadMember,
  deleteLeadManagerMember,
  handleFormDetail,
} from "../store/slice";
import { ActionType } from "../../../sharedComponents/CustomModal";

export const addLeadManager = createAsyncThunk(
  "addLeadManager",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addLeadManagerService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "LeadManager Created Successfully",
          type: "success",
          duration: 2,
          actionType: ActionType.Route,
          actionData: {
            path: `leadManager/leadManagerGroupDetails/${res.data.id}`,
          },
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addLeadManagerDetail = createAsyncThunk(
  "addLeadManagerDetail",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addLeadManagerDetailService(data);
    console.log(res, "res lead ");
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Lead Manager Detail Created Successfully",
          type: "success",
          duration: 2,
        })
      );
      dispatch(handleFormDetail(false));
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateLeadManager = createAsyncThunk(
  "updateLeadManager",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateLeadManagerService(data);
    console.log(res, "Responsee");
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Lead Manager Updated Successfully!",
          type: "success",
          duration: 2,
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllLeadManager = createAsyncThunk(
  "getAllLeadManager",
  async (data, { dispatch, getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const res = await getAllLeadManagerService(data, source);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const getAllLeadManagerPaging = createAsyncThunk(
  "getAllLeadManagerPaging",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllLeadManagerPagingService(data);
    if (res.responseCode === responseCode.Success) {
      console.log(res, "responseee");
      return res;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const getLeadManagerById = createAsyncThunk(
  "getLeadManagerById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getLeadManagerByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);

export const deleteLeadManagerMemberById = createAsyncThunk(
  "deleteLeadManagerMemberById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await deleteLeadManagerMemberByService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(deleteLeadManagerMember(data));
      return res.data;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);
export const getLeadManagerSectionById = createAsyncThunk(
  "getLeadManagerSectionById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getLeadManagerSectionByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);

export const getLeadManagerDetailById = createAsyncThunk(
  "getLeadManagerDetailById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getLeadManagerSectionDetailByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        data: res,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue(res.message);
    }
  }
);

export const addLeadManagerContact = createAsyncThunk(
  "addLeadManagerContact",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addLeadManagerContactService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "LeadManager Contact Created Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateLeadManagerDetail = createAsyncThunk(
  "updateLeadManagerDetail",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateLeadManagerDetailService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Lead Manager Detail Updated Successfully!",
          type: "success",
          duration: 2,
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllLeadManagerContactDetail = createAsyncThunk(
  "getAllLeadManagerContactDetail",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getLeadManagerContactDetailService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const updateLeadManagerContact = createAsyncThunk(
  "updateLeadManagerContact",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateLeadManagerContactService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Lead Manager Contact Updated Successfully!",
          type: "success",
          duration: 2,
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);
export const moveLeadManagerSection = createAsyncThunk(
  "moveLeadManagerSection",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await moveLeadManagerSectionService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const moveLeadManagerDetail = createAsyncThunk(
  "moveLeadManagerDetail",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await moveLeadManagerDetailService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Moved Successfully!",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res);
    }
  }
);

export const addLeadManagerDetailMember = createAsyncThunk(
  "addLeadManagerDetailMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addLeadManagerDetailMemberService(data);
    if (res.responseCode === responseCode.Success) {
      return res.data;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const deleteLeadManagerDetailMember = createAsyncThunk(
  "deleteLeadManagerDetailMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await deleteLeadManagerDetailMemberService(data);
    if (res.responseCode === responseCode.Success) {
      return res.data;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const getAllScheduleAction = createAsyncThunk(
  "getAllSchedule",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllScheduleService(data);
    if (res.responseCode === responseCode.Success) {
      // console.log(res, "DATA IN ACTION");
      return res;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const getScheduleByIdAction = createAsyncThunk(
  "getAllScheduleById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getScheduleByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      return rejectWithValue(res.message);
    }
  }
);
export const getAllLeadManagerMember = createAsyncThunk(
  "GetAllLeadMember",
  async (data) => {
    const response = await getAllLeadManagerMemberService(data);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);
export const addLeadManagereMember = createAsyncThunk(
  "addLeadMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addLeadManagerMemberService(data);
    if (res.data?.responseCode === responseCode.Success) {
      dispatch(addLeadMember(res.data.data));
      return res.data.data;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const ConvertLeadIntoProject = createAsyncThunk(
  "ConvertLeadIntoProject",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await covertLeadIntoPrjectService(id);
    if (res.responseCode === responseCode.Success) {
      message.success("Converted Into Project");
      return res;
    } else {
      message.error(res?.message);
      return rejectWithValue(res?.message);
    }
  }
);

export const deleteLeadManagerDetailCoverPhoto = createAsyncThunk(
  "deleteLeadManagerDetailCoverPhoto",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await deleteLeadManagerDetailCoverPhotoToService(data);
    if (res.responseCode === responseCode.Success) {
      return {
        data: res.data,
      };
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const getAllLeadManangerDetailMember = createAsyncThunk(
  "getAllLeadManangerDetailMember",
  async (data) => {
    const response = await getAllLeadManangerDetailMemberService(data);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);
export const getAllLeadManangerDetailPaging = createAsyncThunk(
  "getAllLeadManangerDetailPaging",
  async (data) => {
    const response = await getAllLeadManagerDetailPagingService(data);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);
export const LeadManagerMetricsAction = createAsyncThunk(
  "LeadManagerMetricsAction",
  async (_,{ dispatch, getState, rejectWithValue }) => {
    const res = await LeadManagerMetricsService();
    console.log(res,"==response==")
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      message.error(res?.message);
      return rejectWithValue(res?.message);
    }
  }
);

export const AddLeadManagerDetailCallAction = createAsyncThunk(
  "AddLeadManagerDetailCallAction",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await AddLeadManagerDetailCallService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Added Successfully!",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const GetAllLeadManagerDetailCallAction = createAsyncThunk(
  "GetAllLeadManagerDetailCallAction",
  async (detailId, { dispatch, getState, rejectWithValue }) => {
    const res = await GetAllLeadManagerDetailCallService(detailId);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const GetAllLeadManagerSectionAction = createAsyncThunk(
  "GetAllLeadManagerSectionAction",
  async (data,{ dispatch, getState, rejectWithValue }) => {
    const res = await GetAllLeadManagerSectionService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      message.error(res?.message);
      return rejectWithValue(res?.message);
    }
  }
);