import React, { useEffect, useState } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import "./style/scheduleComfirmation.css";
import { UpdateScheduleMemberStatusByTokenAction } from "./store/action";
import landscapeSystemLogo from "../../../../../src/content/landscapeSystemLogo.jpeg";
import { responseCode } from "../../../../services/enums/responseCode";
import { useDispatch } from "react-redux";
import { message } from "antd";
function ScheduleConfirmation() {
  const [isVerified, setIsVerified] = useState(false);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const token = params.get("token") + "";
  const status = params.get("status");
  const dispatch = useDispatch();
  useEffect(() => {
    const payload = {
      token: token,
      status: status,
    };
    dispatch(UpdateScheduleMemberStatusByTokenAction(payload)).then(
      (response) => {
        if (response?.payload?.responseCode === responseCode.Success) {
          setIsVerified(true);
        } else {
          message.error(response?.payload?.message);
        }
      }
    );
  }, []);
  return (
    <>
      {isVerified && (
        <>
          <div className="ScheduleConfirmation_1">
            <div className="ScheduleConfirmation_2">
              <div className="checkcircle-icon">
                <CheckCircleFilled />
              </div>
            </div>

            <p>
              <strong className="heading_one">Thank You!</strong>
            </p>

            <p>Your Confirmation has been submitted!</p>

            <footer className="footer_2">
              <div className="PoweredBy" style={{ fontWeight: "bold" }}>
                <div className="my-2" id="heading_3">
                  Powerd By
                </div>
                <div className="mx-3" id="company_logo">
                  <a href="https://workw.com">
                    <img className="company_logo" src={landscapeSystemLogo} />
                  </a>
                </div>
                <div className="my-2" id="tagline">
                  {" "}
                  | 2016 - 2024
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </>
  );
}

export default ScheduleConfirmation;
