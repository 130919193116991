import MasterConfig from "../../../../../utils/services/MasterConfig";
import { jsonToFormData } from "../../../../../utils/base";

export const GetReferenceByIdService = (id) => {
  return MasterConfig.get(`api/Reference/GetReferenceById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const addScheduleByExternalService = (payload) => {
  console.log(payload, "service datatatta");
  const formData = jsonToFormData(payload.data);
  return MasterConfig.post(
    `api/Schedule/AddScheduleByExternal?token=${payload.token}`,
    formData
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const GetDetailForBookAppointmentService = (payload) => {
  console.log(payload,"===payload====")
  return MasterConfig.post(`api/Schedule/GetDetailForBookAppointment`,payload)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetBookedScheduleService = (data) => {
  return MasterConfig.post(`api/Schedule/GetBookedSchedule`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const UpdateScheduleMemberStatusByTokenService = (payload) => {
  const { token, status } = payload;
  return MasterConfig.get(
    `api/Schedule/UpdateScheduleMemberStatusByToken?token=${token}&status=${status}`
  )
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const RequestForScheduleConfirmationService = (data) => {
  return MasterConfig.post(`api/Schedule/RequestForScheduleConfirmation`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const ScheduleConfirmationWithOtpService = (payload) => {
  const { token, otp } = payload;
  return MasterConfig.get(
    `api/Schedule/ScheduleConfirmationWithOtp?token=${token}&otp=${otp}`
  )
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

