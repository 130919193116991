import React, { Suspense, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../utils/routes";
import SideNavigation from "../main/sharedComponents/Nav/";
import TopMenuBar from "../main/sharedComponents/MobileView/topMenu";
import BottomNavigationTab from "../main/sharedComponents/MobileView/bottomNavigationTab/";
import { useSelector } from "react-redux";
import { SOCKET_STATE } from "../utils/Shared/enums/socketEnums";
import Spinner from "../main/sharedComponents/spinner/spinner";

const SideChatBar = lazy(() => import("../main/features/SideChatbar/index"));
const FcmNotification = lazy(() => import("../main/features/notifiation/view/FCM/fcmNotification"));
const IncomingCall = lazy(() => import("../main/features/calling/view/IncomingCall"));
const OutgoingCall = lazy(() => import("../main/features/calling/view/outgoingCall"));
const MainNotification = lazy(() => import("../main/sharedComponents/Notification/Notification"));
const UserCheckInNotification = lazy(() => import("../main/features/attendance/view/Notification/userCheckInNotification"));
const NotificationAudioHandle = lazy(() => import("../main/sharedComponents/Notification/notificationAudioHandler"));
const Dialer = lazy(() => import("../main/features/dialer/view"));

const PrivateRoute = () => {
  const { token } = useSelector((state) => state.userSlice);
  const isLoggedIn = !!token;

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: ROUTES.AUTH.SIGN_IN,
        }}
      />
    );
  }
};

export default function Routes({ isLoggedIn, isMobileView, activityCount }) {
  const socketState = useSelector(
    ({ general }) => general.socketConnectionState
  );
  const socketStateColor =
    socketState === SOCKET_STATE.CONNECTED
      ? "#42b72a"
      : socketState === SOCKET_STATE.DISCONNECTED
      ? "#f03f27"
      : "blue";

  return (
    <React.Fragment>
      {isLoggedIn && (
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: "999",
            background: socketStateColor,
            color: "white",
          }}
        >
          {socketState}
        </div>
      )}
      {isLoggedIn && <SideNavigation />}
      {isLoggedIn && isMobileView ? <TopMenuBar /> : ""}
      <div className="main-app-style">
        <div className="section ov-des" id="section1">
          <Suspense fallback={<Spinner />}>
            <PrivateRoute />
          </Suspense>
        </div>
      </div>

      <Suspense fallback={<Spinner />}>
        {isLoggedIn && <SideChatBar />}
        {isLoggedIn && <FcmNotification />}
        {isLoggedIn && <Dialer />}
        {isLoggedIn && <IncomingCall />}
        {isLoggedIn && <OutgoingCall />}
        {isLoggedIn && <MainNotification />}
        {isLoggedIn && <UserCheckInNotification />}
        {isLoggedIn && <NotificationAudioHandle />}
      </Suspense>
      {isLoggedIn && isMobileView && <BottomNavigationTab />}
    </React.Fragment>
  );
}
