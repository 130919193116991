// import AxiosConfig from "../../../../utils/services/AxiosConfig";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../../utils/api/ResponseResult";
import Config from "../../../../../utils/services/MasterConfig";
import { responseCode as responseCodeEnum } from "../../../../../services/enums/responseCode";
import { jsonToFormData } from "../../../../../utils/base";

export const getAllCompanyService = async (search) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Business/GetAllBusiness?search=${search}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getCompanyByIdService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Business/GetBusinessById?id=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllRewardService = async (id) => {
  console.log(id, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Reward/GetRewardUserById?userId=${id}`);
    console.log(responseCode, "response code");

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllLoanService = async (id) => {
  // console.log(request, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Loan/GetLoanUserById?userId=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const ResendSignupEmailService = (id) => {
  return Config.get(`api/Signup/ResendSignupEmail?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetSignupByIdService = (id) => {
  return Config.get(`api/Signup/GetSignupById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllComplainService = async (id) => {
  // console.log(request, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Complain/GetComplainUserById?userId=${id}`);
    // console.log(responseCode, "REQUEST REWARD RESPONSE");

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllSignupService = async (payload) => {
  console.log(payload, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(
      `api/Signup/GetAllSignupByWaitingForEmailConfirmation`,
      payload
    );
    console.log(data, "REQUEST REWARD RESPONSE");

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const updateBusinessInfoService = async (payload) => {
  // const {
  //   address,
  //   availed,
  //   callEmployees,
  //   email,
  //   id,
  //   memory,
  //   name,
  //   ownerName,
  //   phoneNo,
  //   totalEmployees,
  //   totalGroups,
  //   totalProjects,
  //   used,
  // } = payload;
  // const formData = jsonToFormData({ id, name, email, address, phoneNo });
  const formData = jsonToFormData(payload)
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.put(`/api/Business/UpdateBusiness`, formData);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const dashboardViewService = async (id) => {
  // console.log(request, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Business/GetBusinessDashBoard?id=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const updateBusinessDisableStatusService = async (payload) => {
  const { businessId, isDisable } = payload
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.put(`/api/Business/UpdateBusinessDisableStatus?businessId=${businessId}&isDisable=${isDisable}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const GetAllProductLoanSettingsService = (id) => {
  return Config.get(`api/Business/GetAllProductLoanSettings`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const SaveProductLoanSettingsService = (data) => {
  return Config.post(`api/Business/SaveProductLoanSettings`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};


export const SaveAttachmentUploadSizeSettingService = (data) => {
  return Config.post(`api/Business/SaveAttachmentUploadSizeSetting`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetPersonalLoanService = (data) => {
  return Config.post(`api/Business/GetPersonalLoan`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};


export const GetProductLoanSettingsService = (id) => {
  return Config.get(`api/Business/GetProductLoanSettings?businessId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetAllAttachmentUploadSizeSettingService = () => {
  return Config.get(`api/Business/GetAllAttachmentUploadSizeSetting`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const DisbursedLoanService = (loanId) => {
  return Config.put(`api/Business/DisbursedLoan?loanId=${loanId}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetAllBusinessBankDetailService = () => {
  return Config.get(`api/Business/GetAllBusinessBankDetail`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const AddBusinessBankDetailService = (data) => {
  return Config.post(`api/Business/AddBusinessBankDetail`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};


export const SaveBusinessDetailService = (data) => {
  const formData = jsonToFormData(data);
  return Config.put(`api/Business/SaveBusinessDetail` , formData)
  .then((res) => {
    return res;
  })
  .catch((res) => {
    return res;
  });
};


export const GetOverallBusinessStorageDashboardService = (payload) => {
  return Config.post(`api/Business/GetOverallBusinessStorageDashboard`, payload)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getBusinessDetailService = async (businessId) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(
      `api/Business/GetBusinessDetail?businessId=${businessId}`
    );
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);  
  }
};

export const GetOverallUserStorageDashboardService = (payload) => {
  let { top, userId, businessId } = payload
  return Config.get(`api/Business/GetOverallUserStorageDashboard?top=${top}&userId=${userId}&businessId=${businessId}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};