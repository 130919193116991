import React from 'react'
import unauthIcon from "../../../../content/unath-403.svg"
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd';

const UnauthorizedPage = () =>{

    return(
        <>
             <div className="flex flex-col items-center justify-center h-full w-full">
                <img
                    src={unauthIcon}
                    alt="unauthorized"
                />
                {/* <Button
                    type="primary"
                    className="ThemeBtn"
                    onClick={goBack}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        marginTop: "10px",
                    }}
            >
                <span>Go Back</span>
            </Button> */}
            </div>
        </>
    )

}

export default UnauthorizedPage;