import MasterConfig from "../../../../utils/services/MasterConfig";
export const GetAllPagesService = (data) => {
  return MasterConfig.post(`api/Pages/GetAllPages`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetAllPagesPagingService = (data) => {
  return MasterConfig.post(`api/Pages/GetAllPagesPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addPagesService = (args) => {
  return MasterConfig.post(`api/Pages/AddPages`, args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const GetPagesByIdService = (id) => {
  console.log("ID FROM SERVICE", id);
  return MasterConfig.get(`api/Pages/GetPagesById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const updatePagesStatusService = (args) => {
  let id = args.id;
  return MasterConfig.put(`api/Pages/UpdatePagesStatus?id=${id}`, args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const AddPagesMemberService = async (payload) => {
  let id = payload[0]?.id;
  return MasterConfig.post(`api/Pages/AddPagesMember?id=${id}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const RemovePagesMemberService = async (payload) => {
  let id = payload.id;
  let status = payload.status;
  let memberId = payload.memberId;
  return MasterConfig.post(
    `api/Pages/RemovePagesMember?id=${id}&status=${status}`,
    [memberId]
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllPagesMemberService = (data) => {
  let id = data.id;
  let pageNo = data.pageNo;
  return MasterConfig.get(`api/Pages/GetAllPagesMember?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const changePagesStatusService = (data) => {
  return MasterConfig.put(`api/Pages/UpdatePagesStatus?id=${data.id}&status=${data.status}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};