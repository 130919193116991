import {getItemBorderColor, getRelativeDateAndTime, renderTitleWithMentions, STRINGS} from "../../../../../../utils/base";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import ReferenceRoute from "../../../../../sharedComponents/reference/referenceRoute";
import {ReferenceTypeEnum} from "../../../../../sharedComponents/reference/utils/referenceTypeEnum";
import {useSelector} from "react-redux";

function CommentBubble({creator = {},attachments = [],comment = "",createDate, commentItem ={}}) {
    const {name = "",id = STRINGS.DEFAULTS.guid,type = ReferenceTypeEnum.User , designation = ""} = creator;
    const { unreadNotification = false,
        isImportant = false,
        isPinned = false,
        mentions = []} = commentItem;


    const {
        userSlice: { user },
    } = useSelector((state) => state);
    return (
        <div style={{ display: "flex", width: "100%" }}>
            <div className={`${getItemBorderColor(0,
                unreadNotification,
                isImportant,isPinned,
                mentions,
                user.id
            )} CommentBubble`} >
                <div className="CommentHeader">
                    <div className="CommentHeaderDet">
                        <div className="name">
                            <ReferenceRoute
                                name={name}
                                type={type}
                                id = {id}
                            />
                        </div>
                        <div className="designation">
                            {designation}
                        </div>
                    </div>
                    <div className="CommentHeaderIcon">
                        <span className="time">{getRelativeDateAndTime(createDate)}</span>
                    </div>
                </div>
                <div
                    className="pr-20"
                    dangerouslySetInnerHTML={{
                        __html: renderTitleWithMentions(comment, mentions),
                    }}
                />
                {attachments?.length > 0 &&
                    attachments?.map(({ path, attachmentName }) => (
                        <div className="rounded-[20px] overflow-hidden w-auto inline-block">
                            <img
                                src={path}
                                alt={attachmentName}
                                className="max-w-[210px] aspect-[9/6]"
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
}
export default CommentBubble;