import { createAsyncThunk } from "@reduxjs/toolkit";
import { addfeedBackService, getAllFeedbackPagingService, GetFeedBackByIdService } from "../services/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { message } from "antd";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";

export const addProductFeedBack = createAsyncThunk(
    "ProductFeedback/AddProductFeedback",
    async (data, { dispatch, getState, rejectWithValue }) => {
        const res = await addfeedBackService(data);
        if (res.data?.responseCode === responseCode.Success) {
            dispatch(
                openNotification({
                    message: "Add Feedback",
                    type: "success",
                })
            );
            return res;
        } else {
            message.error(res.data.message);
            return rejectWithValue(res.data.message);
        }
    }
);

export const GetFeedBackById = createAsyncThunk(
    "ProductFeedback/GetProductFeedbackById",
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await GetFeedBackByIdService(id);
            console.log('API Response in Thunk:', response);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const getAllFeedBack = createAsyncThunk(
    "ProductFeedback/GetAllProductFeedbackPaging",
    async (args, { dispatch, getState }) => {
        const res = await getAllFeedbackPagingService(args);
        if (!res.responseCode) {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }
        return res;
    }
);

