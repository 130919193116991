import { useRef, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./style/calenderComponent.css";
import "./style/style.css";
import { Calendar, Modal } from "antd";
import EventDetail from "../../schedule/view/eventDetail";
import moment from "moment";
import SideDrawer from "../../../sharedComponents/Drawer/SideDrawer";
import "../../../sharedComponents/Drawer/sideDrawer.css";
import CreateAppointment from "./CreateAppointment";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setmodal } from "./store/slice";
import { GetBookedSchedule, GetDetailForBookAppointment } from "./store/action";
import { error } from "jquery";
import { STRINGS } from "../../../../utils/base";
import OTPModal from "./otpModal";
import { filterEventsByBusinessHours } from "./utils/filterEventsByBusinessHours";
import { convertSecondsToTime } from "./utils/convertSecondsToTime";

function SchedulersComponent(props, { feed = false, referenceId }) {
  const [calenderView, setCalendarView] = useState("");
  const [todayDate, setTodayDate] = useState(moment().format("YYYY-MM-DD"));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [events, setEvents] = useState([]);
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const [confirmationToken, setConfirmationToken] = useState("");
  const {setIsSuccess, setAppointmentData} = props;
  const { slotRanges, bookedSlots, userData } = useSelector(
    (state) => state.externalBookAppointment
  );
  const modal = useSelector((state) => state.externalBookAppointment.modal);
  const [businessHours, setBusinessHours] = useState([]);
  const dispatch = useDispatch();
  const calendarRef = useRef();
  let isPanelChange = false;

  useEffect(() => {
    let newData = [...bookedSlots]?.map((sch) => {
      const startDate = moment.utc(sch.startDate).local();
      const endDate = moment.utc(sch.endDate).local();
      if (startDate.isSame(endDate, "day")) {
        // Time-wise event
        return {
          ...sch,
          date: startDate.format("YYYY-MM-DDTHH:mm:ssZ"),
          end: endDate.format("YYYY-MM-DDTHH:mm:ssZ"),
        };
      } else {
        return {
          ...sch,
          date: startDate.format("YYYY-MM-DDTHH:mm:ssZ"),
          end: endDate.add(1, "day").format("YYYY-MM-DDTHH:mm:ssZ"),
          allDay: true,
        };
      }
    });
    setEvents(
      businessHours.length > 0
        ? filterEventsByBusinessHours(newData, businessHours)
        : newData
    );
  }, [businessHours]);

  useEffect(() => {
    const todayDayId = moment(todayDate).day(); // Getting the day of the week (0-6, where 0 is Sunday)
    const todaySlot = slotRanges.find(
      (item) => item.dayId === (todayDayId === 0 ? 7 : todayDayId)
    );
    const slots = todaySlot ? [todaySlot] : slotRanges;

    setBusinessHours(
      slots.map((item) => ({
        daysOfWeek: [item.dayId === 7 ? 0 : item.dayId],
        startTime: convertSecondsToTime(item.startDate),
        endTime: convertSecondsToTime(item.endDate),
      }))
    );
  }, [slotRanges]);

  const fetchAllSchedule = (startVal) => {
    const startDate = moment
      .utc(startVal)
      .startOf("day")
      .format();
    const endDate = moment
      .utc(startVal)
      .endOf("day")
      .format();

    dispatch(
      GetDetailForBookAppointment({ startDate, endDate, token: props.id })
    );
  };

  const handleDateChange = (date) => {
    if (!moment(date).isSame(moment(todayDate), "day")) {
      setTodayDate(date);
      fetchAllSchedule(date);
    }
  };

  const onChange = (value) => {
    const date = value.format("YYYY-MM-DD");
    handleDateChange(date);
    calendarRef.current.getApi().gotoDate(new Date(value.format("YYYY-MM-DD")));
    if (isPanelChange) {
      setIsCalendarOpen(true);
      isPanelChange = false;
    } else {
      setIsCalendarOpen(false);
    }
  };

  function handleSelect(info) {
    setSelectedStartTime(info.startStr.slice(0, 16).replace("T", " "));
    setSelectedEndTime(info.end);
    dispatch(setmodal(true));
  }

  const durationInMinutes =
    selectedStartTime && selectedEndTime
      ? moment
          .duration(moment(selectedEndTime).diff(moment(selectedStartTime)))
          .asMinutes()
      : null;

  const selectAllow = (selectInfo) => {
    if (!businessHours.length) return true;

    const start = moment(selectInfo.start);
    const end = moment(selectInfo.end);

    const isWithinBusinessHours = (time, businessHours) => {
      return businessHours.some((hours) => {
        const startTime = moment(time).set({
          hour: parseInt(hours.startTime.split(":")[0], 10),
          minute: parseInt(hours.startTime.split(":")[1], 10),
        });
        const endTime = moment(time).set({
          hour: parseInt(hours.endTime.split(":")[0], 10),
          minute: parseInt(hours.endTime.split(":")[1], 10),
        });
        return time.isBetween(startTime, endTime, null, "[)");
      });
    };

    return (
      isWithinBusinessHours(start, businessHours) &&
      isWithinBusinessHours(end, businessHours)
    );
  };

  return (
    <>
      <EventDetail />
      <div className={`schedulerCalender ${calenderView}`}>
        <FullCalendar
          selectAllow={selectAllow}
          select={handleSelect}
          ref={calendarRef}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          businessHours={businessHours}
          customButtons={{
            myCustomButton: {
              text: "",
              click: () => {
                setIsCalendarOpen(!isCalendarOpen);
              },
            },
            next: {
              text: "Next",
              click: function(value) {
                calendarRef.current.getApi().next();
                const date = moment(
                  calendarRef.current.getApi().getDate()
                ).format("YYYY-MM-DD");
                handleDateChange(date);
                // handleDateChange(date);
              },
            },
            prev: {
              text: "Prev",
              click: function(value) {
                calendarRef.current.getApi().prev();
                const date = moment(
                  calendarRef.current.getApi().getDate()
                ).format("YYYY-MM-DD");
                handleDateChange(date);
              },
            },
          }}
          headerToolbar={
            feed
              ? { start: "title myCustomButton" }
              : {
                  left: "today",
                  center: "title,myCustomButton",
                  right: "prev next",
                }
          }
          eventClick={(info) => {}}
          datesSet={(val) => {
            if (val.view.type !== "timeGridDay") {
              setCalendarView(val.view.type);
            } else {
              setCalendarView("");
            }
          }}
          selectable={userData === null ? false : true}
          dayHeaders={true}
          allDaySlot={true}
          allDayText={"All Day"}
          dayMaxEventRows={true}
          editable={userData === null ? false : true}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={!feed ? "timeGridDay" : "timeGridDay"}
          events={events}
          eventResize={() => {}}
          slotDuration={"00:15:00"}
          slotLabelFormat={{ hour: "numeric", minute: "numeric" }}
          views={{
            day: {
              type: "timeGrid",
              duration: { days: 1 },
              buttonText: "Day",
              eventMaxStack: 3,
            },
          }}
          slotEventOverlap={false}
          eventOverlap={false}
        />

        {durationInMinutes <= 60 ? (
          <SideDrawer
            title={"Book Appointment"}
            isOpen={modal}
            handleOpen={true}
            handleClose={() => dispatch(setmodal(false))}
          >
            <div className="drawer">
              <div className="drawer-title"></div>
              <div className="drawer-body">
                <CreateAppointment
                  selectedEndTime={selectedEndTime}
                  selectedStartTime={selectedStartTime}
                  durationInMinutes={durationInMinutes}
                  userData={userData}
                  setIsOTPModalVisible={() => {
                    dispatch(setmodal(false));
                    setIsOTPModalVisible(true);
                  }}
                  setConfirmationToken={setConfirmationToken}
                  slotCreator={
                    slotRanges.length > 0
                      ? slotRanges?.[0]?.createBy
                      : STRINGS.DEFAULTS.guid
                  }
                  setAppointmentData = {setAppointmentData}
                />
              </div>
            </div>
          </SideDrawer>
        ) : (
          message.warning("Please Select The 60 Min Or Less")
        )}

        <div className="flex justify-center">
          <div
            className={isCalendarOpen ? "site-calendar open" : "site-calendar "}
          >
            <Calendar
              fullscreen={false}
              onChange={onChange}
              onPanelChange={() => {
                isPanelChange = true;
              }}
            />
          </div>
        </div>
      </div>
      {isOTPModalVisible && (
        <OTPModal
          visible={isOTPModalVisible}
          onClose={() => {
            setIsOTPModalVisible(false);
          }}
          confirmationToken={confirmationToken}
          setIsSuccess = {setIsSuccess}
        />
      )}
      
    </>
  );
}

export default SchedulersComponent;
