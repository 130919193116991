import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
    getAllNonMandatoryApprovalAction,
    addNonMandatoryApprovalAction,
} from './action';

const initialState = {
  data: null,
  loadingData: false,
  loader: true,
  newItem: null,

};

const nonMandatoryApprovalSlice = createSlice({
  name: 'nonMandatoryApproval',
  initialState,
  initialState,
  reducers: {
    handleApproversDelete: (state, { payload }) => {
      state.approversData = state.approversData.filter(
        (item) => item.id !== payload
      );
    },
    handleNewItem: (state, { payload }) => {
      
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [...state.data[identifier] , ...state.newItem];
        state.newItem = null
      },
      handleRemoveItem : (state , {payload})=>{
        const { identifier } = payload;

    const removeData = state.data[identifier].filter((x) => x.id !== payload?.id);
    state.data= {
       ...state.data,
       [identifier] : removeData
    }


      }
    
  },

  extraReducers: (builder) => {
    builder
    .addCase(getAllNonMandatoryApprovalAction.fulfilled, (state, action) => {
      const {identifier } = action?.meta?.arg;
      const data = action.payload ?? [];
      console.log(state.data ,"dddddfffff");
      console.log(action ,"action");
      // state.loadingData = false;
      // state.approversData = payload;
      
      // state.loader = false;
      state.data = {
        ...state.data,
        [identifier]: data,
      };
    })
    .addCase(addNonMandatoryApprovalAction.fulfilled, (state, action) => {
        console.log(action.payload, "addDefaultApproversAction Slice");
        // state.approversData = [...state.approversData, ...action.payload];
        // state.loadingData = false;
        // state.loader = false;
        state.newItem = action?.payload

      })
      .addMatcher(
        isPending(...[getAllNonMandatoryApprovalAction, addNonMandatoryApprovalAction]),
        (state) => {
          console.log("pending");
          state.loader = true;
          state.loadingData = true;
        }
      )
      .addMatcher(
        isRejected(
          ...[getAllNonMandatoryApprovalAction, addNonMandatoryApprovalAction]
        ),
        (state) => {
          state.loader = false;
          state.loadingData = false;
        }
      );
  },
});

export const { handleApproversDelete ,handleNewItem , handleRemoveItem } = nonMandatoryApprovalSlice.actions;
export default nonMandatoryApprovalSlice.reducer;

