import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { defaultUiid } from "../../../../../utils/Shared/enums/enums";
import { saveApprovalsRemarks } from "../services";
import ApprovalBody from "./ApprovalBody";
import Header from "./Header";
import RemarkFooter from "./RemarkComposer";
import { ApprovalStatus, ApproverType } from "../enums/index";
import { handleNewApprovalRemark } from "../../../../features/approval/store/action";
import { useDispatch } from "react-redux";
import { STRINGS } from "../../../../../utils/base";
import { showReminderOnHover } from "../action/slice";
import { handleRemoveFromApprovalList, removeApprovalItem } from "../../../../features/approval/store/slice";
import { useLocation } from "react-router-dom";
const { Panel } = Collapse;
function Approval({
  reference=STRINGS.DEFAULTS.guid,
  approver,
  initialRemarks,
  status = ApprovalStatus.InProcess,
  approverId,
  module,
  createBy,
  onStatusChange,
  id,
  approverType = ApproverType.User,
  title = "",
  itemCreator={},
  lastReminder,
  data,
   reminderCount,
   itemDetail={},
   isLevelApproved
}) {
  const approverDetail = approver ? approver : {};
  const {
    businessId,
    designation,
    name,
    image,
    type,
    attachments,
    id : approversId,

  
    
  } = approverDetail;
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch()
  const location = useLocation();
  const isMyApprovals = location.pathname.includes("myApprovals");
  const isApprovals = location.pathname.includes("approvals");

  const [remarks, setRemarks] = useState([]);
  const [remarksText, setremarksText] = useState("");
  const [isRemarkAdded , setIsRemarkAdded] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(status);
  let prevStatus = currentStatus;
  const handleFile = (e) => {
    if (e.target.files.length > 1) {
      setFiles((prevValue) => [...prevValue, ...e.target.files]);
    } else {
      setFiles((prevValue) => [...prevValue, e.target.files[0]]);
    }
  };

  const handleDelete = (deleteFile) => {
    const allFiles = files.filter((file, index) => index !== deleteFile);
    setFiles(allFiles);
  };
  const handleRemarksText = (value, event) => {
    setremarksText(value);  
    if (event?.keyCode === 13) 
    {
      if(status === ApprovalStatus.Cancelled || status === ApprovalStatus.Declined) createRemark(ApprovalStatus.Resend);
      else if(status === ApprovalStatus.Resend) createRemark(ApprovalStatus.InProcess)
      else createRemark(status)
      
    }
  };
  const handleCurrentStatus = (status , payload) => {
    setIsRemarkAdded(true)
    prevStatus = currentStatus;
    setCurrentStatus(status);
    createRemark(status, payload);
  };  

  const createRemark = async (status , payload) => {
  
    const remarks = payload ?  {
      'expense.description':payload.description,
      'expense.amount':payload.amount,
      'expense.attachments': payload.attachments.map(({ id, file }) => ({
        id,
        file
      })),
      'travelAttachments': payload.attachments,
      approvalId: id,
      remark: remarksText,
      module,
      status,
      type: approverType,
      attachments: [...files].map((file) => {
        return { id: defaultUiid, file };
      }),
    } : {
      approvalId: id,
      remark: remarksText,
      module,
      status,
      type: approverType,
      attachments: [...files].map((file) => {
        return { id: defaultUiid, file };
      }),
    } 
    // const remarks = {
    //   approvalId: id,
    //   remark: remarksText,
    //   module,
    //   status,
    //   type: approverType,
    //   attachments: [...files].map((file) => {
    //     return { id: defaultUiid, file };
    //   }),
    // };
    setremarksText("");

    const remark = await saveApprovalsRemarks(remarks)
    if (remark) {
      setIsRemarkAdded(false)
      setRemarks((prevValue) => [...prevValue, remark]);
      isApprovals && dispatch(removeApprovalItem({ id:id,remarks,isMyApprovals}))
      // onStatusChange({ id, status });
      setFiles([]);

      let data = {
        referenceId:reference,
        module:module,
        remark:remark

    }
    dispatch(handleNewApprovalRemark(data));
    dispatch(handleRemoveFromApprovalList(remark))

    } else {
      setCurrentStatus(prevStatus);
      onStatusChange({ id, status });
    }
  };
  useEffect(() => {
    setRemarks([...initialRemarks]);
    // onListStatus({ id, status });
  }, [initialRemarks]);

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);
console.log(id ,"approverDetailapproverDetail");
  return (
    <Collapse
      className="approvalCollapse approvalMargin"
      expandIconPosition={"right"}
      key={businessId}
    >
      <Panel
      onMouseEnter={(e)=> dispatch(showReminderOnHover({id , data : true}))}
      onMouseLeave={(e)=>dispatch(showReminderOnHover({id : "" , data : false}))}

        extra={null}
        header={
          <Header
          reference={reference}
          reminderCount={reminderCount}
          lastReminder={lastReminder}
          approvalId ={id}
          module={module}
            status={currentStatus}
            type={type}
            user={{
              name,
              designation,
              image,
              approversId,
            }}
          ></Header>
        }
      >
        <ApprovalBody remarks={remarks}  />
        <RemarkFooter
        isLevelApproved={isLevelApproved}
          isRemarkAdded={isRemarkAdded}
          module={module}
          itemCreator={itemCreator}
          approverId={approverId}
          onCurrentStatus={handleCurrentStatus}
          onRemarksText={handleRemarksText}
          files={files}
          onFile={handleFile}
          onDelete={handleDelete}
          createBy={createBy}
          status={currentStatus}
          value={remarksText}
          approversId={approversId} 
        />
      </Panel>
    </Collapse>
  );
}

export default Approval;
