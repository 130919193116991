export const ComplainFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
}

export const complainTableSort = {
    referenceNo: { descend: ComplainFilterSortEnum.ReferenceNoDesc, ascend: ComplainFilterSortEnum.ReferenceNo },
    createDate: { descend: ComplainFilterSortEnum.CreateDateDesc, ascend: ComplainFilterSortEnum.CreateDateAsc },
    category: { descend: ComplainFilterSortEnum.CategoryDesc, ascend: ComplainFilterSortEnum.Category },
}