import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";

const hindi = {
    approvalDictionaryLabel: {
        Approval: "मंजूरी",
        [ApprovalsModuleEnum.ExpenseExecutor]: "कर्मचारी",
        [ApprovalsModuleEnum.ExpenseFinance]: "वित्त",
        [ApprovalsModuleEnum.ResignationHrApproval]: "मानव संसाधन",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "वित्त",
        [ApprovalsModuleEnum.ResignationItApproval]: "आईटी",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "प्रशासन",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "अन्य मंजूरियां",
        [ApprovalsModuleEnum.ResignationExitApproval]: "निर्गमन साक्षात्कार",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "रिपोर्टिंग टू",
        [ApprovalsModuleEnum.TravelApproval]: "मंजूर करने वाले",
        [ApprovalsModuleEnum.TravelAgent]: "एजेंट",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "अंतिम मंजूरी देने वाले",
    },
    approvalDictionaryPlaceHolder: {
        Approval: "मंजूरी",
        [ApprovalsModuleEnum.ExpenseExecutor]: "खर्च करने वाले का चयन करें",
        [ApprovalsModuleEnum.ExpenseFinance]: "वित्त से मंजूर करने वाले का चयन करें",
        [ApprovalsModuleEnum.ExpenseApproval]: "खर्च करने वाले का चयन करें",
        [ApprovalsModuleEnum.ResignationHrApproval]: "मानव संसाधन का चयन करें",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "वित्त का चयन करें",
        [ApprovalsModuleEnum.ResignationItApproval]: "आईटी का चयन करें",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "प्रशासन का चयन करें",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "अन्य मंजूरियों का चयन करें",
        [ApprovalsModuleEnum.ResignationExitApproval]: "निर्गमन साक्षात्कार का चयन करें",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "रिपोर्टिंग टू का चयन करें",
        [ApprovalsModuleEnum.TravelApproval]: "यात्रा के लिए मंजूरीदेनेवाले का चयन करें",
        [ApprovalsModuleEnum.TravelAgent]: "यात्रा व्यवस्थाओं के लिए एजेंट का चयन करें",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "अंतिम मंजूरीदेनेवाले का चयन करें",
    },
    Direction: 'rtl',
};

export default hindi;