import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ScheduleMemberType,
} from "../../enum/enum";
import {
  AddScheduleConferenceCallAction,
  addScheduleExternalMemberAction,
  addScheduleMemberAction,
  getScheduleById,
  updateMemberScheduleStatus,
  updateScheduleMemberType,
} from "../../store/action";
import EventDetail from "../../UI/EventDetail";
import Event from "../event";
import "../../styles/styleComposerDetails.css";

import ScheduleMembersList from "../Composer/ScheduleMembersList";
import ScheduleDetailSkeleton from "./ScheduleDetailSkeleton";
import { Button, Rate } from "antd";
import UpdateSchedule from "./UpdateSchedule";
import Attachments from "../../../travel/view/UI/Attachments";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import { VideoCameraOutlined } from "@ant-design/icons";
import { Form, Select } from "antd";
import CustomModal from "../../../workboard/Modal/CustomModal";
import { servicesUrls } from "../../../../../utils/services/baseURLS";
import { handleAddCallWindow } from "../../../calling/store/slice";
import { responseCode as Codes } from "../../../../../services/enums/responseCode";
import Comments from "../../../Comment/comments";
import { CommentModuleEnum } from "../../../Comment/utils/enum/commentModuleEnum";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { readNotificationCounter } from "../../store/slice";
import ReferenceTypeTag from "../../UI/ReferenceTypeTag";
import MinutesOfMeetings from "../../UI/MinutesOfMeetings";
import ScheduleMeetingBtn from "../../UI/ScheduleMeetingBtn";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { ScheduleTypeEnum } from "../../utils/ScheduleTypeEnum";
import { getInterviewDetailAction } from "../../../careers/store/action";
import InterviewRatingQuestionWrapper from "../../../careers/view/UI/InterviewRatingQuestionWrapper";
import moment from "moment";
import MeetingTranscript from "../../UI/meetingTranscript";
import AiGeneratedMintueOfMeetingSummary from "../../UI/AiGeneratedMintueOfMeetingSummary";

function ScheduleComposerDetail({ id, shortEvent = true, onClose = () => { } }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule, sharedLabels } = dictionaryList[userLanguage];
  const eventDetail = useSelector((state) => state?.scheduleSlice?.eventDetail);
  const loading = useSelector((state) => state?.scheduleSlice?.loading);
  const loggedInUserId = useSelector((state) => state?.userSlice?.user?.id);
  const [isActionEnabled, setIsActionEnabled] = useState(false);
  const [editSchedule, setEditSchedule] = useState(false);
  const [MemeberData, setMemberData] = useState(eventDetail?.members);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [externalMemberModal, setExternalMemberModal] = useState(false);
  const userId = useSelector((state) => state.userSlice.user.id);
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.userSlice);
  const [isLoadingDone, setIsLoadingDone] = useState(false)
  const [isInterviewDetail , setisInterviewDetail ] = useState({
    isOpen :false,
    id : null
  })

  const [interviewData , setInterviewData] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScheduleById(id)).then(response => {
      setIsLoadingDone(true)
    });

    dispatch(getInterviewDetailAction(id)).then(response =>{
        setisInterviewDetail({
          isOpen:true,
          id:response?.payload?.userId
        })
        setInterviewData(response?.payload)
    })

    return () => {
      setIsLoadingDone(false)
    }
  }, [id]);

  useEffect(() => {
    const payload = {
      filter: {
        featureType: NotificationFeatureTypeEnum.Schedule,
        featureId: id
      },
    }
    eventDetail?.notificationCount > 0 && dispatch(readNotificationAction(payload)).then(response => {
      dispatch(readNotificationCounter(id))
    })
  }, [eventDetail])

  useEffect(() => {
    if (eventDetail && Object.keys(eventDetail)?.length > 0) {
      for (let index = 0; index < eventDetail?.members?.length; index++) {
        if (
          eventDetail?.members[index].memberType ===
          ScheduleMemberType?.Admin &&
          loggedInUserId === eventDetail?.members[index]?.memberId
        ) {
          setIsActionEnabled(true);
        }
      }
    }
    setMemberData(eventDetail?.members);
  }, [eventDetail]);
  const handleMemberStatusChange = (id, status) => {
    dispatch(
      updateMemberScheduleStatus({
        id,
        status,
      })
    );
  };
  const handleMemberTypeStatusChange = (id, type) => {
    dispatch(
      updateScheduleMemberType({
        id,
        type,
      })
    );
  };
  const handleEditSchedule = () => {
    setEditSchedule(!editSchedule);
  };

  const onClosePop = (da) => {
    setisModalOpen(da);
    dispatch(getScheduleById(id));
  };

  const handleExternalMember = () => {
    setExternalMemberModal(!externalMemberModal);
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmails = (_, value) => {
    if (Array.isArray(value)) {
      for (let email of value) {
        if (!emailRegex.test(email)) {
          return Promise.reject("Invalid email format");
        }
      }
    }
    return Promise.resolve();
  };

  const addFunc = (memberId) => {
    const payload = {
      data: [{ memberId: memberId[0], memberType: 2 }],
      id: eventDetail?.id,
    };
    if (eventDetail?.id && memberId && memberId[0]) {
      const promise = dispatch(addScheduleMemberAction(payload));
      promise.then((res) => {
        if (res?.payload?.data?.length > 0) {
          setMemberData([...MemeberData, res?.payload?.data[0]]);
        }
      });
    }
  };

  const onExternalMemeberAdded = (value) => {
    const payload = {
      data: value.externals,
      id: eventDetail?.id,
    };
    dispatch(addScheduleExternalMemberAction(payload)).then((res) => {
      if (res?.payload?.data?.length > 0) {
        setMemberData([...MemeberData, res?.payload?.data[0]]);
        dispatch(getScheduleById(id));
      }
    });

    setExternalMemberModal(false);
  };

  function checkMemberType() {
    const user = eventDetail?.members.find((user) => user?.memberId === userId);
    if (user && user?.memberType === ScheduleMemberType.Admin) {
      return true;
    }
    return false;
  }

  const instantCall = () => {
    onClose();
    dispatch(
      handleAddCallWindow({
        callUrl: `${servicesUrls.callingSocket}${eventDetail?.videoConferenceToken}?token=${user.accessToken}&isVideo=1`,
        isOpen: true,
      })
    );
  };
  const addWorkwiseCall = () => {
    let schId = id.toString();
    dispatch(AddScheduleConferenceCallAction(schId)).then((resp) => {
      const { data, responseCode } = resp?.payload;
      if (responseCode == Codes.Success) {
        // static value need to be removed
        dispatch(getScheduleById(id));
      }
    });
  };
  return (
    <>
      {isModalOpen && (
        <ItemDetailModal
          data={MemeberData} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={true} //Pass false to hide select member
          addFunc={addFunc} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={isModalOpen}
          setVisible={(data) => onClosePop(data)}
        />
      )}
      {loading ? (
        <ScheduleDetailSkeleton />
      ) : (
        <>
          <div className={`eventDetail ${!shortEvent && ""}`}>
            {shortEvent && (
              <div className="eventDetail__header">
                <p className="eventDetail-title">{sharedLabels.Details}</p>
                <Button className="ThemeBtn" onClick={handleEditSchedule}>
                  {sharedLabels.Update}
                </Button>
              </div>
            )}
            <div className="eventDetail__body">
              <div className="eventDetail__body-event">
                {shortEvent ? (
                  <Event shortDesc={true} data={eventDetail} />
                ) : (
                  <EventDetail data={eventDetail} UpdateScheduleBtn={<Button className="ThemeBtn" onClick={handleEditSchedule}>
                    {schedule.UpdateSchedule}
                  </Button>} />
                  
                )}
                
              </div>
              {!shortEvent && (
                <div className="eventDetail__body-description">
                     
                  <div className="eventDescription-header flex items-center justify-between">
                    <p className="eventDetail-title">{sharedLabels.Description}</p>
                    <div className="scheduleDetail-tagContainer flex ">
                      <span className="refernceNo">{eventDetail?.referenceNo}</span>
                      {!shortEvent && <MinutesOfMeetings data={eventDetail} />}
                      <ReferenceTypeTag referenceId={eventDetail?.referenceId} referenceType={eventDetail?.referenceType} reference={eventDetail?.reference} />
                    </div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail?.description,
                    }}
                    className="break-words dangerous_element_detail"
                  />
                  {/* <div className="flex justify-end"> 
                    <AiGeneratedMintueOfMeetingSummary  />
                  </div> */}
                </div>

              )}
              <div className="flex flex-row justify-between">
                 {eventDetail?.startMeeting && !eventDetail?.endMeeting && <div className="flex !w-[190px] refernceNo">
                  Meeting In Progress
                </div>}
                <div className="flex w-full justify-end">
                <MeetingTranscript eventDetail = {eventDetail}/>
                <AiGeneratedMintueOfMeetingSummary eventDetail={eventDetail}/>
                </div>
                </div>

            </div>
            {eventDetail?.attachments?.length > 0 ? (
              <>
                <div className="eventDetail-title">{sharedLabels.Attachments}</div>
                <div className="!w-max m-4">
                  <Attachments
                    data={eventDetail?.attachments}
                    key={{ data: eventDetail?.attachments }}
                    toShow={1}
                    onClick={() => { }}
                    size={"50px"}
                  />
                </div>
              </>
            ) : null}
            {(eventDetail?.startMeeting === null || eventDetail?.endMeeting === null) &&
             moment.utc().isBefore(moment.utc(eventDetail?.endDate)) &&
             <div className="myDiv gap-[15px]">
             {eventDetail && (
               <>
                 <div className=" flex flex-1 gap-[15px]">
                   {eventDetail.onVideoConference ? (
                     <Button
                       type="primary"
                       className="ThemeBtn"
                       onClick={instantCall}
                       style={{ display: "flex", alignItems: "center" }}
                     >
                       <VideoCameraOutlined />
                       <span>{schedule.JoinWorkwiseCall}</span>
                     </Button>
                   ) : (
                     checkMemberType() && (
                       <Button
                         type="primary"
                         className="ThemeBtn"
                         onClick={addWorkwiseCall}
                         style={{ display: "flex", alignItems: "center" }}
                       >
                         <VideoCameraOutlined />
                         <span>{schedule.AddWorkwiseCall}</span>
                       </Button>
                     )
                   )}
                   <ScheduleMeetingBtn
                     data={eventDetail}
                     isActionEnabled={isActionEnabled}
                   />
                 </div>

                 {checkMemberType() && (
                   <div className=" flex flex-1 gap-[15px]">
                     <Button
                       className="ThemeBtn"
                       onClick={(e) => {
                         e.preventDefault();
                         e.stopPropagation();
                         setisModalOpen(true);
                       }}
                     >
                       {sharedLabels.AddMembers}
                     </Button>
                     <Button
                       className="ThemeBtn"
                       onClick={(e) => {
                         e.preventDefault();
                         e.stopPropagation();
                         setExternalMemberModal(true);
                       }}
                     >
                       {sharedLabels.AddExternalMember}
                     </Button>
                   </div>
                 )}
               </>
             )}
           </div>
            }
            {eventDetail?.scheduleType === ScheduleTypeEnum?.Interview && (<>
              <div className="text-[16px] font-semibold mt-2">Criteria</div>
                  <InterviewRatingQuestionWrapper
                        data={interviewData}
                        isAvg={true}
                  />
              </>)}

            <div className="eventDetail-title flex justify-between items-baseline">
              <div>{sharedLabels.members}</div>
            </div>
            {eventDetail?.members?.map((member, index) => (
              <ScheduleMembersList
                status={member?.status}
                id={member?.id}
                data={member?.member}
                memberType={member?.memberType}
                isActionEnabled={isActionEnabled}
                handleMemberStatusChange={handleMemberStatusChange}
                handleMemberTypeStatusChange={handleMemberTypeStatusChange}
                creator={eventDetail?.creator}
                index = {index}
                scheduleType = {eventDetail?.scheduleType}
                interviewQuestions={
                  <>
                    {eventDetail?.scheduleType ===
                    ScheduleTypeEnum?.Interview ? (
                      <InterviewRatingQuestionWrapper
                        data={interviewData}
                        memberId={member?.memberId}
                        setInterviewData = {setInterviewData}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            ))}

          </div>
          {
              isInterviewDetail.isOpen && isInterviewDetail.id !== null && eventDetail?.scheduleType === ScheduleTypeEnum.Interview && 
                <Comments
                referenceId={isInterviewDetail.id}
                module={CommentModuleEnum.CareerApplicant}
              />

          }
          {
            eventDetail?.scheduleType !== ScheduleTypeEnum.Interview && isLoadingDone &&
            <Comments
              referenceId={id}
              module={CommentModuleEnum.Schedule}
              referenceType={eventDetail?.referenceType}
              mentionMemberRefId = {eventDetail?.referenceId}
            />
          }
        </>
      )}
      <UpdateSchedule
        eventDetail={eventDetail}
        handleEditSchedule={handleEditSchedule}
        isOpen={editSchedule}
      />

      <CustomModal
        footer={null}
        isModalVisible={externalMemberModal}
        centered={true}
        onCancel={handleExternalMember}
        destroyOnClose={true}
        closable={false}
        children={
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              externals: [],
            }}
            onFinish={onExternalMemeberAdded}
          >
            <Form.Item
              name={sharedLabels.externals}
              label={sharedLabels.ExternalMembers}
              rules={[
                { required: true, message: sharedLabels.Pleaseenterexternalmembers },
                { validator: validateEmails },
              ]}
            >
              <Select
                mode="tags"
                dropdownClassName="hidden"
                placeholder={sharedLabels.EnterEmailAddress}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                className="ThemeBtn"
                block
                htmlType="submit"
              >
                {sharedLabels.add}
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </>
  );
}

export default ScheduleComposerDetail;
