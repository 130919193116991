import MasterConfig from "../../../../utils/services/MasterConfig";

export const BusinessNumberAuthServices = () => {
  return MasterConfig.get(`api/User/BusinessNumberAuth`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const addContactNumberService = ({payload}) => {
  return MasterConfig.post(`/api/BusinessNumberContact/AddBusinessNumberContact`,payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const getAllBusinessNumberContactService = (payload) => {
  return MasterConfig.post(`/api/BusinessNumberContact/GetAllBusinessNumberContact`,payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
