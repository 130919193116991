import MasterConfig from "../../../../utils/services/MasterConfig";
export const getAllContractPurposeService = () => {
	return MasterConfig.get(`api/ContractPurpose/GetAllContractPurpose`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addContractPurposeService = args => {
	return MasterConfig.post(`api/ContractPurpose/AddContractPurpose`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const updateContractCategoryService = args => {
	return MasterConfig.put(`api/ContractPurpose/UpdateContractPurpose`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const removeContractPurposeService = id => {
	return MasterConfig.delete(`api/ContractPurpose/RemoveContractPurpose?id=${id}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
