import React, { useContext } from "react";
import moment from "moment";
import { Tooltip } from "antd";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";

const SubTaskDateRangeDisplay = ({ startDate, endDate, onClick, progress, className }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const start = moment(startDate);
  const end = moment(endDate);
  const today = moment();
  const formattedStartDate = start.calendar(null, {
    sameDay: `[${sharedLabels.Today}]`,
    lastDay: "MMM D",
    lastWeek: "MMM D",
    nextWeek: "MMM D",
    nextDay: "MMM D",
    sameElse: "MMM D",
    // sameElse: function(now) {
    //   return this.isSame(now, "year") ? "MMM D" : "MMM D, YYYY";
    // },
  });

  const formattedEndDate = end.calendar(null, {
    sameDay: `[${sharedLabels.Today}]`,
    lastDay: "MMM D",
    lastWeek: "MMM D",
    nextWeek: "MMM D",
    nextDay: "MMM D",
    sameElse: "MMM D",
    // sameElse: function(now) {
    //   return this.isSame(now, "year") ? "MMM D" : "MMM D, YYYY";
    // },
  });

  let placeholder;
  if (progress === 100) { placeholder = "" }
  else if (end.isSame(today, "day")) {
    placeholder = tasks.DueToday;
  } else if (end.isSame(today.clone().add(1, tasks.days), tasks.days)) {
    placeholder = tasks.DueTomorrow;
  } else if (end.isBefore(today)) {
    placeholder = tasks.Overdue;
  } else {
    placeholder = `${tasks.Duein} ${end.diff(today, tasks.days)} ${tasks.days}`;
  }

  return (
    <Tooltip title={placeholder}>
      <span className={`cursor-pointer ${className}`} onClick={onClick}>
        {formattedStartDate} - {formattedEndDate}
      </span>
    </Tooltip>
  );
};

export default SubTaskDateRangeDisplay;
