import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseCode } from '../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';
import {
  addRoomService,
  getAllRoomesService,
  removeRoomService,
  updateRoomService,
} from '../service/service';
import { message } from 'antd';

export const getAllRoom = createAsyncThunk(
  'room/getAllRoom',
  async (args, { dispatch }) => {
    const res = await getAllRoomesService();
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addRoom = createAsyncThunk(
  'room/addRoom',
  async (args, { dispatch }) => {
    const res = await addRoomService(args);
    console.log(res);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Room added successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const updateRoom = createAsyncThunk(
  'room/updateRoom',
  async (args, { dispatch }) => {
    const res = await updateRoomService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Room updated successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const removeRoom = createAsyncThunk(
  'room/removeRoom',
  async (args, { dispatch }) => {
    const res = await removeRoomService(args.id);

    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Room removed successfully!';
      message.success(res.message);

      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);
