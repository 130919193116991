import { ApprovalsModule } from "../enums/index";
import { FeaturesEnum } from "../../../../../../src/utils/Shared/enums/enums";
import { ApprovalsModuleEnum } from "../../../../features/approval/utils/enum/approvalModuleEnum";
import { handleExpenseReminder } from "../../../../features/expense/store/slice";
import { useDispatch } from "react-redux";
import { handleCustomApprovalReminder } from "../../../../features/CustomApprovals/store/slice";
import { handleLeaveReminder } from "../../../../features/leave/store/slice";
import { handleTravelReminder } from "../../../../features/travel/store/slice";
import { handleWarningReminder } from "../../../../features/warning/store/slice";
import { handleComplainReminder } from "../../../../features/complain/store/slice";
import { handleRewardReminder } from "../../../../features/reward/store/slice";
import { handleBonusReminder } from "../../../../features/bonus/store/slice";
import { handlePromotionReminder } from "../../../../features/promotion/store/slice";
import { handleLoanReminder } from "../../../../features/loan/store/slice";
import { handleFormReminder } from "../../../../features/forms/store/slice";
import { handleDocumentsReminder } from "../../../../features/documents/store/slice";
import { handleSalaryReminder } from "../../../../features/salary/store/slice";
import { handleCareerReminder } from "../../../../features/careers/store/slice";
import { handleQuotationReminder } from "../../../../features/quotation/store/slice";
import { handleAppraisalReminder } from "../../../../features/appraisalModule/store/slice";
import { handleAuctionReminder } from "../../../../features/auction/store/slice";
import { handleResignationReminder } from "../../../../features/resignation/store/slice";

export const getFeaturesTypeByApprovalsType = (APPROVALS_TYPE) => {
    console.log(FeaturesEnum, "documentType")

    switch (APPROVALS_TYPE) {
        case ApprovalsModule.ExpenseApproval:
            return FeaturesEnum.FEATURES_TYPE.Expense
        case ApprovalsModule.ExpenseExecutor:
            return FeaturesEnum.FEATURES_TYPE.Expense
        case ApprovalsModule.ExpenseFinance:
            return FeaturesEnum.FEATURES_TYPE.Expense
        case ApprovalsModule.LeaveApproval:
            return FeaturesEnum.FEATURES_TYPE.Leave
        case ApprovalsModule.TravelApproval:
            return FeaturesEnum.FEATURES_TYPE.Travel
        case ApprovalsModule.TravelAgent:
            return FeaturesEnum.FEATURES_TYPE.Travel
        case ApprovalsModule.WarningApproval:
            return FeaturesEnum.FEATURES_TYPE.Warnings
        case ApprovalsModule.ComplainApproval:
            return FeaturesEnum.FEATURES_TYPE.Complains
        case ApprovalsModule.RewardApproval:
            return FeaturesEnum.FEATURES_TYPE.Rewards
        case ApprovalsModule.BonusApproval:
            return FeaturesEnum.FEATURES_TYPE.Bonus
        case ApprovalsModule.PromotionApproval:
            return FeaturesEnum.FEATURES_TYPE.Promotion
        case ApprovalsModule.LoanApproval:
            return FeaturesEnum.FEATURES_TYPE.Loan
        case ApprovalsModule.CustomApproval:
            return FeaturesEnum.FEATURES_TYPE.CustomApproval
        case ApprovalsModule.DocumentApproval:
            return FeaturesEnum.FEATURES_TYPE.Document
        case ApprovalsModule.PayrollApproval:
            return FeaturesEnum.FEATURES_TYPE.Payroll
        case ApprovalsModule.CareerApproval:
            return FeaturesEnum.FEATURES_TYPE.Career
        case ApprovalsModule.RequisitionApproval:
            return FeaturesEnum.FEATURES_TYPE.Requisition
        case ApprovalsModule.RequisitionFinalApproval:
            return FeaturesEnum.FEATURES_TYPE.Requisition
        case ApprovalsModule.AssetApproval:
            return FeaturesEnum.FEATURES_TYPE.Asset

        default:
            //return defaultImage
            break;
    }
}

export const getfunctionForReminderApproval = (module, payload, dispatch) => {
    let dispatchFunction = () => { }
    switch (module) {
        case ApprovalsModuleEnum.ExpenseApproval:
        case ApprovalsModuleEnum.ExpenseExecutor:
        case ApprovalsModuleEnum.ExpenseFinance:
            dispatchFunction = () => dispatch(handleExpenseReminder(payload));
            break;
        case ApprovalsModuleEnum.CustomApproval:
            dispatchFunction = () => dispatch(handleCustomApprovalReminder(payload));
            break;
        case ApprovalsModuleEnum.LeaveApproval:
            dispatchFunction = () => dispatch(handleLeaveReminder(payload));
            break;
        case ApprovalsModuleEnum.TravelApproval:
        case ApprovalsModuleEnum.TravelAgent:
            dispatchFunction = () => dispatch(handleTravelReminder(payload));
            break;
        case ApprovalsModuleEnum.WarningApproval:
            dispatchFunction = () => dispatch(handleWarningReminder(payload));
            break;
        case ApprovalsModuleEnum.ComplainApproval:
            dispatchFunction = () => dispatch(handleComplainReminder(payload));
            break;
        case ApprovalsModuleEnum.RewardApproval:
            dispatchFunction = () => dispatch(handleRewardReminder(payload));
            break;
        case ApprovalsModuleEnum.BonusApproval:
            dispatchFunction = () => dispatch(handleBonusReminder(payload));
            break;
        case ApprovalsModuleEnum.PromotionApproval:
            dispatchFunction = () => dispatch(handlePromotionReminder(payload));
            break;
        case ApprovalsModuleEnum.LoanApproval:
            dispatchFunction = () => dispatch(handleLoanReminder(payload));
            break;
        case ApprovalsModuleEnum.FormApproval:
            dispatchFunction = () => dispatch(handleFormReminder(payload));
            break;
        case ApprovalsModuleEnum.DocumentApproval:
            dispatchFunction = () => dispatch(handleDocumentsReminder(payload));
            break;
        case ApprovalsModuleEnum.SalaryApproval:
            dispatchFunction = () => dispatch(handleSalaryReminder(payload));
            break;
        case ApprovalsModuleEnum.CareerApproval:
            dispatchFunction = () => dispatch(handleCareerReminder(payload));
            break;
        case ApprovalsModuleEnum.QuotationApproval:
            dispatchFunction = () => dispatch(handleQuotationReminder(payload));
            break;
        case ApprovalsModuleEnum.AppraisalApproval:
            dispatchFunction = () => dispatch(handleAppraisalReminder(payload));
            break;
        case ApprovalsModuleEnum.AuctionApproval:
            dispatchFunction = () => dispatch(handleAuctionReminder(payload));
            break;
        case ApprovalsModuleEnum.ResignationReportingToApproval:
        case ApprovalsModuleEnum.ResignationFinanceApproval:
        case ApprovalsModuleEnum.ResignationItApproval:
        case ApprovalsModuleEnum.ResignationExitApproval:
        case ApprovalsModuleEnum.ResignationHrApproval:
        case ApprovalsModuleEnum.ResignationAdminApproval:


            dispatchFunction = () => dispatch(handleResignationReminder(payload));
            break;
        default:
            break;
    }

    return dispatchFunction
}


// salary,
// document