import React, { useEffect, useState } from "react";
import { DirectionEnum } from "../../../../../../utils/Shared/enums/directionEnum";
import { PannelTypeEnum } from "../../../../fullScreenModal/enums/enum";
import FullScreenModalWrapper from "../../../../fullScreenModal/view/fullScreenModalWrapper";
import moment from "moment";
import { CommentModuleEnum } from "../../../../Comment/utils/enum/commentModuleEnum";
import { NotificationFeatureTypeEnum } from "../../../../../features/notifiation/enums";
import { readNotificationAction } from "../../../../notifiation/store/action";
import { readCountforSalary } from "../../../../salary/store/slice";
import { useDispatch } from "react-redux";
import { readCountforPages } from "../../../store/slice";


function PagesFullScreenModal({
  fullScreenModalOpen = false,
  onCancel = () => {},
  data,
}) {

  console.log("wwwwwwwwwwwwwwww", data);
  const dispatch = useDispatch();

  const [fullScreenModalState, setFullScreenModalState] = useState([
    {
      direction: DirectionEnum.Left,
      data: [{ panelType: PannelTypeEnum.Swipper, data: [] }],
    },
    {
      direction: DirectionEnum.Right,
      data: [{ panelType: PannelTypeEnum.Comment, data: [] }],
    },
  ]);


  useEffect(()=>{
    if(data?.notificationCount > 0)
    {
      
      const payload = {
        filter : {
          featureType:NotificationFeatureTypeEnum.Pages,
          featureId:data?.id
        },

      }

      dispatch(readNotificationAction(payload)).then(response=>{

        dispatch(readCountforPages(data?.id))
      })
    }
  },[data])

  useEffect(() => {
    if (data) {
      setFullScreenModalState([
        {
          direction: DirectionEnum.Left,
          data: [
            {
              panelType: PannelTypeEnum.Swipper,
              data: [
                {
                  attachmentId: data.attachmentId,
                  //documentType: data.documentType,
                  //extensionTypeId: data.extensionTypeId,
                   path: data?.path,
                  referenceId: data?.referenceId,
                  name: data.name,
                },
              ],
            },
          ],
        },

        {
          direction: DirectionEnum.Right,
          data: [
            {
              panelType: PannelTypeEnum.Comment,
              data: [
                {
                  referenceId:data?.id,
                  module: CommentModuleEnum.Page,
                  referenceType: data?.referenceType,
                  mentionMemberRefId : data?.referenceId
                },
              ],
            },
          ],
        },
      ]);
    }
  }, [data]);
  return (
    <>
      <FullScreenModalWrapper
        key={`${moment().unix()} ${fullScreenModalState[0]?.data[0]?.data[0]?.referenceId}`}
        data={fullScreenModalState}
        openModal={fullScreenModalOpen}
        onCancel={onCancel}
      />
    </>
  );
}
function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.fullScreenModalOpen === nextProps.fullScreenModalOpen &&
    JSON.stringify(prevProps.data?.id) === JSON.stringify(nextProps.data?.id)
  );
}
export default React.memo(PagesFullScreenModal, arePropsEqual);
