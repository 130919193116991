import MasterConfig from "../../../../utils/services/MasterConfig";

export const getAllBusinessApprovalService = (data) => {
    return MasterConfig.post(`api/Approval/GetAllBusinessApproval`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};