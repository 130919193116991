import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";
import { LoanStatusEnum } from "../../utils/LoanStatusEnum";

const StatusTag = ({ status = LoanStatusEnum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { loan } = dictionaryList[userLanguage];

  let color = ColorsEnum.Primary;
  switch (status) {
    case LoanStatusEnum.Approved:
      color = ColorsEnum.Success;
      break;
    case LoanStatusEnum.Declined:
      color = ColorsEnum.Danger;
      break;
    case LoanStatusEnum.Cancelled:
      color = ColorsEnum.Cancelled;
      break;
    case LoanStatusEnum.Disbursed:
      color = ColorsEnum.Disbursed;
      break;
    case LoanStatusEnum.PartialCleared:
      color = ColorsEnum.Primary;
      break;
    case LoanStatusEnum.Completed:
      color = ColorsEnum.Completed;
      break;
    case LoanStatusEnum.Hold:
      color = ColorsEnum.Hold;
      break;
    case LoanStatusEnum.WaitingForDisburse:
      color = ColorsEnum.WaitingForDisburse;
      break;

    default:
      color = ColorsEnum.Primary;
  }
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {loan.statusEnum[status]}
      </Tag>
    </>
  );
};

export default StatusTag;
