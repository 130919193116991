import MasterConfig from "../../../../utils/services/MasterConfig";

export const GetAllProjectIssuesService = (data) => {
    return MasterConfig.post(`api/Issues/GetAllIssues`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  

  export const addProjectIssuesService = async(data) => {
	return MasterConfig.post(`api/Issues/AddIssue`, data)
		.then(res => {
			return res;
		})
		.catch(res => {
			return res;
		});
    
};

export const AddIssueMemberByIdService = (data) => {
    return MasterConfig.post(`api/Issues/AddIssueMember?id=${data.id}` ,[ {memberId : data.memberId}])
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };

  export const UpdateStatusByIdService = (data) => {
    return MasterConfig.put(`api/Issues/UpdateIssueStatus?id=${data.id}&status=${data.status}`)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };
  
  export const RemoveIssueMemberByIdService = (data) => {
    return MasterConfig.post(`api/Issues/RemoveIssueMember?issueId=${data.id}`, [data.memberId])
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };
  