import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../../../utils/routes";
import ApprovalItem from "./approvalItem";
import "../style.css";
import { useDispatch } from "react-redux";
import { getAllApproval, getAllDefaultApproval } from "../../store/action";
import { useSelector } from "react-redux";
import { setApprovalStatus } from "../../../../../store/appReducer/responsiveSlice";
import { ApprovalStatus } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import OnScroll from "../../../appraisalModule/view/OnScroll";
import { handleResetState } from "../../store/slice";
import { Empty } from 'antd';
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";

export default function Approvals() {
  const dispatch = useDispatch();
  const defaultFilter = {
    pageNo: 1,
    pageSize: 10,
    modules: [],
    search: "",
    status: [ApprovalStatus.InProcess],
  };
  const [filter, setFilter] = useState(defaultFilter);
  const { approvalStatus } = useSelector((state) => state.responsiveSlice);
  const [state, setState] = useState({ isLoading: false, isNext: false });
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];

  const handleDClose = () => {

    setFilter({
      ...filter,
    });
    getAllApprovals(false)
  };

  const defaultApprovalList = useSelector(
    (state) => state.approvalSlice.defaultApprovalList
  );

  const getAllApprovals = (myApprovals) => {
    dispatch(getAllDefaultApproval({ filter: { ...filter, myApprovals } })).then(
      (res) => {
        setState({
          isLoading: false,
          isNext: res?.payload?.length === defaultFilter.pageSize,
        });
      }
    );
  };

  useEffect(() => {
    if (!defaultApprovalList.length || filter.pageNo > 1) {
      setState({ ...state, isLoading: true });
      getAllApprovals(false);
    }
  }, [filter]);

  function handleCloseApprovalModal(status = false) {
    dispatch(setApprovalStatus(false));
  }

  const handleRefresh = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let isMyApproval = true;
    setState({ ...state, isLoading: true });
    dispatch(handleResetState());
    dispatch(
      getAllDefaultApproval({ isMyApproval, filter: defaultFilter })
    ).then((res) => {
      setState({ isLoading: false, isNext: res?.payload?.length === 10 });
    });
  };

  return (
    <div className="approval_list_cont">
      <div className="approval_header">
        <div className="approval_header_child1">{sharedLabels.approvals}</div>
        <div className="approval_header_child2">
          <div onClick={handleRefresh}>{sharedLabels.Refresh}</div>
          <NavLink to={ROUTES.APPROVALS.DEFAULT}>
            <div onClick={handleCloseApprovalModal}>{sharedLabels.SeeAll}</div>
          </NavLink>
        </div>
      </div>
      <OnScroll
        isNext={state.isNext}
        loader={state?.isLoading}
        currentPage={filter?.pageNo}
        onPageChange={(page) => setFilter({ ...filter, pageNo: page })}
        parentDivClass="approval_list h-[500px]"
      >
        {!state.isLoading || filter.pageNo > 1 ? (
          <>
            {defaultApprovalList !== undefined &&
              defaultApprovalList.length > 0 ? (
              defaultApprovalList.map((item) => (
                <ApprovalItem
                  item={item}
                  onDClose={() => handleDClose()}
                />
              ))
            ) : (
              !state.isLoading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="h-[370px] flex justify-center items-center" />
            )}
          </>
        ) : (
          <></>
        )}
      </OnScroll>
    </div>
  );
}
