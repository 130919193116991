import { createAsyncThunk } from "@reduxjs/toolkit";

import { ResponseType } from "../../../../../../utils/api/ResponseResult";
import { openNotification } from "../../../../../../utils/Shared/store/slice";
import {
  addEmployeeSalaryService,
  getCurrentSalaryOfEmployeeService,
  getEmployeeSalaryService,
  getGradeAllowanceByIdServise,
} from "../service/service";

export const getCurrentSalaryOfEmployeeAction = createAsyncThunk(
  `InventoryAsset/GetInventoryAssetById`,
  async (id) => {
    const response = await getCurrentSalaryOfEmployeeService(id);
    return response.data;
  }
);

export const addEmployeeSalaryAction = createAsyncThunk(
  "employeeSalary/addEmployee",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await addEmployeeSalaryService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
            duration: 2,
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: `Employee Salary Added Successfully`,
            type: "success",
            duration: 2,
          })
        );
        return response?.data;
      default:
        return;
    }
  }
);

export const getEmployeeSalaryAction = createAsyncThunk(
  `employeeSlice/GetEmployeeSalary`,
  async (id) => {
    const response = await getEmployeeSalaryService(id);
    return response.data;
  }
);

export const getGradeAllowanceByIdAction = createAsyncThunk(
  `gradeAllowance/getGradeAllowanceByIdAction`,
  async (id) => {
    const response = await getGradeAllowanceByIdServise(id);
    return response.data;
  }
);
