import React, { useState } from 'react'
import ReminderIcon from '../../../../../content/reminderIcon.svg'
import { timeAgoFromNow } from '../../../../../utils/Shared/helper/timeAgoFromNow'
import './style.css'
import { Modal } from 'antd'
import ReminderDetail from './reminderDetail'


const Reminder= ({ lastReminder,
  reminderCount , showLastReminderText ,   approvalId , module}) => {
const initialState = {
  isOpenReminderModal : false
}
const [state , setState] = useState(initialState)
const handleReminderModal =(e)=>{
  e.stopPropagation();
  e.preventDefault();
  setState({...state , isOpenReminderModal : true})
}

  return (
    <>
    <div className='flex gap-4'>

    {   showLastReminderText &&  <div>

        <div className='remindertext'>last reminder</div>

        <div className='remindertext'>{timeAgoFromNow(lastReminder)}</div>

      </div>
}

      <div className='flex gap-[2px]'>

        <div onClick={(e)=> handleReminderModal(e)} className='mt-1'><img src={ReminderIcon} alt="" width={"20px"} height={"20px"} /></div>
        <div className='remindertext mt-3' >({reminderCount})</div>
        
     </div>

     
    </div>
 { state.isOpenReminderModal &&  <Modal
          title=""
          centered
          className="modal-body custom-accessRole-modal "
          footer={false}
          open={state.isOpenReminderModal}
          onOk={(e) => { e.stopPropagation(); e.preventDefault(); setState({...state , isOpenReminderModal : false})}}
          onCancel={(e) => { e.stopPropagation(); e.preventDefault(); setState({...state , isOpenReminderModal : false})}}
          closable={false}
          width={400}
        >
          <ReminderDetail  
           approvalId={approvalId}
           key={approvalId}
              module={module}  />
        </Modal>}
    </>
  )
}

export default Reminder