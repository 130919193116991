import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";

const urdu = {
  approvalDictionaryLabel: {
    Approval: "منظوری",
    [ApprovalsModuleEnum.ExpenseExecutor]: "انجام دینے والا",
    [ApprovalsModuleEnum.ExpenseFinance]: "فنانس",
    [ApprovalsModuleEnum.ResignationHrApproval]: "اےچار",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "فنانس",
    [ApprovalsModuleEnum.ResignationItApproval]: "آئی ٹی",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "ایڈمن",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "دیگر منظوریاں",
    [ApprovalsModuleEnum.ResignationExitApproval]: "باہر نکلنے کا انٹرویو",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "رپورٹنگ ٹو",
    [ApprovalsModuleEnum.TravelApproval]: "منظوری دینے والے",
    [ApprovalsModuleEnum.TravelAgent]: "ایجنٹ",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "آخری منظوری دینے والے",
  },
  approvalDictionaryPlaceHolder: {
    Approval: "منظوری",
    [ApprovalsModuleEnum.ExpenseExecutor]: "انجام دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ExpenseFinance]: "فنانس سے منظوری دینے والوں کا انتخاب کریں",
    [ApprovalsModuleEnum.ExpenseApproval]: "انفینس منظوری دینے والوں کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationHrApproval]: "اےچار کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "فنانس کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationItApproval]: "آئی ٹی کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "ایڈمن کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "دیگر منظوریوں کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationExitApproval]: "باہر نکلنے کے لئے انٹرویو کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "رپورٹنگ ٹو کا انتخاب کریں",
    [ApprovalsModuleEnum.TravelApproval]: "سفر کے لئے منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.TravelAgent]: "سفر کی ترتیبات کے لئے ایجنٹ کا انتخاب کریں",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "آخری منظوری دینے والے کا انتخاب کریں",

  },
  /*** Define language write side ***/
  Direction: 'rtl',
};
export default urdu;
