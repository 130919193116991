import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
import { responseCode as responseCodeEnum } from "../../../../services/enums/responseCode";
import { STRINGS } from "../../../../utils/base";
import {
  addDirectory_dto,
  getAllDocumentListPaging_dto,
  getAllDocumentList_dto,
  getAllDocumentPaging_dto,
  getAllDocument_dto,
} from "./dto";
import { jsonToFormData } from "../../../../utils/base";

const moveDocument_DBO = (data) => {
  return {
    directoryId: data.parentId ? data.parentId : STRINGS.DEFAULTS.guid,
    documents: data.documents ? data.documents : [],
  };
};
const moveDirectory_DBO = (data) => {
  return {
    parentId: data.parentId ? data.parentId : STRINGS.DEFAULTS.guid,
    directories: data.documents ? data.documents : [],
  };
};

export const addDocumentService = async (request) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/AddDocument`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const moveDocumentService = async (payload) => {
  console.log(payload);
  let request = moveDocument_DBO(payload);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/MoveDocument`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const moveDirectoryService = async (payload) => {
  console.log(payload);
  let request = moveDirectory_DBO(payload);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/MoveDirectory`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllDocumentListService = async (data) => {
  let request = getAllDocumentList_dto(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/GetAllDocumentList`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllDocumentService = async (data) => {
  let request = getAllDocument_dto(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/GetAllDocument`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getDocumentByIdService = async (documentId) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Document/GetDocumentById?id=${documentId}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getDocumentDirectoryByIdService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Document/GetDocumentDirectoryById?id=${id}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

// NEW
// --=--=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

export const addDirectoryService = async (payload) => {
  let request = addDirectory_dto(payload);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/AddDocumentDirectory`, request);
    if (responseCode === responseCodeEnum.Success) {
      return ResponseResultSuccess(data);
    } else {
      return ResponseResultError(message);
    }
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllDocumentDirectoryMemberService = (data) => {
  let id = data.id;
  return Config.get(
    `api/Document/GetAllDocumentDirectoryMember?id=${id}&pageNo=1`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addDocumentDirectoryMemberService = async (data) => {
  let id = data.id;
  let memberId = data.memberId;
  let member = [
    {
      memberId: memberId,
    },
  ];
  return Config.post(`api/Document/AddDocumentDirectoryMember?id=${id}`, member)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const removeDocumentDirectoryMemberService = async (data) => {
  let id = data.id;
  let memberId = data.memberId;
  return Config.post(`api/Document/RemoveDocumentDirectoryMember?id=${id}`, [
    memberId,
  ])
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};
export const addDocumentFavoriteService = async (payload) => {
  return Config.get(
    `api/Document/AddDocumentFavourite?documentId=${payload.id}&isPinned=${payload.isPinned}`
  )
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

//   export const getAllCourseMemberService = (id) => {
// 	return Config.get(`api/ELearning/GetAllCourseMember?id=${id}`)
// 	  .then((res) => {
// 		return res.data;
// 	  })
// 	  .catch((err) => {
// 		return err;
// 	  });
//   };

//   export const addCourseMemberService = async (data) => {
// 	let id = data.id;
// 	let memberId = data.memberId;
// 	return Config.post(`api/ELearning/AddCourseMember?id=${id}`, [
// 	  { memberId: memberId },
// 	])
// 	  .then((res) => {
// 		return res;
// 	  })
// 	  .catch((res) => {
// 		return res;
// 	  });
//   };

export const AddDocumentMemberService = async (payload) => {
  let id = payload[0]?.id;
  return Config.post(`api/Document/AddDocumentMember?id=${id}`, payload)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const RemoveDocumentMemberService = async (payload) => {
  // const { id, memberId, memberRightType } = payload[0];
  let id = payload.id;
  let status = payload.status;
  let memberId = payload.memberId;
  return Config.post(
    `api/Document/RemoveDocumentMember?id=${id}&status=${status}`,
    [memberId]
  )
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllDocumentMemberService = (data) => {
  let id = data.id;
  let pageNo = data.pageNo;
  return Config.get(
    `api/Document/GetAllDocumentMember?id=${id}&pageNo=${pageNo}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const CompleteDocumentService = (data) => {
  const { approvers, id } = data;
  const formData = jsonToFormData(data);
  return Config.post(`api/Document/CompleteDocument?id=${id}`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllDocumentListPagingService = async (data) => {
  let request = getAllDocumentListPaging_dto(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/GetAllDocumentListPaging`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllDocumentPagingService = async (data) => {
  let request = getAllDocumentPaging_dto(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/GetAllDocumentPaging`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getDocumentInformationServices = async (data) => {
  let documentId = data.documentId;
  let attachmentId = data.attachmentId;
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(
      `api/Document/GetDocumentInformation?documentId=${documentId}&attachmentId=${attachmentId}`
    );
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const DashboardDocumentMetricsService = async (request) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/DashboardDocumentMetrics`, request);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const updateDocumentService = (data) => {
  return Config.post(`api/Document/UpdateDocument`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const DuplicateDocumentService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Document/DuplicateDocument`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllSignatureDocumentListService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/SignatureDocument/GetAllSignatureDocumentList`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const getAllSignatureDocumentListPagingservice = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/SignatureDocument/GetAllSignatureDocumentListPaging`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const SignatureDocumentMetricsDashboardService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post('api/SignatureDocument/SignatureDocumentMetricsDashboard', payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


