import React, { useState } from "react";
import CreateEntryTable from "../../createEntryTable";
import { Modal } from "antd";
import { TransactionReferenceTypeEnum } from "../../../enum/TransactionReferenceTypeEnum";
import { STRINGS } from "../../../../../../utils/base";
function PostVoucherModal({
  open = false,
  onCancel = () => { },
  id = STRINGS.DEFAULTS.guid,
  referenceType = TransactionReferenceTypeEnum.General,
  details = [],
}) {

  function stopPropagation(e) {
    e.stopPropagation();
  }

  const handleModalCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <>
      <div onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
        <Modal
          open={open}
          onCancel={handleModalCancel}
          footer={null}
          className="modal-body"
          width={"160vh"}
        >
          <div onClick={stopPropagation}>
            <CreateEntryTable
              id={id}
              referenceType={referenceType}
              onCancel={onCancel}
              details={details}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
export default PostVoucherModal;
