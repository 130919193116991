import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { GetAllAttendanceDeductionPolicy, GetAttendanceDeductionPolicyById, saveAttandanceDeductionPolicy } from "./action";

const initialState = {
  AttendanceDeductionPolicy: [],
  AttendanceDeductionPolicyDetail : {},
  loadingData: false,
  loader: false,
  success: false,
  error: false,
};

const AttandanceSettings = createSlice({
  name: "AttandanceSettings",
  initialState,
  reducers: {
    // allowanceDeleted: (state, { payload }) => {
    //   state.allowances = state.allowances.filter((e) => e.id !== payload.id);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAttendanceDeductionPolicy.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.AttendanceDeductionPolicy = payload.data;
      })
      .addCase(GetAttendanceDeductionPolicyById.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.AttendanceDeductionPolicyDetail = payload.data
      })
      .addCase(saveAttandanceDeductionPolicy.fulfilled, (state, {meta, payload}) => {
        if(meta?.arg?.id){
          let index = state.AttendanceDeductionPolicy.findIndex(({id})=> id === meta?.arg?.id )

          if( index !== -1){
            state.AttendanceDeductionPolicy[index] = payload.data
          }
          
        }else{
          state.AttendanceDeductionPolicy = [...state.AttendanceDeductionPolicy , payload.data]
        }
        state.loader = false;
        // state.AttendanceDeductionPolicyDetail = payload.data
      })
    //   .addCase(updateAllowance.fulfilled, (state, { payload }) => {
    //     state.loader = false;
    //     state.allowances = state.allowances.map((e) =>
    //       e.id === payload.data.id ? payload.data : e
    //     );
    //   })
    //   .addMatcher(isPending(...[addAllowance, updateAllowance]), (state) => {
    //     state.loader = true;
    //     state.success = false;
    //     state.error = false;
    //   })
    //   .addMatcher(isPending(...[getAllAllowance]), (state) => {
    //     state.loadingData = true;
    //     state.success = false;
    //     state.error = false;
    //   })
    //   .addMatcher(
    //     isRejected(...[getAllAllowance, addAllowance, updateAllowance]),
    //     (state) => {
    //       state.loader = false;
    //       state.loadingData = false;
    //       state.success = false;
    //       state.error = false;
    //     }
    //   );
  },
});

export const { allowanceDeleted } = AttandanceSettings.actions;
export default AttandanceSettings.reducer;
