// import AxiosConfig from "../../../../utils/services/AxiosConfig";
import MasterConfig from "../../../../utils/services/MasterConfig";

const API_PREFIX = "/api/";

export const getUserBasicInfoByIdService = (data) => {
  return MasterConfig.get(
    `${API_PREFIX}Employee/GetEmployeeById?userId=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getAllUserLeaveType = (data) => {
  console.log(data, "dataaa");
  return MasterConfig.get(
    `${API_PREFIX}EmployeeLeave/GetAllEmployeeLeaveType?userId=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getAllUserLeaveTypeByUserId = (data) => {
  console.log(data, "datat");
  return MasterConfig.get(
    `${API_PREFIX}EmployeeLeave/GetAllEmployeeLeaveTypeByUserId?userId=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const updateUserLeaveService = (payload) => {
  return MasterConfig.put(
    `${API_PREFIX}UserLeave/UpdateUserLeave?userid=${payload.id}`,
    payload.Initialinputs
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const saveEmployeeLeaveService = (payload) => {
  return MasterConfig.post(
    `${API_PREFIX}EmployeeLeave/SaveEmployeeLeaveType?userId=${payload[0].userId}`,
    payload
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
