export const LoanStatusEnum = {
  InProcess: 1,
  Approved: 2,
  Declined: 3,
  Cancelled: 4,
  Disbursed: 5,
  PartialCleared: 6,
  Completed: 7,
  Hold: 8,
  WaitingForDisburse: 9
};
