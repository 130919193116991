import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import jobBoardNoDataFound from "../../../../../content/NoDataFound/jobBoardNoDataFound.svg";
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import { dictionaryList } from '../../../../../utils/localization/languages';

const NoDataFoundJobBoard = () => {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { jobBoard, sharedLabels } = dictionaryList[userLanguage];
    const { user } = useSelector((state) => state.userSlice);

    return (
        <>
            <div className="flex flex-col items-center justify-center h-full w-full">
                <img
                    src={jobBoardNoDataFound}
                    alt="no-data"
                    loading="lazy"


                />
                <h1 className="text-3xl mt-4"><span className="font-bold">{sharedLabels.Hi}, {user.name}!</span></h1>
                <p className="mt-2 text-gray-600">{jobBoard.noDataMessage}</p>

            </div>

        </>
    )
}

export default NoDataFoundJobBoard
