import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseCode } from '../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';
import {
  getAllDiscussionBoardCategoryService,
  addDiscussionBoardCategoryService,
  updateDiscussionBoardCategoryService,
  removeDiscussionBoardCategoryService,
} from '../service/service';
import { message } from 'antd';

export const getAllDiscussionBoardCategory = createAsyncThunk(
  'DiscussionBoardCategory',
  async (args, { dispatch }) => {
    const res = await getAllDiscussionBoardCategoryService();
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addDiscussionBoardCategory = createAsyncThunk(
  'DiscussionBoardCategory/addDiscussionBoard',
  async (args, { dispatch }) => {
    const res = await addDiscussionBoardCategoryService(args);
    console.log(res);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Discussion Board Category added successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const updateDiscussionBoardCategory= createAsyncThunk(
  'DiscussionBoardCategory/updateDiscussionBoardCategory',
  async (args, { dispatch }) => {
    const res = await updateDiscussionBoardCategoryService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Discussion Board Category updated successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const removeDiscussionBoardCategory= createAsyncThunk(
  'DiscussionBoardCategory/removeDiscussionBoardCategory',
  async (args, { dispatch }) => {
    const res = await removeDiscussionBoardCategoryService(args.id);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Discussion Board Category removed successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);
