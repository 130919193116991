import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { addPaymentCardAction, getAllBusinessPaymentCardAction } from "./action";


const initialState = {
    allBusinessPaymentCardData:[],
    isBusinessPaymentCardDataLoading:false
  };

const paymentCardSlice = createSlice({
    name:"paymentCard",
    initialState,
    reducers:{

    },
    extraReducers:(builder) =>{
        builder
        .addCase(getAllBusinessPaymentCardAction.fulfilled, (state, { payload }) => {
          state.isBusinessPaymentCardDataLoading = false;
          state.allBusinessPaymentCardData = payload.data;
        })
        .addCase(addPaymentCardAction.fulfilled, (state, { payload }) => {

        })
        .addMatcher(isPending(...[addPaymentCardAction]), (state) => {
       
      })
      .addMatcher(isPending(...[getAllBusinessPaymentCardAction]), (state) => {
        state.isBusinessPaymentCardDataLoading = true;
      })
      .addMatcher(
        isRejected(...[getAllBusinessPaymentCardAction]),
        (state) => {
          state.isBusinessPaymentCardDataLoading = false;
        }
      );
    }
})
export default paymentCardSlice.reducer;
