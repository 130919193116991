export const BonusFilterSortEnum = {
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
    Amount: 7,
    AmountDesc: 8,
    Salary: 9,
    SalaryDesc: 10
}



export const bonusTableSort = {
    referenceNo: {
        descend: BonusFilterSortEnum.ReferenceNoDesc,
        ascend: BonusFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: BonusFilterSortEnum.CreateDateDesc,
        ascend: BonusFilterSortEnum.CreateDateAsc
    },
    currentSalary: {
        descend: BonusFilterSortEnum.SalaryDesc,
        ascend: BonusFilterSortEnum.Salary
    },
    amount: {
        descend: BonusFilterSortEnum.AmountDesc,
        ascend: BonusFilterSortEnum.Amount
    },

}