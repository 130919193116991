import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { CommentDictionary } from "../../../sharedComponents/Comment/localization";
import Reactions from "../../../sharedComponents/reactionBox";
import {
  reactionColor,
  reactionDescription,
  reactions,
} from "../../feed/ui/reactions/reactions";
import { ReactionTypeEnum } from "../utils/enums/reactionTypeEnum";

function CommentReaction({
  reactionCount = 0,
  myReaction = ReactionTypeEnum.NoReaction,
  onChangeReaction = () => {},
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { Direction } = CommentDictionary[userLanguage];

  return (
    <>
      <Reactions
        direction={Direction}
        onUpdate={(e) => {
          onChangeReaction(e);
        }}
        onLikeBtnClick={() =>
          onChangeReaction(
            myReaction === ReactionTypeEnum.NoReaction
              ? ReactionTypeEnum.Like
              : ReactionTypeEnum.NoReaction
          )
        }
      >
        <div className={`flex justify-between gap-1 btn on`}>
          <span>
            <img
              className={
                ReactionTypeEnum.Like === myReaction ||
                ReactionTypeEnum.NoReaction === myReaction
                  ? "w-[18px] h-[18px] cursor-pointer"
                  : " w-[18px] h-[18px] cursor-pointer"
              }
              src={reactions[myReaction || 0]}
              alt={reactionDescription[myReaction || 0]}
            />
          </span>
          <span
            className="cursor-pointer text-[13px] font-light"
            //To show the details
            // onClick={(e) =>
            //     handleOpenReactions(e)}
          >
            {reactionCount}
          </span>
          <div
          
            className={`text-[${
              reactionColor[myReaction || 0]
            }] text-[13px] font-light cursor-pointer`}
            style={{
              color: reactionColor[myReaction || 0],
            }}
          >
            {reactionDescription[myReaction || 0]}
          </div>
        </div>
      </Reactions>
    </>
  );
}
export default CommentReaction;
