import { message } from "antd";
import { GetAllAvailableNumberService, GetAllBusinessNumberCallHistoryService, GetAllCountriesService, GetBusinessNumberByIdService, GetBusinessNumberService, addBusinessNumberService, allocateNumberService, deleteAllocateNumberService, removeAllocatedMemberService } from "../services/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";

export const getAllCountries = createAsyncThunk(
    "Countries/GetAllCountries",
    async () => {
      const response = await GetAllCountriesService();     
      return response.data;
    }
  );


  
export const addBusinessNumber = createAsyncThunk(
  "api/addBusinessNumber",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addBusinessNumberService(data);
    if (res.data?.responseCode === responseCode.Success) {
    
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const GetAllAvailableNumber = createAsyncThunk(
  "Countries/GetAllAvailableNumber",
  async (data) => {
    const response = await GetAllAvailableNumberService(data);   
    return response.data;
  }
);

export const GetBusinessNumber = createAsyncThunk(
  "api/GetBusinessNumber",
  async () => {
    const response = await GetBusinessNumberService();   
    return response.data;
  }
);

  
export const allocateNumber = createAsyncThunk(
  "api/allocateNumber",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await allocateNumberService(payload);
    if (res.responseCode === responseCode.Success) {
      return res.data;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const deleteAllocateNumber = createAsyncThunk(
  "api/deleteAllocateNumber",
  async (id) => {
    const res = await deleteAllocateNumberService(id);
   
      return res;

  }
);

export const removeAllocatedMember = createAsyncThunk(
  "api/RemoveAllocationBusinessNumber",
  async (payload) => {
    try {
      const res = await removeAllocatedMemberService(payload);
      message.success("Member removed successfully!");
      return res;
    } catch (error) {
      message.error("Failed to remove member. Please try again.");
      throw error;
    }
  }
);

//GetAllBusinessNumberCallHistory Action
export const GetAllBusinessNumberCallHistoryPaging = createAsyncThunk(
  "GetAllBusinessNumberCallHistoryPaging",
  async (data, { dispatch }) => {
    console.log('RequestDataAction ACT', data);
    const res = await GetAllBusinessNumberCallHistoryService(data);
    if (res.data?.responseCode) {
      if (res.data?.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure
      })
    }
    return res.data;
  }
);