import BiddingListItem from "./biddingListItem";

export default function AuctionBiddingList({ data }) {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((bid, index) => {
          return <BiddingListItem item={bid} index={index} />;
        })}
    </>
  );
}
