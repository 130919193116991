import SalaryDetailCard from "../../salary/view/Page/Detail/DetailItem/salaryDetail";
import ComplainDetail from "../../complain/view/Page/Detail/DetailItem/complainDetail";
import TravelDetail from "../../travel/view/Page/Detail/DetailItem/travelDetail";
import SignupDetail from "../../companies/view/Signup/Detail";
import WarningDetail from "../../warning/view/Page/Detail/DetailItem/warningDetail";
import AssetsDetailCard from "../../assets/view/assetsDetailedCard";
import LeaveDetail from "../../leave/view/UI/detailCardItem";
import ResignationDetail from "../../resignation/view/Page/Detail/DetailItem/resignationDetail";
import AppraisalDetail from "../../appraisalModule/view/Page/Detail/DetailItem/AppraisalDetail";
import { NOTIFICATION_ENUMS } from "../utils/enums";
import ScheduleComposerDetail from "../../schedule/view/Composer/ScheduleComposerDetail";
import RequestDetailCard from "../../RequestListItems/view/requestDetailedCard";
import RequisitionDetailCard from "../../requisition/view/DetailCard";
import PostDetail from "../../feed/ui/postDetail";
import ExpenseDetail from "../../expense/view/Page/Detail/DeatailItem/ExpenseDetail";
import PromotionDetail from "../../promotion/view/UI/PromotionDetail";
import DocumentDetailCard from "../../documents/view/UI/documentDetailCard";
import DiscussionBoardDetail from "../../discussionBoard/view/UI/disscussionBoardNotificationDetail";
import LoanDetail from "../../loan/view/UI/loanDetail";
import { RewardDetail } from "../../reward/view/UI/detailCardItem";
import BonusDetail from "../../bonus/view/UI/detailCardItem";
import CustomApprovalDetail from "../../CustomApprovals/view/UI/detailCardItem";
import FormDetail from "../../forms/view/UI/detailCardItem";
import TaskDetailItem from "../../task/view/Page/Detail/DetailItem";
import PagesDetail from "../../pages/view/UI/pagesNotificationView";
import QuotationDetail from "../../quotation/view/UI/detailCardItem";
import PayrollDetailCard from "../../payroll/view/UI/payrollDetailCard";
import CareerDetailCard from "../../careers/view/UI/CareerDetailCard";


const NotificationDetailedView = ({ notificationData }) => {
  function getConditionalyModule({ featureType, featureId: id }) {
    switch (featureType) {
      case NOTIFICATION_ENUMS.FEATURE_TYPE.REWARD:
        return <RewardDetail id={id} />;
      case NOTIFICATION_ENUMS.FEATURE_TYPE.Document:
        return <DocumentDetailCard key={id} id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.EmployeeSalary:
        return <SalaryDetailCard id={id} key={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Travel:
        // case NOTIFICATION_ENUMS.FEATURE_TYPE.TravelAgent:
        return <TravelDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Bonus:
        return <BonusDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Complain:
        return <ComplainDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Promotion:
        return <PromotionDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Loan:
        return <LoanDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Signup:
        return <SignupDetail id={id} />;


      case NOTIFICATION_ENUMS.FEATURE_TYPE.Expense:
      case NOTIFICATION_ENUMS.FEATURE_TYPE.ExpenseExecutor:
      case NOTIFICATION_ENUMS.FEATURE_TYPE.ExpenseFinance:
        return <ExpenseDetail key={id} id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Warning:
        return <WarningDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Asset:
        return <AssetsDetailCard id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Leave:
        return <LeaveDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Resignation:
        return <ResignationDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Appraisal:
        return <AppraisalDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.FEED:
        return <PostDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Task:
        return <TaskDetailItem id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Schedule:
        return <ScheduleComposerDetail id={id} shortEvent={false} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.RequestForItem:
        return <RequestDetailCard id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.CustomApproval:
        return <CustomApprovalDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Form:
        return <FormDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Requisition:
        return <RequisitionDetailCard id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Quotation:
        return <QuotationDetail id={id} />;

      case NOTIFICATION_ENUMS.FEATURE_TYPE.Career:
        return <CareerDetailCard id={id} />;
      case NOTIFICATION_ENUMS.FEATURE_TYPE.DiscussionBoard:
        return <DiscussionBoardDetail id={id} />;
      case NOTIFICATION_ENUMS.FEATURE_TYPE.Pages:
        return <PagesDetail id={id} />;
      case NOTIFICATION_ENUMS.FEATURE_TYPE.Payroll:
        return <PayrollDetailCard id={id} key={id} />
      default:
        return <></>;
    }
  }
  return <>{notificationData && getConditionalyModule(notificationData)}</>;
};
export default NotificationDetailedView;
