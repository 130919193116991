import React, { useContext, useEffect, useState } from "react";
import {
  WalletOutlined,
  TeamOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import "swiper/css";
import { Form, Radio } from "antd";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { DocumentReferenceTypeEnum } from "../../enum";
import SuggestionBox from "../../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionTypeEnum } from "../../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { useSelector } from "react-redux";

function SelectReferenceType({ form, referenceType, referenceId, refObj }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { document, sharedLabels } = dictionaryList[userLanguage];
  const { documentReferenceType } = document;

  const user = useSelector((state) => state.userSlice);
  const getSuggestionType = (key) => {
    switch (key) {
      case DocumentReferenceTypeEnum.General:
        return SuggestionTypeEnum.Employee;
      case DocumentReferenceTypeEnum.Project:
        return SuggestionTypeEnum.Project;
      case DocumentReferenceTypeEnum.Group:
        return SuggestionTypeEnum.Group;
      default:
        return SuggestionTypeEnum.Employee;
    }
  };

  const [state, setState] = useState({
    type: referenceType,
    typeOfSuggestionBox: getSuggestionType(referenceType),
    selectedtype: refObj.referencename !== "" ? [refObj] : [],
  });
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Form.Item label={sharedLabels.Types} name="referenceType">
        <Radio.Group
          defaultValue={referenceType}
          rules={[{ required: true }]}
          className="expenseTypeRadio radioPrimary "
          onChange={(value) => {
            setState({
              ...state,
              type: value.target.value,
              typeOfSuggestionBox: getSuggestionType(value.target.value),
              selectedtype: [],
            });
            form.setFieldsValue({
              referenceId: "",
            });
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex flex-row gap-3"
          >
            <Radio.Button
              value={DocumentReferenceTypeEnum.General}
            >
              <WalletOutlined />
              {documentReferenceType[DocumentReferenceTypeEnum.General]}
            </Radio.Button>
            <Radio.Button value={DocumentReferenceTypeEnum.Group}>
              <TeamOutlined />
              {documentReferenceType[DocumentReferenceTypeEnum.Group]}
            </Radio.Button>
            <Radio.Button value={DocumentReferenceTypeEnum.Project}>
              <PieChartOutlined />
              {documentReferenceType[DocumentReferenceTypeEnum.Project]}
            </Radio.Button>
          </div>
        </Radio.Group>
      </Form.Item>
      {state.type !== DocumentReferenceTypeEnum.General && (
        <Form.Item
          rules={[
            {
              required: true,
              message: `${documentReferenceType[state.type]} is required`,
            },
          ]}
          label={`${documentReferenceType[state.type]} List`}
          name={"referenceId"}
          labelPosition="top"
        >
          <SuggestionBox
            key={`${documentReferenceType[state.type]} ${state.type}`}
            suggestionType={[state.typeOfSuggestionBox]}
            placeholder={documentReferenceType[state.type]}
            isMultiSelect={false}
            initialData={state.selectedtype}
            callback={(val) => {
              form.setFieldsValue({
                referenceId: val[0]?.id,
                referenceType: state.type,
              });
              setState({ ...state, selectedtype: val });
            }}
            referenceId={user.id}
          />
        </Form.Item>
      )}
    </div>
  );
}

export default SelectReferenceType;
