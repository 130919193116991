export const NOTIFICATION_ENUMS = {
  FEATURE_TYPE: {
    REWARD: 1,
    FEED: 2,
    Document: 3,
    Project: 4,
    Task: 5,
    WorkBoard: 6,
    Schedule: 7,
    Group: 8,
    Messenger: 9,
    Expense: 10,
    ExpenseExecutor: 11,
    ExpenseFinance: 12,
    Leave: 13,
    Travel: 14,
    Warning: 15,
    Complain: 16,
    Bonus: 17,
    Promotion: 18,
    RequestForItem: 19,
    Loan: 20,
    CustomApproval: 21,
    Form: 22,
    EmployeeSalary: 23,
    Signup: 24,
    Payroll: 25,
    Career: 26,
    Requisition: 27,
    Item: 28,
    Asset: 29,
    Resignation: 30,
    Quotation: 31,
    Appraisal: 32,
    LeadManager: 33,
    DiscussionBoard: 34,
    Pages: 35,
  },

  NOTIFICATION_TYPE: {
    RewardApproval: 1,
    RewardRemark: 2,
    RewardFinalApprovalStatus: 3,
    FeedComment: 4,
    NewFeed: 5,
    NewGroupFeed: 6,
    NewProjectFeed: 7,
    NewTimelineFeed: 8,
    MentionedOnFeed: 9,
    TaggedOnFeed: 10,
    DocumentApproval: 11,
    DocumentCollabrator: 12,
    DocumentReader: 13,
    DirectoryAccess: 14,
    FeedReaction: 15,
    FeedCommentReaction: 16,
    CommentReaction: 17,
    ProjectMember: 18,
    TaskComment: 19,
    WorkBoardComment: 20,
    WorkBoardTodoComment: 21,
    CommentReply: 22,
    ScheduleMember: 23,
    GroupMember: 24,
    MessageSend: 25,
    FeedPoll: 26,
    GroupFeedPoll: 27,
    TaggedOnGroupFeed: 28,
    MentionedOnGroupFeed: 29,
    ProjectFeedPoll: 30,
    TaggedOnProjectFeed: 31,
    MentionedOnProjectFeed: 32,
    GroupTask: 33,
    GroupWorkBoard: 34,
    GroupExpense: 35,
    GroupSchedule: 36,
    GroupTravel: 37,
    GroupDocument: 38,
    ProjectTask: 39,
    ProjectWorkBoard: 40,
    ProjectExpense: 41,
    ProjectSchedule: 42,
    ProjectTravel: 43,
    ProjectDocument: 44,
    NewSchedule: 45,
    LeadManager: 46,
    LeadManagerSection: 47,
    LeadManagerTodo: 48,
    CustomApproval: 49,
    Travel: 50,
    NewWorkBoard: 51,
    DocumentApprovalRemark: 52,
    ExpenseApproval: 53,
    LeaveApproval: 54,
    TravelApproval: 55,
    WarningApproval: 56,
    ComplainApproval: 57,
    BonusApproval: 58,
    PromotionApproval: 59,
    RequestForItemApproval: 60,
    LoanApproval: 61,
    CustomApprovalApproval: 62,
    FormApproval: 63,
    EmployeeSalaryApproval: 64,
    SignupApproval: 65,
    PayrollApproval: 66,
    CareerApproval: 67,
    RequisitionApproval: 68,
    RequisitionFinalApproval: 69,
    ItemApproval: 70,
    AssetApproval: 71,
    ResignationAdminApproval: 72,
    ResignationExitApproval: 73,
    ResignationFinanceApproval: 74,
    ResignationHrApproval: 75,
    ResignationItApproval: 76,
    ResignationOtherApproval: 77,
    ResignationReportingToApproval: 78,
    QuotationApproval: 79,
    QuotationClientApproval: 80,
    AppraisalApproval: 81,
    ExpenseApprovalRemark: 82,
    ExpenseExecutorRemark: 83,
    ExpenseFinancerRemark: 84,
    LeaveApprovalRemark: 85,
    TravelApprovalRemark: 86,
    TravelAgentRemark: 87,
    WarningApprovalRemark: 88,
    ComplainApprovalRemark: 89,
    RewardApprovalRemark: 90,
    BonusApprovalRemark: 91,
    PromotionApprovalRemark: 92,
    RequestForItemApprovalRemark: 93,
    LoanApprovalRemark: 94,
    CustomApprovalRemark: 95,
    FormApprovalRemark: 96,
    EmployeeSalaryApprovalRemark: 97,
    PayrollApprovalRemark: 98,
    CareerApprovalRemark: 99,
    RequisitionApprovalRemark: 100,
    RequisitionFinalApprovalRemark: 101,
    ItemApprovalRemark: 102,
    AssetApprovalRemark: 103,
    ResignationAdminApprovalRemark: 104,
    ResignationExitApprovalRemark: 105,
    ResignationFinanceApprovalRemark: 106,
    ResignationHrApprovalRemark: 107,
    ResignationItApprovalRemark: 108,
    ResignationOtherApprovalRemark: 109,
    ResignationReportingToApprovalRemark: 110,
    QuotationApprovalRemark: 111,
    AppraisalApprovalRemark: 112,
  },
};
