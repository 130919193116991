import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import Form from "./form";
import { Table } from "../../../sharedComponents/customTable";
import { tableColumn } from "./tableColumn";
import { addQuickEmployee } from "../store/action";
import "./style.css";
import { clearItem, editItem, quickAddClose } from "../store/slice";
import UpdateForm from "./updateForm";

export const QuickAddWrapper = ({ isAdminTour = false, onClose = () => { }, onNext = () => { } }) => {
  const dispatch = useDispatch();

  const { items, success, editData, loader } = useSelector((state) => state.quickAddSlice);
  const { designations } = useSelector((state) => state.designationSlice);

  const handleAddQuick = () => {
    dispatch(addQuickEmployee(items)).then((res) => {
      if (isAdminTour) {
        onNext();
      } else {
        onClose(false);
      }
    });
  };

  const handleClose = () => {
    onClose(false);
    dispatch(clearItem());
  };

  return (
    <>
      {editData === null ? <Form /> : <UpdateForm />}
      {items && items.length > 0 ? (
        <Table
          bordered
          columns={tableColumn(dispatch, designations, editData)}
          className="custom_table"
          data={items}
        />
      ) : (
        ""
      )}
      <Button
        onClick={handleAddQuick}
        className="addQuickEmployeeButton"
        loading={loader ? true : false}
        disabled={items.length === 0}
      >
        Submit
      </Button>
      {!isAdminTour && (
        <Button onClick={handleClose} className="closeButton" style={{ marginLeft: "4px" }}>
          Close
        </Button>
      )}
    </>
  );
};
