import MasterConfig from "../../../../utils/services/MasterConfig";
export const getAllRoomesService = () => {
	return MasterConfig.get(`api/Room/GetAllRooms`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addRoomService = args => {
	return MasterConfig.post(`api/Room/AddRoom`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const updateRoomService = args => {
	return MasterConfig.put(`api/Room/UpdateRoom`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const removeRoomService = id => {
	return MasterConfig.delete(`api/Room/RemoveRoom?id=${id}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
