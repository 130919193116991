import {useEffect, useMemo, useRef, useState} from "react";
import {CommentModuleEnum} from "../../utils/enum/commentModuleEnum";
import CommentComposer from "../commentComposer/commentComposer";
import {getAllCommentAction} from "../../store/action";
import {STRINGS} from "../../../../../utils/base";
import {useDispatch, useSelector} from "react-redux";
import CommentItemWrapper from "../commentItem/commentItemWrapper";
import {removeComments} from "../../store/commentSlice";
import ScrollPagination from "../../../../sharedComponents/Pagination/view/ScrollPagination";
import { Spin } from "antd";
function CommentList({isHeightFull= false ,  comments = [],module = CommentModuleEnum.Feed,parentId = STRINGS.DEFAULTS.guid,
                         referenceId = STRINGS.DEFAULTS.guid , updateRepliesCount = ()=>{},
                         identifier,
                         isOpenComposer=true
                        }) {

    const dispatch = useDispatch();
    const listReference = useRef();
    const  {data} = useSelector(state => state.commentSlice);
    const hasData = data !== null;
    const hasModule = hasData && data.hasOwnProperty(module);
    const hasReferenceId = hasModule && data[module].hasOwnProperty(referenceId);
    let Comments = [];
    if(parentId===STRINGS.DEFAULTS.guid)
    {
        Comments = hasReferenceId ? data[module][referenceId][identifier] : [];
    }
    else
    {
        const comment =   hasReferenceId ? data[module][referenceId][identifier] : [];
        const findIndex = comment?.findIndex(x => x.id === parentId);
        Comments =  comment[findIndex]?.replies;
    }
    
     console.log(Comments,"Comments")


    const [cmts , setCmts] = useState(Comments)
    const [state,setState] = useState({
        filter : {
            pageNo : 1,
            module : module,
            referenceId,
            parentId
        },
        isLoading:true,
        currentRecordSize:0,
        // comments : comments,
        showReplies : [],
    });

    const getAllComments=()=>{
       dispatch(getAllCommentAction({filter: state.filter , identifier:identifier})).then(({payload})=>{

        console.log(payload,"===payload===")
        setState({
            ...state,
            isLoading:false,
            currentRecordSize:payload?.length
            
        })
       });
    };

    useEffect(()=>{
        getAllComments();
    },[state.filter]);


    const onLoadMore = (pgNo) =>{
        setState({
            ...state,
            filter:{
                ...state.filter,
                pageNo:pgNo
            },
            isLoading:true,

            
        })
    }

    const hasMore = () =>{
        return state.currentRecordSize === 20

    }
    const onScroll = () => {
        if (listReference.current) {
         const { scrollTop, scrollHeight, clientHeight } = listReference.current;
          const atBottom = scrollTop + clientHeight + 2 >= scrollHeight;
         if (atBottom  && hasMore() && !state.isLoading)  {
            const nextPage = state.filter.pageNo+1;
            onLoadMore(nextPage)
         }
       }
     };

 
    return(<>
        <div style={{marginLeft :parentId!== STRINGS.DEFAULTS.guid ? "40px" : "0px"}}>
            <div  style={{marginLeft :parentId!== STRINGS.DEFAULTS.guid ? "15px" : "0px",borderLeft : parentId!== STRINGS.DEFAULTS.guid ? "2px solid #d9d9d9" : "0px" ,borderRadius: parentId!== STRINGS.DEFAULTS.guid ? "0px 0px 0px 30px" : "0px"}}>
                <div    
                    style={{maxHeight: isHeightFull ? "100%" : "500px",overflow:"scroll"}}
                    onScroll={onScroll}
                    ref={listReference}
                >
                    {Comments && Comments?.map((item) => {
                        return (<CommentItemWrapper isHeightFull={isHeightFull} module={module} comment={item}  referenceId={referenceId} identifier={identifier} isOpenComposer={isOpenComposer}/>)
                        })
                    }
                </div>
                {state.isLoading && (
                    <div className="mr-4 w-full py-4 flex justify-center items-center bg-transparent">
                        <Spin size="large" />
                    </div>
                 )}
            </div>
            {parentId !== STRINGS.DEFAULTS.guid && isOpenComposer && <CommentComposer parentId={parentId} referenceId={referenceId} module={state.filter.module} identifier={identifier} />}
        </div>
    </>)

}
export default CommentList;