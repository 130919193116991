import React from 'react'
import { Avatar, Divider, List, Skeleton, Tag } from 'antd';
import "../Style/tag.css"
import { CloseOutlined } from '@ant-design/icons';
import { getNameForImage} from "../../../../utils/base";

export default function CustomTag(props)
{

    const {name , id , image, designation  } = props?.selectedUser
    const {isRemoveAble = true , removeTags=()=>false} = props
    
    const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

    function numberFromText(text) {
    // numberFromText("AA");
    const charCodes = text
        ?.split('') // => ["A", "A"]
        .map(char => char.charCodeAt(0)) // => [65, 65]
        .join(''); // => "6565"
    return parseInt(charCodes, 10);
    }
console.log(isRemoveAble ,"isRemoveAbleisRemoveAble");
    return(
        <>
            <div className="Tag">
                <Tag className="tag-avatar">
                    <Avatar
                        // className="!bg-black"
                        name={name}
                        src={image?.length>0 && image}
                        round={true}
                        width={"20px"}
                        height={"20px"}
                        style={{ backgroundColor: colors[numberFromText(name) % colors.length]}}
                    >
                        {getNameForImage(name)}
                    </Avatar>
                    
                    {name}
                    {
                        isRemoveAble && 
                            <CloseOutlined
                                onClick={()=>{removeTags(id)
                                }}
                            />
                    }
                </Tag>
            </div>
        </>

    )
}