import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: "",
    refreshToken: ""
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.deviceToken = action.payload.deviceToken;
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    updateAccessToken: (state, { payload }) => {
      state.token = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.user.accessToken = payload.accessToken;
      document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() +";path=/");});
      document.cookie = `token=${payload.accessToken}; domain=.workw.com; path=/;`;
    }
  },
});

export const { setUser, updateUser, updateAccessToken } = userSlice.actions;
export default userSlice.reducer;
