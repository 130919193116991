import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums";
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";

const hindi = {
  /*** PLEASE READ FIRST ALL COMMENTS BEFORE ADD LABELS ***/

  /*** add all shared labels like send, ok, cancel, etc ***/

  sharedLabels: {
    SelectLanguage: "Select Language",
    English: "English",
    Turkish: "Turkish",
    Urdu: "اردو",
    Arabic: "عربي",
    Hindi: "Hindi",
    czech: "Czech",
    russian: "Russian",
    french: "French",
    spanish: "Spanish",
    italian: "Italian",
    theme: "थीम",
    awardTo: "पुरस्कार",
    inprogress: "कार्य प्रगति में है",
    filter: "फ़िल्टर",
    approvals: "मंजूरियाँ",
    approvers: "मंजूरकर्ता",
    members: "सदस्य",
    Approvers: "मंजूरकर्ता",
    resignations: "त्यागपत्र",
    Description: "विवरण",
    Descriptionisrequired: "विवरण आवश्यक है!",
    WriteDescriptionhere: "यहां विवरण लिखें...",
    enterDescription: "विवरण दर्ज करें",
    pleseEnterDescription: "कृपया विवरण दर्ज करें",
    pleaseEnterGrade: "कृपया ग्रेड दर्ज करें",
    manager: "प्रबंधक",
    requiredMessageManager: "कृपया प्रबंधक चयन करें",
    SelectManager: "प्रबंधक चयन करें",
    create: "बनाएँ",
    humanresource: "मानव संसाधन",
    finance: "वित्त",
    it: "आईटी",
    admin: "व्यवस्थापक",
    selectMember: "सदस्य चयन करें",
    othersapprovals: "अन्य मंजूरियाँ",
    exitinterview: "निकास साक्षात्कार",
    selectAperson: "व्यक्ति चयन करें",
    SelectHumanResource: "मानव संसाधन चयन करें",
    selectIt: "आईटी चयन करें",
    selectFinance: "वित्त चयन करें",
    selectAdmin: "व्यवस्थापक चयन करें",
    selectOthersApprovals: "अन्य मंजूरियाँ चयन करें",
    inProcess: "प्रक्रिया में है",
    reason: "कारण",
    refrence: "संदर्भ",
    Rewards: "पुरस्कार",
    name: "नाम",
    add: "जोड़ें",
    action: "क्रिया",
    userType: "उपयोगकर्ता प्रकार",
    SelectUserType: "उपयोगकर्ता प्रकार चयन करें",
    requiredMessageUserType: "कृपया उपयोगकर्ता प्रकार चयन करें",
    RewardTo: "पुरस्कार देने वाले",
    Approved: "मंजूर",
    Declined: "अस्वीकृत",
    Resend: "पुन: भेजें",
    NotRequired: "आवश्यक नहीं",
    Cancelled: "रद्द",
    InActive: "निष्क्रिय",
    ListView: "सूची दृश्य",
    TableView: "तालिका दृश्य",
    MyReward: "मेरा पुरस्कार",
    ForApprovals: "मंजूरियाँ के लिए",
    ForApproval: "मंजूरी के लिए",
    RewardToMe: "मेरे लिए पुरस्कार",
    Search: "खोज",
    Disable: "अक्षम करें",
    Attachments: "अटैचमेंट्स",
    Update: "अपडेट",
    update: "अपडेट",
    AddEmployee: "कर्मचारी जोड़ें",
    complain: "शिकायत",
    Submit: "प्रस्तुत करें",
    Delete: "हटाएं",
    Edit: "संपादित करें",
    NotDesignated: "अनपेक्षित",
    Profile: "प्रोफ़ाइल",
    Settings: "सेटिंग्स",
    Logout: "लॉग आउट",
    Table: "तालिका",
    List: "सूची",
    Grid: "ग्रिड",
    GridView: "ग्रिड दृश्य",
    startyear: "प्रारंभ वर्ष",
    endyear: "समाप्ति वर्ष",
    startMonth: "प्रारंभ माह",
    endMonth: "समाप्ति माह",
    EnterStartDate: "प्रारंभ तिथि दर्ज करें",
    SelectStartDate: "प्रारंभ तिथि चयन करें",
    startDate: "प्रारंभ तिथि",
    endDate: "समाप्ति तिथि",
    businessLogo: "व्यापार लोगो",
    title: "शीर्षक",
    enterTitle: "शीर्षक दर्ज करें",
    percentage: "प्रतिशत",
    PleaseEnterPercentage: "कृपया प्रतिशत दर्ज करें",
    previousCharge: "पिछला शुल्क",
    min: "न्यूनतम",
    max: "अधिकतम",
    country: "देश",
    requiredCountry: "देश आवश्यक है",
    SelectCountry: "देश चयन करें",
    latitude: "अक्षांश",
    longitude: "देशांतर",
    address: "पता",
    EnterAddress: "पता दर्ज करें",
    requiredMessageAddress: "कृपया पता दर्ज करें",
    branch: "शाखा",
    question: "प्रश्न",
    allotLeaves: "अवकाश आवंटित करें",
    SubjectRequired: "विषय आवश्यक है",
    WriteSubject: "विषय लिखें",
    category: "श्रेणी",
    amount: "राशि",
    documents: "दस्तावेज़",
    enterName: "नाम दर्ज करें",
    nameRequired: "नाम आवश्यक है",
    accessTo: "पहुंच का अधिकार",
    selectAccessTo: "पहुंच का अधिकार चयन करें",
    collaborators: "सहयोगी",
    selectCollaborators: "सहयोगी चयन करें",
    readers: "पाठक",
    selectReaders: "पाठक चयन करें",
    createBoard: "बोर्ड बनाएँ",
    createPad: "पैड बनाएँ",
    createGrid: "ग्रिड बनाएँ",
    createSlide: "स्लाइड बनाएँ",
    createMindmap: "माइंडमैप बनाएँ",
    drive: "ड्राइव",
    myDrive: "मेरी ड्राइव",
    allDocuments: "सभी दस्तावेज़",
    subject: "विषय",
    Subjectisrequired: "विषय आवश्यक है",
    pleaseEnterSubject: "कृपया विषय दर्ज करें",
    EnterSubject: "विषय दर्ज करें",
    enterAmount: "राशि दर्ज करें",
    pleaseEnterAmount: "कृपया राशि दर्ज करें",
    upload: "अपलोड",
    value: "मूल्य",
    addCategory: "श्रेणी जोड़ें",
    saveCategory: "श्रेणी सहेजें",
    clear: "साफ़ करें",
    loans: "ऋण",
    disburse: "चुकाना",
    referenceNo: "संदर्भ संख्या",
    status: "स्थिति",
    createDate: "निर्माण तिथि",
    creator: "निर्माता",
    netSalary: "नेट वेतन",
    type: "प्रकार",
    PleaseSelectType: "कृपया प्रकार चयन करें",
    CreatedByMe: "मेरे द्वारा बनाया गया",
    Members: "सदस्य",
    Member: "सदस्य",
    pleaseEnterCategory: "कृपया श्रेणी दर्ज करें",
    Memberisrequired: "सदस्य आवश्यक है",
    EnterCategoryName: "श्रेणी का नाम दर्ज करें",
    Clear: "साफ़",
    ReferenceNumber: "संदर्भ संख्या",
    Grade: "ग्रेड",
    Status: "स्थिति",
    CreateDate: "निर्माण तिथि",
    Creator: "निर्माता",
    SNo: "क्रम संख्या",
    Employee: "कर्मचारी",
    SelectApprovers: "मंजूरकर्ता चयन करें",
    Approverisrequired: "मंजूरकर्ता आवश्यक है",
    Yes: "हाँ",
    No: "नहीं",
    totalAmount: "कुल राशि",
    Total: "कुल",
    Date: "तिथि",
    Pleaseselectdate: "कृपया तिथि चयन करें",
    SelectDate: "तिथि चयन करें",
    requiredMessageDate: "कृपया तिथि चयन करें",
    Priority: "प्राथमिकता",
    Pleaseselectpriority: "कृपया प्राथमिकता चयन करें",
    PleaseSelectType: "कृपया प्रकार चुनें",
    SelectProject: "परियोजना का चयन करें",
    SelectGroup: "समूह का चयन करें",
    Project: "परियोजना",
    Group: "समूह",
    Progress: "प्रगति",
    SuretoRate: "रेट करने के लिए निश्चित हैं?",
    Invalidfiletype: "अमान्य फ़ाइल प्रकार",
    Pleaseinputyoursubject: "कृपया अपना विषय दर्ज करें",
    Pleaseinputyourdescription: "कृपया अपना विवरण दर्ज करें",
    Return: "वापस",
    EnterReason: "कारण दर्ज करें",
    TravelReason: "यात्रा का कारण",
    NoDesignation: "कोई नामनिर्दिष्टि नहीं",
    Hi: "नमस्ते",
    Quotation: "कोटेशन",
    Amountisrequired: "राशि की आवश्यकता है",
    WriteDescriptionHere: "यहां विवरण लिखें ...",
    Types: "प्रकार",
    Header: "हैडर",
    MyTeam: "मेरी टीम",
    Email: "ईमेल",
    EnterEmail: "ईमेल दर्ज करें",
    EmailIsRequired: "ईमेल आवश्यक है",
    PhoneNo: "फ़ोन नंबर",
    Designation: "नामनिर्दिष्टि",
    ReferenceType: "संदर्भ प्रकार",
    referenceId: "संदर्भ पहचान",
    Save: "सहेजें",
    save: "सहेजें",
    Color: "रंग",
    Yesterday: "कल",
    Overdue: "समय से बाहर",
    Today: "आज",
    Complete: "पूरा",
    DueDate: "नियत तारीख",
    SelectDueDate: "नियत तारीख का चयन करें",
    Cover: "कवर",
    Cancel: "रद्द करें",
    cancel: "रद्द करें",
    and: "और",
    other: "अन्य",
    Others: "अन्य",
    Option: "विकल्प",
    Public: "सार्वजनिक",
    Private: "निजी",
    click: "क्लिक करें",
    With: "के साथ",
    with: "के साथ",
    Comments: "टिप्पणियाँ",
    Commentsdot: "टिप्पणियाँ...",
    Comment: "टिप्पणी",
    Seeless: "कम देखें",
    Seemore: "और देखें",
    Share: "शेयर",
    ShareNow: "अब शेयर करें",
    SelectEmployee: "कर्मचारी का चयन करें",
    SelectCategory: "श्रेणी का चयन करें",
    PleaseSelectCategory: "कृपया श्रेणी का चयन करें",
    privacy: "गोपनीयता",
    tags: "टैग",
    updateDate: "अद्यतित तिथि",
    otherApprovals: "अन्य मंजूरी",
    resignationDate: "नौकरी छोड़ने की तिथि",
    purpose: "उद्देश्य",
    warnings: "चेतावनियाँ",
    Courses: "कोर्सेस",
    selectType: "प्रकार का चयन करें",
    daysCount: "दिनों की गणना",
    reportingTo: "रिपोर्टिंग टू",
    selectReason: "कारण का चयन करें",
    pleaseSelectReason: "कृपया कारण का चयन करें",
    days: "दिन",
    day: 'दिन',
    Dates: "तारीखें",
    selectMembers: "सदस्यों का चयन करें",
    selectGrade: "ग्रेड का चयन करें",
    newGrade: "नया ग्रेड",
    currentGrade: "वर्तमान ग्रेड",
    PleaseSelectMember: "कृपया सदस्य का चयन करें",
    enterCategory: "श्रेणी दर्ज करें",
    deadline: "समय सीमा",
    Department: "विभाग",
    designationmessage: "कृपया नामनिर्दिष्टि का चयन करें",
    selectDesignation: "नामनिर्दिष्टि का चयन करें",
    jobdescription: "नौकरी का विवरण",
    salaryRange: "वेतन सीमा",
    interviewers: "मुलाक़ात लेनेवाले",
    hiringBuddy: "हायरिंग बड़ी",
    enterCity: "कृपया शहर दर्ज करें",
    City: "शहर",
    selectCity: "शहर का चयन करें",
    requiredCity: "शहर की आवश्यकता है",
    skills: "कौशल",
    experience: "अनुभव (वर्षों में)",
    Experience: "अनुभव",
    jobType: "नौकरी का प्रकार",
    education: "शिक्षा",
    interview: "मुलाक़ात लेनेवाला",
    Rating: "रेटिंग",
    poweredbyWorkw: "वर्कवाइज लिमिटेड द्वारा संचालित - कॉपीराइट्स 2016-",
    copyLink: "लिंक कॉपी करें",
    EffectiveDate: "प्रभावी तिथि",
    experienceRequired: "अनुभव आवश्यक है",
    user: "उपयोगकर्ता",
    postinterview: "पोस्ट इंटरव्यूआर्स",
    Copied: "कॉपी किया गया",
    unreadNotifyItem: "अपठित सूचना आइटम",
    createBy: "द्वारा बनाया गया",
    Create: "बनाएं",
    Open: "खोलें",
    Closed: "बंद",
    Nodescription: "कोई विवरण नहीं",
    writeDescription: "यहां अपना विवरण लिखें...",
    Employees: "कर्मचारी",
    BasicInfo: "मौलिक जानकारी",
    FatherName: "पिता का नाम",
    EnterFatherName: "पिता का नाम दर्ज करें",
    PersonalEmail: "व्यक्तिगत ईमेल",
    EnterPersonalEmail: "व्यक्तिगत ईमेल दर्ज करें",
    personalEmailIsRequired: "व्यक्तिगत ईमेल आवश्यक है",
    PhoneNumber: "फ़ोन नंबर",
    EnterPhoneNumber: "फ़ोन नंबर दर्ज करें",
    NationalIdentity: "राष्ट्रीय पहचान",
    EnterNationalIdentityNumber: "राष्ट्रीय पहचान संख्या दर्ज करें",
    Grades: "ग्रेड",
    SelectGrade: "ग्रेड का चयन करें",
    requiredMessageGrade: "कृपया ग्रेड का चयन करें",
    EnterProbationPeriod: "परीक्षण की अवधि दर्ज करें",
    NoticePeriod: "पूर्व सूचना की अवधि (दिन)",
    EnterNoticePeriod: "सूचना की अवधि दर्ज करें",
    requiredMessageNoticePeriod: "कृपया अपनी सूचना की अवधि दर्ज करें",
    requiredMessageNoticePeriodRestriction: "सूचना की अवधि 1 से 60 के बीच होनी चाहिए",
    DateOfBirth: "जन्म तिथि",
    Gender: "लिंग",
    requiredMessageGender: "कृपया लिंग का चयन करें",
    SelectGender: "लिंग का चयन करें",
    MartialStatus: "वैवाहिक स्थिति",
    SelectMartialStatus: "वैवाहिक स्थिति का चयन करें",
    requiredDepartment: "विभाग आवश्यक है",
    SelectDepartment: "विभाग का चयन करें",
    Subsidiary: "सहायक",
    SelectSubsidiary: "सहायक का चयन करें",
    SubsidiaryOffice: "सहायक कार्यालय",
    SelectSubsidiaryOffice: "सहायक कार्यालय का चयन करें",
    PayrollGroup: "पेरोल समूह",
    SelectPayrollGroup: "पेरोल समूह का चयन करें",
    DefaultApproval: "डिफ़ॉल्ट मंजूरी",
    SelectDefaultApproval: "डिफ़ॉल्ट मंजूरी का चयन करें",
    SelectAll: "सभी का चयन करें",
    NonMandatoryApprovals: "गैर-अनिवार्य मंजूरियां",
    SelectNonMandatoryApprovals: "गैर-अनिवार्य मंजूरियां चुनें",
    FirstName: "पहला नाम",
    EnterFirstName: "पहला नाम दर्ज करें",
    requiredMessageName: "कृपया नाम दर्ज करें",
    requiredMessageFName: "कृपया पहला नाम दर्ज करें",
    requiredMessageLName: "कृपया अंतिम नाम दर्ज करें",
    LastName: "अंतिम नाम",
    EnterLastName: "अंतिम नाम दर्ज करें",
    DateOfJoining: "शामिल होने की तिथि",
    requiredDateOfBirth: "जन्म तिथि आवश्यक है",
    requiredMessageJoiningDate: "कृपया शामिल होने की तिथि चुनें",
    JobShift: "नौकरी का समय",
    requiredMessageJobShift: "कृपया नौकरी का समय चुनें",
    SelectJobShift: "नौकरी का समय चुनें",
    AccessRole: "पहुंच भूमिका",
    requiredMessageAccessRole: "कृपया कम से कम एक पहुंच भूमिका चुनें",
    SelectAccessRole: "पहुंच भूमिका चुनें",
    StartEndDate: "शुरू/समाप्त तिथि",
    ErrorInValidation: "मान्यता में त्रुटि",
    EducationInfo: "शिक्षा सूचना",
    NonMandatory: "गैर-अनिवार्य",
    Present: "वर्तमान",
    FailedToFetch: "प्राप्ति में असफल",
    Position: "पद",
    EnterPosition: "पद दर्ज करें",
    requiredMessagePosition: "कृपया पद दर्ज करें",
    Organization: "संगठन",
    EnterOrganization: "संगठन दर्ज करें",
    requiredMessageOrganziation: "कृपया संगठन दर्ज करें",
    Number: "संख्या",
    EnterNumber: "संख्या दर्ज करें",
    requiredMessageNumber: "कृपया संख्या दर्ज करें",
    Relation: "संबंध",
    SelectRelation: "संबंध चुनें",
    requiredMessageRelation: "संबंध आवश्यक है",
    AccountName: "खाता नाम",
    BranchCode: "शाखा कोड",
    EnterBranchCode: "शाखा कोड दर्ज करें",
    requiredMessageBranchCode: "शाखा कोड आवश्यक है",
    BankName: "बैंक का नाम",
    requiredMessageBankName: "बैंक का नाम आवश्यक है",
    EnterBankName: "बैंक का नाम दर्ज करें",
    AccountNumber: "खाता संख्या",
    EnterAccountNumber: "खाता संख्या दर्ज करें",
    requiredAccountNumber: "खाता संख्या आवश्यक है",
    IBAN: "IBAN",
    EnterIBAN: "IBAN दर्ज करें",
    requiredIBAN: "IBAN संख्या आवश्यक है",
    SortCode: "क्रमबद्ध कोड",
    requiredSortCode: "क्रमबद्ध कोड आवश्यक है",
    EnterSortCode: "क्रमबद्ध कोड दर्ज करें",
    BankInfo: "बैंक सूचना",
    AccountTitle: "खाता शीर्षक",
    EnterAccountTitle: "खाता शीर्षक दर्ज करें",
    requiredMessageAccount: "कृपया खाता शीर्षक दर्ज करें",
    validAccountTitle: "कृपया मान्य खाता शीर्षक दर्ज करें",
    AttachmentType: "अटैचमेंट प्रकार",
    requiredAttchmentType: "अटैचमेंट प्रकार आवश्यक है",
    Attachment: "अटैचमेंट",
    requiredAttachment: "अटैचमेंट आवश्यक है",
    AddAttachment: "अटैचमेंट जोड़ें",
    requiredMessageAssignMember: "कृपया सदस्य निर्धारित करें",
    Remove: "हटाएं",
    ContactNo: "संपर्क नंबर",
    EnterContactNo: "संपर्क नंबर दर्ज करें",
    requiredContactNo: "संपर्क नंबर आवश्यक है",
    Contact: "संपर्क",
    pleasEnterDescription: "कृपया विवरण दर्ज करें",
    Information: "जानकारी",
    Features: "विशेषताएं",
    AddMembers: "सदस्य जोड़ें",
    Summary: "संक्षेप",
    AcessRole: "पहुंच भूमिका",
    defaultApprovers: "डिफ़ॉल्ट मंजूरियां",
    NonMandatoryApprovers: "गैर-अनिवार्य मंजूरियां",
    Pleasefillallrequiredfields: "कृपया सभी आवश्यक क्षेत्र भरें",
    Suretodelete: "निश्चित है कि हटाएं?",
    Code: "कोड",
    createdDate: "निर्मित तिथि",
    Searchmembers: "सदस्य खोजें",
    AddDate: "तिथि जोड़ें",
    duesoon: "जल्दी होने वाला",
    Payroll: "पेरोल",
    ReferenceNo: "संदर्भ संख्या",
    Associate: "सहयोगी",
    InternalMembers: "आंतरिक सदस्य",
    ExternalMembers: "बाह्य सदस्य",
    TotalExpense: "कुल व्यय",
    TotalExpenseAmount: "कुल व्यय राशि",
    TotalPost: "कुल पोस्ट",
    TotalMeeting: "कुल मीटिंग",
    Expense: "व्यय",
    TotalBudget: "कुल बजट",
    Balance: "संतुलन",
    successfully: "सफलतापूर्वक",
    enterValidEmail: 'कृपया मान्य ईमेल पता दर्ज करें',
    ViewSummary: "सारांश देखें",
    AddAdmin: "एडमिन जोड़ें",
    AddExternals: "बाह्य सदस्य जोड़ें",
    DefaultApprovers: "डिफ़ॉल्ट मंजूरियां",
    Billings: "बिलिंग्स",
    spent: "खर्च किया गया",
    SelectUser: "उपयोगकर्ता चुनें",
    Image: "छवि",
    Contacts: "संपर्क",
    noContacts: "कोई संपर्क नहीं",
    AddContact: "संपर्क जोड़ें",
    Roles: "भूमिकाएँ",
    Tag: "टैग",
    AccessRoles: "पहुंच भूमिकाएँ",
    member: "सदस्य",
    uploadCover: "कवर अपलोड करें",
    Pleaseselect: "कृपया चुनें",
    composeEmail: "ईमेल लिखें",
    send: "भेजें",
    ContactDetail: "संपर्क विवरण",
    Detail: "विवरण",
    UploadImage: "छवि अपलोड करें",
    WriteName: "नाम लिखें",
    business: "व्यापार",
    website: "वेबसाइट",
    Unknown: "अज्ञात",
    Interested: "रुचि",
    NotInterested: "रुचि नहीं",
    meetings: "मीटिंगें",
    noMeetings: "कोई मीटिंग नहीं",
    Communications: "संवाद",
    Details: "विवरण",
    LastUpdate: "आधुनिकीकरण",
    nameRequiredMessage: "नाम आवश्यक है!",
    addressRequiredMessage: "पता आवश्यक है!",
    Intrested: "रुचि",
    StartDateTime: "शुरू तिथि और समय",
    DateTimeisrequired: "तिथि और समय आवश्यक है",
    Selectdatetime: "तिथि और समय चुनें",
    Board: "बोर्ड",
    Notes: "टिप्पणियां",
    Miletap: "माइलटैप",
    EMail: "ई-मेल",
    Attendance: "उपस्थिति",
    Absent: "अनुपस्थित",
    NoData: "कोई डेटा नहीं",
    Prority: "प्राथमिकता",
    Heigh: "उच्च",
    Medium: "मध्यम",
    Low: "कम",
    error: "त्रुटि",
    Select: "चुनें",
    Interview: "साक्षात्कार",
    Meeting: "मीटिंग",
    Appointment: "नियुक्ति",
    Pleaseinputyouremail: "कृपया अपना ईमेल दर्ज करें",
    EnterEmailAddress: "ईमेल पता दर्ज करें",
    AddExternalMember: "बाह्य सदस्य जोड़ें",
    Pleaseenterexternalmembers: "कृपया बाह्य सदस्य दर्ज करें",
    externals: "बाह्य",
    Decline: "तिरस्कार करें",
    NotAvailable: "उपलब्ध नहीं है",
    AddApprovals: "मंजूरी जोड़ें",
    Everyone: "सभी",
    SelectDownloaders: "डाउनलोड करने वालों का चयन करें",
    SelectCollabrators: "सहयोगी चयन करें",
    pleaseSelectAssignMemeber: "कृपया कर्मचारी का चयन करें!",
    Availed: "लाभान्वित",
    Allotted: "आवंटित",
    Degree: "डिग्री",
    Time: "समय",
    Mood: "माहौल",
    CheckOut: "चेक आउट",
    CheckIn: "चेक इन",
    Late: "देर से",
    State: "स्थिति",
    PleaseSelectYesNo: "कृपया हाँ/नहीं का चयन करें",
    PleaseselectGrade: "कृपया ग्रेड का चयन करें",
    PleaseenterAmount: "कृपया राशि दर्ज करें",
    Salary: "वेतन",
    Task: "कार्य",
    Nameisrequired: "नाम आवश्यक है",
    PhoneNumberisrequired: "फ़ोन नंबर आवश्यक है",
    Dateisrequired: "तारीख आवश्यक है",
    Month: "महीना",
    Year: "साल",
    FinalApprovers: "अंतिम स्वीकृति देने वाले",
    link: "लिंक",
    forFinalApproval: "अंतिम स्वीकृति के लिए",
    validateBonus: "बोनस प्रोसेस करने में असमर्थ: कर्मचारी के लिए कोई वेतन सूचना उपलब्ध नहीं है",
    validateLoan: "ऋण प्रोसेस करने में असमर्थ: कर्मचारी के लिए कोई वेतन सूचना उपलब्ध नहीं है",
    User: "उपयोगकर्ता",
    Action: "कार्रवाई",
    Created: "बनाया गया",
    selectyourcountry: "कृपया अपना देश चुनें",
    PleaseEnterPhoneNumber: "कृपया फ़ोन नंबर दर्ज करें",
    Pleaseselectyourcity: "कृपया अपना शहर चुनें",
    SelectCurrency: "मुद्रा चुनें",
    Pleaseselectcurrency: "कृपया मुद्रा चुनें",
    totalGroups: "कुल समूह",
    totalProjects: "कुल परियोजनाएं",
    totalMemory: "कुल मेमोरी",
    memory: "मेमोरी",
    EnterMemory: "मेमोरी दर्ज करें",
    availedMemory: "उपलब्ध मेमोरी (जीबी)",
    used: "उपयोग किया गया",
    usedMemory: "उपयोग किया गया मेमोरी (जीबी)",
    From: "से",
    To: "तक",
    WelcomeSendEmail: "स्वागत है, ईमेल भेजें",
    SendEmail: "ईमेल भेजें",
    writeYourRemarksHere: "यहाँ अपनी टिप्पणियाँ लिखें...",
    NoRemarkFound: "कोई टिप्पणी नहीं मिली",
    ToastEnable: "टोस्ट सक्षम करें",
    EmailEnable: "ईमेल सक्षम करें",
    FeatureName: "विशेषता नाम",
    LoginMessage: "कृपया पहले क्रेडेंशियल्स दर्ज करें",
    SelectedDate: "चयनित तिथि",
    SelectedUser: "चयनित उपयोगकर्ता",
    Default: "डिफ़ॉल्ट",
    netAmount: "शुद्ध राशि",
    mylog: "मेरा लॉग",
    SelectStatus: "स्थिति चुनें",
    PolicyType: "नीति प्रकार",
    Percent: "प्रतिशत",
    uploadProfilePicture: "प्रोफ़ाइल चित्र अपलोड करें",
    inputUsername: "कृपया उपयोगकर्ता नाम दर्ज करें",
    userName: "उपयोगकर्ता नाम",
    Dummy: "मनका",
    Lesson: "सबक",
    Duration: "अवधि",
    Text: "टेक्स्ट",
    Enterlink: "लिंक दर्ज करें",
    Dashboard: "डैशबोर्ड",
    searchSchedule: "कार्यक्रम खोजें...",
    AddOption: "विकल्प जोड़ें",
    ChangeImage: "चित्र बदलें",
    DeleteImage: "चित्र हटाएं",
    Question: "सवाल",
    Next: "अगला",
    Prev: "पिछला",
    Wrong: "गलत",
    Correct: "सही",
    YourResult: "आपका परिणाम",
    Createdby: "द्वारा बनाई गई",
    Lastupdated: "अंतिम अपडेट",
    Assignedto: "सौंपा गया",
    Addlesson: "सबक जोड़ें",
    Addtopic: "विषय जोड़ें",
    minutes: "मिनट",
    Modules: "मॉड्यूल",
    Valid: "वैध",
    Expired: "समाप्त",
    AssignedMembers: "सौंपे गए सदस्य",
    Start: "शुरू",
    Previous: "पिछला",
    Read: "पढ़ें",
    DeleteBook: "किताब हटाएं",
    Assign: "सौंपें",
    Explanation: "स्पष्टीकरण",
    Hey: "नमस्ते",
    requiredMessageEmail: "कृपया ईमेल दर्ज करें",
    BusinessName: "व्यापार का नाम",
    PleaseEnterBusinessName: "कृपया व्यापार का नाम दर्ज करें",
    BusinessAddress: "व्यापार का पता",
    PleaseEnterBusinessAddress: "कृपया व्यापार का पता दर्ज करें",
    Offers: "ऑफर्स",
    PleaseEnterOffer: "कृपया ऑफर दर्ज करें",
    CreateOffer: "ऑफर बनाएं",
    AppliedDate: "लागू तारीख",
    ShortList: "संक्षिप्त सूची",
    Finalised: "अंतिम रूप देना",
    Member: "सदस्य",
    SelectMember: 'सदस्य चुनें',
    Approver: "मंजूर करने वाले",
    CategoryName: "श्रेणी का नाम",
    serialNo: "क्रमांक",
    Allocated: "आवंटित",
    Available: "उपलब्ध",
    lost: "खो दिया",
    damage: "नुकसान",
    itemType: "वस्तु का प्रकार",
    Service: "सेवा",
    consumable: "उपभोक्तव्य",
    NonConsumable: "गैर-उपभोक्तव्य",
    Quantity: "मात्रा",
    accountType: "खाता प्रकार",
    PleaseEnterQuantity: "कृपया मात्रा दर्ज करें",
    QuantityMustBePositive: "मात्रा सकारात्मक होनी चाहिए",
    MembersIsRequired: "सदस्यों की आवश्यकता है",
    CreateRequest: "अनुरोध बनाएं",
    IBANNumber: "IBAN नंबर",
    About: "के बारे में",
    AboutMe: "मेरे बारे में",
    TimePeriod: "समय अवधि",
    Company: "कंपनी",
    CityTown: "शहर / गाँव",
    BankDetails: "बैंक विवरण",
    Work: "काम",
    addWorkPlace: "कार्यस्थल जोड़ें",
    AddEducation: "शिक्षा जोड़ें",
    basicSalary: "मूल वेतन",
    feed: 'फ़ीड',
    TakeaNote: "नोट लें",
    awards: 'पुरस्कार',
    Appraisal: "मूल्यांकन",
    salary: 'वेतन',
    activityLog: "गतिविधि लॉग",
    device: 'उपकरण',
    leave: 'छुट्टी',
    Ip: "आईपी",
    DeviceId: "डिवाइस आईडी",
    AppVersion: "एप्लिकेशन संस्करण",
    Selectfeature: "फ़ीचर चुनें",
    EnterYourFeedBackDescription: "अपनी प्रतिक्रिया विवरण दर्ज करें",
    HideAll: "सब छिपाएं",
    ShowAll: "सब दिखाएं",
    PrintBy: "मुद्रित करें द्वारा",
    AccCode: "खाता कोड",
    SelectAccountType: "खाता प्रकार चुनें",
    Files: "फ़ाइलें",
    Space: "स्थान",
    DisburseVoucher: "वितरित/वाउचर",
    TopUsersbyMostFiles: "सबसे अधिक फ़ाइलों के लिए शीर्ष उपयोगकर्ता",
    TopUsersbyStorageSize: "भंडारण आकार के लिए शीर्ष उपयोगकर्ता",
    Apply: "लागू करें",
    NumberOfTopUsers: "शीर्ष उपयोगकर्ताओं की संख्या",
    TopUsers: "शीर्ष उपयोगकर्ता",
    SearchUser: "उपयोगकर्ता खोजें",
    ByStorageSize: "भंडारण आकार के अनुसार",
    ByFileSize: "फ़ाइल आकार के अनुसार",
    companyDashboard: "कंपनी डैशबोर्ड",
    signup: "साइनअप की पुष्टि नहीं हुई",
    billing: "बिलिंग",
    PendingBills: "लंबित बिल",
    MonthYear: "महीना - वर्ष",
    SendBill: "बिल भेजें",
    BillingUsers: "बिलिंग उपयोगकर्ता",
    Item: "आइटम",
    Rate: "दर",
    PaidAmount: "भुगतान की गई राशि",
    PaymentDate: "भुगतान की तारीख",
    DarkDays: "काले दिन",
    ProcessingFee: "प्रसंस्करण शुल्क",
    Currency: "मुद्रा",
    IsEnable: "सक्रिय है",
    Actions: "क्रियाएँ",
    TakeNote: "नोट लें",
    PleaseSelectUsers: "कृपया उपयोगकर्ताओं का चयन करें",
    StickyNotes: "चिपचिपे नोट्स",
    Copy: "कॉपी करें",
    MarkAllRead: "सभी को पढ़ा हुआ चिन्हित करें",
    SeeAll: "सभी देखें",
    Refresh: "ताज़ा करें",
    close: "बंद करें",
    Calling: "कॉलिंग",
    LoadMore: "और लोड करें",
    Quizez: "प्रश्नोत्तरी",
    content: "सामग्री",
  },

  navMenuLabel: {
    Schedules: "अनुसूचियों",
    workWiseCompanies: "कार्यवार कंपनियां",
    promotions: "पदोन्नति",
    customApprovals: "कस्टम स्वीकृतियां",
    feed: "न्यूज़फीड",
    messenger: "मैसेंजर",
    chartOfAccount: "खाता का चार्ट",
    voucher: "वाउचर",
    createVoucher: "वाउचर बनाएँ",
    voucherList: "वाउचर सूची",
    Quotation: "उद्धरण",
    QuotationClient: "कोटेशन क्लाइंट",
    transaction: "लेनदेन",
    report: "रिपोर्ट",
    mailBox: "मेल बॉक्स",
    groups: "पदोन्नति0",
    projects: "पदोन्नति1",
    tasks: "पदोन्नति2",
    workBoard: "पदोन्नति3",
    leadManager: "पदोन्नति4",
    schedules: "पदोन्नति5",
    expense: "पदोन्नति6",
    travel: "पदोन्नति7",
    docsArchives: "पदोन्नति8",
    eLearning: "पदोन्नति9",
    inventory: "कस्टम स्वीकृतियां0",
    myTeam: "कस्टम स्वीकृतियां1",
    orgChart: "कस्टम स्वीकृतियां2",
    payroll: "कस्टम स्वीकृतियां3",
    employee: "कस्टम स्वीकृतियां4",
    administration: "कस्टम स्वीकृतियां5",
    appraisals: "कस्टम स्वीकृतियां6",
    departments: "कस्टम स्वीकृतियां7",
    leaves: "कस्टम स्वीकृतियां8",
    loan: "कस्टम स्वीकृतियां9",
    holidays: "न्यूज़फीड0",
    careers: "न्यूज़फीड1",
    companiesSection: "कार्यवार कंपनियां",
    companies: "न्यूज़फीड2",
    menu: "न्यूज़फीड3",
    hr: "न्यूज़फीड4",
    finance: "न्यूज़फीड5",
    inventorySection: "कस्टम स्वीकृतियां0",
    privacy: "न्यूज़फीड6",
    TC: "न्यूज़फीड7",
    more: "न्यूज़फीड8",
    feedBack: "न्यूज़फीड9",
    warnings: "मैसेंजर0",
    complains: "मैसेंजर1",
    bonus: "मैसेंजर2",
    resignations: "मैसेंजर3",
    requisition: "मैसेंजर4",
    rewards: "मैसेंजर5",
    businessPolicy: "मैसेंजर6",
    form: "मैसेंजर7",
    ledgerReport: "लेजर रिपोर्ट",
    salary: "वेतन",
    payroll: "पेरोल",
    teams: "मेरी टीम",
    LD: "एल एंड डी",
    jobBoard: "नौकरी बोर्ड",
    assets: "संपत्ति आवंटन",
    createAssets: "संपत्ति बनाएँ",
    assetsList: "संपत्ति सूची",
    requestListItems: "वस्तुओं के लिए अनुरोध",
    marketPlace: "मार्केटप्लेस",
    loan: "ऋण",
    Courses: "कोर्सेस",
    selectMembers: "सदस्य चुनें",
    auction: "नीलामी",
    Nodescription: "कोई विवरण नहीं",
    discussionBoard: "चर्चा बोर्ड",
    pages: "पृष्ठ",
    trialBalance: "ट्रायल बैलेंस",
    contract: "अनुबंध"
  },

  /*** AppHeader only used in Component headerTab ***/
  appHeader: {
    /*** मुख्य मेनू ***/
    newsFeed: {
      posts: "पोस्ट्स",
      photos: "फ़ोटोज़",
      videos: "वीडियोज़",
      polls: "पोल्स",
      docs: "डॉक्स",
      tagged: "टैग्ड",
    },
    mailBox: {},
    messenger: {},
    groups: {
      groups: "समूह",
    },
    projects: {
      projects: "परियोजनाएं",
      dashboard: "डैशबोर्ड",
    },
    Task: {
      Tasks: "कार्य",
      myTask: "मेरे कार्य",
      assignedByMe: "मेरे द्वारा सौंपा गया",
      createdByMe: "मेरे द्वारा बनाया गया",

      teamTask: "टीम कार्य",
      dashboard: "डैशबोर्ड",
    },
    leadManager: {
      leadCategory: "लीड श्रेणी",
    },

    schedules: {
      calendar: "कैलेंडर",
      schedule: "अनुसूची",
      scheduleInterviews: "साक्षात्कार अनुसूची",
    },
    expense: {
      expenses: "व्यय",
      forApprovals: "मंजूरी के लिए",
      myExpenses: "मेरे व्यय",
      forExecution: "क्रियान्वयन के लिए",
      forFinance: "वित्त के लिए",
      reports: "रिपोर्टें",
      dashboard: "डैशबोर्ड",
    },
    travel: {
      travels: "यात्राएँ",
      forApprovals: "मंजूरी के लिए",
      agentProcess: "एजेंट प्रक्रिया",
      travelDetail: "यात्रा विवरण",
    },
    docsArchives: {
      documents: "दस्तावेज़",
      myDrive: "मेरा ड्राइव",
      myDocuments: "मेरे दस्तावेज़",
      forApprovals: "मंजूरी के लिए",
    },
    eLearning: {
      dashboard: "डैशबोर्ड",
    },
    inventory: {
      approvals: "मंजूरियाँ",
      inventory: "सूची",
      category: "श्रेणी",
    },
    customApproval: {
      CreateCustomApproval: "कस्टम मंजूरी बनाएं",
      CustomApproval: "कस्टम मंजूरी",
      ForApproval: "मंजूरी के लिए",
      MyCustomApprovals: "मेरी कस्टम मंजूरियाँ",
      AllCustomApproval: "सभी कस्टम मंजूरी",
    },
    payroll: {
      payroll: "पेरोल",
      payrollApproval: "पेरोल मंजूरी",
      salaryApproval: "वेतन मंजूरी",
    },
    // employee: {
    //   employees: "कर्मचारी",
    // },
    administration: {
      administration: "प्रशासन",
    },
    appraisals: {
      appraisals: "मूल्यांकन",
    },
    reward_category: {
      reward_category: "सशस्त्र श्रेणी",
    },
    departments: {
      departments: "विभाग",
    },
    leaves: {
      myLeaves: "मेरी अवकाशें",
      forApprovals: "मंजूरी के लिए",
    },
    holidays: {
      holidays: "छुट्टियाँ",
    },
    warnings: {
      warnings: "चेतावनियाँ",
    },
    resignations: {
      resignations: "त्यागपत्र",
      approvers: "मंजूर करने वाले",
    },
    complains: {
      complains: "शिकायतें",
      approvers: "मंजूर करने वाले",
      createComplain: "शिकायत बनाएं",
    },

    rewards: {
      rewards: "पुरस्कार",
      AllRewards: "सभी बोनस",
      CreatedByMe: "मेरे द्वारा बनाया गया",
      ForApprovals: "मंजूरी के लिए",
      MyRewards: "मेरे पुरस्कार"
    },
    promotions: {
      Promotions: "पदोन्नति",
    },
    salary: {
      Salary: "वेतन",
      CreateSalary: "वेतन बनाएं",
    },
    pages: {
      Pages: "पृष्ठ",
    }
  },

  /*** Main Menu ***/
  globalSearch: {
    All: "सभी",
    feed: "फीड",
    Lead_Manager: "लीड मैनेजर",
    Travel: "यात्रा",
    Document: "दस्तावेज़",
    Project: "परियोजना",
    Workboard: "वर्कबोर्ड",
    Group: "समूह",
    Expense: "व्यय",
    Task: "कार्य",
    Employee: "कर्मचारी",
    e_learning_course: "ई-लर्निंग कोर्स",
    e_learning_videos: "ई-लर्निंग वीडियो",
    e_learning_article: "ई-लर्निंग लेख",
    e_learning_quiz: "ई-लर्निंग क्विज़",
    e_learning_book: "ई-लर्निंग पुस्तक",
    e_learning_tedTalks: "ई-लर्निंग टेड टॉक्स",
    rewards: "पुरस्कार",
  },

  auction: {
    auctionType: "नीलामी का प्रकार",
    personal: "व्यक्तिगत",
    official: "आधिकारिक",
    offer: "ऑफर",
    startingBid: "शुरुआती बोली",
    buyNowAmount: "फिर की मात्रा",
    immediateBuy: "तत्काल खरीद",
    minIncreaseBid: "न्यूनतम वृद्धि बोली",
    Auction: "नीलामी",
    CreateAuction: "नीलामी बनाएं",
    actionType: "नीलामी का प्रकार", // हटाने की आवश्यकता है - अतिरिक्त
    external: "बाह्यिक",
    auctionDetail: "नीलामी का विवरण",
    currentBid: "वर्तमान बोली",

    FormLabel: {
      startingBid: "शुरुआती बोली",
      member: "सदस्य",
      approvers: "मंजूरकर्ता",
      minIncreaseBid: "न्यूनतम वृद्धि बोली",
      buyNowAmount: "फिर की मात्रा",
      offer: "ऑफर",
      immediateBuy: "तत्काल खरीद",
    },
    FormPlaceholder: {
      name: "नीलामी का नाम डालें",
      startingBid: "शुरुआती बोली डालें",
      minIncreaseBid: "न्यूनतम वृद्धि बोली डालें",
      selectMember: "सदस्य का चयन करें",
      selectApprovers: "मंजूरकर्ता का चयन करें",
      buyNowAmount: "फिर की मात्रा डालें",
      offer: "ऑफर डालें",
    },
    FormRequiredMessage: {
      name: "कृपया नीलामी का नाम डालें",
      startingBid: "कृपया शुरुआती बोली डालें",
      minIncreaseBid: "कृपया न्यूनतम वृद्धि बोली डालें",
      buyNowAmount: "कृपया फिर की मात्रा डालें",
      offer: "कृपया ऑफर डालें",
      endDate: "कृपया समाप्ति तिथि डालें",
    },
    auctionFilterTypeEnumLabel: {
      [auctionFilterTypeEnum.All]: "सभी नीलामी",
      [auctionFilterTypeEnum.CreatedByMe]: "मेरी नीलामी",
      [auctionFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
    },
    statusEnum: {
      [AuctionStatusEnum.InProcess]: "प्रक्रिया में",
      [AuctionStatusEnum.Approved]: "मंजूर है",
      [AuctionStatusEnum.Declined]: "अस्वीकृत है",
      [AuctionStatusEnum.Resend]: "पुनः भेजें",
      [AuctionStatusEnum.InActive]: "निष्क्रिय",
      [AuctionStatusEnum.NotRequired]: "आवश्यक नहीं है",
      [AuctionStatusEnum.Cancelled]: "रद्द हुआ",
      [AuctionStatusEnum.Hold]: "होल्ड",
    },
    auctionOfferStatus: {
      [AuctionOfferStatusEnum.BidInProcess]: "प्रक्रिया में",
      [AuctionOfferStatusEnum.BidSold]: "बिक गई है",
    },
    noDataMessage: "नीलामी बनाएं",
  },
  newsFeed: {
    WriteForMe: "मेरे लिए लिखें",
    AddDocuments: "दस्तावेज़ जोड़ें",
    AddPolls: "पोल जोड़ें",
    WhatsOnYourMind: "आपके दिल में क्या है",
    Direction: "ltr",
    Generate: "उत्पन्न करें",
    Change: "परिवर्तन",
    Whatwouldyouliketotalkabout: "आप किस बारे में बात करना चाहेंगे?",
    Post: "पोस्ट",
    Poll: "पोल",
    Documents: "दस्तावेज़",
    PhotoVideo: "फ़ोटो/वीडियो",
    Addpolloption: "पोल विकल्प जोड़ें",
    Removepoll: "पोल हटाएं",
    WriteName: "नाम लिखें",
    AddImageVideo: "छवि/वीडियो जोड़ें",
    CheckIn: "चेक इन",
    ShareonFeed: "फ़ीड पर साझा करें",
    ShareinChat: "चैट में साझा करें",
    NoPosts: "कोई पोस्ट नहीं...",
    Nomoreposts: "और कोई पोस्ट नहीं...",
    noDataMessage: "अब पोस्ट बनाएं!",
    Saysomethingaboutthis: "इसके बारे में कुछ कहें...",
    specificTimeline: " की समयरेखा",
    SharedestinationNames: {
      [SharePostDestinations.FEED]: "फ़ीड",
      [SharePostDestinations.MEMBERS_PROFILE]: "सदस्य का प्रोफ़ाइल",
      [SharePostDestinations.GROUP]: "समूह",
      [SharePostDestinations.PROJECT]: "परियोजना",
      [SharePostDestinations.TIMELINE]: "Timeline",
    },
    SharePostSuggestionPlaceholder: {
      [SuggestionTypeEnum.Employee]: "सदस्य चुनें",
      [SharePostDestinations.GROUP]: "समूह चुनें",
      [SharePostDestinations.PROJECT]: "परियोजना चुनें",
    },
    CreateFeedNow: "अब फ़ीड बनाएँ!",

    QuickOptions: {
      CreateSlide: "स्लाइड बनाएं",
      CreateBoard: "बोर्ड बनाएं",
      CreateGrid: "ग्रिड बनाएं",
      CreatePad: "पैड बनाएं",
      CreateTask: "कार्य बनाएं",
      CreateExpense: "खर्च बनाएं",
      AddUser: "उपयोगकर्ता जोड़ें",
    },
    reactionDescription: {
      [ReactionType.Like]: "पसंद",
      [ReactionType.Love]: "प्रेम",
      [ReactionType.NoReaction]: "पसंद",
      [ReactionType.Support]: "कृतज्ञता",
      [ReactionType.Celebrate]: "उत्सव",
      [ReactionType.Curious]: "जिज्ञासु",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "आपके दिल में क्या है",
      [PostType.POLL]: "कुछ पूछें...",
    },
    Titlecantbeempty: "शीर्षक खाली नहीं हो सकता",
    Attachmentscantbeempty: "अटैचमेंट खाली नहीं हो सकता",
    Optioncantbeempty: "विकल्प खाली नहीं हो सकता",
    SuccessfullySharedonFeed: "फ़ीड पर सफलता पूर्वक साझा किया गया",
    SuccessfullySharedonMembersProfile: "सदस्य के प्रोफ़ाइल पर सफलतापूर्वक साझा किया गया",
    SuccessfullyPostSharedMessage:{
      [PostReferenceType.MAIN_FEED]: "मुख्य फ़ीड पर सफलतापूर्वक साझा किया गया",
      [PostReferenceType.GROUP]: "समूह में सफलतापूर्वक साझा किया गया",
      [PostReferenceType.PROJECTS]: "परियोजना पर सफलतापूर्वक साझा किया गया",
      [PostReferenceType.TIMELINE]: "टाइमलाइन पर सफलतापूर्वक साझा किया गया",
    },
  },
  mail: {
    composer: "लिखें",
    menuItem: {
      inbox: "इनबॉक्स",
      starred: "तारांकित",
      snoozed: "याद दिलाएं",
      sent: "भेजा गया",
      spam: "स्पैम",
      archive: "संग्रह",
      trash: "कचरा",
      draft: "ड्राफ्ट",
      allMail: "सभी मेल",
      createNewLabel: "इनबॉक्स0",
    },
    close: "इनबॉक्स1",
    send: "इनबॉक्स2",
    newMessage: "इनबॉक्स3",
  },
  messenger: {
    Messenger: "मैसेंजर",
    Typing: "टाइपिंग...",
    TypeaMessage: "मैसेज लिखें...",
    Recording: "रिकॉर्डिंग...",
    nameYourGroup: "अपने समूह का नाम दें",
    Create: "बनाएं",
    GroupNameRequired: "समूह का नाम आवश्यक है",
    searchMemberToAdd: "जोड़ने के लिए सदस्य खोजें",
    contacts: "संपर्क",
    selected: "चयनित",
    whatwouldyoutalkingabout: "आप किस बारे में बात कर रहे हैं?",
    SayHi: "अपने संपर्कों को नमस्कार कहें",
  },
  marketPlace: {
    jobBoardName: "नौकरी बोर्ड",
    requsitionName: "अपेक्षा",
  },
  discussionBoard: {
    DiscussionBoard: "चर्चा बोर्ड",
    Topic: "विषय",
    pleaseEnterTopicName: "कृपया विषय का नाम दर्ज करें",
    enterTopicName: "विषय का नाम दर्ज करें",
    addDiscussionBoard: "चर्चा बोर्ड जोड़ें",
    errormessage: "चर्चा बोर्ड प्राप्त करते समय एक त्रुटि हुई।",
    noDataMessage: "– अब चर्चा बनाएं!",
  },
  pages: {
    messageName: "कृपया नाम दर्ज करें",
    tags: "टैग्स",
    messageTags: "कृपया टैग्स दर्ज करें",
    enterTag: "टैग्स दर्ज करें",
    createPage: "पृष्ठ बनाएं",
    noDataMessage: "– अब पृष्ठ बनाएं!"
  },
  group: {
    group: "समूह",
    createGroup: "समूह बनाएं",
    Updategroup: "समूह को अपडेट करें",
    enterGroupName: "कृपया समूह का नाम दर्ज करें।",
    Code: "कोड",
    exampleText: "उदाहरण: जी (एक समूह के नाम के लिए)",
    GroupTag: "समूह टैग",
    AddAdmin: "एडमिन जोड़ें",
    TotalBudget: "कुल बजट",
    Spent: "खर्च हुआ",
    Balance: "शेष",
    EnterGroupTag: "समूह टैग दर्ज करें",
    SaveGroupTag: "समूह टैग सहेजें",
    AddGroupTag: "समूह टैग जोड़ें",
    EditGroup: "समूह संपादित करें",
    CreateGroupNow: "अब समूह बनाएँ!"
  },
  project: {
    WIP: "WIP",
    privateProject: "निजी परियोजना",
    Projects: "परियोजनाएँ",
    UpdateProject: "परियोजना को अपडेट करें",
    CreateProject: "परियोजना बनाएं",
    noDataMessage: "– अब परियोजना बनाएं!",
    ProjectTag: "परियोजना टैग",
    ProjectRole: "परियोजना भूमिका",
    exampleProjectName: "उदा. प (एक परियोजना के लिए)",
    projectDate: "परियोजना तिथि",
    EnterProjectTag: "परियोजना टैग दर्ज करें",
    SaveProjectTag: "परियोजना टैग सहेजें",
    AddProjectTag: "परियोजना टैग जोड़ें",
    editProject: "परियोजना संपादित करें",
    PersonalNotes: "व्यक्तिगत नोट्स",
    EnterProjectRole: "परियोजना भूमिका दर्ज करें",
    SaveCustomTag: "कस्टम टैग सहेजें",
    AddProjectRole: "परियोजना भूमिका जोड़ें",
    TakeaNote: "नोट लें",
    PublicProject: "सार्वजनिक परियोजना",
    ProjectFeatureTabs:{
      [ProjectFeatureEnums.Feed]: "फ़ीड",
      [ProjectFeatureEnums.Schedule]: "स्केच",
      [ProjectFeatureEnums.WorkBoard]: "काम का बोर्ड",
      [ProjectFeatureEnums.Document]: "दस्तावेज़",
      [ProjectFeatureEnums.Task]: "कार्य",
      [ProjectFeatureEnums.Expense]: "खर्च",
      [ProjectFeatureEnums.Travel]: "यात्रा",
      [ProjectFeatureEnums.Quotation]: "ऑडिट",
      [ProjectFeatureEnums.Budget]: "बजट",
      [ProjectFeatureEnums.Setting]: "सेटिंग",
      [ProjectFeatureEnums.DiscussionBoard]: "चर्चा बोर्ड",
      [ProjectFeatureEnums.Pages]: "पृष्ठ",
      [ProjectFeatureEnums.Issues]: "समस्या",
      [ProjectFeatureEnums.Contract]: "संविदा",
    }
  },
  tasks: {
    Tasks: "कार्य",
    Predecessor: "पूर्वक",
    AssignTo: "को सौंपें",
    TaskDate: "कार्य तिथि",
    CreateTask: "कार्य बनाएं",
    TaskAssignTo: "कार्य को सौंपें",
    Rating: "रेटिंग",
    TaskDetail: "कार्य विवरण",
    ProgressofAssignedmembers: "सौंपित सदस्यों की प्रगति",
    SubTasks: "उप-कार्य",
    Subtask: "उप-कार्य",
    CreateSubtask: "उप-कार्य बनाएं",
    Addsubtask: "उप-कार्य जोड़ें",
    ParentTask: "मूल कार्य",
    DueToday: "आज तक",
    DueTomorrow: "कल तक",
    Overdue: "अतित",
    Duein: "में बकाया",
    days: "दिन",
    TaskCreatedSuccessfully: "कार्य सफलतापूर्वक बनाया गया",
    noDataMessage: "– अब कार्य बनाएं!",

    selfTaskEnum: {
      [SelfTaskEnum.SelfTask]: "आत्म-कार्य",
      [SelfTaskEnum.AssignTask]: "को सौंपें",
    },
    Priority: "प्राथमिकता",
    taskFilterTypeEnum: {
      [TaskFilterTypeEnum.CreatedByMe]: "मेरे द्वारा सौंपित",
      [TaskFilterTypeEnum.MyTasks]: "मेरे कार्य",
      [TaskFilterTypeEnum.MyTeamTasks]: "टीम के कार्य",
    },
    statusEnum: {
      [TaskStatusEnum.NotStarted]: "शुरू नहीं हुआ",
      [TaskStatusEnum.InProcess]: "प्रक्रिया में",
      [TaskStatusEnum.Completed]: "पूर्ण",
      [TaskStatusEnum.RatingAssign]: "रेटिंग सौंपित",
      [TaskStatusEnum.Cancel]: "रद्द किया गया",
      [TaskStatusEnum.Hold]: "धारित",
    },
    taskReferenceTypeEnum: {
      [TaskReferenceTypeEnum.General]: "सामान्य",
      [TaskReferenceTypeEnum.Project]: "परियोजना",
      [TaskReferenceTypeEnum.Group]: "समूह",
      [TaskReferenceTypeEnum.Lead]: "नेतृत्व",
    },
    taskPriorityEnum: {
      [TaskPriorityEnum.Default]: "डिफ़ॉल्ट",
      [TaskPriorityEnum.Low]: "कम",
      [TaskPriorityEnum.Medium]: "मध्यम",
      [TaskPriorityEnum.High]: "उच्च",
    },
    SortEnum: {
      [TaskFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [TaskFilterSortEnum.CreateDateAsc]: "निर्माण तिथि - आरोही",
      [TaskFilterSortEnum.StartDateDesc]: "प्रारंभ तिथि - अवरोही",
      [TaskFilterSortEnum.StartDateAsc]: "प्रारंभ तिथि - आरोही",
      [TaskFilterSortEnum.UpdateDateDesc]: "अद्यतन तिथि - अवरोही",
      [TaskFilterSortEnum.UpdateDateAsc]: "अद्यतन तिथि - आरोही"
    }
  },
  contracts: {
    Contract: "संविदा",
    createContract: "संविदा बनाएँ",
    contractDate: "संविदा की तारीख",
    contractPurpose: "संविदा का उद्देश्य",
    selectContractPurpose: "संविदा का उद्देश्य चुनें",
    purposeofContractisRequired: "संविदा का उद्देश्य आवश्यक है",
    category: "श्रेणी",
    purpose: "उद्देश्य",
    signatureMember: "हस्ताक्षर सदस्य",
    contractDetail: "संविदा विवरण",
    selectCategory: "श्रेणी चुनें",
    cateogryofContractisRequired: "श्रेणी आवश्यक है",
    externalMember: "बाहरी हस्ताक्षर सदस्य",
    selectinternalMembers: "आंतरिक हस्ताक्षर सदस्यों को चुनें",
    internalMembers: "आंतरिक हस्ताक्षर सदस्य",
    selectExternalMember: "बाहरी हस्ताक्षर सदस्यों को चुनें",
    contractType: "संविदा प्रकार",
    contractTypeRequired: "संविदा प्रकार आवश्यक है",
    internal: "आंतरिक",
    external: "बाहरी",
    noDataMessage: "अब अनुबंध बनाएँ!",
    contractsFilterTypeLables: {
      [ContractFilterTypeEnum.Contracts]: "सभी व्यापारिक संविदाएँ",
      [ContractFilterTypeEnum.BusinessContract]: "मेरी संविदाएँ"
    },
    statusEnum: {
      [ContractStatusEnum.InProcess]: "प्रकिया में",
      [ContractStatusEnum.Completed]: "पूर्ण",
      [ContractStatusEnum.Cancelled]: "रद्द"
    },
    contractReferenceTypeEnum: {
      [ContractReferenceTypeEnum.General]: "सामान्य",
      [ContractReferenceTypeEnum.Project]: "प्रोजेक्ट",
      [ContractReferenceTypeEnum.Group]: "समूह",
      [ContractReferenceTypeEnum.Lead]: "लीड"
    }
  },
  workBoard: {
    WorkBoardDashBoard: "वर्कबोर्ड डैशबोर्ड",
    pleaseEnterYourBoardName: "कृपया अपना बोर्ड नाम दर्ज करें",
    pleaseEnterYourBoardDescription: "कृपया अपना बोर्ड विवरण दर्ज करें",
    EnterMembers: "सदस्य दर्ज करें",
    UpdateBoard: "बोर्ड अपडेट करें",
    CreateBoard: "बोर्ड बनाएं",
    privacy: "गोपनीयता",
    AddSection: "खंड जोड़ें",
    AddTodo: "टोडो जोड़ें",
    Save: "सहेजें",
    EnterSectionTitle: "खंड का शीर्षक दर्ज करें",
    InSection: "खंड में",
    Label: "लेबल",
    Activity: "गतिविधि",
    Labels: "लेबल्स",
    UploadCoverPhoto: "कवर फोटो अपलोड करें",
    Section: "खंड",
    Board: "बोर्ड",
    Todos: "सभी कार्य",
    CompleteDate: "पूर्ण तिथि",
    DetailedDesc: "और विस्तृत विवरण जोड़ें...",
    EnterTodo: "इस कार्ड के लिए टेक्स्ट दर्ज करें...",
    CreateWorkBoardNow: "- अब वर्कबोर्ड बनाएं!",
    workBoardExample: "उदाहरण: वर्क बोर्ड के लिए (WB)",
  },
  leadManager: {
    LeadManager: "लीड प्रबंधक",
    AssignedLeads: "निरुपित लीड्स",
    AllLeads: "सभी लीड्स",
    Groups: "समूह",
    email: "ईमेल",
    Lead: "लीड",
    Leads: "लीड्स",
    Pleaseinputyourboardname: "कृपया अपने बोर्ड का नाम डालें!",
    exampleLeadManagerMessage: "उदा. एलएम (लीड प्रबंधक नामक समूह के लिए)",
    inputBoardDescription: "कृपया अपने बोर्ड का विवरण डालें!",
    UpdateLeadManager: "लीड प्रबंधक को अपडेट करें",
    CreateLeadManager: "लीड प्रबंधक बनाएं",
    CreateLeadGroup: "लीड समूह बनाएं",
    noDataMessage: "– अब लीड प्रबंधक बनाएं!",
    LastUpdated: "अंतिम अपडेट",
    LeadValue: "लीड मूल्य",
    individual: "व्यक्ति",
    leadEmailAddress: "लीड ईमेल पता",
    leadPhoneNumber: "लीड फ़ोन नंबर",
    leadAddress: "लीड पता",
    leadUrl: "लीड URL",
    ConvertProject: "परियोजना में परिवर्तित करें",
    goToProject: "परियोजना पर जाएं",
    SelectAssignMembers: "नियुक्त सदस्यों का चयन करें",
    LeadGroup: "लीड समूह",
    leadName: "लीड का नाम",
    leadMembers: "लीड सदस्य",
    leadWebiste: "लीड वेबसाइट",
    LeadValuenegative: "लीड मूल्य नकारात्मक नहीं हो सकता!",
    AddLead: "लीड जोड़ें",
    SelectCommunicationTo: "संवाद के लिए चयन करें",
    CommunicationTypeisrequired: "संवाद के प्रकार की आवश्यकता है!",
    PleaseselectCommunicationType: "कृपया संवाद के प्रकार का चयन करें",
    CommunicationType: "संवाद के प्रकार",
    AddCommunication: "संवाद जोड़ें",
    CommunicationTo: "संवाद के लिए",
    NoCalls: "कोई कॉल नहीं",
    LeadsValue: "लीड्स मूल्य",
    metricTypeEnum: {
      [LeadManagerMetricTypeEnum.allGroup]: "कुल समूह",
      [LeadManagerMetricTypeEnum.allAssign]: "निरुपित लीड",
      [LeadManagerMetricTypeEnum.leadToProject]: "परियोजना में परिवर्तित करें",
      [LeadManagerMetricTypeEnum.leadSection]: "अधिकतम लीड सेक्शन",
    },
  },
  schedule: {
    StartMeeting: "मीटिंग शुरू करें",
    MeetinginProgress: "चल रही मीटिंग",
    EndMeeting: "मीटिंग समाप्त करें",
    MeetingEnded: "मीटिंग समाप्त हो गई",
    NewTokenAdded: "नया टोकन जोड़ा गया",
    Copied: "कॉपी की गई",
    SuccessfullyBlocked: "सफलतापूर्वक ब्लॉक किया गया",
    SuccessfullyUnBlocked: "सफलतापूर्वक अनब्लॉक किया गया",
    UpdateCalendarLink: "कैलेंडर लिंक अपडेट करें",
    AddCalendarLink: "कैलेंडर लिंक जोड़ें",
    GetCalendarLink: "कैलेंडर लिंक प्राप्त करें",
    CopyLink: "लिंक कॉपी करें",
    Block: "ब्लॉक",
    UnBlock: "अनब्लॉक",
    CreateSchedule: "शेड्यूल बनाएं",
    Draft: "ड्राफ्ट",
    Finalize: "निर्धारित करें",
    Complete: "पूर्ण",
    Preview: "पूर्वावलोकन",
    MinutesOftheMeeting: "मीटिंग के मिनट्स",
    MeetingId: "मीटिंग आईडी",
    Venue: "स्थान",
    Workwisecall: "वर्कवाइज कॉल",
    ConferenceCall: "कॉन्फ़्रेंस कॉल",
    Attendees: "उपस्थितियां",
    Absentees: "अनुपस्थितियां",
    PointsofDiscussion: "चर्चा के बिंदु",
    AddPoint: "बिंदु जोड़ें",
    Print: "प्रिंट",
    SaveToPDF: "PDF में सहेजें",
    MEETINGMINUTES: "मीटिंग के मिनट्स",
    Yes: "हाँ",
    No: "नहीं",
    WorkwiseCall: "वर्कवाइज कॉल",
    MeetingObjectives: "मीटिंग के उद्देश्य",
    Points: "बिंदुएँ",
    RefNo: "रेफरेंस नंबर",
    AttendanceatMeeting: "मीटिंग में उपस्थिति",
    Loadingschedules: "शेड्यूल लोड हो रहे हैं...",
    Nodata: "कोई डेटा नहीं...",
    Projects: "परियोजनाएं",
    Groups: "समूह",
    titleMessage: "क्या आप सुनिश्चित हैं? परिवर्तन प्रकार से निर्धारित सदस्यों को हटा देगा।",
    pleaseSelectAssignMemeber: "कृपया एक सदस्य का चयन करें!",
    SelectAssignto: "असाइन करने के लिए चयन करें",
    GetLink: "लिंक प्राप्त करें",
    Anyone: "कोई भी",
    anyoneText: "लिंक के साथ कोई भी व्यक्ति एक अपॉइंटमेंट बना सकता है।",
    TodayEvents: "आज की घटनाएँ",
    UpcomingEvents: "आगामी घटनाएँ",
    InterviewRequests: "साक्षात्कार के अनुरोध",
    sendviachat: "चैट के माध्यम से भेजें",
    OnVideoConference: "वीडियो कॉन्फ़्रेंस पर:",
    OnWorkwiseCall: "वर्कवाइज कॉल पर",
    Enterlocation: "स्थान दर्ज करें",
    Duration: "कालावधि",
    inminutes: "मिनट में",
    Durationisrequired: "कालावधि आवश्यक है",
    Rooms: "कमरे",
    SelectRoom: "कमरा चुनें",
    CheckRoomAvailability: "कमरे की उपलब्धता जाँचें",
    Available: "उपलब्ध",
    NotAvailable: "उपलब्ध नहीं है",
    TravelTime: "यात्रा का समय",
    PreparationTime: "तैयारी का समय",
    SendviaEmail: "ईमेल के माध्यम से भेजें",
    schedulerCalender: "स्केज़्यूलर कैलेंडर",
    Next: "आगामी",
    Prev: "पिछला",
    titlemyCustomButton: "मेरा कस्टम बटन शीर्षक",
    prevnexttoday: "पिछला आगामी आज",
    timeGridDaytimeGridWeekdayGridMonth: "टाइम ग्रिड डे टाइम ग्रिड वीक डे ग्रिड मंथ",
    timeGridDay: "टाइम ग्रिड डे",
    top: "शीर्ष",
    block: "ब्लॉक",
    minutes: "मिनट",
    AllDay: "पूरा दिन",
    timeGridWeek: "टाइम ग्रिड वीक",
    slotDuration: "00:15:00",
    numeric: "संख्यात्मक",
    dayGridMonth: "डे ग्रिड मंथ",
    dayGridWeek: "डे ग्रिड वीक",
    timeGrid: "टाइम ग्रिड",
    Day: "दिन",
    Week: "सप्ताह",
    Month: "महीना",
    NoEventsonthisday: "इस दिन कोई घटनाएँ नहीं हैं।",
    CreateInterviewSchedule: "साक्षात्कार अनुसूची बनाएं",
    Interviewwith: "साक्षात्कार साथ",
    MySchedules: "मेरी अनुसूचियाँ",
    TeamSchedules: "टीम अनुसूचियाँ",
    Past: "बीता हुआ",
    Today: "आज",
    Upcoming: "आगामी",
    ScheduleDetail: "अनुसूची विवरण",
    JoinWorkwiseCall: "वर्कवाइज कॉल में शामिल हों",
    AddWorkwiseCall: "वर्कवाइज कॉल जोड़ें",
    Host: "(होस्ट)",
    MakeHost: "होस्ट बनाएं",
    Attending: "उपस्थित",
    UpdateSchedule: "अनुसूची को अपडेट करें",
    Calendar: "कैलेंडर",
    Schedule: "अनुसूची",
    ShareCalendarLink: "कैलेंडर लिंक साझा करें",
    pointer: "पॉइंटर",
    default: "डिफ़ॉल्ट",
    blue: "नीला",
    red: "लाल",
    purple: "बैंगनी",
    Self: "खुद",
    AssignTo: "सौंपें",
    assign: "सौंपें",
    min: "मिनट",
    Minutes: "15 मिनट",

  },
  expenses: {
    CreateExpense: "व्यय बनाएं",
    IsReimbursable: "पुनर्प्राप्त किया जा सकता है",
    WriteHeaderHere: "यहां शीर्षक लिखें...",
    ExpenseDetail: "व्यय विवरण",
    ExpenseDate: "व्यय तिथि",
    Executors: "निर्वाहक",
    Financers: "वित्तपोषण करनेवाले",
    ExecutorRequired: "निर्वाहक आवश्यक है",
    selectExecutor: "व्यय के निर्वाहक का चयन करें",
    noDataMessage: "अपने वित्तीय यात्रा को सशक्त बनाएं - अब व्यय बनाएं!",
    expenseFilterTypeEnum: {
      [ExpenseFilterTypeEnum.MyExpense]: "मेरा व्यय",
      [ExpenseFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [ExpenseFilterTypeEnum.ForExecutions]: "निष्पादन के लिए",
      [ExpenseFilterTypeEnum.ForFinance]: "वित्त के लिए",
    },
    expenseType: {
      [ExpenseReferenceTypeEnum.General]: "सामान्य",
      [ExpenseReferenceTypeEnum.Group]: "समूह",
      [ExpenseReferenceTypeEnum.Project]: "परियोजना",
      [ExpenseReferenceTypeEnum.Travel]: "यात्रा",
      [ExpenseReferenceTypeEnum.Assets]: "संपत्ति",
    },
    statusEnum: {
      [ExpenseApprovalStatusEnum.InProcess]: "प्रक्रिया में",
      [ExpenseApprovalStatusEnum.Approved]: "मंजूर",
      [ExpenseApprovalStatusEnum.Cancelled]: "रद्द",
      [ExpenseApprovalStatusEnum.Completed]: "पूर्ण",
      [ExpenseApprovalStatusEnum.Hold]: "होल्ड",
      [ExpenseApprovalStatusEnum.Disbursed]: "संवितरित",
    },
    SortEnum: {
      [ExpenseFilterSortEnum.CreateDateDesc]: "तारीख बनाएँ - अवरोही",
      [ExpenseFilterSortEnum.CreateDateAsc]: "तारीख बनाएँ",
      [ExpenseFilterSortEnum.ExpenseDateDesc]: "खर्च तिथि - अवरोही",
      [ExpenseFilterSortEnum.ExpenseDate]: "खर्च तिथि",
      [ExpenseFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [ExpenseFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [ExpenseFilterSortEnum.Amount]: "रकम",
      [ExpenseFilterSortEnum.AmountDesc]: "रकम - अवरोही"
    },
    Category: {
      [ExpenseCategoryEnum.Transport]: "परिवहन",
      [ExpenseCategoryEnum.Health]: "स्वास्थ्य",
      [ExpenseCategoryEnum.Food]: "भोजन",
      [ExpenseCategoryEnum.Shopping]: "खरीदारी",
      [ExpenseCategoryEnum.Entertainment]: "मनोरंजन",
      [ExpenseCategoryEnum.Travel]: "यात्रा",
      [ExpenseCategoryEnum.Bill]: "बिल",
      [ExpenseCategoryEnum.Office]: "कार्यालय",
      [ExpenseCategoryEnum.Fuel]: "ईंधन",
      [ExpenseCategoryEnum.Asset]: "संपत्ति",
      [ExpenseCategoryEnum.Other]: "अन्य"
    }

  },
  travel: {
    TravelDetail: "यात्रा विवरण",
    CreateTravel: "यात्रा बनाएं",
    PleaseAddTravelDetail: "कृपया यात्रा विवरण जोड़ें",
    TravelToandFrom: "और से यात्रा",
    ReturnDateIsRequired: "वापसी तिथि आवश्यक है",
    PleaseSelectTravelType: "कृपया यात्रा का प्रकार चुनें",
    HotelTADACar: "होटल, टाडा और कार",
    HotelRequired: "होटल आवश्यक है",
    RentaCar: "कार किराए पर लें",
    PickUpRequired: "पिक-अप आवश्यक है",
    DropOffRequired: "ड्रॉप-ऑफ आवश्यक है",
    TADAApplicable: "टाडा लागू है",
    TravelBy: "यात्रा के द्वारा",
    SpecialRequest: "विशेष अनुरोध",
    SearchDestinationCity: "गंतव्य शहर खोजें",
    SearchDepartureCity: "प्रस्थान शहर खोजें",
    PleaseSelectDepartureCity: "कृपया प्रस्थान शहर चुनें।",
    PleaseSelectDestinationCity: "कृपया गंतव्य शहर चुनें।",
    DepartureTimeIsRequired: "प्रस्थान समय आवश्यक है",
    RetunTimeIsRequired: "वापसी समय आवश्यक है",
    AttachmentType: "अटैचमेंट प्रकार",
    WriteSpecialRequestDetail: "विशेष अनुरोध विवरण लिखें",
    Agents: "एजेंट्स",
    Departure: "प्रस्थान",
    Arrival: "आगमन",
    DepartureDate: "प्रस्थान तिथि",
    DepartureTime: "प्रस्थान समय",
    DepartureCity: "प्रस्थान शहर",
    ShiftType: "शिफ्ट प्रकार",
    DepartureShift: "प्रस्थान शिफ्ट",
    ArrivalCity: "आगमन शहर",
    CreateTravelnow: "अब यात्रा बनाएं",

    TravelAttachmentTypeOptions: {
      [TravelAttachmentTypeEnum.Passport]: "पासपोर्ट",
      [TravelAttachmentTypeEnum.Ticket]: "टिकट",
      [TravelAttachmentTypeEnum.Insurance]: "इंश्योरेंस",
      [TravelAttachmentTypeEnum.CarRental]: "कार रेंटल",
      [TravelAttachmentTypeEnum.Other]: "अन्य",
    },

    Shift: {
      [ShiftEnum.Morning]: "सुबह",
      [ShiftEnum.Afternoon]: "दोपहर",
      [ShiftEnum.Evening]: "शाम",
      [ShiftEnum.Night]: "रात",
    },

    travelBy: {
      plane: "हवाई जहाज से",
      bus: "बस से",
      ship: "जहाज से",
      train: "ट्रेन से",
      car: "कार से",
    },

    TravelOptions: {
      [TravelOptionsEnums.ByPlane]: "हवाई जहाज से",
      [TravelOptionsEnums.ByShip]: "जहाज से",
      [TravelOptionsEnums.ByBus]: "बस से",
      [TravelOptionsEnums.ByTrain]: "ट्रेन से",
      [TravelOptionsEnums.ByCar]: "कार से",
    },

    travelFilterTypeEnum: {
      [TravelFilterTypeEnum.Travels]: "यात्राएँ",
      [TravelFilterTypeEnum.MyTravel]: "मेरी यात्रा",
      [TravelFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [TravelFilterTypeEnum.AgentProcess]: "एजेंट प्रक्रिया",
    },

    statusEnum: {
      [TravelStatusEnum.InProcess]: "प्रक्रिया में",
      [TravelStatusEnum.Approved]: "मंजूर",
      [TravelStatusEnum.Declined]: "अस्वीकृत",
      [TravelStatusEnum.Cancelled]: "रद्द",
      [TravelStatusEnum.ApprovedByApprovers]: "मंजूरी देने वालों द्वारा मंजूर",
      [TravelStatusEnum.ApprovedByAgents]: "एजेंट्स द्वारा मंजूर",
      [TravelStatusEnum.DeclineByApproves]: "मंजूरी देने वालों द्वारा अस्वीकृत",
      [TravelStatusEnum.DeclineByAgents]: "एजेंट्स द्वारा अस्वीकृत",
      [TravelStatusEnum.Hold]: "होल्ड",
    },
  },
  eLearning: {
    Course: "कोर्स",
    eBook: "ई-बुक",
    Quiz: "क्विज़",
    TedTalk: "टेडटॉक",
    Article: "लेख",
    Video: "वीडियो",
    BasicCourseDetail: "मूल कोर्स विवरण",
    SelectSubject: "विषय चुनें",
    SelectCourse: "कोर्स चुनें",
    PleaseSelectOption: "कृपया विकल्प चुनें",
    Selectcoursetype: "कोर्स प्रकार चुनें",
    CourseName: "कोर्स का नाम",
    Entercoursename: "कोर्स का नाम दर्ज करें",
    Level: "स्तर",
    PleaseSelectLevel: "कृपया स्तर चुनें",
    Selectlevel: "स्तर चुनें",
    CourseAdminMembers: "कोर्स व्यवस्थापक सदस्य",
    CourseAdminisrequired: "कोर्स व्यवस्थापक आवश्यक है",
    AssignMembers: "सदस्यों को सौंपें",
    UpdateEBook: "ईबुक अपडेट करें",
    CreateEBook: "ईबुक बनाएं",
    AuthorName: "लेखक का नाम",
    EnterInformation: "जानकारी दर्ज करें",
    UploadBook: "बुक अपलोड करें (PDF)",
    CreateQuiz: "क्विज़ बनाएं",
    QuizName: "क्विज़ का नाम",
    CreateTedTalk: "टेडटॉक बनाएं",
    ViaLink: "लिंक के माध्यम से",
    ViaVideoUpload: "वीडियो अपलोड के माध्यम से",
    PleaseInsertLink: "कृपया लिंक डालें",
    UploadVideo: "वीडियो अपलोड करें",
    CreateArticle: "लेख बनाएं",
    InsertLink: "लिंक डालें",
    UploadPDF: "फोटो या पीडीएफ अपलोड करें",
    CreateVideo: "वीडियो बनाएं",
    LessonDetail: "सबक विवरण",
    Eitherselect: "या तो कोई क्विज़ चुनें या उसे हटा दें।",
    Selectquiz: "क्विज़ चुनें",
    AddNewQuiz: "नया क्विज़ जोड़ें",
    Atleastonetopic: "कम से कम इस सबक का एक विषय आवश्यक है।",
    LessonNameRequired: "सबक का नाम आवश्यक है।",
    LessonName: "सबक का नाम",
    DeleteLesson: "सबक हटाएं",
    LessonDescriptionRequired: "सबक विवरण आवश्यक है।",
    TopicNameIsRequired: "विषय का नाम आवश्यक है।",
    TopicName: "विषय का नाम",
    DeleteTopic: "विषय हटाएं",
    TopicDescription: "विषय विवरण आवश्यक है।",
    eitherEnterText: "या टेक्स्ट दर्ज करें या उसे हटा दें।",
    EitherenterA: "या एक दर्ज करें",
    OrRemoveIt: "या उसे हटा दें।",
    CreateArticlesNow: "अब लेख बनाएं!",
    CreateCoursesNow: "अब कोर्स बनाएं!",
    CreateEbooksNow: "अब ईबुक बनाएं!",
    CreateElearningNow: "अब ई-लर्निंग बनाएं!",
    CreateQuizzesNow: "अब क्विज़ बनाएं!",
    CreateTeamDashboardNow: "अब टीम डैशबोर्ड बनाएं!",
    CreateTedTalksNow: "अब टेडटॉक बनाएं!",
    CreateVideosNow: "अब वीडियो बनाएं!",
    OptionEmpty: "विकल्प खाली नहीं हो सकता",
    CorrectOptionRequired: "सही विकल्प आवश्यक है",
    QuestionEmpty: "सवाल खाली नहीं हो सकता",
    UntitledQuestion: "अभिनयित प्रश्न",
    AddQuestion: "प्रश्न जोड़ें",
    DeleteQuestion: "प्रश्न हटाएं",
    Quiznamerequired: "क्विज़ का नाम आवश्यक है!",
    QuizexpirationDate: "क्विज़ समाप्ति तिथि",
    QuizExpirationDateIsRequired: "क्विज़ समाप्ति तिथि आवश्यक है",
    QuizDescriptionRequired: "क्विज़ विवरण आवश्यक है!",
    StartQuiz: "क्विज़ शुरू करें",
    GoToResults: "परिणामों पर जाएं",
    Gettoknown: "उपयोगकर्ता अनुभव को जानें",
    Missingdata: "डेटा गायब है।",
    UntitledLesson: "अभिनयित सबक",
    RemoveText: "टेक्स्ट हटाएं",
    AddText: "टेक्स्ट जोड़ें",
    RemoveImage: "इमेज हटाएं",
    AddImage: "इमेज जोड़ें",
    RemoveVideo: "वीडियो हटाएं",
    AddVideo: "वीडियो जोड़ें",
    RemovePDF: "पीडीएफ हटाएं",
    AddPDF: "पीडीएफ जोड़ें",
    RemoveQuiz: "क्विज़ हटाएं",
    AddQuiz: "क्विज़ जोड़ें",
    RemoveLink: "लिंक हटाएं",
    AddLink: "लिंक जोड़ें",
    RemoveYouTubeLink: "YouTube लिंक हटाएं",
    AddYouTubeLink: "YouTube लिंक जोड़ें",
    RemoveExternalCourse: "बाहरी कोर्स हटाएं",
    AddExternalCourse: "बाहरी कोर्स जोड़ें",
    UntitledTopic: "शीर्षक रहित विषय",
    DashboardChart: "डैशबोर्ड चार्ट",
    LatestTrendInBusiness: "व्यापार में नवीनतम रुझान",
    InformationWillComeHere: "जानकारी यहाँ आएगी",
    CourseContent: "कोर्स सामग्री",
    DummyContent: "डमी सामग्री",
    EndCourse: "कोर्स समाप्त",
    Chapters: "अध्याय •",
    Lectures: "व्याख्यान •",
    LessonsInThisProgram: "इस कार्यक्रम में पाठ्य",
    BookInformation: "पुस्तक सूचना",
    TedTalksDaily: "टेडटॉक डेली",
    TopicTitle: "विषय शीर्षक",
    VisitThis: "इसे देखें",
    LearnPractical: "कोर्स के माध्यम से व्यावहारिक कौशल सीखें और पेड़ अपरेंटिसशिप प्राप्त करें।",
    Courses: "कोर्सेस",
    Book: "पुस्तक",
    Tedtalk: "टेडटॉक",
    Articles: "लेख",
    NoPageExist: "कोई पृष्ठ मौजूद नहीं है",
    Books: "पुस्तकें",
    TedTalks: "टेड टॉक्स",
    Videos: "वीडियो",
    GoTo: "जाएं",
    CourseLevelIdLabel: {
      [CourseLevelIdEnum.Advance]: "उन्नत",
      [CourseLevelIdEnum.Intermediate]: "इंटरमीडिएट",
      [CourseLevelIdEnum.Beginner]: "शुरुआती"
    }
  },
  attendence: {
    Attendance: "उपस्थिति",
    TimeSpent: "समय बिताया",
    GraceTime: "अनुग्रह समय",
    GoodDay: "शुभ दिन",
    Howfeeling: "आज आप कैसा महसूस कर रहे हैं?",
    Note: "नोट",
    Noattendanceforapproval: "अनुमोदन के लिए कोई उपस्थिति नहीं।",
    Iwaslate: "ट्रैफिक के कारण मैं देर से आया।",
    Showtimespent: "बिताया हुआ समय दिखाएं",
    ClockIn: "अपनी उपस्थिति लॉग शुरू करने के लिए क्लॉक-इन करें।",
    selectStatus: "कृपया एक स्थिति चुनें!",
    ByTime: "समय के अनुसार",
    ByStatus: "स्थिति के अनुसार",
    DeductionPolicy: "कटौती नीति",
    UpdateDeductionPolicy: "कटौती नीति अपडेट करें",
    AddDeductionPolicy: "कटौती नीति जोड़ें",
    DaysRange: "दिनों की सीमा",
    PerDay: "प्रति दिन",
    SaveDeductionPolicy: "कटौती नीति सहेजें",
    AbsentPolicy: "अनुपस्थिति नीति",
    LatePolicy: "देर से आने की नीति",
    IsPercentage: "प्रतिशत में है",

    attendanceFilterTypeEnum: {
      [AttendanceFilterTypeEnum.checkInForApproval]: "अनुमोदन के लिए चेक-इन",
      [AttendanceFilterTypeEnum.dayWiseAttendance]: "दैनिक उपस्थिति",
      [AttendanceFilterTypeEnum.userAttendance]: "उपयोगकर्ता उपस्थिति",
      [AttendanceFilterTypeEnum.Summary]: "सारांश",
    },

    updateErrorMessage: {
      [AttendanceStateEnum.Off]: "ऑफ स्थिति के लिए अपडेट क्रिया उपलब्ध नहीं है",
      [AttendanceStateEnum.Holiday]: "छुट्टियों के लिए अपडेट क्रिया उपलब्ध नहीं है",
      [AttendanceStateEnum.Leave]: "छुट्टी स्थिति के लिए अपडेट क्रिया उपलब्ध नहीं है",
    },
    statusEnum: {
      [AttendanceStatusEnum.InProcess]: "प्रक्रिया में",
      [AttendanceStatusEnum.Approved]: "अनुमोदित",
      [AttendanceStatusEnum.Declined]: "अस्वीकृत",
    },
    moodLabels: {
      [AttendanceMoodEnum.VeryUnsatisfied]: "बहुत असंतुष्ट",
      [AttendanceMoodEnum.Unsatisfied]: "असंतुष्ट",
      [AttendanceMoodEnum.Neutral]: "तटस्थ",
      [AttendanceMoodEnum.Satisfied]: "संतुष्ट",
      [AttendanceMoodEnum.VerySatisfied]: "बहुत संतुष्ट",
    },
    attendanceState: {
      [AttendanceStateEnum.Unknown]: "",
      [AttendanceStateEnum.Present]: "वर्तमान",
      [AttendanceStateEnum.Absent]: "अनुपस्थित",
      [AttendanceStateEnum.Leave]: "छुट्टी",
      [AttendanceStateEnum.Late]: "देर से",
      [AttendanceStateEnum.WorkFromHome]: "घर से काम",
      [AttendanceStateEnum.Off]: "बंद",
      [AttendanceStateEnum.Holiday]: "छुट्टी",
    },
    attendanceType: {
      [ATTENDANCE_ENUM.CHECK_IN.IN]: "चेक-इन",
      [ATTENDANCE_ENUM.CHECK_IN.OUT]: "चेक-आउट",
    },
    attendanceMetricLabel: {
      [AttendanceMetricTypeEnum.overallTimeSpent]: "कुल समय बिताया",
      [AttendanceMetricTypeEnum.Present]: "वर्तमान",
      [AttendanceMetricTypeEnum.Absent]: "अनुपस्थित",
      [AttendanceMetricTypeEnum.Leave]: "छुट्टी",
      [AttendanceMetricTypeEnum.Late]: "देर से",
    },
  },
  customApproval: {
    createCustomApproval: "कस्टम अनुमोदन बनाएं",
    customApproval: "कस्टम अनुमोदन",
    noDataMessage: "– अब कस्टम अनुमोदन बनाएं!",
    customApprovalFilterTypeEnum: {
      [CustomApprovalFilterTypeEnum.All]: "सभी कस्टम अनुमोदन",
      [CustomApprovalFilterTypeEnum.CreatedByMe]: "मेरा कस्टम अनुमोदन",
      [CustomApprovalFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
    },
    statusEnum: {
      [CustomApprovalStatusEum.InProcess]: "प्रक्रिया में",
      [CustomApprovalStatusEum.Approved]: "मंजूर",
      [CustomApprovalStatusEum.Declined]: "अस्वीकृत",
      [CustomApprovalStatusEum.Resend]: "पुनः भेजें",
      [CustomApprovalStatusEum.InActive]: "निष्क्रिय",
      [CustomApprovalStatusEum.NotRequired]: "आवश्यक नहीं",
      [CustomApprovalStatusEum.Cancelled]: "रद्द",
      [CustomApprovalStatusEum.Hold]: "रोके रखें",
    },
    SortEnum: {
      [CustomApprovalFilterSortEnum.UpdateDateDesc]: "अपडेट तारीख - अवरोही",
      [CustomApprovalFilterSortEnum.UpdateDateAsc]: "अपडेट तारीख",
      [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [CustomApprovalFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [CustomApprovalFilterSortEnum.CreateDateDesc]: "निर्माण तारीख - अवरोही",
      [CustomApprovalFilterSortEnum.CreateDateAsc]: "निर्माण तारीख"
    }

  },
  reward: {
    CreateReward: "रिवॉर्ड बनाएं",
    Rewards: "रिवॉर्ड्स",
    RewardTo: "किसे रिवॉर्ड देना है",
    RewardCategory: "रिवॉर्ड कैटेगरी",
    EnterRewardCategory: "रिवॉर्ड कैटेगरी डालें",
    RewardName: "रिवॉर्ड का नाम",
    EnterRewardName: "रिवॉर्ड का नाम डालें",
    PleaseEnterRewardName: "कृपया रिवॉर्ड का नाम डालें",
    ReasonForReward: "रिवॉर्ड का कारण",
    EnterReasonForReward: "रिवॉर्ड का कारण डालें",
    PleaseEnterReasonForReward: "कृपया रिवॉर्ड का कारण डालें",
    noDataMessage: "– अब रिवॉर्ड बनाएं!",
    rewardFilterTypeEnum: {
      [RewardFilterTypeEnum.All]: "सभी रिवॉर्ड्स",
      [RewardFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाए गए",
      [RewardFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [RewardFilterTypeEnum.RewardForMe]: "मेरे रिवॉर्ड्स",
    },
    statusEnum: {
      [RewardStatusEnum.InProcess]: "प्रक्रिया में",
      [RewardStatusEnum.Approved]: "मंजूर",
      [RewardStatusEnum.Declined]: "अस्वीकृत",
      [RewardStatusEnum.Cancelled]: "रद्द",
      [RewardStatusEnum.Hold]: "होल्ड",
    },
    SortEnum: {
      [RewardFilterSortEnum.UpdateDateDesc]: "तारीख अपडेट - अवरोही",
      [RewardFilterSortEnum.UpdateDateAsc]: "तारीख अपडेट",
      [RewardFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [RewardFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [RewardFilterSortEnum.StatusDesc]: "स्थिति - अवरोही",
      [RewardFilterSortEnum.Status]: "स्थिति",
      [RewardFilterSortEnum.CategoryDesc]: "श्रेणी - अवरोही",
      [RewardFilterSortEnum.Category]: "श्रेणी",
      [RewardFilterSortEnum.NameDesc]: "नाम - अवरोही",
      [RewardFilterSortEnum.Name]: "नाम",
      [RewardFilterSortEnum.CreateDateDesc]: "तारीख बनाई गई - अवरोही",
      [RewardFilterSortEnum.CreateDateAsc]: "तारीख बनाई गई"
    }
  },
  document: {
    mySigned: "मेरे द्वारा साइन किया गया",
    forMySignature: "मेरे साइन के लिए",
    draftSignature: "ड्राफ्ट",
    inSigning: "साइन हो रहा है",
    signaturesCompleted: "हस्ताक्षर पूरे हो गए",
    DocsArchive: "डॉक्स और आर्काइव्स",
    createDocument: "दस्तावेज़ बनाएं",
    CreateSlides: "स्लाइड्स बनाएं",
    CreateBoard: "बोर्ड बनाएं",
    CreatePad: "पैड बनाएं",
    CreateGrid: "ग्रिड बनाएं",
    newFolder: "नया फ़ोल्डर",
    createFolder: "फ़ोल्डर बनाएं",
    uploadDocuments: "दस्तावेज़ अपलोड करें",
    addBoard: "बोर्ड जोड़ें",
    addPad: "पैड जोड़ें",
    addGrid: "ग्रिड जोड़ें",
    addSlide: "स्लाइड जोड़ें",
    addMindmap: "माइंडमैप जोड़ें",
    CompleteDocument: "दस्तावेज़ पूरी करें",
    noDataMessage: "- अब दस्तावेज़ बनाएं!",
    AllDocuments: "सभी दस्तावेज़",
    MyDrive: "मेरी ड्राइव",
    MyDocuments: "मेरे दस्तावेज़ मंजूरी के लिए",
    ForApprovals: "मंजूरी के लिए",
    OnlyFiles: "केवल फाइलें",
    fileName: "फ़ाइल का नाम",
    shortDescription: "संक्षेप विवरण (अधिकतम 100 वर्ण)",
    AllFiles: "सभी फ़ाइलें",
    Grids: "ग्रिड्स",
    Pads: "पैड्स",
    Slides: "स्लाइड्स",
    Boards: "बोर्ड्स",
    Mindmaps: "माइंडमैप्स",
    updateFolder: "फ़ोल्डर अपडेट करें",
    updateGrid: "ग्रिड अपडेट करें",
    updatePad: "पैड अपडेट करें",
    updateSlide: "स्लाइड अपडेट करें",
    updateBoard: "बोर्ड अपडेट करें",
    updateMindMap: "माइंड मैप अपडेट करें",
    updateDocuments: "दस्तावेज़ अपडेट करें",

    status: {
      InProcess: "प्रक्रिया में",
      Approved: "मंजूर",
      Declined: "अस्वीकृत",
      Resend: "पुन: भेजें",
      NotRequired: "आवश्यक नहीं है",
      Cancelled: "रद्द",
      Draft: "ड्राफ्ट"
    },
    documentReferenceType: {
      [DocumentReferenceTypeEnum.General]: "सामान्य",
      [DocumentReferenceTypeEnum.Group]: "समूह",
      [DocumentReferenceTypeEnum.Project]: "परियोजना",
    },
  },
  warning: {
    WarningDetail: "चेतावनी विवरण",
    warningTo: "चेतावनी किसे",
    createWarning: "चेतावनी बनाएं",
    enterwarningCategory: "चेतावनी श्रेणी दर्ज करें",
    noDataMessage: '– अब चेतावनी बनाएं!',
    filter: "फ़िल्टर",
    warningFilterTypeEnum: {
      [WarningFilterTypeEnum.Warning]: "चेतावनियाँ",
      [WarningFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाई गई",
      [WarningFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [WarningFilterTypeEnum.WarningToMe]: "मेरे लिए चेतावनी",
    },
    statusEnum: {
      [WarningStatusEnum.InProcess]: "प्रक्रिया में",
      [WarningStatusEnum.Approved]: "मंजूर",
      [WarningStatusEnum.Declined]: "अस्वीकृत",
      [WarningStatusEnum.Cancelled]: "रद्द",
      [WarningStatusEnum.Hold]: "होल्ड",
    },
    SortEnum: {
      [WarningFilterSortEnum.UpdateDateDesc]: "अपडेट तिथि - अवरोही",
      [WarningFilterSortEnum.UpdateDateAsc]: "अपडेट तिथि - आरोही",
      [WarningFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [WarningFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [WarningFilterSortEnum.StatusDesc]: "स्थिति - अवरोही",
      [WarningFilterSortEnum.Status]: "स्थिति",
      [WarningFilterSortEnum.CategoryDesc]: "श्रेणी - अवरोही",
      [WarningFilterSortEnum.Category]: "श्रेणी",
      [WarningFilterSortEnum.NameDesc]: "नाम - अवरोही",
      [WarningFilterSortEnum.Name]: "नाम",
      [WarningFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [WarningFilterSortEnum.CreateDateAsc]: "निर्माण तिथि - आरोही"
    }

  },
  jobBoard: {
    jobBoard: "नौकरी बोर्ड",
    noDataMessage: " – अब करियर बनाएं!",
  },
  businessPolicy: {
    BusinessPolicy: "व्यापार नीति",
    policies: "नीतियाँ",
    noPolicy: "कोई नीति नहीं मिली...",
  },
  forms: {
    CreateForm: "फ़ॉर्म बनाएं",
    Forms: "फ़ॉर्म्स",
    Attempt: "प्रयास",
    CopyLink: "लिंक कॉपी करें",
    Title: "शीर्षक",
    EnterTitle: "शीर्षक दर्ज करें",
    PleaseEnterTitle: "कृपया शीर्षक दर्ज करें",
    PleaseFillTheQuestionField: "कृपया सवाल क्षेत्र भरें",
    EnterQuestion: "सवाल दर्ज करें",
    SelectAnswerType: "उत्तर का प्रकार चुनें",
    Text: "टेक्स्ट",
    Number: "संख्या",
    Polls: "मतदान",
    AddQuestion: "सवाल जोड़ें",
    AddOption: "विकल्प जोड़ें",
    Details: "विवरण",
    PoweredBy: "द्वारा संचालित",
    Submit: "प्रस्तुत करें",
    EditForm: "फ़ॉर्म संपादित करें",
    EnterEmail: "ईमेल दर्ज करें",
    Question: "सवाल...",
    YourAnswer: 'आपका जवाब',
    noDataMessage: "– अब फ़ॉर्म बनाएं!",
    formQuestionTypeEnum: {
      [FormQuestionTypeEnum.Number]: "संख्या",
      [FormQuestionTypeEnum.Text]: "टेक्स्ट",
      [FormQuestionTypeEnum.Poll]: "मतदान",
    },
    formFilterTypeEnum: {
      [FormFilterTypeEnum.All]: "सभी फ़ॉर्म्स",
      [FormFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाए गए",
      [FormFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [FormFilterTypeEnum.FormForMe]: "मेरे लिए फ़ॉर्म"
    },
    statusEnum: {
      [FormStatusEnum.InProcess]: "क्रियान्वित",
      [FormStatusEnum.Approved]: "मंजूर",
      [FormStatusEnum.Declined]: "अस्वीकृत",
      [FormStatusEnum.Resend]: "पुन: भेजें",
      [FormStatusEnum.InActive]: "निष्क्रिय",
      [FormStatusEnum.NotRequired]: "आवश्यक नहीं",
      [FormStatusEnum.Cancelled]: "रद्द",
      [FormStatusEnum.Hold]: "होल्ड",
    },
    SortEnum: {
      [FormFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [FormFilterSortEnum.CreateDateAsc]: "निर्माण तिथि",
      [FormFilterSortEnum.UpdateDateDesc]: "अपडेट तिथि - अवरोही",
      [FormFilterSortEnum.UpdateDateAsc]: "अपडेट तिथि",
      [FormFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [FormFilterSortEnum.ReferenceNo]: "संदर्भ संख्या"
    }
  },
  careers: {
    Careers: "करियर",
    CareerDetail: "करियर विवरण",
    createCareer: "करियर बनाएं",
    MinSalary: "न्यूनतम वेतन",
    MaxSalary: "अधिकतम वेतन",
    Applicants: "आवेदक",
    ShortListed: "शॉर्टलिस्टेड",
    Rejected: "अस्वीकृत",
    Completed: "पूर्ण",
    Finalist: "अंतिम उम्मीदवार",
    Maybe: "शायद",
    OfferSent: "प्रस्ताव भेजा गया",
    WaitingForInterview: "साक्षात्कार के लिए प्रतीक्षा",
    OfferAccepted: "प्रस्ताव स्वीकृत",
    OfferDeclined: "प्रस्ताव अस्वीकृत",
    OfferResend: "प्रस्ताव पुनः भेजें",
    noDataMessage: "– अब करियर बनाएं!",
    writeHeaderHere: "यहां हेडर लिखें",
    pickCurrentDate: "वर्तमान तिथि चुनें",
    enterAmount: "राशि दर्ज करें",
    selectApprovers: "मंजूरकर्ता चुनें",
    selectFinancers: "वित्तीय वितरक चुनें",
    selectExecutors: "निष्पादक चुनें",
    selectDesignation: "पदनाम चुनें",
    writeDescription: "विवरण लिखें",
    enterMinSalary: "न्यूनतम वेतन दर्ज करें",
    enterMaxSalary: "अधिकतम वेतन दर्ज करें",
    selectDepartment: "विभाग चुनें",
    selectSupervisor: "पर्यवेक्षक चुनें",
    selectInterviewers: "साक्षात्कारकर्ता चुनें",
    selectPostInterviewers: "पोस्ट साक्षात्कारकर्ता चुनें",
    description: "यहां अपना विवरण लिखें...",
    addSkills: "कौशल जोड़ें",
    selectJobType: "नौकरी का प्रकार चुनें",
    selectJobshift: "नौकरी का समय चुनें",
    selectEducation: "कृपया शिक्षा चुनें",
    selectCareerLevel: "करियर स्तर चुनें",
    selectEndDate: "समाप्ति तिथि चुनें",
    enterreviewcriteria: "समीक्षा मानदंड दर्ज करें",
    reviewCriteria: "समीक्षा मानदंड",
    enterDepartment: "कृपया विभाग दर्ज करें",
    supervisor: "पर्यवेक्षक",
    supervisorrequired: "पर्यवेक्षक आवश्यक है",
    postInterviewers: "पोस्ट साक्षात्कारकर्ता",
    jobShift: "नौकरी का समय",
    enterJobShift: "कृपया नौकरी का समय दर्ज करें",
    enterJobType: "कृपया नौकरी का प्रकार दर्ज करें",
    careerLevel: "करियर स्तर",
    selectBuddy: "हायरिंग बड़ी चुनें",
    selectInerview: "साक्षात्कारकर्ता चुनें",
    requiredInterview: "साक्षात्कारकर्ता की आवश्यकता है",
    enterCareerLevel: "कृपया करियर स्तर दर्ज करें",
    createJob: "नौकरी बनाएं",
    applyJob: "नौकरी पर आवेदन करें",
    firstName: "पहला नाम",
    enterFirstName: "पहला नाम दर्ज करें",
    requiredMessageFName: "पहला नाम आवश्यक है",
    lastName: "उपनाम",
    enterLastName: "उपनाम दर्ज करें",
    requiredMessageLName: "उपनाम आवश्यक है",
    email: "ईमेल",
    enterEmail: "ईमेल दर्ज करें",
    EmailIsRequired: "ईमेल आवश्यक है",
    phoneNumber: "फ़ोन नंबर",
    enterPhoneNumber: "फ़ोन नंबर दर्ज करें",
    currentSalary: "वर्तमान वेतन",
    expectedSalary: "अपेक्षित वेतन",
    radioMessageApplicant: "आवेदक",
    radioMessageShortlisted: "शॉर्टलिस्टेड",
    radioMessageCompleted: "पूर्ण",
    coverNote: "कवर नोट",
    noticePeriod: "पूर्व सूचना काल",
    requiredMessageFName: "कृपया पहला नाम दर्ज करें",
    requiredMessageLName: "कृपया उपनाम दर्ज करें",
    requiredMessageEmail: "कृपया ईमेल दर्ज करें",
    requiredMessagePhoneNumbeer: "कृपया फ़ोन नंबर दर्ज करें",
    requiredMessageCurrentSalary: "कृपया वर्तमान वेतन दर्ज करें",
    requiredMessageExpextedsalary: "कृपया अपेक्षित वेतन दर्ज करें",
    requiredMessageExperience: "कृपया अनुभव दर्ज करें",
    requiredMessageNoticePeriod: "कृपया पूर्व सूचना काल दर्ज करें",
    appliedDate: "आवेदन की तिथि",
    interviewSchedule: "साक्षात्कार का समयसारणी",
    applyNow: "अब आवेदन करें",
    jobPost: "नौकरी पोस्ट की गई",
    jobexpire: "नौकरी समाप्त हो गई है",
    skillrequired: "आवश्यक कौशलें",
    joblocation: "नौकरी स्थान",
    jobType: "नौकरी का प्रकार",
    jobDetails: "नौकरी विवरण",
    EducationLevel: "शिक्षा स्तर",
    MinimumSalary: "न्यूनतम वेतन",
    MaximumSalary: "अधिकतम वेतन",
    YearOfExperience: "अनुभव का वर्ष",
    errormessage: "स्थिति बदली नहीं जा सकती",
    MakeOffer: "प्रस्ताव बनाएं",
    updatestatus: "क्या आप स्थिति अपडेट करना चाहते हैं?",
    Pleaseenterminsalary: "कृपया न्यूनतम वेतन दर्ज करें",
    Pleaseentermaxsalary: "कृपया अधिकतम वेतन दर्ज करें",
    NoRequirements: "कोई आवश्यकता नहीं",
    statusEnum: {
      [CareerStatusEnum.InProcess]: "प्रक्रिया में",
      [CareerStatusEnum.Approved]: "मंजूर है",
      [CareerStatusEnum.Declined]: "अस्वीकृत है",
      [CareerStatusEnum.Cancelled]: "रद्द है",
      [CareerStatusEnum.Hold]: "होल्ड है",
    },
    careerFilterTypeEnum: {
      [CareerFilterTypeEnum.All]: "सभी करियर",
      [CareerFilterTypeEnum.MyCareers]: "मेरे करियर",
      [CareerFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
    },
  },

  /*** HR Menu ***/
  myTeam: {
    MyTeams: "मेरी टीमें",
    EmployeeNo: "कर्मचारी संख्या",
    noDataMessage: "अब टीमें बनाएं!",
    teamDetails: "टीम का विवरण",
    DeductionPerMonth: "महीने का कटौती",
    teamActivities: {
      attendence: "उपस्थिति",
      checkIn: "चेक इन",
      leaves: "अवकाश",
      rewards: "पुरस्कार",
      appraisals: "मूल्यांकन",
      warnings: "चेतावनियाँ",
      complains: "शिकायतें",
      courses: "कोर्सेस",
      education: "शिक्षा",
      experience: "अनुभव",
      loan: "ऋण",
      activityLog: "गतिविधि लॉग",
      checkInCheckOut: " चेक-इन / चेक-आउट",
      storageStatistics: "संग्रहण सांख्यिकी"
    },

  },
  administration: {
    Company_Goal: "कंपनी का लक्ष्य",
    basicInfo: "मूल जानकारी",
    Subsidiary_Office: "उपकंप कार्यालय",
    AddSubsidiary: "उपकंपनी जोड़ें",
    Fiscal_Year: "वित्तीय वर्ष",
    Tax_Slabs: "कर स्लैब्स",
    Tax_Slabs_Group: "कर स्लैब्स समूह",
    E_Learning_Category: "ई-लर्निंग श्रेणी",
    Company_Policy: "कंपनी नीतियां",
    Default_Hiring_Criteria: "डिफ़ॉल्ट हायरिंग मापदंड",
    subsidiary: "उपकंप",
    Business_Logo: "बिजनेस लोगो",
    Complain_Category: "शिकायत श्रेणी",
    Custom_Tag: "कस्टम टैग",
    Payroll_Group: "पेरोल समूह",
    Business_Policy: "बिजनेस नीति",
    Companies_Policy: "कंपनी नीतियां",
    Billing: "बिलिंग",
    Rebate_Category: "रीबेट श्रेणी",
    Assets_Category: "संपत्ति श्रेणी",
    AccessRole: "पहुंच भूमिका",
    Grade: "ग्रेड",
    Default_Account_Header: "डिफ़ॉल्ट खाता हेडर",
    Business_Paging: "बिजनेस नंबर",
    Discussion_Board_Category: "चर्चा बोर्ड श्रेणी",
    Room: "कक्ष",
    Business_Association: "बिजनेस संघ",
    Designation: "नामांकन",
    Appraisal: "मूल्यांकन",
    Office_Timings: "कार्यालय समय",
    Leave_Types: "अवकाश प्रकार",
    User_Types: "उपयोगकर्ता प्रकार",
    Expense_Headers: "व्यय हैडर",
    Salary_Headers: "वेतन हैडर",
    Request_For_Right: "हक के लिए अनुरोध",
    Custom_Approval_Category: "कस्टम मंजूरी श्रेणी",
    GRADE_ALLOWANCES: "ग्रेड अनुमतियाँ",
    Paging_Category: "पेजिंग श्रेणी",
    Page_Category: "पृष्ठ श्रेणी ",
    Project_Category: "परियोजना श्रेणी",
    Email_Configuration: "ईमेल कॉन्फ़िगरेशन",
    Allowances: "अनुमतियाँ",
    Default_Approvals: "डिफ़ॉल्ट मंजूरी",
    NonMandatory_Approvals: "गैर अनिवार्य मंजूरी",
    WORKGATING: "WIP गेटिंग मानक",
    Job_Description: "नौकरी का विवरण",
    Rewards: "पुरस्कार",
    Reward_Category: "पुरस्कार श्रेणी",
    Warning_Category: "चेतावनी श्रेणी",
    Holidays: "छुट्टियाँ",
    paymentCard: "भुगतान कार्ड",
    Company_Info: "कंपनी की जानकारी",
    business_Info:"व्यापार जानकारी",
    Business_Approval: "बिजनेस मंजूरी",
    COMPANY_STATISTICS: "कंपनी के आंकड़े",
    ATTENDANCE_SETTINGS: 'उपस्थिति सेटिंग्स',
    businessInfo : {
      businessInformation: 'व्यापार जानकारी',
      companyBackgroundLabel: 'कंपनी पृष्ठभूमि',
      productAndServicesLable: 'उत्पाद और सेवाएं',
      competitorsLable: 'प्रतिद्वंद्वी',
      recentNewsLable: 'हाल की खबरें',
      leadershipTeamLabel: 'नेतृत्व टीम',
      organizationalStructureLabel: 'संगठनात्मक संरचना',
      customerBaseLabel: 'ग्राहक आधार',
      FinancialHealthLabel: 'वित्तीय स्वास्थ्य',
      industryLabel: 'उद्योग',
      websiteAndSocialMediaLabel: 'वेबसाइट और सोशल मीडिया',
      additionalCommentLabel: 'अतिरिक्त टिप्पणियाँ',
      companyCultureLabel: 'कंपनी संस्कृति',
      ceoLabel: 'सीईओ',
      companyBackground: {
          mission: 'मिशन',
          vision: 'दृष्टि',
          values: 'मूल्य',
          YearFounded: 'स्थापना वर्ष',
          placeHolders: {
              enterMission: 'मिशन दर्ज करें',
              enterVision: 'दृष्टि दर्ज करें',
              enterValues: 'मूल्य दर्ज करें',
              enterYearFounded: 'स्थापना वर्ष दर्ज करें',
          },
          requiredMessages: {
              enterMission: 'कृपया मिशन दर्ज करें',
              enterVision: 'कृपया दृष्टि दर्ज करें',
              enterValues: 'कृपया मूल्य दर्ज करें',
              enterYearFounded: 'कृपया स्थापना वर्ष दर्ज करें',
          }
      },
      productAndService: {
          Benefits: 'लाभ'
      },
      competitors: {
          MarketPosition: 'बाजार स्थिति',
          Strength: 'ताकत',
          Weakness: 'कमजोरी',
      },
      recentNews: {
          Headline: 'शीर्षक',
          News: 'समाचार'
      },
      leadershipTeam: {
          Background: 'पृष्ठभूमि',
          ContactNumber: 'संपर्क नंबर',
      },
      financialHealth: {
          Revenue: 'राजस्व',
          Profitability: 'लाभप्रदता'
      },
      additionalComment: {
          label: {
              comments: 'टिप्पणियाँ'
          },
          placeholders: {
              comments: 'टिप्पणियाँ दर्ज करें'
          },
          requireMessages: {
              enterComments: 'कृपया टिप्पणियाँ दर्ज करें'
          }
      },
      comapnyCulture: {
          placeHolders: {
              enterDescription: 'विवरण दर्ज करें',
              enterWorkEnvironment: 'कार्य वातावरण दर्ज करें'
          },
          label: {
              description: 'विवरण',
              workEnvironment: 'कार्य वातावरण'
          },
          requiredMessages: {
              enterDescription: 'कृपया विवरण दर्ज करें',
              enterWorkEnvironment: 'कृपया कार्य वातावरण दर्ज करें'
          }
      }
  },
    Form: {
      Name: "नाम",
      Entername: "नाम दर्ज करें",
      PleaseEnterName: "कृपया नाम दर्ज करें",
      Description: "विवरण",
      enterDescription: "कृपया विवरण दर्ज करें",
      Create: "बनाएँ",
      Add: "जोड़ें",
      Save: "सहेजें",
      Clear: "साफ़ करें",
      CategoryName: "श्रेणी का नाम",
      Entercategoryname: "श्रेणी का नाम दर्ज करें",
      SaveCategory: "श्रेणी सहेजें",
      Date: "तारीख",
      startDate: "आरंभ तिथि",
      endDate: "समाप्ति तिथि",
      Upload: "अपलोड करें",
      Percent: "प्रतिशत",
      Amount: "राशि",
      EnterAmount: "राशि दर्ज करें",
      addCategory: "श्रेणी जोड़ें",
      SelectMember: "सदस्य चयन करें",
      Email: "ईमेल",
      EnterEmail: "ईमेल दर्ज करें",
      Types: "प्रकार",
      SelectStatus: "स्थिति चयन करें",
      Submit: "प्रस्तुत करें",
      Nomoreapprovals: "और कोई मंजूरी नहीं",
    },
    Table: {
      Name: "नाम",
      Members: "सदस्य",
      Description: "विवरण",
      Value: "मूल्य",
      Allot_Leave: "अवकाश आवंटित करें",
      Type: "प्रकार",
      Unit: "इकाई",
      Taxable: "कर लेने योग्य",
      Provider: "प्रदाता",
      IncomingServerAddress: "इनकमिंग सर्वर पता",
      OutgoingServerAddress: "आउटगोइंग सर्वर पता",
      IncomingPort: "इनकमिंग पोर्ट",
      OutgoingPort: "आउटगोइंग पोर्ट",
      Action: "क्रिया",
      startDate: "आरंभ तिथि",
      endDate: "समाप्ति तिथि",
      Actions: "क्रियाएँ",
      Account: "खाता",
      approvers: "मंजूरकर्ता",
      Status: "स्थिति",
      Allocated: "आवंटित",
      AddMember: "सदस्य जोड़ें",
      CreateDate: "तिथि बनाएँ",
    },
    accessRole: {
      Button: {
        AddAccessRole: "पहुंच भूमिका जोड़ें",
        UpdateAccessRole: "पहुंच भूमिका अपडेट करें",
      },
      Drawer: {
        CreateAccessRole: "पहुंच भूमिका बनाएं",
        UpdateAccessRole: "पहुंच भूमिका अपडेट करें",
        UserType: "उपयोगकर्ता प्रकार",
        Description: "विवरण",
        placeholders: {
          EnterName: "पहुंच भूमिका का नाम दर्ज करें",
          UserType: "कृपया उपयोगकर्ता प्रकार का चयन करें",
          Description: "विवरण दर्ज करें",
        },
        formLabel: {
          AccessRoleName: "पहुंच भूमिका का नाम",
        },
        placeholders: {
          EnterName: "पहुंच भूमिका दर्ज करें",
          SelectUserType: "उपयोगकर्ता प्रकार का चयन करें",
          EnterDescription: "विवरण दर्ज करें",
        },
      },
    },
    grade: {
      AddGrade: "ग्रेड जोड़ें",
      EnterGrade: "ग्रेड दर्ज करें",
      EnterDescription: "विवरण दर्ज करें",
      Grade: "ग्रेड",
      Description: "विवरण",
      Name: "नाम",
      clear: "साफ करें",
      saveGrade: "ग्रेड सहेजें",
    },
    fiscalyear: {
      Add: "जोड़ें",
      EnterName: "नाम दर्ज करें",
      EnterDescription: "विवरण दर्ज करें",
      Fiscalyear: "वित्तीय वर्ष",
      description: "विवरण",
      name: "नाम",
      clear: "साफ करें",
      save: "सहेजें ",
      startyear: "प्रारंभ वर्ष",
      endyear: "समाप्त वर्ष",
      startMonth: "प्रारंभ माह",
      endMonth: "समाप्ति माह",
      startDate: "प्रारंभ तिथि",
      endDate: "समाप्ति तिथि",
    },
    businessPolicy: {
      companypolicy: "कंपनी नीतियां",
      enterName: "नाम दर्ज करें",
      name: "नाम",
      type: "प्रकार चुनें",
      typee: "प्रकार",
      description: "विवरण",
      EnterDescription: "विवरण दर्ज करें",
      create: "बनाएं",
      save: "सहेजें",
      createPolicy: "नीति बनाएं",
      updatePolicy: "नीति अपडेट करें",
    },
    taxSlab: {
      TaxSlabs: "टैक्स स्लैब",
      UpdateTaxSlab: "टैक्स स्लैब अपडेट करें",
      CreateTaxSlab: "टैक्स स्लैब बनाएं",
      NoTaxSlabGroup: "कोई टैक्स स्लैब समूह नहीं...",
      enterCountry: "देश चुनें",
      enterName: "नाम दर्ज करें",
      enterDescription: "विवरण दर्ज करें",
      description: "विवरण",
      name: "नाम",
      TaxSlab: "टैक्स स्लैब",
      title: "शीर्षक",
      enterTitle: "शीर्षक दर्ज करें",
      percentage: "प्रतिशत",
      previousCharge: "पिछला शुल्क",
      min: "न्यूनतम",
      max: "अधिकतम",
      enterMin: "न्यूनतम दर्ज करें",
      enterMax: "अधिकतम दर्ज करें",
      enterPercent: "प्रतिशत दर्ज करें",
      save: "सहेजें",
      clear: "साफ़ करें",
      Add: "जोड़ें",
      TaxSlabgroup: "टैक्स स्लैब समूह",
      country: "देश",
      minimumCharge: "न्यूनतम शुल्क",
    },
    payrollGroup: {
      PayrollGroup: "पेरोल समूह",
      save: "समूह सहेजें",
      clear: "साफ करें",
      Add: "समूह जोड़ें",
      name: "नाम",
      enterName: "नाम दर्ज करें",
    },
    subsidiaryOffice: {
      enterName: "नाम दर्ज करें",
      enterAddress: "पता दर्ज करें",
      name: "नाम",
      subsidiary_Office: "उपकंप कार्यालय",
      latitude: "अक्षांश",
      longitude: "देशांतर",
      address: "पता",
      branch: "शाखा",
      Subsidiary: "उपकंप",
      select: "उपकंप चुनें",
      save: "सहेजें",
      clear: "साफ करें",
      Add: "जोड़ें",
      title: "शीर्षक",
      enterTitle: "शीर्षक दर्ज करें",
    },
    defaultHiringCriteria: {
      default: "डिफ़ॉल्ट हायरिंग मापदंड",
      question: "प्रश्न",
      enterQuestions: "प्रश्न दर्ज करें",
      save: "प्रश्न सहेजें",
      clear: "साफ करें",
      Add: "प्रश्न जोड़ें",
    },
    workGatingCriteria: {
      work: "WIP गेटिंग मानक",
      question: "प्रश्न",
      enterQuestions: "प्रश्न दर्ज करें",
      save: "प्रश्न सहेजें",
      clear: "साफ करें",
      Add: "प्रश्न जोड़ें",
    },
    designation: {
      desig: "नामांकन",
      enterdesig: "नामांकन दर्ज करें",
      enterName: "नाम दर्ज करें",
      enterDescription: "विवरण दर्ज करें",
      description: "विवरण",
      name: "नाम",
      save: "नामांकन सहेजें",
      clear: "साफ करें",
      Add: "नामांकन जोड़ें",
    },
    appraisal: {
      Appraisal: "मूल्यांकन",
      enterAppraisal: "मूल्यांकन दर्ज करें",
      enterDescription: "विवरण दर्ज करें",
      description: "विवरण",
      save: "मूल्यांकन सहेजें",
      clear: "साफ करें",
      Add: "मूल्यांकन जोड़ें",
      CompanyGoal: "कंपनी का लक्ष्य",
      IndividualGoal: "व्यक्तिगत लक्ष्य",
      DepartmentsGoal: "विभागों का लक्ष्य",
      Goal: "लक्ष्य",
      saveGoal: "लक्ष्य सहेजें",
      AddCompanyGoal: "कंपनी का लक्ष्य जोड़ें",
      AddDepartmentGoal: "विभाग का लक्ष्य जोड़ें",
      AddIndividualGoal: "व्यक्तिगत लक्ष्य जोड़ें",
      enterCompanyGoal: "कंपनी का लक्ष्य दर्ज करें",
      enterDepartmentGoal: "विभाग का लक्ष्य दर्ज करें",
      enterIndividualGoal: "व्यक्तिगत लक्ष्य दर्ज करें",
    },
    office: {
      Office_Timing: "कार्यालय समय",
      group: "समूह नाम",
      enterGroup: "नाम दर्ज करें",
      enterDescription: "विवरण दर्ज करें",
      description: "विवरण",
      save: "कार्यालय समय सहेजें",
      clear: "साफ करें",
      Add: "कार्यालय समय जोड़ें",
      days: "दिन",
      work: "काम कर रहा है",
      checkIn: "चेक इन",
      checkOut: "चेक आउट",
      time: "क्षमा समय",
      min: "न्यूनतम",
      create: "समूह बनाएं",
      new: "नया समय समूह",
      UpdateOfficeTiming: "कार्यालय समय अपडेट करें",
      CreateOfficeTiming: "कार्यालय समय बनाएं",
      Monday: "सोमवार",
      Tuesday: "मंगलवार",
      Wednesday: "बुधवार",
      Thursday: "गुरूवार",
      Friday: "शुक्रवार",
      Saturday: "शनिवार",
      Sunday: "रविवार",

    },
    leave: {
      Leave: "अवकाश प्रकार",
      leaveType: "अवकाश प्रकार",
      enterLeave: "अवकाश प्रकार दर्ज करें",
      save: "अवकाश प्रकार सहेजें",
      clear: "साफ करें",
      Add: "अवकाश प्रकार जोड़ें",
      enterDescription: "विवरण दर्ज करें",
      description: "विवरण",
      allotLeaves: "आवंटित अवकाश",
      balanceLeaves: "अवकाश प्रकार (शेष अवकाश)",
      selectType: "प्रकार चुनें",
      leaveFor: "के लिए छुट्टी",
      leaveDates: "अवकाश तिथियाँ",
      createleave: "अवकाश बनाएं",
    },
    expense: {
      Expense: "व्यय हैडर",
      enterExpense: "व्यय हैडर दर्ज करें",
      enterDescription: "विवरण दर्ज करें",
      description: "विवरण",
      save: "व्यय हैडर सहेजें",
      clear: "साफ करें",
      Add: "व्यय हैडर जोड़ें",
    },
    elearningCategory: {
      elearningCategory: "ई-लर्निंग श्रेणी",
      description: "विवरण",
      enterelearningCategory: "श्रेणी दर्ज करें",
      enterDescription: "विवरण दर्ज करें",
      save: "ई-लर्निंग श्रेणी सहेजें",
      clear: "साफ करें",
      Add: "ई-लर्निंग श्रेणी जोड़ें",
    },
    salaryHeader: {
      SalaryHeader: "वेतन हेडर",
      enterSalaryHeader: "वेतन हेडर दर्ज करें",
      desc: "विवरण",
      enterDesc: "विवरण दर्ज करें",
      saveHeader: "हेडर सहेजें",
      addHeader: "हेडर जोड़ें",
      name: "नाम",
      clear: "साफ़ करें",
    },
    room: {
      Room: "कमरा",
      Name: "नाम",
      Entername: "नाम दर्ज करें",
      Description: "विवरण",
      Enterdescription: "विवरण दर्ज करें",
      Location: "स्थान",
      Enterlocation: "स्थान दर्ज करें",
      TotalSeats: "कुल सीटें",
      Enterseats: "सीटें दर्ज करें",
      SaveRoom: "कमरा सहेजें",
      AddRoom: "कमरा जोड़ें",
      clear: "साफ़ करें",
    },
    RebateCategory: {
      rebateCategory: "रिबेट श्रेणी",
      maxPercentage: "अधिकतम प्रतिशत",
      entermaxPercentage: "अधिकतम प्रतिशत दर्ज करें",
      maxAmount: "अधिकतम राशि",
      enterMaxAmount: "अधिकतम राशि दर्ज करें",
      type: "प्रकार",
      basic: "मौलिक",
      tax: "कर",
      fullAmount: "पूर्ण राशि",
      rebateType: "रिबेट प्रकार",
    },
    projectCategory: {
      ProjectCategory: "परियोजना श्रेणी",
      AddProjectCategory: "परियोजना श्रेणी जोड़ें",
    },
    pageCategory: {
      Pagecategory: "पृष्ठ श्रेणी",
      AddPageCategory: "पृष्ठ श्रेणी जोड़ें",
    },
    NonMandatoryApprovals: {
      NonMandatoryApproval: "गैर अनिवार्य मंजूरी",
    },
    jobdescription: {
      jobDesc: "नौकरी का विवरण",
      designation: "पदनाम",
      selectDesign: "पदनाम चयन करें",
      saveJobDesc: "नौकरी का विवरण सहेजें",
      addJobdesc: "नौकरी का विवरण जोड़ें",
    },
    Holiday: {
      Holidays: "छुट्टियाँ",
      AddHoliDay: "छुट्टी जोड़ें"
    },
    GradeAllowance: {
      gradeAllowance: "ग्रेड भत्ता",
      Grades: "ग्रेड",
      AllowanceType: "भत्ता प्रकार",
      SelectType: "प्रकार चयन करें",
      AddAllowance: "भत्ता जोड़ें",
      UpdateAllowance: "भत्ता अपडेट करें",
      Allowance: "भत्ता",
      IsTaxable: "कर लेने योग्य है",
    },
    EmailConfiguration: {
      emailConfi: "ईमेल कॉन्फ़िगरेशन",
      enterEmailConfi: "ईमेल कॉन्फ़िगरेशन दर्ज करें",
      incomingPort: "इनकमिंग पोर्ट",
      enterIncomingPort: "इनकमिंग पोर्ट दर्ज करें",
      incomingServerAddress: "इनकमिंग सर्वर पता",
      enterIncomingServerAddress: "इनकमिंग सर्वर पता दर्ज करें",
      outgoingPort: "आउटगोइंग पोर्ट",
      enterOutgoingPort: "आउटगोइंग पोर्ट दर्ज करें",
      outgoingServerAddress: "आउटगोइंग सर्वर पता",
      enterOutgoingServerAddress: "आउटगोइंग सर्वर पता दर्ज करें",
      provider: "प्रदाता",
      enterProvider: "प्रदाता दर्ज करें",
      saveEmailConfi: "ईमेल कॉन्फ़िगरेशन सहेजें",
      addEmailConfi: "ईमेल कॉन्फ़िगरेशन जोड़ें",
    },
    DiscussionBoardCategory: {
      discussionBoardCategory: "चर्चा बोर्ड श्रेणी",
      SaveDiscussionBoardCategory: "चर्चा बोर्ड श्रेणी सहेजें",
      AddDiscussionBoardCategory: "चर्चा बोर्ड श्रेणी जोड़ें",
    },
    DefaultApprovers: {
      defaultApprovers: "डिफ़ॉल्ट मंजूरकर्ता",
    },
    DefaultAccountHeader: {
      defaultAccountHeader: "डिफ़ॉल्ट खाता हेडर",
      SaveAccountHeader: "खाता हेडर सहेजें",
      SelectAccountTypes: "खाता प्रकार चयन करें",
    },
    CustomTag: {
      customTag: "कस्टम टैग",
      Entercustomtag: "कस्टम टैग दर्ज करें",
      SaveCustomTag: "कस्टम टैग सहेजें",
      AddCustomTag: "कस्टम टैग जोड़ें",
    },
    customApprovalCategory: {
      customApprovalcategory: "कस्टम मंजूरी श्रेणी",
      enterCustomApprovalcategory: "कस्टम मंजूरी श्रेणी दर्ज करें",
    },
    BusinessNumber: {
      Manual: "मैनुअल",
      TotalNumber: "कुल संख्या",
      Country: "देश",
      SelectCountry: "देश चयन करें",
      Number: "संख्या",
      Random: "रैंडम",
      SelectNumber: "संख्या चयन करें",
      Cost: "लागत",
      Request: "अनुरोध",
    },
    BusinessAssociation: {
      businessAssociation: "व्यापार संघ",
      SaveBusinessAssociation: "व्यापार संघ सहेजें",
      AddBusinessAssociation: "व्यापार संघ जोड़ें",
      AssociationType: "संघ प्रकार",
    },
    AssetsCategory: {
      assetsCategory: "संपत्ति श्रेणी",
      categoryName: "श्रेणी का नाम",
      enterCategoryName: "श्रेणी का नाम दर्ज करें",
      desc: "विवरण",
      enterDesc: "विवरण दर्ज करें",
      accountType: "खाता प्रकार",
      parentAccount: "मुख्य खाता",
      selectParent: "मुख्य खाता चयन करें",
      selectType: "प्रकार चयन करें",
      add: "जोड़ें",
      save: "सहेजें",
      clear: "साफ़ करें",
      name: "नाम",
      assets: "संपत्ति",
      liability: "दायित्व",
      capital: "पूंजी",
      revenue: "राजस्व",
      expense: "व्यय",
      costOfGoodsale: "बिक्री की लागत",
    },
    Allowance: {
      allowance: "भत्ता",
      enterAllownace: "भत्ता दर्ज करें",
      percent: "प्रतिशत",
      desc: "विवरण दर्ज करें",
      grade: "ग्रेड",
      selectGrade: "ग्रेड चयन करें",
      amount: "राशि",
      benefit: "लाभ",
      deduction: "कटौती",
      texable: "कर लेने योग्य",
      nonTexable: "कर लेने योग्य नहीं",
      director: "निर्देशक",
      ceo: "मुख्य कार्यकारी अधिकारी",
      addAllowance: "भत्ता जोड़ें",
      saveAllowance: "भत्ता सहेजें",
      clear: "साफ़ करें",
      name: "नाम",
      allowanceType: "भत्ता प्रकार",
      allowanceUnit: "भत्ता इकाई",
      istexable: "कर लेने योग्य है",
      yes: "हाँ",
      no: "नहीं",
    },
    CompanyInfo: {
      businessLogo: "व्यापार लोगो",
      basicinfo: "मौलिक जानकारी",
      ShortLogo: "संक्षिप्त लोगो",
      Logo: "लोगो",
      FullLogo: "पूर्ण लोगो",
      companyName: "कंपनी का नाम",
      PleaseEnterCompanyName: "कृपया कंपनी का नाम दर्ज करें",
      enterCompanyName: "कंपनी का नाम दर्ज करें",
      companyEmail: "कंपनी का ईमेल दर्ज करें",
      PleaseEnterCompanyEmail: "कृपया कंपनी का ईमेल दर्ज करें",
      SelectOwnerName: "मालिक का नाम चुनें",
      totalEmployees: "कुल कर्मचारी",
      totalEmployeesInCall: "कॉल में कुल कर्मचारी",
      SaveShortLogo: "संक्षिप्त लोगो सहेजें",
      LongLogo: "लंबा लोगो",
      SaveLongLogo: "लंबे लोगो को सहेजें",
      SaveFullLogo: "पूर्ण लोगो सहेजें",
    },
    billing: {
      Invoice: "चालान",
      InvoiceNo: "चालान नंबर",
      CustomerName: "ग्राहक का नाम",
      PayInvoice: "चालान भुगतान करें",
      Payment: "भुगतान",
      BillNo: "बिल नंबर",
      CardLast: "कार्ड का आखिरी 4 अंक",
      BusinessName: "व्यवसाय का नाम",
      CardHolder: "कार्ड धारक",
      Viewinvoicedetails: "चालान और भुगतान विवरण देखें",
      Viewinvoicedetail: "चालान विवरण देखें",
      Qty: "मात्रा",
    },
    PaymentCard: {
      paymentCard: "भुगतान कार्ड",
      CardHolderName: "कार्ड धारक का नाम",
      AddCard: "कार्ड जोड़ें",
      PayInvoice: "चालान भुगतान करें",
      CardHolderNameRequired: "कार्ड धारक का नाम आवश्यक है।",
      PayCard: "कार्ड से भुगतान करें",
      payanotherway: "या दूसरे तरीके से भुगतान करें",
      credentialstocontinue: "जारी रखने के लिए अपने क्रेडेंशियल दर्ज करें।",
      Expiry: "समाप्ति",
      Brand: "ब्रांड",
      Country: "देश",
      LastFourDigits: "आखिरी चार अंक",
    },

  },
  billing: {
    BillType: "बिल का प्रकार",
    billingTypeEnum: {
      [BillingTypeEnum.NewUser]: "नया उपयोगकर्ता",
      [BillingTypeEnum.MonthlySubscription]: "मासिक सदस्यता",
      [BillingTypeEnum.NewBusinessNumber]: "नया व्यापार नंबर"
    }
  },
  businessNumber: {
    statusEnum: {
      [BuisnessNumberStatusEnum.Waiting]: "इंतजार कर रहा है",
      [BuisnessNumberStatusEnum.Declined]: "इंकार किया गया",
      [BuisnessNumberStatusEnum.NumberCancelled]: "नंबर रद्द हो गया",
      [BuisnessNumberStatusEnum.Purchased]: "क्रय किया गया",
      [BuisnessNumberStatusEnum.RequestCancelled]: "अनुरोध रद्द हो गया",
      [BuisnessNumberStatusEnum.Rejected]: "अस्वीकृत",
    },
  },
  businessApproval: {
    statusTypeList: {
      [ApprovalStatusTypeEnum.InProcess]: "प्रक्रिया में",
      [ApprovalStatusTypeEnum.Approved]: "मंजूर",
      [ApprovalStatusTypeEnum.Declined]: "अस्वीकृत",
      [ApprovalStatusTypeEnum.Cancelled]: "रद्द",
      [ApprovalStatusTypeEnum.Completed]: "पूर्ण",
    }
  },
  appraisal: {
    Appraisals: "मूल्यांकन",
    createAppraisals: "मूल्यांकन बनाएं",
    noDataMessage: "– अब मूल्यांकन बनाएं!",
    Approvers: "मंजूरकर्ता",
    Questions: "प्रश्न",
    Tasks: "कार्य",
    Bonus: "बोनस",
    Increment: "वृद्धि",
    previousApraisals: "पिछले मूल्यांकन",
    warning: "चेतावनी",
    rewards: "पुरस्कार",
    courses: "कोर्सेस",
    Promotion: "पदोन्नति",
    PleaseEnterBonusPercentage: "कृपया बोनस प्रतिशत दर्ज करें",
    PleaseEnterBonusAmount: "कृपया बोनस राशि दर्ज करें",
    PleaseEnterIncrementAmount: "कृपया वृद्धि राशि दर्ज करें",
    RateAtleastOneItem: "कम से कम एक आइटम को मूल्यांकित करें",
    Noemployeeselected: "कोई कर्मचारी नहीं चयनित हुआ",
    submitAppraisal: "मूल्यांकन सबमिट करें",
    Appraisedof: "का मूल्यांकन किया गया",
    AppraisalApprovers: "मूल्यांकन मंजूरकर्ता",
    AppraisalDetail: "मूल्यांकन विवरण",
    appraisalFilterTypeEnum: {
      [AppraisalFilterEnum.All]: "सभी मूल्यांकन",
      [AppraisalFilterEnum.CreatedByMe]: "मेरे द्वारा बनाए गए",
      [AppraisalFilterEnum.ForApproval]: "मंजूरी के लिए",
      [AppraisalFilterEnum.TeamAppraisal]: "टीम मूल्यांकन",
      [AppraisalFilterEnum.MyApprovedAppraisal]: "मेरा मंजूर मूल्यांकन",
    },
    statusEnum: {
      [AppraisalStatusEnum.InProcess]: "क्रियाशील",
      [AppraisalStatusEnum.Approved]: "मंजूर",
      [AppraisalStatusEnum.Declined]: "अस्वीकृत",
      [AppraisalStatusEnum.Cancelled]: "रद्द",
      [AppraisalStatusEnum.Hold]: "धारित",
    },
  },
  loan: {
    loan: "ऋण",
    createLoan: "ऋण बनाएं",
    loanTenure: "ऋण अवधि (महीनों में)",
    enterLoanTenure: "ऋण अवधि दर्ज करें (महीनों में)",
    deduction: "कटौती/महीने",
    selectLoanPurpose: "ऋण का उद्देश्य चुनें",
    noDataMessage: "– अभी ऋण बनाएं!",
    SortBy: "इसके अनुसार क्रमबद्ध करें",
    SelectSortBy: "क्रमबद्ध करने के लिए चुनें",
    InterestRate: "ब्याज दर",
    loanPurpose: {
      loanPurpose: "ऋण का उद्देश्य (Loan Purpose)",
      vehicle: "वाहन (Vehicle)",
      personal: "व्यक्तिगत (Personal)",
      wedding: "विवाह (Wedding)",
      medical: "चिकित्सा (Medical)",
      education: "शिक्षा (Education)",
      house: "घर (House)",
      other: "अन्य (Other)",
    },
    loanPurposeEnumList: {
      [LoanPurposeEnum.Personal]: "व्यक्तिगत (Personal)",
      [LoanPurposeEnum.Wedding]: "विवाह (Wedding)",
      [LoanPurposeEnum.Medical]: "चिकित्सा (Medical)",
      [LoanPurposeEnum.Education]: "शिक्षा (Education)",
      [LoanPurposeEnum.House]: "घर (House)",
      [LoanPurposeEnum.Vehicle]: "वाहन (Vehicle)",
      [LoanPurposeEnum.Other]: "अन्य (Other)",
    },
    loanFilterTypeEnum: {
      [LoanFilterTypeEnum.All]: "सभी ऋण (All Loans)",
      [LoanFilterTypeEnum.ForApproval]: "मंजूरी के लिए (For Approvals)",
      [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "मेरे ऋण (My Loans)",
      [LoanFilterTypeEnum.LoanForMe]: "मेरे लिए ऋण (Loan For Me)",
    },
    statusEnum: {
      [LoanStatusEnum.InProcess]: "प्रक्रिया में (In Process)",
      [LoanStatusEnum.Approved]: "मंजूर (Approved)",
      [LoanStatusEnum.Declined]: "अस्वीकृत (Declined)",
      [LoanStatusEnum.Cancelled]: "रद्द (Cancelled)",
      [LoanStatusEnum.Disbursed]: "वितरित (Disbursed)",
      [LoanStatusEnum.PartialCleared]: "आंशिक साफ (Partial Cleared)",
      [LoanStatusEnum.Completed]: "पूर्ण (Completed)",
      [LoanStatusEnum.Hold]: "होल्ड (Hold)",
      [LoanStatusEnum.WaitingForDisburse]: "वितरित होने के लिए प्रतीक्षा कर रहा है (Waiting For Disburse)",
    },
    loanTypeEnum: {
      [LoanTypeEnum.Company]: "कंपनी ऋण",
      [LoanTypeEnum.WorkWise]: "वर्कवाइज ऋण",
    },
  },
  quotations: {
    Quotation: "कोटेशन",
    CreateQuotation: "कोटेशन बनाएं",
    quotationDate: "तारीख",
    ClientName: "ग्राहक का नाम",
    ClientEmail: "ग्राहक का ईमेल",
    PhoneNumber: "फ़ोन नंबर",
    TotalAmount: "कुल रकम",
    QuotationType: "प्रकार",
    Rate: "दर",
    Quantity: "मात्रा",
    Attachments: "अटैचमेंट्स",
    serviceItem: "सेवा/आइटम",
    price: "मूल्य",
    tax: "कर (%)",
    taxAmount: "कर राशि",
    sno: "क्रमांक",
    EnterClientName: "क्लाइंट का नाम डालें",
    clientsName: "क्लाइंट का नाम",
    EnterClientEmail: "क्लाइंट का ईमेल डालें",
    addressLine1: "पता लाइन 1",
    addressLine2: "पता लाइन 2",
    EnterAddress1: "पता 1 डालें:",
    EnterAddress2: "पता 2 डालें:",
    Product: "उत्पाद",
    Service: "सेवा",
    netAmount: "नेट राशि",
    CODE: "कोड",
    DATE: "तारीख",
    TOTAL: "कुल",
    To: "किसके लिए",
    SubTotal: "उप-कुल",
    noDataMessage: "– अब कोटेशन बनाएं!",
    quotationFilterTypeEnum: {
      [QuotationFilterEnum.All]: "सभी कोटेशन",
      [QuotationFilterEnum.CreatedByMe]: "मेरे द्वारा बनाए गए",
      [QuotationFilterEnum.ForApproval]: "मंजूरी के लिए",
    },
    statusEnum: {
      [QuotationStatusEnum.InProcess]: "प्रक्रिया में",
      [QuotationStatusEnum.Approved]: "मंजूर",
      [QuotationStatusEnum.Declined]: "अस्वीकृत",
      [QuotationStatusEnum.Cancelled]: "रद्द",
      [QuotationStatusEnum.Hold]: "ठहराया गया",
      [QuotationStatusEnum.ClientApproved]: "ग्राहक द्वारा स्वीकृत",
      [QuotationStatusEnum.ClientRejected]: "ग्राहक द्वारा अस्वीकृत",
    },
    SortEnum: {
      [QuotationFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [QuotationFilterSortEnum.CreateDateAsc]: "निर्माण तिथि - आरोही",
      [QuotationFilterSortEnum.UpdateDateDesc]: "अपडेट तिथि - अवरोही",
      [QuotationFilterSortEnum.UpdateDateAsc]: "अपडेट तिथि - आरोही",
      [QuotationFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [QuotationFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही"
    },
    ErrorFieldMessage:{
      [InputFieldTypes.PRICE]: "मूल्य 0 से अधिक होना चाहिए",
      [InputFieldTypes.QUANTITY]: "मात्रा 0 से अधिक होना चाहिए",
      [InputFieldTypes.TAX]: "कर 0 से अधिक होना चाहिए",
    },
    singleItemError: "कृपया कोटेशन में कम से कम एक आइटम जोड़ें",

  },
  payroll: {
    Payroll: "पेरोल",
    createPayroll: "पेरोल बनाएं",
    noDataMessage: "– अब पेरोल बनाएं!",
    basicSalary: "मूलभूत वेतन",
    loan: "ऋण",
    allowance: "भत्ता",
    ExpenseReimbursement: "व्यय पूर्ति",
    deduction: "कर कटौती",
    tax: "कर",
    rebate: "रिबेट",
    bonus: "बोनस",
    selectMonthYear: "महीना और वर्ष चुनें",
    dispereseDate: "वितरित तिथि",
    Voucher: "वाउचर",
    DownloadtoExcel: "एक्सेल में डाउनलोड करें",
    PayrollDetail: "पेरोल विवरण",
    TransactionMode: "लेन-देन मोड",
    IBANNumber: "आईबीएन नंबर",
    statusEnum: {
      [PayrollStatusEnum.InProcess]: "प्रक्रिया में",
      [PayrollStatusEnum.Approved]: "मंजूर",
      [PayrollStatusEnum.Declined]: "अस्वीकृत",
      [PayrollStatusEnum.Resend]: "पुनः भेजें",
      [PayrollStatusEnum.Inactive]: "निष्क्रिय",
      [PayrollStatusEnum.NotRequired]: "आवश्यक नहीं",
      [PayrollStatusEnum.Cancelled]: "रद्द किया गया",
      [PayrollStatusEnum.Hold]: "धारित",
      [PayrollStatusEnum.Disbursed]: "संवितरित",
      [PayrollStatusEnum.Completed]: "पूर्ण",
    },
    payrollFilterTypeEnum: {
      [PayrollFilterTypeEnum.All]: "सभी मानदंड",
      [PayrollFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाया गया",
      [PayrollFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
    },
  },
  voucher: {
    createVoucher: "वाउचर बनाएं",
    SelectVoucherType: "वाउचर का प्रकार चुनें",
    BasicSalary: "मूलभूत वेतन",
    LoanPayment: "कर्ज का भुगतान",
    LoanDispersable: "कर्ज वितरित किया जा सकता है",
    Allowance: "भत्ता",
    ExpenseReimbursement: "व्यय पूर्ति",
    Deduction: "कटौती",
    VoucherDetail: "वाउचर विवरण",
    SavePrint: "सहेजें और प्रिंट करें",
    Bonus: "बोनस",
    Rebate: "रिबेट",
    Tax: "कर",
    ChequeNo: "चेक नंबर",
    Account: "खाता",
    Narration: "कथन",
    Debit: "डेबिट",
    Credit: "क्रेडिट",
    Dr: "डॉ.",
    Cr: "क्र.",
    differences: "अंतर",
    drCr: "डॉ./क्रेडिट",
    LinkVoucher: "वाउचर को लिंक करें",
    PostVoucher: "वाउचर पोस्ट करें",
    voucherDate: "वाउचर तिथि",
    VoucherType: "वाउचर प्रकार",
    voucherTypeList: {
      [voucherTypeEnum.GeneralVoucher]: "सामान्य वाउचर",
      [voucherTypeEnum.PaymentVoucher]: "भुगतान वाउचर",
      [voucherTypeEnum.ReceiptVoucher]: "रसीद वाउचर",
    },
  },
  trialBalance: {
    accountTypeList: {
      [accountTypeEnum.Asset]: "संपत्ति",
      [accountTypeEnum.Capital]: "पूंजी",
      [accountTypeEnum.Expense]: "खर्च",
      [accountTypeEnum.Liability]: "दायित्व",
      [accountTypeEnum.Revenue]: "राजस्व",
    },
  },
  disperse: {
    transactionModeEnumList: {
      [TransactionModeTypeEnum.Cash]: "कैश",
      [TransactionModeTypeEnum.BankTransfer]: "बैंक ट्रांसफर",
      [TransactionModeTypeEnum.Cheque]: "चेक",
    },
  },
  promotion: {
    CreatePromotion: "पदोन्नति बनाएं",
    Promotion: "पदोन्नति",
    promotionTo: "पदोन्नति को",
    noDataMessage: "अब पदोन्नति बनाएं!",
    selectPromotionTo: "पदोन्नति का चयन करें",
    pleseSelectPromotionTo: "कृपया पदोन्नति का चयन करें",
    createPromotion: "पदोन्नति बनाएं",
    promotionFilterTypeEnum: {
      [PromotionFilterTypeEnum.All]: "सभी पदोन्नतियाँ",
      [PromotionFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाई गई",
      [PromotionFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [PromotionFilterTypeEnum.PromotionForMe]: "मेरी पदोन्नतियाँ",
    },
    statusEnum: {
      [PromotionStatusEnum.InProcess]: "प्रक्रिया में",
      [PromotionStatusEnum.Approved]: "मंजूर",
      [PromotionStatusEnum.Declined]: "अस्वीकृत",
      [PromotionStatusEnum.Cancelled]: "रद्द किया गया",
      [PromotionStatusEnum.Hold]: "ठहराव",
    },
  },
  warnings: {
    inprogress: "पदनाम1",
    filter: "पदनाम2",
    approvers: "पदनाम3",
    createwarnings: "पदनाम4",
  },
  complains: {
    createComplain: "शिकायत बनाएं",
    myComplain: "मेरी शिकायतें",
    AllComplains: "सभी शिकायतें",
    complainOf: "की शिकायत",
    ComplainDetail: "शिकायत का विवरण",
    ComplainToMe: "मुझसे शिकायत",
    ComplainAboutMe: "मेरे बारे में शिकायत",
    noDataMessage: " - अब शिकायतें बनाएं!",
    statusEnum: {
      [ComplainApprovalStatusEnum.InProcess]: "प्रक्रिया में",
      [ComplainApprovalStatusEnum.Approved]: "मंजूर",
      [ComplainApprovalStatusEnum.Declined]: "अस्वीकृत",
      [ComplainApprovalStatusEnum.Resend]: "फिर से भेजें",
      [ComplainApprovalStatusEnum.Inactive]: "निष्क्रिय",
      [ComplainApprovalStatusEnum.NotRequired]: "आवश्यक नहीं",
      [ComplainApprovalStatusEnum.Cancelled]: "रद्द",
      [ComplainApprovalStatusEnum.Hold]: "रुका हुआ",
    },
    SortEnum: {
      [ComplainFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [ComplainFilterSortEnum.CreateDateAsc]: "निर्माण तिथि",
      [ComplainFilterSortEnum.UpdateDateDesc]: "अद्यतन तिथि - अवरोही",
      [ComplainFilterSortEnum.UpdateDateAsc]: "अद्यतन तिथि",
      [ComplainFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [ComplainFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही"
    }

  },
  salary: {
    SalaryMembers: "वेतन सदस्य",
    EffectiveDate: "प्रभावी तारीख",
    BasicSalary: "मूल वेतन",
    Allowances: "अनुमतियाँ",
    Allowance: "अनुमति",
    AllowanceDetail: "अनुमति विवरण",
    IsTaxable: "कर लागू है",
    Deduction: "कटौती",
    GrossSalary: "कुल वेतन",
    CreateSalary: "वेतन बनाएं",
    SalaryDetail: "वेतन विवरण",
    AddSalary: "वेतन जोड़ें",
    Increment: "वेतन वृद्धि",
    Deductions: "कटौतियाँ",
    AllowanceType: "अनुमति प्रकार",
    EffectiveDateIsRequired: "प्रभावी तारीख आवश्यक है",
    BasicSalaryIsRequired: "मूल वेतन आवश्यक है",
    Negativesalaryisnotallowed: "नकारात्मक वेतन की अनुमति नहीं है",
    Benefit: "लाभ",
    noDataMessage: " - अब वेतन बनाएं!",
    EnterIncrementPercentage: "कृपया वृद्धि प्रतिशत दर्ज करें",
    salaryFilterTypeEnum: {
      [SalaryFilterTypeEnum.Salaries]: "वेतन",
      [SalaryFilterTypeEnum.CreateByMe]: "मेरे द्वारा बनाए गए",
      [SalaryFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
    },
    statusEnum: {
      [EmployeeSalaryStatusEnum.InProcess]: "प्रक्रिया में है",
      [EmployeeSalaryStatusEnum.Approved]: "मंजूर",
      [EmployeeSalaryStatusEnum.Declined]: "अस्वीकृत",
      [EmployeeSalaryStatusEnum.Cancelled]: "रद्द",
      [EmployeeSalaryStatusEnum.Hold]: "होल्ड",
      [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "अन्य मंजूरी के लिए प्रतीक्षा कर रहा है",
    },
    SortEnum: {
      [AppraisalFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [AppraisalFilterSortEnum.CreateDateAsc]: "निर्माण तिथि",
      [AppraisalFilterSortEnum.UpdateDateDesc]: "अपडेट तिथि - अवरोही",
      [AppraisalFilterSortEnum.UpdateDateAsc]: "अपडेट तिथि",
      [AppraisalFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [AppraisalFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "निर्माण तिथि - अवरोही",
      [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "निर्माण तिथि - आरोही",
      [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "अपडेट तिथि - अवरोही",
      [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "अपडेट तिथि - आरोही",
      [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [EmployeeSalaryFilterSortEnum.ReferenceNo]: "संदर्भ संख्या"
    }

  },
  resignations: {
    resignationHeader: "नौकरी छोड़ने का निर्णय",
    resignationDate: "नौकरी छोड़ने की तारीख",
    createresignation: "नौकरी छोड़ने का निर्माण करें",
    noDataMessage: "– अब नौकरी छोड़ने का निर्माण करें!",
    reasonforresignation: "नौकरी छोड़ने का कारण",
    istermination: "समाप्ति है",
    resignationDetail: "नौकरी छोड़ने का विवरण",
    statusEnum: {
      [ResignationApprovalStatusEnum.InProcess]: "प्रक्रिया में",
      [ResignationApprovalStatusEnum.Approved]: "मंजूर",
      [ResignationApprovalStatusEnum.Declined]: "अस्वीकृत",
      [ResignationApprovalStatusEnum.Cancelled]: "रद्द",
      [ResignationApprovalStatusEnum.Completed]: "पूर्ण",
      [ResignationApprovalStatusEnum.Hold]: "धारित",
    },
    resignationFilterTypeEnum: {
      [ResignationFilterTypeEnum.Resignation]: "नौकरी छोड़ने का निर्णय",
      [ResignationFilterTypeEnum.CreateByMe]: "मेरे द्वारा बनाया गया",
      [ResignationFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
    },
    resignationTypeEnum: {
      [ResignationTypeEnum.Resignation]: "नौकरी छोड़ने का निर्णय",
      [ResignationTypeEnum.Termination]: "समाप्ति",
    },
  },
  Employees: {
    employeeAttachments: "कर्मचारी अटैचमेंट्स",
    CreateEmployeeNow: "अब कर्मचारी बनाएं",
    ProbationPeriod: "परीक्षण अवधि (दिन)",
    requiredMessageProbation: "कृपया अपनी परीक्षण अवधि दर्ज करें",
    requiredMessageProbationPeriod: "परीक्षण अवधि 0 और 30 के बीच होनी चाहिए",
    EmployeeNo: "कर्मचारी संख्या",
    EnterEmployeeNo: "कर्मचारी संख्या दर्ज करें",
    EmploymentType: "रोजगार का प्रकार",
    SelectEmploymentType: "रोजगार का प्रकार चुनें",
    requiredMessageEmploymentType: "कृपया रोजगार का प्रकार चुनें",
    OfficeShift: "कार्यालय का पारी",
    requiredMessageOfficeShift: "कृपया कार्यालय पारी का चयन करें",
    SelectOfficeShift: "कार्यालय पारी चुनें",
    UpdateBasicInfo: "मौलिक जानकारी अपडेट करें",
    Degree: "डिग्री",
    EnterDegree: "डिग्री दर्ज करें",
    requiredDegree: "कृपया डिग्री दर्ज करें",
    Institute: "संस्थान",
    EnterInstituteName: "संस्थान का नाम दर्ज करें",
    requiredMessageInstitute: "कृपया संस्थान का नाम दर्ज करें",
    ObtainedMarks: "प्राप्त अंक",
    requiredMessageObtained: "कृपया प्राप्त अंक दर्ज करें",
    EnterObtainedMarks: "प्राप्त अंक दर्ज करें",
    TotalMarks: "कुल अंक",
    EnterTotalMarks: "कुल अंक दर्ज करें",
    requiredMessageMarks: "कृपया कुल अंक दर्ज करें",
    UpdateEducation: "शिक्षा अपडेट करें",
    AddEducation: "शिक्षा जोड़ें",
    WorkExperienceInfo: "काम का अनुभव जानकारी",
    UpdateExperience: "अनुभव अपडेट करें",
    AddExperience: "अनुभव जोड़ें",
    EmergencyInfo: "आपातकालीन जानकारी",
    UpdateEmergencyForm: "आपातकालीन फॉर्म अपडेट करें",
    AddEmergency: "आपातकालीन जोड़ें",
    UpdateEmergency: "आपातकालीन अपडेट करें",
    UpdateBank: "बैंक अपडेट करें",
    AddBank: "बैंक जोड़ें",
    EnableEmployee: "क्या आप इस कर्मचारी को सक्षम करना चाहते हैं?",
    DisableEmployee: "क्या आप इस कर्मचारी को अक्षम करना चाहते हैं?",
    Enable: "सक्षम करें",
    Disabled: "अक्षम",
    UpdateFamily: "परिवार अपडेट करें",
    AddFamily: "परिवार जोड़ें",
    AddEmployeesLinkage: "कर्मचारी लिंकेज जोड़ें",
    AllEmployees: "सभी कर्मचारी",
    FamilyInfo: "परिवार जानकारी",
    Basic_Info: "मौलिक जानकारी",
    Email_Configuration: "ईमेल कॉन्फ़िगरेशन",
    Bank_Detail: "बैंक विवरण",
    Education: "शिक्षा",
    Emergency_Info: "आपातकालीन जानकारी",
    Work_Experience: " कार्य अनुभव ",
    Experience: "अनुभव",
    Rebate: "रिबेट",
    Salary: "वेतन",
    Leaves: "अवकाश",
    Devices: "उपकरण",
    basicInfo: "मौलिक जानकारी",
    Appraisal: "मूल्यांकन",
    Family: "परिवार",
    Attachments: "अटैचमेंट्स",
    My_Team: "मेरी टीम",
    Employees_Linkage: "कर्मचारी लिंकेज",
    Emails: "ईमेल",
    Finance: "वित्त",
    Goal: "लक्ष्य",
    RebateInfo: "रिबेट जानकारी",
    UpdateRebate: "रिबेट अपडेट करें",
    AddRebate: "रिबेट जोड़ें",
    AddSalary: "वेतन जोड़ें",
    Statistics: "सांख्यिकी"

  },
  loanDictionary: {
    createLoan: "मूल्यांकन6",
    loanTenureInMonths: "मूल्यांकन7",
    deductionPerMonth: "मूल्यांकन8",
    pleaseEnterloanTenureInMonths: "मूल्यांकन9",
  },
  /*** HR Menu ***/
  employees: {
    placeholders: {
      fName: "प्रथम नाम दर्ज करें",
      lName: "अंतिम नाम दर्ज करें",
      fatherName: "पिता का नाम दर्ज करें",
      email: "ईमेल दर्ज करें",
      pEmail: "व्यक्तिगत ईमेल दर्ज करें",
      phNo: "फोन नंबर दर्ज करें",
      rAddress: "आवासीय पता दर्ज करें",
      pAddress: "स्थायी पता दर्ज करें",
      cnicNo: "सीएनआईसी नंबर दर्ज करें",
      searchToSelect: "चुनने के लिए खोजें",
      probPeriod: "अंतिम नाम दर्ज करें0",
      noticePeriod: "अंतिम नाम दर्ज करें1",
      selectDate: "अंतिम नाम दर्ज करें2",
      selectGender: "अंतिम नाम दर्ज करें3",
      selectMarital: "अंतिम नाम दर्ज करें4",
      selectShift: "अंतिम नाम दर्ज करें5",
      empNo: "अंतिम नाम दर्ज करें6",
      empType: "अंतिम नाम दर्ज करें7",
      selectUserType: "अंतिम नाम दर्ज करें8",
      selectAccessRole: "अंतिम नाम दर्ज करें9",
      degree: "पिता का नाम दर्ज करें0",
      institute: "पिता का नाम दर्ज करें1",
      desc: "पिता का नाम दर्ज करें2",
      tMarks: "पिता का नाम दर्ज करें3",
      oMarks: "पिता का नाम दर्ज करें4",
      sDate: "पिता का नाम दर्ज करें5",
      eDate: "पिता का नाम दर्ज करें6",
      position: "पिता का नाम दर्ज करें7",
      name: "पिता का नाम दर्ज करें8",
      address: "पिता का नाम दर्ज करें9",
      number: "ईमेल दर्ज करें0",
      selectRelation: "ईमेल दर्ज करें1",
      bankName: "ईमेल दर्ज करें2",
      accTitle: "ईमेल दर्ज करें3",
      branchCode: "ईमेल दर्ज करें4",
      accNo: "ईमेल दर्ज करें5",
      iban: "ईमेल दर्ज करें6",
      sortCode: "ईमेल दर्ज करें7",
    },

    EmployeeForm: {
      AddImage: "छवि जोड़ें",
      FirstName: "पहला नाम",
      LastName: "अंतिम नाम",
      FatherName: "पिता का नाम",
      Email: "ईमेल",
      PersonalEmail: "व्यक्तिगत ईमेल",
      CNICNumber: "सीएनआईसी नंबर",
      rAddress: "आवासीय पता",
      pAddress: "स्थायी पता",
      PhoneNumber: "फोन नंबर",
      Designation: "पहला नाम0",
      Manager: "पहला नाम1",
      Grades: "पहला नाम2",
      Department: "पहला नाम3",
      Country: "पहला नाम4",
      City: "पहला नाम5",
      Languages: "पहला नाम6",
      ProbationPeriod: "पहला नाम7",
      NoticePeriod: "पहला नाम8",
      DateOfBirth: "पहला नाम9",
      DateOfJoining: "अंतिम नाम0",
      Gender: "अंतिम नाम1",
      MaritalStatus: "अंतिम नाम2",
      OfficeShift: "अंतिम नाम3",
      EmploymentType: "अंतिम नाम4",
      UserType: "अंतिम नाम5",
      AccessRole: "अंतिम नाम6",
      EmpNo: "अंतिम नाम7",
    },
    EmergencyForm: {
      EmergencyInfo: "अंतिम नाम8",
      Designation: "पहला नाम0",
      Address: "अंतिम नाम9",
      Number: "पिता का नाम0",
      Relation: "पिता का नाम1",
      Name: "पिता का नाम2",
    },

    EducationForm: {
      EducationInfo: "शिक्षा जानकारी",
      Degree: "डिग्री",
      Institute: "संस्थान",
      StartEndDate: "आरंभ/समाप्ति तिथि",
      StartDate: "प्रारंभ तिथि",
      Description: "विवरण",
      TotalMarks: "कुल अंक",
      ObtainedMarks: "प्राप्त अंक",
      Present: "वर्तमान",
      Attachments: "संलग्नक",
      AddMoreEducation: "डिग्री0",
      Upload: "डिग्री1",
      City: "डिग्री2",
    },
    WorkExperienceForm: {
      WorkExperienceInfo: "डिग्री3",
      Employer: "डिग्री4",
      Position: "डिग्री5",
      Designation: "डिग्री6",
      Department: "डिग्री7",
      StartEndDate: "आरंभ/समाप्ति तिथि",
      StartDate: "प्रारंभ तिथि",
      Present: "वर्तमान",
      AddMoreExperience: "डिग्री8",
      EmploymentType: "डिग्री9",
      City: "डिग्री2",
    },
    UserForm: {
      UserRights: "संस्थान0",
      UserType: "संस्थान1",
      UserRole: "संस्थान2",
    },
    BankForm: {
      BankInfo: "संस्थान3",
      BankName: "संस्थान4",
      AccountTitle: "संस्थान5",
      BranchCode: "संस्थान6",
      AccountNumber: "संस्थान7",
      IBAN: "संस्थान8",
      SortCode: "संस्थान9",
      City: "डिग्री2",
      Country: "आरंभ/समाप्ति तिथि0",
      AddMoreBank: "आरंभ/समाप्ति तिथि1",
    },

    EmployeeList: {
      number: "संख्या",
      email: "ईमेल",
      designation: "पदनाम",
    },

    submit: "प्रस्तुत करना",
  },
  leaves: {

    leave: "अवकाश",
    leavedetail: "अवकाश विवरण",
    Leave: "अवकाश प्रकार",
    leaveType: "अवकाश प्रकार",
    enterLeave: "अवकाश प्रकार दर्ज करें",
    save: "अवकाश प्रकार सहेजें",
    clear: "साफ करें",
    Add: "अवकाश प्रकार जोड़ें",
    enterDescription: "विवरण दर्ज करें",
    description: "विवरण",
    allotLeaves: "आवंटित अवकाश",
    balanceLeaves: "अवकाश प्रकार (शेष अवकाश)",
    selectType: "प्रकार चुनें",
    leaveFor: "किसके लिए अवकाश",
    leaveDates: "अवकाश की तारीखें",
    createleave: "अवकाश बनाएं",
    days: "दिन",
    leavetype: "अवकाश प्रकार",
    approvers: "मंजूरीदेनेवाले",
    selectMembers: "सदस्य चुनें",
    daysCount: "दिनों की संख्या",
    messages: "कृपया अवकाश प्रकार चुनें",
    allocatedLeaves: "आवंटित अवकाश",
    availedLeaves: "उपयोगित अवकाश",
    remainingLeaves: "शेष अवकाश",
    noDataMessage: " - अब अवकाश बनाएं!",
    Leavesinfo: "अवकाश जानकारी",
    UpdateLeave: "अवकाश को अपडेट करें",
    leavesFilterTypeEnum: {
      [LeaveFilterTypeEnum.All]: "सभी अवकाश",
      [LeaveFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाए गए",
      [LeaveFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [LeaveFilterTypeEnum.LeaveForMe]: "मेरे अवकाश",
    },
    statusEnum: {
      [LeaveStatusEum.InProcess]: "कार्रवाई में",
      [LeaveStatusEum.Approved]: "मंजूर",
      [LeaveStatusEum.Declined]: "अस्वीकृत",
      [LeaveStatusEum.Resend]: "पुनः भेजें",
      [LeaveStatusEum.InActive]: "निष्क्रिय",
      [LeaveStatusEum.NotRequired]: "आवश्यक नहीं",
      [LeaveStatusEum.Cancelled]: "रद्द",
      [LeaveStatusEum.Hold]: "ठहराया गया",
    },
  },
  bonus: {
    Bonus: "बोनस",
    createBonus: "बोनस बनाएं",
    BonusTo: "को बोनस",
    CurrentSalary: "वर्तमान वेतन",
    noDataMessage: "– अब बोनस बनाएं!",
    PercentageShouldNotExceed: "प्रतिशत 100 से अधिक नहीं होना चाहिए",
    bonusFilterTypeEnum: {
      [BonusFilterTypeEnum.All]: "सभी बोनस",
      [BonusFilterTypeEnum.CreatedByMe]: "मेरे द्वारा बनाए गए",
      [BonusFilterTypeEnum.ForApproval]: "मंजूरी के लिए",
      [BonusFilterTypeEnum.MyBonus]: "मेरा बोनस",
    },
    statusEnum: {
      [BonusStatusEnum.InProcess]: "प्रक्रिया में",
      [BonusStatusEnum.Approved]: "मंजूर",
      [BonusStatusEnum.Declined]: "अस्वीकृत",
      [BonusStatusEnum.Cancelled]: "रद्द",
      [BonusStatusEnum.Inactive]: "निष्क्रिय",
      [BonusStatusEnum.NotRequired]: "आवश्यक नहीं",
      [BonusStatusEnum.Resend]: "पुनः भेजें",
      [BonusStatusEnum.WaitingForOtherApproval]: "अन्य मंजूरी के लिए प्रतीक्षा कर रहा है",
      [BonusStatusEnum.Hold]: "होल्ड",
      [BonusStatusEnum.Disbursed]: "संवितरित",
    },
    SortEnum: {
      [BonusFilterSortEnum.CreateDateDesc]: "तारीख बनाई गई - अवरोही",
      [BonusFilterSortEnum.CreateDateAsc]: "तारीख बनाई गई",
      [BonusFilterSortEnum.UpdateDateDesc]: "तारीख अपडेट - अवरोही",
      [BonusFilterSortEnum.UpdateDateAsc]: "तारीख अपडेट",
      [BonusFilterSortEnum.ReferenceNoDesc]: "संदर्भ संख्या - अवरोही",
      [BonusFilterSortEnum.ReferenceNo]: "संदर्भ संख्या",
      [BonusFilterSortEnum.Amount]: "राशि",
      [BonusFilterSortEnum.AmountDesc]: "राशि - अवरोही",
      [BonusFilterSortEnum.Salary]: "वेतन",
      [BonusFilterSortEnum.SalaryDesc]: "वेतन - अवरोही"
    }
  },
  chartOfAccount: {
    ChartOfAccount: "खाता का चार्ट",
    CreateAccount: "खाता बनाएं",
    CreateChartOfAccount: "चार्ट ऑफ़ एकाउंट बनाएं",
    accountType: "खाता प्रकार",
    selectType: "प्रकार चुनें",
    parentAccount: "मुख्य खाता",
    selectParent: "मुख्य चुनें",
    EnterAccountType: "कृपया खाता प्रकार दर्ज करें",
    clossingBalance: "समाप्ति शेष",
  },
  ledgerReports: {
    reports: "रिपोर्टें",
    selectAccount: "खाता चुनें",
    selectDate: "तारीख चुनें",
    generateReport: "रिपोर्ट बनाएं",
    VoucherNo: "वाउचर नंबर",
  },
  requisitions: {
    requisition: "अनुरोध",
    MyRequisitions: "मेरे अनुरोध",
    createRequisition: "अनुरोध बनाएं",
    PleaseEnterRequisitionName: "कृपया अनुरोध का नाम दर्ज करें",
    EnterRequisitionName: "अनुरोध का नाम दर्ज करें",
    Budget: "बजट",
    EnterBudget: "बजट दर्ज करें",
    Dateofrequestexpire: "अनुरोध समाप्ति की तारीख",
    noDataMessage: "– अब अनुरोध बनाएं!",
  },
  rewards: {
    rewards: "फ़िल्टर5",
    PleaseEnterAwardName: "फ़िल्टर6",
    EnterAwardName: "फ़िल्टर7",
    EnterAwardReason: "फ़िल्टर8",
  },
  DefaultApprovers: {
    TypeEnum: {
      [DefaultApprovalTypeEnum.Expense]: "व्यय",
      [DefaultApprovalTypeEnum.ExpenseFinancers]: "व्यय वितरक",
      [DefaultApprovalTypeEnum.Travel]: "यात्रा",
      [DefaultApprovalTypeEnum.TravelAgent]: "यात्रा एजेंट",
      [DefaultApprovalTypeEnum.Loan]: "ऋण",
      [DefaultApprovalTypeEnum.Leave]: "अवकाश",
      [DefaultApprovalTypeEnum.Asset]: "संपत्ति",
      [DefaultApprovalTypeEnum.Salary]: "वेतन",
      [DefaultApprovalTypeEnum.Payroll]: "पेरोल",
      [DefaultApprovalTypeEnum.Reward]: "पुरस्कार",
      [DefaultApprovalTypeEnum.ResignationHr]: "इस्तीफा इंगीनियरिंग",
      [DefaultApprovalTypeEnum.ResignationAdmin]: "इस्तीफा प्रशासन",
      [DefaultApprovalTypeEnum.ResignationIt]: "इस्तीफा आईटी",
      [DefaultApprovalTypeEnum.ResignationFinance]: "इस्तीफा वित्त",
      [DefaultApprovalTypeEnum.ResignationExit]: "इस्तीफा निकास",
      [DefaultApprovalTypeEnum.Requisition]: "आवश्यकता",
      [DefaultApprovalTypeEnum.CustomApproval]: "कस्टम मंजूरी",
      [DefaultApprovalTypeEnum.Forms]: "फॉर्में",
      [DefaultApprovalTypeEnum.Appraisals]: "मूल्यांकन",
      [DefaultApprovalTypeEnum.Promotion]: "पदोन्नति",
      [DefaultApprovalTypeEnum.Warning]: "चेतावनी",
      [DefaultApprovalTypeEnum.Bonus]: "बोनस",
      [DefaultApprovalTypeEnum.Complains]: "शिकायतें",
      [DefaultApprovalTypeEnum.Career]: "करियर",
      [DefaultApprovalTypeEnum.Quotations]: "उद्धरण",
      [DefaultApprovalTypeEnum.RequisitionFinal]: "अंतिम आवश्यकता",
    },
  },
  NonMandatoryApprovers: {
    TypeEnum: {
      [NonMandatoryApprovalTypeEnum.Expense]: "व्यय",
      [NonMandatoryApprovalTypeEnum.Travel]: "यात्रा",
      [NonMandatoryApprovalTypeEnum.Loan]: "ऋण",
      [NonMandatoryApprovalTypeEnum.Leave]: "अवकाश",
      [NonMandatoryApprovalTypeEnum.Asset]: "संपत्ति",
      [NonMandatoryApprovalTypeEnum.Salary]: "वेतन",
      [NonMandatoryApprovalTypeEnum.Payroll]: "पेरोल",
      [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "व्यय वितरक",
      [NonMandatoryApprovalTypeEnum.TravelAgent]: "यात्रा एजेंट",
      [NonMandatoryApprovalTypeEnum.Reward]: "पुरस्कार",
      [NonMandatoryApprovalTypeEnum.ResignationHr]: "इस्तीफा इंगीनियरिंग",
      [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "इस्तीफा एडमिन",
      [NonMandatoryApprovalTypeEnum.ResignationIt]: "इस्तीफा आईटी",
      [NonMandatoryApprovalTypeEnum.ResignationFinance]: "इस्तीफा फाइनेंस",
      [NonMandatoryApprovalTypeEnum.ResignationExit]: "इस्तीफा निकास",
      [NonMandatoryApprovalTypeEnum.Requisition]: "आवश्यकता",
      [NonMandatoryApprovalTypeEnum.Warning]: "चेतावनी",
      [NonMandatoryApprovalTypeEnum.Complain]: "शिकायत",
      [NonMandatoryApprovalTypeEnum.Bonus]: "बोनस",
      [NonMandatoryApprovalTypeEnum.Promotion]: "पदोन्नति",
      [NonMandatoryApprovalTypeEnum.RequestForItem]: "आइटम के लिए अनुरोध",
      [NonMandatoryApprovalTypeEnum.CustomApproval]: "कस्टम मंजूरी",
      [NonMandatoryApprovalTypeEnum.Form]: "फॉर्म",
      [NonMandatoryApprovalTypeEnum.Document]: "दस्तावेज़",
      [NonMandatoryApprovalTypeEnum.Career]: "करियर",
      [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "आवश्यकता अंतिम मंजूरी",
      [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "आइटम के लिए अनुरोध एसेट कंट्रोलर",
      [NonMandatoryApprovalTypeEnum.Quotation]: "कोटेशन",
      [NonMandatoryApprovalTypeEnum.QuotationClient]: "कोटेशन क्लाइंट",
      [NonMandatoryApprovalTypeEnum.Appraisal]: "मूल्यांकन",
    }
  },
  activityLog: {
    DeviceType: "डिवाइस का प्रकार",
    DeviceToken: "डिवाइस टोकन",
    OSVersion: "OS संस्करण",
    Device: "डिवाइस",
    IP: "आईपी",
    Comment: "टिप्पणी",
    DeviceName: "उपकरण का नाम",
    LoginDate: "लॉगिन तिथि",
  },
  ErrorMessage: {
    someThingWentWrong: "कुछ गलत हो गया है",
  },
  setting: {
    BasicInformation: "मौलिक जानकारी",
    ChangePassword: "पासवर्ड बदलें",
    Notifications: "सूचनाएं",
    Mobile: "मोबाइल #",
    About: "के बारे में",
    ExistingPassword: "मौजूदा पासवर्ड",
    EnterExistingPassword: "मौजूदा पासवर्ड दर्ज करें",
    NewPassword: "नया पासवर्ड",
    EnterNewPassword: "नया पासवर्ड दर्ज करें",
    ConfirmPassword: "पासवर्ड की पुष्टि करें",
    EnterConfirmPassword: "पासवर्ड की पुष्टि करें",
    Signature: "दस्तावेज़ हस्ताक्षर",
    NoSignature: "कृपया हस्ताक्षर बनाएं",
    ErrorSignature: "हस्ताक्षर बनाने में त्रुटि",
    NoSignatureSave: "सहेजने के लिए कोई हस्ताक्षर नहीं है",
    SignatureSaved: "हस्ताक्षर सहेजा गया",
    SaveSignature: "हस्ताक्षर सहेजें",
    ClearSignature: "हस्ताक्षर साफ़ करें",
    SignatureCreated: "हस्ताक्षर बनाया गया",
    SignatureActive: "सक्रिय",
    FailedSignature: "हस्ताक्षर प्राप्त करने में विफल",
    BlockNotificationFeatureTypeEnum: {
      [NotificationBlockFeatureTypeEnum.Reward]: "पुरस्कार",
      [NotificationBlockFeatureTypeEnum.Feed]: "फ़ीड",
      [NotificationBlockFeatureTypeEnum.Document]: "दस्तावेज़",
      [NotificationBlockFeatureTypeEnum.Project]: "परियोजना",
      [NotificationBlockFeatureTypeEnum.Task]: "कार्य",
      [NotificationBlockFeatureTypeEnum.WorkBoard]: "कार्य बोर्ड",
      [NotificationBlockFeatureTypeEnum.Schedule]: "अनुसूची",
      [NotificationBlockFeatureTypeEnum.Group]: "समूह",
      [NotificationBlockFeatureTypeEnum.Messenger]: "मैसेंजर",
      [NotificationBlockFeatureTypeEnum.Expense]: "व्यय",
      [NotificationBlockFeatureTypeEnum.Auction]: "नीलामी",
      [NotificationBlockFeatureTypeEnum.Leave]: "छुट्टी",
      [NotificationBlockFeatureTypeEnum.Travel]: "यात्रा",
      [NotificationBlockFeatureTypeEnum.Warning]: "चेतावनी",
      [NotificationBlockFeatureTypeEnum.Complain]: "शिकायत",
      [NotificationBlockFeatureTypeEnum.Bonus]: "बोनस",
      [NotificationBlockFeatureTypeEnum.Promotion]: "पदोन्नति",
      [NotificationBlockFeatureTypeEnum.Loan]: "ऋण",
      [NotificationBlockFeatureTypeEnum.CustomApproval]: "कस्टम मंजूरी",
      [NotificationBlockFeatureTypeEnum.Form]: "फ़ॉर्म",
      [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "कर्मचारी वेतन",
      [NotificationBlockFeatureTypeEnum.Payroll]: "पेरोल",
      [NotificationBlockFeatureTypeEnum.Career]: "करियर",
      [NotificationBlockFeatureTypeEnum.Requisition]: "अनुरोध",
      [NotificationBlockFeatureTypeEnum.Resignation]: "त्यागपत्र",
      [NotificationBlockFeatureTypeEnum.Quotation]: "कोटेशन",
      [NotificationBlockFeatureTypeEnum.Appraisal]: "मूल्यांकन",
      [NotificationBlockFeatureTypeEnum.LeadManager]: "नेता प्रबंधक",
      [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "चर्चा बोर्ड",
      [NotificationBlockFeatureTypeEnum.Pages]: "पृष्ठ",
    }
  },
  emailConfiguration: {
    emailConfiHeader: "उपयोगकर्ता ईमेल कॉन्फ़िगरेशन",
    emailConfi: "उपयोगकर्ता नाम",
    password: "पासवर्ड",
    enterEmailConfi: "उपयोगकर्ता नाम दर्ज करें",
    incomingPort: "इनकमिंग पोर्ट",
    enterIncomingPort: "इनकमिंग पोर्ट दर्ज करें",
    incomingServerAddress: "इनकमिंग सर्वर पता",
    enterIncomingServerAddress: "इनकमिंग सर्वर पता दर्ज करें",
    outgoingPort: "आउटगोइंग पोर्ट",
    enterOutgoingPort: "आउटगोइंग पोर्ट दर्ज करें",
    outgoingServerAddress: "आउटगोइंग सर्वर पता",
    enterOutgoingServerAddress: "आउटगोइंग सर्वर पता दर्ज करें",
    provider: "प्रदाता",
    enterProvider: "प्रदाता दर्ज करें",
    saveEmailConfi: "ईमेल कॉन्फ़िगरेशन सहेजें",
    addEmailConfi: "ईमेल कॉन्फ़िगरेशन जोड़ें",
    name: "नाम",
    clear: "साफ़ करें",
  },
  assetsAllocation: {
    assetsAllocation: "संपत्तियों का आवंटन",
    assests: "संपत्तियाँ",
    createAssetAllocation: "संपत्ति आवंटन बनाएं",
    handover: "हस्तांतरण",
    forMaintenance: "रखरखाव के लिए",
    noResultFound: "कोई परिणाम नहीं मिला...",
    addAssetsAllocation: "संपत्तियों का आवंटन जोड़ें",
    noDataMessage: "– अब संपत्तियों का आवंटन बनाएं!",
    allocationForMe: "मेरे लिए आवंटन",
    allocationApprovals: "आवंटन मंजूरी",
    deAllocation: "आवंटन स्थिति बदलें",
    liability: "दायित्व",
    capital: "पूंजी",
    revenue: "राजस्व",
    expense: "व्यय",
    costOfGoodsale: "बिक्री की मूल्य",
    assetsCategory: "संपत्तियों की श्रेणी",
    parentAccount: "मुख्य खाता",
    selectParent: "मुख्य का चयन करें",
    assetsList: "संपत्तियों की सूची",
    createAssests: 'संपत्तियाँ बनाएं',
    sno: 'क्रमांक',
    inventoryName: 'सूची का नाम',
    inventoryValue: 'सूची का मूल्य',
    SelectAssetsController: "संपत्तियों का नियंत्रक चुनें",
  },
  requestListItems: {
    noDataMessage: "– अब अनुरोध बनाएं!",
    requestForItems: "वस्तुओं के लिए अनुरोध",
    RequestForItemsApprovals: "वस्तुओं के लिए अनुमोदन",
    AssetController: "संपत्ति नियंत्रक",
    RequestItem: "अनुरोध आइटम",
  },
  profile: {
    firstMenu: '  पहला मेनू आइटम',
    secondMenu: ' दूसरा मेनू आइटम (अक्षम)',
    thirdMenu: ' तीसरा मेनू आइटम (अक्षम)',
    dangerItem: 'खतरनाक आइटम',
    NoPosition: "कोई पद नहीं",
    NoInstitute: "कोई संस्थान नहीं",
    NotFound: "खोज नहीं मिली",
    University: "विश्वविद्यालय",
    School: "स्कूल",
    Graduated: "स्नातक",
    CurrentlyWorkHere: "मैं वर्तमान में यहाँ काम कर रहा हूँ",
    Statistics: "सांख्यिकी",
    StorageStatistics: "संग्रहण सांख्यिकी",
    TotalStorageUsed: "कुल उपयोग की गई संग्रहण",
  },
  /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
  status: {
    InProcess: "कार्रवाई में",
    Approved: "मंजूर",
    Declined: "अस्वीकृत",
    Resend: "फिर से भेजें",
    Inactive: "निष्क्रिय",
    NotRequired: "आवश्यक नहीं है",
    Cancelled: "रद्द",
    ApprovalRequired: "मंजूरी आवश्यक",
    Hold: "होल्ड",
    WaitingForOtherApprover: "अन्य स्वीकृति करने वाले के लिए प्रतीक्षा कर रहा है",
    Draft: "ड्राफ्ट",
    Remark: "टिप्पणी",
    Removed: "हटा दिया गया"
  },
  approvalDictionaryLabel: {
    Approval: "अनुमोदन",
    [ApprovalsModuleEnum.ExpenseExecutor]: "कार्यकर्ता",
    [ApprovalsModuleEnum.ExpenseFinance]: "वित्त",
    [ApprovalsModuleEnum.ResignationHrApproval]: "मानव संसाधन",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "वित्त",
    [ApprovalsModuleEnum.ResignationItApproval]: "आईटी",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "प्रशासनिक",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "अन्य मंजूरी",
    [ApprovalsModuleEnum.ResignationExitApproval]: "बाहरी साक्षात्कार",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "रिपोर्टिंग टू",
    [ApprovalsModuleEnum.TravelApproval]: "यात्रा के लिए मंजूरी",
    [ApprovalsModuleEnum.TravelAgent]: "एजेंट",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "अंतिम मंजूरी",
    [ApprovalsModuleEnum.LoanApproval]: "मंजूरी",
    [ApprovalsModuleEnum.AuctionApproval]: "मंजूरी"

  },

  approvalDictionaryPlaceHolder: {
    Approval: "अनुमोदन",
    [ApprovalsModuleEnum.ExpenseExecutor]: "खर्च के कार्यकर्ता का चयन करें",
    [ApprovalsModuleEnum.ExpenseFinance]: "वित्त से अनुमोदकों का चयन करें",
    [ApprovalsModuleEnum.ExpenseApproval]: "व्यय अनुमोदकों का चयन करें",
    [ApprovalsModuleEnum.ResignationHrApproval]: "मानव संसाधन का चयन करें",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "वित्त का चयन करें",
    [ApprovalsModuleEnum.ResignationItApproval]: "आईटी का चयन करें",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "प्रशासनिक का चयन करें",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "अन्य मंजूरी का चयन करें",
    [ApprovalsModuleEnum.ResignationExitApproval]: "बाहरी साक्षात्कार का चयन करें",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "रिपोर्टिंग टू का चयन करें",
    [ApprovalsModuleEnum.TravelApproval]: "यात्रा के लिए मंजूर करने वालों का चयन करें",
    [ApprovalsModuleEnum.TravelAgent]: "यात्रा व्यवस्थापक का चयन करें",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "अंतिम मंजूर करने वालों का चयन करें",
    [ApprovalsModuleEnum.LoanApproval]: "मंजूर करने वालों का चयन करें",
    [ApprovalsModuleEnum.AuctionApproval]: "मंजूर करने वालों का चयन करें"

  },
  // *** Extra label *** //

  /*** Define language write side ***/
  Direction: "ltr",
};
export default hindi;
