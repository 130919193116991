import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import { jsonToFormData } from "../../../../utils/base";
import MasterConfig from "../../../../utils/services/MasterConfig";
const API_PREFIX = "api/Schedule/";

export const addScheduleService = (data) => {
  return MasterConfig.post(`${API_PREFIX}AddSchedule`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addScheduleMemberService = (data) => {
  return MasterConfig.post(
    `${API_PREFIX}AddScheduleMember?id=${data?.id}`,
    data?.data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllScheduleService = (data) => {
  return MasterConfig.post(`${API_PREFIX}GetAllSchedule`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllScheduleMemberService = (id) => {
  return MasterConfig.get(`${API_PREFIX}GetAllScheduleMember?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getScheduleByIdService = (id) => {
  return MasterConfig.get(`${API_PREFIX}GetScheduleById?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateMemberScheduleStatusService = ({ id, status }) => {
  return MasterConfig.get(
    `${API_PREFIX}updateScheduleMemberStatus?id=${id}&status=${status}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateScheduleMemberTypeService = ({ id, type }) => {
  return MasterConfig.get(
    `${API_PREFIX}updateScheduleMemberType?id=${id}&type=${type}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getCalendarService = (data) => {
  return MasterConfig.post(`${API_PREFIX}GetCalendar`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const addScheduleExternalMemberService = (data) => {
  return MasterConfig.post(
    `${API_PREFIX}AddScheduleExternalMember?id=${data?.id}`,
    data?.data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const roomCheckAvailableService = (data) => {
  return MasterConfig.get(
    `${API_PREFIX}RoomCheckAvailable?roomId=${data?.roomId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    data?.data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const addScheduleConferenceCallService = (schId) => {
  return MasterConfig.get(`${API_PREFIX}AddScheduleConferenceCall?id=${schId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const calendarEmailInvite = (data) => {
  return MasterConfig.post(`${API_PREFIX}SendPersonalCalenderInvite`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getMinutesOfMeetingsService = (id) => {
  return MasterConfig.get(
    `${API_PREFIX}GetScheduleMinutesOfMeetingById?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};




export const getAllRequestForInterviews = (data) => {
  return MasterConfig.get("api/Career/GetAllRequestForInterviews")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateMinutesOfMeetingsService = (data) => {
  return MasterConfig.put(`${API_PREFIX}UpdateScheduleMinutesOfMeeting`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const convertMinutesOfMeetingIntoTaskService = (data) => {

  const { parameters: {
    minutesOfMeetingId,
    detailId
  }} = data
  const formData = jsonToFormData(data.requestBody);
  console.log(formData, "formDaraa");
  return MasterConfig.put(
    `${API_PREFIX}ConvertMinutesOfMeetingPointIntoTask?minutesOfMeetingId=${minutesOfMeetingId}&detailId=${detailId}`,
    formData
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getTaskByIdService = async (taskId) => {
  console.log(taskId, "service");
  try {
    const {
      data: { responseCode, data, message },
    } = await MasterConfig.get(`api/UserTask/GetUserTaskById?id=${taskId}`);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const updateScheduleMeetingTimeService = (data) => {
  let { id, isStartTime } = data;
  return MasterConfig.get(
    `${API_PREFIX}updateScheduleMeetingTime?id=${id}&isStartTime=${isStartTime}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateScheduleMeetingMemberTimeService = (data) => {
  let { id, isStartTime } = data;
  return MasterConfig.get(
    `${API_PREFIX}UpdateScheduleMemberMeetingTime?id=${id}&isStartTime=${isStartTime}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getScheduleUserCalendarLinkService = () => {
  return MasterConfig.get(
    `${API_PREFIX}GetScheduleUserCalenderLink`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const changeScheduleUserCalenderLinkService = (data) => {
  return MasterConfig.post(
    `${API_PREFIX}ChangeUserScheduleCalenderLink?isBlocked=${data}`,
    data?.data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateScheduleUserCalenderSettingIsBlockedService = (data) => {
  return MasterConfig.put(
    `${API_PREFIX}UpdateScheduleUserCalenderSettingIsBlocked?isBlocked=${data}`,
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateScheduleService = (data) => {
  return MasterConfig.put(
    `${API_PREFIX}UpdateSchedule`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCallingTranscribeByRoomIdSercive = (roomId) => {
  return MasterConfig.get(
    `api/Calling/GetCallingTranscribeByRoomId?roomId=${roomId}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const SendEmailService = (id) => {
  return MasterConfig.get(`${API_PREFIX}SendEmail?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};


export const getAllUserCalenderSettingAvailabilityService = () => {
  return MasterConfig.get(
    `api/Schedule/GetAllUserCalenderSettingAvailability`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};



export const GetAiGeneratedSummaryService = ({id}) => {
  return MasterConfig.post(
    `/api/Schedule/GetScheduleTranscriptionFromAi?id=${id}`,
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const saveUserCalenderSettingAvailabilityService = (payload) => {
  return MasterConfig.post(
    `/api/Schedule/SaveUserCalenderSettingAvailability`,payload
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};