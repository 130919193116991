import React, { useContext, useState } from "react";
import { Button } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";
import { calculateAllowance, calculateBasicSalary, calculateBonus, calculateDeduction, calculateLoan, calculateNetSalary, calculateOther, calculateRebate, calculateReimbursement, calculateTax } from "./calculatedPayroll";
import { STRINGS } from "../../../../../utils/base";
import { convertJSONToExcel } from "../../../../../utils/Shared/helper/convertJsonToExcel";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { AiOutlineEyeInvisible, AiOutlineEye  } from "react-icons/ai";


const EmployeesDetail = ({ details = [], referenceNo = STRINGS.DEFAULTS.guid, detailId }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { payroll, sharedLabels } = dictionaryList[userLanguage];
  const [allSalariesVisible, setAllSalariesVisible] = useState(false);

  const handleDownloadPayroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const extractedData = details.map((item) => {
      return {
        "Employee": item?.user?.name,
        "Basic Salary": item?.basicSalary,
        "Loan": item?.loan,
        "Allowance": item?.allowance,
        "Expense Reimbursement": item?.expenseReimbursement,
        "Deduction": item?.deduction,
        "Tax": item?.tax,
        "Rebate": item?.rebate,
        "Bonus": item?.bonus,
        "Other": item?.other,
        "Total": item?.netSalary,
        "Bank Name": item?.disperseDetails?.bankName,
        "Account Title": item?.disperseDetails?.bankAccountTitle,
        "Account Number": item?.disperseDetails?.bankAccountNumber,
        "IBAN Number": item?.disperseDetails?.bankIbanNumber,
        "Branch Code": item?.disperseDetails?.bankBranchCode,
        "Transaction Mode": item?.disperseDetails?.transactionModeName,
      }
    });
    convertJSONToExcel(extractedData, `payroll-${referenceNo}.xlsx`);
  };

  // const toggleSalaryVisibility = (id) => {
  //   setVisibleSalaries((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  const toggleAllSalariesVisibility = () => {
    setAllSalariesVisible(!allSalariesVisible);
  };

  return (
    <div className="mt-5">
      <div className="!flex !justify-end !items-end !ml-auto mb-2 gap-2">
        <Button className="ThemeBtn cursor-pointer" onClick={handleDownloadPayroll}>{payroll.DownloadtoExcel}</Button>
        <Button className="ThemeBtn cursor-pointer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }} onClick={toggleAllSalariesVisibility}>
          <div>
            {allSalariesVisible ? <AiOutlineEyeInvisible className="text-xl"/> : <AiOutlineEye className="text-xl"/>}
          </div>

          <div>
            {allSalariesVisible ? "Hide All" : "Show All"}
          </div>
        </Button>
      </div>
      <div className="createEntryTable overflow-auto">
        <table className="!min-w-full">
          <thead>
            <tr className="whitespace-nowrap">
              <th style={{ minWidth: "200px" }}>
                {sharedLabels.Employee}
              </th>
              <th style={{ minWidth: "120px" }}>
                {payroll.basicSalary}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.loan}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.allowance}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.ExpenseReimbursement}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.deduction}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.tax}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.rebate}
              </th>
              <th style={{ minWidth: "100px" }}>
                {payroll.bonus}
              </th>
              <th style={{ minWidth: "100px" }}>
                {sharedLabels.Others}
              </th>
              <th style={{ minWidth: "100px" }}>
                {sharedLabels.netSalary}
              </th>
            </tr>
          </thead>
          <tbody>
            {details &&
              details.map((item) => (
                <tr key={item.user.id}>
                  <td>{item?.user?.name}</td>
                  <td>
                    {item.basicSalary == 0 || allSalariesVisible ? formatNumberWithCommas(item?.basicSalary.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.loan == 0 || allSalariesVisible ? formatNumberWithCommas(item?.loan.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.allowance == 0 || allSalariesVisible ? formatNumberWithCommas(item?.allowance.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.expenseReimbursement == 0 || allSalariesVisible ? formatNumberWithCommas(item?.expenseReimbursement.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.deduction == 0 || allSalariesVisible ? formatNumberWithCommas(item?.deduction.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.tax == 0 || allSalariesVisible ? formatNumberWithCommas(item?.tax.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.rebate == 0 || allSalariesVisible ? formatNumberWithCommas(item?.rebate.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.bonus == 0 || allSalariesVisible ? formatNumberWithCommas(item?.bonus.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.other == 0 || allSalariesVisible ? formatNumberWithCommas(item?.other.toFixed(2)) : "*****"}
                  </td>
                  <td>
                    {item.netSalary == 0 || allSalariesVisible ? formatNumberWithCommas(item?.netSalary.toFixed(2)) : "*****"}
                  </td>
                </tr>
              ))}
            <tr>
              <td className="font-bold">{sharedLabels.Total}</td>
              <td className="font-bold">{calculateBasicSalary(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateBasicSalary(details)) : "*****"}</td>
              <td className="font-bold">{calculateLoan(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateLoan(details)) : "*****"}</td>
              <td className="font-bold">{calculateAllowance(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateAllowance(details)) : "*****"}</td>
              <td className="font-bold">{calculateReimbursement(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateReimbursement(details)) : "*****"}</td>
              <td className="font-bold">{calculateDeduction(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateDeduction(details)) : "*****"}</td>
              <td className="font-bold">{calculateTax(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateTax(details)) : "*****"}</td>
              <td className="font-bold">{calculateRebate(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateRebate(details)) : "*****"}</td>
              <td className="font-bold">{calculateBonus(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateBonus(details)) : "*****"}</td>
              <td className="font-bold">{calculateOther(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateOther(details)) : "*****"}</td>
              <td className="font-bold">{calculateNetSalary(details) == 0 || allSalariesVisible ? formatNumberWithCommas(calculateNetSalary(details)) : "*****"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeesDetail;
