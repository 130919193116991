export const JobTypeEnum = [
    {
        label: "Full Time",
        value: 1,
    },
    {
        label: "Part Time",
        value: 2,
    },
    {
        label: "Contractual",
        value: 3,
    },
    {
        label: "Consultant",
        value: 4,
    },
    {
        label: "Agent",
        value: 5,
    },
    {
        label: "Project Based",
        value: 6,
    },
];

export const JobTypeNameEnum = {
    1: "Full Time",
    2: "Part Time",
    3: "Contractual",
    4: "Consultant",
    5: "Agent",
    6: "Project Based"
}

export const JobTypeEnums = {
    FullTime: 1,
    PartTime: 2,
    Contractual: 3,
    Consultant: 4,
    Agent: 5,
    ProjectBased: 6,
}