import { Button } from "antd";
import InterviewRatingQuestions from "./interviewRatingQuestions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addCareerInterviewRatingAction } from "../../store/action";
import { useSelector } from "react-redux";
import { CalculateInterviewQuestionAvgRating } from "../../../schedule/utils/calculateInterviewQuestionAvgRating";

export default function InterviewRatingQuestionWrapper({
  data = [],
  memberId = null,
  isAvg = false,
  setInterviewData,
}) {
  const [currentData, setCurrentData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { user } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    const questions = data?.questions;
    setCurrentData(
      isAvg && questions
        ? CalculateInterviewQuestionAvgRating(questions)
        : questions && questions[memberId]
        ? questions[memberId]
        : []
    );
  }, [data, memberId]);

  useEffect(() => {
    setIsEditable(user?.id === memberId);
  }, [memberId]);

  const onCmtChange = (text, index) => {
    let updateData = [...currentData];
    let referenceItem = updateData[index];
    referenceItem = {
      ...referenceItem,
      comment: text,
    };
    updateData[index] = referenceItem;
    setCurrentData(updateData);
  };

  const onRateChange = (rate, index) => {
    let updateData = [...currentData];
    let referenceItem = updateData[index];
    referenceItem = {
      ...referenceItem,
      rating: rate,
    };
    updateData[index] = referenceItem;
    setCurrentData(updateData);
  };

  const onSaveRating = () => {
    setIsloading(true);
    const values = {
      interviewId: currentData?.[0]?.careerInterviewId,
      payload: currentData,
    };
    dispatch(addCareerInterviewRatingAction(values)).then((res) => {
      setInterviewData({
        ...data,
        questions: { ...data.questions, [memberId]: currentData },
      });
      setIsloading(false);
    });
  };

  // Check if isAvg is false and no question of the member
  if (!isAvg && currentData.length === 0) {
    return null;
  }

  return (
    <>
      <InterviewRatingQuestions
        key={isEditable}
        data={currentData}
        isEditable={isEditable}
        onCmtChange={onCmtChange}
        onRateChange={onRateChange}
        isAvg={isAvg}
      />

      {isEditable && !isAvg && (
        <div className="flex justify-end mt-3">
          <Button
            type="primary"
            size="medium"
            className="ThemeBtn"
            onClick={onSaveRating}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      )}
    </>
  );
}
