import { AttendanceStateEnum } from "./AttendanceStateEnum";

export default function calculateAttendanceTotals(details) {
  let totalAbsent = 0;
  let totalLate = 0;
  let totalLeave = 0;
  let totalPresent = 0;
  let totalSpentTime = 0; // Accumulator for time spent
  let totalValidDays = 0; // Counter for valid days with timeSpent

  details.forEach((day) => {
    // Ignore details with id null
    if (day.id !== null) {
      switch (day.status) {
        case 3: // Absent
          totalAbsent++;
          break;
        case 5: // Late
          totalLate++;
          break;
        case 4: // Leave
          totalLeave++;
          break;
        case 2: // Present
        case 6: // WorkFromHome
          totalPresent++;
          break;
        default:
          // Handle Unknown or any other case
          break;
      }

      // Accumulate time spent for valid days
      if (day.timeSpent !== null) {
        totalSpentTime += day.timeSpent;
        totalValidDays++;
      }
    }
  });

  // Calculate average time spent
  const overallTimeSpent = totalValidDays > 0 ? totalSpentTime : 0;

  return { totalAbsent, totalLate, totalLeave, totalPresent, overallTimeSpent };
}
