import { useEffect, useState } from "react";
import { DirectionEnum } from "../../../../utils/Shared/enums/directionEnum";
import { PannelTypeEnum } from "../../fullScreenModal/enums/enum";
import FullScreenModalWrapper from "../../fullScreenModal/view/fullScreenModalWrapper";
import { STRINGS } from "../../../../utils/base";

function DocumentFullScreenModal({
  fullScreenModalOpen = false,
  onCancel = () => {},
  data,
}) {
  const [fullScreenModalState, setFullScreenModalState] = useState([
    {
      direction: DirectionEnum.Left,
      data: [{ panelType: PannelTypeEnum.Swipper, data: [] }],
    },
    {
      direction: DirectionEnum.Right,
      data: [{ panelType: PannelTypeEnum.Comment, data: [] }],
    },
  ]);

  useEffect(() => {
    if (data) {
      setFullScreenModalState([
        {
          direction: DirectionEnum.Left,
          data: [
            {
              panelType: PannelTypeEnum.Swipper,
              data: [
                {
                  attachmentId: data.attachmentId,
                  documentType: data.documentType,
                  extensionTypeId: data.extensionTypeId,
                  path: data.path,
                  referenceId: data?.referenceId,
                  name: data.name,
                },
              ],
            },
          ],
        },

        {
          direction: DirectionEnum.Right,
          data: [
            {
              panelType: PannelTypeEnum.Comment,
              data: [
                {
                  referenceId:
                    data.referenceId !== STRINGS.DEFAULTS.guid
                      ? data.referenceId
                      : data.id,
                  module: 5,
                },
              ],
            },
          ],
        },
      ]);
    }
  }, [data]);
  return (
    <>
      <FullScreenModalWrapper
        data={fullScreenModalState}
        openModal={fullScreenModalOpen}
        onCancel={onCancel}
      />
    </>
  );
}
export default DocumentFullScreenModal;
