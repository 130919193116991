import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Select, Space } from "antd";
import { STRINGS, SvgSpinner } from "../../../../../utils/base";
import {
  ShopOutlined,
  MailOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TextInput from "../../../../sharedComponents/Input/TextInput";
import CountryPhoneInput from "../../../../sharedComponents/Input/CountryPhoneInput";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../../store/slice";
import { signup } from "../../store/actions";
import { Form } from "antd";
import SingleUpload from "../../../../sharedComponents/Upload/singleUpload";
import ReCAPTCHA from "react-google-recaptcha";
import { googleCaptchaKeys } from "../../utils/googleCaptchaKeys";
import { ROUTES } from "../../../../../utils/routes";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { getAllDefaultDesignation } from "../../../../../utils/Shared/store/actions";

let formData = {};

function Signup() {
  const [localState, setlocalState] = useState({
    disableFormSubmit: false,
    dialogMessage: "",
    openView: false,
    path: "",
    error: [],
    designations: [],
  });
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [file, setFile] = useState(null);
  const [isCEO, setIsCEO] = useState(true);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const { userLanguage } = useContext(LanguageChangeContext);
  const {
    DefaultApprovers,
    NonMandatoryApprovers,
    sharedLabels,
    Employees,
    Direction,
  } = dictionaryList[userLanguage];

  const dispatch = useDispatch();

  const { isError, isSuccess, loader } = useSelector(
    (state) => state.authSlice
  );

  const { imageLoader, designations } = useSelector(
    (state) => state.sharedSlice
  );
  const [profileImage, setProfileImage] = useState(null);

  const [reset, setReset] = useState(false);

  useEffect(() => {
    dispatch(getAllDefaultDesignation());
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setReset(true);
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleSignUpSubmit = (values) => {
    let image = {
      id: STRINGS.DEFAULTS.guid,
      file: profileImage && profileImage[0]?.originFileObj,
    };

    if (!captchaResponse) {
      alert("Please verify the reCAPTCHA!");
    } else {

      let {designations , ...palyloadValues} = values
      let signupvalues = {
        ...palyloadValues,
        hasCEODetails: !values.hasCEODetails
      }

      if (Object.keys(image).length > 0) {
        let payload = {
          ...signupvalues,
          image,
        };
        dispatch(signup(payload));
      } else {
        dispatch(signup(signupvalues));
      }
    }
  };

  const onChange = (value, name) => {
    formData = { ...formData, [name]: value };
  };

  const handleImageUpload = (data) => {
    setProfileImage(data);
  };

  const handleCheckboxChange = (e) => {
    setIsCEO(e.target.checked);
  };

  const handleDesignationChange = (value) => {
    setSelectedDesignation(value);
  };

  const additionalOptions = [{ id: "other", name: "Other" }];

  let filteredDesignations =
    designations?.length === 0 ? additionalOptions : designations;

  return (
    <Form
      onFinish={handleSignUpSubmit}
      name="nest-messages"
      id="form"
      className="form small-sign-up-form"
      initialValues={{
        hasCEODetails: true,
      }}
    >
      <div className="form-section small-sign-up-form">
        <div className="input-group">
          <div className="row-header">
            <div className="row-cl-1" style={{ width: "60%" }}>
              <Form.Item area="true">
                <SingleUpload
                  handleImageUpload={handleImageUpload}
                  img="Add Image"
                  position="flex-start"
                  uploadText={"Upload"}
                />
              </Form.Item>
            </div>
            <div className="row-cl-2">
              <div className="row-cl-2-heading1">Sign Up</div>
              <div className="row-cl-2-heading2">
                You’re signing up as an organization.
              </div>
            </div>
          </div>
          <Space direction="vertical" size={8} style={{ width: "100%" }}>
            <Form.Item name="hasCEODetails" valuePropName="checked">
              <Checkbox onChange={handleCheckboxChange}>
                I am the CEO of the company
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="title"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <TextInput
                type="text"
                placeholder="Business Title"
                prefix={ShopOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>

            {/* Checkbox for CEO */}

            <div className="inp-flex-row small-sign-up-form">
              <Space direction="horizontal" size={8} style={{ width: "100%" }}>
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <TextInput
                    type="text"
                    placeholder="First Name"
                    size="large"
                    reset={reset}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <TextInput
                    type="text"
                    placeholder="Last Name"
                    size="large"
                    reset={reset}
                  />
                </Form.Item>
              </Space>
            </div>

            <Form.Item
              name="email"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <TextInput
                type="email"
                placeholder="Personal Email"
                prefix={MailOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>

            {!isCEO && (
              <Form.Item
                rules={[
                  { required: true, message: sharedLabels.designationmessage },
                ]}
                name="designations"
                placeholder={sharedLabels.selectDesignation}
              >
                <Select
                  value={selectedDesignation}
                  placeholder={sharedLabels.selectDesignation}
                  size="large"
                  onChange={handleDesignationChange}
                  key={selectedDesignation}
                >
                  {filteredDesignations.map((designation) => (
                    <Select.Option key={designation.id} value={designation.id}>
                      {designation.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {selectedDesignation === "other" && (
              <Form.Item
                name="designation"
                rules={[
                  {
                    required: true,
                    message: "Please enter your custom designation!",
                  },
                ]}
              >
                <TextInput
                  type="text"
                  placeholder="Enter your designation"
                  size="large"
                  reset={reset}
                />
              </Form.Item>
            )}

            {!isCEO && (
              <>
                <div className="signUpCEODetails">
                  <span className="CEODetailLable">CEO Details</span>
                  <div>
                    <div className="inp-flex-row small-sign-up-form">
                      <Space
                        direction="horizontal"
                        size={8}
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          name="ceoFirstName"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <TextInput
                            type="text"
                            placeholder="CEO First Name"
                            size="large"
                            reset={reset}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ceoLastName"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <TextInput
                            type="text"
                            placeholder="CEO Last Name"
                            size="large"
                            reset={reset}
                          />
                        </Form.Item>
                      </Space>
                    </div>
                    <Form.Item
                      name="ceoEmail"
                      rules={[
                        { required: true, message: "Please enter CEO Email" },
                      ]}
                      className="!mb-0"
                    >
                      <TextInput
                        type="ceoEmail"
                        placeholder="CEO Email"
                        prefix={MailOutlined}
                        size="large"
                        reset={reset}
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}

            <Form.Item
              name="businessEmail"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <TextInput
                type="email"
                placeholder="Business Email"
                prefix={MailOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>
            <Form.Item name="referralCode" rules={[{ required: false }]}>
              <TextInput
                type="text"
                placeholder="Referral Code"
                prefix={MailOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>
            <Form.Item name="phoneNo">
              <div className="row country-phone-number">
                <CountryPhoneInput
                  country="pk"
                  placeholder="Enter your Phone Number"
                  onChange={onChange}
                  reset={reset}
                  autoFocus = {false}
                />
              </div>
            </Form.Item>

            <div className="agreement small-sign-up-form small-sign-up-form-agreement">
              <Form.Item
                name="agree"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "You must agree to the terms and conditions",
                  },
                ]}
              >
                <Checkbox>
                  <span className="terms-and-conditions">
                    I agree to the terms and conditions.
                  </span>
                </Checkbox>
              </Form.Item>
            </div>
          </Space>
        </div>
      </div>

      {localState.error &&
        localState.error.map((error) => {
          return <p key={error.message}>{error.message}</p>;
        })}

      <div className="form-footer">
        <Form.Item>
          <ReCAPTCHA
            sitekey={googleCaptchaKeys.SITE_KEY}
            onChange={(response) => setCaptchaResponse(response)}
            className="signUpReCaptcha"
          />
        </Form.Item>
        <div className="sub-btn">
          <button
            className={`button ${imageLoader || loader ? "disable" : ""}`}
          >
            Sign Up
            {!imageLoader && !loader ? (
              <span className="icon-login">
                <i className="ic-login_icon" />
              </span>
            ) : (
              <SvgSpinner />
            )}
          </button>
        </div>
        <div className="already-acc">
          <p className="p">Already have an account?&nbsp;</p>
          <Link id="login_btn" className="a" to={ROUTES.AUTH.SIGN_IN}>
            Login
          </Link>
        </div>
      </div>
    </Form>
  );
}

export default Signup;
