import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../../../services/enums/responseCode";
import { getAllSignature, saveSignatures } from './actions.js';

const initialState = {
    signature: [],
    loadingData: false,
    loader: false,
    success: false,
    error: false,
}

const signatureSlice = createSlice({
    name: "signature",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllSignature.fulfilled, (state, { payload }) => {
                state.loadingData = false;
                state.signature = payload.data;
            })
            .addCase(saveSignatures.fulfilled, (state, { payload }) => {
                state.loader = false;
                if (payload.responseCode === responseCode.Success)
                    state.signature = state.signature.concat(payload.data)
            })
            .addMatcher(
                isPending(...[saveSignatures]),
                (state) => {
                    state.loader = true;
                    state.success = false;
                    state.error = false;
                }
            )
            .addMatcher(isPending(...[getAllSignature]), (state) => {
                state.loadingData = true;
                state.success = false;
                state.error = false;
            })
            .addMatcher(
                isRejected(
                    ...[
                        getAllSignature,
                        saveSignatures,
                    ]
                ),
                (state) => {
                    state.loader = false;
                    state.loadingData = false;
                    state.success = false;
                    state.error = false;
                }
            );
    }
})

export default signatureSlice.reducer;
