import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { message } from "antd";

import {
  addExpenseService,
  getAllExpenseService,
  getExpenseByIDService,
  getAllExpensePagingService,
  getExpenseMetricService,
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const addExpense = createAsyncThunk(
  "expense/addexpense",
  async (args, { dispatch, getState }) => {
    const res = await addExpenseService(args);
    if (res?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Expense Created Successfully",
          type: "success",
          duration: 2,
        })
      );

      return res.data;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return isRejectedWithValue(res.message);
    }
  }
);

export const getAllExpenseAction = createAsyncThunk(
  "expense/getAllExpenseAction",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllExpensePagingService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllExpenseService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);
export const getExpenseById = createAsyncThunk(
  "expense/getExpenseById",
  async (args, { dispatch, getState }) => {
    const res = await getExpenseByIDService(args?.id);

    if (res?.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
  }
);

export const getExpenseMetricById = createAsyncThunk(
  "expense/getExpenseMetricById",
  async (id, { dispatch, getState }) => {
    const res = await getExpenseMetricService(id);

    if (res?.responseCode === responseCode.Success) {
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
  }
);
