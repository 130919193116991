import React, { useContext, useState } from "react";
import { STRINGS, SvgSpinner } from "../../../../../utils/base";
import { useDispatch } from "react-redux";
import { ApprovalsModuleEnum } from "../../../approval/utils/enum/approvalModuleEnum";
import { ExpenseReferenceTypeEnum } from "../../../expense/utils/expenseReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import BasicModal from "../../../../sharedComponents/Modal/BasicModal/BasicModal";
import AddMember from "../../../members/view/addMember/addMember";
import { CompleteDocumentAction } from "../../store/actions";
import { handleAddDocumentApprover } from "../../store/slice";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { Button, Spin } from "antd";

const CompleteDocument = ({
  referenceId = STRINGS.DEFAULTS.guid,
  visible = false,
  onClose,
  existingMembers = [],
  module = 0,
  referenceType = 0,
  referenceTypeID = STRINGS.DEFAULTS.guid,
  notRequiredMemberId = [],
}) => {


  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  const [state, setState] = useState({
    newApprovers: [],
    key: "",
  });

  const [isCompleteDocumentLoading , setIsCompleteDocumentLoading] = useState(false)
  const dispatch = useDispatch();

  const prepareSuggestionTypes = () => {
    switch (module) {
      case ApprovalsModuleEnum.ExpenseApproval:
      case ApprovalsModuleEnum.ExpenseExecutor:
      case ApprovalsModuleEnum.ExpenseFinance:
        switch (referenceType) {
          case ExpenseReferenceTypeEnum.Group:
            return [SuggestionTypeEnum.GroupMember];
          case ExpenseReferenceTypeEnum.Project:
            return [SuggestionTypeEnum.ProjectMember];
          default:
            return [SuggestionTypeEnum.Employee];
        }
      default:
        return [SuggestionTypeEnum.Employee];
    }
  };

  const handleAddApprovals = () => {
    if (state.newApprovers.length > 0) {
      const payload = {
        approvers: state.newApprovers.map((member) => {
          return { approverId: member.id };
        }),
        id: referenceId,
      };

      dispatch(CompleteDocumentAction(payload)).then(({ payload }) => {
        let approver = payload?.data?.approvers;
        dispatch(
          handleAddDocumentApprover({ referenceId, module, approver })
        );
        setState({ ...state, key: new Date() });
        onClose();
      });
    }
  };

  

  const resetState = (data = null) => {
    setState({ ...state, key: new Date() });
    onClose();
  };



  return (
    <>
      <BasicModal
        isOpen={visible}
        onCancel={() => {
          resetState();
        }}
        onSave={() => {
          handleAddApprovals();
        }}
      >
        <AddMember
          key={referenceId}
          removeSelf={true}
          placeHolder={sharedLabels.AddApprovals}
          callback={(data) => {
            setState({ ...state, newApprovers: data });
          }}
          existingMembers={[
            ...(existingMembers?.[0]?.createBy ? [existingMembers?.[0]?.createBy] : []),
            ...existingMembers.map((member) => member.approverId),
            ...notRequiredMemberId
          ]}
          suggestionType={prepareSuggestionTypes()}
          referenceId={referenceTypeID}
        />
      </BasicModal>
    </>
  );
}

export default CompleteDocument;