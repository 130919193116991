export const FormFilterSortEnum = {
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,

}


export const formTableSort = {
    referenceNo: {
        descend: FormFilterSortEnum.ReferenceNoDesc,
        ascend: FormFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: FormFilterSortEnum.CreateDateDesc,
        ascend: FormFilterSortEnum.CreateDateAsc
    },

}