import React, { useContext } from "react";
import Tab from "../../../../sharedComponents/Tab";
import Loan from "../../../team/view/Loan";
import Warnings from "../../../team/view/Warnings";
import Rewards from "../../../team/view/Rewards";
import Courses from "../../../team/view/Courses";
import "../../style/style.css";
import { useSelector } from "react-redux";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
function DetailTabs({ detailId, RemarksApproval, id }) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];

  const userId = id;
  const { TabPane } = Tab;
  const panes = [
    {
      featureId: 0,
      featureName: sharedLabels.Approvers,
      content: RemarksApproval,
    },
    {
      featureId: 1,
      featureName: sharedLabels.loans,
      content: <Loan userId={userId} />,
    },
    {
      featureId: 2,
      featureName: sharedLabels.warnings,
      content: <Warnings userId={userId} />,
    },
    {
      featureId: 3,
      featureName: sharedLabels.Rewards,
      content: <Rewards userId={userId} />,
    },
    {
      featureId: 4,
      featureName: sharedLabels.Courses,
      content: <Courses userId={userId} />,
    },
  ];
  return (
    <div className="detailTabs">
      <Tab panes={panes} />
    </div>
  );
}

export default DetailTabs;
