import { Avatar, Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNameForImage } from "../../../utils/base";
import { getAllEmployees } from "../../../utils/Shared/store/actions";
import MemberSelect from "../AntdCustomSelects/SharedSelects/MemberSelect";
import SuggestionBox from "../SuggestionBox/View/SuggestionBox";
import { SuggestionTypeEnum } from "../SuggestionBox/utils/SuggestionTypeEnum";
import { PostReferenceType } from "../../features/feed/utils/constants";

function TagSearchable(props) {
	const dispatch = useDispatch();
	const fetchEmployees = (text, pgNo) => {
		dispatch(getAllEmployees({ text, pgNo, pgSize: 20 }));
	};

	const prepareSuggestionTypes = ()=> {
        
                switch (props.referenceType) {
                    case PostReferenceType.GROUP:
                        return [SuggestionTypeEnum.GroupMember]
                    case PostReferenceType.PROJECTS:
                        return [SuggestionTypeEnum.ProjectMember]
                    default:
                        return [SuggestionTypeEnum.Employee]
                }
        }

	const [taggedMember , setTaggerMember ] = useState([])
	const employees = useSelector(state => state.sharedSlice.employees);
	return (

		<>
		<Form.Item
			style={{margin:"0px"}}
		>
		  <SuggestionBox
		    initialData={taggedMember}
			referenceId={props.referenceId}
		  	placeholder="Select Member"
			suggestionType ={prepareSuggestionTypes()}
			removeSelf={true}
			isMultiSelect={true}
			callback={(item)=>{
				const array = [...item]
				setTaggerMember(array)
				props.onChange(item);

			}}
		  
		  />
		  </Form.Item>
		{/* <MemberSelect
			className={props.className}
			data={employees}
			selectedData={(value, obj) => {
				props.onChange(obj);
			}}
			canFetchNow={employees && employees.length > 0}
			fetchData={fetchEmployees}
			placeholder={props.placeholder}
			mode={"multiple"}
			size="middle"
			isObject={true}
			loadDefaultData={false}
			optionComponent={opt => {
				return (
					<>
						{opt.image ? (
							<Avatar src={opt.image} className="!bg-black" />
						) : (
							<Avatar className="!bg-black">
								{getNameForImage(opt.name)}
							</Avatar>
						)}
						{opt.name}
					</>
				);
			}}
			dataVal={[]}
			name={props.name}
		/> */}
		</>
	);
}

export default TagSearchable;
