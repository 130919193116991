import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  addGroupService,
  getAllGroupService,
  getGroupByIdService,
  updateGroupService,
  addGroupMemberService,
  getAllGroupMemberService,
  deleteGroupMemberService,
  addGroupFavoriteMarkService,
  addGroupFeaturesService,
  removeGroupFeaturesService,
  getGroupFeaturesService,
  addGroupTagService,
  getAllGroupTagService,
  addGroupTagMemberService,
  updateGroupTagService,
  removeGroupTagService,
  removeGroupTagMemberService,
  addGroupAccessRoleService,
  getAllGroupAccessRolesService,
  updateGroupAccessRoleByIdService,
  getGroupAccessRoleByIdService,
  AssignGroupTagToMemberService,
  getAllGroupPagingService

} from "../services/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
  addGroupMember,
  deleteGroupMember,
  removeGroupFeatures,
  addgroupTagMember,
  GroupTagMember,
  deleteGroupTagMember,
  checkIsAdminPresent,
} from "../store/slice";
import { ActionType } from "../../../sharedComponents/CustomModal";
import { ROUTES } from "../../../../utils/routes";

export const getAllGroup = createAsyncThunk(
  "getAllGroup",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllGroupService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      return rejectWithValue(res.message);
    }
  }
);

export const addGroup = createAsyncThunk(
  "addGroup",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addGroupService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(checkIsAdminPresent(false));

      dispatch(
        openNotification({
          message: "Group Created Successfully!",
          type: "success",
          duration: 2,
          actionType: ActionType.Route,
          actionData: {
            path: `${ROUTES.GROUP.DEFAULT}/${res.data.id}`,
          },
        })
      );

      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const updateGroup = createAsyncThunk(
  "updateGroup",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateGroupService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Group Updated Successfully!",
          type: "success",
          duration: 2,
        })
      );
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getGroupById = createAsyncThunk(
  "getGroupById",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getGroupByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addGroupMemberAction = createAsyncThunk(
  "groupMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addGroupMemberService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(addGroupMember(res.data));
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllGroupMemberAction = createAsyncThunk(
  "GetgroupMember",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllGroupMemberService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const deleteGroupMemberAction = createAsyncThunk(
  "deletegroupMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await deleteGroupMemberService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(deleteGroupMember(data));
      return data.memberId;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addGroupFavoriteMarkAction = createAsyncThunk(
  "addGroupFavoriteMark",
  async (data, { dispatch, rejectWithValue }) => {
    console.log(data, "addGroupFavoriteMarkAction");
    const res = await addGroupFavoriteMarkService(data);
    console.log(res, "addGroupFavoriteMarkAction");
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addGroupFeatures = createAsyncThunk(
  "addGroupFeature",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await addGroupFeaturesService(data);
    console.log(res, "responsee action");
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const removeGroupFeaturesAction = createAsyncThunk(
  "removeGroupFeature",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await removeGroupFeaturesService(data);
    if (res.responseCode === responseCode.Success) {
      dispatch(removeGroupFeatures(data));
      return data;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getGroupFeatures = createAsyncThunk(
  "getGroupFeature",
  async (id, { dispatch, rejectWithValue }) => {
    const res = await getGroupFeaturesService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const addGroupTag = createAsyncThunk(
  "GroupTag/addGroupTag",
  async (args, { dispatch }) => {
    const res = await addGroupTagService(args);
    console.log(res.data, "actionData");
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = "Group Tag added successfully!";
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res.data;
  }
);

export const getAllGroupTag = createAsyncThunk(
  "GroupTag/getAllGroupTag",
  async (data) => {
    const response = await getAllGroupTagService(data);
    console.log(response, "responseeeeee");
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);

export const updateGroupTag = createAsyncThunk(
  "group/updateGroupTag",
  async (args, { dispatch }) => {
    const res = await updateGroupTagService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = "GroupTag updated successfully!";
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const removGroupTag = createAsyncThunk(
  "group/removGroupTag",
  async (args, { dispatch }) => {
    const res = await removeGroupTagService(args.id);
    console.log(res, "resss actionn");
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = "custom removed successfully!";
      message.success(res.message);

      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const addGroupTagMember = createAsyncThunk(
  "group/addGroupTagMember",
  async (data, { dispatch }) => {
    const res = await addGroupTagMemberService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        dispatch(GroupTagMember(res.data));
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res.data;
  }
);

export const deleteGroupTagMemberAction = createAsyncThunk(
  "deleteGroupTagMember",
  async (data, { dispatch }) => {
    const res = await removeGroupTagMemberService(data);
    console.log(res, "resssssponseeeee");
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        dispatch(deleteGroupTagMember(data));
      return data.memberId;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

/*ACCESS ROLE*/

export const addGroupAccessRole = createAsyncThunk(
  "addAccessRole",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await addGroupAccessRoleService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Access Role added successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAllGroupAccessRoles = createAsyncThunk(
  "getAllAccessRoles",
  async (data, { dispatch, getState }) => {
    const res = await getAllGroupAccessRolesService(data);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const updateGroupAccessRoleById = createAsyncThunk(
  "updateAccessRoleById",
  async (data, { dispatch }) => {
    const res = await updateGroupAccessRoleByIdService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Access Role updated successfully!");
    }
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getGroupAccessRoleById = createAsyncThunk(
  "getAccessRoleById",
  async (data, { dispatch }) => {
    const res = await getGroupAccessRoleByIdService(data);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);


export const assignGroupTagToMember = createAsyncThunk(
  "assignGroupTagToMember",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AssignGroupTagToMemberService(data);
    if (res.responseCode === responseCode.Success) {
      message.success("Tags added successfully!");
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getAllGroupPagination = createAsyncThunk(
  "getAllGroupPagination",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await getAllGroupPagingService(data);
    if (res.responseCode === responseCode.Success) {
      responseMessage({ dispatch, data: res });
      return res;
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
      return rejectWithValue("Something went wrong");
    }
  }
);