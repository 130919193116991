export const CalculateInterviewQuestionAvgRating = (questions) => {
  const questionMap = {};

  Object.values(questions)
    .flat()
    .forEach(({ questionId, question, rating = 0 }) => {
      if (!questionMap[questionId]) {
        questionMap[questionId] = { questionId, question, ratings: [] };
      }
      questionMap[questionId].ratings.push(rating);
    });

  return Object.values(questionMap).map(
    ({ questionId, question, ratings }) => ({
      questionId,
      question,
      rating:
        ratings.reduce((acc, curr) => acc + curr, 0) / ratings.length || 0,
    })
  );
};
