import React, { useContext, useEffect, useState } from "react";
import { Button, Skeleton, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { GetWarningById, cancelWarning } from "../../../../store/actions";
import { removeIdentifier } from "../../../../../leave/store/slice";
import ItemDetailModal from "../../../../../../sharedComponents/ItemDetails";
import {
  ItemContent,
  ItemHeader,
} from "../../../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "../../../UI/statusTag";
import { WarningStatusEnum } from "../../../../utils/WarningStatusEnum";
import Avatar from "../../../../../../sharedComponents/Avatar/avatar";
import { ApprovalsModule } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import ConfirmationRemarkModal from "../../../../../../sharedComponents/ConfirmationRemarkModal/ConfirmationRemarkModal";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import RemarksApproval from "../../../../../../sharedComponents/AppComponents/Approvals/view";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import { readCountforWarning } from "../../../../store/slice";
import ExpandableDescription from "../../../../../../sharedComponents/expandableDescription/expandableDescription";

function WarningDetail({ id, index }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { warning, sharedLabels } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.userSlice);
  let userId = user.id;

  const [isExpanded, setIsExpanded] = useState(false);

  const [state, setState] = useState({
    identifier: moment().unix(),
    warningDetail: {
      creator: {},
      description: "",
      approvers: [],
      members: [],
      status: 1,
      referenceNo: 0,
      createDate: "",
      isLoading: true,
      category: ""
    },
    approvers: {
      isOpen: false,
      data: [],
    },
    members: {
      isOpen: false,
    },
  });

  const { data } = useSelector((state) => state.warningSlice);

  let warningDetail = data !== null ? data[state.identifier] : [];
  warningDetail =
    warningDetail && warningDetail?.length > 0 && warningDetail[0];

  const onClose = () => {
    setIsOpen(false);
  };

  const onFinish = (values) => {
    let id = warningDetail.id;
    let reason = values.remarks;
    dispatch(cancelWarning({ id: id, reason: reason })).then((res) => {
      setIsOpen(false);
    });;
  };

  useEffect(() => {
    setState({
      ...state,
      warningDetail: { ...state.warningDetail, isLoading: true },
    });
    id && dispatch(GetWarningById({ id: id, identifier: state.identifier }));
  }, [id]);

  useEffect(() => {
    warningDetail &&
      setState({
        ...state,
        warningDetail: {
          ...state.warningDetail,
          ...warningDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: warningDetail?.approvers,
        },
      });
  }, [warningDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const {
    creator,
    description,
    category,
    approvers,
    members,
    status,
    referenceNo,
    createDate,
    isLoading,
    notificationCount
  } = state.warningDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Warning,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforWarning(id))
      })
    }
  }, [notificationCount])

  if (state.warningDetail.isLoading) return <Skeleton />;

  return (
    <>
      {
        <ItemDetailModal
          data={members} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={state.members.isOpen}
          setVisible={(da) =>
            setState({ ...state, members: { ...state.members, isOpen: da } })
          }
        />
      }

      {warningDetail && warningDetail?.id && (
        <div className="detailedCard">
          <ItemHeader>
            <div className="left">
              <UserInfo
                avatarSrc={creator?.image}
                status={creator?.userActiveStatus}
                profileId={creator?.id}
                name={creator?.name}
                Subline={
                  <SublineDesigWithTime
                    designation={
                      creator?.designation ? creator?.designation : ""
                    }
                    time={moment
                      .utc(createDate)
                      .local()
                      .fromNow()}
                  />
                }
              />
            </div>
            <div className="right">
              <Tag className="IdTag">{referenceNo}</Tag>
              <StatusTag status={status}></StatusTag>
              {userId === creator?.id ? (
                status !== WarningStatusEnum.Approved &&
                  status !== WarningStatusEnum.Declined &&
                  status !== WarningStatusEnum.Cancelled ? (
                  <Button
                    className="ThemeBtn"
                    onClick={(e) => handleCancel(e, id)}
                  >
                    {sharedLabels.cancel}
                  </Button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </ItemHeader>
          <ItemContent className="flex">
            <div className="description">
              <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </div>
          </ItemContent>
          <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
            <div className="cardSectionItem">
              <div className="cardSection__title">
                {sharedLabels.category}
              </div>
              <div className="cardSection__body">
                <Tag className="IdTag">
                  {category ? category : "Default Category"}
                </Tag>
              </div>
            </div>
            <div className="cardSectionItem">
              <div className="cardSection__title">
                {warning.warningTo}
              </div>
              <div className="cardSection__body">
                {members && (
                  <div
                    onClick={() => {
                      setState({
                        ...state,
                        members: { ...state.members, isOpen: true },
                      });
                    }}
                  >
                    <Avatar
                      isAvatarGroup={true}
                      isTag={false}
                      heading={"Members"}
                      membersData={members}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <RemarksApproval
            module={ApprovalsModule.WarningApproval}
            status={status}
            reference={warningDetail?.id}
            data={state.approvers.data && state.approvers.data}
            title={sharedLabels.Approvers}
            notRequiredMemberId={state.warningDetail.members?.map(
              (item) => item.memberId
            )}
            itemCreator={creator}
          />
        </div>
      )}
      <ConfirmationRemarkModal
        key={Math.random()}
        isOpen={isOpen}
        onCancel={onClose}
        onFinish={onFinish}
      />
    </>
  );
}

export default WarningDetail;
