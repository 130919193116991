import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { addExpense, getExpenseById, getAllExpenseAction } from "./actions.js";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum.js";
import { ExpenseApprovalsModuleEnum } from "../utils/expenseApprovalModuleEnum.js";
import { ExpenseApprovalsModuleStatusEnum } from "../utils/expenseApprovalModuleStatusEnum.js";
import { calculateFinalStatus } from "../utils/calculateStatus.js";

const expenseSlice = createSlice({
  name: "expense",
  initialState: {
    data: null,
    newItem: null,
    newCountFilterButton:null
  },
  reducers: {
    handleResetState: (state, { payload }) => {
      state.data = [];
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined" ) delete state.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined" )
        state.data[identifier] = [state.newItem].concat(state.data?.[identifier]);
    },
    handleExpenseApprovalRemark: (state, { payload }) => {
      try {

        console.log(payload,"PAYLOAD-handleExpenseApprovalRemark");


        const currentState = current(state);
        const { referenceId, remark, module } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              const approvers = [
                ...referenceItem[ExpenseApprovalsModuleEnum[module]],
              ];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                [ExpenseApprovalsModuleEnum[module]]: finalApprovers,
                [ExpenseApprovalsModuleStatusEnum[module]]:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };
              const statusEnums = Object.values(
                ExpenseApprovalsModuleStatusEnum
              );
              const statuses = statusEnums.map(
                (statusKey) => referenceItem[statusKey]
              );

              referenceItem = {
                ...referenceItem,
                status: calculateFinalStatus(statuses),
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleAddExpenseApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem[ExpenseApprovalsModuleEnum[module]],
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                [ExpenseApprovalsModuleEnum[module]]: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    readCountforExpense: (state, { payload }) => {
      const id = payload
      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              referenceItem = {
                ...referenceItem,
                notificationCount:0
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
          state.newCountFilterButton = id
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleNotificationNewItem: (state, { payload }) => {
      
      try{
        const currentState = current(state);
        if(currentState.data!==null)
        { 
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys?.forEach((identifier)=>{
            const current = currentState.data[identifier]
            const index  = current.findIndex(y => y.id === payload.featureId)
            if(index !==-1)
            {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                  ...referenceItem,
                  notificationCount:referenceItem.notificationCount+1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch(e)
      {
        console.log("===error===",e)
      }    },
      handleExpenseReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
            const finanecersIndex = current[index].financers.findIndex(item => item.id == approvalId)
            const executorsIndex = current[index].executors.findIndex(item => item.id == approvalId)

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }
              else if(finanecersIndex !==-1)
              {
                let financers =[ ...referenceItem.financers]
                let currentfinancer = financers[finanecersIndex]

                currentfinancer = {
                  ...currentfinancer,
                  reminderCount : currentfinancer.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                financers[finanecersIndex] = currentfinancer
                
                referenceItem = {
                  ...referenceItem,
                  financers : financers
                }
              }
              else
              {
                let executors =[ ...referenceItem.executors]
                let currentexecutor = executors[executorsIndex]

                currentexecutor = {
                  ...currentexecutor,
                  reminderCount : currentexecutor.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                executors[executorsIndex] = currentexecutor
                
                referenceItem = {
                  ...referenceItem,
                  executors : executors
                }
              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExpenseAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
        }
      })
      .addCase(addExpense.fulfilled, (state, { payload }) => {
        state.newItem = payload;
      })
      .addCase(getExpenseById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;

        const data = action.payload.data ?? [];

        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })
  },
});
export const {
  
  handleNewItem,
  removeIdentifier,
  handleExpenseApprovalRemark,
  handleResetState,
  handleAddExpenseApprover,
  readCountforExpense,
  handleNotificationNewItem,
  handleExpenseReminder
} = expenseSlice.actions;
export default expenseSlice.reducer;
