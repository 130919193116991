import { ExpenseApprovalStatusEnum } from "./expenseStatusEnum";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums/index";


export function calculateFinalStatus(statuses) {
    
    console.log("zzzzzzzzzzzzzzzzz", statuses)
    // If any status is Declined, return Declined
    if (statuses.includes(ApprovalStatus.Declined)) {
        return ExpenseApprovalStatusEnum.Declined;
    }

    // If any status is Hold, return InProcess
    if (statuses.includes(ApprovalStatus.Hold)) {
        return ExpenseApprovalStatusEnum.InProcess;
    }

    // If all statuses are Approved, return Approved
    if (statuses.every(status => status === ApprovalStatus.Approved)) {
        return ExpenseApprovalStatusEnum.Approved;
    }

    // If all statuses are InProcess, return InProcess
    if (statuses.every(status => status === ApprovalStatus.InProcess)) {
        return ExpenseApprovalStatusEnum.InProcess;
    }
    // Default value
    return ExpenseApprovalStatusEnum.InProcess; 
}