import {Avatar} from "antd";
import {getNameForImage} from "../../../../utils/base";

function CommentAvatar({name = "", image="", size = 25}) {
    return(<>
        <Avatar
            className="!bg-black"
            src={image.length>0 && image}
            name={name}
            size={size}
            round={true}
        >
            {getNameForImage(name)}
        </Avatar>
    </>)
}
export default CommentAvatar;