import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Badge } from 'antd';
import "../../style/driveItem.css";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import moment from "moment";
import { LockFilled } from "@ant-design/icons";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { documentDictionaryList } from "../../localization";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { DOCUMENT_ENUM } from "../../utils/DocumentEnum";
import { privacyOption } from "../../../../../utils/Shared/enums/enums";
import { getIconByExtensionType } from "../../constant/helpers";
import { DocumentStatusEnum } from "../../utils/DocumentStatusEnum";
import { handleFavoriteMark, handleParentId } from "../../store/slice";
import {
  addDocumentFavorite,
  moveDirectory,
  moveDocument,
} from "../../store/actions";
import { openNotification } from "../../../../../utils/Shared/store/slice";
import QuickOptions from "./quickOptions";
import { Tag, Avatar } from "antd";
import { getNameForImage } from "../../../../../utils/base";
import NotificationBadge from "../../../../sharedComponents/Badge/NotificationBadge";

export default function DriveItem({
  item,
  handlePreview,
  isDetail = false,
  hideControls = false,
}) {
  // const { userLanguage } = useContext(LanguageChangeContext);
  // const { documentDictionary } = documentDictionaryList[userLanguage];

  const dispatch = useDispatch();

  // variables
  let {
    name,
    documentType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
    creator = {},
    createDate,
    id,
    path,
    extensionTypeId,
    privacyId,
    status,
    notificationCount,
    isSignedDocument
  } = item;

  let { Public, Private, External } = privacyOption;

  let { DUCOMENT_TYPE } = DOCUMENT_ENUM;

  const localTime = moment
    .utc(createDate)
    .local()
    .format();

  //functions

  const handleClick = useCallback((item) => {
    if (documentType === DUCOMENT_TYPE.folder) {
      dispatch(handleParentId(item));
    } else {
      handlePreview(item);
    }
  },[dispatch, handlePreview]);

  const handleDrop = useCallback((item) => {
    console.log(item, "==MoveDrop==");
    let dragData = item?.dragData?.name;
    let dropData = item?.dropData?.name;

    if (
      dragData.documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder &&
      dropData.documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder
    ) {
      dispatch(
        moveDirectory({
          parentId: dropData.id,
          documents: [dragData.id],
        })
      );
    } else if (dropData.documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder) {
      dispatch(
        moveDocument({
          parentId: dropData.id,
          documents: [dragData.id],
        })
      );
    } else {
      dispatch(openNotification({ message: "Invalid Move", type: "error" }));
    }
  },[dispatch]);

  const handleFavorite = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(handleFavoriteMark({ id: item.id, isPinned: !item.isPinnedPost }));
    dispatch(
      addDocumentFavorite({ id: item.id, isPinned: !item.isPinnedPost })
    );
  },[dispatch, item.id, item.isPinnedPost]);

  const CardComponent = useMemo(() => {
    return (
      <div
      // className={status === DocumentStatusEnum.Draft && "shortcardborder"}
    >
       {/* <div class="ribbon ribbon-top-left"><span>Signed</span></div> */}
      <div
        className={`d_ShortCard ${notificationCount > 0 ? "unreadNotifyItem" : ""
          }`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        id={item.id}
      >
        {!hideControls && !isDetail && (
          <div className="d_ShortCard_Child1">
        
            <div className="flex">
              <div>
                <NotificationBadge
                  notificationCount={notificationCount}
                />
              </div>
              {/* <div onClick={(e) => handleFavorite(e)}>
                {item.isPinnedPost ? (
                  <StarFilled className="!text-[18px] !text-yellow-400 cursor-pointer" />
                ) : (
                  <StarOutlined className="!text-[18px] cursor-pointer !text-[#707070]" />
                )}
              </div> */}
            </div>
            <div className="flex justify_between gap-2">
            <div onClick={(e) => handleFavorite(e)}>
                {item.isPinnedPost ? (
                  <StarFilled className="!text-[18px] !text-yellow-400 cursor-pointer" />
                ) : (
                  <StarOutlined className="!text-[18px] cursor-pointer !text-[#707070]" />
                )}
              </div>
              <QuickOptions item={item} documentType={documentType} />
            </div>
          </div>
        )}
        <div className="d_ShortCard_Child2">
          <img
            style={{ height: "45px" }}
            onClick={() => handleClick(item)}
            alt=""
            src={
              documentType === DUCOMENT_TYPE.image && path
                ? path
                : getIconByExtensionType(documentType, extensionTypeId,null,isSignedDocument)
            }
          />
        </div>
        <div className="fileName">
          <p className="w-[120px] truncate mt-[1px] text-center items-center">{name}</p>
        </div>

        {!hideControls && (
          <div className="h-[20px] flex items-center justify-between">
            <Avatar
              style={{ marginBottom: "11px" }}
              className="!bg-black"
              name={creator.name}
              src={creator.image?.length > 0 && creator.image}
              round={true}
              size="small"
            >
              {getNameForImage(creator.name)}
            </Avatar>
            <div className="privacyStatus">
              {privacyId === Private ? (
                <LockFilled
                  style={{ color: "var(--currentThemeColor)" }}
                />
              ) : (
                ""
              )}
            </div>

            {!isDetail && (
              <h6 className="dateTime">{moment(localTime).fromNow()}</h6>
            )}
          </div>
        )}
      </div>
    </div>
    );
  },[item, handleFavorite, handleClick, hideControls, isDetail])


  return (
    <>
      <DragDropContainer
        targetKey={"docsDrag"}
        dragData={{ name: item }}
        onDrop={(e) => handleDrop(e)}
        key={item.id}
        noDragging={false}
      >
        <DropTarget
          onHit={(e) => { }}
          targetKey="docsDrag"
          highlighted
          dropData={{ name: item }}
          key={item.id}
        // onDrop={handleDrop}
        >
          {
            status === DocumentStatusEnum.Draft ? (
              <Badge.Ribbon placement="start" text="Draft" color="var(--currentThemeColor)">
              {CardComponent}
            </Badge.Ribbon>
            ) : (
              CardComponent
            )
          }
         
        
        </DropTarget>
      </DragDropContainer>
    </>
  );
}
