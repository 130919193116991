import { createSlice } from "@reduxjs/toolkit";
import { getAllFinanceAccount } from "./action";

const initialState = {
    financeData: [],
};
const financeAccountSlice = createSlice({
    name: "financeAccount",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllFinanceAccount.fulfilled, (state, action) => {
            const { payload } = action;
            state.financeData = payload
        })
    }
})

export const {
} = financeAccountSlice.actions;
export default financeAccountSlice.reducer;
