import { Modal } from "antd";
import React from "react";
import TaskDetailItem from "./DetailItem";
function TaskDetailModal({ id, index, onClose, isOpen }) {
  return (
    <Modal
      wrapClassName="custom-modal-view"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      style={{
        top: 20,
      }}
      width={1000}
    >
      {id && <TaskDetailItem id={id} index={index} />}
    </Modal>
  );
}
export default TaskDetailModal;
