import MasterConfig from "../../../../utils/services/MasterConfig";

export const saveAttandanceDeductionPolicyService = (args) => {
    return MasterConfig.post(`api/Attendance/SaveAttendanceDeductionPolicy`, args)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  

  export const GetAllAttendanceDeductionPolicyService = () => {
    return MasterConfig.get(`api/Attendance/GetAllAttendanceDeductionPolicy`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  

  export const GetAttendanceDeductionPolicyByIdService = (id) => {
    return MasterConfig.get(`api/Attendance/GetAttendanceDeductionPolicy?policyId=${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  