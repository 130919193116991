import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
  getAllPromotionService,
  GetPromotionByIdService,
  addPromotionService,
  cancelPromotionService,
  getAllPromotionPagingService,
} from "../services/service";
import { cancelPromotionSuccess } from "./slice";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const GetAllPromotionsAction = createAsyncThunk(
  "Promotion/GetAllPromotionsAction",
  async (
    { filter, pageSize , segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllPromotionPagingService({...filter , pageSize});
      console.log(response,"responseeeeeeee");
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllPromotionService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

export const addPromotion = createAsyncThunk(
  "Promotion/addPromotion",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addPromotionService(data);
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Promotion Created");
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const GetPromotionById = createAsyncThunk(
  "Promotion/GetPromotionById",
  async ({ id }) => {
    const response = await GetPromotionByIdService(id);
    return response.data;
  }
);

export const cancelPromotion = createAsyncThunk(
  "promotions/cancelPromotion",
  async (id, { dispatch }) => {
    const response = await cancelPromotionService(id);
    // dispatch(cancelPromotionSuccess({ promotionId: id }));
    return response;
  }
);

export const getAllPromotionPaging = createAsyncThunk(
  "promotion/getAllPromotion/paging",
  async (data) => {
    const response = await getAllPromotionPagingService(data);
    if (!response.responseCode) {
      message.error("Something went wrong");
    }
    return response.data;
  }
);
