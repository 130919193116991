export const WorkBoardReferenceTypeEnum = {
  General: 1,
  Project: 2,
  Group: 3,
};
export const WorkBoardMemberActionTypeEnum = {
  WorkBoardMember: 1,
  WorkBoardToDoMember: 2,
};

export const WorkBoardMemberTypeEnum = {
  Member: 1,
  Admin: 2,
};
