import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleEventDetailComposer } from "../store/slice";

import ScheduleComposer from "./Composer/ScheduleComposer";

function EventDetail() {
	const dispatch = useDispatch();
	const { eventDetailComposer, scheduleComposerData: sched } = useSelector(
		state => state.scheduleSlice
	);
	return (
		<>
			<ScheduleComposer
				Direction={"ltr"}
				visible={eventDetailComposer}
				onClose={() => dispatch(toggleEventDetailComposer(sched))}
				id={sched?.id}
				type={sched?.scheduleType}
			/>

		</>
	);
}

export default EventDetail;
