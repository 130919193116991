import React, { useContext } from "react";
import { Tag } from "antd";
import { TravelStatusEnum } from "../../utils/travelStatusEnum";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";

const StatusTag = ({status = TravelStatusEnum.InProcess}) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { travel } = dictionaryList[userLanguage];
    const {statusEnum} = travel;
    let color = ColorsEnum.Primary;

    switch(status){
        case TravelStatusEnum.Approved: 
            color = ColorsEnum.Success;
            break;
            case TravelStatusEnum.Declined: 
            color = ColorsEnum.Danger;
            break;
            case TravelStatusEnum.Cancelled: 
            color = ColorsEnum.Cancelled;
            break;
            case TravelStatusEnum.Hold: 
            color = ColorsEnum.Hold;
            break;
            
        default : 
            color = ColorsEnum.Primary;
    }
    return (
        <>
        <Tag className="statusTag" style={{ backgroundColor: color }}>
            {statusEnum[status]}
        </Tag>
        </>
    );
};
      

export default StatusTag;