import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { PlusOutlined, EditOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import { dictionaryList } from '../../../../../utils/localization/languages';
import { employeeDictionaryList } from '../../localization/index';
import {
    DatePicker,
    Checkbox,
    Form,
    Divider,
    Input,
    Button,
    Table,
    Row,
    Col,
    Select
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
    addUserEducationAction,
    getEducationDetailByUser,
} from '../../../education/store/actions';
import { STRINGS } from '../../../../../utils/base';
import { getCities, getCountries } from '../../../../../utils/Shared/store/actions';
import SingleUpload from '../../../../sharedComponents/Upload/singleUpload';
import CitySelectHandler from '../employeeCityHandle/citySelectHandler';

const { Option } = Select;
const { RangePicker } = DatePicker;

const PreEducationForm = ({ id, mode, getEducationFormData = () => { } }) => {
    const param = useParams();
    const isEdit = mode === 'edit';
    const dispatch = useDispatch();
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels } = dictionaryList[userLanguage];
    const [isPresent, setIsPresent] = useState(false);
    const [handleUpdateButton, setHandleUpdateButton] = useState(false)
    const { employeesDictionary, Direction } = employeeDictionaryList[userLanguage];
    const { employee: { educationdetails }, success, } = useSelector((state) => state.employeeSlice);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [initialSelectedCity, setInitialSelectedCity] = useState(null);
    const { cities } = useSelector((state) => state.sharedSlice);
    const labels = employeesDictionary.EducationForm;
    const placeholder = employeesDictionary.placeholders;
    const [newUserId, setNewUserId] = useState('');
    const { countries: countriesSlice } = useSelector((state) => state.sharedSlice);
    const [educationDetails, setEducationDetails] = useState([]);
    const [form] = Form.useForm();
    Object.defineProperty(form, 'values', {
        value: function () {
            return educationDetails.map((item) => {
                return {
                    ...item,
                    startDate: moment(item[0]?.startEndDate?._ds)?.format(),
                    endDate: item?.isPresent
                        ? ''
                        : moment(item[1]?.startEndDate?._ds)?.format(),
                };
            });
        },
        writable: true,
        enumerable: true,
        configurable: true,
    });
    useEffect(() => {
        if (success) setEducationDetails([]);
    }, [success]);

    const initialState = {
        degree: '',
        institute: '',
        description: '',
        totalMarks: '',
        obtainedMarks: '',
        startDate: '',
        countryId: "",
        cityId: "",
        city: "",
        isPresent: false,
    };
    const [initialValues, setInitialValues] = useState(initialState);
    const [attachments, setAttachments] = useState([]);
    const [attachmentDetail, setAttachmentDetail] = useState({
        isOpen: false,
        data: [],
    });
    const onHandleAttachment = (attachments) => {
        setAttachmentDetail({
            data: attachments,
            isOpen: true,
        });
    };
    useEffect(() => {

        getEducationFormData(educationDetails)
    }, [educationDetails])
    console.log(educationDetails, "uuuuuuu");

    const columns = (onHandleAttachment) => [
        {
            title: labels.Degree,
            dataIndex: 'degree',
            key: 'degree',
        },
        {
            title: labels.Institute,
            dataIndex: 'institute',
            key: 'institute',
        },
        {
            title: sharedLabels.country,
            dataIndex: 'countryId',
            ellipsis: true,
            key: 'country',
            render: (value) => {
                return countries?.filter((item) => item.id === value?.toString())?.[0]?.name;
            },
        },
        {
            title: sharedLabels.City,
            dataIndex: "city",
            key: "city",
            render: (value) => {
                const city = cities.find((item) => item.id === value?.toString());
                return city ? city.name : '';
            },
        },
        {
            title: labels.Description,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: labels.ObtainedMarks,
            dataIndex: 'obtainedMarks',
            key: 'obtainedMarks',
        },
        {
            title: labels.TotalMarks,
            dataIndex: 'totalMarks',
            key: 'totalMarks',
        },

        {
            title: labels.StartEndDate,
            dataIndex: 'startDate',
            key: 'startDate',
            // width: 5,
            render: (value, row) => {
                return value?.length
                    ? `${moment(row.startDate[0]).format('YYYY/MM/DD')} - ${moment(
                        row.startDate[1]
                    ).format('YYYY/MM/DD')}`
                    : `${moment(row.startDate).format('YYYY/MM/DD')} -  Present`;
            },
        },
        {
            title: sharedLabels.Attachments,
            dataIndex: "attachments",
            key: "attachments",
            render: (attachments) => {

                return (attachments?.length > 0 ? (
                    <a
                        onClick={(e) => {
                            e.stopPropagation();
                            onHandleAttachment(attachments);
                        }}
                    >
                        {`${attachments?.length} ${sharedLabels.Attachments}`}
                    </a>
                ) : null)

            }

        },

        {
            title: sharedLabels.action,
            render: (value, row) => {
                return (
                    <a
                        href=" "
                        onClick={(e) => {
                            e.preventDefault();
                            if (isEdit) {
                                handleRowChange(row.id);
                                setHandleUpdateButton(true);
                            } else {
                                const filterArray = educationDetails.filter((value, i) => {
                                    if (row.id !== value.id) return value;
                                });
                                setEducationDetails(filterArray);
                            }
                        }}
                    >
                        {isEdit ? <EditFilled style={{ color: "#1b5669" }} /> : <DeleteFilled style={{ color: "#1b5669" }} />}
                    </a>
                );
            },
        },
    ];

    const handleRowChange = (rowId) => {
        const selectedRow = educationDetails.find((row) => row.id === rowId);
        setInitialValues(selectedRow);
        setNewUserId(rowId);
        setHandleUpdateButton(true);
        setSelectedCountry(selectedRow.countryId);
        setInitialSelectedCity({
            id: selectedRow.cityId,
            name: selectedRow.city,
            countryId: selectedRow.countryId,
            country: selectedRow.country,
        });
    };

    const handleAddMore = async () => {
        form.submit();

        try {
            const isValidation = await form.validateFields();
            if (isValidation) {
                const payload = form.getFieldsValue();
                console.log(payload, "ppppppp");
                const selectedCity = cities.find((c) => c.id === payload.cityId);
                payload.city = selectedCity ? selectedCity.name : '';
                payload.cityId = payload.cityId;


                const attachmentsData = attachments.map((file) => ({
                    file: file.file,
                    id: file.id,
                }));
                payload.attachments = attachmentsData;

                if (isEdit) {
                    const payloadObj = {
                        payload,
                        id: param.id,
                    };
                    dispatch(addUserEducationAction(payloadObj));
                }

                setEducationDetails((prevValues) => [...prevValues, payload]);
                form.resetFields();
                setIsPresent(false);
                setAttachments([]);
                setInitialValues(initialState);
            }
        } catch (err) {
            throw new Error(`Error in validation ${err}`, { cause: err });
        }
    };
    useEffect(() => {
        form.setFieldsValue(initialValues);
        if (isEdit) setIsPresent(initialValues.isPresent);
    }, [initialValues, form]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getEducationDetailByUser(id));
            if (!cities.length) fetchCityData('', 0);
        }
        return () => {
            form.setFieldsValue(initialValues);
        };
    }, []);

    useEffect(() => {
        if (isEdit) {
            setEducationDetails(
                educationdetails?.map((item) => {
                    return {
                        ...item,
                        startDate: item.isPresent
                            ? moment(item.startDate)
                            : [moment(item.startDate), moment(item.endDate)],
                        // attachments: item.attachments,
                    };
                })
            );
        }
    }, [educationdetails]);

    useEffect(() => {
        isEdit && !countriesSlice.length && dispatch(getCountries())
    }, []);
    useEffect(() => {
        setCountries(countriesSlice);
    }, [countriesSlice]);


    const fetchCityData = (text, pgNo) => {
        dispatch(getCities({ textData: text, page: pgNo }));
    };

    let classes = 'employeeForm educationDetails ';
    classes += Direction === 'ltr' ? 'ltr' : 'rtl';

    console.log(form.getFieldValue(), "educationdetailseducationdetails");
    return (
        <>
            <div className={classes}>
                <Divider orientation="left"> {labels.EducationInfo}</Divider>
                <Form
                    name="educationDetails"
                    form={form}
                    layout={'vertical'}
                    initialValues={initialValues}
                >
                    <Row gutter={[16]}>
                        <Col span={6}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                name="degree"
                                label={labels.Degree}
                            >
                                <Input placeholder={placeholder.degree}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                name="institute"
                                label={labels.Institute}
                            >
                                <Input placeholder={placeholder.institute}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                name="description"
                                label={labels.Description}
                            >
                                <Input placeholder={placeholder.desc}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                name="totalMarks"
                                label={labels.TotalMarks}
                            >
                                <Input disabled={handleUpdateButton} type="number" placeholder={placeholder.tMarks}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16]}>
                        <Col span={6}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                name="obtainedMarks"
                                label={labels.ObtainedMarks}
                            >
                                <Input disabled={handleUpdateButton} type="number" placeholder={placeholder.oMarks}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className="dates">
                                {!isPresent && (
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        name="startDate"
                                        label={labels.StartEndDate}
                                    >
                                        <RangePicker
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            size="large"
                                            format={'DD/MM/YYYY'}
                                            placeholder={[placeholder.sDate, placeholder.eDate]}
                                            disabled={handleUpdateButton}
                                        />
                                    </Form.Item>
                                )}

                                {isPresent && (
                                    <Form.Item
                                        rules={[{ required: true }]}
                                        name="startDate"
                                        label={labels.StartDate}
                                    >
                                        <DatePicker
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            format={'DD/MM/YYYY'}
                                            placeholder={labels.start}
                                            size="large"
                                            disabled={handleUpdateButton}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item name="isPresent" valuePropName="checked">
                                    <Checkbox
                                        disabled={handleUpdateButton}
                                        onChange={() => {
                                            setIsPresent(!isPresent);
                                            form.setFieldValue('startDate', '');
                                            form.setFieldValue('startEndDate', '');
                                        }}
                                    >
                                        {labels.Present}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="countryId"
                                label={sharedLabels.country}
                                rules={[{ required: true, message: sharedLabels.requiredCountry }]}
                            >
                                <Select
                                    showSearch={true}
                                    placeholder={sharedLabels.SelectCountry}
                                    size="large"
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    optionFilterProp="children"
                                    onChange={() => {
                                        setSelectedCountry(form.getFieldValue("countryId"));
                                        form.setFieldValue("cityId", "");
                                    }}
                                >
                                    {countries.map((item) => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="cityId"
                                label={sharedLabels.City}
                                rules={[{ required: true, message: sharedLabels.requiredCity }]}
                            >
                                <CitySelectHandler selectedCountry={selectedCountry} form={form} selectedCityObj={initialSelectedCity} />
                            </Form.Item>

                        </Col>

                    </Row>
                </Form>
                {!handleUpdateButton &&
                    <Form.Item label={sharedLabels.Attachments} name="Attachments" className="w-full block attachmentEmp">
                        <>
                            <SingleUpload key={educationDetails.length}
                                handleImageUpload={(files) =>
                                    setAttachments(
                                        files.map((file) => ({
                                            file: file.originFileObj,
                                            id: STRINGS.DEFAULTS.guid,
                                        }))
                                    )
                                }
                                uploadText={sharedLabels.upload}
                                multiple={true}
                            />
                        </>
                    </Form.Item>
                }
                <div className={isEdit ? 'editButtons' : 'buttons'}>
                    {handleUpdateButton ?
                        <Button
                            className="mb-2 btn ThemeBtn"
                            icon={<EditOutlined />}
                        // onClick={handleUpdate}
                        >
                            {labels.UpdateEducation}
                        </Button> : <Button
                            type="dashed"
                            className="btn addMore"
                            icon={<PlusOutlined />}
                            onClick={handleAddMore}
                        >
                            {labels.AddEducation}
                        </Button>
                    }
                </div>
                {educationDetails.length > 0 && (
                    <Table
                        columns={columns(onHandleAttachment)}
                        dragable={true}
                        dataSource={educationDetails}
                    />
                )}
            </div>

        </>
    );
};

export default PreEducationForm;
