import React, { useState } from "react";
import SwiperCore, {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Zoom,
	Thumbs,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../stylesheet/postAttachment.css"
import { FullscreenOutlined } from "@ant-design/icons";
import { getFile } from "../../../../../travel/view/UI/AttachmentsCarrousel/AttachmentsCarrousel";
import { fileExtentionPreview } from "../../../../../travel/utils/fileExtentionHelper";
import { AttachmentType } from "../../../../../documents/constant";
import { getIconBypathName } from "../../../../../../sharedComponents/FilePreview/helper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom, Thumbs]);

function PostAttachment({ attachments, onOpen, isDetail, setClickedAttachmentIndex}) {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	return (
		<div className="slider-container cursor-pointer">
			<div>
				<div
					className="attacmentFullScreenBtn"
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
						onOpen(true)
					}}
				>
					<FullscreenOutlined />
				</div>
				<div className="slides postAttachment">
					<Swiper
						style={{
							"--swiper-navigation-color": "#fff",
							"--swiper-pagination-color": "#fff",
							height: "100%",
							maxHeight: "360px",
						}}
						spaceBetween={10}
						translate="yes"
						navigation={true}
						thumbs={{ swiper: thumbsSwiper }}
						className="mySwiper2"

						zoom={true}
					>
						{attachments.map((slide, ind) => {
							return (
								<SwiperSlide
									zoom={true}
									key={ind}
									onClick={e => {
										e.stopPropagation()
										e.preventDefault()
									}}
									
								>
									<div
										className="flex justify-center items-center w-full"
										onClick={(e) => {
											e.stopPropagation()
											e.preventDefault()
											setClickedAttachmentIndex(ind.toString())
											onOpen(true);
										}}
									>
									
									{slide.attachmentTypeId ===  AttachmentType.document ? 
										  <img
										  	  style={{width:'170px'}}
											  alt=""
											  src={
												getIconBypathName(slide.path)
												  }
										  /> : 
										  getFile(slide,"",isDetail)
									}
									</div>
								
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</div>
			{attachments.length > 1 && (
				<div className="thumbnails">
					<Swiper
						onSwiper={setThumbsSwiper}
						spaceBetween={2}
						slidesPerView={15}
						freeMode={true}
						watchSlidesProgress={true}
						className="mySwiper"
						touchRatio={0.2}
					>
						{attachments.map((slide, ind) => {
							return (
								<SwiperSlide
									style={{ width: "100px" }}
									zoom={true}
									key={ind}
									className="cursor-pointer"
									onClick={e => {
										e.stopPropagation()
									}}
								>
									<img
										id={1}
										src={fileExtentionPreview(slide.path)}
										style={{
											height: "50px",
											width: "100%",
											objectFit: "contain",
										}}
										alt=""
										className="cursor-pointer"
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			)}
		</div>
	);
}

export default PostAttachment;
