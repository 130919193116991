import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {getAllAccountHeader, getAlldefaultAccountHeader,updatedefaultAccountHeader} from "./action";

const initialState = {
  accountlistData: [],
  accountLebelslistData : [],
  editData: false,
  success: false,
  loader: false,
  error: false,
  createLoader: false,
};

export const DefaultAccountHeaderSlice = createSlice({
  name: "AccountHeader",
  initialState: initialState,
  reducers: {
    handleEdit: (state, { payload }) => {
      state.editData = payload;
    },
  },

  extraReducers: (builder) => {
    builder
    .addCase(getAllAccountHeader.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.accountLebelslistData = payload;
    })
      .addCase(getAlldefaultAccountHeader.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.accountlistData = payload;
      })
      .addCase(updatedefaultAccountHeader.fulfilled, (state, { payload }) => {
        state.createLoader = false;
        state.accountlistData = state.accountlistData.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })
      .addCase(updatedefaultAccountHeader.pending, (state, { payload }) => {
        state.createLoader = true;
       
      })
      .addMatcher(isPending(...[getAlldefaultAccountHeader]), (state) => {
        state.loader = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(
        isRejected(
          ...[getAlldefaultAccountHeader]
        ),
        (state) => {
          state.loader = false;
          state.createLoader = false;
          state.success = false;
          state.error = false;
        }
      );
  },
});

export const { handleEdit } = DefaultAccountHeaderSlice.actions;
export default DefaultAccountHeaderSlice.reducer;
