import { createAsyncThunk } from "@reduxjs/toolkit";
import { jsonToFormData } from "../../../../utils/base";
import { Mail_dto } from "../MailServices/dto";
import { changeMailSeenFlagService, composeMailService, deleteEmailService, getAllEmailService, getMailByIdService, getMenuFoldersService, moveEmailToTrashService } from "../MailServices/MailServices";
import { message } from "antd";

export const getMailFolders = createAsyncThunk(
  "mail/getMailFolders",
  async (configurationId, { rejectWithValue }) => {
    try {
      const response = await getMenuFoldersService(configurationId);
      console.log(response, "responseresponse");
      if (response.data.responseCode === 1001) return response.data;
      else message.error(response?.data?.message)
    } catch (e) {
      console.log(e, "responseresponse");
      return rejectWithValue(e.response.data);
    }
  }
);

export const getAllMail = createAsyncThunk(
  "mail/getAllMail",
  async (objData, { rejectWithValue }) => {
    try {
      // const payload = Mail_dto.getAllMail(objData);
      const response = await getAllEmailService(objData);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const refreshMail = createAsyncThunk(
  "mail/refreshMail",
  async (objData, { rejectWithValue }) => {
    try {
      const response = await getAllEmailService(objData);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getMailByIdAction = createAsyncThunk(
  "mail/getMailById",
  async (params, rejectWithValue) => {
    try {
      const response = await getMailByIdService(params);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const composeMail = createAsyncThunk(
  "mail/compose",
  async (payload, rejectWithValue) => {
    try {
    let {data , configurationId} = payload
      const dataobj = jsonToFormData(data);
      const response = await composeMailService({dataobj, configurationId});
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const changeMailSeenFlag = createAsyncThunk(
  "mail/changeMailSeenFlag",
  async (params, rejectWithValue) => {
    try {
      const response = await changeMailSeenFlagService(params);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const moveEmailToTrash = createAsyncThunk(
  "mail/moveEmailToTrash",
  async (params, rejectWithValue) => {
    try {
      const response = await moveEmailToTrashService(params);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const deleteEmail = createAsyncThunk(
  "mail/deleteEmail",
  async (id, rejectWithValue) => {
    try {
      const response = await deleteEmailService(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
