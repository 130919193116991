import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSignatureService, saveSignature } from "../services/service";
import { responseCode } from "../../../../../../services/enums/responseCode";
import {
    responseMessage,
    responseMessageType,
} from "../../../../../../services/slices/notificationSlice";

export const getAllSignature = createAsyncThunk(
    "api/Signature/getSignatureByUserId",
    async (_, { dispatch }) => {
        try {
            const res = await getAllSignatureService();
            if (res.responseCode !== responseCode.Success) {
                responseMessage({
                    dispatch: dispatch,
                    data: res,
                    type: responseMessageType.ApiFailure,
                });
            }
            else {
                return res;
            }
        } catch (error) {
            console.error("Error fetching signatures:", error);
            responseMessage({
                dispatch,
                type: responseMessageType.ApiFailure
            });
            throw error;
        }
    }
);

export const saveSignatures = createAsyncThunk(
    "/api/Signature/SaveSignature",
    async (args, { dispatch }) => {
        const res = await saveSignature(args);
        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure
            });
        }
        return res;
    }
)
