import React, { useContext, useEffect, useState } from "react";
import CheckoutFormWrapper from "./checkoutFormWrapper";
import { Divider } from "antd";
import { payBillAction } from "../store/action";
import { useDispatch } from "react-redux";
import { SvgSpinner } from "../../../../utils/base";
import { dictionaryList } from "../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import "../style/style.css";
import { responseCode } from "../../../../services/enums/responseCode";

export default function PaymentGatway({
  data = [],
  billingId,
  token,
  onBillPaid = () => {},
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { administration } = dictionaryList[userLanguage];
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isProcessing: false,
    isPaymentSuccess: false,
  });

  const handlePayExistingCard = () => {
    setState({
      ...state,
      isProcessing: true,
    });
    const payload = {
      billingId: billingId,
      cardId: data.find((item) => item.isDefault === true)?.paymentCardId,
      token: token,
      saveCard: false,
      cardToken: "",
    };
    dispatch(payBillAction(payload)).then((response) => {
      setState({
        ...state,
        isProcessing: false,
        isPaymentSuccess:
          response?.payload?.responseCode === responseCode.Success,
      });
    });
  };

  useEffect(() => {
    state.isPaymentSuccess && onBillPaid();
  }, [state.isPaymentSuccess]);

  return (
    <React.Fragment>
      {!state.isPaymentSuccess && (
        <div className="main-header bill-payment">
          <div className="payment-area">
            <div className="payment-heading">
              {data && data.length > 0 ? (
                <button
                  className="payment-payWithBtn"
                  onClick={handlePayExistingCard}
                >
                  {!state.isProcessing ? (
                    `Pay With ****${
                      data.find((item) => item.isDefault === true)
                        .cardLastFourDigit
                    } - ${
                      data.find((item) => item.isDefault === true)
                        .cardHolderName
                    }`
                  ) : (
                    <SvgSpinner />
                  )}
                </button>
              ) : (
                <div>{administration.PaymentCard.PayCard}</div>
              )}
            </div>

            <Divider plain>{administration.PaymentCard.payanotherway}</Divider>
            <div className="note note-heading">
              {administration.PaymentCard.credentialstocontinue}
            </div>
            <CheckoutFormWrapper
              data={data}
              billingId={billingId}
              token={token}
              state={state}
              setState={setState}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
