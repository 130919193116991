export const ExpenseFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    ExpenseDateDesc: 3,
    ExpenseDate: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
    Amount: 7,
    AmountDesc: 8,
}


export const expenseTableSort = {
    referenceNo: {
        descend: ExpenseFilterSortEnum.ReferenceNoDesc,
        ascend: ExpenseFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: ExpenseFilterSortEnum.CreateDateDesc,
        ascend: ExpenseFilterSortEnum.CreateDateAsc
    },
    expenseDate: {
        descend: ExpenseFilterSortEnum.ExpenseDateDesc,
        ascend: ExpenseFilterSortEnum.ExpenseDate
    },
    amount: {
        descend: ExpenseFilterSortEnum.AmountDesc,
        ascend: ExpenseFilterSortEnum.Amount
    },

}