import { useState } from "react";
import { STRINGS } from "../../../../../utils/base";
import { TransactionReferenceTypeEnum } from "../../../createVoucher/enum/TransactionReferenceTypeEnum";
import DisperseModal from "./DisperseModal";
import { Button } from "antd";

export default function Disperse({
    label = "Disburse",
    id = STRINGS.DEFAULTS.guid,
    TransactionReferenceType = TransactionReferenceTypeEnum.General,
    tableClass = "",
}) {
    const [state, setState] = useState({
        isDisperseOpen: false,
    });

    const handleDisperseOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({
            ...state,
            isDisperseOpen: true
        })
    };


    return (
        <div>

            <DisperseModal
                open={state.isDisperseOpen}
                onCancel={() => setState(!state.isDisperseOpen)}
                id={id}
                referenceType={TransactionReferenceType}
            />
            <div
                className="docsPopover"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >

                <Button onClick={(e) => handleDisperseOpen(e)}
                    className={`ThemeBtn w-[87px] h-[30px]  ${tableClass ? tableClass : "!flex !justify-center"}`}>
                    {label}
                </Button>

            </div>
        </div>
    );
}