export const WarningFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    ReferenceNoDesc: 3,
    ReferenceNo: 4,
    StatusDesc: 5,
    Status: 6,
    CategoryDesc: 7,
    Category: 8,
    UpdateDateDesc: 9,
    UpdateDateAsc: 10

}

export const warningTableSort = {
    referenceNo: { descend: WarningFilterSortEnum.ReferenceNoDesc, ascend: WarningFilterSortEnum.ReferenceNo },
    createDate: { descend: WarningFilterSortEnum.CreateDateDesc, ascend: WarningFilterSortEnum.CreateDateAsc },
    status: { descend: WarningFilterSortEnum.StatusDesc, ascend: WarningFilterSortEnum.Status },
    category: { descend: WarningFilterSortEnum.CategoryDesc, ascend: WarningFilterSortEnum.Category },
}