import { useContext, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
} from "antd";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  DeploymentUnitOutlined,
  CalendarOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { getAllEmployees } from "../../../../utils/Shared/store/actions";
import SingleUpload from "../../../sharedComponents/Upload/singleUpload";
import { ScheduleReferenceTypeEnum } from "../enum/enum";
import {
  addSchedule,
  getAllSchedule,
  roomCheckAvailableServiceAction,
  updateScheduleAction,
} from "../store/action";
import { defaultUiid } from "../../../../utils/Shared/enums/enums";
import {
  STRINGS,
  jsonToFormData,
} from "../../../../utils/base";
import { responseCode as Codes } from "../../../../services/enums/responseCode";
import "../styles/style.css";
import {
  meetingDuration,
  preparationDuration,
  travelDuration,
} from "../utils";
import { getAllRoom } from "../../Room/store/actions";
import RichTextEditor from "../../../sharedComponents/RichTextEditor/RichTextEditor";
import SuggestionBox from "../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionTypeEnum } from "../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";
import "../styles/style.css";
import "../styles/calender.css";
import { ScheduleTypeEnum } from "../utils/ScheduleTypeEnum";
import { FeaturePermissionEnum } from "../../../../utils/Shared/enums/featuresEnums";
import { handleInterviewSchedule } from "../store/slice";
import { getIconBypathName } from "../../../sharedComponents/FilePreview/helper";
import FileViewerModal from "../../eLearning/view/Dashboard/Sections/Ebooks/BookDetail/FileViewerModal";

function CreateSchedule({
  scheduleDetail = {},
  referenceType,
  referenceId,
  date = "",
  selectedSlotDuration = "15",
  isInterview = false,
  isUpdate = false,
  onClose = ()=>{},
  userInternalPermission,
  userInternalPermissionEnums = null,
}) {
  console.log(scheduleDetail,"schedule detaill-->");
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule, sharedLabels } = dictionaryList[userLanguage];
  const [venue, setVenue] = useState("Venue");
  const [selectedDate, setSelectedDate] = useState();
  const [quillError, setQuillError] = useState(false);
  const [files, setFiles] = useState([]);
  const [firstTimeEmpData, setFirstTimeEmpData] = useState([]);
  const [isFirstTimeDataLoaded, setIsFirstTimeDataLoaded] = useState(false);
  const {
    sharedSlice: { employees },
  } = useSelector((state) => state);
  const userId = useSelector((state) => state.userSlice.user.id);
  const userPermissions = useSelector((state) => state.userSlice.user.permissions);

  const loading = useSelector((state) => state.scheduleSlice.loading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [membersfromSuggestionBox, setmembersfromSuggestionBox] = useState([]);

  const { rooms, loadingData } = useSelector((state) => state.roomSlice);
  const [RoomsState, setRoomState] = useState([]);
  const [isLoadingAvailibility, setisLoadingAvailibility] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [onWorkWiseCall, setonWorkWiseCall] = useState(false);
  const [RichTextEditorValue, setRichTextEditorValue] = useState("");
  const [isRoomAvailabe, setisRoomAvailabe] = useState(null);
  const [RoomId, setRoomId] = useState(STRINGS.DEFAULTS.guid);
  const [modelState, setmodelState] = useState(false);
  const [isFileViewerModal, setFileViewerModal] = useState(false);
  const [suggestionBoxType, setsuggestionBoxType] = useState(SuggestionTypeEnum.Employee)
  const effectivePermissions =
  userInternalPermission?.length > 0
    ? userInternalPermission
    : userPermissions;

const featureEnum =
  userInternalPermission?.length > 0
    ? userInternalPermissionEnums.InviteExternals
    : FeaturePermissionEnum.InviteExternals;

  useEffect(() => {
    dispatch(getAllRoom()).then((res) => {
      setRoomState(
        res.payload.data.map((room) => ({
          label: `${room.name} - Seats (${room.totalSeats})`,
          value: room.id,
        }))
      );
    });
  }, []);

  const CheckRoomAvailability = () => {
    if (isRoomAvailabe == null) {
      setisLoadingAvailibility(true);

      const startDate = moment.utc(form.getFieldValue("startDate")).format();

      const endDate = moment.utc(form.getFieldValue("startDate"))
        .add((form.getFieldValue("duration")).split(" ")[0], "minute")
        .format();

      const payload = {
        roomId: selectedRoom,
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(roomCheckAvailableServiceAction(payload)).then((response) => {
        if (response?.payload?.responseCode == Codes.Success) {
          const roomAvailability = response?.payload?.data
          setisRoomAvailabe(roomAvailability);
          if (roomAvailability) {
            setRoomId(selectedRoom)
          }

        }
        setisLoadingAvailibility(false);
      });
    }
  };

  const handleRoomChange = (value) => {
    setSelectedRoom(value);
    setisRoomAvailabe(null);
    setRoomId(STRINGS.DEFAULTS.guid)
    const SingleRoom = rooms?.find((item) => item?.id === value);
    const location = SingleRoom ? SingleRoom.location : "";
    form.setFieldsValue({ location });
  };

  const onVideoCall = () => {
    setonWorkWiseCall(!onWorkWiseCall);
  };
  const isButtonDisabled = !selectedRoom;

  const fetchEmployees = (text, pgNo) => {
    dispatch(getAllEmployees({ text, pgNo, pgSize: 20 }));
  };
  useEffect(() => {
    if (employees.length > 0 && !isFirstTimeDataLoaded) {
      setIsFirstTimeDataLoaded(true);
      setFirstTimeEmpData(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (date) {
      form.setFieldsValue({
        startDate: moment(date),
      });
    }
  }, [date]);

  useEffect(() => {
    fetchEmployees("", 0);
  }, []);

  const onFinish = (value) => {
    let objToSend = value;
    if (objToSend.startDate) {
      objToSend.endDate = moment(value.startDate)
        .add(value.duration.split(" ")[0], "minute")
        .format();
      objToSend.startDate = moment(objToSend.startDate).format();
    } else {
      objToSend.endDate = "";
    }
    
    objToSend.roomId = RoomId

    if (membersfromSuggestionBox) {

      const meberIds= membersfromSuggestionBox.map((member) => {
        return { memberId: member.id,
        };
      });
    
      if(scheduleDetail?.members)
      {
        objToSend.members =[...meberIds,...scheduleDetail?.members]
      }
      else
      {
        objToSend.members = meberIds
      }
    }
    
    let attachments=[];
    if (files.length > 0) {

      attachments = files.map((file) => {
        return {
          id: defaultUiid,
          file: file.originFileObj,
        };
      });
    
    }

    if(isInterview && scheduleDetail?.attachments?.length > 0){
      attachments = [...attachments, {id: scheduleDetail?.attachments?.[0].id, file: null}]
    }

    console.log("aaaaaaaaaaaaaattachments", attachments);

    if (venue !== "Venue") {
      objToSend.onVideoConference = true;
    }

    if (onWorkWiseCall) {
      objToSend.onVideoConference = true;
    } else objToSend.onVideoConference = false;

    objToSend.description = RichTextEditorValue;

    !isUpdate
      ? dispatch(
          addSchedule(
            jsonToFormData({
              ...objToSend,
              attachments,
              referenceType: referenceType,
              referenceId: referenceId,
              scheduleType:isInterview?ScheduleTypeEnum?.Interview:ScheduleTypeEnum.Appointment
            })
          )
        ).then((res) => {
          console.log(res,"response--->");
          if (referenceType === ScheduleReferenceTypeEnum.Lead) {
            dispatch(
              getAllSchedule({
                referenceType: referenceType,
                referenceId: referenceId,
              })
            );
          }
          isInterview && res?.payload?.data && dispatch(handleInterviewSchedule(res?.payload?.data?.referenceId))
        })
      : dispatch(
          updateScheduleAction({
            id: scheduleDetail?.id,
            subject: objToSend?.subject,
            description: objToSend?.description,
            startDate: moment.utc(objToSend?.startDate),
            endDate: moment.utc(objToSend?.endDate),
            onVideoConference: objToSend?.onVideoConference,
            location: objToSend?.location,
            travelTime: objToSend?.travelTime,
            preparationTime: objToSend?.preparationTime,
          })
        ).then((res)=>{
          res?.payload?.data && onClose()
        });
  };
  const onFinishFailed = (value) => {
    if (form.getFieldError("description")[0]) {
      setQuillError(true);
      return;
    }
    setQuillError(false);
  };

  useEffect(() => {
    if (Object.keys(scheduleDetail).length > 0) {
      if (isUpdate) {
        const startD = moment.utc(scheduleDetail.startDate).local();
        const endD = moment.utc(scheduleDetail.endDate).local();
        setRichTextEditorValue(scheduleDetail?.description);
        setonWorkWiseCall(scheduleDetail?.onVideoConference);
        form.setFieldsValue({
          ...scheduleDetail,
          startDate: startD,
          duration: `${endD.diff(startD, "minutes")} minutes`,
        });
      } else {
        const startD = moment(scheduleDetail.startDate);
        const endD = moment(scheduleDetail.endDate);
        form.setFieldsValue({
          ...scheduleDetail,
          startDate: startD,
          endDate: `${endD.diff(startD, "minutes")} minutes`,
          members: isInterview
            ? [scheduleDetail.members]
            : scheduleDetail.members
                .map((members) => members.memberId)
                .filter((member) => member !== userId),
        });
      }
    }
  }, [scheduleDetail]);

  const disabledDate = (current) => {
    // Can not select days before today and today

    return current.isBefore(moment().subtract(1, "day"));
  };

  const prevHr = moment()
    .subtract(1, "hours")
    .hours();
  const disHrs = [];
  for (let i = 0; i <= prevHr; i++) {
    disHrs.push(i);
  }

  const prevMin = moment().minutes();
  const disMins = [];
  for (let i = 0; i <= prevMin; i++) {
    disMins.push(i);
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmails = (_, value) => {
    if (Array.isArray(value)) {
      for (let email of value) {
        if (!emailRegex.test(email)) {
          return Promise.reject("Invalid email format");
        }
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    switch(referenceType){
      case ScheduleReferenceTypeEnum.Group:
        setsuggestionBoxType(SuggestionTypeEnum.GroupMember)
        break;
      case ScheduleReferenceTypeEnum.Project:
        setsuggestionBoxType(SuggestionTypeEnum.ProjectMember)
        break;
      default:
        setsuggestionBoxType(SuggestionTypeEnum.Employee)
    }
  }, [referenceType]);

  const openAttachmentModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setmodelState(true);
    setFileViewerModal(true);
  };

  return (
    <>
    <div className="createSchedule">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          description: "",
          location: "",
          scheduleType: isInterview
            ? ScheduleTypeEnum.Interview
            : ScheduleTypeEnum.Meeting,
          endDate: schedule.Minutes,
          onVideoConference: false,
          travelTime: 0,
          preparationTime: 0,
          members: [],
          duration: `${selectedSlotDuration} ${schedule.min}`,
          subject: "",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="subject"
          label={sharedLabels.subject}
          rules={[{ required: true, message: sharedLabels.Subjectisrequired }]}
        >
          <Input placeholder={sharedLabels.EnterSubject}></Input>
        </Form.Item>
        <Form.Item
          name="description"
          label={sharedLabels.Description}
        >
          <RichTextEditor
            setRichTextEditorValue={setRichTextEditorValue}
            placeholderValue={sharedLabels.writeDescription}
            editorContent={
              scheduleDetail?.description ? scheduleDetail?.description : ""
            }
          />
        </Form.Item>

        {!isUpdate && (
          <Form.Item label={sharedLabels.type} name={"scheduleType"}>
            <Radio.Group>
              {isInterview ? (
                <Radio.Button value={ScheduleTypeEnum.Interview}>
                  <CalendarOutlined />
                  {sharedLabels.Interview}
                </Radio.Button>
              ) : (
                <>
                  <Radio.Button value={ScheduleTypeEnum.Meeting}>
                    <DeploymentUnitOutlined />
                    {sharedLabels.Meeting}
                  </Radio.Button>
                  <Radio.Button value={ScheduleTypeEnum.Appointment}>
                    <CalendarOutlined />
                    {sharedLabels.Appointment}
                  </Radio.Button>
                </>
              )}
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item label={schedule.OnVideoConference}>
          <Button
            type={onWorkWiseCall && "primary"}
            onClick={onVideoCall}
            style={{
              border: !onWorkWiseCall && "1px solid darkgrey",
              color: !onWorkWiseCall && "grey",
              display: "flex",
              alignItems: "center",
            }}
          >
            <VideoCameraOutlined />
            <span>{schedule.OnWorkwiseCall}</span>
          </Button>
        </Form.Item>
        <Form.Item label={schedule.Venue} name="location">
          <Input placeholder={schedule.Enterlocation} />
        </Form.Item>
        {/* )} */}
        <div className="formInput w-50">
          <Form.Item
            className="no-wrap-form"
            label={sharedLabels.StartDateTime}
            name="startDate"
            rules={[
              {
                required: true,
                message: sharedLabels.DateTimeisrequired,
              },
            ]}
          >
            <DatePicker
              onChange={() => {
                setisRoomAvailabe(null);
                setRoomId(STRINGS.DEFAULTS.guid)
              }}
              onSelect={setSelectedDate}
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledDate}
              showTime={{
                defaultValue: moment("00:00:00", "HH:mm"),
                disabledHours: () => {
                  if (moment().date() == selectedDate?.date()) return disHrs;
                  else return [];
                },
                disabledMinutes: () => {
                  if (
                    moment().hour() == selectedDate?.hour() &&
                    moment().date() == selectedDate?.date()
                  )
                    return disMins;
                  else return [];
                },
              }}
              placeholder={sharedLabels.Selectdatetime}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                {schedule.Duration} <span className="myCustomClass">( {schedule.inminutes} )</span>
              </span>
            }
            name="duration"
            rules={[{ required: true, message: schedule.Durationisrequired }]}
          >
            <Select
              // defaultValue="15 min"
              options={meetingDuration}
              onChange={() => {
                setisRoomAvailabe(null);
                setRoomId(STRINGS.DEFAULTS.guid)
              }}
            ></Select>
          </Form.Item>
        </div>
        {/*  */}
        {!isUpdate && (
          <div
            className="room__Wrapper"
            style={{
              display: "flex",
              gap: "10px ",
            }}
          >
            <div className="formInput" style={{ flex: "80%" }}>
              <Form.Item
                label={schedule.Rooms}
              >
                <Select
                  allowClear
                  placeholder={schedule.SelectRoom}
                  options={RoomsState}
                  onChange={handleRoomChange}
                ></Select>
              </Form.Item>
            </div>
            <div
              className="formInput"
              style={{ flex: "20%", marginTop: "auto" }}
            >
              <Form.Item label={null} name="toCheck">
                <Button
                  type="primary"
                  onClick={CheckRoomAvailability}
                  disabled={isButtonDisabled}
                  loading={isLoadingAvailibility}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      (isRoomAvailabe !== null &&
                        isRoomAvailabe === false &&
                        schedule.red) ||
                      (isRoomAvailabe !== null &&
                        isRoomAvailabe === true &&
                        schedule.green),
                  }}
                >
                  {isRoomAvailabe === null
                    ? schedule.CheckRoomAvailability
                    : isRoomAvailabe
                    ? schedule.Available
                    : schedule.NotAvailable}
                </Button>
              </Form.Item>
            </div>
          </div>
        )}

        {!isInterview && !isUpdate && effectivePermissions.includes(featureEnum) &&(
          <Form.Item
            name={"externals"}
            label={sharedLabels.ExternalMembers}
            rules={[{ validator: validateEmails }]}
          >
            <Select
              mode="tags"
              dropdownClassName="hidden"
              placeholder={sharedLabels.EnterEmail}
              size="large"
            />
          </Form.Item>
        )}
        {!isUpdate && (
          <Form.Item
            label={sharedLabels.members}
          >
            <SuggestionBox
              initialData={membersfromSuggestionBox}
              suggestionType={[suggestionBoxType]}
              removeSelf={true}
              callback={(item) => {
                setmembersfromSuggestionBox(item);
              }}
              referenceId = {referenceId}
            />
          </Form.Item>
        )}
        <div className="formInput w-50">
          <Form.Item label={schedule.TravelTime} name="travelTime">
            <Select defaultValue={0} options={travelDuration}></Select>
          </Form.Item>
          <Form.Item
            className="no-wrap-form"
            label={schedule.PreparationTime}
            name="preparationTime"
          >
            <Select defaultValue={0} options={preparationDuration}></Select>
          </Form.Item>
        </div>
        {!isUpdate && (
          <Form.Item label={sharedLabels.Attachment} labelPosition="top">
          <div className="flex gap-3">

          {isInterview && scheduleDetail?.attachments[0]?.path &&<div
              className="cursor-pointer w-fit flex  "
              onClick={(e) => {
                openAttachmentModal(e);
              }}
            >
              <div className="flex flex-col justify-center">
              <img
                src={getIconBypathName(scheduleDetail?.attachments[0].path)}
                alt="Resume"
                height="100px"
                width="75px"
              />
              <span className="">Resume</span>

              </div>
              
            </div>}
            <SingleUpload
              handleImageUpload={(file) => {
                setFiles(file);
              }}
              multiple={true}
              position={"left"}
              // url={scheduleDetail?.attachments?.[0] ? scheduleDetail?.attachments[0].path : ""}

            />
          </div>
            
          </Form.Item>
        )}

              
        <Form.Item>
          <Button
            type="primary"
            size="large"
            className="ThemeBtn"
            block
            htmlType="submit"
            loading={loading}
          >
            {isUpdate ? schedule.UpdateSchedule : schedule.CreateSchedule}
          </Button>
        </Form.Item>
      </Form>
    </div>
    
    
    {isFileViewerModal && (
          <FileViewerModal
            isOpen={isFileViewerModal}
            onCancel={() => setFileViewerModal(false)}
            viewItem={scheduleDetail?.attachments[0].path}
          />
        )}
    </>
  );
}

export default CreateSchedule;
