import React, { useContext, useEffect, useState } from 'react'
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import { dictionaryList } from '../../../../../utils/localization/languages';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCountries } from '../../../../../utils/Shared/store/actions';
import { addUserBankInfoAction, getAllBankDetailByUser } from '../../../bankDetails/store/actions';
import { resetBankDetails } from '../../store/slice';
import { addBankDetailsAbout } from '../../../profile/store/slice';
import { Button, Checkbox, Divider, Input, Select, Table, Form, Row, Col } from 'antd';
import { PlusOutlined, EditOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";
import CitySelectHandler from '../employeeCityHandle/citySelectHandler';
const { Option } = Select;


const PreBankForm = ({ mode, id, ShowTable = true, hanldeClose = () => { }, getBankFormData = () => { } }) => {


    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, Direction, Employees } = dictionaryList[userLanguage];
    const isEdit = mode === "edit";
    const params = useParams();
    const [form] = Form.useForm();
    const [bankDetails, setBankDetails] = useState([]);
    const [countries, setCountries] = useState([]);
    const [handleUpdateButton, setHandleUpdateButton] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [initialSelectedCity, setInitialSelectedCity] = useState(null);
    const dispatch = useDispatch();
    const { countries: countriesSlice } = useSelector(
        (state) => state.sharedSlice
    );
    const { userBankDetails, bankCities, loadingData } = useSelector(
        (state) => state.bankInfoSlice
    );
    const initialState = {
        accountNumber: "",
        accountTitle: "",
        bankBranchCode: "",
        bankName: "",
        cityId: "",
        city: "",
        countryId: null,
        ibanNumber: "",
        sortCode: "",
        isDefault: false,
    };
    const [initialValues, setInitialValues] = useState(initialState);
    const [newUserId, setNewUserId] = useState("");
    const {
        success,
    } = useSelector((state) => state.employeeSlice);
    Object.defineProperty(form, "values", {
        value: function () {
            return bankDetails.map((item) => {
                let { city, country, ...values } = item
                return {
                    ...values,
                    countryId: country,
                };
            });
        },
        writable: true,
        enumerable: true,
        configurable: true,
    });

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues, form]);

    useEffect(() => {
        if (isEdit) {
            if (!countriesSlice.length) dispatch(getCountries());
            dispatch(getAllBankDetailByUser(id));
        }

        return () => {
            dispatch(resetBankDetails());
        };
    }, []);

    useEffect(() => {
        if (success) setBankDetails([]);
    }, [success]);


    useEffect(() => {
        if (isEdit) setBankDetails(userBankDetails);
    }, [userBankDetails]);

    useEffect(() => {
        setCountries(countriesSlice);
    }, [countriesSlice]);

    const handleAddMore = async () => {
        form.submit();
        try {
            const isValidation = await form.validateFields();
            if (isValidation) {
                if (isEdit) {
                    const payloadObject = {
                        ...isValidation,
                        userId: params.id,
                        isDefault: isValidation.isDefault,
                    };
                    dispatch(addUserBankInfoAction(payloadObject)).then(({ payload }) => {
                        hanldeClose();
                        dispatch(addBankDetailsAbout(payload));
                    });
                } else {
                    setBankDetails((preValues) => [
                        ...preValues,
                        { ...isValidation, country: isValidation.countryId, city: form.getFieldValue('city') },
                    ]);
                }
            }
            form.resetFields();
            setInitialValues(initialState);
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleRowChange = (rowIndex) => {
        let selectedRecord = bankDetails[rowIndex];
        setInitialValues(selectedRecord);
        setNewUserId(selectedRecord.id);
        setHandleUpdateButton(true);
        setSelectedCountry(selectedRecord.countryId);
        setInitialSelectedCity({
            id: selectedRecord.cityId,
            name: selectedRecord.city,
            countryId: selectedRecord.countryId,
            country: selectedRecord.country,
        });
    };
    useEffect(() => {

        getBankFormData(bankDetails)
    }, [bankDetails])


    const columns = [
        {
            title: sharedLabels.BankName,
            dataIndex: "bankName",
            ellipsis: true,
            key: "bankName",
        },
        {
            title: sharedLabels.AccountName,
            dataIndex: "accountTitle",
            ellipsis: true,
            key: "accountTitle",
        },
        {
            title: sharedLabels.BranchCode,
            dataIndex: "bankBranchCode",
            ellipsis: true,
            key: "bankBranchCode",
        },
        {
            title: sharedLabels.AccountNumber,
            dataIndex: "accountNumber",
            ellipsis: true,
            key: "accountNumber",
        },
        {
            title: sharedLabels.IBAN,
            dataIndex: "ibanNumber",
            ellipsis: true,
            key: "ibanNumber",
        },
        {
            title: sharedLabels.SortCode,
            dataIndex: "sortCode",
            ellipsis: true,
            key: "sortCode",
        },
        {
            title: sharedLabels.country,
            dataIndex: "country",
            ellipsis: true,
            key: "country",
            render: (value) => {
                return countries?.find(item => item.id === value)?.name || '';
            }
        },
        {
            title: sharedLabels.City,
            dataIndex: "city",
            ellipsis: true,
            key: "city",
        },
        {
            title: sharedLabels.IsDefault,
            dataIndex: "isDefault",
            ellipsis: true,
            render: (_, row) => <Checkbox disabled={true} checked={row.isDefault} />,
        },
        {
            title: sharedLabels.action,
            render: (value, record) => {
                return (
                    <a
                        href=" "
                        onClick={(e) => {
                            let rowIndex = bankDetails.findIndex((obj) => obj.id === record.id);
                            e.preventDefault();
                            if (isEdit) {
                                handleRowChange(rowIndex);
                                setNewUserId(bankDetails[rowIndex].id);
                            } else {
                                const filterArray = bankDetails.filter((value, i) => {
                                    if (rowIndex !== i) return value;
                                });
                                setBankDetails(filterArray);
                            }
                        }}
                    >
                        {isEdit ? (
                            <EditFilled style={{ color: "#1b5669" }} />
                        ) : (
                            <DeleteFilled style={{ color: "#1b5669" }} />
                        )}
                    </a>
                );
            },
        },
    ];

    let classes = 'employeeForm bankDetails ';
    classes += Direction === 'ltr' ? 'ltr' : 'rtl';
    return (
        <div className={classes}>
            <Divider orientation="left" orientationMargin="0">
                {sharedLabels.BankInfo} &nbsp; &nbsp;
                {!isEdit && (
                    <span className="non-mandatory-text">
                        ({sharedLabels.NonMandatory})
                    </span>
                )}
            </Divider>
            <Form
                name="bankDetails"
                form={form}
                layout={"vertical"}
                initialValues={initialValues}
                onFinish={() => {
                    console.log("bankDetails");
                }}
            >
                <Row gutter={[16]}>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: sharedLabels.requiredMessageBankName,
                                },
                            ]}
                            name="bankName"
                            label={sharedLabels.BankName}
                        >
                            <Input placeholder={sharedLabels.EnterBankName}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="accountTitle"
                            label={sharedLabels.AccountTitle}
                            rules={[
                                {
                                    required: true,
                                    message: sharedLabels.requiredMessageAccount,
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && value.length > 20) {
                                            return Promise.reject(sharedLabels.validAccountTitle);
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input placeholder={sharedLabels.EnterAccountTitle}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: sharedLabels.requiredMessageBranchCode,
                                },
                            ]}
                            name="bankBranchCode"
                            label={sharedLabels.BranchCode}
                        >
                            <Input
                                type="number"
                                min={0}
                                placeholder={sharedLabels.EnterBranchCode}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: sharedLabels.requiredAccountNumber,
                                },
                            ]}
                            name="accountNumber"
                            label={sharedLabels.AccountNumber}
                        >
                            <Input
                                type="number"
                                min={0}
                                placeholder={sharedLabels.EnterAccountNumber}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: sharedLabels.requiredIBAN,
                                },
                            ]}
                            name="ibanNumber"
                            label={sharedLabels.IBAN}
                        >
                            <Input placeholder={sharedLabels.EnterIBAN}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: sharedLabels.requiredSortCode,
                                },
                            ]}
                            name="sortCode"
                            label={sharedLabels.SortCode}
                        >
                            <Input
                                type="number"
                                min={0}
                                placeholder={sharedLabels.EnterSortCode}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>

                        <Form.Item
                            name="countryId"
                            label={sharedLabels.country}
                            rules={[{ required: true, message: sharedLabels.requiredCountry }]}
                        >
                            <Select
                                showSearch={true}
                                placeholder={sharedLabels.SelectCountry}
                                size="large"
                                getPopupContainer={(trigger) => trigger.parentNode}
                                optionFilterProp="children"
                                onChange={() => {
                                    setSelectedCountry(form.getFieldValue("countryId"));
                                    form.setFieldValue("cityId", "");
                                }}
                            >
                                {countries.map((item) => (
                                    <Option key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>

                        <Form.Item
                            name="cityId"
                            label={sharedLabels.City}
                            rules={[{ required: true, message: sharedLabels.requiredCity }]}
                        >
                            <CitySelectHandler selectedCountry={selectedCountry} form={form} selectedCityObj={initialSelectedCity} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>

                        <Form.Item
                            name="isDefault"
                            valuePropName="checked"
                            label={sharedLabels.IsDefault}
                            style={{ display: 'flex', alignItems: 'center' }}

                        >
                            <Checkbox style={{ fontWeight: 'bold', marginTop: 0 }} />

                        </Form.Item>
                    </Col>

                </Row>
            </Form>

            <div className={isEdit ? "editButtons" : "buttons"}>
                {handleUpdateButton ? (
                    <Button
                        className="mb-2 btn ThemeBtn"
                        icon={<EditOutlined />}
                    // onClick={handleUpdate}
                    >
                        {Employees.UpdateBank}
                    </Button>
                ) : (
                    <Button
                        type="dashed"
                        className="btn addMore"
                        icon={<PlusOutlined />}
                        onClick={handleAddMore}
                    >
                        {Employees.AddBank}
                    </Button>
                )}
            </div>
            {bankDetails.length > 0 && ShowTable && (
                <Table
                    columns={columns}
                    dataSource={bankDetails}
                />
            )}

        </div>
    );
}

export default PreBankForm
