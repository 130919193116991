import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums/";

import {
  addBonus,
  addWarning,
  cancelBonus,
  getAllBonus,
  GetBonusById,
  GetPromotionById,
  getAllEmployeeSalary,
  // getAllBonusPaging,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";

const initialState = {
  bonuses: [],
  loadingData: false,
  loader: true,
  bonusDetail: {},
  drawerOpen: false,
  cancelBonuss: {},
  createLoader: false,
  salaryList: [],
  success: false,
  salaryloader: false,
  bonusApprovers: [],
  pagingBonus: {},
  //neww
  data: null,
  newItem: null,
  totalRecords: [],
  recordSize: 0,
  newCountFilterButton: false
};

const bonusSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    //new
    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    //add approval remarks
    handleBonusApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;

        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              console.log(finalApprovers, "Final Approvers = ");
              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: approverStatus.length > 1 ? 1 : approverStatus[0],
                approverStatus:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) { }
    },
    readCountforBonus: (state, { payload }) => {

      const id = payload
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === id)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id
        }
      }
      catch (e) {
        console.log("===error===", e)
      }
    },
    //handle add approvers
    handleAddBonusApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [...referenceItem?.approvers];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    ///
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },

    cancelBonusSuccess: (state, { payload }) => {
      let bonusList = [...state.bonuses];
      let index = bonusList.findIndex((item) => item.id === payload.bonusId);
      let bonus = bonusList.filter((item) => item.id === payload.bonusId)[0];

      bonusList[index] = {
        ...bonus,
        status: ApprovalStatus.Cancelled,
      };

      state.bonuses = bonusList;
      state.bonusDetail = {
        ...bonus,
        status: ApprovalStatus.Cancelled,
      };
    },
    updateApprover: (state, action) => {
      const { stateapprovalDetail, index } = action.payload;
      if (stateapprovalDetail?.length > 0) {
        if (
          state.bonusApprovers[index][0]?.referenceId ===
          stateapprovalDetail[0].referenceId
        ) {
          state.bonusApprovers[index] = stateapprovalDetail;
        }
      }
    },
    handleNotificationNewItem: (state, { payload }) => {

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === payload.featureId)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch (e) {
        console.log("===error===", e)
      }
    },

    handleBonusReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBonus.fulfilled, (state, action) => {
      const { segmentType, identifier, filter } = action?.meta?.arg;
      const data = action.payload ?? [];
      if (segmentType === SegmentTypeEnum.Grid) {
        state.data = {
          ...state.data,
          [identifier]: [...(state.data?.[identifier] || []), ...data],
        };
        state.recordSize = data.length;
      } else {
        state.data = {
          ...state.data,
          [identifier]: data.data,
        };
        state.totalRecords = action?.payload?.totalRecords;
      }
    });

    builder.addCase(GetBonusById.fulfilled, (state, action) => {
      console.log(action.payload.data, "dataa idd");
      const { identifier } = action?.meta?.arg;
      const data = action.payload.data ?? [];

      state.data = {
        ...state.data,
        [identifier]: [data],
      };
    });

    builder.addCase(getAllEmployeeSalary.fulfilled, (state, { payload }) => {
      state.salaryList = payload.data;
      state.salaryloader = false;
      state.success = true;
    });

    // builder.addCase(cancelBonus.fulfilled, (state, action) => {
    //   state.cancelBonuss = action.payload.data;
    // });

    builder
      .addCase(addBonus.fulfilled, (state, { payload }) => {
        state.newItem = payload.data.data;
      })
      .addMatcher(isPending(...[getAllBonus]), (state) => {
        state.loader = true;
      })

      .addMatcher(isPending(...[GetBonusById]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(isPending(...[addBonus]), (state) => {
        state.createLoader = true;
      })
      .addMatcher(isRejected(...[getAllBonus]), (state) => {
        state.loader = false;
      })
      .addMatcher(isRejected(...[addBonus]), (state) => {
        state.createLoader = false;
      });
  },
});

export const {
  handleOpenComposer,
  cancelBonusSuccess,
  updateApprover,
  //new
  handleResetState,
  removeIdentifier,
  handleNewItem,
  handleBonusApprovalRemark,
  handleAddBonusApprover,
  handleNotificationNewItem,
  readCountforBonus,
  handleBonusReminder
} = bonusSlice.actions;
export default bonusSlice.reducer;
