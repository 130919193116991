import React, { useEffect, useRef, useState } from "react";
import SuggestionInput from "./SuggestionInput";
import useDebounce from "../../../../utils/Shared/helper/use-debounce";
import CustomList from "./CustomList";
import { STRINGS } from "../../../../utils/base";
import { SuggestionBoxType } from "../utils/SuggestionBoxType";
import { SuggestionTypeEnum } from "../utils/SuggestionTypeEnum";
import CustomTag from "./CustomTag";
import { useDispatch } from "react-redux";

function SuggestionBox(props) {
  const {
    initialData = [],
    suggestionType = [SuggestionTypeEnum.Employee],
    callback = () => false,
    placeholder = "Select Member",
    isMultiSelect = true,
    nonRemoveable = [],
    removeSelf = false,
    referenceId = STRINGS.DEFAULTS.guid,
    componentType = 1,
    notRequiredMember = [],
    notRequiredMemberiDs = [],
    setManager = {
      isVisible: false,
      users: [],
    },
  } = props;
  const [selected, setSelected] = useState(-1);
  const [isList, setisList] = useState(false);
  const [value, setValue] = useState("");
  const debouncedSearch = useDebounce(value, 1000);
  const [tagContainerHeight, setTagContainerHeight] = useState(0);
  const tagContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [SuggestionData, setSuggestionData] = useState([]);
  const [isFocus , setisFocus] = useState(false)
  const dispatch = useDispatch()

  const [backtoInputField , setBacktoInputField] = useState(false)
  const [lengthOfcurrentSearch , setLengthOfCurrentSerach] = useState(1)
  const [ArrowDownFromInputField , setArrowDownFromInputFiled] = useState(false)
  const [TopPosition , settopPostion] = useState(0)


  const nonRemoveableData = nonRemoveable?.filter(item => !initialData?.some(dataItem => dataItem?.id === item?.id));



  const extractedNonRemoveAbleIds =
    nonRemoveable?.map((item) => item?.id) || [];
  const extractedNotRequiredMemberIds =
    notRequiredMember?.map((item) => item?.id) || [];
  const extractedIds = notRequiredMemberiDs || [];
  const extractedIdsFromSuggestionData =
    initialData?.map((item) => item?.id) || [];

  const nonRemoveAbleids = [
    ...extractedNonRemoveAbleIds,
    ...extractedNotRequiredMemberIds,
    ...extractedIds,
    ...extractedIdsFromSuggestionData,
  ];



  const handleTagContainerClick = () => {
    isFocus && inputRef.current.focus(); // Focus the input field
  };

  const removeTags = (id) => {
    
    const tagsCopy = [...initialData];
    const updatedTags = tagsCopy.filter((tag, index) => tag.id !== id);
    const deletedItem =  tagsCopy.filter((tag, index) => tag.id === id);
    callback(updatedTags , deletedItem[0] ,"remove")
  };

  const handleTags = (event) => {
    if (
      event.key === "Backspace" &&
      initialData.length &&
      event.target.value == 0
    ) {
      const tagsCopy = [...initialData];
      const popedItem = tagsCopy.pop();
      event.preventDefault();
      callback(tagsCopy,popedItem , "remove");
    }

    if (event.keyCode === 40) {
      event.preventDefault();
      lengthOfcurrentSearch > 0 && setArrowDownFromInputFiled(true)
      setisList(true);
    }
  };
  const handleChange = (event) => {
    setisList(true);
    setValue(event.target.value);
  };

  useEffect(() => {
    backtoInputField && handleTagContainerClick();
    setBacktoInputField(false)
    setisFocus(false)
  }, [backtoInputField]);

  useEffect(() => {
    if (tagContainerRef.current) {
      const newHeight = tagContainerRef.current.offsetHeight;

      if (newHeight == 41 || newHeight == 43) {
       settopPostion(-6)
      } else {
        const differenceBetweenHeight = newHeight - 45;
        settopPostion(differenceBetweenHeight)
      }
    }
  }, [initialData, isList]);

  return (
    <>
      <div
         className="tags"
         ref={tagContainerRef}
         onClick={handleTagContainerClick}
      >
        {nonRemoveableData &&
          nonRemoveableData?.map((tag, index) => (
            <CustomTag selectedUser={tag} isRemoveAble={false} />
        ))}

        {initialData &&
          initialData?.map((tag, index) => (
            <CustomTag selectedUser={tag} removeTags={removeTags} />
          ))}

        <input
            ref={inputRef}
            style={{
              paddingLeft: "5px",
              height: "39px",
              width: "263px",
              outline: "none",
            }}
            type="text"
            onKeyDown={(event) => handleTags(event)}
            onChange={handleChange}
            placeholder={placeholder}
            // onClick={()=>{setisList(!isList)}}
            value={value}
        />
      </div>
      {/* <SuggestionInput propsForSuggestionInput={propsForSuggestionInput} /> */}
      {isList && 
                <CustomList
                  SuggestionData={SuggestionData}
                  setSuggestionData={setSuggestionData}
                  debouncedSearch={debouncedSearch}
                  setValue={setValue}
                  selected={selected}
                  setSelected={setSelected}
                  isList={isList}
                  setisList={setisList}
                  tagContainerHeight={tagContainerHeight}
                  setTagContainerHeight={setTagContainerHeight}
                  tagContainerRef={tagContainerRef}
                  isMultiSelect={isMultiSelect}
                  removeSelf={removeSelf}
                  nonRemoveAbleids={nonRemoveAbleids}
                  componentType={componentType}
                  suggestionType={suggestionType}
                  callback={callback}
                  isFocus={isFocus}
                  setisFocus={setisFocus}
                  nonRemoveableData={nonRemoveableData}
                  initialData={initialData}
                  TopPosition={TopPosition}
                  ArrowDownFromInputField={ArrowDownFromInputField}
                  setArrowDownFromInputFiled={setArrowDownFromInputFiled}
                  setLengthOfCurrentSerach={setLengthOfCurrentSerach}
                  setBacktoInputField={setBacktoInputField}
                  referenceId={referenceId}

                />
      }
    </>
  );
}

export default React.memo(SuggestionBox);
