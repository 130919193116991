import { useContext, useEffect, useState } from "react";
import { Skeleton, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { removeIdentifier, readCountforSalary } from "../../../../store/slice";
import {
  ItemContent,
  ItemHeader,
  SingleItem,
} from "../../../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "../../../UI/statusTag";
import { ApprovalsModule } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../../../sharedComponents/AppComponents/Approvals/view";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { getEmployeeSalaryDetail } from "../../../../store/actions";
import NestedTable from "../../../UI/Table/NestedTable";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { NOTIFICATION_ENUMS } from "../../../../../notifiation/utils/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import Spinner from "../../../../../../sharedComponents/spinner/spinner";
import { getCurrency } from "../../../../../../../utils/Shared/store/actions";
import { BiWorld } from "react-icons/bi";
import ExpandableDescription from "../../../../../../sharedComponents/expandableDescription/expandableDescription"
function SalaryDetail({ id, index }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  const { currencies } = useSelector((state) => state.sharedSlice);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    identifier: moment().unix(),
    salaryDetail: {
      creator: {},
      details: [],
      description: "",
      status: 1,
      referenceNo: "",
      createDate: "",
      salaryMembers: [],
      isLoading: true,
      notificationCount: 0,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
    employee: {
      isOpen: false,
    },
  });

  const { data } = useSelector((state) => state.salarySlice);
  let salaryDetail = data !== null ? data[state.identifier] : [];
  salaryDetail = salaryDetail && salaryDetail?.length > 0 && salaryDetail[0];

  const [isExpanded, setIsExpanded] = useState(false);

  const getCurrencies = () => {
    if (currencies === null) {
      setState({
        ...state,
        salaryDetail: {
          ...state.salaryDetail,
          isLoading: true,
        },
      });
      dispatch(getCurrency()).then((res) => {
        setState({
          ...state,
          salaryDetail: {
            ...state.salaryDetail,
            isLoading: false,
          },
        });
      });
    }
  };

  useEffect(() => {
    id &&
      dispatch(
        getEmployeeSalaryDetail({ id: id, identifier: state.identifier })
      );
  }, []);

  useEffect(() => {
    salaryDetail &&
      setState({
        ...state,
        salaryDetail: {
          ...state.salaryDetail,
          ...salaryDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: salaryDetail?.approvers,
        },
      });
  }, [salaryDetail]);

  useEffect(() => {
    return () => {
      getCurrencies();
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const {
    creator,
    details,
    description,
    status,
    referenceNo,
    createDate,
    salaryMembers,
    notificationCount,
  } = state.salaryDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.EmployeeSalary,
          featureId: id,
        },
      };

      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforSalary(id));
      });
    }
  }, [notificationCount]);

  if (state.salaryDetail.isLoading) return <Spinner />;
  return (
    <>
      <SingleItem>
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              status={creator?.userActiveStatus}
              profileId={creator?.id}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator?.designation : ""}
                  time={moment
                    .utc(createDate)
                    .local()
                    .fromNow()}
                  icon={<BiWorld />}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
          </div>
        </ItemHeader>
        <ItemContent className="flex">
          <div className="description">
            <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />

          </div>
        </ItemContent>

        <NestedTable data={salaryMembers} />

        <RemarksApproval
          module={ApprovalsModule.SalaryApproval}
          status={status}
          reference={salaryDetail?.id}
          data={state.approvers.data && state.approvers.data}
          title={sharedLabels.Approvers}
          notRequiredMemberId={state.salaryDetail.salaryMembers?.map(
            (item) => item.userId
          )}
          itemCreator={creator}
        />
      </SingleItem>
    </>
  );
}
export default SalaryDetail;
