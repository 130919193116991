import en from "./en";
import urdu from "./urdu";
import arabic from "./arabic";
import hindi from "./hindi";
import turkish from "./turkish";
import czech from "./czech";
import russian from "./russian";
import french from "./french";
import spanish from "./spanish";
import italian from "./iitalian";


export const dictionaryList = { en, urdu, arabic, hindi, turkish, czech, russian, french, spanish, italian };

export const languageOptions = {
  en: "English",
  urdu: "Urdu",
  arabic: "Arabic",
  hindi: "Hindi",
  turkish: "Turkish",
  czech: "Czech",
  russian: "Russian",
  french: "French",
  spanish: "Spanish",
  italian: "italian",
};
