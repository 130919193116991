const hindi = {
  employeesDictionary: {
    placeholders: {
      organization: "Organization",
      department: "Department",
      subsidiary: "Subsidiary",
      subsidiaryOffice: "Subsidiary Office",
      payroll: "Payroll ",
      fName: "प्रथम नाम दर्ज करें",
      lName: "अंतिम नाम दर्ज करो",
      fatherName: "पिता का नाम दर्ज करें",
      email: "ईमेल दर्ज करें",
      pEmail: "व्यक्तिगत ईमेल दर्ज करें",
      phNo: "फोन नंबर दर्ज",
      rAddress: "आवासीय पता दर्ज करें",
      pAddress: "स्थायी पता दर्ज करें",
      cnicNo: "सीएनआईसी नंबर दर्ज करें",
      searchToSelect: "चुनने के लिए खोजें",
      probPeriod: "परिवीक्षा अवधि दर्ज करें",
      noticePeriod: "सूचना अवधि दर्ज करें",
      selectDate: "तारीख़ चुनें",
      selectGender: "लिंग चुनें",
      selectMarital: "वैवाहिक स्थिति का चयन करें",
      selectShift: "शिफ्ट का चयन करें",
      empNo: "कर्मचारी संख्या दर्ज करें",
      empType: "रोजगार प्रकार चुनें",
      selectUserType: "उपयोगकर्ता प्रकार चुनें",
      selectAccessRole: "एक्सेस रोल चुनें",
      degree: "डिग्री दर्ज करें",
      institute: "संस्थान का नाम दर्ज करें",
      desc: "विवरण दर्ज करें",
      tMarks: "कुल अंक दर्ज करें",
      oMarks: "प्राप्त अंक दर्ज करें",
      sDate: "आरंभ करने की तिथि",
      selectDate: "तारीख़ चुनें",
      eDate: "अंतिम तिथि",
      position: "स्थिति दर्ज करें",
      name: "नाम दर्ज",
      address: "पता लिखिए",
      number: "नंबर डालें",
      selectRelation: "संबंध चुनें",
      bankName: "बैंक का नाम दर्ज करें",
      accTitle: "खाता शीर्षक दर्ज करें",
      branchCode: "शाखा कोड दर्ज करें",
      accNo: "खाता संख्या दर्ज करें",
      iban: "IBAN नंबर दर्ज करें",
      sortCode: "क्रमबद्ध कोड दर्ज करें",
    },
    EmployeeForm: {
      organization: "Organization",
      department: "Department",
      subsidiary: "Subsidiary",
      subsidiaryOffice: "Subsidiary Office",
      payroll: "Payroll ",
      UpdateBasicInfo: "Update BasicInfo",
      AddImage: "छवि जोड़ें",
      FirstName: "पहला नाम",
      LastName: "उपनाम",
      FatherName: "पिता का नाम",
      Email: "ईमेल",
      PersonalEmail: "व्यक्तिगत ईमेल",
      CNICNumber: " सीएनआईसी नंबर",
      rAddress: "घर का पता",
      pAddress: "स्थायी पता",
      PhoneNumber: "फ़ोन नंबर",
      Designation: "पद",
      Manager: "प्रबंधक",
      Grades: "ग्रेड",
      Department: "विभाग",
      Country: "देश",
      City: "शहर",
      Languages: "बोली",
      ProbationPeriod: "परिवीक्षा काल",
      NoticePeriod: "नोटिस की अवधि",
      DateOfBirth: " जन्म की तारीख",
      DateOfJoining: " शामिल होने की तिथि",
      Gender: "लिंग",
      MaritalStatus: "वैवाहिक स्थिति",
      OfficeShift: "कार्यालय शिफ्ट",
      EmploymentType: "रोजगार के प्रकार",
      UserType: "उपयोगकर्ता का प्रकार",
      AccessRole: "एक्सेस रोल",
      EmpNo: "कर्मचारी संख्या",
    },
    EmergencyForm: {
      UpdateEmergency: "Update Emergency",
      EmergencyInfo: "आपातकालीन जानकारी",
      Designation: "पद",
      Address: "पता",
      Number: "संख्या",
      Relation: "रिश्ता",
      Name: "नाम",
      AddMoreEmergency: "अधिक आपातकाल जोड़ें",
      AddEmergency: "अधिक आपातकाल जोड़ें",
    },
    EducationForm: {
      BasicInfo: "Basic Info",
      AddEducation: "Add Education",
      UpdateEducation: "Update Education",
      EducationInfo: "Education Info",
      Degree: "डिग्री",
      Institute: "संस्था",
      StartEndDate: "प्रारंभ / समाप्ति तिथि",
      StartDate: "आरंभ करने की तिथि",
      Description: "विवरण",
      TotalMarks: "कुल मार्क",
      ObtainedMarks: "प्राप्त अंक",
      Present: "वर्तमान",
      Attachments: "संलग्नक",
      AddEducation: "अधिक शिक्षा जोड़ें",
      Upload: "डालना",
      City: "शहर",
    },
    WorkExperienceForm: {
      organization: "Organization",
      WorkExperienceInfo: "कार्य अनुभव जानकारी",
      Employer: "नियोक्ता",
      Position: "स्थान",
      Designation: "पद",
      Department: "विभाग",
      StartEndDate: "प्रारंभ / समाप्ति तिथि",
      StartDate: "आरंभ करने की तिथि",
      Present: "वर्तमान",
      AddMoreExperience: "अधिक अनुभव जोड़ें",
      AddExperience: "अधिक अनुभव",
      EmploymentType: "रोजगार के प्रकार",
      City: "शहर",
      UpdateExperience: "Update Experience",
    },
    UserForm: {
      UserRights: "प्रयोगकर्ता के अधिकार",
      UserType: "उपयोगकर्ता का प्रकार",
      UserRole: "उपयोगकर्ता भूमिका",
    },
    BankForm: {
      updateBank: "Update Bank",
      BankInfo: "बैंक जानकारी",
      BankName: "बैंक का नाम",
      AccountTitle: "खाते का शीर्षक",
      BranchCode: "शाखा क्र्मांक",
      AccountNumber: "खाता संख्या",
      IBAN: "इबानी",
      SortCode: "क्रमबद्ध कोड",
      City: "शहर",
      Country: "देश",
      AddMoreBank: "अधिक बैंक जोड़ें",
      AddBank: "अधिक बैंक जोड़ें",
    },
    EmployeeList: {
      number: "संख्या",
      email: "ईमेल",
      designation: "पद",
    },
    AddEmployee: "Add Employee",
  },

  /*** Define language write side ***/
  Direction: "ltr",
};
export default hindi;
