import React from 'react'
import { useDispatch } from 'react-redux'
import { AddApprovalReminder } from '../action/action'
import { useSelector } from 'react-redux'
import './style.css'
import { handleReminderApprovalList } from '../../../../features/approval/store/slice'

const SendReminder = ({ approvalId, module, reference}) => {
    const { ReminderLoader, checkLoadingButtonId } = useSelector(({ ApproverSlice }) => ApproverSlice)
    const dispatch = useDispatch()
    const SendReminder = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const requestObj = { approvalId, module }
        dispatch(AddApprovalReminder({ requestObj, referenceId: reference })).then(() => {
            dispatch(handleReminderApprovalList(requestObj))
        })
    }
    return (
        <button disabled={ReminderLoader} onClick={(e) => SendReminder(e)} className="text-[9px] bg-green-600 h-[27px] mt-[1px] hover:bg-green-800 text-white py-[3px] px-[5px] rounded shadow-md">
            {ReminderLoader && checkLoadingButtonId === approvalId ? 'Sending...' : 'Send Reminder'}    </button>)
}

export default SendReminder