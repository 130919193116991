import { Button, Divider, InputNumber, message } from "antd";
import "../../style/offerDetail.css";
import AttachmentsCarrousel from "../../../travel/view/UI/AttachmentsCarrousel/AttachmentsCarrousel";
import { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { AuctionOfferStatusEnum, AuctionTypeEnum } from "../../utils/enums";
import TimerCountDown from "../../../../sharedComponents/timerCountDown";
import { RiAuctionFill } from "react-icons/ri";
import {
  addAuctionOfferAction,
  getAuctionBuyNowAction,
} from "../../store/action";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addAuctionSubmitBidAction,
  getAuctionDetailForMarketplaceAction,
} from "../../../marketPlace/store/action";
import auctionDefaultIcon from "../../../../../content/auction/navbar/AuctionIcon1.svg";
import Spinner from "../../../../sharedComponents/spinner/spinner";
import { STRINGS, SvgSpinner } from "../../../../../utils/base";
import { useNavigate } from "react-router-dom";
import AuctionBidExternalUserInfo from "./auctionBidExternalUserInfo";
import AuctionBiddingList from "./auctionBiddingList";
import { InitializeSocket } from "../../../../../../src/utils/InitSocket";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";

export default function AuctionDetailExteral({ id, isExternal = false }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, auction } = dictionaryList[userLanguage];
  const { user } = useSelector((state) => state.userSlice);

  const handleConnection = (auctionId) => {
    let socketInstance = InitializeSocket.getInstance();
    socketInstance.newAuctionAction(auctionId);
  };

  useEffect(() => {
    if (id) {
      const timer = setTimeout(() => {
        handleConnection(id);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [id]);

  const [state, setState] = useState({
    identifier: moment().unix(),
    auctionOfferDetailData: {
      businessName: "",
      currentBid: 0,
      description: "",
      email: "",
      endDate: "",
      logo: "",
      minIncreaseBid: 0,
      name: "",
      referenceNo: "",
      attachments: [],
      isLoading: true,
    },
    isOfferSubmit: false,
  });

  const [isOpenExternalModal, setIsOpenExternalModal] = useState(false);
  const [isOfferSubmitLoader, setisOfferSubmitLoader] = useState(false);
  const [isBuyNowState, setIsBuyNowState] = useState(false);
  const [buyNowState, setBuyNowState] = useState({
    isBuyNow: false,
    id: STRINGS.DEFAULTS.guid,
  });
  const [offerValue, setOfferValue] = useState(0);
  const { marketPlaceAuctionDetail } = useSelector(
    (state) => state.marketPlaceSlice
  );
  let auctionDetail =
    marketPlaceAuctionDetail !== null
      ? marketPlaceAuctionDetail[state.identifier]
      : [];
  auctionDetail =
    auctionDetail && Object.keys(auctionDetail).length > 0 && auctionDetail;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    id &&
      dispatch(
        getAuctionDetailForMarketplaceAction({
          id: id,
          identifier: state.identifier,
        })
      );
  }, [id]);

  useEffect(() => {
    auctionDetail =
      marketPlaceAuctionDetail !== null
        ? marketPlaceAuctionDetail[state.identifier]
        : [];

    auctionDetail =
      auctionDetail && Object.keys(auctionDetail).length > 0 && auctionDetail;
  }, [marketPlaceAuctionDetail]);

  useEffect(() => {
    auctionDetail &&
      setState({
        ...state,
        isOfferSubmit: false,
        auctionOfferDetailData: {
          ...state.auctionOfferDetailData,
          ...auctionDetail,
          isLoading: false,
        },
      });

    auctionDetail && setOfferValue(auctionDetail.currentBid);
  }, [auctionDetail]);

  const {
    businessName,
    currentBid,
    description,
    email,
    endDate,
    logo,
    minIncreaseBid,
    name,
    referenceNo,
    attachments,
    auctionOffers,
  } = state.auctionOfferDetailData;

  const handleNewAuctionOffer = (offer, member) => {
    let newBidUser = {
      createDate: moment(),
      offer: offer,
      status: AuctionOfferStatusEnum.BidInProcess,
      member: member,
    };

    // Create a copy of the existing auctionOffers array
    let updatedAuctionOffers = [...state.auctionOfferDetailData.auctionOffers];

    // Check if there's at least one bid already and update its status
    if (updatedAuctionOffers.length > 0) {
      updatedAuctionOffers[0] = {
        ...updatedAuctionOffers[0],
        status: AuctionOfferStatusEnum.OutBid,
      };
    }

    // Add the new bid to the beginning of the array
    updatedAuctionOffers = [newBidUser, ...updatedAuctionOffers];
    return updatedAuctionOffers;
  };

  const handleAuctionSubmitBid = () => {
    if (isExternal) {
      setIsOpenExternalModal(true);
    } else {
      if (offerValue < currentBid) {
        message.info("Offer Value Should be greater than Current Bid!");
        return;
      }
      setState({
        ...state,
        isOfferSubmit: true,
      });
      const payload = {
        auctionId: id,
        offer: offerValue,
      };
      dispatch(addAuctionOfferAction(payload)).then((response) => {
        const { id, buyNow } = response.payload;
        setBuyNowState({
          id: id,
          isBuyNow: buyNow,
        });
      }).finally(()=>{
        setState({
          ...state,
          isOfferSubmit: false,
        });
      });
    }
  };
  const handleAddClick = () => {
    setOfferValue(offerValue + 1);
  };

  const handleSubClick = () => {
    setOfferValue(offerValue - 1);
  };

  const handleBuyNow = () => {
    setIsBuyNowState(true);
    const payload = {
      auctionId: id,
      id: buyNowState?.id,
    };
    dispatch(getAuctionBuyNowAction(payload)).then((response) => {
      setIsBuyNowState(false);

      !isExternal && navigate(-1);
    });
  };

  const onExternalUserBid = (payload) => {
    setisOfferSubmitLoader(true);
    const newPayload = {
      ...payload,
      auctionId: id,
    };
    dispatch(addAuctionSubmitBidAction(newPayload)).then((response) => {
      const { id, buyNow } = response.payload;
      setBuyNowState({
        id: id,
        isBuyNow: buyNow,
      });
      response.payload?.id &&
        setState({
          ...state,
          auctionOfferDetailData: {
            ...state.auctionOfferDetailData,
            currentBid: payload.offer,
            auctionOffers: handleNewAuctionOffer(payload.offer, {
              name: payload.name,
              image: "",
            }),
          }, // update current bid value if successfull
        });
      setisOfferSubmitLoader(false);
      setIsOpenExternalModal(false);
    });
  };

  if (state.auctionOfferDetailData.isLoading) return <Spinner />;
  return (
    <>
      <div className="auctionOffer_wrapper">
        <h1>{name}</h1>
        <Divider />
        <div className="flex gap-2">
          <div className="auctioOffer_attachmentCarrousel group bg-white shadow-md p-2 rounded-lg p-4 flex items-center justify-between gap-2">
            {attachments.length > 0 ? (
              <AttachmentsCarrousel attachments={attachments} />
            ) : (
              <img src={auctionDefaultIcon} />
            )}
          </div>
          <Divider type="vertical" />
          <div className="auctionOffer_cardDetail">
            <p>{description}</p>
            <h1>
              {auction.currentBid}: {formatNumberWithCommas(currentBid)}
            </h1>
            <Divider />
            <div className="cardSections">
              <div className="cardSectionItem">
                <div className="cardSection__title">{sharedLabels.name}</div>
                <div className="cardSection__body layout">{name}</div>
              </div>
              <div className="cardSectionItem">
                <div className="cardSection__title">
                  {sharedLabels.referenceNo}
                </div>
                <div className="cardSection__body">{referenceNo}</div>
              </div>

              <div className="cardSectionItem">
                <div className="cardSection__title">
                  {auction.minIncreaseBid}
                </div>
                <div className="cardSection__body">{formatNumberWithCommas(minIncreaseBid)}</div>
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <TimerCountDown endDate={endDate} />
            </div>
            <div className="flex justify-between mt-10 ">
              <div className="flex gap-2">
                <InputNumber
                  size="large"
                  addonBefore={
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleSubClick}
                      type="primary"
                    >
                      -
                    </span>
                  }
                  addonAfter={
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleAddClick}
                      type="primary"
                    >
                      +
                    </span>
                  }
                  value={offerValue}
                  defaultValue={offerValue}
                  onChange={(value) => setOfferValue(value)}
                />
                <Button
                  onClick={handleAuctionSubmitBid}
                  size="large"
                  type="primary"
                  shape="round"
                  className="ThemeBtn"
                  disabled={state.isOfferSubmit}
                >
                  {state.isOfferSubmit ? <SvgSpinner /> : <RiAuctionFill />}
                </Button>
              </div>
              <div>
                {buyNowState.isBuyNow && !isExternal && (
                  <Button
                    size="large"
                    type="primary"
                    shape="round"
                    className="ThemeBtn"
                    onClick={handleBuyNow}
                    style={{ display: "flex", alignItems: "center" }}
                    disabled={isBuyNowState}
                  >
                    {isBuyNowState ? <SvgSpinner /> : <span>BUY NOW</span>}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${attachments.length > 0 && "mt-[60px]"} `}>
          <AuctionBiddingList data={auctionOffers} />
        </div>
      </div>
      {isOpenExternalModal && isExternal && (
        <AuctionBidExternalUserInfo
          isOpenExternalModal={isOpenExternalModal}
          setIsOpenExternalModal={setIsOpenExternalModal}
          offer={offerValue}
          onSubmit={onExternalUserBid}
          isOfferSubmitLoader={isOfferSubmitLoader}
        />
      )}
    </>
  );
}
