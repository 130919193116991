export const EducationTypeEnum = [
    {
        label: "No Education",
        value: 1,
    },
    {
        label: "Primary School",
        value: 2,
    },
    {
        label: "Middle School",
        value: 3,
    },
    {
        label: "High School",
        value: 4,
    },
    {
        label: "Under Graduate",
        value: 5,
    },
    {
        label: "Graduate",
        value: 6,
    },
    {
        label: "Doctorate",
        value: 7,
    },
    {
        label: "Diploma",
        value: 8,
    },
];

export const EducationLevelNameEnum = {
    1: "No Education",
    2: "Primary School",
    3: "Middle School",
    4: "High School",
    5: "Under Graduate",
    6: "Graduate",
    7: "Doctorate",
    8: "Diploma",
};