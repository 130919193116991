import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  getAllLoanService,
  addLoanService,
  GetLoanByIdService,
  getAllLoanPagingService,
  CheckLoanEligibilityService,
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const getAllLoans = createAsyncThunk(
  "loans/GetAllLoan",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllLoanPagingService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllLoanService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

export const GetLoanById = createAsyncThunk(
  "loans/GetLoanById",
  async ({ id }) => {
    const response = await GetLoanByIdService(id);
    return response.data;
  }
);

export const addLoan = createAsyncThunk(
  "Loan/addLoan",
  async (args, { dispatch, getState }) => {
    const res = await addLoanService(args);
    if (res.data?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Loan Created Successfully",
          type: "success",
          duration: 2,
        })
      );
      return res.data;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return isRejectedWithValue(res.data.message);
    }
  }
);

export const CheckLoanEligibilityAction = createAsyncThunk(
  "Loan/CheckLoanEligibilityService",
  async (type) => {
    const response = await CheckLoanEligibilityService(type);
    return response.data;
  }
);
