import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  getAllBonusService,
  addBonusService,
  GetBonusByIdService,
  cancelBonusService,
  getAllEmployeeSalaryService,
  getAllBonuspagingService,
} from "../services/service";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { cancelBonusSuccess } from "./slice";

import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";

import { ResponseType } from "../../../../utils/api/ResponseResult";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const getAllBonus = createAsyncThunk(
  "Bonus/GetAllBonus",
  async (
    { filter, pageSize, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllBonuspagingService({ ...filter, pageSize });
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllBonusService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

export const addBonus = createAsyncThunk(
  "Bonus/addBonus",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addBonusService(data);
    if (res.data?.responseCode === responseCode.Success) {
      // message.success('Bonus Created');
      dispatch(
        openNotification({
          message: "Bonus Created",
          type: "success",
        })
      );
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const GetBonusById = createAsyncThunk(
  "Bonus/GetBonusById'",
  async ({ id }) => {
    const response = await GetBonusByIdService(id);
    return response.data;
  }
);

export const cancelBonus = createAsyncThunk(
  "bonus/cancelBonus",
  async (id, { dispatch, setState }) => {
    const response = await cancelBonusService(id);
    dispatch(cancelBonusSuccess({ bonusId: id }));
    return response;
  }
);

export const getAllEmployeeSalary = createAsyncThunk(
  "EmployeeSalary/getAllEmployeeSalary",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await getAllEmployeeSalaryService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage,
          },
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response;
      default:
        return;
    }
  }
);
