import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
    responseMessage,
    responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
    addPagingCategoryService,
    getAllPageingCategoryService,
    removePagingCategoryService,
    updatePagingCategoryService,
} from "../services/service";

export const getAllPageCategory = createAsyncThunk(
    "PageCategory/getAllPageCategory",
    async (args, { dispatch }) => {
        const res = await getAllPageingCategoryService();
        if (!res.responseCode) {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }
        return res;
    }
);

export const addPageCategory = createAsyncThunk(
    "PageCategory/addPageCategory",
    async (args, { dispatch }) => {
        const res = await addPagingCategoryService(args);
        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                message.success("Page Category added successfully!")
            responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }

        return res;
    }
);

export const updatePageCategory = createAsyncThunk(
    "PageCategory/updatePageCategory",
    async (args, { dispatch }) => {
        const res = await updatePagingCategoryService(args);
        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                message.success("Page Category updated successfully!")
            responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }

        return res;
    }
);

export const removePageCategory = createAsyncThunk(
    "PageCategory/removePageCategory",
    async (args, { dispatch }) => {
        const res = await removePagingCategoryService(args.id);

        if (res.responseCode) {
            if (res.responseCode === responseCode.Success)
                res.message = "Page Category removed successfully!";
            responseMessage({ dispatch, data: res });
        } else {
            responseMessage({
                dispatch: dispatch,
                type: responseMessageType.ApiFailure,
            });
        }

        return res;
    }
);
