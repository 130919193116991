import MasterConfig from "../../../../../utils/services/MasterConfig";

export const getAllCompanyGoalsService = () => {
  return MasterConfig.get(`/api/CompanyGoal/GetAllCompanyGoal`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllDepartmentGoalsService = (id) => {
  return MasterConfig.get(`api/CompanyGoal/GetAllDepartmentCompanyGoal?referenceId=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};



export const addCompanyGoalsService = (args) => {
  return MasterConfig.post(`api/CompanyGoal/AddCompanyGoal`, args)
    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};

export const addDepartmentGoalsService = (args) => {
  return MasterConfig.post(`api/CompanyGoal/AddDepartmentCompanyGoal`, args)
    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};









export const updateCompanyGoalsService = (args) => {
  return MasterConfig.put(`api/CompanyGoal/UpdateCompanyGoal`, args)
    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};

export const updateDepartmentGoalsService = (args) => {
  return MasterConfig.put(`api/CompanyGoal/UpdateDepartmentCompanyGoal`, args)
    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};








export const removeCompanyGoalsService = (id) => {
  return MasterConfig.delete(`api/CompanyGoal/RemoveCompanyGoal?id=${id}`)
    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};

export const removeDepartmentGoalsService = (id) => {
  return MasterConfig.delete(`/api/CompanyGoal/RemoveDepartmentCompanyGoal?id=${id}`)
    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      return err;
    });
};



export const getAllAdminstrationAppraisalQuestionService = () => {
  return MasterConfig.get(`api/AppraisalQuestion/GetAllQuestion`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const getApprovalQuestionForAppraisalByIdService =(id)=>{
  return MasterConfig.get(`api/Appraisal/GetApprovalQuestionForAppraisal?userId=${id}`)
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    return err;
  });
}