export const predefinedColors = [
  "#FF0000", //red
  "#00FF00", //green1
  "#0000FF", //blue
  "#FF00FF", //magenta
  "#00FFFF", //cyan
  "#C0C0C0", //silver
  "#FFA500", //orange
  "#800080", //Purple
  "#008080", //Teal
  "#000000", //black
];
