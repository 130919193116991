import CommentWrapper from "../../../sharedComponents/Comment/CommentWrapper";
import Comments from "../../Comment/comments";
import { PannelTypeEnum } from "../enums/enum";
import SwipperComponnent from "./swipperComponnent";

function ContentContainer({ type = PannelTypeEnum.NoComponnent, data }) {
  const renderComponent = () => {
    switch (type) {
      case PannelTypeEnum.Swipper:
        return <SwipperComponnent data={data} />;
      case PannelTypeEnum.Comment:
        const { referenceId, module, referenceType, mentionMemberRefId } = data;
        return (
          // <CommentWrapper
          //   initailComments={[]}
          //   referenceId={referenceId}
          //   module={module}
          //   isCommentLoad={true}
          // />
          <Comments
          referenceId={referenceId}
          module={module}
          referenceType={referenceType}
          mentionMemberRefId={mentionMemberRefId}
      />
        );

      case PannelTypeEnum.Feed:
      // return <PostModalLeft post={singlePost} />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {data && renderComponent(data)}
      </div>
    </>
  );
}
export default ContentContainer;
