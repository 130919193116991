import { useContext, useEffect, useState } from "react";
import { Skeleton, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { resignationDictionaryList } from "../../../../localization";
import { removeIdentifier } from "../../../../store/slice";
import {
  ItemContent,
  ItemHeader,
} from "../../../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "../../../UI/statusTag";
import Attachments from "../../../../../travel/view/UI/Attachments";
import TagAvatar from "../../../../../../sharedComponents/Avatar/TagAvatar";
import DetailTabs from "../../../UI/detailTabs";
import { ApprovalsModule } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import { ResignationTypeEnumList } from "../../../../utils/resignationTypeEnumList";
import { ResignationPurposeEnumList } from "../../../../utils/resignationPurposeEnumList";
import RemarksApproval from "../../../../../../sharedComponents/AppComponents/Approvals/view";
import { GetResignationById } from "../../../../store/action";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import { readCountforResignation } from "../../../../store/slice";
import { dictionaryList } from "../../../../../../../utils/localization/languages";

function ResignationDetail({ id, index }) {

  // const { userLanguage } = useContext(LanguageChangeContext);
  // const { resignationDictionary } = resignationDictionaryList[userLanguage];

  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, resignations } = dictionaryList[userLanguage];

  const dispatch = useDispatch();
  const [state, setState] = useState({
    identifier: moment().unix(),
    resignationDetail: {
      creator: {},
      description: "",
      purposeId: "",
      type: 0,
      user: {},
      userId: "",
      finance: [],
      hr: [],
      it: [],
      other: [],
      exit: [],
      admin: [],
      reportingTo: [],
      status: 1,
      referenceNo: "",
      createDate: "",
      attachments: [],
      isLoading: true,
    },
    admin: {
      isOpen: false,
      data: [],
    },
    exit: {
      isOpen: false,
      data: [],
    },
    it: {
      isOpen: false,
      data: [],
    },
    other: {
      isOpen: false,
      data: [],
    },
    finance: {
      isOpen: false,
      data: [],
    },
    hr: {
      isOpen: false,
      data: [],
    },
    reportingTo: {
      isOpen: false,
      data: [],
    },
  });

  const { data } = useSelector((state) => state.resignationSlice);

  let resignationDetail = data !== null ? data[state.identifier] : [];
  resignationDetail =
    resignationDetail && resignationDetail?.length > 0 && resignationDetail[0];

  useEffect(() => {
    id &&
      dispatch(GetResignationById({ id: id, identifier: state.identifier }));
  }, []);

  useEffect(() => {
    resignationDetail &&
      setState({
        ...state,
        resignationDetail: {
          ...state.expenseDetail,
          ...resignationDetail,
          isLoading: false,
        },
        admin: {
          ...state.admin,
          data: resignationDetail?.admin,
        },
        exit: {
          ...state.exit,
          data: resignationDetail?.exit,
        },
        it: {
          ...state.it,
          data: resignationDetail?.it,
        },
        other: {
          ...state.other,
          data: resignationDetail?.other,
        },
        finance: {
          ...state.finance,
          data: resignationDetail?.finance,
        },
        hr: {
          ...state.hr,
          data: resignationDetail?.hr,
        },
        reportingTo: {
          ...state.reportingTo,
          data: resignationDetail?.reportingTo,
        },
      });
  }, [resignationDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const {
    creator,
    description,
    purposeId,
    type,
    user,
    userId,
    approvers,
    finance,
    hr,
    it,
    other,
    exit,
    admin,
    status,
    referenceNo,
    reportingTo,
    createDate,
    attachments,
    notificationCount,
  } = state.resignationDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Resignation,
          featureId: id,
        },
      };

      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforResignation(id));
      });
    }
  }, [notificationCount]);

  if (state.resignationDetail.isLoading) return <Skeleton />;

  return (
    <>
      <div className="detailedViewComposer">
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              status={creator?.userActiveStatus}
              profileId={creator?.id}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator.designation : ""}
                  time={moment(createDate).fromNow()}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
          </div>
        </ItemHeader>
        <ItemContent className="flex !max-h-fit">
          <div className="description w-full">
            <p>{description}</p>
          </div>
          <div
            className="attachmentBox"
            style={{ width: "65px", height: "60px" }}
          >
            <div className="!w-max m-4 ml-auto attachmentBox">
              <Attachments
                data={attachments}
                key={{ data: attachments }}
                toShow={1}
                onClick={() => { }}
                size={"50px"}
              />
            </div>
          </div>
        </ItemContent>
        <div className="cardSections">
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.reason}{" "}
            </div>
            <div className="cardSection__body">
              {ResignationPurposeEnumList.map((item) => {
                if (item.value === purposeId) {
                  return item.label;
                }
              })}
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {ResignationTypeEnumList.map((item) => {
                if (item.value === type) {
                  return item.label;
                }
              })}
            </div>
            <div className="cardSection__body">
              {user && (
                <>
                  <TagAvatar text={user?.name} img={user?.image} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <DetailTabs
        detailId={id}
        RemarksApproval={
          <>
            <RemarksApproval
              module={ApprovalsModule.ResignationReportingToApproval}
              status={status}
              reference={id}
              data={reportingTo ? reportingTo : []}
              title={sharedLabels.reportingTo}
              notRequiredMemberId={[user?.id]}
              itemCreator={creator}
            />
            <RemarksApproval
              module={ApprovalsModule.ResignationFinanceApproval}
              status={status}
              reference={id}
              data={finance ? finance : []}
              title={sharedLabels.finance}
              notRequiredMemberId={[user?.id]}
              itemCreator={creator}
            />
            <RemarksApproval
              module={ApprovalsModule.ResignationItApproval}
              status={status}
              reference={id}
              data={it ? it : []}
              title={sharedLabels.it}
              notRequiredMemberId={[user?.id]}
              itemCreator={creator}
            />

            <RemarksApproval
              module={ApprovalsModule.ResignationExitApproval}
              status={status}
              reference={id}
              data={exit ? exit : []}
              title={sharedLabels.exitinterview}
              notRequiredMemberId={[user?.id]}
              itemCreator={creator}
            />

            <RemarksApproval
              module={ApprovalsModule.ResignationAdminApproval}
              status={status}
              reference={id}
              data={admin ? admin : []}
              title={sharedLabels.admin}
              notRequiredMemberId={[user?.id]}
            />

            <RemarksApproval
              module={ApprovalsModule.ResignationHrApproval}
              status={status}
              reference={id}
              data={hr ? hr : []}
              title={sharedLabels.humanresource}
              notRequiredMemberId={[user?.id]}
              itemCreator={creator}
            />

            <RemarksApproval
              module={ApprovalsModule.ResignationOtherApproval}
              status={status}
              reference={id}
              data={other ? other : []}
              title={sharedLabels.otherApprovals}
              notRequiredMemberId={[user?.id]}
              itemCreator={creator}
            />
          </>
        }
        id={userId}
      />
    </>
  );
}
export default ResignationDetail;
