import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  updateLongLogoAction,
  updateShortLogoAction,
  //updateFullBusinessLogoAction,
} from "./actions";
const initialState = {
  longLogo: {},
  shortLogo: {},
  fullLogo: {},
  loadingData: false,
  loader: false,
};
const businessLogoSlice = createSlice({
  name: "businesslogo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateLongLogoAction.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.longLogo = payload.path;
      })
      .addCase(updateShortLogoAction.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.shortLogo = payload.path;
       })
      // .addCase(updateFullBusinessLogoAction.pending, (state) => {
      //   state.loader = true;
      // })
      // .addCase(updateFullBusinessLogoAction.fulfilled, (state, { payload }) => {
      //   state.loader = false;
      //   state.fullLogo = payload;
      // })
      // .addCase(updateFullBusinessLogoAction.rejected, (state) => {
      //   state.loader = false;
      // });
      .addMatcher(isPending(...[updateLongLogoAction,updateShortLogoAction]), (state) => {
        state.loader = true;
      })
      .addMatcher(
        isRejected(...[updateLongLogoAction,updateShortLogoAction]),
        (state) => {
          state.loader = false;
          state.loadingData = false;
        }
      );
  },
});

export default businessLogoSlice.reducer;
