import { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { defaultUiid } from "../../../../../utils/Shared/enums/enums";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { readCountforCareer, removeIdentifier } from "../../store/slice";
import moment from "moment";
import { ItemContentCareers2, ItemHeader, SingleItem } from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { Image, Skeleton, Tag, message } from "antd";
import Avatar from "../../../../sharedComponents/Avatar/avatar";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import { CareerStatusEnum } from "../../utils/careerStatusEnum";
import { getCareerByIdAction } from "../../store/action";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import NotificationBadge from "../../../../sharedComponents/Badge/NotificationBadge";
import CopyToClipboard from "react-copy-to-clipboard";
import { ROUTES } from "../../../../../utils/routes";
import { LinkOutlined } from "@ant-design/icons";
import {formatNumberWithCommas} from "../../../../../utils/Shared/helper/formatNumberWithCommas"
import {timeFromNow} from "../../../../sharedComponents/DateHandler/dateUTCtoLocal"
export default function CareerDetailCard({ id, index }) {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, careers } = dictionaryList[userLanguage];
    const [state, setState] = useState({
        identifier: moment().unix(),
        myCareerDetail: {
            designation: "",
            creator: {},
            manager: {},
            members: [],
            approvers: [],
            department: "",
            educationId: defaultUiid,
            endDate: "",
            interviewers: [],
            minSalary: "",
            maxSalary: "",
            experience: "",
            postInterviewers: [],
            isLoading: true,
            status: CareerStatusEnum.InProcess,
            applicantsCount: 0,
            image: ""
        },
        approvers: {
            isOpen: false,
            data: [],
        },



    });

    // ---- REDUX - HOOK ----
    const dispatch = useDispatch();


    // --- Redux - Hooks 
    const { data } = useSelector((state) => state.careerSlice);
    let myCareerDetail = data !== null ? data[state.identifier] : [];
    myCareerDetail =
        myCareerDetail &&
        myCareerDetail?.length > 0 &&
        myCareerDetail[0];

    // ---- REACT - HOOK - USEFFECT -----
    useEffect(() => {
        id && dispatch(getCareerByIdAction({ id: id, identifier: state.identifier }));
    }, [id])


    useEffect(() => {
        myCareerDetail &&
            setState({
                ...state,
                myCareerDetail: {
                    ...state.myCareerDetail,
                    ...myCareerDetail,
                    isLoading: false,
                },
                approvers: {
                    ...state.approvers,
                    data: myCareerDetail?.approvers,
                },
            });
    }, [myCareerDetail]);



    useEffect(() => {
        return () => {
            dispatch(removeIdentifier({ identifier: state.identifier }));
        };
    }, []);




    const { designation,
        creator,
        createDate,
        referenceNo,
        manager = {},
        members,
        approvers,
        department,
        educationId,
        endDate,
        city,
        country,
        skills,
        interviewers,
        minSalary,
        maxSalary,
        experience,
        postInterviewers,
        notificationCount,
        status,
        description,
        applicantsCount = 0,
        image = ""

    } = state.myCareerDetail;

    const skillsArray = skills?.split(",");

    //expire date 
    const isExpired = moment(endDate).isBefore(moment(), sharedLabels.days);


    const copyfunc = () => {
        message.success(sharedLabels.Copied)

    };
    useEffect(() => {
        if (notificationCount > 0) {
            const payload = {
                filter: {
                    featureType: NotificationFeatureTypeEnum.Career,
                    featureId: id
                },
            }

            dispatch(readNotificationAction(payload)).then(response => {
                dispatch(readCountforCareer(id))
            })
        }
    }, [notificationCount])

    if (state.myCareerDetail.isLoading) return <Skeleton />;

    return (<>

        <SingleItem>
            <ItemHeader>
                <div className="left">
                    <UserInfo
                        avatarSrc={creator.image}
                        name={creator.name}
                        status={creator.userActiveStatus}
                        Subline={
                            <SublineDesigWithTime
                                designation={
                                    creator.designation
                                        ? creator.designation
                                        : ""
                                }
                                time={timeFromNow(createDate)}
                            />
                        }
                    />
                </div>
                <div className="right">
                    <div className="flex items-center">
                        <Tag className="IdTag">{referenceNo}</Tag>
                        <StatusTag status={status}></StatusTag>
                        {!isExpired && status === CareerStatusEnum.Approved ? <div onClick={(e) => { e.preventDefault(); e.stopPropagation() }}> <CopyToClipboard
                            text={`${window.location.origin}${ROUTES.CAREER.APPLYJOB}/${id}`}
                            onCopy={copyfunc}
                        >
                            <Tag className="LinkTag ThemeBtn cursor-pointer">
                                <LinkOutlined /> {sharedLabels.copyLink}
                            </Tag>
                        </CopyToClipboard></div> : ""}
                        <div>
                            <NotificationBadge
                                notificationCount={notificationCount}
                            />
                        </div>
                    </div>
                </div>
            </ItemHeader>
            <ItemContentCareers2>
                <div className="font-bold text-[14px] text-primary-color">
                    {designation}
                </div>
                <p className="careerFooterText">
                    {city}, {country}
                </p>

                <div className="flex justify-between gap-2">
                    <div className=" overflow-auto mr-[14px]" dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                        style={{ width: "fit-content" }}></div>
                    <div
                        style={{ width: "65px", height: "60px" }}
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                    >
                        <Image
                            src={image === "" ? "" : image}
                            rootClassName="antdImgPreviewWrapper"

                                                    />
                    </div>
                </div>

            </ItemContentCareers2>


            <div className="skillsContainer">
                <div className="font-bold">{careers.skillrequired}</div>
                <div>
                    {skills
                        ? skillsArray?.map((item, index) => {
                            return <Tag className="LinkTag">{item}</Tag>;
                        })
                        : "No Requirements"}
                </div>
            </div>


            <div className="cardSections">
                <div className="cardSectionItem">
                    <div className="cardSection__title">{sharedLabels.salaryRange}</div>
                    <div className="cardSection__body">{`${formatNumberWithCommas(minSalary)} - ${formatNumberWithCommas(maxSalary)} `}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">{sharedLabels.EffectiveDate}</div>
                    <div className="cardSection__body">
                        {" "}
                        {moment(createDate).format("Do MMM YY")}
                    </div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {sharedLabels.experienceRequired}
                    </div>
                    <div className="cardSection__body">{experience}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {careers.Applicants}
                    </div>
                    <div className="cardSection__body">{`${applicantsCount} `}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">{careers.jobexpire}</div>
                    <div className="cardSection__body">
                        {" "}
                        {moment(endDate).format("Do MMM YY")}
                    </div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">{sharedLabels.manager}</div>
                    <div className="cardSection__body">
                        <Avatar
                            isAvatarGroup={true}
                            isTag={false}
                            heading={sharedLabels.user}
                            membersData={[{ user: manager }]}
                            text={sharedLabels.user}
                            image={"https://joeschmoe.io/api/v1/random"}
                        />

                    </div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">{sharedLabels.Members}</div>
                    <div className="cardSection__body">
                        <Avatar
                            isAvatarGroup={true}
                            isTag={false}
                            heading={sharedLabels.Members}
                            membersData={members ? members : []}
                            text={sharedLabels.Members}
                            image={"https://joeschmoe.io/api/v1/random"}
                        />

                    </div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">{sharedLabels.interviewers}</div>
                    <div className="cardSection__body">
                        <Avatar
                            isAvatarGroup={true}
                            isTag={false}
                            heading={sharedLabels.user}
                            membersData={interviewers ? interviewers : []}
                            text={sharedLabels.user}
                            image={"https://joeschmoe.io/api/v1/random"}
                        />
                    </div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">{sharedLabels.postinterview}</div>
                    <div className="cardSection__body">
                        <Avatar
                            isAvatarGroup={true}
                            isTag={false}
                            heading={sharedLabels.user}
                            membersData={postInterviewers ? postInterviewers : []}
                            text={sharedLabels.user}
                            image={"https://joeschmoe.io/api/v1/random"}
                        />
                    </div>
                </div>


            </div>

            <RemarksApproval
                module={ApprovalsModule.CareerApproval}
                status={status}
                reference={myCareerDetail?.id}
                onStatusChanged={(prev) => { }}
                setApprovalDetail={(data) => { }}
                data={state.approvers.data && state.approvers.data}
                title={sharedLabels.approvers}
                itemCreator={creator}
            />
        </SingleItem>
    </>)
}
