import { Button, Form } from "antd";
import TextInput from "../../../../../../../sharedComponents/Input/TextInput";
import { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../../utils/localization/languages";
import PrivacyOptions from "../../../../../../../sharedComponents/PrivacyOptionsDropdown/PrivacyOptions";
import { privacyOption } from "../../../../../../../../utils/Shared/enums/enums";
import moment from "moment";
import { DocumentTypeEnum } from "../../../../../utils/DocumentTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../../../utils/DocumentReferenceTypeEnum";
import { STRINGS } from "../../../../../../../../utils/base";
import { DOCUMENT_ENUM } from "../../../../../utils/DocumentEnum";
import { updateDocument } from "../../../../../store/actions";
import { useDispatch } from "react-redux";

const UpdateDocument = ({
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = DocumentReferenceTypeEnum.General,
  documentType = DocumentTypeEnum.Pad,
  OnClose = () => {},
  item = {},
}) => {
  const [form] = Form.useForm();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, document } = dictionaryList[userLanguage];

  const dispatch = useDispatch();

  const [state, setState] = useState({
    privacyId: privacyOption.Public,
    isFormSubmiting: false,
  });

  //get values in fields
  useEffect(() => {
    form.setFieldsValue({
      name: item?.name,
      members: item?.members,
    });
    setState({ ...state, privacyId: item.privacyId });
  }, [item]);

  //update the document on button click
  const handleFormSubmit = () => {
    setState({
      ...state,
      isFormSubmiting: true,
    });
    let members = item.members.map((item) => ({
      memberId: item?.memberId,
      memberRightType: item.memberRightType,
      id: item?.id,
    }));

    form
      .validateFields()
      .then((values) => {
        dispatch(
          updateDocument({
            attachmentId: item?.attachmentId,
            name: values.name,
            privacyId: state.privacyId,
            members: members,
          })
        ).then((res) => {
          setState({
            ...state,
            isFormSubmiting: false,
          });
          OnClose();
        });
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo);
      });
  };

  return (
    <>
      <Form
        form={form}
        name="addDocument"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          label={sharedLabels.name}
          name="name"
          labelPosition="top"
          rules={[
            {
              required: true,
              message: sharedLabels.nameRequired,
            },
          ]}
        >
          <TextInput placeholder={sharedLabels.enterName} />
        </Form.Item>

        <Form.Item>
          <div className="flex items-center gap-2">
            <PrivacyOptions
              privacyId={state.privacyId}
              onPrivacyChange={(value) =>
                setState({
                  ...state,
                  privacyId: value,
                })
              }
            />
            <Button
              type="primary"
              size="medium"
              className="ThemeBtn"
              block
              htmlType="submit"
              loading={state.isFormSubmiting}
              onClick={handleFormSubmit}
            >
              {"Update"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
export default UpdateDocument;
