import React, { useContext } from "react";
import { Tag } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { TaskStatusEnum } from "../../utils/TaskStatusEnum";

const StatusTag = ({status = TaskStatusEnum.NotStarted}) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { tasks } = dictionaryList[userLanguage];
    const {statusEnum} = tasks
    let color = ColorsEnum.Primary;

    switch(status){
        case TaskStatusEnum.NotStarted: 
            color = ColorsEnum.NotRequired;
            break;
            case TaskStatusEnum.Completed: 
            color = ColorsEnum.Success;
            break;
            case TaskStatusEnum.Cancel: 
            color = ColorsEnum.Cancelled;
            break;
            case TaskStatusEnum.Hold: 
            color = ColorsEnum.Hold;
            break;
        default : 
            color = ColorsEnum.Primary;
    }
    return (
        <>
        <Tag className="statusTag" style={{ backgroundColor: color }}>
            {statusEnum[status]}
        </Tag>
        </>
    );
};
      

export default StatusTag;