import {ResignationTypeEnum} from "../utils/resignationTypeEnum";
const en = {
  resignationDictionary: {
    resignationTypeEnum:{
      [ResignationTypeEnum.Resignation]: "Resignation",
      [ResignationTypeEnum.Termination]: "Termination",
    },
    resignation: "Resignation",
    resignationDetail: "Resignation Detail",
    enterRewardName: "Entet Reward Name",
    pleaseEnterRewardName: "Please Enter Reward Name",
    enterRewardReason: "Enter Reward Reason",
    reason: "Reason",
    name: "Name",
    filter: "Filter",
    tableView: "Table View",
    listView: "List View",
    complainOf: "Complain Of",
    selectApprovers: "Select Approvers",
    reward: "Reward",
    create: "Create",
    upload: "Upload",
    selectMember: "Select Members",
    members: "Members",
    category: "Category",
    approvers: "Approvers",
    rewardTo: "Reward To",
    createReward: "Create Reward",
    forApproval: "For Approval",
    deductionPerMonth: "Deduction/Months",
    pleaseEnterloanTenureInMonths: "Please Enter Loan Tenure",
    amount: "Amount",
    pleaseEnterAmount: "Please Enter Amount",
    purpose: "Purpose",
    selectPurpose: "Select Purpose",
    deadline: "Deadline",
    description: "Description",
    enterDescription: "Enter Description",
    createResignation: " Create Resignation",
    createdByMe: "Created By Me",
    table: "Table",
    list: "List",
    selectReason: "Select Reason",
    type: "Type",
    selectType: "Select Type",
    selectMember: "Select Member",
    search: "Search",
    resignationDate: "Resignation Date",
    selectDate: "Select Date",
    manager: "Manager",
    selectManager: "Select Manager",
    hr: "HR",
    selectHr: "Select HR",
    finance: "Finance",
    selectFinance: "select Finance",
    IT: "IT",
    selectItMember: "Select IT Member",
    admin: "Admin",
    selectAdmin: "Select Admin",
    otherApprovals: "Other Approvals",
    exitInterview: "Exit Interview",
    reportingTo: "Reporting To",
    referenceNo: "Reference No",
    status: "Status",
    date: "Date",
    creator: "Creator",
    loanPurpose: {
      vehicle: "Vehicle",
      personal: "Personal",
      wedding: "Wedding",
      medical: "Medical",
      education: "Education",
      house: "House",
      other: "Other",
    },
  },

  /*** Define language write side ***/
  Direction: "ltr",
};
export default en;
