import React, { useState } from "react";
import "../../style/quickOption.css";
import { DOCUMENT_ENUM } from "../../utils/DocumentEnum";
import DocumentMemberModal from "../documentMemberWrapper/documentMemberModal";
import DriveDetailDrawer from "../Page/Listing/Detail/driveDetailDrawer";
import UpdateDocumentComposerDrawer from "../Page/Composer/UpdateComposer/updateDocumentComposerDrawer";
import { useSelector } from "react-redux";
import { DocumentTypeEnum } from "../../utils/DocumentTypeEnum";
import DuplicateDocumentComposerDrawer from "./DuplicateDocumentComposer";
import CompleteDocumentWrapper from "./completeDocumentWrapper";
import { DocumentStatusEnum } from "../../utils/DocumentStatusEnum";
import { CompleteDocumentAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import { handleDocumentStatusChange } from "../../store/slice";

export default function OptionContent({
  handleClose = () => {},
  item = { item },
  documentType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
}) {

  const dispatch = useDispatch()
  console.log(item,"===item===")
  const { user } = useSelector((state) => state.userSlice);
  const [state, setState] = useState({
    isDetailDrawerOpen: false,
    isMemberModalOpen: false,
    isUpdateDrawerOpen: false,
    isDuplicateDocumentOpen: false,
    isCompleteDocumentOpen:false
  });

  const handleOpenMemberModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({
      ...state,
      isMemberModalOpen: true,
    });
    handleClose();
  };

  const handelDetailModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({
      state,
      isDetailDrawerOpen: true,
    });

    handleClose();
  };

  const handleUpdate = () => {
    setState({
      ...state,
      isUpdateDrawerOpen: true,
    });

    handleClose();
  };

  const handleOpenDuplicate = () => {
    setState({
      ...state,
      isDuplicateDocumentOpen: true,
    });
    handleClose();
  };

  const completeDocumentWithoutApprovers = () =>{
   
    const payload = {
      id:item?.id,
      approvers : {

      }
    }
    dispatch(CompleteDocumentAction(payload)).then(response =>{
      dispatch(
        handleDocumentStatusChange({ referenceId:item?.id })
      );
    })
}


  return (
      <>
        <DocumentMemberModal
          documentType={documentType}
          referenceId={item.id}
          visible={state.isMemberModalOpen}
          setVisible={(status) =>
            setState({
              ...state,
              isMemberModalOpen: status,
            })
          }
        />

        <div className="flex flex-col">
          {documentType === DOCUMENT_ENUM.DUCOMENT_TYPE.folder && (
            <div
              className="flex gap-3 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
              onClick={(e) => handleOpenMemberModal(e)}
            >
              <span>Members</span>
            </div>
          )}

          <div
            className="flex gap-3 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
            onClick={(e) => handelDetailModal(e)}
          >
            <span>Information</span>
          </div>

          {user?.id === item?.createBy &&
            documentType !== DocumentTypeEnum.Folder && (
              <div
                className="flex gap-2 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
                onClick={handleUpdate}
              >
                <span>Update</span>
              </div>
            )}
          {DocumentTypeEnum.IsDocumentSuite(documentType) && (
            <div
              className="flex gap-2 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
              onClick={handleOpenDuplicate}
            >
              <span>Duplicate</span>
            </div>
          )}
             {DocumentTypeEnum.IsDocumentSuite(documentType) &&  item.status === DocumentStatusEnum.Draft && (
              <>
                    <div
              className="flex gap-2 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
              onClick={()=>{

                completeDocumentWithoutApprovers()
              }}
            >
              <span>Complete without Approvers</span>
            </div>
            <div
              className="flex gap-2 items-center btn cursor-pointer hover:bg-[#f6f6f6] transition-all p-2 py-1 rounded-[6px]"
              onClick={()=>{

                setState({
                  ...state,
                  isCompleteDocumentOpen:true
                })

                handleClose();
              }}
            >
              <span>Complete</span>
            </div>      
              </>
        
          )}
        </div>

        {state.isDetailDrawerOpen && (
          <DriveDetailDrawer
            key={item.id}
            item={item}
            onClose={() => {
              setState({
                ...state,
                isDetailDrawerOpen: false,
              });
            }}
          />
        )}

        {/* update document drawer */}
        {state.isUpdateDrawerOpen && (
          <UpdateDocumentComposerDrawer
            documentType={documentType}
            referenceId={item?.referenceId}
            referenceType={item?.referenceType}
            onClose={() => {
              setState({
                ...state,
                isUpdateDrawerOpen: false,
                documentType: 0,
              });
            }}
            item={item}
          />
        )}
      
      {state.isDuplicateDocumentOpen && (
        <DuplicateDocumentComposerDrawer
          item={item}
          isOpen={state.isDuplicateDocumentOpen}
          handleOnClose={() => {
            setState({
              ...state,
              isDuplicateDocumentOpen: false,
            });
          }}
        />
      )}

      {
          <CompleteDocumentWrapper
            documentId={item.id}
            isShow={state.isCompleteDocumentOpen}
            onClose={() => {
              setState({
                ...state,
                isCompleteDocumentOpen:false
              })
            }}
          />
        
      }
    </>
  );
}
