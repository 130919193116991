import Avatar from "../../../../../sharedComponents/Avatar/avatar";
import { Input, Rate } from "antd";
import SubTaskRowBtn from "./subTaskRowBtn";
import moment from "moment";
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from "@ant-design/icons";

export const SubTaskTableColumn = (
  tasks,
  sharedLabels,
  inputRef,
  state,
  setState,
  handleSubTask,
  handleBlur,
  parentRecord = {},
  handleDateChange,
  createDetail,
  isGoal
) => {
  return [
    {
      title: sharedLabels.referenceNo,
      dataIndex: "referenceNo",
      key: "referenceNo",
      ellipsis: true,
      render: (text, record) =>
        record.isNew ? { props: { colSpan: 0 } } : text,
      width: "15%",
    },
    {
      title: sharedLabels.subject,
      dataIndex: "subject",
      key: "subject",
      width: "38%",
      ellipsis: true,
      render: (text, record) => {
        if (record.isNew) {
          return {
            children: (
              <div className="flex items-center">
                <ArrowLeftOutlined onClick={createDetail} className=" mr-2" />

                <Input
                  maxLength={50}
                  ref={inputRef}
                  placeholder={sharedLabels.subject}
                  className="flex-1 !p-[0px]  border-none !border-transparent focus:!border-none focus:!shadow-none !mr-3"
                  onBlur={handleBlur}
                  onChange={(e) =>
                    setState({ ...state, subTaskSubject: e.target.value })
                  }
                  value={state.subTaskSubject}
                  onPressEnter={handleSubTask}
                />
                {state.subTaskSubject && (
                  // <CheckOutlined onClick={handleSubTask} className="ml-[5px]" />
                  <div>
                    <SubTaskRowBtn
                      showButtons="!inline-block"
                      onDateRangeConfirm={(dates) => {
                        setState({ ...state, subTaskDate: dates });
                      }}
                      record={{
                        ...parentRecord,
                        isNew: true,
                        subTaskMembers: state.subTaskMembers,
                      }}
                      onAddMember={(members) => {
                        setState({
                          ...state,
                          subTaskMembers: members,
                        });
                      }}
                      showAddedMembers={true}
                      initialData={state.subTaskMembers}
                      alowClearDate={true}
                      isGoal = {isGoal}
                    />

                  </div>
                )}
              </div>
            ),
            props: {
              colSpan: 4,
            },
          };
        }
        return text;
      },
    },
    {
      title: tasks.Rating,
      dataIndex: "ratingAssign",
      ellipsis: true,
      width: "19%",
      sort: true,
      render: (ratingAssign, record) =>
        record.isNew ? (
          { props: { colSpan: 0 } }
        ) : (
          <div className="relative overflow-x-auto">
            <div className="">
              <Rate
                value={ratingAssign}
                disabled={true}
                className="!text-[16px] !mb-[3px]"
              />
            </div>
          </div>
        ),
    },
    {
      title: tasks.AssignTo,
      dataIndex: "members",
      ellipsis: true,
      width: "27%",
      render: (members, record) => {
        if (record.isNew) return { props: { colSpan: 0 } };

        const renderMembers = (
          <Avatar
            isAvatarGroup={true}
            isTag={false}
            heading={sharedLabels.Members}
            membersData={members}
          />
        );

        return (
          <div className="flex items-center justify-between">
            {renderMembers}
            <SubTaskRowBtn
              startDate={record.startDate}
              endDate={record.endDate}
              onDateRangeConfirm={(dates) => {
                handleDateChange(dates, record.id);
              }}
              record={record}
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              stopAction={record?.progress === 100 ? true : false}
              isGoal = {isGoal}
            />
          </div>
        );
      },
    },
  ];
};
