import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import { DeleteFilled } from "@ant-design/icons";

function MemberListItem({ member, isDelete = false, onDelete = () => {} }) {
  const DeleteInItemDetail = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(id);
  };
  return (
    <>
      <div className="flex justify-between mb-2">
        <UserInfo
          avatarSrc={member?.image}
          name={member?.name}
          Subline={
            <SublineDesigWithTime
              designation={member?.designation ? member?.designation : ""}
            />
          }
        />
        {isDelete && (
          <div className="flex items-center">
            <DeleteFilled
              style={{ color: "#000000" }}
              onClick={(e) => DeleteInItemDetail(e, member.id)}
            />
          </div>
        )}
      </div>
      <hr className="mb-2" />
    </>
  );
}
export default MemberListItem;
