export const CareerFilterSortEnum = {
    CreateDateAsc: 1,
    CreateDateDesc: 2,
    ReferenceNoDesc: 3,
    ReferenceNoAsc: 4,
    UpdateDateDesc: 5,
    UpdateDateAsc: 6,
    MinSalary: 7,
    MinSalaryDesc: 8,
    MaxSalary: 9,
    MaxSalaryDesc: 10,
}


export const careerTableSort = {
    createDate: {
        descend: CareerFilterSortEnum.CreateDateDesc,
        ascend: CareerFilterSortEnum.CreateDateAsc
    },
    referenceNo: {
        descend: CareerFilterSortEnum.ReferenceNoDesc,
        ascend: CareerFilterSortEnum.ReferenceNoAsc,
    },
    updateDate: {
        descend: CareerFilterSortEnum.UpdateDateDesc,
        ascend: CareerFilterSortEnum.UpdateDateAsc,
    },
    minSalary: {
        descend: CareerFilterSortEnum.MinSalaryDesc,
        ascend: CareerFilterSortEnum.MinSalary,
    },
    maxSalary: {
        descend: CareerFilterSortEnum.MaxSalaryDesc,
        ascend: CareerFilterSortEnum.MaxSalary,
    },




}



