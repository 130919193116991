import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { openNotification } from "../../../../../utils/Shared/store/slice";
import {
  AddApprovalReminderService,
  addApprovalService,
  getAllApprovalByReferenceIdService,
  getAllApprovalReminderService,
  removeApprovalService,
} from "../services";
import { ResponseType } from "../../../../../utils/api/ResponseResult";
import { responseCode } from "../../../../../services/enums/responseCode";
import { handleAddTravelApprover } from "../../../../features/travel/store/slice";
import { handleAddExpenseApprover, handleExpenseReminder } from "../../../../features/expense/store/slice";
import { handleAddResignationApprover } from "../../../../features/resignation/store/slice";
import { handleAddAppraisalApprover } from "../../../../features/appraisalModule/store/slice";
import { ApprovalsModuleEnum } from "../../../../features/approval/utils/enum/approvalModuleEnum";
import { handleAddPromotionApprover } from "../../../../features/promotion/store/slice";
import { handleAddCustomApprovalsApprover } from "../../../../features/CustomApprovals/store/slice";
import { handleAddDocumentApprover } from "../../../../features/documents/store/slice";
import { handleAddLeaveApprover } from "../../../../features/leave/store/slice";
import { handleAddWarningApprover } from "../../../../features/warning/store/slice";
import { handleAddBonusApprover } from "../../../../features/bonus/store/slice";
import { handleAddLoanApprover } from "../../../../features/loan/store/slice";
import { handleAddRewardpprover } from "../../../../features/reward/store/slice";
import { handleAddComplainApprover } from "../../../../features/complain/store/slice";
import { handleAddSalaryApprover } from "../../../../features/salary/store/slice";
import { handleAddQuotationApprover } from "../../../../features/quotation/store/slice";
import { handleAddFormsApprover } from "../../../../features/forms/store/slice";
import { handleAddCareerApprover } from "../../../../features/careers/store/slice";
import { responseMessage, responseMessageType } from "../../../../../services/slices/notificationSlice";
import { handleAddAuctionApprovers } from "../../../../features/auction/store/slice";
import { message } from "antd";
import { getfunctionForReminderApproval } from "../helper/helpers";
import { handleAddPayrollApprovers } from "../../../../features/payroll/store/slice";
import { handleNewApprovalRemark } from "../../../../features/approval/store/action";

export const addApproversAction = createAsyncThunk(
  "Approvers/addApproversAction",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log(payload, "PAYLOAD FROM ACTION");
    const { approverId, module, referenceId } = payload;
    const response = await addApprovalService(payload);
    if (response.responseCode) {
      if (response.responseCode === responseCode.Success) {
        dispatch(
          openNotification({
            message: "Approvers Added Successfully!",
            type: "success",
            duration: 2,
          })
        );
        let approver = response?.data;
        switch (module) {
          case ApprovalsModuleEnum.TravelAgent:
          case ApprovalsModuleEnum.TravelApproval:
            dispatch(
              handleAddTravelApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.ExpenseApproval:
          case ApprovalsModuleEnum.ExpenseExecutor:
          case ApprovalsModuleEnum.ExpenseFinance:
            dispatch(
              handleAddExpenseApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.ResignationAdminApproval:
          case ApprovalsModuleEnum.ResignationExitApproval:
          case ApprovalsModuleEnum.ResignationFinanceApproval:
          case ApprovalsModuleEnum.ResignationHrApproval:
          case ApprovalsModuleEnum.ResignationItApproval:
          case ApprovalsModuleEnum.ResignationOtherApproval:
          case ApprovalsModuleEnum.ResignationReportingToApproval:
            dispatch(
              handleAddResignationApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.AppraisalApproval:
            dispatch(
              handleAddAppraisalApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.PromotionApproval:
            dispatch(
              handleAddPromotionApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.CustomApproval:
            dispatch(
              handleAddCustomApprovalsApprover({
                referenceId,
                module,
                approver,
              })
            );
            break;
          case ApprovalsModuleEnum.DocumentApproval:
            dispatch(
              handleAddDocumentApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.LeaveApproval:
            dispatch(handleAddLeaveApprover({ referenceId, module, approver }));
            break;
          case ApprovalsModuleEnum.BonusApproval:
            dispatch(handleAddBonusApprover({ referenceId, module, approver }));
          case ApprovalsModuleEnum.LoanApproval:
            dispatch(handleAddLoanApprover({ referenceId, module, approver }));
            break;
          case ApprovalsModuleEnum.RewardApproval:
            dispatch(handleAddRewardpprover({ referenceId, module, approver }));
            break;
          case ApprovalsModuleEnum.WarningApproval:
            dispatch(
              handleAddWarningApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.ComplainApproval:
            dispatch(
              handleAddComplainApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.SalaryApproval:
            dispatch(
              handleAddSalaryApprover({ referenceId, module, approver })
            );
            break;
          case ApprovalsModuleEnum.QuotationApproval:
            dispatch(handleAddQuotationApprover({ referenceId, module, approver }))
          case ApprovalsModuleEnum.FormApproval:
            dispatch(handleAddFormsApprover({ referenceId, module, approver }));
            break;
          case ApprovalsModuleEnum.CareerApproval:
            dispatch(handleAddCareerApprover({ referenceId, module, approver }));
            break;
          case ApprovalsModuleEnum.AuctionApproval:
              dispatch(handleAddAuctionApprovers({referenceId , module , approver}))
          case ApprovalsModuleEnum.PayrollApproval:
            dispatch(handleAddPayrollApprovers({ referenceId, module, approver }));
            break;
          default:
            break;
        }
        return response.data;
      } else {
        dispatch(
          openNotification({
            message: response.message,
            type: "error",
            duration: 2,
          })
        );
      }
    }
  }
);

export const getAllApprovalByReferenceId = createAsyncThunk(
  "Approvers/getAllApprovalByReferenceId",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await getAllApprovalByReferenceIdService(payload);
    if (response.responseCode) {
      if (response.responseCode === responseCode.Success) {
        dispatch(
          openNotification({
            type: "success",
            duration: 2,
          })
        );
        return response.data;
      }
    }
  }
);



export const AddApprovalReminder = createAsyncThunk(
  "AddApprovalReminder",
  async ({ requestObj, referenceId }, { dispatch }) => {
    const res = await AddApprovalReminderService(requestObj);

    if (res.responseCode) {
      if (res.responseCode === responseCode.Success) {
        message.success("Reminded")
        const payload = { ...requestObj, referenceId }
        console.log(payload, "RemindedPayload")
        const dispatchFunction = getfunctionForReminderApproval(payload.module, payload, dispatch)
        dispatchFunction()
        console.log(dispatchFunction, "dispatchFunctiondispatchFunctiondispatchFunction")
        responseMessage({ dispatch, data: res });
      }
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);


export const getAllApprovalReminder = createAsyncThunk(
  "getAllApprovalReminder",
  async (requestObj, { dispatch }) => {
    const res = await getAllApprovalReminderService(requestObj);

    if (res.responseCode) {
      if (res.responseCode === responseCode.Success) {
        responseMessage({ dispatch, data: res });
      }
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);


export const removeApprovalAction = createAsyncThunk(
  "removeApprovalAction",
  async (requestObj, { dispatch }) => {
    const res = await removeApprovalService(requestObj);
    const {id , module , referenceId} = requestObj
    console.log(res,"===response===")
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success) {
        dispatch(
          openNotification({
            message: "Approvers Removed Successfully!",
            type: "success",
            duration: 2,
          })
        );

        const data = {
          referenceId:referenceId,
          module:module,
          remark:res.data
        }
        dispatch(handleNewApprovalRemark(data));
        // responseMessage({ dispatch, data: res });
      }
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
    }
    return res;
  }
);
