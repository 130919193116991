import { jsonToFormData } from "../../../../utils/base";
import MasterConfig from "../../../../utils/services/MasterConfig";

export const getAllAuctionService = (data) => {
  return MasterConfig.post(`api/Auction/GetAllAuction`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAuctionByIdService = (id) => {
  return MasterConfig.get(`api/Auction/GetAuctionById?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((res) => {
      return res;
    });
};

export const addAuctionService = async(data) => {
	const formData = jsonToFormData(data);
	return MasterConfig.post(`api/Auction/AddAuction`, formData)
		.then(res => {
			return res.data;
		})
		.catch(res => {
			return res;
		});
    
};

export const getAllAuctionPagingService = (data) => {
  return MasterConfig.post(`api/Auction/GetAllAuctionPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
  }

export const addAuctionMemberService = (data) => {
  const {id , payload} = data
  return MasterConfig.post(`api/Auction/AddAuctionMember?id=${id}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
}


export const getAllAuctionMemberService = (id) => {
  return MasterConfig.get(`api/Auction/GetAllAuctionMember?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
  }

  export const removeAuctionMemberService = (data) => {
    const {id , payload} = data
    return MasterConfig.post(`api/Auction/RemoveAuctionMember?id=${id}`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
    
  }


  export const addAuctionOfferService = (data) => {
    return MasterConfig.post(`api/Auction/AddAuctionOffer`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
    
  }


  
  export const getAllAuctionOfferService = (data) => {
    return MasterConfig.post(`api/Auction/GetAllAuctionOffer`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
    
}

export const GetAuctionOfferByIdService = (id) => {
  return MasterConfig.get(`api/Auction/GetAuctionOfferById?id${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
}

export const addAuctionSubmitBidService = (payload) =>{
  return MasterConfig.post(`api/Auction/AddAuctionSubmitBid`,payload)
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    return err;
  })

}


// new one
export const getAuctionBuyNowService = (payload) => {
  const {id , auctionId} = payload
  return MasterConfig.get(`api/Auction/AuctionBuyNow?id=${id}&auctionId=${auctionId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
}

