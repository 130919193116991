import { List } from "antd";
import MemberListItem from "../defaultMemberList/listItem";

function DocumentMemberList({ data = [], onDelete = () => {} }) {
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="max-h-[300px] overflow-y-auto"
        renderItem={(item) => {
          return (
            <MemberListItem
              id={item?.id}
              member={item?.member}
              isDelete={true}
              onDelete={onDelete}
            />
          );
        }}
      />
    </>
  );
}
export default DocumentMemberList;
