// import { jsonToFormData } from "../../../../utils/base";
import MasterConfig from "../../../../utils/services/MasterConfig";
import {
	ResponseResultError,
	ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import { getAllNotification_dto } from "./dto";

export const getAllNotificationService = async (payload) => {
	try {
		payload = getAllNotification_dto(payload);
		const {
			data: { responseCode, data, message },
		} = await MasterConfig.post(`api/Notification/GetAllNotificationByUser`, payload);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const getAllNotificationCountService = async () => {
	try {
		const payload = {}
		const {
			data: { responseCode, data, message },
		} = await MasterConfig.get(`api/Notification/GetNotificationNavbarCount`, payload);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const removeNotificationCountService = async (payload={}) => {
	try {
		const {
			data: { responseCode, data, message },
		} = await MasterConfig.post(`api/Notification/RemoveNotificationCounter`, payload);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const readNotificationService = async (payload={}) => {
	try {
		const {
			data: { responseCode, data, message },
		} = await MasterConfig.post(`api/Notification/NotificationRead`, payload);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const NotificationFilterTypeService = async (payload={}) => {
	try {
		const {
			data: { responseCode, data, message },
		} = await MasterConfig.post(`api/Notification/NotificationFilterType`, payload);
		if (responseCode === 1001) 
			return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const markAllReadService = async (payload={}) => {
	try {
		const {
			data: { responseCode, data, message },
		} = await MasterConfig.get(`api/Notification/NotificationReadAll`);
		if (responseCode === 1001) 
			return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};
