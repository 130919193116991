import React from "react";
import { useSelector } from "react-redux";
import { handleUnauthorized } from "../../../utils/base";
import UnauthorizedPage from "./Pages/unauthorizedPage";

function CheckAuthorization({
  feature = -1,
  component,
  allowedUserType = null,
  returnNull = false,
  returnUnAuth = false,
  userInternalPermission = [],
}) {
  const { permissions: userPermissions, userTypeId } = useSelector(
    (state) => state.userSlice.user
  );

  // Validate the feature parameter
  const isValidFeature = Number.isInteger(feature) && feature >= 0;

  if (!isValidFeature) {
    console.error("Invalid feature ID:", feature);
    return returnNull ? null : <UnauthorizedPage />;
  }

  // Use userInternalPermission if it has length, otherwise use userPermissions
  const effectivePermissions =
    userInternalPermission?.length > 0
      ? userInternalPermission
      : userPermissions;

  const isAuthorized =
    effectivePermissions?.includes(feature) &&
    (!allowedUserType || allowedUserType.includes(userTypeId));

  if (isAuthorized) {
    return component;
  }

  if (returnNull) {
    return null;
  }

  if (returnUnAuth) {
    return <UnauthorizedPage />;
  }

  return handleUnauthorized();
}

export default CheckAuthorization;
