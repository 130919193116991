import { Form, Radio } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { TaskPriorityEnum } from "../../../utils/TaskPriorityEnum";
import { dictionaryList } from "../../../../../../utils/localization/languages";

function TaskPriorityComposer({ onChangeCallback = (p) => {} }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const { taskPriorityEnum } = tasks;
  const [state, setState] = useState({
    priority: TaskPriorityEnum.Low,
  });

  useEffect(() => {
    onChangeCallback(state.priority);
  }, [state]);

  return (
    <Form.Item
      label={sharedLabels.Priority}
      name="priority"
      initialValue={state.priority}
      rules={[
        {
          required: true,
          message: sharedLabels.Pleaseselectpriority,
        },
      ]}
    >
      <Radio.Group
        className="radioPrimary taskRadioPriority"
        onChange={(event) => {
          setState({ ...state, priority: event.target.value });
        }}
      >
        <Radio.Button value={TaskPriorityEnum.Low}>
          <CheckCircleOutlined />
          {taskPriorityEnum[TaskPriorityEnum.Low]}
        </Radio.Button>
        <Radio.Button value={TaskPriorityEnum.Medium}>
          <CheckCircleOutlined />
          {taskPriorityEnum[TaskPriorityEnum.Medium]}
        </Radio.Button>
        <Radio.Button value={TaskPriorityEnum.High}>
          <CheckCircleOutlined />
          {taskPriorityEnum[TaskPriorityEnum.High]}
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
}
export default TaskPriorityComposer;
