import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";
import { CareerStatusEnum } from "../../utils/careerStatusEnum";
const StatusTag = ({ status = CareerStatusEnum.InProcess }) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { careers } = dictionaryList[userLanguage];
    let color = ColorsEnum.Primary;

    switch (status) {
        case CareerStatusEnum.InProcess:
            color = ColorsEnum.Primary;
            break;
        case CareerStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case CareerStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case CareerStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case CareerStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;
        default:
            color = ColorsEnum.Primary;
    }
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {careers.statusEnum[status]}
            </Tag>
        </>
    );
};

export default StatusTag;

