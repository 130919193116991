import React from "react";
import ReferenceTag from "../../../../sharedComponents/Tag/ReferenceTag";
import StatusTag from "../../../../sharedComponents/Tag/StatusTag";
import PropTypes from "prop-types";
import NotificationBadge from "../../../../sharedComponents/Badge/NotificationBadge";

function CardStatusTagView(props) {
  return (
    <div className="flex justify-center  sm:m-0">
      {props.refNo && <ReferenceTag refNo={props.refNo} />}
      {React.isValidElement(props.status) ? (
        props.status
      ) : (
        <StatusTag status={props.status} />
      )}
       <span>
      <NotificationBadge notificationCount={props.notificationCount}/>
      </span>
    </div>
  );
}

export default CardStatusTagView;
CardStatusTagView.propTypes = {
  refNo: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.element]).isRequired,
  notificationCount: PropTypes.number.isRequired
};
CardStatusTagView.defaultProps = {
  refNo: "",
  status: 0,
  notificationCount: 0
};
