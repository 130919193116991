import { ResponseResultError, ResponseResultSuccess } from "../../../../utils/api/ResponseResult";
import { STRINGS, handleUnauthorized } from "../../../../utils/base";
import Config from "../../../../utils/services/MasterConfig";

const getAllEmployeeSalary_dto = (data) => {
	return {
		"pageNo": data.pageNo ? data.pageNo : 0,
		"pageSize": data.pageSize ? data.pageSize : 20,
		"search": data.search ? data.search : "",
		"approverStatus": data.approverStatus ? data.approverStatus : [],
		"sortBy": data.sortBy ? data.sortBy : 1,
		"filterType": data.filterType ? data.filterType : 0,
		"status" : data.status ?? [],
		"startDate" : data.startDate ?? "",
		"endDate" : data.endDate ?? ""
	}
}

const addMultipleEmployeeSalary_dto = (data) => {
    return {
        "description": data.description || "",
        "approvers": data.approvers ? data.approvers.map(approver => ({
            "approverId": approver.approverId || ""
        })) : [],
        "salaryMembers": data.salaryMembers ? data.salaryMembers.map(member => ({
			"currencyId": member?.currencyId || "00000000-0000-0000-0000-000000000000",
            "userId": member.userId || null,
            "basicSalary": member.basicSalary ?? 0,
            // "taxSlabId": member.taxSlabId || "",
            "effectiveDate": member.effectiveDate || null,
            "deduction": member.deduction ?? 0,
            "netSalary": member.netSalary ?? 0,
            "details": member.details ? member.details.map(detail => ({
                "allowance": detail.allowance ?? 0,
                "allowanceId": detail.allowanceId || ""
            })) : []
        })) : []
    };
}

export const addMultipleEmployeeSalaryService = async (payload) => {
		try {
		let request = addMultipleEmployeeSalary_dto(payload);
		const {
			data: { responseCode, data, message },
		} = await Config.post(`api/EmployeeSalary/addEmployeeSalary`, request);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		else if(responseCode === 1011) handleUnauthorized();
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const getEmployeeSalaryDetailService = async (id) => {
	try {
		const {
			data: { responseCode, data, message },
		} = await Config.get(`api/EmployeeSalary/GetEmployeeSalaryById?id=${id}`);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const getAllEmployeeSalaryService = async (payload = {}) => {
	try {
		let request = getAllEmployeeSalary_dto(payload);
		if(!request.startDate || !request.endDate){
			delete request.startDate
			delete request.endDate
		}
		const {
			data: { responseCode, data, message },
		} = await Config.post(`api/EmployeeSalary/GetAllEmployeeSalary`, request);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		else if(responseCode === 1011) handleUnauthorized();
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const getAllEmployeeSalaryPagingService = async (payload = {}) => {
	try {
		let request = getAllEmployeeSalary_dto(payload);
		if(!request.startDate || !request.endDate){
			delete request.startDate
			delete request.endDate
		}
		const {
			data: { responseCode, data, message },
		} = await Config.post(`api/EmployeeSalary/GetAllEmployeeSalaryPaging`, request);
		console.log(responseCode,"==============")
		if (responseCode === 1001) return ResponseResultSuccess(data);
		else if(responseCode === 1011) handleUnauthorized();
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const GetAllEmployeeSalaryMemberByBusinessIdService = async () => {
	try {
		const {
			data: { responseCode, data, message },
		} = await Config.get(`api/EmployeeSalary/GetAllEmployeeSalaryMemberByBusinessId`);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};