import { Modal } from "antd"
import React  from "react"

export default function PrivacyContentWrapper({isOpenPrivacyContent , setIsOpenPrivacyCotent}){


    return(
        <Modal
            title="Privacy Policy"
            centered
            open={isOpenPrivacyContent}
            onCancel={() => setIsOpenPrivacyCotent(false)}
            width={"70%"}
            footer={null}
        >
            <h3><b>PRIVACY POLICY Updated: December 20, 2023</b></h3>
            <h2><b>A. INTRODUCTION</b></h2>
            <p>
                We, Workwise, are dedicated to safeguarding your information and complying with all applicable privacy and data protection laws. As a company operating under the laws of the ADGM (Abu Dhabi Global Markets), we take our legal responsibilities seriously.
                This Privacy Policy provides details on how we handle personal information collected through our Website, mobile apps, and Products and Services, which include:
            </p>
            <h5><b> 1. The types of personally identifiable information collected from you and third parties;</b></h5>
            <h5><b> 2. The entity responsible for collecting this information;</b></h5>
            <h5><b> 3. How the information is utilized and disclosed;</b></h5>
            <h5><b> 4. Who may have access to this information;</b></h5>
            <h5><b> 5. Your options regarding the collection, use, and sharing of information, as well as your rights;</b></h5>
            <h5><b> 6. The security measures in place to protect your information from loss, misuse, or alteration under Workwise's control; and</b></h5>
            <h5><b> 7. The WorkWise Privacy Policy is an essential component of the WorkWise Terms of Service. It is crucial that you carefully review this document before continuing to use our website or services. By utilizing the WorkWise website and services, you acknowledge that you have read and comprehended this Privacy Policy.</b></h5>
            <p>
                    Your use of the WorkWise website and the services offered, including any privacy-related disputes, is governed by this Privacy Policy and the WorkWise Terms of Service. By accessing the WorkWise website or using any of the products and services provided, you consent to the use and disclosure of your personal information as outlined in this Policy and the WorkWise Terms of Service.

                    While it is not mandatory for you to provide your personal information, please note that failure to do so may impact the level of service we are able to offer you.

                    Regarding the Privacy Policy, we maintain the right to make updates as necessary. We encourage you to periodically review this policy for any changes. If you do not agree with the revised Policy, kindly refrain from using this Website.
            </p>
            <h2><b>B. INFORMATION WE COLLECT</b></h2> 
            <p>
                     We may require visitors to the Website to create a user account with WorkWise in order to access most of our Products and Services. During the registration process, WorkWise may request certain personal information through an online form. This information may include your name, phone number, email address, credit card details, and residential or business addresses. This information is collected and processed by online payment services, such as merchant accounts, that we may use for payment processing, including automatic subscription renewals. Registering for a user account or participating in activities that require a user account is completely optional.

                    We may ask for your job title and identity documents on occasion.
                    Your contact information includes your billing address, email address, and telephone numbers.
                    Transaction details will include information about payments and other relevant details regarding products and services you have purchased from us.
                    Technical data refers to your IP address, login data, browser type and version, time zone and location, browser plug-ins, operating system, and other technology used on the devices you use to access our website.
                    Your profile data includes your username, password, purchase history, preferences, feedback, and survey responses.
                    Usage data provides information about how you interact with our website, products, and services.
                    Marketing and communications data includes your preferences for receiving marketing material and your communication preferences.
                    You are not obligated to answer any questions and can choose to decline providing this information at any time. However, if you choose not to provide certain requested information, we may not be able to offer you some or all of our products and services. Some of the information we request may be mandatory, while other information is optional, depending on the activity.

                    Failure to provide the necessary information for a specific activity or purchase will result in your inability to participate in said activity or make the desired purchase. WorkWise will notify you of the mandatory and optional nature of the information required.
                    You may have the opportunity to subscribe to a regular newsletter and receive updates on products. This newsletter enables WorkWise to keep you informed about new Products and Services, as well as updates and other relevant company news. To subscribe, you will need to provide your first and last name, email address, and other details. Optionally, you may also provide the name of the company you represent.
                    While browsing our Website or interacting with our email client, when you request a page or click on a URL link, our servers will record the information contained in the HTTP request header, as well as any relevant JavaScript or technical tools. This information includes your IP number, the time of the request, the URL of your request, and other details. We collect this information to ensure the proper functioning of the Website and to provide you with the desired functionality.

                    Enhancing our website by tailoring its contents and features to better suit our users' needs is a priority. 

                    Mobile applications provide a convenient way to access our services. When you install and utilize our app, certain information may be automatically gathered. This includes details about your usage, such as traffic data, logs, and communication data, as well as the resources you interact with on the app.

                    We may also collect information about your device and internet connection, such as the IP address, operating system, browser type, mobile network information, and telephone number.

                    In addition, the app may access metadata and other information associated with files stored on your device, such as photos, audio and video clips, personal contacts, and address book details.

                    If you choose to allow it, the app may collect real-time location information from your device for the purpose of tracking your employees' locations, provided they have given explicit consent.

                    When using our products and services, including through the website, WorkWise collects and securely stores the files you upload, download, or access.

                    WorkWise ensures the protection of sensitive information by not sharing or granting third-party access to such files. However, it is important to note that no password-protected system is entirely impenetrable. Despite the security technologies and procedures employed by Bixtrix24 to safeguard the data on the Website, there is still a possibility of unauthorized access, viewing, copying, modification, and distribution of the data and files stored on the Website or through Products and Services.

                    To utilize most of the Products and Services offered on the Website, a User Account containing personal information is required. Once the User Account is created, you have the option to provide additional information to your profile and manage your account information online through the Website.

                    The decision to provide additional information beyond the minimum requirements for account creation is solely up to you. Any information provided during registration or in your profile section may be utilized in accordance with our Terms of Use and this Privacy Policy.

                    You have the ability to modify the information you publish on the Website by accessing your account and making changes as you see fit. If you decide to deactivate your User Account, you can do so through the User Account interface or by reaching out to WorkWise at privacy@workw.com. Deactivating your account will result in the removal of all data associated with your account from our servers, including any files you have stored through the Website or its Products and Services.

                    We are committed to not collecting personal information from children under the age of 16 without the consent of their parent or legal guardian. To use our Website, Products, or Services, you must be at least 16 years old or the age of majority in your jurisdiction. If you are under 16 years old, you are only allowed to use our website with permission from your parent or legal guardian. Parents and Legal Guardians can request a review, deletion, or cessation of the collection of personally identifiable information of their child by contacting us through mail or email using the provided address information at the bottom of this page.

                    We prioritize the protection of your personal information by implementing a range of security technologies and procedures. Our utmost effort is dedicated to ensuring that appropriate security measures are in place to safeguard your Personal Data. We adhere to the security measures outlined in the relevant regulations, as well as our own technical and organizational security measures, which encompass policy, governance, procedures, responsibilities, risk assessment, network security, and subcontractor security.

                    WorkWise maintains secure data networks that are safeguarded by industry standard firewall and password protection systems. Our security and privacy policies undergo periodic review and enhancement, as needed. Only authorized individuals have access to the information provided by our users. WorkWise takes every precaution to handle your information securely and in accordance with this Privacy Policy.

                    However, it is important to note that transmitting information over the internet is not entirely secure. Therefore, we cannot guarantee the complete security of your Personal Data when transmitted through the Website. Any transmission is done at your own risk. Once we receive your information, we employ strict procedures and security features to prevent unauthorized access.

                    WorkWise takes the protection of confidential information seriously and has implemented industry-standard security measures. Personally identifiable information is stored on secure servers with physical and electronic security features. Additional measures may be put in place depending on the legal requirements of the country. The information collected on our websites is processed and stored in the United States and other countries where WorkWise operates. Our employees are bound by our privacy and security policies, and only those who provide technical support have access to your information. It is important to keep your password confidential and not share it with anyone. If you are using a shared computer, make sure to log out to protect your information.

                    Personal Information Usage and Disclosure
                    Utilization of Your Personal Information
                    Based on Contractual Legitimacy
                    By subscribing to a service or registering a product, your provided information is utilized to establish your entitlements, if applicable, to technical assistance or any other advantages that might be accessible to registered users.

                    To ensure that users are aware of any changes that may affect their ability to use a service they have previously signed up for, we will reach out to them regardless of their email contact preferences.
                    By taking into account our legitimate interests and your consent, we utilize your personal information to advance and operate our business. Examples of how we utilize personal information include enhancing our products and services, customizing communications with you, conducting market research, administering customer surveys, implementing more targeted advertising tailored to individual customers, and processing data.
                    WorkWise gathers your information to document and support your participation in the activities you choose.
                    With your consent, your personal information may also be utilized to keep you informed about new products, product upgrades, patches, special offers, and other products and services from WorkWise and carefully selected third parties. This will only occur if you have agreed to receive such communications.
                    When you establish a User Account with WorkWise and/or provide your email address and other personal identification details, we may periodically contact you via email based on your personal preferences. During these communications, we will provide information about special offers and promotions that we believe may be of interest to you.

                    Our relationship with you involves offering products or services that we believe will interest you. To send you emails, we may use a third-party email service provider. However, they are strictly prohibited from using your email address for any purpose other than sending WorkWise related emails. If you no longer wish to receive emails with updates and information, you can unsubscribe by sending an email to unsubscribe@workw.com. Additionally, each email you receive will provide you with the option to opt-out of future emails by following the instructions provided. You can also opt-out online by updating your User Account as explained in the User Account section of this Privacy Policy (above).

                    We may retain some of your information in order to comply with legal obligations and enforce our website's terms of use. This is to protect our legal rights. Furthermore, we reserve the right to contact you if required as part of a legal proceeding or if there has been a violation of any applicable licensing, warranty, and purchase agreements.

                    There are certain matters that will hold significance for you. These rights do not grant us permission to reach out to you for marketing purposes, whether it be for a new or existing service, if you have specifically requested us not to do so. It is important to note that these types of communications are rare.

                    When it comes to sharing your personal data with third parties, WorkWise relies on the assistance of independent contractors such as email service providers and hosting providers. However, we assure you that we will not disclose your information to any third parties for direct marketing purposes.

                    We have established contracts with our data processors, ensuring that they are unable to take any action with your personal information unless we have explicitly instructed them to do so. They are prohibited from sharing your personal information with any other organization besides us. Additionally, they are responsible for securely storing and retaining your data for the duration of time that we specify.

                    The hosting provider services, which involve the storage and maintenance of databases containing your personal data, are carried out on our behalf by our authorized data processor, Amazon Web Services (AWS), located in Frankfurt, Germany. AWS is a part of a global organization that operates in numerous countries worldwide, through its local operating companies.

                    In the event that we are legally obligated to do so, WorkWise may disclose your personal information. This includes situations where we receive a subpoena or other legal request. Additionally, we may disclose your information if we have reason to believe it is necessary for identifying, contacting, or taking legal action against individuals who may be violating our Privacy Policy or the terms of your agreements with us. Furthermore, we may disclose information to ensure the safety and well-being of our users and the general public, as well as in accordance with confidentiality and licensing agreements with third-party partners who assist us in operating and maintaining our website. If required by law enforcement or judicial authorities, WorkWise will provide personally identifiable information to the appropriate governmental authorities. This action will only be taken upon receipt of proper documentation. We may also share information with law enforcement to safeguard our property and the welfare of individuals, as permitted by applicable laws.

                    In the event of a business transition, where your personal information may be acquired by another entity, we will ensure that you have the opportunity to make a choice if the use of your information will significantly change. This aligns with our policy of notifying users about any changes.
                    The storage location of your personal data depends on the domain zone of WorkWise. Personal data collected through the workw.com domain is processed by WORKWISE LIMITED, registered in Abu Dhabi, and hosted by Google Web Services data centers, which are fully compliant with GDPR regulations.
                    If you would like more information about how data is processed for users registered through the Workw.com domain, please refer to our documentation.
                    In some cases, WorkWise may process user personal data outside of the European Union. However, we ensure that appropriate safeguards are in place, as outlined in article 46 of GDPR, such as the use of standard data protection clauses approved by the European Commission.
                    Regarding your choices, rights, and privacy-related inquiries or complaints, you always have the option to decide whether or not to disclose personally identifiable information. Rest assured, this choice will not hinder your ability to use the Website.

                    You have the opportunity to voluntarily indicate your preferences for any optional items that are not necessary for us to provide our services. You have the option to unsubscribe from receiving emails from WorkWise or from having your personally identifiable information shared with specific companies. If you choose to unsubscribe, you can make the necessary changes to your User Account preferences regarding emails or information sharing. By opting out, we will refrain from sharing your personal information with third parties or sending you emails. However, we may still use your personal information internally for purposes such as enhancing your user experience or complying with applicable laws. 

                    For residents of California, we will not share any Personal Data with third parties for their direct marketing purposes, as prohibited by California law. If there are any changes to our practices, we will comply with relevant laws and provide you with advance notice. 

                    If you are located in the European Union or other jurisdictions, you may have certain rights regarding the processing of your personal data, including the right to access and request copies of your personal information.

                    This right is always applicable. You have the right to request us to correct any information that you believe is inaccurate. Additionally, you have the right to ask us to provide any missing information. 

            </p>
            <h4>This right is applicable in the following cases: </h4>
            <h5><b> 
                    (a) When the personal data is no longer necessary for the purposes for which it was collected or processed.</b></h5>
            <h5><b> (b) When the data subject withdraws their consent or there is no other legal basis for the processing. </b></h5>
            <h5><b> (c) When the data subject objects to the processing and there are no overriding legitimate reasons for it. </b></h5>
            <h5><b> (d) When the personal data has been processed unlawfully. </b></h5>
            <p>
                When processing is restricted, we will store your personal data without further processing it. 

                You have the right to receive the personal data you have provided to us in a structured, commonly used, and machine-readable format. You also have the right to transmit this data to another controller, if the processing is based on consent or a contract and is carried out by automated means. 

                You have the right to object.

                If we process your information in our legitimate interests, you have the right to object to such processing based on your specific circumstances.

                Right to rectification. You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. This right always applies;

                Right to erasure. This right is applicable when one of the following applies:
            </p>       
            <h5><b> 
                    (a)the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;</b></h5>
            <h5><b> (b) the data subject withdraws consent on which the processing is based or where there is no other legal ground for the processing;
 </b></h5>
            <h5><b> (c) the data subject objects to the processing and there are no overriding legitimate grounds for the processing;
 </b></h5>
            <h5><b> (d) the personal data have been unlawfully processed;
</b></h5>
        <p>
                            Right to restriction of processing. When processing is restricted, we will store your personal data, but not further process it;

                    Right to data portability. Is your right to receive the personal data which you have given to us, in a structured, commonly used and machine-readable format and the right to transmit that data to another controller from the current controller applicable if: The processing is based on consent or on a contract, and the processing is carried out by automated means;

                    Right to object. You have the right to object to processing (on grounds relating your particular situation) if we process your information in our legitimate interests. The only reasons we will be able to deny your request is if we can show compelling legitimate grounds for the processing, which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of a legal claims or if the processing of data is necessary for the performance of our contract with you;

                    Right to withdraw your consent at any time in case of any consent-based processing of your personal data without affecting the lawfulness of processing based on consent before your withdrawal; and

                    Right to lodge a complaint with a supervisory authority.


                    You may exercise your legal rights by contacting us via email to privacy@workw.com. This request must include at least the following information: the link to your portal and your administrator registration email address.
                    WorkWise takes and addresses its users' privacy concerns with utmost respect and attention. If you believe that there was an instance of non-compliance with this Privacy Policy with regard to your personal information or you have other related inquiries or concerns, you may write or contact WorkWise at email: privacy@workw.com.
                    In your message, please describe in as much detail as possible the nature of your inquiry or the ways in which you believe that the Privacy Policy has not been complied with. We will investigate your inquiry or complaint promptly.
                    Please note that if you provide WorkWise with inconsistent privacy preferences (for example, by indicating on one occasion that third parties may contact you with marketing offers and indicating on another occasion that they may not), WorkWise cannot guarantee that your most recent privacy preference will be honored.
                    You may exercise your legal rights by contacting our representative in European Union Area and Data Protection Officer via email to privacy@workw.com 
                    Cookies and Tracking pixels
                    Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.
                    A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
        </p>
            <p>We use the following cookies:</p>
            <ul>
                <li> <b> (a) Strictly necessary cookies.</b> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</li>
                <li><b> (b) Analytical or performance cookies.</b> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
                <li><b>(c) Functionality cookies. </b>These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).    </li>
            </ul>
        <p>
            Choices About cookie usage
            You can opt out from any not strictly necessary cookie installation on your first visit of our web sites.
            Additionally, you can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.
            You may setup your privacy preferences at your Facebook account privacy settings.
            You can opt-out of having your site activity available to Google Analytics by installing the Google Analytics opt-out browser add-on:
            You may set your browser to block cookies (consult the instructions for your particular browser on how to do this), although doing so may adversely affect your ability to perform certain transactions, use certain functionality and access certain content on our Website.
            WorkWise Integrations, Third Party Links And Extensions Privacy Statement

        </p>
        <h3> WorkWise Integrations, Third Party Links And Extensions Privacy Statement</h3>
        <p>
            <h5><b>1. Introduction. </b></h5> This Privacy Statement describes how the Personal Data is processed under WorkWise Third Party integrations, Third Party links and Non-WorkWise Applications.
            <h5><b>2. Definitions
                </b></h5> 
                    2.1 “WorkWise,” “we,” “us” “our” means WorkWise and its affiliates;<br/>
                    2.2 “Customer”, “You” means the organization that subscribes to the Services and through whom you, the end user of such Services, are provided access to the WorkWise Services;<br/>
                    2.3 “Customer Data” means electronic data and information submitted by or for Customer to the Services;<br/>
                    2.4 “Personal data” means any information relating to an identified or identifiable natural person;<br/>
                    2.5 “Services” means products and services that are ordered by our Customers and made available online by WorkWise as described in WorkWise's Terms of Service;<br/>
                    2.6 “Third Party Services” means services that are provided to the Customer by a third party and/or listed on an online directory, catalog or marketplace of applications that interoperate with WorkWise Services (“Non-Workwise Applications”).
            <h5><b>3. Limited Use disclosure. </b></h5> WorkWise use of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements.
            <p> 
                <b>4.What data is collected?</b> <br/>
                <b>4.1 Google Services Integrations</b>
                <p>If you connect your WorkWise account to apps or services provided by other parties, such as Google, certain Customer Data may be collected from your device by Google. This data may be collected automatically through the use of application programming interfaces such as the Google API Services or Gmail's API (OAuth), and may include:
                    </p>    
                <p>
                    
                    - The contents, metadata and related information of emails, Google calendar events and stored files when you choose to sync your App with Gmail, calendar and cloud storage services (Google Drive);<br/>
                    - Email addresses of your Inbox and Sent folders, when you choose to sync your WorkWise account and Gmail with your device’s address book; and<br/>
                    - Customer Data that you send from your WorkWise account to Google, or data that you request from any of the Google services, automatically or otherwise. Note that, as a security precaution, if you choose to connect your WorkWise account to apps or services provided by Google and you request data from any of the Google services, information that identifies you or your device may also be sent in order to authenticate the request. You can revoke access at any time.<br/>
                    - App's use of information received, and App's transfer of information to any other app, from Google APIs will adhere to Google's Limited Use Requirements.<br/>
                    - App’s use of information received from Gmail APIs will adhere to Google's Limited Use Requirements.<br/>

                </p>
                <b>4.2 WorkWise Two-way synchronization:</b>
                <p>     
                    - Two-way synchronization between WorkWise and Gmail means that even if you work with emails in Gmail, you’ll have copies of those in WorkWise CRM or Webmail. Also, you can send emails directly from inside WorkWise using Gmail when this integration is enabled. If you’d like more information about this integration email support@workw.com  <br/>
                    - Calendar Two-way synchronization between WorkWise and Google Calendars means all events created in Google Calendar are automatically synched with WorkWise calendars and vice-versa. If you’d like more information about this integration, please email support@workw.com  <br/>
                    - Google Docs: Google Docs can be used inside WorkWise account for viewing and editing documents online. If you’d like more information about this integration, please email support@workw.com 
                </p>
            </p>
             <h5>
             <b>5. How Your Personal Information Is Used via the Third party integrations:</b> <br/>
                </h5>
                5.1 Based on our legitimate interests or as necessary to perform our contract with you, to administer, improve and provide to you our extended integration Services upon your request. <br/>
                5.2 Legal obligation We may hold or request some of your information for complying with legal requirements and enforcing our website terms of use or otherwise protecting our legal rights. <br/>
                In order to comply with the United States and European Union export controls and economic sanctions laws and regulations, including but not limited to, the United States Export Administration Regulations (“EAR”), regulations promulgated by the US Department of the Treasury’s Office of Foreign Assets Control (“OFAC”), and regulations promulgated by the Council of the European Union (“EU”), in certain cases WorkWise might request you to provide your identity documentation. <br/>
                We also reserve the right to contact you if compelled to do so as part of a legal proceeding or if there has been a violation of any applicable licensing, warranty and purchase agreements. <br/>
                WorkWise is retaining these rights because in limited cases we feel that we may need to contact you as a matter of law or regarding matters that will be important to you. These rights do not allow us to contact you to market a new or existing service if you have asked us not to do so, and issuance of these types of communications is rare. <br/>
        </p>
        <h5><b>6. Disclosure of your personal data to Third Parties:</b> <br/> </h5>
        <p>WorkWise is not responsible for the data processing practices of any Third Party Providers. We encourage you to review your Third Party Providers’ respective privacy notices before enabling a Third Party Service integration.</p>
        <h5><b>7. Choices Available to You:</b> <br/> </h5>
        <p>You can always choose whether or not to disclose personally identifiable information and that choice will not prevent you from using our Services. Please note, however, if you should choose not to make available some of your personal information, we may not be able to provide you with some of the services described in this Integrations, Third Party links and extensions privacy statement.
 </p>
        <h5><b>8. Data Retention:</b> <br/> </h5>
        <p>WorkWise may retain your Personal Data for a period of time consistent with the original purpose of collection (see the section “4. How Your Personal Information Is Used via the Third party integrations”).
</p>
        <h5><b>9. Your rights: </b> <br/> </h5>
        <p>In European Union and some other jurisdictions you may have the following rights concerning our processing of your personal data:
Right to access. You have the right to ask us for copies of your personal information. This right always applies;
Right to rectification. You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. This right always applies;
</p>
<p>Right to erasure. This right is applicable when one of the following applies:
(a) the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;
(b) the data subject withdraws consent on which the processing is based or where there is no other legal ground for the processing;
(c) the data subject objects to the processing and there are no overriding legitimate grounds for the processing;
(d) the personal data have been unlawfully processed;
Right to restriction of processing. When processing is restricted, we will store your personal data, but not further process it;
Right to data portability. Is your right to receive the personal data which you have given to us, in a structured, commonly used and machine-readable format and the right to transmit that data to another controller from the current controller applicable if: The processing is based on consent or on a contract, and the processing is carried out by automated means;
Right to object. You have the right to object to processing (on grounds relating your particular situation) if we process your information in our legitimate interests. The only reasons we will be able to deny your request is if we can show compelling legitimate grounds for the processing, which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of a legal claims or if the processing of data is necessary for the performance of our contract with you;
Right to withdraw your consent at any time in case of any consent-based processing of your personal data without affecting the lawfulness of processing based on consent before your withdrawal; and
Right to lodge a complaint with a supervisory authority.
</p>
    </Modal>
    )
}