export const DialerTypeEnum = {
  None: 1,
  Error: 2,
  Dialer: 3,
  Calling: 4,
  CallLog: 5,
  Contacts: 6,
  Incoming: 7,
};

export const  BusinessNumberContactReferenceTypeEnum = 
{
    BusinessNumberContact: 1,
    LeadManagerDetailContact : 2,
    ProjectContact : 3,
    Employee : 4
}