import data from "@emoji-mart/data";
import { Picker } from "emoji-mart";
import { useEffect, useRef } from "react";

function EmojiPicker({ onSelect }) {
	const ref = useRef();
	useEffect(() => {
		new Picker({ data, ref, onEmojiSelect: onSelect });
	}, []);

	return <div  ref={ref} />;
}
export default EmojiPicker;

