import {createSlice,current} from "@reduxjs/toolkit";
import {addCommentAction ,getAllCommentAction} from "./action";
import {isEmptyObj, STRINGS} from "../../../../utils/base";
import { ReactionTypeEnum } from "../../reaction/utils/enums/reactionTypeEnum";


const initialState = {
    allComments : {},
    data:null
}
const commentSlice = createSlice({
    name : "comment",
    initialState,
    reducers : {
        removeComments : (state, {payload}) => {

            let updatedComments = {...state.allComments};

            if(state.allComments)
            {
                payload.forEach(({referenceId,id })=> {
                    updatedComments[referenceId] = updatedComments[referenceId].filter(x=> x.id !== id);
                   if(updatedComments[referenceId].length === 0)
                       delete updatedComments[referenceId]
                });

                state.allComments = updatedComments;
            }
        },
        handleCommentOutSocket : (state,{payload}) =>{
            var currentState = current(state);
            payload.unreadNotification = true;
            const {referenceId = STRINGS.DEFAULTS.guid,parentId = STRINGS.DEFAULTS.guid} = payload;
            if (isEmptyObj(state.allComments)) {

                state.allComments = {[referenceId]: [payload]};
                console.log(state.allComments,"COMMENT_SLICE_REDUCER");

            } else {
                if (typeof state.allComments[referenceId] === "undefined") {
                    const referenceIdKey = {
                        [referenceId]: [payload]
                    };
                    state.allComments = { ...state.allComments, ...referenceIdKey };
                } else {
                    state.allComments = {
                        ...state.allComments,
                        [referenceId]: [...state.allComments[referenceId], payload]
                    };
                }
            }
        },
        adCommentfromSocket : (state , {payload}) =>{
            console.log(payload,"==payload")
           try{

            const {module} = payload;
            const {comment} = payload;
               comment.unreadNotification = true;
            const {referenceId = STRINGS.DEFAULTS.guid,parentId = STRINGS.DEFAULTS.guid } = comment
            const currentState = current(state);
            const myData = currentState.data
            if(currentState.data!==null){
                const keys  =Object.keys(myData[module][referenceId])
                console.log("keysss",keys)
                keys.forEach((identifier) => {
                    if(parentId === STRINGS.DEFAULTS.guid)
                    {
                        const commentExistsInReplies = myData[module][referenceId][identifier].some(reply => reply.id === comment.id);
                        if(!commentExistsInReplies)
                        {
                            const comments =[comment, ...myData[module][referenceId][identifier] ]
                            state.data[module]= {
                                ...state.data[module],
                                [referenceId] :{
                                        [identifier] : comments
                                    }
                            }
                        }
               
                         
                    }
                    else
                    {
                        let comments = myData[module][referenceId][identifier]
                        const findIndex = comments.findIndex(x => x.id === parentId)

                        console.log(comments,"commentsssss",comment)
                        const findExisting = comments[findIndex].replies.some(x => x.id === comment.id)
                        if(!findExisting)
                        {
                            const singleComment = {
                                ...comments[findIndex],
                                replies:  [... comments[findIndex].replies , comment],
                                replyCount : comments[findIndex].replyCount  + 1
                            }
                            
                            const overallcomments = [...comments]; // Create a copy of the comments array
                            overallcomments[findIndex] = singleComment;
                                
                            console.log(comments,"==comments==")
                            state.data[module] = {
                                ...state.data[module],
                                [referenceId] : {
                                    [identifier] : overallcomments
                                }
                            }
                        }
                       
                        
                    }
                    
                })
            }
            
           }
           catch(e)
           {
            console.log(e,"errrrorrr")
           }
        },
        addReactionAtComment:(state,{payload})=>{
            console.log(payload,"payloadAtComments")
           const { parentId , reactionModule , reactionType , referenceId , baseId , identifier , module} = payload.payload
           console.log(parentId,module,"requestforpaload")
           if(parentId === STRINGS.DEFAULTS.guid)
           {
                const comments = state.data[module][baseId][identifier]
                const findIndex = comments.findIndex(x => x.id === referenceId)
                let count =  comments[findIndex].reactionCount
                let existingReactionType = comments[findIndex].myReaction
                if(existingReactionType === ReactionTypeEnum.NoReaction && reactionType !==ReactionTypeEnum.NoReaction)
                {
                    comments[findIndex].reactionCount = count+1;
                }
                else if (existingReactionType !== ReactionTypeEnum.NoReaction  && reactionType===ReactionTypeEnum.NoReaction)
                {
                    comments[findIndex].reactionCount = count-1 ;
                }
                comments[findIndex].myReaction = reactionType
                state.data[module]= {
                   ...state.data[module],
                   [referenceId] :{
                        [identifier]:
                    comments}
               } 
             
           }
           else
           {
            const comments = state.data[module][baseId][identifier]
            const findIndexofComments = comments.findIndex(x => x.id === parentId)
            const findIndexOfReply =  comments[findIndexofComments].replies.findIndex(x => x.id === referenceId)
            let count =   comments[findIndexofComments].replies[findIndexOfReply].reactionCount
            let existingReactionType =   comments[findIndexofComments].replies[findIndexOfReply].myReaction 
           
            if(existingReactionType === ReactionTypeEnum.NoReaction && reactionType !==ReactionTypeEnum.NoReaction)
            {
                comments[findIndexofComments].replies[findIndexOfReply].reactionCount= count+1;
            }
            else if (existingReactionType !== ReactionTypeEnum.NoReaction  && reactionType===ReactionTypeEnum.NoReaction)
            {
                comments[findIndexofComments].replies[findIndexOfReply].reactionCount= count-1 ;
            }

            comments[findIndexofComments].replies[findIndexOfReply].myReaction = reactionType

            state.data[module]= {
               ...state.data[module],
               [referenceId] :{
                [identifier]:
            comments}
           }
           }  
           
        },
        addReactionfromSocket:(state,{payload})=>{
            const {module , referenceId , reaction , parentReactionCount} = payload;
            const currentState = current(state);
            const myData = currentState.data
            if(currentState.data!==null){
                const keys  = Object.keys(myData[module][referenceId])
                keys.forEach((identifier)=>{
                    const comments = myData[module][referenceId][identifier]
                    const index = comments.findIndex(x => x.id === reaction.referenceId)

                    console.log(index,"indexOfComment")
                    if(index==-1)
                    {
                        let CommentIndex = -1;
                        let ChildIndex = -1;
                         comments.map((item , index) => {
                            ChildIndex =  item.replies.findIndex(x => x.id === referenceId)
                            if(ChildIndex!==-1)
                            {
                                CommentIndex = index;
                                return;
                            }
                        })

                        if(CommentIndex !==-1 && ChildIndex!==-1)
                        {
                            let count =   comments[CommentIndex].replies[ChildIndex].reactionCount
                            let existingReactionType = comments[CommentIndex].replies[ChildIndex].myReaction
                            if(existingReactionType === ReactionTypeEnum.NoReaction && reaction.reactionType !==ReactionTypeEnum.NoReaction)
                            {
                                comments[CommentIndex].replies[ChildIndex].reactionCount = count+1;
                            }
                            else if (existingReactionType !== ReactionTypeEnum.NoReaction  && reaction.reactionType===ReactionTypeEnum.NoReaction)
                            {
                                comments[CommentIndex].replies[ChildIndex].reactionCount = count-1 ;
                            }
                        }
                    }
                    else
                    {
                        const singleComment = {
                            ...comments[index],
                            reactionCount: parentReactionCount
                        }
                        const overallcomments = [...comments]; // Create a copy of the comments array
                            overallcomments[index] = singleComment;

                            state.data[module]= {
                                ...state.data[module],
                                [referenceId] :{
                                     [identifier]:
                                overallcomments}
                            } 

                    }

                })
            }
        },
        deleteComments:(state,{payload})=>{
            // const {module , referenceId , identifier } = payload
            // console.log(payload,"paylooooad")
            // if(state.data!==null)
            // delete state.data[module][referenceId][identifier]
        }
        
    },
    extraReducers: builder => {
        builder.addCase(addCommentAction.fulfilled,(state,actions)=>{
            var currentState = current(state);
            const {filter:{module, referenceId , parentId , identifier }} = actions.meta.arg
            const data = actions.payload ?? [];
            if(state.data === null)
            {
                state.data = {
                    ...state.data,
                    [module] : {
                        [referenceId] : {
                            [identifier] : [data]
                            }
                    }
                } 
            }
              else
            {

                if(state.data[module]===undefined || state.data[module]==="undefined" || state.data[module]===null)
                {
                    state.data = {
                        ...state.data,
                        [module] : {
                            [referenceId] : {
                                [identifier] : [data]
                                }
                        }
                    } 
                }
                else
                {
                    if(parentId === STRINGS.DEFAULTS.guid)
                    {
                        const commentExistsInReplies = state.data[module][referenceId][identifier].some(reply => reply.id === data.id);
                        if(!commentExistsInReplies)
                        {
                            let comments =[data, ...state.data[module][referenceId][identifier] ]
                            state.data[module]= {
                                ...state.data[module],
                                [referenceId] : {
                                    [identifier] : comments
                                }
                            } 
                        }
                       
                    }
                    else
                    {
                        const comments = state.data[module][referenceId][identifier]
                        const findIndex = comments.findIndex(x => x.id === parentId)
                        
                    
                        const findExisting = comments[findIndex].replies.some(x => x.id === data.id)
                        if(!findExisting)
                        {

                            comments[findIndex].replies = [... comments[findIndex].replies , data]
                            comments[findIndex].replyCount =comments[findIndex].replyCount  + 1
                            state.data[module] = {
                                ...state.data[module],
                                [referenceId] : {
                                    [identifier] : comments
                                }
                            }
                           
                        }
        
                       
                    }            
                }

                console.log(current(state),"Overall Data")
            } 
            
            // state.data = {
            //     ...state.data,
            //     [module]
            // }

            // console.log(currentState.allComments[referenceId],"COMMENT_SLICE");

            // if (isEmptyObj(state.allComments)) {
            //     state.allComments = { [referenceId]: [payload] };
            // } else {
            //     if (typeof state.allComments[referenceId] === "undefined") {
            //         const referenceIdKey = {
            //             [referenceId]: [payload]
            //         };
            //         state.allComments = { ...state.allComments, ...referenceIdKey };
            //     } else {
            //         state.allComments = {
            //             ...state.allComments,
            //             [referenceId]: [...state.allComments[referenceId], payload]
            //         };
            //     }
            // }

            //Backup for If we need refId -> parentId structure
            /*if(!(isEmptyObj(currentState.allComments)))
            {
                if(typeof currentState.allComments[referenceId] === "undefined"){
                    const referenceIdKey = {
                        [referenceId] : {
                            [parentId] : [payload]
                        }
                    }
                    state.allComments = {...state.allComments,...referenceIdKey};

                }
                else if(typeof currentState.allComments[referenceId][parentId] === "undefined"){
                    const parentIdKey ={
                            [parentId] : [payload]
                    }
                    state.allComments[referenceId] = {...state.allComments[referenceId], ...parentIdKey};
                }
                else {
                    state.allComments[referenceId][parentId].push(payload);
                }
            }
            else {
                state.allComments = {...state.allComments,[referenceId] : {[parentId] : [payload]}};
            }*/
        });
        builder.addCase(getAllCommentAction.fulfilled,(state,actions)=>{
            console.log(actions,"actions for GetAll")
            const {filter:{module, referenceId , parentId , pageNo } , identifier} = actions.meta.arg
            const data = actions.payload ?? [];
            if(state.data === null)
            {
                state.data = {
                    ...state.data,
                    [module] : {
                        [referenceId] : {
                            [identifier] :data
                        } 
                    }
                } 
            }
            else
            {

                if(state.data[module]===undefined || state.data[module]==="undefined" || state.data[module]===null)
                {
                    state.data = {
                        ...state.data,
                        [module] : {
                            [referenceId] : {
                                [identifier] :data
                            } 
                        }
                    } 
                }
                else
                {
                    if(parentId === STRINGS.DEFAULTS.guid)
                    {
                        if(pageNo > 1)
                        {
                                const existingComments = state.data[module][referenceId][identifier];
                                state.data[module]= {
                                    ...state.data[module],
                                    [referenceId] : {
                                        [identifier] : [...existingComments , ...data]
                                    } 
                                } 
                        }
                        else
                        {
                            state.data[module]= {
                                ...state.data[module],
                                [referenceId] : {
                                    [identifier] :data
                                } 
                            } 
                        }
                    }
                    else
                    {
                        const comments = state.data[module][referenceId][identifier]
                        const findIndex = comments.findIndex(x => x.id === parentId)
                        if(pageNo > 1)
                        {
                            const existingComments  = comments[findIndex].replies
                            comments[findIndex].replies = [...existingComments , ...data]
                        }
                        else
                        {
                            comments[findIndex].replies = data
                        }
        
                        state.data[module] = {
                            ...state.data[module],
                            [referenceId] : {
                                [identifier] :comments
                            } 
                        }
                    }            
                }
            }                
        })      
    }
});
export const {
    removeComments,
    handleCommentOutSocket,
    adCommentfromSocket,
    addReactionAtComment,
    addReactionfromSocket,
    deleteComments
} = commentSlice.actions;
export default commentSlice.reducer;