import { Modal } from 'antd';
import { MODAL_TYPES } from '../../utils/appraisalModalTypeEnum';
import QuestionList from './questionList';
import TaskList from './TaskList';
import BonusDetail from "../../../bonus/view/UI/detailCardItem";
import SalaryDetail from "../../../salary/view/Page/Detail/DetailItem/salaryDetail"

export function CustomModal({ type, open, setOpen, data }) {
  const getTitle = () => {
    switch (type) {
      case MODAL_TYPES.QUESTION:
        return "Question";
      case MODAL_TYPES.TASK:
        return "Task";
      case MODAL_TYPES.BONUS:
        return "Bonus Detail";
      case MODAL_TYPES.BONUS:
        return "Salary Detail";
      default:
        return "";
    }
  };

  const getContent = () => {
    switch (type) {
      case MODAL_TYPES.QUESTION:
        return <QuestionList questions={data} />;
      case MODAL_TYPES.TASK:
        return <TaskList tasks={data} />;
      case MODAL_TYPES.BONUS:
        return <BonusDetail id={data} key={data} />
      case MODAL_TYPES.SALARY:
        return <SalaryDetail id={data} key={data} />
      default:
        return null;
    }
  };

  return (
    <Modal
      title={getTitle()}
      centered
      open={open}
      onCancel={() => setOpen(false)}
      width={800}
      footer={null}
    >
      {getContent()}
    </Modal>
  );
}
