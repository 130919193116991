import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";

const initialState = {
    TopPosition: 0,
    ArrowDownFromInputField:false,
    setBacktoInputField:false,
    lengthOfcurrentSearch:0
  };
  
  const SuggestionBoxSlice = createSlice({
    name: "SuggestionBoxSlice",
    initialState,
    reducers: {
      setHeightOfSuggestionBox: (state, { payload }) => {
         state.TopPosition = payload;
      },
      setArrowDownFromInputFiled : (state, {payload}) =>{
         state.ArrowDownFromInputField = payload
      },
      setBacktoInputFieldFromList : (state, {payload}) =>{
        state.setBacktoInputField = payload
     },
     setLengthOfCurrentSerach : (state, {payload}) =>{

      console.log(payload,"payload")
      state.lengthOfcurrentSearch = payload
   }
    },
  });
  
  export const { setHeightOfSuggestionBox , setArrowDownFromInputFiled , setBacktoInputFieldFromList , setLengthOfCurrentSerach} = SuggestionBoxSlice.actions;
  export default SuggestionBoxSlice.reducer;
  