import React, { useContext } from "react";
import { Drawer } from "antd";
import CreateSchedule from "../createSchedule";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";

function UpdateSchedule({ eventDetail, handleEditSchedule, isOpen }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule } = dictionaryList[userLanguage];
  return (
    <>
      <Drawer
        title={schedule.UpdateSchedule}
        placement="right"
        onClose={handleEditSchedule}
        open={isOpen}
        width="768px"
        className="detailedViewComposer drawerSecondary"
      >
        <CreateSchedule
          scheduleDetail={eventDetail}
          isUpdate={true}
          onClose={() => handleEditSchedule()}
        />
      </Drawer>
    </>
  );
}

export default UpdateSchedule;
