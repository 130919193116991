import React, { useContext, useEffect, useState } from "react";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import PostTitleField from "../composer/views/PostTitleField";
import PostTagField from "../composer/views/PostTagField";
import PostPrivacyOptions from "../composer/views/PostPrivacyOptions";
import {
  getAllUser,
  onFeedCreateSubmitAction,
  sharePostOnFeed,
} from "../../store/actions";
import { useSelector } from "react-redux";
import { handleImportant } from "../../store/slice";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { CommentModuleEnum } from "../../../Comment/utils/enum/commentModuleEnum";
import { STRINGS } from "../../../../../utils/base";
import { PostPrivacyType } from "../../utils/constants";

const ShareForm = ({
  postRefType,
  postRefId = STRINGS.DEFAULTS.guid,
  aiInputRef,
  isOpen,
  post = null,
  sharePost,
}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const loading = useSelector((state) => state.feedSlice.postCompose.loading);
  const { newsFeed, sharedLabels } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const [formRef] = Form.useForm();
  const [privacyType, setprivacyType] = useState(PostPrivacyType.PUBLIC);

  useEffect(() => {
    dispatch(getAllUser({ search: "", pageNo: 1, pageSize: 20 }));
  }, []);

  return (
    <Form form={formRef}>
      <PostTitleField
        isOpen={isOpen}
        aiInputRef={aiInputRef}
        referenceId={postRefId}
        referenceType={postRefType}
        module={CommentModuleEnum.Feed}
        placeholder={newsFeed.Saysomethingaboutthis}
        autoSize={{ minRows: 1, maxRows: 4 }}
      />
      <div className="sharePost"> {post} </div>
      {/* <PostTagField referenceType={referenceType} referenceId={referenceId} /> */}
      <div className="submit-wrapper ">
        <PostPrivacyOptions
          onChangePrivacy={(privacy) => setprivacyType(privacy)}
        />
        <Button
          className="post-form !text-[14px]"
          type="submit"
          onClick={() => {
            sharePost(privacyType);
          }}
          loading={loading}
        >
          {sharedLabels.ShareNow}
        </Button>
      </div>
    </Form>
  );
};
export default ShareForm;
