import React, { useContext } from "react";
import CommentIcon from "../../../../../../../content/NewContent/NewsFeed/svg/comment.svg";
import Reactions from "../../../../../../sharedComponents/reactionBox/index";
import { useDispatch } from "react-redux";
import { readCounterFeed } from "../../../../store/slice";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { ReactionType } from "../../../../utils/constants";
import CmtWrapper from "../../../../../Comment/comments";

import { reactionColor, reactions } from "../../../reactions/reactions";
import { addReaction, getAllReactionsAction } from "../../../../store/actions";
import { useState } from "react";
import PostShareContent from "./PostShareContent";
import { handleItemDetailModal } from "../../../../../../../utils/Shared/store/slice";
import ItemDetailModal from "../../../../../../sharedComponents/ItemDetails";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CommentModuleEnum } from "../../../../../Comment/utils/enum/commentModuleEnum";
import NotificationBadge from "../../../../../../sharedComponents/Badge/NotificationBadge";
import {
  NotificationFeatureTypeEnum,
  NotificationTypesEnum,
} from "../../../../../notifiation/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import { ReactionTypeEnum } from "../../../../../reaction/utils/enums/reactionTypeEnum";
import { dictionaryList } from "../../../../../../../utils/localization/languages";

const PostFooter = ({
  commentCount,
  reactionCount,
  id,
  reactionModule,
  myReaction,
  isDetail = false,
  content = "",
  commentNotificationCount = 0,
  commentNotificationTypes,
  post = null,
  referenceType,
  referenceId,
  parentId,
  shareOnFeed = true,
  isSharable = true,
  modulePrivacyId,
  postRefType,
  postRefId,
}) => {
  const {
    postCompose: { reactionMembersData },
  } = useSelector((state) => state.feedSlice);
  let Direction = "ltr";

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed, sharedLabels } = dictionaryList[userLanguage];
  const { reactionDescription } = newsFeed;

  const handleOpenMembers = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(!visible);

    dispatch(
      getAllReactionsAction({
        id: id,
        module: reactionModule,
      })
    );
  };

  useEffect(() => {
    if (visible) {
      dispatch(handleItemDetailModal(true));
    }
  }, [visible]);

  const readCommentNotification = () => {
    if (commentNotificationCount > 0) {
      dispatch(
        readCounterFeed({
          postid: id,
          count: 0,
          type: NotificationTypesEnum.FeedComment,
        })
      );
      const payload = {
        filter: {
          types: commentNotificationTypes,
          featureType: NotificationFeatureTypeEnum.Feed,
          featureId: id,
        },
      };

      dispatch(readNotificationAction(payload));
    }
  };

  const handleNewReaction = (reactionType = ReactionTypeEnum.NoReaction) => {
    dispatch(
      addReaction({
        referenceId: id,
        reactionModule,
        reactionType: reactionType,
      })
    );
  };

  return (
    <>
      <div className="post-footer">
        <div className="post-count h-[20px]">
          <div className="reactionCount">
            <span>
              <img
                src={reactions[myReaction]}
                alt={reactionDescription[myReaction]}
              />
            </span>
            <span
              className="cursor-pointer"
              onClick={(e) => handleOpenMembers(e)}
            >
              {reactionCount}
            </span>
          </div>
          {commentCount > 0 && (
            <div
              className="commentCount"
              onClick={() => {
                setShowComments(true);
              }}
            >
              <div className="hover:underline cursor-pointer">
                {commentCount} &nbsp;
                {commentCount === 1
                  ? sharedLabels.Comment
                  : sharedLabels.Comments}
              </div>
            </div>
          )}
        </div>
        <div className="post-events">
          <div
            className={`btn ${myReaction === ReactionType.Like ? "on" : ""}`}
          >
            <Reactions
              direction={Direction}
              onUpdate={(e) => {
                handleNewReaction(e);
              }}
              onLikeBtnClick={() =>
                handleNewReaction(
                  myReaction === ReactionTypeEnum.NoReaction
                    ? ReactionTypeEnum.Like
                    : ReactionTypeEnum.NoReaction
                )
              }
            >
              <div className={`btn on`}>
                <span>
                  <img
                    className={
                      ReactionType.Like === myReaction ||
                      ReactionType.NoReaction === myReaction
                        ? "w-[20px] h-[30px]"
                        : " w-[30px] h-[30px]"
                    }
                    src={reactions[myReaction]}
                    alt={reactionDescription[myReaction]}
                  />
                </span>
                <div
                  className={`text-[${reactionColor[myReaction]}]`}
                  style={{ color: reactionColor[myReaction] }}
                >
                  {reactionDescription[myReaction]}
                </div>
              </div>
            </Reactions>
          </div>
          <div
            className="btn"
            onClick={() => {
              setShowComments(!showComments);
              readCommentNotification();
            }}
          >
            <div>
              <img src={CommentIcon} alt="commentIcon" />
            </div>
            <div>
              {sharedLabels.Comment}
              <NotificationBadge notificationCount={commentNotificationCount} />
            </div>
          </div>
          {isSharable && (
            <PostShareContent
              postId={id}
              content={content}
              post={post}
              referenceType={referenceType}
              referenceId={referenceId}
              parentId={parentId}
              shareOnFeed={shareOnFeed}
              modulePrivacyId = {modulePrivacyId}
              postRefId = {postRefId}
              postRefType = {postRefType}
            />
          )}
        </div>
      </div>
      {showComments && (
        <CmtWrapper key={id} referenceId={id} module={CommentModuleEnum.Feed} />
      )}
      <ItemDetailModal
        data={reactionMembersData}
        isDeleteDisabled={true}
        isReaction={true}
        addEnabled={false}
        addFunc={false}
        onDelete={false}
        isSearch={false}
        openModal={true}
        visible={reactionMembersData?.length > 0 ? visible : false}
        setVisible={(data) => setVisible(data)}
      />
    </>
  );
};

export default PostFooter;
