import React, { useEffect, useState, useContext, useCallback } from "react";
import VoucherFooter from "./components/VoucherFooter";
import CreateEntryHead from "./components/createEntryTableHead";
import CreateEntryItem from "./components/createEntryItem";
import { Button, DatePicker, Input, Select, message } from "antd";
import { voucherTypes, VOUCHER_ENUM } from "../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { getAllChartOfAccount } from "../../chartOfAccount/store/actions";
import { addVoucher, getTransactionDetailSuggestion } from "../store/actions";
import moment from "moment";
import CustomModal from "../../workboard/Modal/CustomModal";
import VoucherPrint from "./voucherPrintModal";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { STRINGS } from "../../../../utils/base";
import { TransactionReferenceTypeEnum } from "../enum/TransactionReferenceTypeEnum";
import { formatNumberWithCommas } from "../../../../utils/Shared/helper/formatNumberWithCommas";
import { calculateAllowance, calculateBasicSalary, calculateBonus, calculateDeduction, calculateLoanDispersable, calculateLoanPayment, calculateNetSalary, calculateOther, calculateRebate, calculateReimbursement, calculateTax } from "../../../features/payroll/view/UI/calculatedPayroll";
import { dictionaryList } from "../../../../utils/localization/languages";
import { voucherTypeEnum } from "../utils/voucherTypeEnum";

const defaultEntry = {
  accountId: "",
  chequeNo: "",
  narration: "",
  amount: 0,
  dr_cr: VOUCHER_ENUM.DR_CR.DR
}
const CreateEntryTable = ({
  defaultRows = 5,
  id = STRINGS.DEFAULTS.guid,
  referenceType = TransactionReferenceTypeEnum.General,
  onCancel = () => { },
  details = []
}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, voucher } = dictionaryList[userLanguage];
  const defaultForm = {
    voucherId: "",
    voucherDate: moment(),
    voucherType: voucherTypeEnum.GeneralVoucher,
  };
  const [form, setForm] = useState(defaultForm);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const allAccounts = useSelector(
    (state) => state.chartOfAccountsSlice.listData
  );
  const { success, voucherList, loader } = useSelector(
    (state) => state.voucherSlice
  );
  const dispatch = useDispatch();
  const totalCr = transactionData
    .filter((it) => it.dr_cr === VOUCHER_ENUM.DR_CR.CR)
    .reduce((a, b) => a + Number(b.amount), 0);

  const totalDr = transactionData
    .filter((it) => it.dr_cr === VOUCHER_ENUM.DR_CR.DR)
    .reduce((a, b) => a + Number(b.amount), 0);

  useEffect(() => {
    dispatch(getAllChartOfAccount()).then(resp => {
      if (referenceType !== TransactionReferenceTypeEnum.General) {
        dispatch(getTransactionDetailSuggestion({ id, referenceType })).then((res) => {

          const fetchedData = res.payload.map((x) => {
            return {
              ...x,
              amount: x.crAmount + x.dbAmount,
              dr_cr: x.crAmount != 0 ? VOUCHER_ENUM.DR_CR.CR : VOUCHER_ENUM.DR_CR.DR
            }
          });
          handleAddRow(1, fetchedData);

        })
      }
      else {
        handleAddRow(20);
      }
    });
  }, []);




  const handleAddRow = (defaultEntriesCount = 1, referenceData = []) => {
    const defaultEntriesRows = defaultEntriesCount > 0 ? Array(defaultEntriesCount).fill(defaultEntry) : [];

    referenceData = referenceData.concat(defaultEntriesRows);
    setTransactionData(transactionData.concat(referenceData));
  };

  const handleRemoveRow = (index) => {
    const updatedTransactionData = transactionData.filter((_, i) => i !== index);
    setTransactionData(updatedTransactionData)
  };

  const handleChange = (value, name, index) => {
    setTransactionData(prevTransactionData => {
      const updatedData = prevTransactionData.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [name]: value
          };
        }
        return item;
      });

      return updatedData;
    });
  };

  const createPayload = () => {
    let payload = {
      voucherDate: form.voucherDate,
      voucherType: parseInt(form.voucherType),
      amount: 12,
      referenceType: referenceType,
      referenceId: id,
      totalDr,
      totalCr,
      details: transactionData
        .filter((item) => item.accountId)
        .map((entry) => {
          return {
            accountId: entry.accountId,
            dbAmount: entry.dr_cr === VOUCHER_ENUM.DR_CR.DR ? parseFloat(entry.amount).toFixed(2) : 0,
            crAmount: entry.dr_cr === VOUCHER_ENUM.DR_CR.CR ? parseFloat(entry.amount).toFixed(2) : 0,
            narration: entry.naration,
            chequeNo: entry.chequeNo,
            narration: entry?.narration
          };
        }),
    };

    return payload;
  };

  const handleSubmit = () => {
    let obj = {}
    transactionData.filter((item) => item?.accountId).forEach((element, index) => {
      obj = element;
    });
    let dbAmount = obj.dr_cr === VOUCHER_ENUM.DR_CR.DR ? obj?.amount : 0
    let crAmount = obj.dr_cr === VOUCHER_ENUM.DR_CR.CR ? obj?.amount : 0
    if (crAmount === 0 && dbAmount === 0) {
      message.error("Dr & Cr cannot be zero!")
    }
    else {
      let payload = createPayload();
      dispatch(addVoucher(payload)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onCancel();
        }
      })
    }
  };

  const handlePrint = () => {
    let obj = {}
    transactionData.filter((item) => item?.accountId).forEach((element, index) => {
      obj = element;
    });
    let dbAmount = obj.dr_cr === VOUCHER_ENUM.DR_CR.DR ? obj?.amount : 0
    let crAmount = obj.dr_cr === VOUCHER_ENUM.DR_CR.CR ? obj?.amount : 0
    if (crAmount === 0 && dbAmount === 0) {
      message.error("Dr & Cr cannot be zero!")
    }
    else {
      let payload = createPayload();
      dispatch(addVoucher(payload)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setIsOpenModal(true);
        }
      })
    }
  }

  //copy cell content using cntrl+space
  const handleCntrlSpacePress = (index, fieldName) => {
    if (index === 0) return;
    setTransactionData(prevData => {
      const newData = [...prevData];
      const valueToCopy = newData[index - 1][fieldName];
      newData[index] = { ...newData[index], [fieldName]: valueToCopy };
      return newData;
    });
  }

  return (
    <div className="createEntryTable">
      <div className="flex justify-between items-center my-2 bg-white px-4 py-2 rounded-md">
        <div className="flex w-[320px] justify-between gap-2">
          <div>
            {Object.keys(voucher.voucherTypeList).length > 0 && (
              <Select placeholder={voucher.SelectVoucherType}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                size="large"
                allowClear
                onChange={(value) => setForm({ ...form, voucherType: value })}
                value={voucher.voucherTypeList && voucher.voucherTypeList[form.voucherType]}
              >
                {Object.entries(voucher.voucherTypeList).map(([key, value]) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <div>
            <DatePicker
              value={form.voucherDate}
              onChange={(value) => setForm({ ...form, voucherDate: value })}
            />
          </div>
        </div>

      </div>

      <div className="bg-white p-4 rounded-md overflow-x-auto">
        <table >
          <CreateEntryHead />
          <tbody>
            {transactionData?.map((item, ind) => {
              return (
                <CreateEntryItem
                  key={ind}
                  index={ind}
                  accounts={allAccounts}
                  handleChange={handleChange}
                  handleRemoveRow={handleRemoveRow}
                  value={item}
                  handleCntrlSpacePress={handleCntrlSpacePress}
                />
              );
            })}
          </tbody>
        </table>
        <div>
          <div
            className="defaultBtn addRowBtn cursor-pointer"
            onClick={() => handleAddRow()}
          >
            +
          </div>
        </div>

      </div>

      {referenceType === TransactionReferenceTypeEnum.Payroll && <>
        <div className="flex gap-1">

          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm ">
              {voucher.BasicSalary}
            </div>
            <Input
              disabled
              size="middle"
              value={formatNumberWithCommas(calculateBasicSalary(details))}
              className="!w-auto !text-black" />
          </div>


          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.LoanPayment}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateLoanPayment(details))}
              className="!w-auto !text-black" />
          </div>

          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.LoanDispersable}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateLoanDispersable(details))}
              className="!w-auto !text-black" />
          </div>
          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.Allowance}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateAllowance(details))}
              className="!w-auto !text-black" />
          </div>

          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.ExpenseReimbursement}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateReimbursement(details))}
              className="!w-auto !text-black" />
          </div>


          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.Deduction}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateDeduction(details))}
              className="!w-auto !text-black" />
          </div>




        </div>

        <div className="flex gap-1 mt-3">
          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.Tax}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateTax(details))}
              className="!w-auto !text-black" />
          </div>

          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.Rebate}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateRebate(details))}
              className="!w-auto !text-black" />
          </div>
          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {voucher.Bonus}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateBonus(details))}
              className="!w-auto !text-black" />
          </div>

          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {sharedLabels.Others}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateOther(details))}
              className="!w-auto !text-black" />
          </div>

          <div class="flex  flex-col gap-2">
            <div class="font-bold text-sm">
              {sharedLabels.Total}
            </div>
            <Input
              disabled
              value={formatNumberWithCommas(calculateNetSalary(details))}
              className="!w-auto !text-black"
            />
          </div>
        </div>
      </>
      }
      <div className="bg-white p-4 rounded-md flex justify-between w-full  mt-5 sticky bottom-2">
        <div className="flex items-center">
          <Button
            className="ThemeBtn mr-2"
            onClick={() => setTransactionData(Array(defaultRows).fill(defaultEntry))}
          >
            {sharedLabels.Clear}
          </Button>
          <Button
            className="ThemeBtn mr-2"
            onClick={handleSubmit}
            loading={loader}
          >
            {sharedLabels.Save}
          </Button>
          <Button
            className="ThemeBtn mr-2"
            onClick={() => {
              handlePrint();
            }}
          >
            {voucher.SavePrint}
          </Button>


        </div>


        <VoucherFooter dr={totalDr} cr={totalCr} />
        <CustomModal
          isModalVisible={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          width={"100vh"}
          title={voucher.VoucherDetail}
          footer={null}
          children={<VoucherPrint id={voucherList.at(-1)?.id} />}
          className={""}
        />


      </div>
    </div >
  );
};
export default CreateEntryTable;


