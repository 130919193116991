import React, { useEffect, useState } from "react";
import InvoiceAndPaymentDetailCard from "../InvoiceDetail";
import PaymentGatway from "../index";
import { useDispatch } from "react-redux";
import { GetInvoiceForPaymentAction } from "../../store/action";
import { InvoiceStatusEnumValue } from "../../enum/InvoiceStatusEnum";
import { STRINGS } from "../../../../../utils/base";
import {
  FormContainer,
  FormHeader,
} from "../../../../sharedComponents/Administration/StyledComponents/adminForm";
import "./style.css";
import PayBill from "./index";
import { useParams } from "react-router-dom";
import { GetBillForPaymentAction } from "../../../billing/store/actions";
import { useSelector } from "react-redux";
import { BillingStatusEnum } from "../../../billing/enum/billingStatusEnum";

const PayBillDedicatedPage = ({}) => {
  const [state, setState] = useState({
    billingData: null,
    paymentData: null,
    isInvoiceDataLoading: false,
    isShowInvoiceDetail: false,
  });
  const { id } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const userSlice = useSelector((state) => state.userSlice);
  const isLoggedIn = !!userSlice.token;
  const [authToken, setAuthToken] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    isAuthenticated &&
      (authToken !== null || isLoggedIn) &&
      dispatch(
        GetBillForPaymentAction({ token: id, authToken: authToken })
      ).then(({ payload }) => {
        setState({
          ...state,
          isInvoiceDataLoading: false,
          billingData: payload?.data.billing,
          paymentData: payload?.data.paymentDetails,
        });
      });
  }, [isAuthenticated, authToken]);

  useEffect(() => {
    isLoggedIn ? setIsAuthenticated(true) : setIsOpen(true);
  }, [isLoggedIn]);

  const handleBillPaid = () => {
    setState({
      ...state,
      billingData: {
        ...state.billingData,
        status: BillingStatusEnum.Paid,
      },
    });
  };

  return (
    <>
      {isAuthenticated &&
        !state.isInvoiceDataLoading &&
        !isOpen &&
        state.billingData !== null && (
          <div className="payBillMainDiv ">
            <FormContainer className="billPaymentFormContainerCustom">
              <div className="billPaymentContainer mt-4">
                <InvoiceAndPaymentDetailCard data={state.billingData} />
                {state?.status !== InvoiceStatusEnumValue.Completed && (
                  <PaymentGatway
                    data={state.paymentData}
                    billingId={state.billingData?.id}
                    token={id}
                    onBillPaid={handleBillPaid}
                  />
                )}
              </div>
            </FormContainer>
            <div className="flex justify-center">{STRINGS.COPY_RIGHTS}</div>
          </div>
        )}

      {isOpen && (
        <PayBill
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          setIsAuthenticated={setIsAuthenticated}
          setAuthToken={setAuthToken}
        />
      )}
    </>
  );
};
export default PayBillDedicatedPage;
