import React, { useState } from "react";
import { Popover } from "antd";
import "../../style/quickOption.css"
import { DOCUMENT_ENUM } from "../../utils/DocumentEnum";
import OptionContent from "./optionContent";
import menuIcon from "../../../../../content/NewContent/Documents/3dots.svg";


export default function QuickOptions({
                                        item,
                                        documentType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
                                    })
{
        const [state , setState] = useState({
                isOpen:false,

        })

        //functions
        const hide = () =>{
            setState({
                ...state,
                isOpen:false
            })
        }

        const handleOpenChange = (status) =>{
            setState({
                ...state,
                isOpen:status
            })
        }

        return(
            <>
                <div className="docsPopover">
                    <Popover
                        content={
                            <OptionContent
                                handleClose={hide}
                                item={item}
                                documentType={documentType}
                            />
                        }
                         title={null}
                         trigger="click"
                         placement="rightTop"
                         open={state.isOpen}
                         onOpenChange={handleOpenChange}
                         overlayClassName="docsPopover"
                    >
                         <div className="menuIcon mt-2">
                             <img src={menuIcon} />
                        </div>
                    </Popover>
                </div>
            </>
        )


}