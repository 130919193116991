import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import {
  getAllUserEmailConfigurations,
  getAllBussinessEmailConfiguration,
  addUserEmailConfiguration,
  updateUserEmailConfiguration,
  UpdateUserEmailConfigurationIsDefaultAction,
  RemoveUserEmailConfigurationAction,
} from "./actions.js";

const initialState = {
  userEmailConfigurations: [],
  bussinessEmailConfigurations: {},
  loadingData: false,
  loader: false,
  success: false,
  error: false,
  selectedUserEmailAccount: null,
};

const emailUserConfigurationSlice = createSlice({
  name: "emailUserConfiguration",
  initialState,
  reducers: {
    emailConfigurationDeleted: (state, { payload }) => {
      console.log(payload);
      //   state.emailConfigurations = state.emailConfigurations.filter(
      //     (e) => e.id !== payload.id
      //   );
    },
    setSelectedEmailAccount: (state, { payload }) => {
      state.selectedUserEmailAccount = state.userEmailConfigurations.find(
        (account) => account.id === payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllUserEmailConfigurations.fulfilled,
        (state, { payload }) => {
          console.log("payload get user", payload);
          state.loadingData = false;
          state.userEmailConfigurations = payload.data;
          if (payload.data.length > 0) {
            const defaultAccount = payload.data.find((item) => item.isDefault);
            state.selectedUserEmailAccount = defaultAccount || payload.data[0];
          }
        }
      )
      .addCase(
        getAllBussinessEmailConfiguration.fulfilled,
        (state, { payload }) => {
          console.log("payload get business", payload);
          state.bussinessEmailConfigurations = payload.data;
        }
      )
      .addCase(addUserEmailConfiguration.fulfilled, (state, { payload }) => {
        console.log(payload, "data");
        state.loader = false;
        payload?.data && state.userEmailConfigurations.push(payload.data);
      })

      .addCase(updateUserEmailConfiguration.fulfilled, (state, { payload }) => {
        console.log(payload, "data");
        console.log(state.userEmailConfigurations, "data");
        console.log(state.bussinessEmailConfigurations, "data");
        state.loader = false;
        state.userEmailConfigurations = state.userEmailConfigurations.map(
          (item) => (item.id === payload.data?.id ? payload.data : item)
        );
        state.bussinessEmailConfigurations = state.bussinessEmailConfigurations.map(
          (item) => (item.id === payload.data?.id ? payload.data : item)
        );
      })

      .addCase(
        UpdateUserEmailConfigurationIsDefaultAction.fulfilled,
        (state, action) => {
          let { id, isDefault } = action.meta?.arg;
          state.userEmailConfigurations = state.userEmailConfigurations.map(
            (item) =>
              item.id === id
                ? { ...item, isDefault: isDefault }
                : isDefault
                ? { ...item, isDefault: false }
                : item
          );
        }
      )

      .addCase(
        RemoveUserEmailConfigurationAction.fulfilled,
        (state, action) => {
          let  id  = action.meta?.arg;
          state.userEmailConfigurations = state.userEmailConfigurations.filter(
            (item) => item.id !== id
          );
        }
      )

      .addMatcher(isPending(getAllUserEmailConfigurations), (state) => {
        console.log("Pending getAllUserEmailConfigurations");
        state.loadingData = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(isPending(getAllBussinessEmailConfiguration), (state) => {
        console.log("Pending getAllBussinessEmailConfiguration");
      })
      .addMatcher(isPending(addUserEmailConfiguration), (state) => {
        console.log("Pending addUserEmailConfiguration");
        state.loader = true;
      })
      .addMatcher(isPending(updateUserEmailConfiguration), (state) => {
        console.log("Pending updateUserEmailConfiguration");
        state.loader = true;
      })
      .addMatcher(
        isRejected(
          getAllUserEmailConfigurations,
          getAllBussinessEmailConfiguration,
          addUserEmailConfiguration,
          updateUserEmailConfiguration
        ),
        (state) => {
          console.log("Rejected one of the actions");
          state.loader = false;
          state.loadingData = false;
          state.success = false;
          state.error = true;
        }
      );
  },
});

export const {
  emailConfigurationDeleted,
  setSelectedEmailAccount,
} = emailUserConfigurationSlice.actions;
export default emailUserConfigurationSlice.reducer;
