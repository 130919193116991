import MasterConfig from "../../../../utils/services/MasterConfig";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
const getAllEmployeeSalary_dto = (data) => {
  return {
    pageNo: data.pageNo ? data.pageNo : 0,
    pageSize: data.pageSize ? data.pageSize : 20,
    search: data.search ? data.search : "",
    approverStatus: data.approverStatus ? data.approverStatus : [],
    sortBy: data.sortBy ? data.sortBy : 1,
    filterType: data.filterType ? data.filterType : 0,
  };
};

export const getAllBonusService = (data) => {
  return MasterConfig.post(`api/Bonus/GetAllBonus`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addBonusService = (data) => {
  return MasterConfig.post(`api/Bonus/AddBonus`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetBonusByIdService = (id) => {
  return MasterConfig.get(`api/Bonus/GetBonusById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const cancelBonusService = (id) => {
  return MasterConfig.delete(`api/bonus/BonusCancel?bonusId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllEmployeeSalaryService = async (payload = {}) => {
  try {
    let request = getAllEmployeeSalary_dto(payload);
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/EmployeeSalary/GetAllEmployeeSalary`, request);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllBonuspagingService = (data) => {
  return MasterConfig.post(`api/Bonus/GetAllBonusPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
