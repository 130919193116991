import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { SvgSpinner } from "../../../../utils/base";
import { useDispatch } from "react-redux";
import { Form, Input } from "antd";
import { dictionaryList } from "../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import "../style/style.css";

export default function CheckoutForm({ handleAddorPayCard, isAddCard }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { administration } = dictionaryList[userLanguage];

  // ---- STATE ----
  const [state, setState] = useState({
    isSucceeded: false,
    error: null,
    isProcessing: false,
    isDisable: true,
    cardHolderName: "",
    isCardHolderName: false,
  });
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const handleChangeCardHolderName = (value) => {
    setState({
      ...state,
      error: null,
      cardHolderName: value,
      isCardHolderName: value.length > 0 ? true : false,
    });
  };

  const handleChange = async (event) => {
    if (event?.complete && !event.error) {
      setState({
        ...state,
        isDisable: false,
        error: null,
      });
    } else {
      setState({
        ...state,
        isDisable: true,
        error: event?.error?.message,
      });
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (state.isCardHolderName) {
      const { error: stripeError, token } = await stripe.createToken(
        elements.getElement(CardElement),
        {
          name: state.cardHolderName,
        }
      );

      if (stripeError) {
        setState({
          ...state,
          error: stripeError?.message,
        });
      } else {
        setState({
          ...state,
          isProcessing: true,
        });

        console.log(token, "==token==");
        handleAddorPayCard(token)
          .then((result) => {
            setState({
              ...state,
              isProcessing: false,
            });
          })
          .catch((error) => {
            setState({
              ...state,
              isProcessing: false,
            });
          });
      }
    } else {
      setState({
        ...state,
        error: "Card Holder Name is Required.",
      });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="">
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <Form.Item
        style={{ marginTop: "15px" }}
        rules={[
          {
            message: administration.PaymentCard.CardHolderNameRequired,
          },
        ]}
      >
        <Input
          size="large"
          placeholder={administration.PaymentCard.CardHolderName}
          onChange={(e) => {
            handleChangeCardHolderName(e.target.value);
          }}
        />
      </Form.Item>

      <div className="new_btn">
        <button
          id="submit"
          disabled={state.isProcessing || state.isDisable || state.isSucceeded}
          className={`new_button`}
        >
          {!state.isProcessing ? (
            isAddCard ? (
              administration.PaymentCard.AddCard
            ) : (
              administration.PaymentCard.PayInvoice
            )
          ) : (
            <SvgSpinner />
          )}
        </button>
      </div>
      {state.error && (
        <div className="card-error" role="alert">
          {state.error}
        </div>
      )}
    </form>
  );
}
