import React, { useContext, useEffect, useState } from "react";
import { Button, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { getTravelById } from "../../../../store/actions";
import RemarksApproval from "../../../../../../sharedComponents/AppComponents/Approvals/view";
import { ApprovalsModule } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import { readCountforTravel, removeIdentifier } from "../../../../store/slice";
import TravelDetailCard from "./detailCard";
import moment from "moment";
import TravelDetailSkeleton from "../../../UI/TravelDetailSkeleton";
import CardProfileTopView from "../../../UI/CardProfileTopView";
import { fileExtentionPreview } from "../../../../utils/fileExtentionHelper";
import "../../../../styles/travelComposer.css";
import CustomModal from "../../../../../workboard/Modal/CustomModal";
import AttachmentsCarrousel from "../../../UI/AttachmentsCarrousel/AttachmentsCarrousel";
import StatusTag from "../../../UI/statusTag";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { NOTIFICATION_ENUMS } from "../../../../../notifiation/utils/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import ReferenceTypeTag from "../../../UI/referenceTypeTag";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import ExpandableDescription from "../../../../../../sharedComponents/expandableDescription/expandableDescription";
import WhiteCard from "../../../../../projects/UI/WhiteCard";
import { Typography } from "antd";
import SharedExpenseList from "../../../../../expense/view/UI/sharedExpneseList";
import UpdateTravelContainer from "../../../UI/updateTravelContainer";
const { Title } = Typography;

function TravelDetail({ id, index }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { travel, sharedLabels } = dictionaryList[userLanguage];
  const { TravelAttachmentTypeOptions } = travel;
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const [state, setState] = useState({
    identifier: moment().unix(),
    travelDetail: {
      subject: "",
      cities: [],
      creator: {},
      description: "",
      approvers: [],
      agents: [],
      status: 1,
      referenceNo: 0,
      referenceType: 0,
      createDate: "",
      attachments: [],
      isLoading: true,
      header: "",
      agentStatus: 1,
      approverStatus: 1,
      notificationCount: 0,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
    agents: {
      isOpen: false,
      data: [],
    },
    attachmentsDetail: {
      isOpen: false,
      data: [],
    },
    attachmentType: [],
  });

  const { data } = useSelector((state) => state.travelSlice);
  let travelDetail = data !== null ? data[state.identifier] : [];
  travelDetail = travelDetail && travelDetail?.length > 0 && travelDetail[0];

  // handle Attachment
  const onHandleAttachment = (attachments) => {
    setState({
      ...state,
      attachmentsDetail: {
        ...state.attachmentsDetail,
        data: attachments,
        isOpen: true,
      },
    });
  };

  //Close Attachment Modal open
  const onCancelAttachmentModal = () => {
    setState({
      ...state,
      attachmentsDetail: {
        ...state.attachmentsDetail,
        isOpen: false,
      },
    });
  };

  useEffect(() => {
    // setState({
    //   ...state,
    //   travelDetail: { ...state.travelDetail, isLoading: true },
    // });
    id && dispatch(getTravelById({ id: id, identifier: state.identifier }));
  }, [id]);

  useEffect(() => {
    travelDetail &&
      setState({
        ...state,
        travelDetail: {
          ...state.travelDetail,
          ...travelDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: travelDetail?.approvers,
        },
        agents: {
          ...state.agents,
          data: travelDetail?.agents,
        },
      });
  }, [travelDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const {
    subject,
    cities,
    creator,
    description,
    approvers,
    agents,
    status,
    referenceNo,
    referenceType,
    createDate,
    attachments,
    isLoading,
    header,
    agentStatus,
    approverStatus,
    notificationCount,
    reference = null,
    referenceId,
  } = state.travelDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Travel,
          featureId: id,
        },
      };

      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforTravel(id));
      });
    }
  }, [notificationCount]);

  useEffect(() => {
    const typeMap = {};
    state.travelDetail.attachments.forEach((attachment) => {
      const type = attachment.type;
      if (!typeMap[type]) {
        typeMap[type] = [];
      }
      typeMap[type].push(attachment);

      const newArray = Object.keys(typeMap).map((type) => ({
        type: parseInt(type, 10),
        file: typeMap[type],
      }));
      setState({ ...state, attachmentType: newArray });
    });
  }, [state.travelDetail]);

  if (state.travelDetail.isLoading) return <Skeleton />;

  return (
    <div className="p-4 bg-white rounded" direction={"ltr"}>
      <div className="flex flex-col gap-4">
        {!travelDetail ? (
          <TravelDetailSkeleton />
        ) : (
          <>
            <CardProfileTopView
              profileImgSrc={creator?.image}
              createDate={createDate}
              isPublic={true}
              name={creator?.name}
              destination={
                travelDetail && creator?.designation
                  ? creator?.designation
                  : sharedLabels.NoDesignation
              }
              refNo={referenceNo}
              status={<StatusTag status={status}></StatusTag>}
              profileImgSize={40}
              showIcon={false}
              updateBtn={<UpdateTravelContainer traveldetail={travelDetail} />}
            />
            <div className="flex justify-between flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div className=" flex justify-between">
                  <span className="text-black font-bold ">{subject}</span>
                  <ReferenceTypeTag
                    referenceId={referenceId}
                    referenceType={referenceType}
                    reference={reference}
                  />
                </div>
                <span className="text-gray-500 font-bold ">
                  <span className="text-sm text-black font-normal">
                    {/* {description} */}
                    <ExpandableDescription
                      description={description}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                    />
                  </span>
                </span>
              </div>

              <div>
                <div
                  className={`flex overflow-x-auto gap-5 ${cities.length ===
                    1 && "justify-center"} `}
                >
                  {cities.map((detail, index) => (
                    <div className="">
                      <TravelDetailCard
                        travelDetail={detail}
                        isCloseable={false}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="">
                {state.attachmentType.length > 0 && (
                  <>
                    <hr className="border-t-[2px]" />

                    <h6 className="text-[16px] font-semibold py-2">
                      Attachments
                    </h6>
                    <div className="grid grid-cols-6 gap-1 items-center overflow-auto cursor-pointer">
                      <>
                        {state.attachmentType.map((item) => (
                          <div
                            className="image-container rounded-lg h-[130px] w-[100px]  border overflow-hidden"
                            key={item.type}
                          >
                            <div className="image-content ">
                              <div className="flex justify-center items-center font-bold mb-1">
                                {TravelAttachmentTypeOptions[item.type]}
                              </div>
                              <div
                                className="image-wrapper border h-[80px] w-[80px] ml-[1.2px]"
                                onClick={() => onHandleAttachment(item?.file)}
                              >
                                <img
                                  className=" object-cover h-full w-full"
                                  src={fileExtentionPreview(item.file[0].path)}
                                  alt={item.file[0].id}
                                  key={item.file[0].id}
                                />
                                <div className="image-text">
                                  {item.file.length > 1 &&
                                    `+${item.file.length - 1}`}
                                </div>
                              </div>
                              <a onClick={() => onHandleAttachment(item?.file)}>
                                <div className="absolute right-1 text-[10px] bg-white mt-0.5">
                                  {item.file.length > 1 &&
                                    `+${item.file.length - 1} more`}
                                </div>
                              </a>
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                )}
              </div>
              <div>
                <hr className="border-t-[2px]" />
                <div>
                  <UpdateTravelContainer traveldetail={travelDetail} />
                </div>
                <RemarksApproval
                  module={ApprovalsModule.TravelApproval}
                  reference={travelDetail?.id}
                  status={travelDetail.status}
                  data={approvers}
                  title={sharedLabels.Approvers}
                  itemCreator={creator}
                  referenceType={referenceType}
                  referenceTypeID={referenceId}
                />
                <RemarksApproval
                  module={ApprovalsModule.TravelAgent}
                  reference={travelDetail?.id}
                  status={travelDetail.status}
                  data={agents}
                  title={travel.Agents}
                  itemCreator={creator}
                  referenceType={referenceType}
                  referenceTypeID={referenceId}
                  itemDetail={state.travelDetail}
                />
                {/* Expnese - Travel */}
                <div>
                  <WhiteCard className={""}>
                    <Title level={5}>{"Expenses"} </Title>
                    <SharedExpenseList referenceId={id} />
                  </WhiteCard>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <CustomModal
        isModalVisible={state.attachmentsDetail.isOpen}
        footer={null}
        width={"80%"}
        className="attachmentModal"
        onCancel={onCancelAttachmentModal}
        children={
          <AttachmentsCarrousel
            attachments={state.attachmentsDetail.data}
            key={state.attachmentsDetail.data}
          />
        }
      />
    </div>
  );
}

export default TravelDetail;
