import MasterConfig from "../../../../utils/services/MasterConfig"


export const getAllWorkGatingCriteriaService = () => {
	return MasterConfig.get(`api/WipGettingCriteria/GetAllWipGettingCriteria`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};

export const addWorkGatingCriteriaService = args => {
	return MasterConfig.post(`api/WipGettingCriteria/AddWipGettingCriteria`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const updateWorkGatingCriteriaService = args => {
	return MasterConfig.put(`api/WipGettingCriteria/UpdateWipGettingCriteria`, args)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
export const removeWorkGatingCriteriaService = id => {
	return MasterConfig.delete(`api/WipGettingCriteria/RemoveWipGettingCriteria?id=${id}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			return err;
		});
};
