import React, { useEffect, useRef, useState } from 'react';
import { ContBody } from '../../AppComponents/MainFlexContainer';
import Spinner from '../../spinner/spinner';
import { Spin } from 'antd';

const ScrollPagination = ({
  currentPageNo = 1,
  currentRecordSize = 20,
  onLoadMore,
  children,
  isPagination = true, 
  isLoading = false,
  moveToTop = true,
  pageSize = 20
}) => {


  
  // Referene used for pagination
  const listInnerRef = useRef(); 

  // will has more data
  const hasMore = () =>{
        return currentRecordSize === pageSize
  }

  useEffect(()=>{
    listInnerRef.current.scrollTop = 0;
  },[moveToTop])

  // OnScroll 
  const onScroll = () => {
    if (listInnerRef.current) {
     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const atBottom = scrollTop + clientHeight + 2 >= scrollHeight;
     if (atBottom && isPagination && hasMore() && !isLoading)  {
        const nextPage = currentPageNo+1;
        onLoadMore(nextPage)
     }
   }
 };


  return (
    <>
      <ContBody  
          onScroll={onScroll}
          ref={listInnerRef}
      >
          {children}
      </ContBody>
    </>
  );
};
export default ScrollPagination;
