const ValidationResult = (valid, message = "") => ({valid, message})

function isValidTitle(newsFeed, title) {
    if (title.trim() === "")
        return ValidationResult(false, newsFeed?.Titlecantbeempty)
    return ValidationResult(true)
}

function areValidAttachments(newsFeed, attachments) {
    if (!attachments.length)
        return ValidationResult(false, newsFeed?.Attachmentscantbeempty)
    return ValidationResult(true)
}

function isValidPollOptionValue(newsFeed, value) {

    if (value.trim() === "")
        return ValidationResult(false, newsFeed?.Optioncantbeempty)
    return ValidationResult(true)
}

function ValidateDefaultPost(newsFeed, {title, attachments}) {
    const validTitle = isValidTitle(newsFeed, title)
    const validAttachments = areValidAttachments(newsFeed, attachments)
    const validationResult = {validTitle, validAttachments}
    return {
        valid: [validTitle, validAttachments].some((v) => v.valid),
        validationResult
    }
}

function ValidatePollPost(newsFeed,{ pollTitle, poll: {options}}) {
    const validTitle = isValidTitle(newsFeed, pollTitle)
    const validateOptions = {}
    options.forEach((option, index) => validateOptions['option_' + index] = isValidPollOptionValue(newsFeed, option.value))

    const validationResult = {validTitle, ...validateOptions}
    return {
        valid: Object.values(validationResult).every((v) => v.valid),
        validationResult
    }
}
export function replaceURL(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

const ValidateCreatePost = {ValidateDefaultPost, ValidatePollPost}
export default ValidateCreatePost