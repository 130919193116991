import { MemberRenderTypeEnum } from "./enum/enum";
import DocumentMemberList from "./view/documentMember/documentMemberList";

function MemberListContainer({
  type = MemberRenderTypeEnum.Default,
  data = [],
  onDelete = () => {},
}) {
  return (
    <>
      {/* {type === MemberRenderTypeEnum.DocumentMember && ( */}
      <DocumentMemberList data={data} onDelete={onDelete} />
      {/* )} */}
    </>
  );
}
export default MemberListContainer;
