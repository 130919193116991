import React, { useContext, useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { UpdateScheduleMemberMeetingTimeAction, updateScheduleMeetingTime } from "../store/action";
import { STRINGS } from "../../../../utils/base";
import moment from "moment";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";
import { useSelector } from "react-redux";

const ScheduleMeetingBtn = ({ data = {}, isActionEnabled = false }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule } = dictionaryList[userLanguage];
  const userId = useSelector((state) => state.userSlice.user.id);
  const {
    id = STRINGS.DEFAULTS.guid,
    startDate,
    endDate,
    startMeeting = null,
    endMeeting = null,
    members :[]
  } = data;

  const currentMember = data.members.find(item => item.memberId === userId)

  const [state, setState] = useState({
    startMeeting: currentMember?.startMeeting,
    endMeeting: currentMember?.endMeeting,
  });

  const [isJoinMeeting ,setisJoiningMeeting] = useState(false)
  const dispatch = useDispatch();


  const isWithinMeetingTime = () => {
    const currentDate = moment()
      .utc()
      .local()
      .format();
    const meetingStartTime = moment
      .utc(startDate)
      .local()
      .subtract(10, "minutes") // Subtract 10 minutes from the start time before 10 mints
      .format();
    const meetingEndTime = moment
      .utc(endDate)
      .local()
      .format();

    return currentDate >= meetingStartTime && currentDate <= meetingEndTime;
  };

  const isAfterScheduleStartTime = () =>{
    const currentDate = moment()
    .utc()
    .local()
    .format();
  const meetingStartTime = moment
    .utc(startDate)
    .local()
    .subtract(10, "minutes") // Subtract 10 minutes from the start time before 10 mints
    .format();

    return currentDate >= meetingStartTime

  }

  const handleClick = () => {
    const isStartTime = isJoinMeeting 
    let currentTime = moment()
      .utc()
      .local()
      .format();
    dispatch(UpdateScheduleMemberMeetingTimeAction({ id, isStartTime })).then(() => {
      if (isStartTime) {
        setState({
          ...state,
          startMeeting: currentTime,
          endMeeting:null
        });
      } else {
        setState({
          ...state,
          endMeeting: currentTime,
        });
      }
    });
  };

  useEffect(()=>{
    if(state.startMeeting === null || (state.endMeeting !== null && state.startMeeting !==null))
    {
      setisJoiningMeeting(true)
    }
    else if (isAfterScheduleStartTime() && state.startMeeting !==null && state.endMeeting === null)
    {
      setisJoiningMeeting(false)
    }


  },[state])


  return (
    isWithinMeetingTime() &&
    !data?.onVideoConference && (
      <>
        <Button
          type="primary"
          className="ThemeBtn"
          onClick={handleClick}
          style={{ display: "flex", alignItems: "center" }}
        >
          {isJoinMeeting ? "Join Meeting" : "Leave Meeting"}
        </Button>
      </>
    )
  );
};

export default ScheduleMeetingBtn;
