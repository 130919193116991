import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import { ResponseType } from "../../../../utils/api/ResponseResult";

import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  addCareerService,
  getAllCareerService,
  getAllCareerBYIdService,
  addCareerApplicantService,
  getAllCareerApplicantService,
  UpdateCareerApplicantStatusService,
  AddCareerApplicantOfferLetterService,
  GetAllCareerInterviewService,
  AddRequestForInterviewersToScheduleInterviewService,
  getAllCareerLinkByIdService,
  getAllCareerServicePaging,
  getAllInterviewsService,
  getInterviewDetailService,
  addCareerApplicantLinkService,
  addCareerInterviewRatingService,
  getCareerApplicanyByIdService,
  VerifyUserByTokenService,
  CareerApplicantOfferResponseService
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { handleOpenApplyComposer } from "./slice";

export const addCareer = createAsyncThunk(
  "Career/addCareer",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await addCareerService(data);
    switch (res.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: res.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(res.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Career Create Successfully",
            type: "success",
            duration: 2,
          })
        );
        return res.data;
      default:
        return;
    }
  }
);

export const addCareerApplicant = createAsyncThunk(
  "Career/addCareerApplicant",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await addCareerApplicantService(data);
    console.log(res, "FROM CAREER RESPONSE");

    switch (res.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: res.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(res.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Job Applied Successfully",
            type: "success",
            duration: 2,
          })
        );
        return res.data;
      default:
        return;
    }
  }
);

export const getAllCareerAction = createAsyncThunk(
  "careerslice/ getAllCareerAction",
  // async (request, { rejectWithValue }) => {
  //   console.log(request, "GET REQUEST career");
  //   const response = await getAllCareerService({ request });
  //   console.log(request, "GET REQUEST career 22");

  //   switch (response.type) {
  //     case ResponseType.ERROR:
  //       return rejectWithValue(response.errorMessage);
  //     case ResponseType.SUCCESS:
  //       return response.data;
  //     default:
  //       return;
  //   }
  // }
  async (
    { filter, pageSize, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllCareerServicePaging({ ...filter, pageSize });
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllCareerService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

// export const getAllCareerPaginAction = createAsyncThunk(
//   "careerslice/ getAllCareerPagingAction",
//   async (request, { rejectWithValue }) => {
//     console.log(request, "GET REQUEST career");
//     const response = await getAllCareerServicePaging({ request });
//     console.log(request, "GET REQUEST career 22");

//     switch (response.type) {
//       case ResponseType.ERROR:
//         return rejectWithValue(response.errorMessage);
//       case ResponseType.SUCCESS:
//         return response.data;
//       default:
//         return;
//     }
//   }
// );

export const getAllCareerApplicant = createAsyncThunk(
  "careerslice/ getAllCareerApplicant",
  async (request, { rejectWithValue }) => {
    const response = await getAllCareerApplicantService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getCareerByIdAction = createAsyncThunk(
  "careerslice/ getCareerByIdAction ",
  async ({ id }, { rejectWithValue }) => {
    console.log(id, "GET REQUEST career BY ID");
    const response = await getAllCareerBYIdService(id);
    console.log(response, "GET REQUEST career 22 BY ID");

    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getCareerLinkByIdAction = createAsyncThunk(
  "careerslice/ getCareerLinkByIdAction ",
  async (id, { rejectWithValue }) => {
    const response = await getAllCareerLinkByIdService(id);

    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);


export const UpdateCareerApplicantStatus = createAsyncThunk(
  "careerslice/ updateaCareerApplicationStatus",
  async (payload, { rejectWithValue }) => {
    const response = await UpdateCareerApplicantStatusService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const AddCareerApplicantOfferLetterAction = createAsyncThunk(
  "careerslice/ AddCareerApplicantOfferLetter",
  async (payload, { rejectWithValue }) => {
    const response = await AddCareerApplicantOfferLetterService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        message.error(response?.errorMessage);
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        message.success('Offer Sent successfully');
        return response.data;
      default:
        return;
    }
  }
);

export const GetAllCareerInterviewAction = createAsyncThunk(
  "careerslice/ GetAllCareerInterview",
  async (payload, { rejectWithValue }) => {
    const response = await GetAllCareerInterviewService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        message.error(response?.message);
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const AddRequestToScheduleInterview = createAsyncThunk(
  "Career/ AddRequestForInterviewersToScheduleInterview",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await AddRequestForInterviewersToScheduleInterviewService(data);
    switch (res.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: res.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(res.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Request Sent Successfully",
            type: "success",
            duration: 2,
          })
        );
        return res.data;
      default:
        return;
    }
  }
);

export const GetAllInterview = createAsyncThunk(
  "careerslice/ GetAllInterview",
  async (payload, { rejectWithValue }) => {
    const response = await getAllInterviewsService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        message.error(response?.message);
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getInterviewDetailAction = createAsyncThunk(
  "careerslice/getInterviewDetailAction",
  async (payload, { rejectWithValue }) => {
    const response = await getInterviewDetailService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        message.error(response?.message);
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const addCareerInterviewRatingAction = createAsyncThunk(
  "careerslice/addCareerInterviewRatingAction",
  async (values, { dispatch, rejectWithValue }) => {
    const response = await addCareerInterviewRatingService(values);
    switch (response.type) {
      case ResponseType.ERROR:
        message.error(response?.message);
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Rating Added Successfully!",
            type: "success",
            duration: 2,
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const addCareerAplicantLinkAction = createAsyncThunk(
  "Career/addCareerAplicantLinkAction",
  async (data, { dispatch, rejectWithValue }) => {
    const res = await addCareerApplicantLinkService(data);
    switch (res.type) {
      case ResponseType.ERROR:
        message.error(res.errorMessage)
        // dispatch(
        //   openNotification({
        //     message: res.errorMessage,
        //     type: "error",
        //   })
        // );
        return rejectWithValue(res.errorMessage);
      case ResponseType.SUCCESS:
        message.info("Application Submitted Successfully!")
        dispatch(handleOpenApplyComposer(false));
        // dispatch(
        //   openNotification({
        //     message: "Document Create Successfully",
        //     type: "success",
        //     duration: 2,
        //   })
        // );
        return res;
      default:
        return;
    }
  }
);
export const getCareerApplicantById = createAsyncThunk(
  "careerslice/getCareerApplicanyById",
  async (payload, { rejectWithValue }) => {
    const response = await getCareerApplicanyByIdService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        message.error(response?.message);
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);


export const VerifyUserByToken = createAsyncThunk(
  "api/VerifyUserByToken",
  async (token) => {
    const response = await VerifyUserByTokenService(token);   
    return response.data;
  }
);

  
export const CareerApplicantOfferResponse = createAsyncThunk(
  "api/CareerApplicantOfferResponse",
  async (payload) => {
    const response = await CareerApplicantOfferResponseService(payload);   
    return response.data;
  }
);
