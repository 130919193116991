export const RewardFilterSortEnum = {
    UpdateDateDesc: 1,
    UpdateDateAsc: 2,
    ReferenceNoDesc: 3,
    ReferenceNo: 4,
    StatusDesc: 5,
    Status: 6,
    CategoryDesc: 7,
    Category: 8,
    NameDesc: 9,
    Name: 10,
    CreateDateDesc: 11,
    CreateDateAsc: 12,
}


export const rewardTableSort = {
    referenceNo: { descend: RewardFilterSortEnum.ReferenceNoDesc, ascend: RewardFilterSortEnum.ReferenceNo },
    createDate: { descend: RewardFilterSortEnum.CreateDateDesc, ascend: RewardFilterSortEnum.CreateDateAsc },
    name: { descend: RewardFilterSortEnum.NameDesc, ascend: RewardFilterSortEnum.Name },
    category: { descend: RewardFilterSortEnum.CategoryDesc, ascend: RewardFilterSortEnum.Category },
}