import React, { useContext, useEffect, useState } from "react";
import { Drawer, Tag, Image, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import {
  ItemContent,
  ItemHeader,
} from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { leaveDictionaryList } from "../../localization";
import { GetLeaveById } from "../../store/actions";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { removeIdentifier } from "../../store/slice";
import Attachments from "../../../travel/view/UI/Attachments";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import leaveIcon from "../../../../../content/svg/menu/newNavBarIcon/Leaves.svg";
import { STRINGS } from "../../../../../utils/base";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { readCountforLeave } from "../../store/slice";
import { dictionaryList } from "../../../../../utils/localization/languages"
import ExpandableDescription from "../../../../sharedComponents/expandableDescription/expandableDescription";

export default function LeaveDetail({ id, index }) {
  const dispatch = useDispatch();


  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, leaves } = dictionaryList[userLanguage];

  const [isExpanded, setIsExpanded] = useState(false);

  const [state, setState] = useState({
    identifier: moment().unix(),
    LeavesDetail: {
      creator: {},
      startDate: "",
      createDate: "",
      endDate: "",
      description: "",
      image: leaveIcon,
      status: 1,
      referenceNo: 0,
      leaveTypeName: "",
      members: [],
      attachments: "",
      daysCount: 0,
      memberId: STRINGS.DEFAULTS.guid,
      isLoading: true,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });
  const { data, loadingData } = useSelector((state) => state.leaveSlice);
  let LeavesDetail = data !== null ? data[state.identifier] : [];
  LeavesDetail = LeavesDetail && LeavesDetail?.length > 0 && LeavesDetail[0];

  useEffect(() => {
    id &&
      dispatch(GetLeaveById({ id, identifier: state.identifier }))
  }, []);

  useEffect(() => {
    LeavesDetail &&
      setState({
        ...state,
        LeavesDetail: {
          ...state.LeavesDetail,
          ...LeavesDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: LeavesDetail?.approvers,
        },
      });
  }, [LeavesDetail]);

  const {
    creator,
    startDate,
    createDate,
    endDate,
    description,
    image = leaveIcon,
    status,
    referenceNo,
    leaveTypeName,
    members = [],
    memberId,
    approvers,
    attachments,
    daysCount,
    notificationCount
  } = state.LeavesDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Leave,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforLeave(id))
      })
    }
  }, [notificationCount])

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  //close Approvers Modal
  const onCloseApproversModal = (status) => {
    setState({
      ...state,
      approvers: {
        ...state.approvers,
        isOpen: status,
      },
    });
  };

  var a = moment(startDate);
  var b = moment(endDate);
  const days = b.diff(a, "days");

  if (loadingData) return <Skeleton />;

  return (
    <>

      {/* Detail Card */}
      <div className="detailedCard">
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              profileId={creator?.id}
              status={creator?.userActiveStatus}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator?.designation : ""}
                  time={moment
                    .utc(createDate)
                    .local()
                    .fromNow()}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
          </div>
        </ItemHeader>
        <ItemContent className="flex gap-5">
          <div className="description ">
            <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
          </div>
          <div className="!w-max m-4 ml-auto attachmentBox">
            <Attachments
              data={attachments}
              key={{ data: attachments }}
              toShow={1}
              onClick={() => { }}
              size={"60px"}
            />
          </div>
        </ItemContent>
        <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.startDate}
            </div>
            <div className="cardSection__body">
              {moment(startDate).format("ddd,MMM DD,YYYY")}
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.endDate}
            </div>
            <div className="cardSection__body">
              {moment(endDate).format("ddd,MMM DD,YYYY")}
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">{sharedLabels.days}</div>
            <div className="cardSection__body">{daysCount}</div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">{leaves.leavetype}</div>
            <div className="cardSection__body"> {leaveTypeName}</div>
          </div>
        </div>
        {/* Remarks Approval */}
        <RemarksApproval
          module={ApprovalsModule.LeaveApproval}
          status={status}
          reference={LeavesDetail?.id}
          data={approvers ? approvers : []}
          title={sharedLabels.approvers}
          notRequiredMemberId={[memberId]}
          itemCreator={creator}
        />
      </div>
    </>
  );
}
