import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { responseCode } from "../../../../services/enums/responseCode";
import {

	addWorkGatingCriteriaService,
	getAllWorkGatingCriteriaService,
	removeWorkGatingCriteriaService,
	updateWorkGatingCriteriaService,
} from "../services/service";


export const getAllWorkGatingCriteria = createAsyncThunk(
	"WorkGatingCriteria/getAllWorkGatingCriteria",
	async (args, { dispatch }) => {
		const res = await getAllWorkGatingCriteriaService();
		if (!res.responseCode) {
			responseMessage({
				dispatch: dispatch,
				type: responseMessageType.ApiFailure,
			});
		}
		return res;
	}
);

export const addWorkGatingCriteria = createAsyncThunk(
	"WorkGatingCriteria/addWorkGatingCriteria",
	async (args, { dispatch }) => {
		const res = await addWorkGatingCriteriaService(args);
		if (res.responseCode) {
			if (res.responseCode === responseCode.Success) {
				message.success("Question added successfully!")
				responseMessage({ dispatch, data: res });
				return res
			} else {
				message.error(res.message)
			}
		} else {
			message.error("Something went Wrong")
		}
	}
);

export const updateWorkGatingCriteria = createAsyncThunk(
	"WorkGatingCriteria/updateWorkGatingCriteria",
	async (args, { dispatch }) => {
		const res = await updateWorkGatingCriteriaService(args);
		if (res.responseCode) {
			if (res.responseCode === responseCode.Success) {
				message.success("Question updated successfully!")
				responseMessage({ dispatch, data: res });
				return res
			} else {
				message.error(res.message)
			}
		} else {
			message.error("Something went Wrong")
		}

		return res;
	}
);

export const removeWorkGatingCriteria = createAsyncThunk(
	"WorkGatingCriteria/removeWorkGatingCriteria",
	async (args, { dispatch }) => {
		const res = await removeWorkGatingCriteriaService(args.id);

		if (res.responseCode) {
			if (res.responseCode === responseCode.Success)
				message.success("Question removed successfully!")
			responseMessage({ dispatch, data: res });
		} else {
			responseMessage({
				dispatch: dispatch,
				type: responseMessageType.ApiFailure,
			});
		}

		return res;
	}
);
