import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
  addLeaveService,
  getAllLeaveService,
  GetLeaveByIdService,
  getAllLeavePagingService,
  GetLeaveTypeService,
  GetLeaveUserByIdService,
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

// export const getAllLeaves = createAsyncThunk(
//   "leaves/GetAllLeave",
//   async (data) => {
//     const response = await getAllLeaveService(data);

//     if (!response.responseCode) {
//       message.error("Something went wrong");
//     }
//     return response.data;
//   }
// );

// export const GetLeaveById = createAsyncThunk(
//   "Leave/GetLeaveById",
//   async (id) => {
//     console.log(id, "FROM ACTION");
//     const response = await GetLeaveByIdService(id);
//     return response.data;
//   }
// );

export const GetLeaveById = createAsyncThunk(
  "Leave/GetLeaveById",
  async ({ id }) => {
    const response = await GetLeaveByIdService(id);
    console.log("MY ID", id);
    return response.data;
  }
);

export const GetLeaveUserById = createAsyncThunk(
  "Leave/getUserLeave",
  async (id) => {
    console.log(id, "FROM ACTION");
    const response = await GetLeaveUserByIdService(id);
    return response.data;
  }
);

export const addLeave = createAsyncThunk(
  "leaves/addLeave",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addLeaveService(data);
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Leave Created successfuly");
      return res;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const GetLeaveTypeAction = createAsyncThunk(
  "Leave/GetLeaveUserById",
  async (args) => {
    console.log(args, "FROM ACTION");
    const response = await GetLeaveTypeService(args);
    return response.data;
  }
);
export const getAllLeaveAction = createAsyncThunk(
  "getAllLeavePaging",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllLeavePagingService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllLeaveService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

// export const getAllLeavePagingAction= createAsyncThunk(
//   "getAllLeavePaging",
//   async (data, { dispatch, getState, rejectWithValue }) => {
//     const res = await getAllLeavePagingService(data);
//     if (res.responseCode === responseCode.Success) {
//       return res;
//     } else {
//       message.error(res.data.message);
//       return rejectWithValue(res.data.message);
//     }
//   }
// );