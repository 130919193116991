import { Image } from "antd";

export function ImgContainer(image, maxHeight) {
  return (
    <Image
      src={image ?? ""}
      alt="Selected Image"
      style={{ maxHeight: maxHeight, width: "auto" }}
      className="w-fit"
    />
  );
}
