import { Button, DatePicker, Form, Input, message } from "antd";
import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { STRINGS } from "../../../../../../utils/base";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { TaskReferenceTypeEnum } from "../../../utils/TaskReferenceTypeEnum";
import TaskReferenceTypeComposer from "../../UI/composerUI/taskReferenceTypeComposer";
import TaskAssignMembersComposer from "../../UI/composerUI/taskAssignMembersComposer";
import { SelfTaskEnum } from "../../../utils/SelfTaskEnum";
import TaskPriorityComposer from "../../UI/composerUI/taskPriorityComposer";
import SingleUpload from "../../../../../sharedComponents/Upload/singleUpload";
import { convertMinutesMeetingsIntoTask } from "../../../../schedule/store/action";
import { addNewTask } from "../../../store/actions";
import moment from "moment";
import { taskDatesHandling } from "../../../utils/createTaskDatesHandling";
const { RangePicker } = DatePicker;

const CreateTask = ({
  onFormSubmit = () => { },
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = TaskReferenceTypeEnum.General,
  predecessor = "",
  parentId = STRINGS.DEFAULTS.guid,
  subject = "",
  minutesOfMeetingId = STRINGS.DEFAULTS.guid,
  mintueofMeetingPointId = STRINGS.DEFAULTS.guid,
  handleTaskCreated = () => { },
  members = [],
  taskDate = [moment(), moment().add(1, "day")],
}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    requestObj: {
      referenceId: referenceId,
      referenceType: referenceType,
      members: members,
      subject: subject,
      description: "",
      parentId: parentId,
      attachments: [],
      taskDate: taskDate,
    },
    isFormSubmitting: false,
  });

  const handleSubmit = (values) => {
    setState({
      ...state,
      isFormSubmitting: true,
    });

    const { subject, description, taskDate } = values;
    const {
      referenceType,
      referenceId = STRINGS.DEFAULTS.guid,
      members = [],
      priority,
      attachments = [],
      parentId = STRINGS.DEFAULTS.guid,
    } = state.requestObj;

    const { startDate, endDate } = taskDatesHandling(taskDate[0], taskDate[1]);

    const requestObj = {
      subject,
      description,
      referenceId,
      referenceType,
      members: members.map((member) => {
        return { memberId: member.id };
      }),
      priority,
      startDate,
      endDate,
      attachments,
      parentId,
    };

    // to Check if mintueOfMeeting has Id then : Task Compser called from Schedule - mintue of meeting
    if (minutesOfMeetingId !== STRINGS.DEFAULTS.guid) {
      // Call Action of ConvertMintPointIntoTask
      dispatch(
        convertMinutesMeetingsIntoTask({
          payload: {
            parameters: {
              minutesOfMeetingId,
              detailId: mintueofMeetingPointId,
            },
            requestBody: requestObj,
          },
          onSuccess: (response) => {
            handleTaskCreated(response);
            message.success(
               tasks.TaskCreatedSuccessfully,
            );
          },
        })
      ).then((response) => {
        setState({
          ...state,
          isFormSubmitting: false,
        });
        onFormSubmit(false);
        form.resetFields();
      });
    } else {
      dispatch(addNewTask(requestObj)).then((response) => {
        message.success(
           tasks.TaskCreatedSuccessfully,
        );
        setState({
          ...state,
          isFormSubmitting: false,
        });
        onFormSubmit(false, response);
        form.resetFields();
      });
    }
  };

  return (
    <>
      <Form
        className="task-composer"
        name="createTask"
        layout="vertical"
        initialValues={state.requestObj}
        onFinish={handleSubmit}
        form={form}
      >
        {predecessor !== "" && <div >
          <span className="parentTaskLable">{`${tasks.ParentTask}:  `}</span>
          <span className="!text-[17px] font-medium">{predecessor}</span>
        </div>}
        <Form.Item
          label={sharedLabels.subject}
          name="subject"
          rules={[
            {
              required: true,
              message: sharedLabels.SubjectRequired,
            },
          ]}
        >
          <Input placeholder={sharedLabels.WriteSubject} maxLength={100} />
        </Form.Item>

        <Form.Item
          label={sharedLabels.Description}
          name="description"
          rules={[
            {
              required: true,
              message: sharedLabels.enterDescription,
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={500}
            autoSize={{ minRows: 3, maxRows: 3 }}
            placeholder={sharedLabels.enterDescription}
          />
        </Form.Item>

        {referenceType === TaskReferenceTypeEnum.General && predecessor === "" && (
          <TaskReferenceTypeComposer
            onChangeCallback={(data) => {
              setState({
                ...state,
                requestObj: {
                  ...state.requestObj,
                  referenceId: data.referenceId,
                  referenceType: data.referenceType,
                },
              });
            }}
          />
        )}

        <TaskAssignMembersComposer
          members={members}
          referenceType={state?.requestObj?.referenceType}
          referenceId={state?.requestObj?.referenceId}
          selfTaskEnumType={SelfTaskEnum.AssignTask}
          onChangeCallback={(data) => {
            setState({
              ...state,
              requestObj: { ...state.requestObj, members: data },
            });
          }}
        />
        <TaskPriorityComposer
          onChangeCallback={(data) => {
            setState({
              ...state,
              requestObj: { ...state.requestObj, priority: data },
            });
          }}
        />

        <Form.Item
          label={tasks.TaskDate}
          name="taskDate"
          rules={[
            {
              required: true,
              message: sharedLabels.Pleaseselectdate,
            },
          ]}
        >
          <RangePicker
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={[sharedLabels.startDate, sharedLabels.endDate]}
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
            defaultValue={[moment(), moment().add(1, 'day')]}
            name="taskDate"
          />
        </Form.Item>

        <Form.Item label={sharedLabels.Attachments} name="" className="w-max">
          <SingleUpload
            handleImageUpload={(files) =>
              setState({
                ...state,
                requestObj: {
                  ...state.requestObj,
                  attachments: files.map((file) => ({
                    file: file.originFileObj,
                    id: STRINGS.DEFAULTS.guid,
                  })),
                },
              })
            }
            uploadText={sharedLabels.upload}
            multiple={true}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="ThemeBtn mt-2"
            block
            htmlType="submit"
            loading={state.isFormSubmitting}
            title={tasks.CreateTask}
          >
            {tasks.CreateTask}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateTask;
