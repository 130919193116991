import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import {

  addWorkGatingCriteria,
  getAllWorkGatingCriteria,
  updateWorkGatingCriteria,
} from "./actions.js";

const initialState = {
  questions: [],
  loadingData: false,
  loader: false,
  success: false,
  error: false,
};

const workGatingCriteriaSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    WorkGatingCriteriaDeleted: (state, { payload }) => {
      state.questions = state.questions.filter((e) => e.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkGatingCriteria.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.questions = payload.data;
      })
      .addCase(addWorkGatingCriteria.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.questions.push(payload.data);
      })
      .addCase(updateWorkGatingCriteria.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.questions = state.questions.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })
      .addMatcher(
        isPending(...[addWorkGatingCriteria, updateWorkGatingCriteria]),
        (state) => {
          state.loader = true;
          state.success = false;
          state.error = false;
        }
      )
      .addMatcher(isPending(...[getAllWorkGatingCriteria]), (state) => {
        state.loadingData = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(
        isRejected(
          ...[
            getAllWorkGatingCriteria,
            addWorkGatingCriteria,
            updateWorkGatingCriteria,
          ]
        ),
        (state) => {
          state.loader = false;
          state.loadingData = false;
          state.success = false;
          state.error = false;
        }
      );
  },
});

export const {
  WorkGatingCriteriaDeleted
} = workGatingCriteriaSlice.actions;
export default workGatingCriteriaSlice.reducer;
