import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import { getAllBusinessApprovalService } from "../services/service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllBusinessApproval = createAsyncThunk(
    "businessapproval/getAllBusinessApproval",
    async (data, { dispatch, getState, rejectWithValue }) => {
        const res = await getAllBusinessApprovalService(data);
        if (res.responseCode === responseCode.Success) {
            return res.data;
        } else {
            message.error(res.data.message);
            return rejectWithValue(res.data.message);
        }

    }
);
