const urdu = {
  employeesDictionary: {
    placeholders: {
      organization: "تنظیم",
      department: "شعبہ",
      subsidiary: "ماتحت ادارہ",
      subsidiaryOffice: "ماتحت دفتر",
      payroll: "پے رول ",
      fName: "پہلا نام درج کریں",
      lName: "آخری نام درج کریں",
      fatherName: "والد کا نام درج کریں",
      email: "ای میل درج کریں",
      pEmail: "ذاتی ای میل درج کریں",
      phNo: "فون نمبر درج کریں",
      rAddress: "رہائشی پتہ درج کریں",
      pAddress: "مستقل پتہ درج کریں",
      cnicNo: "CNIC نمبر درج کریں",
      searchToSelect: "منتخب کرنے کے لیے تلاش کریں",
      probPeriod: "پروبیشن کی مدت داخل کریں",
      noticePeriod: "نوٹس کی مدت درج کریں",
      selectDate: "تاریخ منتخب کریں",
      selectGender: "صنف منتخب کریں",
      selectMarital: "ازدواجی حیثیت کو منتخب کریں",
      selectShift: "شفٹ کو منتخب کریں",
      empNo: "ملازم نمبر درج کریں",
      empType: "ایمپلائیمنٹ کی قسم منتخب کریں",
      selectUserType: "صارف کی قسم منتخب کریں",
      selectAccessRole: "رسائی کا کردار منتخب کریں",
      degree: "ڈگری داخل کریں",
      institute: "انسٹی ٹیوٹ کا نام درج کریں",
      desc: "تفصیل درج کریں",
      tMarks: "کل نمبر درج کریں",
      oMarks: "حاصل کردہ نمبر درج کریں",
      sDate: "شروع تاریخ",
      eDate: "آخری تاریخ",

      position: "پوزیشن درج کریں",
      name: "نام درج کریں",
      address: "پتہ درج کریں",
      number: "نمبر درج کریں",
      selectRelation: "رشتہ منتخب کریں",
      bankName: "بینک کا نام درج کریں",
      accTitle: "اکاؤنٹ کا عنوان درج کریں",
      branchCode: "برانچ کوڈ درج کریں",
      accNo: "اکاؤنٹ نمبر درج کریں",
      iban: "iban نمبر درج کریں",
      sortCode: "ترتیب کوڈ درج کریں",
      selectCountry:"براہ کرم ملک منتخب کریں۔",
      selectDesignation:"براہ کرم اپنا عہدہ منتخب کریں۔",
    },
    EmployeeForm: {
      organization: "تنظیم",
      department: "شعبہ",
      subsidiary: "ماتحت ادارہ",
      subsidiaryOffice: "ماتحت دفتر",
      payroll: "پے رول ",
      UpdateBasicInfo: "بنیادی معلومات کو اپ ڈیٹ کریں۔",
      AddImage: "تصویر شامل کریں",
      FirstName: "پہلا نام",
      LastName: "آخری نام",
      FatherName: "والد کا نام",
      Email: "ای میل",
      PersonalEmail: "ذاتی ای میل",
      CNICNumber: "سی این آئی سی نمبر",
      rAddress: "رہائشی پتہ",
      pAddress: "مستقل پتہ",
      PhoneNumber: " فون نمبر",
      Designation: " عہدہ",
      Manager: " مینیجر",
      Grades: "درجات",
      Department: "شعبہ",
      Country: "ملک",
      City: "شہر",
      Languages: "زبانیں",
      ProbationPeriod: "ٹرائی مدت",
      NoticePeriod: "نوٹس کی مدت",
      DateOfBirth: "پیدائش کی تاریخ",
      DateOfJoining: "شمولیت کی تاریخ",
      Gender: "صنف",
      MaritalStatus: "ازدواجی حیثیت",
      OfficeShift: "آفس شفٹ",
      EmploymentType: "ملازمت کی قسم",
      UserType: "صارف کی قسم",
      AccessRole: "رسائی کا کردار",
      EmpNo: "ملازم کا نمبر",
    },
    EmergencyForm: {
      EmergencyInfo: "ایمرجنسی معلومات",
      Name: "نام",
      Address: "پتہ",
      Number: "نمبر",
      Relation: "رشتہ",
      AddMoreEmergency: "مزید ایمرجنسی شامل کریں",
      AddEmergency: "ایمرجنسی شامل کریں",
    },
    EducationForm: {
      BasicInfo: "بنیادی معلومات",
      AddEducation: "تعلیم شامل کریں۔",
      UpdateEducation: "تعلیم کو اپ ڈیٹ کریں۔",
      EducationInfo: "تعلیمی معلومات",
      Degree: "ڈگری",
      Institute: "انسٹی ٹیوٹ",
      StartEndDate: "شروع/اختتام کی تاریخ",
      StartDate: "شروع کرنے کی تاریخ",
      Description: "تفصیل",
      TotalMarks: "کل مارکس",
      ObtainedMarks: "مارکس حاصل کئے",
      Present: "موجودہ",
      Attachments: "ملحقات",
      AddEducation: "مزید تعلیم شامل کریں",
      City: "شہر",
      Upload: "اپ لوڈ",
    },
    WorkExperienceForm: {
      organization: "تنظیم",
      WorkExperienceInfo: "کام کے تجربے کی معلومات",
      Employer: "آجر",
      Position: "پوزیشن",
      Department: "محکمہ",
      StartEndDate: "شروع/اختتام کی تاریخ",
      StartDate: "شروع کرنے کی تاریخ",
      Present: "موجودہ",
      AddMoreExperience: "مزید تجربہ شامل کریں",
      AddExperience: "تجربہ شامل کریں",
      EmploymentType: "ملازمت کی قسم",
      City: "شہر",
      UpdateExperience: "اپ ڈیٹ تجربہ",
    },
    UserForm: {
      UserRights: "صارف کے حقوق",
      UserType: "صارف کی قسم",
      UserRole: "صارف کا کردار",
    },
    BankForm: {
      updateBank: "بینک کو اپ ڈیٹ کریں۔",
      BankInfo: "بینک کی معلومات",
      BankName: "بینک کا نام",
      AccountTitle: "اکاؤنٹ ٹائٹل",
      BranchCode: "برانچ کوڈ",
      AccountNumber: "اکاؤنٹ نمبر",
      IBAN: "IBAN",
      SortCode: "سانٹ کوڈ",
      City: "شہر",
      Country: "ملک",
      AddMoreBank: "مزید بینک شامل کریں",
      AddBank: "بینک شامل کریں",
    },
    EmployeeList: {
      number: "نمبر",
      email: "ای میل",
      designation: "عہدہ",
    },
    tables: {
			Id:"آئی ڈی",
      Name:"نام",
      Email:"ای میل",
      Grade:"گریڈ",
      Designation:"عہدہ",
		},
    AddEmployee: "ملازم شامل کریں۔",
  },
  /*** Define language write side ***/
  Direction: "rtl",
};
export default urdu;
