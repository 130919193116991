import React, { useContext, useEffect, useRef, useState } from 'react';
import { TreeSelect } from 'antd';
import { LanguageChangeContext } from '../../../utils/localization/localContext/LocalContext';
import { dictionaryList } from '../../../utils/localization/languages';
import { STRINGS } from '../../../utils/base';

const CustomSelectAccount = ({ data = [], value, onChange = () => { }, index = 0, handleCntrlSpacePress = () => { }, bordered = false, required = false }) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, Direction, voucher } = dictionaryList[userLanguage];
    const [state, setState] = useState({
        search: ""
    });
    const treeSelectRef = useRef();

    const buildTree = (items) => {
        let tempItems = items.map(item => ({ ...item, children: [] }));
        const itemMap = {};

        tempItems.forEach(item => {
            itemMap[item.id] = item;
        });

        const tree = [];
        tempItems.forEach(item => {
            if (item.parentId === STRINGS.DEFAULTS.guid) {
                tree.push(item);
            } else if (itemMap[item.parentId]) {
                itemMap[item.parentId].children.push(item);
            }
        });

        return tree;
    };

    const formatTreeData = (items) => {
        return items.map(item => {
            const hasChildren = item.children && item.children.length > 0;
            return {
                title: item.name,
                value: item.id,
                key: item.id,
                disabled: hasChildren,
                children: item.children && item.children.length > 0 ? formatTreeData(item.children) : [],
            }
        });
    };

    const treeData = formatTreeData(buildTree(data));

    const searchHandler = (value) => {
        setState({
            ...state,
            search: value
        });
    };

    // Copy cell content using ctrl+space
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.keyCode === 32) {
                handleCntrlSpacePress(index, "accountId");
            }
        };

        const current = treeSelectRef.current;
        if (current) {
            current.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (current) {
                current.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [index, handleCntrlSpacePress]);

    const treeSelectStatus = required && !value ? 'error' : '';

    return (
        <div ref={treeSelectRef}>
            <TreeSelect
                showSearch
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    minWidth: 300,
                }}
                placeholder="Select Account"
                dropdownMatchSelectWidth={false}
                bordered={bordered}
                placement="bottomLeft"
                allowClear
                treeDefaultExpandAll
                treeLine
                searchValue={state.search}
                onSearch={searchHandler}
                filterTreeNode={(search, item) => item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                style={{ width: "100%" }}
                treeData={treeData}
                value={value}
                onChange={onChange}
                status={treeSelectStatus}
            />
        </div>
    );
};

export default CustomSelectAccount;
