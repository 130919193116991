import { STRINGS } from "../../../../../../../utils/base";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { CommentDictionary } from "../../../../localization";
import { useSelector } from "react-redux";

function UserCommentFooterReply({
  replyCount = 0,
  id = STRINGS.DEFAULTS.guid,
  parentId = STRINGS.DEFAULTS.guid,
  referenceId = STRINGS.DEFAULTS.guid,
  onClick = () => {},
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { Reply, Direction } = CommentDictionary[userLanguage];
  const [state, setState] = useState({
    replyCount,
  });

  const handleOnClick = () => {
    //const toggleValue = !state.toggleOnClick;
    onClick();
    //setState({...state , toggleOnClick:toggleValue})
  };
  return (
    <>
      <div className="flex justify-between gap-1 btn on ml-[5px] text-[13px] font-light cursor-pointer  ">
        <div onClick={onClick}>{Reply}</div>
        <span className="cursor-pointer">{replyCount}</span>
      </div>
    </>
  );
}

export default UserCommentFooterReply;
