import CommentAvatar from "../../commentAvatar";
import React from "react";
import {
  getRelativeDateAndTime,
  renderTitleWithMentions,
} from "../../../../../../utils/base";
import '../../../styles/style.css'
function SystemCommentItem({
  creator,
  comment = "",
  createDate,
  mentions = [],
  type
}) {
  const { name = "", image = "" } = creator || {};
  
  return (
    <>
    <div className="SystemComment">
    <tr style={{width:"100%"}} className="flex justify-between items-center">
    
    <div className="flex justify-start items-center">
      <td>
      <div className="commentName">
          <CommentAvatar image={image} name={name} />
          <div className="nameText">{name}</div>
      </div>
      </td>
      <td>  
          <span
            className="text-[12px]"
            dangerouslySetInnerHTML={{
              __html: renderTitleWithMentions(comment, mentions),
            }}
          ></span>
      </td>
      </div>
      <td>
      <div className="text-[9px] font-light text-slate-400 m-2">
          {getRelativeDateAndTime(createDate)}
        </div>
      </td>
    </tr>
    </div>
      {/* <div className="SystemComment">
        <div className="commentName">
          <CommentAvatar image={image} name={name} />
          <div className="nameText">{name}</div>

          <span
            className="text-[12px]"
            dangerouslySetInnerHTML={{
              __html: renderTitleWithMentions(comment, mentions),
            }}
          ></span>
        </div>
        <div className="text-[9px] font-light text-slate-400 m-2">
          {getRelativeDateAndTime(createDate)}
        </div>
      </div> */}
    </>
  );
}
export default SystemCommentItem;
