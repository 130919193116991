import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { getAllNotification, getAllNotificationCount ,readNotificationAction ,markAllReadAction} from "./action";

const initialState = {
  notificationList: [],
  loader: false,
  notificationFeatureTypes: {},
  newNotificationItem : null ,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    handleUpdateNotificationCount: (state, { payload }) => {
      let notifiationCountObj = {
        ...state.notificationFeatureTypes
      };

      payload.forEach((item) => {
        notifiationCountObj[item.type] = item.count
      })
      state.notificationFeatureTypes = notifiationCountObj;
    },
    updateNotificationCount: (state, { payload }) => {
      let {notificationFeatureTypes } = payload
      let notifiationCountObj = {
        ...state.notificationFeatureTypes
      };
      notificationFeatureTypes.forEach(key => {
        notifiationCountObj[key] = (notifiationCountObj[key] || 0) + 1;
      });

      state.notificationFeatureTypes = notifiationCountObj;
      state.newNotificationItem = payload;

    },

  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotification.fulfilled, (state, action) => {
      state.loader = false;
      state.notificationList =
        action?.meta?.arg?.pageNo > 1
          ? [...state.notificationList, ...action.payload]
          : action.payload;
    });
    builder.addCase(markAllReadAction.fulfilled, (state, action) => {
      
      const newObject = {}
      for(const key in state.notificationFeatureTypes)
      {
        newObject[key] = 0
      }

      state.notificationFeatureTypes = newObject
    });
    builder.addCase(readNotificationAction.fulfilled, (state, action) => {
      
      console.log(action,"===actions==")
      const {meta : {arg} , payload} = action
      const {filter} = arg

      // Create an object to store the counts of each type
      const typeCounts = {};

      payload.forEach((item) => {
        const { notificationFeatureTypes } = item;
        notificationFeatureTypes.forEach((type) => {
          typeCounts[type] = (typeCounts[type] || 0) + 1;
        });
      });

      for (const type in typeCounts) {
        if (state.notificationFeatureTypes[type]) {
          state.notificationFeatureTypes[type] -= typeCounts[type];
        }
      }
    });
    builder.addCase(getAllNotificationCount.fulfilled, (state, { payload }) => {
      let notifiationCountObj = {};

      payload.forEach((item) => {
        notifiationCountObj[item.type] = item.count
      })
      state.notificationFeatureTypes = notifiationCountObj;
    });
  },
});

export const { handleOpenComposer, handleUpdateNotificationCount , updateNotificationCount} = notificationSlice.actions;
export default notificationSlice.reducer;
