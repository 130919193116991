import React from "react";
// import { Tag } from "antd";
import moment from "moment";
import { ScheduleTypeEnum } from "../enum/enum";
import plane from "../../../../content/NewContent/Schedule/plane.svg";
import calendar from "../../../../content/NewContent/Schedule/calendar.svg";
import task from "../../../../content/NewContent/Schedule/task.svg";
// import "../styles/event.css";
function Event({ data, handleScheduleDetailComposer, showTag = false , userColor = ""}) {
  const startDate = moment(data?.startDate).format("DD MMM");
  const endDate = moment(data?.startDate).format("DD MMM");
  const startTime = moment(data?.startDate).format("hh:mm A");
  const endTime = moment(data?.endDate).format("hh:mm A");

  return (
    <div
      className={`event ${!userColor && "hover:!border-primary-color"} cursor-pointer transition-all w-full`}
      style={{
        ...(userColor ? { border: `3px solid ${userColor}` } : {}),
      }}
      onClick={() => handleScheduleDetailComposer(data)}
    >
      <div className="left">
        {startDate === endDate && startTime === endTime ? (
          <>
            {/* startdate, enddate and startime and endTime are same */}
            <p className="text-[12px]">{startDate}</p>
            <div className="text-gray-900 font-semibold	 text-[10px]">{`${startTime}-${endTime}`}</div>
          </>
        ) : startDate === endDate && startTime !== endTime ? (
          <>
            {/* startdate, enddate but startime and endTime are not same */}{" "}
            <p className="text-[12px]">{startDate}</p>
            <div className="text-gray-900 font-semibold	 text-[10px]">{`${startTime}-${endTime}`}</div>
          </>
        ) : (
          <>
            {" "}
            {/* startdate, enddate and startime and endTime are  same */}
            <p className="text-[12px]">{`${startDate}-${startTime}`}</p>
            <div className="text-[10px] text-gray-900 font-semibold	">{`${endDate}-${endTime}`}</div>
          </>
        )}
      </div>

      <div className="right w-full overflow-auto">
        <div className="flex justify-between items-center">
          <div className="w-[50%] flex flex-col">
            <p className="!text-primary-color text-ellipsis overflow-hidden inline-block w-full whitespace-nowrap">
              {data?.subject}
            </p>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: data?.description,
              }}
              className="description_dangerously text-ellipsis overflow-hidden inline-block w-full"
            /> */}
          </div>
          {showTag && (
            <img
              src={
                data?.scheduleType === ScheduleTypeEnum.Task
                  ? task
                  : data?.scheduleType === ScheduleTypeEnum.Travel
                  ? plane
                  : calendar
              }
              alt="icon"
              className="h-[30px]"
            />
          )}
          {/* {showTag && (data?.scheduleType === ScheduleTypeEnum.Task || data?.scheduleType === ScheduleTypeEnum.Travel) && (
						<Tag
							color={
								data?.scheduleType === ScheduleTypeEnum.Task
									? "#800080"
									: data?.scheduleType === ScheduleTypeEnum.Travel
									? "#ff0000"
									: ""
							}
							className={`!p-1 !text-[12px] !text-white`}
						>
							{data?.scheduleType === ScheduleTypeEnum.Task ? "Task" : "Travel"}
						</Tag>
					)} */}
        </div>
      </div>
    </div>
  );
}

export default Event;
