import { Button, Tooltip } from "antd";
import { React, useContext, useState } from "react";
import CreateTask from "../../Page/Composer/createTask";
import SideDrawer from "../../../../../sharedComponents/Drawer/SideDrawer";
import { PlusOutlined } from "@ant-design/icons";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { STRINGS } from "../../../../../../utils/base";
import "../../../style/task.css";
const AddSubTask = ({ addSubTask  , isGoal = false}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks } = dictionaryList[userLanguage];
  return (
    <>
      <Button
        className="addSubTaskBtn mt-4"
        icon={<PlusOutlined />}
        onClick={addSubTask}
      >
        <span className="!ml-[2px]"> {isGoal ? tasks.AddGoal : tasks.Addsubtask}</span>
      </Button>
    </>
  );
};

export default AddSubTask;
