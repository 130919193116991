import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import { message } from "antd";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { addHolidayService, getAllHolidayService } from "../service/service";

export const addHoliday = createAsyncThunk(
    'Holiday/addHoliday',
    async (args, { dispatch }) => {
      const res = await addHolidayService(args);
  
      if (res.responseCode) {
        if (res.responseCode === responseCode.Success) {
          message.success('Holiday added successfully!');
          return res;
        } else {
          message.error(res.message);
        }
      } else {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
  
      return res;
    }
  );
  

  export const getAllHoliday = createAsyncThunk(
    'Holiday/getAllHoliday',
    async (args, { dispatch }) => {
      const res = await getAllHolidayService();
      if (!res.responseCode) {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  );