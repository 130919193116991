export const AttendanceStateEnum = {
  Unknown: 1,
  Present: 2,
  Absent: 3,
  Leave: 4,
  Late: 5,
  WorkFromHome: 6,
  Off: 7,
  Holiday: 8,
};
