import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";


import { GetBusinessNumber, GetAllBusinessNumberCallHistoryPaging, allocateNumber, removeAllocatedMember } from "./action";

const initialState = {
    loading: false,
    BusinessNumbers: [],
    BusinessNumbersCallHistory: [],
    totalRecords: 0,
};

const businessNumberSlice = createSlice({
    name: "businessNumberSlice",
    initialState,
    reducers: {
        removeMemberAllocated: (state, { payload }) => {
            state.BusinessNumbers = state.BusinessNumbers?.map(item => ({
                ...item,
                member: item.id === payload ? null : item.member
            }))
        },
    },
    extraReducers: (builder) => {

        builder.addCase(GetBusinessNumber.fulfilled, (state, { payload }) => {
            state.BusinessNumbers = payload?.data
            state.loading = false

        });
        // builder.addCase(GetAllBusinessNumberCallHistory.fulfilled, (state, { payload }) => {

        //     // console.log(payload.data, "ppppppppppppppppppppp");
        //     state.BusinessNumbersCallHistory = payload?.data.data
        //     // console.log(state.BusinessNumbersCallHistory, "bbbbbbbbbbbbbbbbbbbb");
        //     state.loading = false

        // });


        builder.addCase(GetAllBusinessNumberCallHistoryPaging.fulfilled, (state, { payload }) => {
            console.log('RequestDataAction RED', payload)
            state.success = true;
            state.loader = false;
            state.BusinessNumbersCallHistory = payload.data.data;
            state.totalRecords = payload.data.totalRecords;
          });

        builder.addCase(allocateNumber.fulfilled, (state, { payload }) => {
            let { businessId } = payload?.[0];
            try {
                const currentState = current(state);
                if (currentState.data !== null) {
                    const keys = Object.keys(currentState.data);
                    let updatedState = { ...currentState.data };
        
                    keys?.forEach((identifier) => {
                        const current = currentState.data[identifier];
                        const index = current.findIndex((y) => y.id === businessId);
                        if (index !== -1) {
                            const updatedCurrent = [...current]; // Create a deep copy of the current array
                            let referenceItem = updatedCurrent[index];
        
                            referenceItem = {
                                ...referenceItem,
                                members: [...referenceItem.members, ...payload],
                            };
        
                            updatedCurrent[index] = referenceItem;
        
                            updatedState = {
                                ...updatedState,
                                [identifier]: updatedCurrent,
                            };
                        }
                    });
                    state.data = updatedState;
                }
            } catch (e) {
                console.log("===error===customApproval", e);
            }
        });




        builder.addCase(removeAllocatedMember.fulfilled, (state, action) => {
            console.log(action, "===action===")
            const { meta: { arg: { allocationNumberId, businessNumberId } } } = action
            try {
                const currentState = current(state)
                const businessNumberIndex = currentState.BusinessNumbers.findIndex(i => i.id === businessNumberId)
                const businessNumberCopy = [...currentState.BusinessNumbers]
                const businessNumberObj = businessNumberCopy[businessNumberIndex]
                console.log(businessNumberObj, "aaaaaaaa")

                let businessNumberAllocationArray = [...businessNumberObj.businessNumberAllocations]
                businessNumberAllocationArray = businessNumberAllocationArray.filter(i => i.id !== allocationNumberId)

                const newObj = {
                    ...businessNumberObj,
                    businessNumberAllocations: businessNumberAllocationArray
                }

                console.log(newObj, "bbbbbb")

                businessNumberCopy[businessNumberIndex] = newObj

                state.BusinessNumbers = businessNumberCopy
            }
            catch (e) {
                console.log(e)
            }
        });

        builder.addMatcher(isPending(...[GetBusinessNumber, GetAllBusinessNumberCallHistoryPaging]), (state) => {
            console.log(state, "state");
            state.loading = true;
        })
    },
});

export const {
    removeMemberAllocated
} = businessNumberSlice.actions;

export default businessNumberSlice.reducer;
