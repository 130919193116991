import moment from "moment";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
import MasterConfig from "../../../../utils/services/MasterConfig";

const addCheckIn_dto = (data) => {
  return {
    // attendanceDate: data.attendanceDate ? data.attendanceDate : moment(),
    userId: data.userId ? data.userId : undefined,
    lat: data.lat ? data.lat : 0,
    lng: data.lng ? data.lng : 0,
    moodId: data.moodId ? data.moodId : null,
    comment: data.comment ? data.comment : "",
    type: data.type ? data.type : 1,
  };
};

export const AddAttendanceCheckInService = async (payload = {}) => {
  let request = addCheckIn_dto(payload);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Attendance/AddAttendanceCheckIn`, request);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const GetAllAttendanceByUserService = async ({ userId }) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(
      `api/Attendance/GetAllAttendanceByUser?userId=${userId}`
    );
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const SaveAttendanceService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.put(`api/Attendance/SaveAttendance`, payload);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const AddAttendanceService = async ({ data }) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Attendance/AddAttendance`, data);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const GetAllAttendanceCheckInService = (data) => {
  return MasterConfig.post("api/Attendance/GetAllAttendanceCheckIn", data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateAttendanceCheckInStatusService = (data) => {
  const { id, status: checkInStatus } = data;
  return MasterConfig.put(
    `api/Attendance/UpdateAttendanceCheckInStatus?id=${id}&checkInStatus=${checkInStatus}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllAttendanceByBusinessService = (limit) => {
  return MasterConfig.get(
    `api/Attendance/GetAllAttendanceByBusiness?dateTime=${limit}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getMonthlyAttendanceService = (data) => {
  const { year, month } = data;
  return MasterConfig.get(
    `api/Attendance/GetMonthlyAttendance?year=${year}&month=${month}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
