import React,{useState,useEffect} from "react";
import navCloseBtn from "../../../../../content/svg/menu/newNavBarIcon/navCloseBtn.svg";
import mobileCloseDrawer from "../../../../../content/svg/topMenu/mobileCloseDrawer.svg";
import { navBarOpen } from "../../../../../store/appReducer/responsiveSlice";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { isImageValid } from "./isImageValid";
function ToggleButton() {
	const {user: {businessShortImage}} = useSelector((state) => state.userSlice); 
  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const [validImage, setValidImage] = useState(null);
  const dispatch = useDispatch();
  const { navBarStatus } = useSelector((state) => state.responsiveSlice);
  
  useEffect(() => {
    const checkImage = async () => {
      if (businessShortImage) {
        const isValid = await isImageValid(businessShortImage);
        setValidImage(isValid ? businessShortImage : null);
      } else {
        setValidImage(null);
      }
    };

    checkImage();
  }, [businessShortImage]);

  return (
    <div
      className="toggleButton"
      onClick={() => dispatch(navBarOpen(!navBarStatus))}
    >
      {isDesktopOrLaptop ? (
        <img src={mobileCloseDrawer} alt="navOpen" />
      ) : (
        <img src={navBarStatus ? navCloseBtn :  validImage || navCloseBtn 
        } alt="navOpen" />
      )}
    </div>
  );
}

export default ToggleButton;
