export const RequisitionOfferStatusEnum = {
  Offers: 1,
  Approved: 2,
  Declined: 3,
  Finalise: 4,
  ShortList: 5,
};

export const getOfferStatuslabelEnum = [
  {
    name: "Offers",
    colorCode: "var(--currentThemeColor)",
    id: 1,
  },
  {
    name: "ShortList",
    colorCode: "#1ECB40",
    id: 5,
  },
  {
    name: "Declined",
    colorCode: "#FF0000",
    id: 3,
  },
  {
    name: "Finalise",
    colorCode: "#ffa500",
    id: 4,
  },
];
// export const getStatusLabelAndColor = (module, statusLabels) => {
//   return {
//     [RequisitionOfferStatusEnum.Offers]: {
//       label: statusLabels.Offers,
//       color: "var(--currentThemeColor)",
//     },
//     [RequisitionOfferStatusEnum.ShortList]: {
//       label: statusLabels.ShortList,
//       color: "#1ECB40",
//     },

//     [RequisitionOfferStatusEnum.Declined]: {
//       label: statusLabels.Declined,
//       color: "#FF0000",
//     },
//     [RequisitionOfferStatusEnum.Finalise]: {
//       label: statusLabels.Finalise,
//       color: "#ffa500",
//     },
//   };
// };
