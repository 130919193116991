import React from 'react';
import '../../Styles/employeeForm.css';
import './style.css';
import { Form } from 'antd';
import PreBasicInfo from './preForm';

const ProfileForm = () => {

  return (

    <div className='card-container addPreEmployeeForm'>
      <Form.Provider>
        <Form>
          <PreBasicInfo />
        </Form>
      </Form.Provider>
    </div>
  );
};

export default ProfileForm;
