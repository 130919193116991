import React, { useContext, useRef, useState, useEffect } from "react";
import "../composer/stylesheet/FeedCompose.css";
import "../../ui/posts_list/post/stylesheet/Post.css";
import { Modal } from "antd";
import ShareForm from "./ShareForm";
import SharePostHeader from "./sharePostHeader";
import "./style.css";
import { sharePostOnFeed } from "../../store/actions";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { PostReferenceType, PostType } from "../../utils/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { handleImportant, resetComposerFeed } from "../../store/slice";
import { STRINGS, getMentionsAndText } from "../../../../../utils/base";
import store from "../../../../../store/store";
import { useNavigate } from "react-router-dom";

const DefaultPostRefType = PostReferenceType.MAIN_FEED;

function SharePostComposer({
  showComposer,
  setShowComposer,
  postRefType = DefaultPostRefType,
  postRefId = STRINGS.DEFAULTS.guid,
  post = null,
  postId,
  moduleReferenceType,
  modulePrivacyId,
}) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed, sharedLabels } = dictionaryList[userLanguage];
  const { user } = useSelector((state) => state.userSlice);
  const [referenceId, setReferenceId] = useState(STRINGS.DEFAULTS.guid);
  const [postReferenceType, setPostReferenceType] = useState(
    postRefType === PostReferenceType.GROUP ||
      postRefType === PostReferenceType.PROJECTS
      ? postRefType
      : DefaultPostRefType
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clicked, postCompose } = useSelector((state) => state.feedSlice);
  const sharePost = (privacyType) => {
    let { title, mentions } = postCompose;
    let data = getMentionsAndText(title, mentions);
    let payload = {
      parentId: postId,
      title: data.title,
      mentions: data.mentions,
      isImportant: clicked ?? false,
      privacyId: privacyType,
      type: PostType.DEFAULT,
      referenceType: postReferenceType,
      referenceId:
        referenceId === STRINGS.DEFAULTS.guid ? user?.id : referenceId,
    };
    dispatch(
      sharePostOnFeed({
        newsFeed,
        payload,
        referenceId : referenceId === STRINGS.DEFAULTS.guid ? null: referenceId ,
        userId: user?.id,
        navigate
      })
    );
    setShowComposer(false);
    clicked && dispatch(handleImportant(!clicked));
  };

  useEffect(() => {
    setPostReferenceType(
      postRefType === PostReferenceType.GROUP ||
        postRefType === PostReferenceType.PROJECTS
        ? postRefType
        : DefaultPostRefType
    );
  }, [postRefType]);

  return (
    <Modal
      wrapClassName="feed-composer-modal"
      width={800}
      open={showComposer}
      onCancel={() => {
        setShowComposer(false);
        dispatch(resetComposerFeed())
      }}
      destroyOnClose={true}
      footer={null}
      header={null}
    >
      <div className="composer-wrapper sharePostModal">
        <SharePostHeader
          setReferenceId={setReferenceId}
          referenceId={referenceId}
          setPostReferenceType={setPostReferenceType}
          moduleReferenceType={moduleReferenceType}
          modulePrivacyId={modulePrivacyId}
          postRefType={postReferenceType}
          postRefId={postRefId}
        />
        <ShareForm
          isOpen={showComposer}
          post={post}
          sharePost={(privacyType) => {
            sharePost(privacyType);
          }}
          postRefId={postRefId}
          postRefType={postRefType}
        />
      </div>
    </Modal>
  );
}

export default SharePostComposer;
