import React, { useEffect, useState, useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
const CreateEntryHead = () => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { voucher, sharedLabels } = dictionaryList[userLanguage];
  return (
    <thead>
      <tr>
        <th style={{ width: "50px" }}>{""}</th>
        <th style={{ width: "230px" }}>{voucher.Account}</th>
        <th style={{ width: "100px" }}>{voucher.ChequeNo}</th>
        <th>{voucher.Narration}</th>
        <th style={{ width: "150px" }}>{sharedLabels.amount}</th>
        <th style={{ width: "120px" }}>{voucher.drCr}</th>
        <th style={{ width: "45px" }}></th>
      </tr>
    </thead>
  );
};
export default CreateEntryHead;
