import React, { useContext } from "react";
import { Tag } from "antd";
import {
  TeamOutlined,
  PieChartOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { TravelReferenceTypeEnum } from "../../utils/travelReferenceTypeEnum";
import { TravelReferenceTypeColor } from "../../utils/travelReferenceTypeColor";
import { ReferenceTypeEnum } from "../../../../sharedComponents/reference/utils/referenceTypeEnum";
import { STRINGS, getRouteByType } from "../../../../../utils/base";
import travelIcon from "../../../../../content/svg/menu/newNavBarIcon/Travel.svg";
import projectIcon from "../../../../../content/svg/menu/newNavBarIcon/Projects.svg";
import "../../styles/Travel.css"

const ReferenceTypeTag = ({
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = TravelReferenceTypeEnum.General,
  reference = [],
}) => {
  let color = TravelReferenceTypeColor?.[referenceType];
  let value =
    reference && reference !== null && reference?.name
      ? reference?.name
      : "General";
  let icon = travelIcon;

  switch (referenceType) {
    case TravelReferenceTypeEnum.Project:
      icon = projectIcon;
      break;
    default:
      icon = travelIcon;
      break;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      referenceId &&
      referenceId !== STRINGS.DEFAULTS.guid &&
      referenceType !== TravelReferenceTypeEnum.General
    ) {

      let refType;
      switch (referenceType) {
        case TravelReferenceTypeEnum.Project:
          refType = ReferenceTypeEnum.Project;
          break;
        default:
          break;
      }

      let url = refType && getRouteByType(referenceId, refType);
      if (url) {
        window.location.href = url;
      }
    }
  };

  return (
    <>
      <Tag
        icon={<img src={icon} className="h-[20px] w-[20px] mr-2" alt="icon" />}
        color={color}
        onClick={handleClick}
        className="travelReferenceTypeTag"
      >
        {value}
      </Tag>
    </>
  );
};

export default ReferenceTypeTag;
