export const DocumentReferenceTypeEnum = {
  General: 1,
  Project: 2,
  Group: 3,
};
export const privacyOption = {
  Public: 1,
  Private: 2,
};

export const DocumentFilterTypeEnum = {
  CreatedByMe: 2,
  ForApproval: 3,
};
