import { jsonToFormData } from "../../../../utils/base";
import Config from "../../../../utils/services/MasterConfig";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import { responseCode as responseCodeEnum } from "../../../../services/enums/responseCode";


export const addCareerService = async (data) => {
  const formData = jsonToFormData(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Career/AddCareer`, formData);
    if (responseCode === responseCodeEnum.Success) {
      return ResponseResultSuccess(data);
    }
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const addCareerApplicantService = async (data) => {
  const formData = jsonToFormData(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Career/AddCareerApplicant`, formData);
    if (responseCode === responseCodeEnum.Success) {
      return ResponseResultSuccess(data);
    }
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllCareerService = async (data) => {
  return Config.post(`api/Career/GetAllCareer`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllCareerServicePaging = async (data) => {
  return Config.post(`api/Career/GetAllCareerPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
};



export const getAllCareerApplicantService = async (daa) => {
  // const payload = {
  //   pageNo: 1,
  //   pageSize: 20,
  //   search: "",
  //   careerIds: daa?.request?.careerIds,
  //   status: []
  // };
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Career/GetAllCareerApplicant`, daa);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllCareerBYIdService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Career/GetCareerById?id=${id}`);
    console.log("data by id", data, id);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllCareerLinkByIdService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Career/GetCareerLinkById?id=${id}`);
    console.log("data by id", data, id);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const UpdateCareerApplicantStatusService = async (payload) => {
  try {

    const { id, careerId, status } = payload
    const {
      data: { responseCode, data, message },
    } = await Config.put(`/api/Career/UpdateCareerApplicantStatus?id=${id}&careerId=${careerId}&status=${status}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
}


export const AddCareerApplicantOfferLetterService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/Career/AddCareerApplicantOfferLetter`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}

export const GetAllCareerInterviewService = async (payload) => {
  try {
    const { userId, careerId } = payload
    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/Career/GetAllCareerInterview?userId=${userId}&careerId=${careerId}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}

export const AddRequestForInterviewersToScheduleInterviewService = async (
  payload
) => {
  try {
    const { careerId, applicantId } = payload;
    const {
      data: { responseCode, data, message },
    } = await Config.post(
      `/api/Career/AddRequestForInterviewersToScheduleInterview`, payload
    );
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllInterviewsService = async (
  payload
) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(
      `/api/Career/getAllInterviews`, payload
    );
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getInterviewDetailService = async (scheduleId) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`/api/Career/GetInterviewDetail?scheduleId=${scheduleId}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}

export const addCareerInterviewRatingService = async (values) => {
  const { interviewId, payload } = values
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/Career/AddCareerInterviewsRating?interviewId=${interviewId}`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}

export const getCareerApplicanyByIdService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`/api/Career/GetCareerApplicantById?id=${id}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}


export const addCareerApplicantLinkService = async (data) => {
  const formData = jsonToFormData(data);
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/Career/AddCareerApplicantLink`, formData);
    if (responseCode === responseCodeEnum.Success) {
      return ResponseResultSuccess(data);
    }
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const VerifyUserByTokenService = async (token) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`/api/User/VerifyToken?token=${token}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}


export const CareerApplicantOfferResponseService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.put(`/api/Career/CareerApplicantOfferResponse?status=${payload.status}&token=${payload.token}`);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  }
  catch (e) {
    return ResponseResultError(e);
  }
}
