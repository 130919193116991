export const SuggestionTypeEnum = {
  Employee: 1,
  Department: 2,
  Group: 3,
  Project: 4,
  CustomTag: 5,
  GroupCustomTag: 6,
  ProjectCustomTag: 7,
  GroupMember: 8,
  ProjectMember: 9,
  TravelAgents: 10,
  LeadManagerMember: 11,
  WorkBoardMember: 12,
  WorkBoardTodoMember: 13,
  LeadManagerDetailContact : 14,
  LeadManagerDetailMember:15,
  LeadManager: 16,
};
