import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { STRINGS } from "../../../../utils/base";
import { PostPrivacyType } from "../../../../utils/Shared/enums/enums";
import { getComposerKeyByType } from "../constant/helpers";
import {
  addDocument,
  getAllDocument,
  getAllDocumentList,
  moveDocument,
  getDocumentInformation,
  addDirectory,
  addDocumentDirectoryMember,
  getAllDocumentDirectoryMember,
  AddDocumentMember,
  GetDocumentDirectoryById,
  CompleteDocumentAction,
  getAllDocumentListPaging,
  getAllDocumentPaging,
  GetDocumentById,
  getAllDocumentListAction,
  getAllDocumentAction,
  updateDocument,
  DuplicateDocumentAction,
  getAllSignatureDocumentListAction,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import moment from "moment";
import { DocumentStatusEnum } from "../constant";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
  data: null,
  driveListData: null,
  documentListData: null,
  newCountFilterButton:null,
  newDocument: null,
  newDirectory: null,
  listLoading: false,
  currentTab: "allDocuments",
  isOpenComposersForAttachment: false,
  isOpenComposers: {
    folder: false,
    upload: false,
    milegrid: false,
    milepad: false,
    mileboard: false,
    mileshow: false,
    updateMembers: false,
  },
  composersInitState: {
    folder: {},
    upload: {},
    milegrid: {},
    milepad: {},
    mileboard: {},
    mileshow: {},
    updateFolderMemberId: null,
    members: [],
  },
  isTableView: false,
  listData: [],
  docListMember: [],
  tableData: [],
  myDocsTableData: [],
  detailListData: [],
  documentApprovers: [],
  documentDetail: {},
  editData: null,
  success: false,
  loader: false,
  listLoader: false,
  detailLoader: false,
  error: false,
  parentId: null,
  breadCumbPath: [
    {
      label: "Home",
      id: STRINGS.DEFAULTS.guid,
    },
  ],
  defaultFiles: [],
  minimzedDocuments: [],
  AddDocReaderAndCollabrator: [],
  totalRecords: 0,
  myDocsTotalRecords: 0,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    handleOpenDocComposer: (state, { payload: key }) => {
      state.isOpenComposersForAttachment = true;
    },
    handleCloseDocComposer: (state, { payload: key }) => {
      state.isOpenComposersForAttachment = false;
      state.defaultFiles = [];
    },
    handleChangeTab: (state, { payload: tab }) => {
      state.currentTab = tab;
    },
    handleChangeView: (state, { payload: isTableView }) => {
      state.isTableView = isTableView;
    },
    handleParentId: (state, { payload }) => {
      state.parentId = payload.id;
      state.breadCumbPath = [
        ...state.breadCumbPath,
        {
          label: payload.name,
          id: payload.id,
        },
      ];
    },
    resetBreadCumb: (state, { payload }) => {
      state.breadCumbPath = [
        {
          label: "Home",
          id: STRINGS.DEFAULTS.guid,
        },
      ];
      state.parentId = null;
    },
    readCountforDocumentList:(state,{payload})=>{
        const id = payload
        try{
          const currentState = current(state);
          let updatedState = { ...currentState.documentListData };
          if (currentState.documentListData !== null) {
            const keys = Object.keys(currentState.documentListData);
            keys.forEach((identifier) => {
              const current = currentState.documentListData[identifier];
              const index = current.findIndex((y) => y.id === id);
  
              if (index !== -1) {
                const updatedCurrent = [...current]; // Create a deep copy of the current array
                let referenceItem = updatedCurrent[index];
  
  
                referenceItem = {
                  ...referenceItem,
                  notificationCount:0
                };
  
                updatedCurrent[index] = referenceItem;
  
                updatedState = {
                  ...updatedState,
                  [identifier]: updatedCurrent,
                };
              }
            });
  
            state.documentListData = updatedState;
            state.newCountFilterButton = id
          }
        }
        catch(e)
        {
          console.log("==Error Document==",e)
        }

    },
    readCountforDriveList: (state,{payload})=>{
      const id = payload
      const currentData = current(state);
      if (currentData.driveListData !== null) {
        let newState = { ...currentData.driveListData };
        const keys = Object.keys(currentData.driveListData);
        keys?.forEach((identifier) => {
          const currentList = currentData.driveListData[identifier];
          const index = currentList.findIndex((item) => item.id === id);
          if (index !== -1) {
            const updateCurrentList = [...currentList];
            let currentItem = updateCurrentList[index];
            currentItem = {
              ...currentItem,
             notificationCount:0
            };
            updateCurrentList[index] = currentItem;
            newState = {
              ...newState,
              [identifier]: updateCurrentList,
            };
          }
        });
        state.driveListData = newState;
        state.newCountFilterButton = payload.id
      }
    },
    handleBreadCumb: (state, { payload }) => {
      state.breadCumbPath = state.breadCumbPath.slice(0, payload.index + 1);
      state.parentId = payload.id;
    },
    handleNotificationNewItemDriveList:(state,{payload})=>{

        const currentData = current(state);
        if (currentData.driveListData !== null) {
          let newState = { ...currentData.driveListData };
          const keys = Object.keys(currentData.driveListData);
          keys?.forEach((identifier) => {
            const currentList = currentData.driveListData[identifier];
            const index = currentList.findIndex((item) =>   item.id === payload.featureId);
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];
              currentItem = {
                ...currentItem,
                notificationCount:currentItem.notificationCount+1
              };
              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });
          state.driveListData = newState;
      }
    },
    handleNOtificationNewItemDocumentList:(state, {payload})=>{
      try{
        const currentState = current(state);
        let updatedState = { ...currentState.documentListData };
        if (currentState.documentListData !== null) {
          const keys = Object.keys(currentState.documentListData);
          keys.forEach((identifier) => {
            const current = currentState.documentListData[identifier];
            const index = current.findIndex((y) => y.id  === payload.featureId);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];


              referenceItem = {
                ...referenceItem,
                notificationCount:referenceItem.notificationCount+1
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });

          state.documentListData = updatedState;
        }
      }
      catch(e)
      {
        console.log("==Error Document==",e)
      }
    },
    handleDocumentApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;
        let updatedState = { ...currentState.documentListData };
        if (currentState.documentListData !== null) {
          const keys = Object.keys(currentState.documentListData);
          keys.forEach((identifier) => {
            const current = currentState.documentListData[identifier];
            const index = current.findIndex((y) => y.id === referenceId);

            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              let newStatus = null;
              
              if(approverStatus.length > 1 )
              {
                 
                    if (approverStatus.length === 2 && approverStatus.includes(ApprovalStatus.Approved) && approverStatus.includes(ApprovalStatus.Removed)) {
                      newStatus = ApprovalStatus.Approved;
                  } else {
                      newStatus = ApprovalStatus.InProcess;
                  }
              
              }
              else
              {
                newStatus = approverStatus[0] === ApprovalStatus.Removed ? ApprovalStatus.Approved : approverStatus[0]
              }


              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status:newStatus,
                approverStatus:
                newStatus,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });

          state.documentListData = updatedState;
        }
      } catch (e) {
        console.log("===Error===", e);
      }
    },

    // updateMoveDocument: (state, { payload }) => {

    //   state.listData = state.listData.filter(
    //     (item) => item.id !== payload.documents[0]
    //   );
    // },
    //new HandlerForUpdateMoveDocument
    // handleAddApprovers:(state , {payload})=>{
    //   console.log(payload,"===Payload===")
    //   const currentData = current(state)
    //   const {referenceId , approvers} = payload
    //   if(current.documentListData!==null)
    //     {
    //         let newState = {...currentData.documentListData}
    //         const keys = Object.keys(currentData.documentListData);
    //         keys?.forEach((identifier)=>{
    //           const currentList = currentData?.documentListData[identifier]
    //           const index = currentList.findIndex(item => item.id === referenceId)
    //           if(index!==-1)
    //           {
    //             const updateCurrentList = [...currentList]
    //             let currentItem = updateCurrentList[index]

    //             const newStatus  = currentItem.approvers.length > 0 ? currentItem.newState : DocumentStatusEnum.InProcess

    //             currentItem = {
    //               ...currentItem,
    //               approvers:approvers,
    //               newStatus:DocumentStatusEnum.InProcess
    //             }

    //             updateCurrentList[index] = currentItem
    //             newState = {
    //               ...newState,
    //               [identifier]:updateCurrentList
    //             }

    //           }
    //         })
    //         state.documentListData = newState
    //     }
    // },
    handleAddDocumentApprover: (state, { payload }) => {
      try {
        const currentData = current(state);
        const { referenceId, approver } = payload;
        if (currentData.documentListData) {
          let newState = { ...currentData.documentListData };
          const keys = Object.keys(currentData.documentListData);
          keys?.forEach((identifier) => {
            const currentList = currentData?.documentListData[identifier];

            console.log("==curentlist==", currentList);
            const index = currentList.findIndex(
              (item) => item.id === referenceId
            );
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];

              if(currentItem.hasOwnProperty('approvers')){
                const oldApprovers = [...currentItem?.approvers];
                const status =
                  oldApprovers.length > 0
                    ? currentItem.status
                    : DocumentStatusEnum.InProcess;
                const finalApprovers = [...oldApprovers, ...approver];
  
                currentItem = {
                  ...currentItem,
                  approvers: finalApprovers,
                  status: status,
                };
  
                updateCurrentList[index] = currentItem;
                newState = {
                  ...newState,
                  [identifier]: updateCurrentList,
                }; 
              }
            }
          });

          state.documentListData = newState;
        }
        if (currentData.driveListData) {

          let newState = { ...currentData.driveListData };
          const keys = Object.keys(currentData.driveListData);
          keys?.forEach((identifier) => {
            const currentList = currentData?.driveListData[identifier];

            console.log("==curentlist==", currentList);
            const index = currentList.findIndex(
              (item) => item.id === referenceId
            );
            console.log(referenceId,"aaa",index)
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];

              // const oldApprovers = [...currentItem?.approvers];

              // const status =
              //   oldApprovers.length > 0
              //     ? currentItem.status
              //     : DocumentStatusEnum.InProcess;
              // const finalApprovers = [...oldApprovers, ...approver];

              currentItem = {
                ...currentItem,
                // approvers: finalApprovers,
                status: DocumentStatusEnum.Approved,
              };

              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });

          state.driveListData = newState;
        }
      } catch (e) {
        console.log("===ERROR WHILE ADDING APPROVERS===", e);
      }
    },
    handleDocumentStatusChange:(state,{payload})=>{
      try {
        const currentData = current(state);
        const { referenceId } = payload;
        if (currentData.documentListData) {
          let newState = { ...currentData.documentListData };
          const keys = Object.keys(currentData.documentListData);
          keys?.forEach((identifier) => {
            const currentList = currentData?.documentListData[identifier];
            const index = currentList.findIndex(
              (item) => item.id === referenceId
            );
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];
              
              currentItem = {
                ...currentItem,
                status:DocumentStatusEnum.Approved,
              };
              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });

          state.documentListData = newState;
        }
        if(currentData.driveListData){
          let newState = { ...currentData.driveListData };
          const keys = Object.keys(currentData.driveListData);
          keys?.forEach((identifier) => {
            const currentList = currentData?.driveListData[identifier];
            const index = currentList.findIndex(
              (item) => item.id === referenceId
            );
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];
              
              currentItem = {
                ...currentItem,
                status:DocumentStatusEnum.Approved,
              };
              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });

          state.driveListData = newState;
        }
      } catch (e) {
        console.log("===ERROR WHILE ADDING APPROVERS===", e);
      }
    },
    updateMoveDocument: (state, { payload }) => {
      const documentId = payload.documents[0];
      const currentData = current(state);
      if (currentData.driveListData !== null) {
        let newState = { ...currentData.driveListData };
        const keys = Object.keys(currentData.driveListData);
        keys?.forEach((identifier) => {
          const currentList = currentData?.driveListData[identifier].filter(
            (item) => item.id !== documentId
          );
          newState = {
            ...newState,
            [identifier]: currentList,
          };
        });
        state.driveListData = newState;
      }
    },

    uploadFileByDrop: (state, { payload }) => {
      state.defaultFiles = payload;
    },
    deleteFile: (state, { payload }) => {
      const newFiles = [...state.defaultFiles];
      newFiles.splice(payload, 1);
      return {
        ...state,
        defaultFiles: newFiles,
      };
    },
    handleUpdateFolder: (state, { payload }) => {
      state.isOpenComposers.folder = true;
      state.composersInitState.folder = payload;
    },
    handleUpdateFolderMemberId: (state, { payload }) => {
      state.composersInitState.updateFolderMemberId = payload.id;
    },
    handleAddMinimizeDocument: (state, { payload }) => {
      let dcoumentItem = {
        isOpen: payload.status,
        id: payload.document.id,
        document: {
          ...payload.document,
        },
      };
      let isAlreadyAvailable = state.minimzedDocuments.find(
        (document) => document.id === payload.document.id
      );
      if (!!!isAlreadyAvailable) {
        state.minimzedDocuments = [...state.minimzedDocuments, dcoumentItem];
      }
    },
    handleRemoveMinimizeDocument: (state, { payload }) => {
      let id = payload.id;
      state.minimzedDocuments = state.minimzedDocuments.filter(
        (item) => item.id !== id
      );
    },
    toggleMinimizeDocument: (state, { payload: { id, status } }) => {
      let index = state.minimzedDocuments.findIndex((item) => item.id === id);
      state.minimzedDocuments[index] = {
        ...state.minimzedDocuments[index],
        isOpen: status,
      };
    },
    // handleFavoriteMark(state, { payload }) {
    //   const favDocs = state.listData.find((group) => group.id === payload.id);
    //   favDocs.isPinnedPost = !favDocs?.isPinnedPost;
    // },

    // new HandelFavoriteMark - Identifier
    handleFavoriteMark(state, { payload }) {
      const currentData = current(state);
      if (currentData.driveListData !== null) {
        let newState = { ...currentData.driveListData };
        const keys = Object.keys(currentData.driveListData);
        keys?.forEach((identifier) => {
          const currentList = currentData.driveListData[identifier];
          const index = currentList.findIndex((item) => item.id === payload.id);
          if (index !== -1) {
            const updateCurrentList = [...currentList];
            let currentItem = updateCurrentList[index];
            currentItem = {
              ...currentItem,
              isPinnedPost: payload.isPinned,
            };
            updateCurrentList[index] = currentItem;
            newState = {
              ...newState,
              [identifier]: updateCurrentList,
            };
          }
        });
        state.driveListData = newState;
      }
    },
    handleAddDirectory(state, { payload }) {
      const { identifier } = payload;
      if (state.newDirectory !== null) {
        if (state.driveListData !== null || state.driveListData !== undefined) {
          console.log(state.newDirectory, "==newDirectory==");
          const {
            id,
            createBy,
            creator,
            description,
            members,
            name,
            parentId,
            privacyId,
            referenceId,
            referenceType,
          } = state.newDirectory;
          const newObj = {
            id: id,
            documentType: 1,
            name: name,
            status: 2,
            parentId: parentId,
            createDate: moment(),
            privacyId: privacyId,
            referenceNo: "",
            createBy: createBy,
            referenceId: referenceId,
            referenceType: referenceType,
            extensionTypeId: 0,
            path: "",
            attachmentId: STRINGS.DEFAULTS.guid,
            creator: creator,
            members: members,
            isPinnedPost: false,
          };

          if (state.driveListData && state.driveListData[identifier]) {
            state.driveListData[identifier] = [
              newObj,
              ...state.driveListData[identifier],
            ];
          }
        }
      }
    },
    handleAddDocument(state, { payload }) {
      const { identifier } = payload;
      console.log(identifier, "==identifier at New document==");
      if (state.newDocument !== null) {
        if (
          state.documentListData !== null ||
          state.documentListData !== undefined
        ) {
          if (state.documentListData && state.documentListData[identifier]) {
            state.documentListData[identifier] = [
              ...state.newDocument,
              ...state.documentListData[identifier],
            ];
          }
        }
        if (state.driveListData !== null || state.driveListData !== undefined) {
          const newArray = state.newDocument?.map((item) => {
            return {
              id: item.id,
              documentType: item.documentType,
              name: item.attachments[0].name,
              status: item.status,
              parentId: item.directoryId,
              createDate: item.createDate,
              privacyId: item.privacyId,
              referenceNo: item.referenceNo,
              createBy: item.createBy,
              referenceId: item.referenceId,
              referenceType: item.referenceType,
              extensionTypeId: item.attachments[0].extensionTypeId,
              path: item.attachments[0].path,
              attachmentId: item.attachments[0].attachmentId,
              creator: item.creator,
              members: item.members,
              isPinnedPost: false,
            };
          });
          if (state.driveListData && state.driveListData[identifier]) {
            state.driveListData[identifier] = [
              ...newArray,
              ...state.driveListData[identifier],
            ];
          }
        }
        state.newDocument = null;
      }
    },
    addDocsMembers(state, { payload }) {},
    handleDocumentsReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllDocumentListAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const { pageNo } = filter;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          if (pageNo == 1) {
            state.driveListData = {
              ...state.driveListData,
              [identifier]: data,
            };
          } else {
            state.driveListData = {
              ...state.driveListData,
              [identifier]: [...state?.driveListData?.[identifier], ...data],
            };
          }
        }
        else
        {
            state.driveListData = {
              ...state.driveListData,
              [identifier]: data?.data,
            };
        }
      })
      .addCase(getAllSignatureDocumentListAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const { pageNo } = filter;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          if (pageNo == 1) {
            state.driveListData = {
              ...state.driveListData,
              [identifier]: data,
            };
          } else {
            state.driveListData = {
              ...state.driveListData,
              [identifier]: [...state?.driveListData?.[identifier], ...data],
            };
          }
        }
        else
        {
            state.driveListData = {
              ...state.driveListData,
              [identifier]: data?.data,
            };
        }
      })
      .addCase(getAllDocumentAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const { pageNo } = filter;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          if (pageNo == 1) {
            state.documentListData = {
              ...state.documentListData,
              [identifier]: data,
            };
          } else {
            state.documentListData = {
              ...state.documentListData,
              [identifier]: [...state?.documentListData?.[identifier], ...data],
            };
          }
        } else {
          state.documentListData = {
            ...state.documentListData,
            [identifier]: data?.data,
          };
        }
      })

      .addCase(addDocument.fulfilled, (state, { payload }) => {
        state.newDocument = payload;

        const newItem = payload?.map((item) => {
          return {
            id: item.id,
            documentType: item.documentType,
            name: item.attachments[0].name,
            status: item.state,
            parentId: item.directoryId,
            createDate: item.createDate,
            privacyId: item.privacyId,
            referenceNo: item.referenceNo,
            createBy: item.createBy,
            referenceId: item.referenceId,
            referenceType: item.referenceType,
            extensionTypeId: item.attachments[0].extensionTypeId,
            path: item.attachments[0].path,
            attachmentId: item.attachments[0].attachmentId,
            creator: item.creator,
            members: item.members,
            isPinnedPost: false,
          };
        });

        let createdDocument = newItem.map((item) => {
          return {
            isOpen: true,
            id: item.id,
            document: {
              ...item,
            },
          };
        });
        state.minimzedDocuments = [
          ...state.minimzedDocuments,
          ...createdDocument,
        ];
        state.loader = false;
        state.success = true;
      })
      .addCase(updateDocument.fulfilled, (state, { payload }) => {
        const { attachmentId } = payload;
        if (state.driveListData !== null) {
          Object.entries(state.driveListData).forEach(([identifier, current]) => {
            const index = current.findIndex((item) => item.attachmentId === attachmentId);
            if (index !== -1) {
              state.driveListData[identifier] = [
                ...current.slice(0, index),
                { ...current[index], ...payload },
                ...current.slice(index + 1),
              ];
            }
          });
        }
      })
      .addCase(addDirectory.fulfilled, (state, { payload }) => {
        state.newDirectory = payload;

        // state.loader = false;
        // state.success = true;
        // state.listData = [payload, ...state.listData];
        // state.isOpenComposers.folder = false;
      })
      // .addCase(moveDocument.fulfilled, (state, { payload }) => {
      //   state.listData = state.listData.filter(item=>item.id !== payload.documents[0]);
      // })
      .addCase(getAllDocumentList.fulfilled, (state, action) => {
        // extracting payload and meta from response/action
        const { payload, meta } = action;
        //extracting pageNo
        const { pageNo } = meta?.arg;
        // if pageNo==1 then assing response/payload to docs
        if (pageNo === 1) {
          state.listData = payload;
        }
        // if pageNo > 1 then add/concat payload to docs
        else if (pageNo > 1) {
          state.listData = [...state.listData, ...payload];
        }
        state.listLoader = false;
      })
      .addCase(getAllDocument.fulfilled, (state, action) => {
        // extracting payload and meta from response/action
        const { payload, meta } = action;
        //extracting pageNo
        const { pageNo } = meta?.arg;
        // if pageNo==1 then assing response/payload to docs
        if (pageNo === 1) {
          state.detailListData = payload;
        }
        // if pageNo > 1 then add/concat payload to docs
        else if (pageNo > 1) {
          state.detailListData = [...state.detailListData, ...payload];
        }
        state.listLoader = false;
      })
      .addCase(getDocumentInformation.fulfilled, (state, action) => {
        console.log(action, "actionss===");
        const {
          meta: {
            arg: { identifier },
          },
        } = action;
        const { payload } = action;

        state.driveListData = {
          ...state.driveListData,
          [identifier]: [payload],
        };
      })
      .addCase(GetDocumentById.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { identifier },
          },
        } = action;
        const { payload } = action;

        state.documentListData = {
          ...state.documentListData,
          [identifier]: [payload],
        };

        // state.documentDetail = payload;
        // state.detailLoader = false;
      })
      .addCase(GetDocumentDirectoryById.fulfilled, (state, { payload }) => {
        state.detailLoader = false;
        state.documentDetail = payload;
      })
      .addCase(
        getAllDocumentDirectoryMember.fulfilled,
        (state, { payload }) => {
          console.log(payload, "payloadddd");
          state.composersInitState.members = payload;
          // state.docListMember = payload;
        }
      )
      .addCase(addDocumentDirectoryMember.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { id, memberId },
          },
        } = action;
        const { payload } = action;

        const currentData = current(state);

        if (currentData.driveListData !== null) {
          let newState = { ...currentData.driveListData };
          const keys = Object.keys(currentData.driveListData);

          keys?.forEach((identifier) => {
            const currentList = currentData.driveListData[identifier];
            const index = currentList.findIndex((item) => item.id === id);
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];

              currentItem = {
                ...currentItem,
                members: [...currentItem?.members, ...payload],
              };

              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });

          state.driveListData = newState;
        }

        // state.composersInitState.members = [...state.composersInitState.members, payload];
        // state.loader = true;
      })
      .addCase(AddDocumentMember.fulfilled, (state, action) => {
        const {
          meta: { arg },
        } = action;
        const { id, memberId } = arg?.length > 0 && arg[0];
        const { payload } = action;

        const currentData = current(state);

        if (currentData.driveListData !== null) {
          let newState = { ...currentData.driveListData };
          const keys = Object.keys(currentData.driveListData);

          keys?.forEach((identifier) => {
            const currentList = currentData.driveListData[identifier];
            const index = currentList.findIndex((item) => item.id === id);
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];

              currentItem = {
                ...currentItem,
                members: [...currentItem?.members, ...payload],
              };

              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });

          state.driveListData = newState;
        }

        if (currentData.documentListData !== null) {
          let newState = { ...currentData.documentListData };
          const keys = Object.keys(currentData.documentListData);

          keys?.forEach((identifier) => {
            const currentList = currentData.documentListData[identifier];
            const index = currentList.findIndex((item) => item.id === id);
            if (index !== -1) {
              const updateCurrentList = [...currentList];
              let currentItem = updateCurrentList[index];

              currentItem = {
                ...currentItem,
                members: [...currentItem?.members, ...payload],
              };

              updateCurrentList[index] = currentItem;
              newState = {
                ...newState,
                [identifier]: updateCurrentList,
              };
            }
          });

          state.documentListData = newState;
        }

        // state.composersInitState.members = [...state.composersInitState.members, payload];
        // state.loader = true;
      })
      .addCase(CompleteDocumentAction.fulfilled, (state, { payload }) => {
        state.documentApprovers = payload.data.approvers;

        // if (state.detailListData) {
        //   if (payload.data?.length) {
        //     let newApprovers = [...state.detailListData.approvers, payload.data.approvers];
        //     state.detailListData = {
        //       ...state.detailListData,
        //       approvers: newApprovers,
        //     };
        //   }
        // }
      })
      .addCase(getAllDocumentListPaging.fulfilled, (state, action) => {
        state.listData = action.payload?.data;
        state.totalRecords = action.payload?.totalRecords;
      })
      .addCase(getAllDocumentPaging.fulfilled, (state, action) => {
        state.detailListData = action.payload?.data;
        state.myDocsTotalRecords = action.payload?.totalRecords;
      })
      .addCase(DuplicateDocumentAction.fulfilled, (state, {payload}) =>{
        console.log("pppppppppppp", payload);
        state.newDocument = [payload];

        const newItem = [payload]?.map((item) => {
          return {
            id: item.id,
            documentType: item.documentType,
            name: item.attachments[0].name,
            status: item.status,
            parentId: item.directoryId,
            createDate: item.createDate,
            privacyId: item.privacyId,
            referenceNo: item.referenceNo,
            createBy: item.createBy,
            referenceId: item.referenceId,
            referenceType: item.referenceType,
            extensionTypeId: item.attachments[0].extensionTypeId,
            path: item.attachments[0].path,
            attachmentId: item.attachments[0].attachmentId,
            creator: item.creator,
            members: item.members,
            isPinnedPost: false,
          };
        });

        let createdDocument = newItem.map((item) => {
          return {
            isOpen: true,
            id: item.id,
            document: {
              ...item,
            },
          };
        });
        state.minimzedDocuments = [
          ...state.minimzedDocuments,
          ...createdDocument,
        ];
        state.loader = false;
        state.success = true;
      })
      .addCase(addDocument.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(addDirectory.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getDocumentInformation.pending, (state, action) => {
        state.detailLoader = true;
      })

      .addMatcher(
        isPending(...[getAllDocument, getAllDocumentList]),
        (state) => {
          state.listLoader = true;
          state.success = false;
          state.error = false;
        }
      )
      .addMatcher(
        isRejected(...[addDocument, getAllDocument, getAllDocumentList]),
        (state) => {
          state.listLoader = false;
          state.loader = false;
        }
      );
  },
});

export const {
  handleOpenDocComposer,
  handleCloseDocComposer,
  handleChangeTab,
  handleParentId,
  resetBreadCumb,
  handleBreadCumb,
  updateMoveDocument,
  uploadFileByDrop,
  handleChangeView,
  handleUpdateFolder,
  handleUpdateFolderMemberId,
  handleAddMinimizeDocument,
  handleRemoveMinimizeDocument,
  toggleMinimizeDocument,
  handleFavoriteMark,
  deleteFile,
  addDocsMembers,
  handleDocumentApprovalRemark,
  handleAddDocument,
  handleAddDirectory,
  handleAddDocumentApprover,
  handleAddApprovers,
  readCountforDocumentList,
  readCountforDriveList,
  handleNotificationNewItemDriveList,
  handleNOtificationNewItemDocumentList,
  handleDocumentsReminder,
  handleDocumentStatusChange
} = documentSlice.actions;
export default documentSlice.reducer;
