import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { LeaveStatusEum } from "../../utils/leaveStatusEnum";
import { Tag } from "antd";

const StatusTag = ({ status = LeaveStatusEum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { leaves } = dictionaryList[userLanguage];
  let color = ColorsEnum.Primary;
  switch (status) {
    case LeaveStatusEum.Approved:
      color = ColorsEnum.Success;
      break;
    case LeaveStatusEum.Declined:
      color = ColorsEnum.Danger;
      break;
    case LeaveStatusEum.Cancelled:
    case LeaveStatusEum.Declined:
      color = ColorsEnum.Cancelled;
      break;
    case LeaveStatusEum.Hold:
      color = ColorsEnum.Hold;
      break;
    case LeaveStatusEum.InActive:
      color = ColorsEnum.InActive;
      break;
    case LeaveStatusEum.NotRequired:
      color = ColorsEnum.NotRequired;
      break;
    default:
      color = ColorsEnum.Primary;
  }
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {leaves.statusEnum[status]}
      </Tag>
    </>
  );
};

export default StatusTag;
