import { jsonToFormData } from "../../../../utils/base";
import MasterConfig from "../../../../utils/services/MasterConfig";
import { getAllRequisitionOffer_dto } from "./dto";

export const getAllRequisitionService = (data) => {
  return MasterConfig.post(`api/Requisition/GetAllRequisition`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addRequisitionService = async (data) => {
  const formData = jsonToFormData(data);
  return MasterConfig.post(`api/Requisition/AddRequisition`, formData)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const addRequisitionOfferService = async (data) => {
  const formData = jsonToFormData(data);
  return MasterConfig.post(`api/Requisition/AddRequisitionOffer`, formData)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetRequisitionByIdService = (id) => {
  return MasterConfig.get(`api/Requisition/GetRequisitionById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetAllRequisitionOfferService = (data) => {
  data = getAllRequisitionOffer_dto(data);
  return MasterConfig.post(`api/Requisition/GetAllRequisitionOffer`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const cancelRewardService = (id) => {
  return MasterConfig.delete(`api/Reward/RewardCancel?rewardId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};
export const updateRequisitionOfferStatusService = (data) => {
  let id = data.id;
  let status = data.status;
  return MasterConfig.put(
    `api/Requisition/UpdateRequisitionOfferStatus?id=${id}&status=${status}`
  )
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getRequisitionOfferByIdService = (data) => {
  let id = data.id;
  return MasterConfig.get(`api/Requisition/GetRequisitionOfferById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetRequisitionLinkByIdService = (id) => {
  return MasterConfig.get(`api/Requisition/GetRequisitionLinkById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllRequisitionsPagingService = (data) => {
  return MasterConfig.post(`api/Requisition/GetAllRequisitionPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })
  
  }