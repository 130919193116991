import {STRINGS} from "../../../../utils/base";
import {ReactionTypeEnum} from "../utils/enums/reactionTypeEnum";
import {ReactionModuleEnum} from "../utils/enums/reactionModuleEnum";
import CommentReaction from "./commentReaction";
import {useDispatch} from "react-redux";
import {addReactionAction} from "../store/reactionAction";
import { commentTypeEnum } from "../../../sharedComponents/Comment/enum/enum";

function ReactionWrapper({referenceId = STRINGS.DEFAULTS.guid,
                            raectionModule = ReactionModuleEnum.Feed,
                             myReaction = ReactionTypeEnum.NoReaction,
                             reactionCount = 0 ,
                             module = commentTypeEnum.Feed,
                            parentId = STRINGS.DEFAULTS.guid,
                            baseId =   STRINGS.DEFAULTS.guid,
                            identifier
                            }) {

    console.log(myReaction,"raectionType",identifier)

    const dispatch = useDispatch();
    const addReaction= (reactionType = ReactionTypeEnum.NoReaction)=>{
        console.log("reactionType" , reactionType)
        dispatch(addReactionAction({
            referenceId: referenceId,
            reactionModule: raectionModule,
            reactionType: reactionType,
            parentId : parentId,
            baseId:baseId,
            identifier:identifier,
            module:module
        }));

    }

    return (<>
        {
             <CommentReaction
                reactionCount = {reactionCount}
                myReaction = {myReaction}
                onChangeReaction = {addReaction}
            />
        }
    </>);
}


export default ReactionWrapper;