import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  addFormService,
  submitFormService,
  getAllFormsService,
  GetFormByIdService,
  updateFormService,
  getAllFormspagingService,
  GetAllFormAttemptService,
  GetFormToAttemptService,
} from "../services/service";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const getAllForms = createAsyncThunk(
  "Form/getAllForms",
  async (
    { filter, pageSize, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllFormspagingService({ ...filter, pageSize });
      console.log(response, "responseeee tablee");
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    } else {
      const response = await getAllFormsService(filter);
      if (!response.responseCode) {
        message.error("Something went wrong");
      }
      return response.data;
    }
  }
);

export const addForm = createAsyncThunk(
  "Form/CreateForm",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addFormService(data);
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Form Created");
      console.log("res from actions", res);
      return res;
    } else {
      message.error(res.statusText);
      return rejectWithValue(res.statusText);
    }
  }
);

export const submitForm = createAsyncThunk(
  "Form/SubmitForm",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await submitFormService(data);
    if (res.data?.responseCode === responseCode.Success) {
      message.success("Form Submitted");
      return res;
    } else {
      message.error(res.data?.message);
      return rejectWithValue(res.data?.message);
    }
  }
);

export const updateForm = createAsyncThunk(
  "Form/updateForm",
  async (data, { dispatch, getState, rejectWithValue }) => {
    // console.log(data, "data in update async");
    const res = await updateFormService(data);

    if (res.data.responseCode) {
      if (res.data.responseCode === responseCode.Success)
        message.success("Form updated successfully!");
    } else {
      message.error(res.statusText);
      return rejectWithValue(res.statusText);
    }

    return res;
  }
);

export const getFormById = createAsyncThunk(
  "Form/getFormById",
  async ({ id }) => {
    const response = await GetFormByIdService(id);
    return response.data;
  }
);

export const getAllFormAttemptAction = createAsyncThunk(
  "Form/getAllFormAttempt",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await GetAllFormAttemptService(id);
    if (res?.responseCode !== responseCode.Success) {
      message.error(res.message);
      return rejectWithValue(res.message);
    }

    return res;
  }
);

export const GetFormToAttemptAction = createAsyncThunk(
  "Form/GetFormToAttempt",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const res = await GetFormToAttemptService(id);
    console.log("ccccccccccccccc", res);
    if (res?.responseCode !== responseCode.Success) {
      message.error(res.message);
      return rejectWithValue(res.message);
    }

    return res;
  }
);
