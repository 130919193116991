import styled from "styled-components";
import Button from "../../../../../sharedComponents/button/index";

 export const ActionButton = styled.button`
  background-color: ${(props) => props.BackgroundColor || "palevioletred"};
  color: #fff;
  font-size: 12px;
  padding: 12px;
  border-radius: 6px;
  height: 34px;
  line-height: 0;
  margin-left: 3px;
  @media (max-width: 768px) {
    border-radius: 6px;
    height: 30px;
    font-size: 12px;
    padding: 12px;
  }
`; 