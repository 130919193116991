import React, { useState, useRef, useEffect } from "react";

function ExpandableDescription({
  label = "Description",
  description = "",
  maxHeight = `[4.5rem]`,
}) {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTextClipped, setIsTextClipped] = useState(false);
  const descriptionRef = useRef(null);

  const checkTextClipped = () => {
    if (descriptionRef.current) {
      const element = descriptionRef.current;
      setIsTextClipped(element.scrollHeight > element.clientHeight);
    }
  };

  useEffect(() => {
    checkTextClipped(); // Initial check
    window.addEventListener("resize", checkTextClipped); // Check on window resize

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", checkTextClipped);
    };
  }, [description]);

  const seeMoreHandler = (e) => {
    e.preventDefault();
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }
  const seeLessHandler = (e) => {
    e.preventDefault();
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }
  return (
    <div>
      <label className="font-bold">{label}</label>
      <div className="relative mt-2">
        <p
          ref={descriptionRef}
          className={`break-words overflow-hidden mb-[0px] ${isExpanded ? "" : `max-h-[4.5rem]`
            }`}
        >
          {description}
        </p>
        {isTextClipped && !isExpanded && (
          <>
            <div className="absolute bottom-0 right-0 w-full h-6 bg-gradient-to-t from-white to-transparent"></div>
            <p
              className="text-blue-500 absolute bottom-0 right-0 mb-1 cursor-pointer bg-white p-1"
              onClick={(e) => seeMoreHandler(e)}
            >
              ... see more
            </p>
          </>
        )}
        {isExpanded && (
          <p
            className="text-blue-500 mt-[0px] mb-[0px] cursor-pointer"
            onClick={(e) => seeLessHandler(e)}
          >
            see less
          </p>
        )}
      </div>
    </div>
  );
}

export default ExpandableDescription;
