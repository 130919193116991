import React from "react";

const InvoiceItemList = ({ billingData }) => {
  const { details: invoiceDetail, netAmount } = billingData;
  return (
    <div className="invoiceItemContainer">
      <div className="invoice-list">
        <div className="invoice-list-header">
          <div className="w-[10%]">QUANTITY</div>
          <div className="w-[30%]">DESCRIPTION</div>
          <div className="w-[10%]">UNIT PRICE</div>
          <div className="w-[10%]">PRICE</div>
        </div>
        {invoiceDetail.map((item, index) => (
          <div key={index} className="invoice-list-item">
            <div>{item.qty}</div>
            <div>{item.item}</div>
            <div>${item.rate.toFixed(2)}</div>
            <div>${item.amount.toFixed(2)}</div>
          </div>
        ))}
      </div>
      <div className="invoice-summary">
        <div></div>
        <div></div>
        <div>SUBTOTAL</div>
        <div>${netAmount.toFixed(2)}</div>
      </div>
      <div className="invoice-summary total">
        <div></div>
        <div></div>
        <div>Total</div>
        <div>${netAmount.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default InvoiceItemList;
