export const ResignationFilterSortEnum = {
    CreateDateAsc: 1,
    CreateDateDesc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
}

export const resignationTableSort = {
    referenceNo: { descend: ResignationFilterSortEnum.ReferenceNoDesc, ascend: ResignationFilterSortEnum.ReferenceNo },
    createDate: { descend: ResignationFilterSortEnum.CreateDateDesc, ascend: ResignationFilterSortEnum.CreateDateAsc },
    updateDate: { descend: ResignationFilterSortEnum.UpdateDateDesc, ascend: ResignationFilterSortEnum.UpdateDateAsc },

}