import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import {
  getAllPages,
  addPages,
  getPagesIdAction,
  addPagesMember,
  removePagesMember,
  updatePagesStatus,
  getAllPagesPaging,
  changePagesStatus,
} from "./action.js";

const initialState = {
  data: null,
  newItem: null,
};

const PagesSlice = createSlice({
  name: "Pages",
  initialState,
  
  reducers: {
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined") delete state?.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    handleUpdateMembers: (state, { payload }) => {
      const { identifier, recordId, members } = payload;
      if (typeof state.data !== "undefined" && state.data[identifier]) {
        state.data[identifier] = state.data[identifier].map((item) => {
          // If the item's id matches the recordId, update its members
          if (item.id === recordId) {
            return {
              ...item,
              members: members,
            };
          }
          return item;
        });
      }
    },
    readCountforPages: (state, { payload }) => {
      const id = payload;
      const currentState = current(state);
      try {
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              referenceItem = {
                ...referenceItem,
                notificationCount: 0,
              };
              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });

          state.data = updatedState;
          state.newCountId = id;
        }
      } catch (e) {}
    },
    handleNotificationNewItem: (state, { payload }) => {
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === payload.featureId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log("===error===customApproval", e);
      }
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getAllPagesPaging.fulfilled, (state, action) => {
        const { identifier, filter } = action?.meta?.arg;
        const data = action.payload?.data ?? [];
        state.data = { ...state.data, [identifier]: data.data };

      })
      .addCase(addPages.fulfilled, (state, { payload }) => {
        state.newItem = payload.data;
      })
      .addCase(changePagesStatus.fulfilled, (state, { payload  , meta}) => {
        const { identifier } = meta?.arg;

        let indexx = state.data[identifier].findIndex(({id})=> id === payload.data.id)
        state.data[identifier][indexx].status = payload.data.status
      })
      .addCase(getPagesIdAction.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      });
  },
});

export const {
  removeIdentifier,
  handleNewItem,
  handleUpdateMembers,
  readCountforPages,
  handleNotificationNewItem,
} = PagesSlice.actions;
export default PagesSlice.reducer;
