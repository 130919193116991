import MasterConfig from "../../../../utils/services/MasterConfig";
import { getCustomTag_dto } from "./dto";

export const getAllProjectRoleService = (id) => {
  console.log(id, "getAllCustomTagService");
  return MasterConfig.get(`api/Project/GetAllProjectRole?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const addProjectRoleService = (args) => {
  return MasterConfig.post(`api/Project/AddProjectRole`, args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const addProjectRoleMemberService = (data) => {
//   let id = data.id;
//   let memberId = data.memberId;
//   let member = [
//     {
//       memberId: memberId,
//     },
//   ];
console.log(data,"datadata");
  return MasterConfig.post(`api/Project/AddProjectRoleMember?roleId=${data?.id}`, data?.member)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getAllCustomTagMemberService = (id) => {
  return MasterConfig.get(`api/CustomTag/GetAllCustomTagMember?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const updateProjectRoleService = (args) => {
  return MasterConfig.post(`api/Project/UpdateProjectRole`, args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const removeProjectRoleService = (id) => {
  return MasterConfig.delete(`api/Project/RemoveProjectRole?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeCustomTagMemberService = (data) => {
  let id = data.id;
  let memberId = data.memberId;
  return MasterConfig.post(`api/CustomTag/RemoveCustomTagMember?id=${id}`,[memberId,])
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const AssignProjectRoleToMemberService = (data) => {

  return MasterConfig.post(`api/Project/AssignProjectRoleToMember?projectId=${data.projectId}&memberId=${data.memberId}` ,data.Roles)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

