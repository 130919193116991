import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  addUserBankInfoAction,
  getAllBankDetailByUser,
  updateUserBankInfoAction,
  getCitiesBankAction
} from './actions';

const initialState = {
  userBankDetails: [],
  loader: false,
  success: false,
  bankCities:[],
  loadingData:false,
};

const bankInfoSlice = createSlice({
  name: 'bankDetail',
  initialState,
  reducers: {
    resetBankInfoState: (state) => {
      state.loader = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBankDetailByUser.fulfilled, (state, action) => {
        // console.log(action.payload, 'getBankDetailsByUserService Slice');
        state.userBankDetails = action.payload.data;
        state.loader = false;
        state.success = true;
      })
      .addCase(updateUserBankInfoAction.fulfilled, (state, action) => {
        // console.log(action.payload, 'updateUserEmergencyContactAction Slice');
        state.loader = false;
        state.success = true;
        const index = state.userBankDetails.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.userBankDetails[index] = action.payload;
      }

      })
      .addCase(addUserBankInfoAction.fulfilled, (state, { payload }) => {
        state.userBankDetails.push(payload[0]);
        state.loader = false;
        state.success = true;
      })
      .addCase(getCitiesBankAction.fulfilled, (state, { payload }) => {
        state.bankCities = payload.data;
        state.loadingData = false;
      })
      .addMatcher(isPending(getCitiesBankAction), (state) => {
        state.loadingData = true;
      })
      .addMatcher(
        isPending(
          ...[
            updateUserBankInfoAction,
            addUserBankInfoAction,
            getAllBankDetailByUser,
          ]
        ),
        (state) => {
          console.log('pending state');
          state.loader = true;
        }
      )
      .addMatcher(
        isRejected(
          ...[
            updateUserBankInfoAction,
            addUserBankInfoAction,
            getAllBankDetailByUser,
          ]
        ),
        (state) => {
          console.log('rejected state');
          state.loader = false;
        }
      );
  },
});

export default bankInfoSlice.reducer;
export const { resetBankInfoState } = bankInfoSlice.actions;
