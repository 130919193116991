import React from "react";
import { DocumentTypeEnum } from "../../../../../utils/DocumentTypeEnum";
import { STRINGS } from "../../../../../../../../utils/base";
import UpdateDocument from "./updateDocument";
import UpdateAttachment from "./updateAttchment";

const UpdateDocumentComposers = ({
  referenceId,
  referenceType,
  documentType,
  onClose,
  item = {},
}) => {
  return (
    <>
      {DocumentTypeEnum.IsDocumentSuite(documentType) && (
        <UpdateDocument
          referenceId={referenceId}
          referenceType={referenceType}
          documentType={documentType}
          parentId={STRINGS.DEFAULTS.guid}
          OnClose={onClose}
          item={item}
        />
      )}
      {documentType === DocumentTypeEnum.Attachment && (
        <UpdateAttachment
          referenceId={referenceId}
          referenceType={referenceType}
          documentType={documentType}
          OnClose={onClose}
          item={item}
        />
      )}
    </>
  );
};

export default UpdateDocumentComposers;
