import React, { useContext } from "react";
import { Tag } from "antd";
import { DocumentStatusEnum } from "../../utils/DocumentStatusEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { getDocStatusLabelAndColor } from "../../constant";
import { dictionaryList } from "../../../../../utils/localization/languages";


export default function DriveStatusTag({ status = DocumentStatusEnum.InProcess }) {


  const { userLanguage } = useContext(LanguageChangeContext);
  const { document } = dictionaryList[userLanguage];
  const { status: statusLabels } = document;
  const { label, color } = getDocStatusLabelAndColor("", statusLabels)[status];

  return (
    <Tag className="statusTag" style={{ backgroundColor: color }}>
      {label}
    </Tag>
  );
}