import MasterConfig from "../../../../../src/utils/services/MasterConfig";
export const GetAllSuggestionService = (data) => {
    return MasterConfig.post(`api/Suggestion/GetAllSuggestion`, data)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  };
  