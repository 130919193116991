export const TravelFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    ReferenceNoDesc: 3,
    ReferenceNoAsc: 4,
    TravelEndDateDesc: 5,
    TravelStartDate: 6,
    UpdateDateDesc: 7,
    UpdateDateAsc: 8,
    // SubjectDesc:3,
    // SubjectAsc:4,
    // StatusDesc:5,
    // StatusAsc:6,
    // ApproverStatusDesc:7,
    // ApproverStatusAsc:8,
    // AgentStatusDesc:9,
    // AgentStatusAsc:10,

}

export const travelTableSort = {
    referenceNo: { descend: TravelFilterSortEnum.ReferenceNoDesc, ascend: TravelFilterSortEnum.ReferenceNoAsc },
    subject: { descend: TravelFilterSortEnum.SubjectDesc, ascend: TravelFilterSortEnum.SubjectAsc },
}