import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import classNames from "classnames";
import { STRINGS } from "../../../utils/base";

const { Title, Text } = Typography;

const SuccessPage = ({ title = "", message = "", extraHTML = null }) => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 w-full flex-col">
      <Card
        className=" bg-white
          shadow-md
          rounded-lg
          p-6
          max-w-md
          w-full
          text-center
          !my-auto"
      >
        <CheckCircleOutlined className="!text-green-600 text-6xl mb-4" />
        <Title level={3}>{title}</Title>
        <Text>{message}</Text>
        {extraHTML && extraHTML}
      </Card>
      <footer>
        <div>{STRINGS.COPY_RIGHTS}</div>
      </footer>
    </div>
  );
};

export default SuccessPage;
