import { useContext, useEffect, useState } from "react";
import { Button, Skeleton, Tag } from "antd";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { useDispatch, useSelector } from "react-redux";
import { getExpenseById } from "../../../../store/actions";
import { ApprovalsModule } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import { readCountforExpense } from "../../../../store/slice";
import { removeIdentifier } from "../../../../store/slice";
import RemarksApproval from "../../../../../../sharedComponents/AppComponents/Approvals/view";
import Attachments from "../../../../../travel/view/UI/Attachments";
import { expenseCategory } from "../../../UI/expenseCategory/expenseCategory";
import moment from "moment";
import UserInfo from "../../../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import { BiWorld } from "react-icons/bi";
import { getStatusLabelAndColor } from "../../../../../../sharedComponents/AppComponents/Approvals/enums";
import { ApprovalDictionary } from "../../../../../../sharedComponents/AppComponents/Approvals/localization";
import { ExpenseReferenceTypeEnum } from "../../../../utils/expenseReferenceTypeEnum";
import { expenseReferenceTypeHandler } from "../../../../utils/expenseReferenceTypeEnum";
import StatusTag from "../../../UI/statusTag";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import "../../../../style/style.css"
import ReferenceTypeTag from "../../../UI/ReferenceTypeTag";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import ExpandableDescription from "../../../../../../sharedComponents/expandableDescription/expandableDescription";
import { STRINGS } from "../../../../../../../utils/base";
import { ExpenseApprovalStatusEnum } from "../../../../utils/expenseStatusEnum";
import { TransactionReferenceTypeEnum } from "../../../../../createVoucher/enum/TransactionReferenceTypeEnum";
import CustomModal from "../../../../../workboard/Modal/CustomModal";
import VoucherPrint from "../../../../../createVoucher/view/voucherPrintModal";
import Disperse from "../../../../../disperse/view/UI/Disperse";
import PostVoucher from "../../../../../createVoucher/view/postVoucher/UI/PostVoucher";
import convertUTCtoLocal, { timeFromNow } from "../../../../../../sharedComponents/DateHandler/dateUTCtoLocal";

function ExpenseDetail({ id, index }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { expenses, sharedLabels, status: statusLabels } = dictionaryList[userLanguage];


  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const [state, setState] = useState({
    identifier: moment().unix(),
    expenseDetail: {
      creator: {},
      description: "",
      approvers: [],
      executors: [],
      financers: [],
      amount: 0,
      categoryId: 0,
      status: 1,
      referenceNo: 0,
      referenceType: 0,
      category: "",
      value: "",
      createDate: "",
      attachments: "",
      isLoading: true,
      header: "",
      notificationCount: 0,
      voucherId: STRINGS.DEFAULTS.guid,
      voucherNo: "",
    },
    approvers: {
      isOpen: false,
      data: [],
    },
    executors: {
      isOpen: false,
      data: [],
    },
    financers: {
      isOpen: false,
      data: [],
    },
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const { user } = useSelector((state) => state.userSlice);


  const { data } = useSelector((state) => state.expenseSlice);

  let expenseDetail = data !== null ? data[state.identifier] : [];
  expenseDetail =
    expenseDetail && expenseDetail?.length > 0 && expenseDetail[0];

  useEffect(() => {
    id && dispatch(getExpenseById({ id: id, identifier: state.identifier }));
  }, []);



  useEffect(() => {
    expenseDetail &&
      setState({
        ...state,
        expenseDetail: {
          ...state.expenseDetail,
          ...expenseDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: expenseDetail?.approvers,
        },
        executors: {
          ...state.executors,
          data: expenseDetail?.executors,
        },
        financers: {
          ...state.financers,
          data: expenseDetail?.financers,
        },
      });
  }, [expenseDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const {
    expenseDate,
    referenceNo,
    status,
    description,
    amount,
    categoryId,
    creator,
    header,
    approvers,
    executors,
    financers,
    attachments,
    createDate,
    referenceType,
    referenceId,
    notificationCount,
    reference = null,
    currencyCode = "USD",
    currencySymbol = "$",
    voucherId = STRINGS.DEFAULTS.guid,
    voucherNo = "",
  } = state.expenseDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Expense,
          featureId: id
        },

      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforExpense(id))
      })
    }
  }, [notificationCount])


  const category = expenseCategory.filter((cate) => cate.id === categoryId)[0];
  const { label, color } = getStatusLabelAndColor("", statusLabels)[status];

  const handleOpenPrintVoucher = (e) => {
    setIsOpenModal(true);
  }
  if (state.expenseDetail.isLoading) return <Skeleton />;




  return (
    <>
      <div className="expenseDetail detailedViewComposer">
        <div className="expenseCard__header">
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              status={creator?.userActiveStatus}
              profileId={creator?.id}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation}
                  time={timeFromNow(createDate)}
                  icon={<BiWorld />}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            < StatusTag status={status}></StatusTag>
          </div>
        </div>
        <div className="expenseCard__body">
          <div class="flex justify-between">
            <div className="expenseCard__para">
              <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </div>
            {/* {referenceType === ExpenseReferenceTypeEnum.Group ? (
              <span class="inline-flex items-center pl-[76px] pr-[85px] h-5 text-xs font-medium bg-blue-200 text-black-500 rounded-md">
                <b>Group</b>
              </span>
            ) : referenceType === ExpenseReferenceTypeEnum.Project ? (
              <span class="inline-flex items-center pl-[76px] pr-[85px] h-5 text-xs font-medium bg-[#faf7aa] text-black-500 rounded-md">
                <b>Project</b>
              </span>
            ) : referenceType === ExpenseReferenceTypeEnum.General ? (
              <span class="inline-flex items-center pl-[76px] pr-[85px] h-5 text-xs font-medium bg-green-300 text-black-500 rounded-md">
                <b>General</b>
              </span>
            ) : null} */}
            <ReferenceTypeTag referenceId={referenceId} referenceType={referenceType} reference={reference} />
          </div>

          <div className="!w-max m-4 ml-auto attachmentBox">
            <Attachments
              data={attachments}
              key={{ data: attachments }}
              toShow={1}
              onClick={() => { }}
              size={"50px"}
            />
          </div>
          <div className="expensedetail">
            <div className="expensedetail__header">
              <div className="left">
                <span className="primaryTag">
                  {category.image}
                  {category.name}
                </span>
                {/* {referenceType !== 1 ? (
                  <div className="referenceType bg-gray-300 rounded-md h-[34px] w-[80px] border border-[#526bb1] text-center text-[#526bb1]">
                    <span className="text-gray-500 text-xs">
                      {expenseReferenceTypeHandler(referenceType) || "N/A"}
                    </span>
                  </div>
                ) : null} */}
              </div>
              <div className="right">
                {(status === ExpenseApprovalStatusEnum.Approved && financers?.some(item => item.approverId === user.id)) && (
                  <Disperse
                    id={id}
                    label="Disburse"
                    TransactionReferenceType={
                      TransactionReferenceTypeEnum.Expense
                    }
                  />
                )}

                {voucherId === STRINGS.DEFAULTS.guid && (status === ExpenseApprovalStatusEnum.Disbursed || status === ExpenseApprovalStatusEnum.Completed) && <PostVoucher
                  label="Post Voucher"
                  id={id}
                  TransactionReferenceType={TransactionReferenceTypeEnum.Expense}
                />}
                {(voucherId !== STRINGS.DEFAULTS.guid && financers?.some(item => item.approverId === user.id)) && voucherNo && <Button type="link" className="!text-[12px] !mt-[-6px] !text-[var(--currentThemeColor)] !font-semibold" onClick={(e) => handleOpenPrintVoucher(e)}>{voucherNo}</Button>
                }
                <p><span className="text-sm">{currencySymbol}</span> {amount.toLocaleString()}</p>
              </div>
            </div>
            <div className="expensedetail__footer">
              <div className="left">
                <span>{expenses.ExpenseDate}</span>
                <span> {convertUTCtoLocal(expenseDate, "DD MMM YYYY")}</span>
              </div>
              <div className="right">
                <span>{sharedLabels.Header}:</span>
                <span> {header}</span>
              </div>
            </div>
          </div>
        </div>
        <RemarksApproval
          module={ApprovalsModule.ExpenseApproval}
          status={status}
          reference={expenseDetail?.id}
          data={state.approvers.data && state.approvers.data}
          title={sharedLabels.Approvers}
          referenceType={referenceType}
          referenceTypeID={referenceId}
          itemCreator={creator}

        />
        <RemarksApproval
          module={ApprovalsModule.ExpenseExecutor}
          status={status}
          reference={expenseDetail?.id}
          data={state.executors.data && state.executors.data}
          title={expenses.Executors}
          referenceType={referenceType}
          referenceTypeID={referenceId}
          itemCreator={creator}

        />
        <RemarksApproval
          module={ApprovalsModule.ExpenseFinance}
          status={status}
          reference={expenseDetail?.id}
          data={state.financers.data && state.financers.data}
          title={expenses.Financers}
          referenceType={referenceType}
          referenceTypeID={referenceId}
          itemCreator={creator}
        />

        <CustomModal
          isModalVisible={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          width={"70%"}
          title={"Voucher"}
          footer={null}
          children={<VoucherPrint id={voucherId} />}
          className={""}
        />
      </div>
    </>
  );
}
export default ExpenseDetail;
