import { createSlice, isPending, isRejected, current } from "@reduxjs/toolkit";
import {
  addProjectAccessRole,
  getAllProjectAccessRoles,
  updateProjectAccessRoleById,
  getProjectAccessRoleById,
  AssignProjectMemberToAccessRole,
} from "./actions";

const initialState = {
  accessRoles: [],
  singleAccessRole: [],
  loader: false,
  createLoader: false,
  isSingleAccessRoleLoaded: false,
  success: false,
  error: false,
};

const projectsAccessRolesSlice = createSlice({
  name: "projectsAccessRole",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProjectAccessRole.fulfilled, (state, { payload }) => {
        state.accessRoles.push(payload.data);
        state.success = true;
        state.createLoader = false;
      })
      .addCase(getAllProjectAccessRoles.fulfilled, (state, { payload }) => {
        state.accessRoles = payload.data;
        state.loader = false;
      })
      .addCase(getProjectAccessRoleById.fulfilled, (state, { payload }) => {
        state.singleAccessRole = payload.data;
        state.isSingleAccessRoleLoaded = false;
        state.loader = false;
      })
      .addCase(AssignProjectMemberToAccessRole.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.accessRoles.findIndex((item) => item.id === data[0]?.accessRoleId);

        if (index !== -1) {
          state.accessRoles[index].accessRoleUsers = [
            ...state.accessRoles[index].accessRoleUsers,
            ...payload.data,
          ];
        }

        console.log(index, "currentStatecurrentState");
      })
      .addCase(updateProjectAccessRoleById.fulfilled, (state, { payload }) => {
        state.accessRoles = state.accessRoles.map((obj) => {
          if (obj.id === payload.data.id) {
            delete payload?.data?.accessRoleUsers
            return { ...obj, ...payload.data };
          }
          return obj;
        });
        state.success = true;
        state.createLoader = false;
      })
      .addMatcher(isPending(...[addProjectAccessRole]), (state) => {
        state.createLoader = true;
        state.success = false;
      })
      .addMatcher(isPending(...[updateProjectAccessRoleById]), (state) => {
        state.createLoader = true;
        state.success = false;
      })
      .addMatcher(isPending(...[getAllProjectAccessRoles]), (state) => {
        state.loader = true;
        state.success = false;
      })
      .addMatcher(isPending(getProjectAccessRoleById), (state) => {
        state.loader = false;
        state.success = false;
        state.isSingleAccessRoleLoaded = true;
      })
      .addMatcher(
        isRejected(
          ...[
            addProjectAccessRole,
            getAllProjectAccessRoles,
            getProjectAccessRoleById,
            updateProjectAccessRoleById,
          ]
        ),
        (state) => {
          state.loader = false;
          state.success = false;
          state.isSingleAccessRoleLoaded = false;
          state.error = true;
          state.createLoader = false;
        }
      );
  },
});


export default projectsAccessRolesSlice.reducer;
