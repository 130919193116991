import React, { useContext } from "react";
import { Tag } from "antd";
import "./style.css";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import {
  InvoiceStatusColor,
  InvoiceStatusEnum,
} from "../../enum/InvoiceStatusEnum";
import convertUTCtoLocal from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";
import { getMonthName } from "../../../billing/enum";
import "./style.css";
import InvoiceItemList from "./invoiceItemList";
import InvoiceItemDetail from "./invoiceItemDetail";
const InvoiceItem = ({ billingData }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { administration, sharedLabels, billing } = dictionaryList[
    userLanguage
  ];

  return (
    <>
      <div className="printable-invoice">
        <InvoiceItemDetail billingData={billingData} />
        <InvoiceItemList billingData={billingData} />
      </div>
    </>
  );
};

export default InvoiceItem;
