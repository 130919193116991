import { Col, Row } from "antd";
import ContentContainer from "./contentContainer";
import { PannelTypeEnum } from "../enums/enum";
import { DirectionEnum } from "../../../../utils/Shared/enums/directionEnum";

function ContentWrapper({ data = [] }) {
  const leftPanel = data.filter((x) => x.direction === DirectionEnum.Left);
  const rightPanel = data.filter((x) => x.direction === DirectionEnum.Right);
  return (
    <>
      <Row style={{ height: "100%", backgroundColor: "#181717" }}>
        <Col
          xs={rightPanel ? 24 : 24}
          sm={rightPanel ? 12 : 24}
          md={rightPanel ? 16 : 24}
          lg={rightPanel ? 18 : 24}
          style={{
            height: "100%",
            // width: "100%",
            backgroundColor: "#181717",
          }}
        >
          <ContentContainer
            type={leftPanel[0].data[0].panelType}
            data={leftPanel[0].data[0].data[0]}
          />
        </Col>

        {rightPanel[0].data[0].panelType !== PannelTypeEnum.NoComponnent && (
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <ContentContainer
              type={rightPanel[0].data[0].panelType}
              data={rightPanel[0].data[0].data[0]}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

export default ContentWrapper;
