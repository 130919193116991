import React from "react";
import { defaultUiid } from "../../../../../utils/Shared/enums/enums";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import AssetsDetailCard from "../../../assets/view/assetsDetailedCard";
import SignupDetail from "../../../companies/view/Signup/Detail";
import ComplainDetail from "../../../complain/view/Page/Detail/DetailItem/complainDetail";
import ExpenseDetail from "../../../expense/view/Page/Detail/DeatailItem/ExpenseDetail";
import SalaryDetailCard from "../../../salary/view/Page/Detail/DetailItem/salaryDetail";
import TravelDetail from "../../../travel/view/Page/Detail/DetailItem/travelDetail";
import WarningDetail from "../../../warning/view/Page/Detail/DetailItem/warningDetail";
import LeaveDetail from "../../../leave/view/UI/detailCardItem";
import ResignationDetail from "../../../resignation/view/Page/Detail/DetailItem/resignationDetail";
import AppraisalDetail from "../../../appraisalModule/view/Page/Detail/DetailItem/AppraisalDetail";
import PromotionDetail from "../../../promotion/view/UI/PromotionDetail";
import DocumentDetailCard from "../../../documents/view/UI/documentDetailCard";
import LoanDetail from "../../../loan/view/UI/loanDetail";
import { RewardDetail } from "../../../reward/view/UI/detailCardItem";
import BonusDetail from "../../../bonus/view/UI/detailCardItem";
import CustomApprovalDetail from "../../../CustomApprovals/view/UI/detailCardItem";
import QuotationDetail from "../../../quotation/view/UI/detailCardItem";
import FormDetail from "../../../forms/view/UI/detailCardItem";
import PayrollDetailCard from "../../../payroll/view/UI/payrollDetailCard";
import CareerDetailCard from "../../../careers/view/UI/CareerDetailCard";
import RequisitionDetailCard from "../../../requisition/view/DetailCard";

const ApprovalDetailedView = ({ approvalDetailData }) => {

  function getConditionalyModule({ module, referenceId: id }) {

    switch (module) {
      case ApprovalsModule.SalaryApproval:
        return <SalaryDetailCard id={id} />;

      case ApprovalsModule.TravelApproval:
      case ApprovalsModule.TravelAgent:
        return <TravelDetail id={id} key={id} />;

      case ApprovalsModule.DocumentApproval:
        return <DocumentDetailCard key={id} id={id} />;

      case ApprovalsModule.RewardApproval:
        return <RewardDetail id={id} />;

      case ApprovalsModule.BonusApproval:
        return <BonusDetail id={id} />;

      case ApprovalsModule.ComplainApproval:
        return <ComplainDetail id={id} />;

      case ApprovalsModule.PromotionApproval:
        return <PromotionDetail id={id} />;

      case ApprovalsModule.LoanApproval:
        return <LoanDetail id={id} />;

      case ApprovalsModule.SignupApproval:
        return <SignupDetail id={id} />;

      case ApprovalsModule.ExpenseApproval:
      case ApprovalsModule.ExpenseExecutor:
      case ApprovalsModule.ExpenseFinance:
        return <ExpenseDetail id={id} />;

      case ApprovalsModule.WarningApproval:
        return <WarningDetail id={id} />;

      case ApprovalsModule.AssetApproval:
        return <AssetsDetailCard id={id} />;

      case ApprovalsModule.LeaveApproval:
        return <LeaveDetail id={id} />;

      case ApprovalsModule.ResignationAdminApproval:
      case ApprovalsModule.ResignationExitApproval:
      case ApprovalsModule.ResignationFinanceApproval:
      case ApprovalsModule.ResignationHrApproval:
      case ApprovalsModule.ResignationItApproval:
      case ApprovalsModule.ResignationOtherApproval:
      case ApprovalsModule.ResignationReportingToApproval:
        return <ResignationDetail id={id} />;

      case ApprovalsModule.AppraisalApproval:
        return <AppraisalDetail id={id} />;

      case ApprovalsModule.CustomApproval:
        return <CustomApprovalDetail id={id} />;
      case ApprovalsModule.QuotationApproval:
        return <QuotationDetail id={id} />

      case ApprovalsModule.FormApproval:
        return <FormDetail id={id} />
      case ApprovalsModule.CareerApproval:
        return <CareerDetailCard id={id} />
      case ApprovalsModule.PayrollApproval:
        return <PayrollDetailCard id={id} />

      case ApprovalsModule.RequisitionApproval:
      case ApprovalsModule.RequisitionFinalApproval:
        return <RequisitionDetailCard id={id} />

      default:
        return <></>;
    }
  }

  return <>{getConditionalyModule(approvalDetailData)}</>;
};
export default ApprovalDetailedView;
