import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleFilled } from "@ant-design/icons";
import { DOCUMENT_ENUM } from "../../utils/DocumentEnum";
import { getDocumentRightLabel, getIconByExtensionType } from "../../constant/helpers";
import DriveStatusTag from "./driveStatusTag";
import { STRINGS } from "../../../../../utils/base";
import { CompleteDocumentAction, getDocumentInformation } from "../../store/actions";
import moment from "moment";
import { Skeleton, Tag } from "antd";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import Avatar from "../../../../sharedComponents/Avatar/avatar";
import { PostPrivacyType } from "../../../../../utils/Shared/enums/enums";
import { CommentModuleEnum } from "../../../Comment/utils/enum/commentModuleEnum";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import Comments from "../../../Comment/comments";
import DocumentMemberModal from "../documentMemberWrapper/documentMemberModal";
import DocumentFullScreenModal from "../documentFullScreenModal";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { NOTIFICATION_ENUMS } from "../../../notifiation/utils/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { handleDocumentStatusChange, readCountforDriveList } from "../../store/slice";
import { DocumentStatusEnum } from "../../utils/DocumentStatusEnum";
import { ActionButton } from "../components/fullCard/cardStyle";
import CompleteDocumentWrapper from "./completeDocumentWrapper";
import { DocumentReferenceTypeEnum } from "../../utils/DocumentReferenceTypeEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";

export default function DriveDetailCard({
    driveType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
    driveId = STRINGS.DEFAULTS.guid,
    driveAttachmentId = STRINGS.DEFAULTS.guid,
}) {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { document, sharedLabels } = dictionaryList[userLanguage];
    let { DUCOMENT_TYPE, MEMBER_RIGHT_TYPE } = DOCUMENT_ENUM;
    const [state, setState] = useState({
        identifier: moment().unix(),
        driveDetail: {
            id: STRINGS.DEFAULTS.guid,
            name: "",
            referenceNo: "",
            documentType: driveType,
            description: "",
            path: "",
            members: [],
            approvers: [],
            image: "",
            extensionTypeId: "",
            status: "",
            privacyId: "",
            attachmentId: "",
            attachments: "",
            referenceId: STRINGS.DEFAULTS.guid,
            referenceType: 0,
            creator: {},
            notificationCount: 0,
            isLoading: true

        },
        collaborators: [],
        readers: [],
        isColabrator: false,
        isReader: false,
        isFullScreenModal: false,
        currentRightType: MEMBER_RIGHT_TYPE.COLLABRATOR
    })

    const [isOpenCompleteDocument, setisOpenCompleteDocument] = useState(false)


    const { user } = useSelector((state) => state.userSlice);

    const disptach = useDispatch();

    const handleCompleteDocument = () => {
        setisOpenCompleteDocument(true)
    }


  useEffect(() => {
    const filter = {
      documentId: driveId,
      attachmentId: driveAttachmentId
        ? driveAttachmentId
        : STRINGS.DEFAULTS.guid,
    };
    driveId &&
      disptach(
        getDocumentInformation({
          filter: filter,
          identifier: state.identifier,
        })
      ).then((res) => {
        setState({
            ...state,
            driveDetail: {
                ...state.driveDetail,
                ...res?.payload,
                isLoading: false
            },
        })
      });
  }, [driveId]);

  // useEffect(() => {
    //   driveListData && setState({
    //       ...state,
    //       driveDetail: {
    //           ...state.driveDetail,
    //           ...driveListData,
    //           isLoading: false
    //       },
    //   })

  // }, [driveListData])

    let {
        id,
        name,
        referenceNo,
        documentType,
        description,
        path,
        members,
        approvers,
        image,
        extensionTypeId,
        status,
        privacyId,
        attachmentId,
        attachments,
        referenceId,
        referenceType,
        notificationCount,
        creator
    } = state.driveDetail

    useEffect(() => {
        if (notificationCount > 0) {
            const payload = {
                filter: {
                    featureType: NotificationFeatureTypeEnum.Document,
                    referenceId: driveId
                },
            }

            disptach(readNotificationAction(payload)).then(response => {
                disptach(readCountforDriveList(driveId))
            })
        }

    }, [notificationCount])

    useEffect(() => {
        setState({
            ...state,
            collaborators: members?.filter(
                (it) => it.memberRightType === MEMBER_RIGHT_TYPE.COLLABRATOR
            ),
            readers: members?.filter(
                (it) => it.memberRightType === MEMBER_RIGHT_TYPE.READER
            )
        })
    }, [state.driveDetail])

    const onClosePopUp = (status) => {
        setState({
            ...state,
            isReader: state.isReader && status,
            isColabrator: state.isColabrator && status
        })
    }

    const handleView = () => {
        setState({
            ...state,
            isFullScreenModal: true
        })
    }

    const handleModalForCollabrator = () => {
        setState({
            ...state,
            isColabrator: true,
            currentRightType: MEMBER_RIGHT_TYPE.COLLABRATOR
        })
    }

    const handleModalForReader = () => {
        setState({
            ...state,
            isReader: true,
            currentRightType: MEMBER_RIGHT_TYPE.READER
        })
    }

    const completeDocumentWithoutApprovers = () =>{

        const payload = {
          approvers: [],
          id: driveId,
        };
  
        disptach(CompleteDocumentAction(payload)).then(({ payload }) => {
            disptach(
            handleDocumentStatusChange({ referenceId:driveId})
          );
          });
      
    }

    
    if (state.driveDetail.isLoading) return <Skeleton />;


    return (
        <>

            <div>

                <CompleteDocumentWrapper
                    documentId={driveId}
                    isShow={isOpenCompleteDocument}
                    onClose={() => {
                        setisOpenCompleteDocument(false)
                    }}
                />


                <DocumentMemberModal
                    documentType={documentType}
                    referenceId={driveId}
                    visible={state.isReader || state.isColabrator}
                    setVisible={(status) => onClosePopUp(status)}
                    memberRightType={state.currentRightType}
                />
                <div className="doc-attachment">
                    <img
                        alt=""
                        src={
                            documentType === DUCOMENT_TYPE.image && path
                                ? path
                                : getIconByExtensionType(documentType, extensionTypeId)
                        }
                        onClick={handleView}
                        width={500}
                    />
                </div>

                <div className="flex justify-between mt-5 mb-5">
                    <div className="font-bold text-[12px]">{name}</div>
                    <div className="flex">
                        {referenceNo ? <Tag className="IdTag">{referenceNo}</Tag> : ""}
                        {driveType !== DUCOMENT_TYPE.folder && <DriveStatusTag status={status}></DriveStatusTag>}
                    </div>

                </div>
                <div className="plusButtonContainer">
                    {status === DocumentStatusEnum.Draft && user.id === creator.id && (
                        <div>
                            <ActionButton
                                onClick={handleCompleteDocument}
                                BackgroundColor="var(--currentThemeColor) !important"
                            >
                                {document.CompleteDocument}
                            </ActionButton>
                        </div>
                    )}
                    {status === DocumentStatusEnum.Draft && user.id === creator.id && (
                         <div className="">
                         <ActionButton
                           onClick={completeDocumentWithoutApprovers}
                           BackgroundColor="var(--currentThemeColor) !important"
                         >
                           {document.CompleteDocumentWithoutApprovers}
                         </ActionButton>
                       </div>
                    )}
                </div>

                <hr />

                <div className="font-bold text-[15px] mt-5">{sharedLabels.Description}</div>
                <p className="text-[12px]">{description}</p>
                <hr />

                <div className="flex justify-between mt-5">
                    <div className="font-bold text-[13px]">
                        {getDocumentRightLabel(documentType)}
                    </div>
                    <div className="flex">
                        {state.collaborators ? (
                            <>
                                {getDocumentRightLabel(documentType) === "Collaborators" && (
                                    <PlusCircleFilled
                                        style={{ fontSize: "20px" }}
                                        className="cursor-pointer"
                                        onClick={(e) =>
                                            getDocumentRightLabel(documentType) === "Collaborators" &&
                                            handleModalForCollabrator(e)
                                        }
                                    />
                                )}
                                <Avatar
                                    isAvatarGroup={true}
                                    heading={sharedLabels.members}
                                    membersData={state.collaborators ? state.collaborators : []}
                                />
                            </>
                        ) : (
                            sharedLabels.NotAvailable
                        )}
                    </div>
                </div>

                <div className="flex justify-between mt-5 mb-3">
                    <div className="font-bold text-[13px]">{sharedLabels.readers} </div>
                    <div className="flex" onClick={handleModalForReader}>
                        {privacyId === PostPrivacyType.PUBLIC ? (
                            sharedLabels.Public
                        ) : state.readers?.length > 0 ? (
                            <>
                                {/* {isUserExistInColab && ( */}
                                <PlusCircleFilled style={{ fontSize: "20px" }} />
                                {/* )} */}
                                <Avatar
                                    isAvatarGroup={true}
                                    heading={sharedLabels.members}
                                    membersData={state.readers ? state.readers : []}
                                />
                            </>
                        ) : (
                            sharedLabels.NotAvailable
                        )}
                    </div>
                </div>

                <hr />

                <div className="mt-5 font-bold ">{sharedLabels.Comments}</div>
                {!driveType === DUCOMENT_TYPE.folder ? (
                    <RemarksApproval
                        module={ApprovalsModule.DocumentApproval}
                        status={status}
                        onStatusChanged={(statusChanged) => { }}
                        data={approvers}
                        title={sharedLabels.approvers}
                        itemCreator={creator}
                    />
                ) : (
                    ""
                )}

                <div className="comments mt-[20px]">
                    <Comments
                        referenceId={driveId}
                        module={CommentModuleEnum.Document}
                        referenceType={referenceType}
                        mentionMemberRefId = {referenceId}
                    />
                </div>

        <DocumentFullScreenModal
          fullScreenModalOpen={state.isFullScreenModal}
          onCancel={() =>
            setState({
              ...state,
              isFullScreenModal: false
                        })
                    }
                    data={state.driveDetail}
                    />





            </div></>
    )
}