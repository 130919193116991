import moment from "moment";

// Function to convert local date to UTC date using Moment.js
export default function convertLocalToUTC(localDate, timeOption = "same") {
  let dateTime = moment(localDate);

  // Adjust time based on timeOption
  if (timeOption === "start") {
    dateTime.startOf("day");
  } else if (timeOption === "end") {
    dateTime.endOf("day");
  }

  const utcDate = moment.utc(dateTime);
  return utcDate;
};


// Separate function to format time
export function formatTime(checkTime) {
  return moment()
    .startOf("day")
    .add(moment.duration(Math.abs(checkTime), "seconds"))
    .format("h:mm A");
}

// Higher-order function to format time duration based on the provided unit
export function formatTimeDuration(time, unit) {
  const duration = moment.duration(time, unit);
  const hours = duration.hours();
  const minutes = duration.minutes();
  
  if (time === 0) return "";
  
  return `${hours > 0 ? `${hours}h ` : ""}${minutes > 0 ? `${minutes}m ` : ""}`;
}