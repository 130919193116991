import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { Image, Radio } from "antd";
import { ImgContainer } from "../../../../utils/formImageContainer";

const Poll = (props) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { forms } = dictionaryList[userLanguage];
  const { formQuestionTypeEnum } = forms;
  const { question, handleChange, index, disableSubmit, required } = props;
  const { answers } = question;

  return (
    <>
      <div className="c-row txt-fields bg-clr p_15 gap-3 flex flex-col">
        <label className={` ${required && "required"} label w-fit`} htmlFor="">
          {question.question}
          <span className="text-sm">{` (${
            formQuestionTypeEnum?.[question.answerType]
          })`}</span>
        </label>
        <div className="w-full flex justify-center">
          {question.image !== "" && ImgContainer(question.image, "250px")}
        </div>
        <div className="">
          <Radio.Group
            onChange={(e) => handleChange(e.target.value, index)}
            disabled={disableSubmit}
            className="w-full"
          >
            {answers.map(({ answer, id, image }, index) => (
              <div
                key={id}
                className={`${
                  image ? "w-[50%] inline-flex justify-center mb-4" : "w-full"
                }`}
              >
                <Radio value={id} className="flex items-center space-x-4 p-4">
                  <div className="flex flex-col">
                    {image && ImgContainer(image, "150px")}
                    <span className="f-bold text-[16px]">{answer}</span>
                  </div>
                </Radio>
              </div>
            ))}
          </Radio.Group>
        </div>
        <div className="flex-end"></div>
      </div>
    </>
  );
};

export default Poll;
