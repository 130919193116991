import { PayrollDetailReferenceTypeEnum } from "../../utils/payrollDetailReferenceTypeEnum";

export const calculateBasicSalary = (payrollCalculatedList,onlyChecked = false) => {
    let basicSalaryTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                basicSalaryTotal += item.basicSalary || 0;
            }
        });
    }

    return basicSalaryTotal.toFixed(2);
};

export const calculateLoan = (payrollCalculatedList, onlyChecked = false) => {
    let loanTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                loanTotal += item.loan || 0;
            }
        });
    }
    return loanTotal.toFixed(2);
}

export const calculateAllowance = (payrollCalculatedList, onlyChecked = false) => {
    let allowanceTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                allowanceTotal += item.allowance || 0;
            }
        });
    }
    return allowanceTotal.toFixed(2);
}

export const calculateReimbursement = (payrollCalculatedList, onlyChecked = false) => {
    let totalReimbursement = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                totalReimbursement += item.expenseReimbursement || 0;
            }
        });
    }
    return totalReimbursement.toFixed(2);
}

export const calculateDeduction = (payrollCalculatedList, onlyChecked = false) => {
    let deductionTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                deductionTotal += item.deduction || 0;
            }
        });
    }
    return deductionTotal.toFixed(2);
}

export const calculateTax = (payrollCalculatedList, onlyChecked = false) => {
    let TaxTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                TaxTotal += item.tax || 0;
            }
        });
    }
    return TaxTotal.toFixed(2);
}

export const calculateRebate = (payrollCalculatedList, onlyChecked = false) => {
    let rebateTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                rebateTotal += item.rebate || 0;
            }
        });
    }
    return rebateTotal.toFixed(2);
}

export const calculateBonus = (payrollCalculatedList, onlyChecked = false) => {
    let bonusTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                bonusTotal += item.bonus || 0;
            }
        });
    }
    return bonusTotal.toFixed(2);
}

export const calculateOther = (payrollCalculatedList, onlyChecked = false) => {
    let otherTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                otherTotal += item.other || 0;
            }
        });
    }
    return otherTotal.toFixed(2);
}

export const calculateNetSalary = (payrollCalculatedList, onlyChecked = false) => {
    let netSalaryTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (!onlyChecked || item.isChecked) {
                const netSalary = item.basicSalary +
                    (item.allowance || 0) +
                    (item.bonus || 0) -
                    Math.abs(item.loan || 0) -
                    Math.abs(item.tax || 0) -
                    Math.abs(item.deduction || 0) -
                    Math.abs(item.rebate || 0) +
                    (item.other || 0) +
                    (item.expenseReimbursement || 0);

                netSalaryTotal += netSalary;
            }
        });
    }
    return netSalaryTotal.toFixed(2);
}

export const calculateTotalNetSalary = (payrollCalculatedList) => {
    let netSalaryTotal = 0;

    if (payrollCalculatedList) {
        payrollCalculatedList.forEach((item) => {
            if (item.isChecked) {
                const netSalary = item.basicSalary +
                    (item.allowance || 0) +
                    (item.expenseReimbursement || 0) +
                    (item.bonus || 0) -
                    Math.abs(item.loan || 0) -
                    Math.abs(item.tax || 0) -
                    Math.abs(item.deduction || 0) -
                    Math.abs(item.rebate || 0) +
                    (item.other || 0);

                netSalaryTotal += netSalary;
            }
        });
    }
    return netSalaryTotal.toFixed(2);
}

export const calculateLoanPayment = (details) => {
    let loanPaymentTotal = 0;

    if (details) {
        details?.map((item) => {
            return item?.referenceDetails.forEach((item) => {
                if (item?.referenceType === PayrollDetailReferenceTypeEnum?.Loan && item?.amount < 0) {
                    loanPaymentTotal += item?.amount || 0;
                }
            })
        })
    }
    return loanPaymentTotal.toFixed(2);

}
export const calculateLoanDispersable = (details) => {
    let loanDispersableTotal = 0;

    if (details) {
        details?.map((item) => {
            return item?.referenceDetails.forEach((item) => {
                if (item?.referenceType === PayrollDetailReferenceTypeEnum?.Loan && item?.amount > 0) {
                    loanDispersableTotal += item?.amount || 0;
                }
            })
        })
    }
    return loanDispersableTotal.toFixed(2);

}