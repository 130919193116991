import React, { useEffect, useState } from "react";
import SchedulersComponent from "./SchedulerComponent";
import "antd/dist/antd.css";
import "../Schedule/style/schedular.css";
import { useSelector, useDispatch } from "react-redux";
import { GetDetailForBookAppointment, GetReferenceById } from "./store/action";
import { useParams } from "react-router-dom";
import "./style/UserAndBusinessInfo.css";
import landscapeSystemLogo from "../../../../../src/content/landscapeSystemLogo.jpeg";
import Spinner from "../../../sharedComponents/spinner/spinner";
import { getNameForImage } from "../../../../utils/base";
import miletapLogo from "../../../../../src/content/miletapLogo.svg";
import coverImage from "../../../../content/default-cover.png";
import { STRINGS } from "../../../../utils/base";
import moment from "moment";
import { responseCode } from "../../../../services/enums/responseCode";
import SuccessPage from "../../../sharedComponents/SpecialMessagePages/successPage";
import AppointmentInfo from "./appointmentInfo";

function Info({ UserAndBusinessData }) {
  return (
    <>
      <img src={coverImage} lazy="true" />
      <section className="section-info_1 flex flex-col items-center">
        <div className="!w-16 !h-16 rounded-full overflow-hidden drop-shadow-2xl color-gray-900 image_1 !ml-[0px]">
          {UserAndBusinessData?.userImage &&
          UserAndBusinessData?.userImage !== null ? (
            <img
              src={UserAndBusinessData?.userImage}
              alt="Avatar"
              className="w-full h-full object-cover"
            />
          ) : (
            <div
              className="w-14 h-14 items-center justify-center bg-black text-white text-[12px] font-bold flex"
              style={{ borderRadius: "50%" }}
            >
              {getNameForImage(UserAndBusinessData?.username)}
            </div>
          )}
        </div>
        <h2 className="name_1">{UserAndBusinessData?.username}</h2>
        <p className="stack_1">{UserAndBusinessData?.userDesignation}</p>
        <p className="stack_1">{UserAndBusinessData?.userEmail}</p>
        <p className="stack_1">
          {UserAndBusinessData?.businessName} -{" "}
          {UserAndBusinessData?.businessAddress}
        </p>
      </section>
    </>
  );
}

function Footer({ UserAndBusinessData }) {
  return (
    <>
      <footer className="section_footer_1">
        <ul className="footer_social_1">
          <div className="flex items-center">
            <div className="w-12 h-12 rounded-full overflow-hidden drop-shadow-2xl color-gray-900">
              <img
                src={UserAndBusinessData?.businessImage}
                alt="Avatar"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="ml-4 mt-10">
              <h2 className="font-bold text-lg name_1">
                {UserAndBusinessData?.businessName}
              </h2>
              <p className="text-gray-500 drop-shadow-2xl	color-black-900 stack_1">
                {UserAndBusinessData?.businessAddress}
              </p>
            </div>
            <br />
          </div>
        </ul>
      </footer>
    </>
  );
}

const Scheduler = (referenceId) => {
  const dispatch = useDispatch();
  const [UserAndBusinessData, setUserAndBusinessData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isData, setIsData] = useState(false);
  const { id } = useParams();
  const [appointmentData, setAppointmentData] = useState({
    email: "",
    dateTime: "",
    appointmentWith: "",
  });
  useEffect(() => {
    const startDate = moment
      .utc(new Date())
      .startOf("day")
      .format();
    const endDate = moment
      .utc(new Date())
      .endOf("day")
      .format();
    setLoader(true);
    dispatch(
      GetDetailForBookAppointment({
        token: id,
        startDate: startDate,
        endDate: endDate,
      })
    ).then((response) => {
      if (response?.payload.responseCode === responseCode.Success) {
        setUserAndBusinessData(response?.payload?.data?.details);
        setIsData(true);
      }
      setLoader(false);
    });
  }, []);

  if (isSuccess) {
    return (
      <SuccessPage
        title="Your Appointment Booked Successfully!"
        message= {<span>{`We have sent your booking information to your email address, `}<a>{appointmentData.email}</a></span>}
        extraHTML={
          <AppointmentInfo
            email={appointmentData.email}
            dateTime={appointmentData.dateTime}
            appointmentWith={appointmentData.appointmentWith}
          />
        }
      />
    );
  }

  return (
    <>
      <div className="schedulerWrapper">
        <div className="externalSchedulerWrapper">
          {isData ? (
            <div className="side-div">
              <div className="mileTap-logo-div">
                <img
                  className="mileTap-logo"
                  src={UserAndBusinessData?.businessImage}
                  lazy="true"
                />
              </div>
              <section className="main__section_1">
                <Info UserAndBusinessData={UserAndBusinessData} />
              </section>
            </div>
          ) : (
            <div>{loader ? <Spinner /> : <></>}</div>
          )}

          <div className="clenderwraper drop-shadow-2xl	color-black-900">
            <SchedulersComponent
              id={id}
              referenceId={referenceId}
              setIsSuccess={setIsSuccess}
              setAppointmentData={setAppointmentData}
            />
          </div>
        </div>
        <footer className="">
          <div>{STRINGS.COPY_RIGHTS}</div>
        </footer>
      </div>
    </>
  );
};

export default Scheduler;
