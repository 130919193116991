export const QuotationFilterSortEnum = {
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
}

export const getEnumByFieldValue = {
    referenceNo: {
        descend: QuotationFilterSortEnum.ReferenceNoDesc,
        ascend: QuotationFilterSortEnum.ReferenceNo
    },

    
}