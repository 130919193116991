export const EmployeeSalaryFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,

}

export const salaryTableSort = {
    referenceNo: {
        descend: EmployeeSalaryFilterSortEnum.ReferenceNoDesc,
        ascend: EmployeeSalaryFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: EmployeeSalaryFilterSortEnum.CreateDateDesc,
        ascend: EmployeeSalaryFilterSortEnum.CreateDateAsc
    },

}