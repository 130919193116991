import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";
import { PromotionStatusEnum } from "../../utils/PromotionStatusEnum";

const StatusTag = ({ status = PromotionStatusEnum.InProcess }) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { promotion, Direction } = dictionaryList[userLanguage];
    let color = ColorsEnum.Primary;
    switch (status) {

        case PromotionStatusEnum.InProcess:
            color = ColorsEnum.Primary;
            break;
        case PromotionStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case PromotionStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case PromotionStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case PromotionStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;
        default:
            color = ColorsEnum.Primary;
    }
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {promotion.statusEnum[status]}
            </Tag>
        </>
    );
};


export default StatusTag;