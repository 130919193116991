import moment from "moment";
import { TaskStatusEnum } from "./TaskStatusEnum";

const getTaskProgressStatus = (
  startDate,
  endDate,
  progress,
  status,
  createDate
) => {
  const now = moment().utc();
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);
  const totalDuration = end.diff(start, "hours", true);
  const elapsedDuration = now.diff(start, "hours", true);
  const timeProgress = elapsedDuration / totalDuration;
  const normalizedProgress = progress / 100;
  const progressDifference = timeProgress - normalizedProgress;

  if (status === TaskStatusEnum.Cancel) {
    return "";
  }

  if (now.isAfter(end)) {
    return "Over Due";
  } else if (progress === 0 && timeProgress <= 0.25) {
    return "";
  } else if (progress === 0 && timeProgress > 0.25) {
    return "Behind Schedule";
  } else if (progressDifference > 0.25) {
    return "Behind Schedule";
  } else if (progressDifference > 0) {
    return "Slightly Behind Schedule";
  } else if (progress === 0) {
    return "";
  } else {
    return "On Track";
  }
};

export default getTaskProgressStatus;

export const TaskProgressStatusColor = {
  "Over Due": "error",
  "Behind Schedule": "volcano",
  "On Track": "green",
  "Slightly Behind Schedule": "gold",
};
