import {ReactionModuleEnum} from "./enums/reactionModuleEnum";
import {CommentModuleEnum} from "../../Comment/utils/enum/commentModuleEnum";

export default function GetReactionModuleByComment(commentModule){

    switch (commentModule)
    {
        case CommentModuleEnum.Feed:
            return ReactionModuleEnum.FeedComment;
        case CommentModuleEnum.UserTask:
            return ReactionModuleEnum.UserTask;
        case CommentModuleEnum.WorkBoard:
            return ReactionModuleEnum.WorkBoard;
        case CommentModuleEnum.WorkBoardTodo:
            return ReactionModuleEnum.WorkBoardTodo;
        case CommentModuleEnum.Document:
            return ReactionModuleEnum.Document;
        case CommentModuleEnum.LeadManager:
            return ReactionModuleEnum.LeadManager;
        case CommentModuleEnum.LeadManagerDetail:
            return ReactionModuleEnum.LeadManagerDetail;
        case CommentModuleEnum.LeadManagerContact:
            return ReactionModuleEnum.LeadManagerDetailContact;
        case CommentModuleEnum.Schedule:
            return ReactionModuleEnum.ScheduleComment;
        case CommentModuleEnum.Requisition:
            return ReactionModuleEnum.Requisition;
        case CommentModuleEnum.CareerApplicant:
            return ReactionModuleEnum.CareerApplicantComment;
        // case CommentModuleEnum.WorkBoardSection:
        // return ReactionModuleEnum.WorkBoardSection
        case CommentModuleEnum.ProjectContact:
            return ReactionModuleEnum.ProjectContact;
        case CommentModuleEnum.DiscussionBoard:
            return ReactionModuleEnum.DiscussionBoard;
        case CommentModuleEnum.Project:
            return ReactionModuleEnum.Project
        case CommentModuleEnum.Issues:
            return ReactionModuleEnum.Issues
        case CommentModuleEnum.Group:
            return ReactionModuleEnum.Group
        case CommentModuleEnum.BusinessNumber:
            return ReactionModuleEnum.BusinessNumber
        case CommentModuleEnum.Auction:
            return ReactionModuleEnum.Auction
        case CommentModuleEnum.AuctionOffer:
            return ReactionModuleEnum.AuctionOffer  
            case CommentModuleEnum.Page:
                return ReactionModuleEnum.Pages
    
    }
}