import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { useContext, useEffect, useState } from "react";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { useSelector } from "react-redux";
import moment from "moment";
import { getFormById } from "../../store/actions";
import { readCountforForm, removeIdentifier } from "../../store/slice";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { Skeleton, Tag } from "antd";
import {
  ItemHeader,
  SingleItem,
} from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import ExpandableDescription from "../../../../sharedComponents/expandableDescription/expandableDescription";
import { timeFromNow } from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";

export default function FormDetail({ id, index }) {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  const { user } = useSelector((state) => state.userSlice);
  const [isExpanded, setIsExpanded] = useState(false);

  const [state, setState] = useState({
    identifier: moment().unix(),
    formDetail: {
      creator: {},
      description: "",
      approvers: [],
      status: 1,
      referenceNo: 0,
      subject: "",
      createDate: "",
      isLoading: true,
      notificationCount: 0,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });

  const { data } = useSelector((state) => state.formSlice);
  let formDetail = data !== null ? data[state.identifier] : [];
  formDetail = formDetail && formDetail?.length > 0 && formDetail[0];

  useEffect(() => {
    id && dispatch(getFormById({ id: id, identifier: state.identifier }));
  }, [id]);

  useEffect(() => {
    formDetail &&
      setState({
        ...state,
        formDetail: {
          ...state.formDetail,
          ...formDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: formDetail?.approvers,
        },
      });
  }, [formDetail]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  const {
    creator,
    description,
    approvers = [],
    status,
    referenceNo,
    subject,
    createDate,
    notificationCount,
  } = state.formDetail;

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Form,
          featureId: id,
        },
      };

      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforForm(id));
      });
    }
  }, [notificationCount]);

  if (state.formDetail.isLoading) return <Skeleton />;

  return (
    <>
      <SingleItem>
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator.image}
              status={creator.userActiveStatus}
              name={creator.name}
              Subline={
                <SublineDesigWithTime
                  designation={creator.designation ? creator.designation : ""}
                  time={timeFromNow(createDate)}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
          </div>
        </ItemHeader>

        <div className="title">
          {subject.length > 0 ? (
            <div>
              <span className="text-base font-bold">
                {sharedLabels.subject}:{" "}
              </span>
              {subject}
            </div>
          ) : null}
        </div>
        <div className="description">
          {description.length > 0 ? (
            <ExpandableDescription
              description={description}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          ) : (
            <p>{sharedLabels.Nodescription}</p>
          )}
        </div>
        <RemarksApproval
          module={ApprovalsModule.FormApproval}
          status={status}
          reference={formDetail?.id}
          onStatusChanged={(prev) => { }}
          setApprovalDetail={(data) => { }}
          data={state.approvers.data && state.approvers.data}
          title={sharedLabels.approvers}
        />
      </SingleItem>
    </>
  );
}
