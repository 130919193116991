import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import {
  GetAllPendingBillingService,
  SendBillService,
  GetBillingByIdService,
  addBillingService,
  getAllBillingService,
  getAllPendingBillsService,
  PayBillRequestService,
  GetBillForPayment,
} from "../services/service";
import { message } from "antd";
import { openNotification } from "../../../../utils/Shared/store/slice";

export const  getAllBilling = createAsyncThunk(
  "Billing/getAllBilling",
  async (args, { dispatch, getState }) => {
    console.log(args);
    //TODO: will be set accordingly
    const res = await getAllBillingService(args);

    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getAllPendingBills = createAsyncThunk(
  "Billing/getAllpendingBills",
  async (args, { dispatch, getState }) => {
    console.log(args);
    //TODO: will be set accordingly
    const res = await getAllPendingBillsService(args);

    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addBilling = createAsyncThunk(
  "Billing/addBiling",
  async (args, { dispatch, getState }) => {
    console.log(args);
    const res = await addBillingService(args);
    if (res.responseCode !== responseCode.Success) {
      message.error(`${res.message}`);
    }
    return res;
  }
);

export const GetAllPendingBillingAction = createAsyncThunk(
  "billing/GetAllPendingBilling",
  async (data) => {
    const res = await GetAllPendingBillingService(data);
    if (res.data.responseCode !== responseCode.Success) {
      message.error(`${res.data.message}`);
    }
    return res.data;
  }
);

export const SendBillAction = createAsyncThunk(
  "billing/SendBill",
  async (data) => {
    const res = await SendBillService(data);
    if (res.data.responseCode !== responseCode.Success) {
      message.error(`${res.data.message}`);
    }
    return res.data;
  }
);

export const GetBillingByIdAction = createAsyncThunk(
  'billong/GetBillingById',
  async (id, { dispatch , rejectWithValue }) => {
    const res = await GetBillingByIdService(id);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      message.error(`${res.message}`);
      return rejectWithValue(res.message);
    }
  }
)

export const PayBillRequestAction = createAsyncThunk(
  "billing/PayBillRequest",
  async (data) => {
    const res = await PayBillRequestService(data);
    if (res.responseCode !== responseCode.Success) {
      message.error(`${res.message}`);
    }
    return res;
  }
);

export const GetBillForPaymentAction = createAsyncThunk(
  "billing/GetBillForPayment",
  async (data) => {
    const res = await GetBillForPayment(data);
    if (res.responseCode !== responseCode.Success) {
      message.error(`${res.message}`);
    }
    return res;
  }
);
