import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "antd";
import "./style.css";
import { useDispatch } from "react-redux";
import { STRINGS } from "../../../../../utils/base";
import InvoiceItem from "./invoiceItem";
import {
  GetBillingByIdAction,
  PayBillRequestAction,
} from "../../../billing/store/actions";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { BillingStatusEnum } from "../../../billing/enum/billingStatusEnum";
import SystemLogo from "../../../../../content/systemLogo.png";
import Spinner from "../../../../sharedComponents/spinner/spinner";

const InvoiceModal = ({
  isOpen,
  onClose,
  invoiceId = STRINGS.DEFAULTS.guid,
  isPaymentView = false,
}) => {
  const navigate = useNavigate();
  const componentRef = useRef();

  const [state, setState] = useState({
    billingData: null,
    isShowInvoiceDetail: false,
  });
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const hideModal = () => {
    onClose();
    setState({
      billingData: null,
      isShowInvoiceDetail: false,
    });
  };

  useEffect(() => {
    invoiceId !== STRINGS.DEFAULTS.guid &&
      isOpen &&
      dispatch(GetBillingByIdAction(invoiceId)).then(({ payload }) => {
        setState({
          ...state,
          billingData: payload?.data ?? null,
        });
        setTimeout(() => {
          setLoader(false)
        }, 500);
      });
  }, [invoiceId, isOpen]);

  const handlePayBill = () => {
    dispatch(PayBillRequestAction(invoiceId)).then((response) => {
      let token = response?.payload?.data;
      token && navigate(`/payBill/${token}`);
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Modal
        title="Bill Details"
        open={isOpen}
        onOk={hideModal}
        onCancel={hideModal}
        footer={
          state.billingData &&
          state.billingData !== null &&
          !loader &&
          ([
            <Button key="print" onClick={handlePrint}>
              Print Invoice
            </Button>,
            state?.billingData?.status !== BillingStatusEnum.Paid ? (
              <Button className="ThemeBtn" onClick={() => handlePayBill()}>
                Pay Bill
              </Button>
            ) : null,
          ])
        }
        width={"1000px"}
        className="BillDetailsModal"
      >
        <div ref={componentRef} className="handlePrint">
          {state.billingData &&
            state.billingData !== null &&
            !loader && (
              <>
                <div className=" payBillHeader !m-[0px]">
                  <div className="note note-heading !text-[16px] !m-[0px]">
                    {"Billing Invoice"}
                  </div>
                  <div>
                    <div className="">
                      <img className="h-[50px]" src={SystemLogo} />
                    </div>
                  </div>
                </div>
                <InvoiceItem billingData={state.billingData} />
              </>
            )}
            {loader && <Spinner/>}
        </div>
      </Modal>
    </>
  );
};

export default InvoiceModal;
