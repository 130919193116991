import MasterConfig from "../../../../utils/services/MasterConfig"

export const getAllPaymentService = (payload) => {
  //TODO: url will be changed
  return MasterConfig.post(`api/Payment/GetAllPayment`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllPaymentPagingService = (payload) => {
  //TODO: url will be changed
  return MasterConfig.post(`api/Payment/GetAllPaymentPaging`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
