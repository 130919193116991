import { CommentTypeEnum } from "../../../utils/enum/commentTypeEnum";
import CommentAvatar from "../../commentAvatar";
import CommentBubble from "./commentBubble";
import UserCommentFooter from "./userCommentFooter";
import { CommentModuleEnum } from "../../../utils/enum/commentModuleEnum";
import { STRINGS } from "../../../../../../utils/base";
import { ReactionTypeEnum } from "../../../../reaction/utils/enums/reactionTypeEnum";

function CommentItem({
  commentItem,
  module = CommentModuleEnum.Feed,
  replyOnClick = () => {},
  identifier,
}) {
  let {
    id = STRINGS.DEFAULTS.guid,
    parentId = STRINGS.DEFAULTS.guid,
    referenceId = STRINGS.DEFAULTS.guid,
    comment = "",
    type = CommentTypeEnum.UserComment,
    createDate,
    createBy = STRINGS.DEFAULTS.guid,
    creator = {
      name: "",
      image: "",
    },
    attachments = [],
    replyCount = 0,
    reactionCount = 0,
    myReaction = ReactionTypeEnum.NoReaction,
    mentions = [],
  } = commentItem;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          marginLeft: "15px",
          transition: "background-color 0.4s ease-in-out",
        }}
      >
        <div className="flex">
          <CommentAvatar name={creator.name} image={creator.image} size={30} />
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div style={{ display: "flex" }}>
            <CommentBubble
              creator={creator}
              attachments={attachments}
              comment={comment}
              createDate={createDate}
              mentions={mentions}
              commentItem={commentItem}
            />
          </div>
          <div className="flex m-4 mr-[10px]">
            <UserCommentFooter
              id={id}
              parentId={parentId}
              module={module}
              replyCount={replyCount}
              reactionCount={reactionCount}
              myReaction={myReaction}
              referenceId={referenceId}
              replyOnClick={replyOnClick}
              identifier={identifier}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CommentItem;
