import {STRINGS} from "../../../utils/base";
import CommentListWrapper from "./view/commentList/commentListWrapper";
import CommentComposer from "./view/commentComposer/commentComposer";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteComments } from "./store/commentSlice";

function Comments({
    isHeightFull=false,
                      referenceId = STRINGS.DEFAULTS.guid,
                      module,
                      initialData = [],
                      isOpenComposer=true,
                      referenceType,
                      mentionMemberRefId = STRINGS.DEFAULTS.guid
                    //   identifier = moment().unix()
}) {

    const [identifier , setIdentifier] = useState(moment().unix())
    const dispatch = useDispatch()
    useEffect(()=>{
        return()=>{
            dispatch(deleteComments({identifier:identifier , referenceId:referenceId,module:module}))
        }
    },[])
    return (<><div className="commentWrapper">
        {isOpenComposer && <CommentComposer referenceId={referenceId} module={module} identifier={identifier} referenceType={referenceType} mentionMemberRefId = {mentionMemberRefId}/>}
        <div className="comments" style={{marginTop : "10px"}}><CommentListWrapper isHeightFull={isHeightFull}  module={module} referenceId = {referenceId} identifier={identifier} isOpenComposer={isOpenComposer} /></div>
    </div></>);

}
export default Comments;