import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { getAllPayment, getAllPaymentPaging } from "./actions.js";

const initialState = {
  payment: [],
  loadingData: false,
  loader: false,
  success: false,
  error: false,
};

const userPaymentSlice = createSlice({
  name: "userPaymentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPayment.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.payment = {
          ...payload.data,
          data: Array.isArray(payload.data.data) ? payload.data.data : [payload.data.data]
        };
      })
      .addCase(getAllPaymentPaging.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.payment = {
          ...payload.data,
          data: Array.isArray(payload.data.data) ? payload.data.data : [payload.data.data]
        };
      })
      .addMatcher(isPending(getAllPayment, getAllPaymentPaging), (state) => {
        state.loadingData = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(isRejected(getAllPayment, getAllPaymentPaging), (state) => {
        state.loadingData = false;
        state.success = false;
      });
  },
});

export default userPaymentSlice.reducer;
