export const TravelStatusEnum = {
  InProcess : 1,
  Approved:2,
  Declined:3,
  ApprovedByApprovers : 4,
  ApprovedByAgents : 5,
  DeclineByApproves:6,
  DeclineByAgents:7,
  Cancelled:8,
  Hold:9,
  };

