import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums";
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";

const urdu = {

  sharedLabels: {
    SelectLanguage: "Select Language",
    English: "English ",
    Turkish: "Turkish",
    Hindi: "Hindi",
    Urdu: "اردو",
    Arabic: "عربي",
    czech: "Czech",
    russian: "Russian",
    french: "French",
    spanish: "Spanish",
    italian: "Italian",
    theme: "تھیم",
    awardTo: "انعام دیا جائے",
    inprogress: "جاری ہے",
    filter: "فلٹر",
    approvals: "منظوری",
    approvers: "منظورکنندگان",
    members: "اہلیہ",
    Approvers: "منظورکنندگان",
    resignations: "استعفی",
    Description: "تفصیل",
    Descriptionisrequired: "تفصیل ضروری ہے!",
    WriteDescriptionhere: "یہاں تفصیل لکھیں...",
    enterDescription: "تفصیل درج کریں",
    pleseEnterDescription: "براہ کرم اہم تفصیل درج کریں",
    pleaseEnterGrade: "براہ کرم گریڈ درج کریں",
    manager: "مینیجر",
    requiredMessageManager: "براہ کرم مینیجر منتخب کریں",
    SelectManager: "مینیجر منتخب کریں",
    create: "تخلیق کریں",
    humanresource: "انسانی وسائل",
    finance: "فنانس",
    it: "آئی ٹی",
    admin: "ایڈمن",
    selectMember: "رکن منتخب کریں",
    othersapprovals: "دیگر منظوریاں",
    exitinterview: "باہری مصاحبہ",
    selectAperson: "ایک شخص منتخب کریں",
    SelectHumanResource: "انسانی وسائل منتخب کریں",
    selectIt: "آئی ٹی منتخب کریں",
    selectFinance: "فنانس منتخب کریں",
    selectAdmin: "ایڈمن منتخب کریں",
    selectOthersApprovals: "دیگر منظوریاں منتخب کریں",
    inProcess: "عمل میں ہے",
    reason: "وجہ",
    refrence: "حوالہ",
    Rewards: "انعامات",
    name: "نام",
    add: "شامل کریں",
    action: "عمل",
    userType: "صارف کی قسم",
    SelectUserType: "صارف کی قسم منتخب کریں",
    requiredMessageUserType: "براہ کرم صارف کی قسم منتخب کریں",
    RewardTo: "انعام دینے والا",
    Approved: "منظور",
    Declined: "رد کر دیا گیا",
    Resend: "دوبارہ بھیجیں",
    NotRequired: "ضرورت نہیں ہے",
    Cancelled: "منسوخ",
    InActive: "غیر فعال",
    ListView: "لسٹ ویو",
    TableView: "ٹیبل ویو",
    MyReward: "میرا انعام",
    ForApprovals: "منظوریوں کے لیے",
    ForApproval: "منظوری کے لئے",
    RewardToMe: "مجھے انعام دیا گیا",
    Search: "تلاش",
    Disable: "غیرفعال",
    Attachments: "منسلکہ فائلیں",
    Update: "اپ ڈیٹ",
    update: "اپ ڈیٹ",
    AddEmployee: "ملازم شامل کریں",
    complain: "شکایت",
    Submit: "جمع کریں",
    Delete: "حذف کریں",
    Edit: "ترتیب دیں",
    NotDesignated: "تعین نہیں کیا گیا",
    Profile: "پروفائل",
    Settings: "سیٹنگز",
    Logout: "لاگ آوٹ",
    Table: "ٹیبل",
    List: "لسٹ",
    Grid: "گرڈ",
    GridView: "گرڈ ویو",
    startyear: "شروع ہونے والا سال",
    endyear: "ختم ہونے والا سال",
    startMonth: "شروع ہونے والا مہینہ",
    endMonth: "ختم ہونے والا مہینہ",
    EnterStartDate: "شروع ہونے والی تاریخ درج کریں",
    SelectStartDate: "شروع ہونے والی تاریخ منتخب کریں",
    startDate: "شروع ہونے والی تاریخ",
    endDate: "ختم ہونے والی تاریخ",
    businessLogo: "کاروبار کا لوگو",
    title: "عنوان",
    enterTitle: "عنوان درج کریں",
    percentage: "فی صد",
    PleaseEnterPercentage: "براہ کرم فی صد درج کریں",
    previousCharge: "پچھلا چارج",
    min: "کم",
    max: "زیادہ",
    country: "ملک",
    requiredCountry: "ملک ضروری ہے",
    SelectCountry: "ملک منتخب کریں",
    latitude: "عرض",
    longitude: "طول",
    address: "پتہ",
    EnterAddress: "پتہ درج کریں",
    requiredMessageAddress: "براہ کرم پتہ درج کریں",
    branch: "شاخ",
    question: "سوالات",
    allotLeaves: "چھٹیاں دینا",
    SubjectRequired: "موضوع ضروری ہے",
    WriteSubject: "موضوع لکھیں",
    category: "قسم",
    amount: "رقم",
    documents: "دستاویزات",
    enterName: "نام درج کریں",
    nameRequired: "نام ضروری ہے",
    accessTo: "رسائی حاصل ہونے والی",
    selectAccessTo: "رسائی حاصل ہونے والوں کو منتخب کریں",
    collaborators: "شراکت دار",
    selectCollaborators: "شراکت دار منتخب کریں",
    readers: "پڑھنے والے",
    selectReaders: "پڑھنے والے منتخب کریں",
    createBoard: "بورڈ بنائیں",
    createPad: "پیڈ بنائیں",
    createGrid: "گرڈ بنائیں",
    createSlide: "اسلائیڈ بنائیں",
    createMindmap: "مائنڈ میپ بنائیں",
    drive: "ڈرائیو",
    myDrive: "میری ڈرائیو",
    allDocuments: "تمام دستاویزات",
    subject: "موضوع",
    Subjectisrequired: "موضوع ضروری ہے",
    pleaseEnterSubject: "براہ کرم موضوع درج کریں",
    EnterSubject: "موضوع درج کریں",
    enterAmount: "رقم درج کریں",
    pleaseEnterAmount: "براہ کرم رقم درج کریں",
    upload: "اپ لوڈ کریں",
    value: "قدر",
    addCategory: "قسم شامل کریں",
    saveCategory: "قسم محفوظ کریں",
    clear: "صاف",
    loans: "قرضے",
    disburse: "تقسیم کرنا",
    referenceNo: "حوالہ نمبر",
    status: "حالت",
    createDate: "تخلیق کی تاریخ",
    creator: "تخلیق کنندہ",
    netSalary: "صاف تنخواہ",
    type: "قسم",
    PleaseSelectType: "براہ کرم قسم منتخب کریں",
    CreatedByMe: "میرے ذریعے بنایا گیا",
    Members: "رکن",
    Member: "رکن",
    pleaseEnterCategory: "براہ کرم قسم درج کریں",
    Memberisrequired: "رکن ضروری ہے",
    EnterCategoryName: "قسم کا نام درج کریں",
    Clear: "صاف",
    ReferenceNumber: "حوالہ نمبر",
    Grade: "گریڈ",
    Status: "حالت",
    CreateDate: "تخلیق کی تاریخ",
    Creator: "تخلیق کنندہ",
    SNo: "سیریل نمبر",
    Employee: "ملازم",
    SelectApprovers: "منظور کنندگان منتخب کریں",
    Approverisrequired: "منظور کنندہ ضروری ہے",
    Yes: "ہاں",
    No: "نہیں",
    totalAmount: "کل رقم",
    Total: "کل",
    Date: "تاریخ",
    Pleaseselectdate: "براہ کرم تاریخ منتخب کریں",
    SelectDate: "تاریخ منتخب کریں",
    requiredMessageDate: "براہ کرم تاریخ منتخب کریں",
    Priority: "ترجیح",
    Pleaseselectpriority: "براہ کرم ترجیح منتخب کریں",
    Pleaseselecttype: "براہ کرم قسم منتخب کریں",
    SelectProject: "پروجیکٹ منتخب کریں",
    SelectGroup: "گروہ منتخب کریں",
    Project: "پروجیکٹ",
    Group: "گروہ",
    Progress: "پیش رفت",
    SuretoRate: "کیا آپ یقینی ہیں کہ ریٹ کریں گے؟",
    Invalidfiletype: "غیر موزوں فائل کی قسم",
    Pleaseinputyoursubject: "براہ کرم اپنا موضوع درج کریں",
    Pleaseinputyourdescription: "براہ کرم اپنا تفصیل درج کریں",
    Return: "واپسی",
    EnterReason: "وجہ درج کریں",
    TravelReason: "سفر کا باعث",
    NoDesignation: "کوئی عہد نامہ نہیں",
    Hi: "ہائی",
    Quotation: "حوالہ",
    Amountisrequired: "رقم کی ضرورت ہے",
    WriteDescriptionHere: "یہاں تفصیل لکھیں...",
    Types: "اقسام",
    Header: "ہیڈر",
    MyTeam: "میری ٹیم",
    Email: "ای میل",
    EnterEmail: "ای میل درج کریں",
    EmailIsRequired: "ای میل کی ضرورت ہے",
    PhoneNo: "فون نمبر",
    Designation: "عہد",
    ReferenceType: "حوالہ کی قسم",
    referenceId: "حوالہ شناخت",
    Save: "محفوظ کریں",
    save: "محفوظ کریں",
    Color: "رنگ",
    Yesterday: "کل",
    Overdue: "معسر",
    Today: "آج",
    Complete: "مکمل",
    DueDate: "اختتامی تاریخ",
    SelectDueDate: "اختتامی تاریخ منتخب کریں",
    Cover: "کور",
    Cancel: "منسوخ",
    cancel: "منسوخ",
    and: "اور",
    other: "دیگر",
    Others: "دوسرے",
    Option: "اختیار",
    Public: "عوام",
    Private: "نجی",
    click: "کلک کریں",
    With: "کے ساتھ",
    with: "کے ساتھ",
    Comments: "تبصرے",
    Commentsdot: "تبصرے...",
    Comment: "تبصرہ",
    Seeless: "کم دیکھیں",
    Seemore: "زیادہ دیکھیں",
    Share: "شیئر",
    ShareNow: "اب شیئر کریں",
    SelectEmployee: "ملازم منتخب کریں",
    SelectCategory: "زمرہ منتخب کریں",
    PleaseSelectCategory: "براہ کرم زمرہ منتخب کریں",
    privacy: "رازی",
    tags: "ٹیگز",
    updateDate: "تاریخ تازہ کریں",
    otherApprovals: "دیگر منظوریاں",
    resignationDate: "استعفی کی تاریخ",
    purpose: "مقصد",
    warnings: "انتباہات",
    Courses: "کورسز",
    selectType: "قسم منتخب کریں",
    daysCount: "دنوں کی تعداد",
    reportingTo: "رپورٹنگ ٹو",
    selectReason: "وجہ منتخب کریں",
    pleaseSelectReason: "براہ کرم وجہ منتخب کریں",
    days: "دن",
    day: 'دن',
    Dates: "تاریخیں",
    selectMembers: "اہلکار منتخب کریں",
    selectGrade: "گریڈ منتخب کریں",
    newGrade: "نیا گریڈ",
    currentGrade: "موجودہ گریڈ",
    PleaseSelectMember: "براہ کرم اہلکار منتخب کریں",
    enterCategory: "زمرہ درج کریں",
    deadline: "موعد",
    Department: "ڈیپارٹمنٹ",
    designationmessage: "براہ کرم عہد منتخب کریں",
    selectDesignation: "عہد منتخب کریں",
    jobdescription: "نوکری کا تفصیلات",
    salaryRange: "تنخواہ کا حد",
    interviewers: "انٹرویو لینے والے",
    hiringBuddy: "ہائرنگ دوست",
    enterCity: "براہ کرم شہر درج کریں",
    City: "شہر",
    selectCity: "شہر منتخب کریں",
    requiredCity: "شہر کی ضرورت ہے",
    skills: "مہارتیں",
    experience: "تجربہ (سال)",
    Experience: "تجربہ",
    jobType: "نوکری کی قسم",
    education: "تعلیم",
    interview: "انٹرویو لینے والا",
    Rating: "ریٹنگ",
    poweredbyWorkw: "ویک وائز لٹڈ کا اہتمام - کاپی رائٹس 2016-",
    copyLink: "لنک کاپی کریں",
    EffectiveDate: "مؤثر تاریخ",
    experienceRequired: "تجربہ ضروری ہے",
    user: "صارف",
    postinterview: "انٹرویو کے بعد",
    Copied: "کاپی کیا گیا",
    unreadNotifyItem: "ناپڑھے گئے خبریں",
    createBy: "دوار کریں",
    Create: "بنائیں",
    Open: "کھولیں",
    Closed: "بند",
    Nodescription: "کوئی تفصیل نہیں",
    writeDescription: "یہاں آپ کی تفصیل لکھیں...",
    Employees: "ملازمین",
    BasicInfo: "بنیادی معلومات",
    FatherName: "والد کا نام",
    EnterFatherName: "والد کا نام درج کریں",
    PersonalEmail: "ذاتی ای میل",
    EnterPersonalEmail: "ذاتی ای میل درج کریں",
    personalEmailIsRequired: "ذاتی ای میل کی ضرورت ہے",
    PhoneNumber: "فون نمبر",
    EnterPhoneNumber: "فون نمبر درج کریں",
    NationalIdentity: "قومی شناخت",
    EnterNationalIdentityNumber: "قومی شناخت نمبر درج کریں",
    Grades: "گریڈز",
    SelectGrade: "گریڈ منتخب کریں",
    requiredMessageGrade: "براہ کرم گریڈ منتخب کریں",
    EnterProbationPeriod: "آزمائشی مدت درج کریں",
    NoticePeriod: "نوٹس مدت (دن)",
    EnterNoticePeriod: "نوٹس مدت درج کریں",
    requiredMessageNoticePeriod: "براہ کرم اپنی نوٹس مدت درج کریں",
    requiredMessageNoticePeriodRestriction: "نوٹس مدت 1 سے 60 کے درمیان ہونی چاہئے",
    DateOfBirth: "پیدائش کی تاریخ",
    Gender: "صنف",
    requiredMessageGender: "براہ کرم صنف منتخب کریں",
    SelectGender: "صنف منتخب کریں",
    MartialStatus: "شادی شدہ حالت",
    SelectMartialStatus: "شادی شدہ حالت منتخب کریں",
    requiredDepartment: "ڈیپارٹمنٹ کی ضرورت ہے",
    SelectDepartment: "ڈیپارٹمنٹ منتخب کریں",
    Subsidiary: "ذیلی",
    SelectSubsidiary: "ذیلی منتخب کریں",
    SubsidiaryOffice: "ذیلی دفتر",
    SelectSubsidiaryOffice: "ذیلی دفتر منتخب کریں",
    PayrollGroup: "پے رول گروہ",
    SelectPayrollGroup: "پے رول گروہ منتخب کریں",
    DefaultApproval: "ڈیفالٹ منظوری",
    SelectDefaultApproval: "ڈیفالٹ منظوری منتخب کریں",
    SelectAll: "سب منتخب کریں",
    NonMandatoryApprovals: "غیر ضروری منظوریاں",
    SelectNonMandatoryApprovals: "غیر ضروری منظوریاں منتخب کریں",
    FirstName: "پہلا نام",
    EnterFirstName: "پہلا نام درج کریں",
    requiredMessageName: "براہ کرم نام درج کریں",
    requiredMessageFName: "براہ کرم پہلا نام درج کریں",
    requiredMessageLName: "براہ کرم آخری نام درج کریں",
    LastName: "آخری نام",
    EnterLastName: "آخری نام درج کریں",
    DateOfJoining: "شامل ہونے کی تاریخ",
    requiredDateOfBirth: "پیدائش کی تاریخ ضروری ہے",
    requiredMessageJoiningDate: "براہ کرم شامل ہونے کی تاریخ منتخب کریں",
    JobShift: "نوکری کا شفٹ",
    requiredMessageJobShift: "براہ کرم نوکری کا شفٹ منتخب کریں",
    SelectJobShift: "نوکری کا شفٹ منتخب کریں",
    AccessRole: "رسائی کا کردار",
    requiredMessageAccessRole: "براہ کرم کم سے کم ایک رسائی کا کردار منتخب کریں",
    SelectAccessRole: "رسائی کا کردار منتخب کریں",
    StartEndDate: "شروع/انتہا تاریخ",
    ErrorInValidation: "تصدیق میں خطا",
    EducationInfo: "تعلیم کی معلومات",
    NonMandatory: "غیر لازمی",
    Present: "موجود",
    FailedToFetch: "حاصل کرنے میں ناکام",
    Position: "پوزیشن",
    EnterPosition: "پوزیشن درج کریں",
    requiredMessagePosition: "براہ کرم پوزیشن درج کریں",
    Organization: "تنظیم",
    EnterOrganization: "تنظیم درج کریں",
    requiredMessageOrganziation: "براہ کرم تنظیم درج کریں",
    Number: "نمبر",
    EnterNumber: "نمبر درج کریں",
    requiredMessageNumber: "براہ کرم نمبر درج کریں",
    Relation: "تعلق",
    SelectRelation: "تعلق منتخب کریں",
    requiredMessageRelation: "تعلق ضروری ہے",
    AccountName: "اکاؤنٹ کا نام",
    BranchCode: "برانچ کوڈ",
    EnterBranchCode: "برانچ کوڈ درج کریں",
    requiredMessageBranchCode: "برانچ کوڈ ضروری ہے",
    BankName: "بینک کا نام",
    requiredMessageBankName: "بینک کا نام ضروری ہے",
    EnterBankName: "بینک کا نام درج کریں",
    AccountNumber: "اکاؤنٹ نمبر",
    EnterAccountNumber: "اکاؤنٹ نمبر درج کریں",
    requiredAccountNumber: "اکاؤنٹ نمبر ضروری ہے",
    IBAN: "آئیبین",
    EnterIBAN: "آئیبین درج کریں",
    requiredIBAN: "آئیبین نمبر ضروری ہے",
    SortCode: "سارٹ کوڈ",
    requiredSortCode: "سارٹ کوڈ ضروری ہے",
    EnterSortCode: "سارٹ کوڈ درج کریں",
    BankInfo: "بینک کی معلومات",
    AccountTitle: "اکاؤنٹ ٹائٹل",
    EnterAccountTitle: "اکاؤنٹ ٹائٹل درج کریں",
    requiredMessageAccount: "اکاؤنٹ ٹائٹل ضروری ہے",
    validAccountTitle: "براہ کرم ایک درست اکاؤنٹ ٹائٹل درج کریں",
    AttachmentType: "پیوست کی قسم",
    requiredAttchmentType: "پیوست کی قسم ضروری ہے",
    Attachment: "پیوست",
    requiredAttachment: "پیوست ضروری ہے",
    AddAttachment: "پیوست شامل کریں",
    requiredMessageAssignMember: "براہ کرم ایک ممبر منتخب کریں",
    Remove: "ہٹائیں",
    ContactNo: "رابطہ نمبر",
    EnterContactNo: "رابطہ نمبر درج کریں",
    requiredContactNo: "رابطہ نمبر ضروری ہے",
    Contact: "رابطہ",
    pleasEnterDescription: "براہ کرم تفصیل درج کریں",
    Information: "معلومات",
    Features: "خصوصیات",
    AddMembers: "ممبر شامل کریں",
    Summary: "خلاصہ",
    AcessRole: "ایکسیس رول",
    defaultApprovers: "ڈیفالٹ منظور کنندگان",
    NonMandatoryApprovers: "غیر ضروری منظور کنندگان",
    Pleasefillallrequiredfields: "براہ کرم تمام ضروری خانے بھریں",
    Suretodelete: "مسلم ہے کہ حذف کریں؟",
    Code: "کوڈ",
    createdDate: "تخلیق کی تاریخ",
    Searchmembers: "ممبر تلاش کریں",
    AddDate: "تاریخ شامل کریں",
    duesoon: "جلد ہی واجب",
    Payroll: "پے رول",
    ReferenceNo: "حوالہ نمبر",
    Associate: "متعلقہ",
    InternalMembers: "انٹرنل ممبرز",
    ExternalMembers: "بیرونی ممبرز",
    TotalExpense: "کل اخراجات",
    TotalExpenseAmount: "کل اخراجات کی رقم",
    TotalPost: "کل پوسٹ",
    TotalMeeting: "کل میٹنگ",
    Expense: "اخراج",
    TotalBudget: "کل بجٹ",
    Balance: "بیلنس",
    successfully: "کامیابی سے",
    enterValidEmail: 'براہ کرم درست ای میل ایڈریس درج کریں',
    ViewSummary: "خلاصہ دیکھیں",
    AddAdmin: "ایڈمن شامل کریں",
    AddExternals: "بیرونی ممبرز شامل کریں",
    DefaultApprovers: " ڈیفالٹ منظور کنندگان",
    Billings: "بلنگز",
    spent: "خرچ ہوا",
    SelectUser: "صارف منتخب کریں",
    Image: "تصویر",
    Contacts: "رابطے",
    noContacts: "کوئی رابطہ نہیں",
    AddContact: "رابطہ شامل کریں",
    Roles: "رولز",
    Tag: "ٹیگ",
    AccessRoles: "ایکسیس رولز",
    member: "ممبر",
    uploadCover: "کور شامل کریں",
    Pleaseselect: "براہ کرم منتخب کریں",
    composeEmail: "ای میل تخلیق کریں",
    send: "بھیجیں",
    ContactDetail: "رابطہ کی تفصیل",
    Detail: "تفصیل",
    UploadImage: "تصویر اپلوڈ کریں",
    WriteName: "نام لکھیں",
    business: "کاروبار",
    website: "ویب سائٹ",
    Unknown: "نامعلوم",
    Interested: "دلچسپی ہے",
    NotInterested: "دلچسپی نہیں ہے",
    meetings: "میٹنگز",
    noMeetings: "کوئی میٹنگ نہیں",
    Communications: "مواصلات",
    Details: "تفصیلات",
    LastUpdate: "آخری اپ ڈیٹ",
    nameRequiredMessage: "نام ضروری ہے!",
    addressRequiredMessage: "پتہ ضروری ہے!",
    Intrested: "دلچسپی ہے",
    StartDateTime: "شروع ہونے کی تاریخ اور وقت",
    DateTimeisrequired: "تاریخ اور وقت ضروری ہے",
    Selectdatetime: "تاریخ اور وقت منتخب کریں",
    Board: "بورڈ",
    Notes: "نوٹس",
    Miletap: "مائل ٹیپ",
    EMail: "ای میل",
    Attendance: "حاضری",
    Absent: "غیر حاضر",
    NoData: "کوئی ڈیٹا نہیں",
    Prority: "ترجیح",
    Heigh: "زیادہ",
    Medium: "میڈیم",
    Low: "کم",
    error: "خطا",
    Select: "منتخب کریں",
    Interview: "انٹرویو",
    Meeting: "میٹنگ",
    Appointment: "ملاقات",
    Pleaseinputyouremail: "براہ کرم اپنا ای میل درج کریں",
    EnterEmailAddress: "ای میل ایڈریس درج کریں",
    AddExternalMember: "بیرونی ممبر شامل کریں",
    Pleaseenterexternalmembers: "براہ کرم بیرونی ممبرز درج کریں",
    externals: "بیرونی",
    Decline: "انکار",
    NotAvailable: "دستیاب نہیں",
    AddApprovals: "منظور کنندگان شامل کریں",
    Everyone: "سب کو",
    SelectDownloaders: "ڈاؤن لوڈرز منتخب کریں",
    SelectCollabrators: "متعاون لوگ منتخب کریں",
    pleaseSelectAssignMemeber: "براہ کرم کسی ممبر کو چنیں!",
    Availed: "حاصل ہوا",
    Allotted: "تخصیص شدہ",
    Degree: "ڈگری",
    Time: "وقت",
    Mood: "موڈ",
    CheckOut: "چیک آؤٹ",
    CheckIn: "چیک ان",
    Late: "دیر سے",
    State: "حالت",
    PleaseSelectYesNo: "براہ کرم ہاں/نہیں منتخب کریں",
    PleaseselectGrade: "براہ کرم گریڈ منتخب کریں",
    PleaseenterAmount: "براہ کرم رقم درج کریں",
    Salary: "تنخواہ",
    Task: "ٹاسک",
    Nameisrequired: "نام ضروری ہے",
    PhoneNumberisrequired: "فون نمبر ضروری ہے",
    Dateisrequired: "تاریخ ضروری ہے",
    Month: "مہینہ",
    Year: "سال",
    FinalApprovers: "آخری منظور کنندگان",
    link: "لنک",
    forFinalApproval: "آخری منظوری کے لئے",
    validateBonus: "بونس پراسیس نہیں کی جا سکتی: ملازم کے لیے کوئی تنخواہ کی معلومات دستیاب نہیں ہیں",
    validateLoan: "قرضہ پروسیس نہیں کیا جا سکتا: ملازم کے لیے کوئی تنخواہ کی معلومات دستیاب نہیں ہیں",
    User: "صارف",
    Action: "عمل",
    Created: "تخلیق شدہ",
    selectyourcountry: "براہ کرم اپنے ملک کا انتخاب کریں",
    PleaseEnterPhoneNumber: "براہ کرم فون نمبر درج کریں",
    Pleaseselectyourcity: "براہ کرم اپنے شہر کا انتخاب کریں",
    SelectCurrency: "کرنسی منتخب کریں",
    Pleaseselectcurrency: "براہ کرم کرنسی منتخب کریں",
    totalGroups: "کل گروہ",
    totalProjects: "کل منصوبے",
    totalMemory: "کل میموری",
    memory: "میموری",
    EnterMemory: "میموری درج کریں",
    availedMemory: "دستیاب میموری (جی بی)",
    used: "استعمال شدہ",
    usedMemory: "استعمال شدہ میموری (جی بی)",
    From: "سے",
    To: "تک",
    WelcomeSendEmail: "خوش آمدید ای میل بھیجیں",
    SendEmail: "ای میل بھیجیں",
    writeYourRemarksHere: "اپنی رائے یہاں لکھیں...",
    NoRemarkFound: "کوئی رائے نہیں ملا",
    ToastEnable: "ٹوسٹ فعال کریں",
    EmailEnable: "ای میل فعال کریں",
    FeatureName: "خصوصیت کا نام",
    Default: "پہلے سے طے شدہ",
    netAmount: "خالص رقم",
    FeatureName: "فیچر کا نام",
    LoginMessage: "براہ کرم پہلے اسناد درج کریں",
    SelectedDate: "منتخب تاریخ",
    SelectedUser: "منتخب صارف",
    Default: "ڈیفالٹ",
    mylog: "میرا لاگ",
    SelectStatus: "حیثیت منتخب کریں",
    Selectuser: "صارف منتخب کریں",
    PolicyType: "پالیسی کی قسم",
    Percent: "فیصد",
    uploadProfilePicture: "پروفائل تصویر اپلوڈ کریں",
    inputUsername: "براہ کرم صارف کا نام درج کریں",
    userName: "صارف کا نام",
    Dummy: "ڈمی",
    Lesson: "سبق",
    Duration: "مدت",
    Text: "متن",
    Enterlink: "لنک درج کریں",
    Dashboard: "ڈیش بورڈ",
    searchSchedule: "شیڈول تلاش کریں...",
    AddOption: "اختیار شامل کریں",
    ChangeImage: "تصویر تبدیل کریں",
    DeleteImage: "تصویر حذف کریں",
    Question: "سوال",
    Next: "اگلا",
    Prev: "پچھلا",
    Wrong: "غلط",
    Correct: "صحیح",
    YourResult: "آپ کا نتیجہ",
    Createdby: "کی طرف سے تخلیق شدہ",
    Lastupdated: "آخری اپ ڈیٹ",
    Assignedto: "تفویض شدہ کو",
    Addlesson: "سبق شامل کریں",
    Addtopic: "موضوع شامل کریں",
    minutes: "منٹ",
    Modules: "ماڈیولز",
    Valid: "درست",
    Expired: "منقضی",
    AssignedMembers: "تفویض شدہ افراد",
    Start: "شروع کریں",
    Previous: "پچھلا",
    Read: "پڑھیں",
    DeleteBook: "کتاب حذف کریں",
    Assign: "تفویض کریں",
    Explanation: "توضیح",
    Hey: "ہیلو",
    requiredMessageEmail: "براہ کرم ای میل درج کریں",
    BusinessName: "کاروبار کا نام",
    PleaseEnterBusinessName: "براہ کرم بزنس کا نام درج کریں",
    BusinessAddress: "کاروبار کا پتہ",
    PleaseEnterBusinessAddress: "براہ کرم بزنس کا پتہ درج کریں",
    Offers: "پیشکشیں",
    PleaseEnterOffer: "براہ کرم پیشکش درج کریں",
    CreateOffer: "پیشکش بنائیں",
    AppliedDate: "لگایا گیا تاریخ",
    ShortList: "مختصر فہرست",
    Finalised: "مکمل شدہ",
    Member: "رکن",
    SelectMember: 'رکن منتخب کریں',
    Approver: "منظور کنندہ",
    CategoryName: "زمرہ کا نام",
    serialNo: "سیریل نمبر",
    Allocated: "تفویض شدہ",
    Available: "دستیاب",
    lost: "ضائع شدہ",
    damage: "نقصان",
    itemType: "آئٹم کی قسم",
    Service: "خدمت",
    consumable: "استعمال ہونے والا",
    NonConsumable: "غیر استعمال ہونے والا",
    Quantity: "مقدار",
    accountType: "اکاؤنٹ کی قسم",
    PleaseEnterQuantity: "براہ کرم مقدار درج کریں",
    QuantityMustBePositive: "مقدار مثبت ہونی چاہئے",
    MembersIsRequired: "رکن درکار ہے",
    CreateRequest: "درخواست بنائیں",
    IBANNumber: "IBAN نمبر",
    About: "معلومات",
    AboutMe: "میرے بارے میں",
    TimePeriod: "وقتی مدت",
    Company: "کمپنی",
    CityTown: "شہر / ٹاؤن",
    BankDetails: "بینک کی تفصیلات",
    Work: "کام",
    addWorkPlace: "کام کی جگہ شامل کریں",
    AddEducation: "تعلیم شامل کریں",
    basicSalary: "بنیادی تنخواہ",
    feed: 'فیڈ',
    TakeaNote: "نوٹ لیں",
    awards: 'ایوارڈز',
    Appraisal: "تشہیر",
    salary: 'تنخواہ',
    activityLog: "سرگرمی کا لاگ",
    device: 'آلہ',
    leave: 'رخصتی',
    Ip: "IP",
    DeviceId: "آلہ کی شناخت",
    AppVersion: "ایپ کی ورژن",
    Selectfeature: "خصوصیت منتخب کریں",
    EnterYourFeedBackDescription: "اپنی رائے کی وضاحت درج کریں",
    HideAll: "سب چھپائیں",
    ShowAll: "سب دکھائیں",
    PrintBy: "پرنٹ بائے",
    AccCode: "اکاؤنٹ کوڈ",
    SelectAccountType: "اکاؤنٹ کی قسم منتخب کریں",
    Files: "فائلیں",
    Space: "جگہ",
    DisburseVoucher: "واؤچر جاری کریں",
    TopUsersbyMostFiles: "سب سے زیادہ فائلوں والے صارفین",
    TopUsersbyStorageSize: "اسٹوریج سائز کے حساب سے سب سے زیادہ صارفین",
    Apply: "درخواست دیں",
    NumberOfTopUsers: "سب سے زیادہ صارفین کی تعداد",
    TopUsers: "سب سے زیادہ صارفین",
    SearchUser: "صارف تلاش کریں",
    ByStorageSize: "اسٹوریج سائز کے حساب سے",
    ByFileSize: "فائل سائز کے حساب سے",
    companyDashboard: "کمپنی کا ڈیش بورڈ",
    signup: "سائن اپ کی تصدیق نہیں ہوئی",
    billing: "بلنگ",
    PendingBills: "زیر التواء بل",
    MonthYear: "مہینہ - سال",
    SendBill: "بل بھیجیں",
    BillingUsers: "بلنگ صارفین",
    Item: "آئٹم",
    Rate: "شرح",
    PaidAmount: "ادا شدہ رقم",
    PaymentDate: "ادائیگی کی تاریخ",
    DarkDays: "اندھیرے دن",
    ProcessingFee: "پروسیسنگ فیس",
    Currency: "کرنسی",
    IsEnable: "فعال ہے",
    Actions: "عمل",
    TakeNote: "نوٹ لیں",
    PleaseSelectUsers: "براہ کرم صارفین کا انتخاب کریں",
    StickyNotes: "چپچپے نوٹس",
    Copy: "کاپی کریں",
    MarkAllRead: "سب کو پڑھا ہوا نشان زد کریں",
    SeeAll: "سب دیکھیں",
    Refresh: "تازہ کریں",
    close: "بند کریں",
    Calling: "کال کر رہا ہے",
  },
  /*** Main Menu ***/
  newsFeed: {
    WriteForMe: "میرے لئے لکھیں",
    AddDocuments: "دستاویزات شامل کریں",
    AddPolls: "پولز شامل کریں",
    WhatsOnYourMind: "آپ کے دل میں کیا ہے",
    Direction: "ltr",
    Generate: "پیدا کریں",
    Change: "تبدیل کریں",
    Whatwouldyouliketotalkabout: "آپ کیسے بات چیت کرنا چاہتے ہیں؟",
    Post: "پوسٹ",
    Poll: "پول",
    Documents: "دستاویزات",
    PhotoVideo: "تصویر / ویڈیو",
    Addpolloption: "پول اختیار شامل کریں",
    Removepoll: "پول ہٹائیں",
    WriteName: "نام لکھیں",
    AddImageVideo: "تصویر / ویڈیو شامل کریں",
    CheckIn: "چیک ان",
    ShareonFeed: "فیڈ پر شیئر کریں",
    ShareinChat: "چیٹ میں شیئر کریں",
    NoPosts: "کوئی پوسٹ نہیں...",
    Nomoreposts: "اور کوئی پوسٹ نہیں...",
    noDataMessage: "ابھی پوسٹ بنائیں!",
    Saysomethingaboutthis: "اس کے بارے میں کچھ کہیں...",
    specificTimeline: "کا ٹائم لائن",
    SharedestinationNames: {
      [SharePostDestinations.FEED]: "فیڈ",
      [SharePostDestinations.MEMBERS_PROFILE]: "رکن کی پروفائل",
      [SharePostDestinations.GROUP]: "گروپ",
      [SharePostDestinations.PROJECT]: "پروجیکٹ",
      [SharePostDestinations.TIMELINE]: "Timeline",
    },
    SharePostSuggestionPlaceholder: {
      [SuggestionTypeEnum.Employee]: "رکن منتخب کریں",
      [SharePostDestinations.GROUP]: "گروپ منتخب کریں",
      [SharePostDestinations.PROJECT]: "پروجیکٹ منتخب کریں",
    },
    QuickOptions: {
      CreateSlide: "اسلائیڈ بنائیں",
      CreateBoard: "بورڈ بنائیں",
      CreateGrid: "گرڈ بنائیں",
      CreatePad: "پیڈ بنائیں",
      CreateTask: "ٹاسک بنائیں",
      CreateExpense: "خرچے بنائیں",
      AddUser: "صارف شامل کریں",
    },
    reactionDescription: {
      [ReactionType.Like]: "پسند ہے",
      [ReactionType.Love]: "محبت ہے",
      [ReactionType.NoReaction]: "پسند ہے",
      [ReactionType.Support]: "قدر کریں",
      [ReactionType.Celebrate]: "جشن منائیں",
      [ReactionType.Curious]: "حیران ہوں",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "آپ کے دل میں کیا ہے",
      [PostType.POLL]: "کچھ پوچھیں...",
    },
    Titlecantbeempty: "عنوان خالی نہیں ہو سکتا",
    Attachmentscantbeempty: "پیغام کو خالی نہیں چھوڑا جا سکتا",
    Optioncantbeempty: "اختیار خالی نہیں ہو سکتا",
    SuccessfullySharedonFeed: "فیڈ پر کامیابی سے شیئر کیا گیا",
    SuccessfullySharedonMembersProfile: "ممبر کی پروفائل پر کامیابی سے شیئر کیا گیا",
    SuccessfullyPostSharedMessage:{
      [PostReferenceType.MAIN_FEED]: "مرکزی فیڈ پر کامیابی سے شیئر کیا گیا",
      [PostReferenceType.GROUP]: "گروپ میں کامیابی سے شیئر کیا گیا",
      [PostReferenceType.PROJECTS]: "پروجیکٹ پر کامیابی سے شیئر کیا گیا",
      [PostReferenceType.TIMELINE]: "ٹائم لائن پر کامیابی سے شیئر کیا گیا",
    },
    LoadMore: "مزید لوڈ کریں",
    Quizez: "کوئزز",
    content: "مواد"

  },


  /*** navMenuLabel only used in LeftSideBar ***/
  navMenuLabel: {
    Schedules: "شیڈولز",
    workWiseCompanies: "کام کے لحاظ سے کمپنیاں",
    promotions: "پروموشنز",
    customApprovals: "حسب ضرورت منظوری",
    feed: "نیوز فیڈ",
    messenger: "میسنجر",
    mailBox: "میل باکس",
    groups: "گروپس",
    projects: "پروجیکٹس",
    tasks: "ٹاسک",
    workBoard: "ورک بورڈ",
    leadManager: "لیڈ مینیجر",
    schedules: "شیڈولز",
    expense: "اخراجات",
    travel: "سفر",
    docsArchives: "دستاویزات",
    eLearning: "ای لرننگ",
    inventory: "انوینٹری",
    customApproval: "اپنی مرضی کی منظوری",
    myTeam: "میری ٹیم",
    orgChart: "تنظیم چارٹ",
    payroll: "پے رول",
    employee: "ملازمین",
    administration: "انتظامیہ",
    appraisals: "تشخیص",
    departments: "محکمے",
    loan: "قرض",
    leaves: "چھٹی",
    loan: "قرضے",
    holidays: "چھٹیاں",
    careers: "کیریئر",
    companies: "کمپنی",
    menu: "مین مینو",
    hr: "حوالہ جات",
    privacy: "رازداری",
    TC: "اصطلاح اور شرط",
    more: "معلومات",
    feedBack: "تاثرات",
    warnings: "انتباہات",
    complains: "شکایت",
    bonus: "انعام",
    resignations: "استعفی",
    requisition: "درخواست",
    assets: "اثاثوں کی تقسیم",
    createAssets: "اثاثے بنائیں",
    assetsList: "اثاثوں کی فہرست",
    requestListItems: "اشیاء کی درخواست",
    rewards: "انعامات",
    businessPolicy: "کاروباری پالیسی",
    form: "فارم",
    finance: "مالیات",
    chartOfAccount: "اکاؤنٹ کا چارٹ",
    report: "رپورٹ",
    voucher: "واؤچر",
    createVoucher: "واوچر بنائیں",
    voucherList: "واوچر لسٹ",
    Quotation: "اقتباسات",
    QuotationClient: "کوٹیشن کلائنٹ",
    transaction: "لین دین",
    ledgerReport: "لیجر رپورٹ",
    salary: "تنخواہ",
    payroll: "پے رول",
    jobBoard: "کونسل برائے ملازمت",
    teams: "میری ٹیم",
    LD: "ایل اینڈ ڈی",
    marketPlace: "مارکیٹ پلیس",
    Courses: "کورسز",
    selectMembers: "ممبرز منتخب کریں",
    auction: "مزاد",
    Nodescription: "کوئی تفصیلات نہیں",
    discussionBoard: "مباحثہ بورڈ",
    pages: "صفحات",
    trialBalance: "ٹریل بیلنس",
  },

  /*** AppHeader only used in Component headerTab ***/
  appHeader: {
    /*** مینو ***/
    newsFeed: {
      posts: "پوسٹس",
      photos: "تصاویر",
      videos: "ویڈیوز",
      polls: "پولز",
      docs: "دستاویزات",
      tagged: "ٹیگ کیا گیا",
    },
    mailBox: {},
    messenger: {},
    groups: {
      groups: "گروہات",
    },
    projects: {
      projects: "پروجیکٹس",
      dashboard: "ڈیش بورڈ",
    },
    Task: {
      Tasks: "ٹاسکس",
      myTask: "میرے ٹاسکس",
      assignedByMe: "میرے ذریعے مختصر کیے گئے",
      createdByMe: "میرے ذریعے بنائے گئے",

      teamTask: "ٹیم ٹاسک",
      dashboard: "ڈیش بورڈ",
    },
    leadManager: {
      leadCategory: "لیڈ کیٹیگری",
    },

    schedules: {
      calendar: "کیلنڈر",
      schedule: "شیڈول",
      scheduleInterviews: "انٹرویو کا شیڈول",
    },
    expense: {
      expenses: "اخراجات",
      forApprovals: "منظوری کے لئے",
      myExpenses: "میرے اخراجات",
      forExecution: "اجراء کے لئے",
      forFinance: "مالیہ کے لئے",
      reports: "رپورٹس",
      dashboard: "ڈیش بورڈ",
    },
    travel: {
      travels: "سفر",
      forApprovals: "منظوری کے لئے",
      agentProcess: "ایجنٹ کا عمل",
      travelDetail: "سفر کی تفصیل",
    },
    docsArchives: {
      documents: "دستاویزات",
      myDrive: "میری ڈرائیو",
      myDocuments: "میرے دستاویزات",
      forApprovals: "منظوری کے لئے",
    },
    eLearning: {
      dashboard: "ڈیش بورڈ",
    },
    inventory: {
      approvals: "منظوری",
      inventory: "انوینٹری",
      category: "کیٹیگری",
    },
    customApproval: {
      CreateCustomApproval: "کسٹم منظوری بنائیں",
      CustomApproval: "کسٹم منظوری",
      ForApproval: "منظوری کے لئے",
      MyCustomApprovals: "میری کسٹم منظوری",
      AllCustomApproval: "سب ٹائپ منظوری",
    },
    payroll: {
      payroll: "پی رول",
      payrollApproval: "پی رول منظوری",
      salaryApproval: "تنخواہ منظوری",
    },
    administration: {
      administration: "انتظامیہ",
    },
    appraisals: {
      appraisals: "تشہیر",
    },
    reward_category: {
      reward_category: "انعام کی قسم",
    },
    departments: {
      departments: "اہلکار",
    },
    leaves: {
      myLeaves: "میری چھٹیاں",
      forApprovals: "منظوری کے لئے",
    },
    holidays: {
      holidays: "چھٹیاں",
    },
    warnings: {
      warnings: "انتباہات",
    },
    resignations: {
      resignations: "استعفی",
      approvers: "منظور کنندگان",
    },
    complains: {
      complains: "شکایتیں",
      approvers: "منظور کنندگان",
      createComplain: "شکایت درست کریں",
    },

    rewards: {
      rewards: "انعامات",
      AllRewards: "تمام بونس",
      CreatedByMe: "میرے ذریعے بنائے گئے",
      ForApprovals: "منظوری کے لئے",
      MyRewards: "میرے انعامات"
    },
    promotions: {
      Promotions: "ترقی",
    },
    salary: {
      Salary: "تنخواہ",
      CreateSalary: "تنخواہ بنائیں",
    },
    pages: {
      Pages: "صفحات",
    }
  },
  /*** Main Menu ***/
  globalSearch: {
    All: "سب",
    feed: "فیڈ",
    Lead_Manager: "لیڈ منیجر",
    Travel: "سفر",
    Document: "دستاویز",
    Project: "پروجیکٹ",
    Workboard: "ورک بورڈ",
    Group: "گروپ",
    Expense: "خرچ",
    Task: "کام",
    Employee: "ملازم",
    e_learning_course: "ای لرننگ کورس",
    e_learning_videos: "ای لرننگ ویڈیوز",
    e_learning_article: "ای لرننگ مضمون",
    e_learning_quiz: "ای لرننگ کوئز",
    e_learning_book: "ای لرننگ کتاب",
    e_learning_tedTalks: "ای لرننگ ٹیڈ ٹاکس",
    rewards: "انعامات",
  },
  newsFeed: {
    WriteForMe: "میرے لئے لکھیں",
    AddDocuments: "دستاویزات شامل کریں",
    AddPolls: "پولز شامل کریں",
    WhatsOnYourMind: "آپ کے دل میں کیا ہے",
    Direction: "ltr",
    Generate: "پیدا کریں",
    Change: "تبدیل کریں",
    Whatwouldyouliketotalkabout: "آپ کیسے بات چیت کرنا چاہتے ہیں؟",
    Post: "پوسٹ",
    Poll: "پول",
    Documents: "دستاویزات",
    PhotoVideo: "تصویر / ویڈیو",
    Addpolloption: "پول اختیار شامل کریں",
    Removepoll: "پول ہٹائیں",
    WriteName: "نام لکھیں",
    AddImageVideo: "تصویر / ویڈیو شامل کریں",
    CheckIn: "چیک ان",
    ShareonFeed: "فیڈ پر شیئر کریں",
    ShareinChat: "چیٹ میں شیئر کریں",
    NoPosts: "کوئی پوسٹ نہیں...",
    Nomoreposts: "اور کوئی پوسٹ نہیں...",
    noDataMessage: "ابھی پوسٹ بنائیں!",
    CreateFeedNow: "اب فیڈ بنائیں!",

    QuickOptions: {
      CreateSlide: "اسلائیڈ بنائیں",
      CreateBoard: "بورڈ بنائیں",
      CreateGrid: "گرڈ بنائیں",
      CreatePad: "پیڈ بنائیں",
      CreateTask: "ٹاسک بنائیں",
      CreateExpense: "خرچے بنائیں",
      AddUser: "صارف شامل کریں",
    },
    reactionDescription: {
      [ReactionType.Like]: "پسند ہے",
      [ReactionType.Love]: "محبت ہے",
      [ReactionType.NoReaction]: "پسند ہے",
      [ReactionType.Support]: "قدر کریں",
      [ReactionType.Celebrate]: "جشن منائیں",
      [ReactionType.Curious]: "حیران ہوں",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "آپ کے دل میں کیا ہے",
      [PostType.POLL]: "کچھ پوچھیں...",
    },
    Titlecantbeempty: "عنوان خالی نہیں ہو سکتا",
    Attachmentscantbeempty: "پیغام کو خالی نہیں چھوڑا جا سکتا",
    Optioncantbeempty: "اختیار خالی نہیں ہو سکتا",
    SuccessfullySharedonFeed: "فیڈ پر کامیابی سے شیئر کیا گیا",
  },
  mail: {
    composer: "نیا میل بنائیں",
    menuItem: {
      inbox: "ان باکس",
      starred: "ستارہ لگے ہوئے",
      snoozed: "اسنوز کردہ",
      sent: "ارسال کردہ",
      spam: "اسپام",
      archive: "آرکائیو",
      trash: "کوڑے دان",
      draft: "مسودے",
      allMail: "تمام میل",
      createNewLabel: "نیا لیبل بنائیں",
    },
    close: "بند کریں",
    send: "بھیجیں",
    newMessage: "نیا پیغام",
  },
  loanDictionary: {
    createLoan: "قرض بنائیں",
    loanTenureInMonths: "قرض کی مدت/مہینے",
    deductionPerMonth: "مہینے/کٹوتی",
    pleaseEnterloanTenureInMonths: "براہ کرم قرض کی مدت درج کریں",
  },
  auction: {
    auctionType: "نیلامی کی قسم",
    personal: "ذاتی",
    official: "آفیشل",
    offer: "پیشکش",
    startingBid: "شروعی بول",
    buyNowAmount: "فوری خرید مقدار",
    immediateBuy: "فوری خرید",
    minIncreaseBid: "کم سے کم بول بڑھانا",
    Auction: "نیلامی",
    CreateAuction: "نیلامی بنائیں",
    actionType: "نیلامی کی قسم", // extra one - need to remove
    external: "بیرونی",
    auctionDetail: "نیلامی کی تفصیلات",
    currentBid: "موجودہ بول",

    FormLabel: {
      startingBid: "شروعی بول",
      member: "رکن",
      approvers: "منظور کنندگان",
      minIncreaseBid: "کم سے کم بول بڑھانا",
      buyNowAmount: "فوری خرید مقدار",
      offer: "پیشکش",
      immediateBuy: "فوری خرید",
    },
    FormPlaceholder: {
      name: "نیلامی کا نام درج کریں",
      startingBid: "شروعی بول درج کریں",
      minIncreaseBid: "کم سے کم بول بڑھانا درج کریں",
      selectMember: "رکن منتخب کریں",
      selectApprovers: "منظور کنندگان منتخب کریں",
      buyNowAmount: "فوری خرید مقدار درج کریں",
      offer: "پیشکش درج کریں",
    },
    FormRequiredMessage: {
      name: "براہ کرم ایکشن کا نام درج کریں",
      startingBid: "براہ کرم شروعی بول درج کریں",
      minIncreaseBid: "براہ کرم کم سے کم بول بڑھانا درج کریں",
      buyNowAmount: "براہ کرم فوری خرید مقدار درج کریں",
      offer: "براہ کرم پیشکش درج کریں",
      endDate: "براہ کرم اختتامی تاریخ درج کریں",
    },
    auctionFilterTypeEnumLabel: {
      [auctionFilterTypeEnum.All]: "تمام نیلامی",
      [auctionFilterTypeEnum.CreatedByMe]: "میری نیلامی",
      [auctionFilterTypeEnum.ForApproval]: "منظوری کے لئے",
    },
    statusEnum: {
      [AuctionStatusEnum.InProcess]: "جاری ہے",
      [AuctionStatusEnum.Approved]: "منظور ہے",
      [AuctionStatusEnum.Declined]: "منسوخ ہے",
      [AuctionStatusEnum.Resend]: "دوبارہ بھیجی گئی ہے",
      [AuctionStatusEnum.InActive]: "غیر فعال ہے",
      [AuctionStatusEnum.NotRequired]: "ضرورت نہیں ہے",
      [AuctionStatusEnum.Cancelled]: "منسوخ ہوگئی ہے",
      [AuctionStatusEnum.Hold]: "محفوظ ہے",
    },
    auctionOfferStatus: {
      [AuctionOfferStatusEnum.BidInProcess]: "جاری ہے",
      [AuctionOfferStatusEnum.BidSold]: "فروخت ہوگئی ہے",
    },
    noDataMessage: "نیلامی بنائیں",
  },
  messenger: {
    Messenger: "میسنجر",
    Typing: "ٹائپنگ...",
    TypeaMessage: "پیغام ٹائپ کریں...",
    Recording: "ریکارڈنگ...",
    nameYourGroup: "اپنے گروہ کا نام دیں",
    Create: "بنائیں",
    GroupNameRequired: "گروہ کا نام ضروری ہے",
    searchMemberToAdd: "شامل کرنے کے لئے ممبر تلاش کریں",
    contacts: "رابطے",
    selected: "منتخب کیا گیا",
    whatwouldyoutalkingabout: "آپ کیسے بات چیت کر رہے ہیں؟",
    SayHi: "اپنے رابطوں کو ہائی بولیں",
  },
  marketPlace: {
    jobBoardName: "نوکری کا بورڈ",
    requsitionName: "درخواست",
  },
  discussionBoard: {
    DiscussionBoard: "چرچا بورڈ",
    Topic: "موضوع",
    pleaseEnterTopicName: "براہ کرم اوضاع کا نام درج کریں",
    enterTopicName: "موضوع کا نام درج کریں",
    addDiscussionBoard: "چرچا بورڈ شامل کریں",
    errormessage: "چرچا بورڈ حاصل کرتے وقت خطا پیش آئی ہے۔",
    noDataMessage: "– اب چرچا شروع کریں!",
  },
  pages: {
    messageName: "براہ کرم نام درج کریں",
    tags: "ٹیگز",
    messageTags: "براہ کرم ٹیگز درج کریں",
    enterTag: "ٹیگز درج کریں",
    createPage: "صفحہ بنائیں",
    noDataMessage: "– اب صفحہ بنائیں!"
  },
  group: {
    group: "گروہ",
    createGroup: "گروہ بنائیں",
    Updategroup: "گروہ کو اپ ڈیٹ کریں",
    enterGroupName: "براہ کرم گروہ کا نام درج کریں۔",
    Code: "کوڈ",
    exampleText: "مثال: جی (ایک گروہ کا نام کے لئے)",
    GroupTag: "گروہ ٹیگ",
    AddAdmin: "ایڈمن شامل کریں",
    TotalBudget: "کل بجٹ",
    Spent: "خرچ کیا گیا",
    Balance: "بیلنس",
    EnterGroupTag: "گروہ ٹیگ درج کریں",
    SaveGroupTag: "گروہ ٹیگ محفوظ کریں",
    AddGroupTag: "گروہ ٹیگ شامل کریں",
    EditGroup: "گروہ میں ترتیب دیں",
    CreateGroupNow: "اب گروپ بنائیں!"
  },
  project: {
    WIP: "WIP",
    privateProject: "نجی منصوبہ",
    Projects: "منصوبے",
    UpdateProject: "پروجیکٹ کو اپ ڈیٹ کریں",
    CreateProject: "پروجیکٹ بنائیں",
    noDataMessage: "– اب پروجیکٹ بنائیں!",
    ProjectTag: "پروجیکٹ ٹیگ",
    ProjectRole: "پروجیکٹ کردار",
    exampleProjectName: "مثال: پ (ایک پروجیکٹ کے نام کے لئے)",
    projectDate: "پروجیکٹ کی تاریخ",
    EnterProjectTag: "پروجیکٹ ٹیگ درج کریں",
    SaveProjectTag: "پروجیکٹ ٹیگ محفوظ کریں",
    AddProjectTag: "پروجیکٹ ٹیگ شامل کریں",
    editProject: "پروجیکٹ میں ترتیب دیں",
    PersonalNotes: "شخصی نوٹس",
    EnterProjectRole: "پروجیکٹ کردار درج کریں",
    SaveCustomTag: "کسٹم ٹیگ محفوظ کریں",
    AddProjectRole: "پروجیکٹ کردار شامل کریں",
    TakeaNote: "نوٹ لیں",
    PublicProject: "عوامی منصوبہ",
    ProjectFeatureTabs:{
      [ProjectFeatureEnums.Feed]: "فیڈ",
      [ProjectFeatureEnums.Schedule]: "پروگرام",
      [ProjectFeatureEnums.WorkBoard]: "ورک بورڈ",
      [ProjectFeatureEnums.Document]: "بیلگ",
      [ProjectFeatureEnums.Task]: "گاسک",
      [ProjectFeatureEnums.Expense]: "گیر",
      [ProjectFeatureEnums.Travel]: "سیاحی",
      [ProjectFeatureEnums.Quotation]: "تحمیل",
      [ProjectFeatureEnums.Budget]: "بجٹ",
      [ProjectFeatureEnums.Setting]: "آپشن",
      [ProjectFeatureEnums.DiscussionBoard]: "چرچا بورڈ",
      [ProjectFeatureEnums.Pages]: "صفحہ",
      [ProjectFeatureEnums.Issues]: "مسائل",
      [ProjectFeatureEnums.Contract]: "معاہدہ",
    }
  },
  tasks: {
    Tasks: "ٹاسکس",
    Predecessor: "پیش گوئی کنندہ",
    AssignTo: "تفویض کریں",
    TaskDate: "ٹاسک کی تاریخ",
    CreateTask: "ٹاسک بنائیں",
    TaskAssignTo: "ٹاسک کو تفویض کریں",
    Rating: "ریٹنگ",
    TaskDetail: "ٹاسک کی تفصیل",
    ProgressofAssignedmembers: "تفویض شدہ ممبرز کا پیشرفت",
    SubTasks: "سب ٹاسکس",
    Subtask: "سب ٹاسک",
    CreateSubtask: "سب ٹاسک بنائیں",
    Addsubtask: "سب ٹاسک شامل کریں",
    ParentTask: "پیرنٹ ٹاسک",
    DueToday: "آج تک",
    DueTomorrow: "کل تک",
    Overdue: "تاخیر سے",
    Duein: "تاخیر میں",
    days: "دن",
    TaskCreatedSuccessfully: "کام کامیابی سے بنا دیا گیا",
    noDataMessage: "– اب ٹاسک بنائیں!",

    selfTaskEnum: {
      [SelfTaskEnum.SelfTask]: "خود کا ٹاسک",
      [SelfTaskEnum.AssignTask]: "تفویض کریں",
    },
    Priority: "ترتیب",
    taskFilterTypeEnum: {
      [TaskFilterTypeEnum.CreatedByMe]: "میرے ذریعے تفویض شدہ",
      [TaskFilterTypeEnum.MyTasks]: "میرے ٹاسکس",
      [TaskFilterTypeEnum.MyTeamTasks]: "ٹیم ٹاسکس",
    },
    statusEnum: {
      [TaskStatusEnum.NotStarted]: "شروع نہیں ہوا",
      [TaskStatusEnum.InProcess]: "عمل میں ہے",
      [TaskStatusEnum.Completed]: "مکمل ہوگیا",
      [TaskStatusEnum.RatingAssign]: "ریٹنگ مختصر",
      [TaskStatusEnum.Cancel]: "منسوخ",
      [TaskStatusEnum.Hold]: "متوقف",
    },
    taskReferenceTypeEnum: {
      [TaskReferenceTypeEnum.General]: "عام",
      [TaskReferenceTypeEnum.Project]: "پروجیکٹ",
      [TaskReferenceTypeEnum.Group]: "گروہ",
      [TaskReferenceTypeEnum.Lead]: "لیڈ",
    },
    taskPriorityEnum: {
      [TaskPriorityEnum.Default]: "پہلے سے مخصوص",
      [TaskPriorityEnum.Low]: "کم",
      [TaskPriorityEnum.Medium]: "متوسط",
      [TaskPriorityEnum.High]: "زیادہ",
    },
    SortEnum: {
      [TaskFilterSortEnum.CreateDateDesc]: "تخلیق کی تاریخ - نزولی",
      [TaskFilterSortEnum.CreateDateAsc]: "تخلیق کی تاریخ - اُرُوجی",
      [TaskFilterSortEnum.StartDateDesc]: "شروع کی تاریخ - نزولی",
      [TaskFilterSortEnum.StartDateAsc]: "شروع کی تاریخ - اُرُوجی",
      [TaskFilterSortEnum.UpdateDateDesc]: "تازہ کاری کی تاریخ - نزولی",
      [TaskFilterSortEnum.UpdateDateAsc]: "تازہ کاری کی تاریخ - اُرُوجی"
    }
  },
  contracts: {
    Contract: "معاہدہ",
    createContract: "معاہدہ بنائیں",
    contractDate: "معاہدے کی تاریخ",
    contractPurpose: "معاہدے کا مقصد",
    selectContractPurpose: "معاہدے کا مقصد منتخب کریں",
    purposeofContractisRequired: "معاہدے کا مقصد ضروری ہے",
    category: "زمرہ",
    purpose: "مقصد",
    signatureMember: "دستخط کرنے والے اراکین",
    contractDetail: "معاہدے کی تفصیلات",
    selectCategory: "زمرہ منتخب کریں",
    cateogryofContractisRequired: "زمرہ ضروری ہے",
    externalMember: "باہر کے دستخط کرنے والے اراکین",
    selectinternalMembers: "اندرونی دستخط کرنے والے اراکین منتخب کریں",
    internalMembers: "اندرونی دستخط کرنے والے اراکین",
    selectExternalMember: "باہر کے دستخط کرنے والے اراکین منتخب کریں",
    contractType: "معاہدے کی قسم",
    contractTypeRequired: "معاہدے کی قسم ضروری ہے",
    internal: "اندرونی",
    external: "باہر",
    noDataMessage: "اب معاہدہ بنائیں!",
    contractsFilterTypeLables: {
      [ContractFilterTypeEnum.Contracts]: "تمام کاروباری معاہدے",
      [ContractFilterTypeEnum.BusinessContract]: "میرے معاہدے"
    },
    statusEnum: {
      [ContractStatusEnum.InProcess]: "عمل میں",
      [ContractStatusEnum.Completed]: "مکمل",
      [ContractStatusEnum.Cancelled]: "منسوخ"
    },
    contractReferenceTypeEnum: {
      [ContractReferenceTypeEnum.General]: "عام",
      [ContractReferenceTypeEnum.Project]: "پروجیکٹ",
      [ContractReferenceTypeEnum.Group]: "گروپ",
      [ContractReferenceTypeEnum.Lead]: "لیڈ"
    }
  },
  workBoard: {
    WorkBoardDashBoard: "ورک بورڈ ڈیش بورڈ",
    pleaseEnterYourBoardName: "براہ کرم اپنے بورڈ کا نام درج کریں",
    pleaseEnterYourBoardDescription: "براہ کرم اپنی بورڈ کی تفصیلات درج کریں",
    EnterMembers: "ممبرز درج کریں",
    UpdateBoard: "بورڈ کو اپ ڈیٹ کریں",
    CreateBoard: "بورڈ بنائیں",
    privacy: "رازداری",
    AddSection: "سیکشن شامل کریں",
    AddTodo: "ٹوڈو شامل کریں",
    Save: "محفوظ کریں",
    EnterSectionTitle: "سیکشن ٹائیٹل درج کریں",
    InSection: "سیکشن میں",
    Label: "لیبل",
    Activity: "سرگرمی",
    Labels: "لیبلز",
    UploadCoverPhoto: "کور کی تصویر اپ لوڈ کریں",
    Section: "سیکشن",
    Board: "بورڈ",
    Todos: "ٹوڈوز",
    CompleteDate: "مکمل تاریخ",
    DetailedDesc: "مزید تفصیلات شامل کریں...",
    EnterTodo: "اس کارڈ کے لئے متن درج کریں...",
    CreateWorkBoardNow: "- اب ورک بورڈ بنائیں!",
    workBoardExample: "مثال: ورک بورڈ کے لئے (WB)",
  },
  leadManager: {
    LeadManager: "لیڈ منیجر",
    AssignedLeads: "تفویض شدہ لیڈز",
    AllLeads: "تمام لیڈز",
    Groups: "گروہات",
    email: "ای میل",
    Lead: "لیڈ",
    Leads: "لیڈز",
    Pleaseinputyourboardname: "براہ کرم اپنا بورڈ کا نام درج کریں!",
    exampleLeadManagerMessage: "مثال: LM (ایک گروہ کا نام لیڈ منیجر)",
    inputBoardDescription: "براہ کرم اپنا بورڈ کا تفصیلات درج کریں!",
    UpdateLeadManager: "لیڈ منیجر کو اپ ڈیٹ کریں",
    CreateLeadManager: "لیڈ منیجر بنائیں",
    CreateLeadGroup: "لیڈ گروہ بنائیں",
    noDataMessage: "– اب لیڈ منیجر بنائیں!",
    LastUpdated: "آخری اپ ڈیٹ",
    LeadValue: "لیڈ قیمت",
    individual: "فرد",
    leadEmailAddress: "لیڈ ای میل ایڈریس",
    leadPhoneNumber: "لیڈ فون نمبر",
    leadAddress: "لیڈ ایڈریس",
    leadUrl: "لیڈ یو آر ایل",
    ConvertProject: "پروجیکٹ میں تبدیل کریں",
    goToProject: "پروجیکٹ پر جائیں",
    SelectAssignMembers: "تفویض کرنے والے ممبرز منتخب کریں",
    LeadGroup: "لیڈ گروہ",
    leadName: "لیڈ کا نام",
    leadMembers: "لیڈ ممبرز",
    leadWebiste: "لیڈ ویب سائٹ",
    LeadValuenegative: "لیڈ قیمت منفی نہیں ہوسکتی!",
    AddLead: "لیڈ شامل کریں",
    SelectCommunicationTo: "مواصلات کے لیے منتخب کریں",
    CommunicationTypeisrequired: "مواصلات کی قسم کی ضرورت ہے!",
    PleaseselectCommunicationType: "براہ کرم مواصلات کی قسم کا انتخاب کریں",
    CommunicationType: "مواصلات کی قسم",
    AddCommunication: "مواصلات شامل کریں",
    CommunicationTo: "مواصلات کرنے والے کو",
    NoCalls: "کوئی کالیں نہیں",
    LeadsValue: "لیڈز قیمت",
    metricTypeEnum: {
      [LeadManagerMetricTypeEnum.allGroup]: "کل گروہات",
      [LeadManagerMetricTypeEnum.allAssign]: "تفویض شدہ لیڈ",
      [LeadManagerMetricTypeEnum.leadToProject]: "پروجیکٹ میں تبدیل کریں",
      [LeadManagerMetricTypeEnum.leadSection]: "سب سے زیادہ لیڈز سیکشن",
    },
  },
  schedule: {
    StartMeeting: "میٹنگ شروع کریں",
    MeetinginProgress: "میٹنگ جاری ہے",
    EndMeeting: "میٹنگ ختم ہوئی",
    MeetingEnded: "میٹنگ ختم ہو گئی",
    NewTokenAdded: "نیا ٹوکن شامل ہوا",
    Copied: "کاپی ہو گئی",
    SuccessfullyBlocked: "کامیابی سے بلاک ہو گیا",
    SuccessfullyUnBlocked: "کامیابی سے ان بلاک ہو گئیا",
    UpdateCalendarLink: "کیلنڈر لنک کو اپ ڈیٹ کریں",
    AddCalendarLink: "کیلنڈر لنک شامل کریں",
    GetCalendarLink: "کیلنڈر لنک حاصل کریں",
    CopyLink: "لنک کاپی کریں",
    Block: "بلاک",
    UnBlock: "ان بلاک",
    CreateSchedule: "شیڈول بنائیں",
    Draft: "ڈرافٹ",
    Finalize: "حتمی کریں",
    Complete: "مکمل",
    Preview: "پیش نظارہ",
    MinutesOftheMeeting: "میٹنگ کی منٹس",
    MeetingId: "میٹنگ آئی ڈی",
    Venue: "جگہ",
    Workwisecall: "ورک وائز کال",
    ConferenceCall: "کانفرنس کال",
    Attendees: "حاضرین",
    Absentees: "غیر حاضرین",
    PointsofDiscussion: "تبادلے کے نکات",
    AddPoint: "نکتہ شامل کریں",
    Print: "پرنٹ",
    SaveToPDF: "PDF میں محفوظ کریں",
    MEETINGMINUTES: "میٹنگ کی منٹس",
    Yes: "ہاں",
    No: "نہیں",
    WorkwiseCall: "ورک وائز کال",
    MeetingObjectives: "میٹنگ کا مقصد",
    Points: "نکات",
    RefNo: "ریف نمبر",
    AttendanceatMeeting: "میٹنگ میں حاضری",
    Loadingschedules: "شیڈول لوڈ ہو رہے ہیں...",
    Nodata: "کوئی ڈیٹا نہیں...",
    Projects: "پروجیکٹس",
    Groups: "گروہات",
    titleMessage: "کیا آپ یقینی ہیں؟ تبدیلی قسم سے منسلک اراکین کو ہٹا دے گی۔",
    pleaseSelectAssignMemeber: "براہ کرم اراکین منتخب کریں!",
    SelectAssignto: "انسائیں کریں",
    GetLink: "لنک حاصل کریں",
    Anyone: "کوئی بھی",
    anyoneText: "لنک کے ساتھ کوئی بھی مقرر کر سکتا ہے۔",
    TodayEvents: "آج کی واقعات",
    UpcomingEvents: "آنے والی واقعات",
    InterviewRequests: "انٹرویو درخواستیں",
    sendviachat: "چیٹ کے ذریعے بھیجیں",
    OnVideoConference: "ویڈیو کانفرنس پر:",
    OnWorkwiseCall: "ورک وائز کال پر",
    Enterlocation: "جگہ درج کریں",
    Duration: "مدت",
    inminutes: "میں منٹ",
    Durationisrequired: "مدت ضروری ہے",
    Rooms: "کمرے",
    SelectRoom: "کمرہ منتخب کریں",
    CheckRoomAvailability: "کمرے کی دستیابی چیک کریں",
    Available: "دستیاب ہے",
    NotAvailable: "دستیاب نہیں ہے",
    TravelTime: "سفر کا وقت",
    PreparationTime: "تیاری کا وقت",
    SendviaEmail: "ای میل کے ذریعے بھیجیں",
    schedulerCalender: "شیڈولر کیلنڈر",
    Next: "اگلا",
    Prev: "پچھلا",
    titlemyCustomButton: "میری کسٹم بٹن",
    prevnexttoday: "پچھلا اگلا آج",
    timeGridDaytimeGridWeekdayGridMonth: "ٹائم گرڈ ڈے ٹائم گرڈ ہفتہ ڈے گرڈ مہینہ",
    timeGridDay: "ٹائم گرڈ ڈے",
    top: "اوپر",
    block: "بلاک",
    minutes: "منٹ",
    AllDay: "پورے دن",
    timeGridWeek: "ٹائم گرڈ ہفتہ",
    slotDuration: "00:15:00",
    numeric: "عددی",
    dayGridMonth: "ڈے گرڈ مہینہ",
    dayGridWeek: "ڈے گرڈ ہفتہ",
    timeGrid: "ٹائم گرڈ",
    Day: "دن",
    Week: "ہفتہ",
    Month: "مہینہ",
    NoEventsonthisday: "اس دن کوئی واقعات نہیں ہیں۔",
    CreateInterviewSchedule: "انٹرویو شیڈول بنائیں",
    Interviewwith: "انٹرویو کے ساتھ",
    MySchedules: "میرے شیڈولز",
    TeamSchedules: "ٹیم کے شیڈولز",
    Past: "ماضی",
    Today: "آج",
    Upcoming: "آئنہ",
    ScheduleDetail: "شیڈول کی تفصیل",
    JoinWorkwiseCall: "ورک وائز کال میں شامل ہوں",
    AddWorkwiseCall: "ورک وائز کال شامل کریں",
    Host: "(ہوسٹ)",
    MakeHost: "ہوسٹ بنائیں",
    Attending: "حاضر",
    UpdateSchedule: "شیڈول کو اپ ڈیٹ کریں",
    Calendar: "کیلنڈر",
    Schedule: "شیڈول",
    ShareCalendarLink: "کیلنڈر لنک شیئر کریں",
    pointer: "پوائنٹر",
    default: "پہلے فیصلہ کردہ",
    blue: "نیلا",
    red: "سرخ",
    purple: "جامنی",
    Self: "خود",
    AssignTo: "انسائیں کریں",
    assign: "انسائیں",
    min: "منٹ",
    Minutes: "15 منٹ",
  },
  expenses: {
    CreateExpense: "اخراجات بنائیں",
    IsReimbursable: "تلافی کے قابل ہے",
    WriteHeaderHere: "یہاں ہیڈر لکھیں...",
    ExpenseDetail: "اخراجات کا تفصیل",
    ExpenseDate: "اخراجات کی تاریخ",
    Executors: "ناظرین",
    Financers: "مالی کارندے",
    ExecutorRequired: "ناظر ضروری ہے",
    selectExecutor: "اخراجات کے ناظر منتخب کریں",
    noDataMessage: "اپنے مالی سفر کو مضبوط بنائیں - ابھی اخراجات بنائیں!",
    expenseFilterTypeEnum: {
      [ExpenseFilterTypeEnum.MyExpense]: "میرا اخراجات",
      [ExpenseFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [ExpenseFilterTypeEnum.ForExecutions]: "نظر ثانی کے لئے",
      [ExpenseFilterTypeEnum.ForFinance]: "مالیہ کے لئے",
    },
    expenseType: {
      [ExpenseReferenceTypeEnum.General]: "عام",
      [ExpenseReferenceTypeEnum.Group]: "گروہ",
      [ExpenseReferenceTypeEnum.Project]: "پروجیکٹ",
      [ExpenseReferenceTypeEnum.Travel]: "سفر",
      [ExpenseReferenceTypeEnum.Assets]: "اثاثے",
    },
    statusEnum: {
      [ExpenseApprovalStatusEnum.InProcess]: "عمل میں ہے",
      [ExpenseApprovalStatusEnum.Approved]: "منظور شدہ",
      [ExpenseApprovalStatusEnum.Cancelled]: "منسوخ شدہ",
      [ExpenseApprovalStatusEnum.Completed]: "مکمل شدہ",
      [ExpenseApprovalStatusEnum.Hold]: "رکاوٹ میں ہے",
      [ExpenseApprovalStatusEnum.Disbursed]: "ادا کیا گیا",
    },
    SortEnum: {
      [ExpenseFilterSortEnum.CreateDateDesc]: "تخلیق تاریخ - کم سے زیادہ",
      [ExpenseFilterSortEnum.CreateDateAsc]: "تخلیق تاریخ - زیادہ سے کم",
      [ExpenseFilterSortEnum.ExpenseDateDesc]: "خرچ کی تاریخ - کم سے زیادہ",
      [ExpenseFilterSortEnum.ExpenseDate]: "خرچ کی تاریخ - زیادہ سے کم",
      [ExpenseFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [ExpenseFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - کم سے زیادہ",
      [ExpenseFilterSortEnum.Amount]: "رقم",
      [ExpenseFilterSortEnum.AmountDesc]: "رقم - کم سے زیادہ"
    },
    Category: {
      [ExpenseCategoryEnum.Transport]: "نقل و حمل",
      [ExpenseCategoryEnum.Health]: "صحت",
      [ExpenseCategoryEnum.Food]: "کھانا",
      [ExpenseCategoryEnum.Shopping]: "خریداری",
      [ExpenseCategoryEnum.Entertainment]: "تفریح",
      [ExpenseCategoryEnum.Travel]: "سفر",
      [ExpenseCategoryEnum.Bill]: "بل",
      [ExpenseCategoryEnum.Office]: "دفتر",
      [ExpenseCategoryEnum.Fuel]: "سوخت",
      [ExpenseCategoryEnum.Asset]: "اثاثہ",
      [ExpenseCategoryEnum.Other]: "دیگر"
    }

  },
  travel: {
    TravelDetail: "سفر کا تفصیل",
    CreateTravel: "سفر بنائیں",
    PleaseAddTravelDetail: "براہ کرم سفر کا تفصیل شامل کریں",
    TravelToandFrom: "سفر کریں اور سے",
    ReturnDateIsRequired: "واپسی کی تاریخ ضروری ہے",
    PleaseSelectTravelType: "براہ کرم سفر کی قسم منتخب کریں",
    HotelTADACar: "ہوٹل، ٹی ڈا اور گاڑی",
    HotelRequired: "ہوٹل کی ضرورت ہے",
    RentaCar: "کار کرائیں",
    PickUpRequired: "پک اپ کی ضرورت ہے",
    DropOffRequired: "ڈراپ آف کی ضرورت ہے",
    TADAApplicable: "ٹی ڈا لاگو ہوتا ہے",
    TravelBy: "سفر کریں",
    SpecialRequest: "خصوصی درخواست",
    SearchDestinationCity: "منزل کا شہر تلاش کریں",
    SearchDepartureCity: "رخصتی شہر تلاش کریں",
    PleaseSelectDepartureCity: "براہ کرم رخصتی شہر منتخب کریں۔",
    PleaseSelectDestinationCity: "براہ کرم منزل کا شہر منتخب کریں۔",
    DepartureTimeIsRequired: "رخصت کا وقت ضروری ہے",
    RetunTimeIsRequired: "واپسی کا وقت ضروری ہے",
    AttachmentType: "منسلکہ قسم",
    WriteSpecialRequestDetail: "خصوصی درخواست کا تفصیل لکھیں",
    Agents: "ایجنٹس",
    Departure: "رخصت",
    Arrival: "آمد",
    DepartureDate: "رخصت کی تاریخ",
    DepartureTime: "رخصت کا وقت",
    DepartureCity: "رخصتی شہر",
    ShiftType: "شفٹ قسم",
    DepartureShift: "رخصت شفٹ",
    ArrivalCity: "آمد شہر",
    CreateTravelnow: "فوراً سفر بنائیں",

    TravelAttachmentTypeOptions: {
      [TravelAttachmentTypeEnum.Passport]: "پاسپورٹ",
      [TravelAttachmentTypeEnum.Ticket]: "ٹکٹ",
      [TravelAttachmentTypeEnum.Insurance]: "بیمہ",
      [TravelAttachmentTypeEnum.CarRental]: "کار کرایہ",
      [TravelAttachmentTypeEnum.Other]: "دیگر",
    },

    Shift: {
      [ShiftEnum.Morning]: "صبح",
      [ShiftEnum.Afternoon]: "دوپہر",
      [ShiftEnum.Evening]: "شام",
      [ShiftEnum.Night]: "رات",
    },

    travelBy: {
      plane: "ہوائی جہاز سے",
      bus: "باس سے",
      ship: "جہاز سے",
      train: "ٹرین سے",
      car: "کار سے",
    },

    TravelOptions: {
      [TravelOptionsEnums.ByPlane]: "ہوائی جہاز سے",
      [TravelOptionsEnums.ByShip]: "جہاز سے",
      [TravelOptionsEnums.ByBus]: "باس سے",
      [TravelOptionsEnums.ByTrain]: "ٹرین سے",
      [TravelOptionsEnums.ByCar]: "کار سے",
    },

    travelFilterTypeEnum: {
      [TravelFilterTypeEnum.Travels]: "سفر",
      [TravelFilterTypeEnum.MyTravel]: "میرا سفر",
      [TravelFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [TravelFilterTypeEnum.AgentProcess]: "ایجنٹ کا عمل",
    },

    statusEnum: {
      [TravelStatusEnum.InProcess]: "عمل میں ہے",
      [TravelStatusEnum.Approved]: "منظور ہوا",
      [TravelStatusEnum.Declined]: "رد کر دیا گیا",
      [TravelStatusEnum.Cancelled]: "منسوخ ہوا",
      [TravelStatusEnum.ApprovedByApprovers]: "منظور ہوا - منظور کرنے والوں نے",
      [TravelStatusEnum.ApprovedByAgents]: "منظور ہوا - ایجنٹس نے",
      [TravelStatusEnum.DeclineByApproves]: "رد کر دیا گیا - منظور کرنے والوں نے",
      [TravelStatusEnum.DeclineByAgents]: "رد کر دیا گیا - ایجنٹس نے",
      [TravelStatusEnum.Hold]: "ہولڈ",
    },
  },
  eLearning: {
    Course: "کورس",
    eBook: "ای بک",
    Quiz: "کوئز",
    TedTalk: "ٹیڈ ٹاک",
    Article: "مضمون",
    Video: "ویڈیو",
    BasicCourseDetail: "بنیادی کورس کی تفصیل",
    SelectSubject: "مضمون منتخب کریں",
    SelectCourse: "کورس منتخب کریں",
    PleaseSelectOption: "براہ کرم اختیار منتخب کریں",
    Selectcoursetype: "کورس کی قسم منتخب کریں",
    CourseName: "کورس کا نام",
    Entercoursename: "کورس کا نام درج کریں",
    Level: "سطح",
    PleaseSelectLevel: "براہ کرم سطح منتخب کریں",
    Selectlevel: "سطح منتخب کریں",
    CourseAdminMembers: "کورس ایڈمن ممبرز",
    CourseAdminisrequired: "کورس ایڈمن ضروری ہے",
    AssignMembers: "ممبرز کو تفویض کریں",
    UpdateEBook: "ای بک کو اپ ڈیٹ کریں",
    CreateEBook: "ای بک بنائیں",
    AuthorName: "مصنف کا نام",
    EnterInformation: "معلومات درج کریں",
    UploadBook: "کتاب اپ لوڈ کریں (PDF)",
    CreateQuiz: "کوئز بنائیں",
    QuizName: "کوئز کا نام",
    CreateTedTalk: "ٹیڈ ٹاک بنائیں",
    ViaLink: "لنک کے ذریعے",
    ViaVideoUpload: "ویڈیو اپ لوڈ کرکے",
    PleaseInsertLink: "براہ کرم لنک درج کریں",
    UploadVideo: "ویڈیو اپ لوڈ کریں",
    CreateArticle: "مضمون بنائیں",
    InsertLink: "لنک درج کریں",
    UploadPDF: "تصویر یا PDF اپ لوڈ کریں",
    CreateVideo: "ویڈیو بنائیں",
    LessonDetail: "سبق کی تفصیل",
    Eitherselect: "یا تو کوئز منتخب کریں یا اسے ہٹا دیں۔",
    Selectquiz: "کوئز منتخب کریں",
    AddNewQuiz: "نیا کوئز شامل کریں",
    Atleastonetopic: "اس سبق کا کم از کم ایک موضوع لازمی ہے۔",
    LessonNameRequired: "سبق کا نام ضروری ہے۔",
    LessonName: "سبق کا نام",
    DeleteLesson: "سبق حذف کریں",
    LessonDescriptionRequired: "سبق کی تفصیل ضروری ہے۔",
    TopicNameIsRequired: "موضوع کا نام ضروری ہے۔",
    TopicName: "موضوع کا نام",
    DeleteTopic: "موضوع حذف کریں",
    TopicDescription: "موضوع کی تفصیل ضروری ہے۔",
    eitherEnterText: "یا تو متن درج کریں یا اسے ہٹا دیں۔",
    EitherenterA: "یا تو ایک درج کریں",
    OrRemoveIt: "یا اسے ہٹا دیں۔",
    CreateArticlesNow: "ابھی مضامین بنائیں!",
    CreateCoursesNow: "ابھی کورسز بنائیں!",
    CreateEbooksNow: "ابھی ای بکس بنائیں!",
    CreateElearningNow: "ابھی ای لرننگ بنائیں!",
    CreateQuizzesNow: "ابھی کوئز بنائیں!",
    CreateTeamDashboardNow: "ابھی ٹیم ڈیش بورڈ بنائیں!",
    CreateTedTalksNow: "ابھی ٹیڈ ٹاکس بنائیں!",
    CreateVideosNow: "ابھی ویڈیوز بنائیں!",
    OptionEmpty: "اختیار خالی نہیں ہوسکتا",
    CorrectOptionRequired: "صحیح اختیار ضروری ہے",
    QuestionEmpty: "سوال خالی نہیں ہوسکتا",
    UntitledQuestion: "بے نام سوال",
    AddQuestion: "سوال شامل کریں",
    DeleteQuestion: "سوال حذف کریں",
    Quiznamerequired: "کوئز کا نام ضروری ہے!",
    QuizexpirationDate: "کوئز ختم ہونے کی تاریخ",
    QuizExpirationDateIsRequired: "کوئز ختم ہونے کی تاریخ ضروری ہے",
    QuizDescriptionRequired: "کوئز کی تفصیل ضروری ہے!",
    StartQuiz: " کوئز شروع کریں",
    GoToResults: "نتائج پر جائیں",
    Gettoknown: "صارف کے تجربے کو جانیں",
    Missingdata: "مواد نہیں ملا۔",
    UntitledLesson: "بے نام سبق",
    RemoveText: "متن ہٹائیں",
    AddText: "متن شامل کریں",
    RemoveImage: "تصویر ہٹائیں",
    AddImage: "تصویر شامل کریں",
    RemoveVideo: "ویڈیو ہٹائیں",
    AddVideo: "ویڈیو شامل کریں",
    RemovePDF: "PDF ہٹائیں",
    AddPDF: "PDF شامل کریں",
    RemoveQuiz: "کوئز ہٹائیں",
    AddQuiz: "کوئز شامل کریں",
    RemoveLink: "لنک ہٹائیں",
    AddLink: "لنک شامل کریں",
    RemoveYouTubeLink: "YouTube لنک ہٹائیں",
    AddYouTubeLink: "YouTube لنک شامل کریں",
    RemoveExternalCourse: "بیرونی کورس ہٹائیں",
    AddExternalCourse: "بیرونی کورس شامل کریں",
    UntitledTopic: "بے نام موضوع",
    DashboardChart: "ڈیش بورڈ چارٹ",
    LatestTrendInBusiness: "آخری کاروبار میں روایت",
    InformationWillComeHere: "معلومات یہاں آئیں گی",
    CourseContent: "کورس کا مواد",
    DummyContent: "جعلی مواد",
    EndCourse: "کورس ختم کریں",
    Chapters: "فصول •",
    Lectures: "لیکچرز •",
    LessonsInThisProgram: "اس پروگرام میں سبق",
    BookInformation: "کتاب کی معلومات",
    TedTalksDaily: "ٹیڈ ٹاک ڈیلی",
    TopicTitle: "موضوع کا عنوان",
    VisitThis: "اسے دیکھیں",
    LearnPractical: "عملی مہارتوں کو سیکھیں کورسز کے ذریعے اور ادائیگی پر پیسے کمائیں۔",
    Courses: "کورسز",
    Book: "کتاب",
    Tedtalk: "ٹیڈ ٹاک",
    Articles: "مضامین",
    NoPageExist: "صفحہ موجود نہیں ہے",
    Books: "کتب",
    TedTalks: "ٹیڈ ٹاکس",
    Videos: "ویڈیوز",
    GoTo: "جائیں",
    CourseLevelIdLabel: {
      [CourseLevelIdEnum.Advance]: "اعلی",
      [CourseLevelIdEnum.Intermediate]: "متوسط",
      [CourseLevelIdEnum.Beginner]: "ابتدائی"
    },
  },
  attendence: {
    Attendance: "حاضری",
    TimeSpent: "صرف شدہ وقت",
    GraceTime: "گریس ٹائم",
    GoodDay: "اچھا دن",
    Howfeeling: "آج آپ کیسا محسوس کر رہے ہیں؟",
    Note: "نوٹ",
    Noattendanceforapproval: "منظوری کے لئے کوئی حاضری نہیں۔",
    Iwaslate: "ٹریفک کی وجہ سے میں دیر سے آیا۔",
    Showtimespent: "صرف شدہ وقت دکھائیں",
    ClockIn: "اپنی حاضری لاگ شروع کرنے کے لئے کلک-ان کریں۔",
    selectStatus: "براہ کرم ایک حیثیت منتخب کریں!",
    ByTime: "وقت کے لحاظ سے",
    ByStatus: "حیثیت کے لحاظ سے",
    DeductionPolicy: "کٹوتی کی پالیسی",
    UpdateDeductionPolicy: "کٹوتی کی پالیسی کو اپ ڈیٹ کریں",
    AddDeductionPolicy: "کٹوتی کی پالیسی شامل کریں",
    DaysRange: "دنوں کی رینج",
    PerDay: "فی دن",
    SaveDeductionPolicy: "کٹوتی کی پالیسی کو محفوظ کریں",
    AbsentPolicy: "غیر حاضری کی پالیسی",
    LatePolicy: "دیر سے آنے کی پالیسی",
    IsPercentage: "کیا یہ فیصد ہے",

    attendanceFilterTypeEnum: {
      [AttendanceFilterTypeEnum.checkInForApproval]: "منظوری کے لئے چیک-ان",
      [AttendanceFilterTypeEnum.dayWiseAttendance]: "روزانہ حاضری",
      [AttendanceFilterTypeEnum.userAttendance]: "صارف کی حاضری",
      [AttendanceFilterTypeEnum.Summary]: "خلاصہ",
    },

    updateErrorMessage: {
      [AttendanceStateEnum.Off]: "آف حالت کے لئے اپ ڈیٹ کارروائی دستیاب نہیں ہے",
      [AttendanceStateEnum.Holiday]: "چھٹیوں کے لئے اپ ڈیٹ کارروائی دستیاب نہیں ہے",
      [AttendanceStateEnum.Leave]: "چھٹی کی حالت کے لئے اپ ڈیٹ کارروائی دستیاب نہیں ہے",
    },
    statusEnum: {
      [AttendanceStatusEnum.InProcess]: "عمل میں",
      [AttendanceStatusEnum.Approved]: "منظور شدہ",
      [AttendanceStatusEnum.Declined]: "مسترد شدہ",
    },
    moodLabels: {
      [AttendanceMoodEnum.VeryUnsatisfied]: "بہت غیر مطمئن",
      [AttendanceMoodEnum.Unsatisfied]: "غیر مطمئن",
      [AttendanceMoodEnum.Neutral]: "غیر جانبدار",
      [AttendanceMoodEnum.Satisfied]: "مطمئن",
      [AttendanceMoodEnum.VerySatisfied]: "بہت مطمئن",
    },
    attendanceState: {
      [AttendanceStateEnum.Unknown]: "",
      [AttendanceStateEnum.Present]: "موجود",
      [AttendanceStateEnum.Absent]: "غیر حاضر",
      [AttendanceStateEnum.Leave]: "چھٹی",
      [AttendanceStateEnum.Late]: "دیر سے",
      [AttendanceStateEnum.WorkFromHome]: "گھر سے کام",
      [AttendanceStateEnum.Off]: "بند",
      [AttendanceStateEnum.Holiday]: "چھٹی",
    },
    attendanceType: {
      [ATTENDANCE_ENUM.CHECK_IN.IN]: "چیک-ان",
      [ATTENDANCE_ENUM.CHECK_IN.OUT]: "چیک-آؤٹ",
    },
    attendanceMetricLabel: {
      [AttendanceMetricTypeEnum.overallTimeSpent]: "کل صرف شدہ وقت",
      [AttendanceMetricTypeEnum.Present]: "موجود",
      [AttendanceMetricTypeEnum.Absent]: "غیر حاضر",
      [AttendanceMetricTypeEnum.Leave]: "چھٹی",
      [AttendanceMetricTypeEnum.Late]: "دیر سے",
    },
  },
  customApproval: {
    createCustomApproval: "اپنی مخصوص منظوری بنائیں",
    customApproval: "مخصوص منظوری",
    noDataMessage: "– اب مخصوص منظوریں بنائیں!",
    customApprovalFilterTypeEnum: {
      [CustomApprovalFilterTypeEnum.All]: "تمام مخصوص منظوری",
      [CustomApprovalFilterTypeEnum.CreatedByMe]: "میری مخصوص منظوری",
      [CustomApprovalFilterTypeEnum.ForApproval]: "منظوری کے لئے",
    },
    statusEnum: {
      [CustomApprovalStatusEum.InProcess]: "عمل میں",
      [CustomApprovalStatusEum.Approved]: "منظور",
      [CustomApprovalStatusEum.Declined]: "منسوخ",
      [CustomApprovalStatusEum.Resend]: "دوبارہ بھیجی جا رہی ہے",
      [CustomApprovalStatusEum.InActive]: "غیر فعال",
      [CustomApprovalStatusEum.NotRequired]: "ضرورت نہیں ہے",
      [CustomApprovalStatusEum.Cancelled]: "منسوخ",
      [CustomApprovalStatusEum.Hold]: "ہولڈ",
    },
    SortEnum: {
      [CustomApprovalFilterSortEnum.UpdateDateDesc]: "تاریخ اپ ڈیٹ - کم سے زیادہ",
      [CustomApprovalFilterSortEnum.UpdateDateAsc]: "تاریخ اپ ڈیٹ",
      [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - کم سے زیادہ",
      [CustomApprovalFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [CustomApprovalFilterSortEnum.CreateDateDesc]: "تاریخ تخلیق - کم سے زیادہ",
      [CustomApprovalFilterSortEnum.CreateDateAsc]: "تاریخ تخلیق"
    }

  },
  reward: {
    CreateReward: "ایک انعام بنائیں",
    Rewards: "انعامات",
    RewardTo: "انعام دینے والے کو",
    RewardCategory: "انعام کی قسم",
    EnterRewardCategory: "انعام کی قسم درج کریں",
    RewardName: "انعام کا نام",
    EnterRewardName: "انعام کا نام درج کریں",
    PleaseEnterRewardName: "براہ کرم انعام کا نام درج کریں",
    ReasonForReward: "انعام کا سبب",
    EnterReasonForReward: "انعام کا سبب درج کریں",
    PleaseEnterReasonForReward: "براہ کرم انعام کا سبب درج کریں",
    noDataMessage: "– اب انعام بنائیں!",
    rewardFilterTypeEnum: {
      [RewardFilterTypeEnum.All]: "سب انعامات",
      [RewardFilterTypeEnum.CreatedByMe]: "میرے ذریعے بنائے گئے",
      [RewardFilterTypeEnum.ForApproval]: "منظوری کے لیے",
      [RewardFilterTypeEnum.RewardForMe]: "میرے انعامات",
    },
    statusEnum: {
      [RewardStatusEnum.InProcess]: "عمل میں ہے",
      [RewardStatusEnum.Approved]: "منظور ہوگیا",
      [RewardStatusEnum.Declined]: "منظور نہیں ہوا",
      [RewardStatusEnum.Cancelled]: "منسوخ ہوا",
      [RewardStatusEnum.Hold]: "روکا ہوا",
    },
    SortEnum: {
      [RewardFilterSortEnum.UpdateDateDesc]: "تاریخ تازہ کاری - نزولی",
      [RewardFilterSortEnum.UpdateDateAsc]: "تاریخ تازہ کاری",
      [RewardFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - نزولی",
      [RewardFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [RewardFilterSortEnum.StatusDesc]: "حالت - نزولی",
      [RewardFilterSortEnum.Status]: "حالت",
      [RewardFilterSortEnum.CategoryDesc]: "شہرت - نزولی",
      [RewardFilterSortEnum.Category]: "شہرت",
      [RewardFilterSortEnum.NameDesc]: "نام - نزولی",
      [RewardFilterSortEnum.Name]: "نام",
      [RewardFilterSortEnum.CreateDateDesc]: "تاریخ بنایا گیا - نزولی",
      [RewardFilterSortEnum.CreateDateAsc]: "تاریخ بنایا گیا"
    }
  },
  document: {
    mySigned: "میرے دستخط شدہ",
    forMySignature: "میرے دستخط کے لیے",
    draftSignature: "مسودہ",
    inSigning: "دستخط ہو رہے ہیں",
    signaturesCompleted: "دستخط مکمل ہو گئے",
    DocsArchive: "داکومینٹس اور آرکائیوز",
    createDocument: "داکومینٹ بنائیں",
    CreateSlides: "سلائیڈز بنائیں",
    CreateBoard: "بورڈ بنائیں",
    CreatePad: "پیڈ بنائیں",
    CreateGrid: "گرڈ بنائیں",
    newFolder: "نیا فولڈر",
    createFolder: "فولڈر بنائیں",
    uploadDocuments: "داکومینٹس اپلوڈ کریں",
    addBoard: "بورڈ شامل کریں",
    addPad: "پیڈ شامل کریں",
    addGrid: "گرڈ شامل کریں",
    addSlide: "سلائیڈ شامل کریں",
    addMindmap: "مائنڈ میپ شامل کریں",
    CompleteDocument: "داکومینٹ مکمل کریں",
    noDataMessage: "- اب داکومینٹ بنائیں!",
    AllDocuments: "تمام داکومینٹس",
    MyDrive: "میرا ڈرائیو",
    MyDocuments: "میرے منظوری کے لئے میرے ڈاکومینٹس",
    ForApprovals: "منظوری کے لئے",
    OnlyFiles: "صرف فائلیں",
    fileName: "فائل کا نام",
    shortDescription: "مختصر تفصیل (زیادہ سے زیادہ 100 حرف)",
    AllFiles: "تمام فائلیں",
    Grids: "گرڈز",
    Pads: "پیڈز",
    Slides: "سلائیڈز",
    Boards: "بورڈز",
    Mindmaps: "مائنڈمیپس",
    updateFolder: "فولڈر کو اپ ڈیٹ کریں",
    updateGrid: "گرڈ کو اپ ڈیٹ کریں",
    updatePad: "پیڈ کو اپ ڈیٹ کریں",
    updateSlide: "سلائیڈ کو اپ ڈیٹ کریں",
    updateBoard: "بورڈ کو اپ ڈیٹ کریں",
    updateMindMap: "ذہنی نقشہ کو اپ ڈیٹ کریں",
    updateDocuments: "دستاویز کو اپ ڈیٹ کریں",

    status: {
      InProcess: "عمل میں ہے",
      Approved: "منظور ہوگیا",
      Declined: "رد ہوگیا",
      Resend: "دوبارہ بھیجیں",
      NotRequired: "ضرورت نہیں",
      Cancelled: "منسوخ ہوگیا",
      Draft: "ڈرافٹ"
    },
    documentReferenceType: {
      [DocumentReferenceTypeEnum.General]: "عام",
      [DocumentReferenceTypeEnum.Group]: "گروپ",
      [DocumentReferenceTypeEnum.Project]: "پروجیکٹ",
    },
  },
  warning: {
    WarningDetail: "خبرداری کا تفصیل",
    warningTo: "خبرداری کا مقصد",
    createWarning: "خبرداری بنائیں",
    enterwarningCategory: "خبرداری کا زمرہ درج کریں",
    noDataMessage: '– اب خبرداری بنائیں!',
    filter: "فلٹر",
    warningFilterTypeEnum: {
      [WarningFilterTypeEnum.Warning]: "خبرداریاں",
      [WarningFilterTypeEnum.CreatedByMe]: "میرے ذریعے بنائی گئی",
      [WarningFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [WarningFilterTypeEnum.WarningToMe]: "میری طرف خبرداری",
    },
    statusEnum: {
      [WarningStatusEnum.InProcess]: "عمل میں ہے",
      [WarningStatusEnum.Approved]: "منظور ہوگیا",
      [WarningStatusEnum.Declined]: "رد ہوگیا",
      [WarningStatusEnum.Cancelled]: "منسوخ ہوگیا",
      [WarningStatusEnum.Hold]: "محفوظ",
    },
    SortEnum: {
      [WarningFilterSortEnum.UpdateDateDesc]: "تاریخ اپ ڈیٹ - نیچے جانب",
      [WarningFilterSortEnum.UpdateDateAsc]: "تاریخ اپ ڈیٹ",
      [WarningFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - نیچے جانب",
      [WarningFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [WarningFilterSortEnum.StatusDesc]: "حالت - نیچے جانب",
      [WarningFilterSortEnum.Status]: "حالت",
      [WarningFilterSortEnum.CategoryDesc]: "زمرہ - نیچے جانب",
      [WarningFilterSortEnum.Category]: "زمرہ",
      [WarningFilterSortEnum.NameDesc]: "نام - نیچے جانب",
      [WarningFilterSortEnum.Name]: "نام",
      [WarningFilterSortEnum.CreateDateDesc]: "تاریخ تخلیق - نیچے جانب",
      [WarningFilterSortEnum.CreateDateAsc]: "تاریخ تخلیق"
    }

  },
  jobBoard: {
    jobBoard: "جاب بورڈ",
    noDataMessage: " – اب کیریئرز بنائیں!",
  },
  businessPolicy: {
    BusinessPolicy: "بزنس پالیسی",
    policies: "پالیسیاں",
    noPolicy: "کوئی پالیسی نہیں ملی...",
  },
  forms: {
    CreateForm: "فارم بنائیں",
    Forms: "فارمز",
    Attempt: "کوشش",
    CopyLink: "لنک کاپی کریں",
    Title: "عنوان",
    EnterTitle: "عنوان درج کریں",
    PleaseEnterTitle: "براہ کرم عنوان درج کریں",
    PleaseFillTheQuestionField: "براہ کرم سوال کے شعبے کو پر کریں",
    EnterQuestion: "سوال درج کریں",
    SelectAnswerType: "جواب کی قسم منتخب کریں",
    Text: "متن",
    Number: "نمبر",
    Polls: "پولز",
    AddQuestion: "سوال شامل کریں",
    AddOption: "اختیار شامل کریں",
    Details: "تفصیلات",
    PoweredBy: "طاقت حاصل کردہ",
    Submit: "جمع کرائیں",
    EditForm: "فارم میں ترتیب دیں",
    EnterEmail: "ای میل درج کریں",
    Question: "سوال...",
    YourAnswer: 'آپ کا جواب',
    noDataMessage: "– اب فارم بنائیں!",
    formQuestionTypeEnum: {
      [FormQuestionTypeEnum.Number]: "نمبر",
      [FormQuestionTypeEnum.Text]: "متن",
      [FormQuestionTypeEnum.Poll]: "پولز",
    },
    formFilterTypeEnum: {
      [FormFilterTypeEnum.All]: "تمام فارمز",
      [FormFilterTypeEnum.CreatedByMe]: "میرے ذریعے بنائے گئے",
      [FormFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [FormFilterTypeEnum.FormForMe]: "میرے لئے فارم"
    },
    statusEnum: {
      [FormStatusEnum.InProcess]: "عمل میں ہے",
      [FormStatusEnum.Approved]: "منظور ہوگیا",
      [FormStatusEnum.Declined]: "رد ہوگیا",
      [FormStatusEnum.Resend]: "دوبارہ بھیجیں",
      [FormStatusEnum.InActive]: "غیر فعال ہے",
      [FormStatusEnum.NotRequired]: "ضرورت نہیں ہے",
      [FormStatusEnum.Cancelled]: "منسوخ ہوگیا",
      [FormStatusEnum.Hold]: "رکاوٹ میں ہے",
    },
    SortEnum: {
      [FormFilterSortEnum.CreateDateDesc]: "تخلیق کی تاریخ - نزولی",
      [FormFilterSortEnum.CreateDateAsc]: "تخلیق کی تاریخ",
      [FormFilterSortEnum.UpdateDateDesc]: "اپ ڈیٹ کی تاریخ - نزولی",
      [FormFilterSortEnum.UpdateDateAsc]: "اپ ڈیٹ کی تاریخ",
      [FormFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - نزولی",
      [FormFilterSortEnum.ReferenceNo]: "حوالہ نمبر"
    }
  },
  careers: {
    Careers: "کیریئرز",
    CareerDetail: "کیریئر تفصیل",
    createCareer: "کیریئر بنائیں",
    MinSalary: "کم سیلری",
    MaxSalary: "زیادہ سیلری",
    Applicants: "درخواست دہندگان",
    ShortListed: "شارٹ لسٹ ہوگئے",
    Rejected: "مسترد ہوگئے",
    Completed: "مکمل ہوگیا",
    Finalist: "فائنلسٹ",
    Maybe: "ممکن ہے",
    OfferSent: "آفر بھیجی گئی",
    WaitingForInterview: "انٹرویو کا منتظر ہے",
    OfferAccepted: "آفر قبول ہوگئی",
    OfferDeclined: "آفر مسترد ہوگئی",
    OfferResend: "آفر دوبارہ بھیجی گئی",
    noDataMessage: "– اب کیریئر بنائیں!",
    writeHeaderHere: "یہاں ہیڈر لکھیں",
    pickCurrentDate: "موجودہ تاریخ چنیں",
    enterAmount: "رقم درج کریں",
    selectApprovers: "منظور کرنے والے کو منتخب کریں",
    selectFinancers: "مالی دینے والے کو منتخب کریں",
    selectExecutors: "ناظمین کو منتخب کریں",
    selectDesignation: "عہد کا انتخاب کریں",
    writeDescription: "یہاں تفصیل لکھیں",
    enterMinSalary: "کم سیلری درج کریں",
    enterMaxSalary: "زیادہ سیلری درج کریں",
    selectDepartment: "ڈپارٹمنٹ منتخب کریں",
    selectSupervisor: "سپروائزر منتخب کریں",
    selectInterviewers: "انٹرویوئرز منتخب کریں",
    selectPostInterviewers: "پوسٹ انٹرویوئرز منتخب کریں",
    description: "یہاں آپکی تفصیل لکھیں...",
    addSkills: "مہارت شامل کریں",
    selectJobType: "نوکری کا قسم منتخب کریں",
    selectJobshift: "نوکری کا شفٹ منتخب کریں",
    selectEducation: "براہ کرم شعبے منتخب کریں",
    selectCareerLevel: "کیریئر کا سطح منتخب کریں",
    selectEndDate: "آخری تاریخ منتخب کریں",
    enterreviewcriteria: "جائزہ کرنے کی معیار درج کریں",
    reviewCriteria: "جائزہ کرنے کی معیار",
    enterDepartment: "براہ کرم ڈپارٹمنٹ درج کریں",
    supervisor: "سپروائزر",
    supervisorrequired: "سپروائزر کی ضرورت ہے",
    postInterviewers: "پوسٹ انٹرویوئرز",
    jobShift: "نوکری کا شفٹ",
    enterJobShift: "براہ کرم نوکری کا شفٹ درج کریں",
    enterJobType: "براہ کرم نوکری کی قسم درج کریں",
    careerLevel: "کیریئر کا سطح",
    selectBuddy: "ہائرنگ بڈی منتخب کریں",
    selectInerview: "انٹرویوئرز منتخب کریں",
    requiredInterview: "انٹرویوئر کی ضرورت ہے",
    enterCareerLevel: "براہ کرم کیریئر کا سطح درج کریں",
    createJob: "نوکری بنائیں",
    applyJob: "نوکری پر درخواست دیں",
    firstName: "پہلا نام",
    enterFirstName: "پہلا نام درج کریں",
    requiredMessageFName: "پہلا نام درخواست ہے",
    lastName: "آخری نام",
    enterLastName: "آخری نام درج کریں",
    requiredMessageLName: "آخری نام درخواست ہے",
    email: "ای میل",
    enterEmail: "ای میل درج کریں",
    EmailIsRequired: "ای میل درخواست ہے",
    phoneNumber: "فون نمبر",
    enterPhoneNumber: "فون نمبر درج کریں",
    currentSalary: "موجودہ تنخواہ",
    expectedSalary: "توقع کردہ تنخواہ",
    radioMessageApplicant: "درخواست دہندہ",
    radioMessageShortlisted: "شارٹ لسٹ ہوگئے",
    radioMessageCompleted: "مکمل ہوگیا",
    coverNote: "کور نوٹ",
    noticePeriod: "اشتہاری مدت",
    requiredMessageFName: "براہ کرم پہلا نام درج کریں",
    requiredMessageLName: "براہ کرم آخری نام درج کریں",
    requiredMessageEmail: "براہ کرم ای میل درج کریں",
    requiredMessagePhoneNumbeer: "براہ کرم فون نمبر درج کریں",
    requiredMessageCurrentSalary: "براہ کرم موجودہ تنخواہ درج کریں",
    requiredMessageExpextedsalary: "براہ کرم توقع کردہ تنخواہ درج کریں",
    requiredMessageExperience: "براہ کرم تجربہ درج کریں",
    requiredMessageNoticePeriod: "براہ کرم اشتہاری مدت درج کریں",
    appliedDate: "درخواست دی گئی تاریخ",
    interviewSchedule: "انٹرویو کا شیڈول",
    applyNow: "ابھی درخواست دیں",
    jobPost: "نوکری کا اشتہار",
    jobexpire: "نوکری کا اعلان ختم ہوگیا",
    skillrequired: "ضرورت ہونے والی مہارتیں",
    joblocation: "نوکری کا مقام",
    jobType: "نوکری کی قسم",
    jobDetails: "نوکری کی تفصیلات",
    EducationLevel: "تعلیم کا سطح",
    MinimumSalary: "کم سیلری",
    MaximumSalary: "زیادہ سیلری",
    YearOfExperience: "تجربہ کا سال",
    errormessage: "حالت تبدیل کرنا ممکن نہیں ہے",
    MakeOffer: "آفر بنائیں",
    updatestatus: "کیا آپ وضع کو اپ ڈیٹ کرنا چاہتے ہیں؟",
    Pleaseenterminsalary: "براہ کرم کم از کم تنخواہ درج کریں",
    Pleaseentermaxsalary: "براہ کرم زیادہ سے زیادہ تنخواہ درج کریں",
    NoRequirements: "کوئی ضرورت نہیں",
    statusEnum: {
      [CareerStatusEnum.InProcess]: "عمل میں",
      [CareerStatusEnum.Approved]: "منظور",
      [CareerStatusEnum.Declined]: "رد کر دیا گیا",
      [CareerStatusEnum.Cancelled]: "منسوخ",
      [CareerStatusEnum.Hold]: "رکاوٹ",
    },
    careerFilterTypeEnum: {
      [CareerFilterTypeEnum.All]: "تمام کیریئرز",
      [CareerFilterTypeEnum.MyCareers]: "میرے کیریئرز",
      [CareerFilterTypeEnum.ForApproval]: "منظوری کے لئے",
    },
  },

  /*** HR Menu ***/
  myTeam: {
    MyTeams: "میری ٹیمیں",
    EmployeeNo: "ملازم شمار",
    noDataMessage: "– ابھی ٹیمیں بنائیں!",
    teamDetails: "ٹیم کی تفصیلات",
    DeductionPerMonth: "مہینے کی کٹوتی",
    teamActivities: {
      attendence: "حاضری",
      checkIn: "چیک ان",
      leaves: "رخصتیں",
      rewards: "انعامات",
      appraisals: "تنخواہ کا مطالعہ",
      warnings: "انتباہات",
      complains: "شکایات",
      courses: "کورسز",
      education: "تعلیم",
      experience: "تجربہ",
      loan: "قرض",
      activityLog: "سرگرمی لاگ",
      checkInCheckOut: "داخلہ / خارجہ",
      storageStatistics: "اسٹوریج کے اعداد و شمار"

    },
  },
  employee: {
    Basic_Info: "Basic Information",
    Email_Configuration: "Email Configuration",
    Bank_Detail: "Bank Detail",
    Education: "Education",
    Emergency_Info: "Emergency Information",
    Experience: "Experience",
    Devices: "Devices",
  },
  administration: {
    Company_Goal: "کمپنی کا مقصد",
    basicInfo: "بنیادی معلومات",
    Subsidiary_Office: "زیر شاخ دفتر",
    AddSubsidiary: "ذیلی شعبہ شامل کریں",
    Fiscal_Year: "مالی سال",
    Tax_Slabs: "ٹیکس سلیبز",
    Tax_Slabs_Group: "ٹیکس سلیبز گروہ",
    E_Learning_Category: "ای لرننگ کیٹیگری",
    Company_Policy: "کمپنی پالیسیاں",
    Default_Hiring_Criteria: "پہلے سے طے شدہ رکنیں کی شرائط",
    subsidiary: "زیر شاخ",
    Business_Logo: "کاروباری لوگو",
    Complain_Category: "شکایت کیٹیگری",
    Custom_Tag: "اپنا ٹیگ",
    Payroll_Group: "تنخواہ گروہ",
    Business_Policy: "کاروباری پالیسی",
    Companies_Policy: "کمپنی پالیسیاں",
    Billing: "بلنگ",
    Rebate_Category: "ریبیٹ کیٹیگری",
    Assets_Category: "اسٹیٹس کیٹیگری",
    AccessRole: "رسائی کا کردار",
    Grade: "گریڈ",
    Default_Account_Header: "پہلے سے طے شدہ اکاؤنٹ ہیڈر",
    Business_Paging: "کاروباری نمبر",
    Discussion_Board_Category: "مباحثہ بورڈ کیٹیگری",
    Room: "کمرہ",
    Business_Association: "کاروباری تعلق",
    Designation: "عہد",
    Appraisal: "تشہیر",
    Office_Timings: "دفتری اوقات",
    Leave_Types: "رخصتیں کی اقسام",
    User_Types: "صارف کی قسمیں",
    Expense_Headers: "اخراجات ہیڈرز",
    Salary_Headers: "تنخواہ ہیڈرز",
    Request_For_Right: "حق کی درخواست",
    Custom_Approval_Category: "اپنی منظوری کیٹیگری",
    GRADE_ALLOWANCES: "گریڈ مشہوریاں",
    Paging_Category: "پیجنگ کیٹیگری",
    Page_Category: "صفحہ زمرہ ",
    Project_Category: "پروجیکٹ کیٹیگری",
    Email_Configuration: "ای میل کنفیگریشن",
    Allowances: "مشہوریاں",
    Default_Approvals: "پہلے سے طے شدہ منظوریاں",
    NonMandatory_Approvals: "غیر لازمی منظوریاں",
    WORKGATING: "WIP گیٹنگ کا معیار",
    Job_Description: "نوکری کی تفصیل",
    Rewards: "انعامات",
    Reward_Category: "انعامات کیٹیگری",
    Warning_Category: "انتباہ کیٹیگری",
    Holidays: "تعطیلات",
    paymentCard: "ادائیگی کارڈ",
    Company_Info: "کمپنی کی معلومات",
    business_Info:"کاروباری معلومات",
    Business_Approval: "کاروباری منظوری",
    COMPANY_STATISTICS: "کمپنی کے اعداد و شمار",
    ATTENDANCE_SETTINGS: 'حاضری کی ترتیبات',
    businessInfo : {
      businessInformation: 'کاروباری معلومات',
      companyBackgroundLabel: 'کمپنی کا پس منظر',
      productAndServicesLable: 'مصنوعات اور خدمات',
      competitorsLable: 'مقابل',
      recentNewsLable: 'حالیہ خبریں',
      leadershipTeamLabel: 'قیادت کی ٹیم',
      organizationalStructureLabel: 'تنظیمی ڈھانچہ',
      customerBaseLabel: 'گاہکوں کی بنیاد',
      FinancialHealthLabel: 'مالی صحت',
      industryLabel: 'صنعت',
      websiteAndSocialMediaLabel: 'ویب سائٹ اور سوشل میڈیا',
      additionalCommentLabel: 'اضافی تبصرے',
      companyCultureLabel: 'کمپنی کی ثقافت',
      ceoLabel: 'سی ای او',
      companyBackground: {
          mission: 'مشن',
          vision: 'ویژن',
          values: 'اقدار',
          YearFounded: 'قیام کا سال',
          placeHolders: {
              enterMission: 'مشن درج کریں',
              enterVision: 'ویژن درج کریں',
              enterValues: 'اقدار درج کریں',
              enterYearFounded: 'قیام کا سال درج کریں',
          },
          requiredMessages: {
              enterMission: 'براہ کرم مشن درج کریں',
              enterVision: 'براہ کرم ویژن درج کریں',
              enterValues: 'براہ کرم اقدار درج کریں',
              enterYearFounded: 'براہ کرم قیام کا سال درج کریں',
          }
      },
      productAndService: {
          Benefits: 'فوائد'
      },
      competitors: {
          MarketPosition: 'مارکیٹ کی پوزیشن',
          Strength: 'طاقت',
          Weakness: 'کمزوری',
      },
      recentNews: {
          Headline: 'سرخی',
          News: 'خبریں'
      },
      leadershipTeam: {
          Background: 'پس منظر',
          ContactNumber: 'رابطہ نمبر',
      },
      financialHealth: {
          Revenue: 'آمدنی',
          Profitability: 'منافع'
      },
      additionalComment: {
          label: {
              comments: 'تبصرے'
          },
          placeholders: {
              comments: 'تبصرے درج کریں'
          },
          requireMessages: {
              enterComments: 'براہ کرم تبصرے درج کریں'
          }
      },
      comapnyCulture: {
          placeHolders: {
              enterDescription: 'تفصیل درج کریں',
              enterWorkEnvironment: 'کام کا ماحول درج کریں'
          },
          label: {
              description: 'تفصیل',
              workEnvironment: 'کام کا ماحول'
          },
          requiredMessages: {
              enterDescription: 'براہ کرم تفصیل درج کریں',
              enterWorkEnvironment: 'براہ کرم کام کا ماحول درج کریں'
          }
      }
  },
    Form: {
      Name: "نام",
      Entername: "نام درج کریں",
      PleaseEnterName: "براہ کرم نام درج کریں",
      Description: "تفصیل",
      enterDescription: "براہ کرم تفصیل درج کریں",
      Create: "تخلیق کریں",
      Add: "شامل کریں",
      Save: "محفوظ کریں",
      Clear: "صاف کریں",
      CategoryName: "کیٹیگری کا نام",
      Entercategoryname: "کیٹیگری کا نام درج کریں",
      SaveCategory: "کیٹیگری محفوظ کریں",
      Date: "تاریخ",
      startDate: "ابتدائی تاریخ",
      endDate: "انتہائی تاریخ",
      Upload: "اپ لوڈ کریں",
      Percent: "فیصد",
      Amount: "رقم",
      EnterAmount: "رقم درج کریں",
      addCategory: "کیٹیگری شامل کریں",
      SelectMember: "ممبر منتخب کریں",
      Email: "ای میل",
      EnterEmail: "براہ کرم ای میل درج کریں",
      Types: "اقسام",
      SelectStatus: "حالت منتخب کریں",
      Submit: "جمع کریں",
      Nomoreapprovals: "مزید منظوریاں نہیں",
    },
    Table: {
      Name: "نام",
      Members: "ممبرز",
      Description: "تفصیل",
      Value: "قیمت",
      Allot_Leave: "تعینات چھوڑیں",
      Type: "قسم",
      Unit: "یونٹ",
      Taxable: "ٹیکس قابل",
      Provider: "فراہم کنندہ",
      IncomingServerAddress: "آمدنی سرور کا پتہ",
      OutgoingServerAddress: "آؤٹ گوئنگ سرور کا پتہ",
      IncomingPort: "آمدنی پورٹ",
      OutgoingPort: "آؤٹ گوئنگ پورٹ",
      Action: "عمل",
      startDate: "ابتدائی تاریخ",
      endDate: "انتہائی تاریخ",
      Actions: "عملات",
      Account: "اکاؤنٹ",
      approvers: "منظور کنندگان",
      Status: "حالت",
      Allocated: "تخصیص دی گئی",
      AddMember: "ممبر شامل کریں",
      CreateDate: "تخلیق کی تاریخ",
    },
    accessRole: {
      Button: {
        AddAccessRole: "رسائی کا کردار شامل کریں",
        UpdateAccessRole: "رسائی کا کردار اپ ڈیٹ کریں",
      },
      Drawer: {
        CreateAccessRole: "رسائی کا کردار بنائیں",
        UpdateAccessRole: "رسائی کا کردار اپ ڈیٹ کریں",
        UserType: "صارف کی قسم",
        Description: "تفصیل",
        placeholders: {
          EnterName: "رسائی کا کردار کا نام درج کریں",
          UserType: "براہ کرم صارف کی قسم منتخب کریں",
          Description: "تفصیل درج کریں",
        },
        formLabel: {
          AccessRoleName: "رسائی کا کردار کا نام",
        },
        placeholders: {
          EnterName: "رسائی کا کردار درج کریں",
          SelectUserType: "صارف کی قسم منتخب کریں",
          EnterDescription: "تفصیل درج کریں",
        },
      },
    },
    grade: {
      AddGrade: "گریڈ شامل کریں",
      EnterGrade: "گریڈ درج کریں",
      EnterDescription: "تفصیل درج کریں",
      Grade: "گریڈ",
      Description: "تفصیل",
      Name: "نام",
      clear: "صاف",
      saveGrade: "گریڈ محفوظ کریں",
    },
    fiscalyear: {
      Add: "شامل کریں",
      EnterName: "نام درج کریں",
      EnterDescription: "تفصیل درج کریں",
      Fiscalyear: "مالی سال",
      description: "تفصیل",
      name: "نام",
      clear: "صاف",
      save: "محفوظ کریں",
      startyear: "شروع ہونے والا سال",
      endyear: "ختم ہونے والا سال",
      startMonth: "شروع ہونے والا مہینہ",
      endMonth: "ختم ہونے والا مہینہ",
      startDate: "شروع ہونے والی تاریخ",
      endDate: "ختم ہونے والی تاریخ",
    },
    businessPolicy: {
      companypolicy: "کمپنی پالیسیاں",
      enterName: "نام درج کریں",
      name: "نام",
      type: "قسم منتخب کریں",
      typee: "قسم",
      description: "تفصیل",
      EnterDescription: "تفصیل درج کریں",
      create: "بنائیں",
      save: "محفوظ کریں",
      createPolicy: "پالیسی بنائیں",
      updatePolicy: "پالیسی اپ ڈیٹ کریں",
    },
    taxSlab: {
      TaxSlabs: "ٹیکس سلیبز",
      UpdateTaxSlab: "ٹیکس سلیب کو اپ ڈیٹ کریں",
      CreateTaxSlab: "ٹیکس سلیب بنائیں",
      NoTaxSlabGroup: "ٹیکس سلیب گروہ نہیں...",
      enterCountry: "ملک منتخب کریں",
      enterName: "نام درج کریں",
      enterDescription: "تفصیلات درج کریں",
      description: "تفصیلات",
      name: "نام",
      TaxSlab: "ٹیکس سلیب",
      title: "عنوان",
      enterTitle: "عنوان درج کریں",
      percentage: "فی صد",
      previousCharge: "پچھلا چارج",
      min: "کم سے کم",
      max: "زیادہ سے زیادہ",
      enterMin: "کم سے کم درج کریں",
      enterMax: "زیادہ سے زیادہ درج کریں",
      enterPercent: "فی صد درج کریں",
      save: "محفوظ کریں",
      clear: "صاف کریں",
      Add: "شامل کریں",
      TaxSlabgroup: "ٹیکس سلیب گروہ",
      country: "ملک",
      minimumCharge: "کم سے کم چارج",
    },
    payrollGroup: {
      PayrollGroup: "تنخواہ گروہ",
      save: "گروہ محفوظ کریں",
      clear: "صاف",
      Add: "گروہ شامل کریں",
      name: "نام",
      enterName: "نام درج کریں",
    },
    subsidiaryOffice: {
      enterName: "نام درج کریں",
      enterAddress: "پتہ درج کریں",
      name: "نام",
      subsidiary_Office: "زیر شاخ دفتر",
      latitude: "آسمانی موقع",
      longitude: "طول و عرض",
      address: "پتہ",
      branch: "شاخ",
      Subsidiary: "زیر شاخ",
      select: "زیر شاخ منتخب کریں",
      save: "محفوظ کریں",
      clear: "صاف",
      Add: "شامل کریں",
      title: "عنوان",
      enterTitle: "عنوان درج کریں",
    },
    defaultHiringCriteria: {
      default: "پہلے سے طے شدہ رکنیں کی شرائط",
      question: "سوالات",
      enterQuestions: "سوال درج کریں",
      save: "سوال محفوظ کریں",
      clear: "صاف",
      Add: "سوال شامل کریں",
    },
    workGatingCriteria: {
      work: "WIP گیٹنگ کا معیار",
      question: "سوالات",
      enterQuestions: "سوال درج کریں",
      save: "سوال محفوظ کریں",
      clear: "صاف",
      Add: "سوال شامل کریں",
    },
    designation: {
      desig: "عہد",
      enterdesig: "عہد درج کریں",
      enterName: "نام درج کریں",
      enterDescription: "تفصیل درج کریں",
      description: "تفصیل",
      name: "نام",
      save: "عہد محفوظ کریں",
      clear: "صاف",
      Add: "عہد شامل کریں",
    },
    appraisal: {
      Appraisal: "تشہیر",
      enterAppraisal: "تشہیر درج کریں",
      enterDescription: "تفصیل درج کریں",
      description: "تفصیل",
      save: "تشہیر محفوظ کریں",
      clear: "صاف",
      Add: "تشہیر شامل کریں",
      CompanyGoal: "کمپنی کا مقصد",
      IndividualGoal: "انفرادی مقصد",
      DepartmentsGoal: "اہم اقسام کا مقصد",
      Goal: "مقصد",
      saveGoal: "مقصد محفوظ کریں",
      AddCompanyGoal: "کمپنی کا مقصد شامل کریں",
      AddDepartmentGoal: "اہم اقسام کا مقصد شامل کریں",
      AddIndividualGoal: "انفرادی مقصد شامل کریں",
      enterCompanyGoal: "کمپنی کا مقصد درج کریں",
      enterDepartmentGoal: "اہم اقسام کا مقصد درج کریں",
      enterIndividualGoal: "انفرادی مقصد درج کریں",
    },
    office: {
      Office_Timing: "دفتری اوقات",
      group: "گروہ کا نام",
      enterGroup: "نام درج کریں",
      enterDescription: "تفصیل درج کریں",
      description: "تفصیل",
      save: "دفتری اوقات محفوظ کریں",
      clear: "صاف",
      Add: "دفتری اوقات شامل کریں",
      days: "دن",
      work: "کام ہے؟",
      checkIn: "چیک ان",
      checkOut: "چیک آؤٹ",
      time: "گریس ٹائم",
      min: "منٹ",
      create: "گروہ بنائیں",
      new: "نیا ٹائم گروہ",
      UpdateOfficeTiming: "دفتر کا وقت اپ ڈیٹ کریں",
      CreateOfficeTiming: "دفتر کا وقت بنائیں",
      Monday: "پیر",
      Tuesday: "منگل",
      Wednesday: "بدھ",
      Thursday: "جمعرات",
      Friday: "جمعہ",
      Saturday: "ہفتہ",
      Sunday: "اتوار",
    },
    leave: {
      Leave: "رخصتیں کی اقسام",
      leaveType: "رخصتیں کی قسم",
      enterLeave: "رخصتی درج کریں",
      save: "رخصتیں کی قسم محفوظ کریں",
      clear: "صاف",
      Add: "رخصتیں کی قسم شامل کریں",
      enterDescription: "تفصیل درج کریں",
      description: "تفصیل",
      allotLeaves: "مختص کردہ رخصتیں",
      balanceLeaves: "رخصتیں کی قسم (بیکنس رخصتیں)",
      selectType: "قسم منتخب کریں",
      leaveFor: "لیئے رخصتیں",
      leaveDates: "رخصتیں کی تاریخیں",
      createleave: "رخصتیں بنائیں",
    },
    expense: {
      Expense: "اخراجات ہیڈر",
      enterExpense: "اخراجات ہیڈر درج کریں",
      enterDescription: "تفصیل درج کریں",
      description: "تفصیل",
      save: "اخراجات ہیڈر محفوظ کریں",
      clear: "صاف",
      Add: "اخراجات ہیڈر شامل کریں",
    },
    elearningCategory: {
      elearningCategory: "ای لرننگ کیٹیگری",
      description: "تفصیل",
      enterelearningCategory: "کیٹیگری درج کریں",
      enterDescription: "تفصیل درج کریں",
      save: "ای لرننگ کیٹیگری محفوظ کریں",
      clear: "صاف",
      Add: "ای لرننگ کیٹیگری شامل کریں",
    },
    salaryHeader: {
      SalaryHeader: "تنخواہ ہیڈر",
      enterSalaryHeader: "تنخواہ ہیڈر درج کریں",
      desc: "تفصیل",
      enterDesc: "تفصیل درج کریں",
      saveHeader: "ہیڈر محفوظ کریں",
      addHeader: "ہیڈر شامل کریں",
      name: "نام",
      clear: "صاف کریں",
    },
    room: {
      Room: "کمرہ",
      Name: "نام",
      Entername: "نام درج کریں",
      Description: "تفصیل",
      Enterdescription: "تفصیل درج کریں",
      Location: "مقام",
      Enterlocation: "مقام درج کریں",
      TotalSeats: "کل سیٹیں",
      Enterseats: "سیٹیں درج کریں",
      SaveRoom: "کمرہ محفوظ کریں",
      AddRoom: "کمرہ شامل کریں",
      clear: "صاف کریں"
    },
    RebateCategory: {
      rebateCategory: "ریبیٹ کیٹیگری",
      maxPercentage: "زیادہ سے زیادہ فیصد",
      entermaxPercentage: "زیادہ سے زیادہ فیصد درج کریں",
      maxAmount: "زیادہ سے زیادہ رقم",
      enterMaxAmount: "زیادہ سے زیادہ رقم درج کریں",
      type: "قسم",
      basic: "بنیادی",
      tax: "ٹیکس",
      fullAmount: "پوری رقم",
      rebateType: "ریبیٹ کی قسم",
    },
    projectCategory: {
      ProjectCategory: "پروجیکٹ کیٹیگری",
      AddProjectCategory: "پروجیکٹ کیٹیگری شامل کریں",
    },
    pageCategory: {
      Pagecategory: "صفحہ کیٹیگری",
      AddPageCategory: "صفحہ کیٹیگری شامل کریں",
    },
    NonMandatoryApprovals: {
      NonMandatoryApproval: "غیر ضروری منظوریاں",
    },
    jobdescription: {
      jobDesc: "نوکری کی تفصیل",
      designation: "عہد",
      selectDesign: "عہد منتخب کریں",
      saveJobDesc: "نوکری کی تفصیل محفوظ کریں",
      addJobdesc: "نوکری کی تفصیل شامل کریں",
    },
    Holiday: {
      Holidays: "چھٹیاں",
      AddHoliDay: "چھٹی شامل کریں"
    },
    GradeAllowance: {
      gradeAllowance: "گریڈ انعام",
      Grades: "گریڈز",
      AllowanceType: "انعام کی قسم",
      SelectType: "قسم منتخب کریں",
      AddAllowance: "انعام شامل کریں",
      UpdateAllowance: "انعام اپ ڈیٹ کریں",
      Allowance: "انعام",
      IsTaxable: "ٹیکس قابل ہے",
    },
    EmailConfiguration: {
      emailConfi: "ای میل کنفیگریشن",
      enterEmailConfi: "ای میل کنفیگریشن درج کریں",
      incomingPort: "آمدنی پورٹ",
      enterIncomingPort: "آمدنی پورٹ درج کریں",
      incomingServerAddress: "آمدنی سرور کا پتہ",
      enterIncomingServerAddress: "آمدنی سرور کا پتہ درج کریں",
      outgoingPort: "آؤٹ گوئنگ پورٹ",
      enterOutgoingPort: "آؤٹ گوئنگ پورٹ درج کریں",
      outgoingServerAddress: "آؤٹ گوئنگ سرور کا پتہ",
      enterOutgoingServerAddress: "آؤٹ گوئنگ سرور کا پتہ درج کریں",
      provider: "فراہم کنندہ",
      enterProvider: "فراہم کنندہ درج کریں",
      saveEmailConfi: "ای میل کنفیگریشن محفوظ کریں",
      addEmailConfi: "ای میل کنفیگریشن شامل کریں",
    },
    DiscussionBoardCategory: {
      discussionBoardCategory: "ڈسکشن بورڈ کیٹیگری",
      SaveDiscussionBoardCategory: "ڈسکشن بورڈ کیٹیگری محفوظ کریں",
      AddDiscussionBoardCategory: "ڈسکشن بورڈ کیٹیگری شامل کریں",
    },
    DefaultApprovers: {
      defaultApprovers: "ڈیفالٹ منظور کنندگان",
    },
    DefaultAccountHeader: {
      defaultAccountHeader: "ڈیفالٹ اکاؤنٹ ہیڈر",
      SaveAccountHeader: " ڈیفالٹ اکاؤنٹ ہیڈر محفوظ کریں",
      SelectAccountTypes: "اکاؤنٹ کی قسمیں منتخب کریں",
    },
    CustomTag: {
      customTag: "کسٹم ٹیگ",
      Entercustomtag: "کسٹم ٹیگ درج کریں",
      SaveCustomTag: "کسٹم ٹیگ محفوظ کریں",
      AddCustomTag: "کسٹم ٹیگ شامل کریں",
    },
    customApprovalCategory: {
      customApprovalcategory: "کسٹم منظوری کیٹیگری",
      enterCustomApprovalcategory: "کسٹم منظوری کیٹیگری درج کریں",
    },
    BusinessNumber: {
      Manual: "دستی",
      TotalNumber: "کل نمبر",
      Country: "ملک",
      SelectCountry: "ملک منتخب کریں",
      Number: "نمبر",
      Random: "بے ترتیب",
      SelectNumber: "نمبر منتخب کریں",
      Cost: "لاگت",
      Request: "درخواست",
    },
    BusinessAssociation: {
      businessAssociation: "بزنس ایسوسی ایشن",
      SaveBusinessAssociation: "بزنس ایسوسی ایشن محفوظ کریں",
      AddBusinessAssociation: "بزنس ایسوسی ایشن شامل کریں",
      AssociationType: "ایسوسی ایشن کی قسم",
    },
    AssetsCategory: {
      assetsCategory: "اثاثے کیٹیگری",
      categoryName: "کیٹیگری کا نام",
      enterCategoryName: "کیٹیگری کا نام درج کریں",
      desc: "تفصیل",
      enterDesc: "تفصیل درج کریں",
      accountType: "اکاؤنٹ کی قسم",
      parentAccount: "والد اکاؤنٹ",
      selectParent: "والد اکاؤنٹ منتخب کریں",
      selectType: "قسم منتخب کریں",
      add: "شامل کریں",
      save: "محفوظ کریں",
      clear: "صاف کریں",
      name: "نام",
      assets: "اثاثہ",
      liability: "ذمہ داری",
      capital: "سرمایہ",
      revenue: "آمدنی",
      expense: "اخراجات",
      costOfGoodsale: "فروخت کی مال کی لاگت",
    },
    Allowance: {
      allowance: "انعام",
      enterAllownace: "انعام درج کریں",
      percent: "فیصد",
      desc: "تفصیل درج کریں",
      grade: "گریڈ",
      selectGrade: "گریڈ منتخب کریں",
      amount: "رقم",
      benefit: "فائدہ",
      deduction: "کمی",
      texable: "ٹیکس قابل",
      nonTexable: "ٹیکس غیر قابل",
      director: "ڈائریکٹر",
      ceo: "سی ای او",
      addAllowance: "انعام شامل کریں",
      saveAllowance: "انعام محفوظ کریں",
      clear: "صاف کریں",
      name: "نام",
      allowanceType: "انعام کی قسم",
      allowanceUnit: "انعام یونٹ",
      istexable: "ٹیکس قابل ہے",
      yes: "ہاں",
      no: "نہیں",
    },
    CompanyInfo: {
      businessLogo: "کاروباری لوگو",
      basicinfo: "بنیادی معلومات",
      ShortLogo: "مختصر لوگو",
      Logo: "لوگو",
      FullLogo: "پورا لوگو",
      companyName: "کمپنی کا نام",
      PleaseEnterCompanyName: "براہ کرم کمپنی کا نام درج کریں",
      enterCompanyName: "کمپنی کا نام درج کریں",
      companyEmail: "کمپنی کا ای میل درج کریں",
      PleaseEnterCompanyEmail: "براہ کرم کمپنی کا ای میل درج کریں",
      SelectOwnerName: "مالک کا نام منتخب کریں",
      totalEmployees: "کل ملازمین",
      totalEmployeesInCall: "کال میں کل ملازمین",
      SaveShortLogo: "مختصر لوگو محفوظ کریں",
      LongLogo: "لمبا لوگو",
      SaveLongLogo: "لمبا لوگو محفوظ کریں",
      SaveFullLogo: "پورا لوگو محفوظ کریں",
    },
    billing: {
      Invoice: "انوائس",
      InvoiceNo: "انوائس نمبر",
      CustomerName: "گاہک کا نام",
      PayInvoice: "انوائس ادا کریں",
      Payment: "ادائیگی",
      BillNo: "بل نمبر",
      CardLast: "کارڈ آخری چار",
      BusinessName: "کاروبار کا نام",
      CardHolder: "کارڈ ہولڈر",
      Viewinvoicedetails: "انوائس اور ادائیگی کی تفصیلات دیکھیں",
      Viewinvoicedetail: "انوائس تفصیل دیکھیں",
      Qty: "مقدار",
    },
    PaymentCard: {
      paymentCard: "ادائیگی کارڈ",
      CardHolderName: "کارڈ ہولڈر کا نام",
      AddCard: "کارڈ شامل کریں",
      PayInvoice: "انوائس ادا کریں",
      CardHolderNameRequired: "کارڈ ہولڈر کا نام ضروری ہے۔",
      PayCard: "کارڈ سے ادا کریں",
      payanotherway: "یا کسی دوسرے طریقے سے ادا کریں",
      credentialstocontinue: "جاری رکھنے کے لئے اپنے کریڈنشلز درج کریں۔",
      Expiry: "میعاد ختم ہونے کی تاریخ",
      Brand: "برانڈ",
      Country: "ملک",
      LastFourDigits: "آخری چار ہندسے",
    },

  },
  billing: {
    BillType: "بل کی قسم",
    billingTypeEnum: {
      [BillingTypeEnum.NewUser]: "نیا صارف",
      [BillingTypeEnum.MonthlySubscription]: "ماہانہ سبسکرپشن",
      [BillingTypeEnum.NewBusinessNumber]: "نیا کاروباری نمبر"
    }
  },
  businessNumber: {
    statusEnum: {
      [BuisnessNumberStatusEnum.Waiting]: "انتظار",
      [BuisnessNumberStatusEnum.Declined]: "انکار شدہ",
      [BuisnessNumberStatusEnum.NumberCancelled]: "نمبر منسوخ شدہ",
      [BuisnessNumberStatusEnum.Purchased]: "خریداری شدہ",
      [BuisnessNumberStatusEnum.RequestCancelled]: "درخواست منسوخ شدہ",
      [BuisnessNumberStatusEnum.Rejected]: "مسترد شدہ",
    },
  },
  businessApproval: {
    statusTypeList: {
      [ApprovalStatusTypeEnum.InProcess]: "عمل میں",
      [ApprovalStatusTypeEnum.Approved]: "منظور شدہ",
      [ApprovalStatusTypeEnum.Declined]: "انکار شدہ",
      [ApprovalStatusTypeEnum.Cancelled]: "منسوخ شدہ",
      [ApprovalStatusTypeEnum.Completed]: "مکمل شدہ",
    }
  },
  appraisal: {
    Appraisals: "تنخواہ کا مطالعہ",
    createAppraisals: "تنخواہ کا مطالعہ بنائیں",
    noDataMessage: "– ابھی تنخواہ کا مطالعہ بنائیں!",
    Approvers: "منظم کنندگان",
    Questions: "سوالات",
    Tasks: "کام",
    Bonus: "بونس",
    Increment: "افزائی",
    previousApraisals: "پچھلے تنخواہ کا مطالعہ",
    warning: "انتباہ",
    rewards: "انعامات",
    courses: "کورسز",
    Promotion: "ترقی",
    PleaseEnterBonusPercentage: "براہ کرم بونس فیصد درج کریں",
    PleaseEnterBonusAmount: "براہ کرم بونس رقم درج کریں",
    PleaseEnterIncrementAmount: "براہ کرم افزائی رقم درج کریں",
    RateAtleastOneItem: "کم سے کم ایک آئٹم کا درجہ دیں",
    Noemployeeselected: "کوئی ملازم منتخب نہیں ہوا",
    submitAppraisal: "تنخواہ کا مطالعہ جمع کریں",
    Appraisedof: "تنخواہ کا مطالعہ کیا گیا",
    AppraisalApprovers: "تنخواہ کا مطالعہ منظم کنندگان",
    AppraisalDetail: "تنخواہ کا مطالعہ تفصیل",
    appraisalFilterTypeEnum: {
      [AppraisalFilterEnum.All]: "تمام تنخواہ کا مطالعہ",
      [AppraisalFilterEnum.CreatedByMe]: "میرے ذریعے بنائیا گیا",
      [AppraisalFilterEnum.ForApproval]: "منظوری کے لئے",
      [AppraisalFilterEnum.TeamAppraisal]: "ٹیم کا تنخواہ کا مطالعہ",
      [AppraisalFilterEnum.MyApprovedAppraisal]: "میرا منظور شدہ تنخواہ کا مطالعہ",
    },
    statusEnum: {
      [AppraisalStatusEnum.InProcess]: "عمل میں ہے",
      [AppraisalStatusEnum.Approved]: "منظور ہوا",
      [AppraisalStatusEnum.Declined]: "منظور نہیں ہوا",
      [AppraisalStatusEnum.Cancelled]: "منسوخ ہوا",
      [AppraisalStatusEnum.Hold]: "روکا گیا",
    },
    SortEnum: {
      [AppraisalFilterSortEnum.CreateDateDesc]: "تخلیق تاریخ - گھٹتے ہوئے",
      [AppraisalFilterSortEnum.CreateDateAsc]: "تخلیق تاریخ",
      [AppraisalFilterSortEnum.UpdateDateDesc]: "اپ ڈیٹ تاریخ - گھٹتے ہوئے",
      [AppraisalFilterSortEnum.UpdateDateAsc]: "اپ ڈیٹ تاریخ",
      [AppraisalFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - گھٹتے ہوئے",
      [AppraisalFilterSortEnum.ReferenceNo]: "حوالہ نمبر"
    }

  },
  promotion: {
    CreatePromotion: "ترقی دیں",
    Promotion: "ترقی",
    promotionTo: "ترقی دیں",
    noDataMessage: "اب ترقی دیں!",
    selectPromotionTo: "ترقی دینے کے لئے چنائیں",
    pleseSelectPromotionTo: "براہ کرم ترقی دینے کے لئے چنائیں",
    createPromotion: "ترقی دیں",
    promotionFilterTypeEnum: {
      [PromotionFilterTypeEnum.All]: "تمام ترقیاں",
      [PromotionFilterTypeEnum.CreatedByMe]: "میرے ذریعے تخلیق شدہ",
      [PromotionFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [PromotionFilterTypeEnum.PromotionForMe]: "میری ترقیاں",
    },
    statusEnum: {
      [PromotionStatusEnum.InProcess]: "عمل میں",
      [PromotionStatusEnum.Approved]: "منظور شدہ",
      [PromotionStatusEnum.Declined]: "منظور نہیں",
      [PromotionStatusEnum.Cancelled]: "منسوخ",
      [PromotionStatusEnum.Hold]: "متوقف",
    },
  },
  employees: {
    placeholders: {
      fName: "پہلا نام درج کریں",
      lName: "آخری نام درج کریں",
      fatherName: "والد کا نام درج کریں",
      email: "ای میل درج کریں",
      pEmail: "ذاتی ای میل درج کریں",
      phNo: "فون نمبر درج کریں",
      rAddress: "رہائشی پتہ درج کریں",
      pAddress: "مستقل پتہ درج کریں",
      cnicNo: "CNIC نمبر درج کریں",
      searchToSelect: "منتخب کرنے کے لیے تلاش کریں",
      probPeriod: "پروبیشن کی مدت داخل کریں",
      noticePeriod: "نوٹس کی مدت درج کریں",
      selectDate: "تاریخ منتخب کریں",
      selectGender: "صنف منتخب کریں",
      selectMarital: "ازدواجی حیثیت کو منتخب کریں",
      selectShift: "شفٹ کو منتخب کریں",
      empNo: "ملازم نمبر درج کریں",
      empType: "ایمپلائیمنٹ کی قسم منتخب کریں",
      selectUserType: "صارف کی قسم منتخب کریں",
      selectAccessRole: "رسائی کا کردار منتخب کریں",
      degree: "ڈگری داخل کریں",
      institute: "انسٹی ٹیوٹ کا نام درج کریں",
      desc: "تفصیل درج کریں",
      tMarks: "کل نمبر درج کریں",
      oMarks: "حاصل کردہ نمبر درج کریں",
      sDate: "شروع تاریخ",
      eDate: "آخری تاریخ",

      position: "پوزیشن درج کریں",
      name: "نام درج کریں",
      address: "پتہ درج کریں",
      number: "نمبر درج کریں",
      selectRelation: "رشتہ منتخب کریں",
      bankName: "بینک کا نام درج کریں",
      accTitle: "اکاؤنٹ کا عنوان درج کریں",
      branchCode: "برانچ کوڈ درج کریں",
      accNo: "اکاؤنٹ نمبر درج کریں",
      iban: "iban نمبر درج کریں",
      sortCode: "ترتیب کوڈ درج کریں",
    },
    EmployeeForm: {
      AddImage: "تصویر شامل کریں",
      FirstName: "پہلا نام",
      LastName: "آخری نام",
      FatherName: "والد کا نام",
      Email: "ای میل",
      PersonalEmail: "ذاتی ای میل",
      CNICNumber: "سی این آئی سی نمبر",
      rAddress: "رہائشی پتہ",
      pAddress: "مستقل پتہ",
      PhoneNumber: " فون نمبر",
      Designation: " عہدہ",
      Manager: " مینیجر",
      Grades: "درجات",
      Department: "شعبہ",
      Country: "ملک",
      City: "شہر",
      Languages: "زبانیں",
      ProbationPeriod: "ٹرائی مدت",
      NoticePeriod: "نوٹس کی مدت",
      DateOfBirth: "پیدائش کی تاریخ",
      DateOfJoining: "شمولیت کی تاریخ",
      Gender: "صنف",
      MaritalStatus: "ازدواجی حیثیت",
      OfficeShift: "آفس شفٹ",
      EmploymentType: "ملازمت کی قسم",
      UserType: "صارف کی قسم",
      AccessRole: "رسائی کا کردار",
      EmpNo: "ملازم کا نمبر",
    },
    EmergencyForm: {
      EmergencyInfo: "ایمرجنسی معلومات",
      Name: "نام",
      Address: "پتہ",
      Number: "نمبر",
      Relation: "رشتہ",
    },
    EducationForm: {
      EducationInfo: "تعلیمی معلومات",
      Degree: "ڈگری",
      Institute: "انسٹی ٹیوٹ",
      StartEndDate: "شروع/اختتام کی تاریخ",
      StartDate: "شروع کرنے کی تاریخ",
      Description: "تفصیل",
      TotalMarks: "کل مارکس",
      ObtainedMarks: "مارکس حاصل کئے",
      Present: "موجودہ",
      Attachments: "ملحقات",
      AddMoreEducation: "مزید تعلیم شامل کریں",
      City: "شہر",
      Upload: "اپ لوڈ",
    },
    WorkExperienceForm: {
      WorkExperienceInfo: "کام کے تجربے کی معلومات",
      Employer: "آجر",
      Position: "پوزیشن",
      Department: "محکمہ",
      StartEndDate: "شروع/اختتام کی تاریخ",
      StartDate: "شروع کرنے کی تاریخ",
      Present: "موجودہ",
      AddMoreExperience: "مزید تجربہ شامل کریں",
      EmploymentType: "ملازمت کی قسم",
      City: "شہر",
    },
    UserForm: {
      UserRights: "صارف کے حقوق",
      UserType: "صارف کی قسم",
      UserRole: "صارف کا کردار",
    },
    BankForm: {
      BankInfo: "بینک کی معلومات",
      BankName: "بینک کا نام",
      AccountTitle: "اکاؤنٹ ٹائٹل",
      BranchCode: "برانچ کوڈ",
      AccountNumber: "اکاؤنٹ نمبر",
      IBAN: "IBAN",
      SortCode: "سانٹ کوڈ",
      City: "شہر",
      Country: "ملک",
      AddMoreBank: "مزید بینک شامل کریں",
    },
    EmployeeList: {
      number: "نمبر",
      email: "ای میل",
      designation: "عہدہ",
    },
    submit: "جمع کرائیں",
  },
  leaves: {
    leave: "چھٹیاں",
    leavedetail: "چھٹیوں کی تفصیلات",
    Leave: "چھٹی",
    leaveType: "چھٹی کی قسم",
    enterLeave: "چھٹی کی قسم درج کریں",
    save: "چھٹی کی قسم محفوظ کریں",
    clear: "صاف",
    Add: "چھٹی کی قسم شامل کریں",
    enterDescription: "تفصیل درج کریں",
    description: "تفصیل",
    allotLeaves: "مختص چھٹیاں",
    balanceLeaves: "چھٹیوں کی قسمیں (بقیہ چھٹیاں)",
    selectType: "قسم منتخب کریں",
    leaveFor: "چھٹی کے لیے",
    leaveDates: "چھٹی کی تاریخیں",
    createleave: "چھٹی بنائیں",
    days: "دن",
    leavetype: "چھٹی کی قسم",
    approvers: "منظم کنندگان",
    selectMembers: "ممبرز منتخب کریں",
    daysCount: "دنوں کی تعداد",
    messages: "براہ کرم کرکے چھٹی کی قسم منتخب کریں",
    allocatedLeaves: "مختص چھٹیاں",
    availedLeaves: "حاصل شدہ چھٹیاں",
    remainingLeaves: "باقی چھٹیاں",
    noDataMessage: " - اب چھٹی بنائیں!",
    Leavesinfo: "رخصتیں کی معلومات",
    UpdateLeave: "رخصتیں کو اپ ڈیٹ کریں",
    leavesFilterTypeEnum: {
      [LeaveFilterTypeEnum.All]: "تمام چھٹیاں",
      [LeaveFilterTypeEnum.CreatedByMe]: "میرے ذریعے بنائی گئی چھٹیاں",
      [LeaveFilterTypeEnum.ForApproval]: "منظوری کے لیے",
      [LeaveFilterTypeEnum.LeaveForMe]: "میری چھٹیاں",
    },
    statusEnum: {
      [LeaveStatusEum.InProcess]: "عمل میں ہے",
      [LeaveStatusEum.Approved]: "منظور ہے",
      [LeaveStatusEum.Declined]: "منظور نہیں ہے",
      [LeaveStatusEum.Resend]: "دوبارہ بھیجی گئی ہے",
      [LeaveStatusEum.InActive]: "غیر فعال ہے",
      [LeaveStatusEum.NotRequired]: "ضرورت نہیں ہے",
      [LeaveStatusEum.Cancelled]: "منسوخ ہے",
      [LeaveStatusEum.Hold]: "متوقف ہے",
    },
  },
  loan: {
    loan: "قرض",
    createLoan: "قرض بنائیں",
    loanTenure: "قرض کی مدت (مہینوں میں)",
    enterLoanTenure: "قرض کی مدت درج کریں (مہینوں میں)",
    deduction: "کٹوتی/مہینے",
    selectLoanPurpose: "قرض کا مقصد منتخب کریں",
    noDataMessage: "– اب قرض بنائیں!",
    SortBy: "ترتیب دیں",
    SelectSortBy: "ترتیب دیں منتخب کریں",
    InterestRate: "سود کی شرح",
    loanPurpose: {
      loanPurpose: "قرض کا مقصد",
      vehicle: "گاڑی",
      personal: "ذاتی",
      wedding: "شادی",
      medical: "طبی",
      education: "تعلیم",
      house: "گھر",
      other: "دیگر",
    },
    loanPurposeEnumList: {
      [LoanPurposeEnum.Personal]: "ذاتی",
      [LoanPurposeEnum.Wedding]: "شادی",
      [LoanPurposeEnum.Medical]: "طبی",
      [LoanPurposeEnum.Education]: "تعلیم",
      [LoanPurposeEnum.House]: "گھر",
      [LoanPurposeEnum.Vehicle]: "گاڑی",
      [LoanPurposeEnum.Other]: "دیگر",
    },
    loanFilterTypeEnum: {
      [LoanFilterTypeEnum.All]: "تمام قروض",
      [LoanFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "میرے قروض",
      [LoanFilterTypeEnum.LoanForMe]: "میرے لئے قرض",
    },
    statusEnum: {
      [LoanStatusEnum.InProcess]: "عمل میں ہے",
      [LoanStatusEnum.Approved]: "منظور ہوگیا",
      [LoanStatusEnum.Declined]: "منظور نہیں ہوا",
      [LoanStatusEnum.Cancelled]: "منسوخ ہوگیا",
      [LoanStatusEnum.Disbursed]: "ادا کیا گیا",
      [LoanStatusEnum.PartialCleared]: "جزوی طور پر صاف ہوگیا",
      [LoanStatusEnum.Completed]: "مکمل ہوگیا",
      [LoanStatusEnum.Hold]: "روکے ہوئے",
      [LoanStatusEnum.WaitingForDisburse]: "تقسیم کا انتظار ہے",
    },
    loanTypeEnum: {
      [LoanTypeEnum.Company]: "کمپنی کا قرض",
      [LoanTypeEnum.WorkWise]: "ورک وائز قرض",
    },
  },
  quotations: {
    Quotation: "قرارداد",
    CreateQuotation: "قرارداد بنائیں",
    quotationDate: "تاریخ (Date)",
    ClientName: "کلائنٹ کا نام",
    ClientEmail: "کلائنٹ کا ای میل",
    PhoneNumber: "فون نمبر",
    TotalAmount: "کل رقم",
    QuotationType: "قرارداد کی قسم",
    Rate: "شرح",
    Quantity: "مقدار",
    Attachments: "منسلکہ",
    serviceItem: "خدمت/آئٹم",
    price: "قیمت",
    tax: "ٹیکس (%)",
    taxAmount: "ٹیکس کی رقم",
    sno: "سیریل نمبر",
    EnterClientName: "کلائنٹ کا نام درج کریں",
    clientsName: "کلائنٹ کا نام",
    EnterClientEmail: "کلائنٹ کا ای میل درج کریں",
    addressLine1: "پتہ لائن 1",
    addressLine2: "پتہ لائن 2",
    EnterAddress1: "پتہ 1 درج کریں:",
    EnterAddress2: "پتہ 2 درج کریں:",
    Product: "پروڈکٹ",
    Service: "خدمت",
    netAmount: "صاف رقم",
    CODE: "کوڈ",
    DATE: "تاریخ",
    TOTAL: "کل",
    To: "کو",
    SubTotal: "ذیلی کل",
    noDataMessage: "– ابھی کوٹیشن بنائیں!",
    quotationFilterTypeEnum: {
      [QuotationFilterEnum.All]: "قراردادیں (Quotations)",
      [QuotationFilterEnum.CreatedByMe]: "میرے ذریعے بنائی گئیں (Created By Me)",
      [QuotationFilterEnum.ForApproval]: "منظوری کے لئے (For Approval)",
    },
    statusEnum: {
      [QuotationStatusEnum.InProcess]: "عمل میں (In Process)",
      [QuotationStatusEnum.Approved]: "منظور (Approved)",
      [QuotationStatusEnum.Declined]: "انکار (Declined)",
      [QuotationStatusEnum.Cancelled]: "منسوخ (Cancelled)",
      [QuotationStatusEnum.Hold]: "روکے ہوئے (Hold)",
      [QuotationStatusEnum.ClientApproved]: "کلائنٹ کی منظوری",
      [QuotationStatusEnum.ClientRejected]: "کلائنٹ کی نامنظوری",
    },
    SortEnum: {
      [QuotationFilterSortEnum.CreateDateDesc]: "تخلیق کی تاریخ - نزولی",
      [QuotationFilterSortEnum.CreateDateAsc]: "تخلیق کی تاریخ - اُرُوجی",
      [QuotationFilterSortEnum.UpdateDateDesc]: "اپ ڈیٹ کی تاریخ - نزولی",
      [QuotationFilterSortEnum.UpdateDateAsc]: "اپ ڈیٹ کی تاریخ - اُرُوجی",
      [QuotationFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [QuotationFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - نزولی"
    },
    ErrorFieldMessage:{
      [InputFieldTypes.PRICE]: "قیمت 0 سے زیادہ ہونا چاہیے",
      [InputFieldTypes.QUANTITY]: "مقدار 0 سے زیادہ ہونا چاہیے",
      [InputFieldTypes.TAX]: "ٹیکس 0 سے زیادہ ہونا چاہیے",
    },
    singleItemError: "براہ کرم کوٹیشن میں کم از کم ایک ایٹم اضافہ کریں۔",

  },
  payroll: {
    Payroll: "پے رول",
    createPayroll: "پے رول بنائیں",
    noDataMessage: "– اب پے رول بنائیں!",
    basicSalary: "بنیادی تنخواہ",
    loan: "قرض",
    allowance: "مناسبت",
    ExpenseReimbursement: "اخراجات کی تلافی",
    deduction: "کٹوتی",
    tax: "ٹیکس",
    rebate: "ریبیٹ",
    bonus: "بونس",
    selectMonthYear: "مہینہ اور سال منتخب کریں",
    dispereseDate: "تشہیر تاریخ",
    Voucher: "واؤچر",
    DownloadtoExcel: "ایکسل میں ڈاؤنلوڈ کریں",
    PayrollDetail: "پے رول ڈیٹیل",
    TransactionMode: "ٹرانزیکشن موڈ",
    IBANNumber: "آئی بین نمبر",
    statusEnum: {
      [PayrollStatusEnum.InProcess]: "عملیات میں ہے",
      [PayrollStatusEnum.Approved]: "منظور شدہ",
      [PayrollStatusEnum.Declined]: "منظور نہیں ہوا",
      [PayrollStatusEnum.Resend]: "دوبارہ بھیجیں",
      [PayrollStatusEnum.Inactive]: "غیر فعال",
      [PayrollStatusEnum.NotRequired]: "ضرورت نہیں",
      [PayrollStatusEnum.Cancelled]: "منسوخ ہوا",
      [PayrollStatusEnum.Hold]: "معطل",
      [PayrollStatusEnum.Disbursed]: "پھیلا ہوا",
      [PayrollStatusEnum.Completed]: "مکمل",
    },
    payrollFilterTypeEnum: {
      [PayrollFilterTypeEnum.All]: "پے رول",
      [PayrollFilterTypeEnum.CreatedByMe]: "میرے ذریعے بنایا گیا",
      [PayrollFilterTypeEnum.ForApproval]: "منظوری کے لئے",
    },
  },
  voucher: {
    createVoucher: "واچر بنائیں",
    SelectVoucherType: "واچر کی قسم منتخب کریں",
    BasicSalary: "بنیادی تنخواہ",
    LoanPayment: "قرض کی ادائیگی",
    LoanDispersable: "قرض قابل توزیع",
    Allowance: "بخشش",
    ExpenseReimbursement: "اخراجات کی تلافی",
    Deduction: "کٹوتی",
    VoucherDetail: "واچر کی تفصیل",
    SavePrint: "محفوظ کریں اور چھاپیں",
    Bonus: "بونس",
    Rebate: "ریبیٹ",
    Tax: "ٹیکس",
    ChequeNo: "چیک نمبر",
    Account: "اکاؤنٹ",
    Narration: "تفصیل",
    Debit: "ڈیبٹ",
    Credit: "کریڈٹ",
    Dr: "ڈاکٹر",
    Cr: "کریڈٹ",
    differences: "اختلافات",
    drCr: "ڈاکٹر/کریڈٹ",
    LinkVoucher: "واچر لنک کریں",
    PostVoucher: "واچر بھیجیں",
    voucherDate: "واوچر تاریخ",
    VoucherType: "واوچر قسم",
    voucherTypeList: {
      [voucherTypeEnum.GeneralVoucher]: "عام واچر",
      [voucherTypeEnum.PaymentVoucher]: "ادائیگی واچر",
      [voucherTypeEnum.ReceiptVoucher]: "رسید واچر",
    },
  },
  trialBalance: {
    accountTypeList: {
      [accountTypeEnum.Asset]: "اثاثہ",
      [accountTypeEnum.Capital]: "سرمایہ",
      [accountTypeEnum.Expense]: "خرچہ",
      [accountTypeEnum.Liability]: "ذمہ داری",
      [accountTypeEnum.Revenue]: "آمدنی",
    },
  },
  disperse: {
    transactionModeEnumList: {
      [TransactionModeTypeEnum.Cash]: "نقد",
      [TransactionModeTypeEnum.BankTransfer]: "بینک ٹرانسفر",
      [TransactionModeTypeEnum.Cheque]: "چیک",
    },
  },
  warnings: {
    inprogress: "جاری ہے",
    filter: "فلٹر",
    approvers: "منظوری دینے والے",
    createwarnings: "انتباہ بنائیں",
    warnings: "انتباہ ",
  },
  complains: {
    createComplain: "شکایت کریں",
    myComplain: "میری شکایات",
    AllComplains: "تمام شکایات",
    complainOf: "شکایت کا موضوع",
    ComplainDetail: "شکایت کی تفصیل",
    ComplainToMe: "مجھ سے شکایت",
    ComplainAboutMe: "میرے بارے میں شکایت",
    noDataMessage: " – اب شکایات بنائیں!",
    statusEnum: {
      [ComplainApprovalStatusEnum.InProcess]: "عمل میں",
      [ComplainApprovalStatusEnum.Approved]: "منظور شدہ",
      [ComplainApprovalStatusEnum.Declined]: "رد کر دیا گیا",
      [ComplainApprovalStatusEnum.Resend]: "دوبارہ بھیجی گئی",
      [ComplainApprovalStatusEnum.Inactive]: "غیر فعال",
      [ComplainApprovalStatusEnum.NotRequired]: "ضرورت نہیں",
      [ComplainApprovalStatusEnum.Cancelled]: "منسوخ",
      [ComplainApprovalStatusEnum.Hold]: "معطل",
    },
    SortEnum: {
      [ComplainFilterSortEnum.CreateDateDesc]: "تخلیقی تاریخ - گرنا",
      [ComplainFilterSortEnum.CreateDateAsc]: "تخلیقی تاریخ",
      [ComplainFilterSortEnum.UpdateDateDesc]: "اپ ڈیٹ کی تاریخ - گرنا",
      [ComplainFilterSortEnum.UpdateDateAsc]: "اپ ڈیٹ کی تاریخ",
      [ComplainFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [ComplainFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - گرنا"
    }

  },
  resignations: {
    resignationHeader: "استعفی",
    resignationDate: "استعفی کی تاریخ",
    createresignation: "استعفی بنائیں",
    noDataMessage: " - اب استعفی بنائیں!",
    reasonforresignation: "استعفی کا باعث",
    istermination: "فسخ ہے",
    resignationDetail: "استعفی کی تفصیل",
    statusEnum: {
      [ResignationApprovalStatusEnum.InProcess]: "عمل میں ہے",
      [ResignationApprovalStatusEnum.Approved]: "منظور",
      [ResignationApprovalStatusEnum.Declined]: "مسترد",
      [ResignationApprovalStatusEnum.Cancelled]: "منسوخ",
      [ResignationApprovalStatusEnum.Completed]: "مکمل ہوگیا",
      [ResignationApprovalStatusEnum.Hold]: "ہولڈ",
    },
    resignationFilterTypeEnum: {
      [ResignationFilterTypeEnum.Resignation]: "استعفی",
      [ResignationFilterTypeEnum.CreateByMe]: "میرے ذریعے بنائیں گئے",
      [ResignationFilterTypeEnum.ForApproval]: "منظوری کے لئے",
    },
    resignationTypeEnum: {
      [ResignationTypeEnum.Resignation]: "استعفی",
      [ResignationTypeEnum.Termination]: "فسخ",
    },
  },
  Employees: {
    employeeAttachments: "ملازم کے دستاویزات",
    CreateEmployeeNow: "ابھی ملازم بنائیں",
    ProbationPeriod: "آزمائشی مدت (دن)",
    requiredMessageProbation: "براہ کرم آپنے آزمائشی مدت درج کریں",
    requiredMessageProbationPeriod: "آزمائشی مدت صفر اور تیس (0 اور 30) کے درمیان ہونی چاہئے",
    EmployeeNo: "ملازم کا نمبر",
    EnterEmployeeNo: "ملازم کا نمبر درج کریں",
    EmploymentType: "روزگار کی قسم",
    SelectEmploymentType: "روزگار کی قسم منتخب کریں",
    requiredMessageEmploymentType: "براہ کرم روزگار کی قسم کا انتخاب کریں",
    OfficeShift: "آفس کا شفٹ",
    requiredMessageOfficeShift: "براہ کرم آفس کا شفٹ منتخب کریں",
    SelectOfficeShift: "آفس کا شفٹ منتخب کریں",
    UpdateBasicInfo: "بنیادی معلومات کو اپ ڈیٹ کریں",
    Degree: "ڈگری",
    EnterDegree: "ڈگری درج کریں",
    requiredDegree: "براہ کرم ڈگری درج کریں",
    Institute: "انسٹی ٹیوٹ",
    EnterInstituteName: "انسٹی ٹیوٹ کا نام درج کریں",
    requiredMessageInstitute: "براہ کرم انسٹی ٹیوٹ کا نام درج کریں",
    ObtainedMarks: "حاصل کردہ نمبر",
    requiredMessageObtained: "براہ کرم حاصل کردہ نمبر درج کریں",
    EnterObtainedMarks: "حاصل کردہ نمبر درج کریں",
    TotalMarks: "کل نمبر",
    EnterTotalMarks: "کل نمبر درج کریں",
    requiredMessageMarks: "براہ کرم کل نمبر درج کریں",
    UpdateEducation: "تعلیم کو اپ ڈیٹ کریں",
    AddEducation: "تعلیم شامل کریں",
    WorkExperienceInfo: "تجربہ کاری کی معلومات",
    UpdateExperience: "تجربہ کو اپ ڈیٹ کریں",
    AddExperience: "تجربہ شامل کریں",
    EmergencyInfo: "اضطراری معلومات",
    UpdateEmergencyForm: "اضطراری فارم کو اپ ڈیٹ کریں",
    AddEmergency: "اضطراری معلومات شامل کریں",
    UpdateEmergency: "اضطراری معلومات کو اپ ڈیٹ کریں",
    UpdateBank: "بینک کو اپ ڈیٹ کریں",
    AddBank: "بینک شامل کریں",
    EnableEmployee: "کیا آپ اس ملازم کو فعال کرنا چاہتے ہیں؟",
    DisableEmployee: "کیا آپ اس ملازم کو غیر فعال کرنا چاہتے ہیں؟",
    Enable: "فعال کریں",
    Disabled: "غیر فعال",
    UpdateFamily: "خاندان کو اپ ڈیٹ کریں",
    AddFamily: "خاندان شامل کریں",
    AddEmployeesLinkage: "ملازمین کا تعلق شامل کریں",
    AllEmployees: "تمام ملازمین",
    FamilyInfo: "خاندان کی معلومات",
    Basic_Info: "بنیادی معلومات",
    Email_Configuration: "ای میل کنفیگریشن",
    Bank_Detail: "بینک ڈیٹیل",
    Education: "تعلیم",
    Emergency_Info: "اضطراری معلومات",
    Work_Experience: "کارِ تجربہ",
    Experience: "تجربہ",
    Rebate: "ریبیٹ",
    Salary: "تنخواہ",
    Leaves: "رخصتیں",
    Devices: "ڈیوائسز",
    basicInfo: "بنیادی معلومات",
    Appraisal: "تنقید",
    Family: "خاندان",
    Attachments: "منسلکہ",
    My_Team: "میری ٹیم",
    Employees_Linkage: "ملازمین کا تعلق",
    Emails: "ای میلز",
    Finance: "مالیت",
    Goal: "مقصد",
    RebateInfo: "ریبیٹ کی معلومات",
    UpdateRebate: "ریبیٹ اپ ڈیٹ کریں",
    AddRebate: "ریبیٹ شامل کریں",
    AddSalary: "تنخواہ شامل کریں",
    Statistics: "شماریات",
  },
  salary: {
    SalaryMembers: "تنخواہ وصول کنندگان",
    EffectiveDate: "مؤثر تاریخ",
    BasicSalary: "بنیادی تنخواہ",
    Allowances: "امداد",
    Allowance: "امداد",
    AllowanceDetail: "امداد کی تفصیل",
    IsTaxable: "کیا قابل ٹیکس ہے",
    Deduction: "کٹوتی",
    GrossSalary: "کل تنخواہ",
    CreateSalary: "تنخواہ بنائیں",
    SalaryDetail: "تنخواہ کی تفصیل",
    AddSalary: "تنخواہ شامل کریں",
    Increment: "اضافہ",
    Deductions: "کٹوتیاں",
    AllowanceType: "امداد کی قسم",
    EffectiveDateIsRequired: "مؤثر تاریخ درکار ہے",
    BasicSalaryIsRequired: "بنیادی تنخواہ درکار ہے",
    Negativesalaryisnotallowed: "منفی تنخواہ ممنوع ہے",
    Benefit: "فائدہ",
    noDataMessage: " - اب تنخواہ بنائیں!",
    EnterIncrementPercentage: "براہ کرم اضافہ فیصد درج کریں",
    salaryFilterTypeEnum: {
      [SalaryFilterTypeEnum.Salaries]: "تنخواہیں",
      [SalaryFilterTypeEnum.CreateByMe]: "میرے ذریعے بنائیں",
      [SalaryFilterTypeEnum.ForApproval]: "منظوری کے لئے",
    },
    statusEnum: {
      [EmployeeSalaryStatusEnum.InProcess]: "عمل میں ہے",
      [EmployeeSalaryStatusEnum.Approved]: "منظور شدہ",
      [EmployeeSalaryStatusEnum.Declined]: "رد کر دیا گیا",
      [EmployeeSalaryStatusEnum.Cancelled]: "منسوخ",
      [EmployeeSalaryStatusEnum.Hold]: "رکاوٹ",
      [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "دوسرے کی منظوری کا انتظار ہے",
    },
    SortEnum: {
      [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "تخلیق تاریخ - کم سے زیادہ",
      [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "تخلیق تاریخ - زیادہ سے کم",
      [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "اپ ڈیٹ تاریخ - کم سے زیادہ",
      [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "اپ ڈیٹ تاریخ - زیادہ سے کم",
      [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - کم سے زیادہ",
      [EmployeeSalaryFilterSortEnum.ReferenceNo]: "حوالہ نمبر"
    }

  },
  rewards: {
    PleaseEnterAwardName: "منظوری دینے والے",
    approvers: "منظوری دینے والے",
    createComplain: "شکایت کریں",
  },
  bonus: {
    Bonus: "بونس",
    createBonus: "بونس بنائیں",
    BonusTo: "بونس کو",
    CurrentSalary: "موجودہ تنخواہ",
    noDataMessage: "– اب بونس بنائیں!",
    PercentageShouldNotExceed: "فی صد صدی نہیں چھوڑنا چاہئے",
    bonusFilterTypeEnum: {
      [BonusFilterTypeEnum.All]: "سب بونس",
      [BonusFilterTypeEnum.CreatedByMe]: "میرے ذریعے بنائیں",
      [BonusFilterTypeEnum.ForApproval]: "منظوری کے لئے",
      [BonusFilterTypeEnum.MyBonus]: "میرا بونس",
    },
    statusEnum: {
      [BonusStatusEnum.InProcess]: "عمل میں ہے",
      [BonusStatusEnum.Approved]: "منظور ہے",
      [BonusStatusEnum.Declined]: "منسوخ ہے",
      [BonusStatusEnum.Cancelled]: "منسوخ ہے",
      [BonusStatusEnum.Inactive]: "غیر فعال ہے",
      [BonusStatusEnum.NotRequired]: "ضرورت نہیں ہے",
      [BonusStatusEnum.Resend]: "دوبارہ بھیجیں",
      [BonusStatusEnum.WaitingForOtherApproval]: "دوسری منظوری کا انتظار ہے",
      [BonusStatusEnum.Hold]: "رکاوٹ ہے",
      [BonusStatusEnum.Disbursed]: "تشہیر ہوگئی ہے",
    },
    SortEnum: {
      [BonusFilterSortEnum.CreateDateDesc]: "تخلیق تاریخ - نزولی",
      [BonusFilterSortEnum.CreateDateAsc]: "تخلیق تاریخ",
      [BonusFilterSortEnum.UpdateDateDesc]: "تازہ کاری کی تاریخ - نزولی",
      [BonusFilterSortEnum.UpdateDateAsc]: "تازہ کاری کی تاریخ",
      [BonusFilterSortEnum.ReferenceNoDesc]: "حوالہ نمبر - نزولی",
      [BonusFilterSortEnum.ReferenceNo]: "حوالہ نمبر",
      [BonusFilterSortEnum.Amount]: "رقم",
      [BonusFilterSortEnum.AmountDesc]: "رقم - نزولی",
      [BonusFilterSortEnum.Salary]: "تنخواہ",
      [BonusFilterSortEnum.SalaryDesc]: "تنخواہ - نزولی"
    }
  },
  chartOfAccount: {
    ChartOfAccount: "چارٹ آف اکاؤنٹ",
    CreateAccount: "اکاؤنٹ بنائیں",
    CreateChartOfAccount: "چارٹ آف اکاؤنٹ بنائیں",
    accountType: "اکاؤنٹ کی قسم",
    selectType: "قسم منتخب کریں",
    parentAccount: "ماں باپ حساب",
    selectParent: "ماں باپ منتخب کریں",
    EnterAccountType: "براہ کرم اکاؤنٹ کی قسم درج کریں",
    clossingBalance: "بندی بیلنس",
  },
  ledgerReports: {
    reports: "رپورٹس",
    selectAccount: "اکاؤنٹ منتخب کریں",
    selectDate: "تاریخ منتخب کریں",
    generateReport: "رپورٹ بنائیں",
    VoucherNo: "واچر نمبر",
  },
  requisitions: {
    requisition: "درخواستیں",
    MyRequisitions: "میری درخواستیں",
    createRequisition: "درخواست بنائیں",
    PleaseEnterRequisitionName: "براہ کرم درخواست کا نام درج کریں",
    EnterRequisitionName: "درخواست کا نام درج کریں",
    Budget: "بجٹ",
    EnterBudget: "بجٹ درج کریں",
    Dateofrequestexpire: "درخواست ختم ہونے کی تاریخ",
    noDataMessage: "– اب درخواستیں بنائیں!",
  },
  DefaultApprovers: {
    TypeEnum: {
      [DefaultApprovalTypeEnum.Expense]: "اخراجات",
      [DefaultApprovalTypeEnum.ExpenseFinancers]: "اخراجات فنانسرز",
      [DefaultApprovalTypeEnum.Travel]: "سفر",
      [DefaultApprovalTypeEnum.TravelAgent]: "سفر کرنے والا",
      [DefaultApprovalTypeEnum.Loan]: "قرض",
      [DefaultApprovalTypeEnum.Leave]: "رخصت",
      [DefaultApprovalTypeEnum.Asset]: "اثاثہ",
      [DefaultApprovalTypeEnum.Salary]: "تنخواہ",
      [DefaultApprovalTypeEnum.Payroll]: "پی رول",
      [DefaultApprovalTypeEnum.Reward]: "انعام",
      [DefaultApprovalTypeEnum.ResignationHr]: "استعفاء ایچ آر",
      [DefaultApprovalTypeEnum.ResignationAdmin]: "  انتظامیہ سے استعفاء",
      [DefaultApprovalTypeEnum.ResignationIt]: "آئی ٹی سے استعفاء",
      [DefaultApprovalTypeEnum.ResignationFinance]: "مالیہ سے استعفاء",
      [DefaultApprovalTypeEnum.ResignationExit]: "باہر کی طرف استعفاء",
      [DefaultApprovalTypeEnum.Requisition]: "درخواست",
      [DefaultApprovalTypeEnum.CustomApproval]: "اہلیت کی منظوری",
      [DefaultApprovalTypeEnum.Forms]: "فارمیں",
      [DefaultApprovalTypeEnum.Appraisals]: "تشہیرات",
      [DefaultApprovalTypeEnum.Promotion]: "ترقی",
      [DefaultApprovalTypeEnum.Warning]: "تنبیہ",
      [DefaultApprovalTypeEnum.Bonus]: "بونس",
      [DefaultApprovalTypeEnum.Complains]: "شکایات",
      [DefaultApprovalTypeEnum.Career]: "کیریئر",
      [DefaultApprovalTypeEnum.Quotations]: "اقتباسات",
      [DefaultApprovalTypeEnum.RequisitionFinal]: "آخری درخواست",
    },
  },
  NonMandatoryApprovers: {
    TypeEnum: {
      [NonMandatoryApprovalTypeEnum.Expense]: "اخراجات",
      [NonMandatoryApprovalTypeEnum.Travel]: "سفر",
      [NonMandatoryApprovalTypeEnum.Loan]: "قرض",
      [NonMandatoryApprovalTypeEnum.Leave]: "رخصت",
      [NonMandatoryApprovalTypeEnum.Asset]: "اثاثہ",
      [NonMandatoryApprovalTypeEnum.Salary]: "تنخواہ",
      [NonMandatoryApprovalTypeEnum.Payroll]: "پی رول",
      [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "اخراجات فنانسرز",
      [NonMandatoryApprovalTypeEnum.TravelAgent]: "سفر کرنے والا",
      [NonMandatoryApprovalTypeEnum.Reward]: "انعام",
      [NonMandatoryApprovalTypeEnum.ResignationHr]: "استعفاء ایچ آر",
      [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "استعفاء ایڈمن",
      [NonMandatoryApprovalTypeEnum.ResignationIt]: "استعفاء آئی ٹی",
      [NonMandatoryApprovalTypeEnum.ResignationFinance]: "استعفاء فنانس",
      [NonMandatoryApprovalTypeEnum.ResignationExit]: "استعفاء نکاس",
      [NonMandatoryApprovalTypeEnum.Requisition]: "درخواست",
      [NonMandatoryApprovalTypeEnum.Warning]: "تنبیہ",
      [NonMandatoryApprovalTypeEnum.Complain]: "شکایت",
      [NonMandatoryApprovalTypeEnum.Bonus]: "بونس",
      [NonMandatoryApprovalTypeEnum.Promotion]: "ترقی",
      [NonMandatoryApprovalTypeEnum.RequestForItem]: "مال کی درخواست",
      [NonMandatoryApprovalTypeEnum.CustomApproval]: "خود مخصوص منظوری",
      [NonMandatoryApprovalTypeEnum.Form]: "فارم",
      [NonMandatoryApprovalTypeEnum.Document]: "دستاویز",
      [NonMandatoryApprovalTypeEnum.Career]: "کیریئر",
      [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "آخری درخواست منظوری",
      [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "مال کی درخواست ایسٹ کنٹرولر",
      [NonMandatoryApprovalTypeEnum.Quotation]: "اقتباس",
      [NonMandatoryApprovalTypeEnum.QuotationClient]: "اقتباس کلائنٹ",
      [NonMandatoryApprovalTypeEnum.Appraisal]: "تشہیر",
    }
  },
  activityLog: {
    DeviceType: "آلہ کی قسم",
    DeviceToken: "آلہ ٹوکن",
    OSVersion: "OS ورژن",
    Device: "آلہ",
    IP: "آئی پی",
    Comment: "تبصرہ",
    DeviceName: "آلہ کا نام",
    LoginDate: "لاگ ان تاریخ",
  },
  ErrorMessage: {
    someThingWentWrong: "کچھ غلط ہو گیا ہے"
  },
  setting: {
    BasicInformation: "بنیادی معلومات",
    ChangePassword: "پاس ورڈ تبدیل کریں",
    Notifications: "اطلاعات",
    Mobile: "موبائل نمبر",
    About: "کے بارے میں",
    ExistingPassword: "موجودہ پاس ورڈ",
    EnterExistingPassword: "موجودہ پاس ورڈ درج کریں",
    NewPassword: "نیا پاس ورڈ",
    EnterNewPassword: "نیا پاس ورڈ درج کریں",
    ConfirmPassword: "پاس ورڈ کی تصدیق کریں",
    EnterConfirmPassword: "پاس ورڈ کی تصدیق درج کریں",
    Signature: "دستخط دستاویز",
    NoSignature: "براہ کرم دستخط بنائیں",
    ErrorSignature: "دستخط بنانے میں خرابی",
    NoSignatureSave: "محفوظ کرنے کے لئے کوئی دستخط نہیں",
    SignatureSaved: "دستخط محفوظ ہوگیا",
    SaveSignature: "دستخط محفوظ کریں",
    ClearSignature: "دستخط صاف کریں",
    SignatureCreated: "دستخط بنایا گیا",
    SignatureActive: "فعال",
    FailedSignature: "دستخط حاصل کرنے میں ناکام",
    BlockNotificationFeatureTypeEnum: {
      [NotificationBlockFeatureTypeEnum.Reward]: "انعام",
      [NotificationBlockFeatureTypeEnum.Feed]: "فیڈ",
      [NotificationBlockFeatureTypeEnum.Document]: "دستاویز",
      [NotificationBlockFeatureTypeEnum.Project]: "پروجیکٹ",
      [NotificationBlockFeatureTypeEnum.Task]: "کام",
      [NotificationBlockFeatureTypeEnum.WorkBoard]: "کام کی بورڈ",
      [NotificationBlockFeatureTypeEnum.Schedule]: "شیڈول",
      [NotificationBlockFeatureTypeEnum.Group]: "گروپ",
      [NotificationBlockFeatureTypeEnum.Messenger]: "میسنجر",
      [NotificationBlockFeatureTypeEnum.Expense]: "اخراجات",
      [NotificationBlockFeatureTypeEnum.Auction]: "مزاد",
      [NotificationBlockFeatureTypeEnum.Leave]: "رخصتی",
      [NotificationBlockFeatureTypeEnum.Travel]: "سفر",
      [NotificationBlockFeatureTypeEnum.Warning]: "انتباہ",
      [NotificationBlockFeatureTypeEnum.Complain]: "شکایت",
      [NotificationBlockFeatureTypeEnum.Bonus]: "انعام",
      [NotificationBlockFeatureTypeEnum.Promotion]: "ترقی",
      [NotificationBlockFeatureTypeEnum.Loan]: "قرض",
      [NotificationBlockFeatureTypeEnum.CustomApproval]: "اختیاری منظوری",
      [NotificationBlockFeatureTypeEnum.Form]: "فارم",
      [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "ملازم کی تنخواہ",
      [NotificationBlockFeatureTypeEnum.Payroll]: "تنخواہ کی شیڈول",
      [NotificationBlockFeatureTypeEnum.Career]: "کیریئر",
      [NotificationBlockFeatureTypeEnum.Requisition]: "درخواست",
      [NotificationBlockFeatureTypeEnum.Resignation]: "استعفیٰ",
      [NotificationBlockFeatureTypeEnum.Quotation]: "اقتباس",
      [NotificationBlockFeatureTypeEnum.Appraisal]: "قدرتی",
      [NotificationBlockFeatureTypeEnum.LeadManager]: "سربراہ منتظم",
      [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "مباحثہ بورڈ",
      [NotificationBlockFeatureTypeEnum.Pages]: "صفحات",
    }
  },
  emailConfiguration: {
    emailConfiHeader: "صارف ای میل کنفیگریشن",
    emailConfi: "صارف کا نام",
    password: "پاس ورڈ",
    enterEmailConfi: "صارف کا نام درج کریں",
    incomingPort: "آمدنی پورٹ",
    enterIncomingPort: "آمدنی پورٹ درج کریں",
    incomingServerAddress: "آمدنی سرور کا پتہ",
    enterIncomingServerAddress: "آمدنی سرور کا پتہ درج کریں",
    outgoingPort: "جاری پورٹ",
    enterOutgoingPort: "جاری پورٹ درج کریں",
    outgoingServerAddress: "جاری سرور کا پتہ",
    enterOutgoingServerAddress: "جاری سرور کا پتہ درج کریں",
    provider: "فراہم کنندہ",
    enterProvider: "فراہم کنندہ درج کریں",
    saveEmailConfi: "ای میل کنفیگریشن محفوظ کریں",
    addEmailConfi: "ای میل کنفیگریشن شامل کریں",
    name: "نام",
    clear: "صاف کریں",
  },
  assetsAllocation: {
    assetsAllocation: "اثاثے کی تقسیم",
    assests: "اثاثے",
    createAssetAllocation: "اثاثہ تقسیم بنائیں",
    handover: "حوالہ دینا",
    forMaintenance: "تعمیرات کے لئے",
    noResultFound: "کوئی نتیجہ نہیں ملا...",
    addAssetsAllocation: "اثاثے کی تقسیم شامل کریں",
    noDataMessage: "– اب اثاثے کی تقسیم بنائیں!",
    allocationForMe: "میرے لئے تقسیم",
    allocationApprovals: "تقسیم کی منظوریاں",
    deAllocation: "تقسیم کی حیثیت تبدیل کریں",
    liability: "ذمہ داری",
    capital: "مال",
    revenue: "آمدنی",
    expense: "اخراجات",
    costOfGoodsale: "فروخت کے اشیاء کی لاگت",
    assetsCategory: "اثاثے کی قسم",
    parentAccount: "ماں اکاؤنٹ",
    selectParent: "ماں اکاؤنٹ منتخب کریں",
    assetsList: "اثاثے کی فہرست",
    createAssests: 'اثاثے بنائیں',
    sno: 'سیریل نمبر',
    inventoryName: 'انوینٹری نام',
    inventoryValue: 'انوینٹری قیمت',
    SelectAssetsController: "اثاثے کا کنٹرولر منتخب کریں",
  },
  requestListItems: {
    noDataMessage: "– اب درخواست بنائیں!",
    requestForItems: "اشیاء کی درخواست",
    RequestForItemsApprovals: "اشیاء کی درخواست کی منظوریاں",
    AssetController: "اثاثے کا کنٹرولر",
    RequestItem: "اشیاء کی درخواست",
  },
  profile: {
    firstMenu: '  پہلا مینو آئٹم',
    secondMenu: ' دوسرا مینو آئٹم (غیر فعال)',
    thirdMenu: ' تیسرا مینو آئٹم (غیر فعال)',
    dangerItem: 'خطرناک آئٹم',
    NoPosition: "کوئی پوزیشن نہیں",
    NoInstitute: "کوئی ادارہ نہیں",
    NotFound: "نہیں ملا",
    University: "یونیورسٹی",
    School: "اسکول",
    Graduated: "گریجویٹ ہوگیا",
    CurrentlyWorkHere: "میں فی الحال یہاں کام کر رہا ہوں",
    Statistics: "شماریات",
    StorageStatistics: "ذخیرہ کے اعدادوشمار",
    TotalStorageUsed: "کل استعمال شدہ ذخیرہ",

  },
  /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
  status: {
    InProcess: "درحال پروسیس",
    Approved: "منظور شدہ",
    Declined: "منظور نہیں",
    Resend: "دوبارہ بھیجیں",
    Inactive: "غیر فعال",
    NotRequired: "ضرورت نہیں",
    Cancelled: "منسوخ شدہ",
    ApprovalRequired: "منظوری کی ضرورت ہے",
    Hold: "روک",
    WaitingForOtherApprover: "دوسرے منظور کنندگان کے لیے منتظر",
    Draft: "ڈرافٹ",
    Remark: "تبصرہ",
    Removed: "ہٹا دیا گیا"
  },
  approvalDictionaryLabel: {
    Approval: "منظوری",
    [ApprovalsModuleEnum.ExpenseExecutor]: "انجام دینے والا",
    [ApprovalsModuleEnum.ExpenseFinance]: "فنانس",
    [ApprovalsModuleEnum.ResignationHrApproval]: "انسداد ایچ آر",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "فنانس",
    [ApprovalsModuleEnum.ResignationItApproval]: "آئی ٹی",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "ایڈمن",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "دیگر منظوریاں",
    [ApprovalsModuleEnum.ResignationExitApproval]: "باہر نکلنے کا انٹرویو",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "رپورٹنگ ٹو",
    [ApprovalsModuleEnum.TravelApproval]: "سafar کی منظوریاں",
    [ApprovalsModuleEnum.TravelAgent]: "ایجنٹ",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "آخری منظوریاں",
    [ApprovalsModuleEnum.LoanApproval]: "قرض کی منظوریاں",
    [ApprovalsModuleEnum.AuctionApproval]: "قرض کی منظوریاں"

  },

  approvalDictionaryPlaceHolder: {
    Approval: "منظوری",
    [ApprovalsModuleEnum.ExpenseExecutor]: "انجام دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ExpenseFinance]: "فنانس سے منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ExpenseApproval]: "انفارمیشن سے منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationHrApproval]: "آئی ٹی منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "فنانس منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationItApproval]: "انسداد ایچ آر منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "ایڈمن منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "دیگر منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationExitApproval]: "باہر نکلنے کا انٹرویو منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "رپورٹنگ ٹو منظوری دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.TravelApproval]: "سفر کی منظوریاں دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.TravelAgent]: "سفر کی منظوریاں دینے والا ایجنٹ کا انتخاب کریں",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "آخری منظوریاں دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.LoanApproval]: "قرض کی منظوریاں دینے والے کا انتخاب کریں",
    [ApprovalsModuleEnum.AuctionApproval]: "قرض کی منظوریاں دینے والے کا انتخاب کریں"

  },

  /*** Define language write side ***/
  Direction: "rtl",
};
export default urdu;
