import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  responseMessage,
  responseMessageType,
} from "../../../../services/slices/notificationSlice";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import {
  AddAttendanceCheckInService,
  AddAttendanceService,
  GetAllAttendanceCheckInService,
  updateAttendanceCheckInStatusService,
  GetAllAttendanceByUserService,
  SaveAttendanceService,
  getAllAttendanceByBusinessService,
  getMonthlyAttendanceService,
} from "../services/service";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../sharedComponents/Notification/notificationHelper";

export const addAttendanceCheckIn = createAsyncThunk(
  "Attendance/addAttendanceCheckIn",
  async (request, { rejectWithValue, dispatch }) => {
    const response = await AddAttendanceCheckInService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage,
          },
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return null;
    }
  }
);

export const getAllAttendanceByUserAction = createAsyncThunk(
  "Attendance/getAllAttendanceByUser",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await GetAllAttendanceByUserService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage,
          },
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return null;
    }
  }
);

export const saveAttendanceAction = createAsyncThunk(
  "Attendance/SaveAttendance",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await SaveAttendanceService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage,
          },
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return null;
    }
  }
);

export const addAttendanceAction = createAsyncThunk(
  "Attendance/AddAttendance",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await AddAttendanceService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
          data: {
            message: response.errorMessage,
          },
        });
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return null;
    }
  }
);

export const getAllAttendanceCheckInAction = createAsyncThunk(
  "GetAllAttendanceCheckIn",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await GetAllAttendanceCheckInService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      res?.message &&
        dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: 2,
          })
        );
      return rejectWithValue(res.message);
    }
  }
);

export const updateAttendanceCheckInStatusAction = createAsyncThunk(
  "UpdateAttendanceCheckInStatus",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await updateAttendanceCheckInStatusService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      res?.message && dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllAttendanceByBusinessAction = createAsyncThunk(
  "GetAllAttendanceByBusiness",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllAttendanceByBusinessService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      res?.message && dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getMonthlyAttendanceAction = createAsyncThunk(
  "attendance/getMonthlyAttendance",
  async (data, { dispatch, getState, rejectWithValue }) => {
    console.log("dddddddd");
    const res = await getMonthlyAttendanceService(data);
    if (res.responseCode === responseCode.Success) {
      return res;
    } else {
      res?.message &&
        dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: 2,
          })
        );
      return rejectWithValue(res.message);
    }
  }
);

