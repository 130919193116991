import { responseCode } from "../../../../services/enums/responseCode";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import {
  getAllContractPagingService,
  getAllContractService,
  getContractByIdService,
  saveContractService,
} from "../service/service";
import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";

export const saveContractAction = createAsyncThunk(
  "contracts/saveContractAction",
  async (args, { dispatch, getState }) => {
    const res = await saveContractService(args);
    if (res?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Contract Created Successfully",
          type: "success",
          duration: 2,
        })
      );

      return res.data;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return isRejectedWithValue(res.message);
    }
  }
);
export const getContractByIdAction = createAsyncThunk(
  "contracts/getContractById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const response = await getContractByIdService(data);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllContractsAction = createAsyncThunk(
  "contracts/getAllContract",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { dispatch, getState, rejectWithValue }
  ) => {
    if (segmentType === SegmentTypeEnum.List) {
      const response = await getAllContractPagingService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    } else {
      const response = await getAllContractService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  }
);
