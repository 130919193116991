import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import { addContactNumberService, BusinessNumberAuthServices ,getAllBusinessNumberContactService, requestForACallServices } from "../services/service";


export const BusinessNumberAuthAction = createAsyncThunk(
  "User/BusinessNumberAuth",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await BusinessNumberAuthServices(data);
    if (res.responseCode === responseCode.Success) {
      if (res?.data?.token === "") {
        // message.error("Vonage token is null");
        return rejectWithValue("Vonage token is null");
      }
      return res.data;
    } else {
      message.error(res.message);
      return rejectWithValue(res.message);
    }
  }
);


export const addContactNumberAction = createAsyncThunk(
  "User/addContactNumberAction",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const res = await addContactNumberService(payload);
     if(res.responseCode === responseCode.Success){
      message.success('Contact added successfully!');
        return res
     }
     else
     {
        rejectWithValue({res})
     }
  }
);



export const getAllBusinessNumbeerAction = createAsyncThunk(
  "User/getAllBusinessNumbeerAction",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await getAllBusinessNumberContactService(data);
    if(res.responseCode === responseCode.Success){
        return res.data
     }
     else
     {
        rejectWithValue(res)  
     }
  }
);
