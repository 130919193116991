import React, { useEffect, useState } from "react";
import InvoiceAndPaymentDetailCard from "./InvoiceDetail";
import PaymentGatway from ".";
import { useDispatch } from "react-redux";
import { GetInvoiceForPaymentAction } from "../store/action";
import { InvoiceStatusEnumValue } from "../enum/InvoiceStatusEnum";
import { STRINGS } from "../../../../utils/base";


const InvoiceAndPaymentCardWrapper = ({invoiceId = STRINGS.DEFAULTS.guid}) =>{


     // ---- STATE----
     const [state  , setState]=  useState({
        invoiceData:{},
        isInvoiceDataLoading:false,
        isShowInvoiceDetail:false,
    })


    const dispatch = useDispatch();

    useEffect(()=>{
            dispatch(GetInvoiceForPaymentAction(invoiceId)).then(({payload}) =>{
                setState({
                    ...state,
                    isInvoiceDataLoading:false,
                    invoiceData:payload?.data
                })
            })
    },[])


    return (
        <>
            <InvoiceAndPaymentDetailCard data={state.invoiceData}/>
            {state?.invoiceData?.invoice?.status !== InvoiceStatusEnumValue.Completed &&  <PaymentGatway data={state.invoiceData}/>}
        </>
    )
}
export default InvoiceAndPaymentCardWrapper;