import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { addRealTimePost, commentCountatFeed, feedSlice, newFeedPollVoteOut } from "../main/features/feed/store/slice";
import {adCommentfromSocket, addReactionfromSocket, handleCommentOutSocket} from "../main/features/Comment/store/commentSlice";
import { updateMessageDeliver } from "../main/features/Messenger/store/actions";
import {
  handleConversationIndexing,
  handleStatusUpdate,
  handleUpdateTypingStatus,
  handleUserOnlineStatus,
  receiveChatMessage,
} from "../main/features/Messenger/store/messengerSlice";
import { servicesUrls } from "./services/baseURLS";
import { openNotification } from "./Shared/store/slice";
import { MESSENGER_ENUMS } from "../main/features/Messenger/utils/Constant";
import { logout } from "./base";
import { NOTIFICATION_ENUMS } from "../main/features/notifiation/utils/enums";
import { handleUpdateNotificationCount , updateNotificationCount} from "../main/features/notifiation/store/slice";
import { pushCommentQueue } from "../main/features/realTimeEvents/store/slice";
import { onSaveComment } from "../main/features/feed/store/actions";
import {handleNewApprovalRemark} from "../main/features/approval/store/action";
import { commentCountatDiscussionBoard } from "../main/features/discussionBoard/store/slice";
import { commentCountatUserTask } from "../main/features/task/store/taskSlice";
import { checkInNotification } from "../main/features/attendance/store/slice";
import {handleNewAuctionBid} from "../main/features/marketPlace/store/slice"
const handleNotificationDetail = (notificationItem, dispatch) => {
  switch (notificationItem.featureType) {
    // working in process
    case NOTIFICATION_ENUMS.FEATURE_TYPE.FEED:
      switch (notificationItem.type) {
        case NOTIFICATION_ENUMS.NOTIFICATION_TYPE.NewFeed:
        case NOTIFICATION_ENUMS.NOTIFICATION_TYPE.NewGroupFeed:
        case NOTIFICATION_ENUMS.NOTIFICATION_TYPE.NewProjectFeed:
          // dispatch(addRealTimePost(notificationItem.details))
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }
};

export class InitializeSocket {
  connection;
  classInstance;
  #dispatch;
  user;
  constructor(dispatch, userSlice) {
    this.#dispatch = dispatch;
    this.user = userSlice;
    this.initializeConnection();
  }

  static getInstance = (dispatch, user) => {
    if (!this.classInstance) {
      this.classInstance = new InitializeSocket(dispatch, user);
      return this.classInstance;
    } else return this.classInstance;
  };

  startPingInterval = () => {
    setInterval(() => {
      this.pingSocketConnection();
    }, 30000); 
  }

  initializeConnection = async () => {
    const URL = `${servicesUrls.master}hub/notificationHub`;
    let connection = new HubConnectionBuilder()
      .withUrl(URL, { accessTokenFactory: () => this.user.token })
      .configureLogging(LogLevel.Information)
      .build();
    this.connection = connection;
    // Start connection here...
    await connection.start().then(() => { 
      this.startPingInterval(); 
    });
    this.#onAppLoad();
  };

  #onAppLoad = async () => {
    // Receive Message Listner Here
    this.connection.on("messageOut", (data) => {
      console.log(data, "messageOut mySocket");
      if (data.creator.id !== this.user.user.id) {
        this.#dispatch(
          updateMessageDeliver({
            chatId: data.chatId,
            msgIds: [data.id],
            // the reason of hardly pass deliver status is
            // this listner will only fire of delivered case
            status: MESSENGER_ENUMS.MESSAGE_STATUS.DELIVERED,
          })
        );
        this.#dispatch(
          openNotification({
            message: `${data.creator.name} sent you a message ${data.message}`,
            playSound: true,
            avatarName: data.creator.name,
            avatarImage: data.creator.image,
          })
        );
      }
      this.#dispatch(receiveChatMessage(data));
    });

    this.connection.on("userActiveStatus", (data) => {
      if (!!data.status) {
        this.#dispatch(
          openNotification({
            message: `${data.user.name} is online`,
            avatarName: data.user.name,
            avatarImage: data.user.image,
            style: { backgroundColor: "#ffb70b", color: "black" },
          })
        );
      }
      this.#dispatch(handleUserOnlineStatus(data));
    });

    this.connection.on("notificationOut", (data) => {
      console.log(data, "notificationOut");
      handleNotificationDetail(data, this.#dispatch);
      this.#dispatch(
        openNotification({
          currentNotification: data,
          message: `${data.fromUser.name} ${data.message}`,
          playSound: true,
          avatarName: data.fromUser.name,
          avatarImage: data.fromUser.image,
          style: { backgroundColor: "#64c4b2" },
        })
      );
      this.#dispatch(updateNotificationCount(data))
    });

    this.connection.on("systemNotificationOut", (data) => {
      console.log(data, "systemNotification");

      this.#dispatch(
          openNotification({
            currentNotification: data,
            message: `${data.fromUser.name} ${data.message}`,
            playSound: true,
            avatarName: data.fromUser.name,
            avatarImage: data.fromUser.image,
            style: { backgroundColor: "#ffb70b", color: "black" },
          })
      );
    });

    this.connection.on("navbarNotificationCount", (data) => {
      console.log("navbarNotificationCount", data);
      this.#dispatch(handleUpdateNotificationCount(data))
    });
    // this.connection.on("newCommentOut", (data) => {
    //   console.log("navbarNotificationCount", data);
    //   this.#dispatch(adCommentfromSocket(data))
    // });

    this.connection.on("newFeedPollVoteOut",(data) => {
      console.log(data,"pool Vote",this.user.user)
      if(data.createBy!==this.user.user.id){
      this.#dispatch(newFeedPollVoteOut(data));}
    });
    
    this.connection.on("ConversationOut", (data) => {
      this.#dispatch(handleConversationIndexing(data));
    });
    this.connection.on("newApprovalRemarkOut", (data) => {
      console.log(data,"INIT SOCEKT");
      this.#dispatch(handleNewApprovalRemark(data));
    });
    this.connection.on("newReactionOut", (data) => {
        console.log("newReactionOut", data);
        // this.#dispatch(feedReactionCount(data))
        this.#dispatch(addReactionfromSocket(data))
      });

      this.connection.on("userCheckIn", (data) => {
        console.log("userCheckIn", data);
        this.#dispatch(checkInNotification(data))
      });

    this.newCommentListner();


    // this.connection.on("chatMessageStatusOut", data => {
    // 	console.log(data, "chatMessageStatusOut")
    // 	if (data) {
    // 		data.forEach((messageItem) => this.#dispatch(handleStatusUpdate(messageItem)))
    // 	}
    // });

    this.connection.on("chatTypingStatus", (chatId, status) => {
      console.log(chatId, status, "chatMessageTypingStatus");
      this.#dispatch(
        handleUpdateTypingStatus({ chatId: chatId, typingStatus: status })
      );
    });

    this.connection.on("newAuctionOffer", (data) => {
      console.log("newAuctionOffer =====auction", data);
      this.#dispatch(handleNewAuctionBid(data))
    });

    this.connection.on("logoutDevice", (data) => {
      console.log("logoutDevice", data);
      logout();
    });

    // this.connection.on("newApprovalRemarkOut", data => {
    // 	console.log(data, "newApprovalRemarkOut")
    // });
    
    // this.connection.on("chatMessageStatusOut", data => {
    // 	console.log(data, "chatMessageStatusOut")
    // 	if (data) {
    // 		data.forEach((messageItem) => this.#dispatch(handleStatusUpdate(messageItem)))
    // 	}
    // });
  };

  chatMessageTypingAction = async (chatId, type) => {
    console.log("chatMessageTypingStatus", "payload", chatId, type);
    this.connection.invoke("chatMessageTypingStatus", chatId, type)
  };

  newAuctionAction = async (auctionId) => {
    console.log("=====auction id", auctionId, this.connection.state);
    this.connection.invoke("connectAuctionDetail", auctionId)
    .then(() => {
        console.log("=====auction Invoke successful");
    })
    .catch(err => {
        console.error("=====auction Invoke failed", err);
    }); 
   };

   pingSocketConnection = async () => {
    console.log("ping");
    this.connection.invoke("ping")
    .then(() => {
        console.log("ping");
    })
    .catch(err => {
        console.error("ping failed");
    }); 
   };

  newFeedListner = (callBack) => {
    this.connection.on("newFeedOut", callBack);
  };
  newFeedListnerRemove = () => {
    this.connection.off("newFeedOut");
  };
  newCommentListner = (callBack) => {
    //this.connection.on("newCommentOut", callBack);
    this.connection.on("newCommentOut", (data) => {
      // this.#dispatch(pushCommentQueue(data))
      console.log("dataaaaa",data)

      this.#dispatch(adCommentfromSocket(data))
      this.#dispatch(commentCountatFeed(data))
      this.#dispatch(commentCountatDiscussionBoard(data));
      this.#dispatch(commentCountatUserTask(data));
    });
  };
  newCommentListnerRemove = () => {
    this.connection.off("newCommentOut");
  };

/*  newApprovalRemarklistner = (callback) =>{
    this.connection.on("newApprovalRemarkOut",callback)
  }

  newApprovalRemarkRemove  = () =>{
    this.connection.off("newApprovalRemarkOut")
  } */
}
