import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import Form from "./form";
import { Table } from "../../../sharedComponents/customTable";
import { tableColumn } from "./tableColumn";
import { addQuickEmployee } from "../store/action";
import "./style.css";
import { clearItem, editItem, quickAddClose } from "../store/slice";
import UpdateForm from "./updateForm";
import { QuickAddWrapper } from "./quickAddWrapper";

const QuickAdd = ({ isOpen, onClose = () => {} }) => {

  return (
    <>
      <Modal
        visible={isOpen}
        width={1150}
        destroyOnClose={true}
        className="QuickAddModal"
        footer={false}
        onCancel={() => {
          onClose(false);
        }}
      >
      <QuickAddWrapper onClose={onClose}/>
      </Modal>
    </>
  );
};

export default QuickAdd;
