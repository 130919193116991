import { createSlice } from "@reduxjs/toolkit";
import {
  getAllContractsAction,
  getContractByIdAction,
  saveContractAction,
} from "./action";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

const initialState = {
  data: null,
  newItem: null,
  newCountFilterButton: null,
};

 const ContractsSlice = createSlice({
  name: "contracts",
  initialState: initialState,
  reducers: {
    handleResetState: (state, { payload }) => {
        state.data = [];
      },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined") delete state?.data?.[identifier];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContractsAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
        }
      })
      .addCase(saveContractAction.fulfilled, (state, { payload }) => {
        console.log("payload--",payload)
        state.newItem = payload;
      })
      .addCase(getContractByIdAction.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;

        const data = action.payload ?? [];

        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      });
  },
});

export const { handleNewItem ,handleResetState  ,removeIdentifier} = ContractsSlice.actions;
export default ContractsSlice.reducer;
