import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createGuid } from '../../../../../../../utils/base';
import { Button, Form, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { getAllEmployees } from '../../../../../employee/store/actions';
import Avatar from '../../../../../../sharedComponents/Avatar/avatarOLD';
import MemberSelect from '../../../../../../sharedComponents/AntdCustomSelects/SharedSelects/MemberSelect';
import {
  sharePostOnChat,
} from '../../../../../Messenger/store/actions';
import {
  sharePostOnFeed,
} from '../../../../store/actions';
import { feedSlice } from '../../../../store/slice';
import { LanguageChangeContext } from '../../../../../../../utils/localization/localContext/LocalContext';
import { dictionaryList } from '../../../../../../../utils/localization/languages';
import SuggestionBox from '../../../../../../sharedComponents/SuggestionBox/View/SuggestionBox';

function PostShareModal({
  isOpen = false,
  shareType = '',
  handleCancel,
  postId = '',
  content = ""
}) {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employeeSlice.employees);
  const [firstTimeEmpData, setFirstTimeEmpData] = useState([]);
  const [isFirstTimeDataLoaded, setIsFirstTimeDataLoaded] = useState(false);
  const [value, setValue] = useState([]);
  const [membersfromSuggestionBox, setmembersfromSuggestionBox] = useState([]);
  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed, sharedLabels } = dictionaryList[userLanguage];


  console.log(value, "valuevalue")
  const handleShareOnChat = () => {
    let payload = {
      members: membersfromSuggestionBox.map((mem) => {
        return {
          memberId: mem.id,
        };
      }),
      message: `${window.location.href}newsFeedDetails/${postId}`,
      id: createGuid(),
      messageType: 1,
      attachments: [],
    };
    dispatch(sharePostOnChat(payload));
    setValue([]);
    handleCancel();
  };
  const handleShareOnFeed = async () => {
    dispatch(
      feedSlice.actions.onPostTitleTextChange({
        value: content,
      })
    );
    dispatch(
      sharePostOnFeed({
        newsFeed,
        referenceType: 4,
        referenceId: membersfromSuggestionBox?.[0]?.id,
      })
    );
    handleCancel();
  };
  const handleChange = (e) => {
    setValue(e);
  };
  const handleShare = () => {
    if (membersfromSuggestionBox.length > 0) {
      if (shareType === 'Chat') handleShareOnChat();
      else if (shareType === 'Feed') handleShareOnFeed();
    }
  };
  const fetchEmployees = (text, pgNo, pgSize) => {
    dispatch(
      getAllEmployees({
        text,
        pgNo,
        pgSize: 20,
      })
    );
  };
  useEffect(() => {
    if (employees.length > 0 && !isFirstTimeDataLoaded) {
      setIsFirstTimeDataLoaded(true);
      setFirstTimeEmpData(employees);
    }
  }, [employees]);

  useEffect(() => {
    fetchEmployees('', 0, 20);
  }, []);


  return (
    <Modal
      open={isOpen}
      onOk={(e) => { }}
      onCancel={handleCancel}
      footer={false}
      closeIcon={<div />}
      className="ApproverModal"
      width={'460px'}
      destroyOnClose={true}
    >
      <Form.Item
      >
        <SuggestionBox
          initialData={membersfromSuggestionBox}
          isMultiSelect={false}
          callback={(item) => {
            setmembersfromSuggestionBox(item);
          }}
        />
      </Form.Item>
      {/* <MemberSelect
        style={{ marginBottom: '0px' }}
        data={firstTimeEmpData}
        selectedData={handleChange}
        canFetchNow={isFirstTimeDataLoaded}
        fetchData={fetchEmployees}
        placeholder={sharedLabels.SelectEmployee}
        isObject={true}
        loadDefaultData={false}
        optionComponent={(opt) => {
          return (
            <>
              <Avatar
                name={opt.name}
                src={opt.image}
                round={true}
                width={'30px'}
                height={'30px'}
              />
              {opt.name}
            </>
          );
        }}
        dataVal={value}
        name="approvers"
        showSearch={true}
      /> */}
      <Button className="sharePostBtn drawerBtn" onClick={handleShare}>
        {sharedLabels.ShareNow}
      </Button>
    </Modal>
  );
}

export default PostShareModal;
