import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  getTeamsAction,
  getRewardsAction,
  getCourseAction,
  getAllLoanAction,
  getAllComplainAction,
  getAllWarningAction,
  getAllLeaveAction,
  getAllCheckInAction,
  getAppraisalsAction,
  getDeviceInfoAction,
  addTeamMemberAction,
  getAllTeamRewardByuserId,
  getAllTeamWarningsByuserId,
  getAllTeamComplainsByuserId,
  getAllTeamLeavesByuserId,
  getAllTeamLoanByuserId,
  getAllTeamAppraisalsByuserId,
  getAllActivityLogAction,
  getAllDeviceAction,
  getAllDevicePagingAction,
  getAllActivityLogPagingAction,
} from "./action";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

const initialState = {
  teams: [],

  team: {
    rewardsdetails: [],
    coursedetails: [],
    loandetails: [],
    appraisalsdetails: [],
    complaindetails: [],
    warningdetails: [],
    leavedetails: [],
    checkIndetails: [],
    devicedetails: [],
    allactivitylogdetails: [],
    teamDeviceTableData: [],
    totalRecords: 0,
    teamActivityLogData: [],


  },
  userDetail: {
    rewardsInfo: [],
    warningsInfo: [],
    complainsInfo: [],
    LeavesInfo: [],
    LoanInfo: [],
    AppraisalsInfo: [],
    infoLoading: false
  },

  loader: false,
  success: false,
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    handleResetState: (state, { payload }) => {
      state.teams = [];
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTeamRewardByuserId.fulfilled, (state, { payload }) => {
        state.userDetail.rewardsInfo = payload?.data;
        state.userDetail.infoLoading = false

      })
      .addCase(getAllTeamWarningsByuserId.fulfilled, (state, { payload }) => {
        state.userDetail.warningsInfo = payload?.data;
        state.userDetail.infoLoading = false

      })
      .addCase(getAllTeamComplainsByuserId.fulfilled, (state, { payload }) => {
        state.userDetail.complainsInfo = payload?.data;
        state.userDetail.infoLoading = false

      })
      .addCase(getAllTeamLeavesByuserId.fulfilled, (state, { payload }) => {
        state.userDetail.LeavesInfo = payload?.data;
        state.userDetail.infoLoading = false

      })
      .addCase(getAllTeamLoanByuserId.fulfilled, (state, { payload }) => {
        state.userDetail.LoanInfo = payload?.data;
        state.userDetail.infoLoading = false

      })
      .addCase(getAllTeamAppraisalsByuserId.fulfilled, (state, { payload }) => {
        state.userDetail.AppraisalsInfo = payload?.data;
        state.userDetail.infoLoading = false

      })
      .addCase(getTeamsAction.fulfilled, (state, action,) => {
        const segmentType = action?.meta?.arg?.segmentType;
        {
          segmentType === SegmentTypeEnum.Grid
            ? (state.teams = [...state.teams, ...action?.payload?.data])
            : (state.teams = [...action?.payload?.data]);
        }
        state.loader = false;
        state.success = true;
      })

      .addCase(getAllDevicePagingAction.fulfilled, (state, action) => {
        console.log(action, "action")
        state.team.teamDeviceTableData = action.payload.data;
        state.team.totalRecords = action.payload.totalRecords;

      })

      .addCase(getAllActivityLogPagingAction.fulfilled, (state, action) => {
        console.log(action, "action")
        state.team.teamActivityLogData = action.payload.data;
        state.team.totalRecords = action.payload.totalRecords;

      })
      .addCase(getAllDeviceAction.fulfilled, (state, { payload }) => {
        state.team.devicedetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(addTeamMemberAction.fulfilled, (state, { payload }) => {
        state.teams = [...state.teams, payload];
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllActivityLogAction.fulfilled, (state, { payload }) => {
        console.log(payload, "aaaa")
        state.team.allactivitylogdetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getRewardsAction.fulfilled, (state, { payload }) => {
        state.team.rewardsdetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getCourseAction.fulfilled, (state, { payload }) => {
        state.team.coursedetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAppraisalsAction.fulfilled, (state, { payload }) => {
        state.team.appraisalsdetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllLoanAction.fulfilled, (state, { payload }) => {
        state.team.loandetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllComplainAction.fulfilled, (state, { payload }) => {
        state.team.complaindetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllWarningAction.fulfilled, (state, { payload }) => {
        state.team.warningdetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllLeaveAction.fulfilled, (state, { payload }) => {
        state.team.leavedetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllCheckInAction.fulfilled, (state, { payload }) => {
        state.team.checkIndetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addMatcher(isPending(...[addTeamMemberAction]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getAllTeamRewardByuserId, getAllTeamWarningsByuserId, getAllTeamComplainsByuserId, getAllTeamLeavesByuserId, getAllTeamLoanByuserId, getAllTeamAppraisalsByuserId]), (state) => {
        state.userDetail.infoLoading = true
      })
      .addMatcher(isRejected(...[addTeamMemberAction]), (state) => {
        state.loader = false;
      })
      .addMatcher(isRejected(...[getAllTeamRewardByuserId, getAllTeamWarningsByuserId, getAllTeamComplainsByuserId, getAllTeamLeavesByuserId, getAllTeamLoanByuserId, getAllTeamAppraisalsByuserId]), (state) => {
        state.userDetail.infoLoading = false
      });
  },
});
export const {
  handleNewItem,
  removeIdentifier,
  handleResetState,
} = teamSlice.actions;
export default teamSlice.reducer;
