import { Segmented } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import "../style/index.css";
import ApprovalWrapper from "../components/ApprovalWrapper";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { ApprovalDictionary } from "../localization";
import { ApprovalStatus } from "../enums";
import { STRINGS } from "../../../../../utils/base";
// import { dictionaryList } from "../../../utils/localization/languages";
import { dictionaryList } from "../../../../../utils/localization/languages"

function Approval({
  title = "",
  data = [],
  module,
  reference,
  status,
  referenceType = 0,
  referenceTypeID = STRINGS.DEFAULTS.guid,
  notRequiredMemberId = [],
  itemCreator = {},
  itemDetail = {},
}) {
  console.log(data, "dtaaa-->");


  const { userLanguage } = useContext(LanguageChangeContext);
  const { Direction } = dictionaryList[userLanguage];
  console.log(module, notRequiredMemberId, "notRequiredMemberIdnotRequiredMemberId");
  return (
    <div className="approval" style={{ direction: Direction }}>
      <div className="approval__header">
        {/* <Segmented
          onChange={(value) => {}}
          options={[
            {
              icon: <BarsOutlined />,
            },
            {
              icon: <AppstoreOutlined />,
            },
          ]}
        /> */}
      </div>

      <div className="approval__body !p-0">
        <ApprovalWrapper
          title={title}
          data={data}
          module={module}
          reference={reference}
          status={status}
          referenceType={referenceType}
          referenceTypeID={referenceTypeID}
          notRequiredMemberId={notRequiredMemberId}
          itemCreator={itemCreator}
          itemDetail={itemDetail}

        />
      </div>
    </div>
  );
}

export default Approval;
