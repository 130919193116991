import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import {
  getAllDiscussionBoard,
  addDiscussionBoard,
  getDiscussionBoardByIdAction,
  updateDiscussionBoardStatus,
  updateDiscussionBoard,
  addDiscussionBoardMember,
  removeDiscussionBoardMember
} from "./action.js";
import { STRINGS } from "../../../../utils/base.js";

const initialState = {
  boards: [],
  status: {},
  success: false,
  boardStatus: {},
  boardDetail: {},
  loadingData: false,
  loader: false,
  createLoader: false,
  closeModal: false,
};

const DiscussionBoardSlice = createSlice({
  name: "DiscussionBoard",
  initialState,
  reducers: {
    handleResetBoards: (state, { payload }) => {
      state.boards = [];
      state.boardDetail = [];
    },
    readCountforDiscussionBoard: (state, { payload }) => {
      const id = payload
      const currentState = current(state);
      if (currentState.boards !== null) {

        const keys = Object.keys(currentState.boards);
        let current = [...currentState.boards];
        const index = current.findIndex((y) => y.id === id);
        if (index !== -1) {
          const updateCurrent = [...current];
          let referenceItem = updateCurrent[index];
          referenceItem = {
            ...referenceItem,
            notificationCount: 0,
          };
          updateCurrent[index] = referenceItem;
          current = [...updateCurrent];
        }

        state.boards = current;
      }

    },
    handleNotificationNewItem: (state, { payload }) => {
      const currentState = current(state);
      if (currentState.boards !== null) {

        const keys = Object.keys(currentState.boards);
        let current = [...currentState.boards];
        const index = current.findIndex((y) => y.id === payload.featureId);
        if (index !== -1) {
          const updateCurrent = [...current];
          let referenceItem = updateCurrent[index];
          referenceItem = {
            ...referenceItem,
            notificationCount: referenceItem.notificationCount + 1
          };
          updateCurrent[index] = referenceItem;
          current = [...updateCurrent];
        }

        state.boards = current;
      }

    },
    commentCountatDiscussionBoard: (state, { payload }) => {
      try {
        const { comment } = payload;
        const { parentId, referenceId } = comment;

        const currentState = current(state);
        if (parentId === STRINGS.DEFAULTS.guid) {
          if (currentState.boards !== null) {

            const keys = Object.keys(currentState.boards);
            let current = [...currentState.boards];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updateCurrent = [...current];
              let referenceItem = updateCurrent[index];
              const count = referenceItem.commentCount + 1;
              referenceItem = {
                ...referenceItem,
                commentCount: count,
              };
              updateCurrent[index] = referenceItem;
              current = [...updateCurrent];
            }

            state.boards = current;
          }
        }
      } catch (e) {
        console.log(e, "error===");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDiscussionBoard.fulfilled, (state, action ) => {
        let {payload} = action;
        state.loadingData = false;
          state.boards = action?.meta?.arg.pageNo === 1 ? payload?.data : [...state.boards , ...payload.data];
      })
      .addCase(addDiscussionBoard.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.createLoader = false;
        state.success = true;
        if (payload.responseCode === responseCode.Success)
          state.boards = [payload.data, ...state.boards];
      })
      .addCase(updateDiscussionBoard.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.boards.findIndex(item => item.id === data.id);
        let newData = {
          ...state.boards[index],
          members: state.boards[index].members,
          name: data.name,
          description: data.description,
          privacyId: data.privacyId,
        };
        state.boards[index] = newData
      })
      .addCase(addDiscussionBoardMember.fulfilled, (state, { payload }) => {
        const { data } = payload;
        data.forEach(boardUpdate => {
          const index = state.boards.findIndex(board => board.id === boardUpdate.discussionBoardId);

          if (index !== -1) {
            // Add the new member to the existing members array
            let updatedBoard = {
              ...state.boards[index],
              members: [...state.boards[index].members, { member: boardUpdate.member, memberId: boardUpdate.member?.id }]
            };
            // Update the board at the found index
            state.boards[index] = updatedBoard;
          }
        });
      })

      .addCase(removeDiscussionBoardMember.fulfilled, (state, action) => {
        const { id, memberId } = action.meta.arg;

        if (Array.isArray(state.boards) && state.boards.length) {
          state.boards = state.boards.map(board => {
            if (board.id === id) {
              let newMembers = board.members.filter(member => member.memberId !== memberId);
              // Return the updated board
              return { ...board, members: newMembers };
            }
            return board;
          });
        }
      })

      .addCase(updateDiscussionBoardStatus.fulfilled, (state, { payload }) => {
        state.loader = false;
        const newStatus = payload.data.status;
        state.boards = state.boards.map((board) => {
          if (board.id === payload.data.id) {
            return { ...board, status: newStatus };
          }
          return board;
        });
      })
      .addCase(getDiscussionBoardByIdAction.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        state.boardDetail = { ...state.boardDetail, [identifier]: action?.payload.data };
      })
      .addMatcher(isPending(...[addDiscussionBoard]), (state) => {
        state.loader = true;
        state.createLoader = true;
      })
      .addMatcher(isPending(...[getDiscussionBoardByIdAction]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getAllDiscussionBoard]), (state) => {
        state.loadingData = true;
        state.loader = true;
      })
      .addMatcher(
        isRejected(
          ...[
            getAllDiscussionBoard,
            addDiscussionBoard,
            getDiscussionBoardByIdAction,
          ]
        ),
        (state) => {
          state.loader = false;
          state.loadingData = false;
          state.createLoader = false;
        }
      );
  },
});

export const {
  handleResetBoards,
  commentCountatDiscussionBoard,
  readCountforDiscussionBoard,
  handleNotificationNewItem
} = DiscussionBoardSlice.actions;
export default DiscussionBoardSlice.reducer;
