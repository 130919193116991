import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { CareerInterviewStatusEnum } from "../../../main/features/careers/utils/CareerInterviewStatusEnum";
import { CareerFilterSortEnum } from "../../../main/features/careers/utils/careerFilterSortEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerLevelEnum } from "../../../main/features/careers/utils/careerLevelTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { JobShiftTypeEnums } from "../../../main/features/careers/utils/jobShiftTypeEnum";
import { JobTypeEnums } from "../../../main/features/careers/utils/jobTypeEnum";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterSortEnum } from "../../../main/features/leave/utils/leaveFilterSortEnum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { LoanFilterSortEnum } from "../../../main/features/loan/utils/loanFilterSortEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterSortEnum } from "../../../main/features/promotion/utils/PromotionFilterSortEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterSortEnum } from "../../../main/features/resignation/utils/ResignationFilterSortEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationPurposeEnum } from "../../../main/features/resignation/utils/resignationPurposeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { TravelFilterSortEnum } from "../../../main/features/travel/utils/travleFilterSortEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums"
import { InputFieldTypes } from "../../../main/features/quotation/utils/quotationInputFieldTypes";
import { ProjectFeatureEnums } from "../../Shared/enums/projectFeatureEnum";

const french = {

    sharedLabels: {
        SelectLanguage: "Select Language",
        English: "English ",
        Turkish: "Turkish",
        Hindi: "Hindi",
        Urdu: "اردو",
        Arabic: "عربي",
        czech: "Czech",
        russian: "Russian",
        french: "French",
        spanish: "Spanish",
        italian: "Italian",
        theme: "Thème",
        awardTo: "Attribuer à",
        inprogress: "En cours",
        filter: "Filtrer",
        approvals: "Approbations",
        approvers: "Approbateurs",
        members: "Membres",
        Approvers: "Approbateurs",
        resignations: "Démissions",
        Description: "Description",
        Descriptionisrequired: "Description requise!",
        WriteDescriptionhere: "Écrire la description ici...",
        enterDescription: "Entrer la description",
        pleseEnterDescription: "Veuillez entrer la description",
        pleaseEnterGrade: "Veuillez entrer la note",
        manager: "Gestionnaire",
        requiredMessageManager: "Veuillez sélectionner un gestionnaire",
        SelectManager: "Sélectionner un gestionnaire",
        create: "Créer",
        humanresource: "Ressources humaines",
        finance: "Finance",
        it: "IT",
        admin: "Admin",
        selectMember: "Sélectionner un membre",
        othersapprovals: "Approbations des autres",
        exitinterview: "Entretien de sortie",
        selectAperson: "Sélectionner une personne",
        SelectHumanResource: "Sélectionner des RH",
        selectIt: "Sélectionner IT",
        selectFinance: "Sélectionner finance",
        selectAdmin: "Sélectionner admin",
        selectOthersApprovals: "Sélectionner les approbations des autres",
        inProcess: "En cours",
        reason: "Raison",
        refrence: "Référence",
        Rewards: "Récompenses",
        name: "Nom",
        add: "Ajouter",
        action: "Action",
        userType: "Type d'utilisateur",
        SelectUserType: "Sélectionner le type d'utilisateur",
        requiredMessageUserType: "Veuillez sélectionner le type d'utilisateur",
        RewardTo: "Récompense à",
        WarningTo: "Avertissement à",
        Approved: "Approuvé",
        Declined: "Refusé",
        Resend: "Renvoyer",
        NotRequired: "Non requis",
        Cancelled: "Annulé",
        InActive: "Inactif",
        ListView: "Vue en liste",
        TableView: "Vue en tableau",
        MyReward: "Ma récompense",
        ForApprovals: "Pour approbations",
        ForApproval: "Pour approbation",
        RewardToMe: "Récompense pour moi",
        Search: "Recherche",
        Disable: "Désactiver",
        Attachments: "Pièces jointes",
        Update: "Mettre à jour",
        update: "mettre à jour",
        AddEmployee: "Ajouter un employé",
        complain: "Plainte",
        Submit: "Soumettre",
        Delete: "Supprimer",
        Edit: "Modifier",
        NotDesignated: "Non désigné",
        Profile: "Profil",
        Settings: "Paramètres",
        Logout: "Déconnexion",
        Table: "Table",
        List: "Liste",
        Grid: "Grille",
        GridView: "Vue en grille",
        startyear: "Année de début",
        endyear: "Année de fin",
        startMonth: "Mois de début",
        endMonth: "Mois de fin",
        EnterStartDate: "Entrez la date de début",
        SelectStartDate: "Sélectionner la date de début",
        startDate: "Date de début",
        endDate: "Date de fin",
        businessLogo: "Logo de l'entreprise",
        title: "Titre",
        enterTitle: "Entrer le titre",
        percentage: "Pourcentage",
        PleaseEnterPercentage: "Veuillez entrer le pourcentage",
        previousCharge: "Charge précédente",
        min: "Min",
        max: "Max",
        country: "Pays",
        requiredCountry: "Le pays est requis",
        SelectCountry: "Sélectionner un pays",
        latitude: "Latitude",
        longitude: "Longitude",
        address: "Adresse",
        EnterAddress: "Entrer l'adresse",
        requiredMessageAddress: "Veuillez entrer l'adresse",
        branch: "Branche",
        question: "Questions",
        allotLeaves: "Congés alloués",
        SubjectRequired: "Sujet requis",
        WriteSubject: "Écrire le sujet",
        category: "Catégorie",
        amount: "Montant",
        documents: "Documents",
        enterName: "Entrer le nom",
        nameRequired: "Le nom est requis",
        accessTo: "Accès à",
        selectAccessTo: "Sélectionner l'accès à",
        collaborators: "Collaborateurs",
        selectCollaborators: "Sélectionner des collaborateurs",
        readers: "Lecteurs",
        selectReaders: "Sélectionner les lecteurs",
        createBoard: "Créer un tableau",
        createPad: "Créer un pad",
        createGrid: "Créer une grille",
        createSlide: "Créer une diapositive",
        createMindmap: "Créer une carte mentale",
        drive: "Conduire",
        myDrive: "Mon lecteur",
        allDocuments: "Tous les documents",
        subject: "Sujet",
        Subjectisrequired: "Le sujet est requis",
        pleaseEnterSubject: "Veuillez entrer le sujet",
        EnterSubject: "Entrer le sujet",
        enterAmount: "Entrer le montant",
        pleaseEnterAmount: "Veuillez entrer le montant",
        upload: "Télécharger",
        value: "Valeur",
        addCategory: "Ajouter une catégorie",
        saveCategory: "Enregistrer la catégorie",
        clear: "Effacer",
        loans: "Prêts",
        disburse: "Distribuer",
        referenceNo: "Numéro de référence",
        status: "Statut",
        createDate: "Date de création",
        creator: "Créateur",
        netSalary: "Salaire net",
        type: "Type",
        PleaseSelectType: "Veuillez sélectionner un type",
        CreatedByMe: "Créé par moi",
        Members: "Membres",
        Member: "Membre",
        pleaseEnterCategory: "Veuillez entrer la catégorie",
        Memberisrequired: "Le membre est requis",
        EnterCategoryName: "Entrer le nom de la catégorie",
        Clear: "Effacer",
        ReferenceNumber: "Numéro de référence",
        Grade: "Note",
        Status: "Statut",
        CreateDate: "Date de création",
        Creator: "Créateur",
        SNo: "S.No.",
        Employee: "Employé",
        SelectApprovers: "Sélectionnez les Approbateurs",
        Approverisrequired: "Approbateur requis",
        Yes: "Oui",
        No: "Non",
        totalAmount: "Montant Total",
        Total: "Total",
        Date: "Date",
        Pleaseselectdate: "Veuillez sélectionner une date",
        SelectDate: "Sélectionner une Date",
        requiredMessageDate: "Veuillez sélectionner une date",
        Priority: "Priorité",
        Pleaseselectpriority: "Veuillez sélectionner une priorité",
        Pleaseselecttype: "Veuillez sélectionner un type",
        SelectProject: "Sélectionner un Projet",
        SelectGroup: "Sélectionner un Groupe",
        Project: "Projet",
        Group: "Groupe",
        Progress: "Progression",
        SuretoRate: "Êtes-vous sûr de noter ?",
        Invalidfiletype: "Type de fichier non valide",
        Pleaseinputyoursubject: "Veuillez saisir votre sujet",
        Pleaseinputyourdescription: "Veuillez saisir votre description",
        Return: "Retour",
        EnterReason: "Entrer la Raison",
        TravelReason: "Raison du Voyage",
        NoDesignation: "Pas de Désignation",
        Hi: "Salut",
        Quotation: "Devis",
        Amountisrequired: "Le montant est requis",
        WriteDescriptionHere: "Écrire la description ici...",
        Types: "Types",
        Header: "En-tête",
        MyTeam: "Mon Équipe",
        Email: "Email",
        EnterEmail: "Entrer l'Email",
        EmailIsRequired: "Email requis",
        PhoneNo: "Numéro de Téléphone",
        Designation: "Désignation",
        ReferenceType: "Type de Référence",
        referenceId: "ID de Référence",
        Save: "Enregistrer",
        save: "enregistrer",
        Color: "Couleur",
        Yesterday: "Hier",
        Overdue: "En Retard",
        Today: "Aujourd'hui",
        Complete: "Complet",
        DueDate: "Date d'Échéance",
        SelectDueDate: "Sélectionner une Date d'Échéance",
        Cover: "Couvrir",
        Cancel: "Annuler",
        cancel: "annuler",
        and: "et",
        other: "autre",
        Others: "Autres",
        Option: "Option",
        Public: "Public",
        Private: "Privé",
        click: "cliquer",
        With: "Avec",
        with: "avec",
        Comments: "Commentaires",
        Commentsdot: "Commentaires...",
        Comment: "Commentaire",
        Seeless: "Voir moins",
        Seemore: "Voir plus",
        Share: "Partager",
        ShareNow: "Partager Maintenant",
        SelectEmployee: "Sélectionner un Employé",
        SelectCategory: "Sélectionner une Catégorie",
        PleaseSelectCategory: "Veuillez Sélectionner une Catégorie",
        privacy: "Confidentialité",
        tags: "Étiquettes",
        updateDate: "Date de Mise à Jour",
        otherApprovals: "Autres Approbations",
        resignationDate: "Date de Démission",
        purpose: "But",
        warnings: "Avertissements",
        Courses: "Cours",
        selectType: "Sélectionner un Type",
        daysCount: "Nombre de Jours",
        reportingTo: "Rapporte À",
        selectReason: "Sélectionner une Raison",
        pleaseSelectReason: "Veuillez Sélectionner une Raison",
        days: "Jours",
        day: 'jour',
        Day: 'Jour',
        Dates: "Dates",
        selectMembers: "Sélectionner les Membres",
        selectGrade: "Sélectionner une Note",
        newGrade: "Nouvelle Note",
        currentGrade: "Note Actuelle",
        PleaseSelectMember: "Veuillez Sélectionner un Membre",
        enterCategory: "Entrer une Catégorie",
        deadline: "Date Limite",
        Department: "Département",
        designationmessage: "Veuillez sélectionner une désignation",
        selectDesignation: "Sélectionner une Désignation",
        jobdescription: "Description du Poste",
        salaryRange: "Fourchette de Salaire",
        interviewers: "Intervieweurs",
        hiringBuddy: "Tuteur d'Embauche",
        enterCity: "Veuillez Entrer une Ville",
        City: "Ville",
        selectCity: "Sélectionner une ville",
        requiredCity: "La Ville est Requise",
        skills: "Compétences",
        experience: "Expérience (Années)",
        Experience: "Expérience",
        jobType: "Type de Poste",
        education: "Éducation",
        interview: "Intervieweur",
        Rating: "Évaluation",
        poweredbyWorkw: "Propulsé par Workwise Ltd - Droits d'Auteur 2016-",
        copyLink: "Copier le Lien",
        EffectiveDate: "Date d'Effet",
        experienceRequired: "Expérience Requise",
        user: "Utilisateurs",
        postinterview: "Post Entretien",
        Copied: "Copié",
        unreadNotifyItem: "Élément de Notification Non Lu",
        createBy: "Créé Par",
        Create: "Créer",
        Open: "Ouvrir",
        Closed: "Fermé",
        Nodescription: "Pas de description",
        writeDescription: "Écrivez votre description ici...",
        Employees: "Employés",
        BasicInfo: "Informations de Base",
        FatherName: "Nom du Père",
        EnterFatherName: "Entrer le Nom du Père",
        PersonalEmail: "Email Personnel",
        EnterPersonalEmail: "Entrer l'Email Personnel",
        personalEmailIsRequired: "L'email personnel est requis",
        PhoneNumber: "Numéro de Téléphone",
        EnterPhoneNumber: "Entrer le Numéro de Téléphone",
        NationalIdentity: "Identité Nationale",
        EnterNationalIdentityNumber: "Entrer le Numéro d'Identité Nationale",
        Grades: "Notes",
        SelectGrade: "Sélectionner la Note",
        requiredMessageGrade: "Veuillez Sélectionner la Note",
        EnterProbationPeriod: "Entrer la Période d'Essai",
        NoticePeriod: "Période de Préavis (Jours)",
        EnterNoticePeriod: "Entrer la Période de Préavis",
        requiredMessageNoticePeriod: "Veuillez saisir votre Période de Préavis",
        requiredMessageNoticePeriodRestriction: "La période de préavis doit être entre 1 et 60",
        DateOfBirth: "Date de Naissance",
        Gender: "Genre",
        requiredMessageGender: "Veuillez Sélectionner le Genre",
        SelectGender: "Sélectionner le Genre",
        MartialStatus: "Statut Martial",
        SelectMartialStatus: "Sélectionner le Statut Martial",
        requiredDepartment: "Le Département est requis",
        SelectDepartment: "Sélectionner le Département",
        Subsidiary: "Filiale",
        SelectSubsidiary: "Sélectionner la Filiale",
        SubsidiaryOffice: "Bureau de la Filiale",
        SelectSubsidiaryOffice: "Sélectionner le Bureau de la Filiale",
        PayrollGroup: "Groupe de Paie",
        SelectPayrollGroup: "Sélectionner le Groupe de Paie",
        DefaultApproval: "Approbation par Défaut",
        SelectDefaultApproval: "Sélectionner l'Approbation par Défaut",
        SelectAll: "Tout Sélectionner",
        NonMandatoryApprovals: "Approbations Non Obligatoires",
        SelectNonMandatoryApprovals: "Sélectionner les Approbations Non Obligatoires",
        FirstName: "Prénom",
        EnterFirstName: "Entrer le Prénom",
        requiredMessageName: "Veuillez Entrer le Nom",
        requiredMessageFName: "Veuillez Entrer le Prénom",
        requiredMessageLName: "Veuillez Entrer le Nom de Famille",
        LastName: "Nom de Famille",
        EnterLastName: "Entrer le Nom de Famille",
        DateOfJoining: "Date d'Embauche",
        requiredDateOfBirth: "La Date de Naissance est requise",
        requiredMessageJoiningDate: "Veuillez Sélectionner la Date d'Embauche",
        JobShift: "Horaires de Travail",
        requiredMessageJobShift: "Veuillez Sélectionner les Horaires de Travail",
        SelectJobShift: "Sélectionner les Horaires de Travail",
        AccessRole: "Rôle d'Accès",
        requiredMessageAccessRole: "Veuillez sélectionner au moins un rôle d'accès",
        SelectAccessRole: "Sélectionner le Rôle d'Accès",
        StartEndDate: "Date de Début/Fin",
        ErrorInValidation: "Erreur de validation",
        EducationInfo: "Informations sur l'Éducation",
        NonMandatory: "Non-Obligatoire",
        Present: "Présent",
        FailedToFetch: "Échec du Chargement",
        Position: "Poste",
        EnterPosition: "Entrer le Poste",
        requiredMessagePosition: "Veuillez Entrer le Poste",
        Organization: "Organisation",
        EnterOrganization: "Entrer l'Organisation",
        requiredMessageOrganziation: "Veuillez Entrer l'Organisation",
        Number: "Numéro",
        EnterNumber: "Entrer le Numéro",
        requiredMessageNumber: "Veuillez Entrer le Numéro",
        Relation: "Relation",
        Id: "ID",
        SelectRelation: "Sélectionner la Relation",
        requiredMessageRelation: "La Relation est requise",
        AccountName: "Nom du Compte",
        BranchCode: "Code de la Succursale",
        EnterBranchCode: "Entrer le Code de la Succursale",
        requiredMessageBranchCode: "Le Code de la Succursale est requis",
        BankName: "Nom de la Banque",
        requiredMessageBankName: "Le Nom de la Banque est requis",
        EnterBankName: "Entrer le Nom de la Banque",
        AccountNumber: "Numéro de Compte",
        EnterAccountNumber: "Entrer le Numéro de Compte",
        requiredAccountNumber: "Le Numéro de Compte est requis",
        IBAN: "IBAN",
        EnterIBAN: "Entrer l'IBAN",
        requiredIBAN: "L'IBAN est requis",
        SortCode: "Code de Tri",
        IsDefault: "Par Défaut",
        requiredSortCode: "Le Code de Tri est requis",
        EnterSortCode: "Entrer le Code de Tri",
        BankInfo: "Informations Bancaires",
        AccountTitle: "Titre du Compte",
        EnterAccountTitle: "Entrer le Titre du Compte",
        requiredMessageAccount: "Le Titre du Compte est requis",
        validAccountTitle: "Veuillez entrer un titre de compte valide",
        AttachmentType: "Type de Pièce Jointe",
        requiredAttchmentType: "Type de Pièce Jointe Requis",
        Attachment: "Pièce Jointe",
        requiredAttachment: "La Pièce Jointe est requise",
        AddAttachment: "Ajouter une Pièce Jointe",
        requiredMessageAssignMember: "Veuillez Sélectionner le Membre Assigné",
        Remove: "Supprimer",
        ContactNo: "Numéro de Contact",
        EnterContactNo: "Entrer le Numéro de Contact",
        requiredContactNo: "Le Numéro de Contact est requis",
        Contact: "Contact",
        pleasEnterDescription: "Veuillez saisir la description.",
        Information: "Information",
        Features: "Fonctionnalités",
        AddMembers: "Ajouter des Membres",
        Summary: "Résumé",
        AcessRole: "Rôle d'Accès",
        defaultApprovers: "Approbateurs par Défaut",
        NonMandatoryApprovers: "Approbateurs Non-Obligatoires",
        Pleasefillallrequiredfields: "Veuillez remplir tous les champs obligatoires",
        Suretodelete: "Êtes-vous sûr de vouloir supprimer ?",
        Code: "Code",
        createdDate: "Date de création",
        Searchmembers: "Rechercher des membres",
        AddDate: "Ajouter une date",
        duesoon: "à échéance bientôt",
        Payroll: "Paie",
        ReferenceNo: "Numéro de référence",
        Associate: "Associé",
        InternalMembers: "Membres internes",
        ExternalMembers: "Membres externes",
        TotalExpense: "Dépense totale",
        TotalExpenseAmount: "Montant total des dépenses",
        TotalPost: "Total des publications",
        TotalMeeting: "Total des réunions",
        Expense: "Dépense",
        TotalBudget: "Budget total",
        Balance: "Solde",
        successfully: "avec succès",
        enterValidEmail: "Veuillez entrer une adresse e-mail valide",
        ViewSummary: "Voir le résumé",
        AddAdmin: "Ajouter un administrateur",
        AddExternals: "Ajouter des externes",
        DefaultApprovers: "Approbateurs par défaut",
        Billings: "Facturations",
        spent: "Dépensé",
        SelectUser: "Sélectionner un utilisateur",
        Image: "Image",
        Contacts: "Contacts",
        noContacts: "Pas de contacts",
        AddContact: "Ajouter un contact",
        Roles: "Rôles",
        Tag: "Étiquette",
        AccessRoles: "Rôles d'accès",
        member: "membre",
        uploadCover: "Télécharger la couverture",
        Pleaseselect: "Veuillez sélectionner",
        composeEmail: "Composer un e-mail",
        send: "Envoyer",
        ContactDetail: "Détail du contact",
        Detail: "Détail",
        UploadImage: "Télécharger une image",
        WriteName: "Écrire un nom",
        business: "Entreprise",
        website: "Site web",
        Unknown: "Inconnu",
        Interested: "Intéressé",
        NotInterested: "Pas intéressé",
        meetings: "Réunions",
        noMeetings: "Pas de réunions",
        Communications: "Communications",
        Details: "Détails",
        LastUpdate: "Dernière mise à jour",
        nameRequiredMessage: "Nom requis!",
        addressRequiredMessage: "Adresse requise!",
        Intrested: "Intéressé",
        StartDateTime: "Date et heure de début",
        DateTimeisrequired: "Date et heure requises",
        Selectdatetime: "Sélectionnez la date et l'heure",
        Board: "Conseil",
        Notes: "Notes",
        Miletap: "Miletap",
        EMail: "E-mail",
        Attendance: "Présence",
        Absent: "Absent",
        NoData: "Pas de données",
        Prority: "Priorité",
        Heigh: "Élevé",
        Medium: "Moyen",
        Low: "Faible",
        error: "Erreur",
        Select: "Sélectionner",
        Interview: "Entretien",
        Meeting: "Réunion",
        Appointment: "Rendez-vous",
        Pleaseinputyouremail: "Veuillez saisir votre e-mail",
        EnterEmailAddress: "Entrez l'adresse e-mail",
        AddExternalMember: "Ajouter un membre externe",
        Pleaseenterexternalmembers: "Veuillez entrer des membres externes",
        externals: "externes",
        Decline: "Décliner",
        Approve: "Approuver",
        NotAvailable: "Non disponible",
        AddApprovals: "Ajouter des approbations",
        Everyone: "Tout le monde",
        SelectDownloaders: "Sélectionner les téléchargeurs",
        SelectCollabrators: "Sélectionner les collaborateurs",
        pleaseSelectAssignMemeber: "Veuillez sélectionner un membre assigné !",
        Availed: "Utilisé",
        Allotted: "Attribué",
        Degree: "Diplôme",
        Time: "Temps",
        Mood: "Humeur",
        CheckOut: "Enregistrer la sortie",
        CheckIn: "Enregistrer l'entrée",
        Late: "En retard",
        State: "État",
        PleaseSelectYesNo: "Veuillez sélectionner Oui/Non",
        PleaseselectGrade: "Veuillez sélectionner un grade",
        PleaseenterAmount: "Veuillez entrer un montant",
        Salary: "Salaire",
        Task: "Tâche",
        Nameisrequired: "Le nom est requis",
        PhoneNumberisrequired: "Le numéro de téléphone est requis",
        Dateisrequired: "La date est requise",
        Month: "Mois",
        Year: "Année",
        FinalApprovers: "Approbateurs finaux",
        link: "Lien",
        forFinalApproval: "Pour approbation finale",
        validateBonus: "Impossible de traiter la prime : Aucune information salariale disponible pour l'employé",
        validateLoan: "Impossible de traiter le prêt : Aucune information salariale disponible pour l'employé",
        User: "Utilisateur",
        Action: "Action",
        Created: "Créé",
        Toast: "Toast",
        selectyourcountry: "Veuillez sélectionner votre pays",
        PleaseEnterPhoneNumber: "Veuillez entrer le numéro de téléphone",
        Pleaseselectyourcity: "Veuillez sélectionner votre ville",
        SelectCurrency: "Sélectionner la devise",
        Pleaseselectcurrency: "Veuillez sélectionner la devise",
        totalGroups: "Groupes totaux",
        totalProjects: "Projets totaux",
        totalMemory: "Mémoire totale",
        memory: "Mémoire",
        EnterMemory: "Entrer la mémoire",
        availedMemory: "Mémoire utilisée (Go)",
        used: "Utilisé",
        usedMemory: "Mémoire utilisée (Go)",
        From: "De",
        To: "À",
        WelcomeSendEmail: "Envoyer un e-mail de bienvenue",
        SendEmail: "Envoyer un e-mail",
        writeYourRemarksHere: "Écrivez vos remarques ici...",
        NoRemarkFound: "Aucune remarque trouvée",
        ToastEnable: "Activation du toast",
        EmailEnable: "Activation de l'e-mail",
        FeatureName: "Nom de la fonctionnalité",
        LoginMessage: "Veuillez d'abord saisir vos identifiants",
        netAmount: "Montant net",
        SelectedDate: "Date sélectionnée",
        SelectedUser: "Utilisateur sélectionné",
        Default: "Défaut",
        mylog: "Mon journal",
        SelectStatus: "Sélectionner le statut",
        Selectuser: "Sélectionner un utilisateur",
        PolicyType: "Type de politique",
        Percent: "Pourcentage",
        uploadProfilePicture: "Télécharger la photo de profil",
        inputUsername: "Veuillez saisir le nom d'utilisateur",
        userName: "nom d'utilisateur",
        Dummy: "Factice",
        Lesson: "Leçon",
        Duration: "Durée",
        Text: "Texte",
        Enterlink: "Entrer le lien",
        Dashboard: "Tableau de bord",
        searchSchedule: "Rechercher un horaire...",
        AddOption: "Ajouter une option",
        ChangeImage: "Changer l'image",
        DeleteImage: "Supprimer l'image",
        Question: "Question",
        Next: "Suivant",
        Prev: "Précédent",
        Wrong: "Faux",
        Correct: "Correct",
        YourResult: "Votre résultat",
        Createdby: "Créé par",
        Lastupdated: "Dernière mise à jour",
        Assignedto: "Assigné à",
        Addlesson: "Ajouter une leçon",
        Addtopic: "Ajouter un sujet",
        minutes: "minutes",
        Modules: "Modules",
        Valid: "Valide",
        Expired: "Expiré",
        AssignedMembers: "Membres assignés",
        Start: "Démarrer",
        Previous: "Précédent",
        Read: "Lire",
        DeleteBook: "Supprimer le livre",
        Assign: "Assigner",
        Explanation: "Explication",
        Hey: "Salut",
        requiredMessageEmail: "Veuillez saisir votre adresse e-mail",
        BusinessName: "Nom de l'entreprise",
        PleaseEnterBusinessName: "Veuillez saisir le nom de l'entreprise",
        BusinessAddress: "Adresse de l'entreprise",
        PleaseEnterBusinessAddress: "Veuillez saisir l'adresse de l'entreprise",
        Offers: "Offres",
        PleaseEnterOffer: "Veuillez saisir l'offre",
        CreateOffer: "Créer une offre",
        AppliedDate: "Date d'application",
        ShortList: "Liste restreinte",
        Finalised: "Finalisé",
        Member: "Membre",
        SelectMember: 'Sélectionner un membre',
        Approver: "Approbateurs",
        CategoryName: "Nom de la catégorie",
        serialNo: "Numéro de série",
        Allocated: "Alloué",
        Available: "Disponible",
        lost: "Perdu",
        damage: "Endommagé",
        itemType: "Type d'article",
        Service: "Service",
        consumable: "Consommable",
        NonConsumable: "Non consommable",
        Quantity: "Quantité",
        accountType: "Type de compte",
        PleaseEnterQuantity: "Veuillez saisir une quantité",
        QuantityMustBePositive: "La quantité doit être positive",
        MembersIsRequired: "Les membres sont requis",
        CreateRequest: "Créer une demande",
        IBANNumber: "Numéro IBAN",
        About: "À propos",
        AboutMe: "À propos de moi",
        TimePeriod: "Période de temps",
        Company: "Entreprise",
        CityTown: "Ville",
        BankDetails: "Détails bancaires",
        Work: "Travail",
        addWorkPlace: "Ajouter un lieu de travail",
        AddEducation: "Ajouter une éducation",
        basicSalary: "Salaire de base",
        feed: 'Alimentation',
        TakeaNote: "Prendre une note",
        awards: 'Récompenses',
        Appraisal: "Évaluation",
        salary: 'Salaire',
        activityLog: "Journal d'activité",
        device: 'Appareil',
        leave: 'Congé',
        Ip: "Adresse IP",
        DeviceId: "Identifiant de l'appareil",
        AppVersion: "Version de l'application",
        Selectfeature: "Sélectionnez la fonctionnalité",
        EnterYourFeedBackDescription: "Entrez la description de votre retour d'information",
        HideAll: "Tout cacher",
        ShowAll: "Tout afficher",
        PrintBy: "Imprimer par",
        AccCode: "Code Compte",
        SelectAccountType: "Sélectionner le type de compte",
        Files: "Fichiers",
        Space: "Espace",
        DisburseVoucher: "Distribuer/Bon",
        TopUsersbyMostFiles: "Meilleurs utilisateurs par nombre de fichiers",
        TopUsersbyStorageSize: "Meilleurs utilisateurs par taille de stockage",
        Apply: "Appliquer",
        NumberOfTopUsers: "Nombre de meilleurs utilisateurs",
        TopUsers: "Meilleurs utilisateurs",
        SearchUser: "Rechercher un utilisateur",
        ByStorageSize: "Par taille de stockage",
        ByFileSize: "Par taille de fichier",
        companyDashboard: "Tableau de bord de l'entreprise",
        signup: "Inscription non confirmée",
        billing: "Facturation",
        PendingBills: "Factures en attente",
        MonthYear: "Mois - Année",
        SendBill: "Envoyer la facture",
        BillingUsers: "Utilisateurs de facturation",
        Item: "Article",
        Rate: "Taux",
        PaidAmount: "Montant payé",
        PaymentDate: "Date de paiement",
        DarkDays: "Jours sombres",
        ProcessingFee: "Frais de traitement",
        Currency: "Devise",
        IsEnable: "Est activé",
        Actions: "Actions",
        TakeNote: "Prenez une note",
        PleaseSelectUsers: "Veuillez sélectionner les utilisateurs",
        StickyNotes: "Notes adhésives",
        Copy: "Copier",
        "MarkAllRead": "Tout marquer comme lu",
        SeeAll: "Voir tout",
        Refresh: "Rafraîchir",
        close: "Fermer",
        Calling: "Appel",
        LoadMore: "Charger plus",
        Quizez: "Quiz",
        content: "Contenu",


    },
    /*** navMenuLabel only used in LeftSideBar ***/
    navMenuLabel: {
        Schedules: "Horaires",
        workWiseCompanies: "Entreprises Workwise",
        promotions: "Promotions",
        customApprovals: "Approbations Personnalisées",
        feed: "Fil d'Actualités",
        messenger: "Messager",
        chartOfAccount: "Plan Comptable",
        createVoucher: "Créer un Bon",
        voucherList: "Liste des Bons",
        Quotation: "Devis",
        QuotationClient: "Client Devis",
        transaction: "Transactions",
        report: "Rapport",
        mailBox: "Boîte Mail",
        groups: "Groupes",
        projects: "Projets",
        tasks: "Tâches",
        discussionBoard: "Tableau de Discussion",
        pages: "Pages",
        workBoard: "Tableaux de Travail",
        leadManager: "Gestionnaire de Prospects",
        schedules: "Horaires",
        expense: "Dépenses",
        travel: "Voyages",
        docsArchives: "Docs/Archives",
        eLearning: "E-Learning",
        inventory: "Inventaire",
        myTeam: "Mon Équipe",
        orgChart: "Organigramme",
        employee: "Employés",
        administration: "Administration",
        appraisals: "Évaluations",
        departments: "Départements",
        leaves: "Congés",
        basicInfo: "Informations de Base",
        holidays: "Vacances",
        careers: "Carrières",
        companiesSection: "Entreprises Workwise",
        companies: "Entreprises",
        menu: "Menu",
        hr: "RH",
        finance: "Finance",
        inventorySection: "Inventaire",
        privacy: "Confidentialité",
        TC: "T & C",
        more: "plus",
        download: "Téléchargements",
        feedBack: "Retour",
        warnings: "Avertissements",
        complains: "Plaintes",
        bonus: "Bonus",
        resignations: "Démissions",
        requisition: "Réquisitions",
        assets: "Attribution des Actifs",
        assetsList: "Liste des Actifs",
        createAssets: "Créer des Actifs",
        requestListItems: "Demande d'Articles",
        rewards: "Récompenses",
        businessPolicy: "Politiques Commerciales",
        companiesPolicy: "Politiques des Entreprises",
        form: "Formulaires",
        ledgerReport: "Rapports du Grand Livre",
        trialBalance: "Balance de Vérification",
        salary: "Salaire",
        payroll: "Paie",
        jobBoard: "Tableau d'Emploi",
        teams: "Mon Équipe",
        LD: "L & D",
        marketPlace: "Marché",
        loan: "Prêts",
        Courses: "Cours",
        selectMembers: "Sélectionner des Membres",
        auction: "Enchère",
        Nodescription: "Pas de description",
        contract: "Contrat"
    },
    /*** AppHeader only used in Component headerTab ***/
    appHeader: {
        newsFeed: {
            posts: "Publications",
            photos: "Photos",
            videos: "Vidéos",
            polls: "Sondages",
            docs: "Documents",
            tagged: "Tagués",
        },
        mailBox: {},
        messenger: {},
        groups: {
            groups: "Groupes",
        },
        projects: {
            projects: "Projets",
            dashboard: "Tableau de Bord",
        },
        Task: {
            Tasks: "Tâches",
            myTask: "Mes Tâches",
            assignedByMe: "Assignées par Moi",
            createdByMe: "Créées par Moi",
            teamTask: "Tâche d'Équipe",
            dashboard: "Tableau de Bord",
        },
        leadManager: {
            leadCategory: "Catégorie de Prospect",
        },
        schedules: {
            calendar: "Calendrier",
            schedule: "Planning",
            scheduleInterviews: "Planifier des Entretiens",
        },
        expense: {
            expenses: "Dépenses",
            forApprovals: "Pour Approbations",
            myExpenses: "Mes Dépenses",
            forExecution: "Pour Exécution",
            forFinance: "Pour Finance",
            reports: "Rapports",
            dashboard: "Tableau de Bord",
        },
        travel: {
            travels: "Voyages",
            forApprovals: "Pour Approbations",
            agentProcess: "Processus Agent",
            travelDetail: "Détail du Voyage",
        },
        docsArchives: {
            documents: "Documents",
            myDrive: "Mon Drive",
            myDocuments: "Mes Documents",
            forApprovals: "Pour Approbations",
        },
        eLearning: {
            dashboard: "Tableau de Bord",
        },
        inventory: {
            approvals: "Approbations",
            inventory: "Inventaire",
            category: "Catégorie",
        },
        customApproval: {
            CreateCustomApproval: "Créer une Approbation Personnalisée",
            CustomApproval: "Approbation Personnalisée",
            ForApproval: "Pour Approbation",
            MyCustomApprovals: "Mes Approbations Personnalisées",
            AllCustomApproval: "Toutes les Approbations Personnalisées",
        },
        payroll: {
            payroll: "Paie",
            payrollApproval: "Approbation de la Paie",
            salaryApproval: "Approbation du Salaire",
        },
        // employee: {
        //   employees: "Employés",
        // },
        administration: {
            administration: "Administration",
        },
        appraisals: {
            appraisals: "Évaluations",
        },
        reward_category: {
            reward_category: "Catégorie de Récompense",
        },
        departments: {
            departments: "Départements",
        },
        leaves: {
            myLeaves: "Mes Congés",
            forApprovals: "Pour Approbations",
        },
        holidays: {
            holidays: "Vacances",
        },
        warnings: {
            warnings: "Avertissements",
        },
        resignations: {
            resignations: "Démissions",
            approvers: "Approbateurs",
        },
        complains: {
            complains: "Plaintes",
            approvers: "Approbateurs",
            createComplain: "Créer une Plainte",
        },
        rewards: {
            rewards: "Récompenses",
            AllRewards: "Tous les Bonus",
            CreatedByMe: "Créés par Moi",
            ForApprovals: "Pour Approbations",
            MyRewards: "Mes Récompenses",
        },
        promotions: {
            Promotions: "Promotions",
        },
        salary: {
            Salary: "Salaire",
            CreateSalary: "Créer un Salaire",
        },
        pages: {
            Pages: "Pages",
        }
    },
    /*** Main Menu ***/
    globalSearch: {
        All: "Tout",
        feed: "Flux",
        Lead_Manager: "Gestionnaire de leads",
        Travel: "Voyage",
        Document: "Document",
        Project: "Projet",
        Workboard: "Tableau de travail",
        Group: "Groupe",
        Expense: "Dépense",
        Task: "Tâche",
        Employee: "Employé",
        e_learning_course: "Cours e-learning",
        e_learning_videos: "Vidéos e-learning",
        e_learning_article: "Article e-learning",
        e_learning_quiz: "Quiz e-learning",
        e_learning_book: "Livre e-learning",
        e_learning_tedTalks: "TED Talks e-learning",
        rewards: "Récompenses",
    },

    auction: {
        auctionType: "Type d'enchère",
        personal: "Personnel",
        official: "Officiel",
        offer: "Offre",
        startingBid: "Enchère de départ",
        buyNowAmount: "Montant Achat Immédiat",
        immediateBuy: "Achat Immédiat",
        minIncreaseBid: "Augmentation Minimum de l'Enchère",
        Auction: "Enchère",
        CreateAuction: "Créer une Enchère",
        actionType: "Type d'enchère",
        external: "Externe",
        auctionDetail: "Détails de l'enchère",
        currentBid: "Enchère Actuelle",

        FormLabel: {
            startingBid: "Enchère de départ",
            member: "Membre",
            approvers: "Approbateurs",
            minIncreaseBid: "Augmentation Minimum de l'Enchère",
            buyNowAmount: "Montant Achat Immédiat",
            offer: "Offre",
            immediateBuy: "Achat Immédiat",
        },
        FormPlaceholder: {
            name: "Entrer le Nom de l'Enchère",
            startingBid: "Entrer l'Enchère de départ",
            minIncreaseBid: "Entrer l'Augmentation Minimum de l'Enchère",
            selectMember: "Sélectionner un Membre",
            selectApprovers: "Sélectionner les Approbateurs",
            buyNowAmount: "Entrer le Montant Achat Immédiat",
            offer: "Entrer l'Offre",
        },
        FormRequiredMessage: {
            name: "Veuillez entrer le Nom de l'Enchère",
            startingBid: "Veuillez entrer l'Enchère de départ",
            minIncreaseBid: "Veuillez entrer l'Augmentation Minimum de l'Enchère",
            buyNowAmount: "Veuillez entrer le Montant Achat Immédiat",
            offer: "Veuillez entrer l'Offre",
            endDate: "Veuillez entrer la Date de Fin",
        },
        auctionFilterTypeEnumLabel: {
            [auctionFilterTypeEnum.All]: "Toutes les enchères",
            [auctionFilterTypeEnum.CreatedByMe]: "Mes enchères",
            [auctionFilterTypeEnum.ForApproval]: "Pour approbation",
        },
        statusEnum: {
            [AuctionStatusEnum.InProcess]: "En cours",
            [AuctionStatusEnum.Approved]: "Approuvée",
            [AuctionStatusEnum.Declined]: "Refusée",
            [AuctionStatusEnum.Resend]: "Renvoyer",
            [AuctionStatusEnum.InActive]: "Inactif",
            [AuctionStatusEnum.NotRequired]: "Non requis",
            [AuctionStatusEnum.Cancelled]: "Annulée",
            [AuctionStatusEnum.Hold]: "En attente",
            [AuctionStatusEnum.Sold]: "Vendu",
        },
        auctionOfferStatus: {
            [AuctionOfferStatusEnum.BidInProcess]: "Enchère en cours",
            [AuctionOfferStatusEnum.BidSold]: "Enchère vendue",
            [AuctionOfferStatusEnum.OutBid]: "Surenchère",
        },
        noDataMessage: "Créer une Enchère",
    },
    newsFeed: {
        WriteForMe: "Écrire pour moi",
        AddDocuments: "Ajouter des documents",
        AddPolls: "Ajouter des sondages",
        WhatsOnYourMind: "À quoi pensez-vous",
        Direction: "ltr",
        Generate: "Générer",
        Change: "Changer",
        Whatwouldyouliketotalkabout: "De quoi aimeriez-vous parler?",
        Post: "Publier",
        Poll: "Sondage",
        Documents: "Documents",
        PhotoVideo: "Photo/Vidéo",
        Addpolloption: "Ajouter une option de sondage",
        Removepoll: "Supprimer le sondage",
        WriteName: "Écrire le nom",
        AddImageVideo: "Ajouter une Image/Vidéo",
        CheckIn: "Enregistrer",
        ShareonFeed: "Partager sur le Fil d'Actualités",
        ShareinChat: "Partager dans le Chat",
        NoPosts: "Aucune publication...",
        Nomoreposts: "Plus de publications...",
        noDataMessage: "Créer une publication maintenant!",
        Saysomethingaboutthis: "Dites quelque chose à ce sujet...",
        specificTimeline: " chronologie de",
        SharedestinationNames: {
        [SharePostDestinations.FEED]: "Fil d'actualité",
        [SharePostDestinations.MEMBERS_PROFILE]: "Profil du membre",
        [SharePostDestinations.GROUP]: "Groupe",
        [SharePostDestinations.PROJECT]: "Projet",
        [SharePostDestinations.TIMELINE]: "Timeline",
        },
        SharePostSuggestionPlaceholder: {
        [SuggestionTypeEnum.Employee]: "Sélectionner un membre",
        [SharePostDestinations.GROUP]: "Sélectionner un groupe",
        [SharePostDestinations.PROJECT]: "Sélectionner un projet",
        },
        CreateFeedNow: "Créez un flux maintenant!",

        QuickOptions: {
            CreateSlide: "Créer une Diapositive",
            CreateBoard: "Créer un Tableau",
            CreateGrid: "Créer une Grille",
            CreatePad: "Créer un Bloc-notes",
            CreateTask: "Créer une Tâche",
            CreateExpense: "Créer une Dépense",
            AddUser: "Ajouter un Utilisateur",
        },
        reactionDescription: {
            [ReactionType.Like]: "J'aime",
            [ReactionType.Love]: "J'adore",
            [ReactionType.NoReaction]: "J'aime",
            [ReactionType.Support]: "Apprécier",
            [ReactionType.Celebrate]: "Célébrer",
            [ReactionType.Curious]: "Curieux",
        },
        PostTypePlaceHolder: {
            [PostType.DEFAULT]: "À quoi pensez-vous",
            [PostType.POLL]: "Poser une question...",
        },
        Titlecantbeempty: "Le titre ne peut pas être vide",
        Attachmentscantbeempty: "Les pièces jointes ne peuvent pas être vides",
        Optioncantbeempty: "L'option ne peut pas être vide",
        SuccessfullySharedonFeed: "Partagé avec succès sur le Fil d'Actualités",
        SuccessfullySharedonMembersProfile: "Partagé avec succès sur le profil du membre",
        SuccessfullyPostSharedMessage:{
        [PostReferenceType.MAIN_FEED]: "Partagé avec succès sur le fil d'actualité principal",
        [PostReferenceType.GROUP]: "Partagé avec succès dans le groupe",
        [PostReferenceType.PROJECTS]: "Partagé avec succès sur le projet",
        [PostReferenceType.TIMELINE]: "Partagé avec succès sur la chronologie",
        },
    },
    mail: {
        composer: "Composer",
        menuItem: {
            inbox: "Boîte de réception",
            starred: "Étoilés",
            snoozed: "En attente",
            sent: "Envoyés",
            spam: "Spam",
            archive: "Archives",
            trash: "Corbeille",
            draft: "Brouillons",
            allMail: "Tous les mails",
            createNewLabel: "Créer une nouvelle étiquette",
        },
        close: "Fermer",
        send: "Envoyer",
        newMessage: "Nouveau message",
        newEmail: "Créer un nouvel email",
        ComposeEmail: "Composer un E-mail",
    },
    messenger: {
        Messenger: "Messenger",
        Typing: "En train d'écrire...",
        TypeaMessage: "Écrire un message...",
        Recording: "Enregistrement...",
        nameYourGroup: "Nommer votre groupe",
        Create: "Créer",
        GroupNameRequired: "Nom du groupe requis",
        searchMemberToAdd: "Rechercher un membre à ajouter",
        contacts: "Contacts",
        selected: "Sélectionné",
        whatwouldyoutalkingabout: "De quoi aimeriez-vous parler?",
        SayHi: "Dites bonjour à vos contacts",
    },
    marketPlace: {
        jobBoardName: "Tableau d'emploi",
        requsitionName: "réquisition",
    },
    discussionBoard: {
        DiscussionBoard: "Forum de discussion",
        Topic: "Sujet",
        pleaseEnterTopicName: "Veuillez entrer le nom du sujet",
        enterTopicName: "Entrer le nom du sujet",
        addDiscussionBoard: "Ajouter un forum de discussion",
        errormessage: "Une erreur s'est produite lors de la récupération du forum de discussion.",
        noDataMessage: "– Créez une discussion maintenant!",
    },
    pages: {
        messageName: "Veuillez entrer le nom",
        tags: "Tags",
        messageTags: "Veuillez entrer les tags",
        enterTag: "Entrer les tags",
        createPage: "Créer une page",
        noDataMessage: "– Créez une page maintenant!",
    },
    group: {
        group: "Groupes",
        createGroup: "Créer un groupe",
        Updategroup: "Mettre à jour le groupe",
        enterGroupName: "Veuillez entrer le nom du groupe.",
        Code: "Code",
        exampleText: "Par exemple, G (pour un nom de groupe)",
        GroupTag: "Tag du groupe",
        AddAdmin: "Ajouter un administrateur",
        TotalBudget: "Budget total",
        Spent: "Dépensé",
        Balance: "Solde",
        EnterGroupTag: "Entrer le tag du groupe",
        SaveGroupTag: "Enregistrer le tag du groupe",
        AddGroupTag: "Ajouter un tag de groupe",
        EditGroup: "Modifier le groupe",
        CreateGroupNow: "Créez un groupe maintenant!",
    },
    project: {
        WIP: "En cours",
        privateProject: "Projet privé",
        Projects: "Projets",
        UpdateProject: "Mettre à jour le projet",
        CreateProject: "Créer un projet",
        noDataMessage: "– Créez un projet maintenant!",
        ProjectTag: "Tag du projet",
        ProjectRole: "Rôle dans le projet",
        exampleProjectName: "Par exemple, P (pour un nom de projet)",
        projectDate: "Date du projet",
        EnterProjectTag: "Entrer le tag du projet",
        SaveProjectTag: "Enregistrer le tag du projet",
        AddProjectTag: "Ajouter un tag de projet",
        editProject: "Modifier le projet",
        PersonalNotes: "Notes personnelles",
        EnterProjectRole: "Entrer le rôle dans le projet",
        SaveCustomTag: "Enregistrer le tag personnalisé",
        AddProjectRole: "Ajouter un rôle de projet",
        TakeaNote: "Prendre une note",
        PublicProject: "Projet public",
        ProjectFeatureTabs:{
            [ProjectFeatureEnums.Feed]: "Fil d'actualité",
            [ProjectFeatureEnums.Schedule]: "Référence",
            [ProjectFeatureEnums.WorkBoard]: "Tableau de travail",
            [ProjectFeatureEnums.Document]: "Documents",
            [ProjectFeatureEnums.Task]: "Tâches",
            [ProjectFeatureEnums.Expense]: "Dépenses",
            [ProjectFeatureEnums.Travel]: "Voyages",
            [ProjectFeatureEnums.Quotation]: "Estimation",
            [ProjectFeatureEnums.Budget]: "Budget",
            [ProjectFeatureEnums.Setting]: "Paramètres",
            [ProjectFeatureEnums.DiscussionBoard]: "Forum de discussion",
            [ProjectFeatureEnums.Pages]: "Pages",
            [ProjectFeatureEnums.Issues]: "Problèmes",
            [ProjectFeatureEnums.Contract]: "Contrats",
          }
    },
    tasks: {
        Tasks: "Tâches",
        Predecessor: "Prédécesseur",
        AssignTo: "Assigner à",
        TaskDate: "Date de la tâche",
        CreateTask: "Créer une tâche",
        TaskAssignTo: "Attribuer une tâche à",
        Rating: "Évaluation",
        TaskDetail: "Détails de la tâche",
        ProgressofAssignedmembers: "Avancement des membres assignés",
        SubTasks: "Sous-tâches",
        SubGoals: "Sous-objectifs",
        Subtask: "Sous-tâche",
        CreateSubtask: "Créer une sous-tâche",
        Addsubtask: "Ajouter une sous-tâche",
        AddGoal: "Ajouter un objectif",
        ParentTask: "Tâche parent",
        DueToday: "Échéance aujourd'hui",
        DueTomorrow: "Échéance demain",
        Overdue: "En retard",
        Duein: "Échéance dans",
        days: "jours",
        TaskCreatedSuccessfully: "Tâche créée avec succès",
        noDataMessage: "– Créez une tâche maintenant!",

        selfTaskEnum: {
            [SelfTaskEnum.SelfTask]: "Tâche personnelle",
            [SelfTaskEnum.AssignTask]: "Assigner à",
        },
        Priority: "Priorité",
        taskFilterTypeEnum: {
            [TaskFilterTypeEnum.CreatedByMe]: "Assignées par moi",
            [TaskFilterTypeEnum.MyTasks]: "Mes tâches",
            [TaskFilterTypeEnum.MyTeamTasks]: "Tâches de l'équipe",
        },
        statusEnum: {
            [TaskStatusEnum.NotStarted]: "Pas commencé",
            [TaskStatusEnum.InProcess]: "En cours",
            [TaskStatusEnum.Completed]: "Terminé",
            [TaskStatusEnum.RatingAssign]: "Évaluation attribuée",
            [TaskStatusEnum.Cancel]: "Annulé",
            [TaskStatusEnum.Hold]: "En attente",
        },
        taskReferenceTypeEnum: {
            [TaskReferenceTypeEnum.General]: "Général",
            [TaskReferenceTypeEnum.Project]: "Projet",
            [TaskReferenceTypeEnum.Group]: "Groupe",
            [TaskReferenceTypeEnum.Lead]: "Lead",
        },
        taskPriorityEnum: {
            [TaskPriorityEnum.Default]: "Par défaut",
            [TaskPriorityEnum.Low]: "Faible",
            [TaskPriorityEnum.Medium]: "Moyenne",
            [TaskPriorityEnum.High]: "Élevée",
        },
        SortEnum: {
            [TaskFilterSortEnum.CreateDateDesc]: "Date de création - Descendant",
            [TaskFilterSortEnum.CreateDateAsc]: "Date de création - Ascendant",
            [TaskFilterSortEnum.StartDateDesc]: "Date de début - Descendant",
            [TaskFilterSortEnum.StartDateAsc]: "Date de début - Ascendant",
            [TaskFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Descendant",
            [TaskFilterSortEnum.UpdateDateAsc]: "Date de mise à jour - Ascendant"
        }
    },
    contracts: {
        Contract: "Contrat",
        createContract: "Créer un contrat",
        contractDate: "Date du contrat",
        contractPurpose: "But du contrat",
        selectContractPurpose: "Sélectionnez le but du contrat",
        purposeofContractisRequired: "Le but du contrat est requis",
        category: "Catégorie",
        purpose: "But",
        signatureMember: "Membres signataires",
        contractDetail: "Détail du contrat",
        selectCategory: "Sélectionnez la catégorie",
        cateogryofContractisRequired: "La catégorie est requise",
        externalMember: "Membres signataires externes",
        selectinternalMembers: "Sélectionnez les membres signataires internes",
        internalMembers: "Membres signataires internes",
        selectExternalMember: "Sélectionnez les membres signataires externes",
        contractType: "Type de contrat",
        contractTypeRequired: "Le type de contrat est requis",
        internal: "Interne",
        external: "Externe",
        noDataMessage: "Créez un contrat maintenant!",
        contractsFilterTypeLables: {
            [ContractFilterTypeEnum.Contracts]: "Tous les contrats commerciaux",
            [ContractFilterTypeEnum.BusinessContract]: "Mes contrats"
        },
        statusEnum: {
            [ContractStatusEnum.InProcess]: "En cours",
            [ContractStatusEnum.Completed]: "Terminé",
            [ContractStatusEnum.Cancelled]: "Annulé"
        },
        contractReferenceTypeEnum: {
            [ContractReferenceTypeEnum.General]: "Général",
            [ContractReferenceTypeEnum.Project]: "Projet",
            [ContractReferenceTypeEnum.Group]: "Groupe",
            [ContractReferenceTypeEnum.Lead]: "Prospect"
        }
    },
    workBoard: {
        WorkBoardDashBoard: "Tableau de bord du tableau de travail",
        pleaseEnterYourBoardName: "Veuillez entrer le nom de votre tableau",
        pleaseEnterYourBoardDescription: "Veuillez entrer la description de votre tableau",
        EnterMembers: "Entrer les membres",
        UpdateBoard: "Mettre à jour le tableau",
        CreateBoard: "Créer un tableau",
        privacy: "Confidentialité",
        AddSection: "Ajouter une section",
        AddTodo: "Ajouter une tâche",
        Save: "Sauvegarder",
        EnterSectionTitle: "Entrer le titre de la section",
        InSection: "Dans la section",
        Label: "Étiquette",
        Activity: "Activité",
        Labels: "Étiquettes",
        UploadCoverPhoto: "Télécharger une photo de couverture",
        Section: "Section",
        Board: " Tableau",
        Todos: "Tâches",
        CompleteDate: "Date de complétion",
        DetailedDesc: "Ajouter une description plus détaillée...",
        EnterTodo: "Entrez le texte pour cette carte...",
        CreateWorkBoardNow: "- Créez un tableau de travail maintenant!",
        workBoardExample: "Par exemple, WB (pour un tableau nommé Work Board)",
    },
    leadManager: {
        LeadManager: "Gestionnaire de leads",
        AssignedLeads: "Leads assignés",
        AllLeads: "Tous les leads",
        Groups: "Groupes",
        email: "E-mail",
        Lead: "Lead",
        Leads: "Leads",
        Pleaseinputyourboardname: "Veuillez saisir le nom de votre tableau !",
        exampleLeadManagerMessage: "Par exemple LM (pour un groupe nommé Gestionnaire de leads)",
        inputBoardDescription: "Veuillez saisir la description de votre tableau !",
        UpdateLeadManager: "Mettre à jour le gestionnaire de leads",
        CreateLeadManager: "Créer un gestionnaire de leads",
        CreateLeadGroup: "Créer un groupe de leads",
        noDataMessage: "– Créez maintenant un gestionnaire de leads !",
        LastUpdated: "Dernière mise à jour",
        LeadValue: "Valeur du lead",
        individual: "Individuel",
        leadEmailAddress: "Adresse e-mail du lead",
        leadPhoneNumber: "Numéro de téléphone du lead",
        leadAddress: "Adresse du lead",
        leadUrl: "URL du lead",
        ConvertProject: "Convertir en projet",
        goToProject: "Aller au projet",
        SelectAssignMembers: "Sélectionner les membres assignés",
        LeadGroup: "Groupe de leads",
        leadName: "Nom du lead",
        leadMembers: "Membres du lead",
        leadWebiste: "Site web du lead",
        LeadValuenegative: "La valeur du lead ne peut pas être négative !",
        AddLead: "Ajouter un lead",
        SelectCommunicationTo: "Sélectionner la communication à",
        CommunicationTypeisrequired: "Le type de communication est requis !",
        PleaseselectCommunicationType: "Veuillez sélectionner le type de communication",
        CommunicationType: "Type de communication",
        AddCommunication: "Ajouter une communication",
        CommunicationTo: "Communication à",
        NoCalls: "Aucun appel",
        LeadsValue: "Valeur des leads",
        metricTypeEnum: {
            [LeadManagerMetricTypeEnum.allGroup]: "Groupes au total",
            [LeadManagerMetricTypeEnum.allAssign]: "Lead assigné",
            [LeadManagerMetricTypeEnum.leadToProject]: "Convertir en projet",
            [LeadManagerMetricTypeEnum.leadSection]: "Section maximale de leads",
        },
    },
    schedule: {
        StartMeeting: "Démarrer la réunion",
        MeetinginProgress: "Réunion en cours",
        EndMeeting: "Terminer la réunion",
        MeetingEnded: "Réunion terminée",
        NewTokenAdded: "Nouveau jeton ajouté",
        Copied: "Copié",
        SuccessfullyBlocked: "Bloqué avec succès",
        SuccessfullyUnBlocked: "Débloqué avec succès",
        UpdateCalendarLink: "Mettre à jour le lien du calendrier",
        AddCalendarLink: "Ajouter un lien de calendrier",
        GetCalendarLink: "Obtenir le lien du calendrier",
        CopyLink: "Copier le lien",
        Block: "Bloquer",
        UnBlock: "Débloquer",
        CreateSchedule: "Créer un emploi du temps",
        Draft: "Brouillon",
        Finalize: "Finaliser",
        Complete: "Complet",
        Preview: "Aperçu",
        MinutesOftheMeeting: "Compte rendu de la réunion",
        MeetingId: "ID de réunion",
        Venue: "Lieu",
        Workwisecall: "Appel Workwise",
        ConferenceCall: "Conférence téléphonique",
        Attendees: "Participants",
        Absentees: "Absents",
        PointsofDiscussion: "Points de discussion",
        AddPoint: "Ajouter un point",
        Print: "Imprimer",
        SaveToPDF: "Enregistrer au format PDF",
        MEETINGMINUTES: "COMPTE RENDU DE LA RÉUNION",
        Yes: "Oui",
        No: "Non",
        WorkwiseCall: "Appel Workwise",
        MeetingObjectives: "Objectifs de la réunion",
        Points: "Points",
        RefNo: "Réf. No",
        AttendanceatMeeting: "Présence à la réunion",
        Loadingschedules: "Chargement des emplois du temps...",
        Nodata: "Aucune donnée...",
        Projects: "Projets",
        Groups: "Groupes",
        titleMessage: "Êtes-vous sûr ? Changer le type supprimera les membres assignés.",
        pleaseSelectAssignMemeber: "Veuillez sélectionner un membre assigné !",
        SelectAssignto: "Sélectionner l'assignation à",
        GetLink: "Obtenir le lien",
        Anyone: "Tout le monde",
        anyoneText: "avec le lien peut créer un rendez-vous.",
        TodayEvents: "Événements du jour",
        UpcomingEvents: "Événements à venir",
        InterviewRequests: "Demandes d'entretien",
        sendviachat: "envoyer via chat",
        OnVideoConference: "Sur une conférence vidéo :",
        OnWorkwiseCall: "Sur un appel Workwise",
        Enterlocation: "Entrer l'emplacement",
        Duration: "Durée",
        inminutes: "en minutes",
        Durationisrequired: "La durée est requise",
        Rooms: "Salles",
        SelectRoom: "Sélectionner une salle",
        CheckRoomAvailability: "Vérifier la disponibilité de la salle",
        Available: "Disponible",
        NotAvailable: "Non disponible",
        TravelTime: "Temps de déplacement",
        PreparationTime: "Temps de préparation",
        SendviaEmail: "Envoyer par e-mail",
        schedulerCalender: "Calendrier de planification",
        Next: "Suivant",
        Prev: "Précédent",
        titlemyCustomButton: "titre myCustomButton",
        prevnexttoday: "préc suiv aujourd'hui",
        timeGridDaytimeGridWeekdayGridMonth: "grille horaire jour grille horaire semaine grille horaire mois",
        timeGridDay: "grille horaire jour",
        top: "haut",
        block: "bloc",
        minutes: "minutes",
        AllDay: "Toute la journée",
        timeGridWeek: "grille horaire semaine",
        slotDuration: "00:15:00",
        numeric: "numérique",
        dayGridMonth: "Mois",
        dayGridWeek: "Semaine",
        timeGrid: "Grille horaire",
        Day: "Jour",
        Week: "Semaine",
        Month: "Mois",
        NoEventsonthisday: "Aucun événement ce jour-là.",
        CreateInterviewSchedule: "Créer un horaire d'entretien",
        Interviewwith: "Entretien avec",
        MySchedules: "Mes horaires",
        TeamSchedules: "Horaires de l'équipe",
        Past: "Passé",
        Today: "Aujourd'hui",
        Upcoming: "À venir",
        ScheduleDetail: "Détail de l'horaire",
        JoinWorkwiseCall: "Rejoindre l'appel Workwise",
        AddWorkwiseCall: "Ajouter un appel Workwise",
        Host: "(Hôte)",
        MakeHost: "Rendre hôte",
        Attending: "Présence",
        UpdateSchedule: "Mettre à jour l'horaire",
        Calendar: "Calendrier",
        Schedule: "Horaire",
        ShareCalendarLink: "Partager le lien du calendrier",
        pointer: "pointeur",
        default: "défaut",
        blue: "bleu",
        red: "rouge",
        purple: "violet",
        Self: "Soi-même",
        AssignTo: "Assigner à",
        assign: "assigner",
        min: "min",
        Minutes: "15 minutes",
        MeetingTranscript: "Transcription de la réunion",
    },
    expenses: {
        CreateExpense: "Créer une dépense",
        IsReimbursable: "Est remboursable",
        WriteHeaderHere: "Écrivez le titre ici...",
        ExpenseDetail: "Détail de la dépense",
        ExpenseDate: "Date de la dépense",
        Executors: "Exécutants",
        Financers: "Financeurs",
        ExecutorRequired: "L'exécutant est requis",
        selectExecutor: "Sélectionnez l'exécutant de la dépense",
        noDataMessage: "Renforcez votre parcours financier - Créez une dépense maintenant !",
        expenseFilterTypeEnum: {
            [ExpenseFilterTypeEnum.MyExpense]: "Mes dépenses",
            [ExpenseFilterTypeEnum.ForApproval]: "En attente d'approbation",
            [ExpenseFilterTypeEnum.ForExecutions]: "En attente d'exécution",
            [ExpenseFilterTypeEnum.ForFinance]: "En attente de financement",
        },
        expenseType: {
            [ExpenseReferenceTypeEnum.General]: "Général",
            [ExpenseReferenceTypeEnum.Group]: "Groupe",
            [ExpenseReferenceTypeEnum.Project]: "Projet",
            [ExpenseReferenceTypeEnum.Travel]: "Voyage",
            [ExpenseReferenceTypeEnum.Assets]: "Actifs",
        },
        statusEnum: {
            [ExpenseApprovalStatusEnum.InProcess]: "En cours",
            [ExpenseApprovalStatusEnum.Approved]: "Approuvé",
            [ExpenseApprovalStatusEnum.Declined]: "Refusé",
            [ExpenseApprovalStatusEnum.Cancelled]: "Annulé",
            [ExpenseApprovalStatusEnum.Completed]: "Terminé",
            [ExpenseApprovalStatusEnum.Hold]: "En attente",
            [ExpenseApprovalStatusEnum.Disbursed]: "Décaissé",
            [ExpenseApprovalStatusEnum.WaitingForDisburse]: "En attente de décaissement",
        },
        SortEnum: {
            [ExpenseFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [ExpenseFilterSortEnum.CreateDateAsc]: "Date de création",
            [ExpenseFilterSortEnum.ExpenseDateDesc]: "Date de la dépense - Décroissant",
            [ExpenseFilterSortEnum.ExpenseDate]: "Date de la dépense",
            [ExpenseFilterSortEnum.ReferenceNo]: "Numéro de référence",
            [ExpenseFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Décroissant",
            [ExpenseFilterSortEnum.Amount]: "Montant",
            [ExpenseFilterSortEnum.AmountDesc]: "Montant - Décroissant"
        },
        Category: {
            [ExpenseCategoryEnum.Transport]: "Transport",
            [ExpenseCategoryEnum.Health]: "Santé",
            [ExpenseCategoryEnum.Food]: "Nourriture",
            [ExpenseCategoryEnum.Shopping]: "Shopping",
            [ExpenseCategoryEnum.Entertainment]: "Divertissement",
            [ExpenseCategoryEnum.Travel]: "Voyage",
            [ExpenseCategoryEnum.Bill]: "Facture",
            [ExpenseCategoryEnum.Office]: "Bureau",
            [ExpenseCategoryEnum.Fuel]: "Carburant",
            [ExpenseCategoryEnum.Asset]: "Actif",
            [ExpenseCategoryEnum.Other]: "Autre"
        }
    },
    travel: {
        TravelDetail: "Détails du voyage",
        CreateTravel: "Créer un voyage",
        PleaseAddTravelDetail: "Veuillez ajouter les détails du voyage",
        TravelToandFrom: "Voyage de et vers",
        ReturnDateIsRequired: "La date de retour est obligatoire",
        PleaseSelectTravelType: "Veuillez sélectionner le type de voyage",
        HotelTADACar: "Hôtel, TADA & Voiture",
        HotelRequired: "Hôtel requis",
        RentaCar: "Louer une voiture",
        PickUpRequired: "Ramassage requis",
        DropOffRequired: "Dépôt requis",
        TADAApplicable: "Applicable au TADA",
        TravelBy: "Voyage en",
        SpecialRequest: "Demande spéciale",
        SearchDestinationCity: "Rechercher la ville de destination",
        SearchDepartureCity: "Rechercher la ville de départ",
        PleaseSelectDepartureCity: "Veuillez sélectionner la ville de départ.",
        PleaseSelectDestinationCity: "Veuillez sélectionner la ville de destination.",
        DepartureTimeIsRequired: "L'heure de départ est requise",
        RetunTimeIsRequired: "L'heure de retour est requise",
        AttachmentType: "Type de pièce jointe",
        WriteSpecialRequestDetail: "Écrivez les détails de la demande spéciale",
        Agents: "Agents",
        Departure: "Départ",
        Arrival: "Arrivée",
        DepartureDate: "Date de départ",
        DepartureTime: "Heure de départ",
        DepartureCity: "Ville de départ",
        ShiftType: "Type de poste",
        DepartureShift: "Poste de départ",
        ArrivalCity: "Ville d'arrivée",
        CreateTravelnow: "Créer un voyage maintenant",
        UpdateTravel: "Mettre à jour le voyage",
        TravelAttachmentTypeOptions: {
            [TravelAttachmentTypeEnum.Passport]: "Passeport",
            [TravelAttachmentTypeEnum.Ticket]: "Billet",
            [TravelAttachmentTypeEnum.Insurance]: "Assurance",
            [TravelAttachmentTypeEnum.CarRental]: "Location de voiture",
            [TravelAttachmentTypeEnum.Other]: "Autre",
        },

        Shift: {
            [ShiftEnum.Morning]: "Matin",
            [ShiftEnum.Afternoon]: "Après-midi",
            [ShiftEnum.Evening]: "Soir",
            [ShiftEnum.Night]: "Nuit",
        },

        travelBy: {
            plane: "En Avion",
            bus: "En Bus",
            ship: "En Bateau",
            train: "En Train",
            car: "En Voiture",
        },

        TravelOptions: {
            [TravelOptionsEnums.ByPlane]: "En Avion",
            [TravelOptionsEnums.ByShip]: "En Bateau",
            [TravelOptionsEnums.ByBus]: "En Bus",
            [TravelOptionsEnums.ByTrain]: "En Train",
            [TravelOptionsEnums.ByCar]: "En Voiture",
        },

        travelFilterTypeEnum: {
            [TravelFilterTypeEnum.Travels]: "Voyages",
            [TravelFilterTypeEnum.MyTravel]: "Mes Voyages",
            [TravelFilterTypeEnum.ForApproval]: "En attente d'approbation",
            [TravelFilterTypeEnum.AgentProcess]: "Traitement par l'agent",
        },

        statusEnum: {
            [TravelStatusEnum.InProcess]: "En Cours",
            [TravelStatusEnum.Approved]: "Approuvé",
            [TravelStatusEnum.Declined]: "Refusé",
            [TravelStatusEnum.Cancelled]: "Annulé",
            [TravelStatusEnum.ApprovedByApprovers]: "Approuvé par les approbateurs",
            [TravelStatusEnum.ApprovedByAgents]: "Approuvé par les agents",
            [TravelStatusEnum.DeclineByApproves]: "Refusé par les approbateurs",
            [TravelStatusEnum.DeclineByAgents]: "Refusé par les agents",
            [TravelStatusEnum.Hold]: "En Attente",
        },
        SortEnum: {
            [TravelFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Décroissant",
            [TravelFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [TravelFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Décroissant",
            [TravelFilterSortEnum.ReferenceNoAsc]: "Numéro de référence",
            [TravelFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [TravelFilterSortEnum.CreateDateAsc]: "Date de création",
            [TravelFilterSortEnum.TravelEndDateDesc]: "Date de fin du voyage",
            [TravelFilterSortEnum.TravelStartDate]: "Date de début du voyage"
        }
    },
    eLearning: {
        Course: "Cours",
        eBook: "Livre électronique",
        Quiz: "Quiz",
        TedTalk: "TedTalk",
        Article: "Article",
        Video: "Vidéo",
        BasicCourseDetail: "Détails de base du cours",
        SelectSubject: "Sélectionner le sujet",
        SelectCourse: "Sélectionner le cours",
        PleaseSelectOption: "Veuillez sélectionner une option",
        Selectcoursetype: "Sélectionner le type de cours",
        CourseName: "Nom du cours",
        Entercoursename: "Entrez le nom du cours",
        Level: "Niveau",
        PleaseSelectLevel: "Veuillez sélectionner le niveau",
        Selectlevel: "Sélectionner le niveau",
        CourseAdminMembers: "Membres administratifs du cours",
        CourseAdminisrequired: "L'administrateur du cours est requis",
        AssignMembers: "Attribuer des membres",
        UpdateEBook: "Mettre à jour le livre électronique",
        CreateEBook: "Créer un livre électronique",
        AuthorName: "Nom de l'auteur",
        EnterInformation: "Entrer les informations",
        UploadBook: "Télécharger le livre (PDF)",
        CreateQuiz: "Créer un quiz",
        QuizName: "Nom du quiz",
        CreateTedTalk: "Créer un TedTalk",
        ViaLink: "Via le lien",
        ViaVideoUpload: "Via le téléchargement vidéo",
        PleaseInsertLink: "Veuillez insérer un lien",
        UploadVideo: "Télécharger la vidéo",
        CreateArticle: "Créer un article",
        InsertLink: "Insérer le lien",
        UploadPDF: "Télécharger une photo ou un PDF",
        CreateVideo: "Créer une vidéo",
        LessonDetail: "Détail de la leçon",
        Eitherselect: "Sélectionnez un quiz ou supprimez-le.",
        Selectquiz: "Sélectionner un quiz",
        AddNewQuiz: "Ajouter un nouveau quiz",
        Atleastonetopic: "Au moins un sujet de cette leçon est requis.",
        LessonNameRequired: "Le nom de la leçon est requis.",
        LessonName: "Nom de la leçon",
        DeleteLesson: "Supprimer la leçon",
        LessonDescriptionRequired: "La description de la leçon est requise.",
        TopicNameIsRequired: "Le nom du sujet est requis.",
        TopicName: "Nom du sujet",
        DeleteTopic: "Supprimer le sujet",
        TopicDescription: "La description du sujet est requise.",
        eitherEnterText: "Saisissez du texte ou supprimez-le.",
        EitherenterA: "Saisissez un",
        OrRemoveIt: "ou supprimez-le.",
        CreateArticlesNow: "Créez des articles maintenant !",
        CreateCoursesNow: "Créez des cours maintenant !",
        CreateEbooksNow: "Créez des livres électroniques maintenant !",
        CreateElearningNow: "Créez de l'apprentissage en ligne maintenant !",
        CreateQuizzesNow: "Créez des quiz maintenant !",
        CreateTeamDashboardNow: "Créez le tableau de bord de l'équipe maintenant !",
        CreateTedTalksNow: "Créez des TedTalks maintenant !",
        CreateVideosNow: "Créez des vidéos maintenant !",
        OptionEmpty: "L'option ne peut pas être vide",
        CorrectOptionRequired: "L'option correcte est requise",
        QuestionEmpty: "La question ne peut pas être vide",
        UntitledQuestion: "Question sans titre",
        AddQuestion: "Ajouter une question",
        DeleteQuestion: "Supprimer la question",
        Quiznamerequired: "Nom du quiz requis !",
        QuizexpirationDate: "Date d'expiration du quiz",
        QuizExpirationDateIsRequired: "La date d'expiration du quiz est requise",
        QuizDescriptionRequired: "Description du quiz requise !",
        StartQuiz: "Démarrer le quiz",
        GoToResults: "Voir les résultats",
        Gettoknown: "Découvrez l'expérience utilisateur",
        Missingdata: "Données manquantes.",
        UntitledLesson: "Leçon sans titre",
        RemoveText: "Supprimer le texte",
        AddText: "Ajouter du texte",
        RemoveImage: "Supprimer l'image",
        AddImage: "Ajouter une image",
        RemoveVideo: "Supprimer la vidéo",
        AddVideo: "Ajouter une vidéo",
        RemovePDF: "Supprimer le PDF",
        AddPDF: "Ajouter un PDF",
        RemoveQuiz: "Supprimer le quiz",
        AddQuiz: "Ajouter un quiz",
        RemoveLink: "Supprimer le lien",
        AddLink: "Ajouter un lien",
        RemoveYouTubeLink: "Supprimer le lien YouTube",
        AddYouTubeLink: "Ajouter un lien YouTube",
        RemoveExternalCourse: "Supprimer le cours externe",
        AddExternalCourse: "Ajouter un cours externe",
        UntitledTopic: "Sujet sans titre",
        DashboardChart: "Graphique du tableau de bord",
        LatestTrendInBusiness: "Dernière tendance en affaires",
        InformationWillComeHere: "Les informations arriveront ici",
        CourseContent: "Contenu du cours",
        DummyContent: "Contenu fictif",
        EndCourse: "Terminer le cours",
        Chapters: "Chapitres •",
        Lectures: "Conférences •",
        LessonsInThisProgram: "Leçons de ce programme",
        BookInformation: "Informations sur le livre",
        TedTalksDaily: "TedTalks quotidiens",
        TopicTitle: "Titre du sujet",
        VisitThis: "Visitez ceci",
        LearnPractical: "Apprenez des compétences pratiques grâce à des cours et obtenez des apprentissages rémunérés.",
        Courses: "Cours",
        Book: "Livre",
        Tedtalk: "Tedtalk",
        Articles: "Articles",
        NoPageExist: "La page n'existe pas",
        Books: "Livres",
        TedTalks: "Ted Talks",
        Videos: "Vidéos",
        GoTo: "Allons-y.",
        CourseLevelIdLabel: {
            [CourseLevelIdEnum.Advance]: "Avancé",
            [CourseLevelIdEnum.Intermediate]: "Intermédiaire",
            [CourseLevelIdEnum.Beginner]: "Débutant"
        }
    },
    attendence: {
        Attendance: "Présence",
        TimeSpent: "Temps passé",
        GraceTime: "Temps de grâce",
        GoodDay: "Bonne journée",
        Howfeeling: "Comment vous sentez-vous aujourd'hui?",
        Note: "Remarque",
        Noattendanceforapproval: "Aucune présence à approuver.",
        Iwaslate: "J'étais en retard à cause du trafic.",
        Showtimespent: "Afficher le temps passé",
        ClockIn: "Connectez-vous pour démarrer votre journal de présence.",
        selectStatus: "Veuillez sélectionner un statut!",
        ByTime: "Par Temps",
        ByStatus: "Par Statut",
        DeductionPolicy: "Politique de déduction",
        UpdateDeductionPolicy: "Mettre à jour la politique de déduction",
        AddDeductionPolicy: "Ajouter une politique de déduction",
        DaysRange: "Plage de jours",
        PerDay: "Par Jour",
        SaveDeductionPolicy: "Enregistrer la politique de déduction",
        AbsentPolicy: "Politique d'absence",
        LatePolicy: "Politique de retard",
        IsPercentage: "Est en pourcentage",
        attendanceFilterTypeEnum: {
            [AttendanceFilterTypeEnum.checkInForApproval]: "Enregistrement à approuver",
            [AttendanceFilterTypeEnum.dayWiseAttendance]: "Présence par jour",
            [AttendanceFilterTypeEnum.userAttendance]: "Présence de l'utilisateur",
            [AttendanceFilterTypeEnum.Summary]: "Résumé",
        },

        updateErrorMessage: {
            [AttendanceStateEnum.Off]: "Action de mise à jour indisponible pour l'état désactivé",
            [AttendanceStateEnum.Holiday]: "Action de mise à jour indisponible pour les jours fériés",
            [AttendanceStateEnum.Leave]: "Action de mise à jour indisponible pour l'état de congé",
        },
        statusEnum: {
            [AttendanceStatusEnum.InProcess]: "En Cours",
            [AttendanceStatusEnum.Approved]: "Approuvé",
            [AttendanceStatusEnum.Declined]: "Refusé",
        },
        moodLabels: {
            [AttendanceMoodEnum.VeryUnsatisfied]: "Très Insatisfait",
            [AttendanceMoodEnum.Unsatisfied]: "Insatisfait",
            [AttendanceMoodEnum.Neutral]: "Neutre",
            [AttendanceMoodEnum.Satisfied]: "Satisfait",
            [AttendanceMoodEnum.VerySatisfied]: "Très Satisfait",
        },
        attendanceState: {
            [AttendanceStateEnum.Unknown]: "",
            [AttendanceStateEnum.Present]: "Présent",
            [AttendanceStateEnum.Absent]: "Absent",
            [AttendanceStateEnum.Leave]: "Congé",
            [AttendanceStateEnum.Late]: "En Retard",
            [AttendanceStateEnum.WorkFromHome]: "Travail à Domicile",
            [AttendanceStateEnum.Off]: "Désactivé",
            [AttendanceStateEnum.Holiday]: "Jour Férié",
        },
        attendanceType: {
            [ATTENDANCE_ENUM.CHECK_IN.IN]: "Enregistrement",
            [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Départ",
        },
        attendanceMetricLabel: {
            [AttendanceMetricTypeEnum.overallTimeSpent]: "Temps Total Passé",
            [AttendanceMetricTypeEnum.Present]: "Présent",
            [AttendanceMetricTypeEnum.Absent]: "Absent",
            [AttendanceMetricTypeEnum.Leave]: "Congé",
            [AttendanceMetricTypeEnum.Late]: "En Retard",
        },
    },
    customApproval: {
        createCustomApproval: "Créer une Approbation Personnalisée",
        customApproval: "Approbation Personnalisée",
        noDataMessage: "– Créez des approbations personnalisées maintenant!",
        customApprovalFilterTypeEnum: {
            [CustomApprovalFilterTypeEnum.All]: "Toutes les Approbations Personnalisées",
            [CustomApprovalFilterTypeEnum.CreatedByMe]: "Créées Par Moi",
            [CustomApprovalFilterTypeEnum.ForApproval]: "En Attente d'Approbation",
        },
        statusEnum: {
            [CustomApprovalStatusEum.InProcess]: "En Cours",
            [CustomApprovalStatusEum.Approved]: "Approuvé",
            [CustomApprovalStatusEum.Declined]: "Refusé",
            [CustomApprovalStatusEum.Resend]: "Renvoyer",
            [CustomApprovalStatusEum.InActive]: "Inactif",
            [CustomApprovalStatusEum.NotRequired]: "Non Requis",
            [CustomApprovalStatusEum.Cancelled]: "Annulé",
            [CustomApprovalStatusEum.Hold]: "En Attente",
        },
        SortEnum: {
            [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Date de Mise à Jour - Décroissant",
            [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Date de Mise à Jour",
            [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Numéro de Référence - Décroissant",
            [CustomApprovalFilterSortEnum.ReferenceNo]: "Numéro de Référence",
            [CustomApprovalFilterSortEnum.CreateDateDesc]: "Date de Création - Décroissant",
            [CustomApprovalFilterSortEnum.CreateDateAsc]: "Date de Création"
        }
    },
    reward: {
        CreateReward: "Créer une Récompense",
        Rewards: "Récompenses",
        RewardTo: "Récompenser",
        RewardCategory: "Catégorie de Récompense",
        AddRewardCategory: "Ajouter une Catégorie de Récompense",
        EnterRewardCategory: "Entrer une Catégorie de Récompense",
        RewardName: "Nom de la Récompense",
        EnterRewardName: "Entrer le Nom de la Récompense",
        PleaseEnterRewardName: "Veuillez Entrer le Nom de la Récompense",
        ReasonForReward: "Raison de la Récompense",
        EnterReasonForReward: "Entrer la Raison de la Récompense",
        PleaseEnterReasonForReward: "Veuillez Entrer la Raison de la Récompense",
        noDataMessage: "– Créez une Récompense maintenant!",
        rewardFilterTypeEnum: {
            [RewardFilterTypeEnum.All]: "Toutes les Récompenses",
            [RewardFilterTypeEnum.CreatedByMe]: "Créées Par Moi",
            [RewardFilterTypeEnum.ForApproval]: "En Attente d'Approbation",
            [RewardFilterTypeEnum.RewardForMe]: "Mes Récompenses",
        },
        statusEnum: {
            [RewardStatusEnum.InProcess]: "En Cours",
            [RewardStatusEnum.Approved]: "Approuvé",
            [RewardStatusEnum.Declined]: "Refusé",
            [RewardStatusEnum.Cancelled]: "Annulé",
            [RewardStatusEnum.Hold]: "En Attente",
        },
        SortEnum: {
            [RewardFilterSortEnum.UpdateDateDesc]: "Date de Mise à Jour - Décroissant",
            [RewardFilterSortEnum.UpdateDateAsc]: "Date de Mise à Jour",
            [RewardFilterSortEnum.ReferenceNoDesc]: "Numéro de Référence - Décroissant",
            [RewardFilterSortEnum.ReferenceNo]: "Numéro de Référence",
            [RewardFilterSortEnum.StatusDesc]: "Statut - Décroissant",
            [RewardFilterSortEnum.Status]: "Statut",
            [RewardFilterSortEnum.CategoryDesc]: "Catégorie - Décroissant",
            [RewardFilterSortEnum.Category]: "Catégorie",
            [RewardFilterSortEnum.NameDesc]: "Nom - Décroissant",
            [RewardFilterSortEnum.Name]: "Nom",
            [RewardFilterSortEnum.CreateDateDesc]: "Date de Création - Décroissant",
            [RewardFilterSortEnum.CreateDateAsc]: "Date de Création",
        }
    },
    document: {
        mySigned: "Mon Signé",
        forMySignature: "Pour Ma Signature",
        draftSignature: "Brouillon",
        inSigning: "En Signature",
        signaturesCompleted: "Signatures Terminées",
        DocsArchive: "Documents et Archives",
        createDocument: "Créer un Document",
        CreateSlides: "Créer des Diapositives",
        CreateBoard: "Créer un Tableau",
        CreatePad: "Créer un Bloc-notes",
        CreateGrid: "Créer une Grille",
        newFolder: "Nouveau Dossier",
        createFolder: "Créer un Dossier",
        uploadDocuments: "Téléverser des Documents",
        addBoard: "Ajouter un Tableau",
        addPad: "Ajouter un Bloc-notes",
        addGrid: "Ajouter une Grille",
        addSlide: "Ajouter une Diapositive",
        addMindmap: "Ajouter une Carte Mentale",
        CompleteDocument: "Document Complet",
        noDataMessage: "- Créez un Document maintenant!",
        AllDocuments: "Tous les Documents",
        MyDrive: "Mon Drive",
        MyDocuments: "Mes Docs Pour Approbation",
        ForApprovals: "Pour Approbations",
        OnlyFiles: "Seulement les Fichiers",
        fileName: "Nom du Fichier",
        shortDescription: "Description Courte (max 100 caractères)",
        AllFiles: "Tous les Fichiers",
        Grids: "Grilles",
        Pads: "Bloc-notes",
        Slides: "Diapositives",
        Boards: "Tableaux",
        Mindmaps: "Cartes Mentales",
        updateFolder: "Mettre à Jour le Dossier",
        updateGrid: "Mettre à Jour la Grille",
        updatePad: "Mettre à Jour le Bloc-notes",
        updateSlide: "Mettre à Jour la Diapositive",
        updateBoard: "Mettre à Jour le Tableau",
        updateMindMap: "Mettre à Jour la Carte Mentale",
        updateDocuments: "Mettre à Jour le Document",
        status: {
            InProcess: "En Cours",
            Approved: "Approuvé",
            Declined: "Refusé",
            Resend: "Renvoyer",
            NotRequired: "Non Requis",
            Cancelled: "Annulé",
            Draft: "Brouillon",
        },
        documentReferenceType: {
            [DocumentReferenceTypeEnum.General]: "Général",
            [DocumentReferenceTypeEnum.Group]: "Groupe",
            [DocumentReferenceTypeEnum.Project]: "Projet",
        },
    },
    warning: {
        WarningDetail: "Détail d'Avertissement",
        warningTo: "Avertissement À",
        createWarning: "Créer un Avertissement",
        enterwarningCategory: "Entrer une catégorie d'avertissement",
        noDataMessage: '– Créez un Avertissement maintenant!',
        filter: "Filtre",
        warningFilterTypeEnum: {
            [WarningFilterTypeEnum.Warning]: "Avertissements",
            [WarningFilterTypeEnum.CreatedByMe]: "Créés Par Moi",
            [WarningFilterTypeEnum.ForApproval]: "Pour Approbation",
            [WarningFilterTypeEnum.WarningToMe]: "Avertissement Pour Moi",
        },
        statusEnum: {
            [WarningStatusEnum.InProcess]: "En Cours",
            [WarningStatusEnum.Approved]: "Approuvé",
            [WarningStatusEnum.Declined]: "Refusé",
            [WarningStatusEnum.Cancelled]: "Annulé",
            [WarningStatusEnum.Hold]: "En Attente",
        },
        SortEnum: {
            [WarningFilterSortEnum.UpdateDateDesc]: "Date de Mise à Jour - Décroissant",
            [WarningFilterSortEnum.UpdateDateAsc]: "Date de Mise à Jour - Croissant",
            [WarningFilterSortEnum.ReferenceNoDesc]: "Numéro de Référence - Décroissant",
            [WarningFilterSortEnum.ReferenceNo]: "Numéro de Référence",
            [WarningFilterSortEnum.StatusDesc]: "Statut - Décroissant",
            [WarningFilterSortEnum.Status]: "Statut",
            [WarningFilterSortEnum.CategoryDesc]: "Catégorie - Décroissant",
            [WarningFilterSortEnum.Category]: "Catégorie",
            [WarningFilterSortEnum.NameDesc]: "Nom - Décroissant",
            [WarningFilterSortEnum.Name]: "Nom",
            [WarningFilterSortEnum.CreateDateDesc]: "Date de Création - Décroissant",
            [WarningFilterSortEnum.CreateDateAsc]: "Date de Création - Croissant",
        }

    },
    jobBoard: {
        jobBoard: "Tableau des Emplois",
        noDataMessage: " – Créez des Carrières maintenant!",
    },
    businessPolicy: {
        BusinessPolicy: "Politique d'Entreprise",
        policies: "Politiques",
        noPolicy: "Aucune Politique Trouvée...",
    },
    forms: {
        CreateForm: "Créer un Formulaire",
        Forms: "Formulaires",
        Attempt: "Tentative",
        CopyLink: "Copier le Lien",
        Title: "Titre",
        EnterTitle: "Entrer le Titre",
        PleaseEnterTitle: "Veuillez Entrer le Titre",
        PleaseFillTheQuestionField: "Veuillez Remplir le Champ de Question",
        EnterQuestion: "Entrer une Question",
        SelectAnswerType: "Sélectionner le Type de Réponse",
        Text: "Texte",
        Number: "Nombre",
        Polls: "Sondages",
        Poll: "Sondage",
        AddQuestion: "Ajouter une Question",
        AddOption: "Ajouter une Option",
        Details: "Détails",
        PoweredBy: "Propulsé Par",
        Submit: "Soumettre",
        EditForm: "Modifier le Formulaire",
        EnterEmail: "Entrer l'Email",
        Question: "Question...",
        YourAnswer: "Votre Réponse",
        noDataMessage: "– Créez un Formulaire maintenant!",
        formQuestionTypeEnum: {
            [FormQuestionTypeEnum.Number]: "Nombre",
            [FormQuestionTypeEnum.Text]: "Texte",
            [FormQuestionTypeEnum.Poll]: "Sondage",
        },
        formFilterTypeEnum: {
            [FormFilterTypeEnum.All]: "Tous les Formulaires",
            [FormFilterTypeEnum.CreatedByMe]: "Créés Par Moi",
            [FormFilterTypeEnum.ForApproval]: "Pour Approbation",
            [FormFilterTypeEnum.FormForMe]: "Formulaire Pour Moi"
        },
        statusEnum: {
            [FormStatusEnum.InProcess]: "En Cours",
            [FormStatusEnum.Approved]: "Approuvé",
            [FormStatusEnum.Declined]: "Refusé",
            [FormStatusEnum.Resend]: "Renvoyer",
            [FormStatusEnum.Inactive]: "Inactif",
            [FormStatusEnum.NotRequired]: "Non Requis",
            [FormStatusEnum.Cancelled]: "Annulé",
            [FormStatusEnum.Hold]: "En Attente",
        },

        SortEnum: {
            [FormFilterSortEnum.CreateDateDesc]: "Date de Création - Décroissant",
            [FormFilterSortEnum.CreateDateAsc]: "Date de Création - Croissant",
            [FormFilterSortEnum.UpdateDateDesc]: "Date de Mise à Jour - Décroissant",
            [FormFilterSortEnum.UpdateDateAsc]: "Date de Mise à Jour - Croissant",
            [FormFilterSortEnum.ReferenceNoDesc]: "Numéro de Référence - Décroissant",
            [FormFilterSortEnum.ReferenceNo]: "Numéro de Référence"
        }

    },
    careers: {
        Careers: "Carrières",
        CareerDetail: "Détail de Carrière",
        createCareer: "Créer une Carrière",
        MinSalary: "Salaire Min",
        MaxSalary: "Salaire Max",
        All: "Tous",
        Applicants: "Candidats",
        ShortListed: "Présélectionné",
        Rejected: "Rejeté",
        Completed: "Complété",
        Finalist: "Finaliste",
        Maybe: "Peut-être",
        OfferSent: "Offre Envoyée",
        WaitingForInterview: "En Attente d'Entretien",
        OfferAccepted: "Offre Acceptée",
        OfferDeclined: "Offre Refusée",
        OfferResend: "Renvoyer l'Offre",
        noDataMessage: "– Créez une Carrière maintenant!",
        writeHeaderHere: "Écrire l'en-tête",
        pickCurrentDate: "Sélectionner la date actuelle",
        enterAmount: "Entrer le montant",
        selectApprovers: "Sélectionner les approbateurs",
        selectFinancers: "Sélectionner les financiers",
        selectExecutors: "Sélectionner les exécutants",
        selectDesignation: "Sélectionner le poste",
        writeDescription: "Écrire la description",
        enterMinSalary: "Entrer le salaire minimum",
        enterMaxSalary: "Entrer le salaire maximum",
        selectDepartment: "Sélectionner le département",
        selectSupervisor: "Sélectionner le superviseur",
        selectInterviewers: "Sélectionner les intervieweurs",
        selectPostInterviewers: "Sélectionner les intervieweurs post-entretien",
        description: "Écrivez votre description ici...",
        addSkills: "Ajouter une compétence",
        selectJobType: "Sélectionner le type d'emploi",
        selectJobshift: "Sélectionner le shift de travail",
        selectEducation: "Veuillez sélectionner l'éducation",
        selectCareerLevel: "Sélectionner le niveau de carrière",
        selectEndDate: "Sélectionner la date de fin",
        enterreviewcriteria: "Entrer les critères de révision",
        reviewCriteria: "Critères de Révision",
        enterDepartment: "Veuillez Entrer le Département",
        supervisor: "Superviseur",
        supervisorrequired: "Le superviseur est requis",
        postInterviewers: "Intervieweurs Post-entretien",
        jobShift: "Shift de Travail",
        enterJobShift: "Veuillez Entrer le Shift de Travail",
        enterJobType: "Veuillez Entrer le Type d'Emploi",
        careerLevel: "Niveau de Carrière",
        selectBuddy: "Sélectionner le Parrainage",
        selectInerview: "Sélectionner les Intervieweurs",
        requiredInterview: "L'intervieweur est requis",
        enterCareerLevel: "Veuillez Entrer le Niveau de Carrière",
        createJob: "Créer un Emploi",
        applyJob: "Postuler à l'Emploi",
        firstName: "Prénom",
        enterFirstName: "Entrer le Prénom",
        requiredMessageFName: "Le Prénom est requis",
        lastName: "Nom de Famille",
        enterLastName: "Entrer le Nom de Famille",
        requiredMessageLName: "Le Nom de Famille est requis",
        email: "Email",
        enterEmail: "Entrer l'Email",
        EmailIsRequired: "L'Email est requis",
        phoneNumber: "Numéro de Téléphone",
        enterPhoneNumber: "Entrer le Numéro de Téléphone",
        currentSalary: "Salaire Actuel",
        expectedSalary: "Salaire Attendu",
        radioMessageApplicant: "Candidat",
        radioMessageShortlisted: "Présélectionné",
        radioMessageCompleted: "Complété",
        coverNote: "Note de Couverture",
        noticePeriod: "Période de Préavis",
        requiredMessageFName: "Veuillez Entrer le Prénom",
        requiredMessageLName: "Veuillez Entrer le Nom de Famille",
        requiredMessageEmail: "Veuillez Entrer l'Email",
        requiredMessagePhoneNumbeer: "Veuillez Entrer le Numéro de Téléphone",
        requiredMessageCurrentSalary: "Veuillez Entrer le Salaire Actuel",
        requiredMessageExpextedsalary: "Veuillez Entrer le Salaire Attendu",
        requiredMessageExperience: "Veuillez Entrer l'Expérience",
        requiredMessageNoticePeriod: "Veuillez Entrer la Période de Préavis ",
        appliedDate: "Date de Candidature",
        interviewSchedule: "Planifier l'Entretien",
        applyNow: "Postuler Maintenant",
        jobPost: "Poste de Travail",
        jobexpire: "Expiration de l'Emploi",
        skillrequired: "Compétences Requises",
        joblocation: "Lieu de Travail",
        jobType: "Type d'Emploi",
        jobDetails: "Détails de l'Emploi",
        EducationLevel: "Niveau d'Éducation",
        MinimumSalary: "Salaire Minimum",
        MaximumSalary: "Salaire Maximum",
        YearOfExperience: "Années d'Expérience",
        errormessage: "Impossible de Changer le Statut",
        MakeOffer: "Faire une Offre",
        updatestatus: "Êtes-vous sûr de vouloir mettre à jour le statut?",
        Pleaseenterminsalary: "Veuillez entrer le salaire minimum",
        Pleaseentermaxsalary: "Veuillez entrer le salaire maximum",
        NoRequirements: "Aucune exigence",
        careerInterviewStatuEnum: {
            [CareerInterviewStatusEnum.NotScheduled]: "Non Planifié",
            [CareerInterviewStatusEnum.Ended]: "Terminé",
            [CareerInterviewStatusEnum.Scheduled]: "Planifié",
            [CareerInterviewStatusEnum.Started]: "Commencé",
            [CareerInterviewStatusEnum.WaitingForReSchedule]: "En Attente de Replanification"
        },
        statusEnum: {
            [CareerStatusEnum.InProcess]: "En Cours",
            [CareerStatusEnum.Approved]: "Approuvé",
            [CareerStatusEnum.Declined]: "Refusé",
            [CareerStatusEnum.Cancelled]: "Annulé",
            [CareerStatusEnum.Available]: "Disponible",
            [CareerStatusEnum.Expired]: "Expiré",
            [CareerStatusEnum.Hold]: "En Attente",
        },
        careerFilterTypeEnum: {
            [CareerFilterTypeEnum.All]: "Toutes les Carrières",
            [CareerFilterTypeEnum.MyCareers]: "Mes Carrières",
            [CareerFilterTypeEnum.ForApproval]: "Pour Approbation",
        },
        SortEnum: {
            [CareerFilterSortEnum.CreateDateDesc]: "Date de Création - Descendante",
            [CareerFilterSortEnum.CreateDateAsc]: "Date de Création",
            [CareerFilterSortEnum.ReferenceNoDesc]: "Date de Mise à Jour - Descendante",
            [CareerFilterSortEnum.ReferenceNoAsc]: "Date de Mise à Jour",
            [CareerFilterSortEnum.UpdateDateDesc]: "Date de Mise à Jour - Descendante",
            [CareerFilterSortEnum.UpdateDateAsc]: "Date de Mise à Jour",
            [CareerFilterSortEnum.MinSalary]: "Salaire Min",
            [CareerFilterSortEnum.MinSalaryDesc]: "Salaire Min - Descendante",
            [CareerFilterSortEnum.MaxSalary]: "Salaire Max",
            [CareerFilterSortEnum.MaxSalaryDesc]: "Salaire Max - Descendante",
        },
        CareerLevelEnum: {
            [CareerLevelEnum.Entry]: "Débutant",
            [CareerLevelEnum.MidLevel]: "Niveau Intermédiaire",
            [CareerLevelEnum.SeniorLevel]: "Niveau Supérieur",
            [CareerLevelEnum.ExecutiveLevel]: "Niveau Exécutif",
            [CareerLevelEnum.CSuite]: "C-Suite",
            [CareerLevelEnum.PresidentCeo]: "Président PDG",
        },
        JobTypeEnums: {
            [JobTypeEnums.FullTime]: "Temps Plein",
            [JobTypeEnums.PartTime]: "Temps Partiel",
            [JobTypeEnums.Contractual]: "Contractuel",
            [JobTypeEnums.Consultant]: "Consultant",
            [JobTypeEnums.Agent]: "Agent",
            [JobTypeEnums.ProjectBased]: "Basé sur un Projet",
        },
        JobShiftTypeEnums: {
            [JobShiftTypeEnums.Day]: "Jour",
            [JobShiftTypeEnums.Morning]: "Matin",
            [JobShiftTypeEnums.AfterNoon]: "Après-midi",
            [JobShiftTypeEnums.Night]: "Nuit",
            [JobShiftTypeEnums.Other]: "Autre",
        }
    },
    /*** HR Menu ***/
    myTeam: {
        MyTeams: "Mes Équipes",
        EmployeeNo: "Numéro d'Employé",
        noDataMessage: "Créez des Équipes maintenant!",
        teamDetails: "Détails de l'Équipe",
        DeductionPerMonth: "Déduction par Mois",
        teamActivities: {
            attendance: "Présence",
            checkIn: "Pointage",
            leaves: "Congés",
            rewards: "Récompenses",
            appraisals: "Évaluations",
            warnings: "Avertissements",
            complains: "Plaintes",
            courses: "Cours",
            education: "Éducation",
            experience: "Expérience",
            loan: "Prêt",
            activityLog: "Journal d'Activité",
            checkInCheckOut: "Pointage Entrée / Sortie",
            storageStatistics: "Statistiques de stockage"
        },
    },
    administration: {
        basicInfo: "Informations de base",
        Subsidiary_Office: "Bureau de filiale",
        Fiscal_Year: "Exercice fiscal",
        Tax_Slabs: "Tranches d'impôt",
        Tax_Slabs_Group: "Groupe de tranches d'impôt",
        E_Learning_Category: "Catégorie de formation en ligne",
        Company_Policy: "Politiques de l'entreprise",
        Default_Hiring_Criteria: "Critères de recrutement par défaut",
        subsidiary: "Filiale",
        AddSubsidiary: "Ajouter une filiale",
        Business_Logo: "Logo de l'entreprise",
        Complain_Category: "Catégorie de plainte",
        Custom_Tag: "Étiquette personnalisée",
        Payroll_Group: "Groupe de paie",
        Business_Policy: "Politique d'entreprise",
        Companies_Policy: "Politiques de l'entreprise",
        Billing: "Facturation",
        Rebate_Category: "Catégorie de remise",
        Assets_Category: "Catégorie d'actifs",
        AccessRole: "Rôle d'accès",
        Grade: "Niveau",
        Default_Account_Header: "En-tête de compte par défaut",
        Business_Paging: "Numéro de l'entreprise",
        Discussion_Board_Category: "Catégorie de tableau de discussion",
        Room: "Salle",
        Business_Association: "Association d'entreprise",
        Designation: "Désignation",
        Company_Goal: "Objectif de l'entreprise",
        Individual_Goal: "Objectif individuel",
        Office_Timings: "Horaires de bureau",
        Leave_Types: "Types de congés",
        User_Types: "Types d'utilisateurs",
        Expense_Headers: "En-tête de dépense",
        Salary_Headers: "En-tête de salaire",
        Request_For_Right: "Demande de droit",
        Custom_Approval_Category: "Catégorie d'approbation personnalisée",
        GRADE_ALLOWANCES: "Allocations de niveau",
        Paging_Category: "Catégorie de pagination",
        Page_Category: "Catégorie de page",
        Add_Paging_Category: "Ajouter une catégorie de pagination",
        Project_Category: "Catégorie de projet",
        Email_Configuration: "Configuration e-mail",
        Allowances: "Allocations",
        Default_Approvals: "Approbations par défaut",
        NonMandatory_Approvals: "Approbations non-obligatoires",
        WORKGATING: "Critères de blocage des travaux en cours",
        Job_Description: "Description de poste",
        Rewards: "Récompenses",
        Reward_Category: "Catégorie de récompense",
        Add_Reward_Category: "Ajouter une catégorie de récompense",
        Warning_Category: "Catégorie d'avertissement",
        Add_Warning_Category: "Ajouter une catégorie d'avertissement",
        Holidays: "Jours fériés",
        paymentCard: "Carte de paiement",
        addPaymentCard: "Ajouter une carte de paiement",
        Company_Info: "Informations sur l'entreprise",
        business_Info:" Informations sur l'entreprise",
        Business_Approval: "Approbation d'entreprise",
        ATTENDANCE_SETTINGS: "Paramètres de présence",
        businessInfo : {
            businessInformation: 'Informations sur l\'entreprise',
            companyBackgroundLabel: 'Contexte de l\'entreprise',
            productAndServicesLable: 'Produits et services',
            competitorsLable: 'Concurrents',
            recentNewsLable: 'Actualités récentes',
            leadershipTeamLabel: 'Équipe de direction',
            organizationalStructureLabel: 'Structure organisationnelle',
            customerBaseLabel: 'Base de clients',
            FinancialHealthLabel: 'Santé financière',
            industryLabel: 'Industrie',
            websiteAndSocialMediaLabel: 'Site web et réseaux sociaux',
            additionalCommentLabel: 'Commentaires supplémentaires',
            companyCultureLabel: 'Culture d\'entreprise',
            ceoLabel: 'PDG',
            companyBackground: {
                mission: 'Mission',
                vision: 'Vision',
                values: 'Valeurs',
                YearFounded: 'Année de fondation',
                placeHolders: {
                    enterMission: 'Entrez la mission',
                    enterVision: 'Entrez la vision',
                    enterValues: 'Entrez les valeurs',
                    enterYearFounded: 'Entrez l\'année de fondation',
                },
                requiredMessages: {
                    enterMission: 'Veuillez entrer la mission',
                    enterVision: 'Veuillez entrer la vision',
                    enterValues: 'Veuillez entrer les valeurs',
                    enterYearFounded: 'Veuillez entrer l\'année de fondation',
                }
            },
            productAndService: {
                Benefits: 'Avantages'
            },
            competitors: {
                MarketPosition: 'Position sur le marché',
                Strength: 'Force',
                Weakness: 'Faiblesse',
            },
            recentNews: {
                Headline: 'Titre',
                News: 'Nouvelles'
            },
            leadershipTeam: {
                Background: 'Contexte',
                ContactNumber: 'Numéro de contact',
            },
            financialHealth: {
                Revenue: 'Revenu',
                Profitability: 'Rentabilité'
            },
            additionalComment: {
                label: {
                    comments: 'Commentaires'
                },
                placeholders: {
                    comments: 'Entrez les commentaires'
                },
                requireMessages: {
                    enterComments: 'Veuillez entrer les commentaires'
                }
            },
            comapnyCulture: {
                placeHolders: {
                    enterDescription: 'Entrez la description',
                    enterWorkEnvironment: 'Entrez l\'environnement de travail'
                },
                label: {
                    description: 'Description',
                    workEnvironment: 'Environnement de travail'
                },
                requiredMessages: {
                    enterDescription: 'Veuillez entrer la description',
                    enterWorkEnvironment: 'Veuillez entrer l\'environnement de travail'
                }
            }
        },
        COMPANY_STATISTICS: "Statistiques de l'entreprise",
        Form: {
            Name: "Nom",
            Entername: "Entrez le nom",
            PleaseEnterName: "Veuillez entrer le nom",
            Description: "Description",
            enterDescription: "Veuillez saisir la description",
            Create: "Créer",
            Add: "Ajouter",
            AddBusinessNumber: "Ajouter un numéro d'entreprise",
            AddAllowance: "Ajouter une allocation",
            AddAssetsCategory: "Ajouter une catégorie d'actifs",
            UpdateAssetsCategory: "Mettre à jour la catégorie d'actifs",
            AddBusinessAssociation: "Ajouter une association d'entreprise",
            AddCustomApprovalCategory: "Ajouter une catégorie d'approbation personnalisée",
            AddCustomTag: "Ajouter une étiquette personnalisée",
            AddDesignation: "Ajouter une désignation",
            AddComplain: "Ajouter une plainte",
            Save: "Enregistrer",
            Clear: "Effacer",
            CategoryName: "Nom de la catégorie",
            Entercategoryname: "Entrer le nom de la catégorie",
            SaveCategory: "Enregistrer la catégorie",
            Date: "Date",
            startDate: "date de début",
            endDate: "date de fin",
            Upload: "Télécharger",
            Percent: "Pourcentage",
            Amount: "Montant",
            EnterAmount: "Entrer le montant",
            addCategory: "Ajouter une catégorie",
            SelectMember: "Sélectionner un membre",
            Email: "Email",
            EnterEmail: "Entrer l'email",
            Types: "Types",
            SelectStatus: "Sélectionner le statut",
            Submit: "Soumettre",
            Nomoreapprovals: "Plus d'approbations",
        },
        Table: {
            Name: "Nom",
            Members: "Membres",
            Description: "Description",
            Value: "Valeur",
            Allot_Leave: "Attribuer un congé",
            Type: "Type",
            Unit: "Unité",
            Taxable: "Imposable",
            Provider: "Fournisseur",
            IncomingServerAddress: "Adresse du serveur de réception",
            OutgoingServerAddress: "Adresse du serveur d'envoi",
            IncomingPort: "Port de réception",
            OutgoingPort: "Port d'envoi",
            Action: "Action",
            startDate: "Date de début",
            endDate: "Date de fin",
            Actions: "Actions",
            Account: "Compte",
            approvers: "Approbateurs",
            Status: "Statut",
            Allocated: "Attribué",
            AddMember: "Ajouter un membre",
            CreateDate: "Date de création",
        },
        accessRole: {
            Button: {
                AddAccessRole: "Ajouter un rôle d'accès",
                UpdateAccessRole: "Mettre à jour le rôle d'accès",
            },
            Drawer: {
                CreateAccessRole: "Créer un rôle d'accès",
                UpdateAccessRole: "Mettre à jour le rôle d'accès",
                UserType: "Type d'utilisateur",
                Description: "Description",
                placeholders: {
                    EnterName: "Entrer le nom du rôle d'accès",
                    UserType: "Veuillez sélectionner le type d'utilisateur",
                    Description: "Entrer la description",
                },
                formLabel: {
                    AccessRoleName: "Nom du rôle d'accès",
                },
                placeholders: {
                    EnterName: "Entrer le rôle d'accès",
                    SelectUserType: "Sélectionner le type d'utilisateur",
                    EnterDescription: "Entrer la description",
                },
            },
        },
        grade: {
            AddGrade: "Ajouter un grade",
            AddGradeAllowances: "Ajouter des allocations de grade",
            EnterGrade: "Entrer le grade",
            EnterDescription: "Entrer la description",
            Grade: "Grade",
            Description: "Description",
            Name: "Nom",
            clear: "Effacer",
            saveGrade: "Enregistrer le grade",
        },
        fiscalyear: {
            Add: "Ajouter",
            AddFiscalYear: "Ajouter une année fiscale",
            EnterName: "Entrer le nom",
            EnterDescription: "Entrer la description",
            Fiscalyear: "Année fiscale",
            description: "Description",
            name: "Nom",
            clear: "Effacer",
            save: "Enregistrer",
            startyear: "Année de début",
            endyear: "Année de fin",
            startMonth: "Mois de début",
            endMonth: "Mois de fin",
            startDate: "Date de début",
            endDate: "Date de fin",
        },
        businessPolicy: {
            companypolicy: "Politiques de l'entreprise",
            enterName: "Entrer le nom",
            name: "Nom",
            type: "Sélectionner le type",
            typee: "Type",
            description: "Description",
            EnterDescription: "Entrer la description",
            create: "Créer",
            save: "Enregistrer",
            createPolicy: "Créer une politique",
            updatePolicy: "Mettre à jour la politique",
        },
        taxSlab: {
            TaxSlabs: "Tranches d'impôt",
            UpdateTaxSlab: "Mettre à jour la tranche d'impôt",
            CreateTaxSlab: "Créer une tranche d'impôt",
            NoTaxSlabGroup: "Aucun groupe de tranches d'impôt...",
            enterCountry: "Sélectionner le pays",
            enterName: "Entrer le nom",
            enterDescription: "Entrer la description",
            description: "Description",
            name: "Nom",
            TaxSlab: "Tranche d'impôt",
            title: "Titre",
            enterTitle: "Entrer le titre",
            percentage: "Pourcentage",
            previousCharge: "Charge précédente",
            min: "Min",
            max: "Max",
            enterMin: "Entrer Min",
            enterMax: "Entrer Max",
            enterPercent: "Entrer le Pourcentage",
            save: "Enregistrer",
            clear: "Effacer",
            Add: "Ajouter",
            TaxSlabgroup: "Groupe de tranches d'impôt",
            country: "Pays",
            minimumCharge: "Charge minimum",
        },
        payrollGroup: {
            PayrollGroup: "Groupe de paie",
            AddPayrollGroup: "Ajouter un groupe de paie",
            save: "Enregistrer le groupe",
            clear: "Effacer",
            Add: "Ajouter un groupe",
            name: "Nom",
            enterName: "Entrer le nom",
        },
        subsidiaryOffice: {
            enterName: "Entrer le nom",
            enterAddress: "Entrer l'adresse",
            name: "Nom",
            subsidiary_Office: "Bureau subsidiaire",
            latitude: "Latitude",
            longitude: "Longitude",
            address: "Adresse",
            branch: "Branche",
            Subsidiary: "Filiale",
            select: "Sélectionner la filiale",
            save: "Enregistrer",
            clear: "Effacer",
            AddSubsidiaryOffice: "Ajouter un bureau subsidiaire",
            Add: "Ajouter",
            title: "Titre",
            enterTitle: "Entrer le titre",
        },
        defaultHiringCriteria: {
            default: "Critères d'embauche par défaut",
            question: "Questions",
            enterQuestions: "Entrer la question",
            save: "Enregistrer la question",
            clear: "Effacer",
            Add: "Ajouter une question",
        },
        workGatingCriteria: {
            work: "Critères de filtrage des travaux en cours",
            question: "Questions",
            enterQuestions: "Entrer la question",
            save: "Enregistrer la question",
            clear: "Effacer",
            Add: "Ajouter une question",
            addWork: "Ajouter des critères de filtrage des travaux en cours"
        },
        designation: {
            desig: "Désignation",
            enterdesig: "Entrer la désignation",
            enterName: "Entrer le nom",
            enterDescription: "Entrer la description",
            description: "Description",
            name: "Nom",
            save: "Enregistrer la désignation",
            clear: "Effacer",
            Add: "Ajouter une désignation",
        },
        appraisal: {
            Appraisal: "Évaluation",
            enterAppraisal: "Entrer l'évaluation",
            enterDescription: "Entrer la description",
            description: "Description",
            save: "Enregistrer l'évaluation",
            clear: "Effacer",
            Add: "Ajouter une évaluation",
            CompanyGoal: "Objectif de l'entreprise",
            IndividualGoal: "Objectif individuel",
            DepartmentsGoal: "Objectif du département",
            Goal: "Objectif",
            saveGoal: "Enregistrer l'objectif",
            AddCompanyGoal: "Ajouter un objectif d'entreprise",
            AddDepartmentGoal: "Ajouter un objectif de département",
            AddIndividualGoal: "Ajouter un objectif individuel",
            enterCompanyGoal: "Entrer l'objectif de l'entreprise",
            enterDepartmentGoal: "Entrer l'objectif du département",
            enterIndividualGoal: "Entrer l'objectif individuel",
        },
        office: {
            Office_Timing: "Horaire de bureau",
            group: "Nom du groupe",
            enterGroup: "Entrer le nom",
            enterDescription: "Entrer la description",
            description: "Description",
            save: "Enregistrer les horaires de bureau",
            clear: "Effacer",
            Add: "Ajouter des horaires de bureau",
            days: "Jours",
            work: "Est en activité",
            checkIn: "Heure d'arrivée",
            checkOut: "Heure de départ",
            time: "Temps de grâce",
            min: "Min",
            create: "Créer un groupe",
            new: "Nouveau groupe horaire",
            UpdateOfficeTiming: "Mettre à jour les horaires de bureau",
            CreateOfficeTiming: "Créer des horaires de bureau",
            Monday: "Lundi",
            Tuesday: "Mardi",
            Wednesday: "Mercredi",
            Thursday: "Jeudi",
            Friday: "Vendredi",
            Saturday: "Samedi",
            Sunday: "Dimanche",
        },
        leave: {
            Leave: "Type de congé",
            leaveType: "Type de congé",
            enterLeave: "Entrer le type de congé",
            save: "Enregistrer le type de congé",
            clear: "Effacer",
            Add: "Ajouter un type de congé",
            enterDescription: "Entrer la description",
            description: "Description",
            allotLeaves: "Congés alloués",
            balanceLeaves: "Types de congés (congés restants)",
            selectType: "Sélectionner le type",
            leaveFor: "Congé pour",
            leaveDates: "Dates de congé",
            createleave: "Créer un congé",
        },
        expense: {
            Expense: "En-tête de dépense",
            enterExpense: "Entrer l'en-tête de dépense",
            enterDescription: "Entrer la description",
            description: "Description",
            save: "Enregistrer",
            clear: "Effacer",
            Add: "Ajouter un en-tête de dépense",
        },
        elearningCategory: {
            elearningCategory: "Catégorie de formation en ligne",
            description: "Description",
            enterelearningCategory: "Entrer la catégorie",
            enterDescription: "Entrer la description",
            save: "Enregistrer",
            clear: "Effacer",
            Add: "Ajouter une catégorie de formation en ligne",
        },
        salaryHeader: {
            SalaryHeader: "En-tête de salaire",
            AddSalaryHeader: "Ajouter un en-tête de salaire",
            enterSalaryHeader: "Entrer l'en-tête de salaire",
            desc: "Description",
            enterDesc: "entrer la description",
            saveHeader: "Enregistrer l'en-tête",
            addHeader: "Ajouter un en-tête",
            name: "Nom",
            clear: "Effacer",
        },
        room: {
            Room: "Salle",
            Name: "Nom",
            Entername: "Entrer le nom",
            Description: "Description",
            Enterdescription: "Entrer la description",
            Location: "Emplacement",
            Enterlocation: "Entrer l'emplacement",
            TotalSeats: "Nombre total de sièges",
            Enterseats: "Entrer le nombre de sièges",
            SaveRoom: "Enregistrer la salle",
            AddRoom: "Ajouter une salle",
            clear: "Effacer"
        },
        RebateCategory: {
            rebateCategory: "Catégorie de remise",
            addRebateCategory: "Ajouter une catégorie de remise",
            maxPercentage: "Pourcentage maximum",
            entermaxPercentage: "Entrer le pourcentage maximum",
            maxAmount: "Montant maximum",
            enterMaxAmount: "Entrer le montant maximum",
            type: "Type",
            basic: "De base",
            tax: "Impôt",
            fullAmount: "Montant total",
            rebateType: "Type de remise",
        },
        projectCategory: {
            ProjectCategory: "Catégorie de projet",
            AddProjectCategory: "Ajouter une catégorie de projet",
        },
        pageCategory: {
            Pagecategory: "Catégorie de page",
            AddPageCategory: "Ajouter une catégorie de page",
        },
        NonMandatoryApprovals: {
            NonMandatoryApproval: "Approbations non obligatoires",
        },
        jobdescription: {
            jobDesc: "Description du poste",
            designation: "Désignation",
            selectDesign: "Sélectionner la désignation",
            saveJobDesc: "Enregistrer",
            addJobdesc: "Ajouter une description de poste",
        },
        Holiday: {
            Holidays: "Jours fériés",
            AddHolidays: "Ajouter des jours fériés",
            AddHoliDay: "Ajouter un jour férié"
        },
        GradeAllowance: {
            gradeAllowance: "Allocation de grade",
            Grades: "Niveaux",
            AllowanceType: "Type d'allocation",
            SelectType: "Sélectionner le type",
            AddAllowance: "Ajouter une allocation",
            UpdateAllowance: "Mettre à jour l'allocation",
            Allowance: "Allocation",
            IsTaxable: "Est imposable",
        },
        EmailConfiguration: {
            emailConfi: "Configuration de messagerie électronique",
            enterEmailConfi: "Entrer la configuration de messagerie électronique",
            incomingPort: "Port entrant",
            enterIncomingPort: "Entrer le port entrant",
            incomingServerAddress: "Adresse du serveur de messagerie entrant",
            enterIncomingServerAddress: "Entrer l'adresse du serveur de messagerie entrant",
            outgoingPort: "Port sortant",
            enterOutgoingPort: "Entrer le port sortant",
            outgoingServerAddress: "Adresse du serveur de messagerie sortant",
            enterOutgoingServerAddress: "Entrer l'adresse du serveur de messagerie sortant",
            provider: "Fournisseur",
            enterProvider: "Entrer le fournisseur",
            saveEmailConfi: "Enregistrer la configuration de messagerie électronique",
            addEmailConfi: "Ajouter une configuration de messagerie électronique",
        },
        DiscussionBoardCategory: {
            discussionBoardCategory: "Catégorie du forum de discussion",
            SaveDiscussionBoardCategory: "Enregistrer la catégorie du forum de discussion",
            AddDiscussionBoardCategory: "Ajouter une catégorie du forum de discussion",
            Save: "Enregistrer",
            Clear: "Effacer",
            Update: "Mettre à jour",
        },
        DefaultApprovers: {
            defaultApprovers: "Approbateurs par défaut",
        },
        DefaultAccountHeader: {
            defaultAccountHeader: "En-tête de compte par défaut",
            SaveAccountHeader: " Enregistrer l'en-tête de compte",
            SelectAccountTypes: "Sélectionner les types de compte",
        },
        CustomTag: {
            customTag: "Étiquette personnalisée",
            Entercustomtag: "Entrer l'étiquette personnalisée",
            SaveCustomTag: "Enregistrer l'étiquette personnalisée",
            AddCustomTag: "Ajouter une étiquette personnalisée",
        },
        customApprovalCategory: {
            customApprovalcategory: "Catégorie d'approbation personnalisée",
            enterCustomApprovalcategory: "Entrer la catégorie d'approbation personnalisée",

        },
        BusinessNumber: {
            Manual: "Manuel",
            TotalNumber: "Nombre total",
            Country: "Pays",
            SelectCountry: "Sélectionner le pays",
            Number: "Nombre",
            Random: "Aléatoire",
            SelectNumber: "Sélectionner le nombre",
            Cost: "Coût",
            Request: "Demande",
        },
        BusinessAssociation: {
            businessAssociation: "Association d'entreprise",
            SaveBusinessAssociation: "Enregistrer l'association d'entreprise",
            AddBusinessAssociation: "Ajouter une association d'entreprise",
            AssociationType: "Type d'association",
        },
        AssetsCategory: {
            assetsCategory: "Catégorie d'actifs",
            categoryName: "Nom de la catégorie",
            enterCategoryName: "Entrer le nom de la catégorie",
            desc: "Description",
            enterDesc: "Entrer la description",
            accountType: "Type de compte",
            parentAccount: "Compte parent",
            selectParent: "Sélectionner le parent",
            selectType: "Sélectionner le type",
            add: "Ajouter",
            save: "Enregistrer",
            clear: "Effacer",
            name: "Nom",
            assets: "Actif",
            liability: "Responsabilité",
            capital: "Capital",
            revenue: "Revenu",
            expense: "Dépense",
            costOfGoodsale: "Coût des biens vendus",
        },
        Allowance: {
            allowance: "Allocation",
            enterAllownace: "Entrer l'allocation",
            percent: "Pourcentage",
            desc: "Entrer la description",
            grade: "Grade",
            selectGrade: "Sélectionner le grade",
            amount: "Montant",
            benefit: "Avantage",
            deduction: "Déduction",
            texable: "Imposable",
            nonTexable: "Non imposable",
            director: "Directeur",
            ceo: "PDG",
            addAllowance: "Ajouter une allocation",
            saveAllowance: "Enregistrer l'allocation",
            clear: "Effacer",
            name: "Nom",
            allowanceType: "Type d'allocation",
            allowanceUnit: "Unité d'allocation",
            istexable: "Est imposable",
            yes: "Oui",
            no: "Non",
        },
        CompanyInfo: {
            businessLogo: "Logo d'entreprise",
            basicinfo: "Informations de base",
            ShortLogo: "Logo court",
            Logo: "Logo",
            FullLogo: "Logo complet",
            companyName: "Nom de l'entreprise",
            PleaseEnterCompanyName: "Veuillez entrer le nom de l'entreprise",
            enterCompanyName: "Entrer le nom de l'entreprise",
            companyEmail: "Entrer l'email de l'entreprise",
            PleaseEnterCompanyEmail: "Veuillez entrer l'email de l'entreprise",
            SelectOwnerName: "Sélectionner le nom du propriétaire",
            totalEmployees: "Total des employés",
            totalEmployeesInCall: "Total des employés en appel",
            SaveShortLogo: "Enregistrer le logo court",
            LongLogo: "Logo long",
            SaveLongLogo: "Enregistrer le logo long",
            SaveFullLogo: "Enregistrer le logo complet",
        },
        billing: {
            Invoice: "Facture",
            InvoiceNo: "Numéro de facture",
            CustomerName: "Nom du client",
            PayInvoice: "Payer la facture",
            Payment: "Paiement",
            BillNo: "Numéro de facture",
            CardLast: "4 derniers chiffres de la carte",
            BusinessName: "Nom de l'entreprise",
            CardHolder: "Titulaire de la carte",
            Viewinvoicedetails: "Voir les détails de la facture et du paiement",
            Viewinvoicedetail: "Voir les détails de la facture",
            Qty: "Qté",
            BillType: "Type de facture"
        },
        PaymentCard: {
            paymentCard: "Carte de paiement",
            CardHolderName: "Nom du titulaire de la carte",
            AddCard: "Ajouter une carte",
            PayInvoice: "Payer la facture",
            CardHolderNameRequired: "Le nom du titulaire de la carte est requis.",
            PayCard: "Payer avec la carte",
            payanotherway: "Ou payer d'une autre manière",
            credentialstocontinue: "Entrez vos informations d'identification pour continuer.",
            Expiry: "Expiration",
            Brand: "Marque",
            Country: "Pays",
            LastFourDigits: "4 derniers chiffres",
        },
    },
    billing: {
        BillType: "Type de facture",
        billingTypeEnum: {
            [BillingTypeEnum.NewUser]: "Nouvel utilisateur",
            [BillingTypeEnum.MonthlySubscription]: "Abonnement mensuel",
            [BillingTypeEnum.NewBusinessNumber]: "Nouveau numéro d'entreprise",
        }
    },
    businessNumber: {
        statusEnum: {
            [BuisnessNumberStatusEnum.Waiting]: "En attente",
            [BuisnessNumberStatusEnum.Declined]: "Refusé",
            [BuisnessNumberStatusEnum.NumberCancelled]: "Numéro annulé",
            [BuisnessNumberStatusEnum.Purchased]: "Acheté",
            [BuisnessNumberStatusEnum.RequestCancelled]: "Demande annulée",
            [BuisnessNumberStatusEnum.Rejected]: "Rejeté",
        },
    },
    appraisal: {
        Appraisals: "Évaluations",
        createAppraisals: "Créer des évaluations",
        noDataMessage: "– Créez des évaluations maintenant !",
        Approvers: "Approbateurs",
        Questions: "Questions",
        Tasks: "Tâches",
        Bonus: "Bonus",
        Increment: "Augmentation",
        previousApraisals: "Évaluations précédentes",
        warning: "Attention",
        rewards: "Récompenses",
        courses: "Cours",
        Promotion: "Promotion",
        PleaseEnterBonusPercentage: "Veuillez saisir le pourcentage de bonus",
        validateIncrement: "Impossible de traiter l'augmentation : aucune information salariale n'est disponible pour l'employé",
        PleaseEnterBonusAmount: "Veuillez saisir le montant du bonus",
        PleaseEnterIncrementAmount: "Veuillez saisir le montant de l'augmentation",
        RateAtleastOneItem: "Notez au moins un élément",
        Noemployeeselected: "Aucun employé sélectionné",
        submitAppraisal: "Soumettre les évaluations",
        Appraisedof: "Évalué de",
        AppraisalApprovers: "Approbateurs de l'évaluation",
        AppraisalDetail: "Détail de l'évaluation",
        appraisalFilterTypeEnum: {
            [AppraisalFilterEnum.All]: "Toutes les évaluations",
            [AppraisalFilterEnum.CreatedByMe]: "Créées par moi",
            [AppraisalFilterEnum.ForApproval]: "En attente d'approbation",
            [AppraisalFilterEnum.TeamAppraisal]: "Évaluation d'équipe",
            [AppraisalFilterEnum.MyApprovedAppraisal]: "Mes évaluations approuvées",
        },
        statusEnum: {
            [AppraisalStatusEnum.InProcess]: "En cours",
            [AppraisalStatusEnum.Approved]: "Approuvé",
            [AppraisalStatusEnum.Declined]: "Refusé",
            [AppraisalStatusEnum.Cancelled]: "Annulé",
            [AppraisalStatusEnum.Hold]: "En attente",
        },
        SortEnum: {
            [AppraisalFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [AppraisalFilterSortEnum.CreateDateAsc]: "Date de création",
            [AppraisalFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Décroissant",
            [AppraisalFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [AppraisalFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Décroissant",
            [AppraisalFilterSortEnum.ReferenceNo]: "Numéro de référence",
        }
    },
    loan: {
        loan: "Prêt",
        createLoan: "Créer un prêt",
        loanTenure: "Durée du prêt (en mois)",
        enterLoanTenure: "Entrez la durée du prêt (en mois)",
        deduction: "Déduction/Mois",
        selectLoanPurpose: "Sélectionnez le but du prêt",
        noDataMessage: "– Créez des prêts maintenant!",
        SortBy: "Trier par",
        SelectSortBy: "Sélectionner Trier par",
        InterestRate: "Taux d'intérêt",
        loanPurpose: {
            loanPurpose: "But du prêt",
            vehicle: "Véhicule",
            personal: "Personnel",
            wedding: "Mariage",
            medical: "Médical",
            education: "Éducation",
            house: "Maison",
            other: "Autre",
        },
        loanPurposeEnumList: {
            [LoanPurposeEnum.Personal]: "Personnel",
            [LoanPurposeEnum.Wedding]: "Mariage",
            [LoanPurposeEnum.Medical]: "Médical",
            [LoanPurposeEnum.Education]: "Éducation",
            [LoanPurposeEnum.House]: "Maison",
            [LoanPurposeEnum.Vehicle]: "Véhicule",
            [LoanPurposeEnum.Other]: "Autre",
        },
        loanFilterTypeEnum: {
            [LoanFilterTypeEnum.All]: "Tous les prêts",
            [LoanFilterTypeEnum.ForApproval]: "En attente d'approbation",
            [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "Mes prêts",
            [LoanFilterTypeEnum.LoanForMe]: "Prêt pour moi",
        },
        statusEnum: {
            [LoanStatusEnum.InProcess]: "En cours",
            [LoanStatusEnum.Approved]: "Approuvé",
            [LoanStatusEnum.Declined]: "Refusé",
            [LoanStatusEnum.Cancelled]: "Annulé",
            [LoanStatusEnum.Disbursed]: "Décaissé",
            [LoanStatusEnum.PartialCleared]: "Partiellement réglé",
            [LoanStatusEnum.Completed]: "Terminé",
            [LoanStatusEnum.Hold]: "En attente",
            [LoanStatusEnum.WaitingForDisburse]: "En attente de décaissement",
        },
        loanTypeEnum: {
            [LoanTypeEnum.Company]: "Prêt d'entreprise",
            [LoanTypeEnum.WorkWise]: "Prêt Workwise",
            [LoanTypeEnum.Both]: "Les deux",
        },
        loanFilterSortEnum: {
            [LoanFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [LoanFilterSortEnum.CreateDateAsc]: "Date de création",
            [LoanFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Décroissant",
            [LoanFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [LoanFilterSortEnum.ReferenceNo]: "Numéro de référence",
        },
    },
    quotations: {
        Quotation: "Devis",
        CreateQuotation: "Créer un devis",
        quotationDate: "Date",
        ClientName: "Nom du client",
        ClientEmail: "E-mail du client",
        PhoneNumber: "Numéro de téléphone",
        TotalAmount: "Montant total",
        QuotationType: "Type",
        Rate: "Taux",
        Quantity: "Quantité",
        Attachments: "Pièces jointes",
        serviceItem: "Service/Article",
        price: "Prix",
        tax: "Taxe (%)",
        taxAmount: "Montant de la taxe",
        sno: "N°",
        EnterClientName: "Entrer le nom du client",
        clientsName: "Nom du client",
        EnterClientEmail: "Entrer l'e-mail du client",
        addressLine1: "Adresse ligne 1",
        addressLine2: "Adresse ligne 2",
        EnterAddress1: "Entrer l'adresse 1 :",
        EnterAddress2: "Entrer l'adresse 2 :",
        Product: "Produit",
        Service: "Service",
        netAmount: "Montant net",
        CODE: "CODE",
        DATE: "DATE",
        TOTAL: "TOTAL",
        To: "À",
        SubTotal: "Sous-total",
        noDataMessage: "– Créez un devis maintenant !",

        quotationFilterTypeEnum: {
            [QuotationFilterEnum.All]: "Devis",
            [QuotationFilterEnum.CreatedByMe]: "Créés par moi",
            [QuotationFilterEnum.ForApproval]: "En attente d'approbation",
        },
        statusEnum: {
            [QuotationStatusEnum.InProcess]: "En cours",
            [QuotationStatusEnum.Approved]: "Approuvé",
            [QuotationStatusEnum.Declined]: "Refusé",
            [QuotationStatusEnum.Cancelled]: "Annulé",
            [QuotationStatusEnum.Hold]: "En attente",
            [QuotationStatusEnum.ClientApproved]: "Approuvé par le client",
            [QuotationStatusEnum.ClientRejected]: "Rejeté par le client",
        },
        SortEnum: {
            [QuotationFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [QuotationFilterSortEnum.CreateDateAsc]: "Date de création - Croissant",
            [QuotationFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Décroissant",
            [QuotationFilterSortEnum.UpdateDateAsc]: "Date de mise à jour - Croissant",
            [QuotationFilterSortEnum.ReferenceNo]: "Numéro de référence",
            [QuotationFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Décroissant"
        },
        ErrorFieldMessage:{
            [InputFieldTypes.PRICE]: "Le prix doit être supérieur à 0",
            [InputFieldTypes.QUANTITY]: "La quantité doit être supérieure à 0",
            [InputFieldTypes.TAX]: "La taxe doit être supérieure à 0",
          },
          singleItemError: "Veuillez ajouter au moins un article au devis",
    },
    payroll: {
        Payroll: "Paie",
        createPayroll: "Créer une paie",
        noDataMessage: "– Créez une paie maintenant !",
        basicSalary: "Salaire de base",
        loan: "Prêt",
        allowance: "Allocation",
        ExpenseReimbursement: "Remboursement des frais",
        deduction: "Déduction",
        tax: "Taxe",
        rebate: "Rabais",
        bonus: "Bonus",
        selectMonthYear: "Sélectionner le mois et l'année",
        disburseDate: "Date de décaissement",
        Voucher: "Bon de caisse",
        DownloadtoExcel: "Télécharger en Excel",
        PayrollDetail: "Détail de la paie",
        TransactionMode: "Mode de transaction",
        IBANNumber: "Numéro IBAN",

        statusEnum: {
            [PayrollStatusEnum.InProcess]: "En cours",
            [PayrollStatusEnum.Approved]: "Approuvé",
            [PayrollStatusEnum.Declined]: "Refusé",
            [PayrollStatusEnum.Resend]: "Renvoyer",
            [PayrollStatusEnum.Inactive]: "Inactif",
            [PayrollStatusEnum.NotRequired]: "Non requis",
            [PayrollStatusEnum.Cancelled]: "Annulé",
            [PayrollStatusEnum.Hold]: "En attente",
            [PayrollStatusEnum.Disbursed]: "Décaissé",
            [PayrollStatusEnum.Completed]: "Terminé",
        },
        payrollFilterTypeEnum: {
            [PayrollFilterTypeEnum.All]: "Paie",
            [PayrollFilterTypeEnum.CreatedByMe]: "Créés par moi",
            [PayrollFilterTypeEnum.ForApproval]: "En attente d'approbation",
        },
    },
    voucher: {
        createVoucher: "Créer un bon de caisse",
        SelectVoucherType: "Sélectionner le type de bon de caisse",
        BasicSalary: "Salaire de base",
        LoanPayment: "Paiement de prêt",
        LoanDispersable: "Montant dispersé du prêt",
        Allowance: "Allocation",
        ExpenseReimbursement: "Remboursement des frais",
        Deduction: "Déduction",
        VoucherDetail: "Détail du bon de caisse",
        SavePrint: "Enregistrer et imprimer",
        Bonus: "Bonus",
        Rebate: "Rabais",
        Tax: "Taxe",
        ChequeNo: "Numéro de chèque",
        Account: "Compte",
        Narration: "Narration",
        Debit: "Débit",
        Credit: "Crédit",
        Dr: "Déb.",
        Cr: "Créd.",
        differences: "Différences",
        drCr: "Déb./Créd.",
        LinkVoucher: "Lier le bon de caisse",
        PostVoucher: "Publier le bon de caisse",
        voucherDate: "Date du bon de caisse",
        VoucherType: "Type de bon de caisse",

        voucherTypeList: {
            [voucherTypeEnum.GeneralVoucher]: "Bon de caisse général",
            [voucherTypeEnum.PaymentVoucher]: "Bon de caisse de paiement",
            [voucherTypeEnum.ReceiptVoucher]: "Bon de caisse de réception",
        },
        accountTypeList: {
            [accountTypeEnum.Asset]: "Actif",
            [accountTypeEnum.Capital]: "Capital",
            [accountTypeEnum.Expense]: "Dépense",
            [accountTypeEnum.Liability]: "Passif",
            [accountTypeEnum.Revenue]: "Revenu",
        }
    },
    trialBalance: {
        accountTypeList: {
            [AccountTypeEnum.Asset]: "Actif",
            [AccountTypeEnum.Capital]: "Capital",
            [AccountTypeEnum.Expense]: "Dépense",
            [AccountTypeEnum.Liability]: "Passif",
            [AccountTypeEnum.Revenue]: "Revenu",
        }
    },
    businessApproval: {
        statusTypeList: {
            [ApprovalStatusTypeEnum.InProcess]: "En cours",
            [ApprovalStatusTypeEnum.Approved]: "Approuvé",
            [ApprovalStatusTypeEnum.Declined]: "Refusé",
            [ApprovalStatusTypeEnum.Cancelled]: "Annulé",
            [ApprovalStatusTypeEnum.Completed]: "Terminé",
        }
    },
    disperse: {
        transactionModeEnumList: {
            [TransactionModeTypeEnum.Cash]: "Espèces",
            [TransactionModeTypeEnum.BankTransfer]: "Virement bancaire",
            [TransactionModeTypeEnum.Cheque]: "Chèque",
        },
    },
    promotion: {
        CreatePromotion: "Créer une promotion",
        Promotion: "Promotion",
        promotionTo: "Promotion à",
        noDataMessage: "Créez une promotion maintenant !",
        selectPromotionTo: "Sélectionner la promotion à",
        pleseSelectPromotionTo: "Veuillez sélectionner la promotion à",
        createPromotion: "Créer une promotion",
        promotionFilterTypeEnum: {
            [PromotionFilterTypeEnum.All]: "Toutes les promotions",
            [PromotionFilterTypeEnum.CreatedByMe]: "Créées par moi",
            [PromotionFilterTypeEnum.ForApproval]: "Pour approbation",
            [PromotionFilterTypeEnum.PromotionForMe]: "Mes promotions",
        },
        statusEnum: {
            [PromotionStatusEnum.InProcess]: "En cours",
            [PromotionStatusEnum.Approved]: "Approuvé",
            [PromotionStatusEnum.Declined]: "Refusé",
            [PromotionStatusEnum.Cancelled]: "Annulé",
            [PromotionStatusEnum.Hold]: "En attente",
        },
        SortEnum: {
            [PromotionFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Descendante",
            [PromotionFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [PromotionFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Descendant",
            [PromotionFilterSortEnum.ReferenceNo]: "Numéro de référence",
            [PromotionFilterSortEnum.CreateDateDesc]: "Date de création - Descendante",
            [PromotionFilterSortEnum.CreateDateAsc]: "Date de création",
        }
    },
    complains: {
        createComplain: "Créer une plainte",
        myComplain: "Mes plaintes",
        AllComplains: "Toutes les plaintes",
        complainOf: "Plainte de",
        ComplainDetail: "Détail de la plainte",
        ComplainToMe: "Plainte à moi",
        ComplainAboutMe: "Plainte à propos de moi",
        noDataMessage: " – Créez des plaintes maintenant !",
        statusEnum: {
            [ComplainApprovalStatusEnum.InProcess]: "En cours",
            [ComplainApprovalStatusEnum.Approved]: "Approuvée",
            [ComplainApprovalStatusEnum.Declined]: "Refusée",
            [ComplainApprovalStatusEnum.Resend]: "Renvoyée",
            [ComplainApprovalStatusEnum.Inactive]: "Inactive",
            [ComplainApprovalStatusEnum.NotRequired]: "Non requis",
            [ComplainApprovalStatusEnum.Cancelled]: "Annulée",
            [ComplainApprovalStatusEnum.Hold]: "En attente",
        },
        SortEnum: {
            [ComplainFilterSortEnum.CreateDateDesc]: "Date de création - Descendante",
            [ComplainFilterSortEnum.CreateDateAsc]: "Date de création",
            [ComplainFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Descendante",
            [ComplainFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [ComplainFilterSortEnum.ReferenceNo]: "Numéro de référence",
            [ComplainFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Descendant"
        },
    },
    salary: {
        SalaryMembers: "Membres du salaire",
        EffectiveDate: "Date d'effet",
        BasicSalary: "Salaire de base",
        Allowances: "Indemnités",
        Allowance: "Indemnité",
        AllowanceDetail: "Détail de l'indemnité",
        IsTaxable: "Est imposable",
        Deduction: "Déduction",
        GrossSalary: "Salaire brut",
        CreateSalary: "Créer un salaire",
        SalaryDetail: "Détail du salaire",
        AddSalary: "Ajouter un salaire",
        Increment: "Augmentation",
        Deductions: "Déductions",
        AllowanceType: "Type d'indemnité",
        EffectiveDateIsRequired: "La date d'effet est requise",
        BasicSalaryIsRequired: "Le salaire de base est requis",
        Negativesalaryisnotallowed: "Un salaire négatif n'est pas autorisé",
        Benefit: "Avantage",
        noDataMessage: "– Créez un salaire maintenant !",
        EnterIncrementPercentage: "Veuillez entrer le pourcentage d'augmentation",
        salaryFilterTypeEnum: {
            [SalaryFilterTypeEnum.Salaries]: "Salaires",
            [SalaryFilterTypeEnum.CreateByMe]: "Créé par moi",
            [SalaryFilterTypeEnum.ForApproval]: "En attente d'approbation",
        },
        statusEnum: {
            [EmployeeSalaryStatusEnum.InProcess]: "En cours",
            [EmployeeSalaryStatusEnum.Approved]: "Approuvé",
            [EmployeeSalaryStatusEnum.Declined]: "Refusé",
            [EmployeeSalaryStatusEnum.Cancelled]: "Annulé",
            [EmployeeSalaryStatusEnum.Hold]: "En attente",
            [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "En attente d'une autre approbation",
        },
        SortEnum: {
            [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Date de création - Descendante",
            [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Date de création",
            [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Descendante",
            [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Descendant",
            [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Numéro de référence",

        }
    },
    resignations: {
        resignationHeader: "Démissions",
        resignationDate: "Date de démission",
        createresignation: "Créer une démission",
        noDataMessage: " – Créez une démission maintenant !",
        reasonforresignation: "Raison de la démission",
        istermination: "Est une résiliation",
        resignationDetail: "Détail de la démission",
        statusEnum: {
            [ResignationApprovalStatusEnum.InProcess]: "En cours",
            [ResignationApprovalStatusEnum.Approved]: "Approuvée",
            [ResignationApprovalStatusEnum.Declined]: "Refusée",
            [ResignationApprovalStatusEnum.Cancelled]: "Annulée",
            [ResignationApprovalStatusEnum.Completed]: "Terminée",
            [ResignationApprovalStatusEnum.Hold]: "En attente",
        },
        resignationFilterTypeEnum: {
            [ResignationFilterTypeEnum.Resignation]: "Démission",
            [ResignationFilterTypeEnum.CreateByMe]: "Créée par moi",
            [ResignationFilterTypeEnum.ForApproval]: "Pour approbation",
        },
        resignationTypeEnum: {
            [ResignationTypeEnum.Resignation]: "Démission",
            [ResignationTypeEnum.Termination]: "Résiliation",
            [ResignationTypeEnum.Both]: "Les deux",
        },
        SortEnum: {
            [ResignationFilterSortEnum.CreateDateDesc]: "Date de création - Descendante",
            [ResignationFilterSortEnum.CreateDateAsc]: "Date de création",
            [ResignationFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Descendante",
            [ResignationFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [ResignationFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Descendant",
            [ResignationFilterSortEnum.ReferenceNo]: "Numéro de référence",
        },
        ResignationPurposeEnum: {
            [ResignationPurposeEnum.InsufficientWorkLifeBalance]: "Déséquilibre entre vie professionnelle et personnelle insuffisant",
            [ResignationPurposeEnum.UnrealisticGoalsAndPerformanceObjectives]: "Objectifs et objectifs de performance irréalistes",
            [ResignationPurposeEnum.LackOfAClearPathForCareerAdvancement]: "Manque d'un parcours clair pour l'avancement professionnel",
            [ResignationPurposeEnum.FeelUnsupportedByManage]: "Sentiment de ne pas être soutenu par la direction",
            [ResignationPurposeEnum.DontFeelChallenged]: "Ne se sent pas suffisamment stimulé",
            [ResignationPurposeEnum.TimeOffAndFlexibility]: "Temps libre et flexibilité",
            [ResignationPurposeEnum.Relocation]: "Déménagement",
            [ResignationPurposeEnum.Others]: "Autres",
        },
    },
    Employees: {
        employeeAttachments: "Pièces jointes des employés",
        CreateEmployeeNow: "Créer un employé maintenant",
        ProbationPeriod: "Période d'essai (jours)",
        requiredMessageProbation: "Veuillez entrer votre période d'essai",
        requiredMessageProbationPeriod: "La période d'essai doit être comprise entre 0 et 30",
        EmployeeNo: "Numéro d'employé",
        EnterEmployeeNo: "Entrer le numéro d'employé",
        EmploymentType: "Type d'emploi",
        SelectEmploymentType: "Sélectionnez le type d'emploi",
        requiredMessageEmploymentType: "Veuillez sélectionner le type d'emploi",
        OfficeShift: "Horaire de bureau",
        requiredMessageOfficeShift: "Veuillez sélectionner le quart de travail",
        SelectOfficeShift: "Sélectionnez le quart de travail",
        UpdateBasicInfo: "Mettre à jour les informations de base",
        Degree: "Degré",
        EnterDegree: "Entrer le degré",
        requiredDegree: "Veuillez entrer le degré",
        Institute: "Institut",
        EnterInstituteName: "Entrer le nom de l'institut",
        requiredMessageInstitute: "Veuillez entrer le nom de l'institut",
        ObtainedMarks: "Notes obtenues",
        requiredMessageObtained: "Veuillez entrer les notes obtenues",
        EnterObtainedMarks: "Entrer les notes obtenues",
        TotalMarks: "Total des points",
        EnterTotalMarks: "Entrer le total des points",
        requiredMessageMarks: "Veuillez entrer le total des points",
        UpdateEducation: "Mettre à jour l'éducation",
        AddEducation: "Ajouter l'éducation",
        WorkExperienceInfo: "Informations sur l'expérience professionnelle",
        UpdateExperience: "Mettre à jour l'expérience",
        AddExperience: "Ajouter de l'expérience",
        EmergencyInfo: "Informations d'urgence",
        UpdateEmergencyForm: "Mettre à jour le formulaire d'urgence",
        AddEmergency: "Ajouter une urgence",
        UpdateEmergency: "Mettre à jour les urgences",
        UpdateBank: "Mettre à jour la banque",
        AddBank: "Ajouter une banque",
        EnableEmployee: "Êtes-vous sûr de vouloir activer cet employé ?",
        DisableEmployee: "Êtes-vous sûr de vouloir désactiver cet employé ?",
        Enable: "Activer",
        Disabled: "Désactivé",
        UpdateFamily: "Mettre à jour la famille",
        AddFamily: "Ajouter une famille",
        AddEmployeesLinkage: "Ajouter un lien d'employés",
        AllEmployees: "Tous les employés",
        FamilyInfo: "Informations sur la famille",
        Basic_Info: "Informations de base",
        Email_Configuration: "Configuration e-mail",
        Bank_Detail: "Détails bancaires",
        Education: "Éducation",
        Emergency_Info: "Informations d'urgence",
        Work_Experience: "Expérience professionnelle",
        Experience: "Expérience",
        Rebate: "Remise",
        Salary: "Salaire",
        Leaves: "Congés",
        Devices: "Appareils",
        basicInfo: "Informations de base",
        Appraisal: "Évaluation",
        Family: "Famille",
        Attachments: "Pièces jointes",
        My_Team: "Mon équipe",
        Employees_Linkage: "Lien d'employés",
        Company_Goal: "Objectif de l'entreprise",
        Individual_Goal: "Objectif individuel",
        Emails: "E-mails",
        Finance: "Finance",
        Goal: "Objectifs",
        RebateInfo: "Informations sur la remise",
        UpdateRebate: "Mettre à jour la remise",
        AddRebate: "Ajouter une remise",
        AddSalary: "Ajouter un salaire",
        AddEmailConfiguration: "Ajouter une configuration e-mail",
        AddBank: "Ajouter des détails bancaires",
        Statistics: "Statistiques"
    },
    leaves: {
        leave: "Congés",
        leavedetail: "Détails du congé",
        Leave: "Type de congé",
        leaveType: "Type de congé",
        enterLeave: "Entrez le type de congé",
        save: "Enregistrer le type de congé",
        Add: "Ajouter un type de congé",
        allotLeaves: "Congés attribués",
        balanceLeaves: "Types de congés (congés restants)",
        leaveFor: "Congé pour",
        leaveDates: "Dates de congé",
        createleave: "Créer un congé",
        messages: "Veuillez sélectionner un type de congé",
        allocatedLeaves: "Congés attribués",
        availedLeaves: "Congés pris",
        remainingLeaves: "Congés restants",
        noDataMessage: " - Créez un congé maintenant !",
        Leavesinfo: "Informations sur les congés",
        UpdateLeave: "Mettre à jour le congé",
        leavesFilterTypeEnum: {
            [LeaveFilterTypeEnum.All]: "Tous les congés",
            [LeaveFilterTypeEnum.CreatedByMe]: "Créés par moi",
            [LeaveFilterTypeEnum.ForApproval]: "En attente d'approbation",
            [LeaveFilterTypeEnum.LeaveForMe]: "Mes congés",
        },
        statusEnum: {
            [LeaveStatusEum.InProcess]: "En cours",
            [LeaveStatusEum.Approved]: "Approuvé",
            [LeaveStatusEum.Declined]: "Refusé",
            [LeaveStatusEum.Resend]: "Renvoyer",
            [LeaveStatusEum.InActive]: "Inactif",
            [LeaveStatusEum.NotRequired]: "Non requis",
            [LeaveStatusEum.Cancelled]: "Annulé",
            [LeaveStatusEum.Hold]: "En attente",
        },
        SortEnum: {
            [LeaveFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [LeaveFilterSortEnum.CreateDateAsc]: "Date de création",
            [LeaveFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Décroissant",
            [LeaveFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [LeaveFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Décroissant",
            [LeaveFilterSortEnum.ReferenceNo]: "Numéro de référence",
        }
    },
    bonus: {
        Bonus: "Bonus",
        createBonus: "Créer un bonus",
        BonusTo: "Bonus à",
        CurrentSalary: "Salaire actuel",
        noDataMessage: " - Créez un bonus maintenant !",
        PercentageShouldNotExceed: "Le pourcentage ne doit pas dépasser 100",
        bonusFilterTypeEnum: {
            [BonusFilterTypeEnum.All]: "Tous les bonus",
            [BonusFilterTypeEnum.CreatedByMe]: "Créés par moi",
            [BonusFilterTypeEnum.ForApproval]: "En attente d'approbation",
            [BonusFilterTypeEnum.MyBonus]: "Mes bonus",
        },
        statusEnum: {
            [BonusStatusEnum.InProcess]: "En cours",
            [BonusStatusEnum.Approved]: "Approuvé",
            [BonusStatusEnum.Declined]: "Refusé",
            [BonusStatusEnum.Cancelled]: "Annulé",
            [BonusStatusEnum.Inactive]: "Inactif",
            [BonusStatusEnum.NotRequired]: "Non requis",
            [BonusStatusEnum.Resend]: "Renvoyer",
            [BonusStatusEnum.WaitingForOtherApproval]: "En attente d'approbation",
            [BonusStatusEnum.Hold]: "En attente",
            [BonusStatusEnum.Disbursed]: "Distribué",
            [BonusStatusEnum.Completed]: "Terminé",
        },

        SortEnum: {
            [BonusFilterSortEnum.CreateDateDesc]: "Date de création - Décroissant",
            [BonusFilterSortEnum.CreateDateAsc]: "Date de création",
            [BonusFilterSortEnum.UpdateDateDesc]: "Date de mise à jour - Décroissant",
            [BonusFilterSortEnum.UpdateDateAsc]: "Date de mise à jour",
            [BonusFilterSortEnum.ReferenceNoDesc]: "Numéro de référence - Décroissant",
            [BonusFilterSortEnum.ReferenceNo]: "Numéro de référence",
            [BonusFilterSortEnum.Amount]: "Montant",
            [BonusFilterSortEnum.AmountDesc]: "Montant - Décroissant",
            [BonusFilterSortEnum.Salary]: "Salaire",
            [BonusFilterSortEnum.SalaryDesc]: "Salaire - Décroissant"
        }
    },
    chartOfAccount: {
        ChartOfAccount: "Plan comptable",
        CreateAccount: "Créer un compte",
        CreateChartOfAccount: "Créer un plan comptable",
        accountType: "Type de compte",
        selectType: "Sélectionner le type",
        parentAccount: "Compte parent",
        selectParent: "Sélectionner le parent",
        EnterAccountType: "Veuillez saisir le type de compte",
        clossingBalance: "Solde de clôture",
    },
    ledgerReports: {
        reports: "Rapports",
        selectAccount: "Sélectionner un compte",
        selectDate: "Sélectionner une date",
        generateReport: "Générer le rapport",
        VoucherNo: "Numéro de pièce",
    },
    requisitions: {
        requisition: "Réquisitions",
        MyRequisitions: "Mes réquisitions",
        createRequisition: "Créer une réquisition",
        PleaseEnterRequisitionName: "Veuillez saisir le nom de la réquisition",
        EnterRequisitionName: "Saisir le nom de la réquisition",
        Budget: "Budget",
        EnterBudget: "Saisir le budget",
        Dateofrequestexpire: "Date d'expiration de la demande",
        noDataMessage: "– Créez des réquisitions maintenant !",
    },
    DefaultApprovers: {
        TypeEnum: {
            [DefaultApprovalTypeEnum.Expense]: "Dépense",
            [DefaultApprovalTypeEnum.ExpenseFinancers]: "Financiers de dépenses",
            [DefaultApprovalTypeEnum.Travel]: "Voyage",
            [DefaultApprovalTypeEnum.TravelAgent]: "Agent de voyage",
            [DefaultApprovalTypeEnum.Loan]: "Prêt",
            [DefaultApprovalTypeEnum.Leave]: "Congé",
            [DefaultApprovalTypeEnum.Asset]: "Actif",
            [DefaultApprovalTypeEnum.Salary]: "Salaire",
            [DefaultApprovalTypeEnum.Payroll]: "Paie",
            [DefaultApprovalTypeEnum.Reward]: "Récompense",
            [DefaultApprovalTypeEnum.ResignationHr]: "Démission RH",
            [DefaultApprovalTypeEnum.ResignationAdmin]: "Démission Administration",
            [DefaultApprovalTypeEnum.ResignationIt]: "Démission IT",
            [DefaultApprovalTypeEnum.ResignationFinance]: "Démission Finance",
            [DefaultApprovalTypeEnum.ResignationExit]: "Démission Sortie",
            [DefaultApprovalTypeEnum.Requisition]: "Demande",
            [DefaultApprovalTypeEnum.CustomApproval]: "Approbation personnalisée",
            [DefaultApprovalTypeEnum.Forms]: "Formulaires",
            [DefaultApprovalTypeEnum.Appraisals]: "Évaluations",
            [DefaultApprovalTypeEnum.Promotion]: "Promotion",
            [DefaultApprovalTypeEnum.Warning]: "Avertissement",
            [DefaultApprovalTypeEnum.Bonus]: "Bonus",
            [DefaultApprovalTypeEnum.Complains]: "Plaintes",
            [DefaultApprovalTypeEnum.Career]: "Carrière",
            [DefaultApprovalTypeEnum.Quotations]: "Devis",
            [DefaultApprovalTypeEnum.RequisitionFinal]: "Demande finale",
            [DefaultApprovalTypeEnum.Attendance]: "Présence",
            [DefaultApprovalTypeEnum.Auction]: "Vente aux enchères",
        },
    },
    NonMandatoryApprovers: {
        TypeEnum: {
            [NonMandatoryApprovalTypeEnum.Expense]: "Dépense",
            [NonMandatoryApprovalTypeEnum.Travel]: "Voyage",
            [NonMandatoryApprovalTypeEnum.Loan]: "Prêt",
            [NonMandatoryApprovalTypeEnum.Leave]: "Congé",
            [NonMandatoryApprovalTypeEnum.Asset]: "Actif",
            [NonMandatoryApprovalTypeEnum.Salary]: "Salaire",
            [NonMandatoryApprovalTypeEnum.Payroll]: "Paie",
            [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Financiers de dépenses",
            [NonMandatoryApprovalTypeEnum.TravelAgent]: "Agent de voyage",
            [NonMandatoryApprovalTypeEnum.Reward]: "Récompense",
            [NonMandatoryApprovalTypeEnum.ResignationHr]: "Démission RH",
            [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "Démission Administration",
            [NonMandatoryApprovalTypeEnum.ResignationIt]: "Démission IT",
            [NonMandatoryApprovalTypeEnum.ResignationFinance]: "Démission Finance",
            [NonMandatoryApprovalTypeEnum.ResignationExit]: "Démission Sortie",
            [NonMandatoryApprovalTypeEnum.Requisition]: "Demande",
            [NonMandatoryApprovalTypeEnum.Warning]: "Avertissement",
            [NonMandatoryApprovalTypeEnum.Complain]: "Plainte",
            [NonMandatoryApprovalTypeEnum.Bonus]: "Bonus",
            [NonMandatoryApprovalTypeEnum.Promotion]: "Promotion",
            [NonMandatoryApprovalTypeEnum.RequestForItem]: "Demande d'article",
            [NonMandatoryApprovalTypeEnum.CustomApproval]: "Approbation personnalisée",
            [NonMandatoryApprovalTypeEnum.Form]: "Formulaire",
            [NonMandatoryApprovalTypeEnum.Document]: "Document",
            [NonMandatoryApprovalTypeEnum.Career]: "Carrière",
            [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Approbation finale de la demande",
            [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Demande d'article contrôleur d'actif",
            [NonMandatoryApprovalTypeEnum.Quotation]: "Devis",
            [NonMandatoryApprovalTypeEnum.QuotationClient]: "Client de devis",
            [NonMandatoryApprovalTypeEnum.Appraisal]: "Évaluation",
            [NonMandatoryApprovalTypeEnum.Attendance]: "Présence",
            [NonMandatoryApprovalTypeEnum.Auction]: "Vente aux enchères",
        }
    },
    activityLog: {
        DeviceType: "Type d'appareil",
        DeviceToken: "Jeton d'appareil",
        OSVersion: "Version du système d'exploitation",
        Device: "Appareil",
        IP: "IP",
        Comment: "Commentaire",
        DeviceName: "Nom de l'appareil",
        LoginDate: "Date de connexion",
    },
    ErrorMessage: {
        someThingWentWrong: "Quelque chose s'est mal passé",
    },
    setting: {
        BasicInformation: "Informations de base",
        ChangePassword: "Changer le mot de passe",
        Notifications: "Notifications",
        Mobile: "Mobile #",
        About: "À propos",
        ExistingPassword: "Mot de passe actuel",
        EnterExistingPassword: "Entrer le mot de passe actuel",
        NewPassword: "Nouveau mot de passe",
        EnterNewPassword: "Entrer le nouveau mot de passe",
        ConfirmPassword: "Confirmer le mot de passe",
        EnterConfirmPassword: "Confirmer le mot de passe",
        Signature: "Signature du document",
        NoSignature: "Veuillez créer une signature",
        ErrorSignature: "Erreur lors de la création de la signature",
        NoSignatureSave: "Aucune signature à enregistrer",
        SignatureSaved: "Signature enregistrée",
        SaveSignature: "Enregistrer la signature",
        ClearSignature: "Effacer la signature",
        SignatureCreated: "Signature créée",
        SignatureActive: "Active",
        FailedSignature: "Échec de la récupération des signatures",

        BlockNotificationFeatureTypeEnum: {
            [NotificationBlockFeatureTypeEnum.Reward]: "Récompense",
            [NotificationBlockFeatureTypeEnum.Feed]: "Fil d'actualités",
            [NotificationBlockFeatureTypeEnum.Document]: "Document",
            [NotificationBlockFeatureTypeEnum.Project]: "Projet",
            [NotificationBlockFeatureTypeEnum.Task]: "Tâche",
            [NotificationBlockFeatureTypeEnum.WorkBoard]: "Tableau de travail",
            [NotificationBlockFeatureTypeEnum.Schedule]: "Emploi du temps",
            [NotificationBlockFeatureTypeEnum.Group]: "Groupe",
            [NotificationBlockFeatureTypeEnum.Messenger]: "Messagerie",
            [NotificationBlockFeatureTypeEnum.Expense]: "Dépense",
            [NotificationBlockFeatureTypeEnum.Auction]: "Vente aux enchères",
            [NotificationBlockFeatureTypeEnum.Leave]: "Congé",
            [NotificationBlockFeatureTypeEnum.Travel]: "Voyage",
            [NotificationBlockFeatureTypeEnum.Warning]: "Avertissement",
            [NotificationBlockFeatureTypeEnum.Complain]: "Plainte",
            [NotificationBlockFeatureTypeEnum.Bonus]: "Bonus",
            [NotificationBlockFeatureTypeEnum.Promotion]: "Promotion",
            [NotificationBlockFeatureTypeEnum.Loan]: "Prêt",
            [NotificationBlockFeatureTypeEnum.CustomApproval]: "Approbation personnalisée",
            [NotificationBlockFeatureTypeEnum.Form]: "Formulaire",
            [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Salaire des employés",
            [NotificationBlockFeatureTypeEnum.Payroll]: "Paie",
            [NotificationBlockFeatureTypeEnum.Career]: "Carrière",
            [NotificationBlockFeatureTypeEnum.Requisition]: "Demande",
            [NotificationBlockFeatureTypeEnum.Resignation]: "Démission",
            [NotificationBlockFeatureTypeEnum.Quotation]: "Devis",
            [NotificationBlockFeatureTypeEnum.Appraisal]: "Évaluation",
            [NotificationBlockFeatureTypeEnum.LeadManager]: "Responsable de l'équipe",
            [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Tableau de discussion",
            [NotificationBlockFeatureTypeEnum.Pages]: "Pages",
        }
    },
    emailConfiguration: {
        emailConfiHeader: "Configuration de l'email utilisateur",
        emailConfi: "Nom d'utilisateur",
        password: "Mot de passe",
        enterEmailConfi: "Entrer le nom d'utilisateur",
        incomingPort: "Port entrant",
        enterIncomingPort: "Entrer le port entrant",
        incomingServerAddress: "Adresse du serveur entrant",
        enterIncomingServerAddress: "Entrer l'adresse du serveur entrant",
        outgoingPort: "Port sortant",
        enterOutgoingPort: "Entrer le port sortant",
        outgoingServerAddress: "Adresse du serveur sortant",
        enterOutgoingServerAddress: "Entrer l'adresse du serveur sortant",
        provider: "Fournisseur",
        enterProvider: "Entrer le fournisseur",
        saveEmailConfi: "Enregistrer la configuration de l'email",
        addEmailConfi: "Ajouter la configuration de l'email",
        name: "Nom",
        clear: "Effacer",
    },
    assetsAllocation: {
        assetsAllocation: "Allocation des actifs",
        assests: "Actifs",
        createAssetAllocation: "Créer une allocation d'actifs",
        handover: "Remise",
        forMaintenance: "Pour maintenance",
        noResultFound: "Aucun résultat trouvé...",
        addAssetsAllocation: "Ajouter une allocation d'actifs",
        noDataMessage: "– Créez maintenant une allocation d'actifs !",
        allocationForMe: "Allocation pour moi",
        allocationApprovals: "Approbations d'allocation",
        deAllocation: "Changer le statut d'allocation",
        liability: "Responsabilité",
        capital: "Capital",
        revenue: "Revenu",
        expense: "Dépense",
        costOfGoodsale: "Coût des biens vendus",
        assetsCategory: "Catégorie d'actifs",
        parentAccount: "Compte parent",
        selectParent: "Sélectionner le parent",
        assetsList: "Liste des actifs",
        createAssests: 'Créer des actifs',
        sno: 'N° de série',
        inventoryName: "'Nom de l'inventaire",
        inventoryValue: "Valeur de l'inventaire",
        SelectAssetsController: "Sélectionner le contrôleur d'actifs",
    },
    requestListItems: {
        noDataMessage: "– Créez maintenant une demande !",
        requestForItems: "Demande d'articles",
        RequestForItemsApprovals: "Approbations de demande d'articles",
        AssetController: "Contrôleur d'actifs",
        RequestItem: "Demander un article",
    },
    profile: {
        firstMenu: '  1ère élément de menu',
        secondMenu: ' 2ème élément de menu (désactivé)',
        thirdMenu: ' 3ème élément de menu (désactivé)',
        dangerItem: 'un élément dangereux',
        NoPosition: "Aucune position",
        NoInstitute: "Aucun institut",
        NotFound: "Non trouvé",
        University: "Université",
        School: "École",
        Graduated: "Diplômé",
        CurrentlyWorkHere: "Je travaille actuellement ici",
        Statistics: "Statistiques",
        StorageStatistics: "Statistiques de stockage",
        TotalStorageUsed: "Stockage total utilisé",
    },
    /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
    status: {
        InProcess: "En cours",
        Approved: "Approuvé",
        Declined: "Refusé",
        Resend: "Renvoyer",
        Inactive: "Inactif",
        NotRequired: "Non requis",
        Cancelled: "Annulé",
        ApprovalRequired: "Approbation requise",
        Hold: "En attente",
        WaitingForOtherApprover: "En attente d'autres approbateurs",
        Draft: "Brouillon",
        Remark: "Remarque",
        Removed: "Supprimé"
    },
    approvalDictionaryLabel: {
        Approval: "Approbation",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Exécutant",
        [ApprovalsModuleEnum.ExpenseFinance]: "Finance",
        [ApprovalsModuleEnum.ResignationHrApproval]: "RH",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finance",
        [ApprovalsModuleEnum.ResignationItApproval]: "IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Admin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Autres Approbations",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Entretien de sortie",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Reporting À",
        [ApprovalsModuleEnum.TravelApproval]: "Approbateurs",
        [ApprovalsModuleEnum.TravelAgent]: "Agent",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Approbateurs Finaux",
        [ApprovalsModuleEnum.LoanApproval]: "Approbateurs",
        [ApprovalsModuleEnum.AuctionApproval]: "Approbateur"
    },
    approvalDictionaryPlaceHolder: {
        Approval: "Approbation",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Sélectionnez l'exécuteur de la dépense",
        [ApprovalsModuleEnum.ExpenseFinance]: "Sélectionnez les approbateurs de la finance",
        [ApprovalsModuleEnum.ExpenseApproval]: "Sélectionnez les approbateurs de dépenses",
        [ApprovalsModuleEnum.ResignationHrApproval]: "Sélectionnez RH",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Sélectionnez Finance",
        [ApprovalsModuleEnum.ResignationItApproval]: "Sélectionnez IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Sélectionnez Admin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Sélectionnez Autres Approbations",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Sélectionnez Entretien de sortie",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Sélectionnez Reporting À",
        [ApprovalsModuleEnum.TravelApproval]: "Sélectionnez les approbateurs pour le voyage",
        [ApprovalsModuleEnum.TravelAgent]: "Sélectionnez l'agent pour les arrangements de voyage",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Sélectionnez les approbateurs finaux",
        [ApprovalsModuleEnum.LoanApproval]: "Sélectionnez les approbateurs",
        [ApprovalsModuleEnum.AuctionApproval]: "Sélectionnez les approbateurs"
    },
    /*** Define language write side ***/
    Direction: "ltr",
};
export default french;
