import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode";
import {

    addProjectCategory,
    getAllProjectCategory,
    updateProjectCategory,
} from "./actions.js";

const initialState = {
    projectCategories: [],
    loadingData: false,
    loader: false,
    success: false,
    error: false,
};

const projectCategorySlice = createSlice({

    name: "projectCategorySlice",
    initialState,
    reducers: {
        ProjectCategoryDeleted: (state, { payload }) => {
            state.projectCategories = state.projectCategories.filter(
                (e) => e.id !== payload
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllProjectCategory.fulfilled, (state, { payload }) => {
                console.log(payload, "FROM SLICE");
                state.loadingData = false;
                state.projectCategories = payload.data;
            })
            .addCase(addProjectCategory.fulfilled, (state, { payload }) => {
                state.loader = false;
                if (payload.responseCode === responseCode.Success)
                    state.projectCategories.push(payload.data);
            })
            .addCase(updateProjectCategory.fulfilled, (state, { payload }) => {
                state.loader = false;
                state.projectCategories = state.projectCategories.map((x) =>
                    x.id === payload.data.id ? payload.data : x
                );
            })
            .addMatcher(
                isPending(...[addProjectCategory, updateProjectCategory]),
                (state) => {
                    state.loader = true;
                    state.success = false;
                    state.error = false;
                }
            )
            .addMatcher(isPending(...[getAllProjectCategory]), (state) => {
                state.loadingData = true;
                state.success = false;
                state.error = false;
            })
            .addMatcher(
                isRejected(
                    ...[
                        getAllProjectCategory,
                        addProjectCategory,
                        updateProjectCategory,
                    ]
                ),
                (state) => {
                    state.loader = false;
                    state.loadingData = false;
                    state.success = false;
                    state.error = false;
                }
            );
    },
});

export const { ProjectCategoryDeleted } = projectCategorySlice.actions;
export default projectCategorySlice.reducer;
