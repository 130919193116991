export const LeaveFilterSortEnum = {
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
}

export const leaveTableSort = {
    referenceNo: { descend: LeaveFilterSortEnum.ReferenceNoDesc, ascend: LeaveFilterSortEnum.ReferenceNo },
    createDate: { descend: LeaveFilterSortEnum.CreateDateDesc, ascend: LeaveFilterSortEnum.CreateDateAsc },
    updateDate: { descend: LeaveFilterSortEnum.UpdateDateDesc, ascend: LeaveFilterSortEnum.UpdateDateAsc },
}