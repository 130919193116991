import React, { memo, useEffect, useState } from 'react'
import { ApprovalStatus } from '../enums'
import { useSelector } from 'react-redux';
import Reminder from './reminder';
import SendReminder from './sendReminder';

const ReminderWrapper = ({ approversId, reminderCount, lastReminder, status, approvalId, module, showLastReminderText = true, reference , isHoverd= true }) => {
  
    const userId = useSelector(({ userSlice }) => userSlice?.user?.id);



    return (
        <>
            {(status === ApprovalStatus.InProcess || status === ApprovalStatus.Hold || status === ApprovalStatus.Resend)
                &&
                (userId !== approversId ?
                    <div className='flex gap-2' >
                        {reminderCount > 0 ?
                            <>
                                <Reminder
                                    reminderCount={reminderCount}
                                    lastReminder={lastReminder}
                                    showLastReminderText={showLastReminderText}
                                    approvalId={approvalId}
                                    module={module}
                                />
                                {isHoverd &&<SendReminder reference={reference} approvalId={approvalId} module={module}
                                />}
                            </>
                            :
                            isHoverd && <SendReminder reference={reference} approvalId={approvalId} module={module}
                            />
                            }
                    </div>
                    : reminderCount > 0 ? <Reminder
                        reminderCount={reminderCount}
                        lastReminder={lastReminder}
                        showLastReminderText={showLastReminderText}
                        approvalId={approvalId}
                        module={module}


                    /> : null)
            }
        </>)
}

export default memo(ReminderWrapper)