export const ColorsEnum = {
    Primary: "#1a5669",
    Success: "#1ECB40",
    Danger: "#FF0000",
    Cancelled: "#a40d0d",
    InActive: "#1ECB40",
    NotRequired: "#1ECB40",
    Default: "#ffffff",
    Completed: "#0E86D4",
    Hold: "#ffa500",
    Disbursed: "#1a5669",
    WaitingForDisburse: '#1a5669'
}

