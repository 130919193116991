import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
import { jsonToFormData } from "../../../../utils/base";



export const addMultipleQuotationService = (payload) => {
  const formData = jsonToFormData(payload);
  console.log(formData, "formData");
  return Config.post(`api/Quotation/AddQuotation`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getQuotationByIdService = (id) => {
  return Config.get(`api/Quotation/GetQuotationById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllQuotationService = (payload) => {
  return Config.post(`api/Quotation/GetAllQuotation`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllQuotationpagingService = (data) => {
  return Config.post(`api/Quotation/GetAllQuotationPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    })

}