import MasterConfig from "../../../../utils/services/MasterConfig";

export const getAllLoanService = (data) => {
  return MasterConfig.post(`api/Loan/GetAllLoan`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

//working
export const addLoanService = (data) => {
  return MasterConfig.post(`api/Loan/AddLoan`, data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetLoanByIdService = (id) => {
  return MasterConfig.get(`api/Loan/GetLoanById?id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};
export const getAllLoanPagingService = (data) => {
  return MasterConfig.post(`api/Loan/GetAllLoanPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const CheckLoanEligibilityService = async(type) => {
  return MasterConfig.get(`api/Loan/CheckLoanEligibility?type=${type}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};
