import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const AppointmentInfo = ({
  dateTime = "",
  appointmentWith = "",
}) => {
  return (
    <div className="mt-6">
      <div className="flex justify-between pt-2">
        <Text strong>Date & Time:</Text>
        <Text>{dateTime}</Text>
      </div>
      <div className="flex justify-between border-t pt-2">
        <Text strong>Appointment With:</Text>
        <Text>{appointmentWith}</Text>
      </div>
    </div>
  );
};

export default AppointmentInfo;
