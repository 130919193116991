import { Modal, Tag ,Button} from "antd";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetAiGeneratedSummaryAction } from "../store/action";
import Spinner from "../../../sharedComponents/spinner/spinner";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";
import { useSelector } from "react-redux"; 


const AiGeneratedMintueOfMeetingSummary = ({eventDetail}) =>{
    const [isGenerateAiSummary, setIsGenrateAiSummary] = useState(false);

    return (
      <>
        {eventDetail &&
          eventDetail?.onVideoConference &&
          eventDetail?.startMeeting &&
          eventDetail?.videoConferenceToken && (
            <Tag
              onClick={() => setIsGenrateAiSummary(true)}
              color="default"
              className="scheduleReferenceTypeTag !mr-[4px] !ml-[2px] w-fit"
            >
              {"Generate Ai Summary"}
            </Tag>
          )}
          {
            isGenerateAiSummary && 
            <Modal
              style={{top:'20px'}}
                open={isGenerateAiSummary}
                footer={null}
                onCancel={()=>{
                    setIsGenrateAiSummary(false)
                }}
                width={900}
                className="transcript-modal"
            >
                    <AiGenratedSummary id={eventDetail?.id}/>
            </Modal>
          }
       
      </>
    );
}

export default AiGeneratedMintueOfMeetingSummary;



function AiGenratedSummary ({id}){

  const { user: { businessImage } } = useSelector((state) => state.userSlice);
  
    const [GenAiSummaryData , setGenAiSummaryData]  = useState("")
    const [isLoading , setIsLoading ] = useState(false)
    const dispatch = useDispatch();
    const { userLanguage } = useContext(LanguageChangeContext);
    const { schedule, sharedLabels } = dictionaryList[userLanguage];

    useEffect(()=>{ 
        setIsLoading(true)
         dispatch(GetAiGeneratedSummaryAction({id})).then(res => {
              if(res?.payload?.data){
                setGenAiSummaryData(res?.payload?.data)
              }
              setIsLoading(false)
         })
    },[])

    if(isLoading) return <Spinner/>
    if(!GenAiSummaryData) return <></>
    return(
      
      <>
      <div className="Meeting__btn">
      <Button className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent "
          onClick={() => { window.print() }}
        >
          {"Regenerate"}
        </Button>
        <Button className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent "
          onClick={() => { window.print() }}
        >
          {schedule.Print}
        </Button>
        <Button className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent"
          onClick={() => { window.print() }}
        >
          {schedule.SaveToPDF}
        </Button>
      </div>
      <div className="content-box" id="SaveAsPdf">
        {
          (businessImage) && <div className="Bussiness__Logo">
            <img src={businessImage} alt="logo" />
          </div>
        }

        <div>
          {/* <h1 className="Meeting_Table_Name"> {sharedLabels.Miletap}</h1> */}
        </div>
        {/* <div>
          <p className="Meeting_Table_Address">New York No. 1 Lake Park</p>
        </div> */}
        <div className="list___name">
          <h1>{"Transcription Summary"}</h1>
        </div>
        <div>
          <MeetingMinutes minutes={GenAiSummaryData}/>
        </div>
        </div>
      </>
      
    )
}

// function MeetingMinutes({ minutes }) {
//   // Check if minutes is empty or undefined
//   if (!minutes?.trim()) {
//     return null;
//   }

//   // Splitting the meeting minutes string into sections
//   const sections = minutes.split("**Actionable Points:**");

//   // Parsing meeting details
//   const meetingDetails = sections[0].split("\n").filter(item => item.trim() !== "");

//   // Parsing actionable points
//   const actionablePoints = sections[1]?.split("\n").filter(item => item.trim() !== "") || [];

//   // Function to extract detail from meetingDetails array by key
//   const getMeetingDetailByKey = (key) => {
//     const detail = meetingDetails.find(d => d.includes(key));
//     return detail ? detail.split(key)[1].trim() : '';
//   };

//   const getActionablePointDetailByKey = (point, key) => {
//     const detail = point.split(key);
//     return detail.length > 1 ? detail[1].trim() : '';
//   };
    
//   return (
//     <div style={{margin:"5px"}}>
//       <p><strong>Meeting Subject:</strong>{getMeetingDetailByKey("**Meeting Subject:**")}</p>
//       <p><strong>Ref. No:</strong> {getMeetingDetailByKey("**Reference Number:**")}</p>
//       <p><strong>Date:</strong> {getMeetingDetailByKey("**Date:**")}</p>
//       <p><strong>Time:</strong> {getMeetingDetailByKey("**Time:**")}</p>
//       <p><strong>Duration:</strong> {getMeetingDetailByKey("**Duration:**")}</p>
//       <p><strong>Meeting Organizer:</strong> {getMeetingDetailByKey("**Meeting Organizer:**")}</p>

//       <h3>Transcription Summary</h3>
//       <p>{sections[0].split("**Summary:**")[1]}</p>

//       <h3>Actionable Points</h3>
//       <ul>
//         {actionablePoints.map((point, index) => (
//           <li key={index}>
//             {getActionablePointDetailByKey(point, "- **Action Item:**")}
//             <br />
//             <strong>Assigned To:</strong> {getActionablePointDetailByKey(point, "- **Assigned To:**")}
//             <br />
//             <strong>Due Date:</strong> {getActionablePointDetailByKey(point, "- **Due Date:**")}
//           </li>
//         ))}
//       </ul>
//       <p><strong>Next Meeting:</strong> {getMeetingDetailByKey("**Next Meeting:**")}</p>
//       <p><strong>Meeting Adjourned at:</strong> {getMeetingDetailByKey("**Meeting Adjourned at:**")}</p>
//     </div>
//   );
// }

function MeetingMinutes({ minutes }) {
  // Check if minutes is empty or undefined
  if (!minutes?.trim()) {
    return null;
  }

  // Function to parse key-value pairs from minutes string
  const parseKeyValuePairs = (text) => {
    const pairs = {};
    const regex = /\*\*(.*?)\*\*([^*]+)/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
      const key = match[1].trim();
      const value = match[2].trim();
      pairs[key] = value;
    }
    return pairs;
  };

  // Splitting the meeting minutes string into sections
  const sections = minutes.split("**");

  // Parsing meeting details
  const meetingDetails = parseKeyValuePairs(sections.slice(1).join("**"));

  // Function to render key-value pairs as paragraphs
  const renderKeyValuePairs = (pairs) => {
    return Object.entries(pairs).map(([key, value], index) => (
      <p key={index}>
        <strong>{key}</strong> {value}
      </p>
    ));
  };

  return (
    <div style={{ margin: "5px" }}>
      {renderKeyValuePairs(meetingDetails)}
    </div>
  );
}
