import { Drawer } from "antd";
import React, { useContext } from "react";
import DriveDetailCard from "../../../UI/driveDetailCard";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";

export default function DriveDetailDrawer({ onClose, item }) {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels } = dictionaryList[userLanguage];
    const { id, documentType, attachmentId } = item
    return (
        <>
            <Drawer
                title={<h1 style={{ fontSize: "20px", margin: 0 }}>{sharedLabels.Information}</h1>}
                width="768"
                height={"85%"}
                placement={"right"}
                onClose={onClose}
                visible={!!id}
                className="drawerSecondary"
                destroyOnClose
            >
                <DriveDetailCard
                    key={id}
                    driveId={id}
                    driveType={documentType}
                    driveAttachmentId={attachmentId}
                />
            </Drawer>
        </>
    )
}