import React, { useEffect, useRef, useState } from "react";
import { Modal, Input, Button } from "antd";
import SystemLogo from "../../../../../src/content/systemLogo.png";
import { useDispatch } from "react-redux";
import { ScheduleConfirmationWithOtpAction } from "./store/action";
import { responseCode } from "../../../../services/enums/responseCode";
import { ResendOtpAction } from "../../auth/store/actions";

const OTPModal = ({ visible, onClose, confirmationToken, setIsSuccess }) => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const [code, setCode] = useState(Array(6).fill(""));
  const [loader, setLoader] = useState(false);
  const [cooldown, setCooldown] = useState(true);
  const [remainingTime, setRemainingTime] = useState(5);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (visible) {
      inputRefs.current[0].focus();
    }
  }, [visible]);

  useEffect(()=>{
    let remainingSeconds = 5;
    const timer = setInterval(() => {
      remainingSeconds--;
      setRemainingTime(remainingSeconds);
      if (remainingSeconds === 0) {
        clearInterval(timer);
        setCooldown(false);
        setRemainingTime(10);
      }
    }, 1000);
  },[])

  const handleOk = () => {
    if (code.includes("")) {
      setHasError(true);
    } else {
      let payload = {
        token: confirmationToken,
        otp: code.join(""),
      };
      setLoader(true);
      dispatch(ScheduleConfirmationWithOtpAction(payload)).then((res) => {
        setLoader(false);
        if (res?.payload?.responseCode === responseCode.Success) {
          onClose(false);
          setIsSuccess(true);
        }
        setCode(Array(6).fill(""));
      });
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\s/g, ""); // Remove any spaces
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value === "") {
      // Backspace was pressed on an empty input
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (index < 5) {
      // Move to next input if current one is filled
      inputRefs.current[index + 1].focus();
    }

    if (newCode.every((char) => char !== "")) {
      setHasError(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text")
      .replace(/\s/g, "")
      .slice(0, 6);
    const newCode = [...code];
    for (let i = 0; i < pastedData.length; i++) {
      newCode[i] = pastedData[i];
    }
    setCode(newCode);
    pastedData.length < 6 ?
      inputRefs.current[pastedData.length].focus() : inputRefs.current[5].focus() ;
    
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendClick = () => {
    if (!cooldown) {
      dispatch(ResendOtpAction(confirmationToken)).then((res) => {
        console.log("rrrrrrrrrrr", res);
      });

      setCooldown(true);
      let remainingSeconds = 10;
      const timer = setInterval(() => {
        remainingSeconds--;
        setRemainingTime(remainingSeconds);
        if (remainingSeconds === 0) {
          clearInterval(timer);
          setCooldown(false);
          setRemainingTime(10);
        }
      }, 1000);
    }
  };

  return (
    <Modal title={null} visible={visible} onCancel={onClose} footer={null}>
      <div className="flex flex-col items-center">
        <div className="mb-4">
          <img
            src={SystemLogo}
            alt="System Logo"
            className="w-[120px] h-auto"
          />
        </div>
        <div className="mb-4 text-center">
          <h2 className="text-lg font-semibold">Two-Factor Authentication</h2>
          <p className="text-sm text-gray-600">
            A message with a verification code has been sent to your email
            address. Enter the code to confirm your appointment.
          </p>
        </div>
        <div className="flex flex-col mb-4">
          <div className=" flex space-x-2">
            {code?.map((char, index) => (
              <Input
                key={index}
                maxLength={1}
                className={`w-12 h-12 text-center text-lg ${
                  hasError && char === "" ? "!border-red-500" : ""
                }`}
                value={char}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                onPaste={(e) => handlePaste(e)}
              />
            ))}
          </div>
          {hasError && <div className="text-red-600">OTP Required</div>}
        </div>
        <Button
          onClick={handleOk}
          className="w-full mb-2 ThemeBtn"
          loading={loader}
        >
          Confirm Appointment
        </Button>
        <Button
          type="link"
          onClick={handleResendClick}
          className={`w-full ${
            cooldown ? "text-gray-400 cursor-not-allowed" : ""
          }`}
          disabled={cooldown}
        >
          {cooldown
            ? `Resend in ${remainingTime}s`
            : "Resend verification code"}
        </Button>
        <Button type="link" onClick={handleCancel} className="w-full">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default OTPModal;
