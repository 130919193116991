import React from "react";
import { Popconfirm, Tooltip } from "antd";
import deleteIcon from "../../../../../content/deleteIcon.svg";
import { removeApprovalAction } from "../action/action";
import { useDispatch } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

export default function DeleteApproval({ lengthOfItem = 0 ,id , module,referenceId }) {

  const dispatch = useDispatch()
  const onConfirmDelete = () => {
    dispatch(removeApprovalAction({id : id , module:module , referenceId:referenceId}))
  };


  // const title =
  //   lengthOfItem === 0 ? (
  //       <>Are you sure you want to delete this Approver? <br/>
  //         In case this is the only Approver, the item will automatically get
  //         approved.
  //       </>
      
  //   ) : (
  //     "Are you sure you want to delete this Approver?"
  //   );

  
  
  return (
    <>
      <Popconfirm
        placement="leftTop"
        title={ "Are you sure you want to delete this Approver?"}
        onConfirm={onConfirmDelete}
        // onCancel={Cancel}
      >
        <Tooltip placement="top" title={"Delete Approver"}>
          <div className="mt-2">
          <FaTrashAlt width={"16px"} />
          </div>
        </Tooltip>
      </Popconfirm>
    </>
  );
}
