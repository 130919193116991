export const TaskFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    StartDateDesc: 3,
    StartDateAsc: 4,
    UpdateDateDesc: 5,
    UpdateDateAsc: 6,
}



export const taskTableSort = {
    referenceNo: {
        descend: TaskFilterSortEnum.ReferenceNoDesc,
        ascend: TaskFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: TaskFilterSortEnum.CreateDateDesc,
        ascend: TaskFilterSortEnum.CreateDateAsc
    },
    startDate: {
        descend: TaskFilterSortEnum.StartDateDesc,
        ascend: TaskFilterSortEnum.StartDateAsc
    },
}