import moment from "moment";

// Parameters:
// - utcDate: The UTC date string to convert
// - format: The format string (e.g., "YYYY-MM-DD HH:mm:ss")
// Returns:
//   A formatted local date string

// Function to convert UTC date to local format using Moment.js
export default function convertUTCtoLocal(
  utcDate = moment.utc().format(),
  format = "DD MMM YYYY hh:mm:ss A"
) {

  if (utcDate === '0001-01-01T00:00:00') {
    return null;
  }
  
  // Check if utcDate is valid
  if (!utcDate || !moment.utc(utcDate).isValid()) {
    return null; // Return null if the date is invalid or null
  }

  // Convert UTC date to local date
  const localDate = moment.utc(utcDate).local();

  // Format the local date according to the provided format string
  const formattedDate = localDate.format(format);

  return formattedDate;
}

// Function to calculate time difference from now
export function timeFromNow(utcDate) {
  const localDate = moment.utc(utcDate).local();
  return moment(localDate).fromNow();
}

// Function to calculate the difference between two moments in days
export function calculateDaysDifference(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const diffInDays = endMoment.diff(startMoment, "days");
  return `${diffInDays}`;
}

// Function to format time span from seconds into hours and minutes
export function formatTimeSpan(record) {
  const hours = Math.floor(record.timeSpanInSeconds / 3600);
  const minutes = Math.floor((record.timeSpanInSeconds % 3600) / 60);

  // Create a new moment object with the correct hours and minutes
  const time = moment()
    .startOf('day') 
    .add(hours, 'hours')
    .add(minutes, 'minutes');

  return time.format('LT');
}

//Overall Time Spent Formatter
export function formatOverallTimeSpent(overallTimeSpent) {
  const hours = Math.floor(moment.duration(overallTimeSpent, 'seconds').asHours());
  const minutes = moment.duration(overallTimeSpent, 'seconds').minutes();

  return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`;
}