import React, { useContext, useEffect, useState } from "react";

import { Button, Input, message, Select } from "antd";
import axios from "axios";
import { generateAiMessage } from "../../../utils/Shared/helper/AiMessageGenerate";
import ToneSelect from "../AI/toneSelect";
import { useDispatch } from "react-redux";
import { GenerateAIResult } from "../../../utils/Shared/store/actions";

const AiInputComments = ({ isOpen, setIsOpenAi, onCommenting, content }) => {
	const [tone, setTone] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isFirstSubmit, setIsFirstSubmit] = useState(true);
	const [state, setState] = useState(({
		input: "",
		tone: 0
	}))
	const dispatch = useDispatch()
	const handleSubmit = async () => {
		setIsLoading(true)

		const aiQuestion = `Write a comment for this post "${content}." covering "${state.input}" in the ${tone} tone in one line`
		const response = await dispatch(GenerateAIResult(aiQuestion));
		if (response.payload.message === 'success') {
			setIsFirstSubmit(false)
			setIsOpenAi(false)
			simulateTypingEffect(response.payload.data)
		} else {
			message.error(response.message)
		}

		setIsLoading(false)
	}

	const simulateTypingEffect = (response) => {
		const textToType = response;
		let currentIndex = 0;

		const typingInterval = setInterval(() => {
			const currentText = textToType.substring(0, currentIndex + 5);
			// store.dispatch(feedSlice.actions.onPostTitleTextChange({ value: currentText }))
			onCommenting(currentText)
			currentIndex += 5;

			if (currentIndex >= textToType.length) {
				clearInterval(typingInterval);
			}
		}, 20); // Delay between each character (adjust as needed)
	};

	if (!isOpen) return <></>

	return (
		<>
			<div className="ai-input-comment" >
				<div className="input-cont" style={{ display: "flex", gap: "10px" }}  >
					<Input
						size="small"
						className=""
						placeholder="What are details you would like to focus on inorder to respond to this?"
						onChange={(e) => setState({
							...state,
							input: e.target.value
						})} />


					<ToneSelect setTone={setTone} />

					<Button
						className="generateBtn"
						type="submit"
						onClick={() => {
							handleSubmit()
						}}
						loading={isLoading} >{isFirstSubmit ? "Generate" : "Change"}


					</Button>
				</div>
			</div>
		</>
	);
};

export default AiInputComments;