import { ResponseResultError, ResponseResultSuccess } from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";

export const getAllTransactionService = async (request) => {
    try {
        const {
            data: { responseCode, data, message },
        } = await Config.post(`api/Transaction/GetAllTransaction`, request);
        if (responseCode === 1001) return ResponseResultSuccess(data);
        return ResponseResultError(message);
    } catch (e) {
        return ResponseResultError(e);
    }
};