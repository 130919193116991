import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { jsonToFormData, STRINGS } from "../../../../utils/base";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { addDocument_dto } from "../services/dto";
import {
  addDocumentService,
  getAllDocumentListService,
  getAllDocumentService,
  moveDocumentService,
  getDocumentByIdService,
  addDirectoryService,
  moveDirectoryService,
  getAllDocumentDirectoryMemberService,
  addDocumentDirectoryMemberService,
  addDocumentFavoriteService,
  AddDocumentMemberService,
  RemoveDocumentMemberService,
  getDocumentDirectoryByIdService,
  CompleteDocumentService,
  getAllDocumentListPagingService,
  getAllDocumentPagingService,
  getDocumentInformationServices,
  removeDocumentDirectoryMemberService,
  getAllDocumentMemberService,
  DashboardDocumentMetricsService,
  updateDocumentService,
  DuplicateDocumentService,
  getAllSignatureDocumentListService,
  getAllSignatureDocumentListPagingservice,
  SignatureDocumentMetricsDashboardService,
} from "../services/service";
import {
  addDocsMembers,
  toggleMinimizeDocument,
  updateMoveDocument,
} from "./slice";
import { message } from "antd";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

export const moveDirectory = createAsyncThunk(
  "document/moveDirectory",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await moveDirectoryService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: "Error " + response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(updateMoveDocument(payload));
        return response.data;
      default:
        return;
    }
  }
);

export const moveDocument = createAsyncThunk(
  "document/moveDocument",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await moveDocumentService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: "Error " + response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(updateMoveDocument(payload));
        return response.data;
      default:
        return;
    }
  }
);

export const addDocument = createAsyncThunk(
  "document/addDocument",
  async ({ payload, form }, { rejectWithValue, dispatch }) => {
    let request = addDocument_dto(payload);
    const formdataRequest = jsonToFormData(request);

    const response = await addDocumentService(formdataRequest);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        form.resetFields();
        dispatch(
          openNotification({
            message: "Document Create Successfully",
            type: "success",
            duration: 2,
          })
        );
        dispatch(
          toggleMinimizeDocument({
            id: response.data.id,
            status: true,
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAllDocumentList = createAsyncThunk(
  "document/getAllDocumentList",
  async (request, { rejectWithValue, dispatch }) => {
    const response = await getAllDocumentListService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllDocument = createAsyncThunk(
  "document/getAllDocument",
  async (request, { rejectWithValue }) => {
    const response = await getAllDocumentService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const GetDocumentById = createAsyncThunk(
  "document/getDocumentById",
  async ({ id }, { rejectWithValue }) => {
    const response = await getDocumentByIdService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const GetDocumentDirectoryById = createAsyncThunk(
  "document/GetDocumentDirectoryById",
  async (request, { rejectWithValue }) => {
    const response = await getDocumentDirectoryByIdService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const UpdateDocumentById = createAsyncThunk(
  "document/UpdateDocumentById",
  async (request, { rejectWithValue }) => {
    const response = await getDocumentByIdService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

// NEW
// --=--=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

export const addDirectory = createAsyncThunk(
  "document/addDirectory",
  async ({ payload, form }, { rejectWithValue, dispatch }) => {
    // const formdataRequest = jsonToFormData(payload);
    const response = await addDirectoryService(payload);
    console.log(response, "responsee");
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        const updatedResponse = {
          ...response.data,
          documentType: 1,
        };
        console.log(updatedResponse, "updatedResponsee");
        form.resetFields();
        dispatch(
          openNotification({
            message: "Folder Create Successfully",
            type: "success",
            duration: 2,
          })
        );
        return updatedResponse;
      default:
        return;
    }
  }
);

export const getAllDocumentDirectoryMember = createAsyncThunk(
  "document/getAllDocumentDirectoryMember",
  async (request, { rejectWithValue }) => {
    const response = await getAllDocumentDirectoryMemberService(request);
    console.log(response, "responseee");
    if (response.responseCode === responseCode.Success) {
      // dispatch(addDocsMembers(res?.data));
      return response.data;
    } else {
      message.error(response.data.message);
      return rejectWithValue(response.data.message);
    }
  }
);

export const addDocumentDirectoryMember = createAsyncThunk(
  "document/addDocumentDirectoryMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await addDocumentDirectoryMemberService(data);
    console.log(res, "responsee");
    if (res?.data.responseCode === responseCode.Success) {
      console.log(res, "responseee");
      // dispatch(addDocsMembers(res?.data));
      return res.data.data;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const removeDocumentDirectoryMember = createAsyncThunk(
  "document/removeDocumentDirectoryMember",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await removeDocumentDirectoryMemberService(data);
    if (res?.data.responseCode === responseCode.Success) {
      return res.data;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const addDocumentFavorite = createAsyncThunk(
  "document/addDocumentFavorite",
  async (data, { rejectWithValue }) => {
    const res = await addDocumentFavoriteService(data);
    if (res.data.responseCode === responseCode.Success) {
      return res.data;
    } else {
      message.error(res.data.message);
      return rejectWithValue(res.data.message);
    }
  }
);

export const AddDocumentMember = createAsyncThunk(
  "document/AddDocumentMember",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await AddDocumentMemberService(payload);
    if (response?.data?.responseCode === responseCode.Success) {
      return response?.data?.data;
    } else {
      dispatch(
        openNotification({
          message: response?.data?.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(response?.data?.message);
    }
  }
);

export const RemoveDocumentMember = createAsyncThunk(
  "document/RemoveDocumentMember",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await RemoveDocumentMemberService(data);
    if (response?.data?.responseCode === responseCode.Success) {
      return response?.data;
    } else {
      dispatch(
        openNotification({
          message: response?.data?.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(response?.data?.message);
    }
  }
);
export const getAllDocumentMember = createAsyncThunk(
  "document/getAllDocumentMember",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await getAllDocumentMemberService(payload);
    if (response?.responseCode === responseCode.Success) {
      return response?.data;
    } else {
      dispatch(
        openNotification({
          message: response?.data?.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(response?.data?.message);
    }
  }
);
export const CompleteDocumentAction = createAsyncThunk(
  "completeDocument",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const res = await CompleteDocumentService(data);

    if (res.responseCode === responseCode.Success) {
      //dispatch(addGroupMember(res.data));
       message.success("Document Completed Successfully")
      return res;
    } else {
      dispatch(
        openNotification({
          message: res.message,
          type: "error",
          duration: 2,
        })
      );
      return rejectWithValue(res.message);
    }
  }
);

export const getAllDocumentListPaging = createAsyncThunk(
  "document/getAllDocumentListPaging",
  async (request, { rejectWithValue, dispatch }) => {
    const response = await getAllDocumentListPagingService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllDocumentPaging = createAsyncThunk(
  "document/getAllDocumentPaging",
  async (request, { rejectWithValue, dispatch }) => {
    const response = await getAllDocumentPagingService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getDocumentInformation = createAsyncThunk(
  "document/getDocumentInformation",
  async ({ filter }, { rejectWithValue, dispatch }) => {
    const response = await getDocumentInformationServices(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const DashboardDocumentMetricsAction = createAsyncThunk(
  "document/DashboardDocumentMetricsAction",
  async (filter, { rejectWithValue, dispatch }) => {
    const response = await DashboardDocumentMetricsService(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const getAllDocumentListAction = createAsyncThunk(
  "document/getAllDocumentListAction",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { rejectWithValue, dispatch }
  ) => {
    if (segmentType === SegmentTypeEnum.Grid) {
      const response = await getAllDocumentListService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          dispatch(
            openNotification({
              message: response.errorMessage,
              type: "error",
            })
          );
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    } else {
      const response = await getAllDocumentListPagingService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          dispatch(
            openNotification({
              message: response.errorMessage,
              type: "error",
            })
          );
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  }
);

export const getAllDocumentAction = createAsyncThunk(
  "document/getAllDocumentAction",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { rejectWithValue, dispatch }
  ) => {
    if (segmentType === SegmentTypeEnum.Grid) {
      const response = await getAllDocumentService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    } else {
      const response = await getAllDocumentPagingService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          dispatch(
            openNotification({
              message: response.errorMessage,
              type: "error",
            })
          );
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  }
);

///update document
export const updateDocument = createAsyncThunk(
  "document/updateDocument",
  async (data, { rejectWithValue }) => {
    const res = await updateDocumentService(data);
    console.log(res, "response before successs-->");
    if (res.responseCode === responseCode.Success) {
      console.log(res, "response--->");
      return res.data;
    } else {
      message.error(res.message);
      return rejectWithValue(res.message);
    }
  }
);

export const DuplicateDocumentAction = createAsyncThunk(
  "document/DuplicateDocument",
  async (payload, { rejectWithValue }) => {
    const res = await DuplicateDocumentService(payload);
    if (res.type === ResponseType.SUCCESS) {
      return res.data;
    } else {
      message.error(res.errorMessage);
      return rejectWithValue(res.errorMessage);
    }
  }
);

export const getAllSignatureDocumentListAction = createAsyncThunk(
  "document/getAllSignatureDocumentListAction",
  async (
    { filter, segmentType = SegmentTypeEnum.Grid, identifier },
    { rejectWithValue, dispatch }
  ) => {
    if (segmentType === SegmentTypeEnum.Grid) {
      const response = await getAllSignatureDocumentListService(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          dispatch(
            openNotification({
              message: response.errorMessage,
              type: "error",
            })
          );
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    } else {
      const response = await getAllSignatureDocumentListPagingservice(filter);
      switch (response.type) {
        case ResponseType.ERROR:
          dispatch(
            openNotification({
              message: response.errorMessage,
              type: "error",
            })
          );
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  }
);

export const SignatureDocumentMetricsDashboardAction = createAsyncThunk(
  "document/SignatureDocumentMetricsDashboardAction",
  async (payload, { rejectWithValue }) => {
    const res = await SignatureDocumentMetricsDashboardService(payload);
    if (res.type === ResponseType.SUCCESS) {
      return res.data;
    } else {
      message.error(res.errorMessage);
      return rejectWithValue(res.errorMessage);
    }
  }
);