import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";

const en = {
  approvalDictionaryLabel: {
    Approval: "Approval",
    [ApprovalsModuleEnum.ExpenseExecutor]: "Executor",
    [ApprovalsModuleEnum.ExpenseFinance]: "Finance",
    [ApprovalsModuleEnum.ResignationHrApproval]: "HR",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finance",
    [ApprovalsModuleEnum.ResignationItApproval]: "IT",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "Admin",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "Other Approvals",
    [ApprovalsModuleEnum.ResignationExitApproval]: "Exit Interview",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "Reporting To",
    [ApprovalsModuleEnum.TravelApproval]: "Approvers",
    [ApprovalsModuleEnum.TravelAgent]: "Agent",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "Final Approvers",
    [ApprovalsModuleEnum.LoanApproval]: "Approvers"
  },
  approvalDictionaryPlaceHolder: {
    Approval: "Approval",
    [ApprovalsModuleEnum.ExpenseExecutor]: "Select the Executer of the Expense",
    [ApprovalsModuleEnum.ExpenseFinance]: "Select the Approvers from Finance",
    [ApprovalsModuleEnum.ExpenseApproval]: "Select Expense Approvers",
    [ApprovalsModuleEnum.ResignationHrApproval]: "Select HR",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "Select Finance",
    [ApprovalsModuleEnum.ResignationItApproval]: "Select IT",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "Select Admin",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "Select Other Approvals",
    [ApprovalsModuleEnum.ResignationExitApproval]: "Select Exit Interview",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "Select Reporting To",
    [ApprovalsModuleEnum.TravelApproval]: "Select Approvers for travel",
    [ApprovalsModuleEnum.TravelAgent]: "Select agent for travel arrangements",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "Select Final Approvers",
    [ApprovalsModuleEnum.LoanApproval]: "Select Approvers"

  },
  /*** Define language write side ***/
  Direction: 'ltr',
};
export default en;
