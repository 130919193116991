import SideDrawer from "../../../../../../sharedComponents/Drawer/SideDrawer";
import React, { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { DocumentReferenceTypeEnum } from "../../../../utils/DocumentReferenceTypeEnum";
import { STRINGS } from "../../../../../../../utils/base";
import { DocumentTypeEnum } from "../../../../utils/DocumentTypeEnum";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import { handleCloseDocComposer } from "../../../../store/slice";
import { useDispatch } from "react-redux";
import UpdateDocumentComposers from "./ComposerItems/documentUpdateComposer";

function UpdateDocumentComposerDrawer({
    documentType = 0,
    referenceId = STRINGS.DEFAULTS.guid,
    referenceType = DocumentReferenceTypeEnum.General,
    onClose = () => { },
    item = {},
}) {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, document } = dictionaryList[userLanguage];
    const dispatch = useDispatch()
    const [state, setState] = useState({
        documentType: documentType,
        isOpen: false
    });

    useEffect(() => {
        if (documentType !== 0)
            setState({
                ...state,
                documentType: documentType,
                isOpen: true
            })

        return () => {
            dispatch(handleCloseDocComposer());
        }
    }, [documentType]);

    const handleOnClose = () => {
        onClose();
        setState({
            ...state,
            documentType: 0,
            isOpen: false
        })
        dispatch(handleCloseDocComposer());
    }



    const updateDoc = (type) => {
        switch (type) {
            case DocumentTypeEnum.Folder:
                return document.updateFolder;
            case DocumentTypeEnum.Grid:
                return document.updateGrid;
            case DocumentTypeEnum.Pad:
                return document.updatePad;
            case DocumentTypeEnum.Slide:
                return document.updateSlide;
            case DocumentTypeEnum.Board:
                return document.updateBoard;
            case DocumentTypeEnum.MindMap:
                return document.updateMindMap;
            case DocumentTypeEnum.Attachment:
                return document.updateDocuments
            default:
                return "";
        }
    }
    return (<>
        <SideDrawer
            title={updateDoc(state.documentType)}
            isDisable={true}
            isOpen={state.isOpen}
            isAccessDrawer={false}
            handleClose={handleOnClose}
        >
            {state.documentType !== 0 && (
                <UpdateDocumentComposers
                    documentType={(state.documentType === DocumentTypeEnum.Image || state.documentType === DocumentTypeEnum.Video || state.documentType === DocumentTypeEnum.Attachment) ? DocumentTypeEnum.Attachment : state.documentType}
                    // documentType={state.documentType}
                    referenceId={referenceId}
                    referenceType={referenceType}
                    onClose={handleOnClose}
                    item={item}
                />
            )}
        </SideDrawer>
    </>);
}
export default UpdateDocumentComposerDrawer;