import React, { useState, useEffect, useContext } from "react";
import { Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
  DestinationTagsColor,
  PostDestinationByPostRefType,
  PostReferenceType,
  PostTypeAction,
} from "../../utils/constants";
import DestinationModal from "./SharePostDestinationModal";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { STRINGS } from "../../../../../utils/base";

const DefaultPostRefType = PostReferenceType.MAIN_FEED;

const DestinationTag = ({
  postReferenceType,
  postReferenceId,
  setPostReferenceType,
  setPostReferenceId,
}) => {
  const {
    userSlice: {
      user: { name, userImage, id: userId },
    },
  } = useSelector((state) => state);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [destinationText, setDestinationText] = useState("Feed");
  const [selectedDestination, setSelectedDestination] = useState(
    postReferenceType === PostReferenceType.TIMELINE &&
      postReferenceId !== userId
      ? PostDestinationByPostRefType?.[`${PostReferenceType.TIMELINE}Member`]
      : PostDestinationByPostRefType?.[postReferenceType]
  );
  const [destinationDisabled, setDestinationDisabled] = useState(false);
  const [membersfromSuggestionBox, setMembersfromSuggestionBox] = useState([]);

  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed } = dictionaryList[userLanguage];
  const { SharedestinationNames } = newsFeed;

  useEffect(() => {
    if (membersfromSuggestionBox.length > 0) {
      setDestinationText(
        `${membersfromSuggestionBox?.[0]?.name}${newsFeed.specificTimeline}`
      );
      setPostReferenceId(membersfromSuggestionBox?.[0]?.id);
    }
  }, [membersfromSuggestionBox]);

  useEffect(() => {
    if (membersfromSuggestionBox.length === 0 && !isModalVisible) {
      let { destination, disabled } = PostTypeAction(
        postReferenceType,
        SharedestinationNames,
        postReferenceId === userId || postReferenceId === STRINGS.DEFAULTS.guid
      );
      setDestinationText(destination);
      setDestinationDisabled(disabled);
    }
  }, [postReferenceType, postReferenceId, isModalVisible]);

  const handleFeedClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectDestination = (destination) => {
    setDestinationText(destination);
    setIsModalVisible(false);
  };

  return (
    <>
      <Tag
        color={DestinationTagsColor?.[selectedDestination]}
        onClick={handleFeedClick}
        className={`${
          destinationDisabled
            ? "cursor-no-drop pointer-events-none"
            : "cursor-pointer"
        } !font-bold !m-0 flex items-center`}
      >
        <div className="flex items-baseline">
          <span>{destinationText}</span>
          {!destinationDisabled && (
            <DownOutlined className="ml-1 text-[10px]" />
          )}
        </div>
      </Tag>

      <DestinationModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSelect={handleSelectDestination}
        membersfromSuggestionBox={membersfromSuggestionBox}
        setmembersfromSuggestionBox={setMembersfromSuggestionBox}
        setPostReferenceType={setPostReferenceType}
        setPostReferenceId={setPostReferenceId}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        setDestinationText={setDestinationText}
        avatarProps={{
          src: userImage,
          name: name,
          width: 30,
          height: 30,
          round: true,
        }}
      />
    </>
  );
};

export default DestinationTag;
