import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { jsonToFormData, STRINGS } from "../../../../utils/base";
import { openNotification } from "../../../../utils/Shared/store/slice";
import {
  getAllTeamsService,
  getAllRewardService,
  getAllLoanService,
  getAllComplainService,
  getAllWarningService,
  getAllLeaveService,
  getAllCheckInService,
  getAllCoursesService,
  getAllAppraisalService,
  getDeviceInfoService,
  addTeamMemberService,
  getAllTeamRewardByuserIdService,
  getAllTeamWarningByuserIdService,
  getAllTeamComplainsByuserIdService,
  getAllTeamLeavesByuserIdService,
  getAllTeamLoanByuserIdService,
  getAllTeamAppraisalsByuserIdService,
  getAllActivityLogService,
  getAllDeviceService,
  getAllDevicePagingService,
  getAllActivityLogPagingService
} from "../services/service";

export const getTeamsAction = createAsyncThunk(
  "teamSlice/getTeamsAction",
  async ({ filter }, { rejectWithValue, dispatch }) => {
    const response = await getAllTeamsService(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getRewardsAction = createAsyncThunk(
  "teamSlice/getRewardsAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllRewardService(id);
    console.log(id, "rewards action");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAppraisalsAction = createAsyncThunk(
  "teamSlice/getAppraisalsAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllAppraisalService(id);
    console.log(id, "appraisals action");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getCourseAction = createAsyncThunk(
  "teamSlice/getCourseAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllCoursesService(id);
    console.log(id, "Course action");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);
export const getAllLoanAction = createAsyncThunk(
  "teamSlice/ getAllLoanAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllLoanService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",

          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAllComplainAction = createAsyncThunk(
  "teamSlice/ getAllComplainAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllComplainService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAllWarningAction = createAsyncThunk(
  "teamSlice/getAllWarningAction ",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllWarningService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);
export const getAllLeaveAction = createAsyncThunk(
  "teamSlice/getAllLeaveAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllLeaveService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAllCheckInAction = createAsyncThunk(
  "teamSlice/getAllCheckInAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllCheckInService(id);
    console.log(id, "CheckIn SERVICE");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getDeviceInfoAction = createAsyncThunk(
  "teamSlice/getDeviceInfoAction",
  async (id, { dispatch, rejectWithValue }) => {
    const response = await getDeviceInfoService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);
export const getAllActivityLogAction = createAsyncThunk(
  "teamSlice/getAllActivityLogAction",
  async (id, { dispatch, rejectWithValue }) => {
    const response = await getAllActivityLogService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);
export const getAllDeviceAction = createAsyncThunk(
  "teamSlice/getAllDeviceAction",
  async (payload, { dispatch, rejectWithValue }) => {
    const response = await getAllDeviceService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const addTeamMemberAction = createAsyncThunk(
  "teamSlice/addTeamMemberAction",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log(payload, "sssa");
    const response = await addTeamMemberService(payload);
    console.log(response, "addUser addTeamMemberAction");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: `Team Member added Successfully`,
            type: "success",
            duration: 2,
          })
        );
        return response.data;
      default:
        return;
    }
  }
);
// export const getAllTeamRewardByuserId = createAsyncThunk(
//   "teamSlice/getAllTeamRewardByuserId",
//   async (id, { rejectWithValue, dispatch }) => {
//     const response = await getAllTeamRewardByuserIdService(id);
//     console.log(id, "rewards action");
//     switch (response.type) {
//       case ResponseType.ERROR:
//         return rejectWithValue(response.errorMessage);
//       case ResponseType.SUCCESS:
//         dispatch(
//           openNotification({
//             type: "success",
//           })
//         );
//         return response.data;
//       default:
//         return;
//     }
//   }
// );

export const getAllTeamRewardByuserId = createAsyncThunk(
  "teamSlice/getAllTeamRewardByuserId",
  async (id) => {
    const response = await getAllTeamRewardByuserIdService(id);
    return response.data;
  }
);

export const getAllTeamWarningsByuserId = createAsyncThunk(
  "teamSlice/getAllTeamWarningsByuserId",
  async (id) => {
    const response = await getAllTeamWarningByuserIdService(id);
    return response.data;
  }
);

export const getAllTeamComplainsByuserId = createAsyncThunk(
  "teamSlice/getAllTeamComplainsByuserId",
  async (id) => {
    const response = await getAllTeamComplainsByuserIdService(id);
    return response.data;
  }
);
export const getAllTeamLeavesByuserId = createAsyncThunk(
  "teamSlice/getAllTeamLeavesByuserId",
  async (id) => {
    const response = await getAllTeamLeavesByuserIdService(id);
    return response.data;
  }
);
export const getAllTeamLoanByuserId = createAsyncThunk(
  "teamSlice/getAllTeamLoanByuserId",
  async (id) => {
    const response = await getAllTeamLoanByuserIdService(id);
    return response.data;
  }
);

export const getAllTeamAppraisalsByuserId = createAsyncThunk(
  "teamSlice/getAllTeamAppraisalsByuserId",
  async (id) => {
    const response = await getAllTeamAppraisalsByuserIdService(id);
    return response.data;
  }
);
export const getAllDevicePagingAction = createAsyncThunk(
  "teamSlice/getAllDevicePagingAction",
  async ({ request }, { rejectWithValue, dispatch }) => {
    const response = await getAllDevicePagingService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllActivityLogPagingAction = createAsyncThunk(
  "teamSlice/getAllActivityLogPagingAction",
  async ({ request }, { rejectWithValue, dispatch }) => {
    const response = await getAllActivityLogPagingService(request);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);