export const LoanFilterSortEnum = {
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
    Amount: 7,
    AmountDesc: 8,
}

export const loanTableSort = {
    createDate: {
        descend: LoanFilterSortEnum.CreateDateDesc,
        ascend: LoanFilterSortEnum.CreateDateAsc
    },
    updateDate: {
        descend: LoanFilterSortEnum.UpdateDateDesc,
        ascend: LoanFilterSortEnum.UpdateDateAsc
    },
    referenceNo: {
        descend: LoanFilterSortEnum.ReferenceNoDesc,
        ascend: LoanFilterSortEnum.ReferenceNo
    },
    amount: {
        descend: LoanFilterSortEnum.Amount,
        ascend: LoanFilterSortEnum.AmountDesc
    },



}