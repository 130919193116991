const en = {
  employeesDictionary: {
    placeholders: {
      organization: "Organization",
      department: "Department",
      subsidiary: "Subsidiary",
      subsidiaryOffice: "Subsidiary Office",
      payroll: "Payroll ",
      fName: "Enter First Name",
      lName: "Enter Last Name",
      fatherName: "Enter Father Name",
      email: "Enter Email",
      pEmail: "Enter Personal Email",
      phNo: "Enter Phone Number",
      rAddress: "Enter Residential Address",
      pAddress: "Enter Permenant Address",
      cnicNo: "Enter National Identity Number",
      searchToSelect: "Search To Select",
      probPeriod: "Enter Probation Period",
      noticePeriod: "Enter Notice Period",
      selectDate: "Select Date",
      selectGender: "Select Gender",
      selectCountry: "Select Country",
      selectDesignation: "Select Designation",
      selectMarital: "Select Marital Status",
      selectShift: "Select Shift",
      empNo: "Enter Employee No",
      empType: "Select Employment Type",
      selectUserType: "Select User Type",
      selectAccessRole: "Select Access Role",
      degree: "Enter Degree",
      institute: "Enter Institute Name",
      desc: "Enter Description",
      tMarks: "Enter Total Marks",
      oMarks: "Enter Obtained Marks",
      sDate: "Start Date",
      eDate: "End Date",
      position: "Enter Position",
      name: "Enter Name",
      address: "Enter Address",
      number: "Enter Number",
      selectRelation: "Select Relation",
      bankName: "Enter Bank Name",
      accTitle: "Enter Account Title",
      branchCode: "Enter Branch Code",
      accNo: "Enter Account Number",
      iban: "Enter IBAN Number",
      sortCode: "Enter Sort Code",
    },
    EmployeeForm: {
      UpdateBasicInfo: "Update BasicInfo",
      BasicInfo: "Basic Info",
      AddImage: "Add Image",
      FirstName: "First Name",
      LastName: "Last Name",
      FatherName: "Father Name",
      Email: "Email",
      PersonalEmail: "Personal Email",
      CNICNumber: "National Identity",
      rAddress: "Residential Address",
      pAddress: "Permenant Address",
      PhoneNumber: " Phone Number",
      Designation: "Designation",
      Manager: "Manager",
      Grades: "Grades",
      Department: "Department",
      Country: "Country",
      City: "City",
      Languages: "Languages",
      ProbationPeriod: "Probation Period (Days)",
      NoticePeriod: "Notice Period (Days)",
      DateOfBirth: " Date Of Birth",
      DateOfJoining: " Date Of Joining",
      Gender: "Gender",
      MaritalStatus: "Marital Status",
      OfficeShift: "Office Shift",
      EmploymentType: "Employment Type",
      UserType: "User Type",
      AccessRole: "Access Role",
      EmpNo: "Employee No",
      department: "Department",
      subsidiary: "Subsidiary",
      subsidiaryOffice: "Subsidiary Office",
      payroll: "Payroll Group",
    },
    EmergencyForm: {
      EmergencyInfo: "Emergency Info",
      Designation: "Designation",
      Address: "Address",
      Number: "Number",
      Relation: "Relation",
      Name: "Name",
      AddMoreEmergency: "Add More Emergency",
      AddEmergency: "Add Emergency",
      UpdateEmergency: "Update Emergency",
    },
    EducationForm: {
      AddEducation: "Add Education",
      UpdateEducation: "Update Education",
      EducationInfo: "Education Info",
      Degree: "Degree",
      Institute: "Institute",
      StartEndDate: "Start/End Date",
      StartDate: "Start Date",
      Description: "Description",
      TotalMarks: "Total Marks",
      ObtainedMarks: "Obtained Marks",
      Present: "Present",
      Attachments: "Attachments",
      Upload: "Upload",
      City: "City",
    },
    WorkExperienceForm: {
      WorkExperienceInfo: "Work Experience Info",
      organization: "Organization",
      Employer: "Employer",
      Position: "Position",
      Designation: "Designation",
      Department: "Department",
      StartEndDate: "Start/End Date",
      StartDate: "Start Date",
      Present: "Present",
      AddMoreExperience: "Add More Experience",
      AddExperience: "Add Experience",
      EmploymentType: "Employment Type",
      City: "City",
      UpdateExperience: "Update Experience",
    },
    UserForm: {
      UserRights: "User Rights",
      UserType: "User Type",
      UserRole: "User Role",
    },
    BankForm: {
      updateBank: "Update Bank",
      BankInfo: "Bank Info",
      BankName: "Bank Name",
      AccountTitle: "Account Title",
      BranchCode: "Branch Code",
      AccountNumber: "Account Number",
      IBAN: "IBAN",
      SortCode: "Sort Code",
      City: "City",
      Country: "Country",
      AddMoreBank: "Add More Bank",
      AddBank: "Add Bank",
    },
    tables: {
      Id: "Id",
      Name: "Name",
      Email: "Email",
      Grade: "Grade",
      Designation: "Designation",
      Image: "Image",
      EmployeeNo: "Employee No",
      PhoneNo: "Phone No",
      Manager: "Manager"
    },
    EmployeeList: {
      number: "Number",
      email: "Email",
      designation: "Designation",
    },
    AddEmployee: "Add Employee",
  },

  /*** Define language write side ***/
  Direction: "ltr",
};
export default en;
