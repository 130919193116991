import { createSlice } from "@reduxjs/toolkit";
import { getAllTransaction } from "./action";

const initialState = {
    data: null, //THis will be with identifiers

    totalRecords: [],
    recordSize: 0,
};
const voucherListSlice = createSlice({
    name: "voucherList",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getAllTransaction.fulfilled, (state, { payload }) => {
            state.data = payload.data;
            state.totalRecords = payload.totalRecords;
        });


    },
});
export const {

} = voucherListSlice.actions;
export default voucherListSlice.reducer