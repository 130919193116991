export const calculateNetSalary = (d) => {
  let netSalary =
    d.basicSalary -
    Math.abs(d.loan) +
    d.allowance -
    Math.abs(d.deduction) -
    Math.abs(d.tax) -
    Math.abs(d.rebate) +
    d.bonus +
    d.other;
  return netSalary;
};
