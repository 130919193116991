import { useContext } from "react";
import { QuotationStatusEnum } from "../../utils/quotationStatusEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";

const StatusTag = ({ status = QuotationStatusEnum.InProcess }) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { quotations } = dictionaryList[userLanguage];
    let color = ColorsEnum.Primary;

    switch (status) {
        case QuotationStatusEnum.InProcess:
            color = ColorsEnum.Primary;
            break;
        case QuotationStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case QuotationStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case QuotationStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case QuotationStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;
        default:
            color = ColorsEnum.Primary;
    }
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {quotations.statusEnum[status]}
            </Tag>
        </>
    );
};

export default StatusTag;

