import { STRINGS } from "../../../../../utils/base";
import SuggestionBox from "../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionBoxType } from "../../../../sharedComponents/SuggestionBox/utils/SuggestionBoxType";
import { DOCUMENT_ENUM } from "../../constant";
import { DocumentReferenceTypeEnum } from "../enum";
import MemberListContainer from "../../../members/memberListContainer";
import { Form } from "antd";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { useContext } from "react";

const DocumentMemberContainer = ({
  documentType = DOCUMENT_ENUM.DUCOMENT_TYPE.folder,
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = DocumentReferenceTypeEnum.General,
  data = [],
  addMemberHandler = () => { },
  onDelete = () => { },
}) => {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  return (
    <>
      <Form.Item>
        <SuggestionBox
          placeholder={sharedLabels.selectMembers}
          SuggestionType={
            referenceType === DocumentReferenceTypeEnum.Project
              ? SuggestionBoxType.ProjectMembers
              : referenceType === DocumentReferenceTypeEnum.Group
                ? SuggestionBoxType.GroupMembers
                : SuggestionBoxType.User
          }
          isMultiSelect={false}
          removeSelf={true}
          referenceId={referenceId}
          callback={(item) => {
            addMemberHandler(item);
          }}
        />
      </Form.Item>
      <MemberListContainer
        type={documentType}
        data={data}
        onDelete={onDelete}
      />
    </>
  );
};
export default DocumentMemberContainer;
