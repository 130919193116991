import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { getAllTransactionService } from "../services/service";

export const getAllTransaction = createAsyncThunk(
    "transaction/getAllTransaction",
    async (data, { rejectWithValue, dispatch }) => {
        const response = await getAllTransactionService(data);
        switch (response.type) {
            case ResponseType.ERROR:
                responseMessage({
                    dispatch: dispatch,
                    type: responseMessageType.ApiFailure,
                    data: {
                        message: response.errorMessage,
                    },
                });
                return rejectWithValue(response.errorMessage);
            case ResponseType.SUCCESS:
                return response.data;
            default:
                return;
        }
    }
);
