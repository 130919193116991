import { useContext, useEffect, useState } from "react";
import { STRINGS } from "../../../../../utils/base";
import moment from "moment";
import { SegmentTypeEnum } from "../../../../sharedComponents/Segment/utils/enum";
import { ExpenseReferenceTypeEnum } from "../../utils/expenseReferenceTypeEnum";
import { ExpenseFilterTypeEnum } from "../../utils/expenseFilterTypeEnum";
import { getAllExpenseAction } from "../../store/actions";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { useSelector } from "react-redux";
import { Table } from "../../../../sharedComponents/customTable";
import { useDispatch } from "react-redux";
import TagAvatar from "../../../../sharedComponents/Avatar/TagAvatar";
import ExpenseDetailContainer from "../Page/Detail/expenseDrawer";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";


export default function SharedExpenseList({referenceId = STRINGS.DEFAULTS.guid}){


    const [state, setState] = useState({
        identifier: moment().unix(),
        isLoading: true,
        filter: {
            filterType: ExpenseFilterTypeEnum.All,
            segmentType: SegmentTypeEnum.List,
            referenceType: ExpenseReferenceTypeEnum.Travel,
            referenceId: referenceId,
            search: "",
            pageNo: 1,
        },
        totalRecords: 0,
        recordSize: 0,
        DetailObj:{
            detailId:STRINGS.DEFAULTS.guid,
            isOpen:false,
            index : -1,
        }
      });

      const dispatch = useDispatch()

      const { userLanguage } = useContext(LanguageChangeContext);
      const { sharedLabels, expenses } = dictionaryList[userLanguage];
      const { data } = useSelector((state) => state.expenseSlice);
      const expense = data !== null ? data[state.identifier] : [];


      const GetAllExpense = () => {
        setState({
          ...state,
          isLoading: true,
        });
    
        const {
          pageNo = 1,
          filterType = ExpenseFilterTypeEnum.All,
          segmentType = SegmentTypeEnum.List,
          referenceId = STRINGS.DEFAULTS.guid,
          referenceType = ExpenseReferenceTypeEnum.Travel,
        } = state?.filter;
        const paylaod = {
          pageNo: pageNo ?? 1,
          search: state.filter?.search,
          filterType: filterType,
          referenceId: referenceId,
          referenceType: referenceType,
        };
    
        dispatch(
          getAllExpenseAction({
            filter: paylaod,
            segmentType: segmentType,
            identifier: state.identifier,
          })
        ).then((response) => {
          setState({
            ...state,
            isLoading: false,
            totalRecords: response.payload?.totalRecords || 0,
            recordSize: response.payload?.length || 0,
          });
        });
      };
    
      useEffect(() => {
        GetAllExpense();
      }, []);

    const ExpneseColumns  =  () => {
        return [
            {
                title: sharedLabels.referenceNo,
                dataIndex: "referenceNo",
                ellipsis: true,
                width: 2,
                sort: true,
              },
              {
                title: sharedLabels.Creator,
                dataIndex: "creator",
                width: 3,
                ellipsis: true,
                render: (creator) => (
                  <TagAvatar text={creator.name} img={creator.image} className={"tableViewTagAvatar-class"}
                  />
                ),
              },
              {
                title: sharedLabels.amount,
                dataIndex: "amount",
                ellipsis: true,
                width: 2,
                sort: true,
                render: (amount) => formatNumberWithCommas(amount)
              },
              {
                title: sharedLabels.Description,
                dataIndex: "description",
                ellipsis: true,
                width: 2,
                sort: true,
              },

        ]
    }

    const onRow = (record, rowIndex) => {
        return {
          onClick: (event) => {
            setState({
                ...state,
                DetailObj:{
                    ...state.DetailObj,
                    index : rowIndex,
                    isOpen:true,
                    detailId:record.id
                }
            })
          },
        };
      };

       //close Detail Modal
  const onDetailModalClose = () => {
    setState({
      ...state,
      DetailObj: {
        ...state.DetailObj,
        index: -1,
        isOpen: false,
        detailId: STRINGS.DEFAULTS.guid,
      },
    });
  };

    return(
        <>
            <Table
                columns={ExpneseColumns()}          
                data={expense ? expense : []}
                pagination={false}
                current={state.filter.pageNo}
                totalRecords={state?.totalRecords}
                onRow={onRow}
            />

            {state.DetailObj.isOpen && (
                <ExpenseDetailContainer
                    direction="ltr"
                    id={state.DetailObj.detailId}
                    index={state.DetailObj.index}
                    onClose={(onDetailModalClose)}
                    isOpen={state.DetailObj.isOpen}
                />
            )}
        </>
    )
}