
import { Form, Modal } from "antd";
import DocumentMemberContainer from "../documentMemberWrapper/documentMemberContainer";
import { useState } from "react";
import { DocumentReferenceTypeEnum } from "../../utils/DocumentReferenceTypeEnum";
import SuggestionBox from "../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionBoxType } from "../../../../sharedComponents/SuggestionBox/utils/SuggestionBoxType";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { addApproversAction } from "../../../../sharedComponents/AppComponents/Approvals/action/action";
import { useDispatch } from "react-redux";
import { handleAddApprovers } from "../../store/slice";
import { responseCode } from "../../../../../services/enums/responseCode";
import CustomAddApproversModal from "../../../../sharedComponents/addApprovalContainer";
import CompleteDocument from "./completeDocument";

export default function CompleteDocumentWrapper({documentId  ,isShow, onClose  , referenceId }){

    const [state , setState] = useState({
        approvers:[]
    })
   
    return(
        <CompleteDocument
            visible={isShow}
            onClose={() =>
                         {
                            setState({
                                ...state,
                                approvers:[]
                            })
                            onClose()
                         }
                    }
            existingMembers={[]}
            referenceId={documentId}
            module={ApprovalsModule.DocumentApproval}
      />
    )
}