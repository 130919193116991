import { createSlice, current, isPending } from "@reduxjs/toolkit";
import { calculateNetSalary } from "../utils/constant";
import { addPayroll, getAllPayroll, getCalculatedPayroll, getPayrollById } from "./actions";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";

const initialState = {
  success: false,
  loader: false,
  createLoader: false,
  error: false,
  payrollCalculatedList: null,
  payrollList: null,
  payrollDetail: null,

  data: null, //THis will be with identifiers
  totalRecords: [],
  newCountFilterButton: false,
  newItem: null,
  recordSize: 0,

};

export const payrollSlice = createSlice({
  name: "Payroll",
  initialState: initialState,
  reducers: {
    handleChangePayrollItem: (state, { payload }) => {
      let { index, data } = payload;
      state.payrollCalculatedList[index] = data;
    },
    handleChangeAllPayrollItem: (state, { payload }) => {
      state.payrollCalculatedList = payload;
    },
    setPayrollDetail: (state, { payload }) => {
      state.payrollDetail = payload;
    },


    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined") delete state.data?.[identifier];
    },

    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },

    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },

    handleNotificationNewItem: (state, { payload }) => {

      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === payload.featureId)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },


    readCountforPayroll: (state, { payload }) => {

      const id = payload
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier]
            const index = current.findIndex(y => y.id === id)
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0
              }

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id
        }
      }
      catch (e) {
        console.log("===error===customApproval", e)
      }
    },
    handlePayrollRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: approverStatus.length > 1 ? 1 : approverStatus[0],
                approverStatus:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptionss");
      }
    },

    handleAddPayrollApprovers: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [
                ...referenceItem?.approvers,
              ];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },

    handleEditRow:(state,{payload})=>{
      const {index , referenceData , key, referenceType} = payload
      const totalAmount = referenceData.reduce((accumulator, currentValue) => {
        // Convert the currentValue.amount to a number before adding to the accumulator
        return accumulator + parseFloat(currentValue.amount);
    }, 0);
      try{
        if(index!==-1)
        {
          const updatedCurrent = [...state.payrollCalculatedList]
          let referenceItem = updatedCurrent[index]
          const existingReferenceIds = new Set(referenceData.map(item => item.referenceId));
          const refDetails = referenceItem?.referenceDetails?.filter(item => item.referenceType !== referenceType);
          referenceItem ={
              ...referenceItem,
              referenceDetails:[...referenceData, ...refDetails],
              [key] : totalAmount,
              netSalary: calculateNetSalary({
                ...referenceItem,
                referenceDetails: [...referenceData, ...refDetails],
                [key]: totalAmount,
              }),

          }

          updatedCurrent[index] = referenceItem

          state.payrollCalculatedList = updatedCurrent
          
        }

        
      }
      catch(e){
        console.log(e,"Errrorrr")
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCalculatedPayroll.fulfilled, (state, { payload }) => {
        state.loader = false;
        let calculatedSalaries = payload.map((item) => ({
          ...item,
          netSalary: calculateNetSalary(item),
          isChecked: true,
        }));
        state.payrollCalculatedList = calculatedSalaries;
      })
      .addCase(addPayroll.fulfilled, (state, { payload }) => {
        state.createLoader = false;
        state.success = true;
        state.payrollList = state.payrollList
          ? [...state.payrollList, payload]
          : [payload];
      })
      .addCase(getAllPayroll.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
          state.recordSize = data.length;
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
          state.totalRecords = action?.payload?.totalRecords;
        }
      })

      .addCase(getPayrollById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })

      .addMatcher(isPending(...[addPayroll]), (state) => {
        state.createLoader = true;
        state.success = false;
        state.error = false;
      })
      .addMatcher(
        isPending(...[getCalculatedPayroll]),
        (state) => {
          state.loader = true;
          state.success = false;
          state.error = false;
        }
      );
  },
});
export const {
  handleChangePayrollItem,
  handleChangeAllPayrollItem,
  setPayrollDetail,

  //
  removeIdentifier,
  handleResetState,
  handleNewItem,
  handleNotificationNewItem,
  readCountforPayroll,
  handlePayrollRemark,
  handleAddPayrollApprovers,
  handleEditRow
} = payrollSlice.actions;
export default payrollSlice.reducer;
