import React, { useContext } from "react";
import { Tag } from "antd";
import { FormStatusEnum } from "../../utils/formStatusEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
const StatusTag = ({ status = FormStatusEnum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { forms } = dictionaryList[userLanguage];
  let color = ColorsEnum.Primary;

  switch (status) {
    case FormStatusEnum.InProcess:
      color = ColorsEnum.Primary;
      break;
    case FormStatusEnum.Approved:
      color = ColorsEnum.Success;
      break;
    case FormStatusEnum.Declined:
      color = ColorsEnum.Danger;
      break;
    case FormStatusEnum.Resend:
      color = ColorsEnum.Primary;
      break;
    case FormStatusEnum.InActive:
      color = ColorsEnum.InActive;
      break;
    case FormStatusEnum.NotRequired:
      color = ColorsEnum.NotRequired;
      break;
    case FormStatusEnum.Cancelled:
      color = ColorsEnum.Cancelled;
      break;
    case FormStatusEnum.Hold:
      color = ColorsEnum.Hold;
      break;
    default:
      color = ColorsEnum.Primary;
  }
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {forms.statusEnum[status]}
      </Tag>
    </>
  );
};

export default StatusTag;
