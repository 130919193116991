export const CareerApplicantStatusEnum = {
    All: 0,
    Applicants: 1,
    ShortListed: 2,
    Rejected: 3,
    Completed: 4,
    Finalist: 5,
    MayBe: 6,
    OfferSent: 7,
    WaitingForInterviews: 8,
    OfferAccepted: 9,
    OfferDeclined: 10,
    OfferResend: 11,
    ScheduleInterview: 12
};

export const getNameCareerApplicantStatusEnum = {
    All : "All",
    Applicants : "Applicants",
    ShortListed : 'Short Listed',
    Rejected : 'Rejected',
    Completed : 'Completed',
    Finalist : 'Finalist',
    MayBe : 'May Be' ,
    OfferSent : 'Offer Sent' ,
    WaitingForInterviews : 'Waiting For Interviews' ,
    OfferAccepted : 'Offer Accepted' ,
    OfferDeclined : 'Offer Declined' ,
    OfferResend : 'Offer Resend' ,
    ScheduleInterview : 'Schedule Interview' ,
};

export const CareerApplicantNameStatusEnum = {
    0: "All",
    1: "Applicants",
    2: "ShortListed",
    3: "Rejected",
    4: "Completed",
    5: "Finalist",
    6: "MayBe",
    7: "OfferSent",
    8: "WaitingForInterviews",
    9: "OfferAccepted",
    10: "OfferDeclined",
    11: "OfferResend",
    12: "ScheduleInterview"
};