import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, message, Skeleton } from "antd";
import { MessagePage } from "./congratsPage";
import FormHeader from "./FormHeader";
import Poll from "./QuestionsItems/Radio";
import TextFields from "./QuestionsItems/TextFields";
import { useSearchParams } from "react-router-dom";
import {
  getFormById,
  submitForm as submitFormAction,
} from "../../../store/actions";
import BusinessLogo from "../../../../../../content/systemLogo.png";
import "../../style/SubmitForm.css";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import landscapeSystemLogo from "../../../../../../content/landscapeSystemLogo.jpeg";
import moment from "moment";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import { FormQuestionTypeEnum } from "../../../utils/formQuestionTypeEnum";
let initialData = {
  formId: "",
  userId: "",
  email: "",
  attempt: [],
};

const SubmitForm = (props) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { forms } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const { disableSubmit } = props;
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const [formStatus, setStatus] = useState("");
  const [submitForm, setSubmitForms] = useState(initialData);
  const [isSubmited, setIsSubmited] = useState(false);
  const [state, setState] = useState({
    identifier: moment().unix(),
  });
  const { user } = useSelector((state) => state.userSlice);
  const [form] = Form.useForm();

  const { data } = useSelector((state) => state.formSlice);
  let formDetail = data !== null ? data[state.identifier] : [];
  formDetail = formDetail && formDetail?.length > 0 && formDetail[0];

  useEffect(() => {
    const id = searchParams.get("id");
    id && dispatch(getFormById({ id: id, identifier: state.identifier }));
  }, [id]);

  useEffect(() => {
    setSubmitForms({
      ...submitForm,
      formId: formDetail?.id,
      userId: user?.id,
      attempt: formDetail?.question?.map((el, index) => {
        return {
          questionId: el.id,
          answer: "",
          required: el.isRequired,
        };
      }),
    });
  }, [formDetail]);

  let handleChange = (value, index) => {
    let updateAnswers = [...submitForm.attempt];
    updateAnswers[index].answer = value;
    setSubmitForms({ ...submitForm, attempt: updateAnswers });
  };

  const handleSubmit = async () => {
    form.submit();
    const isValidation = await form.validateFields(); 

    let payload = submitForm;
    
    const dataCheck = payload.attempt?.map((el, index) => {
      if ((el.answer.length === 0 && el.required) || !isValidation) {
        return false;
      } else {
        return true;
      }
    });
    //sending data to api
    if (!dataCheck.some((el) => el === false)) {
      dispatch(submitFormAction(payload)).then((res) => {
        res?.payload?.data && setIsSubmited(true);
      });
    } else {
      message.error("Please fill all the required fields");
    }
  };

  if (!formDetail) return <MessagePage message={formStatus} />;
  if (isSubmited)
    return (
      <div className="w-full h-full flex m-auto justify-center items-center">
        <MessagePage message="Thank you for your responses" />
      </div>
    );

  return (
    <>
      <div className="submit-form-wrap">
        <div className="formCompanyLogo">
          <img src={BusinessLogo} alt="logo" />
        </div>
        {Object.keys(formDetail).length > 1 ? (
          <Form form={form}>
            <div className="center-fix">
              <FormHeader
                title={formDetail.subject}
                description={formDetail.description}
                isAcceptingResp={formDetail.acceptingResponse}
                handleChangeEmail={(e) =>
                  setSubmitForms({ ...submitForm, email: e.target.value })
                }
              />
              {formDetail &&
                formDetail.question?.map((item, index) => (
                  <>
                    {item?.answerType === FormQuestionTypeEnum.Poll ? (
                      <Poll
                        handleChange={handleChange}
                        question={item}
                        index={index}
                        disableSubmit={disableSubmit}
                        required={item?.isRequired}
                        answer={submitForm?.attempt?.[index].answer}
                      />
                    ) : (
                      <TextFields
                        handleChange={handleChange}
                        fieldData={item}
                        index={index}
                        disableSubmit={disableSubmit}
                        required={item?.isRequired}
                      />
                    )}
                  </>
                ))}
              <div className="flex justify-center mt_10">
                {!disableSubmit && (
                  <Button onClick={handleSubmit} type="submit" className="ThemeBtn">
                    {forms.Submit}
                  </Button>
                )}
              </div>
              <div className="poweredBy">
                <div className="my-2">{forms.PoweredBy}</div>
                <div className="mx-3">
                  <a href="https://workw.com">
                    <img src={landscapeSystemLogo} />
                  </a>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <div className="center-fix">
            {[...Array(5)]?.map((item) => (
              <div className="c-row txt-fields bg-clr p_15">
                <Skeleton active />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default SubmitForm;
