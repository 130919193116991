import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { AppraisalStatusEnum } from "../utils/appraisalStatusEnum";
import {
  getAllTaskForAppraisalAction,
  getAllAppraisalAction,
  addAppraisal,
  getAllAppraisalByIdAction,
  getAllAppraisalByMeAction,
  getAllPreviousAppraisalAction,
  getAllAppraisalPagingAction,
  GetApprovalQuestionForAppraisalAction,
} from "./action";

import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
  data: null,
  newItem: null,
  previousAppraisals: [],
  loader: false,
  previousAppraisalLoader: false,
  newCountFilterButton: false,
};

const appraisalModuleSlice = createSlice({
  name: "appraisalModule",
  initialState,
  reducers: {
    handleResetState: (state, { payload }) => {
      state.data = [];
    },
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (
        typeof state.data !== "undefined" &&
        state.data !== null &&
        identifier
      )
        delete state.data?.[identifier];
    },
    handleNewItem: (state, { payload }) => {
      // const { identifier } = payload;
      // if (typeof state.data !== "undefined")
      //   state?.data[identifier] = [state?.newItem].concat(
      //     state.data?.[identifier]
      //   );
    },
    handleAppraisalApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark, module } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });

              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status:
                  approverStatus?.length > 1
                    ? 1
                    : ApprovalStatus.Hold === approverStatus[0]
                    ? AppraisalStatusEnum.Hold
                    : approverStatus[0],
                approverStatus:
                  approverStatus?.length > 1 ? AppraisalStatusEnum.InProcess : approverStatus[0],
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleAddAppraisalApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [...referenceItem?.approvers];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleNotificationNewItem: (state, { payload }) => {
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };
          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === payload.featureId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1,
              };
              updatedCurrent[index] = referenceItem;
              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log("===error===appraisal", e);
      }
    },
    readCountforAppraisal: (state, { payload }) => {
      const id = payload;
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };
          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0,
              };
              updatedCurrent[index] = referenceItem;
              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
          state.newCountFilterButton = id;
        }
      } catch (e) {
        console.log("===error===appraisal", e);
      }
    },

    handleAppraisalReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTaskForAppraisalAction.fulfilled, (state, { payload }) => {
        state.userTask = payload;
        state.loader = false;
      })
      .addCase(getAllAppraisalAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
        }
      })
      .addCase(getAllAppraisalByIdAction.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload ?? [];
        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })
      .addCase(addAppraisal.fulfilled, (state, { payload }) => {
        state.newItem = payload;
      })
      .addCase(
        getAllPreviousAppraisalAction.fulfilled,
        (state, { payload }) => {
          state.previousAppraisals = payload;
          state.previousAppraisalLoader = false;
        }
      )
      
      .addMatcher(isPending(...[getAllPreviousAppraisalAction]), (state) => {
        state.previousAppraisalLoader = true;
      })
      .addMatcher(isRejected(...[getAllPreviousAppraisalAction]), (state) => {
        state.previousAppraisalLoader = false;
      });
  },
});

export const {
  handleNewItem,
  removeIdentifier,
  handleAppraisalApprovalRemark,
  handleResetState,
  handleAddAppraisalApprover,
  handleNotificationNewItem,
  readCountforAppraisal,
  handleAppraisalReminder
} = appraisalModuleSlice.actions;
export default appraisalModuleSlice.reducer;
