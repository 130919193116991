import { useContext, useEffect } from 'react';
import { PlusOutlined, EditOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Table,
} from 'antd';
import { useState } from 'react';
import { dictionaryList } from '../../../../../utils/localization/languages';
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import { employeeDictionaryList } from '../../localization/index';
import '../../Styles/employeeForm.css';
import { useParams } from 'react-router-dom';
import { employmentType } from '../../../../../utils/Shared/enums/enums';
import { useSelector, useDispatch } from 'react-redux';
import {
    addUserWorkExperienceAction,
    getUserWorkExperience,
} from '../../../experienceInfo/store/actions';
import moment from 'moment';
import { getCities } from '../../../../../utils/Shared/store/actions';
import { resetEmergencydetails } from '../../store/slice';
import SingleUpload from '../../../../sharedComponents/Upload/singleUpload';
import { STRINGS } from '../../../../../utils/base';
const { RangePicker } = DatePicker;
const { Option } = Select;

const PreExperienceForm = ({ mode, id, getWorkFormData = () => { } }) => {

    const param = useParams();
    const isEdit = mode === 'edit';
    const dispatch = useDispatch();
    const [workInfo, setWorkInfo] = useState([]); // complete table 
    const [form] = Form.useForm();
    const [newUserId, setNewUserId] = useState('');
    const [handleUpdateButton, setHandleUpdateButton] = useState(false)


    Object.defineProperty(form, 'values', {
        value: function () {
            return workInfo.map((item) => {
                return {
                    ...item,
                    startDate: moment(item[0]?.startEndDate?._ds)?.format(),
                    endDate: item?.isPresent
                        ? ''
                        : moment(item[1]?.startEndDate?._ds)?.format(),
                };
            });
        },
        writable: true,
        enumerable: true,
        configurable: true,
    });
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels } = dictionaryList[userLanguage];
    const [isPresent, setIsPresent] = useState(false);
    const { employeesDictionary, Direction } = employeeDictionaryList[
        userLanguage
    ];
    const {
        employee: { experiencedetails },
        success,
    } = useSelector((state) => state.employeeSlice);

    const { experienceDetails, experienceInformation } = useSelector(
        (state) => state.workExperienceSlice
    );

    const initialState = {
        position: '',
        employmentTypeId: [],
        startDate: '',
        isPresent: false,
        organization: ""
    };
    const [initialValues, setInitialValues] = useState(initialState);
    const labels = employeesDictionary.WorkExperienceForm;
    const placeholder = employeesDictionary.placeholders;
    const { cities } = useSelector((state) => state.sharedSlice);
    const [attachments, setAttachments] = useState([]);
    const [attachmentDetail, setAttachmentDetail] = useState({
        isOpen: false,
        data: [],
    });
    const onHandleAttachment = (attachments) => {
        setAttachmentDetail({
            data: attachments,
            isOpen: true,
        });
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
        if (isEdit) setIsPresent(initialValues.isPresent);
    }, [initialValues, form]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getUserWorkExperience(id));
            if (!cities.length) fetchCityData('', 0);
        }
        return () => {
            dispatch(resetEmergencydetails());
        };
    }, []);

    useEffect(() => {
        if (success) setWorkInfo([]);
    }, [success]);

    useEffect(() => {
        if (isEdit)
            setWorkInfo(
                experienceInformation?.map((item) => {
                    return {
                        ...item,
                        startDate: item.isPresent
                            ? moment(item.startDate)
                            : [moment(item.startDate), moment(item.endDate)],
                    };
                })
            );
    }, [experienceInformation]);

    // console.log(workInfo, 'workInfo');

    const fetchCityData = (text, pgNo) => {
        dispatch(getCities({ textData: text, page: pgNo }));
    };

    const handleAddMore = async () => {
        try {
            form.submit();
            const isValidation = await form.validateFields();
            if (isValidation) {
                const attachmentsData = attachments.map((file) => ({
                    file: file.file,
                    id: file.id,
                }));
                const payload = {
                    ...form.getFieldsValue(),
                    attachments: attachmentsData,
                };
                console.log('Payload:', payload);
                if (isEdit) {
                    const payloadObj = {
                        payload,
                        id: param.id,
                    };
                    dispatch(addUserWorkExperienceAction(payloadObj));
                }

                setWorkInfo((prevWorkInfo) => [...prevWorkInfo, payload]);
                form.resetFields();
                setIsPresent(false);
                setAttachments([]);
                setInitialValues(initialState);
            }
        } catch (err) {
            console.error('Failed to add work experience:', err);
        }
    };

    const handleRowChange = (rowId) => {
        const selectedRow = workInfo.find((row) => row.id === rowId);
        setInitialValues(selectedRow);
        setHandleUpdateButton(true)
    };
    useEffect(() => {

        getWorkFormData(workInfo)
    }, [workInfo])

    const columns = (onHandleAttachment) => [
        {
            title: labels.Position,
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: labels.EmploymentType,
            dataIndex: 'employmentTypeId',
            key: 'employmentTypeId',
            render: (value) => {
                return employmentType[value - 1]?.name;
            },
        },
        {
            title: labels.StartEndDate,
            dataIndex: 'startDate',
            key: 'startDate',
            render: (value, row) => {
                return value?.length
                    ? `${moment(row.startDate[0]).format('YYYY/MM/DD')} - ${moment(
                        row.startDate[1]
                    ).format('YYYY/MM/DD')}`
                    : `${moment(row.startDate).format('YYYY/MM/DD')} -  Present`;
            },
        },
        {
            title: labels.organization,
            dataIndex: 'organization',
            key: 'organization',
        },
        {
            title: sharedLabels.Attachments,
            dataIndex: "attachments",
            key: "attachments",
            render: (attachments) => {

                return (attachments?.length > 0 ? (
                    <a
                        onClick={(e) => {
                            e.stopPropagation();
                            onHandleAttachment(attachments);
                        }}
                    >
                        {`${attachments?.length} ${sharedLabels.Attachments}`}
                    </a>
                ) : null)

            }

        },
        {
            title: sharedLabels.action,
            render: (value, row) => {
                return (
                    <a
                        href=" "
                        onClick={(e) => {
                            e.preventDefault();
                            if (isEdit) {
                                handleRowChange(row.id);
                                setNewUserId(value?.id);
                            } else {
                                const filterArray = workInfo.filter((value, i) => {
                                    if (row.id !== value.id) return value;
                                });
                                setWorkInfo(filterArray);
                                // console.log(filterArray, 'filterArray');
                            }
                        }}
                    >
                        {isEdit ? <EditFilled style={{ color: "#1b5669" }} /> : <DeleteFilled style={{ color: "#1b5669" }} />}
                    </a>
                );
            },
        },
    ];

    let classes = 'employeeForm workInfo ';
    classes += Direction === 'ltr' ? 'ltr' : 'rtl';
    return (
        <div className={classes}>
            <Divider orientation="left"> {labels.WorkExperienceInfo}</Divider>
            <Form
                name="workInfo"
                form={form}
                layout={'vertical'}
                initialValues={initialValues}
            >
                <Row gutter={[16]}>
                    <Col span={6}>
                        <Form.Item
                            rules={[{ required: true }]}
                            name="position"
                            label={labels.Position}
                        >
                            <Input placeholder={placeholder.position}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[{ required: true }]}
                            name="employmentTypeId"
                            label={labels.EmploymentType}
                        >
                            <Select placeholder={placeholder.empType} size="large">
                                {employmentType.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div className="dates">
                            {!isPresent && (
                                <Form.Item
                                    rules={[{ required: true }]}
                                    name="startDate"
                                    label={labels.StartEndDate}
                                >
                                    <RangePicker
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        size="large"
                                        format={'DD/MM/YYYY'}
                                        placeholder={[placeholder.sDate, placeholder.eDate]}
                                    />
                                </Form.Item>
                            )}

                            {isPresent && (
                                <Form.Item
                                    rules={[{ required: true }]}
                                    name="startDate"
                                    label={labels.StartDate}
                                >
                                    <DatePicker
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        format={'DD/MM/YYYY'}
                                        placeholder={labels.start}
                                        size="large"
                                    />
                                </Form.Item>
                            )}

                            <Form.Item name="isPresent" valuePropName="checked">
                                <Checkbox
                                    onChange={(e) => {
                                        setIsPresent(!isPresent);
                                        if (e.target.checked) {
                                            const sDate = form.getFieldValue('startDate')[0].format()
                                            form.setFieldValue('startDate', moment(sDate));
                                            form.setFieldValue('startEndDate', '');
                                        }
                                        else {
                                            const sDate = form.getFieldValue('startDate').format()
                                            form.setFieldValue('startDate', [moment(sDate), '']);
                                        }
                                    }}
                                >
                                    {labels.Present}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            rules={[{ required: true }]}
                            name="organization"
                            label={labels.organization}
                        >
                            <Input placeholder={placeholder.organization}></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {!handleUpdateButton &&
                <Form.Item label={sharedLabels.Attachments} name="Attachments" className="w-full block attachmentEmp">
                    <>
                        <SingleUpload key={workInfo.length}
                            handleImageUpload={(files) =>
                                setAttachments(
                                    files.map((file) => ({
                                        file: file.originFileObj,
                                        id: STRINGS.DEFAULTS.guid,
                                    }))
                                )
                            }
                            uploadText={sharedLabels.upload}
                            multiple={true}
                        />
                    </>
                </Form.Item>
            }

            <div className={isEdit ? 'editButtons' : 'buttons'}>
                {handleUpdateButton ? (
                    workInfo?.length > 0 && isEdit && (
                        <Button
                            className=" mb-2 btn ThemeBtn"
                            icon={<EditOutlined />}
                        // onClick={handleUpdate}
                        >
                            {labels.UpdateExperience}
                        </Button>
                    )
                ) : (
                    <Button
                        type="dashed"
                        className="btn addMore"
                        icon={<PlusOutlined />}
                        onClick={handleAddMore}
                    >
                        {labels.AddExperience}
                    </Button>
                )}
            </div>

            {workInfo?.length > 0 && (
                <Table columns={columns(onHandleAttachment)} dragable={true} dataSource={workInfo} />
            )}
        </div>
    );
};

export default PreExperienceForm;
