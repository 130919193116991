import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { responseCode } from '../../../../services/enums/responseCode';
import {
	responseMessage,
	responseMessageType,
} from "../../../../services/slices/notificationSlice";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import {getAllAccountHeaderService, getAlldefaultAccountHeaderService ,updatedefaultAccountHeaderService } from "../service/service";
import { message } from "antd";

export const getAlldefaultAccountHeader = createAsyncThunk(
    "AccountHeader/getAlldefaultAccountHeader",
    async (request, { rejectWithValue, dispatch }) => {
      const response = await getAlldefaultAccountHeaderService(request);
      switch (response.type) {
        case ResponseType.ERROR:
          responseMessage({
            dispatch: dispatch,
            type: responseMessageType.ApiFailure,
            data:{
              message:response.errorMessage
            }
          });
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  );

  export const updatedefaultAccountHeader = createAsyncThunk(
    'AccountHeader/updatedefaultAccountHeader',
    async (args, { dispatch }) => {
      const res = await updatedefaultAccountHeaderService(args);
      if (res.responseCode) {
        if (res.responseCode === responseCode.Success)
          res.message = 'DefaultAccountHeader updated successfully!';
        message.success(res.message);
        responseMessage({ dispatch, data: res });
      } else {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  );


  export const getAllAccountHeader = createAsyncThunk(
    "AccountHeader/getAllAccountHeader",
    async (request, { rejectWithValue, dispatch }) => {
      const response = await getAllAccountHeaderService(request);
      switch (response.type) {
        case ResponseType.ERROR:
          responseMessage({
            dispatch: dispatch,
            type: responseMessageType.ApiFailure,
            data:{
              message:response.errorMessage
            }
          });
          return rejectWithValue(response.errorMessage);
        case ResponseType.SUCCESS:
          return response.data;
        default:
          return;
      }
    }
  );