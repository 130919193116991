// import  { useEffect,useState } from "react";
import { Tabs } from "antd";
import "./tab.css";
import NotificationBadge from "../Badge/NotificationBadge";
import { useContext } from "react";
import { LanguageChangeContext } from "../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../utils/localization/languages";

const { TabPane } = Tabs;
function Tab(props) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, project } = dictionaryList[userLanguage];
  const {ProjectFeatureTabs} = project;
  const {
    panes,
    canChangeRoute = false,
    onChangeTab,
    defaultPath,
    className,
    activeKey = "",
  } = props;

  return (
    <>
      {canChangeRoute ? (
        <Tabs
          activeKey={canChangeRoute ? defaultPath : ""}
          onChange={onChangeTab}
          className={"custom_tab " + className}
          tabBarStyle={{
            background: "#ffffff",
            padding: "2px 5px",
            borderRadius: "10px",
            color: "var(--primary_theme_color_green)",
            fontWeight: "bold",
          }}
          dir={props.dir}
        >
          {panes?.map((pane) => {
            return (
              <TabPane
                tab={
                  <>
                  <div className="px-3.5">
                    <span>
                        {ProjectFeatureTabs[pane.featureId] ?? pane.featureName}
                      </span>
                    <NotificationBadge
                      notificationCount={pane.notificationCount}
                      style={{}}
                      customClass="absolute tab-NotificationBadge"
                    />
                    
                    </div>
                  </>
                }
                key={pane.featureId}
              >
                {pane.content}
              </TabPane>
            );
          })}
        </Tabs>
      ) : (
        <Tabs
          defaultActiveKey={activeKey}
          className={"custom_tab " + className}
          tabBarStyle={{
            background: "#ffffff",
            padding: "2px 5px",
            borderRadius: "10px",
            color: "var(--primary_theme_color_green)",
            fontWeight: "bold",
          }}
          dir={props.dir}
        >
          {panes?.map((pane) => {
            return (
              <TabPane
                tab={
                  <>
                    <span>
                    {ProjectFeatureTabs[pane.featureId] ?? pane.featureName}                      
                      </span>

                    <NotificationBadge
                      notificationCount={pane.notificationCount}
                      style={{
                        padding: "0px 2px",
                        fontSize:"10px"
                      }}
                      customClass="absolute right-0 text-xs top-0"
                    />
                  </>
                }
                key={pane.featureId}
              >
                {pane.content}
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </>
  );
}

export default Tab;
