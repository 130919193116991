export const convertSecondsToTime = (seconds) => {
  const date = new Date(seconds * 1000);
  const hours = date
    .getUTCHours()
    .toString()
    .padStart(2, "0");
  const minutes = date
    .getUTCMinutes()
    .toString()
    .padStart(2, "0");
  return `${hours}:${minutes}`;
};
