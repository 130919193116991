import { STRINGS } from "../../../../../../../utils/base";
import ReactionWrapper from "../../../../../reaction/view/reactionWrapper";
import { ReactionTypeEnum } from "../../../../../reaction/utils/enums/reactionTypeEnum";
import UserCommentFooterReply from "./reply";
import GetReactionModuleByComment from "../../../../../reaction/utils/constant";
import { CommentModuleEnum } from "../../../../utils/enum/commentModuleEnum";
import { useEffect, useState } from "react";

function UserCommentFooter({
  module = CommentModuleEnum.Feed,
  replyCount = 0,
  id = STRINGS.DEFAULTS.guid,
  parentId = STRINGS.DEFAULTS.guid,
  replyOnClick = () => {},
  myReaction = ReactionTypeEnum.NoReaction,
  reactionCount = 0,
  referenceId = STRINGS.DEFAULTS.guid,
  identifier,
}) {
  const [state, setState] = useState(replyCount);
  useEffect(() => {
    setState(replyCount);
  }, [replyCount]);
  console.log(replyCount, myReaction, identifier, "replyCount");

  return (
    <>
      <ReactionWrapper
        referenceId={id}
        myReaction={myReaction}
        reactionCount={reactionCount}
        module={module}
        raectionModule={GetReactionModuleByComment(module)}
        parentId={parentId}
        baseId={referenceId}
        identifier={identifier}
      />
      {parentId === STRINGS.DEFAULTS.guid && (
        <div className="border-l border-gray-300 h-4 mx-2">
          <UserCommentFooterReply
            parentId={parentId}
            id={id}
            replyCount={state}
            referenceId={referenceId}
            onClick={replyOnClick}
            identifier={identifier}
          />
        </div>
      )}
    </>
  );
}
export default UserCommentFooter;
