import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { CareerApplicantOfferResponse, VerifyUserByToken } from '../../store/action';
import { verifyTokenService } from '../../../auth/services/service';
import { verifyTokenAction } from '../../../auth/store/actions';

const CareerOfferConfirmation = () => {
  const initialState = {
    tokenStatus : null
  }
  const [state , setState] = useState(initialState)
    const queryParams = new URLSearchParams(window.location.search);

    const id = queryParams.get('id');
  const status = queryParams.get('status');
  const dispatch = useDispatch()
    console.log(id , status , "llllllll");
    useEffect(()=>{
        dispatch(verifyTokenAction(id)).then(({payload})=>{
          if(payload?.data?.status){
            const payload = {token : id , status : status }
            dispatch(CareerApplicantOfferResponse(payload))
          }
        })
    },[])

  return (
    <div>CareerOfferConfirmation</div>
  )
}

export default CareerOfferConfirmation