import React, { useContext, useState } from "react";
import { Dropdown } from "antd";
import { useSelector } from "react-redux";
import store from "../../../../../../store/store";
import { feedSlice } from "../../../store/slice";
import { PostPrivacyType } from "../../../utils/constants";
import WorldIcon from "../../../../../../content/world.svg";
import LockIcon from "../../../../../../content/lock.svg";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";

export default function PostPrivacyOptions({onChangePrivacy}) {
  const onPrivacyChange = (privacyType) => {
    store.dispatch(feedSlice.actions.onPostPrivacyChange({ privacyType }));
    onChangePrivacy(privacyType)
  };

  const { privacyType } = useSelector(({ feedSlice }) => feedSlice.postCompose);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      trigger={["click"]}
      open={isOpen}
      overlay={PostPrivacyOptionsMenu(onPrivacyChange, setIsOpen)}
    >
      <button className="dropdown-button" onClick={() => setIsOpen(true)}>
        <img src={PostPrivacyType.getPostTypeIcon(privacyType)} alt="" />
      </button>
    </Dropdown>
  );
}

function PostPrivacyOptionsMenu(onPrivacyChange, setIsOpen) {
  const { userLanguage } = useContext(LanguageChangeContext);
	const {sharedLabels } = dictionaryList[userLanguage];
  
  return (
    <div className="dropdown-wrapper" onClick={() => setIsOpen(false)}>
      <div onClick={() => onPrivacyChange(PostPrivacyType.PUBLIC)}>
        <img src={WorldIcon} alt="" />
        
        <span>{sharedLabels.Public}</span>
      </div>
      <div onClick={() => onPrivacyChange(PostPrivacyType.PRIVATE)}>
        <img
          src={LockIcon}
          alt=""
        />
        <span>{sharedLabels.Private}</span>
      </div>
    </div>
  );
}
