import React, { useState, useEffect, useContext } from 'react';
import { Modal, Form, Input, Button, Typography, Select, Checkbox, message } from 'antd';
import { FormLabel } from '../../../sharedComponents/Administration/StyledComponents/adminForm';
import { ProductFeedBackTypeEnum } from '../utils/ProductFeedBackTypeEnum';
import NavMenuList from '../../../sharedComponents/Nav/navbarMenuList';
import { useDispatch } from 'react-redux';
import { addProductFeedBack } from '../store/action';
import { dictionaryList } from '../../../../utils/localization/languages';
import { LanguageChangeContext } from '../../../../utils/localization/localContext/LocalContext';
const { Option } = Select;

const FeedBackForm = ({ isVisible, onClose }) => {
    const [form] = Form.useForm();
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, navMenuLabel } = dictionaryList[userLanguage]
    const [formData, setFormData] = useState({ features: [] });
    const { menuItems } = NavMenuList();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(menuItems, "menuItems");
    }, [menuItems]);

    const handleSubmit = (values) => {
        const type = ProductFeedBackTypeEnum[values.type];
        const featuresString = formData.features
            .map((id) => optionFeatures.find((feature) => feature.value === id)?.label)
            .join(', ');

        const payload = {
            subject: values.subject,
            type,
            features: featuresString,
            description: values.description,
        };

        dispatch(addProductFeedBack(payload))
            .then(() => {
                form.resetFields();
                setFormData({ features: [] });
                onClose();
            })
            .catch((error) => {
                console.error('Submission error:', error);
                message.error('Submission failed. Please try again.');
            });
    };

    const feedbackTypeEnums = Object.entries(ProductFeedBackTypeEnum).map(([key, value]) => (
        <Option key={value} value={key}>
            {key}
        </Option>
    ));

    const optionFeatures = menuItems.map(({ name, featureId, to }) => ({
        label: name,
        value: featureId,
    }));

    const handleSelectChange = (selectedValue, field) => {
        setFormData({
            ...formData,
            [field]: selectedValue
        });
    };

    return (
        <Modal
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            wrapClassName="custom-modal"
        >
            <Typography.Title level={3} style={{ textAlign: "center", color: "var(--currentThemeColor)" }}>
                {navMenuLabel.feedBack}
            </Typography.Title>
            <Form
                form={form}
                name="feedBack"
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
                initialValues={{ type: Object.keys(ProductFeedBackTypeEnum).find(key => ProductFeedBackTypeEnum[key] === 1) }}
            >
                <Form.Item
                    name="subject"
                    label={<FormLabel>{sharedLabels.subject}</FormLabel>}
                >
                    <Input placeholder={sharedLabels.subject} />
                </Form.Item>

                <Form.Item
                    name="type"
                    label={<FormLabel>{sharedLabels.type}</FormLabel>}
                >
                    <Select placeholder={sharedLabels.selectType} style={{ width: '100%' }}>
                        {feedbackTypeEnums}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="features"
                    label={<FormLabel>{sharedLabels.Features}</FormLabel>}
                >
                    <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder={sharedLabels.Selectfeature}
                        mode="multiple"
                        size="large"
                        showArrow
                        allowClear
                        value={formData.features}
                        onChange={(e) => handleSelectChange(e, "features")}
                        style={{ width: "100%" }}
                    >
                        {optionFeatures.map((item, index) => (
                            <Option key={index} value={item.value}>
                                <Checkbox
                                    checked={formData.features.includes(item.value)}
                                    onChange={(e) => {
                                        const checkedValue = e.target.checked
                                            ? [...formData.features, item.value]
                                            : formData.features.filter((val) => val !== item.value);
                                        handleSelectChange(checkedValue, "features");
                                    }}
                                >
                                    {item.label}
                                </Checkbox>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="description"
                    label={<FormLabel>{sharedLabels.Description}</FormLabel>}
                >
                    <Input.TextArea rows={4} placeholder={sharedLabels.EnterYourFeedBackDescription} />
                </Form.Item>

                <Form.Item>
                    <Button
                        style={{
                            width: "100%",
                            background: "var(--currentThemeColor)",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "16px",
                            marginTop: "5px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        {navMenuLabel.feedBack}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default FeedBackForm;
