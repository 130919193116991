import { MESSENGER_ENUMS } from "./Constant";

export const getMessageStatus = (chatType, statuses) => {
  if (!statuses) {
    return 1;
  }
  if (chatType === MESSENGER_ENUMS.CHAT_TYPES.INDIVIDUAL_CHAT) {
    return statuses[0].status;
  }
};
export const getMessageByMe = (createBy, currentUser) => {
  if (createBy === "local") return true;
  return createBy === currentUser.id;
};

export const findKeysById = (state, id) => {
  return Object.keys(state).filter((key) =>
    state[key].some((item) => item.chatWithId === id)
  );
};

export const findKeysAndIndicesById = (state, id) => {
  return Object.keys(state).reduce((acc, key) => {
    const index = state[key].findIndex((item) => item.id === id);
    if (index !== -1) {
      acc.push({ key, index });
    }
    return acc;
  }, []);
};
