import React from 'react'
import { Spin, Table } from 'antd';
import 'antd/dist/antd.css'; // Import Ant Design CSS
import '../../../style.css'


  const TableContainer = ({data = [] , columns = [] ,setTableHover ,isloading=false , showHeader = true}) => {
    console.log(data ,"dataaa");
   const onRow=(record, rowIndex) => {
      return {
        onClick: (event) => {}, // click row
        onDoubleClick: (event) => {}, // double click row
        onContextMenu: (event) => {}, // right button click row
        onMouseEnter: (event) => {setTableHover({recordId : record.id , targatedCell : event.target.cellIndex}); console.log(event ,"eventeventevent");}, // mouse enter row
        onMouseLeave: (event) => {setTableHover(null)}, // mouse leave row
      };
    }
    return (
        <>
     <Spin spinning={isloading} size="large">
        <Table
          onRow={onRow}
          dataSource={data}
          className="custom-header"
          pagination={false}
          columns={columns}
          bordered={false}
          size="small"
          scroll={{ y: '300px' }}
          showHeader={showHeader}
        />
      </Spin>
    </>
    );
  };
  
  export default TableContainer;
  