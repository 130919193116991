import { Button, Tag } from "antd";
import React, { useContext, useState } from "react";
import SideDrawer from "../../../../sharedComponents/Drawer/SideDrawer";
import CreateTravel from "../Page/Composer/createTravel";
import { useSelector } from "react-redux";
import { TravelStatusEnum } from "../../utils/travelStatusEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
function UpdateTravelContainer({ traveldetail = null }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { travel, sharedLabels } = dictionaryList[userLanguage];
  const { user } = useSelector((state) => state.userSlice);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      {traveldetail &&
        user.id === traveldetail?.createBy &&
        traveldetail?.status === TravelStatusEnum.InProcess && (
          <div className="flex justify-end mt-2">
            <Button
              onClick={() => setIsDrawerOpen(true)}
              className="!w-fit ThemeBtn"
            >
              {travel.UpdateTravel}
            </Button>
          </div>
        )}

      {isDrawerOpen && (
        <SideDrawer
          isDisable={true}
          title={travel.UpdateTravel}
          buttonText={travel.UpdateTravel}
          handleClose={() => setIsDrawerOpen(false)}
          handleOpen={() => setIsDrawerOpen(true)}
          isOpen={isDrawerOpen}
          children={
            <CreateTravel
              onFormSubmit={() => setIsDrawerOpen(false)}
              traveldetail={traveldetail}
              referenceType={traveldetail?.referenceType}
              referenceId={traveldetail?.referenceId}
            />
          }
        />
      )}
    </>
  );
}

export default UpdateTravelContainer;
