import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import {
  addPaymentCardAction,
  getAllBusinessPaymentCardAction,
  payBillAction,
} from "../store/action";
import { useDispatch } from "react-redux";
import { responseCode } from "../../../../services/enums/responseCode";

const promise = loadStripe(
  "pk_test_51Hs6ovE6WX7VMR4a2q3dV2dNIhjNSl1YIXa4PMDlAAUMmeYimKZoZ1B0et3b2n5VaGjaoFlDoNTIMDdxW0cMv7Jr00YyDry9GE"
);

export default function CheckoutFormWrapper({
  isAddCard = false,
  data = [],
  closeModal,
  billingId,
  token,
  state,
  setState,
}) {
  const dispatch = useDispatch();

  const handleAddorPayCard = (cardToken) => {
    return new Promise((resolve, reject) => {
      if (isAddCard) {
        dispatch(addPaymentCardAction(cardToken?.id))
          .then((response) => {
            dispatch(getAllBusinessPaymentCardAction());
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        setState({
          ...state,
          isProcessing: true,
        });
        const payload = {
          billingId: billingId,
          cardId: "",
          cardToken: cardToken?.id,
          token: token,
          saveCard: false,
        };

        dispatch(payBillAction(payload))
          .then((response) => {
            setState({
              ...state,
              isProcessing: false,
              isPaymentSuccess:
                response?.payload?.responseCode === responseCode.Success,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    })
      .then(() => {
        closeModal(); // Close modal upon successful form submission
      })
      .catch(() => {});
  };

  return (
    <Elements stripe={promise}>
      <CheckoutForm
        handleAddorPayCard={handleAddorPayCard}
        isAddCard={isAddCard}
        closeModal={closeModal}
      />
    </Elements>
  );
}
