import {CommentModuleEnum} from "../../utils/enum/commentModuleEnum";
import {STRINGS} from "../../../../../utils/base";
import {useEffect, useState} from "react";
import CommentList from "./commentList";

function CommentListWrapper({isHeightFull = false, comments = [],module = CommentModuleEnum.Feed,parentId = STRINGS.DEFAULTS.guid,
                                referenceId = STRINGS.DEFAULTS.guid ,identifier ,isOpenComposer=true}) {

    return( <CommentList isHeightFull={isHeightFull} referenceId={referenceId} comments={comments} module={module} identifier={identifier} isOpenComposer={isOpenComposer}/>);

}
export default CommentListWrapper;