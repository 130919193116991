import MasterConfig from "../../../../utils/services/MasterConfig"


export const addfeedBackService = (data) => {
    return MasterConfig.post(`api/ProductFeedback/AddProductFeedback`, data)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        });
};

export const GetFeedBackByIdService = (id) => {
    return MasterConfig.get(`api/ProductFeedback/GetProductFeedbackById?id=${id}`)
        .then((res) => {
            return res;
        })
        .catch((res) => {
            return res;
        });
};

export const getAllFeedbackPagingService = (payload) => {

    return MasterConfig.post(`api/ProductFeedback/GetAllProductFeedbackPaging`, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};
