import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { responseCode } from "../../../../services/enums/responseCode.js";
import { getAllDiscussionBoardCategory, addDiscussionBoardCategory, 
  updateDiscussionBoardCategory, removeDiscussionBoardCategory } from "./action.js";

const initialState = {
  boardcategory: [],
  loadingData: false,
  loader: false,
};

const DiscussionBoardCategorySlice = createSlice({
  name: "DiscussionBoardCategory",
  initialState,
  reducers: {
    DiscussionBoardCategoryDeleted: (state, { payload }) => {
      state.boardcategory = state.boardcategory.filter((e) => e.id !== payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDiscussionBoardCategory.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.boardcategory = payload.data;
      })
      .addCase(addDiscussionBoardCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.boardcategory.push(payload.data);
      })
      .addCase(updateDiscussionBoardCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.boardcategory = state.boardcategory.map((x) =>
          x.id === payload.data.id ? payload.data : x
        );
      })
      .addMatcher(isPending(...[addDiscussionBoardCategory, updateDiscussionBoardCategory]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getAllDiscussionBoardCategory]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(
        isRejected(...[getAllDiscussionBoardCategory, addDiscussionBoardCategory, updateDiscussionBoardCategory]),
        (state) => {
          state.loader = false;
          state.loadingData = false;
        }
      );
  },
});

export const { DiscussionBoardCategoryDeleted } = DiscussionBoardCategorySlice.actions;
export default DiscussionBoardCategorySlice.reducer;
