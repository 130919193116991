import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";

import {
  addLeave,
  //getAllLeaves,
  GetLeaveById,
  GetLeaveTypeAction,
  GetLeaveUserById,
  getAllLeaveAction,
  //getAllLeavePagingAction,
} from "./actions";
import { SegmentTypeEnum } from "../../../sharedComponents/Segment/utils/enum";
import { PrepareApprovalsByNewRemark } from "../../approval/utils/constant/constant";

const initialState = {
  data: null, //THis will be with identifiers
  newItem: null,
  totalRecords: [],
  recordSize: 0,
  newCountFilterButton: false,
};

const leaveSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    removeIdentifier: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined") delete state?.data?.[identifier];
    },
    handleResetState: (state, { payload }) => {
      state.data = [];
      state.totalRecords = 0;
    },
    handleNewItem: (state, { payload }) => {
      const { identifier } = payload;
      if (typeof state?.data !== "undefined")
        state.data[identifier] = [state.newItem].concat(
          state.data?.[identifier]
        );
    },
    handleNotificationNewItem: (state, { payload }) => {
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === payload.featureId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: referenceItem.notificationCount + 1,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log("===error===leave", e);
      }
    },

    readCountforLeave: (state, { payload }) => {
      const id = payload;
      try {
        const currentState = current(state);
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys?.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === id);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];
              referenceItem = {
                ...referenceItem,
                notificationCount: 0,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;

          state.newCountFilterButton = id;
        }
      } catch (e) {
        console.log("===error===leave", e);
      }
    },
    handleLeaveApprovalRemark: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, remark } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current]; // Create a deep copy of the current array
              let referenceItem = updatedCurrent[index];

              const approvers = [...referenceItem.approvers];
              const finalApprovers = PrepareApprovalsByNewRemark({
                remark: remark,
                approversData: approvers,
              });
              const approverStatus = Array.from(
                new Set(finalApprovers.map((item) => item.status))
              );
              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
                status: approverStatus.length > 1 ? 1 : approverStatus[0],
                approverStatus:
                  approverStatus.length > 1 ? 1 : approverStatus[0],
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptionss");
      }
    },
    handleAddLeaveApprover: (state, { payload }) => {
      try {
        const currentState = current(state);
        const { referenceId, module, approver } = payload;
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data };

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            if (index !== -1) {
              const updatedCurrent = [...current];
              let referenceItem = updatedCurrent[index];
              const approvers = [...referenceItem?.approvers];
              const finalApprovers = [...approvers, ...approver];

              referenceItem = {
                ...referenceItem,
                approvers: finalApprovers,
              };

              updatedCurrent[index] = referenceItem;

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
          state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
    },
    handleLeaveReminder : (state , {payload}) =>{
      console.log(payload , "payloadpayloadpayloadvas");
      const {referenceId , module , approvalId} = payload
      console.log(approvalId ,"executorsIndexexecutorsIndex")

      try {
        const currentState = current(state)
        if (currentState.data !== null) {
          const keys = Object.keys(currentState.data);
          let updatedState = { ...currentState.data};

          keys.forEach((identifier) => {
            const current = currentState.data[identifier];
            const index = current.findIndex((y) => y.id === referenceId);
            const approvalIndex = current[index].approvers.findIndex(item => item.id === approvalId)
        

            if(index!==-1)
            {
              const updatedCurrent = [...current]
              let referenceItem = updatedCurrent[index]

              if(approvalIndex !==-1)
              {
                let approvers =[ ...referenceItem.approvers]
                let currentApprover = approvers[approvalIndex]

                currentApprover = {
                  ...currentApprover,
                  reminderCount : currentApprover.reminderCount + 1,
                  lastReminder  : new Date().toISOString().slice(0, 19)
                }

                approvers[approvalIndex] = currentApprover
                
                referenceItem = {
                  ...referenceItem,
                  approvers : approvers
                }


              }

              updatedCurrent[index] = referenceItem

              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };
            }
          });
            state.data = updatedState;
        }
      } catch (e) {
        console.log(e, "Exceptions");
      }
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeaveAction.fulfilled, (state, action) => {
        const { segmentType, identifier, filter } = action?.meta?.arg;
        const data = action.payload ?? [];
        if (segmentType === SegmentTypeEnum.Grid) {
          state.data = {
            ...state.data,
            [identifier]: [...(state.data?.[identifier] || []), ...data],
          };
          state.recordSize = data.length;
        } else {
          state.data = {
            ...state.data,
            [identifier]: data.data,
          };
          state.totalRecords = action?.payload?.totalRecords;
        }
      })
      .addCase(GetLeaveById.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];

        state.data = {
          ...state.data,
          [identifier]: [data],
        };
      })
      .addCase(addLeave.fulfilled, (state, { payload }) => {
        state.newItem = payload.data.data;
      });
  },
});

export const {
  handleNewItem,
  removeIdentifier,
  handleResetState,
  handleLeaveApprovalRemark,
  handleAddLeaveApprover,
  handleNotificationNewItem,
  readCountforLeave,
  handleLeaveReminder
} = leaveSlice.actions;
export default leaveSlice.reducer;
