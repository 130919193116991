import React, { useContext, useEffect, useState } from "react";
import { Tag, Button, message, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import UserInfo from "../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "../../../sharedComponents/Tag/StatusTag";
import RequistionDefaultIcon from "../../../../content/NewContent/requisition/requistion.svg";
import {
  ItemContent,
  ItemHeader,
} from "../../../sharedComponents/Card/CardStyle";
import RemarksApproval from "../../../sharedComponents/AppComponents/Approvals/view";
import moment from "moment";
import { GetRequisitionById } from "../store/actions";
import {
  ApprovalsModule,
  ApprovalStatus,
} from "../../../sharedComponents/AppComponents/Approvals/enums";
import CopyToClipboard from "react-copy-to-clipboard";
import { ROUTES } from "../../../../utils/routes";
import { LinkOutlined } from "@ant-design/icons";
import Attachments from "../../travel/view/UI/Attachments";
import { RequisitionOfferStatusEnum } from "../enum/enums";
import { dictionaryList } from "../../../../utils/localization/languages";
import ExpandableDescription from "../../../sharedComponents/expandableDescription/expandableDescription";

function RequisitionDetailCard(props) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { requisitions, sharedLabels } = dictionaryList[userLanguage];
  const [isExpanded, setIsExpanded] = useState(false);
  const { Detail, loadingData } = useSelector(
    (state) => state.requisitionSlice
  );
  const { user } = useSelector((state) => state.userSlice);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [copy, setCopy] = useState(false);
  const [approvalDetail, setApprovalDetail] = useState(Detail?.approvers);
  const [finalApproval, setFinalApproval] = useState(Detail?.finalApprovers);
  const dispatch = useDispatch();

  let {
    InProcess,
    Approved,
    Declined,
    Resend,
    Inactive,
    NotRequired,
    Cancelled,
    ApprovalRequired,
    Hold,
    NoStatus,
  } = ApprovalStatus;
  let userId = user.id;

  useEffect(() => {
    setApprovalDetail(Detail?.approvers);
    setFinalApproval(Detail?.finalApprovers);
  }, [Detail]);

  useEffect(() => {
    props.id && dispatch(GetRequisitionById(props.id));
  }, [props.id]);

  if (!Detail) {
    return loadingData ? <Skeleton /> : null;
  }

  const {
    creator,
    name,
    description,
    image = RequistionDefaultIcon,
    reason,
    status,
    finalApprovers,
    createDate,
    budget,
    id,
    referenceNo,
    members = [],
    approvers,
    attachments,
    deadline,
  } = Detail;



  const localTime = moment
    .utc(createDate)
    .local()
    .format();

  const handleCancel = (e, payload) => {
    e.preventDefault();
    e.stopPropagation();
    // dispatch(cancelReward(payload));
  };

  const copyfunc = () => {
    setCopy(true);
    message.success("Copied");
  };

  const isTablet = false;

  const linkHandler = (id) => {
    console.log("navigation link");
    window.open(`http://localhost:3000/applyRequisition/${id}`, "_blank");
  };

  const isStatusApproved = status === RequisitionOfferStatusEnum.Approved;
  const currentDate = moment().startOf("day");
  const deadlineDate = moment(deadline).startOf("day");
  const isDeadlinePassed = deadlineDate.isBefore(currentDate);

  if (loadingData) return <Skeleton />;
  return (
    <>
      {" "}
      {Detail.id && (
        <div id={props.id} className="detailedViewComposer">
          <ItemHeader>
            <div className="left">
              <UserInfo
                avatarSrc={creator?.image}
                name={creator?.name}
                status={creator.userActiveStatus}
                profileId={creator.id}
                Subline={
                  <SublineDesigWithTime
                    designation={
                      creator?.designation ? creator?.designation : ""
                    }
                    time={moment(localTime).fromNow()}
                  />
                }
              />
            </div>
            <div className="right">
              <Tag className="IdTag">{referenceNo && referenceNo}</Tag>
              <StatusTag status={status && status}></StatusTag>
              {/* {copy && message.success("Copied")} */}

              {isStatusApproved && !isDeadlinePassed && (
                <>
                  <CopyToClipboard
                    text={`${window.location.origin}${ROUTES.REQUISITION.APPLYREQUISITION}/${id}`}
                    onCopy={copyfunc}
                  >
                    <Tag className="LinkTag ThemeBtn">
                      <LinkOutlined /> {sharedLabels.copyLink}
                    </Tag>
                  </CopyToClipboard>
                  <Button className="ThemeBtn" onClick={() => linkHandler(id)}>
                    {sharedLabels.link}
                  </Button>
                </>
              )}
            </div>
          </ItemHeader>
          <ItemContent className="flex">
            <div className="description">
              <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </div>
            <div className="!w-max m-4 ml-auto attachmentBox">
              <Attachments
                data={attachments}
                key={{ data: attachments }}
                toShow={1}
                onClick={() => { }}
                size={"50px"}
              />
            </div>
          </ItemContent>
          <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
            <div className="cardSectionItem">
              <div className="cardSection__title">
                {requisitions.Budget}
              </div>
              <div className="cardSection__body">{budget}</div>
            </div>
            <div className="cardSectionItem">
              <div className="cardSection__title">
                {sharedLabels.name}
              </div>
              <div className="cardSection__body">{name}</div>
            </div>
            <div className="cardSectionItem">
              <div className="cardSection__title">
                {sharedLabels.reason}
              </div>
              <div className="cardSection__body">{reason}</div>
            </div>
            {/* <div className="cardSectionItem">
              <div className="cardSection__title">
                {requisitionDictionary.approvers}
              </div>
              <div className="cardSection__body">
                {approvers && (
                  <Avatar
                    isAvatarGroup={true}
                    isTag={false}
                    heading={"approvers"}
                    membersData={approvers ? approvers : []}
                    text={"Approvers"}
                    image={"https://joeschmoe.io/api/v1/random"}
                  />
                )}
              </div>
            </div> */}
          </div>
          <RemarksApproval
            module={ApprovalsModule.RequisitionApproval}
            status={status}
            reference={id}
            onStatusChanged={(statusChanged) => {
              setUpdatedStatus(statusChanged);
            }}
            setApprovalDetail={setApprovalDetail}
            data={approvalDetail && approvalDetail}
            title={sharedLabels.approvers}
            itemCreator={creator}
          />
          <RemarksApproval
            module={ApprovalsModule.RequisitionFinalApproval}
            status={status}
            reference={id}
            onStatusChanged={(statusChanged) => {
              setUpdatedStatus(statusChanged);
              console.log(statusChanged);
            }}
            setFinalApproval={setFinalApproval}
            data={finalApproval && finalApproval}
            title={sharedLabels.FinalApprovers}
            itemCreator={creator}
          />
        </div>
      )}
    </>
  );
}

export default RequisitionDetailCard;
