import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addGroupAccessRole,
  getAllGroupAccessRoles,
  updateGroupAccessRoleById,
  getGroupAccessRoleById,
} from "./actions";

const initialState = {
  accessRoles: [],
  singleAccessRole: [],
  loader: false,
  createLoader: false,
  isSingleAccessRoleLoaded: false,
  success: false,
  error: false,
};

const groupsAccessRolesSlice = createSlice({
  name: "groupsAccessRole",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addGroupAccessRole.fulfilled, (state, { payload }) => {
        state.accessRoles.push(payload.data);
        state.success = true;
        state.createLoader = false;
      })
      .addCase(getAllGroupAccessRoles.fulfilled, (state, { payload }) => {
        state.accessRoles = payload.data;
        state.loader = false;
      })
      .addCase(getGroupAccessRoleById.fulfilled, (state, { payload }) => {
        state.singleAccessRole = payload.data;
        state.isSingleAccessRoleLoaded = false;
        state.loader = false;
      })
      .addCase(updateGroupAccessRoleById.fulfilled, (state, { payload }) => {
        state.accessRoles = state.accessRoles.map((obj) => {
          if (obj.id === payload.data.id) {
            return { ...obj, ...payload.data };
          }
          return obj;
        });
        state.success = true;
        state.createLoader = false;
      })
      .addMatcher(isPending(...[addGroupAccessRole]), (state) => {
        state.createLoader = true;
        state.success = false;
      })
      .addMatcher(isPending(...[updateGroupAccessRoleById]), (state) => {
        state.createLoader = true;
        state.success = false;
      })
      .addMatcher(isPending(...[getAllGroupAccessRoles]), (state) => {
        state.loader = true;
        state.success = false;
      })
      .addMatcher(isPending(getGroupAccessRoleById), (state) => {
        state.loader = false;
        state.success = false;
        state.isSingleAccessRoleLoaded = true;
      })
      .addMatcher(
        isRejected(
          ...[
            addGroupAccessRole,
            getAllGroupAccessRoles,
            getGroupAccessRoleById,
            updateGroupAccessRoleById,
          ]
        ),
        (state) => {
          state.loader = false;
          state.success = false;
          state.isSingleAccessRoleLoaded = false;
          state.error = true;
          state.createLoader = false;
        }
      );
  },
});

export default groupsAccessRolesSlice.reducer;
