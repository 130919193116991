import { TravelStatusEnum } from "./travelStatusEnum";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums/index";
export function calculateFinalStatus(statuses) {
    
    // If any status is Declined, return Declined
    if (statuses.includes(ApprovalStatus.Declined)) {
        return TravelStatusEnum.Declined;
    }

    // If any status is Hold, return InProcess
    if (statuses.includes(ApprovalStatus.Hold)) {
        return TravelStatusEnum.InProcess;
    }

    // If all statuses are Approved, return Approved
    if (statuses.every(status => status === ApprovalStatus.Approved)) {
        return TravelStatusEnum.Approved;
    }

    // If all statuses are InProcess, return InProcess
    if (statuses.every(status => status === ApprovalStatus.InProcess)) {
        return TravelStatusEnum.InProcess;
    }
    // Default value
    return TravelStatusEnum.InProcess;
}