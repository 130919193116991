import { Popconfirm, Tag, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { LanguageChangeContext } from '../../../../../utils/localization/localContext/LocalContext';
import Avatar from '../../../Avatar/avatarOLD';
import { ApprovalStatus, getStatusLabelAndColor } from '../enums';
import { ApprovalDictionary } from '../localization';
import { data } from 'jquery';
import { useSelector } from 'react-redux';
import ReminderWrapper from './reminderWrapper.js';
import DeleteApproval from './deleteApproval.js';
import { FeaturePermissionEnum } from '../../../../../utils/Shared/enums/featuresEnums.js';
import { dictionaryList } from '../../../../../utils/localization/languages/index.js';
function Header({ user, type, status, lastReminder, reminderCount, approvalId, module, reference }) {
  const { name, designation, image, approversId } = user;
  const userId = useSelector(({ userSlice }) => userSlice?.user?.id);
  const { checkHoverd } = useSelector(({ ApproverSlice }) => ApproverSlice)



  const { userLanguage } = useContext(LanguageChangeContext);
  const { status: statusLabels } = dictionaryList[userLanguage];

  console.log(userId, approversId, "status")
  // console.log(getStatusLabelAndColor("", statusLabels), "status")
  const { label, color } = getStatusLabelAndColor('', statusLabels)[status];

  const userPermissions = useSelector((state) => state.userSlice.user.permissions);


  return (
    <div className="approval__body-header">
      <div className="left">
        <Avatar
          src={image && image}
          name={name}
          round
          width={'30px'}
          height={'30px'}
        />
        <div className="userDetail">
          <span className="username">{name}</span>
          <span className="designation">{designation}</span>
        </div>
      </div>





      <div className="right flex gap-2">


        <ReminderWrapper
          approversId={approversId}
          reminderCount={reminderCount}
          approvalId={approvalId}
          status={status}
          lastReminder={lastReminder}
          module={module}
          key={approvalId}
          reference={reference}
          isHoverd={checkHoverd.id === approvalId}

        />


        <div>
          <Tag style={{ background: color }}>{label}</Tag>
        </div>
        <div>
          {
            (userPermissions.includes(FeaturePermissionEnum.RemoveApproval) && status !== ApprovalStatus.Approved && status !== ApprovalStatus.Removed && status !== ApprovalStatus.Declined) &&
            <DeleteApproval status={status} referenceId={reference} id={approvalId} module={module} />
          }

        </div>
      </div>
    </div>
  );
}

export default Header;
