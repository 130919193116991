import { createSlice, isPending } from "@reduxjs/toolkit";
import {
  addVoucher,
  getAllVoucher,
  getLedgerReport,
  getVoucherDetail,
} from "./actions";

const initialState = {
  editData: null,
  success: false,
  loader: false,
  createLoader: false,
  error: false,
  voucherDetail: null,
  voucherList: [],
  ledgerReport: null,
  totalCalculateNetSalary: 0,
  dataApprovalSelect: [],
  sortEmp: [],
};

export const voucherSlice = createSlice({
  name: "Voucher",
  initialState: initialState,
  reducers: {
    totalCalculateNetSalary: (state, action) => {
      console.log("dsdsdsds", action.payload);
      state.totalCalculateNetSalary = action.payload;
    },
    ApprovalSelectData: (state, action) => {
      console.log("dsdsdsds", action.payload);
      state.dataApprovalSelect = action.payload;
    },
    employeeSortData: (state, action) => {
      const { i } = action.payload;
      const existingIndex = state.sortEmp.findIndex((item) => item.i === i);

      if (existingIndex !== -1) {
        state.sortEmp.splice(existingIndex, 1);
      }

      state.sortEmp.push(action.payload);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addVoucher.fulfilled, (state, { payload }) => {
        state.createLoader = false;
        state.success = true;
        state.voucherList = [...state.voucherList, payload];
      })
      .addCase(getVoucherDetail.fulfilled, (state, { payload }) => {
        state.voucherDetail = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllVoucher.fulfilled, (state, { payload }) => {
        state.voucherList = payload.data;
        state.loader = false;
        state.success = true;
      })
      .addCase(getLedgerReport.fulfilled, (state, { payload }) => {
        state.ledgerReport = payload;
        state.loader = false;
      })
      .addCase(getVoucherDetail.pending, (state, { payload }) => {
        state.loader = true;
        state.voucherDetail = null;
      })
      .addMatcher(isPending(...[addVoucher]), (state) => {
        state.createLoader = true;
        state.success = false;
        state.error = false;
      });
  },
});
export const {
  employeeSortData,
  ApprovalSelectData,
  totalCalculateNetSalary,
} = voucherSlice.actions;
export default voucherSlice.reducer;
