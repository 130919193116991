import MasterConfig from "../../../../utils/services/MasterConfig";

// const API_PREFIX = "konnectapi/api/leave/leavetype/";
export const addPaymentCardService = (token) => {
  console.log("===here 3", token);
  return MasterConfig.get(`api/PaymentCard/AddPaymentCard?token=${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllBusinessPaymentCardService = () => {
  return MasterConfig.get(`api/PaymentCard/GetAllBusinessPaymentCard`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getInvoiceByIdService = (id) => {
  return MasterConfig.get(`api/Invoice/GetInvoiceById?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetInvoiceForPaymentService = (id) => {
  return MasterConfig.get(`api/Invoice/GetInvoiceForPayment?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const payBillService = (data) => {
  let { billingId, cardId, cardToken, token, saveCard } = data;
  return MasterConfig.get(
    `/api/Payment/PayBill?billingId=${billingId}&token=${token}&cardId=${cardId}&cardToken=${cardToken}&saveCard=${saveCard}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllInoviceService = (payload) => {
  return MasterConfig.post(`api/Invoice/GetAllInvoice`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
