import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import {
  getAllApprovalCountService,
  getAllApprovalService,
} from "../services/service";
import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";
import { handleApprovalRemark } from "../../CustomApprovals/store/slice";
import { handleTravelApprovalRemark } from "../../travel/store/slice";
import { handleExpenseApprovalRemark } from "../../expense/store/slice";
import { handlePromotionApprovalRemark } from "../../promotion/store/slice";
import { handleDocumentApprovalRemark } from "../../documents/store/slice";
import { handleResignationApprovalRemark } from "../../resignation/store/slice";
import { handleAppraisalApprovalRemark } from "../../appraisalModule/store/slice";
import { handleLeaveApprovalRemark } from "../../leave/store/slice";
import { handleWarningApprovalRemark } from "../../warning/store/slice";
import { handleBonusApprovalRemark } from "../../bonus/store/slice";
import { handleLoanApprovalRemark } from "../../loan/store/slice";
import { handleRewardApprovalRemark } from "../../reward/store/slice";
import { handleComplainApprovalRemark } from "../../complain/store/slice";
import { handleSalaryApprovalRemark } from "../../salary/store/slice";
import { handleQuotationApprovalRemark } from "../../quotation/store/slice";
import { handleFormApprovalRemark } from "../../forms/store/slice";
import { handleCareerApprovalRemark } from "../../careers/store/slice";
import { handlePayrollRemark } from "../../payroll/store/slice";
import { handleAuctionApprovalRemrk } from "../../auction/store/slice";

// export const getAllApproval = createAsyncThunk(
//   "Approval/getAllApproval",
//   async ({ isMyApproval, filter }, { rejectWithValue, dispatch }) => {
//     const response = await getAllApprovalService(filter);
//     switch (response.type) {
//       case ResponseType.ERROR:
//         return rejectWithValue(response.errorMessage);
//       case ResponseType.SUCCESS:
//         return response.data;
//       default:
//         return;
//     }
//   }
// );

export const handleNewApprovalRemark = createAsyncThunk(
  "Approval/handleNewApprovalRemark",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log(payload, "newApprovalRemark");
    const { module } = payload;
    switch (module) {
      case ApprovalsModuleEnum.CustomApproval:
        dispatch(handleApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.PromotionApproval:
        dispatch(handlePromotionApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.DocumentApproval:
        dispatch(handleDocumentApprovalRemark(payload));

      default:
        break;
      case ApprovalsModuleEnum.TravelApproval:
      case ApprovalsModuleEnum.TravelAgent:
        dispatch(handleTravelApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.ExpenseApproval:
      case ApprovalsModuleEnum.ExpenseExecutor:
      case ApprovalsModuleEnum.ExpenseFinance:
        dispatch(handleExpenseApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.ResignationAdminApproval:
      case ApprovalsModuleEnum.ResignationExitApproval:
      case ApprovalsModuleEnum.ResignationFinanceApproval:
      case ApprovalsModuleEnum.ResignationHrApproval:
      case ApprovalsModuleEnum.ResignationItApproval:
      case ApprovalsModuleEnum.ResignationOtherApproval:
      case ApprovalsModuleEnum.ResignationReportingToApproval:
        dispatch(handleResignationApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.AppraisalApproval:
        dispatch(handleAppraisalApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.LeaveApproval:
        dispatch(handleLeaveApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.BonusApproval:
        dispatch(handleBonusApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.LoanApproval:
        dispatch(handleLoanApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.RewardApproval:
        dispatch(handleRewardApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.WarningApproval:
        dispatch(handleWarningApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.ComplainApproval:
        dispatch(handleComplainApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.SalaryApproval:
        dispatch(handleSalaryApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.QuotationApproval:
        dispatch(handleQuotationApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.FormApproval:
        dispatch(handleFormApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.CareerApproval:
        dispatch(handleCareerApprovalRemark(payload));
        break;
      case ApprovalsModuleEnum.PayrollApproval:
        dispatch(handlePayrollRemark(payload));
        break;
      case ApprovalsModuleEnum.AuctionApproval:
          dispatch(handleAuctionApprovalRemrk(payload))
          break;
    }
  }
);

export const getAllApproval = createAsyncThunk(
  "Approval/getAllApproval",
  async (filter, { rejectWithValue, dispatch }) => {
    const response = await getAllApprovalService(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllDefaultApproval = createAsyncThunk(
  "Approval/getAllDefaultApproval",
  async (filter, { rejectWithValue, dispatch }) => {
    const response = await getAllApprovalService(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const getAllApprovalCount = createAsyncThunk(
  "Approval/getAllApprovalCount",
  async (filter, { rejectWithValue, dispatch }) => {
    const response = await getAllApprovalCountService(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
