export const DiscussionBoardStatusEnums = {
        Open : 1,
        Close : 2,
};
export const DiscussionBoardStatusLabelEnum = [
    {label : "Closed", value:DiscussionBoardStatusEnums.Close},
    {label : "Open", value:DiscussionBoardStatusEnums.Open}
]
export const DiscussionBoardReferenceTypeEnum = {
    General: 1,
    Project: 2,
    Group: 3,
}
export const DiscussionBoardFilterTypeEnum = {
    All:1,
    CreatedByMe:2,
    ForApproval:3,
}
export const  DiscussionBoardFilterSortEnum = 
{
    CreateDateDesc:1,
    CreateDateAsc:2,
    UpdateDateDesc:3,
    UpdateDateAsc:4,
}