export const InvoiceStatusEnumValue =
{
    Completed : 1,
    InCompleted : 2,
    Overdue : 3
}

export const InvoiceStatusEnum = {
    [InvoiceStatusEnumValue.Completed]  : "Completed",
    [InvoiceStatusEnumValue.InCompleted]  : "Incomplete",
    [InvoiceStatusEnumValue.Overdue]  : "Overdue"
}

export const InvoiceStatusColor = {
    [InvoiceStatusEnumValue.Completed]  : "#FF0000",
    [InvoiceStatusEnumValue.InCompleted]  :  "#1a5669",
    [InvoiceStatusEnumValue.Overdue]  : "#0E86D4",
}


