import React from "react";
import ApplyJob from "../../../careers/view/PublicRoute/ApplyJob";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import NoDataFoundJobBoard from "../NoDataFoundIconJobBoard/noDataFoundJobBoard";

const DetailedView = () => {
  const { jobBoardDetailView } = useSelector((state) => state.defaultHiringCriteriaSlice);

  const { loader, careerByIdLoader } = useSelector(
    (state) => state.careerSlice
  );
  const careers = useSelector((state) => {
    return state.careerSlice.items;
  });


  return (
    <>
      <div className="w-full" >
        {loader || careerByIdLoader ? (
          [...Array(1)].map((item) => (
            <Skeleton
              className="p-4"
              key={item}
              avatar
              paragraph={{ rows: 8 }}
            />
          ))
        ) :
          (
            <>
              {jobBoardDetailView ? (
                <ApplyJob isShowCopyBtn={true} width="100%" />
              ) : (
                <div className="p-20">
                  <NoDataFoundJobBoard />
                </div>
              )}
            </>
          )}
      </div>
    </>
  );
};

export default DetailedView;
