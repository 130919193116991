import MasterConfig from "../../../../utils/services/MasterConfig";

export const getAllFinanceAccountService = (userId) => {
    return MasterConfig.get(`api/Employee/GetAllEmployeeFinanceAccount?userId=${userId}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};
