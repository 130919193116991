export const DeviceTypeEnum = {
  // AndroidPhone: 1,
  // AndroidTab: 2,
  // AppleTab: 3,
  // ApplePhone: 4,
  // Windows: 5,
  Android: 1,
  Tab: 2,
  Ipad: 3,
  Iphone: 4,
  Web: 5,
};
