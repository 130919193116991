export const solutionTools = [
  {
    id: 1,
    description:
      "Project/Task Management (with external members inclusion, scheduling, tasks)",
  },
  {
    id: 2,
    description:
      "HR Management (including remote hiring, leaves, loans, resignations, complains, rewards, appraisals and much more)",
  },
  {
    id: 3,
    description:
      "Document creation/management (Including a complete office suite and digital signatures)",
  },
  {
    id: 4,
    description: "Process/Approval Management (Expenses, Travel and much more)",
  },
  {
    id: 5,
    description: "Communication Center (Email, Chat, Video Conferencing)",
  },

  {
    id: 6,
    description: "Finance (payroll management, lending, P&L and more)",
  },
];

export const Services = {
  description:
    "Catering to services and strategies that a business can use to improve their operational efficiencies and profitability. Workwise is a corporate social solution or simply put an Enterprise SaaS which hosts a number of innovative modules which include but are not limited to a complete communication solution with video conferencing, approval flow management, finance, payroll, document management/ creation, project management, HR management, lending and more.",
};

export const solutionAvailability = {
  description:
    "Our solution has been crafted with the vision of easy access to everyone who has access to the internet and  it is based on technology which enables secure, peer to peer communication without the need for intermediaries. Workwise is easy to access via web browser or any mobile platform i.e. iOS and android.",
};

export const marketPlace = {
  description:
    "A digital platform established to connect buyers and seller around the world, allowing organisation to trade goods and services with just a single click. Workwise is a market place designed to get the best options  in terms of services and goods, by eliminating the difficulty of establishing the trust between buyers and sellers simply by playing the mediator. The rise of e-commerce has fuelled the of online market places which speed up the process and enhancing the overall experience of procurement."
};

export const companyFeel = {
  description:
    "Making a company feel more connected, improving productivity and communication while making work fun.",
};

export const subjectMotive = {
  description:
    "Workwise provides you tools and resources to build a more meaningful relationship with the customers of your business by so you can connect with them on a deeper level. The system is designed to help you and your team manage every aspect of the customers journey from your initial contact to on going engagement and support with our advanced analytics and reporting capabilities. The data indexing and cataloging allows for customer facing employees to have a more detailed view of your customers to understand their preferences and needs enabling them to tailor their interactions and provide experiences that keeps them going the extra miles for those customers."
};

export const whatMakesDifferent = {
  description:
    "We do it all in one place with an easy-to-use solutions making team members more effective and organisations more efficient"
};

export const userFeedback = {
  description:
    "Today’s workforce is under siege by a flood of communications apps. More than ever, our attention is scattered across a wide array of different apps and communications channels. These apps are meant to bolster our productivity, but managing myriad apps is proving difficult—with serious repercussions for businesses.",
  name: "Riadh Dridi, CMO, RingCentral.",
};

export const registerText = {
  description:
    "Register for a 14 day free trial and get the opportunity to get the maximum out of your teams while reducing the work load. After the trial as low as only 15$ per user per month.",
};
export const moduleImages = [
  {
    id: 1,
    url:
      "https://workw.com/static/media/News%20Feed.39a4e27ce8d085fd12239c9105e2d300.svg",
    title: "Project/Task Management",
  },
  {
    id: 2,
    url:
      "https://workw.com/static/media/Schedules.201f16a1c7e5e002f028d81ca96386c2.svg",
    title: "HR Management",
  },
  {
    id: 3,
    url:
      "https://workw.com/static/media/Messenger.63d53f09d8938f3393b6736e906c183a.svg",
    title: "Document creation/management",
  },
  {
    id: 4,
    url:
      "https://workw.com/static/media/Travel.07e4191311ead4b827dfa30bca97f853.svg",
    title: "Process/Approval Management",
  },
  {
    id: 5,
    url:
      "https://workw.com/static/media/folder.284b44d67616ac0cf9af2431e9d72193.svg",
    title: "Communication Center",
  },
  {
    id: 6,
    url:
      "https://workw.com/static/media/Projects.61cb6e89238005785f31f20dda3c29bb.svg",
    title: "Finance",
  },
  {
    id: 7,
    url:
      "https://workw.com/static/media/Expenses.bd31b743524bd6ab5aaa3a6aa5117786.svg",
    title: "Market Place",
  },
  {
    id: 8,
    url:
      "https://workw.com/static/media/Tasks.587227158fc83719bd4b3741dcbd51c2.svg",
    title: "Market Place",
  },
  {
    id: 9,
    url:
      "https://workw.com/static/media/voucher.d2e0583e43775bea39086d76fc9373c9.svg",
    title: "Market Place",
  },
  {
    id: 10,
    url:
      "https://workw.com/static/media/Appraisals.5f733237e87beab2e1e11eab39ec356d.svg",
    title: "Market Place",
  },
  {
    id: 11,
    url:
      "https://workw.com/static/media/rewardIcon.1872d27791f08290da2b85977f16cf07.svg",
    title: "Market Place",
  },
  {
    id: 12,
    url:
      "https://workw.com/static/media/chartAccountsIcon.aeba4763829d93cab322f702239b7183.svg",
    title: "Market Place",
  },
];

export const cardsData = [
  {
    id: 1,
    title: "Flexible Project Management",
    image:
      "https://workw.com/static/media/Projects.61cb6e89238005785f31f20dda3c29bb.svg",
    description:
      "Providing you with the tools for the art of planning, organising and managing resources to achieve specific goals and objectives within a defined time frame. Our solution provides you simple views in order to get your attention to details which otherwise maybe overlooked, clear objectives and collaboration among the team members to ensure that all project activities are aligned with the over all project goals."
  },
  {
    id: 2,
    title: "Finance & More",
    image:
      "https://workw.com/static/media/Expenses.bd31b743524bd6ab5aaa3a6aa5117786.svg",
    description:
      "In order to ensure the stability and sustainability of the organization finance plays a crucial role. Workwise is equipped with the architecture to maintain accurate financial records while making it easy to cater to payroll, expenses, invoicing and more."
  },
  {
    id: 3,
    title: "Schedules & Deadlines",
    image:
      "https://workw.com/static/media/Schedules.201f16a1c7e5e002f028d81ca96386c2.svg",
    description:
      "Workwise handles all the essentials for coordinating your tasks, assigning responsibilities, and ensuring that every task is completed on time with reminders and ongoing notifications. Our solution prioritises tasks and manages your time effectively."
  },
  {
    id: 4,
    title: "Digital Archives",
    image:
      "https://workw.com/static/media/Schedules.201f16a1c7e5e002f028d81ca96386c2.svg",
    description:
      "A vital component for any business is its data and the management of that data. Our solution not only allows you to create that data but provides the ease of storage and retrieval of various types of documents. On Workwise you are able to maintain the previous versions while making sure the up to date records are the ones in circulation with alerts being generated to let team members know to check for changes. With Workwise you get a complete office suit allowing you to create all sorts of documents whether is be a simple note, spreadsheet, mindmap or more."
  },
  {
    id: 5,
    title: "Expense & Travel",
    image:
      "https://workw.com/static/media/Travel.07e4191311ead4b827dfa30bca97f853.svg",
    description:
      "A simple solution to manage all business expenses effectively whether incurred by the company or employees. With a complete approval flow process which ensure the expenses are legitimate. The solution provides you the real-time visibility into spending trends. Additionally within the travel space creating a more detailed expense view and management, from the approval of the travel to the point of completion of the travel with all expenses in between accounted and accordingly approved."
  },
  {
    id: 6,
    title: "HR Management",
    image:
      "https://workw.com/static/media/Appraisals.5f733237e87beab2e1e11eab39ec356d.svg",
    description:
      "In the business world, HR is a critical department within every organisation. Workwise, as a solution, offers a seamless resource management experience with a meticulously designed solution. From onboarding, salary management, rewards, bonuses, promotions, attendance, performance calibrations, warnings, Workwise covers it all and more. It empowers you to efficiently manage your time and resources to maximise efficiencies while keeping your team motivated and updated at all times."
  },
];
