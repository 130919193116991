export const AppraisalFilterSortEnum =
{
    UpdateDateDesc: 1,
    UpdateDateAsc: 2,
    ReferenceNoDesc: 3,
    ReferenceNo: 4,
    StatusDesc: 5,
    Status: 6,
    CreateDateDesc: 7,
    CreateDateAsc: 8,

}


export const appraisalTableSort = {
    referenceNo: {
        descend: AppraisalFilterSortEnum.ReferenceNoDesc,
        ascend: AppraisalFilterSortEnum.ReferenceNo
    },
    createDate: {
        descend: AppraisalFilterSortEnum.CreateDateDesc,
        ascend: AppraisalFilterSortEnum.CreateDateAsc
    },


}