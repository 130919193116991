import React, { useContext, useEffect, useState } from "react";
import PostSection from "../posts_list/post/views/PostSection";
import PostHeader from "../posts_list/post/views/PostHeader";
import { Modal } from "antd";
import PostDetail from "../postDetail";
import PostBody from "../posts_list/post/views/postBody";
function SharedPostItem({ post, isDetail }) {
  const [modelState, setmodelState] = useState(false);

  const handleState = (value) => {
    setmodelState(value);
  };

  return (
    <>
      <div
        onClick={() => {
          handleState(true);
        }}
        className="cursor-pointer"
      >
        <div className="sharePost">
          <PostBody post={post} isShared={true} />
        </div>
      </div>

      {modelState && post?.id && (
        <Modal
          title={null}
          open={modelState}
          footer={null}
          onCancel={() => handleState(false)}
          className={"modal-body"}
          width={700}
        >
          <PostDetail id={post.id} />
        </Modal>
      )}
    </>
  );
}

export default SharedPostItem;
