export const NonMandatoryApprovalsReferenceTypeEnum = {
  General: 1,
  Project: 2,
  Group: 3
}
export const NonMandatoryApprovalTypeEnum =
{
  Expense: 1,
  Travel: 2,
  Loan: 3,
  Leave: 4,
  Asset: 5,
  Salary: 6,
  Payroll: 7,
  ExpenseFinancers: 8,
  TravelAgent: 9,
  Reward: 10,
  ResignationHr: 11,
  ResignationAdmin: 12,
  ResignationIt: 13,
  ResignationFinance: 14,
  ResignationExit: 15,
  Requisition: 16,
  Warning: 17,
  Complain: 18,
  Bonus: 19,
  Promotion: 20,
  RequestForItem: 21,
  CustomApproval: 22,
  Form: 23,
  Document: 24,
  Career: 25,
  RequisitionFinalApproval: 26,
  RequestForItemAssetController: 27,
  Quotation: 28,
  QuotationClient: 29,
  Appraisal: 30,
  Auction:31,
  Attendance : 32
}

export const projectNonMandatoryApprovalTypeEnum = {

  
  Expense: 1,
  ExpenseFinancers: 8,
  Travel: 2,
  Quotation: 28,
  Document: 24,

};

export const GroupNonMandatoryApprovalTypeEnum ={

  
  Expense: 1,
  ExpenseFinancers: 8,
  Travel: 2,
  Quotation: 28,
  Document: 24,

};

export const SingleNonMandatoryApprovals = [
  NonMandatoryApprovalTypeEnum.ExpenseExecutor
]
export const prepareListData = (data , referenceId ,referenceType , moduleLabel)=>{
  console.log("referenceType" ,referenceType);
  if(referenceType == NonMandatoryApprovalsReferenceTypeEnum.Project ){
    return  Object.entries(projectNonMandatoryApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }else if(referenceType == NonMandatoryApprovalsReferenceTypeEnum.Group ){
    return  Object.entries(GroupNonMandatoryApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }else{
    return  Object.entries(NonMandatoryApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }}