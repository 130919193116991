import React, { useContext, useEffect, useState } from "react";
import PostModel from "./PostModel";
import PostAttachment from "./PostAttachment";
import Polls from "../../../../../../sharedComponents/Polls";
import { renderTitleWithMentions } from "../../../../../../../utils/base";
import { PostType } from "../../../../utils/constants";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import SharedPostItem from "../../../sharePostComposer/sharedPostItem";

const PostSection = ({ post, isOpen, onOpen, isDetail , isShared =false}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];
  const [clickedAttachmentIndex, setClickedAttachmentIndex] = useState("0")
  const {
    title = "",
    attachments = [],
    pollOptions = [],
    voteCount,
    mentions = [],
    type,
    id,
    parent = null,
  } = post;

  const [expanded, setExpanded] = useState(false);
  const [state , setState] = useState({
    text:title,
    turncateText:""
  })

  // Function to handle the toggle of show more/less
  const toggleExpansion = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setExpanded(!expanded);
  };

  useEffect(()=>{
      if(title)
      {
        const text = renderTitleWithMentions(title , mentions)
        const truncatedTitle = `${text?.slice(0, 250)}${
          text?.length > 250 ? "..." : ""
        }`;

        setState({
          text:text,
          turncateText:truncatedTitle
        })

      }

  },[title])


  
  return (
    <>
      <div className="post-section">
        <span
          className="whitespace-pre-line break-words flex-grow"
          dangerouslySetInnerHTML={{
            __html:  expanded ? state.text : state.turncateText,
          }}
        ></span>
        {state?.text?.length > 250 && (
          <span
            className="ml-2 cursor-pointer text-blue-500"
            onClick={toggleExpansion}
          >
            {expanded ? sharedLabels.Seeless : sharedLabels.Seemore}
          </span>
        )}

        {PostType.POLL === type ? (
          <Polls options={pollOptions} voteCounts={voteCount} id={id} />
        ) : (
          <div className="post-section-attachments">
            {attachments?.length > 0 && (
              <PostAttachment
                isDetail={isDetail}
                attachments={attachments}
                setClickedAttachmentIndex = {setClickedAttachmentIndex}
                onOpen={onOpen}
              />
            )}
          </div>
        )}

{        parent !== null && <SharedPostItem post={parent}/>}
      </div>
      {/* you can also pass a component to leftComponent which will render on the left side // put false in it if you don't want a component */}
       {isOpen && <PostModel
        // key={isOpen}
        id={post.id}
        setModelState={() => onOpen(false)}
        open={isOpen}
        leftComponent
        isDetail={isDetail}
        clickedAttachmentIndex = {clickedAttachmentIndex}
      />}
    </>
  );
};

export default PostSection;
