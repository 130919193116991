import { Modal } from "antd"
import React  from "react"

export default function TermsAndConditionWrapper({isOpenTermsAndCondition , setisOpenTermsAndCondtion}){
    return(
        <Modal
            title="Terms and Conditions"
            centered
            open={isOpenTermsAndCondition}
            onCancel={() => setisOpenTermsAndCondtion(false)}
            width={"70%"}
            footer={null}
        >

    <div>
      <h2><b>TERMS OF SERVICE</b></h2>
      <p>Dated: <b>20th December 2023</b></p>

      <p>
        By using the WorkWise website, services, and products, you are agreeing
        to these <b>Terms of Service</b> and the <b>Privacy Policy</b>. These
        terms include a waiver of class actions for any disputes related to the
        use of the WorkWise website, services, and products. Please note that
        these terms do not apply to any promotions or activities conducted by
        WorkWise, such as auctions, contests, and sweepstakes.
      </p>

      <p>
        The Services provided by WorkWise allow individuals and legal entities
        to access and use the <b>Website, Services, and Products</b>. These
        <b> Terms of Service</b> serve as a legal agreement between you and
        WorkWise, outlining your rights and obligations as a user. WorkWise's
        virtual software environment enables secure communication,
        collaboration, and creation for its users.
      </p>

      <p>
        The <b>Products</b> are intended solely for use by <b>Service Plan
        users</b> and are subject to the terms of the applicable end user
        license agreement. By accessing and utilizing the <b>Website, Services,
        or Products</b>, downloading or purchasing them, you are agreeing to
        abide by these <b>Terms of Service</b> and all the conditions stated
        herein or referenced on the Website.
      </p>

      <p>
        WorkWise may update the terms of this Agreement without prior notice.
        If there are significant changes, you will be notified in advance
        through a noticeable message within the Service or via email as outlined
        in section <b>31. Contact information</b>. The revised <b>Terms of
        Service</b> will become effective on the date indicated at the top of
        this page, unless otherwise specified in a notice to you. Please
        regularly review these <b>Terms of Service</b> for any updates.
      </p>
      <p>
        If you disagree with any modifications made to these <b>Terms of Service</b>,
        you must cease using any WorkWise Services and Products and refrain from
        accessing the Website. Your continued use of the Website, Services, and
        Products indicates your acceptance of the changes.
      </p>

      <p>
        WorkWise reserves the right, at its sole discretion, to modify,
        terminate, or restrict access to the Website, Services, and Products
        without prior notice.
      </p>

      <p>
        By using the Website, Services, and Products, you agree to only use them
        for your internal business purposes as permitted by these <b>Terms of Service</b>,
        as well as any applicable laws, regulations, or commonly accepted practices.
        It is your responsibility to ensure that your use of the Website, Services,
        and Products complies with all applicable laws, rules, and regulations.
        WorkWise is not liable for any violations of such laws, rules, or regulations
        committed by you or any third party acting on your behalf.
      </p>

      <p>
        You are responsible for ensuring that your use of the Services complies
        with all applicable laws and regulations in your country, state, or
        locality, as well as in the country, state, or locality where WorkWise
        is located or operates. This includes adhering to export and import
        restrictions and other limitations.
      </p>

      <p>
        When using the Services, you agree to only access the Website, the
        Services, and the Products through the approved means provided by
        WorkWise or its authorized partners. You also agree to refrain from
        using automated methods or engaging in any activity that disrupts or
        interferes with the Website, the Services, and the Products.
      </p>

      <p>
        Additionally, you agree not to cause harm to the Website, the Services,
        and the Products or any affiliated or linked sites. You must not attempt
        to use another user's User Account, password, or system, and you must
        not access any User Content that you are not authorized to access under
        these <b>Terms of Service</b>.
      </p>

      <p>
        <b>4. User Account. Administrator.</b>
      </p>

      <p>
        <b>4.1.</b> To access the Website, Services, and Products, all users must
        create their own User Account. Alternatively, a company can create a
        parent account with multiple employee User Accounts. When creating your
        User Account, it is important to provide accurate, up-to-date, and
        complete information about yourself. Failure to do so may result in the
        suspension or termination of your User Account. Once you have accepted
        the Terms of Service and your User Account registration has been
        approved by WorkWise, your User Account will be activated.
      </p>

      <p>
        <b>4.2.</b> If the User Account is created and owned by the Administrator,
        it will be considered the Administrator User Account.
      </p>

      <p>
        Please provide the following additional features for the WorkWise Customer Account:
        (1) the ability to disable certain functions, (2) the authority to delete
        users and their User Content and Personal Information, (3) the authority to
        delete Administrator User Accounts, (4) the authority to delete the entire
        WorkWise Customer Account, (5) the ability to grant users the status of
        Administrator, with the corresponding privileges outlined in this clause
        (if applicable) (referred to as the "Administrator").
      </p>

      <p>
        The WorkWise Customer Account encompasses the Service itself, including
        any additional features, all User Accounts, Administrator User Accounts,
        uploaded User Content and Personal Information, and other relevant
        information associated with the specified domain name.
      </p>

      <p>
        For the purposes of these Terms of Service, the Administrator User Account
        and User Account are collectively referred to as the "User Account",
        unless otherwise specified. Once you accept these Terms of Service, your
        User Account will be created.
      </p>

      <p>
        You are responsible for selecting a personal, non-transferable password.
        WorkWise may occasionally provide you with additional codes or passwords
        necessary to access and utilize certain other features or functions of
        the Website, the Services, and the Products.
      </p>

      <p>
        A social media account, such as Facebook or Twitter, or any other available
        platform, can be used as a User Account. It is important to note that User
        Accounts cannot be shared or used by multiple individuals. You are solely
        responsible for any activities that occur under your User Account,
        including the consequences of using or publishing User Content on or with
        respect to WorkWise. It is your responsibility to maintain the
        confidentiality of your User Account and you may not use anyone else's
        User Account without permission. WorkWise cannot be held liable for any
        harm resulting from the theft or misappropriation of your User Account and
        User Content, but you may be held responsible for any losses incurred by
        WorkWise or third parties due to the misappropriation and use of your User
        Account. If you notice any unauthorized use of your User Account, please
        immediately notify WorkWise using the contact information provided in the
        "Contracting Party" section below.
      </p>

      <p>
        You have the ability to upload data, information, material, and documents
        to be stored in the WorkWise Customer Account that you are connected to.
      </p>

      <p>
        By agreeing to our Terms of Service, you authorize the use of your User
        Content in any manner you have approved. However, you do not have the
        right to utilize the User Content of other users. It is important to note
        that WorkWise will not be held liable if any individual you have granted
        access to your User Content modifies, destroys, corrupts, copies, or
        distributes it.
      </p>
      <p>
        It is your sole responsibility to protect the information on your computer
        or other devices. This can be achieved by installing anti-virus software,
        keeping your applications updated, password protecting your files, and
        preventing unauthorized access to your computer. Please be aware that the
        User Content of other users may become corrupted due to viruses, software
        malfunctions, or other factors. By using and sharing such corrupted User
        Content, you agree that WorkWise is not accountable for any damage incurred
        by any user.
      </p>

      <p>
        Furthermore, you retain all rights to your User Content, including the
        ability to legally use, publish, transfer, or license it.
      </p>

      <p>
        To possess, post, transmit, or display any content in your country of
        residence or that of your end users is your responsibility.
      </p>

      <p>
        <b>5.4.</b> To understand how WorkWise handles users' personally identifiable
        information ("Personal Information"), including its collection, use,
        disclosure, management, and storage, please refer to the "Data Protection"
        section. You must have obtained all necessary consents and permissions in
        compliance with applicable laws before posting, transmitting, or publishing
        any Personal Information, as well as any images or likenesses of individuals,
        entities, or properties included in the User Content. It is your obligation
        to abide by all applicable laws. WorkWise does not have a direct relationship
        with the end users whose Personal Information it processes. If you are an
        end user and have any requests or inquiries regarding your Personal
        Information, please contact the relevant users directly. For instance, if
        you want to access, correct, modify, or delete inaccurate information
        processed by WorkWise, please reach out to the user who controls such data.
        We will respond to any request to remove end user Personal Information within
        thirty (30) days.
      </p>

      <p>
        <b>6. Assistance for Customers.</b>
      </p>

      <p>
        In order to access the benefits and privileges outlined in these Terms of
        Service, it is necessary for you to pay any applicable fees to WorkWise.
        However, please note that WorkWise reserves the right to modify the prices
        of our Services and/or Products at any time. We may also choose to offer
        certain Services and/or Products that are currently free of charge for a
        fee in the future. Rest assured, we will provide you with advance notice of
        at least ten (10) days before implementing any changes to the fees. If you
        do not agree with these changes, you have the option to cancel your
        subscription or terminate the Agreement as outlined in Section 8 of these
        Terms of Service. If you decide to cancel your subscription, it will be
        effective at the end of your current subscription period or payment period,
        and no refunds will be issued for any previously paid Services or Products.
        Additionally, please be aware that downgrading your Service Plan, including
        selecting a Free plan, may result in the loss of User Content or features
        for your WorkWise Customer Account. WorkWise cannot be held liable for any
        such loss.
      </p>

      <p>
        By agreeing to these Terms of Service (as stated in section 7.8), you are
        obligated to pay the fees quoted to you at the time of purchasing the
        Service. Please note that WorkWise reserves the right to change these fees
        at our discretion. If you do not agree to these changes, it is necessary
        for you to cancel your Service Plan subscription within your current billing
        period.
      </p>

      <p>
        Failure to cancel your subscription in accordance with section 7.8 will
        result in automatic renewal of the Service Plan at the current price and
        term length for the next subscription period.
      </p>
      <p>
        Please be advised that WorkWise does not guarantee indefinite availability
        of a particular Service Plan. We reserve the right, in compliance with
        applicable laws, to alter the fees or features of a Service Plan. In some
        cases, these plans may be transformed into "archived" status, which we
        refer to as the "Archived plans". These changes may occur without prior
        notification.
      </p>

      <p>
        Regrettably, we do not offer refunds for any fees associated with the
        Service Plan or your use of the Services and Products.
      </p>

      <p>
        The prices for accessing the Services and Products will be determined by
        mutual agreement between you and WorkWise.
      </p>

      <p>
        The Service Plan offered by WorkWise includes different fees and features
        for each plan. WorkWise reserves the right to modify, restructure, or
        terminate any Service Plan or Archived Plan at its sole discretion,
        including the decision to discontinue the sale or support of any plan if
        it is no longer economically feasible.
      </p>

      <p>
        When a commercial subscription term expires, there is a 5-day Grace period
        during which your WorkWise Customer Account remains active. If you
        purchase a new commercial subscription within this Grace period, the new
        subscription term will begin immediately after the previous one ends.
      </p>

      <p>
        If your commercial subscription term expires and you wish to continue
        using your WorkWise Customer Account under a Free or cheaper Service Plan,
        you must do so in order to avoid suspension of your account as outlined in
        Section 21.
      </p>

      <p>
        By opting for a more cost-effective Service Plan with reduced functionality
        and/or lower limits, Workwise reserves the right to retain your data until
        it deems necessary, after which it may choose to delete the data without
        prior notice.
      </p>

      <p>
        The Services and any Products will be delivered upon payment. All fees do
        not include taxes, levies, or duties imposed by taxing authorities, unless
        specified otherwise. It is your responsibility to pay any applicable taxes.
      </p>

      <p>
        You understand that the amount charged for the Service Plan may vary due to
        promotional offers, changes in your User Account, or changes in the amount
        of applicable sales tax/VAT/GST, etc. You authorize us to bill you for these
        varying amounts.
      </p>

      <p>
        Payment for access to Third Party Materials (as defined in Section 9 of
        these Terms of Service) will be made to third parties, if applicable,
        unless otherwise stated. You agree to use the Third Party Materials in
        accordance with these Terms of Service and subject to the terms of service
        provided by the third party.
      </p>

      <p>
        Your subscription will be renewed by deducting payment from the method you
        used for the initial purchase or enrollment. You have the option to disable
        this feature at any time through your User Account.
      </p>

      <p>
        <b>8. Term and Termination.</b>
      </p>

      <p>
        <b>8.1.</b> These Terms of Service will be effective from the moment you start
        using the Website, the Services, or the Products and will continue as long
        as you use them, unless either WorkWise or you terminate them in writing.
        WorkWise has the right to modify, suspend, or discontinue the Website, the
        Services, or the Products, or any part thereof (including any features of
        the Services), or terminate these Terms of Service with you at any time,
        without any liability to WorkWise. In such cases, WorkWise is not obligated
        to provide any refunds as described in section 7.2.
      </p>

      <p>
        <b>8.2.</b> If you wish to terminate these Terms of Service and delete your
        WorkWise Customer Account, you can do so by notifying WorkWise in writing
        at the address provided in the "Contracting Party" section below. It is
        solely your responsibility to terminate your WorkWise Customer Account.
      </p>

      <p>
        In accordance with Section 21.3.5. of these Terms of Service, if you fail to
        comply with any of the limitations or other requirements described herein,
        these Terms of Service will automatically terminate (except for the
        provisions that shall survive). Upon termination, you must immediately stop
        using the Website, the Services, and the Products, including any use of
        WorkWise's Intellectual Property.
      </p>

      <p>
        WorkWise offers various additional functions that can be activated and
        deactivated by the user, acting on behalf of the Administrator. These
        additional functions may be provided by WorkWise or third parties, either
        for an extra fee or free of charge. Some additional functions may require
        updating the Website and the Services. By using any of the additional
        functions, you acknowledge and agree to comply with the applicable rules and
        requirements (referred to as "Rules") and any relevant laws, regulations,
        or generally accepted practices or guidelines within your jurisdiction.
      </p>

      <p>
        The Rules applicable to additional functions are an integral part of these
        Terms of Service and can be found at the following links:
      </p>

      <p>
        <i>Now, let's talk about extensions.</i>
      </p>

      <p>
        Extend the number of users for your WorkWise Customer Account by purchasing
        the User pack extension. This will add the corresponding amount of users to
        your current subscription period of the Service Plan "WorkWise Enterprise
        1000 (12-Month Subscription)" without extending the subscription period.
      </p>
      <p>
        WorkWise may include links to other websites and third-party products and
        services that are not under our control. These may include Telephony, VMA,
        and services available through the WorkWise Marketplace. These links are
        provided for your convenience, but they do not imply endorsement by WorkWise
        of the content, products, or services of these third-party materials. Please
        note that you do not have any rights or licenses to use these third-party
        materials under these Terms of Service.
      </p>

      <p>
        Users of the Plan may utilize WorkWise, as mentioned in Section 1.3. above,
        as well as designated third parties and/or distributors ("Sellers"), without
        negating the application of these Terms of Service to the Website, Services,
        and Products.
      </p>

      <p>
        By agreeing to this, you acknowledge that any third party providing
        Additional functions or Third Party Materials may choose to suspend or
        discontinue their products and services at their own discretion, without
        prior notice, resulting in the disabling of corresponding features. WorkWise
        holds no responsibility for such suspension or discontinuation.
      </p>

      <p>
        In certain jurisdictions, you have the option to purchase telephony or Voice
        over Internet Protocol Telephony ("Telephony") for your WorkWise Customer
        Account. It is important to note that the Telephony, if available, is
        provided by third party Vonage, Inc. and is subject to their terms of service
        (<a href="https://vonage.com/legal/tos/" target="_blank" rel="noopener noreferrer">https://vonage.com/legal/tos/</a>). Payment for the Telephony should be made directly to Vonage, Inc.
        If your WorkWise Customer Account or telephone number is terminated or
        suspended for any reason, you may lose the ability to use any remaining
        Telephony credits.
      </p>

      <p>
        The transmission of information through third party WEBRTC and its software
        license is not the responsibility of WorkWise. WorkWise does not assume any
        liability for the Third Party Materials. Accessing the Website, Services, and
        Products does not grant you ownership of the intellectual property rights to
        the Licensed Images provided. Licensed Images from the Website, Services, and
        Products can only be used if you acquire licensing rights from the owner.
      </p>

      <p>
        <b>9.4. WorkWise Marketplace.</b>
      </p>

      <p>
        <b>9.4.1.</b> WorkWise Marketplace serves as an online platform for users and
        Sellers. It is important to note that WorkWise is not involved in any
        transactions or relationships between users and Sellers. Here are a few key
        points to acknowledge and remember:
      </p>

      <ol>
        <li>Your purchase is not made from WorkWise, nor do you enter into a contract with WorkWise. Your purchase and contract are solely with the Seller in question;</li>
        <li>WorkWise will not mediate or be involved in any disputes between you, Sellers, or other users. Any claims should be addressed directly with the relevant Seller;</li>
        <li>WorkWise does not screen Sellers or the items they advertise in the Catalog on our Marketplace. Therefore, WorkWise cannot be held responsible for any items sold or the content of any App;</li>
        <li>WorkWise does not take responsibility for any aspect of a transaction and does not provide warranties regarding the quality, safety, or legality of any item(s) purchased, downloaded, or installed from Sellers on our Marketplace. WorkWise will not intervene in any disputes between you, Sellers, or other users.</li>
      </ol>

      <p>
        WorkWise may not offer the same payment methods, processing times, or
        delivery options (or prices).
      </p>

      <p>
        <b>10. Language used in the Terms of Service.</b>
      </p>

      <p>
        If WorkWise has translated the English version of these Terms of Service into
        another language, you acknowledge that the translation is only for your
        convenience and that the English version will govern your relationship with
        WorkWise.
      </p>

      <p>
        <b>11. Intellectual Property.</b>
      </p>

      <p>
        <b>11.1.</b> WorkWise, its affiliates, and its licensors (if any) hold the
        copyright, trademark, and all other proprietary rights in the Website,
        Services, and Products (referred to as "WorkWise's Intellectual Property").
        Unless specifically stated or authorized by WorkWise in writing, all rights
        in the Website, Services, and Products that are not explicitly granted are
        reserved. You agree not to copy, republish, frame, make available for
        download, transmit, modify, rent, lease, loan, sell, assign, distribute,
        license, sublicense, reverse engineer WorkWise's Intellectual Property, or
        create derivative works based on it.
      </p>

      <p>
        <b>11.2.</b>
      </p>

      <p>
        <b>11.3.</b> You acknowledge and agree that in order to make necessary display
        adjustments and perform other technical actions, we will need to upload your
        content to our platform, including cloud services and CDN's.
      </p>

      <p>
        <b>12. Warranties and Disclaimers.</b>
      </p>

      <p>
        <b>12.1.</b> THE WEBSITE, THE SERVICES, AND THE PRODUCTS ARE PROVIDED "AS IS" AND
        "AS AVAILABLE." WORKWISE EXPRESSLY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES OF
        ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT,
        REGARDING THE OPERATION OF THE WEBSITE, THE SERVICES, AND THE PRODUCTS. UNLESS
        OTHERWISE AGREED IN WRITING BY WORKWISE, CONTRACTORS, AGENTS, DEALERS, OR
        DISTRIBUTORS OF WORKWISE OR ANY THIRD PARTY SHALL NOT HAVE THE RIGHT TO MODIFY
        THIS LIMITED WARRANTY OR MAKE ANY ADDITIONAL WARRANTIES.
      </p>

      <p>
        <b>12.2.</b> WORKWISE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE
        SECURITY OF THE WEBSITE, THE SERVICES, AND THE PRODUCTS. YOU ACKNOWLEDGE THAT ANY
        INFORMATION TRANSMITTED MAY BE INTERCEPTED OR OTHERWISE COMPROMISED. WORKWISE
        DOES NOT WARRANT THAT THE WEBSITE, THE SERVICES, AND THE PRODUCTS OR ELECTRONIC
        COMMUNICATIONS SENT BY WORKWISE ARE FREE FROM VIRUSES OR ANY OTHER HARMFUL
        ELEMENTS.
      </p>

      <p>
        By proceeding, you acknowledge and agree to assume all responsibility for any
        potential damage to your computer system or loss of data that may result from
        these activities. Please be aware that WorkWise cannot be held liable for any
        computer viruses or similar software code that may be downloaded to your
        computer while using the website, services, or products. Any advice or
        information obtained from WorkWise or through these platforms does not create
        any additional warranties beyond what is expressly stated in these terms of
        service.
      </p>

      <p>
        WorkWise will make every reasonable effort to limit access to your User Account
        and User Content to unauthorized individuals. However, it is important to
        recognize that no password-protected system can guarantee complete
        invulnerability. You are responsible for maintaining the confidentiality of
        your password and safeguarding your User Content against unauthorized access,
        viewing, copying, or modification.
      </p>

      <p>
        Please note that the Website may mention Services and Products that may not be
        available in certain countries.
      </p>

      <p>
        You acknowledge and accept that when accessing the Website, utilizing the
        Services, and purchasing the Products, there is a possibility of encountering
        content that may be offensive, inappropriate, or objectionable. It is important
        to recognize that you assume the responsibility and risk associated with using
        the Website, Services, and Products in this regard.
      </p>

      <p>
        Workwise shall not be held responsible for any delays or interruptions to the
        website, services, or products, even if they were aware of the possibility of
        such damages. Additionally, Workwise and its third-party providers will not be
        liable for any termination, suspension, interruption, or delay of the website,
        services, or products. It is important to note that the maximum liability of
        Workwise for damages under this agreement, whether due to breach of contract or
        tort, will not exceed the amount paid by you to Workwise in the two-month period
        prior to the claim arising, and will be limited to a maximum of $100 (or $10
        for free plans). This limitation cannot be expanded, even if there are multiple
        claims.
      </p>

      <p>
        Any claims or disputes related to the Website, Services, and Products must be
        initiated and communicated to WorkWise in writing within one year of the
        incident. Despite this, it must be clarified that WorkWise cannot be seen as a
        "publisher" of any User Content and does not endorse such content in any way.
        WorkWise assumes no responsibility for any User Content uploaded or made
        available by users or other parties on the Website, Services, and Products, or
        for any use, loss, deletion, or damage related to such content. Additionally,
        WorkWise will not be held liable for any errors, defamation, false information,
        obscenity, pornography, incitement, or any other unlawful or infringing User
        Content encountered by users or other parties.
      </p>

      <p>
        The risks associated with the violation or harm of such content.
      </p>

      <p>
        <b>13. Your Assurances.</b>
      </p>

      <p>
        <b>13.1.</b>
      </p>

      <p>
        (6) or promote illegal activities are prohibited.
      </p>

      <p>
        <b>13.2.</b> By using the Website, the Services, and the Products, you acknowledge
        that you are solely responsible and assume any risks in the event that they
        violate your country's national laws.
      </p>

      <p>
        <b>14. Export Control and Compliance with Laws.</b>
      </p>

      <p>
        <b>14.1.</b> The Website, the Services, and the Products are subject to export
        controls and economic sanctions laws and regulations of the United States and
        the European Union. These include, but are not limited to, the United States
        Export Administration Regulations ("EAR"), regulations enforced by the US
        Department of the Treasury's Office of Foreign Assets Control ("OFAC"), and
        regulations enforced by the Council of the European Union ("EU"). You agree that
        the Website, the Services, and the Products cannot be exported or re-exported
        to embargoed or restricted countries or territories, or to individuals or
        entities listed on OFAC's Specially Designated Nationals list, EAR's lists, or
        the EU regulations.
      </p>
      <p>
        <b>14.2.</b> Both you and WorkWise are responsible for adhering to export controls,
        sanctions laws, and all other applicable laws, rules, and regulations outlined in
        this Terms of Service agreement. If you find yourself subject to any export control
        and sanctions regulations, it is your duty to promptly inform WorkWise. WorkWise
        maintains the right to limit access to the Website, Services, and Products at any
        time in compliance with these laws and regulations, without prior notification.
      </p>

      <p>
        <b>15. Copyright Infringement.</b>
      </p>

      <p>
        <b>15.1.</b> WorkWise follows a policy of promptly addressing notices of alleged
        copyright infringement that align with relevant international intellectual
        property law, including the Digital Millennium Copyright Act in the United States.
        If you believe that your work has been unlawfully copied in a manner that violates
        copyright law, please furnish WorkWise's Copyright Agent with the following
        written information. (Please note that this procedure is solely intended for
        notifying WorkWise of copyright infringement.)
      </p>

      <ol>
        <li>A signature, either electronic or physical, from the copyright owner or an
            authorized representative acting on their behalf;</li>
        <li>A detailed description of the copyrighted work that has allegedly been
            infringed;</li>
        <li>The infringing content can be found on the Website;</li>
        <li>Please provide your address, telephone number, and email address;</li>
        <li>You must state that you genuinely believe the disputed use is not authorized
            by the copyright owner, its agent, or the law;</li>
        <li>You must also state, under penalty of perjury, that the information provided
            in your notice is accurate and that you are the copyright owner or authorized
            to act on behalf of the copyright owner.</li>
      </ol>

      <p>
        <b>15.2.</b> To notify WorkWise of any claims of copyright infringement on the
        Website, please contact our Copyright Agent at info@fareyaaraoui.com.
      </p>

      <p>
        <b>15.3.</b> WorkWise may, at its discretion, terminate User Accounts of individuals
        who have received multiple takedown notifications resulting in the permanent
        removal of their User Content (known as "Repeat Infringers"). By agreeing to these
        terms, you acknowledge and accept that WorkWise reserves the right to terminate
        User Accounts if it believes, in its sole discretion, that the User Account
        infringes on the intellectual property rights of third parties, including the User
        Accounts of Repeat Infringers.
      </p>

      <p>
        <b>16. Modifications to the Website, Services, and Products can be made by
          WorkWise.</b>
      </p>

      <p>
        <b>16.1.</b> The items, or any component thereof (including any characteristics of
        the Services), may be altered, improved, advanced, refined, or adjusted
        occasionally as we see fit and at our own discretion, encompassing the contents of
        Service Plans and Archived Plans. These adjustments may manifest as error
        corrections, upgrades, the addition or removal of features, the establishment of
        new or alteration of existing usage limits (including, but not limited to, the
        number of users and Administrators, online storage space, maximum file size,
        pipelines, custom fields, analytical reports, storage capacity limits, email
        limits, site limits, role limits, search limits, etc.), new modules, modifications
        to the user interface, or other variations, as well as changes that align with
        imposed regulatory or statutory regulations and standards. Such updates and
        modifications are decided upon by WorkWise based on reasonable judgment and can be
        implemented without prior notice.
      </p>

      <p>
        <b>16.2.</b> If you choose to download the Products, it may automatically download
        and install subsequent updates for said Products. These updates are intended to
        enhance, refine, and further develop the Products and may include error fixes,
        improved functionalities, new software modules, and entirely new versions.
      </p>

      <p>
        <b>17. Publicly-Edited Sections.</b>
      </p>

      <p>
        <b>17.1.</b> The Website may include areas that can be edited by anyone and are
        visible to all visitors, such as forums, chats, guestbook, and comments
        ("Publicly-Edited Sections"). It is important to use caution when sharing personal
        information in these sections, as it can be seen by others. The use of personal
        information shared in Publicly-Edited Sections is subject to the Privacy Policy.
      </p>

      <p>
        <b>17.2.</b> WorkWise has the right to modify or delete any messages submitted to
        the Publicly-Edited Sections, at any time and for any reason. This includes content
        that, in WorkWise's opinion:
      </p>

      <ol>
        <li>may be defamatory, invade privacy, or be obscene, pornographic, abusive, or
            threatening;</li>
        <li>may infringe on the intellectual property or other rights of any individual
            or entity;</li>
        <li>may violate any applicable laws or promote illegal activities;</li>
        <li>advertises or solicits funds, goods, services, advertisers, or sponsors, or
            engages in any commercial activity;</li>
        <li>disrupts the normal flow of conversation or hinders others from engaging in
            real-time activities on the Website.</li>
      </ol>
      <p>
      shall not engage in any activities that involve the transmission of harmful computer code or programs, such as viruses or worms, or any actions that aim to disrupt or disable computer software, hardware, or telecommunications.
b. shall comply with all policies and regulations pertaining to the use of the Website, the Services, and the Products, as well as any networks connected to the Website.
c. shall not include links to other websites that contain the types of content described in points (a) to (g) above.
18. Unlawful or Prohibited Use.
18.1. Your use of the Website, the Services, and the Products must not be unlawful, violate these Terms of Service, or interfere in any way with the proper functioning of the Website, the Services, and the Products. Furthermore, you are prohibited from using the Website, the Services, and the Products in a manner that could harm, disable, overload, or impair them, or that infringes on the rights and enjoyment of any third party. It is agreed that you:

the Products will not engage in or support any form of attack on WorkWise servers or any attempts to disrupt their functioning, as this would be a violation of both criminal and civil laws. In the event that such an attempt is made or assistance is provided for such an attack, WorkWise reserves the right to pursue damages from the responsible user to the fullest extent permitted by law.

Additionally, the Products will not share any disparaging information about WorkWise, the Website, the Services, or any third party in a manner that could be considered defamatory, libelous, obscene, harassing, threatening, incendiary, abusive, racist, offensive, deceptive, or fraudulent. The Products will not promote criminal or harmful behavior, discriminate against any person or group based on their race, gender, religion, nationality, disability, sexual orientation, or age, or violate WorkWise's Intellectual Property or the rights of any third party.

You are prohibited from engaging in any activities that may harm or disrupt the operation of hardware, software, or telecommunications equipment, or any other potentially harmful or invasive code or component. Additionally, you must not burden the infrastructure of WorkWise Services or interfere with their operation, or violate any requirements or regulations of the servers or networks hosting the services. Furthermore, the use of the Website, Services, and Products for spam, fraud, scams, or unethical marketing is strictly prohibited. If you violate any of these terms or engage in abusive or inappropriate behavior, WorkWise may take action against your WorkWise Customer Account or your WorkWise.Sites, including the removal of User Content and suspension of your account.

WorkWise reserves the right to modify or discontinue its Services and Products at any time and for any reason, without prior notice. The decision to enforce or not enforce these Terms of Service rests solely with WorkWise.

Section 19: Indemnification
By agreeing to these Terms of Service, you are accepting the responsibility to indemnify and protect WorkWise, its affiliates, licensors and licensees, officers, directors, agents, and employees from any expenses, losses, claims, damages, fines, penalties, or liabilities. This includes reasonable fees for attorneys and other professionals, which may be incurred as a result of any judgment, verdict, court order, settlement, or any legal action initiated by a third party. This indemnification extends to claims, demands, actions, suits, arbitrations, or other proceedings, including those initiated by governmental agencies or entities, that arise due to your violation of these Terms of Service.


When it comes to linking to the Website, there are certain rules that must be followed. You have the freedom to link to the Website's home page or any other page on the site. However, it is not permissible to use in-line linking or framing. It is important to note that linking to the Website does not imply that WorkWise endorses or sponsors your website, products, or services. Additionally, you must obtain written permission from WorkWise before using any of their Intellectual Property. Lastly, if WorkWise requests it, you must remove the link at any time.

In regards to the suspension and deletion of accounts, there are specific procedures in place. Users have the option to terminate their User Account, but this will result in the loss of access to their related WorkWise Customer Account. The Administrator(s) will be notified when a User Account Deletion Request is made.

When it comes to the deletion of the Administrator User Account, the process is as follows.

If your WorkWise Customer Account remains inactive for 30 days while on the Free service plan, it will be archived. Only an Administrator can retrieve the account by logging in. However, if no Administrator logs in for an additional 15 days after the account has been archived, it will be permanently deleted. Please note that if you are the only Administrator for the account, you must assign administrative privileges to another user before requesting deletion. Failure to do so may result in your deletion request being handled on a case-by-case basis. If there are multiple Administrators for the account, the other Administrators will be promptly notified of the impending deletion of your profile.

In the event that a User Content is found to be incompatible with a Free or cheaper Service Plan, it shall not be deleted, as stated in Section 7.4. of these Terms of Service.
To regain access to a suspended WorkWise Customer Account due to the aforementioned reason, a new commercial subscription must be purchased, ensuring all necessary features and limits for proper account functioning.
WorkWise retains the right to delete a WorkWise Customer Account, along with all associated data and User Content, if the account remains suspended for a period of 50 calendar days, as defined in Section 21.3.2. of these Terms of Service. In such cases, the domain name of the deleted account can be assigned to other customer accounts. WorkWise assumes no responsibility for any damages, liabilities, losses (including data or profit loss), or any other consequences resulting from these actions.

When it comes to the WorkWise Customer Account, if it needs to be deactivated and deleted, it will be done so immediately and completely within a period of 90 calendar days. There will be no refunds given in any case of account deletion. 

WorkWise has the ability to assign or delegate these Terms of Service to any person or entity as long as there is prior notification. However, you cannot assign or delegate any rights or obligations without written consent from WorkWise. Any unauthorized assignment or delegation by you is considered invalid.

All transactions related to the Website, Services, and Products can be conducted electronically. We will keep records of all communications conducted through the Website. Electronic records are considered sent once they are properly addressed and enter a system outside of the sender's control or enter a system under the recipient's control.

The recipient must be able to access and process the electronic record, which contains the required information for the system. WorkWise has the right to obtain immediate injunctive relief if there is a violation or breach of these Terms of Service, as it may cause immediate and irreparable harm and damages. WorkWise may also seek permanent injunctive relief and specific performance of any term in these Terms of Service, in addition to other available remedies. These Terms of Service will be enforced to the maximum extent allowed by law.

Data Protection is an important aspect of the Terms of Service provided by WorkWise. Any personal information that WorkWise collects and uses will be handled in accordance with local Data Protection legislation, the GDPR, and the user's rights under Data Protection Legislation. More information about our GDPR compliance can be found in our dedicated GDPR section.

To understand how WorkWise manages users' Personal Information, it is recommended to read the Privacy Policy. This policy outlines how WorkWise collects, uses, discloses, manages, and stores personal information.

In order to provide the Service, WorkWise processes any users-of-users Personal Data as per the applicable Data Protection Legislation. This is done on your behalf and under your instructions, in accordance with the terms of the Data Processing Agreement (DPA). The DPA is incorporated by reference and both parties agree to comply with its terms.

It is your responsibility to obtain all necessary notices, permissions, and consents to collect, use, and share people's content and information. This includes maintaining a published privacy policy and complying with applicable laws.

If you need to access, correct, modify, or delete any inaccurate information that WorkWise processes on behalf of its users, please contact the relevant User who is in control of that data. If you decide to use Third Party Materials provided by Marketplace Sellers, these Sellers will also collect, store, and handle your Personal information during transactions. This may include your name, email address, and postal address. Users are only allowed to use the personal data of other users as necessary for completing a transaction, communicating about a specific transaction, using our Marketplace, or responding to messages. Adding users to mailing lists, using their data for marketing purposes, or keeping payment details is prohibited. Any additional use of another user's personal data requires their consent. If you have any suggestions, comments, or feedback regarding the Website, Products, or Services, you are welcome to share them with WorkWise.

WorkWise does not require you to provide any feedback, and if you do provide feedback, it will not create any confidentiality obligation for WorkWise unless there is a separate agreement. WorkWise is free to use and disclose the feedback in any way it chooses, without any obligation to you. However, this does not affect the obligations of both parties regarding protected information under the Privacy Policy.

The Terms of Service will be governed by and interpreted according to the laws of the Commonwealth of Virginia, without considering its conflict of laws rules. You agree that any claim or legal action related to these Terms of Service, the Website, the Services, or the Products will be exclusively handled by the courts of the Commonwealth of Virginia.

Both parties agree to waive any right to bring claims against the other party as a representative or member of a class or representative action, unless such waiver is prohibited by law or goes against public policy.

In the event of any legal disputes, jurisdiction and venue will be limited to the courts of the Commonwealth of Virginia, except as otherwise stated in the arbitration provisions. WorkWise will only seek attorney's fees and costs if the court deems your claims to be frivolous. You will be responsible for your own attorneys' fees, unless otherwise specified by the arbitration rules or applicable law.

30. Unforeseen Circumstances.
Both parties will be exempt from fulfilling their obligations under these Terms of Service to the extent that such failure is caused by an unforeseen event beyond their reasonable control. This includes acts of war, civil unrest, strikes, natural disasters, government restrictions, or any other event that is not within the party's control. The party affected by the unforeseen event will notify the other party within a reasonable time and make reasonable efforts to minimize the impact of the event. If possible, any outstanding obligations due to the unforeseen event will be fulfilled as soon as reasonably practicable after the event concludes.

31. Contact Details.

Contracting Party.
31.1. WorkWise has the authority to send you important notifications (such as those related to these Terms of Service) through: (1) a noticeable message within the Service; (2) via email to the Administrator's email address. It is the responsibility of the Administrator to ensure that their email is correct and current. WorkWise cannot be held responsible if the Administrator is unable to receive important notifications due to the deletion of their email from the Administrator User Account.
31.2. The WorkWise entity that is entering into this Agreement will comply with English Law and ADGM Regulations.

      </p>
    </div>


        </Modal>
    )
}