import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
import { responseCode as responseCodeEnum } from "../../../../services/enums/responseCode";

export const getAllTeamsService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Employee/GetAllTeamByUserIdPaging`, payload);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllRewardService = async (id) => {
  console.log(id, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Reward/GetRewardUserById?userId=${id}`);
    console.log(responseCode, "response code");

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllCoursesService = async (id) => {
  console.log(id, "REQUEST");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(
      `api/ELearning/GetELearningCourseCurriculumTopicAttemptByMe?userId=${id}`
    );
    console.log(responseCode, "response code");
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllAppraisalService = async (id) => {
  console.log(id, "Appraisal");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Appraisal/GetAppraisalByMe?userId=${id}`);
    console.log(responseCode, "response code");
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllLoanService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Loan/GetLoanUserById?userId=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllComplainService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Complain/GetComplainUserById?userId=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllWarningService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Warning/GetWarningUserById?userId=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllLeaveService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/UserLeave/GetAllUserLeave?userId=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getAllCheckInService = async (id) => {
  try {
    console.log(id, "REQUEST Leave RESPONSE");
    const {
      data: { responseCode, data, message },
    } = await Config.get(
      `api/Attendance/GetAttendanceCheckInUserById?userId=${id}`
    );
    console.log(data, "REQUEST check in RESPONSE");

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getDeviceInfoService = async (payload) => {
  try {
    console.log(payload, "REQUEST Leave RESPONSE");
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Device/GetAllDevice`, payload);
    console.log(data, "REQUESTcheck");

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllActivityLogService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/Device/GetAllActivityLog?userId=${id}`);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const addTeamMemberService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.put(
      `api/Employee/updateManager?userId=${payload.userId}&managerId=${payload.managerId}`
    );
    console.log(data, "AddUserEmergencyContact service");
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const getAllTeamRewardByuserIdService = (id) => {
  return Config.get(`api/Team/Rewards?userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};


export const getAllTeamWarningByuserIdService = (id) => {
  return Config.get(`api/Team/Warnings?userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllTeamComplainsByuserIdService = (id) => {
  return Config.get(`api/Team/Complains?userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllTeamLeavesByuserIdService = (id) => {
  return Config.get(`api/Team/Leaves?userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllTeamLoanByuserIdService = (id) => {
  return Config.get(`api/Team/Loans?userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllTeamAppraisalsByuserIdService = (id) => {
  return Config.get(`api/Team/Appraisals?userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const getAllDeviceService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Device/GetAllDevice`, payload);

    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllDevicePagingService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Device/GetAllDevicePaging`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllActivityLogPagingService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/Device/GetAllActivityLogPaging`, payload);
    if (responseCode === responseCodeEnum.Success)
      return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
