import React, { useContext, useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Space, message } from "antd";
import TextInput from "../../../../sharedComponents/Input/TextInput";
import PasswordInput from "../../../../sharedComponents/Input/PasswordInput";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { getFirebaseToken } from "../../../../../firebase/initFirebase";
import { AuthAction, loginUser } from "../../../auth/store/actions";
import { responseCode } from "../../../../../services/enums/responseCode";
function PayBillAuthentication({ setIsAuthenticated, onClose, setAuthToken }) {
  let formData = {};
  const [localState, setlocalState] = useState({
    disableFormSubmit: false,
    dialogMessage: "",
    openView: false,
    path: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels } = dictionaryList[userLanguage];

  const [reset, setReset] = useState(false);

  const onSubmit = async (payload) => {
    const { email, password } = payload;

    if (!email && !password) {
      message.error(sharedLabels.LoginMessage);
      return;
    }

    if (!email) {
      message.error("Email Required!");
      return;
    }

    if (!password) {
      message.error("Password Required!");
      return;
    }

    dispatch(AuthAction(payload)).then((res) => {
      if (res?.payload?.responseCode === responseCode.Success) {
        onClose();
        setAuthToken(res?.payload?.data);
        setIsAuthenticated(true);
      }
    });
  };

  return (
    <React.Fragment>
      <Form onFinish={onSubmit} className="lg-form">
        <div className="note note-heading">
          Enter your login credentials to continue.
        </div>
        <div className="">
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <Form.Item name="email">
              <TextInput
                type="email"
                placeholder="Email"
                prefix={UserOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>
            <Form.Item name="password">
              <PasswordInput
                placeholder="Password"
                prefix={LockOutlined}
                size="large"
                reset={reset}
              />
            </Form.Item>
          </Space>
        </div>
        <div className="btn">
          <button className="button">Sign In</button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default PayBillAuthentication;
