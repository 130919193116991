export const ProjectFeatureEnums = {
  Feed: 1,
  Schedule: 2,
  WorkBoard: 3,
  Document: 4,
  Task: 5,
  Expense: 6,
  Travel: 7,
  Quotation: 8,
  Budget: 9,
  Setting: 10,
  DiscussionBoard: 11,
  Pages: 12,
  Issues: 13,
  Contract: 14
};

export const projectFeatureEnums = [
  {
    label: "Feed",
    value: ProjectFeatureEnums.Feed,
    description: "A board to have open conversations",
  },
  {
    label: "Task",
    value: ProjectFeatureEnums.Task,
    description: "Key tasks and milestones",
  },
  {
    label: "Work Board",
    value: ProjectFeatureEnums.WorkBoard,
    description: "A Kanban methodology board to manage tasks",
  },
  {
    label: "Expense",
    value: ProjectFeatureEnums.Expense,
    description: "Expense management",
  },
  {
    label: "Schedule",
    value: ProjectFeatureEnums.Schedule,
    description: "Schedule to manage timelines",
  },
  {
    label: "Travel",
    value: ProjectFeatureEnums.Travel,
    description: "ManagementTravel requirements",
  },
  {
    label: "Documents",
    value: ProjectFeatureEnums.Document,
    description: "documents",
  },

  {
    label: "Quotation",
    value: ProjectFeatureEnums.Quotation,
    description: "Quotation",
  },
  {
    label: "Discussion Board",
    value: ProjectFeatureEnums.DiscussionBoard,
    description: "Discussion Board",
  },
  {
    label: "Pages",
    value: ProjectFeatureEnums.Pages,
    description: "Paging",
  },
  {
    label: "Issues",
    value: ProjectFeatureEnums.Issues,
    description: "Issues",
  },
  {
    label: "Contracts",
    value: ProjectFeatureEnums.Contract,
    description: "Contracts",
  },
];

export const ProjectFeaturePermissionEnum = {
  ViewFeed: 1,
  PostFeed: 2,
  ViewSchedule: 3,
  CreateSchedule: 4,
  InviteExternals: 5,
  ViewWorkBoard: 6,
  CreateWorkBoard: 7,
  ViewDocument: 8,
  CreateDocument: 9,
  ViewTask: 10,
  CreateTask: 11,
  ViewExpense: 12,
  CreateExpense: 13,
  ViewTravel: 14,
  CreateTravel: 15,
  ViewQuotation: 16,
  CreateQuotation: 17,
  ViewBudget: 18,
  CreateBudget: 19,
  ViewMember: 20,
  CreateMember: 21,
  ExternalMember: 22,
  ViewFeature: 23,
  CreateFeature: 24,
  ViewAccessRole: 25,
  CreateAccessRole: 26,
  ViewDiscussionBoard: 27,
  CreateDiscussionBoard: 28,
  ViewPages: 29,
  CreatePages: 30,
  ViewContract: 33,
  CreateContract: 34
};

export const ProjectFeaturePermissionEnumList = [
  {
    id: ProjectFeaturePermissionEnum.ViewFeed,
    name: "View Feed",
    featureId: ProjectFeatureEnums.Feed,
  },
  {
    id: ProjectFeaturePermissionEnum.PostFeed,
    name: "Post Feed",
    featureId: ProjectFeatureEnums.Feed,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewSchedule,
    name: "View Schedule",
    featureId: ProjectFeatureEnums.Schedule,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateSchedule,
    name: "Create Schedule",
    featureId: ProjectFeatureEnums.Schedule,
  },
  {
    id: ProjectFeaturePermissionEnum.InviteExternals,
    featureId: ProjectFeatureEnums.Schedule,
    name: "Invite Externals",
  },
  {
    id: ProjectFeaturePermissionEnum.ViewWorkBoard,
    featureId: ProjectFeatureEnums.WorkBoard,
    name: "View Work Board",
  },
  {
    id: ProjectFeaturePermissionEnum.CreateWorkBoard,
    featureId: ProjectFeatureEnums.WorkBoard,
    name: "Create Work Board",
  },
  {
    id: ProjectFeaturePermissionEnum.ViewExpense,
    name: "View Expense",
    featureId: ProjectFeatureEnums.Expense,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateExpense,
    name: "Create Expense",
    featureId: ProjectFeatureEnums.Expense,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewTask,
    name: "View Task",
    featureId: ProjectFeatureEnums.Task,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateTask,
    name: "Create Task",
    featureId: ProjectFeatureEnums.Task,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewDocument,
    name: "View Document",
    featureId: ProjectFeatureEnums.Document,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateDocument,
    name: "Create Document",
    featureId: ProjectFeatureEnums.Document,
  },
  {
    id: ProjectFeaturePermissionEnum.InviteExternals,
    name: "Invite External",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewMember,
    name: "View Member",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateMember,
    name: "Create Member",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewSummary,
    name: "View Summary",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateSummary,
    name: "Create Summary",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewFeature,
    name: "View Feature",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateFeature,
    name: "Create Feature",
    featureId: ProjectFeatureEnums.Setting,
  },
  {
    id: ProjectFeaturePermissionEnum.CreateDiscussionBoard,
    name: "Create DiscussionBoard",
    featureId: ProjectFeatureEnums.DiscussionBoard,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewDiscussionBoard,
    name: "View DiscussionBoard",
    featureId: ProjectFeatureEnums.DiscussionBoard,
  },
  {
    id: ProjectFeaturePermissionEnum.ViewTravel,
    featureId: ProjectFeatureEnums.Travel,
    name: "View Travel",
  },
  {
    id: ProjectFeaturePermissionEnum.CreateTravel,
    featureId: ProjectFeatureEnums.Travel,
    name: "Create Travel",
  },
  {
    id: ProjectFeaturePermissionEnum.ViewQuotation,
    featureId: ProjectFeatureEnums.Quotation,
    name: "View Quotation",
  },
  {
    id: ProjectFeaturePermissionEnum.CreateQuotation,
    featureId: ProjectFeatureEnums.Quotation,
    name: "Create Quotation",
  },
  {
    id: ProjectFeaturePermissionEnum.ViewPages,
    featureId: ProjectFeatureEnums.Pages,
    name: "View Pages",
  },
  {
    id: ProjectFeaturePermissionEnum.CreatePages,
    featureId: ProjectFeatureEnums.Pages,
    name: "Create Pages",
  },
  {
    id: ProjectFeaturePermissionEnum.CreateContract,
    featureId: ProjectFeatureEnums.Contract,
    name: "Create Contract",
  },
  {
    id: ProjectFeaturePermissionEnum.ViewContract,
    featureId: ProjectFeatureEnums.Contract,
    name: "View Contract",
  },
];

export const ProjectFeatureTree = [
  {
    featureId: ProjectFeatureEnums.Feed,
    name: "Feed",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewFeed,
        featureId: ProjectFeatureEnums.Feed,
        name: "View Feed",
      },
      {
        id: ProjectFeaturePermissionEnum.PostFeed,
        featureId: ProjectFeatureEnums.Feed,
        name: "Post Feed",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Schedule,
    name: "Schedule",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewSchedule,
        featureId: ProjectFeatureEnums.Schedule,
        name: "View Schedule",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateSchedule,
        featureId: ProjectFeatureEnums.Schedule,
        name: "Create Schedule",
      },
      {
        id: ProjectFeaturePermissionEnum.InviteExternals,
        featureId: ProjectFeatureEnums.Schedule,
        name: "Invite Externals",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.WorkBoard,
    name: "WorkBoard",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewWorkBoard,
        featureId: ProjectFeatureEnums.WorkBoard,
        name: "View Work Board",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateWorkBoard,
        featureId: ProjectFeatureEnums.WorkBoard,
        name: "Create Work Board",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Document,
    name: "Document",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewDocument,
        featureId: ProjectFeatureEnums.Document,
        name: "View Document",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateDocument,
        featureId: ProjectFeatureEnums.Document,
        name: "Create Document",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Task,
    name: "Task",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewTask,
        featureId: ProjectFeatureEnums.Task,
        name: "View Task",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateTask,
        featureId: ProjectFeatureEnums.Task,
        name: "Create Task",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Expense,
    name: "Expense",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewExpense,
        featureId: ProjectFeatureEnums.Expense,
        name: "View Expense",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateExpense,
        featureId: ProjectFeatureEnums.Expense,
        name: "Create Expense",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Travel,
    name: "Travel",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewTravel,
        featureId: ProjectFeatureEnums.Travel,
        name: "View Travel",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateTravel,
        featureId: ProjectFeatureEnums.Travel,
        name: "Create Travel",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Quotation,
    name: "Quotation",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewQuotation,
        featureId: ProjectFeatureEnums.Quotation,
        name: "View Quotation",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateQuotation,
        featureId: ProjectFeatureEnums.Quotation,
        name: "Create Quotation",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Budget,
    name: "Budget",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewBudget,
        featureId: ProjectFeatureEnums.Budget,
        name: "View Budget",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateBudget,
        featureId: ProjectFeatureEnums.Budget,
        name: "Create Budget",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.DiscussionBoard,
    name: "DiscussionBoard",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewDiscussionBoard,
        featureId: ProjectFeatureEnums.DiscussionBoard,
        name: "View DiscussionBoard",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateDiscussionBoard,
        featureId: ProjectFeatureEnums.DiscussionBoard,
        name: "Create DiscussionBoard",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Pages,
    name: "Pages",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewPages,
        featureId: ProjectFeatureEnums.Pages,
        name: "View Pages",
      },
      {
        id: ProjectFeaturePermissionEnum.CreatePages,
        featureId: ProjectFeatureEnums.Pages,
        name: "Create Pages",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Setting,
    name: "Setting",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewMember,
        featureId: ProjectFeatureEnums.Setting,
        name: "View Member",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateMember,
        featureId: ProjectFeatureEnums.Setting,
        name: "Create Member ",
      },
      {
        id: ProjectFeaturePermissionEnum.ExternalMember,
        featureId: ProjectFeatureEnums.Setting,
        name: "External Member",
      },
      {
        id: ProjectFeaturePermissionEnum.ViewFeature,
        featureId: ProjectFeatureEnums.Setting,
        name: "View Feature",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateFeature,
        featureId: ProjectFeatureEnums.Setting,
        name: "Create Feature",
      },
      {
        id: ProjectFeaturePermissionEnum.ViewAccessRole,
        featureId: ProjectFeatureEnums.Setting,
        name: "View AccessRole",
      },
      {
        id: ProjectFeaturePermissionEnum.CreateAccessRole,
        featureId: ProjectFeatureEnums.Setting,
        name: "Create AccessRole",
      },
    ],
  },
  {
    featureId: ProjectFeatureEnums.Contract,
    name: "Contract",
    permissions: [
      {
        id: ProjectFeaturePermissionEnum.ViewContract,
        featureId: ProjectFeatureEnums.Contract,
        name: "View Contract",
      },
      {
        id: ProjectFeaturePermissionEnum.ViewContract,
        featureId: ProjectFeatureEnums.Contract,
        name: "Create Contract",
      },
    ],
  },
];

export const defaultMemberPermissions = [
  ProjectFeaturePermissionEnum.ViewAccessRole,
  ProjectFeaturePermissionEnum.ViewBudget,
  ProjectFeaturePermissionEnum.ViewDiscussionBoard,
  ProjectFeaturePermissionEnum.ViewDocument,
  ProjectFeaturePermissionEnum.ViewExpense,
  ProjectFeaturePermissionEnum.ViewFeature,
  ProjectFeaturePermissionEnum.ViewFeed,
  ProjectFeaturePermissionEnum.ViewMember,
  ProjectFeaturePermissionEnum.ViewPages,
  ProjectFeaturePermissionEnum.ViewQuotation,
  ProjectFeaturePermissionEnum.ViewSummary,
  ProjectFeaturePermissionEnum.ViewSchedule,
  ProjectFeaturePermissionEnum.ViewTravel,
  ProjectFeaturePermissionEnum.ViewTask,
  ProjectFeaturePermissionEnum.ViewWorkBoard,
  ProjectFeaturePermissionEnum.ViewContract,
  ProjectFeaturePermissionEnum.CreateContract,
];
// export const ProjectFeatureEnums = {
//     Feed:1,
//     Schedule:2,
//     WorkBoard:3,
//     Documents:4,
//     Task:5,
//     Expense:6,
//     Travel:7,
//     BudgetApproval:8,
//     Setting:9,
// }

// export const ProjectFeaturePermissionEnum = {
//     ViewFeed:1,
//     PostFeed:2,
//     ViewSchedule:3,
//     CreateSchedule:4,
//     InviteExternals:5,
//     ViewWorkBoard:6,
//     CreateWorkBoard:7,
//     ViewDocument:8,
//     CreateDocument:9,
//     ViewTask:10,
//     CreateTask:11,
//     ViewExpense:12,
//     CreateExpense:13,
//     ViewTravel:14,
//     CreateTravel:15,
//     ViewBudgetApproval:16,
//     CreateBudgetApproval:17,
//     ViewMember:18,
//     CreateMember:19,
//     ExternalMember:20,
//     ViewFeature:21,
//     CreateFeature:22,
//     ViewSummary:23,
//     CreateSummary:24,
//     ViewAccessRole:25,
//     CreateAccessRole:26,
//     ViewProjectStickyNote:27,
//     CreateProjectStickyNote:28
// }

// export const ProjectFeaturePermissionEnumList = [
//   {
//     id: ProjectFeaturePermissionEnum.ViewFeed,
//     name: "View Feed",
//     featureId: ProjectFeatureEnums.Feed,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.PostFeed,
//     name: "Post Feed",
//     featureId: ProjectFeatureEnums.Feed,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewSchedule,
//     name: "View Schedule",
//     featureId: ProjectFeatureEnums.Schedule,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateFeature,
//     name: "Create Schedule",
//     featureId: ProjectFeatureEnums.Schedule,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewExpense,
//     name: "View Expense",
//     featureId: ProjectFeatureEnums.Expense,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateExpense,
//     name: "Create Expense",
//     featureId: ProjectFeatureEnums.Expense,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewTask,
//     name: "View Task",
//     featureId: ProjectFeatureEnums.Task,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateTask,
//     name: "Create Task",
//     featureId: ProjectFeatureEnums.Task,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewDocument,
//     name: "View Document",
//     featureId: ProjectFeatureEnums.Document,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateDocument,
//     name: "Create Document",
//     featureId: ProjectFeatureEnums.Document,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.InviteExternals,
//     name: "Invite External",
//     featureId: ProjectFeatureEnums.Setting,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewMember,
//     name: "View Member",
//     featureId: ProjectFeatureEnums.Setting,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateMember,
//     name: "Create Member",
//     featureId: ProjectFeatureEnums.Setting,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewSummary,
//     name: "View Summary",
//     featureId: ProjectFeatureEnums.Setting,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateSummary,
//     name: "Create Summary",
//     featureId: ProjectFeatureEnums.Setting,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.ViewFeature,
//     name: "View Feature",
//     featureId: ProjectFeatureEnums.Setting,
//   },
//   {
//     id: ProjectFeaturePermissionEnum.CreateFeature,
//     name: "Create Feature",
//     featureId: ProjectFeatureEnums.Setting,
//   },
// ]
