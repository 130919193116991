import { useParams } from "react-router";
import AuctionDetailExteral from "./auctionDetailExternal";



export default function AuctionDetailExternalWrapper(){

    const { id } = useParams();

    return(
        <>
            <div style={{width:"90%"}}>
                <AuctionDetailExteral id={id} isExternal={true}/>
            </div>
            
        </>
    )
}