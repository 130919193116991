import React, { useContext } from "react";
import { Tag } from "antd";
import { TaskProgressStatusColor } from "../../../utils/TaskProgressStatus";

const TaskProgressTag = ({ taskProgressStatus , className = ""}) => {
  return (
    <>
      {taskProgressStatus &&<Tag
        className={`!font-bold ${className}`}
        color={TaskProgressStatusColor[taskProgressStatus]}
      >
        {taskProgressStatus}
      </Tag>}
    </>
  );
};

export default TaskProgressTag;
