import { ResponseResultError, ResponseResultSuccess } from "../../../../utils/api/ResponseResult";
import { jsonToFormData } from "../../../../utils/base";
import Config from "../../../../utils/services/MasterConfig";
export const saveContractService = async (payload) => {
  const formData = jsonToFormData(payload);
  return Config.post(`api/ContractPurpose/AddContract`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllContractService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/ContractPurpose/GetAllContract`, payload);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllContractPagingService = async (payload) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/ContractPurpose/GetAllContractPaging`, payload)
    if (responseCode === 1001) return ResponseResultSuccess(data);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const getContractByIdService = async ({id}) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/ContractPurpose/GetContractById?id=${id}`);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

