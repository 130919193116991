export const PayrollStatusEnum = {
    InProcess: 1,
    Approved: 2,
    Declined: 3,
    Resend: 4,
    Inactive: 5,
    NotRequired: 6,
    Cancelled: 7,
    Hold: 8,
    Disbursed: 9,
    Completed: 10
}