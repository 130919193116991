import { jsonToFormData } from "../../../../utils/base";
import MasterConfig from "../../../../utils/services/MasterConfig";

export const addHolidayService = (args) => {
    const formData = jsonToFormData(args);

    return MasterConfig.post(`api/Holiday/AddHoliday`, formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  

  export const getAllHolidayService = () => {
    return MasterConfig.get(`api/Holiday/GetAllHoliday`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
  