import React, { useContext } from "react";
import { Tag } from "antd";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ComplainApprovalStatusEnum } from "../../utils/complainStatusEnum";

const StatusTag = ({status = ComplainApprovalStatusEnum.InProcess}) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { complains } = dictionaryList[userLanguage];
    const { statusEnum } = complains;

    let color = ColorsEnum.Primary;

    switch(status){
        case ComplainApprovalStatusEnum.Approved: 
            color = ColorsEnum.Success;
            break;
            case ComplainApprovalStatusEnum.Declined: 
            color = ColorsEnum.Danger;
            break;
            case ComplainApprovalStatusEnum.Cancelled: 
            color = ColorsEnum.Cancelled;
            break;
            case ComplainApprovalStatusEnum.Inactive: 
            color = ColorsEnum.InActive;
            break;
            case ComplainApprovalStatusEnum.NotRequired: 
            color = ColorsEnum.InActive;
            break;
            case ComplainApprovalStatusEnum.Hold: 
            color = ColorsEnum.Hold;
            break;
            
        default : 
            color = ColorsEnum.Primary;
    }
    return (
        <>
        <Tag className="statusTag" style={{ backgroundColor: color }}>
            {statusEnum[status]}
        </Tag>
        </>
    );
};
      

export default StatusTag;