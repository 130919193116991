import { createSlice, current, isPending, isRejected } from "@reduxjs/toolkit";
import { getAllCareerAction } from "../../careers/store/action";
import { getAllRequisition } from "../../requisition/store/actions";
import { getAllAutionMarketplaceAction, getAllJobBoardAction, getAuctionDetailForMarketplaceAction } from "./action";
import { AuctionOfferStatusEnum } from "../../auction/utils/enums";
const initialState = {
  loader: false,
  marketPlaceData: [],
  requisitionData: [],
  marketPlaceAuctionDetail : null,
  auctionData : []
};

const marketPlaceSlice = createSlice({
  name: "marketPlaceSlice",
  initialState,
  reducers: {
    handleNewAuctionBid: (state, { payload }) => { 
      let newAuctionBid = {...payload};
      if(payload.member === null){
        newAuctionBid.member = {
          name : payload.name,
          image: ""
        }
      }
      try{
        const currentState = current(state);
        if(currentState.marketPlaceAuctionDetail!==null)
        { 
          const keys = Object.keys(currentState.marketPlaceAuctionDetail);
          let updatedState = { ...currentState.marketPlaceAuctionDetail};
          keys?.forEach((identifier)=>{
            const current = currentState.marketPlaceAuctionDetail[identifier]
            let updatedAuctionOffers = [...current.auctionOffers];
              // Check if there's at least one bid already and update its status
            if (updatedAuctionOffers.length > 0) {
              updatedAuctionOffers[0] = {
                ...updatedAuctionOffers[0],
                status: AuctionOfferStatusEnum.OutBid,
              };
            }
              const updatedCurrent = { ...current, currentBid: newAuctionBid.offer, auctionOffers: [newAuctionBid, ...updatedAuctionOffers, ]
              }; // Create a deep copy of the current array
              updatedState = {
                ...updatedState,
                [identifier]: updatedCurrent,
              };       
          });
          state.marketPlaceAuctionDetail = updatedState;
        }
      }
      catch(e)
      {
        console.log("===error===marketPlaceAuction",e)
      }    
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAutionMarketplaceAction.fulfilled,(state,action) =>{
      const { identifier } = action?.meta?.arg;
      const data = action.payload ?? []
          state.auctionData = {
            ...state.auctionData,
            [identifier]: [...(state.auctionData?.[identifier] || []), ...data],
          };
        
  });
    builder.addCase(getAuctionDetailForMarketplaceAction.fulfilled, (state, action) => {
        const { identifier } = action?.meta?.arg;
        const data = action.payload.data ?? [];

        state.marketPlaceAuctionDetail = {
          ...state.marketPlaceAuctionDetail,
          [identifier]: data,
        };
    });
    builder.addCase(getAllJobBoardAction.pending, (state, { payload }) => {
      state.loader = true;
    });
    builder.addCase(getAllJobBoardAction.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.marketPlaceData = payload;
    });
    builder.addCase(getAllRequisition.pending, (state, { payload }) => {
      state.loader = true;
    });
    builder.addCase(getAllRequisition.fulfilled, (state, { payload }) => {
      state.loader = false;
      state.requisitionData = payload;
    });
  },
});
export const { handleNewAuctionBid} = marketPlaceSlice.actions
export default marketPlaceSlice.reducer;
