import React, { useState, useEffect } from "react";
import bell from "../../../../content/svg/topMenu/mobileNotificationIcon.svg";
import SharedButton from "../../button";
import ApprovalIcon from "../../../../content/svg/topMenu/mobileapprovalIcon.svg";
import mobileStickyNote from "./assests/mobileStickyNote.svg";
import Messenger from "../../../../content/svg/topMenu/mobileMsgIcon.svg";
import {
  disable as disableDarkMode,
  enable as enableDarkMode,
} from "darkreader";
import sunIcon from "../../../../content/svg/topMenu/mobileSunLightIcon.svg";
import moonIcon from "../../../../content/svg/topMenu/mobileMoonIcon.svg";

import mobileAddExIcon from "./assests/mobileAddExIcon.svg";
import "./style.css";
import { sideBarOpen } from "../../SideChatbar/store/sideBarChatSlice";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import Notification from "./notification/";
import Approvals from "./approvals/";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import WorkwiseTab from "../../../../content/WorkwiseTab.svg"

const Index = () => {
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem("darkMode") === "1"
  );
  const modeHandler = (status) => {
    if (status) {
      enableDarkMode({
        brightness: 100,
        contrast: 90,
        sepia: 10,
      });
    } else disableDarkMode();

    setDarkMode(status);
    window.localStorage.setItem("darkMode", status ? "1" : "0");
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const Navigate = useNavigate()
  const [isHide, setIsHide] = useState(false);
  const { sideBarChatStatus } = useSelector((state) => state.sideBarChatSlice);
  const [showNotification, setShowNotification] = useState(false);
  const [showApprovals, setShowApprovals] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/messenger")) {
      setIsHide(true);
    } else setIsHide(false);
  }, [location]);

  return (
    <div
      className="bottomNavigationTab"
      style={{ display: isHide ? "none" : "" }}
    >
      <div
        className="bottom-tab-Item"
        onClick={() => 
          Navigate('/')
        }
      >
        
          <SharedButton
          type="default"
          shape="circle"
          size="small"
          onClick={() => {}}
          counter={10}
          icon={WorkwiseTab}
          IconSize={22}
          badge={true}
        ></SharedButton>
          {/* <div>More</div> */}
      </div>

      <div className="bottom-tab-Item" onClick={() => setShowApprovals(true)}>
        <SharedButton
          type="default"
          shape="circle"
          size="small"
          onClick={() => {}}
          counter={10}
          icon={ApprovalIcon}
          IconSize={18}
          badge={true}
        ></SharedButton>
        {/* <div>Approvals</div> */}
      </div>

      <div
        className="bottom-tab-Item"
        onClick={() => 
          Navigate('/messenger')
          // dispatch(sideBarOpen(!sideBarChatStatus))
        
        }
      >
        <SharedButton
          type="default"
          shape="circle"
          size="small"
          onClick={() => {}}
          counter={10}
          icon={Messenger}
          IconSize={18}
          badge={true}
        ></SharedButton>
        {/* <div>Messenger</div> */}
      </div>

      <div
        className="bottom-tab-Item"
        onClick={() => setShowNotification(true)}
      >
        <SharedButton
          type="default"
          shape="circle"
          size="small"
          onClick={() => {}}
          counter={10}
          icon={bell}
          IconSize={18}
          badge={true}
        ></SharedButton>
        {/* <div>Notification</div> */}
      </div>

      <Notification
        drawerStatus={showNotification}
        handleDrawer={setShowNotification}
      />
      <Approvals drawerStatus={showApprovals} handleDrawer={setShowApprovals} />
    </div>
  );
};

export default Index;
