export const BonusStatusEnum = {
  InProcess: 1,
  Approved: 2,
  Declined: 3,
  Cancelled: 4,
  Inactive: 5,
  NotRequired: 6,
  Resend: 7,
  WaitingForOtherApproval: 8,
  Hold: 9,
  Disbursed: 10,
  Completed: 12,
};
