import { useParams } from "react-router-dom";
import Header from "../../../../../layout/header";
import { ContBody, TabbableContainer } from "../../../../../sharedComponents/AppComponents/MainFlexContainer";
import AuctionDetailExteral from "../../ui/auctionDetailExternal";
import styled from "styled-components";


export default function AuctionBiddingDetailPage(){
 
    
    const { id } = useParams();
    console.log(id,"===id===")

    return(
        <>
             <TabbableContainer>
                <Header
                    buttons={[]}
                />
                <ContBody 
                    style={{background:"white"}}
                >
                    <CardWrapper>
                        <AuctionDetailExteral id={id}/>
                    </CardWrapper>
                </ContBody>
            </TabbableContainer>
        </>
    )
}
export const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  margin-top: 5px;
  gap: 0.5rem;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  direction: ltr;
  margin: 0 auto;
  display: block;
  &:hover {
  }
`;
