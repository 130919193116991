export const PromotionFilterSortEnum =
{
    CreateDateDesc: 1,
    CreateDateAsc: 2,
    UpdateDateDesc: 3,
    UpdateDateAsc: 4,
    ReferenceNo: 5,
    ReferenceNoDesc: 6,
}


export const promotionTableSort = {
    referenceNo: { descend: PromotionFilterSortEnum.ReferenceNoDesc, ascend: PromotionFilterSortEnum.ReferenceNo },
    createDate: { descend: PromotionFilterSortEnum.CreateDateDesc, ascend: PromotionFilterSortEnum.CreateDateAsc },
    updateDate: { descend: PromotionFilterSortEnum.UpdateDateDesc, ascend: PromotionFilterSortEnum.UpdateDateAsc },
}