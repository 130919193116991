import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPagesIdAction } from "../../store/action";
import { removeIdentifier } from "../../store/slice";
import PagesFullScreenModal from "./fullScreenModal/pagesFullScreenModal";
import { Spinner } from "../../../../../utils/base";
import { useSelector } from "react-redux";
import ContentWrapper from "../../../fullScreenModal/view/contentWrapper";
import PagesfullScreenItem from "./fullScreenModal/pagesfullScreenItem";

function PagesNotificationView({ id }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    identifier: moment().unix(),
    isLoading: false,
    isOpen: false,
  });
  const { data } = useSelector((state) => state.PagesSlice);

  let pageDetail = data !== null ? data[state.identifier] : [];
  pageDetail = pageDetail && pageDetail?.length > 0 && pageDetail[0];

  useEffect(() => {
    setState({
      ...state,
      isLoading: true,
    });
    id &&
      dispatch(getPagesIdAction({ id: id, identifier: state.identifier })).then(
        (response) => {
          setState({
            ...state,
            isLoading: false,
            isOpen: true,
          });
        }
      );
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  //close Full-Screen
  const onFullScreenClose = () => {
    setState({
      ...state,
      isOpen: false,
    });
  };
  if (state.isLoading) return <Spinner />;

  return (
    <PagesfullScreenItem
      onCancel={onFullScreenClose}
      fullScreenModalOpen={state.isOpen}
      data={pageDetail ? pageDetail : []}
    />
  );
}

export default memo(PagesNotificationView);
