import { jsonToFormData } from "../../../../utils/base";
import MasterConfig from "../../../../utils/services/MasterConfig";
const API_PREFIX = "api/Project/";
// import { jsonToFormData } from "../../../../utils/base";

export const getAllProjectsService = (data) => {
  return MasterConfig.post(`${API_PREFIX}GetAllProject`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getProjectByIdService = (id) => {
  return MasterConfig.get(`${API_PREFIX}GetProjectById?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const updateProjectService = (data) => {
  return MasterConfig.put(`${API_PREFIX}UpdateProject`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addProjectService = (data) => {
  return MasterConfig.post(`${API_PREFIX}AddProject`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeProjectFeatureService = ({ id, featureId }) => {
  return MasterConfig.delete(
    `${API_PREFIX}RemoveProjectFeature?id=${id}&featureId=${featureId}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addProjectFeatureService = (data) => {
  // const id = features.find((feature) => feature.projectId)?.projectId;
  const id = data.id;
  return MasterConfig.post(
    `${API_PREFIX}AddProjectFeature?id=${id}`,
    data.payload
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const saveStickyNoteProject = (data) => {
  return MasterConfig.post(`api/StickyNotes/SaveProjectStickyNotes`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllProjectStickyService = (id) => {
  return MasterConfig.get(`api/StickyNotes/GetProjectStickyNotes?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getAllProjectMemberService = (id) => {
  return MasterConfig.get(`api/Project/GetAllProjectMember?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addProjectMemberService = (data) => {
  console.log(data, "aaadatatatatata");
  let id = data.id;
  let memberId = data.memberId;
  let memberType = data?.memberType || 1;
  let member = [
    {
      memberId: memberId,
      memberType: memberType,
    },
  ];
  return MasterConfig.post(
    `api/Project/AddProjectMember?id=${id}`,
    Array.isArray(data?.memberId) ? data.memberId : member
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteProjectMemberService = (data) => {
  let id = data.id;
  let memberId = data.memberId;
  return MasterConfig.post(`api/Project/RemoveProjectMember?id=${id}`, [
    memberId,
  ])
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addProjectFavoriteService = (payload) => {
  return MasterConfig.get(
    `api/Project/AddProjectFavouriteMark?projectId=${payload.id}&isPinned=${payload.isPinned}`,
    payload.payload
  )
    .then((res) => {
      console.log(res.data, "addProjectFeatureService");
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getProjectFeatureService = (id) => {
  return MasterConfig.get(`api/Project/GetAllProjectFeature?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addProjectTagService = (args) => {
  console.log("projeeect", args);
  const {
    description,
    // members,
    name,
    referenceId,
  } = args;
  let payload = {
    referenceId,
    name,
    description,
    // members,
  };
  console.log("new payload", payload);
  return MasterConfig.post(`api/Project/AddProjectTag`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllProjectTagService = (data) => {
  console.log(data, "getAllCustomTagService");
  return MasterConfig.post(`api/Project/GetAllProjectTag`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const updateProjectTagService = (args) => {
  return MasterConfig.put(`api/Project/UpdateProjectTag`, args)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const removeProjectTagService = (id) => {
  return MasterConfig.delete(`api/Project/RemoveProjectTag?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const removeProjectTagMemberService = (data) => {
  let id = data.id;
  let memberId = data.memberId;
  return MasterConfig.post(`api/Project/RemoveProjectTagMember?id=${id}`, [
    memberId,
  ])
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addProjectTagMemberService = (data) => {
  let id = data.id;
  let memberId = data.memberId;
  let referenceId = data.referenceId;

  let member = [
    {
      referenceId: referenceId,
      memberId: memberId,
    },
  ];
  return MasterConfig.post(`api/Project/AddProjectTagMember?id=${id}`, member)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

/* ACCESS ROLE */

export const addProjectAccessRoleService = (data) => {
  return MasterConfig.post(`/api/ProjectAccessRole/AddProjectAccessRole`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllProjectAccessRolesService = (data) => {
  return MasterConfig.get(
    `/api/ProjectAccessRole/GetAllProjectAccessRole?referenceId=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateProjectAccessRoleByIdService = (data) => {
  return MasterConfig.put(
    `/api/ProjectAccessRole/UpdateProjectAccessRole`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectAccessRoleByIdService = (data) => {
  return MasterConfig.get(
    `/api/ProjectAccessRole/GetProjectAccessRoleById?id=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const AssignProjectTagToMemberService = (data) => {
  return MasterConfig.post(
    `${API_PREFIX}AssignProjectTagToMember?projectId=${data.projectId}&memberId=${data.memberId}`,
    data.tags
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const AddProjectExternalService = (data) => {
  const { projectId, emails } = data;
  return MasterConfig.post(
    `api/Project/AddProjectExternal?projectId=${projectId}`,
    emails
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const AssignProjectAccessRoleToMemberService = (data) => {
  return MasterConfig.post(
    `/api/ProjectAccessRole/AssignProjectAccessRoleToUser?projectId=${data.projectId}&userId=${data.memberId}`,
    data.Roles
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const AssignProjectMemberToAccessRoleService = (data) => {
  return MasterConfig.post(
    `/api/ProjectAccessRole/AssignProjectUserToAccessRole?accessRoleId=${data.id}`,
    data.memberId
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
export const getAllProjectsPaginationService = (data) => {
  return MasterConfig.post(`${API_PREFIX}GetAllProjectPaging`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetAllProjectUserPermissionsService = (data) => {
  return MasterConfig.get(
    `/api/ProjectAccessRole/GetAllProjectUserPermissions?projectId=${data}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
