import { Button, Form, Input, InputNumber } from "antd";
import React, { useEffect, useState, useContext } from "react";
import TextInput from "../../../../sharedComponents/Input/TextInput";
import { useSelector, useDispatch } from "react-redux";
import { addRequisitionOffer } from "../../store/actions";
import SingleUpload from "../../../../sharedComponents/Upload/singleUpload";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { STRINGS } from "../../../../../utils/base";
import { useParams } from "react-router-dom";
import { submitForm } from "../../store/formSlice";
import { dictionaryList } from "../../../../../utils/localization/languages";
const initialState = {
  id: "",
  name: "",
  reason: "",
  description: "",
  categoryId: "",
  imageId: "",
  finalApprovers: [
    {
      approverId: "",
      approverType: 0,
    },
  ],
  approvers: [
    {
      approverId: "",
      approverType: 0,
      isDefault: true,
      status: 1,
      email: "",
    },
  ],
};

const CreateOffer = (props) => {
  const { id } = useParams();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, requisitions, Direction } = dictionaryList[userLanguage];

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState(null);

  const { success, createLoader } = useSelector(
    (state) => state.requisitionSlice
  );
  const { toggleSuccess } = useSelector((state) => state.requisitionSlice);

  const isFormSubmitted = useSelector(
    (state) => state.formSubmittedSlice.isSubmitted
  );

  const handleImageUpload = (data) => {
    setProfileImage(data);
  };

  const onFinish = (values) => {
    let image = {
      id: STRINGS.DEFAULTS.guid,
      file: profileImage && profileImage[0]?.originFileObj,
    };

    if (Object.keys(image).length > 0) {
      let payload = { ...values, image, requisitionId: id };
      dispatch(addRequisitionOffer(payload));
    } else {
      let payload = { ...values, requisitionId: id };
      dispatch(addRequisitionOffer(payload));
    }
  };
  useEffect(() => {
    if (success) {
      form.resetFields();
    }
  }, [success]);

  useEffect(() => {
    dispatch(submitForm(!isFormSubmitted));
  }, [toggleSuccess]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        form={form}
        name="addRequisitionOffer"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={Direction === "rtl" ? "labelRight" : ""}
        style={{ direction: Direction }}
      >
        <Form.Item
          label={sharedLabels.name}
          name="name"
          labelPosition="top"
          rules={[
            {
              required: true,
              message: requisitions.PleaseEnterRequisitionName,
            },
          ]}
        >
          <TextInput
            placeholder={requisitions.PleaseEnterRequisitionName}
          />
        </Form.Item>

        <Form.Item
          label={sharedLabels.Email}
          name="email"
          labelPosition="top"
          rules={[
            {
              required: true,
              message: sharedLabels.requiredMessageEmail,
            },
          ]}
        >
          <TextInput placeholder={sharedLabels.requiredMessageEmail} />
        </Form.Item>

        <Form.Item
          label={sharedLabels.Description}
          name="description"
          rules={[
            {
              required: true,
              message: sharedLabels.enterDescription,
            },
          ]}
        >
          <Input.TextArea
            placeholder={sharedLabels.enterDescription}
          />
        </Form.Item>

        <Form.Item
          label={sharedLabels.PhoneNumber}
          name="phoneNumber"
          labelPosition="top"
          rules={[
            {
              required: true,
              message: sharedLabels.PleaseEnterPhoneNumber,
            },
          ]}
        >
          <TextInput
            placeholder={sharedLabels.PleaseEnterPhoneNumber}
          />
        </Form.Item>

        <Form.Item
          label={sharedLabels.BusinessName}
          name="businessName"
          labelPosition="top"
          rules={[
            {
              required: true,
              message: sharedLabels.PleaseEnterBusinessName,
            },
          ]}
        >
          <TextInput
            placeholder={sharedLabels.PleaseEnterBusinessName}
          />
        </Form.Item>

        <Form.Item
          label={sharedLabels.BusinessAddress}
          name="businessAddress"
          rules={[
            {
              required: true,
              message: sharedLabels.PleaseEnterBusinessAddress,
            },
          ]}
        >
          <Input.TextArea
            placeholder={sharedLabels.PleaseEnterBusinessAddress}
          />
        </Form.Item>

        <Form.Item
          label={sharedLabels.Offer}
          name="offer"
          rules={[
            {
              required: true,
              message: sharedLabels.PleaseEnterOffer,
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            size={"large"}
            placeholder={sharedLabels.PleaseEnterOffer}
          />
        </Form.Item>

        <Form.Item area="true">
          <SingleUpload
            handleImageUpload={handleImageUpload}
            img="Add Image"
            position="flex-start"
            uploadText={sharedLabels.upload}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="medium"
            loading={createLoader}
            className="ThemeBtn"
            block
            htmlType="submit"
            title={sharedLabels.CreateOffer}
          >
            {" "}
            {sharedLabels.CreateOffer}{" "}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateOffer;
