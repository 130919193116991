import React, { useContext } from "react";
import { Tag } from "antd";
import {
  TeamOutlined,
  PieChartOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import {
  ExpenseReferenceTypeEnum,
  expenseReferenceTypeHandler,
} from "../../utils/expenseReferenceTypeEnum";
import { ExpenseReferenceTypeColor } from "../../utils/expenseReferenceTypeColor";
import { ReferenceTypeEnum } from "../../../../sharedComponents/reference/utils/referenceTypeEnum";
import { STRINGS, getRouteByType } from "../../../../../utils/base";
import expenseIcon from "../../../../../content/svg/menu/newNavBarIcon/Expenses.svg";
import projectIcon from "../../../../../content/svg/menu/newNavBarIcon/Projects.svg";
import groupIcon from "../../../../../content/svg/menu/newNavBarIcon/Groups.svg";
import leadIcon from "../../../../../content/svg/menu/newNavBarIcon/Lead Manager.svg";
import "../../view/MetricCard/style.css"

const ReferenceTypeTag = ({
  referenceId = STRINGS.DEFAULTS.guid,
  referenceType = ExpenseReferenceTypeEnum.General,
  reference = [],
}) => {
  let color = ExpenseReferenceTypeColor?.[referenceType];
  let value =
    reference && reference !== null && reference?.name
      ? reference?.name
      : expenseReferenceTypeHandler(referenceType);

  let icon = expenseIcon;

  switch (referenceType) {
    case ExpenseReferenceTypeEnum.Project:
      icon =projectIcon;
      break;
    case ExpenseReferenceTypeEnum.Group:
      icon = groupIcon;
      break;
    case ExpenseReferenceTypeEnum.Travel:
      icon =leadIcon;
      break;
    default:
      icon = expenseIcon;
      break;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (referenceId && referenceId !== STRINGS.DEFAULTS.guid) {
      let refType;
      switch (referenceType) {
        case ExpenseReferenceTypeEnum.Project:
          refType = ReferenceTypeEnum.Project;
          break;
        case ExpenseReferenceTypeEnum.Group:
          refType = ReferenceTypeEnum.Group;
          break;
        default:
          break;
      }

      let url = refType && getRouteByType(referenceId, refType);
      if (url) {
        window.location.href = url;
      }
    }
  };

  return (
    <>
      <Tag
        icon={<img src={icon} className="h-[20px] w-[20px] mr-2" alt="icon" />}
        color={color}
        onClick={handleClick}
        className="expenseReferenceTypeTag"
      >
        {value}
      </Tag>
    </>
  );
};

export default ReferenceTypeTag;
