import { Button, Card, Modal, Tag } from "antd";
import React, { useContext, useRef } from "react";
import { Collapse, Divider } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import InvoiceModal from "../PayBill/invoiceModal";
import InvoiceItemList from "../PayBill/invoiceItemList";
import { getMonthName } from "../../../billing/enum";
import convertUTCtoLocal from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";
import InvoiceItemDetail from "../PayBill/invoiceItemDetail";
import { useReactToPrint } from "react-to-print";
import SystemLogo from "../../../../../../src/content/systemLogo.png";

const { Panel } = Collapse;
export default function InvoiceAndPaymentDetailCard({ data = {} }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { administration, sharedLabels } = dictionaryList[userLanguage];
  const componentRef = useRef();

  // ---- STATE----
  const [state, setState] = useState({
    isShowInvoiceDetail: false,
  });

  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <React.Fragment>
      <div className="bill-main-details printable-invoice payment-area">
        <div ref={componentRef} className="handlePrint">
          <div className=" payBillHeader">
            <div className="note note-heading !text-[16px] !m-[0px]">{"Billing Invoice"}</div>
            <div>
              <div className="">
                <img className="h-[50px]" src={SystemLogo} />
              </div>
              <Button
                key="print"
                type="link"
                className="w-fit printBillBtn"
                onClick={() => handlePrint()}
              >
                Print Invoice
              </Button>
            </div>
          </div>
          <Card bordered={false}>
            <InvoiceItemDetail billingData={data} />
            <Divider></Divider>
            <InvoiceItemList billingData={data} />
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
