import React, { useContext } from "react";
import { ExpenseApprovalStatusEnum } from "../../utils/expenseStatusEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";

const StatusTag = ({ status = ExpenseApprovalStatusEnum.InProcess }) => {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { expenses } = dictionaryList[userLanguage];

    let color = ColorsEnum.Primary;

    switch (status) {
        case ExpenseApprovalStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case ExpenseApprovalStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case ExpenseApprovalStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case ExpenseApprovalStatusEnum.Completed:
            color = ColorsEnum.Completed;
            break;
        case ExpenseApprovalStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;
        case ExpenseApprovalStatusEnum.Disbursed:
            color = ColorsEnum.Disbursed;
            break;
        case ExpenseApprovalStatusEnum.WaitingForDisburse:
            color = ColorsEnum.WaitingForDisburse;
            break;
        default:
            color = ColorsEnum.Primary;
    }
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {expenses.statusEnum[status]}
            </Tag>
        </>
    );
};


export default StatusTag;