import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskDetailItem from "../../task/view/Page/Detail/DetailItem";
import { getTaskById } from "../../task/store/actions";
import { changeOnProgress } from "../../task/store/taskSlice";
import Spinner from "../../../sharedComponents/spinner/spinner";
import moment from "moment";

function TaskDetail({ currentClickId }) {
 
  return <TaskDetailItem id={currentClickId} />;
}

export default TaskDetail;
