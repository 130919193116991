import React from "react";
import { Modal } from "antd";
import "../style/style.css";
import ContentWrapper from "./contentWrapper";
function FullScreenModalWrapper({
  data = [],
  openModal = false,
  onCancel = () => {},
}) {
  return (
    <>
      <Modal
        title={null}
        centered
        className="post-model"
        visible={openModal}
        onCancel={onCancel}
        footer={null}
        style={{ backgroundColor: "#181717" }}
        wrapClassName="custom-modal-fullscreen" 
      >
        <ContentWrapper data={data} />
      </Modal>
    </>
  );
}
export default FullScreenModalWrapper;
