import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseType } from '../../../../utils/api/ResponseResult';
import { openNotification } from '../../../../utils/Shared/store/slice';
import {
  getAllNonMandatoryApproval,
  addNonMandatoryApproval,
  isNonMandatoryApprovalService,
  removeNonMandatoryApproverService,
  getAllNonMandatoryApproversService,
} from '../service/service';
import { handlenonMandatoryApprovalDelete } from './slice';
import MasterConfig from '../../../../utils/services/MasterConfig';
import { responseCode } from '../../../../services/enums/responseCode';
import { message } from 'antd';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';

// export const getAllNonMandatoryApprovalAction = createAsyncThunk(
//   'NonMandatoryApproval/getAllNonMandatoryApprovalAction',
//   async (payload, { rejectWithValue }) => {
//     const response = await getAllNonMandatoryApproval(payload);
//     switch (response.type) {
//       case ResponseType.ERROR:
//         return rejectWithValue(response.errorMessage);
//       case ResponseType.SUCCESS:
//         return response.data;
//       default:
//         return;
//     }
//   }
// );


export const getAllNonMandatoryApprovalAction = createAsyncThunk(
  'NonMandatoryApproval/getAllNonMandatoryApprovalAction',
    async (payload, { rejectWithValue }) => {
      console.log(payload ,"==PAYLOAD");
    const response = await getAllNonMandatoryApproversService(payload);
    console.log(response.data, "getAllDefaultApproversAction");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const addNonMandatoryApprovalAction = createAsyncThunk(
  'NonMandatoryApproval/addNonMandatoryApprovalAction',
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await addNonMandatoryApproval(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: 'NonMandatoryApproval Added Successfully!',
            type: 'success',
            duration: 2,
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

// export const deleteNonMandatoryApprovalAction = createAsyncThunk(
//   'NonMandatoryApproval/deleteNonMandatoryApprovalAction',
//   async (args, { dispatch }) => {
//     return await MasterConfig.delete(
//       `/api/NonMandatoryApproval/RemoveNonMandatoryApproval?id=${args}`
//     )
//       .then((res) => {
//         if (res.data.responseCode === responseCode.Success) {
//           message.success('NonMandatory Approval removed successfully!');
//           dispatch(handlenonMandatoryApprovalDelete(args));
//         } else {
//           message.error(res.message);
//         }
//         responseMessage({ dispatch, data: res.data });
//         return res.data;
//       })
//       .catch((err) => {
//         responseMessage({
//           dispatch: dispatch,
//           type: responseMessageType.ApiFailure,
//         });
//         message.error(`Error: ${err.message}`);
//         return err;
//       });
//   }
// );


export const deleteNonMandatoryApproversByIdAction = createAsyncThunk(
  'NonMandatoryApprovers/deleteNonMandatoryApproversByIdAction',
  async (args, { dispatch }) => {
    const res = await removeNonMandatoryApproverService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = ("NonMandatory Approvers removed successfully!");
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);






export const isNonMandatoryApprovalAction = createAsyncThunk(
  'NonMandatoryApproval/isNonMandatoryApprovalAction',
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await isNonMandatoryApprovalService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response;
      default:
        return;
    }
  }
);

