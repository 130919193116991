import { useDispatch } from "react-redux";
import DocumentMemberModal from "../documentMemberWrapper/documentMemberModal";
import { useSelector } from "react-redux";
import moment from "moment";
import { DOCUMENT_ENUM } from "../../utils/DocumentEnum";
import { useContext, useEffect } from "react";
import { CompleteDocumentAction, GetDocumentById } from "../../store/actions";
import { Skeleton, Tag } from "antd";
import {
  ItemHeader,
  SingleItem,
} from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import DriveStatusTag from "./driveStatusTag";
import DriveItem from "./driveitem";
import DocumentFullScreenModal from "../documentFullScreenModal";
import { ActionButton } from "../components/fullCard/cardStyle";
import { DocumentStatusEnum } from "../../utils/DocumentStatusEnum";
import Avatar from "../../../../sharedComponents/Avatar/avatar";
import { PostPrivacyType } from "../../../../../utils/Shared/enums/enums";
import { privacyOption } from "../enum";
import { getDocumentRightLabel } from "../../constant/helpers";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { PlusCircleFilled } from "@ant-design/icons";
import RemarksApproval from "../../../../sharedComponents/AppComponents//Approvals/view";
import Comments from "../../../Comment/comments";
import { CommentModuleEnum } from "../../../Comment/utils/enum/commentModuleEnum";
import { useState } from "react";
import CompleteDocumentWrapper from "./completeDocumentWrapper";
import { STRINGS } from "../../../../../utils/base";
import { DocumentReferenceTypeEnum } from "../../utils/DocumentReferenceTypeEnum";
import CustomAddApproversModal from "../../../../sharedComponents/addApprovalContainer";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { NOTIFICATION_ENUMS } from "../../../notifiation/utils/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { handleAddDocumentApprover, handleDocumentStatusChange, readCountforDocumentList } from "../../store/slice";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
export default function DocumentDetailCard(props) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { document, sharedLabels } = dictionaryList[userLanguage];
  const {
    referenceId = STRINGS.DEFAULTS.guid,
    referenceType = DocumentReferenceTypeEnum.General,
  } = props;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    identifier: moment().unix(),
    documentDetail: {
      name: "",
      referenceNo: "",
      documentType: "",
      creator: "",
      createDate: "",
      description: "",
      id: "",
      path: "",
      members: [],
      approvers: [],
      image: "",
      extensionTypeId: "",
      status: "",
      privacyId: "",
      attachments: "",
      notificationCount: 0,
    },
    isLoading: true,
    isReader: false,
    isColabrator: false,
    reader: [],
    collabrator: [],
    isFullScreenModal: false,
    attachmentData: {},
    memberRightType: DOCUMENT_ENUM.MEMBER_RIGHT_TYPE.COLLABRATOR,
    isOpenCompleteDocument: false,
  });

  const [isOpenCompleteDocument, setisOpenCompleteDocument] = useState(false);

  let { DUCOMENT_TYPE, MEMBER_RIGHT_TYPE } = DOCUMENT_ENUM;
  let { Public, Private } = privacyOption;

  let { documentListData } = useSelector((state) => state.documentSlice);
  documentListData =
    documentListData && documentListData[state.identifier]
      ? documentListData[state.identifier]
      : [];
  documentListData =
    documentListData && documentListData?.length > 0 && documentListData[0];

  console.log(documentListData, "===DocumentDetailList==");

  const { user } = useSelector((state) => state.userSlice);

  useEffect(() => {
    dispatch(GetDocumentById({ identifier: state.identifier, id: props.id }));
  }, [props.id]);

  useEffect(() => {
    console.log(documentListData, "===listData===");
    documentListData &&
      setState({
        ...state,
        documentDetail: {
          ...state.documentDetail,
          ...documentListData,
        },
        isLoading: false,
      });
  }, [documentListData]);

  let {
    name,
    referenceNo,
    documentType,
    creator,
    createDate,
    description,
    id,
    path,
    members,
    approvers,
    image,
    extensionTypeId,
    status,
    privacyId,
    attachments,
    notificationCount,
  } = state.documentDetail;

  let documentFile = attachments[0] ? attachments[0] : {};
  documentFile = {
    ...documentFile,
    documentType,
  };

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NOTIFICATION_ENUMS.FEATURE_TYPE.Document,
          featureId: props?.id,
        },
      };

      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforDocumentList(id));
      });
    }
  }, [notificationCount]);

  useEffect(() => {
    setState({
      ...state,
      collabrator: members?.filter(
        (it) => it.memberRightType === MEMBER_RIGHT_TYPE.COLLABRATOR
      ),
      reader: members?.filter(
        (it) => it.memberRightType === MEMBER_RIGHT_TYPE.READER
      ),
    });
  }, [state.documentDetail]);

  let isUserExistInColab = state?.collabrator?.some(
    (obj) => obj?.memberId === user?.id
  );

  const onClosePopUp = (status) => {
    setState({
      ...state,
      isReader: state.isReader && status,
      isColabrator: state.isColabrator && status,
    });
  };

  const handleModalForCollabrator = () => {
    setState({
      ...state,
      isColabrator: true,
      currentRightType: MEMBER_RIGHT_TYPE.COLLABRATOR,
    });
  };

  const handleModalForReader = () => {
    setState({
      ...state,
      isReader: true,
      currentRightType: MEMBER_RIGHT_TYPE.READER,
    });
  };

  const handlePreview = (item) => {
    setState({
      ...state,
      isFullScreenModal: true,
      attachmentData: item,
    });
  };

  const handleCompleteDocument = () => {
    setisOpenCompleteDocument(true);
  };

  const completeDocumentWithoutApprovers = () =>{

      const payload = {
        approvers: [],
        id: props?.id,
      };

      dispatch(CompleteDocumentAction(payload)).then(({ payload }) => {
        dispatch(
          handleDocumentStatusChange({ referenceId:props?.id })
        );
        });
    
  }

  

  if (state.isLoading) return <Skeleton />;

  return (
    <>
      {
        <CompleteDocumentWrapper
          documentId={props.id}
          referenceType={referenceType}
          // key={props.id}
          referenceId={referenceId}
          isShow={isOpenCompleteDocument}
          onClose={() => {
            setisOpenCompleteDocument(false);
          }}
        />
      }
      {
        <DocumentMemberModal
          documentType={documentType}
          referenceId={props.id}
          visible={state.isReader || state.isColabrator}
          setVisible={(status) => onClosePopUp(status)}
          memberRightType={state.memberRightType}
        />
      }

      <SingleItem>
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator?.designation : ""}
                  time={moment
                    .utc(createDate)
                    .local()
                    .fromNow()}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            {status && <DriveStatusTag status={status}></DriveStatusTag>}
          </div>
        </ItemHeader>
        <div className="doc_detail_media">
          <DriveItem
            handlePreview={handlePreview}
            item={documentFile}
            key={props.id}
            hideControls={true}
          />

          <div className="plusButtonContainer">
            {status === DocumentStatusEnum.Draft && (
              <>

              <div className="plusButton">
                <ActionButton
                  onClick={handleCompleteDocument}
                  BackgroundColor="var(--currentThemeColor) !important"
                >
                  {document.CompleteDocument}
                </ActionButton>
              </div>
              <div className="plusButton">
              <ActionButton
                onClick={completeDocumentWithoutApprovers}
                BackgroundColor="var(--currentThemeColor) !important"
              >
                {document.CompleteDocumentWithoutApprovers}
              </ActionButton>
            </div>
              </>
            )}
          </div>
        </div>

        <div className="cardSections">
          <div className="cardSectionItem">
            <div className="cardSection__title">{sharedLabels.createDate}</div>
            <div className="cardSection__body">
              {moment(createDate).format("Do MMM YY")}
            </div>
          </div>

          <div className="cardSectionItem">
            <div className="cardSection__title">{sharedLabels.readers}</div>
            <div
              className="cardSection__body"
              onClick={(e) => handleModalForReader(e)}
            >
              {privacyId === PostPrivacyType.PUBLIC ? (
                sharedLabels.Public
              ) : state.reader?.length > 0 ? (
                <>
                  <Avatar
                    isAvatarGroup={true}
                    heading={sharedLabels.members}
                    membersData={state.reader ? state.reader : []}
                  />
                  {isUserExistInColab && (
                    <PlusCircleFilled style={{ fontSize: "20px" }} />
                  )}
                </>
              ) : (
                sharedLabels.NotAvailable
              )}
            </div>
          </div>

          <div className="cardSectionItem">
            <div className="cardSection__title">{sharedLabels.privacy}</div>
            <div className="cardSection__body">
              {" "}
              {privacyId === Public
                ? sharedLabels.Public
                : sharedLabels.Private}
            </div>
          </div>

          <div className="cardSectionItem">
            <div className="cardSection__title">
              {getDocumentRightLabel(documentType)}
            </div>
            <div
              className="cardSection__body"
              onClick={(e) =>
                getDocumentRightLabel(documentType) === "Collaborators" &&
                handleModalForCollabrator(e)
              }
            >
              {state.collabrator?.length > 0 ? (
                <>
                  <Avatar
                    isAvatarGroup={true}
                    heading={sharedLabels.members}
                    membersData={state.collabrator ? state.collabrator : []}
                  />
                  {getDocumentRightLabel(documentType) === "Collaborators" &&
                    isUserExistInColab && (
                      <PlusCircleFilled style={{ fontSize: "20px" }} />
                    )}
                </>
              ) : (
                sharedLabels.NotAvailable
              )}
            </div>
          </div>
        </div>
        {approvers?.length > 0 ? (
          <>
            <RemarksApproval
              module={ApprovalsModule.DocumentApproval}
              reference={id}
              status={status}
              onStatusChanged={(statusChanged) => {}}
              data={approvers ? approvers : []}
              title={sharedLabels.Approvers}
              itemCreator={creator}
            />
          </>
        ) : (
          ""
        )}

        <div className="comments mt-[20px]">
          <Comments
            referenceId={props.id}
            module={CommentModuleEnum.Document}
            mentionMemberRefId = {props?.referenceId}
            referenceType={ props?.referenceType}
          />
        </div>
      </SingleItem>

      {state.isFullScreenModal && (
        <DocumentFullScreenModal
          fullScreenModalOpen={state.isFullScreenModal}
          onCancel={() =>
            setState({
              ...state,
              isFullScreenModal: false,
            })
          }
          data={state.attachmentData}
        />
      )}
    </>
  );
}
