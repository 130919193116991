import React, { useEffect, useState } from "react";
import Post from "../posts_list/post";
import { useSelector, useDispatch } from "react-redux";
import "../stylesheet/EventBox.css";
import "../stylesheet/NewsFeed.css";
import { useParams } from "react-router-dom";
import { getFeedById } from "../../store/actions";
import moment from "moment";
import Spinner from "../../../../sharedComponents/spinner/spinner";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";


function PostDetail(props) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [identifier, setIdentifier] = useState(moment().unix())
  const  {feedData } = useSelector(state => state.feedSlice);
  const [isPostLoading , setIsPostLoading] = useState(false)
  let  singlePost = feedData !== null ? feedData[identifier] : [];
  singlePost = singlePost &&  singlePost?.length > 0 && singlePost[0]
  let Direction = "ltr"

  useEffect(() => {
  
  setIsPostLoading(true)
  const postId = props?.id ? props.id : id 
 dispatch(getFeedById({identifier:identifier , id:postId})).then(()=>{
  setIsPostLoading(false)

  const payload = {
    filter : {
      featureType:NotificationFeatureTypeEnum.Feed,
      featureId:postId
    },
  }

  singlePost.notificationcount > 0 && dispatch(readNotificationAction(payload))

})
  }, [props?.id, dispatch]);



  return (
    <>
      <div className="newsFeed tabbable-container" style={{ direction: Direction }}>
        <div className="newsList" style={{width:"100%"}}>
          <div className="postDetails">
            {isPostLoading ? (
                <Spinner />
              ) : (
                <Post post={singlePost} isDetail={true} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PostDetail;
