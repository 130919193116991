import moment from "moment";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { useSelector } from "react-redux";
import { STRINGS } from "../../../../../utils/base";
import { LoanStatusEnum } from "../../utils/LoanStatusEnum";
import { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { GetLoanById } from "../../store/actions";
import { useEffect } from "react";
import { readCountforLoan, removeIdentifier } from "../../store/slice";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import {
  ItemContent,
  ItemHeader,
  SingleItem,
} from "../../../../sharedComponents/Card/CardStyle";
import { Button, Skeleton, Tag } from "antd";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { TransactionReferenceTypeEnum } from "../../../createVoucher/enum/TransactionReferenceTypeEnum";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import ExpandableDescription from "../../../../sharedComponents/expandableDescription/expandableDescription";
import CustomModal from "../../../workboard/Modal/CustomModal";
import VoucherPrint from "../../../createVoucher/view/voucherPrintModal";
import PostVoucher from "../../../createVoucher/view/postVoucher/UI/PostVoucher";
import Disperse from "../../../disperse/view/UI/Disperse";
import { LoanTypeEnum } from "../../utils/LoanTypeEnum";

function LoanDetail({ id, index }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, Direction, loan } = dictionaryList[userLanguage];
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);


  //state
  const [state, setState] = useState({
    identifier: moment().unix(),
    loanDetail: {
      creator: {},
      description: "",
      approvers: [],
      status: LoanStatusEnum.InProcess,
      referenceNo: "",
      createDate: "",
      member: {},
      memberId: STRINGS.DEFAULTS.guid,
      isLoading: true,
      notificationCount: 0,
      purposeId: 0,
      voucherNo: "",
      voucherId: STRINGS.DEFAULTS.guid,
      loanType : LoanTypeEnum.Company,
      interestRate: 0,
    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });
  //useSelector
  const { voucherList } = useSelector(
    (state) => state.voucherSlice
  );
  const { data } = useSelector((state) => state.loanSlice);

  let loanDetail = data !== null ? data[state.identifier] : [];
  loanDetail = loanDetail && loanDetail?.length > 0 && loanDetail[0];

  //call api
  useEffect(() => {
    id && dispatch(GetLoanById({ id: id, identifier: state.identifier }));
  }, []);

  useEffect(() => {
    loanDetail &&
      setState({
        ...state,
        loanDetail: {
          ...state.loanDetail,
          ...loanDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: loanDetail?.approvers,
        },
      });
  }, [loanDetail]);

  //remove identifier
  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  //close Approvers Modal
  const onCloseApproversModal = (status) => {
    setState({
      ...state,
      approvers: {
        ...state.approvers,
        isOpen: status,
      },
    });
  };

  const {
    creator,
    status,
    approvers,
    description,
    createDate,
    referenceNo,
    memberId,
    purposeId,
    deductionPerMonth,
    deadline,
    amount,
    notificationCount,
    voucherNo,
    voucherId,
    loanType,
    interestRate,
  } = state.loanDetail;



  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Loan,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforLoan(id))
      })
    }
  }, [notificationCount])


  const handleOpenPrintVoucher = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenModal(true);
  }

  const handleVoucher = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  if (state.loanDetail.isLoading) return <Skeleton />;

  return (
    <>
      {
        <ItemDetailModal
          data={approvers}
          isDeleteDisabled={true}
          addEnabled={false}
          addFunc={false}
          onDelete={false}
          isSearch={false}
          openModal={true}
          visible={state.approvers.isOpen}
          setVisible={(da) => onCloseApproversModal(da)}
        />
      }
      <SingleItem >
        <ItemHeader>
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              status={creator?.userActiveStatus}
              profileId={creator?.id}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator?.designation : ""}
                  time={moment
                    .utc(createDate)
                    .local()
                    .fromNow()}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo && referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
          </div>
        </ItemHeader>
       {loanType !== LoanTypeEnum.WorkWise && <div className="flex justify-end">
          {status === LoanStatusEnum.Approved && (<>
            <Disperse label={sharedLabels.disburse}
              id={id}
              TransactionReferenceType={TransactionReferenceTypeEnum.Loan} />
          </>)}

          {
            voucherId === STRINGS.DEFAULTS.guid && (status === LoanStatusEnum.Disbursed || status === LoanStatusEnum.Completed) && (<>
              <div onClick={(e) => handleVoucher(e)}>
                <PostVoucher
                  label="Post Voucher"
                  id={id}
                  TransactionReferenceType={TransactionReferenceTypeEnum.Loan}
                />
              </div>
            </>)
          }
        </div>}
        <ItemContent className="flex">
          <div className="description">
            <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />


          </div>
        </ItemContent>
        <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
          {loanType !== LoanTypeEnum.WorkWise && <><div className="cardSectionItem">
            <div className="cardSection__title">
              {loan.loanPurpose.loanPurpose}
            </div>
            <div className="cardSection__body">
              {loan.loanPurposeEnumList && loan.loanPurposeEnumList[purposeId]}
            </div>
          </div>

          <div className="cardSectionItem" >
            <div className="cardSection__title">{loan.deduction}</div>
            <div className="cardSection__body">
              {deductionPerMonth ? deductionPerMonth : ""}
            </div>
          </div>
          {status === LoanStatusEnum.Approved ?
            <div className="cardSectionItem">
              <div className="cardSection__title"> {sharedLabels.deadline}</div>
              <div className="cardSection__body">
                {deadline
                  && moment(deadline).format("MMM DD,YYYY")}
              </div>
            </div> : ""}</>}
          <div className="cardSectionItem">
            <div className="cardSection__title"> {sharedLabels.amount}</div>
            <div className="cardSection__body"> {amount ? amount : ""}</div>
          </div>
          { loanType === LoanTypeEnum.WorkWise && 
          <div className="cardSectionItem">
            <div className="cardSection__title"> {"Interest Rate"}</div>
            <div className="cardSection__body">
            {`${interestRate} %`}
            </div>
          </div>}

          <div className="cardSectionItem">
            <div className="cardSection__title"> {"Loan Type"}</div>
            <div className="cardSection__body">
              {loan?.loanTypeEnum?.[loanType]}
            </div>
          </div>



          {voucherId !== STRINGS.DEFAULTS.guid && (
            <div className="cardSectionItem">
              <div className="cardSection__title"> {"Voucher No"}</div>
              <div className="cardSection__body">
                {voucherNo && <Button type="link" className="!text-[12px] !mt-[-6px] !text-[var(--currentThemeColor)] !font-semibold" onClick={(e) => handleOpenPrintVoucher(e)}
                >{voucherNo}</Button>
                }
              </div>
            </div>
          )}
        </div>
      </SingleItem>
      {loanType !== LoanTypeEnum.WorkWise && <RemarksApproval
        module={ApprovalsModule.LoanApproval}
        status={status}
        reference={loanDetail?.id}
        onStatusChanged={(prev) => { }}
        setApprovalDetail={(data) => { }}
        data={state.approvers.data && state.approvers.data}
        title="Approvers"
        notRequiredMemberId={[memberId]}
        itemCreator={creator}
      />}

      <CustomModal
        isModalVisible={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        width={"70%"}
        title={"Voucher"}
        footer={null}
        children={<VoucherPrint id={voucherId} />}
        className={""}
      />
    </>
  );
}
export default LoanDetail;
