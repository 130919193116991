import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseCode } from '../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';
import {
    GetAllPagesService,
    addPagesService,
    GetPagesByIdService,
    RemovePagesMemberService,
    getAllPagesMemberService,
    AddPagesMemberService,
    GetAllPagesPagingService,
    changePagesStatusService
} from '../service/service';
import { message } from 'antd';

export const getAllPages = createAsyncThunk(
  'Pages/getAllPages',
  async ({ filter, identifier }, { dispatch }) => {
    const res = await GetAllPagesService(filter);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getAllPagesPaging = createAsyncThunk(
  'Pages/getAllPagesPaging',
  async ({ filter, identifier }, { dispatch }) => {
    const res = await GetAllPagesPagingService(filter);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addPages = createAsyncThunk(
  'Pages/addPages',
  async (args, { dispatch }) => {
    const res = await addPagesService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Page Added successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);
export const getPagesIdAction = createAsyncThunk(
  "Pages/getPagesIdAction",
  async (data) => {
    const response = await GetPagesByIdService(data?.id || data);
    return response.data;
  }
);
// export const updatePagesStatus = createAsyncThunk(
//   'Pages/updatePagesStatus',
//   async (args, { dispatch }) => {
//     const res = await updatePagesStatusService(args);
//     if (res.responseCode) {
//       if (res.responseCode === responseCode.Success)
//         //res.message = 'Discussion Board Status updated successfully!';
//       //message.success(res.message);
//       responseMessage({ dispatch, data: res });
//     } else {
//       responseMessage({
//         dispatch: dispatch,
//         type: responseMessageType.ApiFailure,
//       });
//     }
//     return res;
//   }
// );
export const addPagesMember = createAsyncThunk(
  "Pages/AddPagesMember",
  async (payload, { rejectWithValue, dispatch }) => {
    const res = await AddPagesMemberService(payload);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const removePagesMember = createAsyncThunk(
  "Pages/RemovePagesMember",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await RemovePagesMemberService(data);
    if (res.responseCode) {
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
        
    return rejectWithValue(res?.data?.message);
    }
);
export const getAllPagesMember = createAsyncThunk(
  "Pages/getAllPagesMember",
  async (payload, { rejectWithValue, dispatch }) => {
    const res = await getAllPagesMemberService(payload);
      if (res.responseCode === responseCode.Success){
      responseMessage({ dispatch, data: res });
      }
     else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }     
    return res.data;
    }
);

export const changePagesStatus = createAsyncThunk(
  "pages/changePagesStatus",
  async (args, { dispatch }) => {
    const res = await changePagesStatusService(args);
    if (res.responseCode === responseCode.Success) {
      message.success("status changed");
    } else {
      message.error(`Error: ${res.message}`);
    }
    return res;
  }
)
