import {CommentModuleEnum} from "../../utils/enum/commentModuleEnum";
import CustomMentions from "../../../../sharedComponents/Mentions";
import {IoAlert} from "react-icons/io5";
import closeIcon from "../../../../sharedComponents/Comment/assets/close.svg";
import AiInputComments from "../../../../sharedComponents/Comment/AiInputComments";
import React, {useEffect, useRef, useState} from "react";
import CommentAvatar from "../commentAvatar";
import {useDispatch, useSelector} from "react-redux";
import {createGuid, getItemBorderColor, getMentionsAndText, isValidFileSize, jsonToFormData, STRINGS} from "../../../../../utils/base";
import {defaultUiid} from "../../../../../utils/Shared/enums/enums";
import {postComment} from "../../services";
import {addCommentAction} from "../../store/action";
import writeForMeIcon from "../../../../../content/NewContent/Documents/ai_post.svg"
import SharedButton from "../../../../sharedComponents/button";
import faceIcon from "../../../../../content/face.svg"
import EmojiPicker from "../../../../sharedComponents/emojiPicker";
import { message } from "antd";

const defaultState = {
    comment : "",
    hasAttachment: false,
    attachmentFile: null,
    attachmentName: "",
    attachmentPath: "",
    isImportant : false,
}
function CommentComposer({
    isAttachment = true,
    content = "",
    referenceId = STRINGS.DEFAULTS.guid,
    parentId = STRINGS.DEFAULTS.guid,
    module = CommentModuleEnum.Feed,
    identifier,
    parentCommentCreator = { name: "", image: "", id: STRINGS.DEFAULTS.guid },
    referenceType,
    mentionMemberRefId = STRINGS.DEFAULTS.guid,
  }) {
    const { user } = useSelector((state) => state.userSlice);
    const emojiRef = useRef();
    const dispatch = useDispatch();
    const [mentionsInTitle, setMentionsInTitle] = useState([]);
    const emojiPickerRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [isOpenAi, setIsOpenAi] = useState(false)
    const [isOpenEmoji, setIsOpenEmoji] = useState(false);
    const [state,setState] = useState(defaultState)
    const [loading,setLoading] = useState(false)
    const allowedFileTypes = ['.jpg', '.jpeg', '.gif', '.bmp', '.png', '.mp4'];
    const  placeholder = parentId !== STRINGS.DEFAULTS.guid ? "Write your reply here ..." :"Write your comment here ...";
    const addComment = async (event) => {
        if (event.keyCode === 13 || event.which === 13) {
           
            const { title, mentions } = getMentionsAndText(
                state.comment,
                mentionsInTitle
              );

            const requestObj = {
                id: createGuid(),
                module,
                referenceId,
                parentId,
                comment: title,
                attachments: state.hasAttachment
                    ? [{ id: defaultUiid, file: state.attachmentFile }]
                    : [],
                mentions : mentions,
                isImportant : state.isImportant,
            };
            if (!loading && (requestObj.comment.length > 0 || requestObj.attachments?.length > 0)) {
                setLoading(true)
                const jsonObject = jsonToFormData(requestObj);
                console.log(jsonObject);
                const abc = dispatch(addCommentAction({
                    filter:{referenceId:referenceId,module:module,parentId:parentId,identifier},
                    payload:jsonObject,
                    onSuccess:(response)=>{
                        if(response)
                            setState(defaultState);
                    }}
                )).finally(() => {
                    setLoading(false);
                });
            }
            event.preventDefault();
        }
    };
    const handleCommentImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
          const validFile = isValidFileSize(e.target.files);
          if (validFile.status) {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.onload = (data) =>
              setState({
                ...state,
                hasAttachment: true,
                attachmentPath: data.target.result,
                attachmentFile: file,
              });
            reader.readAsDataURL(file);
          } else {
            alert(validFile.message);
          }
        }
      };
    const handleDeleteAttachments = () => {
    setState({
      ...state,
      hasAttachment: false,
      attachmentPath: "",
      attachmentFile: null,
    });
  };

  const onSelectEmoji = (emoji) =>{
    setState((prevState) => ({
        ...prevState,
        comment: prevState.comment + emoji.native,
    }));
  }

  const handleEmojiPicker = () =>{
    setIsOpenEmoji(!isOpenEmoji)
    
    if (emojiRef.current) {
        const iconRect = emojiRef.current.getBoundingClientRect();
        const dropdownTop = iconRect.bottom - 50;
        const dropdownLeft = iconRect.left - 481;
        setDropdownPosition({ top: dropdownTop, left: dropdownLeft });
      }


  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)  &&
      !event.target.closest(".emoji-picker-container") ) {
        setIsOpenEmoji(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files.length > 1) {
      // Show error message if more than one file is attempted to be added
      message.error("Only one attachment can be added.");
    } else if (files.length === 1) {
      const file = files[0];
      const fileType = file.name.match(/\.[0-9a-z]+$/i); // Extract file extension

      if (fileType && allowedFileTypes.includes(fileType[0].toLowerCase())) {
        const fileEvent = { target: { files: [file] } };
        handleCommentImageChange(fileEvent);
      } else {
        // Alert the user about the specific disallowed file type
        message.error(
          `File type not allowed: ${fileType ? fileType[0] : "Unknown"}`
        );
      }
    }
  };

    console.log(state,"===state====")
    return (
        <>
        <div
        style={{background:"#f4f4f4" , margin:"5px" , borderRadius:"10px"}}
        className={`commentComposer !mb-0 ${state.isImportant ? getItemBorderColor(0,false, true, false, [], true) : ""
          }`
        }
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e)}
      >
            <div className="">
                <CommentAvatar image={user?.image} name={user?.name}/>
            </div>
            <div className="composer-area" style={{marginLeft : '10px'}}>
                <form className="inputs">
                    <div className="inp">
                        <CustomMentions
                            onChange={(event) => {
                                setState({...state,comment: event});
                            }}
                            row={1}
                            onSelect={(event) => {
                                setMentionsInTitle((preValue) => [...preValue, event]);
                            }}
                            value={state.comment}
                            onKeyPress={(event) =>
                            {
                                addComment(event)
                            }//createNewComment(event)
                        }
                            initialMentions={[]}
                            placeholder={placeholder}
                            referenceId={
                                mentionMemberRefId !== STRINGS.DEFAULTS.guid
                                  ? mentionMemberRefId
                                  : referenceId
                              }                            
                            module = {module}
                            referenceType = {referenceType}
                        />
                    </div>
                    {content &&
                        <img
                        style={{ height: "20px", cursor: "pointer" }}
                        src={writeForMeIcon}
                        onClick={() => setIsOpenAi(!isOpenAi)}
                        />
                        } 
                    <div ref={emojiRef}>
                        <SharedButton
                            onClick={handleEmojiPicker}
                            icon={faceIcon}
                            IconSize={20}
                        />
                    </div>


                     <IoAlert
                             onClick={() => setState({
                                ...state,
                                isImportant:!state.isImportant

                             })}
                            size={30}
                            style={{ cursor: "pointer", color: "gray" }}
                        /> 

                        {isAttachment && (
                            <div className="capture">
                                <input
                                    accept= {allowedFileTypes}
                                    type="file"
                                    value=""
                                onChange={handleCommentImageChange}
                                />
                            </div>
                        )}
                    </form>
                    {state.hasAttachment ? (
                    <div className="attach-select-area">
                        {
                        <div
                            className="attach  !h-[80px] !w-[80px]"
                            title={state.attachmentName}
                            style={{
                            backgroundImage: `url("${state.attachmentPath}")`,
                            backgroundRepeat: `no-repeat`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            }}
                        >
                            <div className="overlay">
                            <span>{state.attachmentName}</span>
                            </div>
                            <div className="cut" onClick={handleDeleteAttachments}>
                            <img src={closeIcon} alt="#" />
                            </div>
                        </div>
                        }
                    </div>
                    ) : (
                    ""
                    )}
                <AiInputComments
                    isOpen={isOpenAi}
                    setIsOpenAi={setIsOpenAi}
                    content={"content"}
                    onCommenting={(text) =>
                        setState((preValue) => ({
                        ...preValue,
                        comment: text,
                        }))} />
                


            </div>
        </div>
        {isOpenEmoji &&
            <div
                className="emoji-picker-container"
                style={{position:"absolute" , zIndex:'2' , left:dropdownPosition.left , top:dropdownPosition.top}}
            >
                <EmojiPicker
                    onSelect={onSelectEmoji}
                />
            </div>}
            </>
    );
}
export default CommentComposer;