import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    GetAllSuggestionService
} from "../services/service";

export const GetAllSuggestionAction = createAsyncThunk(
    "GetAllSuggestionAction",
    async (data) => {
      try {
        const response = await GetAllSuggestionService(data);
        return response.data;
      } catch (error) {
        console.log(error.message);
        throw new Error(`Error Fething Data: ${error}`, { cause: error });
      }
    }
  );