import { ResignationPurposeEnum } from "../utils/resignationPurposeEnum";

export const ResignationPurposeEnumList = [
  {
    label: "Insufficient Work Life Balance",
    value: ResignationPurposeEnum.InsufficientWorkLifeBalance,
  },
  {
    label: "Unrealistic Goals And Performance Objectives",
    value: ResignationPurposeEnum.UnrealisticGoalsAndPerformanceObjectives,
  },
  {
    label: "Lack Of A Clear Path For Career Advancement",
    value: ResignationPurposeEnum.LackOfAClearPathForCareerAdvancement,
  },
  {
    label: "Feel Unsupported By Management",
    value: ResignationPurposeEnum.FeelUnsupportedByManage,
  },
  {
    label: "Dont Feel Challenged",
    value: ResignationPurposeEnum.DontFeelChallenged,
  },
  {
    label: "Time-Off And Flexibility",
    value: ResignationPurposeEnum.TimeOffAndFlexibility,
  },
  {
    label: "Relocation",
    value: ResignationPurposeEnum.Relocation,
  },
  {
    label: "Others",
    value: ResignationPurposeEnum.Others,
  },
];
