import { createSlice } from "@reduxjs/toolkit";
import { addProductFeedBack, getAllFeedBack, GetFeedBackById } from "./action";

const initialState = {
    feedBackData: [],
    loading: false,
    error: null,
    success: false,
    data: null,
};
const feedBackSlice = createSlice({
    name: 'feedBackSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addProductFeedBack.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addProductFeedBack.fulfilled, (state, action) => {
                state.loading = false;
                state.feedBackData = action.payload;
            })
            .addCase(addProductFeedBack.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(GetFeedBackById.fulfilled, (state, action) => {
                const { identifier } = action?.meta?.arg;
                const data = action.payload.data ?? [];
                state.data = {
                    ...state.data,
                    [identifier]: [data],
                };
            })
            .addCase(getAllFeedBack.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.feedBackData = {
                    ...payload.data,
                    data: Array.isArray(payload.data.data) ? payload.data.data : [payload.data.data]
                };
            })
    },
});

export default feedBackSlice.reducer;