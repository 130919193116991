import React, { useContext, useEffect, useState } from "react";
import { Modal, Avatar, Form } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
  RightOutlined,
} from "@ant-design/icons";
import SuggestionBox from "../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import { SuggestionTypeEnum } from "../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { PostReferenceType } from "../../utils/constants";
import projectsIcon from "../../../../../content/svg/menu/newNavBarIcon/Projects.svg";
import groupsIcon from "../../../../../content/svg/menu/newNavBarIcon/Groups.svg";
import newsIcon from "../../../../../content/svg/menu/newNavBarIcon/News Feed.svg";

import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { SharePostDestinations } from "../../utils/sharePostDestinationEnums";
import { getNameForImage } from "../../../../../utils/base";
import AvatarOld from "../../../../sharedComponents/Avatar/avatarOLD";
import { useSelector } from "react-redux";

const DefaultPostRefType = PostReferenceType.MAIN_FEED;
const DefaultPostDestination = SharePostDestinations.FEED;

const DestinationModal = ({
  visible,
  onClose,
  onSelect,
  avatarProps,
  membersfromSuggestionBox,
  setmembersfromSuggestionBox,
  setPostReferenceType,
  setPostReferenceId,
  selectedDestination,
  setSelectedDestination,
  setDestinationText,
}) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed } = dictionaryList[userLanguage];
  const { SharedestinationNames, SharePostSuggestionPlaceholder } = newsFeed;
  const [currentView, setCurrentView] = useState("mainView");
  const [suggestionType, setSuggestionType] = useState([
    SuggestionTypeEnum.Employee,
  ]);

  const {
    userSlice: {
      user: { name, userImage, id: userId },
    },
  } = useSelector((state) => state);

  const handleBack = () => {
    setCurrentView("mainView");
    if (membersfromSuggestionBox?.length === 0) {
      setSelectedDestination(DefaultPostDestination);
      setPostReferenceType(DefaultPostRefType);
    }
  };

  const handleClose = (check) => {
    if (
      check &&
      (selectedDestination === SharePostDestinations.GROUP ||
        selectedDestination === SharePostDestinations.PROJECT ||
        selectedDestination === SharePostDestinations.MEMBERS_PROFILE) &&
      membersfromSuggestionBox?.length === 0
    ) {
      setSelectedDestination(DefaultPostDestination);
      setPostReferenceType(DefaultPostRefType);
    }
    setCurrentView("mainView");
    onClose();
  };

  const handleSelect = (destination) => {
    if (destination !== selectedDestination) {
      setmembersfromSuggestionBox([]);
    }

    setSelectedDestination(destination);
    setDestinationText(SharedestinationNames[destination]);
    destination !== SharePostDestinations.FEED &&
      destination !== SharePostDestinations.TIMELINE &&
      setCurrentView(SharedestinationNames[destination]);

    switch (destination) {
      case SharePostDestinations.GROUP:
        setSuggestionType([SuggestionTypeEnum.Group]);
        setPostReferenceType(PostReferenceType.GROUP);
        break;
      case SharePostDestinations.PROJECT:
        setSuggestionType([SuggestionTypeEnum.Project]);
        setPostReferenceType(PostReferenceType.PROJECTS);
        break;
      case SharePostDestinations.MEMBERS_PROFILE:
        setSuggestionType([SuggestionTypeEnum.Employee]);
        setPostReferenceType(PostReferenceType.TIMELINE);
        break;
      case SharePostDestinations.TIMELINE:
        setSuggestionType([SuggestionTypeEnum.Employee]);
        setPostReferenceType(PostReferenceType.TIMELINE);
        setPostReferenceId(userId);
        handleClose(false);
        break;
      default:
        setSuggestionType([SuggestionTypeEnum.Employee]);
        setPostReferenceType(8);
        setPostReferenceId(userId);
        handleClose(false);
        break;
    }
  };

  const memberSelected = (member) => {
    if (member?.length === 0) {
      setSelectedDestination(DefaultPostDestination);
      setPostReferenceType(DefaultPostRefType);
    }
    if (member?.length > 0) {
      handleClose(false);
    }
  };

  const renderDestinationItem = (destination, icon, avatarProps = {}) => (
    <div className="destination-item" onClick={() => handleSelect(destination)}>
      {avatarProps?.src === "" && avatarProps?.name !== "" ? (
        <AvatarOld {...avatarProps} />
      ) : (
        <Avatar {...avatarProps} icon={icon} />
      )}
      <span className="destination-text">
        {SharedestinationNames[destination]}
      </span>
      {selectedDestination === destination ? (
        <CheckOutlined style={{ color: "green", marginLeft: "auto" }} />
      ) : (
        destination !== SharePostDestinations.FEED &&
        destination !== SharePostDestinations.TIMELINE && (
          <RightOutlined style={{ marginLeft: "auto" }} />
        )
      )}
    </div>
  );

  return (
    <Modal
      open={visible}
      onCancel={() => handleClose(true)}
      footer={null}
      className="sharePostDestinationModal"
      title={
        <div className="modal-title">
          {currentView !== "mainView" && (
            <ArrowLeftOutlined onClick={handleBack} className="back-arrow" />
          )}
          <span className="modal-title-text">
            {currentView === "mainView" ? "Select destination" : currentView}
          </span>
        </div>
      }
    >
      <div className={`modal-content ${currentView}`}>
        {currentView === "mainView" ? (
          <div className="destination-list">
            {renderDestinationItem(
              SharePostDestinations.TIMELINE,
              null,
              avatarProps
            )}
            {renderDestinationItem(
              SharePostDestinations.FEED,
              <img src={newsIcon} alt="#" />,
              null
            )}
            {renderDestinationItem(
              SharePostDestinations.MEMBERS_PROFILE,
              <UserOutlined />,
              { style: { backgroundColor: "#1890ff" } }
            )}
            {renderDestinationItem(
              SharePostDestinations.GROUP,
              <img src={groupsIcon} alt="#" />,
              null
            )}
            {renderDestinationItem(
              SharePostDestinations.PROJECT,
              <img src={projectsIcon} alt="#" />,
              null
            )}
          </div>
        ) : (
          <div className="Member-profile-content">
            <Form.Item>
              <SuggestionBox
                initialData={membersfromSuggestionBox}
                isMultiSelect={false}
                callback={(item) => {
                  setmembersfromSuggestionBox(item);
                  memberSelected(item);
                }}
                suggestionType={suggestionType}
                placeholder={SharePostSuggestionPlaceholder[suggestionType]}
                removeSelf={true}
              />
            </Form.Item>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DestinationModal;
