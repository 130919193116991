export const CareerLevelTypeEnum = [
    {
        label: "Entry",
        value: 1,
    },
    {
        label: "Mid Level",
        value: 2,
    },
    {
        label: "Senior Level",
        value: 3,
    },
    {
        label: "Executive Level",
        value: 4,
    },
    {
        label: "C-Suite",
        value: 5,
    },
    {
        label: "President Ceo",
        value: 6,
    },
];


export const CareerLevelNameEnum = {
    1: "Entry",
    2: "Mid Level",
    3: "Senior Level",
    4: "Executive Level",
    5: "C-Suite",
    6: "President CEO",
};

export const CareerLevelEnum = {
    Entry: 1,
    MidLevel: 2,
    SeniorLevel: 3,
    ExecutiveLevel: 4,
    CSuite: 5,
    PresidentCeo: 6,
};