import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import MasterConfig from "../../../../utils/services/MasterConfig";

const API_PREFIX = "api/Comment/";
export const postComment = async (comment) => {
  console.log(comment, "pppp");
  const response = await MasterConfig.post(`${API_PREFIX}AddComment`, comment)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
  if (response.responseCode !== responseCode.Success) {
    //message.error("Something went wrong");
  }
  return response.data;
};
export const getAllCommentService = async (request) => {
  console.log(request,"GET ALLCOMMENT REQUEST");
  const response = await MasterConfig.post(`${API_PREFIX}GetAllComment`,request)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
  return response;
};
export const addCommentService = async (request) => {
  const response = await MasterConfig.post(`${API_PREFIX}AddComment`,request)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  return response;
};