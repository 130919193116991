import { Tag } from "antd";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { BonusStatusEnum } from "../../utils/bonusStatusEnum";
import { useContext } from "react";

const StatusTag = ({ status = BonusStatusEnum.InProcess }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { bonus, Direction } = dictionaryList[userLanguage];
  let color = ColorsEnum.Primary;

  switch (status) {
    case BonusStatusEnum.InProcess:
      color = ColorsEnum.Primary;
      break;
    case BonusStatusEnum.Approved:
      color = ColorsEnum.Success;
      break;
    case BonusStatusEnum.Declined:
      color = ColorsEnum.Danger;
      break;
    case BonusStatusEnum.Cancelled:
      color = ColorsEnum.Cancelled;
      break;
    case BonusStatusEnum.Inactive:
      color = ColorsEnum.InActive;
      break;
    case BonusStatusEnum.NotRequired:
      color = ColorsEnum.NotRequired;
      break;
    case BonusStatusEnum.Resend:
      color = ColorsEnum.Primary;
      break;
    case BonusStatusEnum.WaitingForOtherApproval:
      color = ColorsEnum.Primary;
      break;
    case BonusStatusEnum.Hold:
      color = ColorsEnum.Hold;
      break;
    case BonusStatusEnum.Disbursed:
      color = ColorsEnum.Disbursed;
      break;
    case BonusStatusEnum.Completed:
      color = ColorsEnum.Completed;
      break;
    default:
      color = ColorsEnum.Primary;
  }
  return (
    <>
      <Tag className="statusTag" style={{ backgroundColor: color }}>
        {bonus.statusEnum[status]}
      </Tag>
    </>
  );
};

export default StatusTag;
