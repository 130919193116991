import {createAsyncThunk, isRejectedWithValue} from "@reduxjs/toolkit";
import {responseCode} from "../../../../services/enums/responseCode";
import {addCommentService, getAllCommentService} from "../services";

export const getAllCommentAction = createAsyncThunk(
    'comment/getAllComment',
    async ({filter} , { rejectWithValue, dispatch }) => {
        const res = await getAllCommentService(filter);
        if (res?.data.responseCode === responseCode.Success) {
            return res.data.data;
        } else {
            return isRejectedWithValue(res.message);
        }
    }
);

export const addCommentAction = createAsyncThunk(
    'comment/addComment',
    async ({payload,onSuccess} , { rejectWithValue, dispatch }) => {

        console.log(payload);
        const res = await addCommentService(payload);
        if (res?.data.responseCode === responseCode.Success) {
            onSuccess(res.data.data);
            return res.data.data;
        } else {
            return isRejectedWithValue(res.message);
        }
    }
);
