import { createSlice, isPending, isRejected} from "@reduxjs/toolkit";
import { addHoliday, getAllHoliday } from "./action";
import { responseCode } from "../../../../services/enums/responseCode";

const initialState = {
    Holidays: [],
  loadingData: false,
  loader: false,
};

const holidaySlice = createSlice({
  name: "holidaySlice",
  initialState,
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllHoliday.fulfilled, (state, { payload }) => {
        state.loadingData = false;
        state.Holidays = payload?.data;
      })
      .addCase(addHoliday.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload.responseCode === responseCode.Success)
          state.Holidays = [payload?.data , ...state?.Holidays]
      })
   
      .addMatcher(isPending(...[addHoliday]), (state) => {
        state.loader = true;
      })
      .addMatcher(isPending(...[getAllHoliday]), (state) => {
        state.loadingData = true;
      })
      .addMatcher(
        isRejected(...[getAllHoliday, addHoliday]),
        (state) => {
          state.loader = false;
          state.loadingData = false;
        }
      );
  },
});

export const { gradeDeleted } = holidaySlice.actions;
export default holidaySlice.reducer;
