import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import { jsonToFormData, STRINGS } from "../../../../utils/base";
import Config from "../../../../utils/services/MasterConfig";

export const addNewTaskService = async (request) => {
  const formData = jsonToFormData(request);
  return Config.post(`api/UserTask/AddUserTask`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllTaskService = async (request) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/UserTask/GetAllUserTask`, request);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const getAllTaskPagingService = async (request) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/UserTask/GetAllUserTaskPaging`, request);
    if (responseCode === 1001) return ResponseResultSuccess(data);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const getTaskByIdService = async (taskId) => {
  console.log(taskId, "service");
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.get(`api/UserTask/GetUserTaskById?id=${taskId}`);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const postUserTaskRating = async (taskId, rating) => {
  try {
    const {
      data: { responseCode, message },
    } = await Config.get(
      `api/UserTask/UpdateUserTaskRating?id=${taskId}&rating=${rating}`
    );
    if (responseCode === 1001) return true;
    return false;
  } catch (e) {
    return ResponseResultError(e);
  }
};
export const updateUserTaskMemberProgressService = async (request) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.post(`api/UserTask/UpdateUserTaskMemberProgress`, request);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const cancelTaskService = async (id) => {
  try {
    const {
      data: { responseCode, data, message },
    } = await Config.delete(`api/UserTask/UserTaskCancel?taskId=${id}`);
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const UpdateUserTaskTimeService = async ({ startDate, endDate, id }) => {
  try {
    const url = `api/UserTask/UpdateUserTaskTime?id=${encodeURIComponent(
      id
    )}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
      endDate
    )}`;
    const {
      data: { responseCode, data, message },
    } = await Config.put(url);

    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const AddUserTaskMembersService = async (member, id) => {
  return Config.post(`api/UserTask/AddUserTaskMembers?taskId=${id}`, member)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
