import { SuggestionTypeEnum } from "../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { CommentModuleEnum } from "../../Comment/utils/enum/commentModuleEnum";
import { PostReferenceType } from "../../../features/feed/utils/constants";
import { WorkBoardReferenceTypeEnum } from "../../workboard/enum";
import { ScheduleReferenceTypeEnum } from "../../schedule/enum/enum";
import { DocumentReferenceTypeEnum } from "../../documents/view/enum/index";
import { TaskReferenceTypeEnum } from "../../task/utils/TaskReferenceTypeEnum";
import { DiscussionBoardReferenceTypeEnum } from "../../discussionBoard/utils/enum";
import { PagesReferenceTypeEnum } from "../../pages/utils/PagesReferenceTypeEnum";

export const prepareSuggestionTypes = (module, referenceType) => {
  switch (module) {
    case CommentModuleEnum.Group:
      return [SuggestionTypeEnum.GroupMember];
    case CommentModuleEnum.Project:
      return [SuggestionTypeEnum.ProjectMember];
    case CommentModuleEnum.Feed:
      switch (referenceType) {
        case PostReferenceType.GROUP:
          return [SuggestionTypeEnum.GroupMember];
        case PostReferenceType.PROJECTS:
          return [SuggestionTypeEnum.ProjectMember];
        default:
          return [SuggestionTypeEnum.Employee];
      }
    case CommentModuleEnum.Schedule:
      switch (referenceType) {
        case ScheduleReferenceTypeEnum.Group:
          return [SuggestionTypeEnum.GroupMember];
        case ScheduleReferenceTypeEnum.Project:
          return [SuggestionTypeEnum.ProjectMember];
        default:
          return [SuggestionTypeEnum.Employee];
      }
    case CommentModuleEnum.Document:
      switch (referenceType) {
        case DocumentReferenceTypeEnum.Group:
          return [SuggestionTypeEnum.GroupMember];
        case DocumentReferenceTypeEnum.Project:
          return [SuggestionTypeEnum.ProjectMember];
        default:
          return [SuggestionTypeEnum.Employee];
      }
    case CommentModuleEnum.UserTask:
      switch (referenceType) {
        case TaskReferenceTypeEnum.Group:
          return [SuggestionTypeEnum.GroupMember];
        case TaskReferenceTypeEnum.Project:
          return [SuggestionTypeEnum.ProjectMember];
        default:
          return [SuggestionTypeEnum.Employee];
      }
    case CommentModuleEnum.DiscussionBoard:
      switch (referenceType) {
        case DiscussionBoardReferenceTypeEnum.Group:
          return [SuggestionTypeEnum.GroupMember];
        case DiscussionBoardReferenceTypeEnum.Project:
          return [SuggestionTypeEnum.ProjectMember];
        default:
          return [SuggestionTypeEnum.Employee];
      }
    case CommentModuleEnum.Page:
      switch (referenceType) {
        case PagesReferenceTypeEnum.Group:
          return [SuggestionTypeEnum.GroupMember];
        case PagesReferenceTypeEnum.Project:
          return [SuggestionTypeEnum.ProjectMember];
        default:
          return [SuggestionTypeEnum.Employee];
      }

    case CommentModuleEnum.WorkBoardTodo:
      return [SuggestionTypeEnum.WorkBoardMember];
    case CommentModuleEnum.LeadManagerDetail:
      return [
        SuggestionTypeEnum.LeadManagerMember,
      ];
    default:
      return [SuggestionTypeEnum.Employee];
  }
};
