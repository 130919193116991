import Config from "../../../../utils/services/MasterConfig";
import {ResponseResultError, ResponseResultSuccess} from "../../../../utils/api/ResponseResult";
import {ReactionTypeEnum} from "../utils/enums/reactionTypeEnum";
import {ReactionModuleEnum} from "../utils/enums/reactionModuleEnum";
import {STRINGS} from "../../../../utils/base";

export const addReactionService = async (request) => {
    console.log("Request",request)
    try {
        const {
            data: { responseCode, data, message },
        } = await Config.post(`api/Reaction/AddReaction`, request);
        if (responseCode === 1001) return ResponseResultSuccess(data);
        return ResponseResultError(message);
    } catch (e) {
        return ResponseResultError(e);
    }
};