export const SuggestionBoxType = {
  User: 1,
  Group: 2,
  Project: 3,
  GeneralSuggestion: 4,
  GeneralExpenseApprovers: 5,
  ProjectExpenseApprovers: 6,
  GroupExpenseApprovers: 7,
  TravelExpenseApprovers: 8,
  ProjectMembers: 9,
  GroupMembers: 10,
};
