import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import moment from "moment";
import { getTaskById } from "../../../../store/actions";
import Comments from "../../../../../Comment/comments";
import { CommentModuleEnum } from "../../../../../Comment/utils/enum/commentModuleEnum";
import { removeIdentifier } from "../../../../store/taskSlice";
import Spinner from "../../../../../../sharedComponents/spinner/spinner";
import TaskDetail from "./taskDetail";
import "../../../../style/task.css";
import SubTaskShortCard from "./subTaskShortCard";

function TaskDetailItem({ id }) {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    identifier: moment().unix(),
    progress: 0,
    taskLoader: true,
  });

  const { data } = useSelector((state) => state.taskSlice);
  let taskDetail =
    data !== null && data?.[state.identifier] !== undefined
      ? data[state.identifier]
      : [];
  taskDetail = taskDetail && taskDetail?.length > 0 && taskDetail[0];

  useEffect(() => {
    id &&
      dispatch(getTaskById({ id: id, identifier: state.identifier })).then(
        () => {
          setState({ ...state, taskLoader: false });
        }
      );
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);

  if (state.taskLoader) return <Spinner />;

  return (
    <>
      {!Object?.keys(taskDetail).length ? (
        <Skeleton avatar paragraph={{ rows: 6 }} />
      ) : (
        <div className="taskDetail">
          {
            <TaskDetail
              item={taskDetail}
              isTaskMember={true}
              isRatingDisable={false}
              isDetail={true}
            />
          }
          {taskDetail.predecessor === "" && (
            <div className="mb-3">
              <SubTaskShortCard parentId={id} parentRecord={taskDetail} />
            </div>
          )}
          <Comments
            referenceId={id}
            module={CommentModuleEnum.UserTask}
            referenceType={taskDetail?.referenceType}
            mentionMemberRefId={taskDetail?.referenceId}
          />
        </div>
      )}
    </>
  );
}

export default TaskDetailItem;
