import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

export const useDebounceNavigation = (delay = 300) => {
  const navigate = useNavigate();

  return useCallback(
    debounce((to) => {
      navigate(to);
    }, delay),
    [navigate, delay]
  );
};