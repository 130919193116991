export const DefaultApprovalsReferenceTypeEnum = {
  General : 1,
  Project : 2,
  Group : 3
}

export const projectDefaultApprovalTypeEnum = {

  Expense : 1,
  ExpenseFinance: 2,
  Travel: 4,
  Quotations:26
};


export const GroupDefaultApprovalTypeEnum = {
  Expense : 1,
  ExpenseFinancers: 2,
  Travel: 4,
  Quotations:26
};

export const DefaultApprovalTypeEnum = {
  Expense: 1,
  ExpenseFinancers: 2,
  Travel: 4,
  TravelAgent: 5,
  Loan: 6,
  Leave: 7,
  Asset: 8,
  Salary: 9,
  Payroll: 10,
  Reward: 11,
  ResignationHr: 12,
  ResignationAdmin: 13,
  ResignationIt: 14,
  ResignationFinance: 15,
  ResignationExit: 16,
  Requisition: 17,
  CustomApproval: 18,
  Forms: 19,
  Appraisals: 20,
  Promotion: 21,
  Warning: 22,
  Bonus: 23,
  Complains: 24,
  Career: 25,
  Quotations: 26,
  RequisitionFinal : 27,
  Auction : 28,
  Attendance : 29
};


export const SingleDefaultApprovals=[
  DefaultApprovalTypeEnum.ExpenseExecutor,
  DefaultApprovalTypeEnum.TravelAgent,
]


export const prepareListData1 = (data, referenceType, moduleLabel) => {
  const categorizedMembers = {};

  // Function to process each member and group by type and levelNo
  const processMember = (member) => {
    const { type, levelNo } = member;
    const adjustedLevelNo = (levelNo === 0 || levelNo === 99) ? 99 : levelNo;

    // Initialize the structure if it doesn't exist
    if (!categorizedMembers[type]) {
      categorizedMembers[type] = {};
    }

    // Initialize the array for the specific level if it doesn't exist
    if (!categorizedMembers[type][adjustedLevelNo]) {
      categorizedMembers[type][adjustedLevelNo] = [];
    }

    // Push the member into the correct type and level
    categorizedMembers[type][adjustedLevelNo].push(member.member);
  };

  // Process each data item
  data.forEach((item) => {
    processMember(item);
  });

  Object.keys(categorizedMembers).forEach((type) => {
    if (!categorizedMembers[type][99]) {
      categorizedMembers[type][99] = []; // Assign an empty array to level 99 if not present
    }
  });

  
  // Prepare the final structure based on referenceType
  const result = [];
  let typeEntries;

  // Filter types based on referenceType
  if (referenceType === DefaultApprovalsReferenceTypeEnum.Project) {
    typeEntries = Object.entries(projectDefaultApprovalTypeEnum);
  } else if (referenceType === DefaultApprovalsReferenceTypeEnum.Group) {
    typeEntries = Object.entries(GroupDefaultApprovalTypeEnum);
  } else {
    typeEntries = Object.entries(DefaultApprovalTypeEnum);
  }

  // Build the result to include types even with empty members
  typeEntries.forEach(([key, value]) => {
    const members = categorizedMembers[value] || {};
    const memberLevels = Object.keys(members).length ? members : { 99: [] }; // Default to empty array for level 99

    // Aggregate all levels for this type into a single entry
    const aggregatedLevels = {};
    Object.entries(memberLevels).forEach(([levelNo, membersList]) => {
      aggregatedLevels[levelNo] = membersList;
    });

    // Add the type entry with aggregated levels
    result.push({
      label: moduleLabel[value],
      type: Number(value),
      Member: aggregatedLevels,
    });
  });

  // Sort the result by type and then by levelNo
  result.sort((a, b) => {
    if (a.type === b.type) {
      const aLevels = Object.keys(a.Member);
      const bLevels = Object.keys(b.Member);
      return Math.min(...aLevels) - Math.min(...bLevels); // Sort by the minimum levelNo
    }
    return a.type - b.type; // Sort by type
  });

  return result;
};



export const prepareListData = (data ,referenceType , moduleLabel)=>{
  console.log("referenceType" ,referenceType);
  if(referenceType == DefaultApprovalsReferenceTypeEnum.Project ){
    return  Object.entries(projectDefaultApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }else if(referenceType == DefaultApprovalsReferenceTypeEnum.Group ){
    return  Object.entries(GroupDefaultApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }else{
    return  Object.entries(DefaultApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }
 
}

