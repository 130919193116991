import { ResignationTypeEnum } from "./resignationTypeEnum"
export const ResignationTypeEnumList = [
    {
        label: "Resignation",
        value: ResignationTypeEnum.Resignation,
    },
    {
        label: "Termination",
        value: ResignationTypeEnum.Termination,
    },
]