import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import MinuteofMeetingDetailPrintCard from "./MinuteofMeetingDetailPrintCard";

export default function MintuesOfPrintPdfCardinModal({minutesOfMeetingDetail , isPreview , setisPreview}){

    const handelClose = () =>{
        setisPreview(false)
    }
    return(
        <Modal 
            open={isPreview}
            footer={null}
            onCancel={handelClose}
            width={800}

        >
             <MinuteofMeetingDetailPrintCard
                minutesOfMeetingDetail={minutesOfMeetingDetail}
          />
        </Modal>
    )
}