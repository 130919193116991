export const TaskViewTypeEnum = {
    Dashboard : 1,
    MyTasks : 2,
    AssignedByMe : 3,
    MyTeamTasks : 4
  }

  export const userTaskTypeEnum = 
{
  Task : 1,
  Goal : 2,
  }