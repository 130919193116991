import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
import { GroupNonMandatoryApprovalTypeEnum, NonMandatoryApprovalTypeEnum, NonMandatoryApprovalsReferenceTypeEnum, nonMandatoryApprovalsEnums, projectNonMandatoryApprovalTypeEnum } from "../utils";
import { DefaultApprovalsReferenceTypeEnum } from "../../defaultApprovers/utils";

// export const getAllNonMandatoryApproval = async (payload) => {
//   try {
//     const {
//       data: { responseCode, data, message },
//     } = await Config.post(
//       `/api/NonMandatoryApproval/GetAllNonMandatoryApproval`,
//       payload
//     );
//     if (responseCode === 1001) return ResponseResultSuccess(data);
//     return ResponseResultError(message);
//   } catch (e) {
//     return ResponseResultError(e);
//   }
// };
export const getAllNonMandatoryApproversService = async (payload) => {
  try {

    let apiCall = "GetAllNonMandatoryApproval";
    let payloadData = {...payload , types : Object.entries(NonMandatoryApprovalTypeEnum).map(([key, value]) =>{return value})} 

    if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Group){
      // apiCall = "GetAllGroupNonMandatoryApprovalAsync";
    payloadData = {...payload , types : Object.entries(GroupNonMandatoryApprovalTypeEnum).map(([key, value]) =>{return value})} 

    }

    else if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Project){
      // apiCall = "GetAllProjectNonMandatoryApproval"
      payloadData = {...payload , types : Object.entries(projectNonMandatoryApprovalTypeEnum).map(([key, value]) =>{return value})} 

  }
    const {
      data: { responseCode, data, message },
    } = await Config.post(
      `/api/NonMandatoryApproval/${apiCall}`,
      payload?.types ? payload :  payloadData

    );
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

// export const addNonMandatoryApproval = async (payload) => {
//   try {
//     const {
//       data: { responseCode, data, message },
//     } = await Config.post(`/api/NonMandatoryApproval/AddNonMandatoryApproval`, payload);
//     console.log(data, "NonMandatoryApproval service");
//     if (responseCode === 1001) return ResponseResultSuccess(data);
//     return ResponseResultError(message);
//   } catch (e) {
//     return ResponseResultError(e);
//   }
// };

// export const addNonMandatoryApproval = (payload ) => {
//   let apiCall = "AddNonMandatoryApproval";
//   if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Group)
//     apiCall = "AddGroupNonMandatoryApproval";
//   else if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Project)
//     apiCall = "AddProjectNonMandatoryApproval"

//   return Config.delete(`/api/NonMandatoryApproval/${apiCall}?id=${payload.id}`)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//       return err;
//     });
// };


export const addNonMandatoryApproval = async (payload) => {
  try {
    let apiCall = "AddNonMandatoryApproval";
    if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Group)
      apiCall = "AddGroupNonMandatoryApproval";
    else if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Project)
      apiCall = "AddProjectNonMandatoryApproval"

    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/NonMandatoryApproval/${apiCall}`, payload);
    console.log(data, "getAllDefaultApprovers service");
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const isNonMandatoryApprovalService = async (payload) => {
  try {
      
    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/NonMandatoryApproval/IsNonMandatoryApproval`, payload);
    console.log(data, "NonMandatoryApproval service");
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};


export const removeNonMandatoryApproverService = ({payload}) => {
  console.log(payload ,"payloaad");
  let apiCall = "RemoveNonMandatoryApproval";
  if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Group)
    apiCall = "RemoveGroupNonMandatoryApproval";
  else if (payload.referenceType === NonMandatoryApprovalsReferenceTypeEnum.Project)
    apiCall = "RemoveProjectNonMandatoryApproval"

  return Config.delete(`/api/NonMandatoryApproval/${apiCall}?id=${payload.id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};