import {
  ResponseResultError,
  ResponseResultSuccess,
} from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";
import { DefaultApprovalTypeEnum, DefaultApprovalsReferenceTypeEnum, GroupDefaultApprovalTypeEnum, defaultApprovers, projectDefaultApprovalTypeEnum } from "../utils";

export const getAllDefaultApprovers = async (payload) => {
  try {
    let apiCall = "GetAllDefaultApproval";
    let payloadData = {...payload , types : Object.entries(DefaultApprovalTypeEnum).map(([key, value]) =>{return value})}
    if (payload.referenceType === DefaultApprovalsReferenceTypeEnum.Group){
      // apiCall = "GetAllGroupDefaultApproval";
    payloadData = {...payload , types :  Object.entries(GroupDefaultApprovalTypeEnum).map(([key, value]) =>{return value})}
    
  }else if (payload.referenceType === DefaultApprovalsReferenceTypeEnum.Project){

      // apiCall = "GetAllProjectDefaultApproval"
      payloadData = {...payload , types :  Object.entries(projectDefaultApprovalTypeEnum).map(([key, value]) =>{return value})}

  }
    const {
      data: { responseCode, data, message },
    } = await Config.post(
      `/api/DefaultApproval/${apiCall}`,
      payload?.types ? payload :  payloadData

    );
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const addDefaultApprovers = async (payload) => {
  try {
    let apiCall = "AddDefaultApproval";
    if (payload.referenceType === DefaultApprovalsReferenceTypeEnum.Group)
      apiCall = "AddGroupDefaultApproval";
    else if (payload.referenceType === DefaultApprovalsReferenceTypeEnum.Project)
      apiCall = "AddProjectDefaultApproval"

    const {
      data: { responseCode, data, message },
    } = await Config.post(`/api/DefaultApproval/${apiCall}`, payload);
    console.log(data, "getAllDefaultApprovers service");
    if (responseCode === 1001) return ResponseResultSuccess(data);
    return ResponseResultError(message);
  } catch (e) {
    return ResponseResultError(e);
  }
};

export const removeDefaultApproverService = ({ payload }) => {
  let apiCall = "RemoveDefaultApproval";
  if (payload.referenceType === DefaultApprovalsReferenceTypeEnum.Group)
    apiCall = "RemoveGroupDefaultApproval";
  else if (payload.referenceType === DefaultApprovalsReferenceTypeEnum.Project)
    apiCall = "RemoveProjectDefaultApproval"

  return Config.delete(`/api/DefaultApproval/${apiCall}?id=${payload.id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};