import React, { useContext, useEffect, useState } from "react";
import { Button, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { Tag } from "antd";
import moment from "moment";

import { useDispatch } from "react-redux";
import { PromotionStatusEnum } from "../../utils/PromotionStatusEnum";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { GetPromotionById, cancelPromotion } from "../../store/actions";
import { readCountforPromotion, removeIdentifier } from "../../store/slice";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import ItemDetailModal from "../../../../sharedComponents/ItemDetails";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { STRINGS } from "../../../../../utils/base";
import { dictionaryList } from "../../../../../utils/localization/languages";
import ConfirmationRemarkModal from "../../../../sharedComponents/ConfirmationRemarkModal/ConfirmationRemarkModal";
import { readNotificationAction } from "../../../notifiation/store/action";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import ExpandableDescription from "../../../../sharedComponents/expandableDescription/expandableDescription";
import { ItemContent } from "../../../../sharedComponents/Card/CardStyle";
import { timeFromNow } from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";

function PromotionDetail({ id, index }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { promotion, Direction, sharedLabels } = dictionaryList[userLanguage];
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userSlice);
  const [isOpen, setIsOpen] = useState(false);

  let userId = user.id;
  const [state, setState] = useState({
    identifier: moment().unix(),
    promotionDetail: {
      creator: {},
      description: "",
      approvers: [],
      status: PromotionStatusEnum.InProcess,
      referenceNo: "",
      createDate: "",
      grade: "",
      member: {},
      memberId: STRINGS.DEFAULTS.guid,
      isLoading: true,
      notificationCount: 0
    },
    approvers: {
      isOpen: false,
      data: [],
    },
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const { data } = useSelector((state) => state.promotionSlice);
  let promotionDetail = data !== null ? data[state.identifier] : [];
  promotionDetail =
    promotionDetail && promotionDetail?.length > 0 && promotionDetail[0];



  useEffect(() => {
    id && dispatch(GetPromotionById({ id: id, identifier: state.identifier }));
  }, []);
  useEffect(() => {
    promotionDetail &&
      setState({
        ...state,
        promotionDetail: {
          ...state.promotionDetail,
          ...promotionDetail,
          isLoading: false,
        },
        approvers: {
          ...state.approvers,
          data: promotionDetail?.approvers,
        },
      });
  }, [promotionDetail]);
  useEffect(() => {
    return () => {
      dispatch(removeIdentifier({ identifier: state.identifier }));
    };
  }, []);
  //close Approvers Modal
  const onCloseApproversModal = (status) => {
    setState({
      ...state,
      approvers: {
        ...state.approvers,
        isOpen: status,
      },
    });
  };



  const {
    creator,
    status,
    approvers,
    description,
    createDate,
    member = {},
    referenceNo,
    grade,
    memberId,
    notificationCount
  } = state.promotionDetail;

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onFinish = (values) => {
    let id = promotionDetail.id;
    let reason = values.remarks;
    dispatch(cancelPromotion({ id: id, reason: reason })).then((res) => {
      setIsOpen(false);
    });
  };

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Promotion,
          featureId: id
        },
      }

      dispatch(readNotificationAction(payload)).then(response => {
        dispatch(readCountforPromotion(id))
      })
    }
  }, [notificationCount])

  if (state.promotionDetail.isLoading) return <Skeleton />;

  return (
    <>
      {
        <ItemDetailModal
          data={state.approvers.data} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={state.approvers.isOpen}
          setVisible={(da) => onCloseApproversModal(da)}
        />
      }

      <div className="detailedCard ">
        <div className="item-header">
          <div className="left">
            <UserInfo
              avatarSrc={creator && creator.image}
              status={creator.userActiveStatus}
              name={creator && creator.name}
              Subline={
                <SublineDesigWithTime
                  designation={creator.designation ? creator.designation : ""}
                  time={timeFromNow(creator.createDate)}
                />
              }
            />
          </div>
          <div className="right">
            <Tag className="IdTag">{referenceNo}</Tag>
            <StatusTag status={status}></StatusTag>
            {userId === creator.id ? (
              status !== PromotionStatusEnum.Declined &&
                status !== PromotionStatusEnum.Approved &&
                status !== PromotionStatusEnum.Cancelled ? (
                <Button
                  className="ThemeBtn"
                  onClick={(e) => handleCancel(e, id)}
                >
                  {sharedLabels.cancel}
                </Button>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <ItemContent>
          <div className="flex">
            <ExpandableDescription description={description} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
          </div>
        </ItemContent>
        <div className="cardSections" style={{ marginTop: isExpanded === true ? "120px" : "14px" }}>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {sharedLabels.Grade}
            </div>
            <div className="cardSection__body">
              <Tag className="IdTag">
                {grade}
              </Tag>
            </div>
          </div>
          <div className="cardSectionItem">
            <div className="cardSection__title">
              {promotion.promotionTo}
            </div>
            <div className="cardSection__body">{member && member.name}</div>
          </div>
        </div>
        <RemarksApproval
          module={ApprovalsModule.PromotionApproval}
          status={status}
          reference={promotionDetail?.id}
          onStatusChanged={(prev) => { }}
          setApprovalDetail={(data) => { }}
          data={state.approvers.data && state.approvers.data}
          title={sharedLabels.Approvers}
          notRequiredMemberId={[memberId]}
          itemCreator={creator}
        />
      </div>

      <ConfirmationRemarkModal
        key={Math.random()}
        isOpen={isOpen}
        onCancel={onClose}
        onFinish={onFinish}
      />
    </>
  );
}

export default PromotionDetail;
