import { Button, Dropdown, Segmented, Tag, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import workWiseLogo from "../../../../content/blackLogo.svg";
import "../styles/minutesOfMeeting.css";
import RichTextEditor from "../../../sharedComponents/RichTextEditor/RichTextEditor";
import Discussion from "./Discussion";
import { useDispatch, useSelector } from "react-redux";
import { BarsOutlined } from "@ant-design/icons";
import CommentWrapper from "../../../sharedComponents/Comment/CommentWrapper";
import { getMinutesMeetings, updateMinutesMeetings } from "../store/action";
import moment from "moment";
import {
  ScheduleMemberStatus,
  ScheduleMinutesOfMeetingStatusEnum,
} from "../enum/enum";
import TagAvatar from "../../../sharedComponents/Avatar/TagAvatar";
import ItemDetailModal from "../../../sharedComponents/ItemDetails";
import MinuteofMeetingDetailPrintCard from "./MinuteofMeetingDetailPrintCard";
import MintuesOfPrintPdfCardinModal from "./MintuesOfPrintPdfCardinModal";
import Comments from "../../Comment/comments";
import { CommentModuleEnum } from "../../Comment/utils/enum/commentModuleEnum";
import { dictionaryList } from "../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import convertUTCtoLocal from "../../../sharedComponents/DateHandler/dateUTCtoLocal";

export default function MintuesOfMeetingDetailForm({
  handleMintueOfmeetingPopUp,
}) {

  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule, sharedLabels } = dictionaryList[userLanguage];
  const [selectedOption, setSelectedOption] = useState(schedule.PointsofDiscussion);
  const [showAbsenteeMembers, setShowAbsenteeMembers] = useState(false);
  const [showAttendeesMembers, setShowAttendeesMembers] = useState(false);
  const { meetingDetail } = useSelector((state) => state.scheduleSlice);
  const [subjectValue, setSubjectValue] = useState("");
  const [RichTextEditorValue, setRichTextEditorValue] = useState("");
  const [lastSavedDate , setLastSavedDate] = useState(null)
  const [DetailData, setDetailData] = useState(meetingDetail?.details);
  const [inputs, setInputs] = useState([{ input: "", button: false }]);
  const [isPreview, setisPreview] = useState(false);
  const { scheduleComposerData: sched } = useSelector(
    (state) => state.scheduleSlice
  );

  const attendingMembers = meetingDetail.members?.filter(
    (member) => member.status === ScheduleMemberStatus.Attending
  );

  const nonAttendingMembers = meetingDetail.members?.filter(
    (member) =>
      member.status === ScheduleMemberStatus.Waiting ||
      member.status === ScheduleMemberStatus.NotAttending
  );

  const dispatch = useDispatch();

  const handleSegmentChange = (value) => {
    setSelectedOption(value);
  };
  useEffect(() => {
    sched?.id && dispatch(getMinutesMeetings(sched?.id));
  }, [dispatch, sched?.id]);

  useEffect(() => {
    setSubjectValue(meetingDetail?.subject || "");
    setRichTextEditorValue(meetingDetail?.minutesOfMeeting);
    setDetailData(meetingDetail?.details)
    setLastSavedDate(meetingDetail?.saveDate)
  }, [meetingDetail]);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowAbsenteeMembers(true);
  };
  const handleAttendeesModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowAttendeesMembers(true);
  };

  const menu = (
    <Menu
      items={[
        {
          label: <a>{schedule.Draft}</a>,
          key: "0",
        },
        {
          label: (
            <div>
              <a>{schedule.Finalize}</a>
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );
  const handleUpdateMeetings = (Status) => {
    // const GetPointsWithoutTask = inputs
    //   .filter((item) => item.button === true)
    //   .map((item) => ({
    //     detail: item.input,
    //     minutesOfMeetingId: meetingDetail?.id,
    //   }));

      const newArray = inputs
      .filter((item) => item.button === true || item.taskId)
      .map((item) => {
        if (item?.taskId) {
          // Return the object as is if it has a taskId
          return item;
        } else {
          // Transform the object if it doesn't have a taskId
          return {
            detail: item.input,
            minutesOfMeetingId: meetingDetail?.id,
          };
        }
      });

    console.log( newArray,"InputData", inputs);

    console.log(DetailData, "DetailData");

    const DetailDatass = DetailData
      ? [...DetailData, ...newArray]
      : inputs;

    const payload = {
      id: meetingDetail?.id,
      scheduleId: sched?.id,
      minutesOfMeeting: RichTextEditorValue,
      subject: subjectValue,
      details: DetailDatass,
      status: Status,
      // saveDate : Status ===  ScheduleMinutesOfMeetingStatusEnum.Complete ? null : moment().format(),
      // finalizeDate : Status === ScheduleMinutesOfMeetingStatusEnum.Complete ? moment().format()  : null,
    };
    dispatch(updateMinutesMeetings(payload)).then(() => {
      Status === ScheduleMinutesOfMeetingStatusEnum.Complete && handleMintueOfmeetingPopUp(true);
      setLastSavedDate(moment())
    });
  };

  const handleSubjectChange = (e) => {
    const value = e.target.value;
    setSubjectValue(value);
  };

  return (
    <div>
      <div className="flex justify-between">
        <Button className="!rounded !bg-amber-500 !font-semibold !text-white !border-transparent ">
          {meetingDetail.status == ScheduleMinutesOfMeetingStatusEnum.Complete
            ? schedule.Complete
            : schedule.Draft}
        </Button>
        <div className="flex gap-3 mr-[20%]">
        {meetingDetail.status == ScheduleMinutesOfMeetingStatusEnum.Complete
         &&
                <Button
                  className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !text-black !border-transparent"
                  // onClick={() => setisPreview(true)}
                >
                  {/* {"Send"} */}
                </Button>
        }
        <Button
                  className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !text-black !border-transparent"
                  onClick={() => setisPreview(true)}
                >
                  {schedule.Preview}
                </Button>

          {ScheduleMinutesOfMeetingStatusEnum.Draft ===
            meetingDetail.status && (
              <>
                {" "}
                {/* <Button
                  className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent"
                  onClick={() => setisPreview(true)}
                >
                  {schedule.Preview}
                </Button> */}
                <Button
                  className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent"
                  onClick={() =>
                    handleUpdateMeetings(
                      ScheduleMinutesOfMeetingStatusEnum.Complete
                    )
                  }
                >
                  {schedule.Finalize}
                </Button>
                <Button
                  className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent"
                  onClick={() =>
                    handleUpdateMeetings(ScheduleMinutesOfMeetingStatusEnum.Draft)
                  }
                >
                  {sharedLabels.Save}
                </Button>
              </>
            )}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="font-semibold text-[20px] text-[var(--currentThemeColor)] my-[40px]">
          {schedule.MinutesOftheMeeting}
        </div>
        <div className="flex gap-2">
          {
            meetingDetail.status === ScheduleMinutesOfMeetingStatusEnum.Draft && lastSavedDate ?
            <div className="flex gap-4 mt-3">  
            <label className="font-semibold">{"Last Saved Date :"}</label>
            <label className="font-semibold">{convertUTCtoLocal(lastSavedDate, "DD MMMM YYYY hh:mm A")}</label> 
          </div> : meetingDetail?.finalizeDate ? <div className="flex gap-4 mt-3">  
            <label className="font-semibold">{"Finalized Date :"}</label>
            <label className="font-semibold">{meetingDetail?.finalizeDate && convertUTCtoLocal(meetingDetail?.finalizeDate, "DD MMMM YYYY hh:mm A")}</label>  
          </div> : ""
          
          }
          <div className="rounded-md bg-white shadow-md w-[150px] h-[150px] flex justify-center items-center my-[-40px]">
            <img
              src={meetingDetail?.logo ? meetingDetail?.logo : workWiseLogo}
              alt=""
              className="meeting-image"
            />
          </div>
        </div>
      </div>
      <div className="meetingMinutesDetail">
      <div className="meetingDataItem">
        <div >
          <label className="font-semibold">{sharedLabels.subject} :</label>
          <input
            type="text"
            className="underline-input"
            placeholder={sharedLabels.subject}
            onChange={(e) => handleSubjectChange(e)}
            value={subjectValue}
          />
        </div>
        <div >
          <label className="font-semibold">{schedule.MeetingId}:</label>
          <input
            type="text"
            className="underline-input"
            placeholder={schedule.MeetingId}
            disabled
            value={meetingDetail ? meetingDetail?.referenceNo : ""}
          />
        </div>
      </div>
      <div className="meetingDataItem">
        <div
        >
          <label className="font-semibold">{sharedLabels.startDate} :</label>
          <label className="underline-input">{
              meetingDetail
                ? convertUTCtoLocal(meetingDetail?.startDate,"DD MMMM YYYY hh:mm A") 
                : ""
            }</label>
        </div>
        <div>
          <label className="font-semibold">{sharedLabels.endDate} :</label>
          <label className="underline-input">{
              meetingDetail
              ? convertUTCtoLocal(meetingDetail?.endDate,"DD MMMM YYYY hh:mm A")
              : ""
            }</label>

          {/* <input
            type="text"
            className="underline-input"
            placeholder={sharedLabels.endDate}
            disabled
            value={
              meetingDetail
                ? moment(meetingDetail?.endDate).format("DD MMMM YYYY hh:mm A")
                : ""
            }
          /> */}
        </div>
      </div>
      <div className="meetingDataItem">
        <div>
          <label className="font-semibold">{schedule.Venue} :</label>
          <input
            type="text"
            className="underline-input"
            placeholder={schedule.Venue}
            value={meetingDetail ? meetingDetail?.location : ""}
            disabled
          />
        </div>
        <div>
          <label className="font-semibold">{schedule.Workwisecall} :</label>
          <input
            type="text"
            className="underline-input"
            placeholder={schedule.ConferenceCall}
            disabled
            value={meetingDetail?.onVideoConference ? schedule.Yes : schedule.No}
          />
        </div>
      </div>
      <div className="meetingDataItem">
        <div>
          <label className="font-semibold">{schedule.Attendees} :</label>

          <div className="tag-input flex">
            {attendingMembers && attendingMembers.length > 1 ? (
              <>
                {attendingMembers.slice(0, 1).map((member, index) => (
                  <TagAvatar
                    text={member?.member?.name}
                    img={member?.member?.image}
                  />
                ))}
                <div
                  className="tag-avatar cursor-pointer"
                  onClick={(e) => handleAttendeesModal(e)}
                >
                  + {attendingMembers.length - 1}
                </div>
              </>
            ) : (
              attendingMembers?.map((member, index) => (
                <TagAvatar
                  text={member?.member?.name}
                  img={member?.member?.image}
                />
              ))
            )}
          </div>
        </div>

        <div>
          <label className="font-semibold">{schedule.Absentees} :</label>

          <div className="tag-input flex">
            {nonAttendingMembers && nonAttendingMembers.length > 1 ? (
              <>
                {nonAttendingMembers.slice(0, 1).map((member, index) => (
                  <TagAvatar
                    text={member?.member?.name}
                    img={member?.member?.image}
                  />
                ))}
                <div
                  className="tag-avatar cursor-pointer"
                  onClick={(e) => handleOpenModal(e)}
                >
                  + {nonAttendingMembers.length - 1}
                </div>
              </>
            ) : (
              nonAttendingMembers?.map((member, index) => (
                <TagAvatar
                  text={member?.member?.name}
                  img={member?.member?.image}
                />
              ))
            )}
          </div>
        </div>
      </div>
      </div>
      <hr className="mt-5" />
      <div className="flex justify-between">
        <div>
          <div className="font-semibold text-[20px] text-[var(--currentThemeColor)] my-[20px]">
            {sharedLabels.Notes}
          </div>
          <div className="w-[600px]" >
            <RichTextEditor
              setRichTextEditorValue={setRichTextEditorValue}
              editorContent={meetingDetail.minutesOfMeeting}
              readonly={
                meetingDetail.status ==
                ScheduleMinutesOfMeetingStatusEnum.Complete && true
              }
            />
          </div>
        </div>
        {/* calll compoennt of discusssion and comments */}

        <div className="segment-section">
          <div className="segment-tabs h-[0px] mt-5 !bg-neutral-100">
            <Segmented
              onChange={handleSegmentChange}
              options={[
                {
                  label: schedule.PointsofDiscussion,
                  value: schedule.PointsofDiscussion,
                  icon: (
                    <span className="ant-icons">
                      <BarsOutlined />
                    </span>
                  ),
                },
                {
                  label: sharedLabels.Comments,
                  value: sharedLabels.Comments,
                  icon: (
                    <span className="ant-icons">
                      <BarsOutlined />
                    </span>
                  ),
                },
              ]}
            />
            {selectedOption === schedule.PointsofDiscussion && (
              <Discussion
                DetailData={DetailData}
                setDetailData={setDetailData}
                inputs={inputs}
                setInputs={setInputs}
              />
            )}
            {selectedOption === sharedLabels.Comments && (
              <>
                <div className="comment-wrapper">
                  {/* <CommentWrapper
                    referenceId={sched?.id}
                    module={9}
                    isCommentLoad={true}
                  /> */}
                  <Comments
                    referenceId={sched?.id}
                    module={CommentModuleEnum.Schedule}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex justify-end mt-5">
      <Button className="ThemeBtn" onClick={handleUpdateMeetings}>
        Save
      </Button>
    </div> */}
      {
        <ItemDetailModal
          data={meetingDetail?.members?.filter(
            (member) =>
              member.status === ScheduleMemberStatus.Waiting ||
              member.status === ScheduleMemberStatus.NotAttending
          )} //Data of members will pass here in array
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={showAbsenteeMembers}
          setVisible={(da) => setShowAbsenteeMembers(da)}
        />
      }

      {
        <ItemDetailModal
          data={meetingDetail?.members?.filter(
            (member) => member.status === ScheduleMemberStatus.Attending
          )}
          isDeleteDisabled={true} //Pass true to hide delete icon
          addEnabled={false} //Pass false to hide select member
          addFunc={false} // define and pass addMember action of particular members
          onDelete={false} // define and pass onDeletemember actions of particular members
          isSearch={false} //Pass true if you want to search the list
          openModal={true} // pass true if you want to open member details in modal other wise it display in listing
          visible={showAttendeesMembers}
          setVisible={(da) => setShowAttendeesMembers(da)}
        />
      }
      {
        <MintuesOfPrintPdfCardinModal
          minutesOfMeetingDetail={meetingDetail}
          isPreview={isPreview}
          setisPreview={setisPreview}
        />
      }
    </div>
  );
}
