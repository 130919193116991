import { useContext, useEffect, useState } from "react";
import sunIcon from "../../../../../content/svg/menu/newNavBarIcon/new/dark_mode.svg";
import moonIcon from "../../../../../content/svg/menu/newNavBarIcon/new/light_mode.svg";
import search from "../../../../../content/svg/menu/newNavBarIcon/new/search.svg";
import notification from "../../../../../content/svg/menu/newNavBarIcon/new/ring.svg";
import rewards from "../../../../../content/svg/menu/newNavBarIcon/new/check_list.svg";
import stickyNotes from "../../../../../content/svg/menu/newNavBarIcon/new/sticky_notes.svg";
import calling from "../../../../../content/svg/menu/newNavBarIcon/new/calling.svg";
import { toggleStickyNote } from "../../../../features/notes/newStickyNotes/store/stickySlice";
import {
  setApprovalStatus,
  setNotificationStatus,
} from "../../../../../store/appReducer/responsiveSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  disable as disableDarkMode,
  enable as enableDarkMode,
} from "darkreader";
import NotificationModal from "./NavComposer";
import Approvals from "../../../../features/approval/view/SideBarApproval/sideBarAppovals";
import Notifications from "../../../../features/notifiation/view/index";
import OpenImage from "../../../../features/notes/OpenImage";
import StickyContainer from "../../../../features/notes/newStickyNotes/view/components/StickyNotes";
import { darkModeHandler } from "../../../../../utils/Shared/store/slice";
import { useNavigate } from "react-router-dom";
import { globalSearch } from "../../../../features/search/store/actions";
import {
  handleTab,
} from "../../../../features/search/store/slice";
import NotificationBadge from "../../../Badge/NotificationBadge";
import { toggleDialer } from "../../../../features/dialer/store/slice";
import { NotificationFeatureTypeEnum } from "../../../../features/notifiation/enums";
import { RxCross2 } from "react-icons/rx";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";

function NotificationBar() {

    const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels,setting } = dictionaryList[userLanguage];
  const [isSearch, setIsSearch] = useState(false);
  // const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [theme, setTheme] = useState(
    window.localStorage.getItem("darkMode") === "1"
  );
  const { navBarStatus, notifcationStatus, approvalStatus } = useSelector(
    (state) => state.responsiveSlice
  );
  const { notificationFeatureTypes } = useSelector((state) => state.notificationSliceNew);

  console.log("==notificationCountAtApprovers==",notificationFeatureTypes)
  useEffect(() => {
    // dispatch(getAllNotification());
  }, []);

  // console.log(keyword, "search keyworddd");
  const handleSearch = () => {
    setIsSearch(!isSearch);
  };
  const modeHandler = (status) => {
    if (status) {
      enableDarkMode({
        brightness: 100,
        contrast: 90,
        sepia: 10,
      });
    } else {
      disableDarkMode();
    }
    window.localStorage.setItem("darkMode", status ? "1" : "0");
  };

  useEffect(() => {
    setIsSearch(false);
  }, [navBarStatus === false]);

  let classes = "notificationBar ";
  classes += isSearch ? "open" : "";

  // Sticky Note
  const toggleNote = useSelector((state) => state.stickySlice.open);

  const stickyNoteHandler = () => {
    dispatch(toggleStickyNote());
  };
  const handleOpenDialer = () => {
    dispatch(toggleDialer());
  };

  const handleNotification = (status = true) => {
    dispatch(setNotificationStatus(status));
  };
  const handleApproval = (status = true) => {
    dispatch(setApprovalStatus(status));
  };
  const openImg = useSelector((state) => state.newStickySlice.openImg);

  function onKeyUp(e) {
    e.preventDefault();
    e.stopPropagation();
    // setKeyword(e.target.value);
    let value = e.target.value;
    console.log(value, "value");
    if (e.keyCode === 13 && !e.shiftKey) {
      // setIsSearch();
      setIsSearch(false);
      dispatch(
        globalSearch({
          pageNo: 1,
          pageSize: 20,
          search: value,
          filterType: 0,
        })
      );
      dispatch(handleTab("All"));
      // dispatch(handleGlobalSearch());
      // navigate(`search/sea`);
      navigate(`search?q=${value}`);
      // setIsSearch(false);
      e.target.value = "";
    }
  }

  return (
    <div className={classes}>
      <div className="notiBarIcon" title={sharedLabels.theme}>
        <ul className="list">
          <li className="list__item">
            <img
              alt="theme-icon"
              src={theme ? sunIcon : moonIcon}
              onClick={() => {
                setTheme(!theme);
                modeHandler(!theme);
                dispatch(darkModeHandler(!theme));
              }}
            />
          </li>
          <li className="list__item" title={sharedLabels.Calling}>
            <img src={calling} alt="calling" onClick={handleOpenDialer} />
          </li>
          <li className="list__item" title={sharedLabels.StickyNotes}>
            <img
              src={stickyNotes}
              alt="sticky-notes"
              onClick={stickyNoteHandler}
            />
          </li>
          <NotificationBadge
            notificationCount={notificationFeatureTypes?.[NotificationFeatureTypeEnum.Notification]}
            style={{
              right: "75px",
              position: "absolute",
              bottom: "26px",
              padding: "0 4.5px",
              cursor: "pointer",
            }}
          />
          <li
            className="list__item"
            title={setting.Notifications}
            onClick={handleNotification}
          >
            <img src={notification} alt="notifications-icon" />
          </li>
          <NotificationBadge
            notificationCount={notificationFeatureTypes?.[NotificationFeatureTypeEnum.Approval]}
            style={{
              right: "43px",
              position: "absolute",
              bottom: "26px",
              padding: "0 4.5px",
              cursor: "pointer",
            }}
          />
          <li
            className="list__item rewards__icon"
            title={sharedLabels.approvals}
            onClick={() => handleApproval()}
          >
            <img src={rewards} alt="rewards-icon" />
          </li>
        </ul>
        <div className={`searchBar ${isSearch ? "p-3" : "p-0"}`}>
          <img
            src={search}
            alt="search-icon"
            onClick={handleSearch}
            className="cursor-pointer"
            title={sharedLabels.Search}
          />
          <input
            type="text"
            className={!isSearch ? "d-none" : "globalSearchInput"}
            onKeyUp={(e) => onKeyUp(e)}
          />
          {isSearch && (
            <RxCross2
              onClick={handleSearch}
              className="text-2xl cursor-pointer text-[#757D86]"
              style={{ marginLeft: "10px" }}
              title={sharedLabels.close}
            />
          )}
        </div>
      </div>
      <NotificationModal
        isVisible={notifcationStatus}
        onClose={() => handleNotification(false)}
        key={notifcationStatus}
      >
        <Notifications />
      </NotificationModal>

      <NotificationModal
        isVisible={approvalStatus}
        onClose={() => handleApproval(false)}
        key={Math.random()}
      >
        <Approvals />
      </NotificationModal>

      {openImg && <OpenImage />}
      {toggleNote && <StickyContainer />}
      {/* {isDialeropen && <Dialer />} */}
    </div>
  );
}
export default NotificationBar;
