import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Checkbox, Space } from "antd";
import { STRINGS, SvgSpinner } from "../../../../../../utils/base";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import TextInput from "../../../../../sharedComponents/Input/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { setNewPassword } from "../../store/action";
import PasswordInput from "../../../../../sharedComponents/Input/PasswordInput";
import { useNavigate } from "react-router-dom";
import { ExternalSignUpAction } from "../../../../auth/store/actions";

function Signup({email = null}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading , setLoading ] = useState(false);

  const [form] = Form.useForm();

  const id = useLocation();
  const usertoken = id.search.split("=");
  const stoken = usertoken[1];

  const { token } = useSelector((state) => state.userSlice);

  useEffect(()=>{
    form.setFieldsValue({ email : email });
  },[email])

  let rules = [
    {
      required: true,
      message: "This field is required",
    },
  ];
  const { successPassword } = useSelector(
    (state) => state.projectExternalSlice
  );

  const handleSignUpSubmit = async (values) => {
    setLoading(true)
    const {email , firstName , lastName , password} = values
    const name = firstName +" "+ lastName
    let data = {
      loginPayload :{
        email,
        password
      },
      token: stoken,
      payload: {name  , password},
    };
    // dispatch(setNewPassword(payload));
    dispatch(ExternalSignUpAction(data)).then(()=>{
      setLoading(false)
    })
  };
  useEffect(() => {
    if (token) {
      navigate("/projects");
    }
  }, [token]);
  return (
    <>
      <Form
        form={form}
        onFinish={handleSignUpSubmit}
        name="nest-messages"
        id="form"
        className="form small-sign-up-form"
      >
        <div className="form-section small-sign-up-form">
          {
            <div className="input-group">
              <div className="row-header">
                <div className="row-cl-2">
                  <div className="row-cl-2-heading1">Register</div>
                  <div className="row-cl-2-heading2">
                    You’re signing up as a External Project.
                  </div>
                </div>
              </div>
              <Space direction="vertical" size={8} style={{ width: "100%" }}>
                <Form.Item name="email">
                  <TextInput
                    type="email"
                    placeholder="Enter Email"
                    size="large"
                    disabled={true}
                    prefix={MailOutlined}
                  />
                </Form.Item>
                <Form.Item name="firstName" rules={rules}>
                  <TextInput
                    type="text"
                    placeholder="First Name"
                    size="large"
                    // reset={reset}
                  />
                </Form.Item>
                <Form.Item name="lastName" rules={rules}>
                  <TextInput
                    type="text"
                    placeholder="Last Name"
                    // prefix={ShopOutlined}
                    size="large"
                    // reset={reset}
                  />
                </Form.Item>
                <Form.Item name="password">
                  <PasswordInput
                    placeholder="Password"
                    prefix={LockOutlined}
                    size="large"
                  />
                </Form.Item>

                <div className="agreement small-sign-up-form small-sign-up-form-agreement">
                  <Form.Item name="agree" valuePropName="checked">
                    <Checkbox>
                      <span className="terms-and-conditions">
                        I agree the terms and conditions.
                      </span>
                    </Checkbox>
                  </Form.Item>
                </div>
              </Space>
            </div>
          }
        </div>
       
        <div className="form-footer">
          <div className="sub-btn">
          <button className={`button ${loading ? "disable" : ""}`}>
          Register
            {!loading ? (
              <span className="icon-login">
                <i className="ic-login_icon" />
              </span>
            ) : (
              <SvgSpinner />
            )}
          </button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default Signup;
