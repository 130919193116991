import React, { useEffect, useState } from "react";
import { PlusSquareOutlined, ReloadOutlined } from "@ant-design/icons";
import Approval from "./Approval";
import { useDispatch } from "react-redux";
import "../../../../features/defaultApprovers/styles.css";
import AddAprrovalModal from "./AddAprrovalModal";
import { handleItemDetailModal } from "../../../../../utils/Shared/store/slice";
import { getAllApprovalByReferenceId } from "../action/action";
import { useSelector } from "react-redux";
import { ApprovalStatus } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import { STRINGS } from "../../../../../utils/base";
import CustomAddApproversModal from "../../../addApprovalContainer";
import { ApprovalsModuleEnum } from "../../../../features/approval/utils/enum/approvalModuleEnum";

function ApprovalWrapper({
  title,
  data,
  module,
  status,
  reference = STRINGS.DEFAULTS.guid,
  referenceType = 0,
  referenceTypeID = STRINGS.DEFAULTS.guid,
  notRequiredMemberId = [],
  itemCreator={},
  itemDetail={}
}) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [compData, setCompData] = useState([]);
  const [showPlusIcon, setShowPlusIcon] = useState(true);
  const [state, setState] = useState([]);
  const [currentLevelNo,setCurrentLevelNo] = useState(-1)

    // Group data by levelNo
    const groupByLevel = (data) => {
      return data.reduce((acc, item) => {
        const level = item?.levelNo || 0;
        if (!acc[level]) {
          acc[level] = [];
        }
        acc[level].push(item);
        return acc;
      }, {});
    };
  


  useEffect(() => {
    setCompData(data);
    setState(
      data?.map((item) => {
        return item.status;
      })
    );
  }, [data]);

  useEffect(() => {
    if (
      module === ApprovalsModuleEnum.ExpenseExecutor || module === ApprovalsModuleEnum.TravelAgent ||
      state?.length === 0 ||
      state.every(
        (status) =>
          status === ApprovalStatus.Approved ||
          status === ApprovalStatus.Declined ||
          status === ApprovalStatus.Hold ||
          status === ApprovalStatus.Cancelled
      )
    ) {
      setShowPlusIcon(false);
    } else setShowPlusIcon(true);
  }, [state]);

  const handleOpenApprovers = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
    // dispatch(handleItemDetailModal(true));
  };
  const handleReload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      getAllApprovalByReferenceId({ referenceId: reference, module: module })
    );
  };

  console.log(module,"===module===")
  // Get grouped data by level
  const groupedData = groupByLevel(compData);
  const levelCount = Object.keys(groupedData).length;

  const checkIfLevelApproved = (levelData) => {
    return levelData?.every(
      (item) =>
        item.status === ApprovalStatus.Approved ||
        item.status === ApprovalStatus.NotRequired ||
        item.status === ApprovalStatus.Inactive || 
        item.status === ApprovalStatus.Removed
    );
  };

  
  const approvalStatusByLevel = [];

  Object.keys(groupedData).forEach((levelNo, index) => {
    if (index === 0) {
      // Level 1 should always be true
      approvalStatusByLevel.push(true);
    } else {
      // For other levels, check the previous levels
      const previousLevelApproved = checkIfLevelApproved(
        groupedData[levelNo - 1]
      );
      // If the previous level is approved, check if the current level is approved
      approvalStatusByLevel.push(previousLevelApproved);
    }
  });


  console.log(approvalStatusByLevel,"===ApprovalStatusByLevel===")


  return (
    <>
      {Object.keys(groupedData).map((levelNo, index) => {
       return (<div key={index} className="approvalWrapper">
          {/* Header with title */}
          <div className="!p-0 approvalWrapper__header">
            <h6>
              {title} {levelCount > 1 && `Level ${levelNo}`}
            </h6>
            <ul className="list">
              <li className="list__item">
                <ReloadOutlined onClick={handleReload} />
              </li>

              {showPlusIcon && (
                <li className="list__item">
                  <PlusSquareOutlined onClick={(e)=>{
                    setCurrentLevelNo(levelNo)
                    handleOpenApprovers(e)}} />
                </li>
              )}
            </ul>
          </div>

          {/* Approvals for each level */}
          {groupedData[levelNo].map(
            ({
              approver,
              remarks: initialRemarks,
              status,
              approverId,
              createBy,
              id,
              lastReminder,
              reminderCount,
            }) => (
              <Approval
                key={id}
                module={module}
                approverType={approver?.type}
                approver={approver}
                initialRemarks={initialRemarks || []}
                status={status}
                approverId={approverId}
                createBy={createBy}
                id={id}
                title={title}
                reference={reference}
                itemCreator={itemCreator}
                reminderCount={reminderCount}
                lastReminder={lastReminder}
                data={data}
                itemDetail={itemDetail}
                isLevelApproved={approvalStatusByLevel[index]}
              />
            )
          )}
        </div>)
})}

      {/* Modal for adding approvers */}
      <CustomAddApproversModal
        key={Math.random()}
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        existingMembers={compData}
        referenceId={reference}
        module={module}
        referenceType={referenceType}
        referenceTypeID={referenceTypeID}
        notRequiredMemberId={notRequiredMemberId}
        levelNo={currentLevelNo}
      />
    </>
  );
}

export default ApprovalWrapper;
