export const auctionFilterTypeEnum = {
    All : 0,
    CreatedByMe : 1,
    ForApproval : 2,
}
export const AuctionFilterSortEnum = {

    CreateDateAsc : 1,
    CreateDateDesc : 2,
    UpdateDateAsc : 3,
    UpdateDateDesc : 4,
}

export const AuctionStatusEnum = {

    InProcess:1,
    Approved:2,
    Declined:3,
    Cancelled:4,
    Hold:5,
    WaitingForOtherApprovals : 6,
    Sold :7

}

export const  AuctionTypeEnum  = {
Personal : 1,
Official : 2
}

export const AuctionOfferStatusEnum = {
BidInProcess : 1,
BidSold : 2,
OutBid : 3
}