import React, { useState } from "react";
import { fileExtentionPreview } from "../../travel/utils/fileExtentionHelper";
import { getFile } from "../../travel/view/UI/AttachmentsCarrousel/AttachmentsCarrousel";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Thumbs,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom, Thumbs]);
const SwipperComponnent = ({ data = [] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <div className="slides">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
            height: "100%",
            width: "100%",
          }}
          // loop={true}
          spaceBetween={10}
          translate="yes"
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          className="mySwiper2"
          zoom={true}
        >
          {data.length > 1 ? (
            data?.map((slide, ind) => {
              return (
                <SwiperSlide
                  zoom={false}
                  // key={ind}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div className="flex justify-center items-center h-full">
                    {getFile(slide, "!h-full", true)}
                  </div>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide
              zoom={false}
              // key={ind}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <div className="flex justify-center items-center h-full">
                {getFile(data, "!h-full", true)}
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      {data?.length >= 1 && (
        <div className="thumbnails">
          <Swiper
            onSwiper={setThumbsSwiper}
            //  loop={true}
            spaceBetween={5}
            slidesPerView={10}
            freeMode={true}
            watchSlidesProgress={true}
            className="mySwiper"
            touchRatio={0.2}
          >
            {data?.map(({ path }, ind) => {
              return (
                <SwiperSlide style={{ width: "100px" }} zoom={true}>
                  <img
                    id={1}
                    src={fileExtentionPreview(path, ind)}
                    style={{
                      height: "100px",
                      width: "100%",
                      //objectFit: "contain",
                    }}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};
export default SwipperComponnent;
