import { Button, Tag } from "antd";
import React, { useState } from "react";
import MeetingTranscriptModal from "./meetingTranscriptModal";
import "../../styles/minutesOfMeeting.css"

function MeetingTranscript({ eventDetail }) {
  const [istranscript, setistranscript] = useState(false);

  return (
    <>
      {eventDetail &&
        eventDetail?.onVideoConference &&
        eventDetail?.startMeeting &&
        eventDetail?.videoConferenceToken && (
          <Tag
            onClick={() => setistranscript(true)}
            color="default"
            className="scheduleReferenceTypeTag !mr-[4px] !ml-[2px] w-fit"
          >
            {"Meeting Transcript"}
          </Tag>
        )}
      {istranscript && (
        <MeetingTranscriptModal
          setistranscript={setistranscript}
          istranscript={istranscript}
          roomId={eventDetail?.videoConferenceToken}
        />
      )}
    </>
  );
}

export default MeetingTranscript;
