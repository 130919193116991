import { Button, Form, Input } from "antd";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../../utils/localization/languages";
import SelectReferenceType from "./selectDocReferenceType";
import { DuplicateDocumentAction } from "../../../store/actions";
import { DocumentReferenceTypeEnum } from "../../enum";
import { STRINGS } from "../../../../../../utils/base";

const DuplicateDocumentComposer = ({ OnClose = () => {}, item = {} }) => {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, document } = dictionaryList[userLanguage];
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    name,
    referenceType,
    referenceId,
    attachmentId,
    referenceName,
  } = item;
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("vvvvvvvvvv", values);
        setLoader(true);
        let payload = {
          ...values,
          duplicateId: attachmentId,
          ...(values.referenceType === DocumentReferenceTypeEnum.General && {
            referenceId: STRINGS.DEFAULTS.guid,
          }),
        };
        dispatch(DuplicateDocumentAction(payload)).then((res) => {
          setLoader(false);
          OnClose();
        });
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo);
      });
  };

  return (
    <Form
      style={{ direction: "ltr" }}
      form={form}
      name="duplicateDocument"
      autoComplete="off"
      layout="vertical"
      className="ltr"
      initialValues={{
        documentName: `${name}-copy`,
        referenceId: referenceId,
        referenceType: referenceType,
      }}
    >
      <Form.Item
        label={sharedLabels.name}
        name="documentName"
        rules={[
          {
            required: true,
            message: sharedLabels.nameRequired,
          },
        ]}
      >
        <Input placeholder={sharedLabels.enterName} />
      </Form.Item>

      <SelectReferenceType
        form={form}
        referenceType={referenceType}
        referenceId={referenceId}
        refObj={{ name: referenceName, id: referenceId }}
      />

      <Form.Item>
        <Button
          type="primary"
          size="large"
          className="ThemeBtn"
          block
          htmlType="submit"
          onClick={handleFormSubmit}
          loading={loader}
        >
          {sharedLabels.create}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DuplicateDocumentComposer;
