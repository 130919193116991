import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { getAllApproval, getAllDefaultApproval } from "./action";
import { ApprovalStatus } from "../../../sharedComponents/AppComponents/Approvals/enums";

const initialState = {
  approvalList: [],
  defaultApprovalList: [],
  loader: false,
  itemRemove: null,
  data: null,
  approvalCountChange: null,
};

const approvalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    handleReminderApprovalList: (state, { payload }) => {
      const Index = state.approvalList.findIndex(
        ({ id }) => id === payload?.approvalId
      );
      if (Index !== -1) {
        var currentData = [...state.approvalList];
        let referenceItem = currentData[Index];

        referenceItem = {
          ...referenceItem,
          reminderCount: referenceItem.reminderCount + 1,
          lastReminder: new Date().toISOString().slice(0, 19),
        };

        currentData[Index] = referenceItem;
      }

      state.approvalList = currentData ?? state.approvalList;
    },

    removeApprovalItem: (state, { payload }) => {
      let { id, remarks, isMyApprovals } = payload;
      const statusKey = isMyApprovals
        ? `${remarks?.status}-My`
        : remarks?.status;
      const newApprovalList = state?.approvalList.filter(
        (item) => item.id !== id
      );
      const newDefaultApprovalList = state.defaultApprovalList.filter(
        (item) => item.id !== id
      );
      state.approvalList = newApprovalList;
      state.defaultApprovalList = newDefaultApprovalList;
      state.itemRemove = id;
      let filteredItem = null;
      const newState = {};
      for (const key in state.data) {
        if (state.data.hasOwnProperty(key)) {
          const filteredItems = state.data[key].filter((item) => {
            if (item.id === id) {
              filteredItem = item;
              return false;
            }
            return true;
          });
          newState[key] = filteredItems;
        }
      }
      state.approvalCountChange = {
        keyAdd: remarks.status,
        keySub: filteredItem.status,
      };
      const existingItems = Array.isArray(newState[statusKey])
        ? newState[statusKey]
        : [];
      filteredItem = {
        ...filteredItem,
        status: remarks.status,
        updateDate: remarks?.createDate,
      };
      state.data = {
        ...newState,
        [statusKey]: [filteredItem, ...existingItems],
      };
    },

    handleRemoveFromApprovalList: (state, { payload }) => {
      const { approvalId, status } = payload;
      const currentItem = state.approvalList?.find(
        (item) => item.id === approvalId
      );
      if (currentItem && currentItem.status !== status) {
        const newApprovalList = state.approvalList.filter(
          (item) => item.id !== approvalId
        );
        state.approvalList = newApprovalList;
        state.itemRemove = approvalId;
      }
    },
    handleOpenComposer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    readCounterforApproval: (state, { payload }) => {
      const defaultApprovalData = state.defaultApprovalList.find(
        (data) => data.id === payload.id
      );
      defaultApprovalData.notificationCount = 0;
    },
    readCounterForApprovalDetail: (state, { payload }) => {
      const approvalList = state.approvalList.find(
        (data) => data.id === payload.id
      );
      approvalList.notificationCount = 0;
    },
    handleResetState: (state, action) => {
      state.defaultApprovalList = [];
    },
    handleClearState: (state, { payload }) => {
      const { [payload]: _, ...newData } = state.data;
      state.data = newData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllApproval.fulfilled, (state, action) => {
        const pageNo = action?.meta?.arg?.filter?.pageNo;
        const payload = action.payload;
        const pageSize = action?.meta?.arg?.filter?.pageSize;
        const myApprovals = action?.meta?.arg?.filter?.myApprovals;
        const statusKey = myApprovals
          ? `${action?.meta?.arg?.filter?.status?.[0]}-My`
          : action?.meta?.arg?.filter?.status?.[0];

        state.loader = false;
        state.approvalList = action.payload
          ? action?.meta?.arg?.filter?.pageNo === 1
            ? action.payload
            : [...state.approvalList, ...action.payload]
          : [];

        if (pageNo === 1) {
          state.data = { ...state.data, [statusKey]: payload };
        } else {
          const expectedItems = pageSize * (pageNo - 1);
          if (state.data[statusKey].length === expectedItems) {
            state.data = {
              ...state.data,
              [statusKey]: [...state.data[statusKey], ...payload],
            };
          } else if (state.data[statusKey].length > expectedItems) {
            state.data = {
              ...state.data,
              [statusKey]: [
                ...state.data[statusKey].slice(
                  0,
                  -(state.data[statusKey].length - expectedItems)
                ),
                ...payload,
              ],
            };
          }
        }
      })
      .addCase(getAllDefaultApproval.fulfilled, (state, action) => {
        const pageNo = action?.meta?.arg?.filter?.pageNo;
        const payload = action.payload;
        const pageSize = action?.meta?.arg?.filter?.pageSize;
        if (pageNo === 1) {
          state.defaultApprovalList = payload;
        } else {
          const expectedItems = pageSize * (pageNo - 1);
          if (state.defaultApprovalList.length === expectedItems) {
            state.defaultApprovalList = [
              ...state.defaultApprovalList,
              ...payload,
            ];
          } else if (state.defaultApprovalList > expectedItems) {
            state.data = [
              ...state.defaultApprovalList.slice(
                0,
                -(state.defaultApprovalList.length - expectedItems)
              ),
              ...payload,
            ];
          }
        }
        state.loader = false;
      });
  },
});

export const {
  handleRemoveFromApprovalList,
  removeApprovalItem,
  handleOpenComposer,
  readCounterforApproval,
  readCounterForApprovalDetail,
  handleResetState,
  handleReminderApprovalList,
  handleClearState,
} = approvalSlice.actions;
export default approvalSlice.reducer;
