import React, { useContext } from "react";
import "../styles/MinuteofMeetingDetailPrintCard.css";
import { Button, Tag } from "antd";
import miletapLogo from "../../../../content/miletapLogo.svg";
import moment from "moment";
import {
  ScheduleMemberStatus,
  ScheduleMinutesOfMeetingStatusEnum,
} from "../enum/enum";
import { useSelector } from "react-redux";
import { dictionaryList } from "../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import convertUTCtoLocal from "../../../sharedComponents/DateHandler/dateUTCtoLocal";

const MinuteofMeetingDetailPrintCard = ({ minutesOfMeetingDetail }) => {
  const {
    location,
    members,
    startDate,
    endDate,
    subject,
    referenceNo,
    status,
    minutesOfMeetingId,
    description,
    details,
    minutesOfMeeting,
    logo,
    onVideoConference
  } = minutesOfMeetingDetail;
  console.log(minutesOfMeetingDetail, "details");

  const attendingMembers = members?.filter(
    member => member?.status === ScheduleMemberStatus?.Attending
  );


  const { user: { businessImage } } = useSelector((state) => state.userSlice);
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule, sharedLabels } = dictionaryList[userLanguage];

  // const handleSaveToPDF = () => {
  //   const content = document.getElementById("SaveAsPdf"); // Replace with the actual ID of the printable content container
  //   console.log(content,"con")
  //   // Configuration for the PDF creation
  //   const pdfOptions = {
  //     margin: 10,
  //     filename: "meeting_details.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //   };

  //   // Create a PDF using html2pdf
  //   html2pdf().from(content).set(pdfOptions).save();
  // };



  return (
    <>
      <div className="Meeting__btn">
        <Button className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent "
          onClick={() => { window.print() }}
        >
          {schedule.Print}
        </Button>
        <Button className="!bg-neutral-100 !rounded hover:!bg-primary-color !font-semibold hover:!text-white !border-transparent"
          onClick={() => { window.print() }}
        >
          {schedule.SaveToPDF}
        </Button>
      </div>
      <div className="content-box" id="SaveAsPdf">
        {
          (logo || businessImage) && <div className="Bussiness__Logo">
            <img src={logo || businessImage} alt="logo" />
          </div>
        }

        <div>
          <h1 className="Meeting_Table_Name"> {sharedLabels.Miletap}</h1>
        </div>
        {/* <div>
          <p className="Meeting_Table_Address">New York No. 1 Lake Park</p>
        </div> */}
        <div className="list___name">
          <h1>{schedule.MEETINGMINUTES}</h1>
        </div>
        <div className="Table__Parent">
          <table className="Table_itslef">
            <tbody>
              <tr>
                <td>
                  <b>{sharedLabels.subject} :</b> {subject}
                </td>
                <td colSpan={5}>
                  <b>{schedule.MeetingId} :</b> {referenceNo}
                </td>

              </tr>
              <tr>
                <td colSpan={1}>
                  <b>{sharedLabels.startDate} :</b> {convertUTCtoLocal(startDate, "DD MMMM YYYY hh:mm A")}
                </td>
                <td colSpan={5}>
                  <b>{sharedLabels.endDate} :</b> {convertUTCtoLocal(endDate, "DD MMMM YYYY hh:mm A")}
                </td>
              </tr>
              <tr>
                <td >
                  <b>{schedule.Venue} : </b> {location?.length > 0 ? location : "N/A"}
                </td>
                <td colSpan={3}>
                  <b>{sharedLabels.status} : </b> {status == ScheduleMinutesOfMeetingStatusEnum.Draft ? schedule.Draft : schedule.Complete}
                </td>
                <td colSpan={2}>
                  <b>{schedule.WorkwiseCall}: </b> {onVideoConference === true ? schedule.Yes : schedule.No}
                </td>
              </tr>
              <tr>
                <td className="table__head" colspan={6}>
                  1. {schedule.MeetingObjectives}
                </td>
              </tr>
              <tr dangerouslySetInnerHTML={{
                __html: description
              }}>

              </tr>
              <tr>
                <td className="table__head" colspan={6}>
                  2. {schedule.Points}
                </td>
              </tr>
              <tr>
                <td className="Table__font" colSpan={2}>
                  {schedule.Points}
                </td>
                <td className="Table__font" colSpan={2}>
                  {sharedLabels.DueDate}
                </td>
                <td className="Table__font" colSpan={2}>
                  {schedule.RefNo}
                </td>
              </tr>

              {details?.map((item) => {
                return (
                  <tr>
                    <td colSpan={2}>{item?.detail}</td>
                    <td colspan={3}>{item?.endDate && convertUTCtoLocal(item?.endDate,"DD MMMM YYYY hh:mm A")}</td>
                    <td colspan={1}>{item?.referenceNo}</td>
                  </tr>
                );
              })}

              {
                minutesOfMeeting?.length > 0 && <>

                  <tr>
                    <td className="table__head" colspan={6}>
                      3. {schedule.MinutesOftheMeeting}
                    </td>
                  </tr>
                  <tr
                    dangerouslySetInnerHTML={{
                      __html: minutesOfMeeting
                    }}
                  ></tr>

                </>
              }

              <tr>
                <td className="table__head" colspan={6}>
                  4. {schedule.AttendanceatMeeting}
                </td>
              </tr>
              <tr className="Table__Rows">
                <td>{sharedLabels.name}</td>
                <td colspan={2}>{sharedLabels.Designation}</td>
                <td colspan={2}>{sharedLabels.EMail}</td>
                <td colspan={2}>{sharedLabels.Attendance}</td>
              </tr>
              {members?.map((item) => {
                return (
                  <tr>
                    <td>{item?.member?.name}</td>
                    <td colspan={2}>{item?.member?.designation}</td>
                    <td colspan={2}>{item?.member?.email}</td>
                    <td colspan={2}>{item?.status === ScheduleMemberStatus?.Attending ? <Tag className="attend-declineTag attendingTag">
                      {sharedLabels.Present}
                    </Tag> : <Tag
                      className="attend-declineTag declineTag">
                      {sharedLabels.Absent}
                    </Tag>}</td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MinuteofMeetingDetailPrintCard;
