import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseCode } from '../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';
import {
  getAllDiscussionBoardService,
  addDiscussionBoardService,
  GetDiscussionBoardById,
  updateDiscussionBoardStatusService,
  updateDiscussionBoardService,
  addDiscussionBoardMemberService,
  removeDiscussionBoardMemberService
} from '../service/service';
import { message } from 'antd';

export const getAllDiscussionBoard = createAsyncThunk(
  'DiscussionBoard/getAllDiscussionBoard',
  async (args, { dispatch }) => {
    const res = await getAllDiscussionBoardService(args);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addDiscussionBoard = createAsyncThunk(
  'DiscussionBoard/addDiscussionBoard',
  async (args, { dispatch }) => {
    const res = await addDiscussionBoardService(args);
    console.log(res);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Discussion Board added successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const getDiscussionBoardByIdAction = createAsyncThunk(
  'DiscussionBoard/getDiscussionBoardByIdAction',
  async (args, { dispatch }) => {
    const res = await GetDiscussionBoardById(args.id);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);
export const updateDiscussionBoardStatus = createAsyncThunk(
  'DiscussionBoard/updateDiscussionBoardStatus',
  async (args, { dispatch }) => {
    const res = await updateDiscussionBoardStatusService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const updateDiscussionBoard = createAsyncThunk(
  'DiscussionBoard/updateDiscussionBoard',
  async (args, { dispatch }) => {
    const res = await updateDiscussionBoardService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Update Discussion Board successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addDiscussionBoardMember = createAsyncThunk(
  'DiscussionBoard/addDiscussionBoardMember',
  async ({ members }, { dispatch }) => {
    const res = await addDiscussionBoardMemberService(members);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Member Added!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const removeDiscussionBoardMember = createAsyncThunk(
  'DiscussionBoard/removeDiscussionBoardMember',
  async (data, { dispatch }) => {
    const res = await removeDiscussionBoardMemberService(data);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        console.log(res, "responsee-->");

      res.message = 'Member Deleted Successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);