import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { openNotification } from "../../../../utils/Shared/store/slice";
import { NotificationFilterTypeService, getAllNotificationCountService, getAllNotificationService, markAllReadService, readNotificationService, removeNotificationCountService } from "../services/service";

export const getAllNotification = createAsyncThunk(
  "Notification/getAllNotification",
  async (payload={}, { rejectWithValue, dispatch }) => {
    const response = await getAllNotificationService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const getAllNotificationCount = createAsyncThunk(
  "Notification/getAllNotificationCount",
  async (payload={}, { rejectWithValue, dispatch }) => {
    const response = await getAllNotificationCountService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const removeNotificationCount = createAsyncThunk(
  "Notification/removeNotificationCount",
  async (payload={}, { rejectWithValue, dispatch }) => {
    const response = await removeNotificationCountService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const readNotificationAction = createAsyncThunk(
  "Notification/removeNavBarNotificationCounter",
  async ({filter}, { rejectWithValue, dispatch }) => {
    const response = await readNotificationService(filter);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const NotificationFilterTypeAction = createAsyncThunk(
  "Notification/NotificationFilterType",
  async ({filter}, { rejectWithValue, dispatch }) => {
    const response = await NotificationFilterTypeService(filter);
    console.log(response,"=repons=")
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);

export const markAllReadAction = createAsyncThunk(
  "Notification/markAllReadAction",
  async (payload={}, { rejectWithValue, dispatch }) => {
    console.log("caaaaaaaaaaaaaaa")
    const response = await markAllReadService(payload);
    console.log(response,"=repons=")
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);