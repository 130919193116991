import React, { useContext } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { ColorsEnum } from "../../../../../utils/Shared/enums/colorsEnum";
import { Tag } from "antd";
import { PayrollStatusEnum } from "../../utils/payrollStatusEnum";
const StatusTag = ({ status = PayrollStatusEnum.InProcess }) => {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { payroll } = dictionaryList[userLanguage];

    let color = ColorsEnum.Primary;

    switch (status) {
        case PayrollStatusEnum.InProcess:
            color = ColorsEnum.Primary;
            break;
        case PayrollStatusEnum.Approved:
            color = ColorsEnum.Success;
            break;
        case PayrollStatusEnum.Declined:
            color = ColorsEnum.Danger;
            break;
        case PayrollStatusEnum.Resend:
            color = ColorsEnum.Primary;
            break;
        case PayrollStatusEnum.Inactive:
            color = ColorsEnum.InActive;
            break;
        case PayrollStatusEnum.NotRequired:
            color = ColorsEnum.NotRequired;
            break;
        case PayrollStatusEnum.Cancelled:
            color = ColorsEnum.Cancelled;
            break;
        case PayrollStatusEnum.Hold:
            color = ColorsEnum.Hold;
            break;
        case PayrollStatusEnum.Disbursed:
            color = ColorsEnum.Disbursed;
            break;
        case PayrollStatusEnum.Completed:
            color = ColorsEnum.Completed;
            break;
        default:
            color = ColorsEnum.Primary;
    }
    console.log(color, "color");
    return (
        <>
            <Tag className="statusTag" style={{ backgroundColor: color }}>
                {payroll.statusEnum[status]}
            </Tag>
        </>
    );
};

export default StatusTag;

