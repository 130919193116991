import React, { useEffect, useContext } from "react";
import { TeamTable } from "./TaskTable/TeamTable";
import { getAllLoanAction } from "../store/action";
import { useDispatch, useSelector } from "react-redux";
import StatusTag from "../../../sharedComponents/Tag/StatusTag";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../utils/localization/languages";
import moment from "moment";
import { useParams } from "react-router-dom";
import { LoanTypeEnum } from "../../loan/utils/LoanTypeEnum";

function Loan({ userId = null }) {
  const dispatch = useDispatch();
  const { userLanguage } = useContext(LanguageChangeContext);
  const { sharedLabels, myTeam, loan } = dictionaryList[userLanguage];
  const {
    team: { loandetails },
    success,
  } = useSelector((state) => state.teamSlice);

  const { id } = useParams();

  let myId = userId ? userId : id;
  console.log("WORKING HERE");
  useEffect(() => {
    dispatch(getAllLoanAction(myId));
  }, []);
  const columns = [
    {
      title: sharedLabels.ReferenceNo,
      dataIndex: "referenceNo",
      key: "referenceNo",
      // className: "referenceNo",
      sort: true,
      width: 100,
    },
    {
      title: sharedLabels.createDate,
      dataIndex: "date",
      render: (createDate) => moment(createDate).format("DD MMM YYYY"),
      key: "date",
      sort: true,
      width: 150,
    },
    {
      title: sharedLabels.amount,
      dataIndex: "amount",
      key: "amount",
      sort: true,
      width: 80,
    },
    {
      title: myTeam.DeductionPerMonth,
      dataIndex: "deductionPerMonth",
      key: "deductionPerMonth",
      sort: true,
      width: 100,
    },
    {
      title: "Loan Type",
      dataIndex: "loanType",
      key: "loanType",
      sort: true,
      width: 200,
      render: (loanType) => {
        return loan?.loanTypeEnum?.[loanType];
      },

    },
    {
      title: sharedLabels.Status,
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusTag status={status} />,
      sort: true,
      width: 150,
    },
  ];
  return (
    <>
      <TeamTable
        bordered
        columns={columns}
        className="custom_table"
        dataSource={loandetails}
      />
    </>
  );
}
export default Loan;
