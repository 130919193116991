import { ApprovalsModuleEnum } from "../utils/enum/approvalModuleEnum";

const arabic = {
    approvalDictionaryLabel: {
        Approval: "موافقة",
        [ApprovalsModuleEnum.ExpenseExecutor]: "تنفيذ",
        [ApprovalsModuleEnum.ExpenseFinance]: "مالية",
        [ApprovalsModuleEnum.ResignationHrApproval]: "موارد بشرية",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "مالية",
        [ApprovalsModuleEnum.ResignationItApproval]: "تكنولوجيا المعلومات",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "إدارة",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "موافقات أخرى",
        [ApprovalsModuleEnum.ResignationExitApproval]: "مقابلة خروج",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "تقارير إلى",
        [ApprovalsModuleEnum.TravelApproval]: "موافقون",
        [ApprovalsModuleEnum.TravelAgent]: "وكيل",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "موافقون نهائيين",
    },
    approvalDictionaryPlaceHolder: {
        Approval: "موافقة",
        [ApprovalsModuleEnum.ExpenseExecutor]: "حدد منفذ المصاريف",
        [ApprovalsModuleEnum.ExpenseFinance]: "حدد الموافقون من المالية",
        [ApprovalsModuleEnum.ExpenseApproval]: "حدد موافقين المصاريف",
        [ApprovalsModuleEnum.ResignationHrApproval]: "حدد الموارد البشرية",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "حدد المالية",
        [ApprovalsModuleEnum.ResignationItApproval]: "حدد تكنولوجيا المعلومات",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "حدد الإدارة",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "حدد موافقات أخرى",
        [ApprovalsModuleEnum.ResignationExitApproval]: "حدد مقابلة الخروج",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "حدد التقارير إلى",
        [ApprovalsModuleEnum.TravelApproval]: "حدد موافقون للسفر",
        [ApprovalsModuleEnum.TravelAgent]: "حدد وكيل لترتيب السفر",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "حدد موافقون نهائيين",
    },
    /*** Define language write side ***/
    Direction: 'rtl',
};

export default arabic;
