import { GetInvoiceForPaymentService, addPaymentCardService, getAllBusinessPaymentCardService, getAllInoviceService, getInvoiceByIdService, payBillService } from "../service/service";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";



export const addPaymentCardAction = createAsyncThunk(
    'paymentCard/addPaymentCard',
    async (token, { dispatch , rejectWithValue }) => {
        console.log("===here 2")
      const res = await addPaymentCardService(token);
      if (res.responseCode === responseCode.Success) {
        dispatch(
          openNotification({
            message: "Your card has been added successfully.",
            type: "success",
            duration: 2,
          })
        );
  
        return res;
      } else {
        dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: 2,
          })
        );
        return rejectWithValue(res.message);
      }
    //   if (!res.responseCode) {
    //     responseMessage({
    //       dispatch: dispatch,
    //       type: responseMessageType.ApiFailure,
    //     });
    //   }
    //   return res;
    }
  )


  export const getAllBusinessPaymentCardAction = createAsyncThunk(
    'paymentCard/getAllBusinessPaymentCard',
    async (args, { dispatch }) => {
      const res = await getAllBusinessPaymentCardService();
      if (!res.responseCode) {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  )

  export const getInvoiceByIdAction = createAsyncThunk(
    'paymentCard/getInvoiceByIdAction',
    async (id, { dispatch , rejectWithValue }) => {
      const res = await getInvoiceByIdService(id);
      if (res.responseCode === responseCode.Success) {
        return res;
      } else {
        dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: 2,
          })
        );
        return rejectWithValue(res.message);
      }
    }
  )

  export const GetInvoiceForPaymentAction = createAsyncThunk(
    'paymentCard/GetInvoiceForPaymentAction',
    async (id, { dispatch , rejectWithValue }) => {
      const res = await GetInvoiceForPaymentService(id);
      if (res.responseCode === responseCode.Success) {
        return res;
      } else {
        dispatch(
          openNotification({
            message: res.message,
            type: "error",
            duration: 2,
          })
        );
        return rejectWithValue(res.message);
      }
    }
  )

  export const payBillAction = createAsyncThunk(
    'paymentCard/payBillAction',
    async (payload, { dispatch , rejectWithValue }) => {
      const res = await payBillService(payload);
      if (res.responseCode === responseCode.Success) {
        message.success("Your payment has been processed successfully.")
        return res;
      } else {
        message.error(res.message)
        return rejectWithValue(res.message);
      }
    }
  )

   export const getAllInoviceAction = createAsyncThunk(
    'paymentCard/getAllInoviceAction',
    async (args, { dispatch }) => {
      const res = await getAllInoviceService(args);
      if (!res.responseCode) {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  )
