import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDisburseDetailSuggestionService, submitDisburseService } from "../services/service";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { ResponseType } from "../../../../utils/api/ResponseResult";
import { message } from "antd";
import { responseCode } from "../../../../services/enums/responseCode";
import { openNotification } from "../../../../utils/Shared/store/slice";

export const getDisburseDetailSuggestion = createAsyncThunk(
    "Disburse/getDisburseDetailSuggestion",
    async (data, { rejectWithValue, dispatch }) => {
        const { id, referenceType } = data;
        const response = await getDisburseDetailSuggestionService(id, referenceType);

        switch (response.type) {
            case ResponseType.ERROR:
                responseMessage({
                    dispatch: dispatch,
                    type: responseMessageType.ApiFailure,
                    data: {
                        message: response.errorMessage,
                    },
                });
                return rejectWithValue(response.errorMessage);
            case ResponseType.SUCCESS:
                return response.data;
            default:
                return;
        }
    }
);


export const submitDisburse = createAsyncThunk(
    "Disburse/submitDisburse",
    async (data, { dispatch, getState, rejectWithValue }) => {
        const response = await submitDisburseService(data);
        switch (response.type) {
            case ResponseType.ERROR:
                responseMessage({
                    dispatch: dispatch,
                    type: responseMessageType.ApiFailure,
                    data: {
                        message: response.errorMessage,
                    },
                });
                return rejectWithValue(response.errorMessage);
            case ResponseType.SUCCESS:
                dispatch(openNotification({
                    message: "Disbursed Successfully!",
                    type: "success",
                    duration: 2
                }))
                return response.data;
            default:
                return;
        }
    }

);