import MasterConfig from "../../../../utils/services/MasterConfig";
export const getAllBillingService = (payload) => {
  console.log(payload, "PAYLOAD");
  //TODO: url will be changed
  return MasterConfig.post(`/api/Payment/GetAllBilling`, payload)
    .then((res) => {
      console.log(res, "getAllBiling");

      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllPendingBillsService = (payload) =>{

  return MasterConfig.post(`api/Payment/GetAllPendingBilling`,payload)
  .then((res)=>{
    console.log("res");

    return res?.data
  })
  .catch((err)=>{
    return err;
  })
}

export const addBillingService = (payload) =>{

  return MasterConfig.post(`api/Payment/AddBilling`,payload)
  .then((res)=>{
    console.log("res");

    return res?.data
  })
  .catch((err)=>{
    return err;
  })
}

export const GetAllPendingBillingService = (data) => {
  return MasterConfig.post(`api/Billing/GetAllPendingBilling` , data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const SendBillService = (data) => {
  return MasterConfig.post(`api/Billing/SendBill` , data)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
};

export const GetBillingByIdService = (id) => {
  return MasterConfig.get(`/api/Billing/GetBillingById?id=${id}`)
    .then((res) => {    
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const PayBillRequestService = (id) => {
  return MasterConfig.get(`/api/Billing/PayBillRequest?id=${id}`)
    .then((res) => {    
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetBillForPayment = (data) => {
  let {token , authToken:customToken} = data
  return MasterConfig.get(`/api/Billing/GetBillForPayment?token=${token}`,{customToken})
    .then((res) => {    
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

