import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddIssueMemberByIdService, GetAllProjectIssuesService,RemoveIssueMemberByIdService,UpdateStatusByIdService,addProjectIssuesService } from "../service/service";
import { responseMessage, responseMessageType } from "../../../../services/slices/notificationSlice";
import { responseCode } from "../../../../services/enums/responseCode";
import { message } from "antd";

export const GetAllProjectIssues = createAsyncThunk(
    'Pages/GetAllProjectIssues',
    async ({ filter, identifier }, { dispatch }) => {
      const res = await GetAllProjectIssuesService(filter);
      if (!res.responseCode) {
        responseMessage({
          dispatch: dispatch,
          type: responseMessageType.ApiFailure,
        });
      }
      return res;
    }
  );
  
  export const addProjectIssues = createAsyncThunk(
    "ProjectIssues/addProjectIssues",
    async (data, { dispatch, getState, rejectWithValue }) => {
      const res = await addProjectIssuesService(data);
      if (res.data?.responseCode === responseCode.Success) {
        message.success("Issue Created");
        return res;
      } else {
        message.error(res.data.message);
        return rejectWithValue(res.data.message);
      }
    }
  );
  

  export const AddIssueMemberById = createAsyncThunk(
    "AddIssueMemberById/AddIssueMember",
    async (data) => {
      const response = await AddIssueMemberByIdService(data);
      return response.data;
    }
  );

  export const UpdateIssueStatusById = createAsyncThunk(
    "UpdateIssue/UpdateIssueStatusById",
    async (data) => {
      const response = await UpdateStatusByIdService(data);
      return response.data;
    }
  );

  export const RemoveIssueMemberById = createAsyncThunk(
    "RemoveMember/RemoveIssueMemberById",
    async (data) => {
      const response = await RemoveIssueMemberByIdService(data);
      return response.data;
    }
  );