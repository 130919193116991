import { ResponseResultError, ResponseResultSuccess } from "../../../../utils/api/ResponseResult";
import Config from "../../../../utils/services/MasterConfig";

export const getAlldefaultAccountHeaderService = async () => {
	try {
		const {
			data: { responseCode, data, message },
		} = await Config.get(`api/ChartOfAccount/GetAllChartOfAccount`);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};

export const updatedefaultAccountHeaderService = (args) => {
	return Config.put(`api/DefaultAccountHeader/UpdateDefaultAccountHeader`, args)
	  .then((res) => {
		return res.data;
	  })
	  .catch((err) => {
		return err;
	  });
  };

  export const getAllAccountHeaderService = async () => {
	try {
		const {
			data: { responseCode, data, message },
		} = await Config.get(`/api/DefaultAccountHeader/GetAllDefaultAccountHeader`);
		if (responseCode === 1001) return ResponseResultSuccess(data);
		return ResponseResultError(message);
	} catch (e) {
		return ResponseResultError(e);
	}
};