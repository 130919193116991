import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseCode } from '../../../../services/enums/responseCode';
import {
  responseMessage,
  responseMessageType,
} from '../../../../services/slices/notificationSlice';
import {
    getBusinessAssociationService,
    addBusinessAssociationService,
    updateBusinessAssociationService,
} from '../service/service';
import { message } from 'antd';

export const getBusinessAssociation = createAsyncThunk(
  'BusinessAssociation/getBusinessAssociation',
  async (data, { dispatch }) => {
    const res = await getBusinessAssociationService(data);
    if (!res.responseCode) {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }
    return res;
  }
);

export const addBusinessAssociation = createAsyncThunk(
  'BusinessAssociation/addBusinessAssociation',
  async (args, { dispatch }) => {
    const res = await addBusinessAssociationService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Business Association added successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);

export const updateBusinessAssociationAction = createAsyncThunk(
  'BusinessAssociation/updateBusinessAssociationAction',
  async (args, { dispatch }) => {
    const res = await updateBusinessAssociationService(args);
    if (res.responseCode) {
      if (res.responseCode === responseCode.Success)
        res.message = 'Business Association updated successfully!';
      message.success(res.message);
      responseMessage({ dispatch, data: res });
    } else {
      responseMessage({
        dispatch: dispatch,
        type: responseMessageType.ApiFailure,
      });
    }

    return res;
  }
);