import { Button, Input, Modal } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import tasks from "../../../../content/svg/menu/newNavBarIcon/Tasks.svg";
import TaskComposerForMintueOfMeeting from "./TaskComposerForMintueOfMeeting";
import TaskComposer from "./TaskComposer";
import TaskDetail from "./TaskDetail";
import {
  ScheduleMinutesOfMeetingDetailStatusEnum,
  ScheduleMinutesOfMeetingStatusEnum,
} from "../enum/enum";
import { STRINGS } from "../../../../utils/base";
import { dictionaryList } from "../../../../utils/localization/languages";
import { LanguageChangeContext } from "../../../../utils/localization/localContext/LocalContext";

const Discussion = ({ setDetailData, inputs, setInputs }) => {
  const { TextArea } = Input;

  ///states
  const { userLanguage } = useContext(LanguageChangeContext);
  const { schedule } = dictionaryList[userLanguage];
  const [openTask, setOpenTask] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentClickId, setCurrentClickedId] = useState();
  const [subject, setSubject] = useState("");
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const inputRefs = useRef([]);
  const textInputRefs = useRef([]);

  ///data from redux
  const { meetingDetail } = useSelector((state) => state.scheduleSlice);
  const [DetailsObject, setDetailsObject] = useState(meetingDetail?.details);

  const handleTaskFormSubmit = (index, taskData) => {
    // Check if the taskData.id already exists in DetailsObject
    const existingIndex = DetailsObject.findIndex(
      (item) => item.id === taskData.id
    );
    console.log(
      existingIndex,
      index,
      "indexx",
      DetailsObject,
      taskData,
      inputs
    );

    const updatedDetailsObject = [...DetailsObject];
    let updateinputObj = [...inputs];

    if (existingIndex !== -1) {
      // If taskData.id exists, update the existing task
      updatedDetailsObject[existingIndex] = taskData;
      setDetailsObject(updatedDetailsObject);
      setDetailData(updatedDetailsObject);
    } else {
      // If taskData.id doesn't exist, add the new task
      // updatedDetailsObject.push(taskData);
      updateinputObj[index - DetailsObject.length] = taskData;
      setInputs(updateinputObj);
    }

    setOpenTask(false);
    setCurrentIndex(-1);

    if (existingIndex === -1) {
      // Remove text from textArea after adding task
      // setInputs((prevInputs) => {
      //   const updatedInputs = [...prevInputs];
      //   updatedInputs[index] = { input: "", button: false };
      //   return updatedInputs;
      // });
    }
  };

  const handleOpenModal = (id) => {
    console.log(id, "Current clicked Id");
    setCurrentClickedId(id);
    setOpenDetailModal(true);
  };

  useEffect(() => {
    setDetailsObject(meetingDetail?.details);
  }, [meetingDetail]);

  // Helper function to update the state when the input values change
  const handleInputChange = (event, index, fromDetail = false) => {
    const { value } = event.target;

    console.log(value, index, fromDetail, "datatatatatatat");
    if (fromDetail) {
      const updatedDetailsObject = [...DetailsObject];
      updatedDetailsObject[index] = {
        ...updatedDetailsObject[index],
        detail: value, // Update the detail property with the new characters
      };
      setDetailsObject(updatedDetailsObject);
      setDetailData(updatedDetailsObject);
    } else {
      setInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs[index] = {
          ...updatedInputs[index],
          input: value,
          button: !!value,
        };
        return updatedInputs;
      });
    }
  };

  // Button click handler for a specific input field
  const handleButtonClick = (index, id = STRINGS.DEFAULTS.guid) => {
    if (id == STRINGS.DEFAULTS.guid) {
      setSubject(inputs[index].input);
      setCurrentIndex(index + DetailsObject.length);
    } else {
      setSubject(DetailsObject[index]?.detail);
      setCurrentIndex(index);
    }
    setTaskId(id);
    setOpenTask(true);
  };

  // Function to add a new input field
  const addInputField = () => {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs, { input: "", button: false }];
      return newInputs;
    });
    setTimeout(() => {
      if (inputRefs.current[inputRefs.current.length - 1]) {
        inputRefs.current[inputRefs.current.length - 1].scrollIntoView({
          behavior: "smooth",
        });
        textInputRefs.current[textInputRefs.current.length - 1].focus();
      }
    }, 0);
  };

  return (
    <>
      <div className="discussion-tab">
        {DetailsObject?.length > 0 &&
          DetailsObject?.map((item, index) => {
            return (
              <div className="flex items-center gap-2 mt-2" key={index}>
                <label className="font-semibold">{index + 1}-</label>
                {item.status ===
                ScheduleMinutesOfMeetingDetailStatusEnum.ConvertedNotToTask ? (
                  <a>
                    <span
                      className="cursor-pointer"
                      onClick={() => handleOpenModal(item.taskId)}
                    >
                      {item?.detail}
                    </span>
                  </a>
                ) : (
                  <>
                    <TextArea
                      key={item.id}
                      showCount
                      maxLength={100}
                      onChange={(e) => handleInputChange(e, index, true)}
                      defaultValue={item?.detail}
                      // value={item.detail}
                      onPressEnter={(event) => {
                        if (!event.shiftKey) {
                          addInputField();
                          event.preventDefault();
                        }
                      }}
                    />
                    <Button
                      className="ThemeBtn"
                      onClick={() =>
                        handleButtonClick(index, DetailsObject[index]?.id)
                      }
                    >
                      <img src={tasks} width={20} className="task-icon" />
                    </Button>
                  </>
                )}
              </div>
            );
          })}

        {/* code  */}

        {meetingDetail?.status === ScheduleMinutesOfMeetingStatusEnum.Draft &&
          inputs.map((inputData, index) => {
            const labelNumber = DetailsObject?.length
              ? index + DetailsObject.length + 1
              : index + 1;
            return (
              <div
                className="flex items-center gap-2 mt-2"
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
              >
                <label className="font-semibold">{labelNumber}-</label>
                {inputData.status ===
                ScheduleMinutesOfMeetingDetailStatusEnum.ConvertedNotToTask ? (
                  <a>
                    <span
                      className="cursor-pointer"
                      onClick={() => handleOpenModal(inputData.taskId)}
                    >
                      {inputData?.detail}
                    </span>
                  </a>
                ) : (
                  <>
                    <TextArea
                      showCount
                      maxLength={100}
                      onChange={(e) => handleInputChange(e, index)}
                      value={inputs[index]?.input}
                      onPressEnter={(event) => {
                        if (!event.shiftKey) {
                          addInputField();
                          event.preventDefault();
                        }
                      }}
                      ref={(el) => (textInputRefs.current[index] = el)}
                    />
                    {inputData.button && (
                      <Button
                        className="ThemeBtn"
                        onClick={() => handleButtonClick(index)}
                      >
                        <img src={tasks} width={20} className="task-icon" />
                      </Button>
                    )}
                  </>
                )}
              </div>
            );
          })}

        {/* code  */}
      </div>
      {meetingDetail.status == ScheduleMinutesOfMeetingStatusEnum.Draft && (
        <div className="flex justify-end mt-3">
          <Button className="ThemeBtn" onClick={addInputField}>
            {schedule.AddPoint}
          </Button>
        </div>
      )}
      {openTask && (
        <Modal
          open={openTask}
          onCancel={() => setOpenTask(false)}
          closable={false}
          footer={null}
          className="modal-body"
          width={800}
          centered={false}
          style={{ top: 20 }}
        >
          {/* <TaskComposer
            onTaskCreated={(index, taskData) =>
              handleTaskFormSubmit(index, taskData)
            }
            index={currentIndex}
            subject={subject}
            taskId={taskId}
          /> */}
          <TaskComposerForMintueOfMeeting
            onTaskCreated={(index, taskData) =>
              handleTaskFormSubmit(index, taskData)
            }
            index={currentIndex}
            subject={subject}
            taskId={taskId}
            mintueOfMeetingDetail={meetingDetail}
          />
        </Modal>
      )}

      {openDetailModal && (
        <Modal
          open={openDetailModal}
          onCancel={() => setOpenDetailModal(false)}
          closable={false}
          footer={null}
          className="modal-body"
          width={800}
          centered={false}
          style={{ top: 20 }}
        >
          <TaskDetail currentClickId={currentClickId} />
        </Modal>
      )}
    </>
  );
};
export default Discussion;
