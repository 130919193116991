export const ALLOWANCE_ENUM = {
  UNIT: {
    PERCENT: 1,
    AMOUNT: 2,
  },
  TYPE: {
    BENEFIT: 1,
    DEDUCTION: 2,
  },
};

export const ALLOWANCE_UNIT_ENUM = {
  PERCENT: 1,
  AMOUNT: 2,
};
export const ALLOWANCE_TYPE_ENUM = {
  BENEFIT: 1,
  DEDUCTION: 2,
};

export const ALLOWANCE_TYPE_ENUM_LIST = [
  {
    id: ALLOWANCE_TYPE_ENUM.BENEFIT,
    name: "Benefit",
  },
  {
    id: ALLOWANCE_TYPE_ENUM.DEDUCTION,
    name: "Deduction",
  },
];
